import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Icon, notification, Select, Spin } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Conteudos extends Component {
    static contextType = GlobalContext;

    state = {
        bncc: [],
        conteudo: "",
        conceitos: undefined,
        metodologia: "",
        tituloConteudos: "",
        tituloHabilidades: "",
        tituloMetodologia: "",
        //LOADING
        iconLoading: false,
        searchValue: "",
        filteredData: [],
        loading: false,
        page: 1,
    };

    componentDidMount() {
        this.detalhe();
        this.titulosDetalhe();
        this.listarBncc();
    }

    componentDidUpdate(prevProps) {
        if (this.props.sumarioExcluido) {
            this.props.form.resetFields();
            this.setState({
                conteudo: "",
                conceitos: undefined,
                metodologia: ""
            });
            this.props.resetSumarioExcluido();
        }

        if (this.props.aulaId !== prevProps.aulaId) {
            this.detalhe();
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSelect = conceitos => {
        this.setState({
            conceitos,
            filteredData: this.state.bncc.slice(0, 20),
            page: 2,
        });
    };

    listarBncc = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-bncc",
            params: {
                aulaId: this.props.aulaId
            }
        })
            .then(response => {
                this.setState({
                    bncc: response.data,
                    filteredData: response.data.slice(0, 20),
                    page: 2,
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a bncc!");
            });
    };

    detalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe-conteudo",
            params: {
                aulaId: this.props.aulaId
            }
        })
            .then(response => {
                this.setState({
                    conteudo: response.data.conteudo,
                    conceitos: response.data.conceitos ? response.data.conceitos.split(";") : undefined,
                    metodologia: response.data.metodologia
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    };

    titulosDetalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/titulos-detalhe-conteudo",
            params: {
                aulaId: this.props.aulaId
            }
        })
            .then(response => {
                this.setState({
                    tituloConteudos: response.data.conteudos,
                    tituloHabilidades: response.data.habilidades,
                    tituloMetodologia: response.data.metodologias
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    };

    alterar = (multisseriado) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && (this.state.conteudo || this.state.conceitos || this.state.metodologia)) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.aulaId);
                if (this.state.conteudo)
                    item.append("conteudo", this.state.conteudo);
                if (this.state.conceitos)
                    item.append("conceitos", this.state.conceitos.join("; "));
                if (this.state.metodologia)
                    item.append("metodologia", this.state.metodologia);
                item.append("multisseriado", multisseriado);

                axios({
                    method: "put",
                    url: "/api/aulas/alterar-conteudo",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Conteúdos da aula alterados!");
                        this.setState({ iconLoading: false });
                        this.props.disponibilizarExcluir();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível alterar conteúdos da aula!");
                        this.setState({ iconLoading: false });
                    });
            } else {
                if (!this.state.conteudo && !this.state.conceitos && !this.state.metodologia)
                    openNotificationWithIcon("error", "Erro", "Não existem dados a serem guardados!");
            }
        });
    };

    handleSearch = (searchValue) => {
        this.setState({
            searchValue
        });
        if (searchValue) {
            const filtered = this.state.bncc.filter(item => item.toLowerCase().split("-")[0].includes(searchValue.toLowerCase()));

            this.setState({
                filteredData: filtered.slice(0, 20),
                loading: false,
                page: 2,
            });
        }
        else {
            this.setState({
                filteredData: [],
                loading: false,
                page: 2,
            });
        }
    };

    fetchMoreData = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            let listaCampos = this.state.bncc;
            if (this.state.searchValue)
                listaCampos = this.state.bncc.filter(item => item.toLowerCase().split("-")[0].includes(this.state.searchValue.toLowerCase()));

            const newData = listaCampos.slice((this.state.page - 1) * 20, this.state.page * 20);
            if (newData.length > 0) {
                this.setState(prevState => ({
                    filteredData: [...prevState.filteredData, ...newData],
                    loading: false,
                    page: prevState.page + 1,
                }));
            }
        }, 1000);
    };

    handlePopupScroll = e => {
        const { target } = e;
        if (target.scrollTop + target.clientHeight === target.scrollHeight) {
            this.fetchMoreData();
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        this.props.form.resetFields();

        const {
            bncc,
            conteudo,
            conceitos,
            metodologia,
            tituloConteudos,
            tituloHabilidades,
            tituloMetodologia,
            //LOADING
            iconLoading
        } = this.state;

        const {
            codigoMultisseriado
        } = this.props;

        const acesso = this.context.acessos?.find(x => x.func === 17);

        return (
            <>
                <Form className="form-categorias" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label={tituloConteudos}>
                            {getFieldDecorator("conteudo", {
                                initialValue: conteudo
                            })(
                                <TextArea
                                    name="conteudo"
                                    rows={4}
                                    style={{ height: 100 }}
                                    onChange={this.handleChange}
                                    disabled={!acesso?.update}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={tituloHabilidades}>
                            {getFieldDecorator("conceitos", {
                                initialValue: conceitos
                            })(
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Selecionar conceito"
                                    onChange={this.handleChangeSelect}
                                    onSearch={this.handleSearch}
                                    disabled={!acesso?.update}
                                    onPopupScroll={this.handlePopupScroll}
                                >
                                    {/*{this.state.searchValue && this.state.filteredData.map((item, index) => (*/}
                                    {/*    <Option key={index} value={item}>*/}
                                    {/*        {item}*/}
                                    {/*    </Option>*/}
                                    {/*))}*/}
                                    {this.state.filteredData.map((item, index) => (
                                        <Option key={index} value={item}>
                                            {item}
                                        </Option>
                                    ))}
                                    {this.state.loading && (
                                        <Option key="loading" disabled>
                                            <Spin size="small" />
                                        </Option>
                                    )}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label={tituloMetodologia}>
                            {getFieldDecorator("metodologia", {
                                initialValue: metodologia
                            })(
                                <TextArea
                                    name="metodologia"
                                    rows={4}
                                    style={{ height: 100 }}
                                    onChange={this.handleChange}
                                    disabled={!acesso?.update}
                                />
                            )}
                        </Form.Item>
                    </div>
                    {acesso?.update && (
                        <div style={{ display: "flex", gap: 10 }}>
                            {codigoMultisseriado && (
                                <button className="botao-principal" onClick={() => this.alterar(true)} disabled={iconLoading} type="primary">
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Salvar Multisseriado
                                </button>)}
                            <button className="botao-principal" onClick={() => this.alterar(false)} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Salvar
                            </button>
                        </div>
                    )}
                </Form>
            </>
        );
    }
}

const FormConteudos = Form.create({ name: "form-conteudos" })(Conteudos);

export default withRouter(FormConteudos);
