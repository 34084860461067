import React, { useState, useEffect } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Icon } from 'antd';
import axios from "axios";

const Tabela = ({ match }) => {
    const [cursos, setCursos] = useState([])
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [loading, setLoading] = useState(false);

    const cols = [
        {
            title: "Edição",
            dataIndex: "edicao"
        },
        {
            title: "Curso",
            dataIndex: "nome"
        },
        //{
        //    title: "Periodo",
        //    dataIndex: "periodo"
        //},
        {
            title: "Classificação",
            dataIndex: "classificacao"
        },
        {
            title: "Estado",
            dataIndex: "estado"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-cursos",
            params: {
                code: match.params.code,
            }
        })
            .then(response => {
                var lista = response.data.map(curso => {
                    return {
                        key: curso.id,
                        edicao: curso.edicao,
                        nome: curso.nome,
                        classificacao: "",
                        estado: "",
                        opcoes: "",
                    }
                })
                setCursos(lista)
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    //const data = [
    //    {
    //        key: 1,
    //        edicao: "2022 / 2023",
    //        curso: "Marketing Digital",
    //        periodo: "01/01/2023 - 31/03/2023",
    //        classificacao: 20,
    //        estado: "A frequentar",
    //        opcoes: <Link to="#" className="botao-icon-download" title="Descarregar certificado">
    //            <Icon type="download" />
    //        </Link>
    //    },
    //    {
    //        key: 2,
    //        edicao: "2022 / 2023",
    //        curso: "Programação",
    //        periodo: "01/01/2023 - 31/03/2023",
    //        classificacao: 20,
    //        estado: "A frequentar",
    //        opcoes: <Link to="#" className="botao-icon-download" title="Descarregar certificado">
    //            <Icon type="download" />
    //        </Link>
    //    },
    //    {
    //        key: 3,
    //        edicao: "2022 / 2023",
    //        curso: "Excel avançado",
    //        periodo: "01/01/2023 - 31/03/2023",
    //        classificacao: 20,
    //        estado: "Concluído",
    //        opcoes: <Link to="#" className="botao-icon-download" title="Descarregar certificado">
    //            <Icon type="download" />
    //        </Link>
    //    }
    //]

    const onRowClick = (cursoNome, cursoId) => {
        localStorage.setItem("codigo_curso", cursoId);
        localStorage.setItem("opcao_voltar", JSON.stringify({
            link_voltar: `/utilizador-detalhe/${match.params.code}`
        }))

        const nomeCurso = cursoNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase();
        setRedirectLink(`/gerir-curso/${nomeCurso}/curso-visao-geral`);
        setRedirect(true);
    }

    useEffect(() => {
        carregar();
    }, [])

    if (redirect)
        return <Redirect to={redirectLink} />;

    return (
        <Table
            className="tabela-user-disciplinas"
            columns={cols}
            pagination={false}
            dataSource={cursos}
            rowClassName="clickeble-row"
            onRow={(record, index) => {
                return {
                    onClick: () => onRowClick(record.nome, record.key)
                };
            }}
            loading={{
                spinning: loading,
                indicator: (
                    <div className="loading-data-table">
                        <div className="loading" />
                    </div>
                )
            }}
        />
    );
}

export default withRouter(Tabela);