import React, { useEffect, useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { notification } from "antd";

import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Acessos = ({ funcionalidadeId }) => {
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const context = useContext(GlobalContext);


    const validarAcesso = () => {
        const acesso = context.acessos?.find(x => x.func === funcionalidadeId);
        if (!acesso) {
            openNotificationWithIcon("warning", "Atenção", "Não tem permissão para aceder a esta opção.");

            const perfilId = context.perfil?.id;

            if (perfilId == 1) //SUPORTE
                setRedirectLink("/gestao-suporte");
            else if (perfilId == 2) //GESTOR
                setRedirectLink("/gestao-turmas");
            else if (perfilId == 3) //ADMINISTRATIVO
                setRedirectLink("/gestao-utilizadores-todos");
            else if (perfilId == 4) //DIRETOR
                setRedirectLink("/gestao-turmas");
            else //ALUNO, PROFESSOR E COORDENADOR
                setRedirectLink("/utilizador-detalhe");

            setRedirect(true);
        }
    }

    useEffect(() => {
        if (context.acessos && context.perfil)
            validarAcesso();
    }, [context.acessos, context.perfil])

    if (redirect)
        return <Redirect to={redirectLink} />;

    //const acesso = context.acessos?.find(x => x.func === funcionalidadeId);
    //if (!acesso) {
    //    openNotificationWithIcon("warning", "Atenção", "Não tem permissão para aceder a esta opção.");

    //    const perfilId = context.perfil?.id;

    //    if (perfilId == 1) //SUPORTE
    //        return <Redirect to="/gestao-suporte" />;
    //    else if (perfilId == 2) //GESTOR
    //        return <Redirect to="/gestao-utilizadores-todos" />;
    //    else if (perfilId == 3) //ADMINISTRATIVO
    //        return <Redirect to="/gestao-utilizadores-todos" />;
    //    else if (perfilId == 4) //DIRETOR
    //        return <Redirect to="/gestao-turmas" />;
    //    else //ALUNO, PROFESSOR E COORDENADOR
    //        return <Redirect to="/utilizador-detalhe" />;
    //}

    return (
        <div></div>
    );
}

export default Acessos;
