import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import MsgSecundaria from "../Geral/_Aux/MsgSecundaria";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class Unsubscribe extends Component {
    state = {
        nome: "",
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.unsubscribe();
    }

    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    unsubscribe = () => {
        if (!this.props.match.params.entidade)
            axios({
                method: "get",
                url: "/api/anonimo/unsubscribe",
                params: {
                    code: this.getParameterByName("code"),
                    entidade: false
                }
            })
                .then(response => {
                    if (response.data)
                        this.setState({
                            nome: response.data
                        });
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível cancelar a sua subscrição");
                });
        else
            axios({
                method: "get",
                url: "/api/anonimo/unsubscribe",
                params: {
                    code: this.getParameterByName("code"),
                    entidade: true
                }
            })
                .then(response => {
                    if (response.data)
                        this.setState({
                            nome: response.data
                        });
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível cancelar a sua subscrição");
                });
    };

    render() {
        const { redirect, redirectLink } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <div className="pagina-erro">
                    <div className="hero">
                        {/*<LazyLoad offsetVertical={200}>*/}
                        {/*    <img src={require("../images/wave_hero_user.svg")} />*/}
                        {/*</LazyLoad>*/}
                        <div className="container" style={{ marginTop: 80 }}>
                            <div className="pagina-erro-header">
                                <div className="container-img">
                                    <img src={require("../images/celebrate.svg")} />
                                </div>
                            </div>
                            <div className="pagina-erro-content">
                                <MsgSecundaria texto="Subscrição cancelada" style={{ fontSize: 32, color: "white" }} />
                                <MsgSecundaria
                                    texto={
                                        "Subscrição anulada! A partir deste momento deixará de receber quaisquer informações sobre a " +
                                        this.state.nome
                                    }
                                />
                                <Link to="/" className="botao-principal">
                                    Ir para STEPFORMA
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Unsubscribe;
