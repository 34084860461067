import React, { Component } from "react";
import { notification } from "antd";
import axios from "axios";
import moment from "moment";

import VistaDesktop from "../../Vistas/VistaDesktop";
import VistaMobile from "../../Vistas/VistaMobile";
import ModalLoading from "../../../../Componentes/Modals/ModalLoading";
import ModalFrequenciaDia from "./Componentes/Modals/ModalFrequenciaDia";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ExcelTabelaFrequencia extends Component {
    state = {
        disciplinaId: localStorage.getItem("vista") === "TURMA" ? null : localStorage.getItem("codigo_curso"),
        //FILTROS
        disciplinas: [],
        disciplinasId: [],
        disciplinasNome: "",
        num: 1,
        total: 0,
        mesPeriodo: 0,
        anoPeriodo: 0,
        semPeriodos: false,
        mesesPeriodo: [],
        periodos: [],
        numDias: 0,
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        mes: "",
        ano: "",
        periodoTitulo: "",
        titulo: "",
        aulas: [],
        participantes: [],
        //LOADING
        iconLoading: false,
        //MODAL LISTAGEM AULAS
        visible: false,
        participanteId: 0,
        aulasDia: [],
        keys: [],
        dataAula: "",
        estado: "Proximo"
    };

    componentDidMount() {
        if (localStorage.getItem("vista") === "TURMA")
            this.listarDisciplinas();

        this.total();
    }

    mesId(mes) {
        switch (mes.toLowerCase()) {
            case "janeiro":
                return 0;
            case "fevereiro":
                return 1;
            case "março":
                return 2;
            case "abril":
                return 3;
            case "maio":
                return 4;
            case "junho":
                return 5;
            case "julho":
                return 6;
            case "agosto":
                return 7;
            case "setembro":
                return 8;
            case "outubro":
                return 9;
            case "novembro":
                return 10;
            case "dezembro":
                return 11;
        }
    }

    selectDiscipline = opcao => {
        this.setState({ isSelected: true });
        this.handleSelect(opcao);
    };

    handleSelect = opcao => {
        let disciplinaId = opcao;
        if (opcao === "Todos") {
            this.setState(
                {
                    todos: opcao === "Todos"
                },
                () => this.listar(true)
            );
        }

        if (disciplinaId) {
            this.props.setDisciplinaId(disciplinaId);
            this.setState(
                {
                    disciplinaId,
                    disciplinasNome: this.state.disciplinas.find(f => f.id === disciplinaId)
                },
                () => this.listar(true)
            );
        } else {
            this.setState({
                aulas: [],
                participantes: []
            });
        }
    };

    total = () => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/total-periodos-frequencia",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            mesPeriodo: this.mesId(response.data[0].mes),
                            anoPeriodo: parseInt(response.data[0].ano)
                        });

                        if (response.data.length > 0) {
                            this.setState(
                                {
                                    total: response.data.length,
                                    mesesPeriodo: response.data,
                                    numDias: response.data[0].dias,
                                    mes: response.data[0].mes,
                                    ano: response.data[0].ano,
                                    periodos: response.data,
                                    periodoTitulo: response.data[0].titulo,
                                    titulo: `${response.data[0].mes.charAt(0).toUpperCase() + response.data[0].mes.slice(1)} ${response.data[0].ano
                                        } / ${response.data[0].titulo}`,
                                    dt_inicio: response.data[0].dt_inicio,
                                    dt_fim: response.data[0].dt_fim
                                },
                                () => {
                                    if (!localStorage.getItem("vista"))
                                        this.listar(true);
                                }
                            );
                        }
                        else {
                            this.setState({
                                semPeriodos: true,
                                iconLoading: false
                            });
                            openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                        }
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                    });
            }
        );
    };

    listar = listarParticipantes => {
        //this.setState({
        //    iconLoading: true
        //}, () => {
            axios({
                method: "get",
                url: "/api/aulas/listar-frequencia",
                params: {
                    cursoid: this.state.disciplinaId == "Todos" ? localStorage.getItem("codigo_curso") : this.state.disciplinaId,
                    dt_inicio: this.state.dt_inicio,
                    dt_fim: this.state.dt_fim
                }
            })
                .then(response => {
                    if (listarParticipantes)
                        this.setState(
                            {
                                aulas: response.data
                            },
                            () => this.listarParticipantes()
                        );
                    else {
                        this.setState(
                            {
                                aulas: response.data,
                                iconLoading: false
                            },
                            () => this.adicionarAcumulado()
                        );
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                });
        //})
    };

    listarParticipantes = () => {
        axios({
            method: "get",
            url: "/api/aulas/participantes",
            timeout: 60 * 10 * 1000,
            params: {
                cursoid: this.state.disciplinaId == "Todos" ? localStorage.getItem("codigo_curso") : this.state.disciplinaId,
                dataAno: this.state.ano,
                dataMes: this.state.mes,
                mostrarTodos: true
            }
        })
            .then(response => {
                const participantes = response.data.map(participante => {
                    return {
                        ...participante,
                        acumulados: [this.montarAcumulados(participante.id, participante.ativo, participante.dataAtualizado)]
                    };
                });

                this.setState({
                    participantes,
                    iconLoading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    adicionarAcumulado = () => {
        const participantes = this.state.participantes.map(participante => {
            if (participante.acumulados.find(x => x.periodo === this.state.periodoTitulo && x.mes === this.state.mes)) {
                return participante;
            }
            else {
                return {
                    ...participante,
                    acumulados: [
                        ...participante.acumulados,
                        this.montarAcumulados(participante.id, participante.ativo, participante.dataAtualizado)
                    ]
                };
            }
        });

        this.setState({
            participantes
        });
    };

    montarAcumulados = (participanteId, participanteAtivo, participanteData) => {
        let totalPresencas = 0;
        let totalFaltas = 0;
        let totalJustificadas = 0;
        let dias = [];

        for (let i = 0; i < this.state.numDias; i++) {
            const aulas = this.state.aulas.filter(x =>
                moment(x.dt_inicio).isSame(
                    `${this.state.ano}-${("0" + moment().month(this.state.mes).format("M")).slice(-2)}-${("0" + (i + 1)).slice(-2)}`
                )
            );

            if (aulas.length > 0) {
                let presencas = 0;
                let faltas = 0;
                let faltasJustificadas = 0;
                const ativo =
                    participanteAtivo || (!participanteAtivo && moment(participanteData).isSameOrAfter(moment(aulas[0].dt_inicio)));

                var presente = aulas[0].presentes?.some(x => x === participanteId);

                if (ativo && presente) {
                    aulas.map(aula => {
                        const presencasAlunos = aula.presencas ? JSON.parse(aula.presencas) : [];
                        const participante = presencasAlunos.find(x => x.id === participanteId);
                        if (
                            (aula.presenca ||
                                (!aula.presenca &&
                                    presencasAlunos.length &&
                                    presencasAlunos.find(x => x.id === participanteId && x.estado === "P"))) &&
                            participante != undefined &&
                            !participante?.bloqueado
                        ) {
                            presencas++;
                        }
                        if (
                            (aula.falta ||
                                (!aula.falta &&
                                    presencasAlunos.length &&
                                    presencasAlunos.find(x => x.id === participanteId && x.estado === "F"))) &&
                            participante != undefined &&
                            !participante?.bloqueado
                        ) {
                            faltas++;
                        }
                        if (
                            !aula.presenca &&
                            !aula.falta &&
                            presencasAlunos.length &&
                            presencasAlunos.find(x => x.id === participanteId && x.estado === "FJ")
                        ) {
                            faltasJustificadas++;
                        }
                    });

                    totalPresencas += presencas;
                    totalFaltas += faltas;
                    totalJustificadas += faltasJustificadas;
                }
            }
        }

        const acumulados = {
            periodo: this.state.periodoTitulo,
            mes: this.state.mes,
            totalPresencas: totalPresencas,
            totalFaltas: totalFaltas,
            totalJustificadas: totalJustificadas
        };

        return acumulados;
    };

    anteriorProximo = (num, estado) => {
        var periodo = this.state.mesesPeriodo[num - 1];

        this.setState(
            {
                iconLoading: true,
                num,
                numDias: periodo.dias,
                periodoTitulo: periodo.titulo,
                titulo: `${periodo.mes.charAt(0).toUpperCase() + periodo.mes.slice(1)} ${periodo.ano} / ${periodo.titulo}`,
                mes: periodo.mes,
                ano: periodo.ano,
                dt_inicio: periodo.dt_inicio,
                dt_fim: periodo.dt_fim,
                estado,
                mesPeriodo: this.mesId(periodo.mes),
                anoPeriodo: parseInt(periodo.ano)
            },
            () => {
                this.listar(false);
            }
        );
    };

    listarDisciplinas = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-turma-disciplinas",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    disciplinas: response.data,
                    iconLoading: false,
                    disciplinasId: response.data.map(e => e.id)
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as disciplinas!");
            });
    };

    //Abre modal de frequencia individual por dia
    abrirModal = (aulasDia, participanteId) => {
        this.setState({
            visible: true,
            participanteId,
            aulasDia,
            keys: [],
            dataAula: moment(aulasDia[0].dt_inicio).format("DD/MM/YYYY")
        });
    };
    
    //Fecha modal de frequencia individual por dia
    fecharModal = (existemAlteracoes) => {
        this.setState({
            visible: false
        });

        if (existemAlteracoes)
            this.anteriorProximo(this.state.num, this.state.estado);
    };

    render() {
        const {
            //LOADING
            iconLoading,
            //MODAL LISTAGEM AULAS
            visible,
            participanteId,
            aulasDia,
            keys,
            dataAula
        } = this.state;

        return (
            <>
                <div className="curso-diario curso-diario-frequencia-desktop">
                    <VistaDesktop
                        dados={this.state}
                        selectDiscipline={this.selectDiscipline}
                        anteriorProximo={this.anteriorProximo}
                        abrirModal={this.abrirModal}
                    />
                </div>
                <div className="curso-diario curso-diario-frequencia-mobile">
                    <VistaMobile
                        dados={this.state}
                        selectDiscipline={this.selectDiscipline}
                        anteriorProximo={this.anteriorProximo}
                        abrirModal={this.abrirModal}
                    />
                </div>
                <ModalLoading
                    visible={iconLoading}
                    text="A carregar..."
                />
                <ModalFrequenciaDia
                    visible={visible}
                    participanteId={participanteId}
                    dataAula={dataAula}
                    aulasDia={aulasDia}
                    keys={keys}
                    onChange={keys => this.setState({ keys })}
                    fecharModal={this.fecharModal}
                />
            </>
        );
    }
}

export default ExcelTabelaFrequencia;
