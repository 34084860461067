import React, { Component } from "react";

class TesteDropBox extends Component {
    render() {
        return (
            <div>
                Drop Box....
            </div>
        );
    }
}

export default TesteDropBox;
