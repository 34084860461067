import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ConteudoResumo from "./ConteudoResumo";
import Controlos from "../_Aux/Controlos";
import { GlobalContext } from "../../GlobalState";
import LazyLoad from "react-lazy-load";
import { notification } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Resumo extends Component {
    static contextType = GlobalContext;
    state = {
        aulaid: 0,
        totalCertas: 0,
        totalRespostas: 0,
        loading: true,
        redirect: false,
        redirectLink: "",
        //redirectLinkResolucao: "",
    }

    componentDidMount() {
        axios.defaults.timeout = 60 * 10 * 1000;

        this.setState({
            aulaid: localStorage.getItem("codigo_aula"),
            redirectLink: `/curso/${this.props.match.params.curso}/"${this.props.match.params.aula}/player-questionario-progresso?repetir=true`,
            //redirectLinkResolucao: `/curso/${this.props.match.params.curso}/${this.props.match.params.aula}/player-questionario-progresso-resolucao`,
        }, () => {
                this.validar();
        });
    }

    validar = () => {
        axios({
            method: "get",
            url: "/api/questionario/validar-questionario",
            params: {
                aulaid: this.state.aulaid
            }
        })
            .then(response => {
                this.setState({
                    totalCertas: response.data.totalRespostasCertas,
                    totalRespostas: response.data.userRespostasCertas,
                    loading: false
                });

                if (response.data.totalRespostasCertas > response.data.userRespostasCertas)
                    this.context.atualizarListaModulos(true);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível repetir!");
            });
    }

    RepetirQuestionario = event => {
        event.preventDefault();

        axios({
            method: "put",
            url: "/api/questionario/repetir-questionario",
            params: {
                aulaid: this.state.aulaid
            }
        })
            .then(() => {
                this.setState({
                    redirect: true
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível repetir!");
            });
    };

    render() {
        if (this.state.redirect) return <Redirect to={this.state.redirectLink} />;

        return (
            <div className="questionario-resumo">
                <div className="player-wrapper">
                    {/*<LazyLoad offsetVertical={200}>*/}
                    {/*    <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                    {/*</LazyLoad>*/}
                    <ConteudoResumo
                        totalCertas={this.state.totalCertas}
                        totalRespostas={this.state.totalRespostas}
                        RepetirQuestionario={this.RepetirQuestionario}
                        //redirectLinkResolucao={this.state.redirectLinkResolucao}
                        loading={this.state.loading}
                    />
                    <Controlos
                        backUrl={`/curso/${this.props.match.params.curso
                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                            .toLowerCase()}/curso-modulos`}
                        sameUrl={true}
                        temRating
                        temAnotacoes
                        temMarcador
                        temMensagens
                        conclusao
                    />
                </div>
            </div>
        );
    }
}

export default Resumo;
