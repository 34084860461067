import Tabela from "./Tabela";
import React, {useContext, useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import { DataContext } from '../Contexts/DataProvider';

export default function AlunosPropEvasao() {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    const {listaFaltasAlunos} = useContext(DataContext);
    const columnsAlunosPropensosEvasao = [
        {
            title: 'Alunos',
            dataIndex: 'nomeAluno',
            key: 'nomeAluno',
            width:"25%",  
        },
        {
            title: 'Quant. de Faltas',
            dataIndex: 'numeroFaltas',
            key: 'numeroFaltas',
            width:"25%",  
        },
        {
            title: 'Escola',
            dataIndex: 'escola',
            key: 'escola',
            width:"25%",  
        },
        {
            title: 'Ultima Presença',
            dataIndex: 'dataUltimaPresenca',
            key: 'dataUltimaPresenca',
            width:"20%",  
        },
    ];


    // console.log(listaAlunos.faltasPorAluno)
    // setListaAlunos2(listaAlunos.faltasPorAluno)
    // console.log(listaFaltasAlunos)
    
    return(
        listaFaltasAlunos && <Tabela data={listaFaltasAlunos} columns={columnsAlunosPropensosEvasao} titulo="Alunos propensos de evasão"></Tabela>
    );
}