import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Input, notification, Icon, Form } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const InputSender = ({ form }) => {
    const [email, setEmail] = useState("");
    const [sending, setSending] = useState(false);

    const send = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setSending(true)

                var item = new FormData();
                item.append("email", email);

                axios({
                    method: "post",
                    url: "/api/pagina-entidade/request-demo",
                    data: item
                })
                    .then(() => {
                        form.resetFields();
                        openNotificationWithIcon("success", "Sucesso", "Solicitação enviada!");
                        setEmail("");
                        setSending(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setSending(false);
                    });
            }
        })

        //var item = new FormData();
        //item.append("email", email);
        //axios({
        //    method: "post",
        //    url: "/api/pagina-entidade/request-demo",
        //    data: item
        //})
        //    .then(response => {
        //        form.resetFields();
        //        openNotificationWithIcon("success", "Sucesso", "Solicitação enviada!");
        //        setEmail("");
        //        setSending(response.data);
        //    })
        //    .catch(error => {
        //        openNotificationWithIcon("error", "Erro", "Não foi possível enviar solicitação!");
        //    });
    }

    const { getFieldDecorator } = form;

    return (
        <Form>
            <Form.Item>
                {getFieldDecorator("email", {
                    rules: [
                        {
                            required: true,
                            message: "Campo obrigatório"
                        }
                    ],
                    initialValue: email
                })(
                    <Input
                        placeholder="Digite seu e-mail ..."
                        className="request-demo-input"
                        onChange={e => setEmail(e.target.value)}
                        suffix={sending ? <Icon type="loading" /> : <Icon type="arrow-right" onClick={send} />}
                    />
                )}
            </Form.Item>
        </Form>
    );
}

const FormInputSender = Form.create({ name: "input-sender" })(InputSender);

export default withRouter(FormInputSender);
