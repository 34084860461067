import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Carousel } from 'antd';
import Noticia from "./Noticia";

export default function Detalhe({ match }) {
    const [data, setData] = useState([]);
    const [noticias, setNoticias] = useState([]);

    const formatDate = data => {
        if (data === undefined) return;
        const date = data.toString();
        return date.slice(0, -3);
    };

    const carregarDetalhe = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-informacao-detalhe",
            params: {
                code: match.params.detalhe
            }
        })
            .then(response => {
                setData(response.data);
            })
            .catch(() => {});
    };

    const listarNoticas = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-todas-informacoes-detalhe",
            params: {
                entidadeUrl: match.params.entidade,
                code: match.params.detalhe
            }
        })
            .then(response => {
                setNoticias(response.data);
            })
            .catch(() => {});
    };

    useEffect(() => {
        carregarDetalhe();
        listarNoticas();
    }, [match.params.detalhe]);

    function showDateAndTime(dateTime, hour) {
        const date = new Date(dateTime);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        let mes = "";
        switch (month) {
            case "01":
                mes = "Janeiro";
                break;
            case "02":
                mes = "Fevereiro";
                break;
            case "03":
                mes = "Março";
                break;
            case "04":
                mes = "Abril";
                break;
            case "05":
                mes = "Maio";
                break;
            case "06":
                mes = "Junho";
                break;
            case "07":
                mes = "Julho";
                break;
            case "08":
                mes = "Agosto";
                break;
            case "09":
                mes = "Setembro";
                break;
            case "10":
                mes = "Outubro";
                break;
            case "11":
                mes = "Novembro";
                break;
            case "12":
                mes = "Dezembro";
                break;
        }

        const day = ("0" + date.getDate()).slice(-2);
        //if(dateTime === null && hour === null ) return "";
        if (dateTime === null)
            return `${hour}`;
        else if (hour === null)
            return `${day} de ${mes} de ${year}`;
        else
            return `${day} de ${mes} de ${year}, ${hour}`;
    }

    return (
        <>
            <div className="panel-body bloco-noticia-detalhe">
                <div className="left-panel" style={noticias.length === 0 ? { width: "100%" } : {}}>
                    <h2 className="panel-h2">{data.titulo}</h2>
                    <div className="corpo-noticia">
                        {(data.data || data.hora) &&
                            <p className="texto texto-info info-publicacao">
                                {showDateAndTime(data.data, data.hora)}
                            </p>}
                        {data.capa &&
                            <img className="panel-img" src={data.capa} alt="Image" />}
                        <p
                            className="texto texto-noticia"
                            dangerouslySetInnerHTML={{ __html: data.texto }}
                            style={{ fontSize: 22 }}
                        >
                        </p>
                        {data.link &&
                            <div className="texto texto-info link">
                                <a href={data.link} target="_blank" rel="noopener noreferrer" className="link">
                                    <i className="fa fa-link"></i> Link
                                </a>
                            </div>}
                    </div>
                </div>
                {noticias.length > 0 &&
                    <div style={{ padding: 7 }} className="right-panel">
                        <div className="bloco-noticias">
                            <h2 style={{ textAlign: "center", color: "#3A3355", fontWeight: "bold", opacity: "0.7" }}>Outras notícias</h2>
                            <div className="noticias">
                                <Carousel autoplay>
                                    {noticias.map(noticia => (
                                        <Noticia noticia={noticia} />
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </div>}
            </div>
        </>
    );
}
