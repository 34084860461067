import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, notification, Modal } from "antd";
import axios from "axios";
import moment from "moment";

import ExcelTabelaFrequencia from "../../_Aux/ExcelTabelaFrequencia";
import CardsFrequencia from "../../_Aux/CardsFrequencia";
import ModalViewer from "../../../../Componentes/Modals/ModalViewer";
import ModalHorariosFrequencias from "../../_Aux/ModalHorariosFrequencias";
import ModalLoading from "../../../../Componentes/Modals/ModalLoading";

import ModalGerarDocumento from "../../../../Componentes/Modals/GerarDocumento";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ListaFrequencia extends Component {
    state = {
        disciplinaId: localStorage.getItem("vista") === "TURMA" ? null : localStorage.getItem("codigo_curso"),
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //LOADING
        visibleLoading: false,
        //VIEWER
        visibleViewer: false,
        docs: [],
        dates: []
    };

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                disciplinaId: localStorage.getItem("vista") === "TURMA" ? null : localStorage.getItem("codigo_curso")
            });
        }
    }

    exportar = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/excel/faltas-horas",
                    params: {
                        cursoId: this.state.disciplinaId
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarPdf = (disciplina, option, startDate, endDate, dataEmissao) => {
        this.getMeses();
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pdf/frequencia-pdf",
                    params: {
                        cursoId: disciplina,
                        periodoId: this.state.periodoId,
                        start: moment(startDate).format("YYYY/MM/DD"),
                        end: moment(endDate).format("YYYY/MM/DD"),
                        tipo: option,
                        dataEmissao: moment(dataEmissao).format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data }]
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    };

    getMeses = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/get-meses-frequencias",
            params: {
                id: this.state.disciplinaId == null ? localStorage.getItem("codigo_curso") : this.state.disciplinaId,
                periodoId: this.state.periodoId
            }
        })
            .then(response => {
                this.setState({
                    dates: response.data
                });
            })
            .catch(() => {});
    };

    toggleModal = () => {
        this.getMeses();
        this.setState(prevState => ({
            isModalVisible: !prevState.isModalVisible
        }));
    };

    render() {
        const {
            disciplinaId,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //LOADING
            visibleLoading,
            //VIEWER
            visibleViewer,
            docs,
            dates
        } = this.state;

        return (
            <>
                <div className="container container-body">
                    <div className="bloco-curso-diario-opcoes" style={{ gap: 10 }}>
                        <h3 className="titulo-bloco">Frequência</h3>
                        <div className="bloco-opcoes-esquerda">
                            <Link className={`botao-icon-detalhe botao-maior`} to="#" title="Descarregar" onClick={this.toggleModal}>
                                <Icon type="eye" />
                            </Link>
                            <Link
                                className={`botao-icon-download botao-maior ${
                                    localStorage.getItem("vista") === "TURMA" && !disciplinaId ? "botao-disabled" : ""
                                }`}
                                to="#"
                                title="Descarregar"
                                disabled={localStorage.getItem("vista") === "TURMA" && !disciplinaId}
                                style={{ margin: 0 }}
                                onClick={this.exportar}
                            >
                                <Icon type="download" />
                            </Link>
                            <Link
                                to={`/gerir-multisseriado/${this.props.match.params.turma}/${
                                    localStorage.getItem("vista") === "TURMA" ? "horario" : "horario-disciplina"
                                }`}
                                className="botao-secundario botao-secundario-redondo"
                                title="Voltar"
                                style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <Icon type="arrow-left" />
                            </Link>
                        </div>
                    </div>
                    <div className="curso-diario curso-diario-frequencia-desktop">
                        <ExcelTabelaFrequencia setDisciplinaId={disciplinaId => this.setState({ disciplinaId })} />
                    </div>
                    <div className="curso-diario curso-diario-frequencia-mobile">
                        <CardsFrequencia setDisciplinaId={disciplinaId => this.setState({ disciplinaId })} />
                    </div>
                </div>
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
                <ModalLoading visible={visibleLoading} text="A gerar documento..." />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => this.setState({ visibleViewer: false })} />
                <ModalHorariosFrequencias
                    datas={dates}
                    disciplina={this.state.disciplinaId}
                    visible={this.state.isModalVisible}
                    onClose={this.toggleModal}
                    onSelect={(option3, option1, option2, dataEmissao) => {
                        this.setState({
                            startDate: option2.start_date,
                            endDate: option2.end_date
                        });
                        this.exportarPdf(option3, option1, option2.start_date, option2.end_date, dataEmissao);
                    }}
                />
            </>
        );
    }
}

export default ListaFrequencia;
