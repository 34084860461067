import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Switch, Table, Icon, Modal, notification } from "antd";
import DrawerArtigo from "./DrawerArtigo";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Artigos extends Component {
    state = {
        informacoes: [],
        informacoesMobile: [],
        ativo: true,
        infoId: "",
        ordem: 1,
        //LOADING
        loading_table: false,
        //DRAWER
        visible: false
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA CATEGORIA NA TABELA
    montarMenu = (infoId) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => { event.stopPropagation(); this.ativar(infoId) }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => { event.stopPropagation(); this.ativar(infoId) }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => { event.stopPropagation(); this.excluir(infoId) }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    adicionar = () => {
        this.setState({
            infoId: "",
            visible: true
        })
    }

    editar = infoId => {
        this.setState({
            infoId,
            visible: true
        })
    }

    excluir = infoId => {
        confirm({
            title: "Pretende excluir este artigo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/empresa/excluir-pagina-artigo",
                    params: {
                        infoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Artigo excluido!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir este registo!");
                    });
            }
        });
    };

    ativar = (infoId) => {
        confirm({
            title: this.state.ativo ? "Pretende inativar este artigo?" : "Pretende ativar este artigo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/empresa/ativar-pagina-artigo",
                    params: {
                        infoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", this.state.ativo ? "Artigo inativado!" : "Artigo ativado!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            this.state.ativo ? "Não foi possível inativar o artigo!" : "Não foi possível ativar o artigo!"
                        );
                    });
            }
        });
    };

    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/empresa/listar-pagina-artigos",
                    params: {
                        paginaId: this.props.paginaId,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var informacoes = [];
                        var informacoesMobile = [];

                        response.data.map((info, index) => {
                            informacoes.push({
                                key: index,
                                id: info.id,
                                info: info.titulo,
                                ordem: info.ordem,
                                opcoes: this.montarMenu(info.id)
                            });
                            informacoesMobile.push({
                                key: index,
                                id: info.id,
                                info: <div className="bloco-pagina">
                                    <div className="info-pagina">
                                        <span className="titulo-pagina">{info.titulo}</span>
                                        <span className="ordem-pagina"><strong>Ordem:</strong> {info.ordem}</span>
                                    </div>
                                </div>,
                                opcoes: this.montarMenu(info.id)
                            });
                        });

                        this.setState({
                            informacoes,
                            informacoesMobile,
                            ordem: (response.data.length + 1),
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    atualizarRegisto = () => {
        this.setState({
            visible: false
        })
        this.listar();
    }

    onRowClick = (infoId) => {
        this.editar(infoId);
    }

    render() {
        const {
            informacoes,
            informacoesMobile,
            infoId,
            ordem,
            //LOADING
            loading_table,
            //DRAWER
            visible
        } = this.state;

        const columns = [
            {
                title: "Artigo",
                dataIndex: "info"
            },
            {
                title: "Ordem",
                dataIndex: "ordem",
                width: 100
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const columnsMobile = [
            {
                title: "Artigo",
                dataIndex: "info"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        return (
            <div className="bloco-minha-conta">
                <div className="bloco-gestor-paginas">
                    <h3 className="titulo-separador" style={{ marginBottom: 20 }}>{this.props.titulo}</h3>
                    <div className="controlos controlos-pagina-informacoes">
                        <button className="botao-principal botao-principal-redondo" title="Adicionar" onClick={this.adicionar}>
                            +
                        </button>
                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <Switch
                                checkedChildren="Ativos"
                                unCheckedChildren="Inativos"
                                defaultChecked
                                onChange={this.handleChangeSwitch}
                            />
                            <button
                                className="botao-secundario botao-secundario-redondo"
                                title="Voltar"
                                style={{ marginLeft: 10 }} onClick={this.props.voltar}
                            >
                                <Icon type="arrow-left" />
                            </button>
                        </div>
                    </div>
                    <div className="bloco-tabela">
                        <Table
                            id="tabela_categorias"
                            className="tabela-paginas-desktop"
                            loading={{
                                spinning: loading_table,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                            columns={columns}
                            dataSource={informacoes}
                            pagination={false}
                            rowClassName="clickeble-row"
                            onRow={(record, index) => {
                                return {
                                    onClick: () => this.onRowClick(record.id)
                                };
                            }}
                            locale={{ emptyText: "Não existem dados!" }}
                        />
                        <Table
                            id="tabela_categorias"
                            className="tabela-paginas-mobile"
                            loading={{
                                spinning: loading_table,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                            columns={columnsMobile}
                            dataSource={informacoesMobile}
                            pagination={false}
                            rowClassName="clickeble-row"
                            onRow={(record, index) => {
                                return {
                                    onClick: () => this.onRowClick(record.id)
                                };
                            }}
                            locale={{ emptyText: "Não existem dados!" }}
                        />
                    </div>
                </div>
                <DrawerArtigo
                    ativo={this.state.ativo}
                    infoId={infoId}
                    ordem={ordem}
                    paginaId={this.props.paginaId}
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    atualizarRegisto={this.atualizarRegisto}
                />
            </div>
        );
    }
}

export default Artigos;
