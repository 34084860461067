import React, { Component } from "react";
import { matchPath, Redirect } from "react-router-dom";
import { notification } from "antd";
import ConteudoResumo from "./ConteudoResumo";
import Controlos from "../_Aux/Controlos";
import screenfull from "screenfull";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import LazyLoad from "react-lazy-load";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Resumo extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        aula: "",
        aulaIndex: 0,
        fullscreen: false,
        total: 0,
        corretas: 0,
        updateModulos: false,
        //REDIRECT
        repetir: false,
        redirect: false,
        redirectLink: "",
        redirectLinkResolucao: "",
        sameUrl: true
    };

    componentDidMount() {
        axios.defaults.timeout = 60 * 10 * 1000;

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: localStorage.getItem("codigo_aula"),
            redirectLink: `/curso/${this.props.match.params.curso}/"${this.props.match.params.aula}/player-questionario?repetir=true`,
            redirectLinkResolucao: `/curso/${this.props.match.params.curso}/${this.props.match.params.aula}/player-questionario-resolucao`,
        });
    }

    addMarcador = () => {
        this.setState({
            updateModulos: true
        });
    };

    stopUpdateModulos = () => {
        this.setState({
            updateModulos: false
        });
    };

    DevolveInfo = (modulo, moduloIndex, aula, aulaIndex) => {
        this.setState({
            modulo,
            moduloIndex,
            aula,
            aulaIndex
        });
    };

    RepetirQuestionario = event => {
        event.preventDefault();

        axios({
            method: "put",
            url: "/api/questionario/repetir-questionario",
            params: {
                aulaid: this.state.aulaid
            }
        })
            .then(response => {
                this.setState({
                    repetir: true
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível repetir!");
            });
    };

    onClickFullscreen = () => {
        this.setState({ fullscreen: !this.state.fullscreen });
        screenfull.toggle();
    };

    render() {
        const {
            curso,
            //REDIRECT
            repetir,
            redirect,
            redirectLink,
            redirectLinkResolucao,
            sameUrl
        } = this.state;

        if (repetir || redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="questionario-resumo">
                <div className="player-wrapper">
                    {/*<LazyLoad offsetVertical={200}>*/}
                    {/*    <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                    {/*</LazyLoad>*/}
                    <ConteudoResumo
                        RepetirQuestionario={this.RepetirQuestionario}
                        redirectLinkResolucao={redirectLinkResolucao}
                    />
                    <Controlos
                        backUrl={`/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                        sameUrl={sameUrl}
                        temRating
                        temAnotacoes
                        temMarcador
                        temMensagens
                        conclusao
                    />
                </div>
            </div>
        );
    }
}

export default Resumo;
