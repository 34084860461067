import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Drawer,
    Table,
    Form,
    Icon,
    Input,
    Switch,
    Select,
    Spin,
    Dropdown,
    Menu,
    Checkbox,
    notification,
    Modal,
    DatePicker,
    Tooltip,
    Upload,
    Alert,
    InputNumber,
    Collapse
} from "antd";
import Header from "../../../User/Header";
import DrawerAgrupar from "../_Aux/DrawerAgrupar";
import noimage from "../../../images/noimage.png";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Agrupar = (props) => {
    const [turma, setTurma] = useState({});
    const [turmas, setTurmas] = useState([]);
    const [turmasSelecionadas, setTurmasSelecionadas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visibleAgrupar, setVisibleAgrupar] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const onSelectChange = turmasSelecionadas => {
        setTurmasSelecionadas(turmasSelecionadas);
    };

    const carregarTurmaInfo = () => {
        axios({
            method: "get",
            url: "/api/gestao-turmas/detalhe-code",
            params: {
                code: props.match.params.code
            }
        })
            .then(response => {
                setTurma(response.data)
            })
            .catch(error => {

            });
    }

    const listarTurmas = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/gestao-turmas/listar-turmas-agrupar",
            params: {
                code: props.match.params.code
            }
        })
            .then(response => {
                var turmas = [];

                response.data.map(curso => {
                    turmas.push({
                        key: curso.id,
                        id: curso.id,
                        edicao: curso.edicao,
                        curso: (
                            <>
                                <div className="imagem-curso">
                                    <img src={curso.imagem ? curso.imagem : noimage} />
                                </div>
                                <div className="nome-colaborador">
                                    <span className="titulo-curso">
                                        {curso.codigo} - {curso.nome}
                                    </span>
                                    {curso.nome_organismo ?
                                        <span className="nome-organismo">
                                            {curso.nome_organismo}
                                        </span>
                                        : null}
                                </div>
                            </>
                        ),
                        categoria: curso.categoria,
                        codigo: curso.codigo,
                        nome: curso.nome,
                        periodo: <div className="calendario-info">
                            {curso.dt_inicio && curso.dt_fim &&
                                <span className="calendario-info__titulo">{moment(curso.dt_inicio).format("DD/MM/YYYY")} - {moment(curso.dt_fim).format("DD/MM/YYYY")}</span>}
                            <span className="calendario-info__tipo">{curso.periodo_letivo}</span>
                        </div>,
                        dt_inicio: curso.dt_inicio,
                        dt_fim: curso.dt_fim,
                        dt_disponivel: curso.dt_disponivel,
                        coordenadores: curso.coordenadores,
                        grupo: curso.grupo
                    });
                });

                setTurmas(turmas);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const abrirDrawerAgrupar = () => {
        if (turmasSelecionadas.length === 0) {
            openNotificationWithIcon("error", "Erro", "Não foram selecionadas turmas");
            return false;
        }

        setVisibleAgrupar(true);
    }

    const guardarAgrupar = () => {
        setVisibleAgrupar(false);
        setRedirect(true)
    }

    useEffect(() => {
        carregarTurmaInfo();
        listarTurmas();
    }, []);

    const columns = [
        {
            title: "Edição",
            dataIndex: "edicao",
            className: "td-50"
        },
        {
            title: "Turma",
            dataIndex: "curso",
            className: "td-curso td-300"
        },
        {
            title: "Categoria",
            dataIndex: "categoria",
            className: "td-155"
        },
        {
            title: "Cal. Escolar",
            dataIndex: "periodo",
            className: "td-300"
        },
        {
            title: "Coordenadores",
            dataIndex: "coordenadores",
            className: "td-300"
        },
        {
            title: "",
            dataIndex: "opcoes",
            className: "td-50"
        }
    ];

    const rowSelection = {
        turmasSelecionadas,
        onChange: onSelectChange
    };

    if (redirect)
        return <Redirect to="/gestao-turmas" />;

    return (
        <div>
            <Header titulo="Turmas" />
            <div className="container container-body">
                <div className="bloco-cursos">
                    <div className="bloco-tabela">
                        <div className="bloco-flex" style={{ marginBottom: 20 }}>
                            <div>
                                <h3 className="tabela_titulo">
                                    Agrupar turmas com {turma?.nome}
                                </h3>
                                <span>Seleciona abaixo quais as turmas que se pretendem agrucar.</span>
                            </div>
                            <Link
                                to="/gestao-turmas"
                                className="botao-principal"
                            >
                                Voltar
                            </Link>
                        </div>
                        <Table
                            id="cursos-disciplinas"
                            className="curso-disciplinas-desktop"
                            loading={{
                                spinning: loading,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                            columns={columns}
                            dataSource={turmas}
                            pagination={false}
                            locale={{ emptyText: "Não existem dados!" }}
                            rowSelection={rowSelection}
                        />
                    </div>
                </div>
            </div>
            <div className="opcoes-meus-cursos">
                {turmasSelecionadas.length > 0 &&
                    <>
                        <Link to="#" className="btn-confirmar-op" title="Confirmar" onClick={abrirDrawerAgrupar}>
                            <Icon type="check" />
                        </Link>
                        <Link to="#" className="btn-cancelar-op" title="Cancelar" onClick={() => setTurmasSelecionadas([])}>
                            <Icon type="close" />
                        </Link>
                    </>
                }
            </div>
            <DrawerAgrupar
                onClose={() => setVisibleAgrupar(false)}
                visible={visibleAgrupar}
                ids={[...turmasSelecionadas, turma.id]}
                guardarAgrupar={guardarAgrupar}
            />
        </div>
    );
}
const FormAgrupar = Form.create({ name: "agrupar" })(Agrupar);

export default FormAgrupar;
