import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Menu, notification, Modal, Select, Dropdown, Icon, DatePicker, TimePicker, Row, Col, Tooltip, Carousel, Checkbox, Drawer } from "antd";
import Sidebar from "./Menu";
import TabelaProximas from "./TabelaProximas";
import TabelaProximasMobile from "./TabelaProximasMobile";
import TabelaAnteriores from "./TabelaAnteriores";
import TabelaAnterioresMobile from "./TabelaAnterioresMobile";
import noimage from "../../images/noimage.png";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import DrawerForm from "./_Aux/FormVideoConf";
import DrawerFormUploadVideo from "./_Aux/FormUploadVideo";
import DrawerFormUploadFicheiro from "./_Aux/FormUploadFicheiro";
import { GlobalContext } from "../../GlobalState";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";

import ModalGerarDocumento from "../../Componentes/Modals/GerarDocumento";

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514"

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
}

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        videoConfId: "",
        meetingName: "",
        videoconferencias: [],
        videoconferenciasMobile: [],
        total_recebidos: 0,
        proximas_ativo_on: "ativo",
        total_enviados: 0,
        anteriores_ativo_on: "",
        nr_proximas_videoconferencias: 0,
        nr_anteriores_videoconferencias: 0,
        //TABELA
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        loading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER
        visible: false,
        alterarVideoconferencia: null,
        //DRAWER UPLOAD
        visibleUpload: false,
        //DRAWER IMPORTAR
        visibleImportar: false,
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        ficheiroNomeExportar: "",
        //HORA SAIDA MODAL
        visibleDataSaida: false,
        dataHoraEntrada: null,
        dataSaida: moment(),
        horaSaida: moment(),
        //BROWSERS INFO
        visibleBrowsers: false,
        meetID: 0,
        indexCarousel: 0,
        tituloCarousel: "Informação sobre como deve sair da Stepmeet",
        //MODAL PREPARAR MEET
        visibleIniciarMeet: false,
        prepararMeet: false,
        linkIniciar: "",
        meetExterno: false,
        meetExternaConvite: "",
        //MODAL LISTA PARTICIPANTES
        visibleParticipantes: false,
        carregarLista: false,
        participantes: [],
        //MODAL MEET EXTERNA
        visibleMeetExterna: false,
        //FILTROS
        pesquisa: "",
        excluirTodos: false,
        //DRAWER PROXIMAS
        visibleProximas: false,
        proximasAgendada: "",
        proximasParticipantes: "",
        //DRAWER ANTERIORES
        visibleAnteriores: false,
        anterioresRealizada: "",
        anterioresParticipantes: "",
        //VALIDAR INFORMACOES
        mensagemId: 1,
        naoMostrarMensagem: false,
        //CURSO
        fechado: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        //this.validarUtilizadorInformacoes();
        this.carregarEstadoCurso(localStorage.getItem("codigo_curso"));
        if (localStorage.getItem("menu_mensagens"))
            if (localStorage.getItem("menu_mensagens") === "proximas") {
                this.setState(
                    {
                        cursoid: localStorage.getItem("codigo_curso"),
                        proximas_ativo_on: "ativo",
                        anteriores_ativo_on: ""
                    },
                    () => {
                        this.totalProximas();
                        this.listarProximas();
                    }
                );
            } else {
                this.setState(
                    {
                        cursoid: localStorage.getItem("codigo_curso"),
                        proximas_ativo_on: "",
                        anteriores_ativo_on: "ativo"
                    },
                    () => {
                        this.totalAnteriores();
                        this.listarAnteriores();
                    }
                );
            }
        else {
            this.setState(
                {
                    cursoid: localStorage.getItem("codigo_curso")
                },
                () => {
                    this.totalProximas();
                    this.listarProximas();
                }
            );
        }
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChangeSelect = value => {
        const pagination = { ...this.state.pagination };
        pagination.current = 1;
        pagination.pageSize = value;

        this.setState(
            {
                pagination
            },
            () => {
                if (this.state.anteriores_ativo_on === "ativo") {
                    this.totalAnteriores();
                    this.listarAnteriores();
                } else {
                    this.totalProximas();
                    this.listarProximas();
                }
            }
        );
    };

    handleTableChange = (pagination, filters, sorter) => {
        const _pagination = { ...this.state.pagination };
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                if (this.state.anteriores_ativo_on === "ativo") {
                    this.totalAnteriores();
                    this.listarAnteriores();
                } else {
                    this.totalProximas();
                    this.listarProximas();
                }
            }
        );
    };

    handlerChangeExcluirTodos = event => {
        this.setState({
            [event.target.name]: event.target.checked
        }, () => {
            this.totalAnteriores();
            this.listarAnteriores();
        });
    };

    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    this.totalAnteriores();
                    this.listarAnteriores();
                }
            );
        }
    };

    //LIMPA A CAIXA DE PESQUISA E LISTA NOVAMENTE O TOTAL DE TICKETS
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                this.totalAnteriores(false);
                this.listarAnteriores(this.state.current);
            }
        );
    };

    filtro_proximas = () => {
        localStorage.setItem("menu_mensagens", "proximas");

        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                pesquisa: "",
                anteriores_ativo_on: "",
                proximas_ativo_on: "ativo",
                loading: true
            },
            () => {
                this.totalProximas();
                this.listarProximas();
            }
        );
    };

    filtro_anteriores = () => {
        localStorage.setItem("menu_mensagens", "anteriores");

        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                pesquisa: "",
                anteriores_ativo_on: "ativo",
                proximas_ativo_on: "",
                loading: true
            },
            () => {
                this.totalAnteriores();
                this.listarAnteriores();
            }
        );
    };

    totalProximas = () => {
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/total-proximas",
            params: {
                cursoId: this.state.cursoid
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(() => { });
    };

    carregarEstadoCurso = (cursoId) => {
        axios({
            method: "get",
            url: "/api/curso/carregar-estado-curso",
            params: {
                cursoId
            }
        })
            .then(response => {
                this.setState({
                    fechado: response.data
                })
            })
            .catch(() => { });
    }

    listarProximas = () => {
        this.setState({ loading: true });
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/listar-proximas",
            params: {
                cursoId: this.state.cursoid
            }
        })
            .then(response => {
                var videoconferencias = [];
                var videoconferenciasMobile = [];

                response.data.map((videoConf, index) => {
                    videoconferencias.push({
                        key: index,
                        id: videoConf.id,
                        inicio: (
                            <div className="info-data">
                                <span className="data">{moment(videoConf.meetingDate).format("ddd, DD MMM")}</span>
                                <p className="hora">{videoConf.hora}</p>
                            </div>
                        ),
                        datahora: moment(videoConf.meetingDate).format("MM/DD/YYYY") + " " + videoConf.hora,
                        assunto: videoConf.assunto,
                        agendada: (<div className="info-participante">
                            <div className="bloco-img">
                                <img src={videoConf.criador.foto ? videoConf.criador.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {videoConf.criador.nome_completo.length > 1 ? `${videoConf.criador.nome_completo.split(" ")[0]} ${videoConf.criador.nome_completo.split(" ")[videoConf.criador.nome_completo.split(" ").length - 1]}` : videoConf.criador.nome_completo}
                                </span>
                                <p className="numero">{videoConf.criador.numero}</p>
                            </div>
                        </div>),
                        participantes: this.montarParticipantes(videoConf),
                        controlos: !this.state.fechado ? (
                            <div className="controlos">
                                {videoConf.meetExterna ?
                                    <button className="botao-icon-convite" onClick={() => this.iniciarExterna(videoConf.id, videoConf.meetExternaConvite)} title="Iniciar">
                                        <Icon type="play-circle" />
                                    </button>
                                    :
                                    <button className="botao-icon-iniciar" onClick={() => this.iniciar(videoConf.id, videoConf.gravarSessao, videoConf.connected, videoConf.registoCriado)} title="Iniciar">
                                        <Icon type="play-circle" />
                                    </button>}
                                <Dropdown
                                    overlay={this.montarMenuProximas(videoConf.id, videoConf.realizada, videoConf.meetingName, videoConf.meetExterna)}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="botao-icon-configs" style={{ marginLeft: 10 }}>
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            </div>
                        ) : null
                    });

                    const agendada = (<div className="info-participante">
                        <div className="container-img">
                            <img src={videoConf.criador.foto ? videoConf.criador.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">
                                {videoConf.criador.nome_completo.length > 1 ? `${videoConf.criador.nome_completo.split(" ")[0]} ${videoConf.criador.nome_completo.split(" ")[videoConf.criador.nome_completo.split(" ").length - 1]}` : videoConf.criador.nome_completo}
                            </span>
                            <p className="numero">{videoConf.criador.numero}</p>
                        </div>
                    </div>);

                    const participantes = this.montarParticipantes(videoConf);

                    videoconferenciasMobile.push({
                        key: index,
                        id: videoConf.id,
                        assunto: (
                            <div className="info-videoconferencias">
                                <span className="assunto">{videoConf.assunto}</span>
                                <p className="data-hora">
                                    {moment(videoConf.meetingDate).format("ddd, DD MMM")} {videoConf.hora}
                                </p>
                            </div>
                        ),
                        controlos: !this.state.fechado ? (
                            <div className="controlos controlos-vertical">
                                <div>
                                    {videoConf.meetExterna ?
                                        <button className="botao-icon-convite" onClick={() => this.iniciarExterna(videoConf.id, videoConf.meetExternaConvite)} title="Iniciar">
                                            <Icon type="play-circle" />
                                        </button>
                                        :
                                        <button className="botao-icon-iniciar" onClick={() => this.iniciar(videoConf.id, videoConf.gravarSessao, videoConf.connected, videoConf.registoCriado)} title="Iniciar">
                                            <Icon type="play-circle" />
                                        </button>}
                                    <Dropdown
                                        overlay={this.montarMenuProximasMobile(videoConf.id, videoConf.realizada, agendada, participantes, videoConf.meetingName, videoConf.meetExterna)}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                </div>
                            </div>
                        ) : null
                    });
                });

                this.setState({
                    loading: false,
                    videoconferencias,
                    videoconferenciasMobile
                });
            })
            .catch();
    };

    montarParticipantes = (videoConf) => {
        if (videoConf.opcao === 1)
            return "Todos os professores e alunos";
        else if (videoConf.opcao === 2)
            return "Todos os professores";
        else if (videoConf.opcao === 3)
            return "Todos os alunos";
        else
            return this.montarTipoPartilha(videoConf);
    }

    montarTipoPartilha = (videoConf) => {
        if (videoConf.todos && videoConf.listaParticipantes > 0 || !videoConf.todos && videoConf.listaParticipantes > 1) {
            return (<div className="info-participantes">
                <Link to="#" className="link-model-participantes" onClick={() => this.listarParticipantes(videoConf.id)}>
                    <i className="fas fa-users icon-tipo-partilha partilhado"></i>
                </Link>
            </div>)
        }
        else {
            return (<div className="info-participante">
                <div className="container-img">
                    <img src={videoConf.participante?.foto ? videoConf.participante.foto : noimage} />
                </div>
                <div className="info">
                    <span className="nome">
                        {videoConf.participante?.nome_completo?.length > 1 ? `${videoConf.participante.nome_completo.split(" ")[0]} ${videoConf.participante.nome_completo.split(" ")[videoConf.participante.nome_completo.split(" ").length - 1]}` : videoConf.participante.nome_completo}
                    </span>
                    <p className="numero">{videoConf.participante?.numero}</p>
                </div>
            </div>)
        }
    };

    montarMenuProximas = (videoConfId, realizada, meetingName, meetExterna) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarDetalhe(videoConfId)}>
                        Editar
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(videoConfId)}>
                        Excluir
                        </Link>
                </Menu.Item>
                {realizada || meetExterna ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.marcarRealizada(videoConfId)}>
                            Marcar como realizada
                            </Link>
                    </Menu.Item>
                    :
                    <Menu.Item>
                        <Link to="#" onClick={() => this.marcarNaoRealizada(videoConfId)}>
                            Marcar como não realizada
                        </Link>
                    </Menu.Item>
                }
                {!this.state.fechado ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.setState({ videoConfId, visibleUpload: true, meetingName })}>
                            Vídeos
                        </Link>
                    </Menu.Item> : null}
                {!this.state.fechado ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.setState({ videoConfId, visibleImportar: true, meetingName })}>
                            Anexos
                        </Link>
                    </Menu.Item> : null}
            </Menu>
        );
    };

    montarMenuProximasMobile = (videoConfId, realizada, agendada, participantes, meetingName, meetExterna) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleProximas: true, proximasAgendada: agendada, proximasParticipantes: participantes })}>
                        Detalhe
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarDetalhe(videoConfId)}>
                        Editar
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(videoConfId)}>
                        Excluir
                        </Link>
                </Menu.Item>
                {realizada || meetExterna ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.marcarRealizada(videoConfId)}>
                            Marcar como realizada
                            </Link>
                    </Menu.Item>
                    :
                    <Menu.Item>
                        <Link to="#" onClick={() => this.marcarNaoRealizada(videoConfId)}>
                            Marcar como não realizada
                        </Link>
                    </Menu.Item>
                }
                {!this.state.fechado ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.setState({ videoConfId, visibleUpload: true, meetingName })}>
                            Vídeos
                        </Link>
                    </Menu.Item> : null}
                {!this.state.fechado ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.setState({ videoConfId, visibleImportar: true, meetingName })}>
                            Anexos
                        </Link>
                    </Menu.Item> : null}
            </Menu>
        );
    };

    marcarRealizada = videoConfId => {
        confirm({
            title: "Pretende marcar como realizada esta Stepmeet?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gerir-videoconferencia/marcar-realizada",
                    params: {
                        videoConfId
                    }
                })
                    .then(response => {
                        if (response.data) {
                            this.setState({
                                visibleDataSaida: true,
                                dataHoraEntrada: `${moment(response.data.dataInicio).format("DD/MM/YYYY")} ${moment(response.data.dataHora, "HH:mm").add({ hours: moment(response.data.duracao, "HH:mm").hour(), minutes: moment(response.data.duracao, "HH:mm").minutes() }).format("HH:mm")}`,
                                videoConfId
                            })
                        }
                        else {
                            openNotificationWithIcon("success", "Sucesso", "Stepmeet marcada como realizada!")
                            this.totalProximas();
                            this.listarProximas(this.state.current);
                        }
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível marcar a Stepmeet como realizada!")
                    });
            }
        });
    }

    marcarNaoRealizada = videoConfId => {
        confirm({
            title: "Pretende marcar como não realizada esta Stepmeet?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gerir-videoconferencia/marcar-nao-realizada",
                    params: {
                        videoConfId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Stepmeet marcada como não realizada!")
                        this.totalProximas();
                        this.listarProximas(this.state.current);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível marcar a Stepmeet como não realizada!")
                    });
            }
        });
    }

    totalAnteriores = () => {
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/total-anteriores",
            params: {
                cursoId: this.state.cursoid,
                pesquisa: this.state.pesquisa,
                excluirTodos: this.state.excluirTodos
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(() => { });
    };

    listarAnteriores = () => {
        this.setState({ loading: true });
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/listar-anteriores",
            params: {
                cursoId: this.state.cursoid,
                pesquisa: this.state.pesquisa,
                pageSize: this.state.pagination.pageSize,
                page: this.state.pagination.current,
                excluirTodos: this.state.excluirTodos
            }
        })
            .then(response => {
                var videoconferencias = [];
                var videoconferenciasMobile = [];

                response.data.map((videoConf, index) => {
                    videoconferencias.push({
                        key: index,
                        id: videoConf.id,
                        assunto: videoConf.assunto,
                        inicio: (
                            <div className="info-data">
                                <span className="data">{moment(videoConf.meetingDate).format("ddd, DD MMM")}</span>
                                <p className="hora">{videoConf.hora}</p>
                            </div>
                        ),
                        realizada: videoConf.iniciada ? (<div className="info-participante">
                            <div className="container-img">
                                <img src={videoConf.iniciada.foto ? videoConf.iniciada.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {videoConf.iniciada.nome_completo.split(" ").length > 1 ? `${videoConf.iniciada.nome_completo.split(" ")[0]} ${videoConf.iniciada.nome_completo.split(" ")[videoConf.iniciada.nome_completo.split(" ").length - 1]}` : videoConf.iniciada.nome_completo}
                                </span>
                                <p className="numero">{videoConf.iniciada.numero}</p>
                            </div>
                        </div>) : "---",
                        participantes: this.montarParticipantes(videoConf),
                        controlos:
                            <div className="controlos">
                                <Dropdown
                                    overlay={this.montarMenuRealizadas(videoConf.id, videoConf.realizada, videoConf.meetingName, videoConf.ficheiro)}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            </div>
                    });

                    const realizada = videoConf.iniciada ? (<div className="info-participante">
                        <div className="container-img">
                            <img src={videoConf.iniciada.foto ? videoConf.iniciada.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">
                                {videoConf.iniciada.nome_completo.length > 1 ? `${videoConf.iniciada.nome_completo.split(" ")[0]} ${videoConf.iniciada.nome_completo.split(" ")[videoConf.iniciada.nome_completo.split(" ").length - 1]}` : videoConf.iniciada.nome_completo}
                            </span>
                            <p className="numero">{videoConf.iniciada.numero}</p>
                        </div>
                    </div>) : "---";

                    const participantes = this.montarParticipantes(videoConf);

                    videoconferenciasMobile.push({
                        key: index,
                        id: videoConf.id,
                        assunto: (
                            <div className="info-videoconferencias">
                                <span className="assunto">{videoConf.assunto}</span>
                                <p className="data-hora">
                                    {moment(videoConf.meetingDate).format("ddd, DD MMM")} {videoConf.hora}
                                </p>
                            </div>
                        ),
                        controlos:
                            <div className="controlos controlos-vertical">
                                <div>
                                    <Dropdown
                                        overlay={this.montarMenuRealizadasMobile(videoConf.id, videoConf.realizada, videoConf.meetingName, videoConf.ficheiro, realizada, participantes)}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                </div>
                            </div>
                    });
                });

                this.setState({
                    loading: false,
                    videoconferencias,
                    videoconferenciasMobile
                }, () => {
                        response.data.map((videoConf, index) => {
                            if (videoConf.gravarSessao)
                                this.carregarVideo(videoConf.meetingName, videoConf.id, videoConf.realizada, videoConf.ficheiro)
                        })
                });
            })
            .catch(() => { });
    };

    atualizarListagemVisivel = () => {
        if (localStorage.getItem("menu_mensagens") === "anteriores")
            this.listarAnteriores();
        else
            this.listarProximas();
    }

    carregarVideo = (meetingName, videoConfId, realizada, ficheiro) => {
        axios({
            method: "get",
            url: `https://api.vimeo.com/me/videos`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost,
            params: {
                query: meetingName
            }
        }).then(response => {
            if (response.data.data.length) {
                var videos = [];

                response.data.data.forEach(video => {
                    videos = [...videos, video.uri.split("/").pop()]
                })

                var videoconferencias = this.state.videoconferencias;
                var confIndex = videoconferencias.findIndex(x => x.id === videoConfId)
                videoconferencias[confIndex].controlos = (
                    <div className="controlos">
                        <Dropdown
                            overlay={this.montarMenuRealizadas(videoConfId, realizada, meetingName, ficheiro)}
                            placement="bottomLeft"
                        >
                            <Link to="#" className="botao-icon-configs">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                    </div>
                )
                this.setState({
                    videoconferencias
                }, () => {
                    response.data.data.forEach(video => {
                        this.moverParaPasta(video.uri.split("/").pop());
                        this.carregarDominio(video.uri.split("/").pop());
                    })
                    this.guardarVideoID(videoConfId, JSON.stringify(videos));
                });
            }
        })
            .catch(() => { });
    }

    atualizarRealizadas = (videosID, meetingName, videoConfId) => {
        var videos = [];

        videosID.forEach(video => {
            videos = [...videos, video]
        })

        var videoconferencias = this.state.videoconferencias;
        var confIndex = videoconferencias.findIndex(x => x.id === videoConfId)
        videoconferencias[confIndex].controlos = (
            <div className="controlos">
                <Dropdown
                    overlay={this.montarMenuVideosRealizadas(JSON.stringify(videos))}
                    placement="bottomLeft"
                >
                    <button className="btn-start">Ver</button>
                </Dropdown>
                <Dropdown
                    overlay={this.montarMenuRealizadas(videoConfId, true, meetingName, false)}
                    placement="bottomLeft"
                >
                    <Link to="#" className="botao-icon-configs">
                        <i className="fas fa-cog" />
                    </Link>
                </Dropdown>
            </div>
        )
        this.setState({
            visibleUpload: false,
            videoconferencias
        });
    }

    guardarVideoID = (videoConfId, videoId) => {
        axios({
            method: "put",
            url: "/api/gerir-videoconferencia/guardar-video-id",
            params: {
                videoConfId,
                videoId
            }
        })
            .catch(() => { });
    };

    moverParaPasta = videoId => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/me/projects/${this.context.albumId_entidade}/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    carregarDominio = videoId => {
        axios({
            method: "get",
            url: "/api/curso/carregar-vimeo-configs",
        }).then(response => {
            this.adicionarDominio(videoId, response.data.dominio);
        });
    };

    adicionarDominio = (videoId, dominio) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/privacy/domains/${dominio}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    montarMenuVideosRealizadas = sessaoVimeoID => {
        var montar = JSON.parse(sessaoVimeoID).map((video, index) => (
            <Menu.Item>
                <Link to="#" onClick={() => this.iniciarVideo(video)}>
                    Video {index + 1}
                </Link>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    montarMenuRealizadas = (videoConfId, realizada, meetingName, Ficheiro) => {

        return (
            <Menu>
                {(realizada || Ficheiro) &&
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportar(videoConfId)}>
                            Participantes
                        </Link>
                    </Menu.Item>}
                {!this.state.fechado ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.setState({ videoConfId, visibleUpload: true, meetingName })}>
                            Vídeos
                        </Link>
                    </Menu.Item> : null}
                {!this.state.fechado ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.setState({ videoConfId, visibleImportar: true, meetingName })}>
                            Anexos
                        </Link>
                    </Menu.Item> : null}
            </Menu>
        );
    };

    montarMenuRealizadasMobile = (videoConfId, realizada, meetingName, Ficheiro, realizadaPor, participantes) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleAnteriores: true, anterioresRealizada: realizadaPor, anterioresParticipantes: participantes })}>
                        Detalhe
                        </Link>
                </Menu.Item>
                {(realizada || Ficheiro) &&
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportar(videoConfId)}>
                            Participantes
                        </Link>
                    </Menu.Item>}
                {!this.state.fechado ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.setState({ videoConfId, visibleUpload: true, meetingName })}>
                            Vídeos
                        </Link>
                    </Menu.Item> : null}
                {!this.state.fechado ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.setState({ videoConfId, visibleImportar: true, meetingName })}>
                            Anexos
                        </Link>
                    </Menu.Item> : null}
            </Menu>
        );
    };

    downloadVideo = sessaoVimeoID => {
        axios({
            method: "get",
            url: `https://api.vimeo.com/me/videos/${sessaoVimeoID}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        }).then(response => {
            if (response.data.download) {
                var element = document.createElement('a');

                if (response.data.download.find(x => x.quality === "source"))
                    element.setAttribute('href', response.data.download.find(x => x.quality === "source").link);
                else if (response.data.download.find(x => x.quality === "hd"))
                    element.setAttribute('href', response.data.download.find(x => x.quality === "hd").link);
                else
                    element.setAttribute('href', response.data.download.find(x => x.quality === "sd").link);

                element.style.display = 'none';

                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            }
        })
    }

    carregarDetalhe = id => {
        this.setState({
            videoConfId: id,
            visible: true
        });
    };

    criarVideconferencia = () => {
        this.setState({
            videoConfId: "",
            visible: true
        });
    };

    iniciar = (id, gravarSessao, connected, registoCriado) => {
        const meet = this.state.videoconferencias.find(x => x.id === id);
        const listaMeets = this.state.videoconferencias.filter(x => x.id !== id);

        if (moment().isAfter(moment(meet.datahora), 'days')) {
            this.iniciarForaDeData(id, gravarSessao, connected, registoCriado);
        }
        else {

            let count = 0;
            for (let i = 0; listaMeets.length > i; i++) {
                if (moment(meet.datahora).diff(moment(listaMeets[i].datahora), 'minutes') >= 0) {
                    count += 1;
                }
            }

            if (count > 0)
                openNotificationWithIcon("warning", "Atenção", "Tem Stepmeets por finalizar. Deve fazê-lo assim que possível.");

            if (gravarSessao && connected) {
                confirm({
                    title: "Esta sessão está a ser gravada!",
                    okText: "Continuar",
                    okType: "Cancelar",
                    onOk: () => {
                        if (!registoCriado)
                            this.setState({
                                meetID: id,
                                redirectLink: `/gerir-detalhe-turma/${this.props.match.params.turma
                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                    .toLowerCase()}/vconf/${id}/player-videoconferencia`
                            }, () => this.registarEntrada());
                        else
                            this.setState({
                                visibleBrowsers: true,
                                meetID: id,
                                redirectLink: `/gerir-detalhe-turma/${this.props.match.params.turma
                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                    .toLowerCase()}/vconf/${id}/player-videoconferencia`
                            });
                    }
                });
            }
            else {
                if (!registoCriado)
                    this.setState({
                        meetID: id,
                        redirectLink: `/gerir-detalhe-turma/${this.props.match.params.turma
                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                            .toLowerCase()}/vconf/${id}/player-videoconferencia`
                    }, () => this.registarEntrada());
                else
                    this.setState({
                        visibleBrowsers: true,
                        meetID: id,
                        redirectLink: `/gerir-detalhe-turma/${this.props.match.params.turma
                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                            .toLowerCase()}/vconf/${id}/player-videoconferencia`
                    });
            }
        }
    };

    registarEntrada = () => {
        axios({
            method: "post",
            url: "/api/gerir-videoconferencia/add-participant-entry",
            params: {
                meetingID: this.state.meetID,
                TempId: "",
                tipoEvento: 0
            }
        })
            .then(response => {
                this.setState({
                    redirect: true
                })
            })
            .catch(() => { });
    }

    marcarNaoMostrar = () => {
        axios({
            method: "post",
            url: "/api/utilizadores-informacoes/marcar-não-mostrar",
            params: {
                //cursoId: this.state.cursoid,
                mensagemId: this.state.mensagemId,
            }
        })
    }

    inicioDireto = () => {
        axios({
            method: "post",
            url: "/api/gerir-videoconferencia/add-participant-entry",
            params: {
                meetingID: this.state.meetID,
                TempId: "",
                tipoEvento: 0
            }
        })
            .then(response => {
                this.setState({
                    redirect: true,
                    redirectLink: this.state.linkIniciar
                })
            })
            .catch(() => { });
    }

    iniciarForaDeData = (id, gravarSessao, connected, registoCriado) => {
        confirm({
            title: "Esta Stepmeet está agendada para um dia anterior ao de hoje. Tem a certeza que pretende iniciar/reiniciar.",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                if (gravarSessao && connected) {
                    confirm({
                        title: "Esta sessão está a ser gravada!",
                        okText: "Continuar",
                        okType: "Cancelar",
                        onOk: () => {
                            if (!registoCriado)
                                this.setState({
                                    meetID: id,
                                    redirectLink: `/gerir-detalhe-turma/${this.props.match.params.turma
                                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                        .toLowerCase()}/vconf/${id}/player-videoconferencia`
                                }, () => this.registarEntrada());
                            else
                                this.setState({
                                    visibleBrowsers: true,
                                    meetID: id,
                                    redirectLink: `/gerir-detalhe-turma/${this.props.match.params.turma
                                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                        .toLowerCase()}/vconf/${id}/player-videoconferencia`
                                });
                        }
                    });
                }
                else {
                    if (!registoCriado)
                        this.setState({
                            meetID: id,
                            redirectLink: `/gerir-detalhe-turma/${this.props.match.params.turma
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/vconf/${id}/player-videoconferencia`
                        }, () => this.registarEntrada());
                    else
                        this.setState({
                            visibleBrowsers: true,
                            meetID: id,
                            redirectLink: `/gerir-detalhe-turma/${this.props.match.params.turma
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/vconf/${id}/player-videoconferencia`
                        });
                }
            }
        });
    };

    iniciarExterna = (id, meetExternaConvite) => {
        var meet = this.state.videoconferencias.find(x => x.id === id);
        var listaMeets = this.state.videoconferencias.filter(x => x.id !== id);

        let count = 0;
        for (var i = 0; listaMeets.length > i; i++) {
            if (moment(meet.datahora).diff(moment(listaMeets[i].datahora), 'minutes') >= 0) {
                count += 1;
            }
        }

        if (count > 0)
            openNotificationWithIcon("warning", "Atenção", "Tem Stepmeets por finalizar. Deve fazê-lo assim que possível.");

        this.setState({
            visibleMeetExterna: true,
            meetExternaConvite
        })
    };

    iniciarVideo = id => {
        this.setState({
            redirect: true,
            redirectLink: `/gerir-curso/${this.props.match.params.curso
                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                .toLowerCase()}/vconf/${id}/player-video`
        });
    };

    excluir = id => {
        confirm({
            title: "Pretende excluir esta Stepmeet?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gerir-videoconferencia/excluir",
                    params: {
                        id
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Stepmeet excluida");
                        this.context.atualizarEventosHoje();
                        this.atualizarListagem();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a Stepmeet");
                    });
            }
        });
    };

    atualizarListagem = () => {
        if (localStorage.getItem("menu_mensagens") === "recebidos") {
            this.setState(
                {
                    visible: false
                },
                () => {
                    this.totalAnteriores();
                    this.listarAnteriores();
                }
            );
        }
        else {
            this.setState(
                {
                    visible: false
                },
                () => {
                    this.totalProximas();
                    this.listarProximas();
                }
            );
        }
    }

    atualizarListagemNovo = () => {
        this.setState(
            {
                proximas_ativo_on: "ativo",
                anteriores_ativo_on: "",
                visible: false
            },
            () => {
                this.totalProximas();
                this.listarProximas();
            }
        );
    }

    exportar = videoConfId => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/videoconferencia",
                    params: {
                        videoConfId
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data.ficheiro,
                            ficheiroNomeExportar: response.data.nome
                        });
                    })
                    .catch(error => {
                        if (error.response.data === "SEM_REGISTOS")
                            openNotificationWithIcon("warning", "Atenção", "Não existem registo para serem exportados!");
                        else
                            openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");

                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    validarDataHoraSaida = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var dataHoraEntrada = moment(this.state.dataHoraEntrada, "DD/MM/YYYY HH:mm");
                var dataHoraSaida = moment(`${this.state.dataSaida.format("DD/MM/YYYY")} ${this.state.horaSaida.format("HH:mm")}`, "DD/MM/YYYY HH:mm");

                if (dataHoraEntrada.isAfter(dataHoraSaida, 'days')) {
                    confirm({
                        title: "Está a marcar como realizada uma Stepmeet com data posterior a data de inicio.",
                        okText: "Confirmar",
                        okType: "Cancelar",
                        onOk: () => {
                            this.adicionarDataHoraSaida(dataHoraSaida);
                        }
                    });
                }
                else {
                    this.adicionarDataHoraSaida(dataHoraSaida);
                }
            }
        })
    }

    adicionarDataHoraSaida = dataHoraSaida => {
        axios({
            method: "put",
            url: "/api/gerir-videoconferencia/inserir-hora-saida",
            params: {
                videoConfId: this.state.videoConfId,
                dataHoraSaida: dataHoraSaida.format("YYYY/MM/DD HH:mm")
            }
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Stepmeet marcada como realizada!");
                this.atualizarListagem();
                this.setState({
                    visibleDataSaida: false
                })
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível marcar a Stepmeet como realizada!");
            });
    }

    listarParticipantes = meetingId => {
        this.setState({
            visibleParticipantes: true,
            carregarLista: true
        })
        axios({
            method: "get",
            url: "/api/videoconferencia/listar-participantes",
            params: {
                meetingId
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data,
                    carregarLista: false
                })
            })
            .catch(() => { });
    };

    alterarTituloCarousel = index => {
        if (index === 0)
            this.setState({
                mensagemId: index + 1,
                tituloCarousel: "Informação sobre como deve sair da Stepmeet"
            })
        else
            this.setState({
                mensagemId: index + 1,
                tituloCarousel: "Informação sobre como deve acessar a esta Stepmeet"
            })
    }

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}>
                Voltar
            </Link>);
        }
    }

    render() {
        const {
            anteriores_ativo_on,
            proximas_ativo_on,
            videoconferencias,
            videoconferenciasMobile,
            pagination,
            loading,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER
            videoConfId,
            meetingName,
            visible,
            //DRAWER UPLOAD
            visibleUpload,
            //DRAWER IMPORTAR
            visibleImportar,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            ficheiroNomeExportar,
            //HORA SAIDA MODAL
            visibleDataSaida,
            dataHoraEntrada,
            dataSaida,
            horaSaida,
            //BROWSERS INFO
            visibleBrowsers,
            tituloCarousel,
            //MODAL PREPARAR MEET
            visibleIniciarMeet,
            prepararMeet,
            linkIniciar,
            meetExterno,
            meetExternaConvite,
            //MODAL LISTA PARTICIPANTES
            visibleParticipantes,
            carregarLista,
            participantes,
            //MODAL MEET EXTERNA
            visibleMeetExterna,
            //DRAWER PROXIMAS
            visibleProximas,
            proximasAgendada,
            proximasParticipantes,
            //DRAWER ANTERIORES
            visibleAnteriores,
            anterioresRealizada,
            anterioresParticipantes,
            //CURSO
            fechado
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const { Option } = Select;

        return (
            <>
                <div className="container container-body">
                    <div className="curso-mensagens curso-videoconferencias">
                        <div className="controlos controlos-fundo">
                            <div className="controlos-opcoes">
                                {this.montarBotaoVoltar()}
                                {fechado ?
                                    <button className="botao-principal botao-disabled" disabled>
                                        Nova Stepmeet
                                                </button>
                                    :
                                    <button className="botao-principal" onClick={this.criarVideconferencia}>
                                        Nova Stepmeet
                                                </button>
                                }
                            </div>
                            {anteriores_ativo_on ?
                                <>
                                    <div className="controlos-opcoes controlo-search">
                                        <BotaoProcurar
                                            HandleKeyPress={this.HandleKeyPress}
                                            resetCaixaProcura={this.resetCaixaProcura}
                                            tem_placeholder
                                            placeholder="Procurar no Assunto"
                                        //disabled={!anteriores_ativo_on}
                                        />
                                    </div>
                                    <div className="controlos-opcoes controlo-checkbox">
                                        <Checkbox
                                            name="excluirTodos"
                                            //disabled={!anteriores_ativo_on}
                                            onChange={this.handlerChangeExcluirTodos}>
                                            Excluir Partilha com Todos
                                                </Checkbox>
                                    </div>
                                </>
                                : null}
                        </div>
                        <div className="tickets stepmeets min-height">
                            <Sidebar
                                filtro_proximas={this.filtro_proximas}
                                filtro_anteriores={this.filtro_anteriores}
                                proximas_ativo={this.state.proximas_ativo_on}
                                anteriores_ativo={this.state.anteriores_ativo_on}
                            //HandleKeyPress={this.HandleKeyPress}
                            //resetCaixaProcura={this.resetCaixaProcura}
                            //criarVideconferencia={this.criarVideconferencia}
                            />
                            <div className="bloco-formadores bloco-videoconferencias-desktop">
                                {proximas_ativo_on === "ativo" ? (
                                    <TabelaProximas
                                        videoconferencias={videoconferencias}
                                        pagination={pagination}
                                        loading={loading}
                                        handleTableChange={this.handleTableChange}
                                        handleChangeSelect={this.handleChangeSelect}
                                    />
                                ) : (
                                        <TabelaAnteriores
                                            videoconferencias={videoconferencias}
                                            pagination={pagination}
                                            loading={loading}
                                            handleTableChange={this.handleTableChange}
                                            handleChangeSelect={this.handleChangeSelect}
                                        />
                                    )}
                            </div>
                            <div className="bloco-formadores bloco-videoconferencias-mobile">
                                {proximas_ativo_on === "ativo" ? (
                                    <TabelaProximasMobile
                                        videoconferencias={videoconferenciasMobile}
                                        pagination={pagination}
                                        loading={loading}
                                        handleTableChange={this.handleTableChange}
                                        handleChangeSelect={this.handleChangeSelect}
                                    />
                                ) : (
                                        <TabelaAnterioresMobile
                                            videoconferencias={videoconferenciasMobile}
                                            pagination={pagination}
                                            loading={loading}
                                            handleTableChange={this.handleTableChange}
                                            handleChangeSelect={this.handleChangeSelect}
                                        />
                                    )}
                            </div>
                        </div>
                        <DrawerForm
                            videoConfId={videoConfId}
                            visible={visible}
                            onClose={() => this.setState({ visible: false })}
                            atualizarListagem={this.atualizarListagemNovo}
                            prepararMeet={estado =>
                                this.setState({
                                    visibleIniciarMeet: estado,
                                    prepararMeet: true
                                })
                            }
                            IniciarMeet={(linkIniciar, meetExterno, meetID) =>
                                this.setState({
                                    prepararMeet: false,
                                    linkIniciar,
                                    meetID,
                                    meetExterno
                                })
                            }
                        />
                        <DrawerFormUploadVideo
                            videoConfId={videoConfId}
                            meetingName={meetingName}
                            visible={visibleUpload}
                            onClose={() => this.setState({ visibleUpload: false })}
                            atualizarListagemVisivel={this.atualizarListagemVisivel}
                            nomeCurso={this.props.match.params.curso}
                        />
                        <DrawerFormUploadFicheiro
                            videoConfId={videoConfId}
                            meetingName={meetingName}
                            visible={visibleImportar}
                            onClose={() => this.setState({ visibleImportar: false })}
                            atualizarListagemVisivel={this.atualizarListagemVisivel}
                        />
                    </div>
                </div>
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
                <Modal
                    title="Atualizar data e hora"
                    visible={visibleDataSaida}
                    okText="Confirmar"
                    onOk={this.validarDataHoraSaida}
                    onCancel={() => this.setState({ visibleDataSaida: false })}
                    cancelText="Cancelar"
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal" style={{ padding: 0 }}>
                            <h3 className="titulo-separador">Hora sugerida</h3>
                            <div className="bloco">
                                <Form.Item>
                                    {dataHoraEntrada}
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Término</h3>
                                <Form.Item label="Data">
                                    {getFieldDecorator("dataSaida", {
                                        rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                        }]
                                    })(<DatePicker
                                        className="input-50"
                                        placeholder="Selecionar"
                                        format="DD-MM-YYYY"
                                        name="dataSaida"
                                        onChange={dataSaida => this.setState({ dataSaida })}
                                    />)}
                                </Form.Item>
                                <Form.Item label="Hora">
                                    {getFieldDecorator("horaSaida", {
                                        rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                        }]
                                    })(<TimePicker
                                        className="input-50"
                                        name="hora"
                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                        format="HH:mm"
                                        onChange={horaSaida => this.setState({ horaSaida })}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                    />)}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    visible={visibleIniciarMeet}
                    okText={
                        meetExterno ?
                            <a href={linkIniciar} target="_blank" rel="noopener noreferrer" onClick={() => this.setState({ visibleIniciarMeet: false })}>
                                Entrar
                            </a>
                            :
                            <Link to="#" onClick={this.inicioDireto}>
                                Entrar
                            </Link>
                    }
                    onCancel={() => this.setState({ visibleIniciarMeet: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: prepararMeet }}
                    maskClosable={false}
                    className="modal-loading"
                >
                    <div className="modal-loading-bloco">
                        {prepararMeet ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A preparar Stepmeet...</p>
                            </>
                        ) : (
                                <>
                                    <p>
                                        <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                    </p>
                                    <p className="texto">Stepmeet pronto!</p>
                                </>
                            )}
                    </div>
                </Modal>
                <Modal
                    title={<>Participantes<br /><span style={{ fontSize: 13, color: "#8e9aa0" }}>Total: {participantes.length}</span></>}
                    visible={visibleParticipantes}
                    onCancel={() => this.setState({ visibleParticipantes: false })}
                    maskClosable={false}
                    className="modal-lista-participantes"
                    footer={null}
                >
                    <div className="bloco-lista-participantes">
                        {carregarLista ? (
                            <div className="bloco-loading">
                                <p>
                                    <Icon className="icon-loading" type="loading" />
                                </p>
                                <p className="texto">A carregar...</p>
                            </div>
                        ) : (
                                <>
                                    {participantes.map((participante, index) => (
                                        <div key={index} className="info-participante">
                                            <div className="bloco-img">
                                                <img src={participante.foto ? participante.foto : noimage} />
                                            </div>
                                            <div className="info">
                                                <span className="nome">
                                                    {participante.nome}
                                                </span>
                                                <p className="numero">{participante.numero}</p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                    </div>
                </Modal>
                <Modal
                    title="Convite"
                    visible={visibleMeetExterna}
                    onCancel={() => this.setState({ visibleMeetExterna: false })}
                    maskClosable={false}
                    className="modal-lista-participantes"
                    footer={null}
                >
                    <div className="bloco-lista-participantes" dangerouslySetInnerHTML={{ __html: meetExternaConvite?.replace(/(?:\r\n|\r|\n)/g, '<br>') }}>
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-proximas"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleProximas: false })}
                    visible={visibleProximas}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Agendada por">
                                    {proximasAgendada}
                                </Form.Item>
                                <Form.Item label="Participantes">
                                    {proximasParticipantes}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleProximas: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Drawer
                    className="drawer-detalhe-anteriores"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleAnteriores: false })}
                    visible={visibleAnteriores}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Iniciada por">
                                    {anterioresRealizada}
                                </Form.Item>
                                <Form.Item label="Participantes">
                                    {anterioresParticipantes}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleAnteriores: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

const MainForm = Form.create({ name: "form-categorias" })(Main);

export default MainForm;
