import React, { Component } from "react";
import {
    Drawer,
    Form,
    Input,
    DatePicker,
    TimePicker,
    Icon,
    notification,
    Radio,
    Row,
    Col,
    InputNumber,
    Select,
    Checkbox,
    Switch
} from "antd";
import { CirclePicker } from "react-color";
import { GeralContext } from "../../ContextProvider";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerAdicionar extends Component {
    static contextType = GeralContext;

    state = {
        titulo: "",
        dt_inicio: null,
        hr_inicio: null,
        hr_termino: null,
        opcao: 1,
        avaliacao: false,
        //LOADING
        iconLoading: false,
        //VALIDAR CRIAR AULAS
        validateStatus: "",
        validateHelp: ""
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeDataInicio = dt_inicio => {
        //if (!this.state.dt_termino)
        //    this.setState({
        //        dt_inicio,
        //        dt_termino: dt_inicio
        //    });
        //else
        if (this.props.aulaId)
            this.setState({
                dt_inicio
            });
        else
            this.setState(
                {
                    dt_inicio
                },
                () => (dt_inicio ? this.props.atualizarPosicaoCalendário(dt_inicio.toDate()) : {})
            );
    };

    handleChangeHoraInicio = hr_inicio => {
        this.setState({ hr_inicio });
    };

    handleChangeHoraTermino = hr_termino => {
        this.setState({ hr_termino });
    };

    selecionarOpcaoHandler = event => {
        this.setState({
            opcao: event.target.value
        });
    };

    handlerChangeRadio = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    criar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (
                this.context.professorPolivalente &&
                !this.state.aulaId &&
                this.state.listaCursos.length > 0 &&
                this.state.selecionados.length === 0
            ) {
                this.setState({
                    validateStatus: "error",
                    validateHelp: "Campo obrigatório"
                });
            }

            if (
                !err &&
                (!this.context.professorPolivalente ||
                    (this.context.professorPolivalente &&
                        !this.state.aulaId &&
                        this.state.listaCursos.length > 0 &&
                        this.state.selecionados.length > 0))
            ) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_curso", localStorage.getItem("codigo_curso"));
                item.append("titulo", this.state.titulo);
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                item.append("hr_inicio", this.state.hr_inicio.format("HH:mm"));
                item.append("hr_termino", this.state.hr_termino.format("HH:mm"));
                item.append("avaliacao", this.state.avaliacao);

                axios({
                    method: "post",
                    url: "/api/aulas/adicionar",
                    data: item
                })
                    .then(response => {
                        this.setState({ iconLoading: false });
                        const lista = response.data.map(aula => {
                            return {
                                id: aula.id,
                                title: (
                                    <>
                                        {aula.avaliacao ? <i className="evento-icon-avaliacao" title="Aula com avaliação"></i> : null}
                                        {aula.titulo}
                                    </>
                                ),
                                start: new Date(`${moment(aula.dt_inicio).format("YYYY/MM/DD")} ${aula.hr_inicio}`),
                                end: new Date(`${moment(aula.dt_inicio).format("YYYY/MM/DD")} ${aula.hr_termino}`)
                            };
                        });
                        this.props.adicionarAulas(lista);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar a aula!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    detalhe = aulaId => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe",
            params: {
                aulaId
            }
        })
            .then(response => {
                let evento = response.data;
                this.setState({
                    titulo: evento.titulo,
                    dt_inicio: moment(evento.dt_inicio),
                    hr_inicio: moment(evento.hr_inicio, "HH:mm"),
                    hr_termino: moment(evento.hr_termino, "HH:mm"),
                    avaliacao: evento.avaliacao
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    };

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.aulaId);
                item.append("titulo", this.state.titulo);
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                item.append("hr_inicio", this.state.hr_inicio.format("HH:mm"));
                item.append("hr_termino", this.state.hr_termino.format("HH:mm"));
                item.append("opcao", this.state.opcao);
                item.append("avaliacao", this.state.avaliacao);

                axios({
                    method: "put",
                    url: "/api/aulas/alterar",
                    data: item
                })
                    .then(response => {
                        this.setState({ iconLoading: false });
                        this.props.atualizarAula({
                            titulo: this.state.titulo,
                            dt_inicio: this.state.dt_inicio,
                            hr_inicio: this.state.hr_inicio,
                            hr_termino: this.state.hr_termino
                        });
                        localStorage.setItem("posicao_calendario", this.state.dt_inicio.toDate());
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível alterar a aula!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.aulaId) {
                this.detalhe(this.props.aulaId);
            } else {
                if (this.props.dataHoraInicio && this.props.dataHoraFim) {
                    var dataIni = moment(this.props.dataHoraInicio);
                    var dataFim = moment(this.props.dataHoraFim);

                    this.setState({
                        titulo: "",
                        dt_inicio: dataIni,
                        hr_inicio: dataIni,
                        hr_termino: dataFim,
                        avaliacao: false,
                        //LOADING
                        iconLoading: false,
                        //REPLICAR
                        selecionados: [],
                        indeterminate: false,
                        todos: false
                    });
                } else {
                    const inicio = moment();
                    const minutos = 15 - (inicio.minute() % 15);

                    const hora = moment(inicio).add(minutos, "minutes");

                    this.setState({
                        titulo: "",
                        dt_inicio: moment(),
                        hr_inicio: hora,
                        hr_termino: moment(hora).add(1, "hours"),
                        avaliacao: false,
                        //LOADING
                        iconLoading: false,
                        //REPLICAR
                        selecionados: [],
                        indeterminate: false,
                        todos: false
                    });
                }
            }
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            titulo,
            dt_inicio,
            hr_termino,
            hr_inicio,
            avaliacao,
            opcao,
            //LOADING
            iconLoading
        } = this.state;

        const { visible, aulaId } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-adicionar-evento"
                    //title={aulaId ? "Alterar" : "Adicionar"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                {aulaId && (
                                    <Form.Item className="form-item-checkbox">
                                        <Radio.Group name="opcao" onChange={this.handlerChangeRadio} value={opcao}>
                                            <Radio value={1}>Aula de avaliação</Radio>
                                            <Radio value={2}>Aula não realizada</Radio>
                                            <Radio value={3}>Aula de compensação</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                )}
                                <Form.Item label="Aula">
                                    {getFieldDecorator("titulo", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: titulo
                                    })(<Input name="titulo" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label="Inicio">
                                    {getFieldDecorator("dt_inicio", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: dt_inicio
                                    })(
                                        <DatePicker
                                            className="input-25"
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            name="dt_fimAula"
                                            onChange={this.handleChangeDataInicio}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Hora Inicio">
                                    {getFieldDecorator("hr_inicio", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: hr_inicio
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="duracaoAula"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.handleChangeHoraInicio}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Hora Término">
                                    {getFieldDecorator("hr_termino", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: hr_termino
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="duracaoAula"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.handleChangeHoraTermino}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator("avaliacao", {
                                        initialValue: avaliacao,
                                        valuePropName: "checked"
                                    })(
                                        <Checkbox name="avaliacao" onChange={this.handleChangeCheckbox}>
                                            Aula de avaliação
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </button>
                        <button
                            className="botao-principal"
                            onClick={aulaId ? this.alterar : this.criar}
                            disabled={iconLoading}
                            type="primary"
                        >
                            {iconLoading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>
                        {/*{aulaId ? (*/}
                        {/*    <button className="botao-principal" onClick={this.alterar} disabled={iconLoading} type="primary">*/}
                        {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*        Guardar*/}
                        {/*    </button>*/}
                        {/*) : (*/}
                        {/*    <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">*/}
                        {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                        {/*        Criar*/}
                        {/*    </button>*/}
                        {/*)}*/}
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerAdicionar = Form.create({ name: "form-drawer-adicionar" })(DrawerAdicionar);

export default FormDrawerAdicionar;
