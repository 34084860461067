import React from "react";
import { Modal, Row, Col, Carousel, Button, Checkbox } from "antd";
import chrome from "../images/browsers/chrome.png";
import edgeChromium from "../images/browsers/edge-chromium.png";
import firefox from "../images/browsers/firefox.png";
import safari from "../images/browsers/safari.png";
import encerrarStepmeet from "../images/browsers/encerrar-stepmeet2.png";
import encerrarStepmeetTodos from "../images/browsers/encerrar-stepmeet-todos2.png";

const CarouselGestor = ({ tituloCarousel, visibleBrowsers, confirmarEntrada, cancelarEntrada, alterarTituloCarousel, selecionarNaoMostrar, visivelMensagem1, visivelMensagem2 }) => {
    return (
        <>
            <Modal
                title={tituloCarousel}
                visible={visibleBrowsers}
                //okText="Confirmar"
                //onOk={confirmarEntrada}
                //onCancel={cancelarEntrada}
                //cancelText="Cancelar"
                maskClosable={false}
                className="modal-browsers"
                footer={[
                    <>
                        <div>
                            <Checkbox onClick={selecionarNaoMostrar}>Não mostrar mais esta mensagem</Checkbox>
                        </div>
                        <div>
                            <Button key="back" onClick={cancelarEntrada}>
                                Cancelar
                            </Button>
                            <Button key="submit" type="primary" onClick={confirmarEntrada}>
                                        Confirmar
                            </Button>
                        </div>
                    </>
                ]}
            >
                <div className="body-modal-browsers">
                    <Carousel afterChange={alterarTituloCarousel}>
                        {!visivelMensagem1 ?
                            <div>
                                <div className="bloco-info-browsers">
                                    <h3>Sair da Stepmeet</h3>
                                    <div className="bloco-texto-imagem">
                                        <p>Para sair da stepmeet utilize o botão apresentado na imagem. Não encerre a sessão fechando o navegador.</p>
                                        <img src={encerrarStepmeet} />
                                    </div>
                                </div>
                                <div className="bloco-info-separador"></div>
                                <div className="bloco-info-browsers">
                                    <h3>Encerrar para todos e finalizar</h3>
                                    <p>Ao encerrar para todos finalize a sessão (ficará na lista das realizadas e não poderá ser reiniciada).</p>
                                    <img src={encerrarStepmeetTodos} className="imagem-centro" />
                                </div>
                            </div>
                            : null}
                        {!visivelMensagem2 ?
                            <div>
                                <div className="bloco-info-browsers">
                                    <h3 style={{ marginBottom: 30 }}>Windows e Android (Browsers suportados):</h3>
                                    <Row style={{ textAlign: "center" }}>
                                        <Col span={8}>
                                            <img src={edgeChromium} style={{ width: 60 }} />
                                        </Col>
                                        <Col span={8}>
                                            <img src={chrome} style={{ width: 60 }} />
                                        </Col>
                                        <Col span={8}>
                                            <img src={firefox} style={{ width: 60 }} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="bloco-info-separador"></div>
                                <div className="bloco-info-browsers">
                                    <h3 style={{ marginBottom: 30 }}>Mac, Ipad e Iphone (Browsers suportados):</h3>
                                    <Row style={{ textAlign: "center" }}>
                                        <Col span={8}>
                                            <img src={safari} style={{ width: 60 }} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="bloco-info-separador"></div>
                                <div className="bloco-info-browsers">
                                    <h3>
                                        Verifique que tem as permissões ativas de microfone e camera no navegador para esta sessão.
                                </h3>
                                </div>
                            </div>
                            : null}
                    </Carousel>
                </div>
            </Modal>
        </>
    );
};

export default CarouselGestor;
