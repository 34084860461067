import React, { Component } from "react";
import { Link, matchPath } from "react-router-dom";
import { Modal, Form, Input, Tooltip, Icon, DatePicker } from "antd";
import { GlobalContext } from "../../GlobalState";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import moment from "moment";

class ModalInscricaoPart1 extends Component {
    state = {
        nome_completo: "",
        cc: "",
        dt_ccvalidade: null,
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeData = dt_ccvalidade => {
        this.setState({ dt_ccvalidade });
    };

    confirmar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.confirmarPart1(this.state.nome_completo, this.state.cc, this.state.dt_ccvalidade)
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            nome_completo,
            cc,
            dt_ccvalidade,
        } = this.state;
        const {
            codigo,
            nome,
            carga_horaria,
            dt_inicio,
            dt_fim,
            visiblePart1
        } = this.props;

        return (
            <>
                <Modal
                    title="Inscrição"
                    visible={visiblePart1}
                    okText="Confirmar"
                    cancelText="Cancelar"
                    className="modal-inscricao"
                    onOk={this.confirmar}
                    onCancel={this.props.cancelar}
                    maskClosable={false}
                >
                    <Form className="form-incricao">
                        <div className="bloco">
                            <Form.Item >
                                <div className="bloco-info">
                                    <span className="info info-curso"><span className="info-curso-nome">{nome}</span><span className="info-curso-codigo">{codigo}</span></span>
                                    <span className="info">Carga Horária: <strong>{carga_horaria} Horas</strong></span>
                                    <span className="info"><span>Inicio em: <strong>{moment(dt_inicio).format("DD/MM/YYYY")}</strong></span>{dt_fim ? <span> e Término em: <strong>{moment(dt_fim).format("DD/MM/YYYY")}</strong></span> : null}</span>
                                </div>
                            </Form.Item>
                            <Form.Item label="Nome Completo">
                                {getFieldDecorator("nome-completo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                            whitespace: true
                                        }
                                    ],
                                    initialValue: nome_completo
                                })(<Input name="nome_completo" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        Identificação / CC
                                        <Tooltip className="info-icon" title="Documento de Identificação, Passaporte, ...">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                }
                            >
                                {getFieldDecorator("cc", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                            whitespace: true
                                        }
                                    ],
                                    initialValue: cc
                                })(<Input name="cc" className="input-50" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        Validade
                                        <Tooltip className="info-icon" title="Validade do documento de identificação">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                }
                            >
                                {getFieldDecorator("dt_ccvalidade", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: dt_ccvalidade
                                })(<DatePicker
                                    className="input-25"
                                    placeholder="Selecionar"
                                    format="DD-MM-YYYY"
                                    name="dt_ccvalidade"
                                    onChange={this.handleChangeData}
                                />)}
                            </Form.Item>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }
}

const FormModalInscricaoPart1 = Form.create({ name: "form-modal-inscricao-part1" })(ModalInscricaoPart1);

export default FormModalInscricaoPart1;
