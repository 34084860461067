import React, { Component } from "react";
import { GlobalContext } from "../../GlobalState";

class ControlosTopo extends Component {
    static contextType = GlobalContext;
    render() {
        return (
            <div className="controlos">
                {this.props.controlos_responder_topo &&
                    <>
                        <div className="bloco1">
                        
                        </div>
                        <div className="bloco2">
                            {/*<span className="codigo">#{this.props.codigo}</span>*/}
                            <span className={"estado " + this.props.estado_cor}>{this.props.estado}</span>
                        </div>
                    </>}
            </div>
        );
    }
}

export default ControlosTopo;
