import React, { Component } from "react";
import axios from "axios";

class Pontos extends Component {
    state = {
        //GAMIFICACAO
        pontos: 0,
        posicao: 0,
    }

    UNSAFE_componentWillMount() {
        this.carregarPontos();
    }

    carregarPontos = () => {
        axios({
            method: "get",
            url: "/api/gerir-curso-ranking/carregar-participante-pontos",
            params: {
                cursoid: this.props.cursoid,
                utilizadorid: this.props.utilizadorid
            }
        })
            .then(response => {
                this.setState({
                    pontos: response.data.pontos,
                    posicao: response.data.posicao,
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            pontos,
            posicao,
        } = this.state;

        return (<span className="pontuacao">{pontos} pts ({posicao ? `${posicao}º` : "---"})</span>);
    }
}

export default Pontos;
