import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Icon, Modal, Checkbox, Row, Col, Select, notification } from "antd";
import moment from "moment";
import axios from "axios";
import { useReactToPrint } from "react-to-print";

import SearchBar from "../../_Aux/SearchBar";
import ModalGerarDocumento from "../../../Componentes/Modals/GerarDocumento";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Controlos = ({
    handleSearch,
    search,
    handleChangeSearch,
    resetSearch,
    filterSchool,
    setFilterSchool,
    filterEdition,
    setFilterEdition,
    filterPhase,
    setFilterPhase,
    filterState,
    setFilterState,
    setCols,
    menuOptionSelected,
    disabledSearch,
    disabledButtons,
    columnsPositions,
    sorter,
    cols,
    data,
    pageTitle
}) => {
    const { Option } = Select;
    const [visible, setVisible] = useState(false);
    const [columns, setColumns] = useState([
        {
            title: "Edição",
            key: "edition"
        },
        {
            title: "Ano",
            key: "year"
        },
        {
            title: "Turma",
            key: "classLetter"
        },
        {
            title: "Código",
            key: "code"
        },
        {
            title: "Nome",
            key: "name"
        },
        {
            title: "Categoria",
            key: "category"
        },
        {
            title: "Inicio",
            key: "start"
        },
        {
            title: "Término",
            key: "end"
        },
        {
            title: "Inscritos",
            key: "registers"
        },
        {
            title: "Atividades",
            key: "activities"
        },
        {
            title: "Tarefas",
            key: "tasks"
        }
    ]);
    const [selectedColumns, setSelectedColumns] = useState(["edition", "year", "classLetter", "code", "name"]);
    const [schools, setSchools] = useState([]);
    const [editions, setEditions] = useState([]);
    const [phases, setPhases] = useState([]);
    const [states, setStates] = useState([
        {
            id: 1,
            name: "Ativo"
        },
        {
            id: 2,
            name: "Inativo"
        },
        {
            id: 3,
            name: "Excludío"
        }
    ]);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const onChangeCheckbox = checkedValues => {
        setSelectedColumns(checkedValues);
    };

    const confirmColumns = () => {
        var columns = selectedColumns.map(value => {
            return returnColumn(value);
        });

        setCols(columns);
        setVisible(false);
    };

    const returnColumn = key => {
        if (key === "edition") {
            return {
                title: "Edição",
                dataIndex: "edition",
                sorter: (a, b) => a.edition.localeCompare(b.edition),
                sortDirections: ["descend", "ascend"]
            };
        }
        if (key === "year") {
            return {
                title: "Ano",
                dataIndex: "year",
                sorter: (a, b) => a.year.localeCompare(b.year),
                sortDirections: ["descend", "ascend"]
            };
        }
        if (key === "classLetter") {
            return {
                title: "Turma",
                dataIndex: "classLetter",
                sorter: (a, b) => a.classLetter.localeCompare(b.classLetter),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "code") {
            return {
                title: "Código",
                dataIndex: "code",
                sorter: (a, b) => a.code.localeCompare(b.code),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "name") {
            return {
                title: "Nome",
                dataIndex: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "category") {
            return {
                title: "Categoria",
                dataIndex: "category",
                sorter: (a, b) => a.category.localeCompare(b.category),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "start") {
            return {
                title: "Inicio",
                dataIndex: "start",
                sorter: (a, b) => moment(a.start).unix() - moment(b.start).unix(),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "end") {
            return {
                title: "Término",
                dataIndex: "end",
                sorter: (a, b) => moment(a.end).unix() - moment(b.end).unix(),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "registers") {
            return {
                title: "Inscritos",
                dataIndex: "registers",
                sorter: (a, b) => a.registers - b.registers,
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "activities") {
            return {
                title: "Atividades",
                dataIndex: "activities",
                sorter: (a, b) => a.activities - b.activities,
                sortDirections: ["descend", "ascend"]
            };
        } else {
            return {
                title: "Tarefas",
                dataIndex: "tasks",
                sorter: (a, b) => a.tasks - b.tasks,
                sortDirections: ["descend", "ascend"]
            };
        }
    };

    const loadSchoolsList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-escolas"
        })
            .then(response => {
                setSchools(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const loadEditionsList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-edicoes"
        })
            .then(response => {
                setEditions(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const loadPhasesList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-fases"
        })
            .then(response => {
                setPhases(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const exportPdf = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/relatorios/lista-cursos-exportar-pdf",
            params: {
                search,
                sorter: JSON.stringify(sorter),
                selectedColumns: JSON.stringify(columnsPositions),
                filterSchool,
                filterEdition,
                filterPhase,
                filterState
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    };

    const exportCsv = () => {
        setVisibleExport(true);
        setButtonDownload(true);

        axios({
            method: "get",
            url: "/api/relatorios/lista-cursos-exportar-csv",
            params: {
                search,
                sorter: JSON.stringify(sorter),
                selectedColumns: JSON.stringify(columnsPositions),
                filterSchool,
                filterEdition,
                filterPhase,
                filterState
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    };

    useEffect(() => {
        loadSchoolsList();
        loadEditionsList();
        loadPhasesList();
    }, [menuOptionSelected]);

    const useWindowWidth = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            // Function to update windowWidth state with current innerWidth
            const handleWindowResize = () => {
                setWindowWidth(window.innerWidth);
            };

            // Event listener for window resize
            window.addEventListener("resize", handleWindowResize);

            // Cleanup: remove event listener on unmount
            return () => {
                window.removeEventListener("resize", handleWindowResize);
            };
        }, []);

        return windowWidth;
    };

    const windowWidth = useWindowWidth();

    return (
        <>
            {windowWidth > 1025 ? (
                <div className="controls">
                    <div className="controls-block controls-block-space-between">
                        <div className="controls-block">
                            <span className="search">
                                <SearchBar
                                    placeholder="Procurar"
                                    search={search}
                                    setSearch={handleChangeSearch}
                                    resetSearch={resetSearch}
                                />
                            </span>
                            <span className="options">
                                <Button onClick={() => setVisible(true)}>
                                    <Icon type="table" />
                                    Colunas
                                </Button>
                            </span>
                        </div>
                        <div className="controls-block">
                            <span className="options">
                                <Link
                                    to="#"
                                    className={`button-print ${disabledButtons ? "button-disabled" : ""}`}
                                    disabled={disabledButtons}
                                    onClick={handlePrint}
                                >
                                    <Icon type="printer" />
                                </Link>
                                <Link
                                    to="#"
                                    className={`button-export-pdf ${disabledButtons ? "button-disabled" : ""}`}
                                    disabled={disabledButtons}
                                    onClick={exportPdf}
                                >
                                    <Icon type="file-pdf" />
                                </Link>
                                <Link
                                    to="#"
                                    className={`button-export ${disabledButtons ? "button-disabled" : ""}`}
                                    disabled={disabledButtons}
                                    onClick={exportCsv}
                                >
                                    <Icon type="download" />
                                </Link>
                            </span>
                        </div>
                    </div>
                    <div className="controls-block-space-between">
                        <span className="filters">
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Escola"
                                allowClear={true}
                                value={filterSchool}
                                onChange={filter => setFilterSchool(filter)}
                            >
                                {schools.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Edição"
                                allowClear={true}
                                value={filterEdition}
                                onChange={filter => setFilterEdition(filter)}
                            >
                                {editions.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Fase"
                                allowClear={true}
                                value={filterPhase}
                                onChange={filter => setFilterPhase(filter)}
                            >
                                {phases.map((item, index) => (
                                    <Option key={index} value={item.code}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Estado"
                                allowClear={true}
                                value={filterState}
                                onChange={filter => setFilterState(filter)}
                            >
                                {states.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </span>
                    </div>
                    <div className="controls-block">
                        <button className="search-button botao-principal" disabled={disabledSearch} onClick={handleSearch}>
                            Pesquisar
                            <Icon type="search" />
                        </button>
                    </div>
                </div>
            ) : (
                <div className="controls">
                    <div className="controls-block controls-block-space-between">
                        <div className="controls-block">
                            <span className="options">
                                <Button onClick={() => setVisible(true)}>
                                    <Icon type="table" />
                                    Colunas
                                </Button>
                            </span>
                        </div>
                        <div className="controls-block">
                            <span className="options">
                                <Link
                                    to="#"
                                    className={`button-print ${disabledButtons ? "button-disabled" : ""}`}
                                    disabled={disabledButtons}
                                    onClick={handlePrint}
                                >
                                    <Icon type="printer" />
                                </Link>
                                <Link
                                    to="#"
                                    className={`button-export-pdf ${disabledButtons ? "button-disabled" : ""}`}
                                    disabled={disabledButtons}
                                    onClick={exportPdf}
                                >
                                    <Icon type="file-pdf" />
                                </Link>
                                <Link
                                    to="#"
                                    className={`button-export ${disabledButtons ? "button-disabled" : ""}`}
                                    disabled={disabledButtons}
                                    onClick={exportCsv}
                                >
                                    <Icon type="download" />
                                </Link>
                            </span>
                        </div>
                    </div>
                    <div className="controls-block controls-block-space-between">
                        <div className="controls-block">
                            <span className="search">
                                <SearchBar
                                    placeholder="Procurar"
                                    search={search}
                                    setSearch={handleChangeSearch}
                                    resetSearch={resetSearch}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="controls-block-space-between">
                        <span className="filters">
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Escola"
                                allowClear={true}
                                value={filterSchool}
                                onChange={filter => setFilterSchool(filter)}
                            >
                                {schools.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Edição"
                                allowClear={true}
                                value={filterEdition}
                                onChange={filter => setFilterEdition(filter)}
                            >
                                {editions.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Fase"
                                allowClear={true}
                                value={filterPhase}
                                onChange={filter => setFilterPhase(filter)}
                            >
                                {phases.map((item, index) => (
                                    <Option key={index} value={item.code}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Estado"
                                allowClear={true}
                                value={filterState}
                                onChange={filter => setFilterState(filter)}
                            >
                                {states.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </span>
                    </div>
                    <div className="controls-block">
                        <button className="search-button botao-principal" disabled={disabledSearch} onClick={handleSearch}>
                            Pesquisar
                            <Icon type="search" />
                        </button>
                    </div>
                </div>
            )}

            <div ref={componentRef} className="printArea">
                <div className="print-header">
                    <h1>Relatórios - {pageTitle}</h1>
                    <div className="filters-block">
                        <span>Filtros</span>
                        <div className="filters-tags">
                            {search && <span>Pesquisa: {search}</span>}
                            {filterSchool && <span>Escola: {schools.find(x => x.id === filterSchool)?.name}</span>}
                            {filterEdition && <span>Edição: {editions.find(x => x.id === filterEdition)?.name}</span>}
                            {filterPhase && <span>Fase: {phases.find(x => x.code === filterPhase)?.name}</span>}
                            {filterState && <span>Estado: {states.find(x => x.id === filterState)?.name}</span>}
                        </div>
                    </div>
                </div>
                <div className="print-body">
                    <Table columns={cols} pagination={false} dataSource={data} />
                </div>
            </div>
            <Modal title="Selecionar colunas" visible={visible} onOk={confirmColumns} okText="Confirmar" onCancel={() => setVisible(false)}>
                <Checkbox.Group defaultValue={selectedColumns} style={{ width: "100%" }} onChange={onChangeCheckbox}>
                    <Row>
                        {columns.map((column, index) => (
                            <Col key={index} span={8}>
                                <Checkbox value={column.key}>{column.title}</Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Modal>
            <ModalGerarDocumento
                visibleExportar={visibleExport}
                ficheiroExportar={exportFile}
                btnDescarregar={buttonDownload}
                onClose={() => setVisibleExport(false)}
            />
        </>
    );
};

export default Controlos;
