import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, notification, Checkbox, Icon } from "antd";
import Modulo from "../_Aux/Modulo";
import ModuloDrawer from "../_Aux/FormModulo";
import AulaDrawer from "../_Aux/FormAula";
import DrawerPresencas from "../_Aux/DrawerPresencas";
import { GeralContext } from "../ContextProvider";
import { GlobalContext } from "../../GlobalState";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import axios from "axios";
import moment from "moment";

import Acessos from "../../Geral/Acessos/Acessos";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514";

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
};

class Modulos extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        turma: "",
        retomar: {},
        modulo_aberto: "0",
        modulos: [],
        botoesVisiveis: false,
        total_conteudos: 0,
        total_conteudos_realizados: 0,
        //DRAWER MODULO
        drawerModuloVisible: false,
        moduloid: 0,
        existeCompetencias: false,
        proximaPosicaoModulo: 1,
        dt_precedencia: "",
        //DRAWEER AULA
        drawerAulaVisible: false,
        aulaid: 0,
        aulaTipo: "",
        subTarefaAssiduidade: false,
        subTarefaSumario: false,
        aulaidlvl2: 0,
        tituloAula: "",
        objetivosAula: "",
        tipoQuestionario: "",
        tipoAvaliacao: "",
        descricaoAula: "",
        ordemAula: "",
        ordemAulalvl2: 0,
        estadosModulo: [],
        inicioModulo: "",
        terminoModulo: "",
        objetivosModulo: "",
        tipoAula: "",
        recursoAula: "",
        duracaoAula: null,
        aulalvl2: false,
        dt_modulo_inicio: "",
        dt_modulo_fim: "",
        //AULA TIPO VIDEO
        video: [],
        videoLista: [],
        video_thumbnail: [],
        transcricaoPT: [],
        transcricaoPTId: 0,
        transcricaoBR: [],
        transcricaoBRId: 0,
        transcricaoEN: [],
        transcricaoENId: 0,
        transcricaoES: [],
        transcricaoESId: 0,
        //AULA TIPO AUDIO
        audio: [],
        audioLista: [],
        //AULA TIPO PDF
        pdf: [],
        pdfLista: [],
        //AULA TIPO DOWNLOAD
        download: [],
        downloadLista: [],
        //AULA TIPO HTML
        paginaHTML: false,
        //setHTML: false,
        resetEditor: false,
        //AULA TIPO QUESTIONARIO OU AVALIACAO
        questionario: false,
        questoes: [],
        reset: false,
        timer: false,
        dt_inicioAula: null,
        horAula: "",
        gerar_aleatorio: false,
        permitir_exportar: false,
        atribuir_classificacao: true,
        permissaoAula: 1,
        avaliacao_competencia: false,
        baralhar_questoes: false,
        baralhar_opcoes: false,
        //AULA TIPO LINK
        link: "",
        link_embed: false,
        //AULA TIPO TRABALHO
        enunciado: [],
        enunciadoLista: [],
        dt_entrega_inicoAula: null,
        dt_fimAula: null,
        tipo_trabalhoAula: "",
        numero_elementosAula: 2,
        //AULA TIPO SINCRONO e PRESENCIAL
        horaAula: "",
        horaFimAula: "",
        horaFimAula: "",
        codigoAula: "",
        localAula: "",
        editar: false,
        //DRAWER ADD RECURSOS
        recurosid: 0,
        drawerAddRecursos: false,
        //DRAWER PRESENCIAL
        drawerPresencialVisible: false,
        nomePresencial: "",
        dataPresencial: null,
        horaPresencial: "",
        localPresencial: "",
        //DRAWER PRESENCIAL
        drawerSincronaVisible: false,
        nomeSincrona: "",
        dataSincrona: null,
        horaSincrona: "",
        ////DRAWER PRESENÇAS
        //falta: false,
        //presenca: false,
        //presencas: [],
        //participantes: [],
        //loadingParticipantes: false,
        //PERMISSOES
        loading: false,
        //COPIAR/COLAR
        id_copia: 0,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //FILTROS
        excluirTodos: false,
        pesquisa: "",
        //DRAWER PRESENÇAS
        folhaPresencasId: 0,
        drawerPresencasVisible: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoid: cursoId,
            turma: this.props.match.params.turma
        });

        if (this.context.lista_modulos.length === 0)
            this.listarModulos(cursoId);
        else
            this.setState({
                modulos: this.context.lista_modulos,
                proximaPosicaoModulo: this.context.lista_modulos[this.context.lista_modulos.length - 1].ordem + 1
            });
    }

    handlerChangeModulo = modulo => {
        this.setState({ modulo });
    };

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeCheckTimer = event => {
        this.setState({ timer: event.target.checked });
    };

    handlerChangeCheck = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    //handlerChangeCheckboxPresencaColetiva = event => {
    //    this.setState({
    //        [event.target.name]: event.target.checked,
    //        falta: false,
    //        presencas: event.target.checked ? this.state.participantes.map(participante => participante.id) : []
    //    });
    //};

    //handlerChangeCheckboxFaltaColetiva = event => {
    //    this.setState({
    //        [event.target.name]: event.target.checked,
    //        presenca: false,
    //        presencas: []
    //    });
    //};

    //handlerChangeCheckboxPresencas = presencas => {
    //    this.setState({ presencas });
    //};

    handlerChangeExcluirTodos = event => {
        this.setState(
            {
                [event.target.name]: event.target.checked
            },
            () => this.listarModulos(this.state.cursoid)
        );
    };

    handlerChangeSelectTipo = value => {
        this.setState({ tipoAula: value });
    };

    handlerChangeSelectTipoQuestionario = value => {
        this.setState({ tipoQuestionario: value });
    };

    handlerChangeSelectTipoAvaliacao = value => {
        this.setState({ tipoAvaliacao: value });
    };

    handlerChangeSelectTipoTrabalho = value => {
        this.setState({ tipo_trabalhoAula: value });
    };

    handlerChangeRadio = event => {
        this.setState({ permissaoAula: event.target.value });
    };

    handleChangeEditor = value => {
        this.setState({
            recursoAula: value,
            paginaHTML: true
        });
    };

    handlerChangeNumberAula = value => {
        this.setState({ ordemAula: value });
    };

    handleChangeTime = value => {
        this.setState({ duracaoAula: value });
    };

    handleChangeHora = value => {
        this.setState({ horaAula: value });
    };

    handleChangeHoraFim = value => {
        this.setState({ horaFimAula: value });
    };

    handlerChangeNumberRepeticoes = value => {
        this.setState({ nr_repeticoes: value });
    };

    handleChangeDataInicio = date => {
        this.setState({ dt_inicioAula: date });
    };

    handleChangeDataFim = date => {
        this.setState({ dt_fimAula: date });
    };

    handlerChangeNumberNrElementos = value => {
        this.setState({ numero_elementosAula: value });
    };

    //CARREGA E LISTA TODOS OS MODULOS PERTENCENTES AO CURSO E IDENTIFICA A ULTIMA POSIÇÃO NA ORDEM
    listarModulos = cursoid => {
        this.setState({
            loading: true
        });

        axios({
            method: "get",
            url: "/api/gerir-curso-modulo/gerir-listar-modulos",
            params: {
                cursoid: cursoid,
                excluirTodos: this.state.excluirTodos,
                pesquisa: this.state.pesquisa
            }
        })
            .then(response => {
                if (response.data.length > 0) {
                    this.context.atualizarState({
                        lista_modulos: response.data
                    });

                    this.setState({
                        modulos: response.data,
                        proximaPosicaoModulo: response.data[response.data.length - 1].ordem + 1,
                        loading: false
                    });
                } else
                    this.setState({
                        loading: false
                    });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarModulo = moduloid => {
        axios({
            method: "get",
            url: "/api/gerir-curso-modulo/gerir-listar-modulo",
            params: {
                cursoid: localStorage.getItem("codigo_curso"),
                moduloid
            }
        })
            .then(response => {
                var moduloIndex = this.state.modulos.findIndex(x => x.id === moduloid);
                var _modulos = this.state.modulos;
                _modulos[moduloIndex] = response.data;

                this.setState({
                    modulos: _modulos
                });
            })
            .catch(() => {});
    };

    //DRAWER MODULO
    //ATUALIZA LISTA
    atualizar = () => {
        this.listarModulos(this.state.cursoid);
        this.setState({
            drawerModuloVisible: false
        });
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DO MODULO QUE ABRIMOS O DETALHE
    carregarModulo = (moduloid, existeCompetencias) => {
        this.setState({
            moduloid,
            existeCompetencias,
            drawerModuloVisible: true
        });
    };

    //EXCLUI O MODULO
    excluirModulo = moduloid => {
        confirm({
            title: "Pretende excluir esta atividade?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gerir-curso-modulo/excluir-modulo",
                    params: {
                        moduloid: moduloid
                    }
                })
                    .then(() => {
                        var modulos = this.state.modulos;
                        var moduloIndex = modulos.findIndex(x => x.id === moduloid);
                        modulos.splice(moduloIndex, 1);
                        this.setState({
                            modulos
                        });

                        openNotificationWithIcon("success", "Sucesso", "Atividade excluida!");
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    //ATIVAR/INATIVAR O MODULO
    ativarModulo = (moduloid, ativo) => {
        confirm({
            title: ativo ? "Pretende ocultar esta atividade?" : "Pretende mostrar esta atividade?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gerir-curso-modulo/ativar-modulo",
                    params: {
                        moduloid
                    }
                })
                    .then(() => {
                        var _modulos = this.state.modulos;
                        var moduloIndex = _modulos.findIndex(x => x.id === moduloid);
                        _modulos[moduloIndex].ativo = !ativo;

                        var totalSubAulas = 0;
                        _modulos[moduloIndex].modulo_Aula.forEach(aula => {
                            totalSubAulas = totalSubAulas + aula.modulo_Aula_lvl2.length;
                        });

                        if (ativo)
                            _modulos[moduloIndex].modulo_Aula.forEach(aula => {
                                aula.ativo = false;

                                aula.modulo_Aula_lvl2.forEach(sub => {
                                    sub.ativo = false;
                                });
                            });

                        this.setState({
                            modulos: _modulos
                        });

                        if (ativo) openNotificationWithIcon("success", "Sucesso", "Atividade ocultada!");
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível ocultar a atividade!" : "Não foi possível mostrar a atividade!"
                        );
                    });
            }
        });
    };

    //ABRE O DRAWER PARA SE CRIAR UM NOVO MODULO
    onOpenDrawerModulo = () => {
        this.setState({
            drawerModuloVisible: true
        });
    };

    //DRAWER AULA
    //CRIA UMA NOVA AULA
    criarAula = moduloId => {
        this.listarModulo(moduloId);
        this.setState({
            drawerAulaVisible: false
        });
    };

    carregarAula = (moduloid, aulaid, aulalvl2) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                var modulo = this.state.modulos.find(x => x.id === moduloid);
                var tarefaPai = "";
                if (aulalvl2) tarefaPai = modulo.modulo_Aula.find(x => x.id === response.data.id_modulo_aula);

                this.setState({
                    moduloid,
                    //existeCompetencias,
                    aulaid: !aulalvl2 ? aulaid : response.data.id_modulo_aula,
                    aulaidlvl2: aulalvl2 ? aulaid : 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    descricaoAula: response.data.descricao,
                    ordemAula: response.data.ordem,
                    tipoAula: response.data.id_tipo_recurso,
                    aulaTipo: aulalvl2 ? tarefaPai.tipo_Recurso.id : "",
                    recursoAula: response.data.recurso,
                    duracaoAula: response.data.duracao ? moment(response.data.duracao, "HH:mm") : null,
                    dt_inicioAula: response.data.dt_inicio ? moment(response.data.dt_inicio) : null,
                    horaAula: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                    dt_fimAula: response.data.dt_fim ? moment(response.data.dt_fim) : null,
                    drawerAulaVisible: true,
                    reset: true,
                    link: response.data.recurso,
                    link_embed: response.data.link_embed,
                    codigoAula: response.data.recurso,
                    localAula: response.data.recurso,
                    editar: true,
                    aulalvl2,
                    dt_modulo_inicio: modulo.dt_inicio,
                    dt_modulo_fim: modulo.dt_termino
                });
            })
            .catch(() => {});
    };

    carregarAulaVideo = (moduloid, aulaid, aulalvl2) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula-video",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                //var video = [];
                var transcricaoPT = [];
                var transcricaoPTId = 0;
                var transcricaoBR = [];
                var transcricaoBRId = 0;
                var transcricaoEN = [];
                var transcricaoENId = 0;
                var transcricaoES = [];
                var transcricaoESId = 0;

                response.data.modulo_Aula_Linguas.forEach(lingua => {
                    if (lingua.id_lingua === 1 && lingua.transcricao) {
                        transcricaoPT.push({
                            uid: "1",
                            name: lingua.transcricao_nome,
                            status: "done",
                            legendaid: lingua.transcricao
                            //url: lingua.transcricao
                        });
                        transcricaoPTId = lingua.transcricao;
                    }
                    if (lingua.id_lingua === 2 && lingua.transcricao) {
                        transcricaoBR.push({
                            uid: "1",
                            name: lingua.transcricao_nome,
                            status: "done",
                            legendaid: lingua.transcricao
                            //url: lingua.transcricao
                        });
                        transcricaoBRId = lingua.transcricao;
                    }
                    if (lingua.id_lingua === 3 && lingua.transcricao) {
                        transcricaoEN.push({
                            uid: "1",
                            name: lingua.transcricao_nome,
                            status: "done",
                            legendaid: lingua.transcricao
                            //url: lingua.transcricao
                        });
                        transcricaoENId = lingua.transcricao;
                    }
                    if (lingua.id_lingua === 4 && lingua.transcricao) {
                        transcricaoES.push({
                            uid: "1",
                            name: lingua.transcricao_nome,
                            status: "done",
                            legendaid: lingua.transcricao
                            //url: lingua.transcricao
                        });
                        transcricaoESId = lingua.transcricao;
                    }
                });

                //video.push({
                //    uid: "-1",
                //    name: response.data.recurso_nome,
                //    status: "done"
                //    //url: response.data.recurso
                //});

                this.setState({
                    moduloid,
                    aulaid: !aulalvl2 ? aulaid : response.data.id_modulo_aula,
                    aulaidlvl2: aulalvl2 ? aulaid : 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    ordemAula: response.data.ordem,
                    tipoAula: response.data.id_tipo_recurso,
                    recurso: response.data.recurso,
                    duracaoAula: response.data.duracao ? moment(response.data.duracao, "HH:mm") : null,
                    video: [],
                    videoLista: [response.data.recurso_nome],
                    transcricaoPT,
                    transcricaoPTId,
                    transcricaoBR,
                    transcricaoBRId,
                    transcricaoEN,
                    transcricaoENId,
                    transcricaoES,
                    transcricaoESId,
                    drawerAulaVisible: true,
                    reset: true,
                    editar: true,
                    aulalvl2
                });
            })
            .catch(() => {});
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DA AULA QUE ABRIMOS O DETALHE
    carregarAulaAudio = (moduloid, aulaid, aulalvl2) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    moduloid,
                    aulaid: !aulalvl2 ? aulaid : response.data.id_modulo_aula,
                    aulaidlvl2: aulalvl2 ? aulaid : 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    ordemAula: response.data.ordem,
                    tipoAula: response.data.id_tipo_recurso,
                    recursoAula: response.data.recurso,
                    duracaoAula: response.data.duracao ? moment(response.data.duracao, "HH:mm") : null,
                    audio: [],
                    audioLista: [response.data.recurso_nome],
                    drawerAulaVisible: true,
                    reset: true,
                    editar: true,
                    aulalvl2
                });
            })
            .catch(() => {});
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DA AULA QUE ABRIMOS O DETALHE
    carregarAulaHtml = (moduloid, aulaid, aulalvl2) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    paginaHTML: true
                });

                this.setState({
                    moduloid,
                    aulaid: !aulalvl2 ? aulaid : response.data.id_modulo_aula,
                    aulaidlvl2: aulalvl2 ? aulaid : 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    ordemAula: response.data.ordem,
                    tipoAula: response.data.id_tipo_recurso,
                    recursoAula: response.data.recurso,
                    duracaoAula: response.data.duracao ? moment(response.data.duracao, "HH:mm") : null,
                    drawerAulaVisible: true,
                    reset: true,
                    editar: true,
                    aulalvl2
                });
            })
            .catch(() => {});
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DA AULA QUE ABRIMOS O DETALHE
    carregarAulaPdf = (moduloid, aulaid, aulalvl2) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    moduloid,
                    aulaid: !aulalvl2 ? aulaid : response.data.id_modulo_aula,
                    aulaidlvl2: aulalvl2 ? aulaid : 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    ordemAula: response.data.ordem,
                    tipoAula: response.data.id_tipo_recurso,
                    recursoAula: response.data.recurso,
                    duracaoAula: response.data.duracao ? moment(response.data.duracao, "HH:mm") : null,
                    pdf: [],
                    pdfLista: [response.data.recurso_nome],
                    drawerAulaVisible: true,
                    reset: true,
                    editar: true,
                    aulalvl2
                });
            })
            .catch(() => {});
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DA AULA QUE ABRIMOS O DETALHE
    carregarAulaQuestionario = (moduloid, existeCompetencias, aulaid, aulalvl2) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                var questoes = [];
                var modulo = this.state.modulos.find(x => x.id === moduloid);

                if (response.data.questoes.length > 0)
                    response.data.questoes.forEach(questao => {
                        var opcoes = [];
                        var ficheiro_pergunta = [];
                        var ficheiro_resolucao = [];

                        if (questao.ficheiro_pergunta)
                            ficheiro_pergunta.push({
                                uid: "1",
                                name: questao.ficheiro_nome_pergunta,
                                status: "done",
                                url: questao.ficheiro_pergunta
                            });

                        if (questao.ficheiro_resolucao)
                            ficheiro_resolucao.push({
                                uid: "1",
                                name: questao.ficheiro_nome_resolucao,
                                status: "done",
                                url: questao.ficheiro_resolucao
                            });

                        questao.opcoes.forEach(opcao => {
                            opcoes.push({
                                id: opcao.id,
                                texto: opcao.texto,
                                ordem: opcao.ordem,
                                verdadeiro: opcao.verdadeiro
                            });
                        });

                        if (questao.opcoes.length === 0)
                            if (questao.tipo_pergunta === "Escolha única" || questao.tipo_pergunta === "Escolha múltipla")
                                opcoes.push({
                                    id: 0,
                                    texto: "",
                                    ordem: 1,
                                    verdadeiro: false
                                });

                        questoes.push({
                            id: questao.id,
                            pergunta: questao.pergunta,
                            ficheiro_pergunta: ficheiro_pergunta,
                            tipo_pergunta: questao.tipo_pergunta,
                            ordem: questao.ordem,
                            pontuacao: questao.pontuacao,
                            texto_resolucao: questao.texto_resolucao,
                            ficheiro_resolucao: ficheiro_resolucao,
                            opcoes: opcoes
                        });
                    });
                else
                    questoes.push({
                        id: 0,
                        pergunta: "",
                        ficheiro_pergunta: [],
                        tipo_pergunta: 0,
                        ordem: 1,
                        pontuacao: "",
                        texto_resolucao: "",
                        ficheiro_resolucao: [],
                        opcoes: [
                            {
                                id: 0,
                                texto: "",
                                ordem: 1,
                                verdadeiro: false
                            }
                        ]
                    });

                var _id_tipo_recurso = response.data.id_tipo_recurso;
                var _id_tipo_questionario = 0;
                if (_id_tipo_recurso === 5 || _id_tipo_recurso === 14 || _id_tipo_recurso === 15) {
                    _id_tipo_questionario = _id_tipo_recurso;
                    _id_tipo_recurso = 5;
                }

                //var _id_tipo_avaliacao = 0;
                //if (_id_tipo_recurso === 6 || _id_tipo_recurso === 16) {
                //    _id_tipo_avaliacao = _id_tipo_recurso;
                //    _id_tipo_recurso = 6;
                //}

                this.setState({
                    moduloid,
                    existeCompetencias,
                    aulaid: !aulalvl2 ? aulaid : response.data.id_modulo_aula,
                    aulaidlvl2: aulalvl2 ? aulaid : 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    ordemAula: response.data.ordem,
                    tipoAula: _id_tipo_recurso,
                    tipoQuestionario: _id_tipo_questionario,
                    //tipoAvaliacao: _id_tipo_avaliacao,
                    recursoAula: response.data.recurso,
                    duracaoAula: response.data.duracao ? moment(response.data.duracao, "HH:mm") : null,
                    timer: response.data.timer,
                    dt_inicioAula: response.data.dt_inicio ? moment(response.data.dt_inicio) : null,
                    horaAula: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                    questionario: response.data.questoes.length > 0 ? true : false,
                    permitir_exportar: response.data.permitir_exportar,
                    atribuir_classificacao: response.data.atribuir_classificacao,
                    avaliacao_competencia: response.data.avaliacao_competencia,
                    questoes,
                    drawerAulaVisible: true,
                    reset: true,
                    editar: true,
                    aulalvl2,
                    dt_modulo_inicio: modulo.dt_inicio,
                    dt_modulo_fim: modulo.dt_termino,
                    permissaoAula: response.data.permissao,
                    baralhar_questoes: response.data.baralhar_questoes,
                    baralhar_opcoes: response.data.baralhar_opcoes
                });
            })
            .catch(() => {});
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DA AULA QUE ABRIMOS O DETALHE
    carregarAulaDownload = (moduloid, aulaid, aulalvl2) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    moduloid,
                    aulaid: !aulalvl2 ? aulaid : response.data.id_modulo_aula,
                    aulaidlvl2: aulalvl2 ? aulaid : 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    ordemAula: response.data.ordem,
                    tipoAula: response.data.id_tipo_recurso,
                    recursoAula: response.data.recurso,
                    duracaoAula: response.data.duracao ? moment(response.data.duracao, "HH:mm") : null,
                    download: [],
                    downloadLista: [response.data.recurso_nome],
                    drawerAulaVisible: true,
                    reset: true,
                    editar: true,
                    aulalvl2
                });
            })
            .catch(() => {});
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DA AULA QUE ABRIMOS O DETALHE
    carregarAulaTrabalho = (moduloid, aulaid, aulalvl2) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                var enunciadoLista = [];
                var modulo = this.state.modulos.find(x => x.id === moduloid);

                if (response.data.id_tipo_recurso === 8 && response.data.recurso) {
                    enunciadoLista = [response.data.recurso_nome];
                }

                this.setState({
                    moduloid,
                    aulaid: !aulalvl2 ? aulaid : response.data.id_modulo_aula,
                    aulaidlvl2: aulalvl2 ? aulaid : 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    descricaoAula: response.data.descricao,
                    ordemAula: response.data.ordem,
                    tipoAula: response.data.id_tipo_recurso,
                    recursoAula: response.data.recurso,
                    duracaoAula: response.data.duracao ? moment(response.data.duracao, "HH:mm") : null,
                    dt_inicioAula: response.data.dt_inicio ? moment(response.data.dt_inicio) : null,
                    horaAula: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                    dt_fimAula: response.data.dt_fim ? moment(response.data.dt_fim) : null,
                    tipo_trabalhoAula: response.data.tipo_trabalho,
                    numero_elementosAula: response.data.numero_elementos,
                    avaliacao_competencia: response.data.avaliacao_competencia,
                    enunciado: [],
                    enunciadoLista,
                    drawerAulaVisible: true,
                    reset: true,
                    editar: true,
                    aulalvl2,
                    dt_modulo_inicio: modulo.dt_inicio,
                    dt_modulo_fim: modulo.dt_termino
                });
            })
            .catch(() => {});
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DA AULA QUE ABRIMOS O DETALHE
    carregarAulaPasta = (moduloid, aulaid) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula-pasta",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    moduloid,
                    aulaid: aulaid,
                    aulaidlvl2: 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    ordemAula: response.data.ordem,
                    tipoAula: response.data.id_tipo_recurso,
                    drawerAulaVisible: true,
                    reset: true,
                    editar: true
                });
            })
            .catch(() => {});
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DA AULA QUE ABRIMOS O DETALHE
    carregarAulaPresencas = (moduloid, aulaid, aulalvl2) => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-aula-presencas",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                var dados = JSON.parse(response.data.recurso);

                this.setState({
                    moduloid,
                    aulaid: !aulalvl2 ? aulaid : response.data.id_modulo_aula,
                    aulaidlvl2: aulalvl2 ? aulaid : 0,
                    tituloAula: response.data.nome,
                    objetivosAula: response.data.objetivos ? response.data.objetivos : "",
                    ordemAula: response.data.ordem,
                    //dt_inicioAula: response.data.dt_inicio ? moment(response.data.dt_inicio) : null,
                    //horaAula: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                    //horaFimAula: response.data.hora_fim ? moment(response.data.hora_fim, "HH:mm") : null,
                    tipoAula: response.data.id_tipo_recurso,
                    //presenca: dados.presenca_coletiva,
                    //falta: dados.falta_coletiva,
                    //presencas: dados.presencas,
                    drawerAulaVisible: true,
                    reset: true,
                    editar: true,
                    aulalvl2
                });
            })
            .catch();
    };

    //EXCLUI A AULA
    excluirAula = (moduloIndex, aulaid) => {
        confirm({
            title: "Pretende excluir este conteúdo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/curso-aula/excluir-aula",
                    params: {
                        aulaid: aulaid
                    }
                })
                    .then(() => {
                        //ATUALIZA AS AULAS NO MODULO APOS EXCLUIR
                        var modulo = this.state.modulos[moduloIndex];

                        const aula = modulo.modulo_Aula.find(x => x.id === aulaid);
                        modulo.modulo_Aula = modulo.modulo_Aula.filter(x => x.id !== aulaid);

                        this.setState({
                            modulo
                        });

                        if (aula.tipo_Recurso.id === 6 || aula.tipo_Recurso.id === 8 || aula.tipo_Recurso.id === 11)
                            this.context.atualizarEventosHoje();

                        openNotificationWithIcon("success", "Sucesso", "Conteúdo excluido!");
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    excluirAulaUnica = (moduloId, aulaid) => {
        confirm({
            title: "Pretende excluir este conteúdo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/curso-aula/excluir-aula",
                    params: {
                        aulaid: aulaid
                    }
                })
                    .then(() => {
                        this.listarModulo(moduloId);

                        openNotificationWithIcon("success", "Sucesso", "Conteúdo excluido!");
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    //EXCLUI A AULA
    excluirVideoVimeo = (moduloIndex, aulaid, videoId) => {
        confirm({
            title: "Pretende excluir esta tarefa?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "get",
                    url: "/api/curso/validar-excluir-vimeo",
                    params: {
                        videoId
                    }
                })
                    .then(response => {
                        if (response.data) this.excluirAulaVideo(moduloIndex, aulaid);
                        else
                            axios({
                                method: "delete",
                                url: `https://api.vimeo.com/videos/${videoId}`,
                                transformRequest: [
                                    function (data, headers) {
                                        delete headers.common["apikey"];

                                        return JSON.stringify(data);
                                    }
                                ],
                                headers: headerPost
                            }).then(() => {
                                this.excluirAulaVideo(moduloIndex, aulaid);
                            });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a tarefa!");
                    });
            }
        });
    };

    excluirAulaVideo = (moduloIndex, aulaid) => {
        axios({
            method: "delete",
            url: "/api/curso-aula/excluir-aula",
            params: {
                aulaid: aulaid
            }
        })
            .then(() => {
                //ATUALIZA AS AULAS NO MODULO APOS EXCLUIR
                var modulo = this.state.modulos[moduloIndex];
                modulo.modulo_Aula = modulo.modulo_Aula.filter(x => x.id !== aulaid);

                this.setState({
                    modulo
                });

                openNotificationWithIcon("success", "Sucesso", "Tarefa excluida!");
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    };

    //ATIVAR/INATIVAR A AULA
    ativarAula = (moduloId, moduloIndex, aulaid, ativo, pagina_unica) => {
        confirm({
            title: ativo ? "Pretende ocultar esta tarefa?" : "Pretende mostrar esta tarefa?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/curso/ativar-aula",
                    params: {
                        aulaid: aulaid
                    }
                })
                    .then(() => {
                        if (pagina_unica) {
                            this.listarModulo(moduloId);
                        } else {
                            var _modulos = this.state.modulos;
                            var aulaIndex = _modulos[moduloIndex].modulo_Aula.findIndex(x => x.id === aulaid);
                            _modulos[moduloIndex].modulo_Aula[aulaIndex].ativo = !ativo;

                            if (ativo)
                                _modulos[moduloIndex].modulo_Aula[aulaIndex].modulo_Aula_lvl2.forEach(sub => {
                                    sub.ativo = false;
                                });

                            this.setState({
                                modulos: _modulos
                            });
                        }

                        if (ativo) openNotificationWithIcon("success", "Sucesso", "Tarefa ocultada!");
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível ocultar a tarefa!" : "Não foi possível mostrar a tarefa!"
                        );
                    });
            }
        });
    };

    //ATIVAR/INATIVAR A AULA
    ativarAulaUnica = (moduloId, aulaid, ativo) => {
        confirm({
            title: ativo ? "Pretende ocultar esta tarefa?" : "Pretende mostrar esta tarefa?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/curso/ativar-aula",
                    params: {
                        aulaid: aulaid
                    }
                })
                    .then(() => {
                        this.listarModulo(moduloId);

                        if (ativo) openNotificationWithIcon("success", "Sucesso", "Tarefa ocultada!");
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível ocultar a tarefa!" : "Não foi possível mostrar a tarefa!"
                        );
                    });
            }
        });
    };

    //EXCLUI A AULA
    excluirAulaLvl2 = (moduloIndex, aulaLvl1Index, aulaid) => {
        confirm({
            title: "Pretende excluir este conteúdo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/curso-aula/excluir-aula",
                    params: {
                        aulaid: aulaid
                    }
                })
                    .then(() => {
                        //ATUALIZA AS AULAS NO MODULO APOS EXCLUIR
                        var aulas = this.state.modulos[moduloIndex].modulo_Aula[aulaLvl1Index];
                        aulas.modulo_Aula_lvl2 = aulas.modulo_Aula_lvl2.filter(x => x.id !== aulaid);

                        this.setState({
                            aulas
                        });

                        openNotificationWithIcon("success", "Sucesso", "Conteúdo excluido!");
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o conteúdo!");
                    });
            }
        });
    };

    excluirVideoVimeoLvl2 = (moduloIndex, aulaLvl1Index, aulaid, videoId) => {
        confirm({
            title: "Pretende excluir esta tarefa?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: `https://api.vimeo.com/videos/${videoId}`,
                    transformRequest: [
                        function (data, headers) {
                            delete headers.common["apikey"];

                            return JSON.stringify(data);
                        }
                    ],
                    headers: headerPost
                }).then(() => {
                    this.excluirAulaVideoLvl2(moduloIndex, aulaLvl1Index, aulaid);
                });
            }
        });
    };

    //EXCLUI A AULA
    excluirAulaVideoLvl2 = (moduloIndex, aulaLvl1Index, aulaid) => {
        axios({
            method: "delete",
            url: "/api/curso-aula/excluir-aula",
            params: {
                aulaid: aulaid
            }
        })
            .then(() => {
                //ATUALIZA AS AULAS NO MODULO APOS EXCLUIR
                var aulas = this.state.modulos[moduloIndex].modulo_Aula[aulaLvl1Index];
                //var aulaIndex = aulas.modulo_Aula_lvl2.findIndex(x => x.id == aulaid);
                //aulas.modulo_Aula_lvl2.splice(aulaIndex, 1);
                aulas.modulo_Aula_lvl2 = aulas.modulo_Aula_lvl2.filter(x => x.id !== aulaid);

                this.setState({
                    aulas
                });

                openNotificationWithIcon("success", "Sucesso", "Conteúdo excluido!");
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível excluir o conteúdo!");
            });
    };

    //ATIVAR/INATIVAR A AULA
    ativarAulaLvl2 = (moduloIndex, aulaLvl1Index, aulaid, ativo) => {
        confirm({
            title: ativo ? "Pretende ocultar esta tarefa?" : "Pretende mostrar esta tarefa?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/curso/ativar-aula",
                    params: {
                        aulaid: aulaid
                    }
                })
                    .then(() => {
                        var _modulos = this.state.modulos;
                        var aulaIndex = _modulos[moduloIndex].modulo_Aula[aulaLvl1Index].modulo_Aula_lvl2.findIndex(x => x.id === aulaid);
                        _modulos[moduloIndex].modulo_Aula[aulaLvl1Index].modulo_Aula_lvl2[aulaIndex].ativo = !ativo;
                        this.setState({
                            modulos: _modulos
                        });

                        if (ativo) openNotificationWithIcon("success", "Sucesso", "Tarefa ocultada!");
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível ocultar a tarefa!" : "Não foi possível mostrar a tarefa!"
                        );
                    });
            }
        });
    };

    adicionarQuestionarioAvaliacao = () => {
        this.setState({
            questoes: [
                {
                    pergunta: "",
                    ficheiro_pergunta: [],
                    tipo_pergunta: undefined,
                    ordem: 1,
                    texto_resolucao: "",
                    ficheiro_resolucao: [],
                    opcoes: []
                }
            ]
        });
    };

    //GUARDA AS NOVAS RESPOSTAS AO QUESTIONARIO
    alterarQuestionario = questoes => {
        this.setState({
            questionario: questoes.length > 0 ? true : false,
            questoes: questoes
        });
    };

    //GUARDA AS NOVAS RESPOSTAS AO QUESTIONARIO
    alterarAvaliacao = questoes => {
        this.setState({
            questionario: questoes.length > 0 ? true : false,
            questoes: questoes
        });
    };

    //GUARDA AS NOVAS RESPOSTAS AO QUESTIONARIO
    alterarQuestProgresso = questoes => {
        this.setState({
            questionario: questoes.length > 0 ? true : false,
            questoes: questoes
        });
    };

    //ABRE O DRAWER PARA SE CRIAR UMA NOVA AULA
    onOpenDrawerAula = (moduloid, ordemAula, existeCompetencias) => {
        var modulo = this.state.modulos.find(x => x.id === moduloid);

        this.setState({
            moduloid: moduloid,
            existeCompetencias,
            aulaid: 0,
            tituloAula: "",
            objetivosAula: "",
            ordemAula: ordemAula,
            tipoAula: undefined,
            subTarefaAssiduidade: false,
            subTarefaSumario: false,
            tipoQuestionario: undefined,
            tipoAvaliacao: undefined,
            descricaoAula: "",
            recursoAula: "",
            duracaoAula: null,
            dt_inicioAula: null,
            dt_fimAula: null,
            aulalvl2: false,
            video: [],
            videoLista: [],
            video_thumbnail: [],
            audio: [],
            audioLista: [],
            transcricaoPT: [],
            transcricaoBR: [],
            transcricaoEN: [],
            transcricaoES: [],
            pdf: [],
            pdfLista: [],
            download: [],
            downloadLista: [],
            paginaHTML: false,
            //setHTML: false,
            resetEditor: true,
            questionario: false,
            questoes: [
                {
                    pergunta: "",
                    ficheiro_pergunta: [],
                    tipo_pergunta: undefined,
                    ordem: 1,
                    pontuacao: "",
                    texto_resolucao: "",
                    ficheiro_resolucao: [],
                    opcoes: []
                }
            ],
            drawerAulaVisible: true,
            enunciado: [],
            enunciadoLista: [],
            dt_entrega_inicoAula: null,
            tipo_trabalhoAula: undefined,
            numero_elementosAula: 2,
            reset: true,
            timer: false,
            link: "",
            link_embed: false,
            horaAula: "",
            horaFimAula: "",
            codigoAula: "",
            localAula: "",
            editar: false,
            permitir_exportar: false,
            avaliacao_competencia: false,
            //atribuir_classificacao: true,
            dt_modulo_inicio: modulo.dt_inicio,
            dt_modulo_fim: modulo.dt_termino,
            permissaoAula: 1,
            baralhar_questoes: false,
            baralhar_opcoes: false
        });
    };

    //ABRE O DRAWER PARA SE CRIAR UMA NOVA AULA
    //onOpenDrawerAulalvl2 = (moduloid, existeCompetencias, aulaid, aulaTipo, ordemAula, subTarefaAssiduidade, subTarefaSumario) => {
    onOpenDrawerAulalvl2 = (moduloid, existeCompetencias, aula) => {
        var aulaid = aula.id;
        var aulaTipo = aula.tipo_Recurso.id;
        var ordemAula = aula.modulo_Aula_lvl2.length > 0 ? aula.modulo_Aula_lvl2[aula.modulo_Aula_lvl2.length - 1].ordem + 1 : 1;
        var subTarefaAssiduidade = aula.subTarefaAssiduidade;
        var subTarefaSumario = aula.subTarefaSumario;

        var tempDuracao = "";
        var tempData = "";
        var tempHora = "";
        var tempHoraFim = "";
        if (aulaTipo === 11) {
            tempDuracao = moment(aula.duracao, "HH:mm");
            tempData = moment(aula.dt_inicio);
            tempHora = moment(aula.hora, "HH:mm");
            tempHoraFim = moment(aula.hora, "HH:mm").add(tempDuracao.hours(), "hours").add(tempDuracao.minutes(), "minutes");
        }

        this.setState({
            moduloid: moduloid,
            existeCompetencias,
            aulaid: aulaid,
            aulaTipo: aulaTipo,
            aulaidlvl2: 0,
            tituloAula: "",
            objetivosAula: "",
            ordemAula: ordemAula,
            tipoAula: undefined,
            subTarefaAssiduidade,
            subTarefaSumario: false,
            tipoQuestionario: undefined,
            subTarefaSumario,
            descricaoAula: "",
            recursoAula: "",
            duracaoAula: null,
            dt_inicioAula: null,
            dt_fimAula: null,
            aulalvl2: true,
            videoLista: [],
            video_thumbnail: [],
            audio: [],
            audioLista: [],
            transcricaoPT: [],
            transcricaoBR: [],
            transcricaoEN: [],
            transcricaoES: [],
            pdf: [],
            pdfLista: [],
            download: [],
            downloadLista: [],
            paginaHTML: false,
            //TEMP
            tempDuracao,
            tempData,
            tempHora,
            tempHoraFim,
            //setHTML: false,
            resetEditor: true,
            questionario: false,
            questoes: [
                {
                    pergunta: "",
                    ficheiro_pergunta: [],
                    tipo_pergunta: undefined,
                    ordem: 1,
                    pontuacao: "",
                    texto_resolucao: "",
                    ficheiro_resolucao: [],
                    opcoes: []
                }
            ],
            drawerAulaVisible: true,
            enunciado: [],
            enunciadoLista: [],
            dt_entrega_inicoAula: null,
            tipo_trabalhoAula: undefined,
            numero_elementosAula: 2,
            reset: true,
            timer: false,
            link: "",
            link_embed: false,
            horaAula: "",
            horaFimAula: "",
            codigoAula: "",
            localAula: "",
            editar: false,
            permitir_exportar: false,
            avaliacao_competencia: false,
            ////DRAWER PRESENÇAS
            //falta: false,
            //presenca: false,
            //presencas: [],
            //participantes: [],
            //loadingParticipantes: false,
            //atribuir_classificacao: false,
            permissaoAula: 1,
            baralhar_questoes: false,
            baralhar_opcoes: false,
            //DRAWER PRESENÇAS
            drawerPresencasVisible: false
        });
    };

    //FECHA O DRAWER DA AULA(NOVO OU EDIÇÃO)
    onCloseDrawerAula = () => {
        this.setState({
            drawerAulaVisible: false
        });
    };

    //ABRE O DRAWER DE UM TRABALHO
    onOpenDrawerPresencial = aulaid => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-presencial",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    aulaid: aulaid,
                    drawerPresencialVisible: true,
                    nomePresencial: response.data.nome,
                    dataPresencial: moment(response.data.dt_inicio).format("DD/MM/YYYY"),
                    horaPresencial: response.data.hora,
                    localPresencial: response.data.recurso
                });
            })
            .catch(() => {});
    };

    //FECHA O DRAWER DE UM TRABALHO
    onCloseDrawerPresencial = () => {
        this.setState({
            drawerPresencialVisible: false
        });
    };

    //ABRE O DRAWER DE UM TRABALHO
    onOpenDrawerSincrona = aulaid => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-sincrona",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    aulaid: aulaid,
                    drawerSincronaVisible: true,
                    nomeSincrona: response.data.nome,
                    dataSincrona: moment(response.data.dt_inicio).format("DD/MM/YYYY"),
                    horaSincrona: response.data.hora
                });
            })
            .catch(() => {});
    };

    //FECHA O DRAWER DE UM TRABALHO
    onCloseDrawerSincrona = () => {
        this.setState({
            drawerSincronaVisible: false
        });
    };

    copiarTarefa = tarefaid => {
        this.context.atualizarState({
            id_copia_tarefa: tarefaid
        });
        //this.setState({
        //    id_copia: tarefaid
        //});
    };

    stopReset = () => {
        this.setState({
            reset: false
        });
    };

    carregarDataPrecedencia = precedenciaId => {
        if (precedenciaId > 0) {
            var modulo = this.state.modulos.find(x => x.id === precedenciaId);

            this.setState({
                dt_precedencia: modulo.dt_termino ? modulo.dt_termino : modulo.dt_inicio
            });
        } else
            this.setState({
                dt_precedencia: ""
            });
    };

    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value
                },
                () => this.listarModulos(this.state.cursoid)
            );
        }
    };

    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => this.listarModulos(this.state.cursoid)
        );
    };

    listarParticipantes = () => {
        this.setState(
            {
                loadingParticipantes: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/curso/presencas-participantes",
                    timeout: 60 * 10 * 1000,
                    params: {
                        cursoid: this.state.cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            participantes: response.data,
                            loadingParticipantes: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (
                <Link
                    to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar}
                    className="botao-secundario botao-secundario-redondo"
                    onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}
                    style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <Icon type="arrow-left" />
                </Link>
            );
        }
    };

    render() {
        const {
            cursoid,
            turma,
            moduloid,
            proximaPosicaoModulo,
            modulo_aberto,
            modulos,
            botoesVisiveis,
            //COPIAR/COLAR
            id_copia,
            //REDIRECT
            redirect,
            redirectLink,
            //FILTROS
            excluirTodos,
            pesquisa,
            //DRAWER PRESENÇAS
            folhaPresencasId,
            drawerPresencasVisible
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const acesso = this.context.acessos?.find(x => x.func === 18);

        return (
            <>
                <Acessos funcionalidadeId={18} />
                <GeralContext.Consumer>
                    {geralContext => {
                        return (
                            <div className="container container-body">
                                {this.state.loading ? (
                                    <div className="loading-data">
                                        <div className="loading" />
                                    </div>
                                ) : (
                                    <div className="curso-modulos">
                                        {this.state.botoesVisiveis ? (
                                            <div className="controlos controlos-fundo">
                                                <div className="bloco-esquerda">
                                                    <div>
                                                        {acesso?.create && (
                                                            <button
                                                                className="botao-principal botao-principal-redondo"
                                                                title="Nova Atividade"
                                                                onClick={() => this.setState({ moduloid: 0, drawerModuloVisible: true })}
                                                            >
                                                                +
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="controlo-search">
                                                        <BotaoProcurar
                                                            HandleKeyPress={this.HandleKeyPress}
                                                            resetCaixaProcura={this.resetCaixaProcura}
                                                            tem_placeholder
                                                            placeholder="Procurar nos Participantes"
                                                        />
                                                    </div>
                                                    <div className="controlo-checkbox">
                                                        <Checkbox
                                                            name="excluirTodos"
                                                            checked={excluirTodos}
                                                            onChange={this.handlerChangeExcluirTodos}
                                                        >
                                                            Excluir Partilha com Todos
                                                        </Checkbox>
                                                    </div>
                                                </div>
                                                <div className="bloco-direita">
                                                    <Link
                                                        to="#"
                                                        className="botao-icon-unlock"
                                                        title="Botões bloqueados"
                                                        onClick={() => this.setState({ botoesVisiveis: false })}
                                                        style={{ width: 40, height: 40 }}
                                                    >
                                                        <Icon type="unlock" />
                                                    </Link>
                                                    {this.montarBotaoVoltar()}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="controlos controlos-fundo" style={{ justifyContent: "flex-end" }}>
                                                <div>
                                                    <div className="bloco-direita">
                                                        {(acesso?.create || acesso?.update) && (
                                                            <Link
                                                                to="#"
                                                                className="botao-icon-lock"
                                                                title="Botões desbloqueados"
                                                                onClick={() => this.setState({ botoesVisiveis: true })}
                                                                style={{ width: 40, height: 40 }}
                                                            >
                                                                <Icon type="lock" />
                                                            </Link>
                                                        )}
                                                        {localStorage.getItem("opcao_voltar") != null && this.montarBotaoVoltar()}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {modulos.map((modulo, index) => (
                                            <Modulo
                                                key={index}
                                                turma={turma}
                                                id_copia={id_copia}
                                                cursoid={cursoid}
                                                index={index}
                                                modulo_aberto={modulo_aberto}
                                                modulo={modulo}
                                                botoesVisiveis={botoesVisiveis}
                                                carregarModulo={this.carregarModulo}
                                                excluirModulo={this.excluirModulo}
                                                ativarModulo={this.ativarModulo}
                                                onOpenDrawerAula={this.onOpenDrawerAula}
                                                onOpenDrawerAulalvl2={this.onOpenDrawerAulalvl2}
                                                carregarAula={this.carregarAula}
                                                excluirAula={this.excluirAula}
                                                excluirAulaUnica={this.excluirAulaUnica}
                                                excluirVideoVimeo={this.excluirVideoVimeo}
                                                ativarAula={this.ativarAula}
                                                ativarAulaUnica={this.ativarAulaUnica}
                                                excluirAulaLvl2={this.excluirAulaLvl2}
                                                excluirVideoVimeoLvl2={this.excluirVideoVimeoLvl2}
                                                ativarAulaLvl2={this.ativarAulaLvl2}
                                                onOpenDrawerAddRecursos={this.onOpenDrawerAddRecursos}
                                                onOpenDrawerPresencial={this.onOpenDrawerPresencial}
                                                onOpenDrawerSincrona={this.onOpenDrawerSincrona}
                                                excluirRecurso={this.excluirRecurso}
                                                disabled={!modulo.subItens}
                                                inscrito={!geralContext.inscricao || geralContext.inscrito}
                                                carregarAulaVideo={this.carregarAulaVideo}
                                                carregarAulaAudio={this.carregarAulaAudio}
                                                carregarAulaHtml={this.carregarAulaHtml}
                                                carregarAulaPdf={this.carregarAulaPdf}
                                                carregarAulaQuestionario={this.carregarAulaQuestionario}
                                                carregarAulaDownload={this.carregarAulaDownload}
                                                carregarAulaTrabalho={this.carregarAulaTrabalho}
                                                carregarAulaPasta={this.carregarAulaPasta}
                                                carregarAulaPresencas={this.carregarAulaPresencas}
                                                carregarPresencas={aulaid =>
                                                    this.setState({
                                                        folhaPresencasId: aulaid,
                                                        drawerPresencasVisible: true
                                                    })
                                                }
                                                copiarTarefa={this.copiarTarefa}
                                                listarModulo={this.listarModulo}
                                            />
                                        ))}
                                    </div>
                                )}
                                {/*{!geralContext.fechado && (*/}
                                {/*    <div className="opcoes-curso">*/}
                                {/*        {this.state.botoesVisiveis ? (*/}
                                {/*            <Link*/}
                                {/*                to="#"*/}
                                {/*                className="btn-bloquear"*/}
                                {/*                title="Botões bloqueados"*/}
                                {/*                onClick={() => this.setState({ botoesVisiveis: false })}*/}
                                {/*            >*/}
                                {/*                <i className="fas fa-lock"></i>*/}
                                {/*                Bloquear*/}
                                {/*            </Link>*/}
                                {/*        ) : (*/}
                                {/*            <Link*/}
                                {/*                to="#"*/}
                                {/*                className="btn-desbloquear"*/}
                                {/*                title="Botões desbloqueados"*/}
                                {/*                onClick={() => this.setState({ botoesVisiveis: true })}*/}
                                {/*                >*/}
                                {/*                    <i className="fas fa-unlock"></i>*/}
                                {/*                    Desbloquear*/}
                                {/*            </Link>*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                <ModuloDrawer
                                    cursoid={cursoid}
                                    moduloid={moduloid}
                                    dt_precedencia={this.state.dt_precedencia}
                                    proximaPosicaoModulo={proximaPosicaoModulo}
                                    onClose={() => this.setState({ drawerModuloVisible: false })}
                                    visible={this.state.drawerModuloVisible}
                                    atualizar={this.atualizar}
                                    carregarDataPrecedencia={this.carregarDataPrecedencia}
                                />
                                <AulaDrawer
                                    {...this.state}
                                    tituloAula={this.state.tituloAula}
                                    objetivosAula={this.state.objetivosAula}
                                    ordem={this.state.ordemAula}
                                    tipoAula={this.state.tipoAula}
                                    tipoQuestionario={this.state.tipoQuestionario}
                                    tipoAvaliacao={this.state.tipoAvaliacao}
                                    criarAula={this.criarAula}
                                    onClose={this.onCloseDrawerAula}
                                    adicionarQuestionarioAvaliacao={this.adicionarQuestionarioAvaliacao}
                                    alterarQuestionario={this.alterarQuestionario}
                                    alterarAvaliacao={this.alterarAvaliacao}
                                    alterarQuestProgresso={this.alterarQuestProgresso}
                                    alterarPaginaHTML={this.alterarPaginaHTML}
                                    handlerChange={this.handlerChange}
                                    handleChangeEditor={this.handleChangeEditor}
                                    handlerChangeNumber={this.handlerChangeNumberAula}
                                    handlerChangeNumberRepeticoes={this.handlerChangeNumberRepeticoes}
                                    handleChangeTime={this.handleChangeTime}
                                    handleChangeHora={this.handleChangeHora}
                                    handleChangeHoraFim={this.handleChangeHoraFim}
                                    handlerChangeSelectTipo={this.handlerChangeSelectTipo}
                                    handlerChangeSelectTipoQuestionario={this.handlerChangeSelectTipoQuestionario}
                                    handlerChangeSelectTipoAvaliacao={this.handlerChangeSelectTipoAvaliacao}
                                    handlerChangeSelectTipoTrabalho={this.handlerChangeSelectTipoTrabalho}
                                    handlerChangeCheckRepeticoes={this.handlerChangeCheckRepeticoes}
                                    handlerChangeCheckTimer={this.handlerChangeCheckTimer}
                                    handleChangeDataInicio={this.handleChangeDataInicio}
                                    handleChangeDataFim={this.handleChangeDataFim}
                                    handlerChangeCheck={this.handlerChangeCheck}
                                    handlerChangeNumberNrElementos={this.handlerChangeNumberNrElementos}
                                    handlerChangeRadio={this.handlerChangeRadio}
                                    //handlerChangeCheckboxPresencaColetiva={this.handlerChangeCheckboxPresencaColetiva}
                                    //handlerChangeCheckboxFaltaColetiva={this.handlerChangeCheckboxFaltaColetiva}
                                    //handlerChangeCheckboxPresencas={this.handlerChangeCheckboxPresencas}
                                    listarParticipantes={this.listarParticipantes}
                                    stopReset={this.stopReset}
                                />
                                <DrawerPresencas
                                    onClose={() => this.setState({ drawerPresencasVisible: false })}
                                    cursoid={this.state.cursoid}
                                    folhaPresencasId={folhaPresencasId}
                                    drawerPresencasVisible={drawerPresencasVisible}
                                />
                            </div>
                        );
                    }}
                </GeralContext.Consumer>
            </>
        );
    }
}

export default Modulos;
