﻿import React, { useState } from "react"
import { Drawer } from "antd"
import Editor from "./Editor";
import { Context } from "react-responsive"

const FormPaginaHTML2 = props => {
    const [show, setShow] = useState(false)

    function afterVisibleChange(visible) {
        if (visible) {
            setShow(visible)
        }
    }

    function closeDrawer() {
        props.onClose();
        setShow(false)
    }

    return (
        <Drawer
            className="drawer-from-pagina-html"
            width={720}
            onClose={closeDrawer}
            visible={props.visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div>
                <div style={{ minHeight: 40 }}></div>
                {
                    show
                    &&
                    <Editor
                        value={props.recurso}
                        height="700px"
                    />}
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={closeDrawer}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
            </div>
        </Drawer>
    )
}

export default FormPaginaHTML2
