import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { Icon, Modal, Form, Checkbox, Row, Col, Tooltip } from "antd";

const CheckboxGroup = Checkbox.Group;

const ModalDuplicarChecklist = ({ visible, onCancel, onConfirm }) => {
    const [opcoes, setOpcoes] = useState(['Inscrições', 'Informações', 'Atividades e Tarefas', 'Diário', 'Drive', 'Anotações', 'Fórum']);
    const [selecionadas, setSelecionadas] = useState(['Inscrições', 'Informações', 'Atividades e Tarefas', 'Diário', 'Drive', 'Anotações', 'Fórum']);
    const [indeterminate, setIndeterminate] = useState(false);
    const [todas, setTodas] = useState(true);

    const onChange = selecionadas => {
        setSelecionadas(selecionadas);
        setIndeterminate(!!selecionadas.length && selecionadas.length < opcoes.length);
        setTodas(selecionadas.length === opcoes.length);
    };

    const selecionarTodas = (event) => {
        setSelecionadas(event.target.checked ? opcoes : []);
        setIndeterminate(false);
        setTodas(event.target.checked);
    }

    return (
        <Modal
            visible={visible}
            okText="Confirmar"
            onOk={() => onConfirm(selecionadas)}
            onCancel={onCancel}
            cancelText="Cancelar"
            okButtonProps={true}
            maskClosable={false}
            className=""
        >
            <div className="bloco-alerta">
                <span className="icon-alerta">
                    <Icon type="info-circle" />
                </span>
                <span className="mensagem-alerta">
                    <span className="mensagem-titulo">Pretende duplicar este curso?</span>
                    <span className="mensagem-texto">Selecione abaixo quais as opções que pretende duplicar no curso.</span>
                </span>
            </div>
            <div>
                <Form className="form-emitir-declaracao">
                    <Form.Item>
                        <div style={{ marginBottom: "20px"}}>
                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={selecionarTodas}
                                checked={todas}
                            >
                                Todas as opções
                            </Checkbox>
                        </div>
                        <Checkbox.Group style={{ width: '100%' }} value={selecionadas} onChange={onChange}>
                            <Row>
                                {opcoes.map((opcoa, index) => (
                                    <Col key={index} span={16}>
                                        <Checkbox value={opcoa} >
                                            {opcoa}
                                            {opcoa === "Drive" &&
                                                <Tooltip className="info-icon" title="Para registos de alunos na drive, apenas serão duplicados caso o autor seja duplicado.">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>}
                                            {opcoa === "Anotações" &&
                                                <Tooltip className="info-icon" title="Registos de alunos nas anotações apenas serão duplicados caso o autor seja duplicado.">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

const FormModalDuplicarChecklist = Form.create({ name: "modal-duplicar-checklist" })(ModalDuplicarChecklist);

export default FormModalDuplicarChecklist;
