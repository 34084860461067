import React from "react";
import { Icon, Modal } from "antd";

const ModalAssociarErros = ({ visibleErros, totalAssociado, erros, onClose }) => {
    return (
        <Modal visible={visibleErros} maskClosable={false} className="modal-associar" onCancel={onClose} footer={null}>
            <div className="modal-associar-bloco">
                <p className="texto">Usuários associados: {totalAssociado}</p>
                <div className="bloco-usuarios-falhados">
                    <div className="titulo-usuarios-falhados">Não associados({erros.length}):</div>
                    <ul className="lista-usuarios-falhados">
                        {erros.map((erro, index) => (
                            <li key={index}>
                                {erro.user} - <span>{erro.mensagem}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

export default ModalAssociarErros;

//export default ModalAssociarErros;
