import React, { Component, useEffect } from "react";
import { Drawer, Form, Input, DatePicker, TimePicker, Icon, notification, Select, Radio, Col, Row, InputNumber, Switch } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerAdicionarAula extends Component {
    static contextType = GlobalContext;

    state = {
        //INERTVALO CALENDARIO
        dtInicio: null,
        dtTermino: null,
        //
        dt_inicio: null,
        hr_inicio: null,
        carga_horaria: undefined,
        hr_termino: null,
        listaProfessores: [],
        professorId: undefined,
        listaSalas: [],
        salaId: undefined,
        observacoes: "",
        avaliacao: false,
        extracurricular: false,
        //LOADING
        iconLoading: false,
        //RECORRENCIA
        recorrente: false,
        recorrenciaIntervalo: 1,
        recorrencia: "SEMANA",
        recorrenciaFim: null,
        //PERMISSAO
        permissao: false
    };

    componentDidMount() {
        this.intervaloCalendario();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeAvaliacao = avaliacao => {
        this.setState({ avaliacao });
    };

    handleChangeExtracurricular = extracurricular => {
        this.setState({ extracurricular });
    };

    handleChangeDataInicio = dt_inicio => {
        if (this.props.aulaId)
            this.setState({
                dt_inicio
            });
        else
            this.setState(
                {
                    dt_inicio
                },
                () => {
                    if (dt_inicio) {
                        this.props.atualizarPosicaoCalendário(dt_inicio.toDate());
                        this.apanharUltimaHora();
                    }
                }
            );
    };

    handleChangeHoraInicio = hr_inicio => {
        this.props.form.resetFields();
        this.setState(
            {
                hr_inicio,
                hr_termino: moment(hr_inicio).add(1, "hour")
            },
            () => this.calcularCargaHoraria()
        );
    };

    handleChangeHoraTermino = hr_termino => {
        this.setState(
            {
                hr_termino
            },
            () => this.calcularCargaHoraria()
        );
    };

    calcularCargaHoraria = () => {
        if (this.state.hr_inicio != null && this.state.hr_termino != null) {
            const inicio = this.state.hr_inicio;
            const termino = this.state.hr_termino;

            const formatoHora = "HH:mm";
            const inicioMoment = moment(inicio, formatoHora);
            const terminoMoment = moment(termino, formatoHora);

            const duracao = moment.duration(terminoMoment.diff(inicioMoment));

            const minutosTotais = duracao.asMinutes();

            const minutosMinimos = Math.max(minutosTotais, 60);

            const minutosArredondados = Math.ceil(minutosMinimos / 30) * 30;

            const cargaHorariaFormatada = moment.utc(minutosArredondados * 60000).format("HH:mm");

            this.setState({
                carga_horaria: cargaHorariaFormatada
            });
        }
    };

    handleChangeSelectSala = salaId => {
        this.setState({ salaId });
    };

    handleChangeSelectProfessor = professorId => {
        this.setState({ professorId });
    };

    //RECORRENCIA
    handlerChangeRadioGeral = event => {
        this.props.form.resetFields();
        this.setState({
            [event.target.name]: event.target.value,
            avaliacao: false
        });
    };

    handlerChangeRecorrenciaIntervalo = value => {
        this.setState({
            recorrenciaIntervalo: value
        });
    };

    handlerChangeSelect = value => {
        this.setState({
            recorrencia: value
        });
    };

    apanharUltimaHora = () => {
        axios({
            method: "get",
            url: "/api/aulas/apanhar-ultima-hora",
            params: {
                disciplinaId: this.props.disciplinaId,
                data: this.state.dt_inicio.format("YYYY/MM/DD")
            }
        })
            .then(response => {
                this.props.form.resetFields();
                if (response.data.hr_inicio && response.data.hr_termino) {
                    this.setState(
                        {
                            hr_inicio: moment(response.data.hr_inicio, "HH:mm"),
                            hr_termino: moment(response.data.hr_termino, "HH:mm")
                        },
                        () => this.calcularCargaHoraria()
                    );
                } else {
                    this.setState(
                        {
                            hr_inicio: moment(response.data, "HH:mm"),
                            hr_termino: moment(response.data, "HH:mm").add(1, "hour")
                        },
                        () => this.calcularCargaHoraria()
                    );
                }
            })
            .catch(() => {});
    };

    intervaloCalendario = () => {
        axios({
            method: "get",
            url: "/api/aulas/intervalo-calendario",
            params: {
                disciplinaId: this.props.disciplinaId
            }
        })
            .then(response => {
                this.setState({
                    dtInicio: response.data.dt_inicio,
                    dtTermino: response.data.dt_termino
                });
            })
            .catch(() => {});
    };

    listarProfessores = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-professores",
            params: {
                cursoId: this.props.disciplinaId
            }
        })
            .then(response => {
                this.setState({
                    listaProfessores: response.data
                });
            })
            .catch(() => {});
    };

    listarSalas = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-salas",
            params: {
                estabelecimentoId: this.props.filtroEscolaId
            }
        })
            .then(response => {
                this.setState({
                    listaSalas: response.data
                });
            })
            .catch(() => {});
    };

    validarPermissao = () => {
        axios({
            method: "get",
            url: "/api/aulas/validar-permissao",
            params: {
                disciplinaId: this.props.disciplinaId
            }
        })
            .then(response => {
                this.setState({
                    permissao: response.data || this.context.perfil?.nome == "Suporte" || this.context.perfil?.nome == "Gestor"
                });
            })
            .catch(() => {});
    };

    //disabledHoursForEndTime = () => {
    //    if (this.state.hr_inicio) {
    //        // Desativar as horas anteriores ao horário de início selecionado
    //        return Array.from({ length: this.state.hr_inicio.hour() }, (_, index) => index);
    //    }
    //    return [];
    //};

    criar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_curso", this.props.disciplinaId);
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                item.append("hr_inicio", this.state.hr_inicio.format("HH:mm"));
                item.append("carga_horaria", this.state.carga_horaria);
                item.append("hr_termino", this.state.hr_termino.format("HH:mm"));
                if (this.state.permissao && this.state.professorId) item.append("id_professor", this.state.professorId);
                if (this.state.salaId) item.append("id_sala", this.state.salaId);
                item.append("titulo", this.state.observacoes);
                item.append("avaliacao", this.state.avaliacao);
                item.append("extracurricular", this.state.extracurricular);
                if (this.state.recorrente) {
                    item.append("recorrente", this.state.recorrente);
                    item.append("recorrenciaIntervalo", this.state.recorrenciaIntervalo);
                    item.append("recorrencia", this.state.recorrencia);
                    item.append("recorrenciaFim", moment(this.state.recorrenciaFim).format("YYYY/MM/DD"));
                }

                axios({
                    method: "post",
                    url: !this.state.recorrente ? "/api/aulas/adicionar-aula" : "/api/aulas/adicionar-aula-recorrencia",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon(
                            "success",
                            "Sucesso",
                            !this.state.recorrente
                                ? "Aula adicionada!"
                                : `Recorrência criada. Ultima aula criada a ${moment(response.data).format("DD/MM/YYYY")}`
                        );
                        this.setState({ iconLoading: false });
                        this.props.atualizarListagem();
                    })
                    .catch(error => {
                        if (!error.response.data) openNotificationWithIcon("error", "Erro", "Não foi possível criar recorrência.");

                        if (error.response.data.total > 1) {
                            openNotificationWithIcon(
                                "warning",
                                "Atenção",
                                <div dangerouslySetInnerHTML={{ __html: error.response.data.mensagem }}></div>
                            );
                            this.props.atualizarListagem();
                        } else openNotificationWithIcon("error", "Erro", error.response.data.mensagem);

                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            this.listarProfessores();
            this.listarSalas();
            this.validarPermissao();

            const inicio = moment();
            const minutos = 15 - (inicio.minute() % 15);

            const hora = moment(inicio).add(minutos, "minutes");

            this.setState(
                {
                    dt_inicio: moment(),
                    hr_inicio: hora,
                    hr_termino: hora.add(1, "hour"),
                    carga_horaria: undefined,
                    professorId: undefined,
                    salaId: undefined,
                    observacoes: "",
                    avaliacao: false,
                    extracurricular: false,
                    //LOADING
                    iconLoading: false,
                    //RECORRENCIA
                    recorrente: false,
                    recorrenciaIntervalo: 1,
                    recorrencia: "SEMANA",
                    recorrenciaFim: null
                },
                () => this.apanharUltimaHora()
            );
        }
    };

    disabledDate = current => {
        return current < moment(this.state.dtInicio).startOf("day") || current > moment(this.state.dtTermino).endOf("day");
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            dt_inicio,
            carga_horaria,
            hr_inicio,
            hr_termino,
            listaProfessores,
            professorId,
            listaSalas,
            salaId,
            observacoes,
            avaliacao,
            extracurricular,
            //LOADING
            iconLoading,
            //RECORRENCIA
            recorrente,
            recorrenciaIntervalo,
            recorrencia,
            recorrenciaFim,
            //PERMISSAO
            permissao
        } = this.state;

        const { visible, aulaId } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-adicionar-evento"
                    //title={aulaId ? "Alterar" : "Adicionar"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Data">
                                    {getFieldDecorator("dt_inicio", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: dt_inicio
                                    })(
                                        <DatePicker
                                            className="input-25"
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            name="dt_fimAula"
                                            onChange={this.handleChangeDataInicio}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Hora Inicio">
                                    {getFieldDecorator("hr_inicio", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: hr_inicio
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="duracaoAula"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.handleChangeHoraInicio}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Hora Término">
                                    {getFieldDecorator("hr_termino", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: hr_termino
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="duracaoAula"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.handleChangeHoraTermino}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    )}
                                    {carga_horaria && (
                                        <div style={{ borderTop: "1px solid #d9d9d9", marginTop: 10, paddingTop: 5 }}>
                                            <span style={{ display: "block", lineHeight: "initial" }}>
                                                <strong>Carga Horária:</strong> {carga_horaria}
                                            </span>
                                        </div>
                                    )}
                                </Form.Item>
                                {permissao && (
                                    <Form.Item label="Professor">
                                        {getFieldDecorator("professor", {
                                            //rules: [
                                            //    {
                                            //        required: true,
                                            //        message: "Campo obrigatório"
                                            //    }
                                            //],
                                            initialValue: professorId
                                        })(
                                            <Select placeholder="Selecionar" onChange={this.handleChangeSelectProfessor} allowClear={true}>
                                                {listaProfessores.map(professor => (
                                                    <Option key={professor.id} value={professor.id}>
                                                        {professor.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                )}
                                <Form.Item label="Sala">
                                    {getFieldDecorator("sala", {
                                        initialValue: salaId
                                    })(
                                        <Select placeholder="Selecionar" onChange={this.handleChangeSelectSala}>
                                            {listaSalas.map(sala => (
                                                <Option key={sala.id} value={sala.id}>
                                                    {sala.nome}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Observações">
                                    {getFieldDecorator("observacoes", {
                                        initialValue: observacoes
                                    })(<TextArea name="observacoes" rows={4} style={{ height: 100 }} onChange={this.handleChange} />)}
                                </Form.Item>
                                <div className="bloco">
                                    <h3 className="titulo-separador">Recorrência</h3>
                                    <Form.Item className="form-item-checkbox">
                                        <Radio.Group name="recorrente" value={recorrente} onChange={this.handlerChangeRadioGeral}>
                                            <Radio value={false}>Evento único</Radio>
                                            <Radio value={true}>Recorrente (máximo de 60 agendamentos)</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {recorrente && (
                                        <>
                                            <Form.Item>
                                                <Row gutter={8}>
                                                    <Col span={3}>
                                                        <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>
                                                            A cada
                                                        </label>
                                                    </Col>
                                                    <Col span={3}>
                                                        <InputNumber
                                                            value={recorrenciaIntervalo}
                                                            min={1}
                                                            max={12}
                                                            onChange={this.handlerChangeRecorrenciaIntervalo}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Select
                                                            className="input-100"
                                                            placeholder="Selecionar"
                                                            defaultValue={recorrencia}
                                                            onChange={this.handlerChangeSelect}
                                                        >
                                                            <Option value="SEMANA">Semana</Option>
                                                            <Option value="MES">Mês</Option>
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                            <Form.Item>
                                                <Row gutter={8}>
                                                    <Col span={4}>
                                                        <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>
                                                            Repetir até
                                                        </label>
                                                    </Col>
                                                    <Col span={8}>
                                                        {getFieldDecorator("recorrenciaFim", {
                                                            rules: [{ required: true, message: "Campo obrigatório" }],
                                                            initialValue: recorrenciaFim
                                                        })(
                                                            <DatePicker
                                                                placeholder="Selecionar"
                                                                format="DD-MM-YYYY"
                                                                name="dia"
                                                                onChange={recorrenciaFim => this.setState({ recorrenciaFim })}
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </>
                                    )}
                                </div>
                                <Form.Item>
                                    <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                        <Switch
                                            size="small"
                                            checked={avaliacao}
                                            disabled={recorrente}
                                            onChange={this.handleChangeAvaliacao}
                                        />
                                        Aula de avaliação
                                    </span>
                                    <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                        <Switch size="small" checked={extracurricular} onChange={this.handleChangeExtracurricular} />
                                        {localStorage.getItem("entidade_id") === "33" ? "Reposição de aulas" : "Aula extracurricular"}
                                    </span>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </button>
                        <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">
                            {iconLoading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerAdicionarAula = Form.create({ name: "form-drawer-adicionar-aula" })(DrawerAdicionarAula);

export default FormDrawerAdicionarAula;
