import React, { useState, useEffect } from "react";
import {
    Modal,
    Form,
    Select,
    Icon,
    notification
} from "antd";
import axios from "axios";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const ModalMover = ({ visible, temOrganismos, onClose, onConfirm, cursoPai, form }) => {
    const [organismo, setOrganismo] = useState(undefined);
    const [curso, setCurso] = useState(undefined);
    const [listaOrganismos, setListaOrganismos] = useState([]);
    const [listaCursos, setListaCursos] = useState([]);

    const { getFieldDecorator } = form;

    const onHandleChangeOrganismo = (value) => {
        setOrganismo(value);
        if (value)
            listarCursos(value);
    }

    const onHandleChangeCurso = (value) => {
        setCurso(value);
    }

    const listarOrganismos = () => {
        axios({
            method: "get",
            url: "/api/listagens/listar-organismos",
        })
            .then(response => {
                setListaOrganismos(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível inativar o curso!");
            });
    }

    const listarCursos = (organismoId) => {
        axios({
            method: "get",
            url: "/api/listagens/listar-cursos-disciplina",
            params: {
                organismoId
            }
        })
            .then(response => {
                setListaCursos(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível inativar o curso!");
            });
    }

    const confirmar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                reset();
                onConfirm(organismo, curso);
            }
        });
    }

    const reset = () => {
        setOrganismo(undefined);
        setCurso(undefined);
    }

    useEffect(() => {
        if (visible) {
            if (temOrganismos)
                listarOrganismos();
            else
                listarCursos();
        }
    }, [visible])

    return (
        <Modal
            visible={visible}
            okText="Confirmar"
            onOk={() => confirmar()}
            onCancel={() => { onClose(); reset(); }}
            cancelText="Cancelar"
            okButtonProps={true}
            maskClosable={false}
            className=""
        >
            <div className="bloco-alerta">
                <span className="icon-alerta">
                    <Icon type="info-circle" />
                </span>
                <span className="mensagem-alerta">
                    <span className="mensagem-titulo">Pretende mover este curso?</span>
                    <span className="mensagem-texto">Selecione abaixo para onde pretende mover o curso.</span>
                </span>
            </div>
            <div>
                <Form className="form-emitir-declaracao">
                    {temOrganismos &&
                        <Form.Item label="Unidade Organizacional">
                            {getFieldDecorator("organismo", {
                                initialValue: organismo,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(
                                <Select
                                    placeholder="Selecionar"
                                    allowClear={true}
                                    onChange={onHandleChangeOrganismo}
                                >
                                    {listaOrganismos.map(organismo => (
                                        <Option key={organismo.id} value={organismo.id}>
                                            {organismo.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>}
                    {!cursoPai &&
                        <Form.Item label="Curso com disciplina">
                            <Select
                                placeholder="Selecionar"
                                allowClear={true}
                                onChange={onHandleChangeCurso}
                                value={curso}
                                disabled={listaCursos.length === 0}
                            >
                                {listaCursos.map(curso => (
                                    <Option key={curso.id} value={curso.id}>
                                        {curso.nome}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>}
                </Form>
            </div>
        </Modal>
    );
}

const FormModalMover = Form.create({ name: "modal-mover" })(ModalMover);

export default FormModalMover;
