import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, notification, Modal } from "antd";
import axios from "axios";
import moment from "moment";

import ModalViewer from "../../../../Componentes/Modals/ModalViewer";
import ModalLoading from "../../../../Componentes/Modals/ModalLoading";
import ModalHorariosFrequencias from "../../_Aux/ModalHorariosFrequencias";
import ModalGerarDocumento from "../../../../Componentes/Modals/GerarDocumento";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Opcoes extends Component {
    state = {
        disciplinaId: localStorage.getItem("vista") === "TURMA" ? null : localStorage.getItem("codigo_curso"),
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //LOADING
        visibleLoading: false,
        //VIEWER
        visibleViewer: false,
        docs: []
    };

    exportar = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/excel/faltas-horas",
                    params: {
                        cursoId: this.state.disciplinaId
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "N�o foi poss�vel exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarPdf = (disciplina, option, startDate, endDate, dataEmissao) => {
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pdf/frequencia-pdf",
                    params: {
                        cursoId: disciplina,
                        periodoId: this.state.periodoId,
                        start: moment(startDate).format("YYYY/MM/DD"),
                        end: moment(endDate).format("YYYY/MM/DD"),
                        tipo: option,
                        dataEmissao: moment(dataEmissao).format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data }]
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "N�o foi poss�vel exportar!");
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isModalVisible: !prevState.isModalVisible
        }));
    };

    render() {
        const {
            disciplinaId,
            //EXPORTAR
            visibleLoading,
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //VIEWER
            visibleViewer,
            docs
        } = this.state;

        return (
            <>
                <div className="bloco-opcoes-esquerda">
                    <Link className={`botao-icon-detalhe botao-maior`} to="#" title="Descarregar" onClick={this.toggleModal}>
                        <Icon type="eye" />
                    </Link>
                    <Link
                        className={`botao-icon-download botao-maior ${localStorage.getItem("vista") === "TURMA" && !disciplinaId ? "botao-disabled" : ""
                            }`}
                        to="#"
                        title="Descarregar"
                        disabled={localStorage.getItem("vista") === "TURMA" && !disciplinaId}
                        style={{ margin: 0 }}
                        onClick={this.exportar}
                    >
                        <Icon type="download" />
                    </Link>
                    <Link
                        to={`/gerir-detalhe-turma/${this.props.match.params.turma}/${localStorage.getItem("vista") === "TURMA" ? "horario" : "horario-disciplina"
                            }`}
                        className="botao-secundario botao-secundario-redondo"
                        title="Voltar"
                        style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Icon type="arrow-left" />
                    </Link>
                </div>
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
                <ModalLoading visible={visibleLoading} text="A gerar documento..." />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => this.setState({ visibleViewer: false })} />
                <ModalHorariosFrequencias
                    disciplina={this.state.disciplinaId}
                    visible={this.state.isModalVisible}
                    onClose={this.toggleModal}
                    onSelect={(option3, option1, option2, dataEmissao) => {
                        this.setState({
                            startDate: option2.start_date,
                            endDate: option2.end_date
                        });
                        this.exportarPdf(option3, option1, option2.start_date, option2.end_date, dataEmissao);
                    }}
                />
            </>
        );
    }
}

export default withRouter(Opcoes);
