import React, { Component } from "react";
import { Tooltip } from "antd";
import moment from "moment";

class TabelaFrequencia extends Component {
    state = {
        totalFaltas: 0
    }

    montarColunasDias = (color, periodo) => {
        let dias = [];
        for (let i = 0; i < 31; i++) {
            const aulas = periodo.aulas.filter(x => moment(x.dt_inicio).isSame(`${periodo.ano}-${(('0' + (moment().month(periodo.mes).format("M"))).slice(-2))}-${(('0' + (i + 1)).slice(-2))}`));
            if (aulas.length > 0) {
                let presencas = 0;
                let faltas = 0;
                let aulasPresencas = [];
                let aulasFaltas = [];
                aulas.map(aula => {
                    const presencasAlunos = aula.presencas ? JSON.parse(aula.presencas) : [];

                    if (aula.presenca || !aula.presenca && presencasAlunos.length && presencasAlunos.find(x => x.id === this.props.utilizadorId && x.presente)) {
                        aulasPresencas.push(<><span>{aula.titulo}: Presente</span><br /></>)
                        presencas++;
                    }
                    if (aula.falta || !aula.falta && presencasAlunos.length && presencasAlunos.find(x => x.id === this.props.utilizadorId && !x.presente)) {
                        aulasFaltas.push(<><span>{aula.titulo}: Faltou</span><br /></>)
                        faltas++;
                    }
                });
                dias.push(<td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={color} sdnum="1033;0;@">
                    {presencas > 0 ?
                        <font face="Cambria" style={{ color: "green" }}>
                            <Tooltip placement="bottom"
                                title={<span>
                                    {aulasPresencas.map(presenca => presenca)}
                                </span>}
                            >
                                <strong>{presencas > 1 ? `P (${presencas})` : "P"}</strong>
                            </Tooltip>
                            <br />
                        </font>
                        : null}
                    {faltas > 0 ?
                        <font face="Cambria" style={{ color: "red" }}>
                            <Tooltip placement="bottom"
                                title={<span>
                                    {aulasFaltas.map(falta => falta)}
                                </span>}
                            >
                                <strong>{faltas > 1 ? `F (${faltas})` : "F"}</strong>
                            </Tooltip>
                            <br />
                        </font>
                        : null}
                </td>)
            }
            else
                dias.push(<td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={color} sdnum="1033;0;@"><font face="Cambria"><br /></font></td>)
        }

        return dias;
    }

    calcularTotalPresencas = (periodo, index) => {
        let acumulado = 0;

        for (let i = index; i > 0; i--) {
            acumulado += this.props.periodos[i].presencas;
        }
        if (acumulado > 0)
            return `${periodo.presencas}/${acumulado}`;
        else
            return `${periodo.presencas}/${periodo.presencas}`;
    }

    calcularTotalFaltas = (periodo, index) => {
        let acumulado = 0;

        for (let i = index; i > 0; i--) {
            acumulado += this.props.periodos[i].faltas;
        }
        if (acumulado > 0)
            return `${periodo.faltas}/${acumulado}`;
        else
            return `${periodo.faltas}/${periodo.faltas}`;
    }

    render() {
        return (
            <table cellSpacing={0} border={0} style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000">BIMESTRE</font></b></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000">MÊS</font></b></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">01</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">02</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">03</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">04</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">05</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">06</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">07</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">08</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">09</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">10</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">11</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">12</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">13</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">14</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">15</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">16</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">17</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">18</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">19</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">20</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">21</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">22</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">23</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">24</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">25</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">26</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">27</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">28</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">29</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">30</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000">31</font></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000">PRESENÇAS<br />(Mês/Bimestre)</font></b></td>
                        <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000">FALTAS<br />(Mês/Bimestre)</font></b></td>
                    </tr>
                    {this.props.periodos.map((periodo, index) => (
                        <tr key={index}>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} align="center" valign="middle" bgcolor={index % 2 === 0 ? "#FFFFFF" : "#D9D9D9"} sdval={2} sdnum="1033;0;00"><b><font face="Cambria">{periodo.titulo}</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="left" valign="middle" bgcolor={index % 2 === 0 ? "#FFFFFF" : "#D9D9D9"}><font face="Cambria">{periodo.mes}<br /></font></td>
                            {this.montarColunasDias((index % 2 === 0 ? "#FFFFFF" : "#D9D9D9"), periodo).map(dia => dia)}
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={index % 2 === 0 ? "#FFFFFF" : "#D9D9D9"} sdnum="1033;0;00"><font face="Cambria">{this.calcularTotalPresencas(periodo, index)}<br /></font></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={index % 2 === 0 ? "#FFFFFF" : "#D9D9D9"} sdnum="1033;0;00"><font face="Cambria">{this.calcularTotalFaltas(periodo, index)}<br /></font></td>
                        </tr>
                    ))}
                </tbody></table>
        );
    }
}

export default TabelaFrequencia;
