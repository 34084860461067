import React, { useState, useEffect } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Icon, notification } from 'antd';
import DrawerPagamento from "./Drawers/DrawerPagamento";
import axios from "axios";
import moment from "moment";

const Tabela = () => {
    const [visible, setVisible] = useState(false);
    const [dividaId, setDividaId] = useState();
    const [dividas, setDividas] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [loading, setLoading] = useState(false);

    const extensions = [".bmp", ".csv", ".odt", ".doc", ".docx", ".gif", ".htm", ".html", ".jpg", ".jpeg", ".pdf", ".png", ".ppt", ".pptx", ".tiff", ".txt", ".xls", ".xlsx"];

    const cols = [
        {
            title: "Descrição",
            dataIndex: "descricao"
        },
        {
            title: "Data",
            dataIndex: "data"
        },
        {
            title: "Tipo de Pagamento",
            dataIndex: "tipo"
        },
        {
            title: "Estado",
            dataIndex: "estado"
        },
        {
            title: "Valor",
            dataIndex: "valor"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-dividas"
        })
            .then(response => {
                var lista = response.data.map(divida => {
                    return {
                        descricao: divida.tipo,
                        data: moment(divida.data).format("DD/MM/YYYY"),
                        tipo: divida.pagamento,
                        estado: divida.pago ? <span className="state-green-tag">Pago</span> : <span className="state-red-tag">Pendente</span>,
                        valor: `R$ ${divida.valor}`,
                        opcoes: <div className="documentos-opcoes">
                            <Link className="botao-icon-editar" to="#" title="Editar" onClick={() => { setVisible(true); setDividaId(divida.id) }}>
                                <Icon type="edit" />
                            </Link>
                        </div>
                    }
                })
                setDividas(lista);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    useEffect(() => {
        carregar();
    }, [])

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <>
            <Table
                className="tabela-documentos tabela-aproveitamento"
                columns={cols}
                pagination={false}
                dataSource={dividas}
                loading={{
                    spinning: loading,
                    indicator: (
                        <div className="loading-data-table">
                            <div className="loading" />
                        </div>
                    )
                }}
            />
            <DrawerPagamento
                extensions={extensions}
                dividaId={dividaId}
                visible={visible}
                onClose={() => setVisible(false)}
                atualizar={() => { carregar(); setVisible(false); }}
            />
        </>
    );
}

export default withRouter(Tabela);