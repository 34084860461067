import React, { useState, useContext, useEffect } from "react";
import { Icon, Menu, Dropdown, Select, notification } from 'antd';
import { Link } from "react-router-dom";
import DadosGerais from "./Gerir/DadosGerais/DadosGerais";
import Educacao from "./Gerir/Educacao/Educacao";
import Aproveitamento from "./Gerir/Aproveitamento/Aproveitamento";
import Assiduidade from "./Gerir/Assiduidade/Assiduidade";
import Matriculas from "./Gerir/Historico/Matriculas/Matriculas";
import Percurso from "./Gerir/Historico/Percurso/Percurso";
import Estados from "./Gerir/Historico/Estados/Estados";
import Estatutos from "./Gerir/Historico/Estatutos/Estatutos";
import Outros from "./Gerir/Historico/Outros/Outros";
import Formacao from "./Gerir/Formacao/Formacao";
import Documentos from "./Gerir/Documentos/Documentos";
import Pagamentos from "./Gerir/Pagamentos/Pagamentos";
//COORDENADOR
//import GestaoCoordenador from "./Gerir/Coordenador/Educacao/Educacao";
/*import FormacaoCoordenador from "./Gerir/Coordenador/Formacao/Formacao";*/
//COORDENADOR E PROFESSOR
import GestaoCoordProf from "./Gerir/Professor/Educacao/Educacao";
import FormacaoCoordenador from "./Gerir/Coordenador/Formacao/Formacao";
import FormacaoProfessor from "./Gerir/Professor/Formacao/Formacao";
//CONTEXT
import { GlobalContext } from "../GlobalState";

import axios from "axios";


const { SubMenu } = Menu;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const UserDetalhe = ({ match }) => {
    const [perfis, setPerfis] = useState([]);
    const [perfil, setPerfil] = useState("Aluno");
    const [primeiraEntrada, setPrimeiraEntrada] = useState(true);
    const globalContext = useContext(GlobalContext);

    const listaAcessosEducacao = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];
    const listaAcessosFormacao = [201, 202, 203, 204, 205];

    const { Option } = Select;

    const [menuOptionSelected, setMenuOptionSelected] = useState({
        menuItemKey: "1",
        menuItemName: "Gestão",
    })

    const handleClick = e => {
        setMenuOptionSelected({
            menuItemKey: e.keyPath[0],
            menuItemName: e.item.props.children, 
        })

        if (perfil === "Professor" && perfil === "Coordenador" && e.keyPath[0] !== "2") {
            globalContext.atualizarState({
                [`edicao${match.params.code}`]: null,
                [`escola${match.params.code}`]: null,
                [`categoria${match.params.code}`]: null
            });
        }
    };

    const listarPerfis = () => {
        const pesquisaInfo = globalContext.pesquisaInfo;
        let entidadeId = 0;/* globalContext.estabelecimento.id;*/
        if (pesquisaInfo && pesquisaInfo.estabelecimento)
            entidadeId = pesquisaInfo.estabelecimento

        axios({
            method: "get",
            url: "/api/user-detalhe/listar-perfis",
            params: {
                code: match.params.code,
                entidadeId
            }
        })
            .then(response => {
                setPerfis(response.data);
                if (primeiraEntrada && response.data?.find(x => x.nome === globalContext.cardPerfilSelecionado)?.nome)
                    setPerfil(globalContext.cardPerfilSelecionado);
                else
                    setPerfil(response.data?.find(x => x.principal)?.nome);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não for possível carregar perfis");
            });
    }

    const buildPage = () => {
        var key = menuOptionSelected.menuItemKey;

        if (perfil == "Suporte") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
        }

        if (perfil == "Gestor") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
        }

        if (perfil == "Administrativo") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
        }

        if (perfil == "Diretor") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
        }

        if (perfil === "Coordenador") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
            if (key === "2")
                return <GestaoCoordProf menuOptionSelected={menuOptionSelected} />;
            if (key === "3")
                return <FormacaoCoordenador menuOptionSelected={menuOptionSelected} />;
            if (key === "4")
                return <Documentos menuOptionSelected={menuOptionSelected} />;
            if (key === "5")
                return <Pagamentos menuOptionSelected={menuOptionSelected} />;
        }

        if (perfil === "Professor") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
            if (key === "2")
                return <GestaoCoordProf menuOptionSelected={menuOptionSelected} />;
            if (key === "3")
                return <FormacaoProfessor menuOptionSelected={menuOptionSelected} />;
            if (key === "4")
                return <Documentos menuOptionSelected={menuOptionSelected} />;
            if (key === "5")
                return <Pagamentos menuOptionSelected={menuOptionSelected} />;
        }

        if (perfil === "Aluno") { 
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
            if (key === "2")
                return <Educacao menuOptionSelected={menuOptionSelected} />;
            if (key === "3")
                return <Aproveitamento menuOptionSelected={menuOptionSelected} />;
            if (key === "4")
                return <Assiduidade menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-1")
                return <Matriculas menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-3")
                return <Percurso menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-4")
                return <Estados menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-5")
                return <Estatutos menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-6")
                return <Outros menuOptionSelected={menuOptionSelected} />;
            if (key === "6")
                return <Formacao menuOptionSelected={menuOptionSelected} />;
            if (key === "7")
                return <Documentos menuOptionSelected={menuOptionSelected} />;
            if (key === "8")
                return <Pagamentos menuOptionSelected={menuOptionSelected} />;
        }
    }

    useEffect(() => {
        if (globalContext.estabelecimento)
            listarPerfis();
    }, [globalContext.estabelecimento])

    const acessos = globalContext.acessos;
    const acessosEducacao = acessos?.find(x => listaAcessosEducacao.includes(x.func));
    const acessosFormacao = acessos?.find(x => listaAcessosFormacao.includes(x.func));

    return (
        <div className="details-page" style={{ marginTop: 80 }}>
            <div className="menu">
                {/*<div>*/}
                {/*    <Link to="/gestao-utilizadores-todos" className="botao-secundario botao-secundario-redondo" title="Voltar">*/}
                {/*        <Icon type="arrow-left" />*/}
                {/*    </Link>*/}
                {/*</div>*/}
                {perfis.length > 1 &&
                    <div className="profil-filter-block">
                        <Select
                            className="profil-filter-select"
                            placeholder="Selecionar"
                            value={perfil}
                            onChange={(perfil) => setPerfil(perfil)}
                        >
                            {perfis.map(perfil => (
                                <Option key={perfil.id} value={perfil.nome}>{perfil.nome}</Option>
                            ))}
                        </Select>
                    </div>}
                {perfil === "Suporte" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                    </Menu>}
                {perfil === "Gestor" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                    </Menu>}
                {perfil === "Administrativo" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                    </Menu>}
                {perfil === "Diretor" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                    </Menu>}
                {(perfil === "Coordenador" || perfil === "Professor") &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                        {(globalContext.diario_entidade && globalContext.diario_stepforma && acessosEducacao) ?
                            <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} /> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma && acessosEducacao) ?
                            <Menu.Item key="2">
                                Educação
                            </Menu.Item> : null}
                        {(globalContext.modulo_formacao && globalContext.modulo_formacao_stepforma && acessosFormacao) ?
                            <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} /> : null}
                        {(globalContext.modulo_formacao && globalContext.modulo_formacao_stepforma && acessosFormacao) ?
                            <Menu.Item key="3">
                                Formação
                            </Menu.Item> : null}
                        <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} />
                        <Menu.Item key="4">
                            Documentação
                        </Menu.Item>
                    </Menu>}
                {perfil === "Aluno" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                        {(globalContext.diario_entidade && globalContext.diario_stepforma && acessosEducacao) ?
                            <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} /> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma && acessosEducacao) ?
                            <Menu.Item key="2">
                                Educação
                            </Menu.Item> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma && acessosEducacao) ?
                            <Menu.Item key="3">
                                Aproveitamento
                            </Menu.Item> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma && acessosEducacao) ?
                            <Menu.Item key="4">
                                Assiduidade
                            </Menu.Item> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma && acessosEducacao) ?
                            <SubMenu key="sub5" title="Histórico">
                                <Menu.Item key="sub5-1">Matrículas</Menu.Item>
                                {/*<Menu.Item key="sub5-2">Inscrições</Menu.Item>*/}
                                <Menu.Item key="sub5-3">Percurso</Menu.Item>
                                <Menu.Item key="sub5-4">Estados</Menu.Item>
                                <Menu.Item key="sub5-5">Estatutos</Menu.Item>
                                <Menu.Item key="sub5-6">Outros</Menu.Item>
                            </SubMenu> : null}
                        {(globalContext.modulo_formacao && globalContext.modulo_formacao_stepforma && acessosFormacao) ?
                            <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} /> : null}
                        {(globalContext.modulo_formacao && globalContext.modulo_formacao_stepforma && acessosFormacao) ?
                            <Menu.Item key="6">
                                Formação
                            </Menu.Item> : null}
                        <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} />
                        <Menu.Item key="7">
                            Documentação
                        </Menu.Item>
                        <Menu.Item key="8">
                            Pagamentos
                        </Menu.Item>
                    </Menu>}
            </div>
            {buildPage()}
        </div>
    );
}

export default UserDetalhe;