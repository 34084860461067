import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table } from "antd";
import axios from "axios";

export class TempoConteudos extends Component {
    state = {
        lista: [],
        listaMobile: [],
        //TABELA
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    componentDidUpdate() {
        if (this.props.atualizar) {
            this.listar();
            this.props.paraAtualizar();
        }
    }

    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-estatistica/tempo-conteudos-list",
                    params: {
                        dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.props.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        var lista = [];
                        var listaMobile = [];

                        response.data.map((dado, index) => {
                            lista.push({
                                key: index,
                                ano: dado.ano,
                                mes: dado.mes,
                                acessos: dado.total,
                                tempo: dado.totalt,
                                concluidos: dado.totalc
                            });
                            listaMobile.push({
                                key: index,
                                ano: `${dado.mes}/${dado.ano}`,
                                acessos: <span>{`${dado.total}/${dado.totalc}`}<br/>{dado.totalt}</span>
                            });
                        });

                        this.setState({
                            lista,
                            listaMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    render() {
        const {
            lista,
            listaMobile,
            //TABELA
            loading_table,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const columns = [
            {
                title: "Ano",
                dataIndex: "ano",
                key: "ano"
            },
            {
                title: "Mês",
                dataIndex: "mes",
                key: "mes"
            },
            {
                title: "Visitadas",
                dataIndex: "acessos",
                key: "acessos"
            },
            {
                title: "Tempo",
                dataIndex: "tempo",
                key: "tempo"
            },
            {
                title: "Concluídas",
                dataIndex: "concluidos",
                key: "concluidos"
            }
        ];

        const columnsMobile = [
            {
                title: "Mês/Ano",
                dataIndex: "ano",
                key: "ano"
            },
            {
                title: "Visitadas/Concluídas",
                dataIndex: "acessos",
                key: "acessos"
            }
        ];

        return (
            <>
                <Table
                    columns={columns}
                    className="tabela-estatisticas-desktop"
                    dataSource={lista}
                    pagination={false}
                    locale={{ emptyText: "Não existem dados!" }}
                    loading={{
                        spinning: loading_table,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
                <Table
                    columns={columnsMobile}
                    className="tabela-estatisticas-mobile"
                    dataSource={listaMobile}
                    pagination={false}
                    locale={{ emptyText: "Não existem dados!" }}
                    loading={{
                        spinning: loading_table,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
            </>
        );
    }
}

export default TempoConteudos;
