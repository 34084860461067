// Fun��o principal para download de arquivos
export const downloadFile = (fileName, fileUrl) => {
    if (isMinioUrl(fileUrl)) {
        createMinioLink(fileName, fileUrl);
    } else {
        createNormalLink(fileName, fileUrl);
    }
}

// Fun��o para verificar se a URL pertence ao MinIO
const isMinioUrl = (url) => {
    return url.includes('s3.stepforma.com.br');
};

// Fun��o para criar o link de download para arquivos do MinIO
const createMinioLink = async (fileName, fileUrl) => {
    try {
        const res = await fetch(fileUrl);
        if (!res.ok) throw new Error('Network response was not ok');

        const blob = await res.blob(); // Converte a resposta em Blob
        const url = window.URL.createObjectURL(blob); // Cria uma URL para o Blob

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Define o nome do arquivo

        document.body.appendChild(link);
        link.click(); // Simula o clique para baixar
        link.parentNode.removeChild(link); // Remove o link do DOM

        // Libere a URL do Blob
        window.URL.revokeObjectURL(url);
    } catch (err) {
        console.error('Erro ao baixar o arquivo:', err);
    }
}

// Fun��o para criar o link de download para arquivos normais
const createNormalLink = (fileName, fileUrl) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click(); // Simula o clique para baixar
    link.parentNode.removeChild(link); // Remove o link do DOM
}
