import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, DatePicker, Modal, Icon, notification, Select, Checkbox, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerOutraAvaliacao extends Component {
    state = {
        iconLoading: false
    };

    UNSAFE_componentWillUpdate() {
        this.props.form.resetFields();
    }

    //CRIA NOVO MODULO
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                var item = new FormData();
                item.append("id_curso", this.props.cursoid);
                item.append("nome", this.props.nome);

                axios({
                    method: "post",
                    url: "/api/gestao-curso-resultados/adicionar-outra-avaliacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Outra avaliação adicionada!");
                        this.setState({ iconLoading: false });
                        this.props.atualizar();
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar outra avaliação!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //ATUALIZA O MODULO
    alterar = event => {
        event.preventDefault();

        confirm({
            title: "Pretende alterar?",
            content: "Atenção, ao confirmar a alteração será efetuada em todos os inscritos",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.props.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        this.setState({ iconLoading: true });

                        var item = new FormData();
                        item.append("id", this.props.outraId);
                        item.append("id_curso", this.props.cursoid);
                        item.append("nome", this.props.nome);

                        axios({
                            method: "put",
                            url: "/api/gestao-curso-resultados/alterar-outra-avaliacao",
                            data: item
                        })
                            .then(() => {
                                openNotificationWithIcon("success", "Sucesso", "Outra avaliação alterada!");
                                this.setState({ iconLoading: false });
                                this.props.atualizar();
                                this.props.onClose();
                            })
                            .catch(() => {
                                openNotificationWithIcon("error", "Erro", "Não foi possível alterar outra avaliação!");
                                this.setState({ iconLoading: false });
                            });
                    }
                });
            }
        });
    };

    //ATUALIZA 
    alterarClassificacao = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.classificacaoId);
                item.append("nome", this.props.nome);
                if (this.props.data)
                    item.append("data", moment(this.props.data).format("YYYY/MM/DD"));
                if (this.props.classificacao)
                    item.append("classificacao", this.props.classificacao);
                if (this.props.observacoes)
                    item.append("classificacao_notas", this.props.observacoes);
                item.append("medalha", this.props.medalha);
                item.append("notificar", this.props.notificar);

                axios({
                    method: "put",
                    url: "/api/gestao-curso-resultados/alterar-outra-avaliacao-classificacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Outra avaliação alterada!");
                        this.setState({ iconLoading: false });
                        this.props.atualizar();
                        this.props.onClose();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível alterar outra avaliação!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    render() {
        const { TextArea } = Input;
        const { iconLoading } = this.state;
        const { getFieldDecorator } = this.props.form;

        const montarTitulo = () => {
            if (this.props.outraId > 0) {
                if (!this.props.atribuir_classificacao)
                    return "Editar outra avaliação";
                else {
                    if (!this.props.classificacao)
                        return "Adicionar classificação";
                    else
                        return "Editar classificação";
                }
            }
            else
                return "Adicionar outra avaliação";

        }

        return (
            <Drawer
                className="drawer-from-outra-avaliacao"
                title={montarTitulo()}
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
            >
                <div className="bloco-info">
                    <Form className="form-modulo" layout="horizontal">
                        {(this.props.outraId > 0 && this.props.atribuir_classificacao) ?
                            <Form.Item label="Nome">
                                <span>{this.props.nome}</span>
                            </Form.Item>
                            :
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome_avaliacao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: this.props.nome
                                })(<Input name="nome_avaliacao" onChange={this.props.handleChange} />)}
                            </Form.Item>
                        }
                        {(this.props.outraId > 0 && this.props.atribuir_classificacao) &&
                            <>
                                <Form.Item label="Data">
                                    <DatePicker value={this.props.data} placeholder="Selecionar" format="DD-MM-YYYY" onChange={this.props.handleChangeData} />
                                </Form.Item>
                                <Form.Item label="Classificação">
                                {/*{getFieldDecorator("outra_classificacao", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: this.props.classificacao
                                    })(<Input name="outra_classificacao" onChange={this.props.handleChange} />)}*/}
                                <Input value={this.props.classificacao} name="outra_classificacao" onChange={this.props.handleChange} />
                                </Form.Item>
                                <Form.Item label="Observações">
                                    <TextArea value={this.props.observacoes} name="outra_observacoes" onChange={this.props.handleChange} rows="3" style={{ height: "100%" }} />
                                </Form.Item>
                                <Form.Item>
                                    <Checkbox name="outra_medalha" checked={this.props.medalha} onChange={this.props.handlerChangeCheck}>
                                        Atribuir medalha
                                    </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="outra_notificar" checked={this.props.notificar} onChange={this.props.handlerChangeCheck}>
                                    Notificar por e-mail
                                    <Tooltip className="info-icon" title="Notificar os participantes por e-mail">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                            </Checkbox>
                            </Form.Item>
                            </>}
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    {this.props.outraId > 0 ? (
                        <button className="botao-principal" type="primary" disabled={iconLoading} onClick={!this.props.atribuir_classificacao ? this.alterar : this.alterarClassificacao} style={{ padding: "0 10px" }}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>
                    ) : (
                            <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.criar}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Salvar
                            </button>
                        )}
                </div>
            </Drawer>
        );
    }
}
const FormDrawerOutraAvaliacao = Form.create({ name: "form-outra-avaliacao" })(DrawerOutraAvaliacao);

export default FormDrawerOutraAvaliacao;
