import React from "react";
export default function PoliticaPrivacidade() {


    return (
        <div className="termos-uso">
            <div>
                <h3>1. </h3>
                <p>A NextOpinion Lda fornece, no formato software como serviço (SaaS), acesso à plataforma de ensino à
                    distância STEPFORMA, disponível no domínio stepforma.com.br, que permite a criação e gestão de cursos
                    online, que poderão ser disponibilizados a utilizadores (associados a licenças autorizadas por
                    VOCÊ).
                </p>
            </div>
            <div>
                <h3>2. </h3>
                <p>Este Acordo de Utilizador de Serviço e Termos de Utilização constitui o acordo entre a STEPFORMA e VOCÊ,
                    pessoa jurídica ou física (contratante) como utilizador que estabelece uma conexão com o site da
                    Internet localizado em www.stepforma.com.br ou quaisquer serviços fornecidos em conexão com o site
                    (coletivamente o "Serviço"), que são de propriedade e controlados pela NextOpinion Lda. Se VOCÊ se
                    registrou para ou em nome de uma entidade, VOCÊ é considerado como tendo aceito este Acordo em nome
                    dessa entidade.</p>
                <p>VOCÊ CONCORDA QUE, AO USAR O SERVIÇO QUE VOCÊ REPRESENTA QUE VOCÊ TEM PELO MENOS 18 ANOS DE IDADE E QUE
                    VOCÊ É LEGALMENTE CAPAZ DE ENTRAR NESTE ACORDO.</p>
                <p>VOCÊ concorda em cumprir todas as disposições do presente Acordo a fim de permanecer um utilizador
                    autorizado do Serviço, e Seu uso do Serviço constitui o Seu acordo em cumprir estas disposições. VOCÊ é
                    o único responsável por Seu uso do Serviço, por todo o uso do Serviço feito por terceiros usando Seu
                    nome de utilizador e senha, e por garantir que tal uso esteja em total conformidade com as disposições
                    do presente Acordo.</p>
                <p>A NextOpinion Lda reserva tudo fará para fornecer o acesso à PLATAFORMA 24 horas por dia, 7 dias por
                    semana.
                </p>
                <p>No entanto, não podemos garantir que os Serviços sejam totalmente imunes a falhas, interrupções,
                    incidentes de segurança, indisponibilidades, entre outros, como qualquer outro serviço similar.</p>
                <p>A NextOpinion Lda respeita a Sua privacidade e permite-lhe controlar o tratamento das Suas informações
                    pessoais. Uma declaração completa da atual política de privacidade da Empresa pode ser encontrada no
                    site www.stepforma.com.br. A política de privacidade da Empresa está expressamente incorporada neste
                    Acordo por esta referência.</p>
                <p>A NextOpinion Lda reserva-se o direito, a seu exclusivo critério, de alterar qualquer ou todas as
                    disposições do presente Acordo a qualquer momento.</p>
                <p> A NextOpinion Lda notificará os utilizadores de quaisquer alterações, colocando-as no Serviço ou através
                    de outros meios razoáveis de notificação. Quaisquer alterações ao presente Acordo entrarão em vigor
                    imediatamente após notificação ao utilizador. A sua utilização do Serviço após a notificação de
                    alterações ao presente Acordo será considerada a Sua aceitação das alterações.</p>
                <p>A subscrição pelo período acordado da plataforma não é reembolsável. Em casos especiais, e apenas aqueles
                    devidos a dificuldades técnicas, os pedidos de reembolso serão considerados. A NextOpinion Lda analisará
                    as questões técnicas e determinará o resultado do pedido de reembolso caso a caso.</p>
                <p>A NextOpinion Lda reserva-se o direito, a seu exclusivo critério, de alterar, limitar ou descontinuar
                    qualquer aspeto, conteúdo ou característica do Serviço, bem como qualquer aspeto relativo à utilização
                    do Serviço. A NextOpinion Lda reserva-se ainda o direito, a seu exclusivo critério, de restringir a
                    utilização do Serviço, bem como de suspender ou revogar os Seus direitos de utilização do Serviço com
                    base na convicção da NextOpinion Lda de que a sua utilização do Serviço viola o permitido por este
                    Contrato ou pela lei aplicável.</p>
                <p>SE NÃO CONCORDAR COM A DISPOSIÇÃO DESTE ACORDO OU NÃO ESTIVER SATISFEITO COM O SERVIÇO, OCORREREM ERROS
                    OU INDISPONIBILIDADE NOS SERVIÇOS O SEU ÚNICO E EXCLUSIVO RECURSO É INTERROMPER A SUA UTILIZAÇÃO DO
                    SERVIÇO.</p>
                <p>Independentemente do Seu ciclo de faturação, não há reembolsos ou créditos por meses parciais do Serviço
                    ou reembolsos por tempo não utilizado se fechar a Sua conta antes do final do Seu período de subscrição.
                    Nenhuma exceção será feita para tratar todos igualmente e manter Nossos custos administrativos baixos
                    para o benefício final de Nossa base de clientes. Baixar o nível do Seu plano pode causar a perda de
                    conteúdo, recursos ou capacidade da Sua conta e a NextOpinion não aceita qualquer responsabilidade por
                    tal perda.</p>
            </div>
            <div>
                <h3>3. </h3>
                <p>Direitos no Serviço NextOpinion:</p>
                <p>O Serviço está protegido por direitos de autor, patentes, marcas registadas e outras leis de propriedade
                    intelectual e direitos de propriedade aplicáveis e é propriedade, controlado e/ou licenciado pela
                    NextOpinion Lda. O logótipo da STEPFORMA é uma marca comercial da NextOpinion Lda. Todas as outras
                    marcas comerciais que aparecem no Serviço são propriedade dos respetivos proprietários.</p>
                <p>A NextOpinion Lda. concede-lhe, pelo presente acordo,
                    e não exclusiva para utilizar o Serviço exclusivamente para Seu uso pessoal, organizacional ou interno.
                    VOCÊ não fará nenhum outro uso do conteúdo sem a permissão expressa por escrito da NextOpinion Lda. do
                    proprietário dos direitos autorais ou de Seu agente autorizado.</p>
                <p>VOCÊ não irá modificar, publicar, distribuir, transmitir, participar na transferência ou venda, criar
                    trabalhos derivados, ou de qualquer forma explorar, qualquer conteúdo, no todo ou em parte, encontrado
                    no Serviço que não foi expressamente criado por VOCÊ ou alguém em Sua organização com Sua permissão.</p>
                <p>VOCÊ também não "enquadrará" qualquer conteúdo do Serviço ou do próprio Serviço sem a permissão expressa
                    por escrito da NextOpinion Lda, e do proprietário dos direitos autorais ou de Seu agente autorizado. Ao
                    aceitar este acordo de utilizador VOCÊ concorda com os termos nele expressos.</p>
                <p>Atualizações, melhorias e alterações na plataforma de ensino à distância STEPFORMA e suas funcionalidades
                    poderão ser implementadas, incluindo a sua substituição ou exclusão a qualquer tempo pela NextOpinion
                    Lda.</p>
            </div>
            <div>
                <h3>4. </h3>
                <p>Deveres do CONTRATANTE: <br></br>
                    Ao aceitar este Acordo, VOCÊ (CONTRATANTE) se compromete a utilizar a Plataforma para fins lícitos e
                    educacionais, de acordo com as informações constantes no site www.stepforma.com.br e outras recebidas
                    por email. VOCÊ é totalmente responsável pela criação e configuração dos cursos e todos os conteúdos
                    associados a esses cursos que VOCÊ disponibilizar.</p>
                <p>VOCÊ pode disponibilizar cursos online para terceiros: incluindo seus colaboradores, alunos ou outros
                    (UTILIZADORES com licenças de utilização).</p>
                <p>A relação entre VOCÊ e seus utilizadores assim como as ações destes é de sua inteira responsabilidade e
                    VOCÊ deverá garantir que eles aceitem os seus termos de uso e os constantes neste contrato. A
                    NextOpinion Lda. não terá qualquer relação jurídica com os Seus utilizadores, e VOCÊ declara que
                    isentará de quaisquer reclamações, ações judiciais ou indenizações que possam vir a ser exigidas.</p>
                <p>Eventuais benefícios e pagamentos entre VOCÊ e os seus utilizadores são da Sua inteira responsabilidade,
                    assim como eventuais falhas de recebimentos provindos de Seus utilizadores. A utilização do Serviço
                    possui exigências técnicas que deverão ser asseguradas pelos utilizadores do mesmo, nomeadamente a
                    qualidade do acesso à Internet.</p>
            </div>
            <div>
                <h3>5. </h3>
                <p>A NextOpinion Lda. utiliza diversos mecanismos para evitar a perda ou a corrupção de dados, porém o
                    acesso à Internet envolve diversos riscos pelos quais não podemos nos responsabilizar, pelo que VOCÊ
                    declara que isentará de quaisquer reclamações, ações judiciais ou indenizações que possam vir a ser
                    exigidas por eventuais incidentes desta natureza.</p>
                <p>A NextOpinion Lda. tudo fará para responder rapidamente a todas as questões que lhe cheguem quer por via
                    email, por mensagem interna ou pelo formulário de contato existente no site www.stepforma.com.br. VOCÊ
                    pode ainda consultar a página de suporte técnico com questões frequentes e suas respostas sobre o uso
                    das diversas funcionalidades para cada um dos perfis de utilizadores.</p>
            </div>
        </div>
    )
}