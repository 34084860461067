import React from "react";
import { Link, withRouter } from "react-router-dom";
import noimage from "../../images/noimage.png";
import moment from "moment";

const Evento = ({ evento, match }) => {
    return (
        <Link to={`/${match.params.entidade}/eventos/${evento.code}`} >
            <div className="item-noticia">
                <div className="imagem">
                    <img src={evento.capa ? evento.capa : noimage } />
                </div>
                <div className="corpo">
                    <span className="titulo">{evento.nome}</span>
                    <p className="texto" dangerouslySetInnerHTML={{ __html: evento.descricao }}></p>
                    <div className="corpo-subinfo">
                        {evento.link && (
                            <a href={evento.link} target="_blank" rel="noopener noreferrer" className="link">
                                <i className="fa fa-link"></i> Inscrição
                            </a>)}
                        {evento.codigo && (
                            <a href={`/${match.params.entidade}/inscricao-evento/${evento.codigo}`} target="_blank" rel="noopener noreferrer" className="link">
                                <i className="fa fa-link"></i> Inscrição
                            </a>)}
                        {evento.data && (
                            <span className="data">
                                {moment(evento.data).format("DD MMM YYYY")}
                            </span>)}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default withRouter(Evento);
