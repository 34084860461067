import React from "react";

import {Icon} from "antd";
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
export default function TituloSeccao({ titulo, handlerPrevSlide, handlerNextSlide, currentIndex, numeroDeSlides, slides }) {
    return(

        <div className="tit-seccao">

            <div className="justificar-direita">
                <div className="retangulo">
                </div>
                <div>
                    <span>{titulo}</span>
                </div>

            </div>
            <div>
                <button
                    className={`botao-seccao ${currentIndex === 0 ? 'disabled' : ''}`}
                    onClick={handlerPrevSlide}
                    disabled={currentIndex === 0}
                >
                    <FaAngleLeft/>
                </button>
                <button
                    className={`botao-seccao ${currentIndex === numeroDeSlides - slides || numeroDeSlides <= slides ? 'disabled' : ''}`}
                    onClick={handlerNextSlide}
                    disabled={currentIndex === numeroDeSlides - slides || numeroDeSlides <= slides}
                >
                    <FaAngleRight/>
                </button>
            </div>
        </div>
)
    
}