import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Vimeo from "@vimeo/player";
import screenfull from "screenfull";

class Pagina extends Component {
    state = {
        vimeo: "",
        //LOADING
        loading: true,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //FULLSCREEN
        fullscreen: false
    };

    componentDidMount() {
        this.CarregarConteudo();
    }

    //CARREGA CONTEUDO DA AULA
    CarregarConteudo = () => {
        this.setState({
            vimeo: "https://player.vimeo.com/video/" + this.props.match.params.vconfid,
            loading: false
        }, () => {
            var iframe = document.querySelector("iframe");
            var player = new Vimeo(iframe);

            this.setState({
                player
            })

            player.on("seeked", data => {
                this.setState({ current: data.seconds });
            });
        });
    };

    onClickFullscreen = () => {
        this.setState({ fullscreen: !this.state.fullscreen });
        screenfull.toggle();
    };

    render() {
        const {
            vimeo,
            //LOADING
            loading,
            //REDIRECT
            redirect,
            redirectLink,
        } = this.state;
        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                {this.state.loading ?
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                    :
                    <div className="player">
                        <div className="player-wrapper">
                            <iframe src={vimeo} id="player"></iframe>
                            <div className="bloco-controlos">
                                <div className="controlos">
                                    <div className="container-btn">
                                        <Link to="#" onClick={this.onClickFullscreen} title={this.context.fullscreen ? "Sair de ecrã inteiro" : "Ecrã inteiro"}>
                                            {this.state.fullscreen ? <i className="fas fa-compress" /> : <i className="fas fa-expand" />}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default Pagina;
