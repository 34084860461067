import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, InputNumber, Icon, Select, Modal, Input, notification } from "antd";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class FormQuestionario extends Component {
    state = {
        questoes2: [],
        btn_reload: false,
        show: false
    };

    componentDidUpdate() {
        var objDiv = document.getElementsByClassName("lista-questoes")[0];
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
    }

    handleChangeQuestao = (event, index) => {
        var _questoes2 = this.state.questoes2;
        _questoes2[index].pergunta = event.target.value;

        this.setState({
            questoes2: _questoes2
        });
    };

    handleChangeOrdem = (value, index) => {
        var _questoes2 = this.state.questoes2;
        _questoes2[index].ordem = value;

        this.setState({
            btn_reload: true
        });

        this.setState({
            questoes2: _questoes2
        });
    };

    handleChangeTipo = (value, index) => {
        var _questoes2 = this.state.questoes2;
        _questoes2[index].tipo_pergunta = value;

        if (value === "Escolha única" || value === "Escolha múltipla")
            _questoes2[index].opcoes2 = [
                {
                    texto: "",
                    ordem: 1,
                    verdadeiro: false,
                    disabled: true
                },
                {
                    texto: "",
                    ordem: 2,
                    verdadeiro: false,
                    disabled: true
                }
            ];
        else _questoes2[index].opcoes2 = [];

        this.setState({
            questoes2: _questoes2
        });
    };

    handleChangeOpcao = (event, index, index2) => {
        var _questoes2 = this.state.questoes2;
        _questoes2[index].opcoes2[index2].texto = event.target.value;
        _questoes2[index].opcoes2[index2].disabled = false;

        this.setState({
            questoes2: _questoes2
        });
    };

    adicionarOpcao = (event, index) => {
        event.preventDefault();

        var _questoes2 = this.state.questoes2;
        var ordem = _questoes2[index].opcoes2.length + 1;
        _questoes2[index].opcoes2.push({
            texto: "",
            ordem: ordem,
            verdadeiro: false,
            disabled: true
        });

        this.setState({
            questoes2: _questoes2
        });
    };

    handleChangeVerdadeiro = (index, index2) => {
        var _questoes2 = this.state.questoes2;

        if (_questoes2[index].tipo_pergunta === "Escolha única")
            for (var i = 0; i < _questoes2[index].opcoes2.length; i++) {
                _questoes2[index].opcoes2[i].verdadeiro = false;
            }

        _questoes2[index].opcoes2[index2].verdadeiro = !_questoes2[index].opcoes2[index2].verdadeiro;

        this.setState({
            questoes2: _questoes2
        });
    };

    //EXCLUI QUESTÃO
    handleChangeExcluirQuestao = index => {
        confirm({
            title: "Pretende excluir esta questão?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                var _questoes2 = this.state.questoes2;
                _questoes2.splice(index, 1);

                _questoes2.forEach((questao, index) => {
                    questao.ordem = index + 1;
                });

                this.setState({
                    questoes2: _questoes2
                });
            }
        });
    };

    //EXCLUIR OPÇÃO DENTRO DA QUESTÃO
    handleChangeExcluir = (index, index2) => {
        confirm({
            title: "Pretende excluir esta opção?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                var _questoes2 = this.state.questoes2;
                _questoes2[index].opcoes2.splice(index2, 1);

                this.setState({
                    questoes2: _questoes2
                });
            }
        });
    };

    //ADICIONA UM NOVO BROCO PARA UMA NOVA QUESTÃO
    addQuestao = () => {
        var _questoes2 = this.state.questoes2;
        _questoes2.push({
            pergunta: "",
            tipo_pergunta: undefined,
            ordem: this.state.questoes2.length > 0 ? _questoes2[_questoes2.length - 1].ordem + 1 : 1,
            opcoes2: []
        });

        this.setState({
            questoes2: _questoes2
        });
    };

    //REORDENAR QUESTÕES
    ReordenarQuestoes = () => {
        var _questoes2 = this.state.questoes2;

        this.setState({
            btn_reload: false
        });

        this.setState({
            questoes2: _questoes2.sort((a, b) => (a.ordem > b.ordem ? 1 : b.ordem > a.ordem ? -1 : 0))
        });
    };

    voltar = () => {
        var _questoes2 = [];
        var stopFaltaOpcao = false;

        this.state.questoes2.forEach((questao, index) => {
            if (questao.pergunta && questao.tipo_pergunta) {
                if (questao.tipo_pergunta === "Escolha única" || questao.tipo_pergunta === "Escolha múltipla") {
                    if (questao.opcoes2.length > 0) {
                        var opcaoVazia = false
                        questao.opcoes2.forEach(opcao => {
                            if (!opcao.texto) {
                                opcaoVazia = true
                                return false
                            }
                        })
                        if (opcaoVazia) {
                            stopFaltaOpcao = true
                            return false
                        }
                        else
                            _questoes2.push(questao);
                    }
                }
                else
                    _questoes2.push(questao);
            }
        });

        if (stopFaltaOpcao)
            openNotificationWithIcon("error", "Erro", "Adicione opções de resposta")
        else {
            this.props.alterarQuestionario(_questoes2);
            this.props.onClose();
        }
    };

    afterVisibleChange = visible => {
        this.setState({ show: visible });

        if (visible) {
            if (this.props.questoes2.length === 0)
                this.setState({
                    questoes2: [
                        {
                            pergunta: "",
                            ficheiro_pergunta: [],
                            tipo_pergunta: undefined,
                            ordem: 1,
                            texto_resolucao: "",
                            ficheiro_resolucao: [],
                            opcoes2: []
                        }
                    ]
                })
            else
                this.setState({
                    questoes2: this.props.questoes2
                })
        }
    };

    render() {
        const { TextArea } = Input;

        return (
            <Drawer
                className="drawer-from-questionario"
                //title={this.props.questionario ? "Editar" : "Adicionar"}
                width={720}
                onClose={this.voltar}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-aula lista-questoes" layout="horizontal" hideRequiredMark>
                        {this.state.questoes2.map((questao, index) => (
                            <React.Fragment key={"lvl1" + index}>
                                <div className="bloco-questao">
                                    <div className="dados-questao">
                                        <Link to="#" className="btn-excluir" onClick={() => this.handleChangeExcluirQuestao(index)}>
                                            <Icon type="delete" />
                                        </Link>
                                        <div className="tipo-questao">
                                            <Form.Item hasFeedback label="Ordem">
                                                <InputNumber value={questao.ordem} onChange={e => this.handleChangeOrdem(e, index)} />
                                            </Form.Item>
                                            <Form.Item hasFeedback label="Tipo">
                                                <Select placeholder="Selecionar" allowClear={true} onChange={e => this.handleChangeTipo(e, index)} value={questao.tipo_pergunta}>
                                                    <Option value="Escolha única">Escolha única</Option>
                                                    <Option value="Escolha múltipla">Escolha múltipla</Option>
                                                    <Option value="Escala 1 a 5">Escala 1 a 5</Option>
                                                    <Option value="Escala 1 a 10">Escala 1 a 10</Option>
                                                    <Option value="Resposta livre">Resposta livre</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <Form.Item hasFeedback label="Questão">
                                            <TextArea value={questao.pergunta} onChange={e => this.handleChangeQuestao(e, index)} style={{ height: "100%", marginBottom: 10 }} rows={2} />
                                        </Form.Item>
                                        {questao.opcoes2.length > 0 && (
                                            <div className="bloco-respostas">
                                                <button className="botao-principal" onClick={e => this.adicionarOpcao(e, index)}>
                                                    <Icon type="plus" /> Opção
                                                </button>
                                                {questao.opcoes2.map((opcao, index2) => (
                                                    <div key={"lvl2" + index2} className="resposta">
                                                        <Form.Item
                                                            label={<>
                                                                Opção
                                                                <button className={`botao-excluir-questionario ${questao.opcoes2.length <= 2 ? "botao-excluir-questionario-disable" : ""}`} disabled={questao.opcoes2.length <= 2} onClick={() => this.handleChangeExcluir(index, index2)}>
                                                                    <Icon type="delete" />
                                                                </button>
                                                            </>}
                                                            className="resposta-input"
                                                        >
                                                            <TextArea value={opcao.texto} onChange={e => this.handleChangeOpcao(e, index, index2)} style={{ height: "100%" }} rows={1} />
                                                        </Form.Item>
                                                    </div>
                                                ))}
                                                <button className="botao-principal" onClick={e => this.adicionarOpcao(e, index)} style={{ marginBottom: 10, marginTop: 10 }}>
                                                    <Icon type="plus" /> Opção
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.voltar} style={{ marginRight: 20 }}>
                        Voltar
                    </button>
                    {this.state.btn_reload ? (
                        <button className="botao-secundario" title="Reordenar questões" onClick={this.ReordenarQuestoes} style={{ marginRight: 20 }}>
                            Reordenar questões
                        </button>
                    ) : null}
                    <button className="botao-principal" title="Adicionar questão" onClick={this.addQuestao}>
                        <Icon type="plus" /> Questão
                    </button>
                </div>
            </Drawer>
        );
    }
}

export default FormQuestionario;