import React from "react";
import {Link, useLocation} from "react-router-dom";
export default function (props) {
    const { capa, titulo, descricao, foto_autor, autor, data, id } = props;
    
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entidade = pathParts[1]
    
    

    const monthNames = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];
    const isoDate = data;
    const date = new Date(isoDate);

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    const formattedDate = `${day} de ${month} de ${year}`;
    
    return(
       
        <div className="card " >
            <Link to={`/${entidade}/noticia/${id}`}>
                <img className="card_img" style={{ height: '200px' }} src={capa} alt="img3" />
                
                <div>
                    <h3 className="p-1">{titulo}</h3>
                <p className="p-1 descricao"> {descricao}</p>
            </div>

                <div className="container p-0 ">
                    {foto_autor ? (
                        <div className="autor row align-items-center w-100">
                            <div className="col-3 p-0">
                                <img className="card_autor_img" src={foto_autor} />
                            </div>
                            <div className="col-8 p-0">
                                <div>
                                    <span className="bold ">{autor}</span>
                                    {data != null &&
                                        <p> {formattedDate}</p>}
                                </div>
                            </div>
                            <div className={`row  p-0 ${foto_autor !== undefined && foto_autor !== "" ? ("col-1") : ("col-4")} justify-content-end`}>
                                <img className="icon " src="/crad1.png" />
                            </div>
                        </div>
                    )
                        :
                        (
                            <div className="autor row align-items-center w-100" style={{ justifyContent: "space-between" }}>
                                <div className="col-8 p-0">
                                    <div>
                                        <span className="bold ">{autor}</span>
                                        {data != null &&
                                            <p> {formattedDate}</p>}
                                    </div>
                                </div>
                                <div className={`row  p-0 ${foto_autor !== undefined && foto_autor !== "" ? ("col-1") : ("col-4")} justify-content-end`}>
                                    <img className="icon " src="/crad1.png" />
                                </div>

                            </div>)
                    }
            </div>
            </Link>
        </div>
        
    );
}