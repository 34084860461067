import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table, Icon } from 'antd';
import axios from "axios";

const Detalhe = ({ match, record }) => {
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(false);

    const carregarDados = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-dados-aproveitamento-periodo",
            params: {
                code: match.params.code,
                disciplinaId: record.id
            }
        })
            .then(response => {
                setDados(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                setLoading(false);
            });
    };

    const cols = [
        {
            title: "",
            dataIndex: "nome",
            width: 150,
        },
        {
            title: "",
            dataIndex: "notas",
        }
    ];

    useEffect(() => {
        if (record)
            carregarDados();
    }, [record])

    return (
        <Table
            showHeader={false}
            className="tabela-aproveitamento"
            columns={cols}
            pagination={false}
            dataSource={dados}
            loading={{
                spinning: loading,
                indicator: (
                    <div className="loading-data-table">
                        <div className="loading" />
                    </div>
                )
            }}
        />
    );
}

export default withRouter(Detalhe);