import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Drawer,
    Icon,
    Form,
    Input,
    Select,
    Collapse,
    notification,
    DatePicker
} from "antd";
import moment from "moment";
import uuid from 'react-uuid'

const { Panel } = Collapse;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerPeriodo = ({ onClose, atualizarAdicionado, atualizarEditado, visibleDrawerPeriodo, estado, periodo, form }) => {
    const [titulo, setTitulo] = useState("")
    const [inicio, setInicio] = useState("")
    const [termino, setTermino] = useState("")
    const [ordem, setOrdem] = useState(1)
    const [iconLoading, setIconLoading] = useState(false)
    const { getFieldDecorator } = form;

    const handleChangeTitulo = (event) => {
        setTitulo(event.target.value);
    };

    const handleChangeInicio = (date) => {
        setInicio(date)
    };

    const handleChangeTermino = (date) => {
        setTermino(date);
    };

    const handleChangeOrdem = (event) => {
        setOrdem(event.target.value);
    };

    const detalhe = () => {
        if (periodo) {
            setTitulo(periodo.nome);
            setInicio(moment(periodo.inicio, "YYYY-MM-DD"));
            setTermino(moment(periodo.termino, "YYYY-MM-DD"));
            setOrdem(periodo.ordem);
        }
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                try {
                    atualizarAdicionado({
                        id: uuid(),
                        idPai: 0,
                        nome: titulo,
                        inicio: inicio.format("YYYY/MM/DD"),
                        termino: termino.format("YYYY/MM/DD"),
                        ordem: ordem,
                        ativo: true
                    });
                }
                catch {
                    openNotificationWithIcon("error", "Erro", "Não foi possível adicionar o período.")
                }
            }
        })
    }

    const guardar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                atualizarEditado({
                    id: periodo.id,
                    idPai: 0,
                    nome: titulo,
                    inicio: inicio.format("YYYY/MM/DD"),
                    termino: termino.format("YYYY/MM/DD"),
                    ordem: ordem,
                    ativo: periodo.ativo
                });
            }
        })
    }

    const afterVisibleChange = aberto => {
        if(aberto) {
            form.resetFields();
            if (estado === "EDITAR")
                detalhe();
            else {
                setTitulo("")
                setInicio("")
                setTermino("")
                setOrdem(1)
                setIconLoading(false)
            }
        }
    }

    return (
        <Drawer
            className="drawer-add-cursos"
            title="Período"
            width={720}
            onClose={onClose}
            visible={visibleDrawerPeriodo}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias listagem-avaliacoes" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label="Titulo">
                            {getFieldDecorator("titulo", {
                                initialValue: titulo,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="titulo" onChange={handleChangeTitulo} />)}
                        </Form.Item>
                        <Form.Item label="Inicio">
                            {getFieldDecorator("inicio", {
                                initialValue: inicio,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(
                                <DatePicker
                                    name="inicio"
                                    format="DD-MM-YYYY"
                                    onChange={handleChangeInicio}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Término">
                            {getFieldDecorator("termino", {
                                initialValue: termino,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(
                                <DatePicker
                                    name="termino"
                                    format="DD-MM-YYYY"
                                    onChange={handleChangeTermino}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Ordem">
                            {getFieldDecorator("ordem", {
                                initialValue: ordem,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="ordem" onChange={handleChangeOrdem} />)}
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </button>
                {estado === "EDITAR" ?
                    <button className="botao-principal" onClick={guardar} disabled={iconLoading} type="primary">
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                    :
                    <button className="botao-principal" onClick={adicionar} disabled={iconLoading} type="primary">
                        {iconLoading ? <Icon type="loading" /> : null}
                        Adicionar
                    </button>
                }
            </div>
        </Drawer>
    );
}

const FormDrawerPeriodo = Form.create({ name: "drawer-periodo" })(DrawerPeriodo);

export default FormDrawerPeriodo;