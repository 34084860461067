import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Controlos from "./_Aux/Controlos";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Vimeo from "@vimeo/player";

class Pagina extends Component {
    state = {
        vimeo: "",
        //LOADING
        loading: true,
        //REDIRECT
        redirect: false,
        redirectLink: "",
    };

    componentDidMount() {
        this.CarregarConteudo();
    }

    //CARREGA CONTEUDO DA AULA
    CarregarConteudo = () => {
        this.setState({
            vimeo: "https://player.vimeo.com/video/" + this.props.match.params.vconfid,
            loading: false
        }, () => {
            var iframe = document.querySelector("iframe");
            var player = new Vimeo(iframe);

            this.setState({
                player
            })

            player.on("seeked", data => {
                this.setState({ current: data.seconds });
            });
        });
    };

    render() {
        const {
            vimeo,
            //LOADING
            loading,
            //REDIRECT
            redirect,
            redirectLink,
        } = this.state;
        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                {this.state.loading ?
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                    :
                    <div className="player">
                        <div className="player-wrapper">
                            <iframe src={vimeo} id="player"></iframe>
                            <Controlos />
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default Pagina;
