import React, { Component } from "react";
import { Input, Form, Tooltip, Icon, Upload, Checkbox } from "antd";

const { TextArea } = Input;
const Dragger = Upload.Dragger;

class Responder extends Component {
    render() {
        const props = {
            multiple: true,
            onChange: this.props.onChangeUpload,
            onRemove: this.props.onRemoveUpload,
            beforeUpload: this.props.beforeUpload,
            fileList: this.props.fileList
        };

        const { iconLoading } = this.props;

        return (
            <div className="responder">
                <Form>
                    <Form.Item validateStatus={this.props.status} help={this.props.helper}>
                        <label>Mensagem</label>
                        <TextArea name="" id="" rows="8" placeholder="Mensagem ..." onChange={this.props.handleChangeTextArea} />
                    </Form.Item>
                    <Form.Item validateStatus={this.props.validateStatus} help={this.props.help}>
                        <div className="anexos">
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <i className="fas fa-upload" />
                                </p>
                                <p className="ant-upload-text">
                                    Adicionar anexos{" "}
                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </p>
                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                            </Dragger>
                        </div>
                    </Form.Item>
                    {/*<Form.Item className="form-item-checkbox">
                        <Checkbox name="notificar" checked={this.props.notificar} onChange={this.props.handlerChangeCheck}>
                            Notificar por e-mail
                        </Checkbox>
                    </Form.Item>*/}
                </Form>
                <div className="responder-controlos">
                    <button
                        className="botao-principal"
                        disabled={iconLoading}
                        onClick={e => this.props.enviar(e)}
                    >
                        {iconLoading ? <Icon type="loading" /> : null} Enviar
                        </button>
                    <button
                        className="botao-secundario"
                        onClick={() => this.props.cancelar_topo()}
                    >
                        Cancelar
                        </button>
                </div>
            </div>
        );
    }
}

export default Responder;
