import React, { useState, useEffect, useRef } from "react";
import { Menu, Switch, Button, Icon } from "antd";
import Relatorio from "./Usuarios/Logs";
import Turmas from "./Turmas/Logs";
import Disciplinas from "./Disciplinas/Disciplinas";
import Logins from "./Usuarios/Logins/Logins";
import Aulas from "./Aulas/Aulas";
import Pareceres from "./Aulas/Pareceres/Pareceres";
import Sumarios from "./Aulas/Sumarios/Sumarios";
import Assiduidade from "./Aulas/Assiduidade/Assiduidade";
import Inscricoes from "./Disciplinas/Inscricoes/Inscricoes";
import Avaliacoes from "./Disciplinas/Avaliacoes/Avaliacoes";
import Notas from "./Disciplinas/Notas/Notas";
import Frequencias from "./Aulas/Frequencias/Frequencias";
import RelatorioObservacoesCurso from "../Relatorios/ObservacoesCurso/Relatorio";
import RelatorioPareceres from "../Relatorios/Pareceres/Relatorio";
import RelatorioPareceresCurso from "../Relatorios/PareceresCurso/Relatorio";

const { SubMenu } = Menu;

function Logs() {
    const [menuOptionSelected, setMenuOptionSelected] = useState(null);
    const [isMenuVisible, setMenuVisible] = useState(true);
    const menuRef = useRef();

    const useWindowWidth = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            // Function to update windowWidth state with current innerWidth
            const handleWindowResize = () => {
                setWindowWidth(window.innerWidth);
            };

            // Event listener for window resize
            window.addEventListener("resize", handleWindowResize);

            // Cleanup: remove event listener on unmount
            return () => {
                window.removeEventListener("resize", handleWindowResize);
            };
        }, []);

        return windowWidth;
    };

    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (windowWidth > 1025) {
            return;
        } else {
            let handler = e => {
                if (!menuRef.current.contains(e.target)) {
                    setMenuVisible(false);
                }
            };
            document.addEventListener("mousedown", handler);

            return () => {
                document.removeEventListener("mousedown", handler);
            };
        }
    }, []);

    const handleClick = e => {
        setMenuOptionSelected({
            menuItemKey: e.keyPath[0],
            menuItemName: e.item.props.children
        });
    };

    const buildReport = () => {
        var key = menuOptionSelected.menuItemKey;

        if (key === "sub1-1") return <Logins menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub1-2" || key === "sub1-3" || key === "sub1-4" || key === "sub1-5" || key === "sub1-6")
            return <Relatorio menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub2-1" || key === "sub2-3") return <Turmas menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub2-2") return <Disciplinas menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub3-1") return <Disciplinas menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub3-2") return <Inscricoes menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub3-3") return <Avaliacoes menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub3-4") return <Notas menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub3-5" || key === "sub3-6") return <Relatorio menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub4-1") return <Aulas menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub4-2") return <Sumarios menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub4-3") return <Assiduidade menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub4-4") return <Pareceres menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub4-5") return <Frequencias menuOptionSelected={menuOptionSelected} />;
    };

    const buildMenu = () => {
        return (
            <>
                <Button
                    style={windowWidth > 1025 ? { display: "none" } : {}}
                    type="primary"
                    className="search-button botao-principal"
                    onClick={x => {
                        setMenuVisible(true);
                    }}
                >
                    <Icon type="arrow-left" />
                </Button>
                {buildReport()}
            </>
        );
    };

    return (
        <div className="reports" style={{ marginTop: 80 }}>
            <div className="menu" style={windowWidth > 1025 ? {} : { position: "fixed", zIndex: "1" }} ref={menuRef}>
                {isMenuVisible ? (
                    <Menu onClick={handleClick} style={{ width: 256 }} mode="inline">
                        <SubMenu key="sub2" title="Turmas">
                            <Menu.Item key="sub2-1">Dados Gerais</Menu.Item>
                            <Menu.Item key="sub2-2">Disciplinas</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub3" title="Disciplinas">
                            <Menu.Item key="sub3-1">Dados Gerais</Menu.Item>
                            <Menu.Item key="sub3-2">Inscricoes</Menu.Item>
                            <Menu.Item key="sub3-3">Avaliacao</Menu.Item>
                            <Menu.Item key="sub3-4">Notas</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub4" title="Aulas">
                            <Menu.Item key="sub4-1">Dados Gerais</Menu.Item>
                            <Menu.Item key="sub4-2">Sumarios</Menu.Item>
                            <Menu.Item key="sub4-3">Assiduidade</Menu.Item>
                            <Menu.Item key="sub4-4">Pareceres</Menu.Item>
                            <Menu.Item key="sub4-5">Frequencias</Menu.Item>
                        </SubMenu>
                        {/*<Menu.Item key="9" disabled>Conselho de classe <i className="soon">Brevemente</i></Menu.Item>*/}
                    </Menu>
                ) : null}
            </div>

            {menuOptionSelected ? (
                buildMenu()
            ) : (
                <>
                    <Button
                        style={windowWidth > 1025 ? { display: "none" } : {}}
                        type="primary"
                        className="search-button botao-principal"
                        onClick={x => {
                            setMenuVisible(true);
                        }}
                    >
                        <Icon type="arrow-left" />
                    </Button>

                    <div className="report-not-selected">
                        <div className="page-container">
                            <img src={require("../images/select-option.svg")} />
                            <h1>Selecione o relatório pretendido.</h1>
                            <h3>Utilize as opções no menu da esquerda para selecionar o relatório que pretende consultar.</h3>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Logs;

/**
 * 
 *                         <SubMenu key="sub1" title="Usuários">
                            <Menu.Item key="sub1-1">Logins / Logouts</Menu.Item>
                            <Menu.Item key="sub1-2">Estabelecimentos</Menu.Item>
                            <Menu.Item key="sub1-3">Por Curso</Menu.Item>
                            <Menu.Item key="sub1-4">Navegacao</Menu.Item>
                            <Menu.Item key="sub1-5">Counteudos</Menu.Item>
                            <Menu.Item key="sub1-6">Documentos</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" title="Turmas">
                            <Menu.Item key="sub2-1">Dados Gerais</Menu.Item>
                            <Menu.Item key="sub2-2">Disciplinas</Menu.Item>
                            <Menu.Item key="sub2-3">Documentos</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub3" title="Disciplinas">
                            <Menu.Item key="sub3-1">Dados Gerais</Menu.Item>
                            <Menu.Item key="sub3-2">Inscricoes</Menu.Item>
                            <Menu.Item key="sub3-3">Avaliacao</Menu.Item>
                            <Menu.Item key="sub3-4">Conteudos</Menu.Item>
                            <Menu.Item key="sub3-5">Navegacao</Menu.Item>
                            <Menu.Item key="sub3-6">Documentos</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub4" title="Aulas">
                            <Menu.Item key="sub4-1">Dados Gerais</Menu.Item>
                            <Menu.Item key="sub4-2">Sumarios</Menu.Item>
                            <Menu.Item key="sub4-3">Assiduidade</Menu.Item>
                            <Menu.Item key="sub4-4">Pareceres</Menu.Item>
                        </SubMenu>
 * 
 */
