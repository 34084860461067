import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Switch, Table, Icon, Modal, notification } from "antd";
import DrawerPagina from "./DrawerPagina";
import Artigos from "./Artigos/Artigos";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Paginas extends Component {
    state = {
        paginas: [],
        paginasMobile: [],
        ativo: true,
        paginaId: "",
        tituloArtigos: "",
        ordem: 1,
        //LOADING
        loading_table: false,
        //DRAWER
        visible: false,
        //INFORMACOES
        paginaInformacoesVisivel: false
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA CATEGORIA NA TABELA
    montarMenu = paginaId => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-enviar"
                        title="Artigos"
                        onClick={event => {
                            event.stopPropagation();
                            this.carregarArtigos(paginaId);
                        }}
                    >
                        <Icon type="file-text" />
                    </Link>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativar(paginaId, this.state.ativo);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        this.ativar(paginaId, this.state.ativo);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        this.excluir(paginaId);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    adicionar = () => {
        this.setState({
            paginaId: "",
            visible: true
        });
    };

    editar = paginaId => {
        this.setState({
            paginaId,
            visible: true
        });
    };

    excluir = paginaId => {
        confirm({
            title: "Pretende excluir esta página?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/empresa/excluir-pagina",
                    params: {
                        paginaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Página excluida!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    ativar = (paginaId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar esta página?" : "Pretende ativar esta página?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/empresa/ativar-pagina",
                    params: {
                        paginaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Página inativada!" : "Página ativada!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a página!" : "Não foi possível ativar a página!"
                        );
                    });
            }
        });
    };

    carregarArtigos = paginaId => {
        var pagina = this.state.paginas.find(x => x.id === paginaId);

        this.setState({
            paginaId,
            tituloArtigos: `${pagina.estabelecimento} - ${pagina.pagina}`,
            paginaInformacoesVisivel: true
        });
    };

    voltar = () => {
        this.setState({
            paginaId: "",
            paginaInformacoesVisivel: false
        });
    };

    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/empresa/listar-paginas",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var paginas = [];
                        var paginasMobile = [];

                        response.data.map((pagina, index) => {
                            paginas.push({
                                key: index,
                                id: pagina.id,
                                estabelecimento: `${pagina.estabelecimento} (${pagina.abreviatura})`,
                                pagina: pagina.titulo,
                                ordem: pagina.ordem,

                                opcoes: this.montarMenu(pagina.id)
                            });
                            paginasMobile.push({
                                key: index,
                                id: pagina.id,
                                estabelecimento: `${pagina.abreviatura} - ${pagina.estabelecimento}`,
                                pagina: (
                                    <div className="bloco-pagina">
                                        <div className="info-pagina">
                                            <span className="titulo-pagina">{pagina.titulo}</span>
                                            <span className="ordem-pagina">
                                                <strong>Ordem:</strong> {pagina.ordem}
                                            </span>
                                        </div>
                                    </div>
                                ),
                                ordem: pagina.ordem,
                                opcoes: this.montarMenu(pagina.id)
                            });
                        });

                        this.setState({
                            paginas,
                            paginasMobile,
                            ordem: response.data.length + 1,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    atualizarRegisto = () => {
        this.setState({
            visible: false
        });
        this.listar();
    };

    onRowClick = paginaId => {
        this.editar(paginaId);
    };

    render() {
        const {
            paginas,
            paginasMobile,
            paginaId,
            tituloArtigos,
            ordem,
            //LOADING
            loading_table,
            //DRAWER
            visible,
            //INFORMACOES
            paginaInformacoesVisivel
        } = this.state;

        const columns = [
            {
                title: "Estabelecimento",
                dataIndex: "estabelecimento"
            },
            {
                title: "Ordem",
                dataIndex: "ordem",
                width: 100
            },
            {
                title: "Página",
                dataIndex: "pagina"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const columnsMobile = [
            {
                title: "Página",
                dataIndex: "pagina"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        return (
            <>
                {!paginaInformacoesVisivel ? (
                    <div className="bloco-minha-conta">
                        <div className="bloco-gestor-paginas">
                            <div className="controlos">
                                <button
                                    className="botao-principal botao-principal-redondo"
                                    title="Adicionar"
                                    onClick={this.adicionar}
                                    disabled={!this.state.ativo}
                                >
                                    +
                                </button>
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <div className="bloco-tabela">
                                <Table
                                    id="tabela_categorias"
                                    className="tabela-paginas-desktop"
                                    loading={{
                                        spinning: loading_table,
                                        indicator: (
                                            <div className="loading-data-table">
                                                <div className="loading" />
                                            </div>
                                        )
                                    }}
                                    columns={columns}
                                    dataSource={paginas}
                                    pagination={false}
                                    rowClassName="clickeble-row"
                                    onRow={(record, index) => {
                                        return {
                                            onClick: () => this.onRowClick(record.id)
                                        };
                                    }}
                                    locale={{ emptyText: "Não existem dados!" }}
                                />
                                <Table
                                    id="tabela_categorias"
                                    className="tabela-paginas-mobile"
                                    loading={{
                                        spinning: loading_table,
                                        indicator: (
                                            <div className="loading-data-table">
                                                <div className="loading" />
                                            </div>
                                        )
                                    }}
                                    columns={columnsMobile}
                                    dataSource={paginasMobile}
                                    pagination={false}
                                    rowClassName="clickeble-row"
                                    onRow={(record, index) => {
                                        return {
                                            onClick: () => this.onRowClick(record.id)
                                        };
                                    }}
                                    locale={{ emptyText: "Não existem dados!" }}
                                />
                            </div>
                        </div>
                        <DrawerPagina
                            ativo={this.state.ativo}
                            paginaId={paginaId}
                            ordem={ordem}
                            visible={visible}
                            onClose={() => this.setState({ visible: false })}
                            atualizarRegisto={this.atualizarRegisto}
                        />
                    </div>
                ) : (
                    <Artigos paginaId={paginaId} titulo={tituloArtigos} voltar={this.voltar} />
                )}
            </>
        );
    }
}

export default Paginas;
