import React from "react";

const Declaracoes = ({ declaracao }) => {

    return (
        <div>
            {declaracao === "1" ?
                <img
                    src="/uploads/declaracoes/Declaração-de-Escolaridade.png"
                    style={{ width: "100%" }}
                /> : null}
            {declaracao === "2" ?
                <img
                    src="/uploads/declaracoes/Declaração-de-Escolaridade-EJA.png"
                    style={{ width: "100%" }}
                /> : null}
            {declaracao === "3" ?
                <img
                    src="/uploads/declaracoes/Declaração-de-Transferência-EJA.png"
                    style={{ width: "100%" }}
                /> : null}
            {declaracao === "4" ?
                <img
                    src="/uploads/declaracoes/DECLARAÇÃO-DE-CONCLUSÃO-BELO-MONTE.png"
                    style={{ width: "100%" }}
                /> : null}
            {declaracao === "5" ?
                <img
                    src="/uploads/declaracoes/DECLARAÇÃO-DE-ESCOLARIDADE-BELO-MONTE.png"
                    style={{ width: "100%" }}
                /> : null}
            {declaracao === "6" ?
                <img
                    src="/uploads/declaracoes/DECLARAÇÃO-DE-TRANSFERÊNCIA-BELO-MONTE.png"
                    style={{ width: "100%" }}
                /> : null}
            {declaracao === "7" ?
                <img
                    src="/uploads/declaracoes/Atestado-de-Escolaridade.png"
                    style={{ width: "100%" }}
                /> : null}
            {declaracao === "8" ?
                <img
                    src="/uploads/declaracoes/Declaração-de-Matrícula.png"
                    style={{ width: "100%" }}
                /> : null}
        </div>
    );
};

export default Declaracoes;
