import React, { useState, useEffect, useContext } from "react";
import {Link, Redirect, useLocation} from "react-router-dom";
import {
    Form,
    notification,
    Icon,
    Drawer,
    Checkbox,
} from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerComentario = ({ ativo, visible, id,tipo, form, onClose, atualizar }) => {
    const [formData, setFormData] = useState({
        opcao: false,
        capa: "",
        preview: "",
        titulo: "",
        texto: "",
        link: "",
        data: null,
        hora: null,
        selecionados: [],
        dataVisivelAte: null,
        previews: [null, null, null],
        files: [null, null, null],
        validado: "",
        comentario: "",
        nome: "",
        email: "",
        estabelecimento:""
    });
    const [validate, setValidate] = useState({
        totalBytes: 0,
        formatoValido: false,
        validateStatus: "",
        help: ""
    });
    const [loading, setLoading] = useState(false);
    
    const guardar = () => {
        const item = new FormData();
        item.append('comentario1', JSON.stringify({
            id,
            validado: formData.validado
        }));
        
        form.validateFieldsAndScroll(async err => {
            setLoading(true);
            try {
                const response = await axios(`/api/comentario/${ tipo === 'Resposta' ? ('resposta_comentario'):('comentario')}/toggle-validado`, {
                    method: 'PATCH',
                    data: item
                });
                if (response.status === 200 ) {
                    openNotificationWithIcon("success", "Sucesso", "Informação guardada!");
                    setLoading(false);
                    atualizar();

                } else {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar");
                    setLoading(false);
                }
            } catch (error) {
                openNotificationWithIcon("error", "Erro", "Não foi possível guardar");
                setLoading(false);
            }
        })
    };
    
    // Função para alterar o estado do formData.validado
    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, validado: e.target.checked });
    };

    const carregarDetalhe = () => {
        axios({
            method: "get",
            url: `/api/comentario/${ tipo === 'Resposta' ? ('resposta-comentario'):('comentario')}/listarId`,
            params: {
                id
            }
        })
            .then(response => {
                setFormData({
                    validado: response.data[0].validado,
                    comentario: response.data[0].comentario,
                    nome: response.data[0].nome,
                    email: response.data[0].email,
                    estabelecimento: response.data[0].estabelecimento,
                    
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar informação");
            });
    };
    

    const afterVisibleChange = aberto => {
        if (aberto) {
            form.resetFields();
            //document.getElementById("input-foto").value = "";
            if (id > 0) carregarDetalhe();
            else {
                setFormData({
                    opcao: false,
                    capa: "",
                    preview: "",
                    titulo: "",
                    descricao: "",
                    link: "",
                    data: null,
                    hora: null,
                    selecionados: [],
                    dataVisivelAte: null
                });
                setValidate({
                    totalBytes: 0,
                    formatoValido: false,
                    validateStatus: "",
                    help: ""
                });
            }
        }
    };

    return (
        <Drawer
            className="drawer-add-cursos drawer-videoconferencias"
            //title={noticiaId > 0 ? "Alterar" : "Adicionar"}
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">

                <div style={{margin:"20px 0"}}>
                    <span style={{fontWeight:"bold"}}> Nome: </span>
                    <span> {formData.nome}</span>
                </div>

                <div  style={{margin:"20px 0"}}>
                    <span style={{fontWeight:"bold"}} > Email: </span>
                    <span> {formData.email}</span>
                </div>
                <div  style={{margin:"20px 0"}}>
                    <span style={{fontWeight:"bold"}} > Estabelecimento: </span>
                    <span> {formData.estabelecimento}</span>
                </div>
                <div  style={{margin:"20px 0"}}>
                    <span style={{fontWeight:"bold"}} > Comentário: </span>
                    <span> {formData.comentario}</span>
                </div>

                <Form className="form-categorias" layout="horizontal">
                    <Form.Item
                        name="validado"
                        valuePropName="checked"
                        label="Validado"
                    >
                        <Checkbox checked={formData.validado} onChange={handleCheckboxChange}>
                            Validado
                        </Checkbox>
                    </Form.Item>

                </Form>
            </div>
            <div className="ant-drawer-footer">
                <Link to="#" className="botao-secundario" onClick={onClose}
                      style={{marginRight: 20, display: "inline-block" }}>
                    Voltar
                </Link>
                {ativo && (
                    <button className="botao-principal" disabled={loading} onClick={guardar}>
                        {loading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                )}
            </div>
        </Drawer>
    );
};

const FormDrawerComentario = Form.create({ name: "form-drawer-comentario" })(DrawerComentario);

export default FormDrawerComentario;
