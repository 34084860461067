import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class Menu extends Component {
    render() {
        return (
            <div className="menu">
                <div className="menu-filtros">
                    <div className={"menu-item " + `${this.props.opcaoSelecionado === "AGENDADAS" ? "ativo" : ""}`} onClick={() => this.props.selecionarOpcao("AGENDADAS")}>
                        Agendadas
                    </div>
                    <div className={"menu-item " + `${this.props.opcaoSelecionado === "REALIZADAS" ? "ativo" : ""}`} onClick={() => this.props.selecionarOpcao("REALIZADAS")}>
                        Realizadas
                    </div>
                    <div className={"menu-item " + `${this.props.opcaoSelecionado === "NAO_REALIZADAS" ? "ativo" : ""}`} onClick={() => this.props.selecionarOpcao("NAO_REALIZADAS")}>
                        Não Realizadas
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Menu);
