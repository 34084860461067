import React, { Component } from "react";
import { Modal, Icon, Form, Radio, Tooltip } from "antd";

class Excluir extends Component {
    render() {
        const {
            visible,
            opcao,
        } = this.props;

        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginBottom: 0,
        };

        return (
            <>
                <Modal
                    visible={visible}
                    width="450px"
                    className="modal-encerrar-stepmeet"
                    cancelText="Cancelar"
                    onCancel={this.props.onClose}
                    okText="Confirmar"
                    okButtonProps={{ disabled: !opcao }}
                    onOk={this.props.excluir}
                >
                    <Icon type="question-circle" className="icon-modal-encerrer-stepmeet" />
                    <p className="texto-modal-encerrar-stepmeet">Pretende excluir?</p>
                    <Form>
                        <Form.Item>
                            <Radio.Group onChange={this.props.onChangeSelect} value={opcao}>
                                <Radio style={radioStyle} value={1}>
                                    Esta aula
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    Esta aula e as seguintes
                                    <Tooltip className="info-icon info-icon2" title="Esta opção não excluí aulas que contenham registos de conteúdos, frequências e pareceres.">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default Excluir;
