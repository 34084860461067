import React, { useState, useEffect, useContext } from "react";
import { notification, Drawer, Input, Card, Upload } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../../images/noimage.png";
import { GlobalContext } from "../../../../GlobalState";
import DrawerListaCursos from "../DrawerListaCursos/DrawerListaCursos";

const { Search } = Input;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerInscricao = ({ visible, onClose }) => {
    const [pesquisa, setPesquisa] = useState("");
    const [utilizadores, setUtilizadores] = useState([]);
    const [utilizador, setUtilizador] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const context = useContext(GlobalContext);

    const pesquisar = value => {
        if (value) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/gestao-curso-inscricoes/pesquisar-utilizador-curso-disciplinas",
                params: {
                    pesquisa: value,
                    cursoid: localStorage.getItem("codigo_curso"),
                    organismoId: localStorage.getItem("escola_id")
                }
            })
                .then(response => {
                    setUtilizadores(response.data);
                    setLoading(false);
                })
                .catch(() => { });
        }
        else
            setUtilizadores([]);
    };

    const listarCursos = () => {
        axios({
            method: "get",
            url: "/api/gestao-curso-inscricoes/listar-subcursos",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setCursos(response.data);
            })
            .catch(() => { });
    }

    const inscreverEmTodos = (event, utilizadorId) => {
        event.preventDefault();
        axios({
            method: "post",
            url: "/api/gestao-curso-inscricoes/inscrever-utilizador",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                utilizadorId
            }
        })
            .then(() => {
                const utilizadorIndex = utilizadores.findIndex(x => x.id === utilizadorId);
                const tempUtilizador = utilizadores;
                tempUtilizador[utilizadorIndex].inscricoes = cursos.map(x => x.id);
                tempUtilizador[utilizadorIndex].tudo = true;
                setUtilizadores(tempUtilizador);

                openNotificationWithIcon("success", "Sucesso", "Usuário inscrito em todos os cursos!");
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    };

    const abrirDrawer = (utilizador) => {
        setUtilizador(utilizador);
        setDrawerVisible(true);
    }

    const confirmarDrawer = (selecionados) => {
        const utilizadorIndex = utilizadores.findIndex(x => x.id === utilizador.id);
        const tempUtilizador = utilizadores;
        tempUtilizador[utilizadorIndex].inscricoes = selecionados;
        setUtilizadores(tempUtilizador);
        setDrawerVisible(false);
    }

    const afterVisibleChange = visible => {
        if (!visible) {
            setPesquisa("");
            setUtilizadores([]);
        }
    }

    useEffect(() => {
        listarCursos();
    }, [])

    return (
        <>
            <Drawer
                className="drawer-inscrever"
                //title="Inscrições"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <div className="pesquisar">
                        <Search
                            placeholder="Coloque o nome, código ou e-mail a pesquisar..."
                            onChange={e => setPesquisa(e.target.value)}
                            onSearch={pesquisar}
                            style={{ width: 400 }}
                            allowClear={true}
                            value={pesquisa}
                        />
                    </div>
                    <div className="listagem" style={{ marginTop: 30 }}>
                        {loading ? (
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                        ) : (
                            utilizadores.map((utilizador, index) => (
                                <Card key={index}>
                                    <div className="bloco-info-card-head">
                                        <div className="container-img">
                                            <img src={utilizador.foto ? utilizador.foto : noimage} alt="Foto" />
                                        </div>
                                        <span className="nome">{utilizador.nome_completo}</span>
                                    </div>
                                    <p>
                                        <strong>Código:</strong> {utilizador.nr_aluno} {utilizador.pendente ? <span className="info-estado-pendente">Registo Pendente</span> : ""}
                                    </p>
                                    <p>
                                        <strong>Email:</strong> {utilizador.email}
                                    </p>
                                    {utilizador.contacto && (
                                        <p>
                                            <strong>Contacto:</strong> {utilizador.contacto}
                                        </p>
                                    )}
                                    {utilizador.organismo && context.escolas_entidade && (
                                        <p>
                                            <strong>Organismo:</strong> {utilizador.organismo}
                                        </p>
                                    )}
                                    <div className="card-footer-controlos">
                                        <button className="botao-principal" disabled={cursos.length === utilizador.inscricoes.length || utilizador.tudo} onClick={e => inscreverEmTodos(e, utilizador.id)}>
                                            Inscrever em todos
                                        </button>
                                        <button className="botao-principal" onClick={() => abrirDrawer(utilizador)}>
                                            Selecionar cursos
                                        </button>
                                    </div>
                                </Card>
                            ))
                        )}
                    </div>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                </div>
            </Drawer>
            <DrawerListaCursos
                visible={drawerVisible}
                cursos={cursos}
                utilizador={utilizador}
                onClose={() => setDrawerVisible(false)}
                onConfirm={(selecionados) => confirmarDrawer(selecionados)}
            />
        </>
    );
}

export default DrawerInscricao;
