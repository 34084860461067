import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../User/Header";
import Cursos from "../Cursos/Cursos";
import axios from "axios";
import Oops from "../Geral/Oops";
import { GlobalContext } from "../GlobalState";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        loading: true,
        cursos: [],
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.listarCursos();
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    listarCursos = () => {
        axios({
            method: "get",
            url: "/api/favoritos/listar-favoritos"
        })
            .then(response => {
                this.setState({
                    cursos: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            loading,
            cursos,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Favoritos" negativeTop />
                <div className="container container-body lista-favoritos">
                    {this.state.loading ? (
                        <div className="loading-data">
                            <div className="loading" />
                        </div>
                    ) : cursos.length > 0 ? (
                        <Cursos loading={loading} cursos={cursos} keytext="categorias" />
                    ) : (
                        <Oops />
                    )}
                </div>
            </>
        );
    }
}

export default Main;
