import React, { useState } from 'react';
import '../../../scss/_cardCarrosel.scss'
import TituloSeccao from "./tituloSeccao";

const CardCarousel = ({ cards, titulo, slides, Card})=>{
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slide, setSlide] = useState(slides)
    
    const prevSlide = () => {
        
            if ((currentIndex-slides) > 0) {
                setCurrentIndex(currentIndex - slides);
            }
            else {
                setCurrentIndex(0);
            }
    };
    
    const nextSlide = () => {
        if(cards.length > slide){
            if ((cards.length - (currentIndex+slides)) > slides) {
                setCurrentIndex(currentIndex + slides);
            }
            else{
                setCurrentIndex(currentIndex +(cards.length - (currentIndex + slides)))
            }
        }
    };
    
    return (
        <>
        <TituloSeccao titulo={titulo} handlerPrevSlide={prevSlide} handlerNextSlide={nextSlide} 
                      currentIndex={currentIndex} 
                      numeroDeSlides={cards.length}
                      slides={slides}
        ></TituloSeccao>
            
        <div className="card-carousel-container">
            <div className="carousel-wrapper">
                <div className="card-carousel">
                    <div
                        className="carousel-inner"
                        style={{transform: `translateX(-${currentIndex * (100 / slides)}%)`}}
                    >
                        {cards.map((cardData, index) => (
                            <div key={index} className="carousel-item" style={{ minWidth: `${100 / slides}%` }}>
                                <Card {...cardData} />
                            </div>
                        ))}
                    </div>
                </div>
               
            </div>
        </div>
        </>
    )
};
export default CardCarousel;