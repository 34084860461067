import React, { useEffect, useState,useRef } from "react";
import "../../../scss/_noticia.scss";
import { FaAngleLeft } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";

import Detalhe from "./Componentes/Detalhe";
import Comentario from "../Components/Comentarios";
import AdicionarComentario from "../Components/AdicionarComentario";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export default function Noticia() {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entidade = pathParts[1];
    const id = pathParts[3];

    const [noticia, setNoticia] = useState();
    const [comentarios, setComentarios] = useState([]);

    const [comentario, setComentario] = useState(false)
    const [idComentarioResponder, setIdComentarioResponder] = useState(0)

    const targetRef = useRef(null);

    function trocarComentario (){
        setComentario(!comentario)
       
    }

    const scrollToTarget = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const converterData = (isoDate) => {
        const monthNames = [
            "janeiro", "fevereiro", "março", "abril", "maio", "junho",
            "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
        ];
        const date = new Date(isoDate);
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} de ${month} de ${year}`;
    };

    useEffect(() => {
        const fetchNoticia = async () => {
            const url = `/api/pagina-publica/noticia/buscar`;
            try {
                const response = await axios.get(url, {
                    params: {id: id}
                });
                
                setNoticia(response.data);
            } catch (error) {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a notícia");
            }
        };
        fetchNoticia();
    }, [id]);

    useEffect(() => {
        const fetchComentarios = async () => {
            const url = `/api/pagina-publica/comentario/listar`;
            try {
                const response = await axios.get(url, {
                    params: {idNoticia: id}
                });
                setComentarios(response.data);
            } catch (error) {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar comentários");
            }
        };
        fetchComentarios();
    }, [id]);
    
    return (
        <>
            {noticia !== undefined &&
                <div className="paginaNoticia">
                    <div className="noticiaCard">
                        <div>
                            <Link to={`/${entidade}`} className="botao-seccao">
                                <FaAngleLeft />
                            </Link>
                        </div>

                        <div className="capa">
                            <div className="justificar-direita">
                                <div className={isTabletOrMobile ? "retanguloPequeno" : "retangulo"}></div>
                                <span className="titulo"> {noticia.titulo}</span>
                            </div>
                            <div>
                                <img className="imagens" src={noticia.capa} alt="Capa" />
                            </div>
                        </div>
                    </div>

                    <Detalhe
                        data={converterData(noticia.data)}
                        comentarios={comentarios.length.toString()}
                        categoria={noticia.categoria}
                    />

                    <div className="noticiaCard">
                        <div className="texto" dangerouslySetInnerHTML={{ __html: noticia.texto }}></div>
                        <div className="justificar-direita">
                            <div className={"retanguloPequeno"}></div>
                            <span className="titulo" style={{fontSize: "20px"}}>Comentário</span>
                        </div>

                        {comentarios.length > 0 ? (
                            comentarios.map((comentario, index) => (
                                <Comentario
                                    key={index}
                                    nome={comentario.nome}
                                    data={converterData(comentario.dtCriado)}
                                    texto={comentario.comentario}
                                    idComentario = {comentario.id}
                                    trocarComentario={trocarComentario}
                                    setIdComentarioResponder={setIdComentarioResponder}
                                    scrollToTarget = {scrollToTarget}
                                    
                                />
                            ))
                        ) : (
                            <p>Nenhum comentário disponível</p>
                        )}
                        <div ref={targetRef}>
                            <AdicionarComentario 
                                                 idNoticia={id} 
                                                 comentarioResposta={comentario} 
                                                 idComentarioResponder={idComentarioResponder} 
                                                 trocarComentario={trocarComentario}
                            
                            />
                        </div>
                        
                    </div>
                </div>
            }
        </>
    );
}
