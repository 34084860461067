import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";
import { Link } from "react-router-dom";

const ConteudoResumo = props => {
    const context = useContext(GlobalContext);
    const { modulo, aula } = JSON.parse(localStorage.getItem("questionario_" + localStorage.getItem("codigo_aula")));

    return (
        <div className="container">
            <div className="bloco-resumo">
                <div className="resumo">
                    {!props.loading &&
                        <>
                            {props.totalRespostas === props.totalCertas ?
                                <div className="header">
                                    <i className="fas fa-check-circle" />
                                    <span className="titulo">Questionário concluído com sucesso!</span>
                                    <span className="descricao">Obteve 100% de acerto</span>
                                </div>
                                :
                                <div className="header">
                                    <i className="fas fa-times-circle tenta-novamente" />
                                    <span className="titulo">Questionário concluído sem sucesso!</span>
                                    <span className="descricao">Não obteve 100% de acerto</span>
                                    <span>Efetue de novo a tarefa <strong>{localStorage.getItem("curso_pai_nome")}</strong> e volte a repetir este questionário</span>
                                </div>
                            }
                        </>
                    }
                    <div className="apresentacao">
                        <span className="nome-modulo">{modulo}</span>
                        <span className="aula">{aula}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ConteudoResumo);
