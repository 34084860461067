import React from "react";
import { Link as LinkScroll, Element } from 'react-scroll';
import { Carousel } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import globogabipng from "../../images/home/globogabipng.png";
import globogabipngv2 from "../../images/home/globogabipngv2.png";
import note2 from "../../images/home/img_note2.png";
import note3 from "../../images/home/img_note3.png";
import note4 from "../../images/home/img_persona.png";
import icon1 from "../../images/home/icon1.png";
import icon2 from "../../images/home/icon2.png";
import icon3 from "../../images/home/icon3.png";
import icon4 from "../../images/home/icon4.png";
import anim1 from "../../images/home/ANIM1.gif";

const HomeConteudo = () => {
    const whatsapp = "https://api.whatsapp.com/send?phone=+55 8297400-8501&text=Eu tenho interesse em aderir &agrave; Stepforma"

    return (
        <div className="home-container">
            <div className="home-hero">
                <div className="hero-info-block">
                    <h1 className="hero-title">Inicie seu Ambiente de Aprendizagem.</h1>
                    <p className="hero-text">Crie um ambiente de aprendizagem e treinamentos completo para a sua instituição, contando com as ferramentas e o controle que você precisa. Em alguns cliques, tenha todas as ferramentas para engajar seus usários nas atividades que designar e avaliar o progresso de todos.</p>
                    <p className="hero-text">Tecnologia e gestão simplificada unidas em um só lugar,  garantindo mais tranquilidade e resultados para sua instituição!</p>
                    <div className="hero-buttons">
                        <a href={whatsapp} target="_blank" className="btn-primary">Iniciar</a>
                        <LinkScroll to="stepforma" className="btn-secondary" smooth={true} offset={-100} duration={500}>Saiba Mais</LinkScroll>
                    </div>
                </div>
                <div className="hero-img hero-img-desktop">
                    {/*<img src={globogabipng} />*/}
                    <LazyLoadImage
                        alt="Foto"
                        effect="opacity"
                        src={globogabipng}
                        className="lazy-image" />
                </div>
                <div className="hero-img hero-img-tablet">
                    {/*<img src={globogabipngv2} />*/}
                    <LazyLoadImage
                        alt="Foto"
                        effect="opacity"
                        src={globogabipngv2}
                        className="lazy-image" />
                </div>
            </div>
            <div className="Line"></div>
            <div className="home-left-info home-block-1">
                <div className="home-left-block">
                    <h3 className="home-block-title">Conteúdos</h3>
                    <div className="info-block">
                        <span className="info-block-title">Cursos</span>
                        <span className="info-block-text">Organizados por categorias, anos e turmas</span>
                        <span className="info-block-text">Planos de ensino com diversos cursos e disciplinas</span>
                        <span className="info-block-title">Atividades, Tarefas e Documentação</span>
                        <span className="info-block-text">Trilhas de cursos e de atividades</span>
                        <span className="info-block-text">Cursos estruturados por atividades e tarefas</span>
                        <span className="info-block-text">Drive / repositório de documentação, privada ou partilhada</span>
                        <span className="info-block-text">Diversos tipos de tarefas (vídeos, pdf, avaliações, questionários, trabalhos, etc..)</span>
                    </div>
                </div>
                <div className="home-right-block">
                    {/*<img src={anim1} />*/}
                    <LazyLoadImage
                        alt="Foto"
                        effect="opacity"
                        src={anim1}
                        className="lazy-image" />
                </div>
            </div>
            <div className="home-cards home-block-2">
                <div className="cards-list cards-list-desktop">
                    <div className="card-item">
                        <div className="card-info">
                            <div className="card-icon">
                                {/*<img src={icon1} />*/}
                                <LazyLoadImage
                                    alt="Foto"
                                    effect="opacity"
                                    src={icon1}
                                    className="lazy-image" />
                            </div>
                            <div className="card-title">Tudo Integrado</div>
                            <div className="card-text">Tenha um canal próprio e com todos os recursos integrados para gerenciar as tarefas, atividades e resultados.</div>
                        </div>
                    </div>
                    <div className="card-item">
                        <div className="card-info">
                            <div className="card-icon">
                                {/*<img src={icon2} />*/}
                                <LazyLoadImage
                                    alt="Foto"
                                    effect="opacity"
                                    src={icon2}
                                    className="lazy-image" />
                            </div>
                            <div className="card-title">Do seu jeito</div>
                            <div className="card-text">Personalize seu canal de aprendizagem como achar melhor, mantendo seus estudantes e atraindo novos alunos.</div>
                        </div>
                    </div>
                    <div className="card-item">
                        <div className="card-info">
                            <div className="card-icon">
                                {/*<img src={icon3} />*/}
                                <LazyLoadImage
                                    alt="Foto"
                                    effect="opacity"
                                    src={icon3}
                                    className="lazy-image" />
                            </div>
                            <div className="card-title">Conecte-se em qualquer lugar</div>
                            <div className="card-text">Amplifique o alcance do seu centro de ensino com novas possibilidades de aprendizagem.</div>
                        </div>
                    </div>
                    <div className="card-item">
                        <div className="card-info">
                            <div className="card-icon">
                                {/*<img src={icon4} />*/}
                                <LazyLoadImage
                                    alt="Foto"
                                    effect="opacity"
                                    src={icon4}
                                    className="lazy-image" />
                            </div>
                            <div className="card-title">Atendimento e suporte humanizado</div>
                            <div className="card-text">Ouvimos você e designamos agentes especializados em educação e tecnologia, encontrando a melhor solução para o seu negócio dentro da Stepforma.</div>
                        </div>
                    </div>
                </div>
                <div className="cards-list cards-list-tablet">
                    <Carousel autoplay>
                        <div className="cards-list-carousel">
                            <div className="card-item">
                                <div className="card-info">
                                    <div className="card-icon">
                                        {/*<img src={icon1} />*/}
                                        <LazyLoadImage
                                            alt="Foto"
                                            effect="opacity"
                                            src={icon1}
                                            className="lazy-image" />
                                    </div>
                                    <div className="card-title">Tudo Integrado</div>
                                    <div className="card-text">Tenha um canal próprio e com todos os recursos integrados para gerenciar as tarefas, atividades e resultados.</div>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-info">
                                    <div className="card-icon">
                                        {/*<img src={icon2} />*/}
                                        <LazyLoadImage
                                            alt="Foto"
                                            effect="opacity"
                                            src={icon2}
                                            className="lazy-image" />
                                    </div>
                                    <div className="card-title">Do seu jeito</div>
                                    <div className="card-text">Personalize seu canal de aprendizagem como achar melhor, mantendo seus estudantes e atraindo novos alunos.</div>
                                </div>
                            </div>
                        </div>
                        <div className="cards-list-carousel">
                            <div className="card-item">
                                <div className="card-info">
                                    <div className="card-icon">
                                        {/*<img src={icon3} />*/}
                                        <LazyLoadImage
                                            alt="Foto"
                                            effect="opacity"
                                            src={icon3}
                                            className="lazy-image" />
                                    </div>
                                    <div className="card-title">Conecte-se em qualquer lugar</div>
                                    <div className="card-text">Amplifique o alcance do seu centro de ensino com novas possibilidades de aprendizagem.</div>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-info">
                                    <div className="card-icon">
                                        {/*<img src={icon4} />*/}
                                        <LazyLoadImage
                                            alt="Foto"
                                            effect="opacity"
                                            src={icon4}
                                            className="lazy-image" />
                                    </div>
                                    <div className="card-title">Atendimento e suporte humanizado</div>
                                    <div className="card-text">Ouvimos você e designamos agentes especializados em educação e tecnologia, encontrando a melhor solução para o seu negócio dentro da Stepforma.</div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className="cards-list cards-list-mobile">
                    <Carousel autoplay>
                        <div className="cards-list-carousel">
                            <div className="card-item">
                                <div className="card-info">
                                    <div className="card-icon">
                                        {/*<img src={icon1} />*/}
                                        <LazyLoadImage
                                            alt="Foto"
                                            effect="opacity"
                                            src={icon1}
                                            className="lazy-image" />
                                    </div>
                                    <div className="card-title">Tudo Integrado</div>
                                    <div className="card-text">Tenha um canal próprio e com todos os recursos integrados para gerenciar as tarefas, atividades e resultados.</div>
                                </div>
                            </div>
                        </div>
                        <div className="cards-list-carousel">
                            <div className="card-item">
                                <div className="card-info">
                                    <div className="card-icon">
                                        {/*<img src={icon2} />*/}
                                        <LazyLoadImage
                                            alt="Foto"
                                            effect="opacity"
                                            src={icon2}
                                            className="lazy-image" />
                                    </div>
                                    <div className="card-title">Do seu jeito</div>
                                    <div className="card-text">Personalize seu canal de aprendizagem como achar melhor, mantendo seus estudantes e atraindo novos alunos.</div>
                                </div>
                            </div>
                        </div>
                        <div className="cards-list-carousel">
                            <div className="card-item">
                                <div className="card-info">
                                    <div className="card-icon">
                                        {/*<img src={icon3} />*/}
                                        <LazyLoadImage
                                            alt="Foto"
                                            effect="opacity"
                                            src={icon3}
                                            className="lazy-image" />
                                    </div>
                                    <div className="card-title">Conecte-se em qualquer lugar</div>
                                    <div className="card-text">Amplifique o alcance do seu centro de ensino com novas possibilidades de aprendizagem.</div>
                                </div>
                            </div>
                        </div>
                        <div className="cards-list-carousel">
                            <div className="card-item">
                                <div className="card-info">
                                    <div className="card-icon">
                                        {/*<img src={icon4} />*/}
                                        <LazyLoadImage
                                            alt="Foto"
                                            effect="opacity"
                                            src={icon4}
                                            className="lazy-image" />
                                    </div>
                                    <div className="card-title">Atendimento e suporte humanizado</div>
                                    <div className="card-text">Ouvimos você e designamos agentes especializados em educação e tecnologia, encontrando a melhor solução para o seu negócio dentro da Stepforma.</div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
            <Element name="stepforma">
                <div className="home-left-info home-block-3">
                    <div className="home-left-block">
                        <h3 className="home-block-title">Afinal, o que é a Stepforma?</h3>
                        <div className="info-block">
                            <span className="info-block-text">A Stepforma é uma plataforma que reúne diversas ferramentas funções administrativas e pedagógicas em um só lugar. Pensada para gestão do processo ensino-aprendizagem, a sua instituição pode ter um ambiente virtual de aprendizagem completamente integrado com ferramentas como:</span>
                            <ul>
                                <li>Gestão de usuários</li>
                                <li>Diário eletrônico</li>
                                <li>Estatísticas</li>
                                <li>Resultados</li>
                            </ul>
                            <span className="info-block-text">A Stepforma é a sua porta de entrada para os benefícios da educação tecnológica e integrada.</span>
                        </div>
                    </div>
                    <div className="home-right-block">
                       {/* <img src={note2} />*/}
                        <LazyLoadImage
                            alt="Foto"
                            effect="opacity"
                            src={note2}
                            className="lazy-image" />
                    </div>
                </div>
            </Element>
            <div className="home-right-info home-block-4">
                <div className="home-left-block">
                    {/*<img src={note3} />*/}
                    <LazyLoadImage
                        alt="Foto"
                        effect="opacity"
                        src={note3}
                        className="lazy-image" />
                </div>
                <div className="home-right-block">
                    <h3 className="home-block-title">Transforme a experiência do ensino</h3>
                    <div className="info-block">
                        <span className="info-block-title">Flexibilidade e eficácia</span>
                        <span className="info-block-text">Complementando as atividades síncronas, a Stepforma oferece total flexibilidade para criação, edição e acompanhamento de cursos e atividades assíncronas.</span>
                        <span className="info-block-title">Versatilidade</span>
                        <span className="info-block-text">Crie e acesse conteúdos em diversos formatos, com traduções automatizadas de linguagens.</span>
                        <span className="info-block-title">Use onde quiser</span>
                        <span className="info-block-text">Com seu uso responsivo, a Stepforma se adapta a qualquer que seja o dispositivo utilizado, em todas as instâncias de usuários.</span>
                    </div>
                </div>
            </div>
            <div className="home-right-info home-block-5">
                <div className="home-left-block">
                    {/*<img src={note4} />*/}
                    <LazyLoadImage
                        alt="Foto"
                        effect="opacity"
                        src={note4}
                        className="lazy-image" />
                </div>
                <div className="home-right-block">
                    <h3 className="home-block-title">Tudo do seu jeito</h3>
                    <div className="info-block">
                        <span className="info-block-title">Totalmente personalizável</span>
                        <span className="info-block-text">Entregamos um AVA (Ambiente Virtual de Aprendizagem) 100% adequado as suas necessidades, criado a partir das suas requisições.</span>
                        <span className="info-block-title">Centralidade no aluno</span>
                        <span className="info-block-text">Crie cursos e atividades próprias, direcionando-as a turmas e alunos específicos para criar trilhas de aprendizagem únicas e assertivas, unido a recursos </span>
                        <span className="info-block-title">Suporte</span>
                        <span className="info-block-text">Encontre na Stepforma o suporte humanizado e dedicado a fazer seu centro de ensino funcionar de maneira digital e escalável.</span>
                    </div>
                </div>
            </div>
            {/*<div className="eclipse1"></div>*/}
        </div>
    );
}

export default HomeConteudo;
