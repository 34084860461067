import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";

class Adesao extends Component {
    static contextType = GlobalContext;

    render() {
        return (
            <div className="bloco-minha-conta">
                <div className="info-adesao" style={{ gridRow: "1/3" }}>
                    <div className="line">
                        <span>Código: </span>
                        {this.context.nr_utilizador}
                    </div>
                    <div className="line">
                        <span>Adesão: </span>
                        {moment(this.context.dt_adesao_utilizador).format("DD/MM/YYYY")}
                    </div>
                    {this.context.escolas_entidade ? (
                    <div className="line">
                            <span>Unidade Organizacional: </span>
                        {this.context.organismo}
                        </div>)
                        : (<></>)}
                    {this.context.login2 ?
                        <div className="line">
                            <span>Login: </span>
                            {this.context.nome_apelido}
                        </div>
                        :
                        <div className="line">
                            <span>Login: </span>
                            {this.context.email}
                        </div>
                    }

                </div>
            </div>
        );
    }
}

export default Adesao;
