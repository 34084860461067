import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Select, notification, Icon, Input } from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../GlobalState"
import noimage from "../../images/noimage.png";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerPesquisar extends Component {
    static contextType = GlobalContext;
    state = {
        loading_pesquisa: false,
        visibleDrawerInscrever: false,
        pesquisaUtilizadores: "",
        pesquisar_utilizadores: [],
        //PARTICIPANTES
        participantes: []
    };

    pesquisarUtilizador = value => {
        if (value) {
            this.setState(
                {
                    loading_pesquisa: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/eventos/pesquisar-participantes",
                        params: {
                            pesquisa: value,
                            cursoid: localStorage.getItem("codigo_curso")
                        }
                    })
                        .then(response => {
                            this.setState({
                                pesquisar_utilizadores: response.data,
                                loading_pesquisa: false
                            });
                        })
                        .catch(() => { });
                }
            );
        } else
            this.setState({
                pesquisar_utilizadores: []
            });
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.setState({
                loading_pesquisa: false,
                visibleDrawerInscrever: false,
                pesquisaUtilizadores: "",
                pesquisar_utilizadores: [],
                //PARTICIPANTES
                participantes: this.props.participantes
            })
        }
    };

    render() {
        const { Search } = Input;
        const {
            loading_pesquisa,
            visibleDrawerInscrever,
            pesquisaUtilizadores,
            pesquisar_utilizadores,
            //PARTICIPANTES
            participantes
        } = this.state;

        return (
            <Drawer
                className="drawer-inscrever"
                //title="Pesquisar"
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visibleParticipantes}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <div className="pesquisar">
                        <Search
                            placeholder="Coloque o nome ou o código a pesquisar..."
                            onChange={e => this.setState({ pesquisaUtilizadores: e.target.value })}
                            onSearch={this.pesquisarUtilizador}
                            style={{ width: 400 }}
                            allowClear={true}
                            value={this.state.pesquisaUtilizadores}
                        />
                    </div>
                    <div className="listagem" style={{ marginTop: 30 }}>
                        {loading_pesquisa ? (
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                        ) : (
                                <div className="lista-participantes">
                                    {pesquisar_utilizadores.map((participante, index) => (
                                        <div key={index} className="participante">
                                            <div className="info-participante">
                                                <div className="container-img">
                                                    <img src={participante.foto ? participante.foto : noimage} />
                                                </div>
                                                <div className="info">
                                                    <span className="nome">
                                                        {participante.nome}
                                                    </span>
                                                    <p className="numero">{participante.numero}</p>
                                                </div>
                                            </div>
                                            <div className="opcoes">
                                                {this.props.participantes.find(x => x.id === participante.id) ?
                                                    <button className="botao-principal botao-adicionar-disable" disabled title="Adicionado">
                                                        <Icon type="plus" />
                                                    </button>
                                                    :
                                                    <button className="botao-principal botao-adicionar" title="Adicionar" onClick={() => this.props.selecionarParticipante(participante)}>
                                                        <Icon type="plus" />
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                    </div>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                        </button>
                </div>
            </Drawer>
        );
    }
}

export default DrawerPesquisar;
