import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import { GlobalContext } from "../GlobalState";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Oops from "../Geral/Oops.jsx";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Audio extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        nome: "",
        objetivos: "",
        current: "",
        aulaIndex: 0,
        fullscreen: false,
        updateModulos: false,
        dataHora: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        start: true,
        play: false,
        sameUrl: true,
        //NAO DISPONIVEL
        nao_disponivel: false,
        //LOADING
        loading: true,
        //VALIDAR 
        conclusao: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
        this.registarEntrada(aulaId);
        this.validarConclusao(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.registarEntrada(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    registarEntrada = aulaid => {
        axios({
            method: "post",
            url: "/api/logs/registar-entrada",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                localStorage.setItem("codigo_entrada", response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    validarConclusao = aulaid => {
        axios({
            method: "get",
            url: "/api/player-trabalho/validar-conclusao",
            params: {
                aulaid
            }
        })
            .then(response => {
                this.setState({
                    conclusao: response.data
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    //componentDidMount() {
    //    var timeout;

    //    if (!this.state.nao_disponivel) {
    //        this.player.addEventListener("play", e => {
    //            this.setState({ play: true });

    //            this.marcarPosicao(e.target.currentTime);
    //        });

    //        this.player.addEventListener("pause", e => {
    //            this.marcarPosicao(e.target.currentTime);
    //            this.setState({ play: false });
    //        });

    //        this.player.addEventListener("seeked", e => {
    //            this.marcarPosicao(e.target.currentTime);
    //        });

    //        this.player.addEventListener("ended", e => {
    //            this.marcarPosicao(e.target.currentTime);
    //            this.concluirAula();
    //        });

    //        this.player.oncanplay = () => {
    //            if (this.getParameterByName("start") && this.state.start) {
    //                this.player.currentTime = this.getParameterByName("start");
    //                this.setState({
    //                    start: false
    //                });
    //            }
    //        };
    //    }
    //}

    concluirAula = () => {
        axios({
            method: "put",
            url: "/api/player/concluir-audio",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        }).then(() => {
            this.setState({
                conclusao: true
            })
            this.context.atualizarListaModulos();
        }).catch(() => {
            openNotificationWithIcon("error", "Erro", "Não foi possível marcar o áudio como concluído.");
        });
    };

    //GUARDA O PERIODO DO VIDEO ONDE O UTILIZADOR ESTA
    marcarPosicao = periodo => {
        axios({
            method: "put",
            url: "/api/player/marcar-video",
            params: {
                aulaid: this.state.aulaid,
                periodo
            }
        }).catch(() => {
            openNotificationWithIcon("error", "Erro", "Ocorreu um erro!");
        });
    };

    //AVANÇAR VIA TEMPO ANOTAÇÃO
    anotacaoSeek = current => {
        this.player.currentTime = current;
    };

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/player/conteudo1",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    dataHora: moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"),
                    loading: false
                }, () => {
                    this.player.addEventListener("play", e => {
                        this.setState({ play: true });

                        this.marcarPosicao(e.target.currentTime);
                    });

                    this.player.addEventListener("pause", e => {
                        this.marcarPosicao(e.target.currentTime);
                        this.setState({ play: false });
                    });

                    this.player.addEventListener("seeked", e => {
                        this.marcarPosicao(e.target.currentTime);
                    });

                    this.player.addEventListener("ended", e => {
                        this.marcarPosicao(e.target.currentTime);
                        this.concluirAula();
                    });

                    this.player.oncanplay = () => {
                        if (this.getParameterByName("start") && this.state.start) {
                            this.player.currentTime = this.getParameterByName("start");
                            this.setState({
                                start: false
                            });
                        }
                    };
                    this.player.src = response.data.recurso
                });
            })
            .catch(error => {
                //if (error.response.data === "SEM_PERMISSAO")
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/user"
                //    });
                //else
                    if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    nao_disponivel: true
                });
            });
    };

    //APANHA OS ATRIBUTOS PASSADOS PELO ENDEREÇO
    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    opcaoMarcar = () => {
        this.marcarPosicao(this.player.currentTime);
    };

    opcaoMarcarVoltar = () => {
        this.marcarPosicao(this.player.currentTime);
        this.setState({
            redirect: true,
            redirectLink: `/${this.state.curso}/curso-modulos`
        });
    };

    render() {
        const {
            curso,
            nome,
            objetivos,
            current,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            play,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel,
            //LOADING
            loading,
            //VALIDAR 
            conclusao
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data pagina-inteira">
                <div className="loading" />
            </div>
        ) : (
                <div className="questionario">
                    <div className="player-wrapper">
                        {/*<LazyLoad offsetVertical={200}>*/}
                        {/*    <img src={require("../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                        {/*</LazyLoad>*/}
                        {!nao_disponivel ? (
                            <>
                                <div className="container">
                                    <div className="bloco-presencial">
                                        <div className="bloco-download-link">
                                            <div className="bloco-info-tarefa">
                                                <span className="icon-tarefa">
                                                    <img src={require("../images/icons/audio.png")} />
                                                </span>
                                                <span className="titulo-tarefa">{nome}</span>
                                            </div>
                                            <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                            <div className="player-audio">
                                                <div className="container-audio">
                                                    <audio controls ref={ref => (this.player = ref)} controlsList="nodownload">
                                                        <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/9473/new_year_dubstep_minimix.ogg" type="audio/x-wav" />
                                                    </audio>
                                                </div>
                                                <div className="container-audio container-barras">
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                        <div className="row"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Controlos
                                    backUrl={`/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                    sameUrl={sameUrl}
                                    temRating
                                    temAnotacoes
                                    temMarcador
                                    temMensagens
                                    nome={nome}
                                    objetivos={objetivos}
                                    icon={require("../images/icons/audio.png")}
                                    current={current}
                                    anotacaoSeek={this.anotacaoSeek}
                                    player
                                    opcaoMarcar={this.opcaoMarcar}
                                    opcaoMarcarVoltar={this.opcaoMarcarVoltar}
                                    dataHora={dataHora}
                                    conclusao={conclusao}
                                />
                            </>
                        ) : (
                                <>
                                    <div className="container">
                                        <div className="bloco-nao-disponivel">
                                            <div className="bloco-info nao-disponivel">
                                                <Oops tem_texto texto={"Conteúdo não disponível"} />
                                            </div>
                                        </div>
                                    </div>
                                    <ControlosSimples />
                                </>
                            )}
                    </div>
                </div>
            );
    }
}

export default Audio;
