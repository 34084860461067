import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table } from 'antd';
import TabelaDetalhe from "./Detalhe";
import { GlobalContext } from "../../../../../GlobalState";
import axios from "axios";

const Tabela = ({ match }) => {
    const globalContext = useContext(GlobalContext);
    const [colunas, setColunas] = useState([]);
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(false);

    const carregarColunas = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-colunas-aproveitamento",
            params: {
                turmaId: globalContext[`turma${match.params.code}`]?.id,
            }
        })
            .then(response => {
                var avaliacoes = response.data.map(avaliacao => {
                    return {
                        title: <div>{avaliacao.legenda ? avaliacao.legenda : avaliacao.titulo}<br />({avaliacao.mediaDisciplinas.legenda ? avaliacao.mediaDisciplinas.legenda : avaliacao.mediaDisciplinas.titulo}: {avaliacao.mediaDisciplinas.nota ? avaliacao.mediaDisciplinas.nota : "-"})</div>,
                        dataIndex: avaliacao.titulo.toLowerCase().replaceAll(' ', '')
                    }
                })

                setColunas([
                    {
                        title: "Disciplinas",
                        dataIndex: "disciplinas"
                    },
                    ...avaliacoes
                ])
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const carregarDados = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-dados-aproveitamento",
            params: {
                turmaId: globalContext[`turma${match.params.code}`]?.id
            }
        })
            .then(response => {
                const avaliacoes = response.data.map(avaliacao => {
                    let pautas = {};
                    avaliacao.pautas.map(pauta => {
                        pautas[pauta.titulo.toLowerCase().replaceAll(' ', '')] = pauta.nota
                    })

                    return {
                        id: avaliacao.id,
                        disciplinas: avaliacao.disciplina,
                        ...pautas
                    }
                })

                setDados(avaliacoes);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                setLoading(false);
            });
    };

    useEffect(() => {
        if (globalContext[`turma${match.params.code}`]) {
            carregarColunas();
            carregarDados();
        }
    }, [globalContext[`turma${match.params.code}`]])

    return (
        <Table
            className="tabela-aproveitamento"
            columns={colunas}
            pagination={false}
            dataSource={dados}
            loading={{
                spinning: loading,
                indicator: (
                    <div className="loading-data-table">
                        <div className="loading" />
                    </div>
                )
            }}
            expandedRowRender={record =>
                <TabelaDetalhe
                    record={record}
                />
            }
        />
    );
}

export default withRouter(Tabela);