import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import PlayerVideo from "./Video";
import PlayerAudio from "./Audio";
import PlayerPagina from "./Pagina";
import PlayerPdf from "./Pdf";
import PlayerDownload from "./Download";
import PlayerLink from "./Link";
import PlayerSumario from "./Sumario";
import PlayerSessaoPresencial from "./SessaoPresencial";
import { GlobalContext } from "../../GlobalState";

class PlayerLayout extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    render() {
        const {
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <Switch>
                <Route exact path="/:entidade/curso/:curso/:cursoid/tarefa/:aula/player-video" component={PlayerVideo} />
                <Route exact path="/:entidade/curso/:curso/:cursoid/tarefa/:aula/player-audio" component={PlayerAudio} />
                <Route exact path="/:entidade/curso/:curso/:cursoid/tarefa/:aula/player-pagina" component={PlayerPagina} />
                <Route exact path="/:entidade/curso/:curso/:cursoid/tarefa/:aula/player-pdf" component={PlayerPdf} />
                <Route exact path="/:entidade/curso/:curso/:cursoid/tarefa/:aula/player-download" component={PlayerDownload} />
                <Route exact path="/:entidade/curso/:curso/:cursoid/tarefa/:aula/player-link" component={PlayerLink} />
                <Route exact path="/:entidade/curso/:curso/:cursoid/tarefa/:aula/player-sumario" component={PlayerSumario} />
                <Route exact path="/:entidade/curso/:curso/:cursoid/tarefa/:aula/player-sessao-presencial" component={PlayerSessaoPresencial} />
            </Switch>
        );
    }
}

export default PlayerLayout;
