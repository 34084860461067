import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Input, Select, Checkbox, Radio } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import noimage from "../../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerMultisseriado = ({ multisseriadoId, turmaId, visible, onClose, atualizar, form }) => {
    const [turmas, setTurmas] = useState([]);
    const [turmasSelecionadas, setTurmasSelecionadas] = useState([]);
    const [loading, setLoading] = useState(false);

    const adicionar = id => {
        var tempTurma = turmas.find(x => x.id === id);
        var tempTurmas = turmas.filter(x => x.id !== id);

        setTurmas([...tempTurmas]);
        setTurmasSelecionadas([...turmasSelecionadas, tempTurma]);
    };

    const remover = id => {
        var tempTurma = turmasSelecionadas.find(x => x.id === id);
        var tempTurmas = turmasSelecionadas.filter(x => x.id !== id);

        setTurmas([...turmas, tempTurma]);
        setTurmasSelecionadas([...tempTurmas]);
    };

    const listar = () => {
        axios({
            method: "get",
            url: "/api/gestao-multisseriado",
            params: {
                turmaId
            }
        })
            .then(response => {
                setTurmas(response.data.porSelecionadas);
                setTurmasSelecionadas(response.data.selecionadas);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possivel carregar as turmas.");
                setLoading(false);
            });
    };

    const guardar = event => {
        event.preventDefault();

        const ids = turmasSelecionadas.map(x => x.id);

        axios({
            method: "post",
            url: "/api/gestao-multisseriado",
            params: {
                ids: JSON.stringify(ids)
            }
        })
            .then(() => {
                atualizar();
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possivel carregar as turmas.");
                setLoading(false);
            });
    };

    const alterar = event => {
        event.preventDefault();

        const ids = turmasSelecionadas.map(x => x.id);

        axios({
            method: "put",
            url: "/api/gestao-multisseriado",
            params: {
                multisseriadoId,
                ids: JSON.stringify(ids)
            }
        })
            .then(() => {
                atualizar();
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possivel carregar as turmas.");
                setLoading(false);
            });
    };

    const afterVisibleChange = aberto => {
        if (aberto) {
            listar();
        }
    };

    const { getFieldDecorator } = form;

    return (
        <Drawer
            title=""
            placement="right"
            className="drawer-envio-mensagem-inscricao drawer-add-cursos drawer-multisseriado"
            maskClosable={false}
            closable={true}
            onClose={onClose}
            visible={visible}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info bloco-lista-multisseriado">
                <div className="lista-multisseriado selecionadas">
                    <h3 class="titulo">Turmas selecionadas</h3>
                    <div className="lista-turmas">
                        <ul>
                            {turmasSelecionadas.map(turma => (
                                <li key={turma.id}>
                                    <span>
                                        {turma.codigo} - {turma.nome}
                                    </span>
                                    <Link to="#" className="botao-icon-excluir" onClick={() => remover(turma.id)}>
                                        <Icon type="delete" />
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="separador"></div>
                <div className="lista-multisseriado por-selecionar">
                    <h3 class="titulo">Turmas por selecionar</h3>
                    <div className="lista-turmas">
                        <ul>
                            {turmas.map(turma => (
                                <li key={turma.id}>
                                    <span>
                                        {turma.codigo} - {turma.nome}
                                    </span>
                                    <Link to="#" className="botao-icon-adicionar" onClick={() => adicionar(turma.id)}>
                                        <Icon type="plus" />
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </button>
                <button className="botao-principal" onClick={multisseriadoId != undefined ? alterar : guardar} disabled={loading}>
                    {loading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
        </Drawer>
    );
};

const FormDrawerMultisseriado = Form.create({ name: "form-drawer-multisseriado" })(DrawerMultisseriado);

export default withRouter(FormDrawerMultisseriado);
