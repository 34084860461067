import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, notification, Icon, Drawer, Menu, Modal, Dropdown, Tooltip, Table, Switch } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";
import DrawerEscola from "./DrawerEscola";
import { GlobalContext } from "../../../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Escolas extends Component {
    static contextType = GlobalContext;
    state = {
        //FILTROS
        ativo: true,
        //TABELA
        escolas: [],
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER
        visible: false,
        organismoId: 0,
        //LOADING
        loading: false
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    listar = () => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-organismos",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        let escolas = [];

                        response.data.map(escola => {
                            escolas.push({
                                key: escola.id,
                                id: escola.id,
                                nome: escola.nome,
                                diretor: this.montarNome(escola.diretor),
                                secretario: this.montarNome(escola.secretario),
                                localidade: escola.localidade,
                                opcoes: this.montarMenu(escola)
                            });
                        });

                        this.setState({
                            escolas,
                            loading: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        this.setState({
                            loading: false
                        });
                    });
            }
        );
    };

    montarNome = (nome) => { 
        if (!nome)
            return null;

        const partes = nome.split(" ");
        return partes.length > 1 ? `${partes[0]} ${partes[partes.length - 1]}` : partes;
    }

    excluir = organismoId => {
        confirm({
            title: "Pretende remover este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-organismos/excluir-organismo",
                    params: {
                        organismoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Registo removido!");
                        var escolas = this.state.escolas.filter(x => x.id !== organismoId);
                        this.setState({
                            escolas
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível remover este registo!");
                    });
            }
        });
    };

    ativo = organismoId => {
        confirm({
            title: this.state.ativo ? "Pretende inativar este Estabelecimento?" : "Pretende ativar este Estabelecimento?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-organismos/ativar-organismo",
                    params: {
                        organismoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon(
                            "success",
                            "Sucesso",
                            this.state.ativo ? "Estabelecimento inativado!" : "Estabelecimento ativado!"
                        );
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            this.state.ativo ? "Não foi possível inativar o Estabelecimento!" : "Não foi possível ativar o Estabelecimento!"
                        );
                    });
            }
        });
    };

    montarMenu = escola => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativo(escola.id);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        this.ativo(escola.id);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        this.excluir(escola.id);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };

    onRowClick = organismoId => {
        this.setState({
            organismoId,
            visible: true
        });
    };

    render() {
        const {
            //FILTROS
            ativo,
            //TABELA
            escolas,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER
            visible,
            organismoId,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const columns = [
            {
                title: "Estabelecimento",
                dataIndex: "nome",
                className: "td-150"
            },
            {
                title: "Diretor",
                dataIndex: "diretor",
                className: "td-50"
            },
            {
                title: "Secretário",
                dataIndex: "secretario",
                className: "td-50"
            },
            {
                title: "Localidade",
                dataIndex: "localidade",
                className: "td-50"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50 td-text-right"
            }
        ];

        const columnsMobile = [
            {
                title: "Estabelecimento",
                dataIndex: "nome",
                className: "td-150"
            },
            // {
            //     title: "Diretor",
            //     dataIndex: "diretor",
            //     className: "td-50"
            // },
            // {
            //     title: "Secretário",
            //     dataIndex: "secretario",
            //     className: "td-50"
            // },
            // {
            //     title: "Localidade",
            //     dataIndex: "localidade",
            //     className: "td-50"
            // },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50 td-text-right"
            }
        ];

        return (
            <div className="bloco-minha-conta">
                <div className="controlos">
                    <div className="controlos-space-between">
                        <button
                            className="botao-principal botao-principal-redondo"
                            title="Adicionar"
                            onClick={() => this.setState({ organismoId: 0, visible: true })}
                            disabled={!this.state.ativo}
                        >
                            +
                        </button>
                        <Switch
                            checkedChildren="Ativos"
                            unCheckedChildren="Inativos"
                            defaultChecked
                            checked={ativo}
                            onChange={this.handleChangeSwitch}
                        />
                    </div>
                </div>

                <div className="box-ficheiros box-ficheiros-mobile">
                    <Table
                        id="tabela-cursos"
                        className="tabela-cursos-mobile"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                        columns={columnsMobile}
                        dataSource={escolas}
                        pagination={false}
                        rowClassName="clickeble-row"
                        onRow={(record, index) => {
                            return {
                                onClick: () => this.onRowClick(record.id)
                            };
                        }}
                        locale={{ emptyText: "Não existem dados!" }}
                    />
                </div>
                <div className="box-ficheiros box-ficheiros-desktop">
                    <Table
                        id="tabela-cursos"
                        className="tabela-cursos-desktop"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                        columns={columns}
                        dataSource={escolas}
                        pagination={false}
                        rowClassName="clickeble-row"
                        onRow={(record, index) => {
                            return {
                                onClick: () => this.onRowClick(record.id)
                            };
                        }}
                        locale={{ emptyText: "Não existem dados!" }}
                    />
                </div>
                <DrawerEscola
                    ativo={this.state.ativo}
                    organismoId={organismoId}
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    atualizar={() => {
                        this.setState({ visible: false });
                        this.listar();
                    }}
                />
            </div>
        );
    }
}

const FormEscolas = Form.create({ name: "form-escolas" })(Escolas);

export default FormEscolas;
