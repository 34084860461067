import React from "react"
import noimage from "../../images/noimage.png";
import { notification, Icon } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

const Participante = ({ id, foto, nome, numero, instrutor, coordenador, notificacao, ultimoAcesso, abrirDrawerEnviarMsg }) => {

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description
        });
    };

    const montarTitulo = (instrutor, coordenador) => {
        if (instrutor && coordenador)
            return "(Coordenador / Professor)";
        else if (coordenador)
            return "(Coordenador)";
        else
            return "(Professor)";
    }

    return (
        <div className="cartao-participante">
            <div className="opcao-notificar">
                <Link to="#" className="botao-icon-enviar" onClick={() => notificacao ? abrirDrawerEnviarMsg(id) : openNotificationWithIcon("info", "O aluno não pretende receber notificações por e-mail de acordo com as suas configurações", "")}>
                    <Icon type="mail" />
                </Link>
            </div>
            <div className="container-img">
                <img src={foto ? foto : noimage} />
            </div>
            <div className="info">
                <span className="nome">{nome.split(" ").length > 1 ? `${nome.split(" ")[0]} ${nome.split(" ").pop()}` : nome}</span>
                <span className="numero">{numero}</span>
            </div>
            {(instrutor || coordenador) && <span className="instrutor">{montarTitulo(instrutor, coordenador)}</span>}
            {ultimoAcesso?.valida ?
                <span className="ultimo-acesso" title="Último acesso ao curso">{moment(ultimoAcesso.data).isAfter(moment('0001-01-01')) ? moment(ultimoAcesso.data).format("DD/MM/YYYY HH:mm") : "Nunca"}</span>
                : null}
        </div>
    )
}

export default Participante
