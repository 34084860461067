import React from "react";
import { Link, withRouter } from "react-router-dom";
import noimage from "../../images/noimage.png";
import moment from "moment";

const Noticia = ({ noticia, match }) => {
    return (
        <Link to={`/${match.params.entidade}/informacoes/${noticia.code}`} >
            <div className="item-noticia">
                <div className="imagem">
                    <img src={noticia.capa ? noticia.capa : noimage } />
                </div>
                <div className="corpo">
                    <span className="titulo">{noticia.titulo}</span>
                    <p className="texto">{noticia.texto.substring(0, 150)}{noticia.texto.length > 150 ? "..." : ""}</p>
                    <div className="corpo-subinfo">
                        {noticia.link &&
                            <a href={noticia.link} target="_blank" rel="noopener noreferrer" className="link">
                                <i className="fa fa-link"></i> Link
                            </a>}
                        {noticia.data &&
                            <span className="data">
                                {moment(noticia.data).format("DD MMM YYYY")} {noticia.hora}
                            </span>}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default withRouter(Noticia);
