import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Icon, Modal, Checkbox, Row, Col, Select, notification, Input, DatePicker } from "antd";
import moment from "moment";
import axios from "axios";
import { useReactToPrint } from "react-to-print";

const { RangePicker } = DatePicker;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Controlos = ({
    handleSearch,
    menuItemKey,
    menuItemName,
    search,
    handleChangeSearch,
    resetSearch,
    filterDate,
    setFilterDate,
    filterSchool,
    setFilterSchool,
    filterEdition,
    setFilterEdition,
    filterCourse,
    setFilterCourse,
    filterDiscipline,
    setFilterDiscipline,
    setCols,
    menuOptionSelected,
    disabledSearch,
    disabledButtons,
    columnsPositions,
    sorter,
    cols,
    data,
    pageTitle
}) => {
    const { Option } = Select;
    const [visible, setVisible] = useState(false);
    const [buttonValue, setButtonValue] = useState(false);
    const [columns, setColumns] = useState([
        {
            title: "Número",
            key: "number"
        },
        {
            title: "Nome",
            key: "name"
        },
        {
            title: "Último Acesso",
            key: "lastAccess"
        },
        {
            title: "Ausência",
            key: "absence"
        },
        {
            title: "Localidade",
            key: "locale"
        },
        {
            title: "Idade",
            key: "age"
        },
        {
            title: "Escola",
            key: "school"
        },
        {
            title: "Sexo",
            key: "gender"
        },
        {
            title: "Perfil",
            key: "role"
        },
        {
            title: "Última Alteração",
            key: "lastChange"
        }
    ]);

    const [selectedColumns, setSelectedColumns] = useState(["number", "name", "lastAccess"]);
    const [school, setSchool] = useState([]);
    const [edition, setEdition] = useState([]);
    const [course, setCourse] = useState([]);
    const [discipline, setDiscipline] = useState([]);
    const [roles, setRoles] = useState([
        {
            id: 1,
            name: "Gestor"
        },
        {
            id: 2,
            name: "Professor"
        },
        {
            id: 3,
            name: "Aluno"
        }
    ]);
    const [states, setStates] = useState([
        {
            id: 1,
            name: "Ativo"
        },
        {
            id: 2,
            name: "Inativo"
        },
        {
            id: 3,
            name: "Excludío"
        }
    ]);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const onChangeCheckbox = checkedValues => {
        setSelectedColumns(checkedValues);
    };

    const confirmColumns = () => {
        var columns = selectedColumns.map(value => {
            return returnColumn(value);
        });

        setCols(columns);
        setVisible(false);
    };

    const returnColumn = key => {
        if (key === "number") {
            return {
                title: "Número",
                dataIndex: "number",
                sorter: (a, b) => a.number.localeCompare(b.number),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "name") {
            return {
                title: "Nome",
                dataIndex: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "lastAccess") {
            return {
                title: "Último Acesso",
                dataIndex: "lastAccess",
                sorter: (a, b) => moment(a.lastAccess).unix() - moment(b.lastAccess).unix(),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "absence") {
            return {
                title: "Ausência",
                dataIndex: "absence",
                sorter: (a, b) => a.absence - b.absence,
                sortDirections: ["descend", "ascend"],
                render: absence =>
                    absence ? (
                        <span className={`total-absence ${absence > 7 ? "above" : "normal"}`} title="Número de dias ausente">
                            {absence} {absence > 1 ? "dias" : "dia"}
                        </span>
                    ) : (
                        ""
                    )
            };
        } else if (key === "locale") {
            return {
                title: "Localidade",
                dataIndex: "locale",
                sorter: (a, b) => a.locale.localeCompare(b.locale),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "age") {
            return {
                title: "Idade",
                dataIndex: "age",
                sorter: (a, b) => a.age.localeCompare(b.age),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "school") {
            return {
                title: "Escola",
                dataIndex: "school",
                sorter: (a, b) => a.school.localeCompare(b.school),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "gender") {
            return {
                title: "Sexo",
                dataIndex: "gender",
                sorter: (a, b) => a.gender.localeCompare(b.gender),
                sortDirections: ["descend", "ascend"]
            };
        } else if (key === "role") {
            return {
                title: "Perfil",
                dataIndex: "role",
                sorter: (a, b) => a.role.localeCompare(b.role),
                sortDirections: ["descend", "ascend"]
            };
        } else {
            return {
                title: "Última Alteração",
                dataIndex: "lastChange",
                sorter: (a, b) => moment(a.lastChange).unix() - moment(b.lastChange).unix(),
                sortDirections: ["descend", "ascend"]
            };
        }
    };

    const loadSchoolList = () => {
        axios({
            method: "get",
            url: "/api/logs/buscar-acoes-escola"
        })
            .then(response => {
                setSchool(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const loadEditionList = () => {
        axios({
            method: "get",
            url: "/api/logs/buscar-acoes-edicao"
        })
            .then(response => {
                setEdition(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const loadCourseList = () => {
        axios({
            method: "get",
            url: "/api/logs/buscar-acoes-turmas",
            params: {
                filteredEdition: filterEdition,
                filteredSchool: filterSchool
            }
        })
            .then(response => {
                setCourse(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const loadDisciplineList = () => {
        axios({
            method: "get",
            url: "/api/logs/buscar-acoes-disciplinas",
            params: {
                filteredCourse: filterCourse
            }
        })
            .then(response => {
                setDiscipline(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    useEffect(() => {
        loadSchoolList();
        loadEditionList();
        loadCourseList();
        loadDisciplineList();
    }, [menuOptionSelected]);

    useEffect(() => {
        if (filterCourse) loadDisciplineList();
        else {
            setFilterDiscipline(undefined);
            setDiscipline([]);
        }
    }, [filterCourse]);

    useEffect(() => {
        if (filterSchool || filterEdition) loadCourseList();
        else {
            loadCourseList();
            setFilterDiscipline(undefined);
            setDiscipline([]);
        }
    }, [filterSchool, filterEdition]);

    const useWindowWidth = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            // Function to update windowWidth state with current innerWidth
            const handleWindowResize = () => {
                setWindowWidth(window.innerWidth);
            };

            // Event listener for window resize
            window.addEventListener("resize", handleWindowResize);

            // Cleanup: remove event listener on unmount
            return () => {
                window.removeEventListener("resize", handleWindowResize);
            };
        }, []);

        return windowWidth;
    };

    const handleFilterChange = filter => {
        setFilterDate(filter);
    };

    const windowWidth = useWindowWidth();

    const expandableConfig = {
        expandedRowRender: data => <Table columns={columns} dataSource={data.pareceres} pagination={false} />
    };

    const expandedRowRender = () => {
        const columns = [
            { title: "Nome", dataIndex: "nome" },
            { title: "Texto", dataIndex: "texto" }
        ];

        const info = [];

        data.pareceres.map(e => {
            info.push({
                nome: e.nome,
                texto: e.texto
            });
        });

        return <Table columns={columns} dataSource={info} pagination={false} />;
    };

    return (
        <>
            {windowWidth > 1025 ? (
                <div className="controls">
                    <div className="controls-block-space-between">
                        <span className="filters">
                            <RangePicker
                                size="large"
                                format={"DD/MM/YYYY"}
                                className="filter"
                                allowClear={true}
                                value={filterDate}
                                onChange={handleFilterChange}
                            />

                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Escola"
                                allowClear={true}
                                value={filterSchool}
                                onChange={filter => setFilterSchool(filter)}
                            >
                                {school.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.nome}
                                    </Option>
                                ))}
                            </Select>

                            <Select
                                className="filter"
                                showSearch
                                disabled={!filterSchool}
                                placeholder="Selecionar Edicao"
                                allowClear={true}
                                value={filterEdition}
                                onChange={filter => setFilterEdition(filter)}
                            >
                                {edition.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.nome}
                                    </Option>
                                ))}
                            </Select>

                            <Select
                                className="filter"
                                showSearch
                                disabled={!filterEdition}
                                placeholder="Selecionar Turma"
                                allowClear={true}
                                value={filterCourse}
                                onChange={filter => setFilterCourse(filter)}
                            >
                                {course.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.nome}
                                    </Option>
                                ))}
                            </Select>

                            <Select
                                className="filter"
                                showSearch
                                disabled={!filterCourse}
                                placeholder="Selecionar Disciplina"
                                allowClear={true}
                                value={filterDiscipline}
                                onChange={filter => setFilterDiscipline(filter)}
                            >
                                {discipline.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.nome}
                                    </Option>
                                ))}
                            </Select>
                        </span>
                    </div>
                    <div className="controls-block">
                        <button className="search-button botao-principal" disabled={!filterDiscipline} onClick={handleSearch}>
                            Pesquisar
                            <Icon type="search" />
                        </button>
                    </div>
                </div>
            ) : (
                <div className="controls">
                    <div className="controls-block-space-between">
                        <span className="filters">
                            <RangePicker
                                size="large"
                                format={"DD/MM/YYYY"}
                                className="filter"
                                allowClear={true}
                                value={filterDate}
                                onChange={handleFilterChange}
                            />

                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Escola"
                                allowClear={true}
                                value={filterSchool}
                                onChange={filter => setFilterSchool(filter)}
                            >
                                {school.map((item, index) => (
                                    <Option key={index} value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </Select>

                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Edicao"
                                allowClear={true}
                                value={filterEdition}
                                onChange={filter => setFilterEdition(filter)}
                            >
                                {edition.map((item, index) => (
                                    <Option key={index} value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </Select>

                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Turma"
                                allowClear={true}
                                value={filterCourse}
                                onChange={filter => setFilterCourse(filter)}
                            >
                                {course.map((item, index) => (
                                    <Option key={index} value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </Select>

                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Disciplina"
                                allowClear={true}
                                value={filterDiscipline}
                                onChange={filter => setFilterDiscipline(filter)}
                            >
                                {discipline.map((item, index) => (
                                    <Option key={index} value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </Select>
                        </span>
                    </div>
                    <div className="controls-block">
                        <button className="search-button botao-principal" disabled={disabledSearch} onClick={handleSearch}>
                            Pesquisar
                            <Icon type="search" />
                        </button>
                    </div>
                </div>
            )}
            <div ref={componentRef} className="printArea">
                <div className="print-header">
                    <h1>
                        <span>Relatórios - {pageTitle}</span>
                        <span>{moment().format("DD/MM/YYYY HH:mm")}</span>
                    </h1>
                </div>
                <div className="print-body">
                    <Table
                        className="components-table-demo-nested"
                        columns={cols}
                        pagination={false}
                        expandedRowRender={expandedRowRender}
                        dataSource={data}
                    />
                </div>
            </div>
            <Modal title="Selecionar colunas" visible={visible} onOk={confirmColumns} okText="Confirmar" onCancel={() => setVisible(false)}>
                <Checkbox.Group defaultValue={selectedColumns} style={{ width: "100%" }} onChange={onChangeCheckbox}>
                    <Row>
                        {columns.map((column, index) => (
                            <Col key={index} span={8}>
                                <Checkbox value={column.key}>{column.title}</Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Modal>
        </>
    );
};

export default Controlos;

/**
 * 
 *                     <div className="filters-block">
                        <span>Filtros</span>
                        <div className="filters-tags">
                            {search && <span>Pesquisa: {search}</span>}
                            {filterSchool && <span>Escola: {schools.find(x => x.id === filterSchool)?.name}</span>}
                            {filterEdition && <span>Edição: {editions.find(x => x.id === filterEdition)?.name}</span>}
                            {filterCourse && <span>Turma: {courses.find(x => x.id === filterCourse)?.name}</span>}
                        </div>
                    </div>
 * 
 * 
 */
