import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Topico from "./_Aux/Topico";
import { GeralContext } from "../ContextProvider";
import axios from "axios";

class Forum extends Component {
    static contextType = GeralContext;
    state = {
        cursoid: 0,
        curso: "",
        retomar: {},
        forum_curso: [],
        forum_curso_modulo: [],
        razao: "",
        total_conteudos: 0,
        total_conteudos_realizados: 0,
        //DRAWER
        visible: false,
        //LOADING
        loading: true,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoid: cursoId,
            curso: this.props.match.params.curso
        });
        this.CarregarForumCurso(cursoId);
    }

    handlerChange = event => {
        this.setState({
            razao: event.target.value
        });
    };

    //CARREGA OS TOPICO SOBRE O CURSO
    CarregarForumCurso = cursoid => {
        axios({
            method: "get",
            url: "/api/forum/forum-curso",
            timeout: 60 * 10 * 1000,
            params: {
                cursoid: cursoid
            }
        })
            .then(response => {
                this.setState({
                    forum_curso: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)} style={{ marginRight: 10 }}>
                Voltar
            </Link>);
        }
    }

    render() {
        const {
            cursoid,
            curso,
            forum_curso,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="container container-body">
                {this.state.loading ? (
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                ) : (
                    <div className="curso-forum">
                        <div className="controlos controlos-fundo">
                            <div className="controlos-opcoes">
                                {this.montarBotaoVoltar()}
                            </div>
                        </div>
                        <div className="topicos">
                            <div className="bloco-topicos">
                                <div className="forum-aulas-listagem">
                                    {forum_curso.length > 0 ? (
                                        <div className="forum-content">
                                            <div className="forum-listagem">
                                                {forum_curso.map((topico, index) => (
                                                    <Topico
                                                        key={index}
                                                        url={`/curso/${curso
                                                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                                            .toLowerCase()}/curso-forum-detalhe`}
                                                        topico={topico}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Forum;
