import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, notification, Icon, Form, Input, Modal } from "antd";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerFicha = ({ ativo, visible, fichaId, form, onClose, atualizar, update }) => {
    const [nome, setNome] = useState("");
    const [estrutura, setEstrutura] = useState([]);
    const [bloqueada, setBloqueada] = useState(false);
    const [loading, setLoading] = useState(false);
    const [estruturaPreenchida, setEstruturaPreenchida] = useState(false);

    const { getFieldDecorator } = form;

    const handleChange = (event, index) => {
        let _estrutura = estrutura;
        _estrutura[index].nome = event.target.value;

        setEstrutura(_estrutura);
    };

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/gestao-fichas/detalhe",
            params: {
                fichaId
            }
        })
            .then(response => {
                setNome(response.data.nome);
                setEstrutura(response.data.estrutura);
                setBloqueada(response.data.bloqueada);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.setState({ iconLoading: false });
            });
    };

    const criar = event => {
        event.preventDefault();

        if (estrutura.length === 0) {
            openNotificationWithIcon("error", "Erro", "A estrutura de campos está vazia");
            return;
        }

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("nome", nome);
                item.append("estrutura", JSON.stringify(estrutura));

                axios({
                    method: "post",
                    url: "/api/gestao-fichas/adicionar",
                    data: item
                })
                    .then(() => {
                        setLoading(false);
                        openNotificationWithIcon("success", "Sucesso", "Ficha adicionada!");
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            }
        });
    };

    const alterar = event => {
        event.preventDefault();

        if (estrutura.length === 0) {
            openNotificationWithIcon("error", "Erro", "A estrutura de campos está vazia");
            return;
        }

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("id", fichaId);
                item.append("nome", nome);
                item.append("estrutura", JSON.stringify(estrutura));

                axios({
                    method: "put",
                    url: "/api/gestao-fichas/alterar",
                    data: item
                })
                    .then(() => {
                        setLoading(false);
                        openNotificationWithIcon("success", "Sucesso", "Ficha adicionada!");
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            }
        });
    };

    const adicionarCampo = () => {
        const campo = {
            id: "",
            nome: ""
        };

        setEstrutura([...estrutura, campo]);
    };

    const excluirCampo = index => {
        form.resetFields();
        let _estrutura = estrutura;
        _estrutura.splice(index, 1);
        setEstrutura(_estrutura);
    };

    const afterVisibleChange = aberto => {
        form.resetFields();
        if (aberto) {
            if (fichaId) detalhe(fichaId);
        } else {
            setNome("");
            setEstrutura([]);
        }
    };

    const permissao = update || !fichaId;

    return (
        <Drawer
            className="drawer-add-cursos drawer-ficha"
            //title={fichaId ? "Alterar" : "Adicionar"}
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label="Estrutura">
                            {getFieldDecorator("nome", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: nome
                            })(<Input name="nome" disabled={!ativo} onChange={event => setNome(event.target.value)} />)}
                        </Form.Item>
                    </div>
                    <div className="bloco bloco-estrutura">
                        <h3 className="titulo-separador">
                            Estrutura de Campos
                            {!bloqueada && ativo && (
                                <button className="botao-principal" onClick={adicionarCampo}>
                                    <Icon type="plus" />
                                </button>
                            )}
                        </h3>
                        <div className="lista">
                            {!estrutura.length ? <span>Sem dados</span> : null}
                            {estrutura.map((campo, index) => (
                                <div key={index} className="item">
                                    <Form.Item>
                                        {getFieldDecorator(`campo${index}`, {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: campo.nome
                                        })(
                                            <Input
                                                name={`campo${index}`}
                                                disabled={bloqueada || !ativo}
                                                placeholder="Titulo do campo"
                                                maxLength="100"
                                                onChange={event => handleChange(event, index)}
                                            />
                                        )}
                                    </Form.Item>
                                    {!bloqueada && ativo && (
                                        <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={() => excluirCampo(index)}>
                                            <Icon type="delete" />
                                        </Link>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </button>
                {ativo && permissao && (
                    <button className="botao-principal" onClick={fichaId ? alterar : criar} disabled={loading} type="primary">
                        {loading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                )}
            </div>
        </Drawer>
    );
};
const FormDrawerFicha = Form.create({ name: "form-drawer-ficha" })(DrawerFicha);

export default FormDrawerFicha;
