import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Modal, Upload, notification, DatePicker, Tooltip, Icon, Input, Select } from "antd";
import axios from "axios";
import moment from "moment";

import { GlobalContext } from "../../../../GlobalState";

const { Option } = Select;
const confirm = Modal.confirm;
const Dragger = Upload.Dragger;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
const { TextArea } = Input;

const DrawerAnular = ({ visible, inscricaoId, form, onClose }) => {
    const [data, setData] = useState(moment());
    const [fileList, setFileList] = useState([]);
    const [motivo, setMotivo] = useState(undefined);
    const [iconLoading, setIconLoading] = useState(false);
    const [docGerado, setDocGerado] = useState("");

    const context = useContext(GlobalContext);

    const { getFieldDecorator } = form;

    const confirma = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                confirm({
                    title: "Pretende anular esta matrícula?",
                    okText: "Confirmar",
                    okType: "Cancelar",
                    onOk: () => {
                        setIconLoading(true);
                        var item = new FormData();
                        item.append("id", inscricaoId);
                        item.append("data", moment(data).format("YYYY-MM-DD"));
                        item.append("anexo", fileList[0]);
                        item.append("motivo", motivo);

                        axios({
                            method: "post",
                            url: "/api/gestao-turma-matriculas/anular-matricula",
                            data: item
                        })
                            .then(response => {
                                onClose();
                                setIconLoading(false);
                                openNotificationWithIcon("success", "Sucesso", response.data);
                            })
                            .catch(error => {
                                setIconLoading(false);
                                openNotificationWithIcon("error", "Erro", error.response.data);
                            });
                    }
                });
            }
        })
    }

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    const validarFormatoFicheiro = formato => {
        var formatos = ["pdf"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file) {
            if (value.file.size > 26214400)
                callback("Limite de 25 MB por upload!");
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido!");
        }

        callback();
    };

    const download = () => {
        const link = document.createElement('a');
        link.href = docGerado;
        link.setAttribute(
            'download',
            'Transferência.pdf',
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    const excluirDoc = () => {
        setFileList([]);
        setDocGerado("");
    }

    const props = {
        accept: ".pdf",
        multiple: false,
        onRemove: file => {
            form.resetFields("ficheiro");
            setFileList(fileList => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: file => {

            setFileList([file]);

            const blob = new Blob([file]);
            const fileUrl = URL.createObjectURL(blob);
            setDocGerado(fileUrl);

            return false;
        },
        fileList
    };

    const afterVisibleChange = visible => {
        form.resetFields();
        if (!visible) {
            setData(moment());
            setFileList([]);
            setMotivo(undefined);
            setIconLoading(false);
            setDocGerado("");
        }
    }

    return (
        <Drawer
            className="drawer-inscrever drawer-editar-matricula"
            //title="Alterar"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-drawer-associar">
                <Form className="form-emitir-declaracao">
                    <div className="bloco">
                        <Form.Item label="Data">
                            {getFieldDecorator("data", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: data
                            })(
                                <DatePicker
                                    name="data"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={(data) => setData(data)}
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <div className="anexos">
                                {getFieldDecorator("ficheiro", {
                                    rules: [
                                        {
                                            validator: validar
                                        }
                                    ],
                                    initialValue: fileList
                                })(
                                    <Dragger {...props} disabled={docGerado} showUploadList={false}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexo
                                            <Tooltip title="Formatos válidos: .pdf">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                )}
                            </div>
                            {docGerado && (
                                <div className="bloco">
                                    <div className="lista-videos">
                                        <div className="item-video">
                                            <span className="video-titulo">Doc</span>
                                            <div className="video-controlos">
                                                <Link to="#" className="botao-icon-download" title="Descarregar" onClick={download}>
                                                    <Icon type="download" />
                                                </Link>
                                                <Link to="#" className="botao-icon-excluir" title="Excluir" onClick={excluirDoc}>
                                                    <Icon type="delete" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Form.Item>
                        <Form.Item label="Motivo">
                            {getFieldDecorator("motivo", {
                                initialValue: motivo
                            })(
                                <Select
                                    placeholder="Selecionar"
                                    name="motivo"
                                    allowClear={true}
                                    onChange={(motivo) => setMotivo(motivo)}
                                >
                                    <Option value="Transferido">Transferido</Option>
                                    <Option value="Remanejado">Remanejado</Option>
                                    <Option value="Desistente">Desistente</Option>
                                    <Option value="Falecido">Falecido</Option>
                                    {context.id_entidade === 33 && (
                                        <Option value="Reclassificado">Reclassificado</Option>)}
                                </Select>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button className="botao-principal" disabled={iconLoading} type="primary" onClick={confirma}>
                    {iconLoading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
        </Drawer>
    );
}

const FormDrawerAnular = Form.create({ name: "drawer-anular" })(DrawerAnular);

export default FormDrawerAnular;
