import React, { Component } from "react";
import { notification } from "antd";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";
import Noticia from "./Noticia";
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Lista extends Component {
    static contextType = GlobalContext;
    state = {
        noticias: [],
        //LOADING
        iconLoading: false,
        countNum: 0
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    componentDidUpdate() {
        this.state.count = this.state.countNum;
    }

    listar = () => {
        this.setState({
            iconLoading: true
        });
        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-todas-informacoes-ler-mais",
            params: {
                entidadeUrl: this.props.match.params.entidade,
                count: this.state.countNum
            }
        })
            .then(response => {
                this.setState({
                    noticias: [...this.state.noticias, ...response.data.noticias],
                    fim: response.data.fim,
                    iconLoading: false
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as informações");
                this.setState({
                    iconLoading: false
                });
            });
    };

    incrementar = () => {
        this.setState({ countNum: this.state.countNum + 1 }, () => {
            this.listar();
        });
    };

    render() {
        const { iconLoading } = this.state;

        return (
            <>
                <Header titulo="Notícias" />
                <div className="lista-noticias">
                    <div className="lista">
                        {this.state.noticias.map(noticia => (
                            <Noticia noticia={noticia} />
                        ))}
                    </div>
                    {!this.state.fim ? (
                        <div style={{ display: "flex", justifyContent: "center", padding: 40 }}>
                            <button className="botao-secundario btn-load-more" disabled={iconLoading} onClick={this.incrementar}>
                                {!iconLoading ? "Ver mais" : "A carregar..."}
                            </button>
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}
export default Lista;
