﻿import React, { useState } from "react"
import { Drawer } from "antd"
import Editor from "./Editor"

const FormPaginaHTML = props => {
    const [show, setShow] = useState(false)

    function afterVisibleChange(visible) {
        if (visible)
            setShow(visible)
    }

    return (
        <Drawer
            className="drawer-from-pagina-html"
            width={720}
            onClose={props.onClose}
            visible={props.visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <div style={{ minHeight: 40 }}></div>
                {show && <Editor value={props.recurso} onBlur2={props.handleChangeEditor} height="700px" />}
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={props.onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
            </div>
        </Drawer>
    )
}

export default FormPaginaHTML
