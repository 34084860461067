import React from 'react';


const TabelaRelatorioAlunoMobile = ({ dynamicColumns, disciplinas }) => {
    const tempCols = dynamicColumns.filter(dc => dc.title !== "Disciplinas" && dc.title !== "Estado")

    const montarValores = (avaliacoes) => {
        let registos = [];
        let media = 0;

        let tempAvaliacoes = avaliacoes;

        tempCols.forEach((column, index) => {
            const avaIndex = tempAvaliacoes.findIndex(av => av.nome === column.title);

            if (avaIndex !== -1) {
                const ava = tempAvaliacoes[avaIndex];
                if (ava.nome !== "Média Bimestral" && ava.nome !== "Média Anual") {
                    registos.push(
                        <tr key={index} className="tabelaRelatorioAlunoDados">
                            <td>{column.title}</td>
                            <td>{ava.nota}</td>
                        </tr>
                    );
                } else {
                    media = ava.nota.replace(",", ".");
                    registos.push(
                        <tr key={index} className="tabelaRelatorioAlunoDados">
                            <td>{column.title}</td>
                            <td>
                                <div className={media >= 6 ? 'fundoCelula aprovado' : 'fundoCelula reprovado'}>
                                    {media}
                                </div>
                            </td>
                        </tr>
                    );
                }

                // Remove o item encontrado
                tempAvaliacoes = tempAvaliacoes.filter((_, i) => i !== avaIndex);
            } else {
                registos.push(
                    <tr key={index} className="tabelaRelatorioAlunoDados">
                        <td>{column.title}</td>
                        <td>-</td>
                    </tr>
                );
            }
        });

        registos.push(<tr className="tabelaRelatorioAlunoDados">
            <td>Estado</td>
            <td>
                {
                    <div className={media >= 6 ? 'fundoCelula aprovado' : 'fundoCelula reprovado'}>
                        {media >= 6 ? 'Aprovado' : 'Reprovado'}
                    </div>
                }
            </td>
        </tr>)

        return registos;
    }

    return (
        <div style={{ margin: "10px 0" }}>
            {disciplinas.map((disciplina, index) => (
                <div key={index} className="disciplina" style={{ margin: "10px 0" }}>
                    <table className="tabelaRelatorioAluno" >
                        <tbody>
                            <tr className="tabelaRelatorioAlunoDados">
                                <td>Disciplinas</td>
                                <td>{disciplina.disciplina}</td>
                            </tr>
                            {montarValores(disciplina.avaliacoes).map(registo => registo)}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default TabelaRelatorioAlunoMobile;
