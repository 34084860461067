import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, Select } from "antd";

const Option = Select.Option;

class TabelaProximas extends Component {
    state = {
        filteredInfo: null,
        sortedInfo: null
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter
        });
    };

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        const { videoconferencias } = this.props;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: "Inicio",
                dataIndex: "inicio",
                key: "inicio"
            },
            {
                title: "Assunto",
                dataIndex: "assunto",
                key: "assunto"
            },
            {
                title: "Agendada por",
                dataIndex: "agendada",
                key: "agendada"
            },
            {
                title: "",
                dataIndex: "controlos",
                key: "controlos"
            }
        ];

        return (
            <>
                <Table
                    columns={columns}
                    dataSource={videoconferencias}
                    pagination={false}
                    loading={this.props.loading}
                    onChange={this.props.handleTableChange}
                    rowClassName="clickeble-row"
                    onRow={(record, index) => {
                        return {
                            onClick: () => this.props.onRowClick(record.id)
                        };
                    }}
                    locale={{ emptyText: "Não existem dados!" }}
                />
            </>
        );
    }
}

export default withRouter(TabelaProximas);
