import React, {useContext, useEffect, useState} from "react";
import DropdownMeses from "./DropdownMeses";
import 'antd/dist/antd.css';
import BarChartGrad from "./BarChartGradiente"
import {FaSortDown, FaSortUp} from "react-icons/fa";
import DropdownAnos from "./DropdownAnos";
import {DataContext} from "../Contexts/DataProvider";
import {Icon} from "antd";

export default function GraficoBarras() {
    const {eficienciaAlunos} = useContext(DataContext);
    const [total, setTotal] = useState()
    
    // console.log("eficienciaAlunos",eficienciaAlunos)

    useEffect(() => {
        var total = 0
        
        if(eficienciaAlunos){
            eficienciaAlunos.map((x)=>{
                total += x;
            })
            
            setTotal(total)
        }
       
        
    }, [eficienciaAlunos]);

    // const percentagem = -2.45
    return (
        <div className="graficoCircular">
            <div className="topo-tabela">
                <span>Eficiência de Alunos</span>
                {/*<span className={`aligned-text ${percentagem < 0 && 'corVermelha'}`}> */}
                {/*   {percentagem > 0 ? (<FaSortUp />):(<FaSortDown />)} {percentagem>0 ? ("+"):("")} {percentagem}%*/}
                {/*</span>*/}
            </div>
            <div>
                <span className="numeroTopo">
                   {total ? total:0}
                </span>
                <span className="atividadesFrequentes">
                    Notas acima da média.
                </span>
            </div>
            <div style={{marginTop: '-30px'}}>
                {eficienciaAlunos ? <BarChartGrad data={eficienciaAlunos}/> : <div className="carregar">
                    <div className="alinhar"><Icon type="loading"/> <span>A carregar</span></div>
                </div>}
            </div>

        </div>
    );
}
