import React from "react";
import { Link } from "react-router-dom";

const IndiceVersoes = props => {
    return (<li className={props.indice_ativo}>
        <Link to={`${props.url}`} className={props.styleContacto} onClick={props.onClick}>
            <span>{props.titulo_indice}</span>
        </Link>
    </li>);
};

export default IndiceVersoes;
