import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { notification, Modal, Icon, Collapse, Dropdown, Menu, Drawer, Form } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../images/noimage.png";
import DrawerFormUploadFicheiro from "./_Aux/FormUploadFicheiro";
import { GlobalContext } from "../../GlobalState";

const { Panel } = Collapse;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Pagamentos extends Component {
    static contextType = GlobalContext;
    state = {
        pagamentos: [],
        //IMPORTAR COMPROVATIVO
        pagamentoId: 0,
        codigoPagamento: "",
        visibleImportar: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER DETALHE
        visibleDetalhe: false,
        tipoPagamento: "",
        docRecibo: "",
        docComprovativo: "",
        produtos: []
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listarPagamentos();
    }

    listarPagamentos = () => {
        axios({
            method: "get",
            url: "/api/minha-conta/listar-pagamentos"
        })
            .then(response => {
                let pagamentos = [];

                response.data.map(pagamento => {
                    let produtos = [];

                    pagamento.produtos.map(produto => {
                        produtos.push({
                            capa: produto.curso.imagem,
                            nome: produto.nome,
                            descricao: produto.descricao,
                            preco: produto.preco,
                            tipo: produto.tipo,
                            nr_meses: produto.nr_meses,
                            dt_inicio: produto.dt_inicio,
                            dt_fim: produto.dt_fim
                        })
                    });

                    pagamentos.push({
                        id: pagamento.id,
                        codigo: pagamento.codigo,
                        data: pagamento.dt_criado,
                        total: pagamento.total,
                        tipo: pagamento.tipo,
                        estado: pagamento.pago ? "PAGO" : "PENDENTE",
                        documento: pagamento.documento,
                        comprovativo: pagamento.comprovativo,
                        produtos
                    })
                });

                this.setState({
                    pagamentos
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    adicionarFicheiros = () => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            documentoId: 0,
            nome: "",
            fileList: []
        })
    }

    abrirDrawerUpload = (pagamentoId, codigoPagamento) => {
        this.setState({
            pagamentoId,
            codigoPagamento,
            visibleImportar: true,
        })
    }

    abrirDetalhe = (pagamento) => {
        this.setState({
            visibleDetalhe: true,
            tipoPagamento: pagamento.tipo === "PAYPAL" ? "Paypal, CC ou Mercado Pago" : "Transferência",
            docRecibo: pagamento.documento,
            docComprovativo: pagamento.tipo === "TRANSFERENCIA" && pagamento.estado === "PAGO" && pagamento.comprovativo ? pagamento.comprovativo : "",
            produtos: pagamento.produtos
        })
    }

    render() {
        const {
            pagamentos,
            //IMPORTAR COMPROVATIVO
            pagamentoId,
            codigoPagamento,
            visibleImportar,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER DETALHE
            visibleDetalhe,
            tipoPagamento,
            docRecibo,
            docComprovativo,
            produtos
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const opcoes = pagamento => {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.abrirDrawerUpload(pagamento.id, pagamento.codigo)
                            }}
                        >
                            Comprovativo
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        const opcoesMobile = pagamento => {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(pagamento)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.abrirDrawerUpload(pagamento.id, pagamento.codigo)
                            }}
                        >
                            Comprovativo
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        const montarHeader = pagamento => {
            return (<div className="content">
                <span className="desc">{pagamento.codigo}</span>
                <span className="desc">{moment(pagamento.data).format("DD/MM/YYYY")}</span>
                <span className="desc">{pagamento.tipo === "PAYPAL" ? "Paypal, CC ou Mercado Pago"  : "Transferência"}</span>
                <span className={`desc ${pagamento.estado === "PAGO" ? "pagamento-pago" : "pagamento-pendente"}`}>{pagamento.estado}</span>
                <span className="desc">{this.context.moeda_entidade} {pagamento.total}</span>
                <span className="desc" style={{ textAlign: "right" }}>
                    {pagamento.documento ?
                        <a href={pagamento.documento} download title="Descarregar Recibo" onClick={event => event.stopPropagation()} style={{ marginRight: 10 }}><Icon type="download" /></a>
                        : null}
                    {pagamento.tipo === "TRANSFERENCIA" && pagamento.estado === "PAGO" && pagamento.comprovativo ?
                        <a href={pagamento.comprovativo} download title="Descarregar Comprovativo" onClick={event => event.stopPropagation()}><Icon type="download" /></a>
                        : null}
                </span>
                <span className="desc">
                    {pagamento.tipo === "TRANSFERENCIA" && pagamento.estado === "PENDENTE" ?
                        <Dropdown overlay={opcoes(pagamento)} placement="bottomLeft">
                            <Link to="#" className="botao-icon-configs">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                        : null}
                </span>
            </div>)
        }

        return (
            <div className="bloco-minha-conta">
                <div className="pagamentos">
                    <div className="box-registos-pagamentos box-registos-pagamentos-desktop">
                        <div className="header">
                            <span className="desc"></span>
                            <span className="desc">Compra</span>
                            <span className="desc">Data</span>
                            <span className="desc">Tipo de Pagamento</span>
                            <span className="desc">Estado</span>
                            <span className="desc">Preço total</span>
                            <span className="desc">Documentos</span>
                            <span className="desc"></span>
                        </div>
                        <Collapse className="lista-pagamentos-collapse">
                            {pagamentos.length > 0 ?
                                pagamentos.map((pagamento, index) => (
                                    <Panel key={index} header={montarHeader(pagamento)}>
                                        <div className="box-registos-pagamentos-produtos">
                                            <div className="header">
                                                <span className="desc">Nome</span>
                                                <span className="desc"></span>
                                                <span className="desc"></span>
                                            </div>
                                            {pagamento.produtos.map((produto, index2) => (
                                                <div key={index2} className="content">
                                                    {produto.tipo === "CURSO" ?
                                                        <span className="desc desc-grid">
                                                            <div className="container-capa">
                                                                <img src={produto.capa} />
                                                            </div>
                                                            <div className="bloco-info">
                                                                <span className="produto-nome">{produto.nome}</span>
                                                                <span className="produto-descricao">{produto.descricao}</span>
                                                            </div>
                                                        </span>
                                                        :
                                                        <span className="desc">
                                                            <div className="bloco-info">
                                                                <span className="produto-nome">Subscrição {produto.nr_meses > 1 ? `${produto.nr_meses} meses` : `${produto.nr_meses} mês`}</span>
                                                                <span className="produto-descricao">Subscrição de {moment(produto.dt_inicio).format("DD/MM/YYYY")} a {moment(produto.dt_fim).format("DD/MM/YYYY")}</span>
                                                            </div>
                                                        </span>
                                                    }
                                                    <span className="desc"></span>
                                                    <span className="desc">{this.context.moeda_entidade} {produto.preco}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </Panel>
                                ))
                                :
                                <div className="sem-registos">
                                    Não existem dados!
                                </div>
                            }
                        </Collapse>
                    </div>
                    <div className="box-registos-pagamentos box-registos-pagamentos-mobile">
                        <div className="header">
                            <span className="desc">Compra</span>
                            <span className="desc">Estado</span>
                            <span className="desc"></span>
                        </div>
                        {pagamentos.length > 0 ?
                            pagamentos.map((pagamento, index) => (
                                <div key={index} className="content">
                                    <span className="desc">
                                        <div className="box-pagamento">
                                            <div className="info-pagamento">
                                                <span className="codigo-pagamento">
                                                    {pagamento.codigo}
                                                </span>
                                                <div className="data-pagamento">
                                                    {moment(pagamento.data).format("DD/MM/YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <span className="desc">
                                        <div className="estado-pagamento">
                                            {pagamento.estado === "PAGO" ?
                                                <span className="estado-pagamento-icon-pago"><Icon type="check-circle" title="Pagamento Efetuado" /></span>
                                                :
                                                <span className="estado-pagamento-icon-pendente"><Icon type="warning" title="Pagamento Pendente" /></span>
                                            }
                                            <span className="estado-pagamento-texto">{this.context.moeda_entidade} {pagamento.total}</span>
                                        </div>
                                    </span>
                                    <span className="desc">
                                        {pagamento.tipo === "TRANSFERENCIA" && pagamento.estado === "PENDENTE" ?
                                            <Dropdown overlay={opcoesMobile(pagamento)} placement="bottomLeft">
                                                <Link to="#" className="botao-icon-configs">
                                                    <i className="fas fa-cog" />
                                                </Link>
                                            </Dropdown>
                                            : null}
                                    </span>
                                </div>
                            ))
                            :
                            <div className="sem-registos">
                                Não existem dados!
                                </div>
                        }
                    </div>
                </div>
                <Drawer
                    className="drawer-detalhe-pagamentos"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Tipo de Pagamento">
                                    {tipoPagamento}
                                </Form.Item>
                                {docRecibo &&
                                    <Form.Item label="Recibo">
                                    <a href={docRecibo} download title="Descarregar Recibo"><Icon type="download" /></a>
                                    </Form.Item>}
                                {docComprovativo &&
                                    <Form.Item label="Comprovativo">
                                    <a href={docComprovativo} download title="Descarregar Comprovativo"><Icon type="download" /></a>
                                    </Form.Item>}
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Produtos</h3>
                                <div className="box-registos-pagamentos-produtos">
                                    <div className="header">
                                        <span className="desc">Nome</span>
                                        <span className="desc"></span>
                                    </div>
                                    {produtos.map((produto, index2) => (
                                        <div key={index2} className="content">
                                            {produto.tipo === "CURSO" ?
                                                <span className="desc desc-grid">
                                                    <div className="container-capa">
                                                        <img src={produto.capa} />
                                                    </div>
                                                    <div className="bloco-info">
                                                        <span className="produto-nome">{produto.nome}</span>
                                                        <span className="produto-descricao">{produto.descricao}</span>
                                                    </div>
                                                </span>
                                                :
                                                <span className="desc">
                                                    <div className="bloco-info">
                                                        <span className="produto-nome">Subscrição {produto.nr_meses > 1 ? `${produto.nr_meses} meses` : `${produto.nr_meses} mês`}</span>
                                                        <span className="produto-descricao">Subscrição de {moment(produto.dt_inicio).format("DD/MM/YYYY")} a {moment(produto.dt_fim).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                </span>
                                            }
                                            <span className="desc">{this.context.moeda_entidade} {produto.preco}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <DrawerFormUploadFicheiro
                    pagamentoId={pagamentoId}
                    codigoPagamento={codigoPagamento}
                    visible={visibleImportar}
                    onClose={() => this.setState({ visibleImportar: false })}
                />
            </div>
        );
    }
}

export default Pagamentos;
