import {FaRegCalendar, FaRegCommentDots, FaRegFolder} from "react-icons/fa6";
import React from "react";


export default function Detalhes (props){
    return (
        <div className="detalhe">
            <div>
                <FaRegCalendar />
                {props.data != null &&
                    <span>{props.data}</span>}
            </div>
            <div>
                <FaRegCommentDots/>
                <span>Comentarios : {props.comentarios}</span>

            </div>
            <div>
                <FaRegFolder/>
                <span>Categoria : {props.categoria}</span>
            </div>
        </div>
    );

}