import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Input, notification, Progress, Icon, Drawer, Modal, Tooltip, Checkbox, Col, Row } from "antd";
import { Upload as UploadVimeo } from "tus-js-client";
import axios from "axios";
import { GlobalContext } from "../../../GlobalState";
import Dragger from "antd/es/upload/Dragger";

import { arraysHaveSameElements } from "../../../Utilities/compare"

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514";

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
};

const DrawerVideos = ({ ativo, visible, videoId, form, onClose, atualizar }) => {
    const context = useContext(GlobalContext);
    const [todos, setTodos] = useState(false);
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [state, setState] = useState({
        capa: "",
        preview: null,
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        videoId: "",
        video: [],
        videoDisponivel: false,
        upload_progress: 0,
        iconLoading: false,
        redirect: false,
        redirectLink: "",
        intervalID: "",
        titulo: "",
        descricao: "",
        selecionados: []
    });

    const [validate, setValidate] = useState({
        totalBytes: 0,
        formatoValido: false,
        validateStatus: "",
        help: ""
    });

    const [loading, setLoading] = useState(false);

    const { getFieldDecorator } = form;
    const { TextArea } = Input;

    const listarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos"
        })
            .then(response => {
                setEstabelecimentos(response.data);
            })
            .catch(error => {});
    };

    const carregarDetalhe = () => {
        axios({
            method: "get",
            url: "/api/videos/detalhe",
            params: {
                id: videoId
            }
        })
            .then(response => {
                const sels = response.data.estabelecimentos.filter(estabelecimento => !!estabelecimento);

                if (response.data.idVideo) {
                    setState(prevState => ({
                        ...prevState,
                        preview: response.data.capa,
                        videoId: response.data.idVideo,
                        titulo: response.data.titulo,
                        descricao: response.data.descricao,
                        selecionados: sels
                    }));
                    videoStatusCheck(response.data.idVideo);
                } else {
                    setState(prevState => ({
                        ...prevState,
                        preview: response.data.capa,
                        selecionados: sels,
                        videoId: response.data.idVideo,
                        titulo: response.data.titulo,
                        descricao: response.data.descricao,
                        videoDisponivel: true
                    }));
                }
            })
            .catch(error => {
                if (error.response.status === 401)
                    setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    useEffect(() => {
        listarEstabelecimentos();
    }, []);

    const videoStatusCheck = videoId => {
        if (!videoId) return;

        axios({
            method: "get",
            url: `https://api.vimeo.com/videos/${videoId}`,
            transformRequest: [
                function (data, headers) {
                    delete headers.common["apikey"];
                    return JSON.stringify(data);
                }
            ],
            headers: headerPost
        })
            .then(response => {
                setState(prevState => ({
                    ...prevState,
                    videoDisponivel: response.data.status === "available"
                }));
                if (response.data.status === "available") window.clearInterval(state.intervalID);
            })
            .catch(error => {
                //if (error.response.status === 404) openNotificationWithIcon("error", "Erro", "O vídeo não existe!");
                //setState(prevState => ({
                //    ...prevState,
                //    videoId: ""
                //}));
                window.clearInterval(state.intervalID);
            });
    };

    const criarVideoVimeo = () => {
        setState(prevState => ({ ...prevState, iconLoading: true, upload_progress: 0 }));

        const file = state.video[0];
        const fileSize = file.size.toString();

        axios({
            method: "post",
            url: `https://api.vimeo.com/me/videos`,
            transformRequest: [
                function (data, headers) {
                    delete headers.common["apikey"];
                    return JSON.stringify(data);
                }
            ],
            headers: headerPost,
            data: {
                upload: {
                    status: "in_progress",
                    approach: "tus",
                    size: fileSize
                },
                name: "Video Capa",
                privacy: { view: "disable" },
                outro: "Stepforma"
            }
        }).then(response => {
            uploadVimeo(file, response);
        });
    };

    const uploadVimeo = (file, response) => {
        const upload = new UploadVimeo(file, {
            endPoint: "https://api.vimeo.com/me/videos",
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar o vídeo!");
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1);
                setState(prevState => ({
                    ...prevState,
                    upload_progress: percentage
                }));
            },
            onSuccess: () => {
                var idVideoVimeo = response.data.link.split("/").pop();
                moverParaPasta(idVideoVimeo);
                adicionarDominioEPreset(idVideoVimeo);
                videoId > 0 ? atualizarVideo(idVideoVimeo) : guardarVideo(idVideoVimeo);
            }
        });

        upload.start();
    };

    const moverParaPasta = videoId => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/me/projects/${context.albumId_entidade}/videos/${videoId}`,
            transformRequest: [
                function (data, headers) {
                    delete headers.common["apikey"];
                    return JSON.stringify(data);
                }
            ],
            headers: headerPost
        });
    };

    const adicionarDominioEPreset = videoId => {
        axios({
            method: "get",
            url: "/api/curso/carregar-vimeo-configs"
        }).then(response => {
            adicionarPreset(videoId, response.data.presetId);
            adicionarDominio(videoId, response.data.dominio);
        });
    };

    const adicionarPreset = (videoId, presetId) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/presets/${presetId}`,
            transformRequest: [
                function (data, headers) {
                    delete headers.common["apikey"];
                    return JSON.stringify(data);
                }
            ],
            headers: headerPost
        });
    };

    const adicionarDominio = (videoId, dominio) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/privacy/domains/${dominio}`,
            transformRequest: [
                function (data, headers) {
                    delete headers.common["apikey"];
                    return JSON.stringify(data);
                }
            ],
            headers: headerPost
        });
    };

    const validarFormatos = (formato, formatos_aceites) => {
        return formatos_aceites.includes(formato);
    };

    const afterVisibleChange = aberto => {
        if (aberto) {
            form.resetFields();
            //document.getElementById("input-foto").value = "";
            if (videoId > 0)
                carregarDetalhe();
            else {
                setState({
                    capa: "",
                    preview: null,
                    totalBytes: 0,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    videoId: "",
                    video: [],
                    videoDisponivel: false,
                    upload_progress: 0,
                    iconLoading: false,
                    redirect: false,
                    redirectLink: "",
                    intervalID: "",
                    titulo: "",
                    descricao: "",
                    selecionados: []
                });

                setValidate({
                    totalBytes: 0,
                    formatoValido: false,
                    validateStatus: "",
                    help: ""
                });
            }
        }
    };

    const guardarVideo = videoId => {
        form.validateFieldsAndScroll(err => {
            if (state.titulo !== "" && state.descricao !== "" && state.video !== [] && state.selecionados !== null) {
                setLoading(true);

                var item = new FormData();
                item.append("capa", state.capa);
                item.append("idVideo", videoId);
                item.append("titulo", state.titulo);
                item.append("descricao", state.descricao);
                item.append("estabelecimentos", JSON.stringify(state.selecionados));

                axios({
                    method: "post",
                    url: "/api/videos/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Vídeo adicionado!");
                        atualizar();
                        setLoading(false);

                        setState(prevState => ({
                            ...prevState,
                            upload_progress: 0,
                            iconLoading: false
                        }));
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                        setState(prevState => ({
                            ...prevState,
                            iconLoading: false,
                            upload_progress: 0
                        }));
                    });
            }
        });
    };

    const atualiza = () => {
        if (!state.videoId) {
            criarVideoVimeo();
        } else {
            atualizarVideo();
        }
    };

    const atualizarVideo = idVideoVimeo => {
        form.validateFieldsAndScroll(err => {
            if (state.titulo !== "" && state.descricao !== "" && state.video !== [] && state.selecionados !== null) {
                setLoading(true);

                var item = new FormData();
                item.append("capa", state.capa);
                item.append("idVideo", idVideoVimeo === undefined ? state.videoId : idVideoVimeo);
                item.append("titulo", state.titulo);
                item.append("descricao", state.descricao);
                item.append("estabelecimentos", JSON.stringify(state.selecionados));

                axios({
                    method: "post",
                    url: "/api/videos/atualizar",
                    params: {
                        id: videoId
                    },
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Notícia adicionada!");
                        atualizar();
                        setLoading(false);

                        setState(prevState => ({
                            ...prevState,
                            upload_progress: 0,
                            iconLoading: false
                        }));
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            }
        });
    };

    const handleChangeCheckTodos = e => {
        const todos = e.target.checked;
        setTodos(todos)
        form.resetFields();

        if (todos) {
            const ids = estabelecimentos.map(x => x.id)
            setState({ ...state, selecionados: ids })
        }
        else
            setState({ ...state, selecionados: [] })
    };

    const fecharDrawer = () => {
        onClose();
    };

    const validarTodosSelecionados = () => {
        const sels = state.selecionados.filter(estabelecimento => !!estabelecimento);
        const ids = estabelecimentos.map(x => x.id);

        const sameElements = arraysHaveSameElements(ids, sels);

        setTodos(sameElements)
    }

    useEffect(() => {
        validarTodosSelecionados()
    }, [state.selecionados, todos]);

    const propsVideo = {
        accept: ".mp4, .mov, .wmv, .avi, .flv",
        name: "file",
        multiple: false,
        showUploadList: true,
        onRemove: file => {
            form.resetFields("video");
            setState(prevState => ({ ...prevState, video: [] }));
        },
        defaultFileList: state.video,
        beforeUpload: file => {
            if (!validarFormatos(file.name.split(".").pop().toLowerCase(), ["mp4", "mov", "wmv", "avi", "flv"])) {
                openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
                return false;
            }
            if (file.size > 5368709120) {
                openNotificationWithIcon("error", "Erro", "Limite de 5 GB por upload");
                return false;
            }
            if (state.video.length === 1) {
                setState(prevState => ({ ...prevState, video: [] }));
            }
            setState(prevState => ({ ...prevState, video: [...prevState.video, file] }));
            return false;
        }
    };

    return (
        <Drawer
            className="drawer-add-cursos drawer-videoconferencias"
            width={1440}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias" layout="horizontal">
                    <Form.Item label="Vídeo">
                        {getFieldDecorator("video", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: state.video
                        })(
                            <>
                                {state.videoId &&
                                    <div className="bloco-video" style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                                        {state.videoDisponivel ? (
                                            <iframe
                                                className="video"
                                                src={`https://player.vimeo.com/video/${state.videoId}`}
                                                id="player"
                                                frameBorder="0"
                                            ></iframe>
                                        ) : (
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                <span style={{ fontSize: 20 }}>O vídeo está quase pronto!</span>
                                                <span style={{ fontSize: 16 }}>Aguarde...</span>
                                            </div>
                                        )}
                                    </div>}
                                <Dragger {...propsVideo} fileList={state.video}>
                                    <p className="ant-upload-drag-icon">
                                        <i className="fas fa-upload" />
                                    </p>
                                    <p className="ant-upload-text">
                                        {state.videoId ? "Substituir anexo" : "Adicionar anexo"}{" "}
                                        <Tooltip title="Formatos válidos: .mp4, .mov, .wmv, .avi, .flv">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </p>
                                    <p className="ant-upload-hint">Limite de 5GB por upload</p>
                                </Dragger>
                            </>
                        )}
                    </Form.Item>
                    <Form.Item label="Título">
                        {getFieldDecorator("titulo", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: state.titulo
                        })(
                            <Input
                                name="titulo"
                                maxLength={100}
                                disabled={!ativo}
                                onChange={e => setState({ ...state, titulo: e.target.value })}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Descricao">
                        {getFieldDecorator("descricao", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: state.descricao
                        })(
                            <TextArea
                                rows={6}
                                name="descricao"
                                disabled={!ativo}
                                maxLength={200}
                                onChange={e => setState({ ...state, descricao: e.target.value })}
                                style={{ height: "100%" }}
                            />
                        )}
                        <p>{state.descricao?.length} / 200</p>
                    </Form.Item>
                    <Form.Item label="Publicar em:">
                        <Checkbox checked={todos} onClick={handleChangeCheckTodos}>Selecionar todos</Checkbox>
                        {getFieldDecorator("selecionados", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: state.selecionados // Certifique-se de que selecionados é um valor único (não uma array)
                        })(
                            <Checkbox.Group
                                style={{ width: "100%" }}
                                disabled={!ativo}
                                onChange={selecionados => setState({ ...state, selecionados })}
                            >
                                <Row>
                                    {estabelecimentos.map((estabelecimento, index) => (
                                        <Col key={estabelecimento.id} span={16}>
                                            <Checkbox value={estabelecimento.id} title={estabelecimento.nome}>{estabelecimento.abrev}</Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        )}
                    </Form.Item>
                </Form>
            </div>
            <Modal visible={state.iconLoading} maskClosable={false} className="modal-loading" footer={null} closable={false}>
                <div className="modal-loading-bloco">
                    <p>
                        <Progress type="circle" percent={Number(state.upload_progress)} />
                    </p>
                    <p className="texto">A enviar...</p>
                </div>
            </Modal>
            <div className="ant-drawer-footer">
                <Link to="#" className="botao-secundario" onClick={fecharDrawer} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </Link>
                {ativo && (
                    /*<button className="botao-principal" disabled={loading} onClick={videoId > 0 ? atualiza : criarVideoVimeo}>*/
                    <button className="botao-principal" disabled={state.iconLoading} onClick={!state.video.length ? atualiza : criarVideoVimeo}>
                        {state.iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                )}
            </div>
        </Drawer>
    );
};

const FormDrawerVideos = Form.create({ name: "form-drawer-videos" })(DrawerVideos);

export default FormDrawerVideos;
