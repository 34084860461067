import React, { Component } from "react";
import { Link, Redirect, matchPath } from "react-router-dom";
import Header from "../../User/Header";
import { Icon, DatePicker, Modal, notification, Tooltip, Alert } from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";

import ModalGerarDocumento from "../../Componentes/Modals/GerarDocumento";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class CursosEstatisticas extends Component {
    static contextType = GlobalContext;
    state = {
        dt_inicio: moment().subtract(1, "month"),
        dt_termino: moment(),
        cursoid: 0,
        nome_curso: "",
        nr_alunos: "",
        interacoes_forum: "",
        interacoes_anotacoes: "",
        mensagens_enviadas: "",
        formadores: "",
        tarefas_visitadas: "",
        tempos_tarefas: "",
        conteudos_concluidos: "",
        doc_fichs: "",
        ratingscursos: "",
        //ratingsaulas: "",
        avaliacoescursos: "",
        avaliacoesaulas: "",
        assiduidade: "",
        totalStepmeets: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //LOADING
        loading: false
    };

    UNSAFE_componentWillMount() {
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoid: cursoId
        });
        this.carregar(cursoId);
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    onChangeDataInicio = dt_inicio => {
        this.setState({
            dt_inicio
        });
    };

    onChangeDataTermino = dt_termino => {
        this.setState({
            dt_termino
        });
    };

    pesquisar = () => {
        var diff = this.state.dt_termino.diff(this.state.dt_inicio, "months");
        var diffDays = this.state.dt_termino.diff(this.state.dt_inicio, "days");

        if (diff >= 0 && diff <= 12 && diffDays >= 0) {
            this.carregar(this.state.cursoid);
        } else if (diff < 0 || diffDays < 0)
            openNotificationWithIcon("error", "Erro", "A data de Inicio deve ser inferior a data de Término");
        else openNotificationWithIcon("error", "Erro", "O intervalo das duas datas não deve ser superior a 12 meses");
    };

    //CARREGA ESTATISTICAS
    carregar = cursoid => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-estatistica/curso",
                    params: {
                        cursoid: cursoid,
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            nr_alunos: response.data.inscricoes,
                            formadores: response.data.cursosf,
                            interacoes_forum: response.data.interacoes_forum,
                            interacoes_anotacoes: response.data.interacoes_anotacoes,
                            mensagens_enviadas: response.data.mensagens_enviadas,
                            tarefas_visitadas: response.data.tarefas_visitadas,
                            tempos_tarefas: response.data.tempos_tarefas,
                            conteudos_concluidos: response.data.conteudos_concluidos,
                            doc_fichs: response.data.doc_fichs,
                            ratingscursos: response.data.ratingscursos,
                            //ratingsaulas: response.data.ratingsaulas,
                            avaliacoescursos: response.data.avaliacoescursos,
                            avaliacoesaulas: response.data.avaliacoesaulas,
                            assiduidade: response.data.assiduidade,
                            totalStepmeets: response.data.totalStepmeets,
                            loading: false
                        });
                    })
                    .catch(error => {
                        this.setState({
                            loading: false
                        });

                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    exportarCSV = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/curso",
                    params: {
                        cursoid: this.state.cursoid,
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarDET = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/curso-det",
                    params: {
                        cursoid: this.state.cursoid,
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    render() {
        const {
            dt_inicio,
            dt_termino,
            nome_curso,
            nr_alunos,
            formadores,
            interacoes_forum,
            interacoes_anotacoes,
            mensagens_enviadas,
            tarefas_visitadas,
            tempos_tarefas,
            conteudos_concluidos,
            doc_fichs,
            ratingscursos,
            //ratingsaulas,
            avaliacoescursos,
            avaliacoesaulas,
            assiduidade,
            totalStepmeets,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //LOADING
            loading
        } = this.state;

        return (
            <>
                <Header titulo="Estatísticas" />
                <div className="container container-body">
                    <div className="dashboard">
                        <div className="bloco-flex">
                            {localStorage.getItem("nome_curso_pai") ? (
                                <div>
                                    <h3>
                                        {localStorage.getItem("cod_curso_pai")} - {localStorage.getItem("nome_curso_pai")}
                                    </h3>
                                    <span>
                                        {localStorage.getItem("cod_subcurso")} - {localStorage.getItem("nome_subcurso")}
                                    </span>
                                </div>
                            ) : (
                                <h3 className="tabela_titulo">
                                    {localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}
                                </h3>
                            )}
                            <div className="controlos">
                                <Link to="/formador-lista-cursos" className="botao-principal">
                                    Voltar
                                </Link>
                            </div>
                        </div>
                        <div className="bloco bloco-cursos-estatisticas">
                            <div className="combo-datas combo-datas-desktop" style={{ marginBottom: 50 }}>
                                <div className="inicio">
                                    <label>Inicio:</label>
                                    <DatePicker
                                        value={dt_inicio}
                                        placeholder="Data Inicio"
                                        format="DD/MM/YYYY"
                                        onChange={this.onChangeDataInicio}
                                    />
                                </div>
                                <div className="termino">
                                    <label>Término:</label>
                                    <DatePicker
                                        value={dt_termino}
                                        placeholder="Data Término"
                                        format="DD/MM/YYYY"
                                        onChange={this.onChangeDataTermino}
                                    />
                                </div>
                                <div className="icon-informacao">
                                    <Tooltip className="info-icon" title="Intervalo máximo de 6 meses">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </div>
                                <button className="procurar" onClick={this.pesquisar}>
                                    <Icon type="search" />
                                </button>
                            </div>
                            <div className="combo-datas combo-datas-mobile" style={{ marginBottom: 50 }}>
                                <Alert message="Intervalo máximo de 6 meses" type="info" showIcon />
                                <div className="bloco-filtro-datas">
                                    <div className="inicio">
                                        <label>Inicio:</label>
                                        <DatePicker
                                            value={dt_inicio}
                                            placeholder="Data Inicio"
                                            format="DD/MM/YYYY"
                                            onChange={this.onChangeDataInicio}
                                        />
                                    </div>
                                    <div className="termino">
                                        <label>Término:</label>
                                        <DatePicker
                                            value={dt_termino}
                                            placeholder="Data Término"
                                            format="DD/MM/YYYY"
                                            onChange={this.onChangeDataTermino}
                                        />
                                    </div>
                                    <button className="procurar" onClick={this.pesquisar}>
                                        <Icon type="search" />
                                    </button>
                                </div>
                            </div>
                            {loading ? (
                                <div className="loading-data">
                                    <div className="loading" />
                                </div>
                            ) :
                                <>
                                    <div className="grid" style={{ marginBottom: 60 }}>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Total de participantes no curso", "")}>
                                            <Icon type="team" />
                                            <span className="desc">{nr_alunos}</span>
                                            <span className="titulo">Participantes</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tempo de consulta nas tarefas visitadas no curso", "")}>
                                            <Icon type="user" />
                                            <span className="desc">{formadores}</span>
                                            <span className="titulo">Professores</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas visitadas no curso", "")}>
                                            <Icon type="desktop" />
                                            <span className="desc">{tarefas_visitadas}</span>
                                            <span className="titulo">Tarefas visitadas</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tempo de consulta nas tarefas visitadas no curso", "")}>
                                            <Icon type="history" />
                                            <span className="desc">{tempos_tarefas}</span>
                                            <span className="titulo">Tempo nas tarefas</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas concluídas no curso", "")}>
                                            <Icon type="check" />
                                            <span className="desc">{conteudos_concluidos}</span>
                                            <span className="titulo">Tarefas concluídas</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Ficheiros adicionados na drive", "")}>
                                            <Icon type="file-add" />
                                            <span className="desc">{doc_fichs}</span>
                                            <span className="titulo">Ficheiros</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Anotações pessoais adicionadas", "")}>
                                            <Icon type="file" />
                                            <span className="desc">{interacoes_anotacoes}</span>
                                            <span className="titulo">Anotações</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Mensagens registadas em conversações", "")}>
                                            <Icon type="message" />
                                            <span className="desc">{mensagens_enviadas}</span>
                                            <span className="titulo">Mensagens enviadas</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Questões submetidas em fóruns", "")}>
                                            <Icon type="form" />
                                            <span className="desc">{interacoes_forum}</span>
                                            <span className="titulo">Interações fórum</span>
                                        </div>
                                        {/*<div className="item">
                                            <Icon type="number" />
                                            <span className="desc">{ratingsaulas}</span>
                                            <span className="titulo">Ratings/média (tarefas)</span>
                                        </div>*/}
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Média de classificações obtidas nas tarefas do curso (quando aplicável)", "")}>
                                            <Icon type="check-circle" />
                                            <span className="desc">{avaliacoesaulas}</span>
                                            <span className="titulo">Classificações/média (tarefas)</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Média de Rating registado no curso (após a sua conclusão)", "")}>
                                            <Icon type="number" />
                                            <span className="desc">{ratingscursos}</span>
                                            <span className="titulo">Ratings/média (curso)</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Classificação média obtida no curso (após a sua conclusão)", "")}>
                                            <Icon type="check-circle" />
                                            <span className="desc">{avaliacoescursos}</span>
                                            <span className="titulo">Classificações/média (curso)</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Assiduidade", "")}>
                                            <Icon type="user" />
                                            <span className="desc">{assiduidade} %</span>
                                            <span className="titulo">Presenças/Total (assiduidade)</span>
                                        </div>
                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Presenças em Stepmeets", "")}>
                                            <Icon type="video-camera" />
                                            <span className="desc">{totalStepmeets}</span>
                                            <span className="titulo">Presenças/Total (stepmeets)</span>
                                        </div>
                                    </div>
                                    <div className="botoes-exportar" style={{ padding: 0 }}>
                                        <Link to="#" onClick={this.exportarCSV}>
                                            <Icon type="export" />
                                    Exportar resumo para csv
                                </Link>
                                        <Link to="#" onClick={this.exportarDET}>
                                            <Icon type="export" />
                                    Exportar detalhe para csv
                                </Link>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
            </>
        );
    }
}

export default CursosEstatisticas;
