import React from "react";
import LazyLoad from "react-lazy-load";

const Header = props => {
    return (
        <div className="header_user" style={(props.marginTop0 ? { marginTop: 80 } : { marginTop: 80 }, props.style)}>
            <LazyLoad height={200} offsetVertical={200}>
                <img style={props.negativeTop && { top: "-80px" }} />
            </LazyLoad>
            {props.temImagem ? (
                <div className="container">
                    <h2 className="com-imagem">
                        <div className="container-img">
                            <img src={props.imagem} />
                        </div>
                        <span>{props.titulo}</span>
                    </h2>
                    {props.tem_subtitulo && <h3>{props.subtitulo}</h3>}
                </div>
            ) : (
                <div className="container" style={{ textAlign: "center" }}>
                    <h2 className="header-title">{props.titulo}</h2>
                    {props.tem_subtitulo && <h3 className="header-subtitle">{props.subtitulo}</h3>}
                </div>
            )}
        </div>
    );
};

export default Header;
