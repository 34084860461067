import React, { Component } from "react";
import { Link, matchPath, withRouter, NavLink } from "react-router-dom";
import { Dropdown, Icon } from "antd";
import { GeralContext } from "./ContextProvider";
import { GlobalContext } from "../GlobalState";
import axios from "axios";

class MenuCurso extends Component {
    static contextType = GeralContext;
    state = {
        total: 0
    };

    totalAlunos = () => {
        axios({
            method: "get",
            url: "/api/turma/total-alunos",
            timeout: 60 * 10 * 1000,
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    total: response.data
                });
            })
            .catch(() => {});
    };

    componentDidMount() {
        this.totalAlunos();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.turma !== prevProps.match.params.turma) {
            this.totalAlunos();

            if (!this.context.subcursos && localStorage.getItem("temp_opcao_voltar") != null) {
                localStorage.setItem("opcao_voltar", localStorage.getItem("temp_opcao_voltar"));
                localStorage.removeItem("temp_opcao_voltar");
            }
        }
    }

    render() {
        const { total } = this.state;
        const { turma } = this.props.match.params;
        var inicioUrl = `/gerir-multisseriado/${turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}`;

        let menuOpcaoAtivo = "";

        const match = matchPath(window.location.pathname, {
            path: "/gerir-multisseriado/:turma/:pagina"
        });

        const valoresPermitidos = [14, 15, 16, 17, 18, 19, 20, 21, 22];
        const acessos = this.props.acessos?.filter(x => valoresPermitidos.includes(x.func))?.map(x => x.func);

        if (match && match.params.pagina) menuOpcaoAtivo = match.params.pagina;

        if (this.context.subcursos)
            return (
                <GlobalContext.Consumer>
                    {globalContext => {
                        return (
                            <div className="menu-detalhe-curso menu-detalhe-curso-v2">
                                <div className="container">
                                    {(!globalContext.chave_entidade && globalContext.role === "UTILIZADOR") ||
                                    globalContext.role !== "UTILIZADOR" ? (
                                        <ul>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/visao-geral`}
                                                    className={menuOpcaoAtivo === "visao-geral" ? "ativo" : ""}
                                                >
                                                    Visão Geral
                                                </Link>
                                            </li>
                                            {acessos?.includes(14) && (
                                                <li>
                                                    <Link
                                                        to={`${inicioUrl}/horario`}
                                                        className={
                                                            menuOpcaoAtivo === "horario" ||
                                                            menuOpcaoAtivo === "horario-detalhe" ||
                                                            menuOpcaoAtivo === "diario-folha-conteudos" ||
                                                            menuOpcaoAtivo === "diario-folha-frequencia" ||
                                                            menuOpcaoAtivo === "diario-folha-observacoes" ||
                                                            menuOpcaoAtivo === "diario-folha-pareceres"
                                                                ? "ativo"
                                                                : ""
                                                        }
                                                    >
                                                        Diário
                                                    </Link>
                                                </li>
                                            )}
                                            {acessos?.includes(19) && (
                                                <li>
                                                    <Link
                                                        to={`${inicioUrl}/ficheiros`}
                                                        className={menuOpcaoAtivo === "ficheiros" ? "ativo" : ""}
                                                    >
                                                        Drive
                                                    </Link>
                                                </li>
                                            )}
                                            {acessos?.includes(15) && (
                                                <li>
                                                    <Link
                                                        to={`${inicioUrl}/relatorios-aproveitamento`}
                                                        className={menuOpcaoAtivo === "relatorios-aproveitamento" ? "ativo" : ""}
                                                    >
                                                        Avaliação Geral
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    ) : (
                                        <ul>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/visao-geral`}
                                                    className={menuOpcaoAtivo === "visao-geral" ? "ativo" : ""}
                                                >
                                                    Visão Geral
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/horario`}
                                                    className={
                                                        menuOpcaoAtivo === "horario" ||
                                                        menuOpcaoAtivo === "horario-detalhe" ||
                                                        menuOpcaoAtivo === "diario-folha-conteudos" ||
                                                        menuOpcaoAtivo === "diario-folha-frequencia" ||
                                                        menuOpcaoAtivo === "diario-folha-observacoes" ||
                                                        menuOpcaoAtivo === "diario-folha-pareceres"
                                                            ? "ativo"
                                                            : ""
                                                    }
                                                >
                                                    Diário
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/ficheiros`}
                                                    className={menuOpcaoAtivo === "ficheiros" ? "ativo" : ""}
                                                >
                                                    Drive
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/relatorios-aproveitamento`}
                                                    className={menuOpcaoAtivo === "relatorios-aproveitamento" ? "ativo" : ""}
                                                >
                                                    Avaliação Geral
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        );
                    }}
                </GlobalContext.Consumer>
            );

        return (
            <GlobalContext.Consumer>
                {globalContext => {
                    return (
                        <div className="menu-detalhe-curso menu-detalhe-curso-v2">
                            <div className="container">
                                {(!globalContext.chave_entidade && globalContext.role === "UTILIZADOR") ||
                                globalContext.role !== "UTILIZADOR" ? (
                                    <ul>
                                        {acessos?.includes(16) && (
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/visao-geral-disciplina`}
                                                    className={menuOpcaoAtivo === "visao-geral-disciplina" ? "ativo" : ""}
                                                >
                                                    Visão Geral
                                                </Link>
                                            </li>
                                        )}
                                        {acessos?.includes(17) && (
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/horario-disciplina`}
                                                    className={
                                                        menuOpcaoAtivo === "horario-disciplina" ||
                                                        menuOpcaoAtivo === "horario-detalhe" ||
                                                        menuOpcaoAtivo === "diario-folha-conteudos" ||
                                                        menuOpcaoAtivo === "diario-folha-frequencia" ||
                                                        menuOpcaoAtivo === "diario-folha-observacoes" ||
                                                        menuOpcaoAtivo === "diario-folha-pareceres"
                                                            ? "ativo"
                                                            : ""
                                                    }
                                                >
                                                    Diário
                                                </Link>
                                            </li>
                                        )}
                                        {acessos?.includes(18) && (
                                            <li>
                                                <Link to={`${inicioUrl}/modulos`} className={menuOpcaoAtivo === "modulos" ? "ativo" : ""}>
                                                    Conteúdos
                                                </Link>
                                            </li>
                                        )}
                                        {acessos?.includes(19) && (
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/ficheiros`}
                                                    className={menuOpcaoAtivo === "ficheiros" ? "ativo" : ""}
                                                >
                                                    Drive
                                                </Link>
                                            </li>
                                        )}
                                        {acessos?.includes(20) && (
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/forum`}
                                                    className={`${
                                                        menuOpcaoAtivo === "forum" ||
                                                        menuOpcaoAtivo === "nova-pergunta" ||
                                                        menuOpcaoAtivo === "forum-detalhe" ||
                                                        menuOpcaoAtivo === "mensagens" ||
                                                        menuOpcaoAtivo === "mensagens-nova" ||
                                                        menuOpcaoAtivo === "mensagens-detalhe"
                                                            ? "ativo"
                                                            : ""
                                                    } ant-dropdown-link"`}
                                                >
                                                    Fórum
                                                </Link>
                                            </li>
                                        )}
                                        {acessos?.includes(21) && (
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/aproveitamento`}
                                                    className={
                                                        menuOpcaoAtivo === "aproveitamento" ||
                                                        menuOpcaoAtivo === "aproveitamento-notas" ||
                                                        menuOpcaoAtivo === "aproveitamento-bncc-pautas"
                                                            ? "ativo"
                                                            : ""
                                                    }
                                                >
                                                    Avaliações
                                                </Link>
                                            </li>
                                        )}
                                        {acessos?.includes(22) && (
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/participantes`}
                                                    className={menuOpcaoAtivo === "participantes" ? "ativo" : ""}
                                                >
                                                    Alunos ({total})
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                ) : (
                                    <ul>
                                        <li>
                                            <Link
                                                to={`${inicioUrl}/visao-geral-disciplina`}
                                                className={menuOpcaoAtivo === "visao-geral-disciplina" ? "ativo" : ""}
                                            >
                                                Visão Geral
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`${inicioUrl}/modulos`} className={menuOpcaoAtivo === "modulos" ? "ativo" : ""}>
                                                Atividades
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    );
                }}
            </GlobalContext.Consumer>
        );
    }
}

export default withRouter(MenuCurso);
