import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Rate, notification } from "antd"
import { Skeleton, Card, Tooltip, Icon } from "antd"
import noimage from "../../images/noimage.png"
import moment from "moment"
import axios from "axios"
import { GlobalContext } from "../../GlobalState"

class Curso extends Component {
    static contextType = GlobalContext

    state = {
        value: 3,
        cursoConcluido: false,
        formador: false,
        tem_licenca: false,
        formador_curso: false
    }

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000
    }

    handleChange = value => {
        this.setState({ value })
    }

    //FORMATA STRING DE HORAS EM OOHOO
    formatarHoras = hora => {
        var horas = moment(hora, "HH:mm")
        if (moment.duration(hora, "minutes").asMinutes() > 59)
            return horas.format("HH").slice(0, 2) + "h" + horas.format("mm").slice(0, 2)
        else return horas.format("m").slice(0, 2) + "m"
    }

    concluido = curso => {
        return curso.total_Aulas > 0 ? curso.total_Aulas === curso.total_Aulas_Concluidas : false
    }

    onClick = cursoId => {
        //localStorage.setItem("modulo_aberto", "0");
        localStorage.removeItem("modulo_aberto");
        localStorage.setItem("codigo_curso", cursoId);
        localStorage.setItem("opcao_voltar", JSON.stringify({
            pesquisa: "",
            tipo_curso: "",
            ativo: "",
            link_voltar: this.props.link,
            link_voltar_curso: "",
            pagination: ""
        }))
        this.context.atualizarState({
            lista_modulos: [],
            lista_disciplinas: []
        });
    }

    render() {
        const desc = ["(1)", "(2)", "(3)", "(4)", "(5)"]

        const { curso, loading, keytext } = this.props

        const balaoLinguas = (
            <div className="balao">
                {curso.curso_Linguas.map((curso_lingua, index) => (
                    <div key={index} className="item">
                        <img src={curso_lingua.lingua.imagem} alt={curso_lingua.lingua.nome} />
                        <span className="desc">{curso_lingua.lingua.nome}</span>
                    </div>
                ))}
            </div>
        )

        const montarInstrutores = (instrutores) => {
            let lista = [];

            instrutores.map(instrutor => {
                const nome = instrutor.split(" ").length > 1 ? `${instrutor.split(" ")[0]} ${instrutor.split(" ")[instrutor.split(" ").length - 1]}` : instrutor
                lista.push(nome);
            });

            return lista.join(", ");
        }

        return (
            <div className="curso" title={curso.nome} key={keytext}>
                <Card>
                    <Skeleton loading={loading} avatar active>
                        {(curso.cursoIniciado && (curso.inscricao && curso.inscrito || !curso.inscricao && curso.inscrito || !curso.inscricao && !curso.subscricao && !curso.preco)) || (curso.subscricao && curso.subscricaoAtiva) || curso.instrutor ?
                            <Link
                                className="header"
                                to={`/${curso.instrutor ? "gerir-curso" : "curso"}/${this.props.curso.nome
                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                    .toLowerCase()}/${curso.sub_Cursos ? "curso-cursos" : "curso-modulos"}`}
                                onClick={() => this.onClick(curso.id)}
                            >
                                <img src={curso.imagem ? curso.imagem : noimage} alt="Imagem do curso" />
                            </Link>
                            :
                            <Link
                                className="header"
                                to={`/curso/${this.props.curso.nome
                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                    .toLowerCase()}/curso-visao-geral`}
                                onClick={() => this.onClick(curso.id)}
                            >
                                <img src={curso.imagem ? curso.imagem : noimage} alt="Imagem do curso" />
                            </Link>
                        }
                        <div className="content">
                            <div className="titulo">
                                <span className="curso-codigo">{curso.codigo}</span>
                                <span className="titulo-nome" title={curso.nome}>{curso.nome}</span>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="footer-autor-flag">
                                <span className="autor" title={montarInstrutores(curso.instrutores)}>
                                    {curso.instrutores &&
                                        `${curso.instrutores[0].split(" ").length > 1 ? `${curso.instrutores[0].split(" ")[0]} ${curso.instrutores[0].split(" ")[curso.instrutores[0].split(" ").length - 1]}` : curso.instrutores[0]} ${(curso.instrutores.length > 1 ? "..." : "")}`}
                                </span>
                                <div className="container-flag">
                                    {curso.curso_Linguas.length > 1 ?
                                        <Tooltip
                                            title={balaoLinguas}
                                            overlayClassName="tooltip-balao tooltip-balao-linguas"
                                        >
                                            <img
                                                src={require("../../images/flags/globe.jpg")}
                                                alt="Multilíngua"
                                            />
                                        </Tooltip>
                                        :
                                        <img src={curso.curso_Linguas[0].lingua.imagem} alt={curso.curso_Linguas[0].lingua.nome} title={curso.curso_Linguas[0].lingua.nome} />
                                    }
                                </div>
                            </div>
                            <div className="bloco-rating-monetizacao">
                                {curso.totalAvaliacoes ?
                                    <span className="rating">
                                        <Rate
                                            disabled
                                            tooltips={desc}
                                            onChange={this.handleChange}
                                            value={curso.mediaAvaliacoes ? curso.mediaAvaliacoes : 0}
                                        />
                                        <span>({curso.totalAvaliacoes})</span>
                                    </span> : null}
                                {this.context.monetizacao_entidade && this.context.subscricao_entidade || curso.preco ?
                                    curso.preco || curso.subscricao ?
                                            <span className="monetizacao">
                                                {curso.subscricao && this.context.subscricao_entidade ?
                                                    <i className="fas fa-shopping-cart"></i>
                                                    : null}
                                            {curso.preco ?
                                                <span dangerouslySetInnerHTML={{ __html: `${this.context.moeda_entidade} ${curso.valor}` }}></span>
                                                    : null}
                                            </span>
                                            : null
                                    : null}
                            </div>
                            <div className="info">
                                {curso.sub_Cursos ? (
                                    <span className="aulas">
                                        <i className="fa fa-play" /> {curso.sub_Cursos}{" "}
                                        {curso.sub_Cursos > 1 || curso.sub_Cursos === 0
                                            ? "disciplinas"
                                            : "disciplina"}
                                    </span>
                                ) : (
                                        <span className="aulas">
                                            <i className="fa fa-play" /> {curso.curso_Modulos}{" "}
                                            {curso.curso_Modulos > 1 || curso.curso_Modulos === 0
                                                ? "atividades"
                                                : "atividade"}
                                        </span>
                                    )}
                                <span className="horas" title="Carga horária">
                                    <i className="fa fa-clock" />
                                    {curso.carga_horaria}
                                </span>
                                {this.concluido(curso) ? (
                                    <span className="curso_concluido">
                                        <i className="fas fa-check-square" />
                                    </span>
                                ) : null}
                            </div>
                            {curso.precedencia && (
                                <div className="precedencia">
                                    <span>
                                        <Icon type="api" />
                                        {curso.precedencia}
                                    </span>
                                </div>
                            )}
                        </div>
                    </Skeleton>
                    <div className="overlay">
                        <i className="fas fa-play-circle" />
                    </div>
                </Card>
            </div>
        )
    }
}

export default Curso
