import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Drawer, Form, Input, DatePicker, Icon, Select, Upload, Tooltip, notification, Checkbox } from "antd";
import ModalViewer from "../../../../../Componentes/Modals/ModalViewer";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerEstatuto = ({ estatutoId, visible, onClose, atualizar, form, match }) => {
    const [tipos, setTipos] = useState([]);
    const [formData, setFormData] = useState({
        tipo: undefined,
        inicio: null,
        termino: null,
        descricao: "",
        aprovado: false
    });
    const [anexos, setAnexos] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [iconLoading, setIconLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const extensions = [".bmp", ".csv", ".odt", ".doc", ".docx", ".gif", ".htm", ".html", ".jpg", ".jpeg", ".pdf", ".png", ".ppt", ".pptx", ".tiff", ".txt", ".xls", ".xlsx"];

    const Dragger = Upload.Dragger;
    const { Option } = Select;
    const { TextArea } = Input;
    const { getFieldDecorator } = form;

    const listar = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-estatutos"
        })
            .then(response => {
                setTipos(response.data);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-estatuto",
            params: {
                estatutoId
            }
        })
            .then(response => {
                setFormData({
                    inicio: moment(response.data.inicio),
                    termino: response.data.termino ? moment(response.data.termino) : null,
                    tipo: response.data.tipo,
                    descricao: response.data.descricao,
                    aprovado: response.data.aprovado
                })
                setAnexos(response.data.anexos);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("id_estatuto", formData.tipo);
                item.append("inicio", moment(formData.inicio).format("YYYY/MM/DD"));
                if (formData.termino)
                    item.append("termino", moment(formData.termino).format("YYYY/MM/DD"));
                item.append("descricao", formData.descricao);
                item.append("aprovado", formData.aprovado);
                fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "post",
                    url: "/api/user-detalhe/adicionar-estatuto",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!.");
                        setIconLoading(false)
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false)
                    });
            }
        })
    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("id", estatutoId);
                item.append("id_estatuto", formData.tipo);
                item.append("inicio", moment(formData.inicio).format("YYYY/MM/DD"));
                if (formData.termino)
                    item.append("termino", moment(formData.termino).format("YYYY/MM/DD"));
                item.append("descricao", formData.descricao);
                item.append("aprovado", formData.aprovado);
                if (anexos.length)
                    item.append("listaFicheiros", JSON.stringify(anexos));
                fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "put",
                    url: "/api/user-detalhe/alterar-estatuto",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!.");
                        setIconLoading(false)
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false)
                    });
            }
        })
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (!visible) {
            setFormData({
                tipo: undefined,
                inicio: null,
                termino: null,
                descricao: "",
                aprovado: false
            });
            setFileList([]);
            setIconLoading(false);
        }
        else {
            if (estatutoId > 0)
                detalhe();
        }
    }

    const validarFormatoFicheiro = formato => {
        var formatos = ["docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file)
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed")
                callback("Campo obrigatório");

        callback();
    };

    const download = (nome, url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            nome,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    useEffect(() => {
        listar();
    }, [])

    const props = {
        multiple: true,
        onRemove: (file) => {
            form.resetFields("ficheiro");
            setFileList((fileList) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: (file) => {
            setFileList((fileList) => [...fileList, file]);

            return false;
        },
        fileList,
    };

    return (
        <>
            <Drawer
                className="drawer-inscrever drawer-editar-matricula"
                //title="Adicionar"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <Form className="form-emitir-declaracao">
                        <div className="bloco">
                            <Form.Item label="Tipo">
                                {getFieldDecorator("tipo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        }
                                    ],
                                    initialValue: formData.tipo
                                })(
                                    <Select
                                        className="input-50"
                                        placeholder="Selecionar"
                                        allowClear={true}
                                        onChange={(tipo) => setFormData({ ...formData, tipo })}
                                    >
                                        {tipos.map(tipo => (
                                            <Option key={tipo.id} value={tipo.id}>{tipo.titulo}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Inicio">
                                {getFieldDecorator("inicio", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        }
                                    ],
                                    initialValue: formData.inicio
                                })(
                                    <DatePicker
                                        name="data"
                                        format="DD-MM-YYYY"
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                        onChange={(inicio) => setFormData({ ...formData, inicio })}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Término">
                                {getFieldDecorator("termino", {
                                    initialValue: formData.termino
                                })(
                                    <DatePicker
                                        name="data"
                                        format="DD-MM-YYYY"
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                        onChange={(termino) => setFormData({ ...formData, termino })}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Descritivo">
                                {getFieldDecorator("descricao", {
                                    initialValue: formData.descricao
                                })(
                                    <TextArea
                                        name="descricao"
                                        onChange={(e) => setFormData({ ...formData, descricao: e.target.value })}
                                        rows={4}
                                        style={{ height: 100 }}
                                    />
                                )}
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>Documento</h3>
                            {(estatutoId > 0 && anexos.length > 0) &&
                                <Form.Item>
                                    {anexos.map(anexo => (
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <span>{anexo.nome}</span>
                                            <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10 }}>
                                                {extensions.find(x => x === anexo.extensao) &&
                                                    <Link to="#" title="Visualizar" className="botao-icon-detalhe" onClick={() => { setModalVisible(true); setDocs([{ uri: anexo.ficheiro }]) }}>
                                                        <Icon type="eye" />
                                                    </Link>}
                                                <Link to="#" title="Descarregar" className="botao-icon-download" onClick={() => download(anexo.nome, anexo.ficheiro)}>
                                                    <Icon type="download" />
                                                </Link>
                                                <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={() => setAnexos([...anexos.filter(x => x.id !== anexo.id)])}>
                                                    <Icon type="delete" />
                                                </Link>
                                            </span>
                                        </div>
                                    ))}
                                </Form.Item>}
                            <Form.Item>
                                <div className="anexos">
                                    {getFieldDecorator("ficheiro", {
                                        rules: [
                                            {
                                                validator: validar
                                            }
                                        ],
                                        initialValue: fileList
                                    })(
                                        <Dragger {...props}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    )}
                                </div>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                    <button className="botao-principal" disabled={iconLoading} type="primary" onClick={estatutoId > 0 ? alterar : adicionar}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                </div>
            </Drawer>
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </>
    );
}
const FormDrawerEstatuto = Form.create({ name: "drawer-estatuto" })(DrawerEstatuto);

export default withRouter(FormDrawerEstatuto);