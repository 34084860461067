import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../User/Header";
import { Form, notification } from "antd";
import axios from "axios";
import Escolas from "./Unidade/Escolas";
import Edificios from "./Edificios/Edificios";
import Salas from "./Salas/Salas";
import { GlobalContext } from "../../GlobalState";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //GRID
        filtroAtivo: "Estabelecimentos"
    };
    
    ativarFiltro = (filtroAtivo) => {
        this.setState({ filtroAtivo });
    }

    render() {
        const {
            //REDIRECT
            redirect,
            redirectLink,
            filtroAtivo
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const opcoesMenu = () => {
            var opcoes = ["Estabelecimentos", "Edifícios", "Salas"];

            if (!this.context.escolas_entidade)
                opcoes = opcoes.filter(x => x !== "Unidades Organizacionais")

            if (!this.context.visivel_entidade)
                opcoes = opcoes.filter(x => x !== "Página Pública" && x !== "Página Pública 2" && x !== "Página Pública 3")

            return opcoes;
        }

        return (
            <>
                <Header titulo="Estabelecimentos / Escolas" />
                <div className="administracao-empresa gestao-unidades-organizacionais">
                    <div className="container container-body">
                        <div className="bloco-grid">
                            <div className="filtros">
                                <ul>
                                    {opcoesMenu().map((item, index) => (
                                        <li
                                            key={index}
                                            className={filtroAtivo === item ? "ativo" : ""}
                                            onClick={() => this.ativarFiltro(item)}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="bloco-cursos">
                                {filtroAtivo === "Estabelecimentos" && <Escolas />}
                                {filtroAtivo === "Edifícios" && <Edificios />}
                                {filtroAtivo === "Salas" && <Salas />}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const FormMain = Form.create({ name: "form-minha-conta" })(Main);

export default FormMain;
