import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Select, Form, Checkbox, notification, Icon, DatePicker, Upload, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";
import DrawerDetalhe from "./DrawerDetalhe";

const { Option } = Select;
const Dragger = Upload.Dragger;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class InfoAluno extends Component {
    state = {
        //DRAWER PRESENÇAS
        existeRegisto: false,
        dt_inicio: "",
        presenca: "",
        ficheiro: [],
        participante: "",
        loadingParticipantes: false,
        //LOADING
        iconLoading: false,
        //DRAWER DETALHE
        visivel: false
    };

    componentWillReceiveProps = (nextProps) => {
        //this.listar();
    }

    componentDidMount() {
        this.listar();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeParticipante = (event, participanteId) => {
        var presencas = this.state.presencas;
        var index = presencas.findIndex(x => x.id === participanteId)
        presencas[index].observacoes = event.target.value;

        this.setState({ presencas });
    };

    handlerChangeSelect = (value) => {
        var presenca = this.state.presenca;
        presenca.estado = value;

        this.setState({
            presenca
        });
    };

    listar = () => {
        this.setState(
            {
                loadingParticipantes: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/detalhe-assiduidade-utilizador",
                    timeout: 60 * 10 * 1000,
                    params: {
                        aulaId: this.props.aulaId
                    }
                })
                    .then(response => {
                        let existeRegisto = false;
                        let registo = response.data;
                        let participante = registo.participante;
                        let presenca = {
                            id: participante.id,
                            estado: undefined,
                            observacoes: ""
                        }

                        if (registo.presenca) {
                            presenca.estado = "P";
                            existeRegisto = true;
                        }
                        else if (registo.falta) {
                            presenca.estado = "F";
                            existeRegisto = true;
                        }
                        else {
                            if (response.data.presencas) {
                                let presencas = JSON.parse(response.data.presencas);
                                const temp = presencas.find(x => x.id === participante.id);
                                if (temp) {
                                    presenca = temp;
                                    existeRegisto = true;
                                }
                            }
                        }

                        this.setState({
                            existeRegisto,
                            dt_inicio: response.data.dt_inicio,
                            presenca,
                            participante,
                            loadingParticipantes: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    }

    adicionarDetalhe = (detalhe) => {
        var presenca = this.state.presenca;
        presenca.tipo = detalhe.tipo;
        presenca.ficheiro = detalhe.ficheiro;
        presenca.variosDias = detalhe.variosDias;
        presenca.dataInicio = detalhe.dataInicio;
        presenca.dataFim = detalhe.dataFim;

        this.setState({
            presenca,
            visivel: false
        });
    }

    guardarPresencas = () => {
        this.setState({ iconLoading: true })

        var item = new FormData()
        item.append("id", this.props.aulaId)
        item.append("presenca", JSON.stringify(this.state.presenca))
        if (this.state.presenca.ficheiro)
            item.append("ficheiro", JSON.stringify(this.state.presenca.ficheiro))

        axios({
            method: "put",
            url: "/api/aulas/alterar-assiduidade-individual",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Assiduidade guardada!")
                this.listar();
                this.setState({
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi guardar assiduidade!")
                this.setState({ iconLoading: false })
            })
    }

    validar = (rule, value, callback) => {
        if (value.file)
            if (!this.validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"]) || value.file.size > 26214400)
                callback("Campo obrigatório")

        callback()
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const {
            //DRAWER PRESENÇAS
            existeRegisto,
            participante,
            presenca,
            ficheiro,
            //LOADING
            iconLoading,
            //DRAWER DETALHE
            visivel
        } = this.state;

        return (
            <>
                <div className="bloco-presencial">
                    <div className="bloco-frequencia-diario" style={{ boxShadow: "none" }}>
                        <div className="listagem-participantes" style={{ marginTop: 0 }}>
                            <Form className="form-presencas" layout="horizontal">
                                <div className="bloco">
                                    <Form.Item>
                                        <div className="listagem-presencas">
                                            <div key={presenca.id} className="item-presenca">
                                                <div className="header">
                                                    <div className="participante">
                                                        <div className="info-participante">
                                                            <div className="container-img">
                                                                <img src={participante.foto ? participante.foto : noimage} />
                                                            </div>
                                                            <div className="info">
                                                                <span className="nome">{participante.nome}</span>
                                                                <p className="numero">{participante.numero}</p>
                                                            </div>
                                                            {presenca.pendente &&
                                                                <div>
                                                                    <Icon type="warning" className="estado-pendente" title="Aguarda validação" />
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <div className="opcao">
                                                        <Form.Item>
                                                            {existeRegisto ?
                                                                <Select value={presenca.estado} disabled={existeRegisto} placeholder="Selecionar">
                                                                    <Option value="P">
                                                                        Presença
                                                                    </Option>
                                                                    <Option value="F">
                                                                        Falta
                                                                    </Option>
                                                                    <Option value="FJ">
                                                                        Falta justificada
                                                                    </Option>
                                                                </Select>
                                                                :
                                                                <Select value={presenca.estado} placeholder="Selecionar" allowClear={true} onChange={(e) => this.handlerChangeSelect(e, presenca.id)}>
                                                                    <Option value="FJ">
                                                                        Falta justificada
                                                                    </Option>
                                                                </Select>
                                                            }
                                                        </Form.Item>
                                                        {((!existeRegisto || existeRegisto && (presenca.estado === "F" || presenca.estado === "FJ")) && !presenca.pendente && !presenca.confirmada)  &&
                                                            <Form.Item>
                                                                <Link to="#" className="link-detalhe" onClick={() => this.setState({ visivel: true })}>
                                                                    <Icon type="plus" />
                                                                    Adicionar detalhe
                                                                </Link>
                                                            </Form.Item>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                        {((!existeRegisto || existeRegisto && (presenca.estado === "F" || presenca.estado === "FJ")) && !presenca.pendente && !presenca.confirmada) &&
                            <div style={{ width: "100%" }}>
                                <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.guardarPresencas}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Salvar
                                </Link>
                            </div>}
                    </div>
                </div>
                <DrawerDetalhe
                    visivel={visivel}
                    onClose={() => this.setState({ visivel: false })}
                    adicionarDetalhe={this.adicionarDetalhe}
                />
            </>
        );
    }
}

const FormInfoAluno = Form.create({ name: "form-info-aluno" })(InfoAluno);

export default FormInfoAluno;
