import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import PdfReader from "./PdfReader";
import { GlobalContext } from "../GlobalState";
import axios from "axios";
import Oops from "../Geral/Oops.jsx";

class Pdf extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        objetivos: "",
        curso: "",
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        aula: "",
        aulaIndex: 0,
        pdf: "",
        dataHora: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        visivel: true,
        sameUrl: true,
        //NAO DISPONIVEL
        nao_disponivel: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: localStorage.getItem("codigo_aula")
        });
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState(
                {
                    visivel: false,
                    sameUrl: false
                },
                () =>
                    this.setState({
                        visivel: true,
                        sameUrl: true
                    })
            );
        }
    }

    marcarComoNaoDisponivel = () => {
        this.setState({
            nao_disponivel: true
        })
    }

    render() {
        const {
            nome,
            objetivos,
            curso,
            sameUrl,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            //NAO DISPONIVEL
            nao_disponivel
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="pagina-html" style={{ zIndex: 1, position: "relative" }}>
                <div className="player-wrapper" style={{ position: "fixed", width: "100%" }}>
                    {!nao_disponivel ?
                        <>
                            <div className="bloco-html bloco-pdf" style={{ boxShadow: "none", background: "transparent" }}>
                                <div className="bloco-info" style={{ padding: "0" }}>
                                    {this.state.visivel && <PdfReader marcarComoNaoDisponivel={this.marcarComoNaoDisponivel} carregarInfo={(nome, objetivos, dataHora) => this.setState({ nome, objetivos, dataHora })} />}
                                </div>
                            </div>
                            <Controlos
                                backUrl={localStorage.getItem("player_back_url") ? localStorage.getItem("player_back_url") : `/gerir-curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                sameUrl={sameUrl}
                                temAnotacoes
                                temInformacoes
                                nome={nome}
                                objetivos={objetivos}
                                icon={require("../images/icons/pdf.png")}
                                dataHora={dataHora}
                            />
                        </>
                        :
                        <>
                            <div className="container">
                                <div className="bloco-nao-disponivel">
                                    <div className="bloco-info nao-disponivel">
                                        <Oops tem_texto texto={"Conteúdo não disponível"} />
                                    </div>
                                </div>
                            </div>
                            <ControlosSimples />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default Pdf;
