import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Drawer, Icon, Form, Input, Select, Collapse, notification, Modal, Radio, Tooltip, InputNumber } from "antd";
import axios from "axios";
import moment from "moment";
import DrawerPeriodo from "./DrawerPeriodo";
import DrawerAvaliacao from "./DrawerAvalicao";
import uuid from "react-uuid";

const { Panel } = Collapse;
const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerDiario = ({ ativo, atualizarRegistos, onClose, visible, escolaridadeId, form }) => {
    const [search, setSearch] = useState("");
    const [titulo, setTitulo] = useState("");
    const [calendarioEscolar, setCalendarioEscolar] = useState(undefined);
    const [periodoLetivo, setPeriodoLetivo] = useState([]);
    const [nrModulos, setNrModulos] = useState(0);
    const [bncc, setBncc] = useState("CRECHE");
    const [tipo, setTipo] = useState(undefined);
    const [formula, setFormula] = useState(undefined);
    const [estruturaBncc, setEstruturaBncc] = useState([]);
    const [estrutura, setEstrutura] = useState([]);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [iconLoading, setIconLoading] = useState(false);
    const [visibleDrawerAvaliacao, setVisibleDrawerAvaliacao] = useState(false);
    const [estadoDrawer, setEstadoDrawer] = useState("");
    const [periodo, setPeriodo] = useState(null);
    const [registo, setRegisto] = useState(null);
    const [registoPaiId, setRegistoPaiId] = useState(0);
    const [associado, setAssociado] = useState(false);
    const [estruturaUtilizada, setEstruturaUtilizada] = useState(false);
    const [grupo, setGrupo] = useState(false);
    const [primeiroNivel, setPrimeiroNivel] = useState(false);
    const [existeRecuperacao, setExisteRecuperacao] = useState(false);
    const [incompleto, setIncompleto] = useState(false);
    const { getFieldDecorator } = form;

    const handleChangeAvaliacao = (event, index) => {
        let _avaliacoes = avaliacoes;
        _avaliacoes[index].nome = event.target.value;

        setAvaliacoes([..._avaliacoes]);
    };

    const handleChangeAvaliacaoLegenda = (event, index) => {
        let _avaliacoes = avaliacoes;
        _avaliacoes[index].legenda = event.target.value;

        setAvaliacoes([..._avaliacoes]);
    };

    const handleChangePeriodosLeticos = periodo => {
        let temp = [];
        let estrutura = [];
        let ordem = 0;

        switch (periodo) {
            case "Módulos":
                let modulos = criarArrayModulos(nrModulos);

                modulos.map(modulo => {
                    ordem += 1;
                    temp.push(...montarPeriodoRegisto(modulo, ordem));
                });

                estrutura.push(...temp);
                break;
            case "Bimestres":
                const bimestres = ["1º Bimestre", "2º Bimestre", "3º Bimestre", "4º Bimestre", "Outros"];
                bimestres.map(bimestre => {
                    ordem += 1;
                    temp.push(...montarPeriodoRegisto(bimestre, ordem));
                });

                estrutura.push(...temp);
                break;
            case "Trimestres":
                const trimestres = ["1º Trimestre", "2º Trimestre", "3º Trimestre", "4º Trimestre", "Outros"];
                trimestres.map(trimestre => {
                    ordem += 1;
                    temp.push(...montarPeriodoRegisto(trimestre, ordem));
                });

                estrutura.push(...temp);
                break;
            case "Semestres":
                const semestres = ["1º Semestre", "2º Semestre", "Outros"];
                semestres.map(semestre => {
                    ordem += 1;
                    temp.push(...montarPeriodoRegisto(semestre, ordem));
                });

                estrutura.push(...temp);
                break;
            case "Anual":
                const anual = ["Anual", "Outros"];
                anual.map(item => {
                    ordem += 1;
                    temp.push(...montarPeriodoRegisto(item, ordem));
                });

                estrutura.push(...temp);
                break;
        }

        setEstrutura(estrutura);
        setPeriodoLetivo(periodo);
    };

    const montarPeriodoRegisto = (nome, ordem) => {
        const periodo = {
            id: uuid(),
            idPai: 0,
            nome,
            ordem,
            ativo: true
        };

        if (tipo === "BNCC") {
            const bnccEstrutura = adicionarBncc(periodo.id);
            return [periodo, ...bnccEstrutura];
        } else return [periodo];
    };

    const handleChangeTipo = event => {
        const tempTipo = event.target.value;
        setTipo(tempTipo);
    };

    const carregarDetalheFase = escolaridadeId => {
        axios({
            method: "get",
            url: "/api/gestao-diario/detalhe",
            params: {
                id: escolaridadeId
            }
        })
            .then(response => {
                const escolaridade = response.data;
                const estruturaPeriodos = escolaridade.periodos;
                const estruturaRegistos = escolaridade.registos;

                setTitulo(escolaridade.nome);
                setCalendarioEscolar(escolaridade.id_calendario);
                setTipo(escolaridade.tipo);
                setBncc(escolaridade.codigo);
                setFormula(escolaridade.formula);
                setEstrutura([...estruturaPeriodos, ...estruturaRegistos]);
                setEstruturaUtilizada(escolaridade.utilizada);
                setAvaliacoes([...escolaridade.avaliacoes]);
                setPeriodoLetivo(escolaridade.periodo_letivo);
                if (escolaridade.nr_modulos) setNrModulos(escolaridade.nr_modulos);
                carregarBncc(escolaridade.codigo);
                setAssociado(escolaridade.associado);
                setIncompleto(!escolaridade.tipo || !escolaridade.periodo_letivo || !estruturaPeriodos.length || !estruturaRegistos.length);
            })
            .catch(error => {});
    };

    const comparadorPorOrdem = (a, b) => {
        return a.ordem - b.ordem;
    };

    const carregarBncc = () => {
        if (search)
            axios({
                method: "get",
                url: "/api/gestao-diario/carregar-bncc",
                params: {
                    search: search
                }
            })
                .then(response => {
                    setEstruturaBncc(response.data);
                })
                .catch(error => {});
    };

    const adicionar = event => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            const avaliacoesPreenchdias = avaliacoes.every(obj => obj.nome && obj.nome.trim() !== "");
            const estruturaPreenchida = estrutura.some(obj => obj.idPai !== "0");

            if (
                !err &&
                (tipo === "Notas" || (tipo !== "Notas" && avaliacoes.length && avaliacoesPreenchdias)) &&
                estrutura.length &&
                estruturaPreenchida
            ) {
                setIconLoading(true);

                var item = new FormData();
                item.append("nome", titulo);
                item.append("tipo", tipo);
                item.append("estrutura", JSON.stringify(estrutura));
                item.append("avaliacoes", JSON.stringify(avaliacoes));
                item.append("periodo_letivo", periodoLetivo);
                item.append("nr_modulos", nrModulos);

                axios({
                    method: "post",
                    url: "/api/gestao-diario/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        setIconLoading(false);
                        atualizarRegistos();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false);
                    });
            } else {
                let campos = ["Classificações", "Estruturas de pautas"];

                if (tipo !== "Notas" && avaliacoes.length && avaliacoesPreenchdias) campos = campos.filter(x => x !== "Classificações");

                if (estrutura.length && estruturaPreenchida) campos = campos.filter(x => x !== "Estruturas de pautas");

                if (campos.length)
                    openNotificationWithIcon(
                        "error",
                        "Erro",
                        <span>
                            Os seguintes campos estão por preencher:
                            <ul>
                                {campos.map(campo => (
                                    <li>{campo}</li>
                                ))}
                            </ul>
                        </span>
                    );

                //if (campos.length)
                //    openNotificationWithIcon("error", "Erro", "As classificações estão por preencher");

                //if (!estrutura.length || !estruturaPreenchida)
                //    openNotificationWithIcon("error", "Erro", "As estruturas de pautas estão por preencher");
            }
        });
    };

    //ATUALIZA A EDIÇÕES
    const guardar = event => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("id", escolaridadeId);
                item.append("nome", titulo);
                item.append("tipo", tipo);
                item.append("estrutura", JSON.stringify(estrutura));
                item.append("avaliacoes", JSON.stringify(avaliacoes));
                item.append("periodo_letivo", periodoLetivo);
                item.append("nr_modulos", nrModulos);

                axios({
                    method: "put",
                    url: "/api/gestao-diario/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        setIconLoading(false);
                        atualizarRegistos();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false);
                    });
            }
        });
    };

    const atualizarAdicionado = registo => {
        if (registo.idPai === 0) {
            if (tipo === "BNCC") {
                const bnccEstrutura = adicionarBncc(registo.id);
                setEstrutura(estruturaOriginal => [...estruturaOriginal, registo, ...bnccEstrutura]);
            } else setEstrutura(estruturaOriginal => [...estruturaOriginal, registo]);
        } else setEstrutura(estruturaOriginal => [...estruturaOriginal, registo]);
        setVisibleDrawerAvaliacao(false);
        setEstadoDrawer("");
    };

    const criarRegistro = (idPai, grupo) => ({
        key: uuid(),
        id: uuid(),
        idPai: idPai,
        idOriginal: grupo.id,
        nome: grupo.titulo,
        ordem: grupo.ordem,
        ativo: true
    });

    const criarAvaliacao = (idPai, avaliacao) => ({
        key: uuid(),
        id: uuid(),
        idPai: idPai,
        idOriginal: avaliacao.id,
        codigo: avaliacao.codigo,
        nome: avaliacao.titulo,
        ordem: avaliacao.ordem,
        ativo: true
    });

    const adicionarBncc = idPai => {
        let bncc = [];

        estruturaBncc.map((grupo, index) => {
            let registo = criarRegistro(idPai, grupo);

            const avaliacoes = grupo.avaliacoes.map(avaliacao => {
                return criarAvaliacao(registo.id, avaliacao);
            });

            bncc.push(registo, ...avaliacoes);
        });

        return bncc;
    };

    const atualizarBncc = () => {
        let bncc = [];
        const periodos = [...estrutura.filter(x => x.idPai === 0)];

        periodos.forEach(periodo => {
            bncc.push(periodo);
            const gruposExistentes = [...estrutura.filter(x => x.idPai === periodo.id)];
            let gruposIdsExistentes = [];

            estruturaBncc.forEach(grupo => {
                const grupoExistente = gruposExistentes.find(x => x.idOriginal === grupo.id);

                if (grupoExistente) {
                    bncc.push(grupoExistente);
                    gruposIdsExistentes.push(grupoExistente.id);
                    const avaliacoesExistentes = [...estrutura.filter(x => x.idPai === grupoExistente.id)];
                    let novaAvs = [];

                    grupo.avaliacoes.forEach(avaliacao => {
                        const avExistente = avaliacoesExistentes.find(x => x.idOriginal === avaliacao.id);
                        if (!avExistente) {
                            novaAvs.push(criarAvaliacao(grupoExistente.id, avaliacao));
                        }
                    });

                    bncc.push(...[...avaliacoesExistentes, ...novaAvs].sort(comparadorPorOrdem));
                } else {
                    let registo = criarRegistro(periodo.id, grupo);

                    const avaliacoes = grupo.avaliacoes.map((avaliacao, index2) => {
                        return criarAvaliacao(registo.id, avaliacao);
                    });

                    bncc.push(registo);
                    bncc.push(...avaliacoes);
                }
            });

            const gruposNaoUtilizados = gruposExistentes.filter(x => !gruposIdsExistentes.includes(x.id));
            gruposNaoUtilizados.forEach(grupo => {
                bncc.push(grupo);
                bncc.push(...estrutura.filter(x => x.idPai === grupo.id));
            });
        });

        setEstrutura(bncc);
    };

    const atualizarEditado = registo => {
        let tempEstrutura = estrutura;
        const index = tempEstrutura.findIndex(x => x.id === registo.id);
        tempEstrutura[index] = registo;
        setEstrutura([...tempEstrutura]);
        setVisibleDrawerAvaliacao(false);
        setEstadoDrawer("");
    };

    const carregarDetalhe = registo => {
        setRegisto(registo);
        setVisibleDrawerAvaliacao(true);
        setEstadoDrawer("EDITAR");
    };

    const excluirRegisto = id => {
        confirm({
            title: "Pretende excluir este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                const lista = estrutura.filter(x => x.id !== id);
                setEstrutura(lista);
            }
        });
    };

    const ocultarRegistosFilhos = (estrutura, id, ativo) => {
        let lista = estrutura;
        const filhos = lista.filter(x => x.idPai === id);

        filhos.map(filho => {
            const index = lista.findIndex(x => x.id === filho.id);
            lista[index].ativo = !ativo;
            lista = ocultarRegistosFilhos(lista, filho.id, ativo);
        });

        return lista;
    };

    const montarPeriodos = lista => {
        if (lista.length === 0) {
            return null;
        }

        if (tipo !== "Notas") lista = lista.filter(x => x.nome !== "Outros");

        let temp = lista.map((item, index) => {
            let totalNiveis = 1;

            return (
                <>
                    <div key={uuid()} className="item-estrutura">
                        <span className={`titulo-item-estrutura ${!item.ativo ? "oculto" : ""}`}>
                            {item.nome}{" "}
                            {item.inicio && item.termino
                                ? `(${moment(item.inicio).format("DD/MM/YYYY")} - ${moment(item.termino).format("DD/MM/YYYY")})`
                                : null}
                        </span>
                        {ativo && (
                            <>
                                {tipo !== "BNCC" && (
                                    <span className="controlos-item-estrutura">
                                        <Link
                                            className="botao-icon-adicionar"
                                            to="#"
                                            onClick={() => {
                                                setVisibleDrawerAvaliacao(true);
                                                setRegistoPaiId(item.id);
                                                setEstadoDrawer("NOVO");
                                                setGrupo(true);
                                                setPrimeiroNivel(true);
                                                setExisteRecuperacao(estrutura.some(x => x.idPai === item.id && x.recuperacao));
                                            }}
                                            title="Adicionar sub-registo"
                                        >
                                            <Icon type="plus" />
                                        </Link>
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                    {montarAvaliacoes(
                        estrutura.filter(x => x.idPai === item.id),
                        item.id,
                        totalNiveis
                    )}
                </>
            );
        });
        let estruturaHtml = <div className="diario-estrutura"> {temp}</div>;
        return estruturaHtml;
    };

    const montarAvaliacoes = (lista, idPai, totalNiveis) => {
        if (lista.length === 0) {
            return null;
        }

        let temp = lista.map((item, index) => {
            return (
                <>
                    <div key={uuid()} className="item-estrutura">
                        {tipo === "Notas" ? (
                            <span className={`titulo-item-estrutura ${item.campo_calculado ? "campo-calculado" : ""}`}>{item.nome}</span>
                        ) : (
                            <span className={`titulo-item-estrutura ${item.campo_calculado ? "campo-calculado" : ""}`}>
                                {item.codigo ? `${item.codigo} - ` : ""} {item.nome}
                            </span>
                        )}
                        {ativo && (
                            <>
                                {tipo === "BNCC" ? (
                                    <span className="controlos-item-estrutura">
                                        <Link
                                            className="botao-icon-excluir"
                                            to="#"
                                            onClick={() => excluirRegisto(item.id)}
                                            title="Excluir registo"
                                        >
                                            <Icon type="delete" />
                                        </Link>
                                    </span>
                                ) : (
                                    <span className="controlos-item-estrutura">
                                        {totalNiveis < 2 && (
                                            <Link
                                                className="botao-icon-adicionar"
                                                to="#"
                                                onClick={() => {
                                                    setVisibleDrawerAvaliacao(true);
                                                    setRegistoPaiId(item.id);
                                                    setEstadoDrawer("NOVO");
                                                    setGrupo(false);
                                                    setPrimeiroNivel(false);
                                                    setExisteRecuperacao(false);
                                                }}
                                                title="Adicionar sub-registo"
                                            >
                                                <Icon type="plus" />
                                            </Link>
                                        )}
                                        <Link
                                            className="botao-icon-editar"
                                            to="#"
                                            onClick={() => {
                                                carregarDetalhe(item);
                                                setGrupo(totalNiveis < 2);
                                                setPrimeiroNivel(false);
                                                setRegistoPaiId(idPai);
                                                setExisteRecuperacao(estrutura.some(x => x.idPai === idPai && x.recuperacao));
                                            }}
                                            title="Editar registo"
                                        >
                                            <Icon type="edit" />
                                        </Link>
                                        {!associado && (
                                            <Link
                                                className="botao-icon-excluir"
                                                to="#"
                                                onClick={() => excluirRegisto(item.id)}
                                                title="Excluir registo"
                                            >
                                                <Icon type="delete" />
                                            </Link>
                                        )}
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                    {montarAvaliacoes(
                        estrutura.filter(x => x.idPai === item.id),
                        item.id,
                        totalNiveis + 1
                    )}
                </>
            );
        });
        let estruturaHtml = <div className="diario-estrutura"> {temp}</div>;
        return estruturaHtml;
    };

    const montarModulos = nrModulos => {
        let temp = [];

        for (let i = 0; i < nrModulos; i++) {
            temp.push(...montarPeriodoRegisto(`${i + 1}º Modulo`, i + 1));
        }
        temp.push(...montarPeriodoRegisto("Outros", nrModulos + 1));

        setNrModulos(nrModulos);
        setEstrutura(temp);
    };

    const criarArrayModulos = num => {
        const array = [];
        let nrMod = num;
        if (num == 0) nrMod = 1;

        for (let i = 0; i < nrMod; i++) {
            array.push(`${i + 1}º Modulo`);
        }
        array.push("Outros");

        return array;
    };

    useEffect(() => {
        handleChangePeriodosLeticos(periodoLetivo);
    }, [tipo, bncc]);

    useEffect(() => {
        if (estrutura.filter(x => x.idPai !== 0).length === 0) handleChangePeriodosLeticos(periodoLetivo);
        else atualizarBncc();
    }, [estruturaBncc]);

    useEffect(() => {
        carregarBncc(bncc);
    }, [bncc]);

    let html = null;
    if (estrutura.length > 0) html = montarPeriodos(estrutura.filter(x => x.idPai === 0));

    const afterVisibleChange = aberto => {
        if (aberto) {
            form.resetFields();

            if (escolaridadeId > 0) carregarDetalheFase(escolaridadeId);
        } else {
            setSearch("");
            setEstruturaBncc([]);
            setTitulo("");
            setNrModulos(0);
            setPeriodoLetivo([]);
            setTipo("Notas");
            setEstrutura([]);
            setAvaliacoes([]);
            setIconLoading(false);
        }
    };

    return (
        <>
            <Drawer
                className="drawer-add-cursos"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias listagem-avaliacoes" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Nota">
                                {getFieldDecorator("titulo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: titulo
                                })(<Input name="titulo" disabled={!ativo} onChange={event => setTitulo(event.target.value)} />)}
                            </Form.Item>
                            <Form.Item label="Periodos letivos">
                                {getFieldDecorator("periodoLetivo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: periodoLetivo
                                })(
                                    <Radio.Group
                                        className="lista-estabelecimentos"
                                        disabled={(escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto}
                                        style={{ width: "100%" }}
                                        onChange={event => handleChangePeriodosLeticos(event.target.value)}
                                    >
                                        <Radio value="Módulos">Módulos</Radio>
                                        <Radio value="Bimestres">Bimestres</Radio>
                                        <Radio value="Trimestres">Trimestres</Radio>
                                        <Radio value="Semestres">Semestres</Radio>
                                        <Radio value="Anual">Anual</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            {periodoLetivo === "Módulos" && (
                                <Form.Item label="Nr de módulos">
                                    {getFieldDecorator("nr_modulos", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: nrModulos
                                    })(
                                        <InputNumber
                                            name="nr_modulos"
                                            min={1}
                                            disabled={(escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto}
                                            onChange={montarModulos}
                                        />
                                    )}
                                </Form.Item>
                            )}
                            <Form.Item label="Tipo">
                                {getFieldDecorator("tipo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: tipo
                                })(
                                    <Radio.Group
                                        name="periodo"
                                        disabled={(escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto}
                                        onChange={handleChangeTipo}
                                    >
                                        <Radio value="Notas">Notas</Radio>
                                        <Radio value="BNCC">BNCC</Radio>
                                        <Radio value="Conceitos">Conceitos</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            {tipo !== "Notas" && (
                                <div className="bloco bloco-lista-avaliacao-opcoes">
                                    <h3 className="titulo-separador">
                                        <span>
                                            Classificações
                                            <Tooltip className="info-icon" title="Opções de resposta para os items da estrutura">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </span>
                                        {ativo && (
                                            <button
                                                className="botao-principal"
                                                disabled={(escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto}
                                                onClick={
                                                    (escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto
                                                        ? {}
                                                        : event => {
                                                              event.stopPropagation();
                                                              setAvaliacoes([...avaliacoes, { id: uuid(), nome: "" }]);
                                                          }
                                                }
                                            >
                                                <Icon type="plus" />
                                            </button>
                                        )}
                                    </h3>
                                    <div className="lista-avaliacao-opcoes">
                                        {avaliacoes.map((avaliacao, index) => (
                                            <Form.Item key={index}>
                                                <div className="avaliacao-opcao">
                                                    <div className="bloco-inputs-v2">
                                                        <Input
                                                            name="nome"
                                                            disabled={(escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto}
                                                            onChange={
                                                                (escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto
                                                                    ? {}
                                                                    : event => handleChangeAvaliacao(event, index)
                                                            }
                                                            value={avaliacao.nome}
                                                        />
                                                        <Input
                                                            name="legenda"
                                                            disabled={(escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto}
                                                            onChange={
                                                                (escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto
                                                                    ? {}
                                                                    : event => handleChangeAvaliacaoLegenda(event, index)
                                                            }
                                                            value={avaliacao.legenda}
                                                        />
                                                    </div>
                                                    {ativo && (
                                                        <button
                                                            className="botao-excluir"
                                                            disabled={(escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto}
                                                            onClick={
                                                                (escolaridadeId > 0 || !ativo) && estruturaUtilizada && !incompleto
                                                                    ? {}
                                                                    : () =>
                                                                          setAvaliacoes([...avaliacoes.filter(x => x.id !== avaliacao.id)])
                                                            }
                                                        >
                                                            <Icon type="delete" />
                                                        </button>
                                                    )}
                                                </div>
                                            </Form.Item>
                                        ))}
                                        {!avaliacoes.length && "Sem dados..."}
                                    </div>
                                </div>
                            )}
                        </div>
                        <Collapse defaultActiveKey={["1"]} className="collapse-campos">
                            <Panel header="Estrutura de pautas" key="1">
                                {tipo === "BNCC" && (
                                    <div className="bloco-pesquisa">
                                        <Input
                                            name="search"
                                            placeholder="Pesquisar"
                                            disabled={!periodoLetivo.length && associado}
                                            onChange={!periodoLetivo.length && associado ? {} : e => setSearch(e.target.value)}
                                        />
                                        {ativo && (
                                            <button
                                                className="botao-principal"
                                                disabled={!periodoLetivo.length && associado}
                                                onClick={!periodoLetivo.length && associado ? {} : carregarBncc}
                                            >
                                                <Icon type="search" />
                                            </button>
                                        )}
                                    </div>
                                )}
                                <div className="bloco">{estrutura.length ? html : <div>Sem dados...</div>}</div>
                            </Panel>
                        </Collapse>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    {ativo && (
                        <button
                            className="botao-principal"
                            onClick={escolaridadeId > 0 ? guardar : adicionar}
                            disabled={iconLoading}
                            type="primary"
                        >
                            {iconLoading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>
                    )}
                </div>
            </Drawer>
            <DrawerAvaliacao
                visible={visibleDrawerAvaliacao}
                onClose={() => {
                    setVisibleDrawerAvaliacao(false);
                    setEstadoDrawer("");
                }}
                atualizarAdicionado={atualizarAdicionado}
                atualizarEditado={atualizarEditado}
                estado={estadoDrawer}
                estrutura={estrutura}
                registoPaiId={registoPaiId}
                registo={registo}
                tipo={tipo}
                grupo={grupo}
                primeiroNivel={primeiroNivel}
                recuperacaoBloqueada={
                    (primeiroNivel && existeRecuperacao) ||
                    (!primeiroNivel && existeRecuperacao && estadoDrawer === "EDITAR") ||
                    (!primeiroNivel && estadoDrawer !== "EDITAR")
                }
            />
        </>
    );
};

const FormDrawerDiario = Form.create({ name: "drawer-diario" })(DrawerDiario);

export default FormDrawerDiario;
