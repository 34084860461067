import React, { useState } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Input, InputNumber, Select, Radio, Alert } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerNotificar = ({ visible, total, onClose, form }) => {
    const [texto, setTexto] = useState("");
    const [ficheiro, setFicheiro] = useState([]);
    const [iconLoading, setIconLoading] = useState(false);

    const handleChange = event => {
        setTexto(event.target.value);
    };

    const enviar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("cursoId", localStorage.getItem("codigo_curso"));
                item.append("texto", texto);

                ficheiro.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "post",
                    url: "/api/turma/notificar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "E-mail enviado!");
                        setIconLoading(false)
                        onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false)
                    });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    const validarFormatos = (formato, formatos_aceites) => {
        var valido = false

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato === formatos_aceites[i]) valido = true
        }

        return valido
    }

    const validar = (rule, value, callback) => {
        if (value.file)
            if (!validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"]) || value.file.size > 26214400)
                callback("Campo obrigatório")

        callback()
    }

    const afterVisibleChange = aberto => {
        if (aberto) {
            form.resetFields();
            setTexto("");
        }
    }

    const { getFieldDecorator } = form;
    const Dragger = Upload.Dragger;

    const props = {
        accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
        multiple: false,
        onRemove: file => {
            form.resetFields("download")
            setFicheiro([])
        },
        defaultFileList: ficheiro,
        beforeUpload: file => {
            if (!validarFormatos(file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"])) {
                openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                return false;
            }

            if (file.size > 26214400) {
                openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                return false;
            }

            setFicheiro([file])

            return false
        },
        ficheiro
    }

    return (
        <Drawer
            placement="right"
            className="drawer-envio-mensagem-inscricao"
            maskClosable={false}
            closable={true}
            onClose={onClose}
            visible={visible}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-nova-pergunta">
                    <div className="bloco">
                        <Form.Item label={`Mensagem para todos (${total})`}>
                            {getFieldDecorator("texto", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: texto
                            })(<TextArea rows={15} name="texto" placeholder="Mensagem..." style={{ height: "100%" }} onChange={handleChange} />)}
                        </Form.Item>
                        {getFieldDecorator("upload", {
                            rules: [
                                {
                                    validator: validar
                                }
                            ],
                            initialValue: ficheiro
                        })(
                            <Dragger {...props} fileList={ficheiro}>
                                <p className="ant-upload-drag-icon">
                                    <i className="fas fa-upload" />
                                </p>
                                <p className="ant-upload-text">
                                    Adicionar anexo
                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </p>
                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                            </Dragger>
                        )}
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button
                    className="botao-principal"
                    onClick={enviar}
                    disabled={iconLoading}
                >
                    {iconLoading ? <Icon type="loading" /> : null}
                    Enviar
                </button>
            </div>
        </Drawer>
    );
}

const FormDrawerNotificar = Form.create({ name: "form-notificar" })(DrawerNotificar);

export default withRouter(FormDrawerNotificar);
