import React, { Component } from "react";
import { notification } from "antd";
import { Redirect } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import axios from "axios";
import { GlobalContext } from "../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class LoginAutoV2 extends Component {
    static contextType = GlobalContext;
    state = {
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.login();
    }

    login = () => {
        if (this.getParameterByName("email"))
            axios({
                method: "post",
                url: "/api/autenticacaonew/login-auto-v2",
                params: {
                    nome: this.getParameterByName("nome"),
                    email: this.getParameterByName("email")
                }
            })
                .then(response => {
                    if (response.data.token) {
                        localStorage.setItem("accessToken", response.data.token)
                        axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
                        this.context.atualizarState({
                            atualizarEntidadeFooter: true,
                            is_authenticated: true,
                            is_authenticated_nav: true,
                            role: response.data.role ? response.data.role : "",
                            informacoes: response.data.informacoes
                        });

                        this.setState({
                            redirectLink: "/user",
                            redirect: true
                        });
                    }
                    else {
                        localStorage.removeItem("accessToken")
                        delete axios.defaults.headers.common.Authorization;
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    window.history.back();
                });
        else
            this.setState({
                redirect: true,
                redirectLink: "/"
            });
    };

    getParameterByName = name => {
        const match = RegExp("[?&]" + name + "=([^&]*)").exec(this.props.location.pathname);
        return match && decodeURIComponent(match[1]);
    };

    render() {
        if (this.state.redirect) return <Redirect to={this.state.redirectLink} />;

        return (
            <>
                <div className="login-auto-redirecionar">
                    {/*<LazyLoad height={0} offsetVertical={0}>*/}
                    {/*    <img src={require("../images/wave_hero_user.svg")} style={this.props.negativeTop && { top: "-80px" }} />*/}
                    {/*</LazyLoad>*/}
                    <div className="bloco-redirecionar">
                        <div>
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                            <div className="texto-redirecionar">
                                <h2>A redirecionar...</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LoginAutoV2;
