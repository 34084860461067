import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Icon, Tabs, Collapse, Modal, notification, Select } from "antd";
import Conteudos from "./Conteudos/Conteudos";
import InfoAluno from "./InfoAluno/InfoAluno";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const { TabPane } = Tabs;
const { Panel } = Collapse;

class Detalhe extends Component {
    state = {
        aulaId: "",
        visible: false,
        titulo: "",
        avaliacao: false,
        dt_inicio: "",
        hr_inicio: "",
        hr_termino: "",
        recorrencia: false,
        localizacao: "",
        //MODAL EXCLUIR
        visibleExcluir: false,
        visibleExcluirWaiting: false,
        opcao: 1,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    }

    componentDidMount() {
        this.detalhe();
    }

    selecionarOpcaoHandler = (event) => {
        this.setState({
            opcao: event.target.value
        })
    }

    detalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe-vista-aluno",
            params: {
                aulaId: localStorage.getItem("aula_diario")
            }
        })
            .then(response => {
                this.setState({
                    titulo: response.data.titulo,
                    avaliacao: response.data.avaliacao,
                    dt_inicio: moment(response.data.dt_inicio).format("DD/MM/YYYY"),
                    hr_inicio: response.data.hr_inicio,
                    hr_termino: response.data.hr_termino,
                    recorrencia: response.data.recorrencia,
                    localizacao: response.data.localizacao
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    selecionarExcluir = () => {
        if (!this.state.recorrencia)
            confirm({
                title: "Pretende excluir esta aula?",
                okText: "Confirmar",
                okType: "Cancelar",
                onOk: () => {
                    this.excluir(false);
                }
            });
        else {
            this.setState({
                visibleExcluir: true
            })
        }
    }

    excluir = () => {
        this.setState({
            visibleExcluir: false,
            visibleExcluirWaiting: true
        })
        axios({
            method: "delete",
            url: "/api/aulas/excluir",
            params: {
                aulaId: localStorage.getItem("aula_diario"),
                opcao: this.state.opcao
            }
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Aula excluida!");
                this.setState({
                    visibleExcluirWaiting: false,
                    redirect: true,
                    redirectLink: `/curso/${this.props.match.params.curso}/curso-diario`
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível excluir esta aula!");
            });
    }

    render() {
        const {
            aulaId,
            visible,
            titulo,
            avaliacao,
            dt_inicio,
            hr_inicio,
            hr_termino,
            localizacao,
            //MODAL EXCLUIR
            visibleExcluir,
            visibleExcluirWaiting,
            opcao,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginBottom: 0,
        };

        return (
            <>
                <div className="container container-body">
                    <div className="curso-diario curso-diario-desktop">
                        <div className="bloco-informacoes">
                            <div className="info-aula">
                                <span>
                                    {avaliacao ?
                                        <span className="titulo-aula evento-avaliacao">{`${localizacao.edificio} - ${localizacao.sala}`}<i className="icon-avaliacao" title="Aula com avaliação"></i></span>
                                        :
                                        <span className="titulo-aula">{`${localizacao.edificio} - ${localizacao.sala}`}</span>
                                    }
                                    <span className="data-aula">{dt_inicio}<i className="fas fa-circle"></i>{hr_inicio} - {hr_termino}</span>
                                </span>
                                <span className="bloco-opcoes">
                                    <Link to={`/detalhe-turma/${this.props.match.params.turma}/horario-disciplina`} className="botao-icon-voltar" title="Voltar">
                                        <Icon type="arrow-left" />
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="collapse-info-diario">
                            <Collapse defaultActiveKey={[1]} className="collapse-campos">
                                <Panel header="Conteúdos" key="1">
                                    <Conteudos />
                                </Panel>
                                <Panel header="Frequência" key="2">
                                    <InfoAluno
                                        aulaId={localStorage.getItem("aula_diario")}
                                    />
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                    <div className="curso-diario curso-diario-mobile">
                        <div className="bloco-informacoes">
                            <div className="info-aula">
                                <span>
                                    {avaliacao ?
                                        <span className="titulo-aula evento-avaliacao">{titulo}<i className="icon-avaliacao" title="Aula com avaliação"></i></span>
                                        :
                                        <span className="titulo-aula">{titulo}</span>
                                    }
                                    <span className="data-aula">{dt_inicio}</span>
                                    <span className="data-aula">{hr_inicio} - {hr_termino}</span>
                                </span>
                                <span className="bloco-opcoes">
                                    <Link to={`/curso/${this.props.match.params.curso}/curso-diario`} className="botao-icon-voltar" title="Voltar">
                                        <Icon type="arrow-left" />
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="collapse-info-diario">
                            <Collapse defaultActiveKey={[1]} className="collapse-campos">
                                <Panel header="Frequência" key="1">
                                    <InfoAluno
                                        aulaId={localStorage.getItem("aula_diario")}
                                    />
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Detalhe;
