import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Modal, notification, Icon, Button, Dropdown, Menu } from "antd";
import DrawerAdicionar from "./Drawers/DrawerAdicionar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { GlobalContext } from "../../GlobalState";
import { GeralContext } from "../ContextProvider";
import axios from "axios";
import moment from "moment";

import ModalDetalhe from "./Modals/Detalhe/Detalhe";
import ModalLoading from "../../Componentes/Modals/ModalLoading";
import ModalViewer from "../../Componentes/Modals/ModalViewer";

import Acessos from "../../Geral/Acessos/Acessos";

import "react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment);

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
class GerirHorario extends Component {
    static contextType = GlobalContext;

    state = {
        dataCalendario: new Date(),
        vista: "week",
        inicio: moment().startOf("week").format("YYYY/MM/DD"),
        termino: moment().endOf("week").format("YYYY/MM/DD"),
        aulas: [],
        ultimaCor: "",
        isNota: false,
        fases: [],
        //LOADING
        iconLoading: false,
        //DRAWER ADICIONAR
        visible: false,
        dataHoraInicio: "",
        dataHoraFim: "",
        //DRAWER DETALHE
        modalEventoVisible: false,
        aulaId: "",
        titulo: "",
        mesmoDia: false,
        data: "",
        horas: "",
        descricao: "",
        autor: false,
        cursoId: "",
        moduloIndex: "",
        tarefaId: "",
        stepmeet: "",
        tipo: "",
        link: "",
        botaoAtivo: false,
        registarUrl: "",
        aluno: false,
        tipoBotao: {},
        externa: false,
        convite: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL DETALHE
        visibleModalDetalhe: false,
        //FILTRO
        filtroEscolaId: null,
        info: "",
        //MODAL EXPORT
        visibleExport: false,
        buttonDownload: false,
        exportFile: "",
        //DOCS
        docs: [],
        visibleLoading: false,
        modalVisible: false
    };

    UNSAFE_componentWillMount() {
        if (
            localStorage.getItem("posicao_calendario") &&
            localStorage.getItem("temp_codigo_curso") === localStorage.getItem("codigo_curso")
        ) {
            const data = new Date(localStorage.getItem("posicao_calendario"));
            let vista = localStorage.getItem("vista_calendario");

            if (vista === null) {
                localStorage.setItem("vista_calendario", "week");
                vista = "week";
            }

            this.setState(
                {
                    dataCalendario: data,
                    vista,
                    inicio: moment(data).startOf(vista).format("YYYY/MM/DD"),
                    termino: moment(data).endOf(vista).format("YYYY/MM/DD")
                },
                () => this.listar()
            );
        } else {
            localStorage.setItem("posicao_calendario", this.state.dataCalendario);
            localStorage.setItem("vista_calendario", this.state.vista);
            localStorage.setItem("temp_codigo_curso", localStorage.getItem("codigo_curso"));
            this.listar();
        }

        this.listarFasesEscolares();
        this.listaDiarioEscolaridade();
    }

    selecionarOpcaoHandler = event => {
        this.setState({
            opcao: event.target.value
        });
    };

    componentDidUpdate() {
        const { estabelecimento } = this.context;
        const { filtroEscolaId } = this.state;

        if (estabelecimento?.id && estabelecimento.id !== filtroEscolaId) {
            this.setState({
                filtroEscolaId: estabelecimento.id
            });
        }
    }

    atualizarPosicaoCalendário = dataCalendario => {
        localStorage.setItem("posicao_calendario", dataCalendario);
        const vista = localStorage.getItem("vista_calendario");
        this.setState(
            {
                dataCalendario,
                vista,
                inicio: moment(dataCalendario).startOf(vista).format("YYYY/MM/DD"),
                termino: moment(dataCalendario).endOf(vista).format("YYYY/MM/DD")
            },
            () => this.listar()
        );
        this.listarFasesEscolares();
        this.listaDiarioEscolaridade();
    };

    listar = () => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/listar-aulas-horario",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        inicio: this.state.inicio,
                        termino: this.state.termino
                    }
                })
                    .then(response => {
                        const aulas = response.data.map(aula => {
                            return {
                                id: aula.id,
                                title: `${aula.titulo}${aula.localizacao?.sala ? ` (${aula.localizacao?.sala})` : ""}`,
                                start: new Date(aula.inicio),
                                end: new Date(aula.termino),
                                cor: aula.cor,
                                //corBorder: aula.corBorder,
                                recorrencia: aula.recorrencia,
                                titulo: aula.titulo,
                                data: aula.data,
                                horaInicio: aula.horaInicio,
                                horaFim: aula.horaFim,
                                disciplina: aula.disciplina,
                                professor: aula.professor,
                                localizacao: aula.localizacao,
                                avaliacao: aula.avaliacao
                            };
                        });

                        this.setState({
                            aulas,
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        );
    };

    exportPdf = () => {
        this.setState(
            {
                visibleExport: true,
                buttonDownload: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pdf/horarios",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            buttonDownload: false,
                            exportFile: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExport: false
                        });
                    });
            }
        );
    };

    gerarHorario = () => {
        this.setState({
            visibleLoading: true
        });
        if (localStorage.getItem("vista_calendario") === "month")
            axios({
                method: "get",
                url: "/api/pdf/calendario-mes",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    inicio: this.state.dataCalendario
                }
            })
                .then(response => {
                    this.setState({
                        docs: [{ uri: response.data }],
                        visibleLoading: false,
                        modalVisible: true
                    });
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                    this.setState({
                        visibleLoading: false
                    });
                });
        else
            axios({
                method: "get",
                url: "/api/pdf/horarios-pp",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    inicio: this.state.inicio,
                    termino: this.state.termino
                }
            })
                .then(response => {
                    this.setState({
                        docs: [{ uri: response.data }],
                        visibleLoading: false,
                        modalVisible: true
                    });
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                    this.setState({
                        visibleLoading: false
                    });
                });
    };

    listarOndeExistem = (toolbar, tipo) => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/listar-onde-existem",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        inicio: this.state.inicio,
                        termino: this.state.termino,
                        vista: toolbar.view,
                        tipo
                    }
                })
                    .then(response => {
                        if (response.data === "ANTERIOR" || response.data === "PROXIMO") {
                            openNotificationWithIcon(
                                "warning",
                                "Atenção",
                                response.data === "ANTERIOR" ? "Não existem anteriores" : "Não existem próximas aulas"
                            );
                            this.setState({
                                iconLoading: false
                            });
                        } else {
                            var count = response.data;

                            if (tipo === "ANTERIOR") {
                                if (toolbar.view === "month") {
                                    toolbar.date.setMonth(toolbar.date.getMonth() - count * 1);
                                    toolbar.onNavigate("prev");
                                    this.setState(
                                        {
                                            dataCalendario: toolbar.date
                                        },
                                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                                    );
                                } else if (toolbar.view === "week") {
                                    let mDate = this.state.dataCalendario;
                                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - count * 7);
                                    toolbar.onNavigate("prev", newDate);
                                    this.setState(
                                        {
                                            dataCalendario: newDate
                                        },
                                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                                    );
                                } else {
                                    let mDate = this.state.dataCalendario;
                                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - count);
                                    toolbar.onNavigate("prev", newDate);
                                    this.setState(
                                        {
                                            dataCalendario: newDate
                                        },
                                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                                    );
                                }
                            } else {
                                if (toolbar.view === "month") {
                                    toolbar.date.setMonth(toolbar.date.getMonth() + count * 1);
                                    toolbar.onNavigate("next");
                                    this.setState(
                                        {
                                            dataCalendario: toolbar.date
                                        },
                                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                                    );
                                } else if (toolbar.view === "week") {
                                    let mDate = this.state.dataCalendario;
                                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + count * 7);
                                    toolbar.onNavigate("prev", newDate);
                                    this.setState(
                                        {
                                            dataCalendario: newDate
                                        },
                                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                                    );
                                } else {
                                    try {
                                        let mDate = this.state.dataCalendario;
                                        let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + count);
                                        toolbar.onNavigate("prev", newDate);
                                        this.setState(
                                            {
                                                dataCalendario: newDate
                                            },
                                            () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                                        );
                                    } catch (error) {}
                                }
                            }
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        );
    };

    listarFasesEscolares = () => {
        axios({
            method: "get",
            url: "/api/aulas/lista-fases-escolares",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    fases: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listaDiarioEscolaridade = () => {
        axios({
            method: "get",
            url: "/api/aulas/lista-diario-escolaridade",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    isNota: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    onRangeChange = range => {
        if (range.length) {
            this.setState(
                {
                    inicio: moment(range[0]).format("YYYY/MM/DD"),
                    termino: moment(range.pop()).format("YYYY/MM/DD")
                },
                () => this.listar()
            );
        } else {
            this.setState(
                {
                    inicio: moment(range.start).format("YYYY/MM/DD"),
                    termino: moment(range.end).format("YYYY/MM/DD")
                },
                () => this.listar()
            );
        }
    };

    onDrillDown = range => {
        this.setState(
            {
                inicio: moment(range).format("YYYY/MM/DD"),
                termino: moment(range).format("YYYY/MM/DD"),
                vista: "day",
                dataCalendario: range
            },
            () => {
                localStorage.setItem("posicao_calendario", this.state.dataCalendario);
                localStorage.setItem("vista_calendario", "day");
            }
        );
    };

    getMonthNameInPortuguese(date) {
        const monthIndex = date.month();
        const monthNames = [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro"
        ];
        return monthNames[monthIndex];
    }

    getDayOfWeekInPortuguese(date) {
        const dayIndex = date.day();
        const dayNames = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
        return dayNames[dayIndex];
    }

    onSelectEvent = event => {
        const acesso = this.context.acessos?.find(x => x.func === 17);

        if (acesso?.read) {
            localStorage.setItem("aula_diario", event.id);
            localStorage.setItem("link_voltar_calendario", this.props.match.url);
            localStorage.setItem("horario_detalhe_titulo", !event.titulo ? event.disciplina : event.titulo);
            this.setState({
                redirect: true,
                redirectLink: `/gerir-detalhe-turma/${this.props.match.params.turma}/horario-detalhe`
            });
        }
    };

    editarEvento = () => {
        this.setState({
            modalEventoVisible: false,
            visible: true
        });
    };

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (
                <Link
                    to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar}
                    className="botao-secundario botao-secundario-redondo"
                    onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}
                    style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <Icon type="arrow-left" />
                </Link>
            );
        }
    };

    render() {
        const {
            dataCalendario,
            vista,
            aulas,
            isNota,
            //DRAWER ADICIONAR
            visible,
            dataHoraInicio,
            dataHoraFim,
            //MODAL DETALHE
            aulaId,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            iconLoading,
            //MODAL DETALHE
            visibleModalDetalhe,
            info,
            //MODAL EXPORT
            visibleExport,
            buttonDownload,
            exportFile,
            //DOCS
            docs,
            visibleLoading,
            modalVisible
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const eventStyleGetter = (event, start, end, isSelected) => {
            const style = {
                background: event.avaliacao
                    ? `linear-gradient(145deg, rgba(255,166,0,1) 0%, rgba(255,166,0,1) 15%, ${event.cor} 15%)`
                    : event.cor
            };
            return {
                style: style
            };
        };

        const toolbar = toolbar => {
            const aulasAnteriores = () => {
                this.listarOndeExistem(toolbar, "ANTERIOR");
            };

            const proximasAulas = () => {
                this.listarOndeExistem(toolbar, "PROXIMO");
            };

            const anterior = () => {
                if (toolbar.view === "month") {
                    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
                    toolbar.onNavigate("prev");
                    this.setState(
                        {
                            dataCalendario: toolbar.date
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                } else if (toolbar.view === "week") {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 7);
                    toolbar.onNavigate("prev", newDate);
                    this.setState(
                        {
                            dataCalendario: newDate
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                } else {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 1);
                    toolbar.onNavigate("prev", newDate);
                    this.setState(
                        {
                            dataCalendario: newDate
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                }
            };

            const proximo = () => {
                if (toolbar.view === "month") {
                    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
                    toolbar.onNavigate("next");
                    this.setState(
                        {
                            dataCalendario: toolbar.date
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                } else if (toolbar.view === "week") {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + 7);
                    toolbar.onNavigate("prev", newDate);
                    this.setState(
                        {
                            dataCalendario: newDate
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                } else {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + 1);
                    toolbar.onNavigate("prev", newDate);
                    this.setState(
                        {
                            dataCalendario: newDate
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                }
            };

            const vistaHoje = () => {
                toolbar.onNavigate("current", new Date());
                this.setState(
                    {
                        dataCalendario: new Date()
                    },
                    () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                );
            };

            const labelData = () => {
                const date = moment(toolbar.date);
                if (toolbar.view === "month") return <span>{date.format("MMMM YYYY")}</span>;
                else if (toolbar.view === "week") {
                    return <span>{toolbar.label}</span>;
                } else return <span>{date.format("dddd, DD [de] MMMM YYYY")}</span>;
            };

            const vistaMes = () => {
                toolbar.onView("month");
                this.setState(
                    {
                        vista: "month"
                    },
                    () => localStorage.setItem("vista_calendario", "month")
                );
            };

            const vistaSemana = () => {
                toolbar.onView("week");
                this.setState(
                    {
                        vista: "week"
                    },
                    () => localStorage.setItem("vista_calendario", "week")
                );
            };

            const vistaDia = () => {
                toolbar.onView("day");
                this.setState(
                    {
                        vista: "day"
                    },
                    () => localStorage.setItem("vista_calendario", "day")
                );
            };

            const montarTitleDuplaSetaEsq = () => {
                if (vista === "month") return "Mês anterior com aulas";
                else if (vista === "week") return "Semana anterior com aulas";
                else return "Dia anterior com aulas";
            };

            const montarTitleDuplaSetaDir = () => {
                if (vista === "month") return "Próximo mês com aulas";
                else if (vista === "week") return "Próxima semana com aulas";
                else return "Próximo dia com aulas";
            };

            const montarTitleSetaEsq = () => {
                if (vista === "month") return "Mês anterior";
                else if (vista === "week") return "Semana anterior";
                else return "Dia anterior";
            };

            const montarTitleSetaDir = () => {
                if (vista === "month") return "Próximo mês";
                else if (vista === "week") return "Próxima semana";
                else return "Próximo dia";
            };

            return (
                <>
                    <div className="toolbar-container toolbar-container-desktop">
                        <div className="toolbal-opcoes-esquerda">
                            <Button.Group>
                                <Button className="btn-anterior" title={montarTitleDuplaSetaEsq()} onClick={aulasAnteriores}>
                                    <Icon type="double-left" />
                                </Button>
                                <Button className="btn-anterior" title={montarTitleSetaEsq()} onClick={anterior}>
                                    <Icon type="left" />
                                </Button>
                                <Button className="btn-hoje" onClick={vistaHoje}>
                                    Hoje
                                </Button>
                                <Button className="btn-proximo" title={montarTitleSetaDir()} onClick={proximo}>
                                    <Icon type="right" />
                                </Button>
                                <Button className="btn-proximo" title={montarTitleDuplaSetaDir()} onClick={proximasAulas}>
                                    <Icon type="double-right" />
                                </Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-label-data">
                            <label className="label-data">{labelData()}</label>
                        </div>
                        <div className="toolbal-opcoes-direita" style={{ minWidth: "150px" }}>
                            <Button.Group>
                                <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>
                                    Mês
                                </Button>
                                <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>
                                    Semana
                                </Button>
                            </Button.Group>
                        </div>
                    </div>
                    <div className="toolbar-container toolbar-container-tablet">
                        <div className="toolbal-opcoes-cima">
                            <Button.Group>
                                <Button.Group>
                                    <Button className="btn-anterior" title={montarTitleDuplaSetaEsq()} onClick={aulasAnteriores}>
                                        <Icon type="double-left" />
                                    </Button>
                                    <Button className="btn-anterior" title={montarTitleSetaEsq()} onClick={anterior}>
                                        <Icon type="left" />
                                    </Button>
                                    <Button className="btn-hoje" onClick={vistaHoje}>
                                        Hoje
                                    </Button>
                                    <Button className="btn-proximo" title={montarTitleSetaDir()} onClick={proximo}>
                                        <Icon type="right" />
                                    </Button>
                                    <Button className="btn-proximo" title={montarTitleDuplaSetaDir()} onClick={proximasAulas}>
                                        <Icon type="double-right" />
                                    </Button>
                                </Button.Group>
                            </Button.Group>
                            <Button.Group>
                                <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>
                                    Mês
                                </Button>
                                <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>
                                    Semana
                                </Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-opcoes-baixo">
                            <div className="toolbal-label-data">
                                <label className="label-data">{labelData()}</label>
                            </div>
                        </div>
                    </div>
                    <div className="toolbar-container toolbar-container-mobile">
                        <div className="toolbal-opcoes-esquerda">
                            <Button.Group>
                                <Button.Group>
                                    <Button className="btn-anterior" title={montarTitleDuplaSetaEsq()} onClick={aulasAnteriores}>
                                        <Icon type="double-left" />
                                    </Button>
                                    <Button className="btn-anterior" title={montarTitleSetaEsq()} onClick={anterior}>
                                        <Icon type="left" />
                                    </Button>
                                    <Button className="btn-hoje" onClick={vistaHoje}>
                                        Hoje
                                    </Button>
                                    <Button className="btn-proximo" title={montarTitleSetaDir()} onClick={proximo}>
                                        <Icon type="right" />
                                    </Button>
                                    <Button className="btn-proximo" title={montarTitleDuplaSetaDir()} onClick={proximasAulas}>
                                        <Icon type="double-right" />
                                    </Button>
                                </Button.Group>
                            </Button.Group>
                        </div>
                        <div className="toolbal-opcoes-direita">
                            <Button.Group>
                                <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>
                                    Mês
                                </Button>
                                <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>
                                    Semana
                                </Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-label-data">
                            <label className="label-data">{labelData()}</label>
                        </div>
                    </div>
                </>
            );
        };

        const hoje = new Date();

        moment.updateLocale("pt", {
            week: {
                dow: 0
            }
        });

        const acesso = this.context.acessos?.find(x => x.func === 14);

        return (
            <>
                <Acessos funcionalidadeId={14} />
                <div className="container container-body bloco-diario">
                    <div className="turma-visao-geral">
                        <div className="controlos controlos-fundo">
                            <div className="bloco-esquerda">
                                {acesso?.create && (
                                    <Link
                                        to="#"
                                        className="botao-principal botao-principal-redondo"
                                        title="Nova aula"
                                        onClick={() => this.setState({ visible: true, aulaId: "", dataHoraInicio: "", dataHoraFim: "" })}
                                    >
                                        +
                                    </Link>
                                )}
                            </div>
                            {localStorage.getItem("entidade_id") === "33" ?
                                <div className="bloco-direita">
                                    {isNota && (
                                        <Link
                                            className="botao-icon-relatorio-aproveitamento "
                                            to={`/gerir-detalhe-turma/${this.props.match.params.turma}/relatorios-notas`}
                                            title="Relatórios"
                                            onClick={() => localStorage.setItem("vista", "TURMA")}
                                        >
                                            <Icon type="table" />
                                            Médias
                                        </Link>
                                    )}
                                    <Link
                                        className="botao-icon-conteudo "
                                        to={`/gerir-detalhe-turma/${this.props.match.params.turma}/diario-folha-conteudos`}
                                        title="Sumários"
                                        onClick={() => localStorage.setItem("vista", "TURMA")}
                                    >
                                        <Icon type="read" />
                                        Conteúdos
                                    </Link>
                                    <Link
                                        className="botao-icon-frequencia "
                                        to={`/gerir-detalhe-turma/${this.props.match.params.turma}/diario-folha-frequencia`}
                                        title="Frequência"
                                        onClick={() => localStorage.setItem("vista", "TURMA")}
                                    >
                                        <Icon type="carry-out" />
                                        Frequências
                                    </Link>
                                    {(!this.props.existeProfTitular ||
                                        (this.props.existeProfTitular && this.props.temPermissao && !this.props.pareceresPorAula) ||
                                        this.props.pareceresPorAula) && (
                                            <Link
                                                className="botao-icon-pareceres "
                                                to={`/gerir-detalhe-turma/${this.props.match.params.turma}/${this.props.pareceresPorAula ? "diario-folha-pareceres" : "diario-folha-pareceres-turma"}`}
                                                title="Pareceres"
                                                onClick={() => localStorage.setItem("vista", "TURMA")}
                                            >
                                                <Icon type="snippets" />
                                                Pareceres
                                            </Link>)}
                                    <Link
                                        className="botao-icon-observacoes "
                                        to={`/gerir-detalhe-turma/${this.props.match.params.turma}/diario-folha-observacoes`}
                                        title="Observações"
                                        onClick={() => localStorage.setItem("vista", "TURMA")}
                                    >
                                        <Icon type="profile" />
                                        Complementares
                                    </Link>
                                    <Link className="botao-icon-detalhe-diario " to="#" title="Descarregar PDF" onClick={this.gerarHorario}>
                                        <Icon type="eye" />
                                        Horário
                                    </Link>
                                    {this.montarBotaoVoltar()}
                                </div>
                                :
                                <div className="bloco-direita">
                                    <Link
                                        className="botao-icon-conteudo "
                                        to={`/gerir-detalhe-turma/${this.props.match.params.turma}/diario-folha-conteudos`}
                                        title="Sumários"
                                        onClick={() => localStorage.setItem("vista", "TURMA")}
                                    >
                                        <Icon type="read" />
                                        Conteúdos
                                    </Link>
                                    <Link
                                        className="botao-icon-frequencia "
                                        to={`/gerir-detalhe-turma/${this.props.match.params.turma}/diario-folha-frequencia`}
                                        title="Frequência"
                                        onClick={() => localStorage.setItem("vista", "TURMA")}
                                    >
                                        <Icon type="carry-out" />
                                        Frequência
                                    </Link>
                                    {isNota && (
                                        <Link
                                            className="botao-icon-relatorio-aproveitamento "
                                            to={`/gerir-detalhe-turma/${this.props.match.params.turma}/relatorios-notas`}
                                            title="Relatórios"
                                            onClick={() => localStorage.setItem("vista", "TURMA")}
                                        >
                                            <Icon type="table" />
                                            Notas
                                        </Link>
                                    )}
                                    {(!this.props.existeProfTitular ||
                                        (this.props.existeProfTitular && this.props.temPermissao && !this.props.pareceresPorAula) ||
                                        this.props.pareceresPorAula) && (
                                            <Link
                                                className="botao-icon-pareceres "
                                                to={`/gerir-detalhe-turma/${this.props.match.params.turma}/${this.props.pareceresPorAula ? "diario-folha-pareceres" : "diario-folha-pareceres-turma"}`}
                                                title="Pareceres"
                                                onClick={() => localStorage.setItem("vista", "TURMA")}
                                            >
                                                <Icon type="snippets" />
                                                Pareceres
                                            </Link>)}
                                    <Link
                                        className="botao-icon-observacoes "
                                        to={`/gerir-detalhe-turma/${this.props.match.params.turma}/diario-folha-observacoes`}
                                        title="Observações"
                                        onClick={() => localStorage.setItem("vista", "TURMA")}
                                    >
                                        <Icon type="profile" />
                                        Observações
                                    </Link>
                                    <Link className="botao-icon-detalhe-diario " to="#" title="Descarregar PDF" onClick={this.gerarHorario}>
                                        <Icon type="eye" />
                                        Horário
                                    </Link>
                                    {this.montarBotaoVoltar()}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="curso-diario">
                        <Calendar
                            localizer={localizer}
                            events={aulas}
                            scrollToTime={new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(), 8)}
                            startAccessor="start"
                            endAccessor="end"
                            style={{height: 700}}
                            defaultView={vista}
                            view={vista}
                            date={dataCalendario}
                            views={["month", "week", "day"]}
                            components={{
                                toolbar: toolbar
                            }}
                            onView={() => {
                            }}
                            onNavigate={() => {
                            }}
                            onSelectEvent={this.onSelectEvent}
                            onDrillDown={this.onDrillDown}
                            onRangeChange={this.onRangeChange}
                            eventPropGetter={eventStyleGetter}
                            selectable={true}
                            //onSelectSlot={this.onSelectSlot}
                        />
                        <div className="legenda">
                            <div className="bloco-cores visible-mobile">
                                    <span className="item">
                                        <i className="cor-estrutura cor-1"></i>Prevista
                                    </span>
                                <span className="item">
                                        <i className="cor-estrutura cor-2"></i>Dada
                                    </span>
                                <span className="item">
                                        <i className="cor-estrutura cor-3"></i>Falta
                                    </span>
                                <span className="item">
                                        <i className="cor-estrutura cor-4"></i>Compensação
                                    </span>

                                <span className="item">
                                        <i className="cor-estrutura cor-5"></i>Sumário e/ou Frequência por preencher
                                </span>
                                <span className="item">
                                        <i className="cor-estrutura cor-9"></i>Aula por validar
                                    </span>
                                {localStorage.getItem("entidade_id") === "33" &&
                                    <span className="item">
                                            <i className="cor-estrutura cor-10"></i>Reposição de aulas
                                        </span>}
                                <span className="item">
                                        <i className="cor-estrutura-quadrada"></i>Aula de avaliação
                                    </span>
                            </div>

                            <div className="bloco-cores visible-desktop">
                                <span className="item">
                                    <i className="cor-estrutura cor-1"></i>Prevista
                                </span>
                                <span className="item">
                                    <i className="cor-estrutura cor-2"></i>Dada
                                </span>
                                <span className="item">
                                    <i className="cor-estrutura cor-3"></i>Falta
                                </span>
                                <span className="item">
                                    <i className="cor-estrutura cor-4"></i>Compensação
                                </span>
                                <span className="item">
                                        <i className="cor-estrutura cor-5"></i>Sumário e/ou Frequência por preencher
                                </span>

                            </div>
                            <div className="bloco-cores visible-desktop">
                                <span className="item">
                                    <i className="cor-estrutura cor-9"></i>Aula por validar
                                </span>
                                {localStorage.getItem("entidade_id") === "33" && (
                                    <span className="item">
                                        <i className="cor-estrutura cor-10"></i>Reposição de aulas
                                    </span>
                                )}
                                <span className="item">
                                    <i className="cor-estrutura-quadrada"></i>Aula de avaliação
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <DrawerAdicionar
                    aulaId={aulaId}
                    visible={visible}
                    onClose={() =>
                        this.setState({
                            visible: false
                        })
                    }
                    adicionarAula={evento => this.setState({aulas: [...aulas, evento], visible: false})}
                    adicionarAulas={eventos => this.setState({aulas: [...aulas, ...eventos], visible: false})}
                    atualizarAula={evento => this.setState({
                        aulas: [...aulas.filter(x => x.id !== aulaId), evento],
                        visible: false
                    })}
                    dataHoraInicio={dataHoraInicio}
                    dataHoraFim={dataHoraFim}
                    atualizarPosicaoCalendário={dataCalendario => this.atualizarPosicaoCalendário(dataCalendario)}
                    filtroEscolaId={this.state.filtroEscolaId}
                    atualizarListagem={() => {
                        this.setState({visible: false});
                        this.listar();
                    }}
                />
                <Modal visible={iconLoading} maskClosable={false} className="modal-loading" footer={null}
                       closable={false}>
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading"/>
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
                <ModalDetalhe
                    info={info}
                    visible={visibleModalDetalhe}
                    abrirEditar={() => this.setState({visible: true, visibleModalDetalhe: false})}
                    onClose={() => this.setState({visibleModalDetalhe: false})}
                    atualizarListagem={() => this.listar()}
                />
                <ModalLoading visible={visibleLoading} text="A gerar documento..."/>
                <ModalViewer visible={modalVisible} docs={docs} onCancel={() => this.setState({modalVisible: false})}/>
            </>
        );
    }
}

const ContextGerirHorario = ({match}) => (
    <GeralContext.Consumer>
        {({pareceresPorAula, existeProfTitular, temPermissao}) => <GerirHorario pareceresPorAula={pareceresPorAula}
                                                                                existeProfTitular={existeProfTitular}
                                                                                temPermissao={temPermissao}
                                                                                match={match}/>}
    </GeralContext.Consumer>
);

export default ContextGerirHorario;
