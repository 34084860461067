import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Icon, Tooltip } from "antd";
import noimage from "../../../images/noimage.png";
import moment from "moment";

class Topico extends Component {
    montarMenu = (respostaid, estado) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.props.aprovarResposta(respostaid)}>
                        Aprovar
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.props.rejeitarResposta(respostaid)}>
                        Rejeitar
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuUtilizador = respostaid => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.props.excluir(respostaid)}>
                        Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    render() {
        const razao = (
            <div>
                <h4>Rejeitado por</h4>
                <p>{this.props.resposta.razao}</p>
            </div>
        );

        return (
            <div className="linha-resposta">
                <div className="info-resposta">
                    <div className="questao questao-desktop">
                        <div className="info-autor">
                            <div className="container-img">
                                <img src={this.props.resposta.utilizador.foto ? this.props.resposta.utilizador.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {this.props.resposta.utilizador.nome_completo.split(" ").length > 1 ? `${this.props.resposta.utilizador.nome_completo.split(" ")[0]} ${this.props.resposta.utilizador.nome_completo.split(" ")[this.props.resposta.utilizador.nome_completo.split(" ").length - 1]}` : this.props.resposta.utilizador.nome_completo}
                                    <span className="separador">-</span>
                                    <span className="data">
                                        {moment(this.props.resposta.dt_criado).format("DD/MM/YYYY - HH:mm")}
                                        {this.props.resposta.estado === 0 ? (
                                            <Icon type="warning" className="aguarda-validacao" title="Aguarda validação" />
                                        ) : (
                                                ""
                                            )}
                                        {this.props.resposta.estado === 2 ? (
                                            <Tooltip placement="bottomLeft" title={razao} overlayClassName="tooltip-razao">
                                                {" "}
                                                <Icon type="stop" className="rejeitado" title="Rejeitado" />{" "}
                                            </Tooltip>
                                        ) : (
                                                ""
                                            )}
                                    </span>
                                </span>
                                <p className="numero">{this.props.resposta.utilizador.numero}</p>
                            </div>
                        </div>
                        {localStorage.getItem("tem_comandos") === "true" && (
                            <span className="controlos">
                                {this.props.resposta.estado === 0 ?
                                    <>
                                        <Link to="#" className="botao-icon-like" onClick={() => this.props.aprovarResposta(this.props.resposta.id)} title="Aprovar">
                                            <Icon type="like" />
                                        </Link>
                                        <Link to="#" className="botao-icon-dislike" onClick={() => this.props.rejeitarResposta(this.props.resposta.id)} title="Rejeitar">
                                            <Icon type="dislike" />
                                        </Link>
                                    </>
                                    :
                                    <Link to="#" className="botao-icon-excluir" onClick={() => this.props.excluirAprovada(this.props.resposta.id)}>
                                        <Icon type="delete" />
                                    </Link>
                                }
                            </span>
                        )}
                        {(localStorage.getItem("tem_comandos") !== "true" && this.props.resposta.proprio) && !this.props.fechado ? (
                            <span className="controlos">
                                <Link to="#" className="botao-icon-excluir" onClick={() => this.props.excluir(this.props.resposta.id)}>
                                    <Icon type="delete" />
                                </Link>
                            </span>
                        ) : null}
                    </div>
                    <div className="questao questao-mobile">
                        <div className="questao-item">
                            <div className="info-autor">
                                <div className="container-img">
                                    <img src={this.props.resposta.utilizador.foto ? this.props.resposta.utilizador.foto : noimage} />
                                </div>
                                <div className="info">
                                    <span className="nome">
                                        {this.props.resposta.utilizador.nome_completo.split(" ").length > 1 ? `${this.props.resposta.utilizador.nome_completo.split(" ")[0]} ${this.props.resposta.utilizador.nome_completo.split(" ")[this.props.resposta.utilizador.nome_completo.split(" ").length - 1]}` : this.props.resposta.utilizador.nome_completo}
                                        {this.props.resposta.estado === 0 ? (
                                            <Icon type="warning" className="aguarda-validacao" title="Aguarda validação" />
                                        ) : (
                                            ""
                                        )}
                                        {this.props.resposta.estado === 2 ? (
                                            <Tooltip placement="bottomLeft" title={razao} overlayClassName="tooltip-razao">
                                                {" "}
                                                <Icon type="stop" className="rejeitado" title="Rejeitado" />{" "}
                                            </Tooltip>
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                    <p className="numero">
                                        {this.props.resposta.utilizador.numero}
                                        <span className="separador">-</span>
                                        <span className="data">
                                            {moment(this.props.resposta.dt_criado).format("DD/MM/YYYY - HH:mm")}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {localStorage.getItem("tem_comandos") === "true" && (
                                <span className="controlos">
                                    { this.props.resposta.estado === 0 ?
                                        <Dropdown
                                            overlay={this.montarMenu(this.props.resposta.id)}
                                            placement="bottomLeft"
                                        >
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog"></i>
                                            </Link>
                                        </Dropdown>
                                        :
                                        <Link to="#" className="botao-icon-excluir" onClick={() => this.props.excluirAprovada(this.props.resposta.id)}>
                                            <Icon type="delete" />
                                        </Link>
                                    }
                                </span>
                            )}
                            {(localStorage.getItem("tem_comandos") !== "true" && this.props.resposta.proprio) && !this.props.fechado ? (
                                <span className="controlos">
                                    <Link to="#" className="botao-icon-excluir" onClick={() => this.props.excluir(this.props.resposta.id)}>
                                        <Icon type="delete" />
                                    </Link>
                                </span>
                            ) : null}
                        </div>
                    </div>
                    <span
                        className="resposta"
                        dangerouslySetInnerHTML={{ __html: this.props.resposta.texto.replace(/(?:\r\n|\r|\n)/g, "<br>") }}
                    ></span>
                </div>
            </div>
        );
    }
}

export default Topico;
