import React, { useState, useRef, useEffect } from "react";
//import 'jodit';
//import 'jodit/build/jodit.min.css';
import JoditEditor from "jodit-react";

const Editor = props => {
    const editor = useRef(null);
    //const [content, setContent] = useState("");

    //useEffect(() => {
    //    props.setContent(content)
    //}, [content]);

    const config = {
        readonly: false,
        iframe: false,
        toolbarButtonSize: "large",
        height: 800,
        autofocus: false,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        // preset: "inline",
        uploader: {
            insertImageAsBase64URI: true
        },
        disabledPlugins:
            "addnewline,cleanhtml,clipboard,draganddrop,draganddropelement,paste,pastestorage,errormessages,formatblock,imageprocessor,imageproperties,inlinepopup,limit,media,tablekeyboardnavigation,tooltip",
        activeButtonsInReadOnly: ["source", "fullsize", "print", "about"],
        buttons: "source,|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,table,link,|,align,undo,redo,|,fullsize",
        enableDragAndDropFileToEditor: true,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        placeholder: ""
    }

    return (
        <JoditEditor
            //disable={true}
            // ref={editor}
            // value={"VALUE"}
            // config={config}
            //onBlur={props.onChange}
            ref={editor}
            value={props.value}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => localStorage.setItem("editor_conteudo", newContent)} // preferred to use only this option to update the content for performance reasons
        //onChange={newContent => {}}
        />
    );
};

export default Editor;
