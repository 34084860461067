import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Form, Select, notification, Modal, Dropdown, Menu, Switch, Icon } from "antd";
import noimage from "../../../images/noimage.png";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../../GlobalState";

import Header from "../../../User/Header";
import BotaoProcurar from "../../../Geral/_Aux/BotaoProcurar";
import DrawerTransferencia from "./Drawers/Transferencia";
import DrawerInscricao from "./Drawers/Inscricao";
import DrawerDetalhe from "../_Aux/DrawerDetalheInscricao";
import DrawerTransferir from "./Drawers/Transferir";
import DrawerEditar from "./Drawers/Editar";
import DrawerAnular from "./Drawers/Anular";
import DrawerRetomar from "./Drawers/Retomar";
import DrawerEscolaridade from "./Drawers/Escolaridade";
import DrawerConclusao from "./Drawers/Conclusao";
import DrawerDocumentos from "./Drawers/Documentos";

import ModalGerarDocumento from "../../../Componentes/Modals/GerarDocumento";

const confirm = Modal.confirm;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Inscricoes extends Component {
    static contextType = GlobalContext;
    state = {
        ativo: true,
        //TABELA
        loading_table: true,
        alunos: [],
        ativo: true,
        inscritos: false,
        sorter: {
            order: "ascend",
            field: "nome"
        },
        //INFO PRINCIPAL
        cursoid: 0,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER DETALHE INSCRIÇÃO
        inscricaoId: 0,
        drawer_detalhe_visible: false,
        //DRAWER SEARCH INSCRICAO
        drawer_inscricao_visible: false,
        //DRAWER TRANSFERENCIA
        drawer_transferencia_visible: false,
        //DRAWER CONCLUSÃO
        drawer_conclusao_visible: false,
        //DRAWER TRANSFERIR
        drawer_transferir_visible: false,
        //DRAWER EDITAR
        drawer_editar_visible: false,
        //DRAWER ANULAR
        drawer_anular_visible: false,
        //DRAWER RETOMAR
        drawer_retomar_visible: false,
        //DRAWER ESCOLARIDADE
        drawer_escolaridade_visible: false,
        //DRAWER DUCUMENTOS
        drawer_documentos_visible: false,
        //EXPORT
        visibleExport: false,
        buttonDownload: false,
        exportFile: ""
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    //PESQUISA
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa
                },
                () => {
                    this.listar();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => {
                this.listar();
            }
        );
    };

    //LISTA TODOS OS ALUNOS DA ENTIDADE
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-turma-matriculas/listar-inscricoes-cursos-disciplinas",
                    params: {
                        cursoid: localStorage.getItem("codigo_curso"),
                        pesquisa: this.state.pesquisa,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var alunos = [];
                        const acesso = this.context.acessos?.find(x => x.func === 13);

                        response.data.map((aluno, index) => {
                            let matricula = "";
                            const estado = aluno.ultimoEstado;

                            if (estado?.titulo === "A frequentar" && estado?.data)
                                matricula = (
                                    <span>
                                        {moment(estado.data).format("DD/MM/YYYY")} <span title="Matrícula">(M)</span>
                                    </span>
                                );

                            if (estado?.titulo === "Transferência" && estado?.data)
                                matricula = (
                                    <span>
                                        {moment(estado.data).format("DD/MM/YYYY")}{" "}
                                        <span title="Transferência">
                                            (T) <Icon type="caret-up" style={{ fontSize: 20 }} />
                                        </span>
                                    </span>
                                );

                            if (estado?.titulo === "Transferido" && estado?.data)
                                matricula = (
                                    <span>
                                        {moment(estado.data).format("DD/MM/YYYY")}{" "}
                                        <span title="Transferido">
                                            (T) <Icon type="caret-down" style={{ fontSize: 20 }} />
                                        </span>
                                    </span>
                                );

                            if (estado?.titulo === "Anulado" && estado?.data) {
                                if (estado.motivo === "Falecido" && estado.data) {
                                    matricula = (
                                        <span>
                                            {moment(estado.data).format("DD/MM/YYYY")} <span title="Falecido">(F)</span>
                                        </span>
                                    );
                                }
                                else if (estado.motivo === "Desistente" && estado.data) {
                                    matricula = (
                                        <span>
                                            {moment(estado.data).format("DD/MM/YYYY")}{" "}
                                            <span title="Desistente">{this.context.id_entidade === 3 ? "(DF)" : "(D)"}</span>
                                        </span>
                                    );
                                }
                                else if (estado.motivo === "Remanejado" && estado.data) {
                                    matricula = (
                                        <span>
                                            {moment(estado.data).format("DD/MM/YYYY")} <span title="Remanejado">(R)</span>
                                        </span>
                                    );
                                }
                                else if (estado.motivo === "Transferido" && estado.data) {
                                    matricula = (
                                        <span>
                                            {moment(estado.data).format("DD/MM/YYYY")} <span title="Transferido">(T)</span>
                                        </span>
                                    );
                                }
                                else if (estado.motivo === "Reclassificado" && estado.data) {
                                    matricula = (
                                        <span>
                                            {moment(estado.data).format("DD/MM/YYYY")} <span title="Reclassificado">(RC)</span>
                                        </span>
                                    );
                                }
                                else {
                                    matricula = (
                                        <span>
                                            {moment(estado.data).format("DD/MM/YYYY")} <span title="Anulado">(A)</span>
                                        </span>
                                    );
                                }
                            }

                            alunos.push({
                                key: aluno.inscricaoId,
                                id: aluno.inscricaoId,
                                turmaId: aluno.cursoId,
                                utilizadorId: aluno.id,
                                code: aluno.code,
                                nome: (
                                    <>
                                        <div className="imagem-curso">
                                            <img src={aluno.foto ? aluno.foto : noimage} />
                                        </div>
                                        <div className="nome-colaborador">
                                            <span className="titulo-curso">{aluno.nome_completo}</span>
                                            <div className="info-numero-estado">
                                                <span className="info-numero">{aluno.nr_aluno}</span>
                                                {aluno.pendente ? <span className="info-estado">Registo Pendente</span> : null}
                                            </div>
                                        </div>
                                    </>
                                ),
                                matricula: matricula,
                                estado: aluno.ultimaEstado,
                                tipos: aluno.tipoDocs,
                                opcoes: (acesso?.read || acesso?.update || acesso?.delete) && (
                                    <Dropdown
                                        overlay={this.montarMenu(aluno.inscricaoId, estado, aluno.tipoDocs)}
                                        placement="bottomLeft"
                                        onClick={event => event.stopPropagation()}
                                        overlayStyle={{
                                            zIndex: "9"
                                        }}
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ),
                                cursos: []
                            });
                        });

                        this.setState({
                            alunos,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        console.log("error", error)

                        if (error?.response?.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    listarDisciplinas = (expanded, record) => {
        axios({
            method: "get",
            url: "/api/gestao-turma-matriculas/listar-inscricoes-disciplinas",
            params: {
                turmaId: record.turmaId,
                utilizadorId: record.utilizadorId
            }
        })
            .then(response => {
                var _alunos = this.state.alunos;

                var index = _alunos.findIndex(x => x.id === record.id);

                _alunos[index].cursos = response.data;

                this.setState({
                    alunos: [..._alunos]
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    // MONTA O MENU DE OPÇÕES POR CADA CURSO/LINHA
    montarMenu = (inscricaoId, estado, tipoDocs) => {
        const acesso = this.context?.acessos?.find(x => x.func === 13);

        if (this.state.ativo)
            return (
                <Menu>
                    {acesso?.read && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.setState({
                                        inscricaoId,
                                        drawer_editar_visible: true,
                                        disciplina: false
                                    });
                                }}
                            >
                                Editar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.update && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.ativarInscricao(inscricaoId, this.state.ativo);
                                }}
                            >
                                Inativar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.update && estado?.titulo !== "Anulado" && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.setState({ inscricaoId, drawer_anular_visible: true });
                                }}
                            >
                                Anular
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.delete && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.excluir(inscricaoId);
                                }}
                            >
                                Excluir
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.update && estado?.titulo !== "Transferido" && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.setState({ inscricaoId, drawer_transferir_visible: true });
                                }}
                            >
                                Transferir
                            </Link>
                        </Menu.Item>
                    )}
                    {!tipoDocs?.find(x => x === 6) && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.setState({ inscricaoId, drawer_conclusao_visible: true });
                                }}
                            >
                                Conclusão
                            </Link>
                        </Menu.Item>
                    )}
                    {!tipoDocs?.find(x => x === 5) && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.setState({ inscricaoId, drawer_escolaridade_visible: true });
                                }}
                            >
                                Escolaridade
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.setState({ inscricaoId, drawer_documentos_visible: true });
                            }}
                        >
                            Documentos
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        else
            return (
                <Menu>
                    {acesso?.update && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.ativarInscricao(inscricaoId, this.state.ativo);
                                }}
                            >
                                Ativar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.update && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.setState({ inscricaoId, drawer_retomar_visible: true });
                                }}
                            >
                                Retomar
                            </Link>
                        </Menu.Item>
                    )}
                    {acesso?.delete && (
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.excluir(inscricaoId);
                                }}
                            >
                                Excluir
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.setState({ inscricaoId, drawer_documentos_visible: true });
                            }}
                        >
                            Documentos
                        </Link>
                    </Menu.Item>
                </Menu>
            );
    };

    montarMenuDisciplina = (inscricaoTurmaId, inscricaoId) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.setState({
                                inscricaoId,
                                inscricaoTurmaId,
                                drawer_editar_visible: true,
                                disciplina: true
                            });
                        }}
                    >
                        Editar
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    exportApoio = (cursoId, utilizadorId, tipo) => {
        this.setState(
            {
                visibleExport: true,
                buttonDownload: true
            },
            () => {
                axios({
                    method: "get",
                    url: `/api/concelho-classe/${tipo}`,
                    params: {
                        cursoId,
                        utilizadorId
                    },
                    timeout: 60 * 30 * 1000
                })
                    .then(response => {
                        this.setState({
                            buttonDownload: false,
                            exportFile: response.data
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 400) openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        else if (error.response?.data != "") openNotificationWithIcon("error", "Erro", error.response.data);
                        else openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");

                        this.setState({
                            visibleExport: false,
                            buttonDownload: false
                        });
                    });
            }
        );
    };

    excluir = id => {
        confirm({
            title: "Pretende excluir esta matrícula?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-turma-matriculas/excluir-inscricao",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        var alunos = this.state.alunos.filter(x => x.key !== id);

                        this.setState({
                            alunos
                        });

                        openNotificationWithIcon("success", "Sucesso", "Matrícula excluída.");
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a matrícula.");
                    });
            }
        });
    };

    ativarInscricao = (inscricaoId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar esta matrícula?" : "Pretende ativar esta matrícula?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-turma-matriculas/ativar-matricula",
                    params: {
                        inscricaoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Matrícula inativada!" : "Matrícula ativada!");
                        var alunos = this.state.alunos.filter(x => x.key !== inscricaoId);

                        this.setState({
                            alunos
                        });
                    })
                    .catch(error => {
                        if (error.response.data) openNotificationWithIcon("error", "Erro", error.response.data);
                        else
                            openNotificationWithIcon(
                                "error",
                                "Erro",
                                ativo ? "Não foi possível inativar a matrícula!" : "Não foi possível ativar a matrícula!"
                            );
                    });
            }
        });
    };

    closeDrawer = () => {
        this.setState({
            drawer_inscricao_visible: false
        });
        this.listar();
    };

    closeDrawerTransferencia = () => {
        this.setState({
            drawer_transferencia_visible: false
        });
        this.listar();
    };

    closeDrawerTransferir = () => {
        this.setState({
            drawer_transferir_visible: false
        });
        this.listar();
    };

    confirmDrawerConclusao = inscricaoId => {
        const acesso = this.context.acessos?.find(x => x.func === 13);
        let _alunos = this.state.alunos;
        const alunoIndex = this.state.alunos.findIndex(x => x.id === inscricaoId);
        const aluno = _alunos[alunoIndex];
        _alunos[alunoIndex].tipos = [...aluno.tipos, 6];
        _alunos[alunoIndex].opcoes = (acesso?.read || acesso?.update || acesso?.delete) && (
            <Dropdown
                overlay={this.montarMenu(inscricaoId, aluno.estado, aluno.tipos)}
                placement="bottomLeft"
                onClick={event => event.stopPropagation()}
                overlayStyle={{
                    zIndex: "9"
                }}
            >
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        );

        this.setState({
            drawer_conclusao_visible: false,
            drawer_documentos_visible: false,
            alunos: [..._alunos, aluno]
        });
    };

    confirmDrawerEscolaridade = inscricaoId => {
        const acesso = this.context.acessos?.find(x => x.func === 13);
        let _alunos = this.state.alunos;
        const alunoIndex = this.state.alunos.findIndex(x => x.id === inscricaoId);
        const aluno = _alunos[alunoIndex];
        _alunos[alunoIndex].tipos = [...aluno.tipos, 5];
        _alunos[alunoIndex].opcoes = (acesso?.read || acesso?.update || acesso?.delete) && (
            <Dropdown
                overlay={this.montarMenu(inscricaoId, aluno.estado, aluno.tipos)}
                placement="bottomLeft"
                onClick={event => event.stopPropagation()}
                overlayStyle={{
                    zIndex: "9"
                }}
            >
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        );

        this.setState({
            drawer_escolaridade_visible: false,
            drawer_documentos_visible: false,
            alunos: [..._alunos]
        });
    };

    closeDrawerEditar = () => {
        this.setState({
            drawer_editar_visible: false
        });

        if (!this.state.disciplina) this.listar();
        else {
            const record = this.state.alunos.find(x => x.id === this.state.inscricaoTurmaId);
            record.cursos = [];

            this.listarDisciplinas([], record);
        }
    };

    closeDrawerAnular = () => {
        this.setState({
            drawer_anular_visible: false
        });
        this.listar();
    };

    closeDrawerRetomar = () => {
        this.setState({
            drawer_retomar_visible: false
        });
        this.listar();
    };

    abrirDrawers = tipo => {
        switch (tipo) {
            case 1: //Matricula
                this.setState({
                    drawer_transferir_visible: true
                });
                break;
            case 2: //Transferencia
                this.setState({
                    drawer_transferir_visible: true
                });
                break;
            case 3: //Transferido
                this.setState({
                    drawer_transferir_visible: true
                });
                break;
            case 4: //Transferencia Provisoria
                this.setState({
                    drawer_transferir_visible: true
                });
                break;
            case 5: //Escolaridade
                this.setState({
                    drawer_escolaridade_visible: true
                });
                break;
            case 6: //Conclusão
                this.setState({
                    drawer_conclusao_visible: true
                });
                break;
            case 7: //Anulado
                this.setState({
                    drawer_anular_visible: true
                });
                break;
            case 8: //Remotar
                this.setState({
                    drawer_retomar_visible: true
                });
                break;
        }
    };

    closeDrawerDocumentos = () => {
        this.setState({
            drawer_documentos_visible: false
        });
    };

    onDeleteFile = (inscricaoId, tipo) => {
        const acesso = this.context.acessos?.find(x => x.func === 13);
        let _alunos = this.state.alunos;
        const alunoIndex = this.state.alunos.findIndex(x => x.id === inscricaoId);
        const aluno = _alunos[alunoIndex];
        _alunos[alunoIndex].tipos = [...aluno.tipos.filter(x => x !== tipo)];
        _alunos[alunoIndex].opcoes = (acesso?.read || acesso?.update || acesso?.delete) && (
            <Dropdown
                overlay={this.montarMenu(inscricaoId, aluno.estado, aluno.tipos)}
                placement="bottomLeft"
                onClick={event => event.stopPropagation()}
                overlayStyle={{
                    zIndex: "9"
                }}
            >
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        );

        this.setState({
            alunos: [..._alunos]
        });
    };

    onRowClick = code => {
        localStorage.setItem("link_voltar", this.props.match.url);
        this.setState({
            redirect: true,
            redirectLink: `/gestao-utilizadores-detalhe/${code}`
        });
    };

    render() {
        const {
            //TABELA
            loading_table,
            alunos,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER DETALHE INSCRIÇÃO
            inscricaoId,
            drawer_detalhe_visible,
            //DRAWER FORM INSCRICAO
            drawer_inscricao_visible,
            //DRAWER IMPORTAR
            drawer_transferencia_visible,
            //DRAWER CONNCLUSÃO
            drawer_conclusao_visible,
            //DRAWER TRANSFERIR
            drawer_transferir_visible,
            //DRAWER EDITAR
            drawer_editar_visible,
            disciplina,
            //DRAWER ANULAR
            drawer_anular_visible,
            //DRAWER RETOMAR
            drawer_retomar_visible,
            //DRAWER ESCOLARIDADE
            drawer_escolaridade_visible,
            //DRAWER DUCUMENTOS
            drawer_documentos_visible,
            //EXPORT
            visibleExport,
            buttonDownload,
            exportFile
        } = this.state;

        const columns = [
            {
                title: "Aluno",
                dataIndex: "nome",
                className: "td-curso"
            },
            {
                title: "Matricula",
                dataIndex: "matricula"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-nome"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-opcoes"
            }
        ];

        const expandedRowRender = record => {
            const columns = [
                {
                    title: "Disciplina",
                    dataIndex: "curso",
                    key: "curso",
                    className: "td-curso"
                },
                {
                    title: "Matrícula / Transferência",
                    dataIndex: "matricula",
                    key: "matricula"
                },
                {
                    title: "",
                    dataIndex: "opcoes",
                    className: "td-50"
                }
            ];

            const lista = record.cursos.map(curso => {
                let matricula = "";
                const estado = curso.ultimaEstado;

                if (estado.titulo === "A frequentar" && estado.data)
                    matricula = (
                        <span>
                            {moment(estado.data).format("DD/MM/YYYY")} <span title="Matrícula">(M)</span>
                        </span>
                    );

                if (estado.titulo === "Transferência" && estado.data)
                    matricula = (
                        <span>
                            {moment(estado.data).format("DD/MM/YYYY")}{" "}
                            <span title="Transferência">
                                (T) <Icon type="caret-up" style={{ fontSize: 20 }} />
                            </span>
                        </span>
                    );

                if (estado.titulo === "Transferido" && estado.data)
                    matricula = (
                        <span>
                            {moment(estado.data).format("DD/MM/YYYY")}{" "}
                            <span title="Transferido">
                                (T) <Icon type="caret-down" style={{ fontSize: 20 }} />
                            </span>
                        </span>
                    );

                if (estado.titulo === "Anulado" && estado.data) {
                    if (estado.motivo === "Falecido" && estado.data) {
                        matricula = (
                            <span>
                                {moment(estado.data).format("DD/MM/YYYY")} <span title="Falecido">(F)</span>
                            </span>
                        );
                    }
                    else if (estado.motivo === "Desistente" && estado.data) {
                        matricula = (
                            <span>
                                {moment(estado.data).format("DD/MM/YYYY")}{" "}
                                <span title="Desistente">{this.context.id_entidade === 3 ? "(DF)" : "(D)"}</span>
                            </span>
                        );
                    }
                    else if (estado.motivo === "Remanejado" && estado.data) {
                        matricula = (
                            <span>
                                {moment(estado.data).format("DD/MM/YYYY")} <span title="Remanejado">(R)</span>
                            </span>
                        );
                    }
                    else if (estado.motivo === "Transferido" && estado.data) {
                        matricula = (
                            <span>
                                {moment(estado.data).format("DD/MM/YYYY")} <span title="Transferido">(T)</span>
                            </span>
                        );
                    }
                    else if (estado.motivo === "Reclassificado" && estado.data) {
                        matricula = (
                            <span>
                                {moment(estado.data).format("DD/MM/YYYY")} <span title="Reclassificado">(RC)</span>
                            </span>
                        );
                    }
                    else {
                        matricula = (
                            <span>
                                {moment(estado.data).format("DD/MM/YYYY")} <span title="Anulado">(A)</span>
                            </span>
                        );
                    }
                }

                return {
                    key: curso.id,
                    id: curso.id,
                    curso: (
                        <>
                            <div className="imagem-curso">
                                <img src={curso.imagem ? curso.imagem : noimage} />
                            </div>
                            <div className="nome-curso">
                                {!curso.agrupado ? (
                                    <span className="titulo-curso" title={curso.nome}>
                                        {curso.codigo} - {curso.nome}
                                    </span>
                                ) : (
                                    <span className="titulo-curso" title={curso.nome}>
                                        {curso.nome}
                                    </span>
                                )}
                            </div>
                        </>
                    ),
                    matricula: matricula,
                    opcoes: acesso?.read && (
                        <Dropdown
                            overlay={this.montarMenuDisciplina(record.id, curso.inscricaoId)}
                            placement="bottomLeft"
                            onClick={event => event.stopPropagation()}
                            overlayStyle={{
                                zIndex: "9"
                            }}
                        >
                            <Link to="#" className="botao-icon-configs">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                    )
                };
            });

            return (
                <Table
                    columns={columns}
                    dataSource={lista}
                    loading={{
                        spinning: !lista.length,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                    pagination={false}
                    locale={{ emptyText: "Não existem dados!" }}
                />
            );
        };

        const acesso = this.context.acessos?.find(x => x.func === 13);

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Matrículas" />
                <div className="container container-body">
                    <div className="bloco-cursos bloco-cursos-inscricoes">
                        <div className="bloco-tabela">
                            <div className="bloco-flex" style={{ marginBottom: 20 }}>
                                <div>
                                    {localStorage.getItem("cod_curso") ? (
                                        <h3 className="tabela_titulo">
                                            {localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}
                                        </h3>
                                    ) : (
                                        <h3 className="tabela_titulo">{localStorage.getItem("nome_curso")}</h3>
                                    )}
                                    <span className="escola-nome">{localStorage.getItem("escola_nome")}</span>
                                </div>
                                <div className="filtros">
                                    <Switch
                                        checkedChildren="Ativos"
                                        unCheckedChildren="Inativos"
                                        defaultChecked
                                        checked={this.state.ativo}
                                        onChange={this.handleChangeSwitch}
                                    />
                                </div>
                            </div>
                            <div className="filtros filtros-inscricoes">
                                <div className="bloco-esquerda">
                                    <BotaoProcurar
                                        placeholder="Coloque o nome a pesquisar..."
                                        tem_placeholder
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                </div>
                                <div className="bloco-direita" style={{ display: "flex", gap: 20 }}>
                                    {acesso?.create && (
                                        <button
                                            className="botao-principal"
                                            onClick={() => this.setState({ drawer_inscricao_visible: true })}
                                        >
                                            Matrícula
                                        </button>
                                    )}
                                    {acesso?.create && (
                                        <button
                                            className="botao-secundario"
                                            onClick={() => this.setState({ drawer_transferencia_visible: true })}
                                        >
                                            Transferência
                                        </button>
                                    )}
                                    <Link
                                        to={localStorage.getItem("return_link")}
                                        className="botao-secundario botao-secundario-redondo"
                                        onClick={() => {
                                            localStorage.removeItem("escola_nome");
                                            localStorage.removeItem("escola_id");
                                        }}
                                        style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <Icon type="arrow-left" />
                                    </Link>
                                </div>
                            </div>
                            <Table
                                id="tabela-inscricoes"
                                className="tabela-inscricoes-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={alunos}
                                onExpand={this.listarDisciplinas}
                                expandedRowRender={expandedRowRender}
                                pagination={false}
                                locale={{ emptyText: "Não existem dados!" }}
                                rowClassName={acesso?.read ? "clickeble-row" : ""}
                                onRow={(record, index) => {
                                    return {
                                        onClick: acesso?.read ? () => this.onRowClick(record.code) : () => {}
                                    };
                                }}
                            />
                            <Table
                                id="tabela-inscricoes"
                                className="tabela-inscricoes-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsMobile}
                                dataSource={alunos}
                                pagination={false}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <DrawerDocumentos
                    inscricaoId={inscricaoId}
                    visible={drawer_documentos_visible}
                    abrirDrawers={this.abrirDrawers}
                    onClose={this.closeDrawerDocumentos}
                    onDeleteFile={this.onDeleteFile}
                />
                <DrawerDetalhe
                    visible={drawer_detalhe_visible}
                    onClose={() => this.setState({ drawer_detalhe_visible: false })}
                    onCloseUpdate={() => {
                        this.setState({ drawer_detalhe_visible: false });
                        this.listar();
                    }}
                    inscricaoId={inscricaoId}
                />
                <DrawerInscricao visible={drawer_inscricao_visible} onClose={this.closeDrawer} />
                <DrawerTransferencia visible={drawer_transferencia_visible} onClose={this.closeDrawerTransferencia} />
                <ModalGerarDocumento
                    visibleExportar={visibleExport}
                    ficheiroExportar={exportFile}
                    btnDescarregar={buttonDownload}
                    onClose={() => this.setState({ visibleExport: false })}
                />
                <DrawerTransferir inscricaoId={inscricaoId} visible={drawer_transferir_visible} onClose={this.closeDrawerTransferir} />
                <DrawerEscolaridade
                    inscricaoId={inscricaoId}
                    visible={drawer_escolaridade_visible}
                    onClose={() =>
                        this.setState({
                            drawer_escolaridade_visible: false
                        })
                    }
                    onConfirm={this.confirmDrawerEscolaridade}
                />
                <DrawerConclusao
                    inscricaoId={inscricaoId}
                    visible={drawer_conclusao_visible}
                    onClose={() =>
                        this.setState({
                            drawer_conclusao_visible: false
                        })
                    }
                    onConfirm={this.confirmDrawerConclusao}
                />
                <DrawerEditar
                    ativo={this.state.ativo}
                    inscricaoId={inscricaoId}
                    visible={drawer_editar_visible}
                    acesso={acesso}
                    disciplina={disciplina}
                    onClose={this.closeDrawerEditar}
                />
                <DrawerAnular inscricaoId={inscricaoId} visible={drawer_anular_visible} onClose={this.closeDrawerAnular} />
                <DrawerRetomar inscricaoId={inscricaoId} visible={drawer_retomar_visible} onClose={this.closeDrawerRetomar} />
            </>
        );
    }
}
export default Inscricoes;
