import React, { useState, useEffect } from "react";
import Header from "../User/Header";
import axios from "axios";
import { Collapse } from "antd";
import { GlobalContext } from "../GlobalState";

const FAQ = () => {
    const [activeKeys, setActiveKey] = useState([])
    const [grupos, setGrupos] = useState([])
    const [selecionado, setSelecionado] = useState(0)
    const [infos, setInfos] = useState([])
    const [loading, setLoading] = useState(false)

    const { Panel } = Collapse;

    const listar = () => {
        axios({
            method: "get",
            url: "/api/faq/carregar-opcoes"
        })
            .then(response => {
                setGrupos(response.data);
                if (response.data.length > 0) {
                    setActiveKey([response.data[0].id])

                    if (response.data[0].opcoes.length > 0) {
                        listarInfo(response.data[0].opcoes[0].id)
                        setSelecionado(response.data[0].opcoes[0].id)
                    }
                }
            })
            .catch(() => { });
    }

    const listarInfo = (opcaoId) => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/faq/carregar-infos",
            params: {
                opcaoId
            }
        })
            .then(response => {
                setInfos(response.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        listar();
    }, [])

    return (
        <>
            <div className="suporte">
                <Header titulo="FAQ" />
                <div className="container container-body">
                    <div className="content bloco-grid">
                        <div className="opcao-menu">
                            <Collapse activeKey={activeKeys} onChange={key => setActiveKey(key)} className="opcao-tutoriais">
                                {grupos.map(grupo => (
                                    <Panel header={grupo.titulo} key={grupo.id}>
                                        <ul>
                                            {grupo.opcoes.map(opcao => (
                                                <li key={opcao.id} className={selecionado === opcao.id ? "ativo" : ""} onClick={() => { setSelecionado(opcao.id); listarInfo(opcao.id); }}>
                                                    <span>{opcao.titulo}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </Panel>))}
                            </Collapse>
                        </div>
                        <div className="artigo">
                            {infos.map(info => (
                                <div className="faq">
                                    <span className="titulo">{info.titulo}</span>
                                    <span className="descritivo" dangerouslySetInnerHTML={{ __html: info.texto }}></span>
                                    {info.video && (
                                        <div className="bloco-video">
                                            <iframe src={`https://player.vimeo.com/video/${info.video}`} id="player" frameBorder="0"></iframe>
                                        </div>)}
                                    {info.transcricao && (
                                        <div>
                                            <span>Transcrição:</span>
                                            <span className="transcricao" dangerouslySetInnerHTML={{ __html: info.transcricao }}></span>
                                        </div>)}
                                </div>
                            ))}
                            {!loading && infos.length == 0 &&
                                <span>Não existem dados</span>
                            }
                            {loading &&
                                <div className="loading-data">
                                    <div className="loading" />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FAQ;
