import React, { useState } from "react";
import { Button, Modal, Collapse } from "antd";
import moment from "moment";

import InfoAluno from "../../../../Frequencia/InfoAluno/InfoAluno";

const { Panel } = Collapse;

const ModalFrequenciaDia = ({ visible, participanteId, dataAula, aulasDia, keys, onChange, fecharModal }) => {
    const [existemAlteracoes, setExistemAlteracoes] = useState(false);

    const montarTitulo = aula => {
        return (
            <div className="bloco-titulo-frequencia">
                <span className="titulo-aula">{aula.titulo}</span>
                <span className="data-aula">
                    {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                    <i className="fas fa-circle"></i>
                    {aula.hr_inicio} - {aula.hr_termino}
                </span>
            </div>
        );
    };

    return (
        <Modal
            title={`Aulas lecionadas no dia ${dataAula}`}
            visible={visible}
            maskClosable={false}
            onCancel={() => fecharModal(existemAlteracoes)}
            className="modal-listagem-aulas"
            footer={[
                <Button key="back" onClick={() => fecharModal(existemAlteracoes)}>
                    Voltar
                </Button>
            ]}
        >
            <div className="collapse-info-diario collapse-frequencia">
                <Collapse
                    onChange={onChange}
                    activeKey={keys}
                    defaultActiveKey={[]}
                    className="collapse-campos"
                >
                    {aulasDia.map(aula => (
                        <Panel header={montarTitulo(aula)} key={aula.id}>
                            <InfoAluno aulaId={aula.id} participanteId={participanteId} setExistemAlteracoes={setExistemAlteracoes} />
                        </Panel>
                    ))}
                </Collapse>
            </div>
        </Modal>
    );
}

export default ModalFrequenciaDia;
