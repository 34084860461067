import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification, Form, Icon } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";
import LazyLoad from "react-lazy-load";
import Oops from "../../Geral/Oops.jsx";

class SessaoPresencial extends Component {
    static contextType = GlobalContext;
    state = {
        entidade: "",
        curso: "",
        cursoId: "",
        aulaid: 0,
        nome: "",
        objetivos: "",
        dt_inicio: null,
        hora: null,
        local: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        //LOADING
        loading: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            entidade: this.props.match.params.entidade,
            curso: this.props.match.params.curso,
            cursoId: this.props.match.params.cursoid,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-player/conteudo3",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    dt_inicio: response.data.dt_inicio ? moment(response.data.dt_inicio).format("DD/MM/YYYY") : "",
                    hora: response.data.hora,
                    local: response.data.recurso,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            entidade,
            curso,
            cursoId,
            nome,
            objetivos,
            dt_inicio,
            hora,
            local,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
                <div className="questionario">
                    <div className="player-wrapper">
                        {/*<LazyLoad offsetVertical={200}>*/}
                        {/*    <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                        {/*</LazyLoad>*/}
                        <div className="container">
                            <div className="bloco-presencial">
                                <div className="bloco-info sessao-presencial bloco-player-template">
                                    <div className="bloco-info-tarefa">
                                        <span className="icon-tarefa">
                                            <img src={require("../../images/icons/sessao-presencial.png")} />
                                        </span>
                                        <span className="titulo-tarefa">{nome}</span>
                                    </div>
                                    <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                    <Form className="form-aula" layout="horizontal" style={{ margin: 0 }}>
                                        <div className="info">
                                            <Form.Item>
                                                <span className="desc">
                                                    <Icon type="calendar" /> {dt_inicio}
                                                </span>
                                            </Form.Item>
                                            {hora && (
                                                <Form.Item>
                                                    <span className="desc">
                                                        <Icon type="clock-circle" /> {hora}
                                                    </span>
                                                </Form.Item>
                                            )}
                                            <Form.Item>
                                                <span className="desc">
                                                    <Icon type="home" /> <span dangerouslySetInnerHTML={{ __html: local.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>
                                                </span>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <Controlos
                            backUrl={`/${entidade}/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${cursoId}/curso-modulos`}
                            sameUrl={sameUrl}
                        />
                    </div>
                </div>
            );
    }
}

export default SessaoPresencial;
