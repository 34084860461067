import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Menu, Table, DatePicker, Dropdown, Modal, notification, Form, Drawer, Icon } from "antd";
import Header from "../../User/Header";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import axios from "axios";
import moment from "moment";
//import { CSVLink } from "react-csv";
import DrawerTrabalho from "./_Aux/DrawerTrabalho";
import DrawerCertificacao from "./_Aux/DrawerCertificacao";
import DrawerOutraAvaliacao from "./_Aux/DrawerOutraAvaliacao";
import DrawerAtribuirClassificacao from "./_Aux/DrawerAtribuirClassificacao";
import DrawerAtribuirAvaliacaoCompetencia from "./_Aux/DrawerAtribuirAvaliacaoCompetencia";
import DrawerAtribuirClassificacaoAula from "./_Aux/DrawerAtribuirClassificacaoAula";
import DrawerAtribuirClassificacaoTrabalho from "./_Aux/DrawerAtribuirClassificacaoTrabalho";
import DrawerRelatorio from "./_Aux/DrawerRelatorio";
import { GlobalContext } from "../../GlobalState";
import noimage from "../../images/noimage.png";

import ModalGerarDocumento from "../../Componentes/Modals/GerarDocumento";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class CursosResultados extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        cursoAtivo: false,
        questoes: [],
        utilizadores: [],
        utilizadoresMobile: [],
        //FILTRO
        pesquisa: "",
        dt_inicio: "",
        dt_fim: "",
        //MODAL
        utilizadorid: 0,
        utilizadorFoto: "",
        utilizadorNome: "",
        utilizadorEmail: "",
        utilizadorCodigo: "",
        modal_visible: false,
        nota: "",
        //DRAWER ATRIBUIR CLASSIFICACAO
        atribuir_classificacao_visible: false,
        atribuir_classificacao: "",
        atribuir_observacoes: "",
        atribuir_medalha: false,
        atribuir_notificar: false,
        //DRAWER AVALIAR COMPETENCIA
        aula_atribuir_avaliacao_competencia_visible: false,
        //DRAWER AULA ATRIBUIR CLASSIFICACAO
        aula_atribuir_classificacao_visible: false,
        aula_trabalho_atribuir_classificacao_visible: false,
        aula_atribuir_classificacao: "",
        aula_atribuir_observacoes: "",
        aula_atribuir_medalha: false,
        aula_atribuir_notificar: false,
        aula_atribuir_mesma_nota: false,
        aula_atribuir_elementos: "",
        //MODAL AVALIACAO
        modal__avaliacao_visible: false,
        nota_avaliacao: "",
        //MODAL TRABALHO
        aulaid: 0,
        alunoId: 0,
        modal_trabalho_visible: false,
        nota_trabalho: "",
        //DRAWER TRABALHO
        trabalho_visible: false,
        nome: "",
        tipo: "",
        participantes: [],
        observacoes: "",
        ficheiros: [],
        //DRAWER CERTIFICACAO
        certificacao_visible: false,
        documento: "",
        documento_nome: "",
        //DRAWER OUTRA AVALIACAO
        outraId: 0,
        classificacaoId: 0,
        outra_avaliacao_visible: false,
        nome_avaliacao: "",
        data_avaliacao: null,
        outra_classificacao: "",
        outra_observacoes: "",
        outra_medalha: false,
        outra_notificar: false,
        outra_atribuir_classificacao: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING
        loading_table: false,
        loading_table_competencias: false,
        loading_table_todas_avaliacoes: false,
        //loading_table_avaliacoes: false,
        //loading_table_questionarios: false,
        //loading_table_trabalhos: false,
        loading_table_outras: false,
        loading_table_progressos: false,
        loading_table_lvl3: false,
        //TIPO RESULTADO
        tipo_resultado: "",
        tipo_trabalho: "",
        temp_record: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //REABRIR
        reabrir: false,
        //DRAWER RELATORIO
        relatorioid: 0,
        visibleDrawerRelatorio: false,
        //TEMP
        expanded: false,
        record: "",
        record2: "",
        //DRAWER DETAHE
        visibleDetalhe: false,
        visibleDetalheRelatorio: false,
        detalheRegisto: "",
        detalheClassificacao: "",
        detalheProgresso: "",
        detalheDescarregar: "",
        detalheNotificacao: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoid: cursoId,
            cursoAtivo: localStorage.getItem("curso_ativo") === "true"
        });
        this.listar(cursoId);
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    //PESQUISA NA CAIXA DE PROCURA APOS ENTER
    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value
                },
                () => {
                    this.listar(this.state.cursoid);
                }
            );
        }
    };

    //LIMPA A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => {
                this.listar(this.state.cursoid);
            }
        );
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeDataInicio = date => {
        this.setState({ dt_inicio: date });
    };

    handleChangeDataFim = date => {
        this.setState({ dt_fim: date });
    };

    handleChangeData = date => {
        this.setState({ data_avaliacao: date });
    };

    handlerChangeCheck = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    //CARREGA TODOS OS ALUNOS E SUAS AVALIAÇÕES
    listar = cursoid => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-curso-resultados/listar-resultados-curso",
                    params: {
                        cursoid: cursoid,
                        pesquisa: this.state.pesquisa,
                        dt_inicio: this.state.dt_inicio ? moment(this.state.dt_inicio).format("YYYY/MM/DD") : "",
                        dt_fim: this.state.dt_fim ? moment(this.state.dt_fim).format("YYYY/MM/DD") : ""
                    }
                })
                    .then(response => {
                        let utilizadores = response.data;
                        let utilizadoresMobile = response.data;
                        let _utilizadores = this.state.utilizadores;
                        let _utilizadoresMobile = this.state.utilizadoresMobile;

                        if (_utilizadores.length) {
                            _utilizadores.map((utilizador, index) => {
                                if (utilizador.competencias) {
                                    utilizadores[index] = { ...utilizadores[index], competencias: utilizador.competencias };
                                }
                                if (utilizador.tarefas) {
                                    utilizadores[index] = { ...utilizadores[index], tarefas: utilizador.tarefas };
                                }
                                if (utilizador.outras_avaliacoes) {
                                    utilizadores[index] = { ...utilizadores[index], outras_avaliacoes: utilizador.outras_avaliacoes };
                                }
                                if (utilizador.estatisticas) {
                                    utilizadores[index] = { ...utilizadores[index], estatisticas: utilizador.estatisticas };
                                }
                            })
                        }

                        if (_utilizadoresMobile.length) {
                            _utilizadoresMobile.map((utilizador, index) => {
                                if (utilizador.competencias) {
                                    utilizadoresMobile[index] = { ...utilizadoresMobile[index], competencias: utilizador.competencias };
                                }
                                if (utilizador.tarefas) {
                                    utilizadoresMobile[index] = { ...utilizadoresMobile[index], tarefas: utilizador.tarefas };
                                }
                                if (utilizador.outras_avaliacoes) {
                                    utilizadoresMobile[index] = { ...utilizadoresMobile[index], outras_avaliacoes: utilizador.outras_avaliacoes };
                                }
                                if (utilizador.estatisticas) {
                                    utilizadoresMobile[index] = { ...utilizadoresMobile[index], estatisticas: utilizador.estatisticas };
                                }
                            })
                        }

                        this.setState({
                            utilizadores,
                            utilizadoresMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    procurar = () => {
        this.listar(this.state.cursoid);
    };

    // MONTA O MENU DE OPÇÕES POR CADA UTILIZADOR
    montarMenuUtilizador = utilizador => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.openDrawerAtribuirClassificacao(utilizador)}>
                        Classificação
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleDrawerRelatorio: true, utilizadorid: utilizador.id, relatorioid: 0 })}>
                        Relatório de Progresso
                    </Link>
                </Menu.Item>
                {/*<Menu.Item>
                    <Link to="#" onClick={() => this.openDrawerOutra(utilizador.id)}>
                        Adicionar outra avaliação
                    </Link>
                </Menu.Item>*/}
                <Menu.Item>
                    <Link to="#" onClick={() => this.openDrawerCertificacao(utilizador)}>
                        Certificado
                    </Link>
                </Menu.Item>
                {localStorage.getItem("habilitar_competencias") === "true" ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarCompetenciasUtilizador(utilizador.id, this.state.cursoid)}>
                            Competências
                    </Link>
                    </Menu.Item> : null}
            </Menu>
        );
    };

    montarMenuUtilizadorMobile = utilizador => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalhe(utilizador)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.openDrawerAtribuirClassificacao(utilizador)}>
                        Classificação
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleDrawerRelatorio: true, utilizadorid: utilizador.id, relatorioid: 0 })}>
                        Relatório de Progresso
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.openDrawerCertificacao(utilizador)}>
                        Certificado
                    </Link>
                </Menu.Item>
                {localStorage.getItem("habilitar_competencias") === "true" ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarCompetenciasUtilizador(utilizador.id, this.state.cursoid)}>
                            Competências
                    </Link>
                    </Menu.Item> : null}
            </Menu>
        );
    };

    abrirDetalhe = utilizador => {
        const detalheRegisto = utilizador.dt_criado ? moment(utilizador.dt_criado).format("DD/MM/YYYY") : "";
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{utilizador.classificacao}{utilizador.classificacao_observacoes ? "*" : ""}</span> {utilizador.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;
        const detalheProgresso = utilizador.progresso;
        const detalheDescarregar = utilizador.documento ? (<a href={utilizador.documento} className="botao-icon-download" download={utilizador.documento_nome} className="icon-download" title="Descarregar certificado">
            <Icon type="download" />
        </a>) : "";

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao,
            detalheProgresso,
            detalheDescarregar
        })
    }

    abrirDetalheCompetencias = avaliacao => {
        const detalheRegisto = avaliacao.modulo_Aulas_Utilizadores?.dt_concluido
            ? moment(avaliacao.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
            : "";
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{avaliacao.modulo_Aulas_Utilizadores?.nota_final}{avaliacao.modulo_Aulas_Utilizadores?.nota_final_notas ? "*" : ""}</span> {avaliacao.modulo_Aulas_Utilizadores?.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao
        })
    }

    abrirDetalheTarefa = tarefa => {
        const detalheRegisto = tarefa.modulo_Aulas_Utilizadores?.dt_concluido
            ? moment(tarefa.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
            : tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "" : "";
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{tarefa.modulo_Aulas_Utilizadores?.nota_final}{tarefa.modulo_Aulas_Utilizadores?.nota_final_notas ? "*" : ""}</span> {tarefa.modulo_Aulas_Utilizadores?.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao
        })
    }

    abrirDetalheOutraAvaliacao = avaliacao => {
        const detalheRegisto = avaliacao.data ? moment(avaliacao.data).format("DD/MM/YYYY") : "";
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{avaliacao.classificacao}{avaliacao.classificacao_notas ? "*" : ""}</span> {avaliacao.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao
        })
    }

    //DRAWER ATRIBUIR CLASSIFICACAO
    openDrawerAtribuirClassificacao = utilizador => {
        this.props.form.resetFields();
        this.setState({
            utilizadorid: utilizador.id,
            atribuir_classificacao_visible: true,
            atribuir_classificacao: utilizador.classificacao,
            atribuir_observacoes: utilizador.classificacao_observacoes,
            atribuir_medalha: utilizador.medalha,
            atribuir_notificar: false
        });
    };

    onCloseAtribuirClassificacaoAtualizar = () => {
        this.setState({
            atribuir_classificacao_visible: false
        });

        this.listar(this.state.cursoid);
    };

    openDrawerAulaAtribuirAvaliacao = (utilizadorid, aulaid) => {
        this.props.form.resetFields();
        this.setState({
            utilizadorid: utilizadorid,
            aulaid: aulaid,
            aula_atribuir_avaliacao_competencia_visible: true
        });
    };

    //DRAWER ATRIBUIR CLASSIFICACAO
    openDrawerAulaAtribuirClassificacao = (utilizadorid, aulaid, classificacao, observacoes, medalha) => {
        this.props.form.resetFields();
        this.setState({
            utilizadorid: utilizadorid,
            aulaid: aulaid,
            aula_atribuir_classificacao_visible: true,
            aula_atribuir_classificacao: classificacao,
            aula_atribuir_observacoes: observacoes,
            aula_atribuir_medalha: medalha ? medalha : false,
            aula_atribuir_notificar: false
        });
    };

    //DRAWER ATRIBUIR CLASSIFICACAO
    openDrawerAulaTrabalhoAtribuirClassificacao = (utilizadorid, aulaid, classificacao, observacoes, medalha, elementos) => {
        this.props.form.resetFields();
        this.setState({
            utilizadorid: utilizadorid,
            aulaid: aulaid,
            aula_trabalho_atribuir_classificacao_visible: true,
            aula_atribuir_classificacao: classificacao,
            aula_atribuir_observacoes: observacoes,
            aula_atribuir_medalha: medalha ? medalha : false,
            aula_atribuir_mesma_nota: false,
            aula_atribuir_elementos: elementos
        });
    };

    onCloseAulaAtribuirClassificacaoAtualizar = () => {
        this.setState({
            aula_atribuir_classificacao_visible: false,
            aula_trabalho_atribuir_classificacao_visible: false
        });

        var utilizadorIndex = this.state.utilizadores.findIndex(x => x.id === this.state.utilizadorid);

        if (this.state.tipo_resultado === "AVALIACAO") {
            var aulaIndex = this.state.utilizadores[utilizadorIndex].tarefas.findIndex(x => x.id === this.state.aulaid);

            if (aulaIndex > -1) {
                var _utilizadores = this.state.utilizadores;

                if (this.state.reabrir) {
                    _utilizadores[utilizadorIndex].tarefas[aulaIndex].data = "";
                    _utilizadores[utilizadorIndex].tarefas[aulaIndex].dt_concluido = "";
                }
                else {
                    _utilizadores[utilizadorIndex].tarefas[aulaIndex].classificacao = this.state.aula_atribuir_classificacao;
                    _utilizadores[utilizadorIndex].tarefas[aulaIndex].classificacao_observacoes = this.state.aula_atribuir_observacoes;
                    _utilizadores[utilizadorIndex].tarefas[aulaIndex].medalha = this.state.aula_atribuir_medalha;
                }

                _utilizadores[utilizadorIndex].tarefas[aulaIndex].opcoes = (
                    <Dropdown
                        overlay={this.montarMenuAvaliacao(
                            _utilizadores[utilizadorIndex].id,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].id,
                            this.state.aula_atribuir_classificacao,
                            this.state.aula_atribuir_observacoes,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].dt_concluido,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].medalha
                        )}
                        placement="bottomLeft"
                    >
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                );

                this.setState({
                    utilizadores: _utilizadores,
                    disponilbilizar: false
                });
            }
        }
        else if (this.state.tipo_resultado === "QUESTIONARIO") {
            let aulaIndex = this.state.utilizadores[utilizadorIndex].tarefas.findIndex(x => x.id === this.state.aulaid);

            if (aulaIndex > -1) {
                let _utilizadores = this.state.utilizadores;
                _utilizadores[utilizadorIndex].tarefas[aulaIndex].classificacao = this.state.aula_atribuir_classificacao;
                _utilizadores[utilizadorIndex].tarefas[aulaIndex].classificacao_observacoes = this.state.aula_atribuir_observacoes;
                _utilizadores[utilizadorIndex].tarefas[aulaIndex].medalha = this.state.aula_atribuir_medalha;
                _utilizadores[utilizadorIndex].tarefas[aulaIndex].opcoes = (
                    <Dropdown
                        overlay={this.montarMenuQuestionario(
                            _utilizadores[utilizadorIndex].id,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].id,
                            this.state.aula_atribuir_classificacao,
                            this.state.aula_atribuir_observacoes,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].dt_concluido,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].medalha
                        )}
                        placement="bottomLeft"
                    >
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                );

                this.setState({
                    utilizadores: _utilizadores
                });
            }
        }
        else {
            let aulaIndex = this.state.utilizadores[utilizadorIndex].tarefas.findIndex(x => x.id === this.state.aulaid);

            if (aulaIndex > -1) {
                let _utilizadores = this.state.utilizadores;
                _utilizadores[utilizadorIndex].tarefas[aulaIndex].classificacao = this.state.aula_atribuir_classificacao;
                _utilizadores[utilizadorIndex].tarefas[aulaIndex].classificacao_observacoes = this.state.aula_atribuir_observacoes;
                _utilizadores[utilizadorIndex].tarefas[aulaIndex].medalha = this.state.aula_atribuir_medalha;
                _utilizadores[utilizadorIndex].tarefas[aulaIndex].opcoes = (
                    <Dropdown
                        overlay={this.montarMenuTrabalho(
                            _utilizadores[utilizadorIndex].id,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].id,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].tipo_trabalho,
                            this.state.aula_atribuir_elementos,
                            this.state.aula_atribuir_classificacao,
                            this.state.aula_atribuir_observacoes,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].dt_concluido,
                            _utilizadores[utilizadorIndex].tarefas[aulaIndex].medalha
                        )}
                        placement="bottomLeft"
                    >
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                );

                this.setState({
                    utilizadores: _utilizadores
                });
            }
        }
    };

    onCloseAulaAtribuirClassificacaoCompetencias = registo => {
        this.setState({
            aula_atribuir_avaliacao_competencia_visible: false
        });

        var utilizadorIndex = this.state.utilizadores.findIndex(x => x.id === this.state.utilizadorid);

        var aulaIndex = this.state.utilizadores[utilizadorIndex].competencias.findIndex(x => x.id === this.state.aulaid);

        if (aulaIndex > -1) {
            var _utilizadores = this.state.utilizadores;

            _utilizadores[utilizadorIndex].competencias[aulaIndex].classificacao = registo.nota_final;
            _utilizadores[utilizadorIndex].competencias[aulaIndex].medalha = registo.medalha;

            this.setState({
                utilizadores: _utilizadores
            });

            if (this.state.expanded)
                this.listarCompetencias(this.state.record2, this.state.record);
        }
    };

    openDrawerOutra = cursoId => {
        this.setState({
            outraId: 0,
            classificacaoId: 0,
            outra_avaliacao_visible: true,
            nome_avaliacao: "",
            data_avaliacao: null,
            outra_classificacao: "",
            outra_medalha: false,
            cursoid: cursoId
        });
    };

    openDrawerCertificacao = utilizador => {
        this.setState({
            certificacao_visible: true,
            utilizadorid: utilizador.id,
            utilizadorFoto: utilizador.foto,
            utilizadorNome: utilizador.nome_completo,
            utilizadorEmail: utilizador.emailnotif,
            utilizadorCodigo: utilizador.codigo,
            documento: utilizador.documento,
            documento_nome: utilizador.documento_nome
        });
    };

    montarMenuCompetencia = (utilizadorid, aulaid) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.openDrawerAulaAtribuirAvaliacao(utilizadorid, aulaid)}>
                        Classificação
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    // MONTA O MENU DE OPÇÕES POR CADA AVALIACAO
    montarMenuAvaliacaoCompetencias = (utilizadorid, aulaid, dt_conclusao, iniciado) => {
        if (dt_conclusao || iniciado) {
            return (
                <Menu>
                    {this.state.cursoAtivo ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => this.openDrawerAulaAtribuirAvaliacao(utilizadorid, aulaid)}
                            >
                                Classificação
                        </Link>
                        </Menu.Item> : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarAvaliacaoCSV(utilizadorid, aulaid)}>
                            Exportar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarAvaliacaoTodosCSV(aulaid)}>
                            Exportar Todos
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.reabrirAvaliacao(aulaid, utilizadorid)}>
                            Reabrir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => this.openDrawerAulaAtribuirAvaliacao(utilizadorid, aulaid)}
                        >
                            Classificação
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    montarMenuAvaliacaoCompetenciasMobile = (utilizadorid, tarefa, dt_conclusao, iniciado) => {
        if (dt_conclusao || iniciado) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalheCompetencias(tarefa)}>
                            Detalhe
                    </Link>
                    </Menu.Item>
                    {this.state.cursoAtivo ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => this.openDrawerAulaAtribuirAvaliacao(utilizadorid, tarefa.id)}
                            >
                                Classificação
                        </Link>
                        </Menu.Item> : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarAvaliacaoCSV(utilizadorid, tarefa.id)}>
                            Exportar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarAvaliacaoTodosCSV(tarefa.id)}>
                            Exportar Todos
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.reabrirAvaliacao(tarefa.id, utilizadorid)}>
                            Reabrir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(tarefa.id)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalheCompetencias(tarefa)}>
                            Detalhe
                    </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => this.openDrawerAulaAtribuirAvaliacao(utilizadorid, tarefa.id)}
                        >
                            Classificação
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(tarefa.id)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    //// MONTA O MENU DE OPÇÕES POR CADA QUESTIONARIO
    //montarMenuQuestionarioCompetencias = (utilizadorid, aulaid, classificacao, observacoes, dt_conclusao, medalha) => {
    //    if (dt_conclusao) {
    //        return (
    //            <Menu>
    //                {this.state.cursoAtivo ?
    //                    <Menu.Item>
    //                        <Link
    //                            to="#"
    //                            onClick={() => this.openDrawerAulaAtribuirAvaliacao(utilizadorid, aulaid)}
    //                        >
    //                            Classificação
    //                    </Link>
    //                    </Menu.Item> : null}
    //                <Menu.Item>
    //                    <Link to="#" onClick={() => this.exportarQuestionarioCSV(utilizadorid, aulaid)}>
    //                        Exportar
    //                    </Link>
    //                </Menu.Item>
    //                <Menu.Item>
    //                    <Link to="#" onClick={() => this.exportarQuestionarioTodosCSV(aulaid)}>
    //                        Exportar Todos
    //                    </Link>
    //                </Menu.Item>
    //                <Menu.Item>
    //                    <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
    //                        Pauta
    //                    </Link>
    //                </Menu.Item>
    //            </Menu>
    //        );
    //    } else {
    //        return (
    //            <Menu>
    //                <Menu.Item>
    //                    <Link
    //                        to="#"
    //                        onClick={() => this.openDrawerAulaAtribuirAvaliacao(utilizadorid, aulaid)}
    //                    >
    //                        Classificação
    //                    </Link>
    //                </Menu.Item>
    //                <Menu.Item>
    //                    <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
    //                        Pauta
    //                    </Link>
    //                </Menu.Item>
    //            </Menu>
    //        );
    //    }
    //};

    //// MONTA O MENU DE OPÇÕES POR CADA UTILIZADOR
    //montarMenuTrabalhoCompetencias = (utilizadorid, aulaid, tipoTrabalho, elementos, classificacao, observacoes, dt_conclusao, medalha) => {
    //    if (dt_conclusao) {
    //        return (
    //            <Menu>
    //                {this.state.cursoAtivo ?
    //                    <Menu.Item>
    //                        <Link
    //                            to="#"
    //                            onClick={() => this.openDrawerAulaAtribuirAvaliacao(utilizadorid, aulaid)}
    //                        >
    //                            Classificação
    //                    </Link>
    //                    </Menu.Item> : null}
    //                <Menu.Item>
    //                    <Link to="#" onClick={() => this.exportarTrabalho(utilizadorid, aulaid)}>
    //                        Exportar
    //                    </Link>
    //                </Menu.Item>
    //                <Menu.Item>
    //                    <Link to="#" onClick={() => this.exportarTrabalhoTodos(aulaid)}>
    //                        Exportar Todos
    //                    </Link>
    //                </Menu.Item>
    //                <Menu.Item>
    //                    <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
    //                        Pauta
    //                    </Link>
    //                </Menu.Item>
    //            </Menu>
    //        );
    //    } else {
    //        return (
    //            <Menu>
    //                <Menu.Item>
    //                    <Link
    //                        to="#"
    //                        onClick={() => this.openDrawerAulaAtribuirAvaliacao(utilizadorid, aulaid)}
    //                    >
    //                        Classificação
    //                    </Link>
    //                </Menu.Item>
    //                <Menu.Item>
    //                    <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
    //                        Pauta
    //                    </Link>
    //                </Menu.Item>
    //            </Menu>
    //        );
    //    }
    //};

    // MONTA O MENU DE OPÇÕES POR CADA AVALIACAO
    montarMenuAvaliacao = (utilizadorid, aulaid, classificacao, observacoes, dt_conclusao, medalha, iniciado) => {
        if (dt_conclusao || iniciado) {
            return (
                <Menu>
                    {this.state.cursoAtivo ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => {
                                    this.openDrawerAulaAtribuirClassificacao(utilizadorid, aulaid, classificacao, observacoes, medalha);
                                    this.setState({
                                        tipo_resultado: "AVALIACAO"
                                    });
                                }}
                            >
                                Classificação
                        </Link>
                        </Menu.Item> : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarAvaliacaoCSV(utilizadorid, aulaid)}>
                            Exportar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarAvaliacaoTodosCSV(aulaid)}>
                            Exportar Todos
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.reabrirAvaliacao(aulaid, utilizadorid)}>
                            Reabrir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                this.openDrawerAulaAtribuirClassificacao(utilizadorid, aulaid, classificacao, observacoes, medalha);
                                this.setState({
                                    tipo_resultado: "AVALIACAO"
                                });
                            }}
                        >
                            Classificação
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    montarMenuAvaliacaoMobile = (utilizadorid, tarefa, classificacao, observacoes, dt_conclusao, medalha, iniciado) => {
        if (dt_conclusao || iniciado) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                            Detalhe
                    </Link>
                    </Menu.Item>
                    {this.state.cursoAtivo ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => {
                                    this.openDrawerAulaAtribuirClassificacao(utilizadorid, tarefa.id, classificacao, observacoes, medalha);
                                    this.setState({
                                        tipo_resultado: "AVALIACAO"
                                    });
                                }}
                            >
                                Classificação
                        </Link>
                        </Menu.Item> : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarAvaliacaoCSV(utilizadorid, tarefa.id)}>
                            Exportar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarAvaliacaoTodosCSV(tarefa.id)}>
                            Exportar Todos
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.reabrirAvaliacao(tarefa.id, utilizadorid)}>
                            Reabrir
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(tarefa.id)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                            Detalhe
                    </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                this.openDrawerAulaAtribuirClassificacao(utilizadorid, tarefa.id, classificacao, observacoes, medalha);
                                this.setState({
                                    tipo_resultado: "AVALIACAO"
                                });
                            }}
                        >
                            Classificação
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(tarefa.id)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    // MONTA O MENU DE OPÇÕES POR CADA QUESTIONARIO
    montarMenuQuestionario = (utilizadorid, aulaid, classificacao, observacoes, dt_conclusao, medalha) => {
        if (dt_conclusao) {
            return (
                <Menu>
                    {this.state.cursoAtivo ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => {
                                    this.openDrawerAulaAtribuirClassificacao(utilizadorid, aulaid, classificacao, observacoes, medalha);
                                    this.setState({
                                        tipo_resultado: "QUESTIONARIO"
                                    });
                                }}
                            >
                                Classificação
                        </Link>
                        </Menu.Item> : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarQuestionarioCSV(utilizadorid, aulaid)}>
                            Exportar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarQuestionarioTodosCSV(aulaid)}>
                            Exportar Todos
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                this.openDrawerAulaAtribuirClassificacao(utilizadorid, aulaid, classificacao, observacoes, medalha);
                                this.setState({
                                    tipo_resultado: "QUESTIONARIO"
                                });
                            }}
                        >
                            Classificação
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    montarMenuQuestionarioMobile = (utilizadorid, tarefa, classificacao, observacoes, dt_conclusao, medalha) => {
        if (dt_conclusao) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    {this.state.cursoAtivo ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => {
                                    this.openDrawerAulaAtribuirClassificacao(utilizadorid, tarefa.id, classificacao, observacoes, medalha);
                                    this.setState({
                                        tipo_resultado: "QUESTIONARIO"
                                    });
                                }}
                            >
                                Classificação
                        </Link>
                        </Menu.Item> : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarQuestionarioCSV(utilizadorid, tarefa.id)}>
                            Exportar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarQuestionarioTodosCSV(tarefa.id)}>
                            Exportar Todos
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(tarefa.id)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                this.openDrawerAulaAtribuirClassificacao(utilizadorid, tarefa.id, classificacao, observacoes, medalha);
                                this.setState({
                                    tipo_resultado: "QUESTIONARIO"
                                });
                            }}
                        >
                            Classificação
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(tarefa.id)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    // MONTA O MENU DE OPÇÕES POR CADA UTILIZADOR
    montarMenuTrabalho = (utilizadorid, aulaid, tipoTrabalho, elementos, classificacao, observacoes, dt_conclusao, medalha) => {
        if (dt_conclusao) {
            return (
                <Menu>
                    {this.state.cursoAtivo ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => {
                                    this.openDrawerAulaTrabalhoAtribuirClassificacao(utilizadorid, aulaid, classificacao, observacoes, medalha, elementos);
                                    this.setState({
                                        tipo_resultado: "TRABALHO",
                                        tipo_trabalho: tipoTrabalho
                                    });
                                }}
                            >
                                Classificação
                        </Link>
                        </Menu.Item> : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarTrabalho(utilizadorid, aulaid)}>
                            Exportar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarTrabalhoTodos(aulaid)}>
                            Exportar Todos
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                this.openDrawerAulaTrabalhoAtribuirClassificacao(utilizadorid, aulaid, classificacao, observacoes, medalha, elementos);
                                this.setState({
                                    tipo_resultado: "TRABALHO",
                                    tipo_trabalho: tipoTrabalho
                                });
                            }}
                        >
                            Classificação
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(aulaid)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    montarMenuTrabalhoMobile = (utilizadorid, tarefa, tipoTrabalho, elementos, classificacao, observacoes, dt_conclusao, medalha) => {
        if (dt_conclusao) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                            Detalhe
                    </Link>
                    </Menu.Item>
                    {this.state.cursoAtivo ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => {
                                    this.openDrawerAulaTrabalhoAtribuirClassificacao(utilizadorid, tarefa.id, classificacao, observacoes, medalha, elementos);
                                    this.setState({
                                        tipo_resultado: "TRABALHO",
                                        tipo_trabalho: tipoTrabalho
                                    });
                                }}
                            >
                                Classificação
                        </Link>
                        </Menu.Item> : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarTrabalho(utilizadorid, tarefa.id)}>
                            Exportar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarTrabalhoTodos(tarefa.id)}>
                            Exportar Todos
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(tarefa.id)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        } else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                            Detalhe
                    </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                this.openDrawerAulaTrabalhoAtribuirClassificacao(utilizadorid, tarefa.id, classificacao, observacoes, medalha, elementos);
                                this.setState({
                                    tipo_resultado: "TRABALHO",
                                    tipo_trabalho: tipoTrabalho
                                });
                            }}
                        >
                            Classificação
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarPautaTarefa(tarefa.id)}>
                            Pauta
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    // MONTA O MENU DE OPÇÕES POR CADA UTILIZADOR
    montarMenuOutraAvaliacao = (outraid, classificacaoId, record) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarOutra(outraid, record.id)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarOutraClassificacao(outraid, classificacaoId, record.id)}>
                        Classificação
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarPautaOutra(outraid)}>
                        Pauta
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuOutraAvaliacaoMobile = (avaliacao, classificacaoId, record) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheOutraAvaliacao(avaliacao)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarOutra(avaliacao.id, record.id)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarOutraClassificacao(avaliacao.id, classificacaoId, record.id)}>
                        Classificação
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarPautaOutra(avaliacao.id)}>
                        Pauta
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    // MONTA O MENU DE OPÇÕES POR CADA UTILIZADOR
    montarMenuProgresso = (relatorioid, cursoId, utilizador) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleDrawerRelatorio: true, relatorioid })}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.notificarParticipanteRelatorio(relatorioid, utilizador.id, cursoId)}>
                        Notificar
                    </Link>
                </Menu.Item>
                {(this.context.responsaveis_entidade && utilizador.responsavel_nome && utilizador.responsavel_email) ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.notificarResponsavelRelatorio(relatorioid, utilizador.id, cursoId)}>
                            Notificar Responsável
                    </Link>
                    </Menu.Item> : null}
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluirRelatorioProgresso(relatorioid, utilizador.id)}>
                        Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuProgressoMobile = (relatorio, cursoId, utilizador) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleDrawerRelatorio: true, relatorioid: relatorio.id })}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.notificarParticipanteRelatorio(relatorio.id, utilizador.id, cursoId)}>
                        Notificar
                    </Link>
                </Menu.Item>
                {(this.context.responsaveis_entidade && utilizador.responsavel_nome && utilizador.responsavel_email) ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.notificarResponsavelRelatorio(relatorio.id, utilizador.id, cursoId)}>
                            Notificar Responsável
                    </Link>
                    </Menu.Item> : null}
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluirRelatorioProgresso(relatorio.id, utilizador.id)}>
                        Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    excluirRelatorioProgresso = (relatorioId, utilizadorId) => {
        confirm({
            title: "Pretende excluir este relatório?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-curso-resultados/excluir-relatorio-progresso",
                    params: {
                        relatorioId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Relatório de porgresso excluido!");
                        var index = this.state.utilizadores.findIndex(utilizador => utilizador.id === utilizadorId);
                        var _utilizadores = this.state.utilizadores;
                        var _relatorios_progresso = this.state.utilizadores[index].relatorios_progresso;
                        _utilizadores[index].relatorios_progresso = _relatorios_progresso.filter(x => x.id !== relatorioId);

                        this.setState({
                            utilizadores: _utilizadores
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o relatório de progresso");
                    });
            }
        });
    };

    carregarOutra = (outraId, utilizadorid) => {
        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/carregar-outra-avaliacao",
            params: {
                outraId,
                utilizadorid
            }
        })
            .then(response => {
                this.setState({
                    outraId,
                    utilizadorid,
                    outra_avaliacao_visible: true,
                    nome_avaliacao: response.data.nome,
                    outra_atribuir_classificacao: false
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar o trabalho!");
            });
    };

    carregarOutraClassificacao = (outraId, classificacaoId, utilizadorid) => {
        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/carregar-outra-avaliacao-classificacao",
            params: {
                classificacaoId,
                utilizadorid
            }
        })
            .then(response => {
                this.setState({
                    outraId,
                    classificacaoId,
                    utilizadorid,
                    outra_avaliacao_visible: true,
                    nome_avaliacao: response.data.nome,
                    data_avaliacao: response.data.data ? moment(response.data.data) : null,
                    outra_classificacao: response.data.classificacao,
                    outra_observacoes: response.data.classificacao_notas,
                    outra_medalha: response.data.medalha,
                    outra_atribuir_classificacao: true
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar o trabalho!");
            });
    };

    //CARREGA OS DADOS NA TABELA DE NIVEL 1
    carregaDados = lista => {
        var utilizadores = [];

        lista.map((utilizador, index) => {
            utilizadores.push({
                key: index,
                id: utilizador.id,
                nome: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="imagem-curso">
                            <img src={utilizador.foto ? utilizador.foto : noimage} />
                        </div>
                        <div className="nome-colaborador">
                            <span className="titulo-curso">{utilizador.nome_completo}</span>
                            <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.8)" }}>{utilizador.nr_aluno}</span>
                        </div>
                    </div>
                ),
                registo: utilizador.dt_criado ? moment(utilizador.dt_criado).format("DD/MM/YYYY") : "",
                classificacao: utilizador.classificacao,
                classificacao_observacoes: utilizador.classificacao_observacoes,
                medalha: utilizador.medalha,
                progresso: utilizador.progresso,
                descarregar: utilizador.documento ? (
                    <a href={utilizador.documento} className="botao-icon-download" download={utilizador.documento_nome} className="icon-download" title="Descarregar certificado">
                        <Icon type="download" />
                    </a>
                ) : (
                        ""
                    ),
                opcoes: this.state.cursoAtivo ? (
                    <Dropdown overlay={this.montarMenuUtilizador(utilizador)} placement="bottomLeft">
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                ) : null
            });
        });
        return utilizadores;
    };

    carregaDadosMobile = lista => {
        var utilizadores = [];

        lista.map((utilizador, index) => {
            utilizadores.push({
                key: index,
                id: utilizador.id,
                nome: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="imagem-curso">
                            <img src={utilizador.foto ? utilizador.foto : noimage} />
                        </div>
                        <div className="nome-colaborador">
                            <span className="titulo-curso">{utilizador.nome_completo}</span>
                            <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.8)" }}>{utilizador.nr_aluno}</span>
                        </div>
                    </div>
                ),
                registo: utilizador.dt_criado ? moment(utilizador.dt_criado).format("DD/MM/YYYY") : "",
                classificacao: utilizador.classificacao,
                classificacao_observacoes: utilizador.classificacao_observacoes,
                medalha: utilizador.medalha,
                progresso: utilizador.progresso,
                descarregar: utilizador.documento ? (
                    <a href={utilizador.documento} className="botao-icon-download" download={utilizador.documento_nome} className="icon-download" title="Descarregar certificado">
                        <Icon type="download" />
                    </a>
                ) : (
                        ""
                    ),
                opcoes: this.state.cursoAtivo ? (
                    <Dropdown overlay={this.montarMenuUtilizadorMobile(utilizador)} placement="bottomLeft">
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                ) : null
            });
        });
        return utilizadores;
    };

    onCloseDrawerOutraAtualizar = () => {
        var _utilizadores = this.state.utilizadores
        _utilizadores.forEach((utilizador, index) => {
            if (utilizador.outras_avaliacoes) {
                var _utilizador = { ...utilizador, key: index };
                this.listarOutrasAvaliacoes(_utilizador)
            }
        })
    };

    atualizarDocumento = (documento, documento_nome) => {
        var index = this.state.utilizadores.findIndex(utilizador => utilizador.id === this.state.utilizadorid);
        var _utilizadores = this.state.utilizadores;
        _utilizadores[index].documento = documento;
        _utilizadores[index].documento_nome = documento_nome;

        this.setState({
            utilizadores: _utilizadores
        });
    };

    //LISTA RESULTADOS DE TODAS AS TABELAS DO ALUNO SELECIONADO
    listarResultados = (expanded, record) => {
        if (expanded) {
            this.setState({
                record
            })
            if (localStorage.getItem("habilitar_competencias") === "true")
                this.listarAvaliacaoCompetencias(record);
            this.listarTodasAvaliacoes(record);
            //this.listarAvaliacoes(record);
            //this.listarQuestionarios(record);
            //this.listarTrabalhos(record);
            this.listarOutrasAvaliacoes(record);
            this.listarRelatoriosProgresso(record);
            this.listarEstatisticas(record);
        }
    };

    listarResultadosMobile = (expanded, record) => {
        if (expanded) {
            this.setState({
                record
            })
            if (localStorage.getItem("habilitar_competencias") === "true")
                this.listarAvaliacaoCompetenciasMobile(record);
            this.listarTodasAvaliacoesMobile(record);
            this.listarOutrasAvaliacoesMobile(record);
            this.listarRelatoriosProgressoMobile(record);
            this.listarEstatisticasMobile(record);
        }
    };

    //LISTA RESULTADOS DE TODAS AS TABELAS DO ALUNO SELECIONADO
    listarResultadosLvl2 = (expanded, record2, record) => {
        if (expanded) {
            this.listarCompetencias(record2, record);
        }

        this.setState({
            expanded,
            record,
            record2
        })
    };

    //LISTA TODAS AS AVALIACOES PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarAvaliacaoCompetencias = record => {
        this.setState({
            loading_table_competencias: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-avaliacoes-competencias",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadores;
                var competencias = [];

                response.data.map((avaliacao, index) => {
                    if (!avaliacao.modulo_Aulas_Utilizadores) {
                        competencias.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(avaliacao.tipo_recurso, avaliacao.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: this.state.cursoAtivo ?
                                this.montarMenuSimplesCompetencias(record, avaliacao) : null
                        });
                    }
                    else {
                        competencias.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(avaliacao.tipo_recurso, avaliacao.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: avaliacao.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(avaliacao.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : "",
                            classificacao: avaliacao.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: avaliacao.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: avaliacao.modulo_Aulas_Utilizadores.medalha,
                            opcoes: (this.state.cursoAtivo || (!this.state.cursoAtivo && avaliacao.modulo_Aulas_Utilizadores.dt_concluido)) ?
                                this.montarMenuCompletoCompetencias(record, avaliacao) : null
                        });
                    }
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], competencias };
                this.setState({
                    utilizadores: _utilizadores,
                    loading_table_competencias: false
                });
            })
            .catch(() => { });
    };

    listarAvaliacaoCompetenciasMobile = record => {
        this.setState({
            loading_table_competencias: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-avaliacoes-competencias",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadoresMobile;
                var competencias = [];

                response.data.map((avaliacao, index) => {
                    if (!avaliacao.modulo_Aulas_Utilizadores) {
                        competencias.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(avaliacao.tipo_recurso, avaliacao.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: this.state.cursoAtivo ?
                                this.montarMenuSimplesCompetenciasMobile(record, avaliacao) : null
                        });
                    }
                    else {
                        competencias.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(avaliacao.tipo_recurso, avaliacao.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: avaliacao.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(avaliacao.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : "",
                            classificacao: avaliacao.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: avaliacao.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: avaliacao.modulo_Aulas_Utilizadores.medalha,
                            opcoes: (this.state.cursoAtivo || (!this.state.cursoAtivo && avaliacao.modulo_Aulas_Utilizadores.dt_concluido)) ?
                                this.montarMenuCompletoCompetenciasMobile(record, avaliacao) : null
                        });
                    }
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], competencias };
                this.setState({
                    utilizadoresMobile: _utilizadores,
                    loading_table_competencias: false
                });
            })
            .catch(() => { });
    };

    montarMenuSimplesCompetencias = (record, tarefa) => {
        const tipo = tarefa.tipo_recurso;
        if (tipo === 6) {
            return <Dropdown overlay={this.montarMenuAvaliacaoCompetencias(record.id, tarefa.id)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>;
        }
        else if (tipo === 5) {
            return <Dropdown overlay={this.montarMenuQuestionarioCompetencias(record.id, tarefa.id)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
        else {
            return <Dropdown overlay={this.montarMenuTrabalhoCompetencias(record.id, tarefa.id, tarefa.tipo_trabalho, tarefa.elementos)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
    }

    montarMenuSimplesCompetenciasMobile = (record, tarefa) => {
        const tipo = tarefa.tipo_recurso;
        if (tipo === 6) {
            return <Dropdown overlay={this.montarMenuAvaliacaoCompetenciasMobile(record.id, tarefa)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>;
        }
        else if (tipo === 5) {
            return <Dropdown overlay={this.montarMenuQuestionarioCompetenciasMobile(record.id, tarefa.id)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
        else {
            return <Dropdown overlay={this.montarMenuTrabalhoCompetenciasMobile(record.id, tarefa.id, tarefa.tipo_trabalho, tarefa.elementos)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
    }

    montarMenuCompletoCompetencias = (record, tarefa) => {
        return <Dropdown
            overlay={this.montarMenuAvaliacaoCompetencias(
                record.id,
                tarefa.id,
                tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                tarefa.modulo_Aulas_Utilizadores.iniciado
            )}
            placement="bottomLeft"
        >
            <Link to="#" className="botao-icon-configs">
                <i className="fas fa-cog" />
            </Link>
        </Dropdown>;
    }

    montarMenuCompletoCompetenciasMobile = (record, tarefa) => {
        return <Dropdown
            overlay={this.montarMenuAvaliacaoCompetenciasMobile(
                record.id,
                tarefa,
                tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                tarefa.modulo_Aulas_Utilizadores.iniciado
            )}
            placement="bottomLeft"
        >
            <Link to="#" className="botao-icon-configs">
                <i className="fas fa-cog" />
            </Link>
        </Dropdown>;
    }

    monterCoresBNCC = gerais => {
        var _gerais = gerais.map((geral, index) => {
            switch (geral.split(".")[0]) {
                case "1":
                    return <span key={index} className="bcnn-competencia competencia-1">{geral}</span>
                    break;
                case "2":
                    return <span key={index} className="bcnn-competencia competencia-2">{geral}</span>
                    break;
                case "3":
                    return <span key={index} className="bcnn-competencia competencia-3">{geral}</span>
                    break;
                case "4":
                    return <span key={index} className="bcnn-competencia competencia-4">{geral}</span>
                    break;
                case "5":
                    return <span key={index} className="bcnn-competencia competencia-5">{geral}</span>
                    break;
                case "6":
                    return <span key={index} className="bcnn-competencia competencia-6">{geral}</span>
                    break;
                case "7":
                    return <span key={index} className="bcnn-competencia competencia-7">{geral}</span>
                    break;
                case "8":
                    return <span key={index} className="bcnn-competencia competencia-8">{geral}</span>
                    break;
                case "9":
                    return <span key={index} className="bcnn-competencia competencia-9">{geral}</span>
                    break;
                case "10":
                    return <span key={index} className="bcnn-competencia competencia-10">{geral}</span>
                    break;
            }
        })

        return _gerais;
    }

    //LISTA TODAS AS AVALIACOES PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarCompetencias = (record2, record) => {
        this.setState({
            loading_table_lvl3: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-competencias",
            params: {
                aulaid: record2.id,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadores;
                var competencias = [];

                response.data.competencias.map((competencia, index) => {
                    var tipos = JSON.parse(competencia.tipos)
                    var lista = this.monterCoresBNCC(JSON.parse(competencia.gerais));

                    competencias.push({
                        key: index,
                        id: competencia.id,
                        competencia: (<div className="compentencia">
                            <div className="info-compentencia">
                                <span className="titulo-competencia">{competencia.nome}</span>
                                <div className="lista-tipos-competencias">
                                    {tipos.map((tipo, index) => (
                                        <div key={index} className="tipo-competencia">
                                            <span className={"icon-competencia " + (this.montarIcon(tipo, competencia.id, response.data.avaliacao))}></span>
                                            <span className="tipo">{tipo}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="lista-tipos-competencias-gerais">
                                    <span className="titulo-competencias-gerais lista-competencias-gerais"><strong>(BNCC </strong> {lista.map(geral => (geral))}<strong>)</strong></span>
                                </div>
                            </div>
                        </div>)
                    });
                });


                var _utilizador = _utilizadores[record.key];
                _utilizador.competencias[record2.key] = { ..._utilizador.competencias[record2.key], competencias }
                _utilizadores[record.key] = _utilizador;

                this.setState({
                    utilizadores: _utilizadores,
                    loading_table_lvl3: false
                });
            })
            .catch(() => { });
    };

    //montarIconCompetencia = (tipoId, tipoTrabalho) => {
    //    switch (tipoId) {
    //        case 5:
    //            return (<div className="imagem-curso" title="Questionário">
    //                <img src={require("../../images/icons/questionario.png")} />
    //            </div>)
    //            break;
    //        case 6:
    //            return (<div className="imagem-curso" title="Avaliação">
    //                <img src={require("../../images/icons/avaliacao.png")} />
    //            </div>)
    //            break;
    //        case 8:
    //            switch (tipoTrabalho) {
    //                case "":
    //                    return (<div className="imagem-curso" title="Trabalho Individual">
    //                        <img src={require("../../images/icons/trabalho_individual.png")} />
    //                    </div>)
    //                    break;
    //                case "":
    //                    return (<div className="imagem-curso" title="Trabalho de Grupo">
    //                        <img src={require("../../images/icons/trabalho_grupo.png")} />
    //                    </div>)
    //                    break;
    //                default:
    //                    return (<div className="imagem-curso" title="Trabalho Colaborativo">
    //                        <img src={require("../../images/icons/trabalho_colaborativo.png")} />
    //                    </div>)
    //                    break;
    //            }
    //            break;
    //        case 14:
    //            return (<div className="imagem-curso" title="Questionario Progresso">
    //                <img src={require("../../images/icons/progresso.png")} />
    //            </div>)
    //            break;
    //        case 15:
    //            return (<div className="imagem-curso" title="Questionário Dirigido">
    //                <img src={require("../../images/icons/competencia.png")} />
    //            </div>)
    //            break;
    //    }
    //}

    montarIcon = (tipo, competenciaId, avaliacao) => {
        var valor = "";
        if (avaliacao)
            if (avaliacao.tipos)
                if (avaliacao.tipos.length) {
                    var tipos = JSON.parse(avaliacao.tipos)
                    var _competencia = tipos.find(x => x.competenciaId === competenciaId)
                    if (_competencia) {
                        var _tipo = _competencia.tipos.find(x => x.tipo === tipo)
                        if (_tipo)
                            valor = _tipo.valor;
                    }
                }

        if (valor === "Competência satisfatória")
            return "icon-satisfatoria"
        else if (valor === "Competência não satisfatória")
            return "icon-nao-satisfatoria"
        else if (valor === "Competência parcialmente satisfatória")
            return "icon-parcialmente-satisfatoria"
        else
            return "icon-sem-avaliacao"
    }

    montarIconTarefa = (tipo, tipoTrabalho) => {
        if (tipo === 6) {
            return <div className="imagem-curso" title="Avaliação">
                <img src={require("../../images/icons/avaliacao.png")} />
            </div>
        }
        else if (tipo === 5) {
            return <div className="imagem-curso" title="Questionário">
                <img src={require("../../images/icons/questionario.png")} />
            </div>
        }
        else if (tipo === 8) {
            if (tipoTrabalho === "INDIVIDUAL")
                return <div className="imagem-curso" title="Trabalho Individual">
                    <img src={require("../../images/icons/trabalho_individual.png")} />
                </div>
            else if (tipoTrabalho === "GRUPO")
                return <div className="imagem-curso" title="Trabalho de Grupo">
                    <img src={require("../../images/icons/trabalho_grupo.png")} />
                </div>
            else
                return <div className="imagem-curso" title="Trabalho Colaborativo">
                    <img src={require("../../images/icons/trabalho_colaborativo.png")} />
                </div>
        }
        else {
            return <div className="imagem-curso" title="Questionário de Progresso">
                <img src={require("../../images/icons/progresso.png")} />
            </div>
        }
    }

    montarMenuSimplesTarefa = (record, tarefa) => {
        const tipo = tarefa.tipo_recurso;
        if (tipo === 6) {
            return <Dropdown overlay={this.montarMenuAvaliacao(record.id, tarefa.id)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>;
        }
        else if (tipo === 5) {
            return <Dropdown overlay={this.montarMenuQuestionario(record.id, tarefa.id)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
        else {
            return <Dropdown overlay={this.montarMenuTrabalho(record.id, tarefa.id, tarefa.tipo_trabalho, tarefa.elementos)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
    }

    montarMenuSimplesTarefaMobile = (record, tarefa) => {
        const tipo = tarefa.tipo_recurso;
        if (tipo === 6) {
            return <Dropdown overlay={this.montarMenuAvaliacaoMobile(record.id, tarefa)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>;
        }
        else if (tipo === 5) {
            return <Dropdown overlay={this.montarMenuQuestionarioMobile(record.id, tarefa)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
        else {
            return <Dropdown overlay={this.montarMenuTrabalhoMobile(record.id, tarefa, tarefa.tipo_trabalho, tarefa.elementos)} placement="bottomLeft">
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
    }

    montarMenuCompletoTarefa = (record, tarefa) => {
        const tipo = tarefa.tipo_recurso;
        if (tipo === 6) {
            return <Dropdown
                overlay={this.montarMenuAvaliacao(
                    record.id,
                    tarefa.id,
                    tarefa.modulo_Aulas_Utilizadores.nota_final,
                    tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                    tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                    tarefa.modulo_Aulas_Utilizadores.medalha,
                    tarefa.modulo_Aulas_Utilizadores.iniciado
                )}
                placement="bottomLeft"
            >
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>;
        }
        else if (tipo === 5) {
            return <Dropdown
                overlay={this.montarMenuQuestionario(
                    record.id,
                    tarefa.id,
                    tarefa.modulo_Aulas_Utilizadores.nota_final,
                    tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                    tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                    tarefa.modulo_Aulas_Utilizadores.medalha
                )}
                placement="bottomLeft"
            >
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
        else {
            return <Dropdown
                overlay={this.montarMenuTrabalho(
                    record.id,
                    tarefa.id,
                    tarefa.tipo_trabalho,
                    tarefa.elementos,
                    tarefa.modulo_Aulas_Utilizadores.nota_final,
                    tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                    tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                    tarefa.modulo_Aulas_Utilizadores.medalha
                )}
                placement="bottomLeft"
            >
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
    }

    montarMenuCompletoTarefaMobile = (record, tarefa) => {
        const tipo = tarefa.tipo_recurso;
        if (tipo === 6) {
            return <Dropdown
                overlay={this.montarMenuAvaliacaoMobile(
                    record.id,
                    tarefa,
                    tarefa.modulo_Aulas_Utilizadores.nota_final,
                    tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                    tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                    tarefa.modulo_Aulas_Utilizadores.medalha,
                    tarefa.modulo_Aulas_Utilizadores.iniciado
                )}
                placement="bottomLeft"
            >
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>;
        }
        else if (tipo === 5) {
            return <Dropdown
                overlay={this.montarMenuQuestionarioMobile(
                    record.id,
                    tarefa,
                    tarefa.modulo_Aulas_Utilizadores.nota_final,
                    tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                    tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                    tarefa.modulo_Aulas_Utilizadores.medalha
                )}
                placement="bottomLeft"
            >
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
        else {
            return <Dropdown
                overlay={this.montarMenuTrabalhoMobile(
                    record.id,
                    tarefa,
                    tarefa.tipo_trabalho,
                    tarefa.elementos,
                    tarefa.modulo_Aulas_Utilizadores.nota_final,
                    tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                    tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                    tarefa.modulo_Aulas_Utilizadores.medalha
                )}
                placement="bottomLeft"
            >
                <Link to="#" className="botao-icon-configs">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
    }

    //LISTA TODAS AS AVALIACOES PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarTodasAvaliacoes = record => {
        this.setState({
            loading_table_avaliacoes: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-todas-avaliacoes",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadores;
                var tarefas = [];

                response.data.map((tarefa, index) => {
                    if (!tarefa.modulo_Aulas_Utilizadores) {
                        tarefas.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "" : "",
                            opcoes: tarefa.tipo_recurso !== 14 && this.state.cursoAtivo ?
                                this.montarMenuSimplesTarefa(record, tarefa) : null
                        });
                    }
                    else {
                        tarefas.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: tarefa.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(tarefa.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "" : "",
                            classificacao: tarefa.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: tarefa.modulo_Aulas_Utilizadores.medalha,
                            dt_concluido: tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                            opcoes: tarefa.tipo_recurso !== 14 && (this.state.cursoAtivo || (!this.state.cursoAtivo && tarefa.modulo_Aulas_Utilizadores.dt_concluido)) ?
                                this.montarMenuCompletoTarefa(record, tarefa) : null
                        });
                    }
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], tarefas };
                this.setState({
                    utilizadores: _utilizadores,
                    loading_table_avaliacoes: false
                });
            })
            .catch(() => { });
    };

    listarTodasAvaliacoesMobile = record => {
        this.setState({
            loading_table_avaliacoes: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-todas-avaliacoes",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadoresMobile;
                var tarefas = [];

                response.data.map((tarefa, index) => {
                    if (!tarefa.modulo_Aulas_Utilizadores) {
                        tarefas.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "" : "",
                            opcoes: tarefa.tipo_recurso !== 14 && this.state.cursoAtivo ?
                                this.montarMenuSimplesTarefaMobile(record, tarefa) : null
                        });
                    }
                    else {
                        tarefas.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: tarefa.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(tarefa.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "" : "",
                            classificacao: tarefa.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: tarefa.modulo_Aulas_Utilizadores.medalha,
                            dt_concluido: tarefa.modulo_Aulas_Utilizadores.dt_concluido,
                            opcoes: tarefa.tipo_recurso !== 14 && (this.state.cursoAtivo || (!this.state.cursoAtivo && tarefa.modulo_Aulas_Utilizadores.dt_concluido)) ?
                                this.montarMenuCompletoTarefaMobile(record, tarefa) : null
                        });
                    }
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], tarefas };
                this.setState({
                    utilizadoresMobile: _utilizadores,
                    loading_table_avaliacoes: false
                });
            })
            .catch(() => { });
    };

    //LISTA TODAS AS AVALIACOES PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarAvaliacoes = record => {
        this.setState({
            loading_table_avaliacoes: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-avaliacoes",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadores;
                var avaliacoes = [];

                response.data.map((avaliacao, index) => {
                    if (!avaliacao.modulo_Aulas_Utilizadores) {
                        avaliacoes.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Avaliação">
                                        <img src={require("../../images/icons/avaliacao.png")} />
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: this.state.cursoAtivo ? (
                                <Dropdown overlay={this.montarMenuAvaliacao(record.id, avaliacao.id)} placement="bottomLeft">
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            ) : null
                        });
                    }
                    else {
                        avaliacoes.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Avaliação">
                                        <img src={require("../../images/icons/avaliacao.png")} />
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: avaliacao.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(avaliacao.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : "",
                            classificacao: avaliacao.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: avaliacao.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: avaliacao.modulo_Aulas_Utilizadores.medalha,
                            dt_concluido: avaliacao.modulo_Aulas_Utilizadores.dt_concluido,
                            opcoes: this.state.cursoAtivo || (!this.state.cursoAtivo && avaliacao.modulo_Aulas_Utilizadores.dt_concluido) ? (
                                <Dropdown
                                    overlay={this.montarMenuAvaliacao(
                                        record.id,
                                        avaliacao.id,
                                        avaliacao.modulo_Aulas_Utilizadores.nota_final,
                                        avaliacao.modulo_Aulas_Utilizadores.nota_final_notas,
                                        avaliacao.modulo_Aulas_Utilizadores.dt_concluido,
                                        avaliacao.modulo_Aulas_Utilizadores.medalha,
                                        avaliacao.modulo_Aulas_Utilizadores.iniciado
                                    )}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            ) : null
                        });
                    }
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], avaliacoes };
                this.setState({
                    utilizadores: _utilizadores,
                    loading_table_avaliacoes: false
                });
            })
            .catch(() => { });
    };

    //LISTA TODAS OS QUESTIONARIOS PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarQuestionarios = record => {
        this.setState({
            loading_table_questionarios: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-questionarios",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadores;
                var questionarios = [];

                response.data.map((questionario, index) => {
                    if (!questionario.modulo_Aulas_Utilizadores) {
                        questionarios.push({
                            key: index,
                            id: questionario.id,
                            questionario: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {questionario.tipo_recurso === 5 ?
                                        <div className="imagem-curso" title="Questionário">
                                            <img src={require("../../images/icons/questionario.png")} />
                                        </div>
                                        :
                                        <div className="imagem-curso" title="Questionário de Progresso">
                                            <img src={require("../../images/icons/progresso.png")} />
                                        </div>
                                    }
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{questionario.nome}</span>
                                        <p className="atividade">{questionario.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: questionario.tipo_recurso === 5 && this.state.cursoAtivo ? (
                                <Dropdown overlay={this.montarMenuQuestionario(record.id, questionario.id)} placement="bottomLeft">
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            ) : null
                        });
                    } else {
                        questionarios.push({
                            key: index,
                            id: questionario.id,
                            questionario: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Questionário">
                                        {questionario.tipo_recurso === 5 ?
                                            <img src={require("../../images/icons/questionario.png")} />
                                            :
                                            <img src={require("../../images/icons/progresso.png")} />
                                        }
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{questionario.nome}</span>
                                        <p className="atividade">Atividade</p>
                                    </div>
                                </div>
                            ),
                            data: questionario.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(questionario.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : "",
                            classificacao: questionario.tipo_recurso === 5 ? questionario.modulo_Aulas_Utilizadores.nota_final : questionario.modulo_Aulas_Utilizadores.dt_concluido ? "Realizado com sucesso" : "Realizado com respostas erradas",
                            classificacao_observacoes: questionario.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: questionario.modulo_Aulas_Utilizadores.medalha,
                            dt_concluido: questionario.modulo_Aulas_Utilizadores.dt_concluido,
                            opcoes: questionario.tipo_recurso === 5 && (this.state.cursoAtivo || (!this.state.cursoAtivo && questionario.modulo_Aulas_Utilizadores.dt_concluido)) && (
                                <Dropdown
                                    overlay={this.montarMenuQuestionario(
                                        record.id,
                                        questionario.id,
                                        questionario.modulo_Aulas_Utilizadores.nota_final,
                                        questionario.modulo_Aulas_Utilizadores.nota_final_notas,
                                        questionario.modulo_Aulas_Utilizadores.dt_concluido,
                                        questionario.modulo_Aulas_Utilizadores.medalha
                                    )}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            )
                        });
                    }
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], questionarios };
                this.setState({
                    utilizadores: _utilizadores,
                    loading_table_questionarios: false
                });
            })
            .catch(() => { });
    };

    montarIconTrabalho = tipo => {
        if (tipo === "INDIVIDUAL")
            return <div className="imagem-curso" title="Trabalho Individual">
                <img src={require("../../images/icons/trabalho_individual.png")} />
            </div>
        else if (tipo === "GRUPO")
            return <div className="imagem-curso" title="Trabalho de Grupo">
                <img src={require("../../images/icons/trabalho_grupo.png")} />
            </div>
        else
            return <div className="imagem-curso" title="Trabalho Colaborativo">
                <img src={require("../../images/icons/trabalho_colaborativo.png")} />
            </div>
    }

    //LISTA TODAS OS TRABALHOS PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarTrabalhos = record => {
        this.setState({
            loading_table_trabalhos: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-trabalhos",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadores;
                var trabalhos = [];

                response.data.map((trabalho, index) => {
                    if (!trabalho.modulo_Aulas_Utilizadores) {
                        trabalhos.push({
                            key: index,
                            id: trabalho.id,
                            trabalho: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTrabalho(trabalho.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{trabalho.nome}</span>
                                        <p className="atividade">{trabalho.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: moment().isAfter(moment(trabalho.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "",
                            tipo_trabalho: trabalho.tipo_trabalho,
                            opcoes: this.state.cursoAtivo ? (
                                <Dropdown overlay={this.montarMenuTrabalho(record.id, trabalho.id, trabalho.tipo_trabalho, trabalho.elementos)} placement="bottomLeft">
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            ) : null
                        });
                    } else {
                        trabalhos.push({
                            key: index,
                            id: trabalho.id,
                            trabalho: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTrabalho(trabalho.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{trabalho.nome}</span>
                                        <p className="atividade">{trabalho.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: trabalho.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(trabalho.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : moment().isAfter(moment(trabalho.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "",
                            classificacao: trabalho.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: trabalho.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: trabalho.modulo_Aulas_Utilizadores.medalha,
                            dt_concluido: trabalho.modulo_Aulas_Utilizadores.dt_concluido,
                            tipo_trabalho: trabalho.tipo_trabalho,
                            opcoes: this.state.cursoAtivo || (!this.state.cursoAtivo && trabalho.modulo_Aulas_Utilizadores.dt_concluido) ? (
                                <Dropdown
                                    overlay={this.montarMenuTrabalho(
                                        record.id,
                                        trabalho.id,
                                        trabalho.tipo_trabalho,
                                        trabalho.elementos,
                                        trabalho.modulo_Aulas_Utilizadores.nota_final,
                                        trabalho.modulo_Aulas_Utilizadores.nota_final_notas,
                                        trabalho.modulo_Aulas_Utilizadores.dt_concluido,
                                        trabalho.modulo_Aulas_Utilizadores.medalha
                                    )}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            ) : null
                        });
                    }
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], trabalhos };
                this.setState({
                    utilizadores: _utilizadores,
                    loading_table_trabalhos: false
                });
            })
            .catch(() => { });
    };

    //LISTA TODAS AS OUTRAS AVALIACOES PARA O CURSO EM QUESTAO DO ALUNO SELECIONADO
    listarOutrasAvaliacoes = record => {
        this.setState({
            loading_table_outras: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-outras-avaliacoes",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadores;
                var outras_avaliacoes = [];

                response.data.map((avaliacao, index) => {
                    outras_avaliacoes.push({
                        key: index,
                        id: avaliacao.id,
                        nome: avaliacao.nome,
                        data: avaliacao.data ? moment(avaliacao.data).format("DD/MM/YYYY") : "",
                        classificacao: avaliacao.classificacao,
                        observacoes: avaliacao.classificacao_notas,
                        medalha: avaliacao.medalha,
                        opcoes: this.state.cursoAtivo ? (
                            <Dropdown overlay={this.montarMenuOutraAvaliacao(avaliacao.id, avaliacao.idClassificacao, record)} placement="bottomLeft">
                                <Link to="#" className="botao-icon-configs">
                                    <i className="fas fa-cog" />
                                </Link>
                            </Dropdown>
                        ) : null
                    });
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], outras_avaliacoes };

                this.setState({
                    utilizadores: _utilizadores,
                    loading_table_outras: false
                });
            })
            .catch(() => { });
    };

    listarOutrasAvaliacoesMobile = record => {
        this.setState({
            loading_table_outras: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-outras-avaliacoes",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadoresMobile;
                var outras_avaliacoes = [];

                response.data.map((avaliacao, index) => {
                    outras_avaliacoes.push({
                        key: index,
                        id: avaliacao.id,
                        nome: avaliacao.nome,
                        data: avaliacao.data ? moment(avaliacao.data).format("DD/MM/YYYY") : "",
                        classificacao: avaliacao.classificacao,
                        observacoes: avaliacao.classificacao_notas,
                        medalha: avaliacao.medalha,
                        opcoes: this.state.cursoAtivo ? (
                            <Dropdown overlay={this.montarMenuOutraAvaliacaoMobile(avaliacao.id, avaliacao.idClassificacao, record)} placement="bottomLeft">
                                <Link to="#" className="botao-icon-configs">
                                    <i className="fas fa-cog" />
                                </Link>
                            </Dropdown>
                        ) : null
                    });
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], outras_avaliacoes };

                this.setState({
                    utilizadoresMobile: _utilizadores,
                    loading_table_outras: false
                });
            })
            .catch(() => { });
    };

    //LISTA TODAS AS OUTRAS AVALIACOES PARA O CURSO EM QUESTAO DO ALUNO SELECIONADO
    listarRelatoriosProgresso = record => {
        this.setState({
            loading_table_relatorios: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-relatorios-progresso",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadores;
                var relatorios_progresso = [];

                response.data.map((relatorio, index) => {
                    relatorios_progresso.push({
                        key: index,
                        id: relatorio.id,
                        nome: (<div style={{ display: "flex", alignItems: "center" }}>
                            <div className="imagem-curso" title="Relatório de Progresso">
                                <img src={require("../../images/icons/relatorio.png")} />
                            </div>
                            <div className="nome-colaborador">
                                <span className="titulo-curso" style={{ marginTop: 0 }}>{relatorio.nome}</span>
                            </div>
                        </div>),
                        data: moment(relatorio.data).format("DD/MM/YYYY"),
                        partilhado: this.montarTipoPartilha(relatorio.tipo_partilha),
                        notificacao: relatorio.responsavel_notificado && (<Link href="#" className="icon-notificacao" title="Responsável notificado">
                            <Icon type="mail" />
                        </Link>),
                        opcoes: <Dropdown overlay={this.montarMenuProgresso(relatorio.id, this.state.cursoid, record)} placement="bottomLeft">
                            <Link to="#" className="botao-icon-configs">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                    });
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], relatorios_progresso };
                this.setState({
                    utilizadores: _utilizadores,
                    loading_table_relatorios: false
                });
            })
            .catch(() => { });
    };

    listarRelatoriosProgressoMobile = record => {
        this.setState({
            loading_table_relatorios: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-relatorios-progresso",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadoresMobile;
                var relatorios_progresso = [];

                response.data.map((relatorio, index) => {
                    relatorios_progresso.push({
                        key: index,
                        id: relatorio.id,
                        nome: (<div style={{ display: "flex", alignItems: "center" }}>
                            <div className="imagem-curso" title="Relatório de Progresso">
                                <img src={require("../../images/icons/relatorio.png")} />
                            </div>
                            <div className="nome-colaborador">
                                <span className="titulo-curso" style={{ marginTop: 0 }}>{relatorio.nome}</span>
                            </div>
                        </div>),
                        data: moment(relatorio.data).format("DD/MM/YYYY"),
                        partilhado: this.montarTipoPartilha(relatorio.tipo_partilha),
                        notificacao: relatorio.responsavel_notificado && (<Link href="#" className="icon-notificacao" title="Responsável notificado">
                            <Icon type="mail" />
                        </Link>),
                        opcoes: <Dropdown overlay={this.montarMenuProgressoMobile(relatorio.id, this.state.cursoid, record)} placement="bottomLeft">
                            <Link to="#" className="botao-icon-configs">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                    });
                });

                _utilizadores[record.key] = { ..._utilizadores[record.key], relatorios_progresso };
                this.setState({
                    utilizadoresMobile: _utilizadores,
                    loading_table_relatorios: false
                });
            })
            .catch(() => { });
    };

    montarTipoPartilha = tipo => {
        if (tipo === "PESSOAL")
            return (<i className="fas fa-user icon-tipo-partilha pessoal" title="Pessoal"></i>);
        else if (tipo === "INSTRUTORES")
            return (<i className="fas fa-user-friends icon-tipo-partilha partilhado-instrutor" title="Partilhado com os professores" ></i>);
        else
            return (<i className="fas fa-users icon-tipo-partilha partilhado" title="Partilhado com o aluno"></i>)
    };

    notificarParticipanteRelatorio = (relatorioId, utilizadorId, cursoId) => {
        confirm({
            title: "Pretende notificar?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "post",
                    url: "/api/gestao-curso-resultados/notificar-participante-relatorios-progresso",
                    params: {
                        relatorioId,
                        cursoId,
                        utilizadorid: utilizadorId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Notificado!")
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível notificar!")
                    });
            }
        });
    }

    notificarResponsavelRelatorio = (relatorioid, utilizadorId, cursoId) => {
        confirm({
            title: "Pretende notificar responsável?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "post",
                    url: "/api/gestao-curso-resultados/notificar-responsavel-relatorios-progresso",
                    params: {
                        cursoid: cursoId,
                        utilizadorid: utilizadorId,
                        relatorioid
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Responsável notificado!")
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível notificar responsável!")
                    });
            }
        });
    }

    //LISTA TODAS AS OUTRAS AVALIACOES PARA O CURSO EM QUESTAO DO ALUNO SELECIONADO
    listarEstatisticas = record => {
        //this.setState({
        //    loading_table_outras: true
        //})

        axios({
            method: "get",
            url: "/api/gestao-estatistica/curso-aluno",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadores;
                var estatisticas = {
                    tarefas_visitadas: response.data.tarefas_visitadas,
                    tempos_tarefas: response.data.tempos_tarefas,
                    conteudos_concluidos: response.data.conteudos_concluidos,
                    doc_fichs: response.data.doc_fichs,
                    interacoes_anotacoes: response.data.interacoes_anotacoes,
                    mensagens_enviadas: response.data.mensagens_enviadas,
                    interacoes_forum: response.data.interacoes_forum,
                    ratingscursos: response.data.ratingscursos,
                    ratingsaulas: response.data.ratingsaulas,
                    avaliacoescursos: response.data.avaliacoescursos,
                    avaliacoesaulas: response.data.avaliacoesaulas,
                    medalhacurso: response.data.medalhacurso,
                    medalhastarefas: response.data.medalhastarefas,
                };

                _utilizadores[record.key] = { ..._utilizadores[record.key], estatisticas };

                this.setState({
                    utilizadores: _utilizadores
                    //loading_table_outras: false
                });
            })
            .catch(() => { });
    };

    listarEstatisticasMobile = record => {
        axios({
            method: "get",
            url: "/api/gestao-estatistica/curso-aluno",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: record.id
            }
        })
            .then(response => {
                var _utilizadores = this.state.utilizadoresMobile;
                var estatisticas = {
                    tarefas_visitadas: response.data.tarefas_visitadas,
                    tempos_tarefas: response.data.tempos_tarefas,
                    conteudos_concluidos: response.data.conteudos_concluidos,
                    doc_fichs: response.data.doc_fichs,
                    interacoes_anotacoes: response.data.interacoes_anotacoes,
                    mensagens_enviadas: response.data.mensagens_enviadas,
                    interacoes_forum: response.data.interacoes_forum,
                    ratingscursos: response.data.ratingscursos,
                    ratingsaulas: response.data.ratingsaulas,
                    avaliacoescursos: response.data.avaliacoescursos,
                    avaliacoesaulas: response.data.avaliacoesaulas,
                    medalhacurso: response.data.medalhacurso,
                    medalhastarefas: response.data.medalhastarefas,
                };

                _utilizadores[record.key] = { ..._utilizadores[record.key], estatisticas };

                this.setState({
                    utilizadoresMobile: _utilizadores
                });
            })
            .catch(() => { });
    };

    exportarAvaliacaoCSV = (utilizadorid, aulaid) => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/avaliacao-csv",
                    params: {
                        utilizadorId: utilizadorid,
                        moduloaulaId: aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarAvaliacaoTodosCSV = aulaid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/avaliacao-todos",
                    params: {
                        moduloaulaId: aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarQuestionarioCSV = (utilizadorid, aulaid) => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/questionario-csv",
                    params: {
                        utilizadorId: utilizadorid,
                        moduloaulaId: aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarQuestionarioTodosCSV = aulaid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/questionario-todos",
                    params: {
                        moduloaulaId: aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarTrabalho = (utilizadorid, aulaid) => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/trabalho-zip",
                    params: {
                        utilizadorId: utilizadorid,
                        moduloaulaId: aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarTrabalhoTodos = aulaid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/trabalho-todos",
                    params: {
                        moduloaulaId: aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarEstatisticasCSV = utilizadorid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/curso-aluno",
                    params: {
                        utilizadorid: utilizadorid,
                        cursoid: this.state.cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarEstatisticasDET = utilizadorid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/curso-aluno-det",
                    params: {
                        utilizadorid: utilizadorid,
                        cursoid: this.state.cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    reabrirAvaliacao = (aulaid, utilizadorid) => {
        confirm({
            title: "Pretende reabrir a avaliação?",
            content: "Para que o aluno possa realizar a avaliação, a mesma não poderá estar fechada",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-curso-resultados/reabrir-avaliacao",
                    params: {
                        aulaid,
                        utilizadorid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Avaliação reaberta!");
                        this.setState({
                            aulaid,
                            utilizadorid,
                            reabrir: true,
                            tipo_resultado: "AVALIACAO"
                        }, () => {
                            this.onCloseAulaAtribuirClassificacaoAtualizar();
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível reabrir a avaliação");
                    });
            }
        });
    }

    montarMenuExportar = cursoId => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarPauta(cursoId)}>
                        Pauta
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarSumario(cursoId)}>
                        Sumários
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarQuestionarios(cursoId)}>
                        Questionários
                    </Link>
                </Menu.Item>
                {(localStorage.getItem("habilitar_gamificacao") === "true" && this.context.gamificacao_entidade) ?
                    <Menu.Item>
                        {localStorage.getItem("nome_curso_pai") ?
                            <Link to={`/${localStorage.getItem("nome_curso_pai").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-ranking`}>
                                Ranking
                        </Link>
                            :
                            <Link to={`/${localStorage.getItem("nome_curso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/gestao-cursos-ranking`}>
                                Ranking
                        </Link>
                        }
                    </Menu.Item>
                    : null}
                {localStorage.getItem("habilitar_competencias") === "true" ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarCompetencias(cursoId)}>
                            Competências
                    </Link>
                    </Menu.Item> : null}
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarPresencas(cursoId)}>
                        Presenças
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.openDrawerOutra(cursoId)}>
                        Adicionar outra avaliação
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    exportarCompetenciasUtilizador = (utilizadorId, cursoId) => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/competencias",
                    params: {
                        utilizadorId,
                        cursoId
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarCompetencias = cursoId => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/competencias-todos",
                    params: {
                        cursoId
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarPresencas = cursoId => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/presencas",
                    params: {
                        cursoId
                    }
                })
                    .then(response => {
                        if (response.data === "NAO_EXISTEM") {
                            openNotificationWithIcon("info", "Informação", "Não existem presenças a serem exportadas");
                            this.setState({
                                visibleExportar: false
                            });
                        }
                        else
                            this.setState({
                                btnDescarregar: false,
                                ficheiroExportar: response.data
                            });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    //QUESTIONÁRIOS DE SATISFACÃO
    exportarQuestionarios = cursoid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/questionarios-satisfacao",
                    params: {
                        cursoid
                    }
                })
                    .then(response => {
                        if (response.data === "NAO_EXISTEM") {
                            openNotificationWithIcon("info", "Informação", "Não existem questionários associados ao curso");
                            this.setState({
                                visibleExportar: false
                            });
                        }
                        else
                            this.setState({
                                btnDescarregar: false,
                                ficheiroExportar: response.data
                            });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarSumario = cursoid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/sumarios",
                    params: {
                        cursoid
                    }
                })
                    .then(response => {
                        if (response.data === "NAO_EXISTEM") {
                            openNotificationWithIcon("info", "Informação", "Não existem sumários a serem exportados!");
                            this.setState({
                                visibleExportar: false
                            });
                        }
                        else
                            this.setState({
                                btnDescarregar: false,
                                ficheiroExportar: response.data
                            });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarPauta = cursoid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/pautas",
                    params: {
                        cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarPautaTarefa = aulaid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/pautas-tarefa",
                    params: {
                        aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarPautaOutra = outraid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/pautas-outra",
                    params: {
                        outraid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    render() {
        const {
            utilizadores,
            utilizadoresMobile,
            //DRAWER ATRIBUIR CLASSIFICACAO
            atribuir_classificacao_visible,
            atribuir_classificacao,
            atribuir_observacoes,
            atribuir_medalha,
            atribuir_notificar,
            //DRAWER AVALIAR COMPETENCIA
            aula_atribuir_avaliacao_competencia_visible,
            //DRAWER AULA ATRIBUIR CLASSIFICACAO
            aula_atribuir_classificacao_visible,
            aula_trabalho_atribuir_classificacao_visible,
            aula_atribuir_classificacao,
            aula_atribuir_observacoes,
            aula_atribuir_medalha,
            aula_atribuir_notificar,
            aula_atribuir_mesma_nota,
            aula_atribuir_elementos,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading_table,
            loading_table_todas_avaliacoes,
            //loading_table_avaliacoes,
            //loading_table_questionarios,
            //loading_table_trabalhos,
            loading_table_outras,
            loading_table_progressos,
            loading_table_lvl3,
            //TIPO RESULTADO
            tipo_resultado,
            tipo_trabalho,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //DRAWER RELATORIO
            visibleDrawerRelatorio,
            //DRAWER DETAHE
            visibleDetalhe,
            visibleDetalheRelatorio,
            detalheRegisto,
            detalheClassificacao,
            detalheProgresso,
            detalheDescarregar,
            detalheNotificacao
        } = this.state;

        //COLUNAS DA TABELA DE NIVEL 1
        const columns = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-500"
            },
            {
                title: "Data",
                dataIndex: "registo",
                key: "registo",
                className: "td-200"
            },
            {
                title: "Classificação",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-200",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}{record.classificacao_observacoes ? "*" : ""}</span> {record.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>,
            },
            {
                title: "Progresso",
                dataIndex: "progresso",
                key: "progresso",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "descarregar",
                className: "td-50"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-500"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        //COLUNAS DA TABELA DE NIVEL 2 AVALICOES
        const columnslvl2Competencias = [
            {
                title: "Avaliação por Competências",
                dataIndex: "avaliacao",
                key: "avaliacao",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-205",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}{record.classificacao_observacoes ? "*" : ""}</span> {record.medalha && <i className="fas fa-award icon-medalha-aula" title="Medalha de Tarefa"></i>}</div>,
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-250",
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ]

        const columnslvl2CompetenciasMobile = [
            {
                title: "Avaliação por Competências",
                dataIndex: "avaliacao",
                key: "avaliacao",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ]

        const columnslvl3Competencias = [
            {
                title: "Competências",
                dataIndex: "competencia",
                key: "competencia"
            }
        ]

        //COLUNAS DA TABELA DE NIVEL 2 AVALICOES
        const columnslvl2TodasAvaliacoes = [
            {
                title: "Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-205",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}{record.classificacao_observacoes ? "*" : ""}</span> {record.medalha && <i className="fas fa-award icon-medalha-aula" title="Medalha de Tarefa"></i>}</div>,
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-250",
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnslvl2TodasAvaliacoesMobile = [
            {
                title: "Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        //COLUNAS DA TABELA DE NIVEL 2 OUTRAS AVALIAÇÕES
        const columnslvl2OutrasAvaliacoes = [
            {
                title: "Outras Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-205",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}{record.observacoes ? "*" : ""}</span> {record.medalha && <i className="fas fa-award icon-medalha-aula" title="Medalha de Tarefa"></i>}</div>,
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-250",
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        const columnslvl2OutrasAvaliacoesMobile = [
            {
                title: "Outras Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        //COLUNAS DA TABELA DE NIVEL 2 PROGRESSOS
        const columnslvl2Progressos = [
            {
                title: "Relatórios de Progresso",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "partilhado",
                key: "partilhado",
                className: "td-50",
            },
            {
                title: "",
                dataIndex: "notificacao",
                key: "notificacao",
                className: "td-50"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        const columnslvl2ProgressosMobile = [
            {
                title: "Relatórios de Progresso",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Resultados" />
                <div className="container container-body">
                    <div className="bloco-formadores bloco-cursos-avaliacoes">
                        <div className="bloco">
                            <div className="bloco-flex">
                                {localStorage.getItem("nome_curso_pai") ? (
                                    <div>
                                        <h3>
                                            {localStorage.getItem("cod_curso_pai")} - {localStorage.getItem("nome_curso_pai")}
                                        </h3>
                                        <span>
                                            {localStorage.getItem("cod_subcurso")} - {localStorage.getItem("nome_subcurso")}
                                        </span>
                                        <span className="escola-nome" style={{ marginLeft: 20 }}>
                                            {localStorage.getItem("escola_nome")}
                                        </span>
                                    </div>
                                ) : (
                                        <div>
                                            <h3 className="tabela_titulo">
                                                {localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}
                                            </h3>
                                            <span className="escola-nome">
                                                {localStorage.getItem("escola_nome")}
                                            </span>
                                        </div>
                                    )}
                                <Link
                                    to={this.context.escolas_entidade ? "/gestao-cursos-organismos" : "/gestao-cursos"}
                                    className="botao-principal"
                                    style={{ display: "inline" }}
                                >
                                    Voltar
                                </Link>
                            </div>
                            <div className="filtro-pesquisa-resultados" style={{ marginTop: 40 }}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <BotaoProcurar
                                            tem_placeholder
                                            placeholder="Pesquisar pelo nome..."
                                            HandleKeyPress={this.HandleKeyPress}
                                            resetCaixaProcura={this.resetCaixaProcura}
                                        />
                                    </div>
                                    <div
                                        className="col-lg-6 bloco-opcoes"
                                        style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", fontSize: 16 }}
                                    >
                                        <Dropdown overlay={this.montarMenuExportar(this.state.cursoid)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-desktop"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)" }}
                                columns={columns}
                                dataSource={this.carregaDados(utilizadores)}
                                onExpand={this.listarResultados}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                                expandedRowRender={record => (
                                    <div className="tabela-lvl2">
                                        {localStorage.getItem("habilitar_competencias") === "true" &&
                                            <div className="bloco-avaliacoes">
                                                <Table
                                                    className="tabela_lvl2"
                                                    columns={columnslvl2Competencias}
                                                    dataSource={utilizadores[record.key].competencias}
                                                    pagination={false}
                                                    loading={{
                                                        spinning: loading_table,
                                                        indicator: (
                                                            <div className="loading-data-table">
                                                                <div className="loading" />
                                                            </div>
                                                        )
                                                    }}
                                                    locale={{ emptyText: "Não existem dados!" }}
                                                    onExpand={(expand, record2) => this.listarResultadosLvl2(expand, record2, record)}
                                                    expandedRowRender={record2 => (
                                                        <div className="tabela-lvl2">
                                                            <div className="bloco-avaliacoes">
                                                                <Table
                                                                    id="tabela-avaliacoes-finais_lvl2"
                                                                    className="tabela_lvl2"
                                                                    columns={columnslvl3Competencias}
                                                                    dataSource={utilizadores[record.key].competencias[record2.key].competencias}
                                                                    pagination={false}
                                                                    locale={{ emptyText: "Não existem dados!" }}
                                                                    loading={{
                                                                        spinning: loading_table_lvl3,
                                                                        indicator: (
                                                                            <div className="loading-data-table">
                                                                                <div className="loading" />
                                                                            </div>
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>}
                                        <div className="bloco-avaliacoes">
                                            <Table
                                                id="tabela-avaliacoes-finais_lvl2"
                                                className="tabela_lvl2"
                                                columns={columnslvl2TodasAvaliacoes}
                                                dataSource={utilizadores[record.key].tarefas}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem dados!" }}
                                                loading={{
                                                    spinning: loading_table_todas_avaliacoes,
                                                    indicator: (
                                                        <div className="loading-data-table">
                                                            <div className="loading" />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="bloco-avaliacoes">
                                            <Table
                                                id="tabela-avaliacoes-finais_lvl2"
                                                className="tabela_lvl2"
                                                columns={columnslvl2OutrasAvaliacoes}
                                                dataSource={utilizadores[record.key].outras_avaliacoes}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem dados!" }}
                                                loading={{
                                                    spinning: loading_table_outras,
                                                    indicator: (
                                                        <div className="loading-data-table">
                                                            <div className="loading" />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="bloco-avaliacoes">
                                            <Table
                                                id="tabela-avaliacoes-finais_lvl2"
                                                className="tabela_lvl2"
                                                columns={columnslvl2Progressos}
                                                dataSource={utilizadores[record.key].relatorios_progresso}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem dados!" }}
                                                loading={{
                                                    spinning: loading_table_progressos,
                                                    indicator: (
                                                        <div className="loading-data-table">
                                                            <div className="loading" />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="bloco-avaliacoes">
                                            <h3>Estatísticas</h3>
                                            <div className="dashboard" style={{ padding: 0, boxShadow: "none" }}>
                                                <div className="bloco" style={{ padding: 0, boxShadow: "none" }}>
                                                    <div className="grid">
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas visitadas no curso", "")}>
                                                            <Icon type="desktop" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.tarefas_visitadas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Tarefas visitadas</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tempo de consulta nas tarefas visitadas no curso", "")}>
                                                            <Icon type="history" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.tempos_tarefas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Tempo nas tarefas</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas concluídas no curso", "")}>
                                                            <Icon type="check" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.conteudos_concluidos
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Tarefas concluídas</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Ficheiros adicionados na drive", "")}>
                                                            <Icon type="file-add" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.doc_fichs
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Ficheiros</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Anotações pessoais adicionadas", "")}>
                                                            <Icon type="file" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.interacoes_anotacoes
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Anotações</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Mensagens registadas em conversações", "")}>
                                                            <Icon type="message" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.mensagens_enviadas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Mensagens enviadas</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Questões submetidas em fóruns", "")}>
                                                            <Icon type="form" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.interacoes_forum
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Interações fórum</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Ratings registados nas tarefas do curso", "")}>
                                                            <Icon type="number" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.ratingsaulas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Ratings/média (tarefas)</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Classificações obtidas nas tarefas do curso (quando aplicável)", "")}>
                                                            <Icon type="check-circle" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.avaliacoesaulas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Classificações/média (tarefas)</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Rating registado no curso (após a sua conclusão)", "")}>
                                                            <Icon type="number" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.ratingscursos
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Rating (curso)</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Classificação obtida no curso (após a sua conclusão)", "")}>
                                                            <Icon type="check-circle" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.avaliacoescursos
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Classificação (curso)</span>
                                                        </div>
                                                        <div className="item icon-medalha" onClick={() => openNotificationWithIcon("info", "Medalhas recebida ao curso", "")}>
                                                            <i className="fas fa-award icon-curso" style={{ width: 40 }}></i>
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.medalhacurso
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Medalha no curso</span>
                                                        </div>
                                                        <div className="item icon-medalha" onClick={() => openNotificationWithIcon("info", "Medalhas recebidas em tarefas", "")}>
                                                            <i className="fas fa-award icon-aula" style={{ width: 40 }}></i>
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.medalhastarefas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Medalhas em Tarefas</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="botoes-exportar">
                                                    <Link to="#" onClick={() => this.exportarEstatisticasCSV(utilizadores[record.key].id)}>
                                                        <Icon type="export" />
                                                        Exportar resumo para csv
                                                    </Link>
                                                    <Link to="#" onClick={() => this.exportarEstatisticasDET(utilizadores[record.key].id)}>
                                                        <Icon type="export" />
                                                        Exportar detalhe para csv
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-mobile"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)" }}
                                columns={columnsMobile}
                                dataSource={this.carregaDadosMobile(utilizadores)}
                                onExpand={this.listarResultadosMobile}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem dados!" }}
                                expandedRowRender={record => (
                                    <div className="tabela-lvl2">
                                        {localStorage.getItem("habilitar_competencias") === "true" &&
                                            <div className="bloco-avaliacoes">
                                                <Table
                                                    className="tabela_lvl2 tabela-competencias-mobile"
                                                    columns={columnslvl2CompetenciasMobile}
                                                    dataSource={utilizadoresMobile[record.key].competencias}
                                                    pagination={false}
                                                    loading={{
                                                        spinning: loading_table,
                                                        indicator: (
                                                            <div className="loading-data-table">
                                                                <div className="loading" />
                                                            </div>
                                                        )
                                                    }}
                                                    locale={{ emptyText: "Não existem dados!" }}
                                                    onExpand={(expand, record2) => this.listarResultadosLvl2(expand, record2, record)}
                                                    expandedRowRender={record2 => (
                                                        <div className="tabela-lvl2">
                                                            <div className="bloco-avaliacoes">
                                                                <Table
                                                                    id="tabela-avaliacoes-finais_lvl2"
                                                                    className="tabela_lvl2"
                                                                    columns={columnslvl3Competencias}
                                                                    dataSource={utilizadoresMobile[record.key].competencias[record2.key].competencias}
                                                                    pagination={false}
                                                                    locale={{ emptyText: "Não existem dados!" }}
                                                                    loading={{
                                                                        spinning: loading_table_lvl3,
                                                                        indicator: (
                                                                            <div className="loading-data-table">
                                                                                <div className="loading" />
                                                                            </div>
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>}
                                        <div className="bloco-avaliacoes">
                                            <Table
                                                id="tabela-avaliacoes-finais_lvl2"
                                                className="tabela_lvl2 tabela-resultados-mobile"
                                                columns={columnslvl2TodasAvaliacoesMobile}
                                                dataSource={utilizadoresMobile[record.key].tarefas}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem dados!" }}
                                                loading={{
                                                    spinning: loading_table_todas_avaliacoes,
                                                    indicator: (
                                                        <div className="loading-data-table">
                                                            <div className="loading" />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="bloco-avaliacoes">
                                            <Table
                                                id="tabela-avaliacoes-finais_lvl2"
                                                className="tabela_lvl2 tabela-resultados-mobile"
                                                columns={columnslvl2OutrasAvaliacoesMobile}
                                                dataSource={utilizadoresMobile[record.key].outras_avaliacoes}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem dados!" }}
                                                loading={{
                                                    spinning: loading_table_outras,
                                                    indicator: (
                                                        <div className="loading-data-table">
                                                            <div className="loading" />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="bloco-avaliacoes">
                                            <Table
                                                id="tabela-avaliacoes-finais_lvl2"
                                                className="tabela_lvl2 tabela-resultados-mobile"
                                                columns={columnslvl2ProgressosMobile}
                                                dataSource={utilizadoresMobile[record.key].relatorios_progresso}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem dados!" }}
                                                loading={{
                                                    spinning: loading_table_progressos,
                                                    indicator: (
                                                        <div className="loading-data-table">
                                                            <div className="loading" />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="bloco-avaliacoes">
                                            <h3>Estatísticas</h3>
                                            <div className="dashboard" style={{ padding: 0, boxShadow: "none" }}>
                                                <div className="bloco" style={{ padding: 0, boxShadow: "none" }}>
                                                    <div className="grid">
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas visitadas no curso", "")}>
                                                            <Icon type="desktop" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.tarefas_visitadas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Tarefas visitadas</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tempo de consulta nas tarefas visitadas no curso", "")}>
                                                            <Icon type="history" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.tempos_tarefas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Tempo nas tarefas</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas concluídas no curso", "")}>
                                                            <Icon type="check" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.conteudos_concluidos
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Tarefas concluídas</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Ficheiros adicionados na drive", "")}>
                                                            <Icon type="file-add" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.doc_fichs
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Ficheiros</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Anotações pessoais adicionadas", "")}>
                                                            <Icon type="file" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.interacoes_anotacoes
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Anotações</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Mensagens registadas em conversações", "")}>
                                                            <Icon type="message" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.mensagens_enviadas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Mensagens enviadas</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Questões submetidas em fóruns", "")}>
                                                            <Icon type="form" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.interacoes_forum
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Interações fórum</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Ratings registados nas tarefas do curso", "")}>
                                                            <Icon type="number" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.ratingsaulas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Ratings/média (tarefas)</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Classificações obtidas nas tarefas do curso (quando aplicável)", "")}>
                                                            <Icon type="check-circle" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.avaliacoesaulas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Classificações/média (tarefas)</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Rating registado no curso (após a sua conclusão)", "")}>
                                                            <Icon type="number" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.ratingscursos
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Rating (curso)</span>
                                                        </div>
                                                        <div className="item" onClick={() => openNotificationWithIcon("info", "Classificação obtida no curso (após a sua conclusão)", "")}>
                                                            <Icon type="check-circle" />
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.avaliacoescursos
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Classificação (curso)</span>
                                                        </div>
                                                        <div className="item icon-medalha" onClick={() => openNotificationWithIcon("info", "Medalhas recebida ao curso", "")}>
                                                            <i className="fas fa-award icon-curso" style={{ width: 40 }}></i>
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.medalhacurso
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Medalha no curso</span>
                                                        </div>
                                                        <div className="item icon-medalha" onClick={() => openNotificationWithIcon("info", "Medalhas recebidas em tarefas", "")}>
                                                            <i className="fas fa-award icon-aula" style={{ width: 40 }}></i>
                                                            <span className="desc">
                                                                {utilizadores[record.key].estatisticas
                                                                    ? utilizadores[record.key].estatisticas.medalhastarefas
                                                                    : 0}
                                                            </span>
                                                            <span className="titulo">Medalhas em Tarefas</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="botoes-exportar">
                                                    <Link to="#" onClick={() => this.exportarEstatisticasCSV(utilizadores[record.key].id)}>
                                                        <Icon type="export" />
                                                        Exportar resumo para csv
                                                    </Link>
                                                    <Link to="#" onClick={() => this.exportarEstatisticasDET(utilizadores[record.key].id)}>
                                                        <Icon type="export" />
                                                        Exportar detalhe para csv
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <DrawerAtribuirClassificacao
                    visible={atribuir_classificacao_visible}
                    onClose={() => this.setState({ atribuir_classificacao_visible: false })}
                    classificacao={atribuir_classificacao}
                    observacoes={atribuir_observacoes}
                    medalha={atribuir_medalha}
                    notificar={atribuir_notificar}
                    cursoid={this.state.cursoid}
                    utilizadorid={this.state.utilizadorid}
                    handleChange={this.handleChange}
                    onCloseAtribuirClassificacaoAtualizar={this.onCloseAtribuirClassificacaoAtualizar}
                    handlerChangeCheck={this.handlerChangeCheck}
                />
                <DrawerAtribuirAvaliacaoCompetencia
                    aulaid={this.state.aulaid}
                    utilizadorid={this.state.utilizadorid}
                    visible={aula_atribuir_avaliacao_competencia_visible}
                    onClose={() => this.setState({ aula_atribuir_avaliacao_competencia_visible: false })}
                    onCloseAulaAtribuirClassificacaoCompetencias={this.onCloseAulaAtribuirClassificacaoCompetencias}
                />
                <DrawerAtribuirClassificacaoAula
                    visible={aula_atribuir_classificacao_visible}
                    onClose={() => this.setState({ aula_atribuir_classificacao_visible: false })}
                    classificacao={aula_atribuir_classificacao}
                    observacoes={aula_atribuir_observacoes}
                    medalha={aula_atribuir_medalha}
                    notificar={aula_atribuir_notificar}
                    aulaid={this.state.aulaid}
                    tipo_resultado={tipo_resultado}
                    tipo_trabalho={tipo_trabalho}
                    utilizadorid={this.state.utilizadorid}
                    handleChange={this.handleChange}
                    onCloseAtribuirClassificacaoAtualizar={this.onCloseAulaAtribuirClassificacaoAtualizar}
                    handlerChangeCheck={this.handlerChangeCheck}
                />
                {/*<DrawerAtribuirClassificacaoAula
                    visible={aula_atribuir_classificacao_visible}
                    onClose={() => this.setState({ aula_atribuir_classificacao_visible: false })}
                    classificacao={aula_atribuir_classificacao}
                    observacoes={aula_atribuir_observacoes}
                    medalha={aula_atribuir_medalha}
                    notificar={aula_atribuir_notificar}
                    aulaid={this.state.aulaid}
                    tipo_resultado={tipo_resultado}
                    tipo_trabalho={tipo_trabalho}
                    utilizadorid={this.state.utilizadorid}
                    handleChange={this.handleChange}
                    onCloseAtribuirClassificacaoAtualizar={this.onCloseAulaAtribuirClassificacaoAtualizar}
                    handlerChangeCheck={this.handlerChangeCheck}
                />*/}
                <DrawerAtribuirClassificacaoTrabalho
                    visible={aula_trabalho_atribuir_classificacao_visible}
                    onClose={() => this.setState({ aula_trabalho_atribuir_classificacao_visible: false })}
                    classificacao={aula_atribuir_classificacao}
                    observacoes={aula_atribuir_observacoes}
                    medalha={aula_atribuir_medalha}
                    notificar={aula_atribuir_notificar}
                    mesma_nota={aula_atribuir_mesma_nota}
                    elementos={aula_atribuir_elementos}
                    aulaid={this.state.aulaid}
                    tipo_resultado={tipo_resultado}
                    tipo_trabalho={tipo_trabalho}
                    utilizadorid={this.state.utilizadorid}
                    handleChange={this.handleChange}
                    onCloseAtribuirClassificacaoAtualizar={this.onCloseAulaAtribuirClassificacaoAtualizar}
                    handlerChangeCheck={this.handlerChangeCheck}
                />
                <DrawerOutraAvaliacao
                    visible={this.state.outra_avaliacao_visible}
                    onClose={() => this.setState({ outra_avaliacao_visible: false })}
                    outraId={this.state.outraId}
                    classificacaoId={this.state.classificacaoId}
                    cursoid={this.state.cursoid}
                    utilizadorid={this.state.utilizadorid}
                    nome={this.state.nome_avaliacao}
                    data={this.state.data_avaliacao}
                    classificacao={this.state.outra_classificacao}
                    observacoes={this.state.outra_observacoes}
                    medalha={this.state.outra_medalha}
                    notificar={this.state.outra_notificar}
                    atribuir_classificacao={this.state.outra_atribuir_classificacao}
                    handleChange={this.handleChange}
                    handleChangeData={this.handleChangeData}
                    atualizar={() => this.onCloseDrawerOutraAtualizar()}
                    handlerChangeCheck={this.handlerChangeCheck}

                />
                <DrawerTrabalho
                    visible={this.state.trabalho_visible}
                    onClose={() => this.setState({ trabalho_visible: false })}
                    nome={this.state.nome}
                    participantes={this.state.participantes}
                    observacoes={this.state.observacoes}
                    ficheiros={this.state.ficheiros}
                />
                <DrawerCertificacao
                    visible={this.state.certificacao_visible}
                    onClose={() => this.setState({ certificacao_visible: false })}
                    atualizar={this.atualizarDocumento}
                    documento={this.state.documento}
                    documento_nome={this.state.documento_nome}
                    cursoid={this.state.cursoid}
                    utilizadorId={this.state.utilizadorid}
                    utilizadorFoto={this.state.utilizadorFoto}
                    utilizadorNome={this.state.utilizadorNome}
                    utilizadorEmail={this.state.utilizadorEmail}
                    utilizadorCodigo={this.state.utilizadorCodigo}
                />
                <DrawerRelatorio
                    relatorioid={this.state.relatorioid}
                    utilizadorId={this.state.utilizadorid}
                    onClose={() => this.setState({ visibleDrawerRelatorio: false })}
                    atualizar={() => this.listarRelatoriosProgresso(this.state.record)}
                    visibleDrawerRelatorio={visibleDrawerRelatorio}
                />
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
                <Drawer
                    className="drawer-detalhe-curso"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Data">
                                    {detalheRegisto}
                                </Form.Item>
                                {detalheClassificacao &&
                                    <Form.Item label="Classificação">
                                        {detalheClassificacao}
                                    </Form.Item>}
                                {detalheProgresso &&
                                    <Form.Item label="Progresso">
                                        {detalheProgresso}
                                    </Form.Item>}
                                {detalheDescarregar &&
                                    <Form.Item label="Certificado">
                                        {detalheDescarregar}
                                    </Form.Item>}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Drawer
                    className="drawer-detalhe-curso"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalheRelatorio: false })}
                    visible={visibleDetalheRelatorio}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Data">
                                    {detalheRegisto}
                                </Form.Item>
                                {detalheNotificacao &&
                                    <Form.Item label="Responsável notificado">
                                        {detalheNotificacao}
                                    </Form.Item>}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalheRelatorio: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const FormCursosResultados = Form.create({ name: "form-cursos-resultados" })(CursosResultados);

export default withRouter(FormCursosResultados);
