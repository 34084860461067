import React, { Component } from "react";
import { GlobalContext } from "../GlobalState";
import noimage from "../images/noimage.png";
import axios from "axios";

class Pontos extends Component {
    static contextType = GlobalContext;
    state = {
        //GAMIFICACAO
        avatar: "",
        pontos: 0,
        posicao: 0,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.carregarPontos();
    }

    carregarPontos = () => {
        axios({
            method: "get",
            url: "/api/curso-ranking/carregar-header-pontos",
            params: {
                cursoid: this.props.cursoId
            }
        })
            .then(response => {
                this.setState({
                    avatar: response.data.avatar,
                    pontos: response.data.pontos,
                    posicao: response.data.posicao,
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            //GAMIFICACAO
            avatar,
            pontos,
            posicao,
        } = this.state;

        return (
            <div className="bloco-direita">
                <div className="curso-pontuacao">
                    <div className="container-img">
                        <img src={avatar ? avatar : noimage} />
                    </div>
                    <span>{pontos} pts (<strong>{posicao > 0 ? `${posicao}º` : "---"}</strong>)</span>
                </div>
            </div>
        );
    }
}

export default Pontos;
