import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Menu, notification, Drawer, Form, Input, Icon, DatePicker, Tooltip, Pagination, Select, Modal, Collapse, Checkbox } from "antd";
import Header from "../User/Header";
import HeaderColaboradores from "./_Aux/HeaderColaboradores";
import ListaColaboradores from "./_Aux/ListaColaboradores";
import Colaborador from "./_Aux/Colaborador";
import axios from "axios";
import moment from "moment";
import noimage from "../images/noimage.png";
import Oops from "../Geral/Oops.jsx";
import DrawerAlterarSenha from "./_Aux/DrawerAlterarSenha";
import DrawerAlterarLogin from "./_Aux/DrawerAlterarLogin";
//import { CSVLink } from "react-csv";
import { GlobalContext } from "../GlobalState";
import ModalSelecionarDeclaracao from "./_Aux/ModalSelecionarDeclaracao";
import DrawerPerfis from "./DrawerPerfis/DrawerPerfis";
import DrawerEstabelecimentos from "./DrawerEstabelecimentos/DrawerEstabelecimentos";

import Acessos from "../Geral/Acessos/Acessos";
import ModalGerarDocumento from "../Componentes/Modals/GerarDocumento";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ColaboradoresTodos extends Component {
    static contextType = GlobalContext;
    state = {
        titulo: "",
        escolas: [],
        escolasCarregadas: false,
        //LISTA

        colaboradores: [],
        colaboradoresCSV: [],
        ativo: "",
        totalLicencas: 0,
        licencasUtilizadas: 0,
        pesquisa: "",
        filtro: undefined,
        associado: undefined,
        filtroEscolasUtilizado: false,
        filtroEscolaId: undefined,
        lista_ativo: true,
        //DRAWER
        utilizadorid: 0,
        escolaId: undefined,
        //FOTO UTILIZADOR
        foto: "",
        preview: null,
        //DADOS GERAIS
        nome_completo: "",
        nr_aluno: "",
        emailnotif: "",
        contacto: "",
        descricao: "",
        habilitacao: "",
        paginaPessoal: "",
        dt_nascimento: null,
        naturalidade: "",
        sexo: "",
        estadoCivil: "",
        cc: "",
        dt_ccvalidade: null,
        nif: "",
        cep: "",
        localidade: "",
        morada: "",
        pais: "",
        //FILIAÇÃO
        maeResponsavel: "",
        paiResponsavel: "",
        //RESPONSAVEL
        responsavelNome: "",
        responsavelEmail: "",
        responsavelContacto: "",
        //PERMISSOES
        podeAlterarDadosPessoais: false,
        visible: false,
        //LOADING
        iconLoading: false,
        //UPLOAD VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //PAGINATION
        current: 1,
        pageSize: 10,
        total: 0,
        //LOADING COLABORADORES
        loadingLista: true,
        //MODAL
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //DRAWER ASSOCIAR
        pesquisaAssociar: "",
        loading_pesquisa: false,
        nome_utilizador: "",
        id_utilizador: 0,
        id_responsavel: 0,
        visibleAssociar: false,
        visibleAssociarUtilizador: false,
        pesquisar_utilizadores: [],
        //DRAWER ALTERAR SENHA
        visibleAlterarSenha: false,
        //DRAWER ALTERAR LOGIN
        visibleAlterarLogin: false,
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalheEntidade: "",
        detalheLogin: "",
        //MODAL EMITIR DECLARACAO
        visibleEmitirDeclaracao: false,
        //DRAWER PERFIS
        visiblePerfis: false,
        //DRAWER ESTABELECIMENTO
        visibleEstabelecimentos: false,
        //FILTROS
        listaAssociados: [],
        //
        loginAtual: "",
        //teste
        perfilNavSelecionado: 0
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        const pesquisaInfo = this.context.pesquisaInfo;

        if (pesquisaInfo) {
            this.setState({
                pesquisa: pesquisaInfo.pesquisa,
                filtro: pesquisaInfo.perfil ? pesquisaInfo.perfil : undefined,
                titulo: pesquisaInfo.estabelecimento ? this.context.estabelecimentos.find(x => x.id === pesquisaInfo.estabelecimento).nome : "",
                filtroEscolaId: pesquisaInfo.estabelecimento ? pesquisaInfo.estabelecimento : undefined,
                //filtroEscolasUtilizado: pesquisaInfo.estabelecimento != undefined,
                current: pesquisaInfo.pagina ? pesquisaInfo.pagina : 1
            }, () => {
                this.total();
                this.listar();
                this.carregarTotalLicencas();
            })
        }
        else if (this.context.estabelecimento) {
            this.setState({
                titulo: this.context.estabelecimento.id ? this.context.estabelecimentos.find(x => x.id === this.context.estabelecimento.id).nome : "",
                filtroEscolaId: this.context.estabelecimento.id
            }, () => {
                this.total();
                this.listar();
                this.carregarTotalLicencas();
            })
        }
        else {
            this.total();
            this.listar();
            this.carregarTotalLicencas();
        }
    }

    componentDidUpdate() {
        const { estabelecimento, perfil } = this.props;
        const { filtroEscolasUtilizado, filtroEscolaId, filtro } = this.state;

        const pesquisaInfo = this.context.pesquisaInfo;

        if (estabelecimento?.id && estabelecimento.id !== filtroEscolaId && (!filtroEscolasUtilizado && !pesquisaInfo || this.context.filtroEstabelecimentosUtilizado)) {
            this.setState(
                {
                    titulo: estabelecimento.id ? this.context.estabelecimentos.find(x => x.id === estabelecimento.id).nome : "",
                    filtroEscolaId: estabelecimento.id
                },
                () => {
                    this.total();
                    this.listar();
                }
            );
            this.context.atualizarState({
                filtroEstabelecimentosUtilizado: false
            });
        }

        if (perfil?.id && perfil.id !== this.state.perfilNavSelecionado) {
            this.setState({
                perfilNavSelecionado: perfil.id
            }, () => {
                this.total();
                this.listar();
            })
        }

        //if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
        //    this.setState({
        //        redirect: true,
        //        redirectLink: "/user"
        //    });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeDataNascimento = date => {
        this.setState({ dt_nascimento: date });
    };

    handleChangeDate = date => {
        this.setState({ dt_ccvalidade: date });
    };

    handleChangeSelect = value => {
        this.setState({ pais: value });
    };

    handleChangeSelectOrganismo = escolaId => {
        this.setState({
            escolaId
        });
    };

    handleChangeSwitch = lista_ativo => {
        this.setState(
            {
                lista_ativo,
                //PAGINATION
                current: 1,
                //pageSize: 10,
                total: 0
            },
            () => {
                this.total(this.state.pesquisa, this.state.filtro);
                this.listar();
            }
        );
    };

    handleChangeCard = (event, index) => {
        let pesquisar_utilizadores = this.state.pesquisar_utilizadores;
        pesquisar_utilizadores[index].qualidade = event.target.value;
        this.setState({ pesquisar_utilizadores });
    };

    onChangePageSize = pageSize => {
        this.setState(
            {
                pageSize
            },
            () => this.listar()
        );
    };

    //CHAMADA APÓS INTERASSÃO COM A CAIXA DE PROCURA
    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value,
                    //PAGINATION
                    current: 1,
                    pageSize: 10,
                    total: 0
                },
                () => {
                    this.montarRegistoFiltros();
                    this.total();
                    this.listar();
                }
            );
        }
    };

    //LIMPA A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                //PAGINATION
                current: 1,
                //pageSize: 10,
                total: 0
            },
            () => {
                this.total();
                this.listar();
            }
        );
    };

    //FILTRAR REGISTOS
    filtrar = value => {
        this.setState(
            {
                filtro: value,
                //PAGINATION
                current: 1,
                //pageSize: 10,
                total: 0
            },
            () => {
                this.montarRegistoFiltros();
                this.total();
                this.listar();
            }
        );
    };

    //FILTRAR REGISTOS
    filtrarOrganismo = filtroEscolaId => {
        this.setState(
            {
                titulo: filtroEscolaId ? this.context.estabelecimentos.find(x => x.id === filtroEscolaId).nome : "",
                filtroEscolaId,
                filtroEscolasUtilizado: true,
                //PAGINATION
                current: 1,
                //pageSize: 10,
                total: 0
            },
            () => {
                this.montarRegistoFiltros();
                this.total();
                this.listar();
            }
        );
    };

    filtrarAssociados = value => {
        this.setState(
            {
                listaAssociados: value,
                associado: value,
                //PAGINATION
                current: 1,
                //pageSize: 10,
                total: 0
            },
            () => {
                this.montarRegistoFiltros();
                this.total();
                this.listar();
            }
        );
    };



    paginar = pag => {
        this.setState(
            {
                current: pag
            },
            () => {
                this.montarRegistoFiltros();
                this.listar();
            }
        );
    };

    //listarEscolas = () => {
    //    axios({
    //        method: "get",
    //        url: "/api/gestao-organismos/listar-organismos-drawer",
    //        params: {
    //            ativo: true
    //        }
    //    })
    //        .then(response => {
    //            this.setState({
    //                escolas: response.data
    //            });
    //        })
    //        .catch(error => { });
    //};

    //CARREGA OS UTILIZADORES
    listar = () => {
        this.setState(
            {
                loadingLista: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/colaboradores/listar-todos",
                    params: {
                        pesquisa: this.state.pesquisa,
                        perfilId: this.state.filtro,
                        estabelecimentoId: this.state.filtroEscolaId,
                        perfilAtual: this.context.perfil?.id,
                        pageSize: this.state.pageSize,
                        page: this.state.current,
                        ativo: this.state.lista_ativo,
                        listarAssociados: this.state.listaAssociados
                    }
                })
                    .then(response => {
                        this.setState({
                            loadingLista: false,
                            colaboradores: response.data
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    total = () => {
        axios({
            method: "get",
            url: "/api/colaboradores/total-todos",
            params: {
                pesquisa: this.state.pesquisa,
                perfilId: this.state.filtro,
                filtro: this.state.filtro,
                estabelecimentoId: this.state.filtroEscolaId,
                ativo: this.state.lista_ativo,
                perfilAtual: this.context.perfil?.id,
                listarAssociados: this.state.listaAssociados
            }
        })
            .then(response => {
                this.setState({
                    total: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA OS DADOS DE UM UTILIZADOR E ABRE O DRAWER
    carregar = utilizadorid => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/colaboradores/detalhe",
            params: {
                utilizadorid: utilizadorid
            }
        })
            .then(response => {
                this.setState({
                    utilizadorid: response.data.id,
                    escolaId: response.data.id_organismo ? response.data.id_organismo : undefined,
                    //FOTO UTILIZADOR
                    foto: response.data.foto,
                    preview: response.data.foto,
                    //DADOS GERAIS
                    nome_completo: response.data.nome_completo,
                    nr_aluno: response.data.nr_aluno ? response.data.nr_aluno : "",
                    emailnotif: response.data.emailnotif,
                    contacto: response.data.contacto ? response.data.contacto : "",
                    descricao: response.data.descricao ? response.data.descricao : "",
                    habilitacao: response.data.habilitacao ? response.data.habilitacao : "",
                    paginaPessoal: response.data.paginaPessoal ? response.data.paginaPessoal : "",
                    dt_nascimento: response.data.dt_nascimento ? moment(response.data.dt_nascimento) : null,
                    naturalidade: response.data.naturalidade ? response.data.naturalidade : "",
                    sexo: response.data.sexo ? response.data.sexo : undefined,
                    estadoCivil: response.data.estadoCivil ? response.data.estadoCivil : "",
                    cc: response.data.cc ? response.data.cc : "",
                    dt_ccvalidade: response.data.dt_ccvalidade ? moment(response.data.dt_ccvalidade) : null,
                    nif: response.data.nif ? response.data.nif : "",
                    cep: response.data.cep ? response.data.cep : "",
                    localidade: response.data.localidade ? response.data.localidade : "",
                    morada: response.data.morada ? response.data.morada : "",
                    pais: response.data.pais ? response.data.pais : "",
                    maeResponsavel: response.data.maeResponsavel ? response.data.maeResponsavel : "",
                    paiResponsavel: response.data.paiResponsavel ? response.data.paiResponsavel : "",
                    responsavelNome: response.data.responsavelNome ? response.data.responsavelNome : "",
                    responsavelEmail: response.data.responsavelEmail ? response.data.responsavelEmail : "",
                    responsavelContacto: response.data.responsavelContacto ? response.data.responsavelContacto : "",
                    podeAlterarDadosPessoais: response.data.podeAlterarDadosPessoais,
                    visible: true
                });
            })
            .catch(() => { });
    };

    //ATUALIZA A INFORMAÇÃO DO UTILIZADOR
    alterar = event => {
        event.preventDefault();
        this.props.form.resetFields();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                let item = new FormData();
                item.append("id", this.state.utilizadorid);
                if (this.state.escolaId) item.append("id_organismo", this.state.escolaId);
                item.append("foto", this.state.foto);
                item.append("nome_completo", this.state.nome_completo);
                item.append("nr_aluno", this.state.nr_aluno);
                item.append("emailnotif", this.state.emailnotif);
                item.append("contacto", this.state.contacto);
                item.append("descricao", this.state.descricao);
                item.append("habilitacao", this.state.habilitacao);
                item.append("paginaPessoal", this.state.paginaPessoal);
                if (this.state.dt_nascimento) item.append("dt_nascimento", this.state.dt_nascimento.format("YYYY/MM/DD"));
                item.append("naturalidade", this.state.naturalidade);
                if (this.state.sexo) item.append("sexo", this.state.sexo);
                item.append("estadoCivil", this.state.estadoCivil);
                item.append("cc", this.state.cc);
                if (this.state.dt_ccvalidade) item.append("dt_ccvalidade", this.state.dt_ccvalidade.format("YYYY/MM/DD"));
                item.append("nif", this.state.nif);
                item.append("cep", this.state.cep);
                item.append("localidade", this.state.localidade);
                item.append("morada", this.state.morada);
                item.append("pais", this.state.pais);
                item.append("maeResponsavel", this.state.maeResponsavel);
                item.append("paiResponsavel", this.state.paiResponsavel);
                item.append("responsavelNome", this.state.responsavelNome);
                item.append("responsavelEmail", this.state.responsavelEmail);
                item.append("responsavelContacto", this.state.responsavelContacto);
                item.append("podeAlterarDadosPessoais", this.state.podeAlterarDadosPessoais);

                axios({
                    method: "put",
                    url: "/api/colaboradores/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATIVA E INATIVA UTILIZADOR
    ativarInativarColaborador = (utilizadorid) => {

        confirm({
            title: this.state.lista_ativo ? "Pretende inativar este usuário?" : "Pretende ativar este usuário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                let item = new FormData();
                item.append("ativo", this.state.lista_ativo);

                axios({
                    method: "put",
                    url: "/api/colaboradores/ativar-inativar-utilizador",
                    data: item,
                    params: {
                        utilizadorid: utilizadorid
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", !this.state.lista_ativo ? "Usuário ativado!" : "Usuário inativado!");
                        let colaboradores = this.state.colaboradores.filter(x => x.id !== utilizadorid);
                        this.setState({
                            colaboradores,
                            total: this.state.total - 1
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    //CALCULA O TOTAL DE LICENÇAS VÁLIDAS DA ENTIDADE
    carregarTotalLicencas = () => {
        axios({
            method: "get",
            url: "/api/colaboradores/total-licencas"
        })
            .then(response => {
                this.setState({
                    totalLicencas: response.data.total,
                    licencasUtilizadas: response.data.utilizadas
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //MONTA O MENU DE OPÇÕES POR UTILIZADOR
    menu = utilizador => {
        const acesso = this.context.acessos?.find(x => x.func === 101);
        const acessoProf = this.context.acessos?.find(x => x.func === 125);
        const valoresPermitidosPerfis = [114, 115, 116];
        const acessoPerfis = this.context.acessos?.filter(x => valoresPermitidosPerfis.includes(x.func))?.map(x => x.func);
        const valoresPermitidosEstabelecimentos = [117, 118, 119];
        const acessoEstabelecimentos = this.context.acessos?.filter(x => valoresPermitidosEstabelecimentos.includes(x.func))?.map(x => x.func);
        const existePerfilAluno = utilizador.estabelecimentos.some(estabelecimento =>
            estabelecimento.perfils.includes("Aluno")
        );
        const existePerfilProfessor = utilizador.estabelecimentos.some(estabelecimento =>
            estabelecimento.perfils.includes("Professor")
        );

        return (
            <Menu>
                {(acessoPerfis && acessoPerfis.includes(114) || acessoPerfis && acessoPerfis.includes(115) && existePerfilAluno || acessoPerfis && acessoPerfis.includes(116) && acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="0">
                        <Link
                            to="#"
                            onClick={() => this.setState({ visiblePerfis: true, utilizadorid: utilizador.id })}
                        >
                            Perfis
                        </Link>
                    </Menu.Item>)}
                {(acessoEstabelecimentos && acessoEstabelecimentos.includes(117) || acessoEstabelecimentos && acessoEstabelecimentos.includes(118) && existePerfilAluno || acessoEstabelecimentos && acessoEstabelecimentos.includes(119) && acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="1">
                        <Link
                            to="#"
                            onClick={() => this.setState({ visibleEstabelecimentos: true, utilizadorid: utilizador.id })}
                        >
                            Estabelecimentos
                        </Link>
                    </Menu.Item>)}
                {(!existePerfilProfessor || acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="2">
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.ativarInativarColaborador(utilizador.id);
                            }}
                        >
                            {this.state.lista_ativo ? "Inativar" : "Ativar"}
                        </Link>
                    </Menu.Item>)}
                {(!existePerfilProfessor || acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="3">
                        <Link
                            to="#"
                            onClick={() => this.setState({
                                visibleAlterarSenha: true,
                                nome_completo: utilizador.nome_completo,
                                utilizadorid: utilizador.id
                            })}
                        >
                            Alterar Senha
                        </Link>
                    </Menu.Item>)}
                {(!existePerfilProfessor || acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="4">
                        <Link
                            to="#"
                            onClick={() => this.setState({
                                visibleAlterarLogin: true,
                                nome_completo: utilizador.nome_completo,
                                loginAtual: utilizador.email,
                                utilizadorid: utilizador.id
                            })}
                        >
                            Alterar Login
                        </Link>
                    </Menu.Item>)}
                <Menu.Item>
                    <Link to={"/gestao-utilizadores-estatisticas"} onClick={() => localStorage.setItem("sis_utilizador", JSON.stringify({ id: utilizador.id, foto: utilizador.foto, nome: utilizador.nome_completo, numero: utilizador.nr_aluno, formador: utilizador.role === "FORMADOR" }))}>
                        Estatísticas
                    </Link>
                </Menu.Item>
                {(!this.state.lista_ativo && (acesso?.delete || acessoProf?.update)) && (
                    <Menu.Item key="5">
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.excluirUtilizador(utilizador.id);
                            }}
                        >
                            Excluir
                        </Link>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    menuMobile = utilizador => {
        const acesso = this.context.acessos?.find(x => x.func === 101);
        const acessoProf = this.context.acessos?.find(x => x.func === 125);
        const valoresPermitidosPerfis = [114, 115, 116];
        const acessoPerfis = this.context.acessos?.filter(x => valoresPermitidosPerfis.includes(x.func))?.map(x => x.func);
        const valoresPermitidosEstabelecimentos = [117, 118, 119];
        const acessoEstabelecimentos = this.context.acessos?.filter(x => valoresPermitidosEstabelecimentos.includes(x.func))?.map(x => x.func);
        const existePerfilAluno = utilizador.estabelecimentos.some(estabelecimento =>
            estabelecimento.perfils.includes("Aluno")
        );
        const existePerfilProfessor = utilizador.estabelecimentos.some(estabelecimento =>
            estabelecimento.perfils.includes("Professor")
        );

        return (
            <Menu>
                <Menu.Item>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.abrirDetalhe(utilizador);
                        }}
                    >
                        Detalhe
                    </Link>
                </Menu.Item>
                {(acessoPerfis && acessoPerfis.includes(114) || acessoPerfis && acessoPerfis.includes(115) && existePerfilAluno || acessoPerfis && acessoPerfis.includes(116) && acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="0">
                        <Link
                            to="#"
                            onClick={() => this.setState({ visiblePerfis: true, utilizadorid: utilizador.id })}
                        >
                            Perfis
                        </Link>
                    </Menu.Item>)}
                {(acessoEstabelecimentos && acessoEstabelecimentos.includes(117) || acessoEstabelecimentos && acessoEstabelecimentos.includes(118) && existePerfilAluno || acessoEstabelecimentos && acessoEstabelecimentos.includes(119) && acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="1">
                        <Link
                            to="#"
                            onClick={() => this.setState({ visibleEstabelecimentos: true, utilizadorid: utilizador.id })}
                        >
                            Estabelecimentos
                        </Link>
                    </Menu.Item>)}
                {(!existePerfilProfessor || acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="2">
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.ativarInativarColaborador(utilizador.id);
                            }}
                        >
                            {this.state.lista_ativo ? "Inativar" : "Ativar"}
                        </Link>
                    </Menu.Item>)}
                {(!existePerfilProfessor || acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="3">
                        <Link
                            to="#"
                            onClick={() => this.setState({
                                visibleAlterarSenha: true,
                                nome_completo: utilizador.nome_completo,
                                utilizadorid: utilizador.id
                            })}
                        >
                            Alterar Senha
                        </Link>
                    </Menu.Item>)}
                {(!existePerfilProfessor || acessoProf?.update && existePerfilProfessor) && (
                    <Menu.Item key="4">
                        <Link
                            to="#"
                            onClick={() => this.setState({
                                visibleAlterarLogin: true,
                                nome_completo: utilizador.nome_completo,
                                loginAtual: utilizador.email,
                                utilizadorid: utilizador.id
                            })}
                        >
                            Alterar Login
                        </Link>
                    </Menu.Item>)}
                <Menu.Item>
                    <Link to={"/gestao-utilizadores-estatisticas"} onClick={() => localStorage.setItem("sis_utilizador", JSON.stringify({ id: utilizador.id, foto: utilizador.foto, nome: utilizador.nome_completo, numero: utilizador.nr_aluno, formador: utilizador.role === "FORMADOR" }))}>
                        Estatísticas
                    </Link>
                </Menu.Item>
                {(!this.state.lista_ativo && (acesso?.delete || acessoProf?.update)) && (
                    <Menu.Item key="5">
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.excluirUtilizador(utilizador.id);
                            }}
                        >
                            Excluir
                        </Link>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    excluirUtilizador = utilizadorId => {
        confirm({
            title: "Pretende excluir este usuário?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/colaboradores/excluir-utilizador",
                    params: {
                        utilizadorId
                    }
                })
                    .then(() => {
                        this.total();
                        this.listar();
                        this.carregarTotalLicencas();

                        openNotificationWithIcon("success", "Sucesso", "Usuário excluido!");
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o usuário");
                    });
            }
        });
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //UPLOAD DE UMA IMAGEM PARA O FORMADOR
    uploadFoto = event => {
        if (event.target.files[0].size < 100 * 1024)
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    foto: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        const formatos = ["jpg", "jpeg", "png", "gif"];
        let valido = false;
        for (let i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //REMOVE A FOTO
    removerFoto = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            foto: "",
            preview: ""
        });
    };

    ExportarDados = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/exportar-utilizadores",
                    params: {
                        pesquisa: this.state.pesquisa,
                        perfilId: this.state.filtro,
                        filtro: this.state.filtro,
                        estabelecimentoId: this.state.filtroEscolaId,
                        ativo: this.state.lista_ativo,
                        perfilAtual: this.context.perfil?.id,
                        listarAssociados: this.state.listaAssociados
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => { });
            }
        );
    };

    ExportarDadosInscricao = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/exportar-utilizadores-inscricao",
                    params: {
                        pesquisa: this.state.pesquisa,
                        perfilId: this.state.filtro,
                        filtro: this.state.filtro,
                        estabelecimentoId: this.state.filtroEscolaId,
                        ativo: this.state.lista_ativo,
                        perfilAtual: this.context.perfil?.id,
                        listarAssociados: this.state.listaAssociados
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => { });
            }
        );
    };

    //FECHA O DRAWER SEM GUARDAR
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    onClosePesquisa = () => {
        this.total();
        this.listar();
        this.setState({
            visibleAssociar: false
        });
    };

    onClosePesquisaUtilizador = () => {
        this.total();
        this.listar();
        this.setState({
            visibleAssociarUtilizador: false
        });
    };

    pesquisar = value => {
        if (value) {
            this.setState(
                {
                    loading_pesquisa: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/colaboradores/pesquisar",
                        params: {
                            pesquisa: value
                        }
                    })
                        .then(response => {
                            this.setState({
                                pesquisar_utilizadores: response.data,
                                loading_pesquisa: false
                            });
                        })
                        .catch(() => { });
                }
            );
        } else
            this.setState({
                pesquisar_utilizadores: []
            });
    };

    pesquisarUtilizador = value => {
        if (value) {
            this.setState(
                {
                    loading_pesquisa: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/colaboradores/pesquisar-utilizador",
                        params: {
                            pesquisa: value
                        }
                    })
                        .then(response => {
                            this.setState({
                                pesquisar_utilizadores: response.data,
                                loading_pesquisa: false
                            });
                        })
                        .catch(() => { });
                }
            );
        } else
            this.setState({
                pesquisar_utilizadores: []
            });
    };

    disabledDate = current => {
        return current && current > moment().endOf("day");
    };

    validarLink = (rule, value, callback) => {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
            "(\\?[;&a-z\\d%_.~+=-]*)?" +
            "(\\#[-a-z\\d_]*)?$",
            "i"
        );
        if (!pattern.test(value) && value) callback("Introduza uma página válida");

        callback();
    };

    abrirDetalhe = utilizador => {
        const detalheEntidade = utilizador.emp_nome ? (
            <>
                <div className="container-img">
                    <img src={utilizador.emp_logo ? utilizador.emp_logo : noimage} alt="Foto" />
                </div>
                <div className="head">
                    <span className="nome">{utilizador.emp_nome}</span>
                </div>
            </>
        ) : (
            ""
        );

        this.setState({
            visibleDetalhe: true,
            detalheEntidade,
            detalheLogin: utilizador.ultimoLogin
        });
    };

    montarRegistoFiltros = () => {
        const registos = {
            pesquisa: this.state.pesquisa,
            perfil: this.state.filtro,
            estabelecimento: this.state.filtroEscolaId,
            pagina: this.state.current
        }

        this.context.atualizarState({
            pesquisaInfo: registos,
            filtroEstabelecimentosUtilizado: false
        });
    }

    render() {
        const { TextArea } = Input;
        const { Option } = Select;
        const { getFieldDecorator } = this.props.form;
        const { Panel } = Collapse;
        const { Search } = Input;

        const {
            //FILTROS
            pesquisa,
            filtro,
            associado,
            filtroEscolaId,
            //
            titulo,
            escolas,
            //DRAWER
            utilizadorid,
            escolaId,
            foto,
            preview,
            //DADOS GERAIS
            nome_completo,
            nr_aluno,
            emailnotif,
            contacto,
            descricao,
            habilitacao,
            paginaPessoal,
            dt_nascimento,
            naturalidade,
            sexo,
            estadoCivil,
            cc,
            dt_ccvalidade,
            nif,
            cep,
            localidade,
            morada,
            pais,
            //FILIAÇÃO
            maeResponsavel,
            paiResponsavel,
            //RESPONSAVEL
            responsavelNome,
            responsavelEmail,
            responsavelContacto,
            //PERMISSOES
            podeAlterarDadosPessoais,
            //LOADING
            iconLoading,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //REDIRECT
            redirect,
            redirectLink,
            //PAGINATION
            current,
            pageSize,
            total,
            //LOADING COLABORADORES
            loadingLista,
            //MODAL
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //DRAWER ALTERAR SENHA
            visibleAlterarSenha,
            //DRAWER ALTERAR LOGIN
            visibleAlterarLogin,
            //DRAWER DETALHE
            visibleDetalhe,
            detalheEntidade,
            detalheLogin,
            //MODAL EMITIR DECLARACAO
            visibleEmitirDeclaracao,
            //DRAWER PERFIS
            visiblePerfis,
            //DRAWER ESTABELECIMENTO
            visibleEstabelecimentos,
            //
            loginAtual
        } = this.state;

        const acesso = this.context.acessos?.find(x => x.func === 101);

        const menu = (
            <Menu>
                <Menu.Item onClick={this.ExportarDados}>Exportar</Menu.Item>
                <Menu.Item onClick={this.ExportarDadosInscricao}>Exportar para Inscrição</Menu.Item>
            </Menu>
        );

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Acessos funcionalidadeId={101} />
                <Header titulo={`Usuários - ${titulo ? titulo : this.context.estabelecimento?.nome}`} />
                <div className="container container-body">
                    <div className="bloco-colaboradores">
                        <div className="bloco-grid">
                            <div className="bloco-esquerda">
                                <div className="info-colaboradores">
                                    <HeaderColaboradores
                                        pesquisa={pesquisa}
                                        perfilId={filtro}
                                        associadosId={associado}
                                        estabelecimentoId={filtroEscolaId}
                                        headerTodos
                                        nr_colaboradores={this.state.colaboradores.length}
                                        HandleKeyPress={this.HandleKeyPress}
                                        handleChangeSwitch={this.handleChangeSwitch}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                        tem_filtro
                                        tem_switch
                                        filtrar={this.filtrar}
                                        filtraAssociados={this.filtrarAssociados}
                                        filtrarOrganismo={this.filtrarOrganismo}
                                        filtros
                                        escolas={escolas}
                                        temEscolas
                                    />
                                </div>
                                <ListaColaboradores
                                    todosAtivo="ativo"
                                    totalLicencas={this.state.totalLicencas}
                                    licencasUtilizadas={this.state.licencasUtilizadas}
                                    acesso={acesso}
                                />
                            </div>
                            <div className="bloco-direita">
                                <div className="info-colaboradores">
                                    <HeaderColaboradores
                                        headerTodos
                                        nr_colaboradores={this.state.colaboradores.length}
                                        botoesMensagens
                                        menu={menu}
                                        valor={this.state.filtro}
                                    />
                                </div>
                                <div className="conteudo-colaboradores-todos">
                                    {loadingLista ? (
                                        <div className="loading-data">
                                            <div className="loading" />
                                        </div>
                                    ) : this.state.colaboradores.length > 0 ? (
                                        this.state.colaboradores.map((colaborador, index) => (
                                            <Colaborador
                                                key={index}
                                                primeiro={index === 0}
                                                colaborador={colaborador}
                                                tem_definicoes
                                                menu={this.menu(colaborador)}
                                                menuMobile={this.menuMobile(colaborador)}
                                                filtro={this.state.filtro}
                                                organismos={this.context.escolas_entidade}
                                                acesso={acesso}
                                            />
                                        ))
                                    ) : (
                                        <Oops />
                                    )}
                                </div>
                                {!loadingLista && (
                                    <div className="pagination-block">
                                        {total > pageSize && (
                                            <Select placeholder="Selecionar" value={pageSize} onChange={this.onChangePageSize}>
                                                <Option key={10}>10</Option>
                                                <Option key={20}>20</Option>
                                                <Option key={50}>50</Option>
                                                <Option key={100}>100</Option>
                                            </Select>
                                        )}
                                        <Pagination
                                            current={current}
                                            total={total}
                                            pageSize={pageSize}
                                            onChange={this.paginar}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} registos`}
                                            hideOnSinglePage={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos"
                    //title="Alterar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-img">
                        <Form>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="container-img">
                                    <img src={preview ? preview : noimage} />
                                </div>
                                <input
                                    type="file"
                                    id="input-foto"
                                    accept="image/*"
                                    onChange={this.uploadFoto}
                                    style={{ display: "none" }}
                                />
                                {!preview ? (
                                    <>
                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                            <span>Adicionar</span>
                                        </Link>{" "}
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <>
                                                    <p>Tamanho máximo: 100KB</p>
                                                    <p>Tamanho recomendado: 200x200px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <Link to="#" className="link-principal-border btn-alterar" onClick={this.ativarUploadFoto}>
                                            <span>Alterar</span>
                                        </Link>
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <>
                                                    <p>Tamanho máximo: 100KB</p>
                                                    <p>Tamanho recomendado: 200x200px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                        <Link
                                            to="#"
                                            className="link-principal-border btn-remover"
                                            onClick={this.removerFoto}
                                            style={{ marginLeft: 20 }}
                                        >
                                            <span>Remover</span>
                                        </Link>
                                    </>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Nome Completo">
                                    {getFieldDecorator("nome-completo", {
                                        initialValue: nome_completo,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                                whitespace: true
                                            }
                                        ]
                                    })(<Input name="nome_completo" onChange={this.handleChange} />)}
                                </Form.Item>
                                {this.context.escolas_entidade && (
                                    <Form.Item label="Unidade Organizacional">
                                        {getFieldDecorator("organismo", {
                                            initialValue: escolaId,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(
                                            <Select placeholder="Selecionar" onChange={this.handleChangeSelectOrganismo}>
                                                {escolas.map(escola => (
                                                    <Option key={escola.id} value={escola.id}>
                                                        {escola.nome}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                )}
                                <Form.Item label="Código">
                                    {getFieldDecorator("nr_aluno", {
                                        initialValue: nr_aluno
                                    })(<Input name="nr_aluno" className="input-50" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <>
                                            E-mail{" "}
                                            <Tooltip className="info-icon" title="E-mail usado para as notificações...">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    {getFieldDecorator("email", {
                                        rules: [
                                            {
                                                type: "email",
                                                message: "Introduza um endereço de e-mail válido"
                                            },
                                            {
                                                required: false
                                            }
                                        ],
                                        initialValue: emailnotif
                                    })(<Input name="emailnotif" enabled="true" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label="Telefone / Celular">
                                    <Input value={contacto} name="contacto" className="input-50" onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <>
                                            Breve Descrição{" "}
                                            <Tooltip className="info-icon" title="Resumo sobre esta pessoa...">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    <TextArea
                                        value={descricao}
                                        name="descricao"
                                        onChange={this.handleChange}
                                        rows={4}
                                        style={{ height: 100 }}
                                    />
                                </Form.Item>
                                <Form.Item label="Habilitações">
                                    <TextArea
                                        value={habilitacao}
                                        name="habilitacao"
                                        onChange={this.handleChange}
                                        rows={3}
                                        style={{ height: 100 }}
                                    />
                                </Form.Item>
                                <Form.Item label="Página Web pessoal">
                                    {getFieldDecorator("paginaPessoal", {
                                        rules: [
                                            {
                                                validator: this.validarLink
                                            }
                                        ],
                                        initialValue: paginaPessoal
                                    })(<Input name="paginaPessoal" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label="Data de nascimento">
                                    {getFieldDecorator("dt_nascimento", {
                                        initialValue: dt_nascimento
                                    })(
                                        <DatePicker
                                            className="input-25"
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            name="dt_ccvalidade"
                                            onChange={this.handleChangeDataNascimento}
                                            disabledDate={this.disabledDate}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Naturalidade">
                                    <Input value={naturalidade} name="naturalidade" onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Sexo">
                                    <Select
                                        className="input-50"
                                        placeholder="Selecionar"
                                        name="certificacao"
                                        allowClear={true}
                                        value={sexo}
                                        onChange={sexo =>
                                            this.setState({
                                                sexo
                                            })
                                        }
                                    >
                                        <Option value="MASCULINO">Masculino</Option>
                                        <Option value="FEMININO">Feminino</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Estado Civil">
                                    {getFieldDecorator("estadoCivil", {
                                        initialValue: estadoCivil
                                    })(
                                        <Select
                                            className="input-50"
                                            placeholder="Selecionar"
                                            allowClear={true}
                                            onChange={estadoCivil =>
                                                this.setState({
                                                    estadoCivil
                                                })
                                            }
                                        >
                                            <Option key="Solteiro(a)">Solteiro(a)</Option>
                                            <Option key="Casado(a)">Casado(a)</Option>
                                            <Option key="Viúvo(a)">Viúvo(a)</Option>
                                            <Option key="Separado(a) Judicialmente">Separado(a) Judicialmente</Option>
                                            <Option key="Divorciado(a)">Divorciado(a)</Option>
                                            <Option key="Outro">Outro</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <>
                                            Identificação / RG
                                            <Tooltip className="info-icon" title="Documento de Identificação, Passaporte, ...">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    <Input value={cc} name="cc" onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <>
                                            Validade
                                            <Tooltip className="info-icon" title="Validade do documento de identificação">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    <DatePicker
                                        className="input-25"
                                        placeholder="Selecionar"
                                        format="DD-MM-YYYY"
                                        name="dt_ccvalidade"
                                        value={dt_ccvalidade}
                                        onChange={this.handleChangeDate}
                                    />
                                </Form.Item>
                                <Form.Item label="Dados Fiscais / CPF">
                                    <Input value={nif} name="nif" className="input-50" onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Código Postal / CEP">
                                    <Input value={cep} name="cep" className="input-50" onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Localidade / Estado">
                                    <Input value={localidade} name="localidade" onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Endereço">
                                    <TextArea value={morada} name="morada" onChange={this.handleChange} rows={4} style={{ height: 100 }} />
                                </Form.Item>
                                <Form.Item label="País">
                                    <Select
                                        showSearch
                                        placeholder="Selecionar"
                                        value={pais}
                                        allowClear={true}
                                        onChange={this.handleChangeSelect}
                                    >
                                        <Option value="África do Sul">África do Sul</Option>
                                        <Option value="Albânia">Albânia</Option>
                                        <Option value="Alemanha">Alemanha</Option>
                                        <Option value="Andorra">Andorra</Option>
                                        <Option value="Angola">Angola</Option>
                                        <Option value="Anguilla">Anguilla</Option>
                                        <Option value="Antigua">Antigua</Option>
                                        <Option value="Arábia Saudita">Arábia Saudita</Option>
                                        <Option value="Argentina">Argentina</Option>
                                        <Option value="Armênia">Armênia</Option>
                                        <Option value="Aruba">Aruba</Option>
                                        <Option value="Austrália">Austrália</Option>
                                        <Option value="Áustria">Áustria</Option>
                                        <Option value="Azerbaijão">Azerbaijão</Option>
                                        <Option value="Bahamas">Bahamas</Option>
                                        <Option value="Bahrein">Bahrein</Option>
                                        <Option value="Bangladesh">Bangladesh</Option>
                                        <Option value="Barbados">Barbados</Option>
                                        <Option value="Bélgica">Bélgica</Option>
                                        <Option value="Benin">Benin</Option>
                                        <Option value="Bermudas">Bermudas</Option>
                                        <Option value="Botsuana">Botsuana</Option>
                                        <Option value="Brasil" selected>
                                            Brasil
                                        </Option>
                                        <Option value="Brunei">Brunei</Option>
                                        <Option value="Bulgária">Bulgária</Option>
                                        <Option value="Burkina Fasso">Burkina Fasso</Option>
                                        <Option value="botão">botão</Option>
                                        <Option value="Cabo Verde">Cabo Verde</Option>
                                        <Option value="Camarões">Camarões</Option>
                                        <Option value="Camboja">Camboja</Option>
                                        <Option value="Canadá">Canadá</Option>
                                        <Option value="Cazaquistão">Cazaquistão</Option>
                                        <Option value="Chade">Chade</Option>
                                        <Option value="Chile">Chile</Option>
                                        <Option value="China">China</Option>
                                        <Option value="Cidade do Vaticano">Cidade do Vaticano</Option>
                                        <Option value="Colômbia">Colômbia</Option>
                                        <Option value="Congo">Congo</Option>
                                        <Option value="Coréia do Sul">Coréia do Sul</Option>
                                        <Option value="Costa do Marfim">Costa do Marfim</Option>
                                        <Option value="Costa Rica">Costa Rica</Option>
                                        <Option value="Croácia">Croácia</Option>
                                        <Option value="Dinamarca">Dinamarca</Option>
                                        <Option value="Djibuti">Djibuti</Option>
                                        <Option value="Dominica">Dominica</Option>
                                        <Option value="EUA">EUA</Option>
                                        <Option value="Egito">Egito</Option>
                                        <Option value="El Salvador">El Salvador</Option>
                                        <Option value="Emirados Árabes">Emirados Árabes</Option>
                                        <Option value="Equador">Equador</Option>
                                        <Option value="Eritréia">Eritréia</Option>
                                        <Option value="Escócia">Escócia</Option>
                                        <Option value="Eslováquia">Eslováquia</Option>
                                        <Option value="Eslovênia">Eslovênia</Option>
                                        <Option value="Espanha">Espanha</Option>
                                        <Option value="Estônia">Estônia</Option>
                                        <Option value="Etiópia">Etiópia</Option>
                                        <Option value="Fiji">Fiji</Option>
                                        <Option value="Filipinas">Filipinas</Option>
                                        <Option value="Finlândia">Finlândia</Option>
                                        <Option value="França">França</Option>
                                        <Option value="Gabão">Gabão</Option>
                                        <Option value="Gâmbia">Gâmbia</Option>
                                        <Option value="Gana">Gana</Option>
                                        <Option value="Geórgia">Geórgia</Option>
                                        <Option value="Gibraltar">Gibraltar</Option>
                                        <Option value="Granada">Granada</Option>
                                        <Option value="Grécia">Grécia</Option>
                                        <Option value="Guadalupe">Guadalupe</Option>
                                        <Option value="Guam">Guam</Option>
                                        <Option value="Guatemala">Guatemala</Option>
                                        <Option value="Guiana">Guiana</Option>
                                        <Option value="Guiana Francesa">Guiana Francesa</Option>
                                        <Option value="Guiné-bissau">Guiné-bissau</Option>
                                        <Option value="Haiti">Haiti</Option>
                                        <Option value="Holanda">Holanda</Option>
                                        <Option value="Honduras">Honduras</Option>
                                        <Option value="Hong Kong">Hong Kong</Option>
                                        <Option value="Hungria">Hungria</Option>
                                        <Option value="Iêmen">Iêmen</Option>
                                        <Option value="Ilhas Cayman">Ilhas Cayman</Option>
                                        <Option value="Ilhas Cook">Ilhas Cook</Option>
                                        <Option value="Ilhas Curaçao">Ilhas Curaçao</Option>
                                        <Option value="Ilhas Marshall">Ilhas Marshall</Option>
                                        <Option value="Ilhas Turks & Caicos">Ilhas Turks & Caicos</Option>
                                        <Option value="Ilhas Virgens (brit.)">Ilhas Virgens (brit.)</Option>
                                        <Option value="Ilhas Virgens(amer.)">Ilhas Virgens(amer.)</Option>
                                        <Option value="Ilhas Wallis e Futuna">Ilhas Wallis e Futuna</Option>
                                        <Option value="Índia">Índia</Option>
                                        <Option value="Indonésia">Indonésia</Option>
                                        <Option value="Inglaterra">Inglaterra</Option>
                                        <Option value="Irlanda">Irlanda</Option>
                                        <Option value="Islândia">Islândia</Option>
                                        <Option value="Israel">Israel</Option>
                                        <Option value="Itália">Itália</Option>
                                        <Option value="Jamaica">Jamaica</Option>
                                        <Option value="Japão">Japão</Option>
                                        <Option value="Jordânia">Jordânia</Option>
                                        <Option value="Kuwait">Kuwait</Option>
                                        <Option value="Latvia">Latvia</Option>
                                        <Option value="Líbano">Líbano</Option>
                                        <Option value="Liechtenstein">Liechtenstein</Option>
                                        <Option value="Lituânia">Lituânia</Option>
                                        <Option value="Luxemburgo">Luxemburgo</Option>
                                        <Option value="Macau">Macau</Option>
                                        <Option value="Macedônia">Macedônia</Option>
                                        <Option value="Madagascar">Madagascar</Option>
                                        <Option value="Malásia">Malásia</Option>
                                        <Option value="Malaui">Malaui</Option>
                                        <Option value="Mali">Mali</Option>
                                        <Option value="Malta">Malta</Option>
                                        <Option value="Marrocos">Marrocos</Option>
                                        <Option value="Martinica">Martinica</Option>
                                        <Option value="Mauritânia">Mauritânia</Option>
                                        <Option value="Mauritius">Mauritius</Option>
                                        <Option value="México">México</Option>
                                        <Option value="Moldova">Moldova</Option>
                                        <Option value="Mônaco">Mônaco</Option>
                                        <Option value="Montserrat">Montserrat</Option>
                                        <Option value="Nepal">Nepal</Option>
                                        <Option value="Nicarágua">Nicarágua</Option>
                                        <Option value="Niger">Niger</Option>
                                        <Option value="Nigéria">Nigéria</Option>
                                        <Option value="Noruega">Noruega</Option>
                                        <Option value="Nova Caledônia">Nova Caledônia</Option>
                                        <Option value="Nova Zelândia">Nova Zelândia</Option>
                                        <Option value="Omã">Omã</Option>
                                        <Option value="Palau">Palau</Option>
                                        <Option value="Panamá">Panamá</Option>
                                        <Option value="Papua-nova Guiné">Papua-nova Guiné</Option>
                                        <Option value="Paquistão">Paquistão</Option>
                                        <Option value="Peru">Peru</Option>
                                        <Option value="Polinésia Francesa">Polinésia Francesa</Option>
                                        <Option value="Polônia">Polônia</Option>
                                        <Option value="Porto Rico">Porto Rico</Option>
                                        <Option value="Portugal">Portugal</Option>
                                        <Option value="Qatar">Qatar</Option>
                                        <Option value="Quênia">Quênia</Option>
                                        <Option value="Rep. Dominicana">Rep. Dominicana</Option>
                                        <Option value="Rep. Tcheca">Rep. Tcheca</Option>
                                        <Option value="Reunion">Reunion</Option>
                                        <Option value="Romênia">Romênia</Option>
                                        <Option value="Ruanda">Ruanda</Option>
                                        <Option value="Rússia">Rússia</Option>
                                        <Option value="Saipan">Saipan</Option>
                                        <Option value="Samoa Americana">Samoa Americana</Option>
                                        <Option value="Senegal">Senegal</Option>
                                        <Option value="Serra Leone">Serra Leone</Option>
                                        <Option value="Seychelles">Seychelles</Option>
                                        <Option value="Singapura">Singapura</Option>
                                        <Option value="Síria">Síria</Option>
                                        <Option value="Sri Lanka">Sri Lanka</Option>
                                        <Option value="St. Kitts & Nevis">St. Kitts & Nevis</Option>
                                        <Option value="St. Lúcia">St. Lúcia</Option>
                                        <Option value="St. Vincent">St. Vincent</Option>
                                        <Option value="Sudão">Sudão</Option>
                                        <Option value="Suécia">Suécia</Option>
                                        <Option value="Suiça">Suiça</Option>
                                        <Option value="Suriname">Suriname</Option>
                                        <Option value="Tailândia">Tailândia</Option>
                                        <Option value="Taiwan">Taiwan</Option>
                                        <Option value="Tanzânia">Tanzânia</Option>
                                        <Option value="Togo">Togo</Option>
                                        <Option value="Trinidad & Tobago">Trinidad & Tobago</Option>
                                        <Option value="Tunísia">Tunísia</Option>
                                        <Option value="Turquia">Turquia</Option>
                                        <Option value="Ucrânia">Ucrânia</Option>
                                        <Option value="Uganda">Uganda</Option>
                                        <Option value="Uruguai">Uruguai</Option>
                                        <Option value="Venezuela">Venezuela</Option>
                                        <Option value="Vietnã">Vietnã</Option>
                                        <Option value="Zaire">Zaire</Option>
                                        <Option value="Zâmbia">Zâmbia</Option>
                                        <Option value="Zimbábue">Zimbábue</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                    Filiação
                                </h3>
                                <Form.Item label="Nome da Mãe">
                                    <Input value={maeResponsavel} name="maeResponsavel" onChange={this.handleChange} />
                                </Form.Item>
                                <Form.Item label="Nome do Pai">
                                    <Input value={paiResponsavel} name="paiResponsavel" onChange={this.handleChange} />
                                </Form.Item>
                            </div>
                            {this.context.responsaveis_entidade && (
                                <div className="bloco">
                                    <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                                        Responsável
                                    </h3>
                                    <Form.Item label="Nome">
                                        <Input value={responsavelNome} name="responsavelNome" onChange={this.handleChange} />
                                    </Form.Item>
                                    <Form.Item label="E-mail">
                                        {getFieldDecorator("responsavelEmail", {
                                            rules: [
                                                {
                                                    type: "email",
                                                    message: "Introduza um endereço de e-mail válido"
                                                },
                                                {
                                                    required: false
                                                }
                                            ],
                                            initialValue: responsavelEmail
                                        })(<Input name="responsavelEmail" onChange={this.handleChange} />)}
                                    </Form.Item>
                                    <Form.Item label="Telefone / Celular">
                                        <Input
                                            value={responsavelContacto}
                                            name="responsavelContacto"
                                            className="input-25"
                                            onChange={this.handleChange}
                                        />
                                    </Form.Item>
                                </div>
                            )}
                            <div className="bloco">
                                <Form.Item>
                                    <Checkbox
                                        name="podeAlterarDadosPessoais"
                                        checked={podeAlterarDadosPessoais}
                                        onChange={this.handleChangeCheckbox}
                                    >
                                        Pode alterar os dados pessoais
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        <button className="botao-principal" onClick={this.alterar} disabled={iconLoading} type="primary">
                            {iconLoading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>
                    </div>
                </Drawer>
                <DrawerAlterarSenha
                    visible={visibleAlterarSenha}
                    utilizadorid={utilizadorid}
                    nome_completo={nome_completo}
                    onClose={() => this.setState({ visibleAlterarSenha: false })}
                />
                <DrawerAlterarLogin
                    visible={visibleAlterarLogin}
                    utilizadorid={utilizadorid}
                    nome_completo={nome_completo}
                    loginAtual={loginAtual}
                    onClose={() => this.setState({ visibleAlterarLogin: false })}
                    onCloseAtualizar={() => {
                        this.setState({ visibleAlterarLogin: false });
                        this.listar();
                    }}
                />
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
                <Drawer
                    className="drawer-detalhe-utilizador"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Entidade">{detalheEntidade}</Form.Item>
                                <Form.Item label="Último login">{detalheLogin}</Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <ModalSelecionarDeclaracao
                    visibleEmitirDeclaracao={visibleEmitirDeclaracao}
                    onCancel={() => this.setState({ visibleEmitirDeclaracao: false })}
                />
                <DrawerPerfis
                    utilizadorId={utilizadorid}
                    visible={visiblePerfis}
                    onClose={() => this.setState({ visiblePerfis: false })}
                    onUpdate={() => {
                        this.setState({ visiblePerfis: false });
                        this.listar();
                    }}
                />
                <DrawerEstabelecimentos
                    utilizadorId={utilizadorid}
                    visible={visibleEstabelecimentos}
                    onClose={() => this.setState({ visibleEstabelecimentos: false })}
                    onUpdate={() => {
                        this.setState({ visibleEstabelecimentos: false });
                        this.listar();
                    }}
                />
            </>
        );
    }
}

const ContextColaboradoresTodos = ({ form }) => (
    <GlobalContext.Consumer>
        {({ estabelecimento, perfil }) => <ColaboradoresTodos estabelecimento={estabelecimento} perfil={perfil} form={form} />}
    </GlobalContext.Consumer>
);

const FormColaboradoresTodos = Form.create({ name: "form-colaboradores" })(ContextColaboradoresTodos);

export default FormColaboradoresTodos;
