import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Drawer,
    Icon,
    Timeline
} from "antd";
import axios from "axios";
import moment from "moment";

import ModalLogDetail from "../Modal/ModalLogDetail"

const DrawerLogs = ({ notaId, visible, onClose }) => {
    const [logs, setLogs] = useState([]);
    const [logDetail, setLogDetail] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const carregarLogs = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/logs/carregar-logs-nota",
            params: {
                notaId
            }
        })
            .then(response => {
                setLogs(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const afterVisibleChange = aberto => {
        if (aberto) {
            carregarLogs();
        }
    }

    const montarAcao = (log) => {
        const tipo = log.controladora.includes("observacoes") ? "Observação" : "Nota"
        switch (log.acao) {
            case "ALTERACAO":
                return <Timeline.Item className="timeline-item-clickable" dot={<Icon type="edit" className="timeline-icon" />} onClick={() => abrirDetail(log)}>{tipo} alterada por {log.autor} a {moment(log.data_hora).format("DD/MM/YYYY HH:mm")}</Timeline.Item>;
            case "EXCLUSÃO":
                return <Timeline.Item dot={<Icon type="close" className="timeline-icon" />}>{tipo} excluída por {log.autor} a {moment(log.data_hora).format("DD/MM/YYYY HH:mm")}</Timeline.Item>;
        }
    }

    const abrirDetail = (log) => {
        setLogDetail(log);
        setModalVisible(true);
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <Drawer
                className="drawer-add-cursos drawer-logs"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-info">
                    <h1>Lista de logs</h1>
                    <div className="bloco-timeline">
                        {loading && (<div className="loading-data-table">
                            <div className="loading" />
                        </div>)}
                        {!loading && (
                            <Timeline>
                                {logs.map(log => montarAcao(log))}
                            </Timeline>)}
                    </div>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose}>
                        Voltar
                    </button>
                </div>
            </Drawer>
            <ModalLogDetail
                logDetail={logDetail}
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
            />
        </>
    );
}

export default DrawerLogs;