import React, {useEffect, useState} from "react";
import { FaRegCommentDots } from "react-icons/fa6";
import { notification } from "antd";
import { useMediaQuery } from "react-responsive";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export default function AdicionarComentario({idNoticia,comentarioResposta,idComentarioResponder,trocarComentario}) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [errors, setErrors] = useState({});
    const [comentario, setComentario] = useState({
        nome:"",
        email:"",
        comentario:"",
        idNoticia: idNoticia,
        IdComentario: idComentarioResponder
        
    })
    
    useEffect(() => {
        setComentario(prevState => ({ ...prevState, IdComentario: idComentarioResponder }));
    }, [idComentarioResponder]);

    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setComentario(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevState => ({
            ...prevState,
            [name]: ""
        }));
    };

    const validate = () => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex para validar email

        if (!comentario.nome) {
            errors.nome = 'Nome é obrigatório.';
        }

        if (!comentario.email) {
            errors.email = 'Email é obrigatório.';
        }
        else if (!emailRegex.test(comentario.email)) {
            errors.email = 'Email inválido.';
        }

        if (!comentario.comentario) {
            errors.comentario = 'Comentário é obrigatório.';
        }

        return errors;
    };

    async function handleSubmit() {
        
        // Validate fields
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        
        try {
            const response = await fetch(comentarioResposta ? ('/api/pagina-publica/resposta_comentario/adicionar'):('/api/pagina-publica/comentario/adicionar'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(comentario),
            });

            if (response.ok) {
                openNotificationWithIcon("success", "Sucesso","Comentário adicionado!")

                setComentario({
                    nome: '',
                    email: '',
                    comentario: '',
                });
                setErrors({});
            }
            else {
                openNotificationWithIcon("error", "Erro", "Não foi possível adicionar o comentário!")
            }
        } catch (error) {
            openNotificationWithIcon("error", "Erro", "Não foi possível adicionar o comentário!")
        }
    };
    
   
    
    return(
        <div className="adicionarComentario">
            <div className="justificar-direita">
                <div className={"retanguloPequeno"}>
                </div>
                <span className="titulo" style={{fontSize: "20px"}}>Add Comentário</span>
            </div>
            <div className="comment-form">
                <div style={{flex:1}}>
                    <div className="input-group">
                        <label htmlFor="name">Nome: </label>
                        <input type="text" 
                               id="name" 
                               name="nome"
                               value={comentario.nome}
                               onChange={handleChange}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="email">Email: </label>
                        <input type="email" 
                               id="email" 
                               name="email"
                               value={comentario.email}
                               onChange={handleChange}
                        />
                    </div>
                    <ul className="errors-list">
                        {errors.nome && (<li>{errors.nome}</li>)}
                        {errors.email && (<li>{errors.email}</li>)}
                        {errors.comentario && (<li>{errors.comentario}</li>)}
                    </ul>
                </div>
                <div className="comment-group">
                    <label htmlFor="comment">Comentário: </label>
                    <textarea id="comment" 
                              name="comentario"
                              value={comentario.comentario}
                              onChange={handleChange}
                              rows= {isTabletOrMobile ? 4 : 8}>
                        
                    </textarea>
                </div>
            </div>
            <div className="enviarComentario">
                {comentarioResposta && (
                    <div className="botao" onClick={trocarComentario}>
                        <div className="botaoEnviar cinza">
                            <span style={{color: "white"}}>Cancelar</span>
                        </div>
                    </div>

                )}
                <div className="botao" onClick={handleSubmit}>
                    <div className="botaoEnviar">
                        <FaRegCommentDots style={{color: "white", fontSize: "16px"}}/>
                        <span
                            style={{ color: "white", marginLeft: 5 }}>{comentarioResposta ? ("Enviar Resposta Comentário") : ('Enviar Comentário')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}