import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Alert, notification, Icon } from "antd";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class VideoConfCodigo extends Component {
    static contextType = GlobalContext;
    state = {
        codigo: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    componentDidMount() {
        this.validar();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1]);
    };

    validar = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-chat/validar-estado",
            params: {
                codigo: this.getParameterByName("code")
            }
        })
            .then(response => {
                if (!response.data)
                    this.setState({
                        redirect: true,
                        redirectLink: `/${this.props.match.params.entidade}/chat-inativo`
                    });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Ocorreu um erro!");
            });
    };

    //ENVIA CODIGO PARA VALIDAR SE PODE OU NAO ACEDER A VIDEOCONFERENCIA
    iniciar = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("codigo", this.state.codigo);
                item.append("entidadeId", localStorage.getItem("entidadeId"));

                axios({
                    method: "post",
                    url: "/api/pagina-entidade-chat/chat-codigo",
                    data: item
                })
                    .then(() => {
                        this.setState({
                            redirect: true,
                            redirectLink: `/${this.props.match.params.entidade}/chat?code=${this.getParameterByName("code")}`
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível confirmar o codigo!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            iconLoading,
            redirect,
            redirectLink,
        } = this.state;

        if (redirect)
            return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Validação" />
                <div className="container container-body">
                    <div className="content">
                        <Form className="suporte-contacto">
                            <Form.Item>
                                <Alert message="Foi enviado um email com o código para poder iniciar o chat" type="info" showIcon />
                            </Form.Item>
                            <Form.Item label="Código">
                                {getFieldDecorator("codigo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="codigo" onChange={this.handleChange} />)}
                            </Form.Item>
                            <div className="btn_plano">
                                <Link to="#" className="botao-principal" disabled={iconLoading} onClick={this.iniciar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Iniciar
                                </Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}
const FormVideoConfCodigo = Form.create({ name: "video-conf-codigo" })(VideoConfCodigo);

export default FormVideoConfCodigo;
