import React from 'react';

const DropdownMeses = ({ alterarMes, mes }) => {
    const meses = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const handleChange = (event) => {
        alterarMes(event.target.value);
    };

    return (
        <div>
            <select id="meses" value={meses[mes - 1]} onChange={handleChange} style={{ border: 0 }} className="custom-dropdown">
                {meses.map((mesNome, index) => (
                    <option key={index} value={mesNome}>
                        {mesNome}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownMeses;