import React, { useState, useEffect } from "react";
import { Redirect, withRouter } from 'react-router-dom';
import { Table } from 'antd';
import axios from "axios";

const Tabela = ({ match }) => {
    const [estados, setEstados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const cols = [
        {
            title: "Data",
            dataIndex: "data"
        },
        {
            title: "Turma",
            dataIndex: "turma"
        },
        {
            title: "Estado",
            dataIndex: "estado"
        },
        {
            title: "Motivo",
            dataIndex: "motivo"
        }
    ];

    const carregarEstados = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-estados-utilizador",
            params: {
                code: match.params.code
            }
        })
            .then(response => {
                setEstados(response.data)
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    useEffect(() => {
        carregarEstados();
    }, [])

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <Table
            className="tabela-documentos tabela-aproveitamento"
            columns={cols}
            pagination={false}
            dataSource={estados}
            loading={{
                spinning: loading,
                indicator: (
                    <div className="loading-data-table">
                        <div className="loading" />
                    </div>
                )
            }}
        />
    );
}

export default withRouter(Tabela);