import React from "react"
import { Link } from "react-router-dom";
import noimage from "../../../images/noimage.png";

const Participante = ({ foto, nome, numero, professor, coordenador }) => {

    const montarTitulo = (professor, coordenador) => {
        if (professor && coordenador)
            return <span className="perfil coordenador">
                Coord. / Prof.
            </span>
        else if (coordenador)
            return <span className="perfil coordenador">
                Coordenador
            </span>
        else if (professor)
            return <span className="perfil professor">
                Professor
            </span>
        else return;
    }

    return (
        <div className="cartao-participante sem-hover">
            {montarTitulo(professor, coordenador)}
            <div className="container-img">
                <img src={foto ? foto : noimage} />
            </div>
            <div className="info">
                <span className="nome">{nome.split(" ").length > 1 ? `${nome.split(" ")[0]} ${nome.split(" ").pop()}` : nome}</span>
                <span className="numero">{numero}</span>
            </div>
        </div>
    )
}

export default Participante
