import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Icon } from 'antd';

const Tabela = () => {
    const cols = [
        {
            title: "Cod.",
            dataIndex: "codigo"
        },
        {
            title: "Curso",
            dataIndex: "curso"
        },
        {
            title: "Edição",
            dataIndex: "edicao"
        },
        {
            title: "Carga Horária (H)",
            dataIndex: "carga"
        },
        {
            title: "Serviço (H)",
            dataIndex: "servico"
        },
        {
            title: "Inscritos",
            dataIndex: "inscritos"
        },
        {
            title: "Estado",
            dataIndex: "estado"
        },
        {
            title: "Coordenador",
            dataIndex: "coordenador"
        }
    ];

    const data = [
        {
            key: 1,
            codigo: "00001",
            curso: "Marketing Digital",
            edicao: "2022 / 2023",
            carga: "200",
            servico: 0,
            inscritos: 35,
            coordenador: <span className="check-mark"><Icon type="check" /></span>
        },
        {
            key: 2,
            codigo: "00001",
            curso: "Programação",
            edicao: "2022 / 2023",
            carga: "200",
            servico: 0,
            inscritos: 35,
            coordenador: <span className="check-mark"><Icon type="check" /></span>
        },
        {
            key: 3,
            codigo: "00001",
            curso: "Excel Avançado",
            edicao: "2022 / 2023",
            carga: "200",
            servico: 0,
            inscritos: 35,
            coordenador: <span className="check-mark"><Icon type="check" /></span>
        }
    ]

    return (
        <Table
            className="tabela-user-disciplinas"
            columns={cols}
            pagination={false}
            dataSource={data}
        />
    );
}

export default Tabela;