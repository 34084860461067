import React, { Component } from "react";
import { Icon, notification } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export class Formador extends Component {
    state = {
        cursosf: "",
        cursosfr: "",
        modaulascri: "",
        doc_fichs: "",
        interacoes_anotacoes: "",
        interacoes_forumf: "",
        mensagens_enviadasf: "",
        stepmeets: "",
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        const utilizador = JSON.parse(localStorage.getItem("sis_utilizador"));
        this.setState({
            utilizadorid: utilizador.id
        });
        this.carregar(utilizador.id);
    }

    componentDidUpdate() {
        if (this.props.atualizar) {
            const utilizador = JSON.parse(localStorage.getItem("sis_utilizador"));
            this.setState({
                utilizadorid: utilizador.id
            });
            this.carregar(utilizador.id);
            this.props.paraAtualizar();
        }
    }

    //CARREGA ESTATISTICAS
    carregar = utilizadorid => {
        axios({
            method: "get",
            url: "/api/gestao-estatistica/formador",
            params: {
                utilizadorid: utilizadorid,
                dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                dtfim: this.props.dt_termino.format("YYYY/MM/DD")
            }
        })
            .then(response => {
                this.setState({
                    cursosf: response.data.cursosf,
                    cursosfr: response.data.cursosfr,
                    modaulascri: response.data.modaulascri,
                    doc_fichs: response.data.doc_fichs,
                    interacoes_anotacoes: response.data.interacoes_anotacoes,
                    interacoes_forumf: response.data.interacoes_forumf,
                    mensagens_enviadasf: response.data.mensagens_enviadasf,
                    stepmeets: response.data.stepmeets
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            cursosf,
            cursosfr,
            modaulascri,
            doc_fichs,
            interacoes_anotacoes,
            interacoes_forumf,
            mensagens_enviadasf,
            stepmeets,
        } = this.state;

        return (
            <div className="dashboard" style={{ borderRadius: "0 0 4px 0" }}>
                <div className="bloco">
                    <div className="grid">
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Cursos onde é Professor", "")}>
                            <Icon type="laptop" />
                            <span className="desc">{cursosf}</span>
                            <span className="titulo">Cursos (como professor)</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Cursos onde é Coordenador", "")}>
                            <Icon type="laptop" />
                            <span className="desc">{cursosfr}</span>
                            <span className="titulo">Cursos (como coordenador)</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas criadas nos cursos", "")}>
                            <Icon type="history" />
                            <span className="desc">{modaulascri}</span>
                            <span className="titulo">Tarefas criadas</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Ficheiros adicionados na drive", "")}>
                            <Icon type="file-add" />
                            <span className="desc">{doc_fichs}</span>
                            <span className="titulo">Ficheiros</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Anotações pessoais adicionadas", "")}>
                            <Icon type="file" />
                            <span className="desc">{interacoes_anotacoes}</span>
                            <span className="titulo">Anotações</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Questões submetidas em fóruns", "")}>
                            <Icon type="form" />
                            <span className="desc">{interacoes_forumf}</span>
                            <span className="titulo">Interações fórum</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Mensagens registadas em conversações", "")}>
                            <Icon type="message" />
                            <span className="desc">{mensagens_enviadasf}</span>
                            <span className="titulo">Mensagens enviadas</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Stepmeets onde participou, como professor", "")}>
                            <Icon type="video-camera" />
                            <span className="desc">{stepmeets}</span>
                            <span className="titulo">Stepmeets</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Formador;
