import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, notification, Icon, Drawer, Menu, Modal, Dropdown, Tooltip, Table } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";
import Oops from "../../../Geral/Oops.jsx";
import { GlobalContext } from "../../../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class UploadImagem extends Component {
    static contextType = GlobalContext;
    state = {
        //UPLOAD VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
    };

    //ABRE O FILE BROWSER
    ativarUpload = () => {
        document.getElementById(this.props.idName).click();
    };

    //ATUALIZA A IMAGEM
    upload = event => {
        if (event.target.files[0].size < this.props.limite * 1024)
            if (this.validarFormato(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: ""
                });
                this.props.atualizarImagem(event.target.files[0]);
            }
            else {
                document.getElementById(this.props.idName).value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        else {
            document.getElementById(this.props.idName).value = "";
            openNotificationWithIcon("error", "Erro", `Limite de ${this.props.limite} KB para a imagem.`);
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //REMOVE A IMAGEM
    remover = () => {
        document.getElementById(this.props.idName).value = "";
        this.props.atualizarImagem("");
    };

    isFile(variable) {
        return variable instanceof File;
    }

    render() {
        const {
            validateStatus,
            help
        } = this.state;

        const {
            ativo
        } = this.props;

        return (
            <div className="bloco-img">
                <Form.Item label={this.props.titulo} validateStatus={validateStatus} help={help}>
                    <div className="container-img">
                        <img src={this.isFile(this.props.imagem) ? URL.createObjectURL(this.props.imagem) : this.props.imagem ? this.props.imagem : noimage} />
                    </div>
                    {ativo && (
                        <>
                            <input type="file" id={this.props.idName} accept="image/*" onChange={this.upload} style={{ display: "none" }} />
                            {!this.props.imagem ? (
                                <>
                                    <Link to="#" className="link-principal-border" onClick={this.ativarUpload}>
                                        <span>Adicionar</span>
                                    </Link>{" "}
                                    <Tooltip
                                        className="info-icon"
                                        title={
                                            <div>
                                                <p>Tamanho recomendado: {this.props.size}</p>
                                                <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                            </div>
                                        }
                                    >
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <Link to="#" className="link-principal-border" onClick={this.ativarUpload} style={{ marginRight: 20 }}>
                                        <span>Alterar</span>
                                    </Link>
                                    <Link to="#" className="link-principal-border" onClick={this.remover}>
                                        <span>Remover</span>
                                    </Link>
                                </>
                            )}
                        </>
                    )}
                </Form.Item>
            </div>
        );
    }
}

export default UploadImagem;
