import React, { Component } from "react";
import Header from "../../User/Header";
import DadosGerais from "./DadosGerais";
import CategoriasFavoritas from "./CategoriasFavoritas";
import Adesao from "./Adesao";
import Documentacao from "./Documentacao";
import Entidade from "./Entidade";
import Conquistas from "./Conquistas";
import Pagamentos from "./Pagamentos";
import { GlobalContext } from "../../GlobalState";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        //GRID
        filtroAtivo: "Dados Gerais",
    };

    ativarFiltro = (item) => {
        this.setState({ filtroAtivo: item });
    }

    render() {
        const {
            //GRID
            filtroAtivo
        } = this.state;

        const montarMenu = () => {
            var opcoes = ["Dados Gerais", "Categorias Favoritas", "Documentação", "Minha Entidade", "Ranking", "Pagamentos", "Adesão"]

            if (!this.context.gamificacao_entidade)
                opcoes = opcoes.filter(x => x !== "Ranking")

            if (!this.context.monetizacao_entidade)
                opcoes = opcoes.filter(x => x !== "Pagamentos")

            return opcoes;
        }

        return (
            <>
                <Header titulo="Conta" />
                <div className="administracao-minha-conta">
                    <div className="container container-body">
                        <div className="bloco-grid">
                            <div className="filtros">
                                <ul>
                                    {montarMenu().map((item, index) => (
                                        <li
                                            key={index}
                                            className={filtroAtivo === item ? "ativo" : ""}
                                            onClick={() => this.ativarFiltro(item)}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="bloco-cursos-estatisticas">
                                {filtroAtivo === "Dados Gerais" && <DadosGerais />}
                                {filtroAtivo === "Categorias Favoritas" && <CategoriasFavoritas />}
                                {filtroAtivo === "Documentação" && <Documentacao />}
                                {filtroAtivo === "Minha Entidade" && <Entidade />}
                                {filtroAtivo === "Ranking" && <Conquistas />}
                                {filtroAtivo === "Pagamentos" && <Pagamentos />}
                                {filtroAtivo === "Adesão" && <Adesao />}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
