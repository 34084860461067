import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Modal, Input } from "antd";
import Anotacao from "./Anotacao";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class OpcaoAnotacoes extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        cursoid: 0,
        aulaid: 0,
        anotacoes: [],
        drawerAnotacoes: false,
        filtros: true,
        nao_nova_anotacao: true,
        abrir_detalhe: false,
        anotacaoid: 0,
        texto: "",
        anexo: "",
        fileList: [],
        checked: false,
        status: "",
        helper: "",
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //ESTADO BOTAO
        botaoVisivel: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.setState({
            curso: this.props.match.params.curso,
            cursoid: localStorage.getItem("codigo_curso"),
            aulaid: localStorage.getItem("codigo_aula"),
        });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeEditor = texto => {
        this.setState({
            texto
        });
    };

    //LISTA TODAS AS ANOTAÇÕES PARA A AULA ABERTA
    CarregarAnotacoes = () => {
        axios({
            method: "get",
            url: "/api/anotacoes/anotacoes-aula",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        })
            .then(response => {
                this.setState({
                        anotacoes: response.data
                    });
            })
            .catch(() => { });
    };

    //ADICIONA UMA NOVA ANOTAÇÃO
    novaAnotacao = event => {
        event.preventDefault();

        if (this.state.texto !== "" && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
            this.setState({ iconLoading: true });

            var item = new FormData();
            item.append("id_curso", this.state.cursoid);
            item.append("id_modulo_aula", this.state.aulaid);
            item.append("texto", this.state.texto);

            this.state.fileList.forEach(file => {
                item.append("anexo[]", file);
            });

            axios({
                method: "post",
                url: "/api/anotacoes/add-anotacao-player",
                data: item
            })
                .then(() => {
                    this.CarregarAnotacoes();
                    this.setState({
                        nao_nova_anotacao: true,
                        filtros: true,
                        iconLoading: false,
                        fileList: []
                    });
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    this.setState({
                        iconLoading: false
                    });
                });
        } else {
            if (!this.state.texto)
                this.setState({
                    status: "error",
                    helper: "Campo obrigatório"
                });
        }
    };

    //ABRE O DETALHE DE UMA ANOTAÇÃO
    abrirAnotacaoDetalhe = (anotacaoid, texto, anexo) => {
        this.setState({
            abrir_detalhe: !this.state.abrir_detalhe,
            anotacaoid,
            texto,
            anexo
        });
    };

    //EXCLUI UMA ANOTAÇÃO
    excluir = () => {
        confirm({
            title: "Pretende excluir esta anotação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/anotacoes/excluir-anotacao",
                    params: {
                        anotacaoid: this.state.anotacaoid,
                        autor: this.state.autor
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Anotação excluida!");
                        this.CarregarAnotacoes();
                        this.setState({
                            abrir_detalhe: !this.state.abrir_detalhe
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a anotação!");
                    });
            }
        });
    };

    //CM
    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = (formato, formatos_aceites) => {
        var valido = false;

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato === formatos_aceites[i]) valido = true;
        }

        return valido;
    };

    openNovaAnotacao = () => {
        this.props.form.resetFields();
        this.setState({
            nao_nova_anotacao: false,
            filtros: false,
            anotacaoid: 0,
            texto: "",
            anexo: "",
            fileList: [],
            checked: false,
            status: "",
            helper: "",
            totalBytes: 0,
            formatoValido: true,
            validateStatus: "",
            help: ""
        })
    }

    afterVisibleChange = aberto => {
        if (aberto)
            this.CarregarAnotacoes();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const Dragger = Upload.Dragger;
        const {
            anotacoes,
            anotacaoid,
            texto,
            anexo,
            fileList,
            checked,
            status,
            helper,
            validateStatus,
            help,
            iconLoading,
            //ESTADO BOTAO
            botaoVisivel
        } = this.state;

        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (
                        !this.validarFormato(file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"])
                    ) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload.."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (
                            !this.validarFormato(file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"])
                        ) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <Drawer
                placement="right"
                //mask={false}
                maskClosable={false}
                closable={true}
                onClose={this.props.fecharAnotacoes}
                visible={this.props.drawerAnotacoes}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="drawer-anotacoes">
                    {this.state.filtros && botaoVisivel ? (
                        <div className="player-curso-forum">
                            <div className="filtros-forum">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="botao-fazer-pergunta">
                                            {localStorage.getItem("curso_fechado") === "true" ?
                                                <Link
                                                    to="#"
                                                    className="botao-principal botao-disabled"
                                                    disabled
                                                >
                                                    Nova anotação
                                                </Link>
                                                :
                                                <Link
                                                    to="#"
                                                    className="botao-principal"
                                                    onClick={this.openNovaAnotacao}
                                                >
                                                    Nova anotação
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {this.state.nao_nova_anotacao ? (
                        <div className="bloco-anotacoes">
                            <Anotacao
                                anotacoes={anotacoes}
                                fechado={localStorage.getItem("curso_fechado") === "true"}
                                esconderBotao={(botaoVisivel) => this.setState({ botaoVisivel })}
                                atualizarListagem={(anotacoes) => this.setState({ anotacoes })}
                            />
                        </div>
                    ) : (
                            <div className="bloco-nova-anotacao">
                                <div className="bloco-info">
                                    <Form className="form-nova-pergunta">
                                        <Form.Item label="Anotação">
                                            {getFieldDecorator("texto", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: this.state.texto
                                            })(<TextArea name="texto" onChange={this.handleChange} style={{ height: "100%" }} rows={10} />)}
                                        </Form.Item>
                                        <Form.Item validateStatus={validateStatus} help={help}>
                                            <Dragger {...props}>
                                                <p className="ant-upload-drag-icon">
                                                    <i className="fas fa-upload" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Adicionar anexos{" "}
                                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </p>
                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                            </Dragger>
                                        </Form.Item>
                                        <button
                                            className="botao-principal"
                                            onClick={this.novaAnotacao}
                                            disabled={iconLoading}
                                        >
                                            {iconLoading ? <Icon type="loading" /> : null}
                                            Salvar
                                    </button>
                                        <button
                                            className="botao-secundario"
                                            onClick={() => this.setState({ nao_nova_anotacao: true, filtros: true })}
                                        >
                                            Voltar
                                    </button>
                                    </Form>
                                </div>
                            </div>
                        )}
                </div>
            </Drawer>
        );
    }
}

const FormOpcaoAnotacoes = Form.create({ name: "opcao-anotacoers" })(OpcaoAnotacoes);

export default withRouter(FormOpcaoAnotacoes);
