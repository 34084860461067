import React, { Component } from "react"
import { Link, withRouter, Redirect } from "react-router-dom"
import { Table, Modal, notification, Select, Icon } from "antd"
import Header from "../User/Header"
import DrawerAdicionarDoc from "./DrawerAdicionarDoc/DrawerAdicionarDoc";
import axios from "axios"
import moment from "moment"
import { GlobalContext } from "../GlobalState"
import noimage from "../images/noimage.png"

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    })
}

class Documentos extends Component {
    static contextType = GlobalContext
    state = {
        anos: [],
        anoSelecionado: null,
        documentoId: 0,
        utilizador: "",
        documentos: [],
        //DRAWER ADD
        visible: false,
        //LOADING
        loading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
    }

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.infoUtilizador();
        this.carregarAnosFiltro();
    }

    infoUtilizador = () => {
        axios({
            method: "get",
            url: "/api/colaboradores/info-utilizador",
            params: {
                code: this.props.match.params.code
            }
        })
            .then(response => {
                this.setState({
                    utilizador: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    download = (documentoId) => {
        axios({
            method: "post",
            url: "/api/documentos/download",
            params: {
                documentoId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    excluir = documentoId => {
        confirm({
            title: "Pretende excluir este ficheiro?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/documentos/excluir",
                    params: {
                        documentoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Ficheiro excluido!");
                        let documentos = this.state.documentos.filter(x => x.key !== documentoId);
                        this.setState({
                            documentos
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o ficheiro!");
                    });
            }
        });
    };

    listar = () => {
        this.setState({
            loading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/documentos/listar",
                params: {
                    code: this.props.match.params.code,
                    ano: this.state.anoSelecionado
                }
            })
                .then(response => {
                    let documentos = [];

                    response.data.map(documento => {
                        documentos.push({
                            key: documento.id,
                            data: documento.dt_alterado ? moment(documento.dt_alterado).format("DD/MM/YYYY") : moment(documento.dt_criado).format("DD/MM/YYYY"),
                            documento: documento.nome,
                            opcoes: <div className="documentos-opcoes">
                                <Link to="#" title="Visualizar" className="botao-icon-detalhe">
                                    <Icon type="eye" />
                                </Link>
                                <Link to="#" title="Descarregar" className="botao-icon-download" onClick={() => this.download(documento.id)}>
                                    <Icon type="download" />
                                </Link>
                                <Link className="botao-icon-editar" to="#" onClick={() => this.setState({ visible: true, documentoId: documento.id })} title="Editar">
                                    <Icon type="edit" />
                                </Link>
                                <Link className="botao-icon-excluir" to="#" onClick={() => this.excluir(documento.id)} title="Excluir">
                                    <Icon type="delete" />
                                </Link>
                            </div>
                        });
                    });

                    this.setState({
                        documentos,
                        loading: false
                    });
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });

                    this.setState({
                        loading: false
                    });
                });
        })
    };

    carregarAnosFiltro = () => {
        axios({
            method: "get",
            url: "/api/documentos/carregar-anos",
            params: {
                code: this.props.match.params.code
            }
        })
            .then(response => {
                var anos = response.data;
                this.setState({
                    anos: response.data,
                    anoSelecionado: anos.length > 0 ? anos[0] : null
                }, () => this.listar());
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    render() {
        const {
            anos,
            anoSelecionado,
            documentoId,
            utilizador,
            documentos,
            //DRAWER ADD
            visible,
            //LOADING
            loading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state

        if (redirect) return <Redirect to={redirectLink} />

        const columns = [
            {
                title: "Data",
                dataIndex: "data"
            },
            {
                title: "Documento",
                dataIndex: "documento"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        return (
            <div>
                <Header titulo="Documentação" />
                <div className="container container-body">
                    <div className="bloco-entidades bloco-documentacao-utilizador">
                        <div className="bloco">
                            <div className="bloco-flex">
                                <div className="bloco-info" style={{ padding: 0 }}>
                                    <div className="dados-user">
                                        <div className="container-img">
                                            <img src={utilizador.foto ? utilizador.foto : noimage} alt="" />
                                        </div>
                                        <div>
                                            <h6 style={{ marginBottom: 0, color: "#3d464d", fontSize: "14px" }}>{utilizador.nome}</h6>
                                            <span style={{ fontSize: 12 }}>{utilizador.numero}</span>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/gestao-utilizadores-todos" className="botao-principal">
                                    Voltar
                                </Link>
                            </div>
                            <div className="bloco-filtros">
                                <Select
                                    className="filter"
                                    showSearch
                                    placeholder="Selecionar Ano"
                                    value={anoSelecionado}
                                    onChange={anoSelecionado => this.setState({ anoSelecionado }, () => this.listar())}
                                >
                                    {anos.map((ano, index) => (
                                        <Option key={index} value={ano}>{ano}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="bloco-documentacao" style={{ marginTop: 20 }}>
                                <Table
                                    id="tabela_categorias"
                                    className="tabela-documentos"
                                    loading={{
                                        spinning: loading,
                                        indicator: (
                                            <div className="loading-data-table">
                                                <div className="loading" />
                                            </div>
                                        )
                                    }}
                                    columns={columns}
                                    dataSource={documentos}
                                    pagination={false}
                                    locale={{ emptyText: "Não existem dados!" }}
                                />
                            </div>
                            <div className="opcoes-meus-cursos">
                                <Link to="#" className="btn-adicionar" title="Adicionar" onClick={() => this.setState({ visible: true })}>
                                    +
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <DrawerAdicionarDoc
                    documentoId={documentoId}
                    code={this.props.match.params.code}
                    visible={visible}
                    onClose={() => { this.setState({ visible: false }); anos.length > 0 ? this.listar() : this.carregarAnosFiltro() }}
                />
            </div>
        )
    }
}

export default Documentos
