import React, { Component, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { FaFacebookSquare, FaInstagramSquare, FaWhatsappSquare, FaEnvelope, FaPhone, FaArrowRight } from "react-icons/fa";

import InputSender from "./InputSender";
import logo from "../../images/logos/logo_stepforma.svg";

const Footer = () => {
    const email = "contato@stepforma.com.br";
    const phone = "+55 8297400-8501";
    //const facebook = "https://www.facebook.com/stepformabrasil";
    const instagram = "https://www.instagram.com/stepformaedu/";
    const whastapp = "https://api.whatsapp.com/send?phone=+55 8297400-8501&text=Eu tenho interesse em aderir &agrave; Stepforma"

    return (
        <>
            <div className="footer-home">
                <div className="footer-left-block footer-left-block-desktop">
                    <div className="logo-block">
                        <img src={logo} alt="Logo" />
                        <div className="terms-links">
                            <Link to="#">Política de Privacidade</Link>
                            <Link to="#">Termos de Uso</Link>
                        </div>
                        <span>Todos os direitos reservados @ StepForma</span><br/>
                        <span>Agosto 2023 v5</span>
                    </div>
                    <div className="contacts-block">
                        <span>
                            <span className="contacts-title">Social</span>
                            <ul>
                                {/*<li>*/}
                                {/*    <a href={facebook} target="_blank" rel="noopener noreferrer" title="Facebook">*/}
                                {/*        <FaFacebookSquare />*/}
                                {/*        Facebook*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                <li>
                                    <a href={instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                        <FaInstagramSquare />
                                        Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href={whastapp} target="_blank" rel="noopener noreferrer" title="Whatsapp">
                                        <FaWhatsappSquare />
                                        Whatsapp
                                    </a>
                                </li>
                            </ul>
                        </span>
                        <span>
                            <span className="contacts-title">Contato</span>
                            <ul>
                                <li>
                                    <a href={`mailto:${email}`} className="contacts-smaller-icon" rel="noopener noreferrer" title="E-mail">
                                        <FaEnvelope />
                                        {email}
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="contacts-smaller-icon" rel="noopener noreferrer" title="Telefone" style={{ cursor: "default" }}>
                                        <FaPhone />
                                        {phone}
                                    </a>
                                </li>
                            </ul>
                        </span>
                    </div>
                </div>
                <div className="footer-left-block footer-left-block-laptop">
                    <div>
                        <div className="footer-left-block-top">
                            <div className="logo-block">
                                <img src={logo} alt="Logo" />
                            </div>
                            <div className="contacts-block">
                                <span>
                                    <span className="contacts-title">Social</span>
                                    <ul>
                                        {/*<li>*/}
                                        {/*    <a href={facebook} target="_blank" rel="noopener noreferrer" title="Facebook">*/}
                                        {/*        <FaFacebookSquare />*/}
                                        {/*        Facebook*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href={instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                                <FaInstagramSquare />
                                                Instagram
                                            </a>
                                        </li>
                                        <li>
                                            <a href={whastapp} target="_blank" rel="noopener noreferrer" title="Whatsapp">
                                                <FaWhatsappSquare />
                                                Whatsapp
                                            </a>
                                        </li>
                                    </ul>
                                </span>
                                <span>
                                    <span className="contacts-title">Contato</span>
                                    <ul>
                                        <li>
                                            <a href={`mailto:${email}`} className="contacts-smaller-icon" rel="noopener noreferrer" title="E-mail">
                                                <FaEnvelope />
                                                {email}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="contacts-smaller-icon" rel="noopener noreferrer" title="Telefone" style={{ cursor: "default" }}>
                                                <FaPhone />
                                                {phone}
                                            </a>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                        <div className="footer-left-block-bottom">
                            <div className="terms-links">
                                <Link to="#">Política de Privacidade</Link>
                                <Link to="#">Termos de Uso</Link>
                            </div>
                            <span>Todos os direitos reservados @ StepForma - Agosto 2023 v5</span>
                        </div>
                    </div>
                </div>
                <div className="footer-separator"></div>
                <div className="footer-right-block">
                    <span className="request-demo-block">
                        <h2 className="request-demo-title">Solicite uma demonstração</h2>
                        <InputSender />
                    </span>
                    <span className="message-block">
                        <div className="sentence">Ensinar e aprender é mais fácil com a Stepforma.</div>
                        <div className="sentence">Tenha tudo em um só lugar!</div>
                    </span>
                </div>
            </div>
            <div className="footer-home footer-home-tablet">
                <div className="footer-right-block">
                    <span className="request-demo-block">
                        <h2 className="request-demo-title">Solicite uma demonstração</h2>
                        <InputSender />
                    </span>
                    <span className="message-block">
                        <div className="sentence">Ensinar e aprender é mais fácil com a Stepforma.</div>
                        <div className="sentence">Tenha tudo em um só lugar!</div>
                    </span>
                </div>
                <div className="footer-left-block">
                    <div>
                        <div className="footer-left-block-top">
                            <div className="logo-block">
                                <img src={logo} alt="Logo" />
                            </div>
                            <div className="contacts-block">
                                <span>
                                    <span className="contacts-title">Social</span>
                                    <ul>
                                        {/*<li>*/}
                                        {/*    <a href={facebook} target="_blank" rel="noopener noreferrer" title="Facebook">*/}
                                        {/*        <FaFacebookSquare />*/}
                                        {/*        Facebook*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href={instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                                <FaInstagramSquare />
                                                Instagram
                                            </a>
                                        </li>
                                        <li>
                                            <a href={whastapp} target="_blank" rel="noopener noreferrer" title="Whatsapp">
                                                <FaWhatsappSquare />
                                                Whatsapp
                                            </a>
                                        </li>
                                    </ul>
                                </span>
                                <span>
                                    <span className="contacts-title">Contato</span>
                                    <ul>
                                        <li>
                                            <a href={`mailto:${email}`} className="contacts-smaller-icon" rel="noopener noreferrer" title="E-mail">
                                                <FaEnvelope />
                                                {email}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="contacts-smaller-icon" rel="noopener noreferrer" title="Telefone" style={{ cursor: "default" }}>
                                                <FaPhone />
                                                {phone}
                                            </a>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                        <div className="footer-left-block-bottom">
                            <div className="terms-links">
                                <Link to="#">Política de Privacidade</Link>
                                <Link to="#">Termos de Uso</Link>
                            </div>
                            <span>Todos os direitos reservados @ StepForma - Agosto 2023 v5</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-home footer-home-mobile">
                <div className="message-block">
                    <div className="sentence">Ensinar e aprender é mais fácil com a Stepforma.</div>
                    <div className="sentence">Tenha tudo em um só lugar!</div>
                </div>
                <div className="request-demo-block">
                    <h2 className="request-demo-title">Solicite uma demonstração</h2>
                    <InputSender />
                </div>
                <div className="contacts-block">
                    <div>
                        <span>
                            <span className="contacts-title">Social</span>
                            <ul>
                                {/*<li>*/}
                                {/*    <a href={facebook} target="_blank" rel="noopener noreferrer" title="Facebook">*/}
                                {/*        <FaFacebookSquare />*/}
                                {/*        Facebook*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                <li>
                                    <a href={instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                        <FaInstagramSquare />
                                        Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href={whastapp} target="_blank" rel="noopener noreferrer" title="Whatsapp">
                                        <FaWhatsappSquare />
                                        Whatsapp
                                    </a>
                                </li>
                            </ul>
                        </span>
                        <span>
                            <span className="contacts-title">Contato</span>
                            <ul>
                                <li>
                                    <a href={`mailto:${email}`} className="contacts-smaller-icon" rel="noopener noreferrer" title="E-mail">
                                        <FaEnvelope />
                                        {email}
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="contacts-smaller-icon" rel="noopener noreferrer" title="Telefone" style={{ cursor: "default" }}>
                                        <FaPhone />
                                        {phone}
                                    </a>
                                </li>
                            </ul>
                        </span>
                    </div>
                </div>
                <div className="logo-block">
                    <img src={logo} alt="Logo" />
                    <div className="terms-links">
                        <Link to="#">Política de Privacidade</Link>
                        <Link to="#">Termos de Uso</Link>
                    </div>
                    <span>Todos os direitos reservados @ StepForma - Agosto 2023 v5</span>
                </div>
            </div>
        </>
    );
}

export default withRouter(Footer);
