import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Rate, Skeleton, Affix, Icon } from "antd";
import { GlobalContext } from "../../GlobalState";
import noimage from "../../images/noimage.png";
import axios from "axios";
import HelmetMetaData from "../../Geral/_Aux/Helmet";
import LazyLoad from "react-lazy-load";

class Header extends Component {
    static contextType = GlobalContext;
    state = {
        //DADOS
        imagem: "",
        codigo: "",
        nome: "",
        preco: "",
        subscricao: false,
        objectivo: "",
        avaliacao: "",
        totalAvaliacao: "",
        escola: "",
        coordenador: "",
        dt_curso: "",
        linguas: [],
        linguasLegendas: [],
        formacao: "",
        progress: 0,
        classificacao: "",
        concluido: "",
        aula: "",
        //DRAWER
        loading: true,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = this.props.match.params.id;

        this.setState({
            cursoId
        });
        this.carregarInfo(cursoId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.turma !== prevProps.match.params.turma) {
            var cursoId = this.props.match.params.id;

            this.setState({
                cursoId
            });
            this.carregarInfo(cursoId);
        }
    }

    //CARREGA A INFORMAÇÃO DO CURSO
    carregarInfo = turmaId => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/carregar-header-info",
            params: {
                turmaId
            }
        })
            .then(response => {
                this.setState({
                    codigo: response.data.codigo,
                    nome: response.data.nome,
                    edicao: response.data.edicao,
                    ano: response.data.ano,
                    turma: response.data.turma,
                    nivel: response.data.nivel,
                    turno: response.data.turno,
                    periodo: JSON.parse(response.data.periodo).toString(),
                    fechado: response.data.fechado,
                    escola: response.data.escola,
                    loading: false
                });
            })
            .catch(() => { });
    };

    render() {
        const {
            //DADOS
            codigo,
            nome,
            edicao,
            ano,
            turma,
            nivel,
            turno,
            periodo,
            fechado,
            escola,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        let location = window.location;

        return (
            <>
                <HelmetMetaData
                    title={`${codigo} - ${nome}`}
                    image={`${location.protocol}//${location.hostname}${this.state.imagem}`}
                />
                <div className="header-cursos" style={{ height: "auto" }}>
                    
                    <div className="container">
                        <Skeleton active={this.state.loading} avatar loading={this.state.loading}>
                            <div className="lado-esquerdo">
                                <div className="titulo-favorito">
                                    <div className="titulo-curso">
                                        <h2 title={nome}>{codigo} - {nome}</h2>
                                    </div>
                                </div>
                                <div className="bloco-rating-info bloco-rating-info-desktop">
                                    <div className="bloco-esquerda info-items">
                                        <span className="info-item">{nivel}</span>
                                        <span className="info-item">{turno}</span>
                                        <span className="info-item">{periodo}</span>
                                        <span className="info-item">{edicao}</span>
                                    </div>
                                </div>
                                <div className="bloco-rating-info bloco-rating-info-mobile">
                                    <div className="bloco-items info-items">
                                        <span className="info-item">{edicao}</span>
                                        <span className="info-item">{ano ? ano : ""} {turma ? turma : ""}</span>
                                        <span className="info-item">{turno}</span>
                                        {(escola && GlobalContext.escolas_entidade) &&
                                            <span className="info-item">{escola}</span>}
                                    </div>
                                </div>
                            </div>
                        </Skeleton>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Header);
