import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Drawer, Form, Input, DatePicker, Icon, Select, Upload, Tooltip, notification } from "antd";
import ModalViewer from "../../../../../Componentes/Modals/ModalViewer";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerOutros = ({ outroId, visible, extensions, onClose, atualizar, form, match }) => {
    const [tipos, setTipos] = useState([]);
    const [formData, setFormData] = useState({
        data: null,
        tipo: undefined,
        descricao: ""
    });
    const [ficheiros, setFicheiros] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [iconLoading, setIconLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const Dragger = Upload.Dragger;
    const { Option } = Select;
    const { TextArea } = Input;
    const { getFieldDecorator } = form;

    const listar = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-outros"
        })
            .then(respose => {
                setTipos(respose.data)
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-outro",
            params: {
                outroId
            }
        })
            .then(response => {
                var info = response.data.registo;
                setFormData({
                    data: moment(info.data),
                    tipo: info.tipo,
                    descricao: info.descricao
                })
                setFicheiros(response.data.ficheiros)
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("code", match.params.code);
                item.append("id_outro", formData.tipo);
                item.append("data", moment(formData.data).format("YYYY/MM/DD"));
                item.append("descricao", formData.descricao);
                fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "post",
                    url: "/api/user-detalhe/adicionar-outro",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!.");
                        setIconLoading(false)
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false)
                    });
            }
        })
    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("id", outroId);
                item.append("id_outro", formData.tipo);
                item.append("data", moment(formData.data).format("YYYY/MM/DD"));
                item.append("descricao", formData.descricao);
                if (ficheiros.length)
                    item.append("listaFicheiros", JSON.stringify(ficheiros));
                fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "put",
                    url: "/api/user-detalhe/alterar-outro",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!.");
                        setIconLoading(false)
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false)
                    });
            }
        })
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (!visible) {
            setFormData({
                data: null,
                tipo: undefined,
                descricao: ""
            });
            setFileList([]);
            setIconLoading(false);
        }
        else {
            if (outroId > 0)
                detalhe();
        }
    }

    const validarFormatoFicheiro = formato => {
        var formatos = ["docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file)
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed")
                callback("Campo obrigatório");

        callback();
    };

    useEffect(() => {
        listar();
    }, [])

    const props = {
        multiple: true,
        onRemove: (file) => {
            form.resetFields("ficheiro");
            setFileList((fileList) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: (file) => {
            setFileList((fileList) => [...fileList, file]);

            return false;
        },
        fileList,
    };

    return (
        <Drawer
            className="drawer-inscrever drawer-editar-matricula"
            //title="Adicionar"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-drawer-associar">
                <Form className="form-emitir-declaracao">
                    <div className="bloco">
                        <Form.Item label="Data">
                            {getFieldDecorator("data", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: formData.data
                            })(
                                <DatePicker
                                    name="data"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={(data) => setFormData({ ...formData, data })}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Tipo">
                            {getFieldDecorator("tipo", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: formData.tipo
                            })(
                                <Select
                                    className="input-50"
                                    placeholder="Selecionar"
                                    allowClear={true}
                                    onChange={(tipo) => setFormData({ ...formData, tipo })}
                                >
                                    {tipos.map(tipo => (
                                        <Option key={tipo.id} value={tipo.id}>{tipo.titulo}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Descritivo">
                            {getFieldDecorator("descricao", {
                                initialValue: formData.descricao
                            })(
                                <TextArea
                                    name="descricao"
                                    onChange={(e) => setFormData({ ...formData, descricao: e.target.value })}
                                    rows={4}
                                    style={{ height: 100 }}
                                />
                            )}
                        </Form.Item>
                        <div className="bloco">
                            <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>Documento</h3>
                            {ficheiros.map(ficheiro => (
                                <Form.Item>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <span>{ficheiro.nome}</span>
                                        <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10 }}>
                                            {extensions.find(x => x === ficheiro.extensao) &&
                                                <Link to="#" title="Visualizar" className="botao-icon-detalhe" onClick={() => { setModalVisible(true); setDocs([{ uri: ficheiro.url }]) }}>
                                                    <Icon type="eye" />
                                                </Link>}
                                            <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={() => setFicheiros([...ficheiros.filter(x => x.id !== ficheiro.id)])}>
                                                <Icon type="delete" />
                                            </Link>
                                        </span>
                                    </div>
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <div className="anexos">
                                    {getFieldDecorator("ficheiro", {
                                        rules: [
                                            {
                                                validator: validar
                                            }
                                        ],
                                        initialValue: fileList
                                    })(
                                        <Dragger {...props}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    )}
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button className="botao-principal" disabled={iconLoading} type="primary" onClick={outroId > 0 ? alterar : adicionar}>
                    {iconLoading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </Drawer>
    );
}
const FormDrawerOutros = Form.create({ name: "drawer-outros" })(DrawerOutros);

export default withRouter(FormDrawerOutros);