import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Icon, notification, Modal } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class Consultar extends Component {
    static contextType = GlobalContext;
    state = {
        numero: "",
        redirect: false,
        redirectLink: "",
        iconLoading: false,
        remember: false,
        //MODAL MENSAGEM
        visibleMensagem: false
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            remember: false
        });
    };

    handleKeyPress = event => {
        if (event.key === "Enter") this.consultar(event);
    };

    consultar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                axios({
                    method: "get",
                    url: "/api/consultar-resultados/consultar",
                    params: {
                        numero: this.state.numero
                    }
                })
                    .then(response => {
                        this.setState({
                            redirectLink: `/${this.props.match.params.entidade}/consultar/${response.data}`,
                            redirect: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível consultar os registos");
                        this.setState({
                            iconLoading: false
                        })
                    });
            }
        });
    };

    render() {
        const { redirect, redirectLink, iconLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Consultar resultados" marginTop0={true} />
                <div className="content">
                    <Form onSubmit={this.handleSubmit} className="login">
                        <Form.Item label="Nº de Matriz / Código">
                            {getFieldDecorator("numero", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Introduza o número de matriz / código"
                                    }
                                ]
                            })(<Input name="numero" onChange={this.handleChange} onKeyPress={this.handleKeyPress} />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Link to="#">
                                <Button type="primary" className="login-form-button" disabled={iconLoading} onClick={this.consultar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Consultar
                                </Button>
                            </Link>
                        </Form.Item>
                    </Form>
                </div>
            </>
        );
    }
}

const ConsultarForm = Form.create({ name: "form-consultar" })(Consultar);

export default ConsultarForm;
