import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Button, Icon, Menu } from "antd";
import { GlobalContext } from "../GlobalState";
import axios from "axios";

class Footer extends Component {
    static contextType = GlobalContext;
    state = {
        semFooter: ""
    };

    componentDidMount() {
        if (
            this.props.location.pathname.split("/").pop() === "player-webinar" ||
            this.props.location.pathname.split("/").pop() === "player-videoconferencia" ||
            this.props.location.pathname.split("/").pop() === "player-video" ||
            this.props.location.pathname.split("/").pop() === "player-video-meets" ||
            this.props.location.pathname.split("/").pop() === "player-audio" ||
            this.props.location.pathname.split("/").pop() === "player-pagina" ||
            this.props.location.pathname.split("/").pop() === "player-pdf" ||
            this.props.location.pathname.split("/").pop() === "player-questionario" ||
            this.props.location.pathname.split("/").pop() === "player-questionario-resumo" ||
            this.props.location.pathname.split("/").pop() === "player-questionario-resolucao" ||
            this.props.location.pathname.split("/").pop() === "player-avaliacao" ||
            this.props.location.pathname.split("/").pop() === "player-avaliacao-resumo" ||
            this.props.location.pathname.split("/").pop() === "player-avaliacao-resolucao" ||
            this.props.location.pathname.split("/").pop() === "player-download" ||
            this.props.location.pathname.split("/").pop() === "player-trabalho" ||
            this.props.location.pathname.split("/").pop() === "player-link" ||
            this.props.location.pathname.split("/").pop() === "player-sessao-online" ||
            this.props.location.pathname.split("/").pop() === "player-sessao-presencial" ||
            this.props.location.pathname.split("/").pop() === "player-sumario" ||
            this.props.location.pathname.split("/").pop() === "player-trabalho-ficheiro" ||
            this.props.location.pathname.split("/").pop() === "player-ficheiro" ||
            this.props.location.pathname.split("/").pop() === "player-assiduidade" ||
            this.props.location.pathname.split("/").pop() === "player-sessao-persencial" ||
            this.props.location.pathname.split("/")[1] === "controller"
        )
            this.setState({
                semFooter: "sem-footer"
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (
                this.props.location.pathname.split("/").pop() === "player-webinar" ||
                this.props.location.pathname.split("/").pop() === "player-videoconferencia" ||
                this.props.location.pathname.split("/").pop() === "player-video" ||
                this.props.location.pathname.split("/").pop() === "player-video-meets" ||
                this.props.location.pathname.split("/").pop() === "player-audio" ||
                this.props.location.pathname.split("/").pop() === "player-pagina" ||
                this.props.location.pathname.split("/").pop() === "player-pdf" ||
                this.props.location.pathname.split("/").pop() === "player-questionario" ||
                this.props.location.pathname.split("/").pop() === "player-questionario-resumo" ||
                this.props.location.pathname.split("/").pop() === "player-questionario-resolucao" ||
                this.props.location.pathname.split("/").pop() === "player-avaliacao" ||
                this.props.location.pathname.split("/").pop() === "player-avaliacao-resumo" ||
                this.props.location.pathname.split("/").pop() === "player-avaliacao-resolucao" ||
                this.props.location.pathname.split("/").pop() === "player-download" ||
                this.props.location.pathname.split("/").pop() === "player-trabalho" ||
                this.props.location.pathname.split("/").pop() === "player-link" ||
                this.props.location.pathname.split("/").pop() === "player-sessao-online" ||
                this.props.location.pathname.split("/").pop() === "player-sessao-presencial" ||
                this.props.location.pathname.split("/").pop() === "player-trabalho-ficheiro" ||
                this.props.location.pathname.split("/").pop() === "player-ficheiro" ||
                this.props.location.pathname.split("/").pop() === "player-assiduidade" ||
                this.props.location.pathname.split("/").pop() === "player-sessao-persencial" ||
                this.props.location.pathname.split("/")[1] === "controller"
            )
                this.setState({
                    semFooter: "sem-footer"
                });
            else
                this.setState({
                    semFooter: ""
                });
        }
    }

    render() {
        const { redessociais, fundoBranco, homeStepforma } = this.props;

        return (
            <div className={`footer ${this.state.semFooter}`} style={fundoBranco ? { background: "white" } : {}}>
                {redessociais.length > 0 && (
                    <div className="bloco-footer">
                        <ul className="redes_sociais">
                            {/*{redessociais[0] && ( //FACEBOOK*/}
                            {/*    <li>*/}
                            {/*        <a href={redessociais[0]} target="_blank" rel="noopener noreferrer" title="Facebook">*/}
                            {/*            <img src={require("../images/redesSociais/facebook.png")} alt="Facebook" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*)}*/}
                            {/*{redessociais[1] && ( //TWITTER*/}
                            {/*    <li>*/}
                            {/*        <a href={redessociais[1]} target="_blank" rel="noopener noreferrer" title="Twitter">*/}
                            {/*            <img src={require("../images/redesSociais/twitter.png")} alt="Twitter" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*)}*/}
                            {/*{redessociais[2] && ( //LINKEDIN*/}
                            {/*    <li>*/}
                            {/*        <a href={redessociais[2]} target="_blank" rel="noopener noreferrer" title="Linkedin">*/}
                            {/*            <img src={require("../images/redesSociais/linkedin.png")} alt="Linkedin" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*)}*/}
                            {/*{redessociais[3] && ( //INSTAGRAM*/}
                            {/*    <li>*/}
                            {/*        <a href={redessociais[3]} target="_blank" rel="noopener noreferrer" title="Instagram">*/}
                            {/*            <img src={require("../images/redesSociais/instagram.png")} alt="Instagram" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*)}*/}
                            {/*{redessociais[4] && ( //EMAIL*/}
                            {/*    <li>*/}
                            {/*        <a href={"mailto:" + redessociais[4]} target="_blank" rel="noopener noreferrer" title="E-mail">*/}
                            {/*            <img src={require("../images/redesSociais/email.png")} alt="E-mail" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*)}*/}
                            {/*{redessociais[5] && ( //SITE*/}
                            {/*    <li>*/}
                            {/*        <a href={redessociais[5]} target="_blank" rel="noopener noreferrer" title="Website">*/}
                            {/*            <img src={require("../images/redesSociais/web.png")} alt="Website" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*)}*/}
                            <li>
                                <Link to="/faq" className="faq" rel="noopener noreferrer" title="FAQ">
                                    FAQ
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}
                {homeStepforma ?
                    <span className="direitos">
                        Todos os direitos reservados @ StepForma - junho 2023 v5
                    </span>
                    :
                    <span className="direitos">
                        Todos os direitos reservados @StepForma - junho 2023 v5
                    </span>
                }
            </div>
        );
    }
}

export default withRouter(Footer);
