import React, { useState } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Input, Select, Checkbox, Radio } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import noimage from "../../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerFicha = ({ visible, onClose, atualizar, form }) => {
    const [nome, setNome] = useState("");
    const [estrutura, setEstrutura] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (event, index) => {
        let _estrutura = estrutura;
        _estrutura[index].texto = event.target.value;

        setEstrutura(_estrutura);
    };

    const listarCampos = () => {
        axios({
            method: "get",
            url: "/api/turma/carregar-ficha",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setNome(response.data.nome);
                setEstrutura(
                    response.data.estrutura.map(campo => {
                        return {
                            id: campo.id,
                            nome: campo.nome,
                            texto: campo.texto
                        };
                    })
                );
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possivel carregar informação.");
            });
    };

    const guardar = event => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);
                var item = new FormData();
                item.append("cursoId", localStorage.getItem("codigo_curso"));
                item.append("estrutura", JSON.stringify(estrutura));

                axios({
                    method: "put",
                    url: "/api/turma/alterar-campos",
                    data: item
                })
                    .then(response => {
                        setLoading(false);
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            }
        });
    };

    const afterVisibleChange = aberto => {
        if (aberto) {
            listarCampos();
        }
    };

    const { getFieldDecorator } = form;

    return (
        <Drawer
            //title={`Ficha ${nome}`}
            placement="right"
            className="drawer-envio-mensagem-inscricao drawer-add-cursos"
            maskClosable={false}
            closable={true}
            onClose={onClose}
            visible={visible}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-nova-pergunta">
                    {estrutura.map((campo, index) => (
                        <div key={index} className="bloco">
                            <h3 className="titulo-separador">{campo.nome}</h3>
                            <Form.Item>
                                {getFieldDecorator(`texto${index}`, {
                                    initialValue: campo.texto,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(
                                    <TextArea
                                        //maxLength="200"
                                        name="breveDescricao"
                                        onChange={event => handleChange(event, index)}
                                        rows={10}
                                        style={{ height: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    ))}
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </button>
                <button className="botao-principal" onClick={guardar} disabled={loading}>
                    {loading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
        </Drawer>
    );
};

const FormDrawerFicha = Form.create({ name: "form-ficha" })(DrawerFicha);

export default withRouter(FormDrawerFicha);
