import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, InputNumber, Checkbox, Icon, notification, Select, Upload, Button, Radio, Modal, Tooltip } from "antd";

const { Option } = Select;
const Dragger = Upload.Dragger;
const RadioGroup = Radio.Group;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
const confirm = Modal.confirm;

class FormAvaliacao extends Component {
    state = {
        btn_relaod: false,
        show: false,
        questoes: []
    };

    handleChangeQuestao = (event, index) => {
        var _questoes = this.state.questoes;
        _questoes[index].pergunta = event.target.value;

        this.setState({
            questoes: _questoes
        });
    };

    handleChangeOrdem = (value, index) => {
        var _questoes = this.state.questoes;
        _questoes[index].ordem = value;

        this.setState({
            btn_relaod: true
        });

        this.setState({
            questoes: _questoes
        });
    };

    handleChangePontuacao = (value, index) => {
        var _questoes = this.state.questoes;
        _questoes[index].pontuacao = value;

        this.setState({
            questoes: _questoes
        });
    };

    handleChangeResolucao = (event, index) => {
        var _questoes = this.state.questoes;
        _questoes[index].texto_resolucao = event.target.value;

        this.setState({
            questoes: _questoes
        });
    };

    handleChangeTipo = (value, index) => {
        var _questoes = this.state.questoes;
        _questoes[index].tipo_pergunta = value;

        if (value === "Escolha única" || value === "Escolha múltipla")
            _questoes[index].opcoes = [
                {
                    texto: "",
                    ordem: 1,
                    verdadeiro: false,
                    disabled: true
                },
                {
                    texto: "",
                    ordem: 2,
                    verdadeiro: false,
                    disabled: true
                }
            ];
        else _questoes[index].opcoes = [];

        this.setState({
            questoes: _questoes
        });
    };

    handleChangeOpcao = (event, index, index2) => {
        var _questoes = this.state.questoes;
        _questoes[index].opcoes[index2].texto = event.target.value;
        _questoes[index].opcoes[index2].disabled = false;

        this.setState({
            questoes: _questoes
        });
    };

    adicionarOpcao = (event, index) => {
        event.preventDefault();

        var _questoes = this.state.questoes;
        var ordem = _questoes[index].opcoes.length + 1;
        _questoes[index].opcoes.push({
            texto: "",
            ordem: ordem,
            verdadeiro: false,
            disabled: true
        });

        this.setState({
            questoes: _questoes
        });
    };

    handleChangeVerdadeiro = (index, index2) => {
        var _questoes = this.state.questoes;

        if (_questoes[index].tipo_pergunta === "Escolha única")
            for (var i = 0; i < _questoes[index].opcoes.length; i++) {
                _questoes[index].opcoes[i].verdadeiro = false;
            }

        _questoes[index].opcoes[index2].verdadeiro = !_questoes[index].opcoes[index2].verdadeiro;

        this.setState({
            questoes: _questoes
        });
    };

    //EXCLUI QUESTÃO
    handleChangeExcluirQuestao = index => {
        confirm({
            title: "Pretende excluir esta questão?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                var _questoes = this.state.questoes;
                _questoes.splice(index, 1);

                _questoes.forEach((questao, index) => {
                    questao.ordem = index + 1;
                });

                this.setState({
                    questoes: _questoes
                });
            }
        });
    };

    //EXCLUIR OPÇÃO DENTRO DA QUESTÃO
    handleChangeExcluir = (index, index2) => {
        confirm({
            title: "Pretende excluir esta opção?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                var _questoes = this.state.questoes;
                _questoes[index].opcoes.splice(index2, 1);

                this.setState({
                    questoes: _questoes
                });
            }
        });
    };

    //ADICIONA UM NOVO BROCO PARA UMA NOVA QUESTÃO
    addQuestao = () => {
        var _questoes = this.state.questoes;
        _questoes.push({
            pergunta: "",
            ficheiro_pergunta: [],
            tipo_pergunta: undefined,
            ordem: this.state.questoes.length > 0 ? _questoes[_questoes.length - 1].ordem + 1 : 1,
            pontuacao: "",
            texto_resolucao: "",
            ficheiro_resolucao: [],
            opcoes: []
        });

        this.setState({
            questoes: _questoes
        });
    };

    //REORDENAR QUESTÕES
    ReordenarQuestoes = () => {
        var _questoes = this.state.questoes;

        this.setState({
            btn_relaod: false
        });

        this.setState({
            questoes: _questoes.sort((a, b) => (a.ordem > b.ordem ? 1 : b.ordem > a.ordem ? -1 : 0))
        });
    };

    //CM
    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = (formato, formatos) => {
        var valido = false;

        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) valido = true;
        }

        return valido;
    };

    uplaodPropsPergunta = index => {
        var _questoes = this.state.questoes;

        return {
            accept: ".pdf, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: false,
            onRemove: file => {
                const index2 = _questoes[index].ficheiro_pergunta.indexOf(file);
                _questoes[index].ficheiro_pergunta.splice(index2, 1);

                this.setState({
                    questoes: _questoes
                });
            },
            defaultFileList: _questoes[index].ficheiro_pergunta,
            beforeUpload: file => {
                if (!this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase(), ["pdf", "bmp", "gif", "png", "jpeg", "jpg"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload");
                    return false;
                }

                _questoes[index].ficheiro_pergunta = [file];
                this.setState({
                    questoes: _questoes
                });

                return false;
            }
        };
    };

    uplaodProps = index => {
        var _questoes = this.state.questoes;

        return {
            accept: ".pdf, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: false,
            onRemove: file => {
                const index2 = _questoes[index].ficheiro_resolucao.indexOf(file);
                _questoes[index].ficheiro_resolucao.splice(index2, 1);

                this.setState({
                    questoes: _questoes
                });
            },
            defaultFileList: _questoes[index].ficheiro_resolucao,
            beforeUpload: file => {
                if (!this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase(), ["pdf", "bmp", "gif", "png", "jpeg", "jpg"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload");
                    return false;
                }

                _questoes[index].ficheiro_resolucao = [file];
                this.setState({
                    questoes: _questoes
                });

                return false;
            }
        };
    };

    voltar = () => {
        var _questoes = [];
        var stopFaltaOpcao = false;
        var stopVerdadeiro = false;

        this.state.questoes.forEach((questao, index) => {
            if (questao.pergunta && questao.tipo_pergunta) {
                if (questao.tipo_pergunta === "Escolha única" || questao.tipo_pergunta === "Escolha múltipla") {
                    if (questao.opcoes.length > 0) {
                        var opcaoVazia = false
                        var totalNaoSel = 0;
                        questao.opcoes.forEach(opcao => {
                            if (!opcao.texto) {
                                opcaoVazia = true
                                return false
                            }

                            if (!opcao.verdadeiro)
                                totalNaoSel = totalNaoSel + 1
                        })

                        if (opcaoVazia) {
                            stopFaltaOpcao = true
                            return false
                        }
                        else
                            _questoes.push(questao);

                        if (totalNaoSel === questao.opcoes.length) {
                            stopVerdadeiro = true;
                            return false
                        }
                    }
                }
                else
                    _questoes.push(questao);
            }
        });

        if (stopFaltaOpcao)
            openNotificationWithIcon("error", "Erro", "Adicione opções de resposta")
        else if (stopVerdadeiro)
            openNotificationWithIcon("error", "Erro", "Assinale quais a questões corretas")
        else {
            this.props.alterarAvaliacao(_questoes);
            this.props.onClose();
        }
    };

    afterVisibleChange = visible => {
        this.setState({ show: visible });

        if (visible) {
            if (this.props.questoes.length === 0)
                this.setState({
                    questoes: [
                        {
                            pergunta: "",
                            ficheiro_pergunta: [],
                            tipo_pergunta: undefined,
                            ordem: 1,
                            pontuacao: "",
                            texto_resolucao: "",
                            ficheiro_resolucao: [],
                            opcoes: []
                        }
                    ]
                });
            else
                this.setState({
                    questoes: this.props.questoes
                });
        }
    };

    render() {
        const { TextArea } = Input;
        const Dragger = Upload.Dragger;

        return (
            <Drawer
                className="drawer-from-questionario"
                //title="Avaliação"
                width={720}
                onClose={this.voltar}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-aula lista-questoes" layout="horizontal" hideRequiredMark>
                        {this.state.questoes.map((questao, index) => (
                            <div key={index} className="bloco-questao">
                                <div className="dados-questao">
                                    <Link to="#" className="btn-excluir" onClick={() => this.handleChangeExcluirQuestao(index)}>
                                        <Icon type="delete" />
                                    </Link>
                                    <div className="tipo-questao">
                                        <Form.Item label="Ordem">
                                            <InputNumber value={questao.ordem} onChange={e => this.handleChangeOrdem(e, index)} />
                                        </Form.Item>
                                        <Form.Item label="Pontuação da Questão">
                                            <InputNumber value={questao.pontuacao} onChange={e => this.handleChangePontuacao(e, index)} />
                                        </Form.Item>
                                        <Form.Item label="Tipo">
                                            <Select
                                                placeholder="Selecionar"
                                                allowClear={true}
                                                onChange={e => this.handleChangeTipo(e, index)}
                                                value={questao.tipo_pergunta}
                                                style={{ width: "495px" }}
                                            >
                                                <Option value="Escolha única">Escolha única</Option>
                                                <Option value="Escolha múltipla">Escolha múltipla</Option>
                                                <Option value="Resposta livre">Resposta livre</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <Form.Item label="Questão" style={{ marginBottom: 25 }}>
                                        <TextArea
                                            value={questao.pergunta}
                                            onChange={e => this.handleChangeQuestao(e, index)}
                                            style={{ height: "100%" }}
                                            rows={2}
                                        />
                                        <Dragger {...this.uplaodPropsPergunta(index)} fileList={questao.ficheiro_pergunta}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formato válido: .pdf, .bmp, .gif, .png, .jpeg, .jpg">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    </Form.Item>
                                    <Form.Item label="Solução" style={{ marginBottom: 0 }}>
                                        <TextArea
                                            value={questao.texto_resolucao}
                                            onChange={e => this.handleChangeResolucao(e, index)}
                                            style={{ height: "100%" }}
                                            rows={2}
                                        />
                                        <Dragger {...this.uplaodProps(index)} fileList={questao.ficheiro_resolucao}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formato válido: .pdf, .bmp, .gif, .png, .jpeg, .jpg">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    </Form.Item>
                                    {questao.opcoes.length > 0 && (
                                        <div className="bloco-respostas">
                                            <button
                                                className="botao-principal"
                                                onClick={e => this.adicionarOpcao(e, index)}
                                                style={{ marginBottom: 20 }}
                                            >
                                                <Icon type="plus" /> Opção
                                            </button>
                                            {questao.opcoes.map((opcao, index2) => (
                                                <React.Fragment key={"lvl2" + index2}>
                                                    <div className="resposta" style={{ justifyContent: "flex-end" }}>
                                                        <Form.Item
                                                            label={
                                                                <div className="resposta-bloco-opcoes">
                                                                    <div>
                                                                        <span>Opção</span>
                                                                        <button
                                                                            className={`botao-excluir-questionario  ${questao.opcoes.length <= 2 ? "botao-excluir-questionario-disable" : ""}`}
                                                                            disabled={questao.opcoes.length <= 2}
                                                                            onClick={() => this.handleChangeExcluir(index, index2)}
                                                                        >
                                                                            <Icon type="delete" />
                                                                        </button>
                                                                    </div>
                                                                    <div>
                                                                        <Link
                                                                            to="#"
                                                                            disabled={opcao.disabled}
                                                                            className={`btn-selecionar-correta ${(opcao.verdadeiro ? "ativo" : "")}`}
                                                                            onClick={() => this.handleChangeVerdadeiro(index, index2)}
                                                                        >
                                                                            <Icon type="check" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            }
                                                            className="resposta-input"
                                                        >
                                                            <TextArea
                                                                value={opcao.texto}
                                                                onChange={e => this.handleChangeOpcao(e, index, index2)}
                                                                style={{ height: "100%" }}
                                                                rows={1}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                            <button
                                                className="botao-principal"
                                                onClick={e => this.adicionarOpcao(e, index)}
                                                style={{ marginBottom: 20, marginTop: 24 }}
                                            >
                                                <Icon type="plus" /> Opção
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.voltar} style={{ marginRight: 20 }}>
                        Voltar
                    </button>
                    {this.state.btn_reload ? (
                        <button
                            className="botao-secundario"
                            title="Reordenar questões"
                            onClick={this.ReordenarQuestoes}
                            style={{ marginRight: 20 }}
                        >
                            Reordenar questões
                        </button>
                    ) : null}
                    <button className="botao-principal" title="Adicionar questão" onClick={this.addQuestao}>
                        <Icon type="plus" /> Questão
                    </button>
                </div>
            </Drawer>
        );
    }
}

export default FormAvaliacao;
