import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Modal, notification, Icon, Button, Dropdown, Menu } from "antd";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { GlobalContext } from "../../GlobalState";
import { GeralContext } from "../ContextProvider";
import axios from "axios";
import moment from 'moment'

import "react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment)

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
class Main extends Component {
    static contextType = GlobalContext;

    state = {
        dataCalendario: new Date(),
        vista: "week",
        inicio: moment().startOf('week').format("YYYY/MM/DD"),
        termino: moment().endOf('week').format("YYYY/MM/DD"),
        aulas: [],
        ultimaCor: "",
        fases: [],
        //LOADING
        iconLoading: false,
        //DRAWER ADICIONAR
        visible: false,
        dataHoraInicio: "",
        dataHoraFim: "",
        //MODAL DETALHE
        modalEventoVisible: false,
        aulaId: "",
        titulo: "",
        mesmoDia: false,
        data: "",
        horas: "",
        descricao: "",
        autor: false,
        cursoId: "",
        moduloIndex: "",
        tarefaId: "",
        stepmeet: "",
        tipo: "",
        link: "",
        botaoAtivo: false,
        registarUrl: "",
        aluno: false,
        tipoBotao: {},
        externa: false,
        convite: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL BROWSERS INFO
        visibleBrowsers: false,
        tituloCarousel: "Informação sobre como deve sair da Stepmeet",
        meetID: 0,
        mensagemId: 1,
        visivelMensagem1: false,
        visivelMensagem2: false,
        naoMostrarMensagem: false,
    }

    UNSAFE_componentWillMount() {
        if (localStorage.getItem("posicao_calendario") && (localStorage.getItem("temp_codigo_curso") === localStorage.getItem("codigo_curso"))) {
            const data = new Date(localStorage.getItem("posicao_calendario"));
            const vista = localStorage.getItem("vista_calendario");
            this.setState({
                dataCalendario: data,
                vista,
                inicio: moment(data).startOf(vista).format("YYYY/MM/DD"),
                termino: moment(data).endOf(vista).format("YYYY/MM/DD")
            }, () => this.listar())
        }
        else {
            localStorage.setItem("posicao_calendario", this.state.dataCalendario)
            localStorage.setItem("vista_calendario", this.state.vista)
            localStorage.setItem("temp_codigo_curso", localStorage.getItem("codigo_curso"))
            this.listar();
        }
    }

    atualizarPosicaoCalendário = (dataCalendario) => {
        localStorage.setItem("posicao_calendario", dataCalendario)
        const vista = localStorage.getItem("vista_calendario");
        this.setState({
            dataCalendario,
            vista,
            inicio: moment(dataCalendario).startOf(vista).format("YYYY/MM/DD"),
            termino: moment(dataCalendario).endOf(vista).format("YYYY/MM/DD")
        }, () => this.listar())
    }

    listar = () => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/listar",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        inicio: this.state.inicio,
                        termino: this.state.termino
                    }
                })
                    .then(response => {
                        const aulas = response.data.eventos.map(aula => {
                            return ({
                                id: aula.id,
                                title: <>{aula.avaliacao ? <i className="evento-icon-avaliacao" title="Aula com avaliação"></i> : null}{aula.titulo}</>,
                                start: new Date(aula.inicio),
                                end: new Date(aula.termino),
                                cor: aula.cor,
                                recorrencia: aula.recorrencia
                            })
                        });

                        this.setState({
                            aulas,
                            ultimaCor: response.data.ultimaCor,
                            iconLoading: false
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        this.setState({
                            iconLoading: false
                        })
                    });
            }
        );
    }

    listarOndeExistem = (toolbar, tipo) => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/listar-onde-existem",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        inicio: this.state.inicio,
                        termino: this.state.termino,
                        vista: toolbar.view,
                        tipo
                    }
                })
                    .then(response => {
                        if (response.data === "ANTERIOR" || response.data === "PROXIMO") {
                            openNotificationWithIcon("warning", "Atenção", response.data === "ANTERIOR" ? "Não existem anteriores" : "Não existem próximas aulas");
                            this.setState({
                                iconLoading: false
                            })
                        }
                        else {
                            var count = response.data;

                            if (tipo === "ANTERIOR") {
                                if (toolbar.view === "month") {
                                    toolbar.date.setMonth(toolbar.date.getMonth() - (count * 1));
                                    toolbar.onNavigate('prev');
                                    this.setState({
                                        dataCalendario: toolbar.date
                                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                                }
                                else if (toolbar.view === "week") {
                                    let mDate = this.state.dataCalendario;
                                    let newDate = new Date(
                                        mDate.getFullYear(),
                                        mDate.getMonth(),
                                        mDate.getDate() - (count * 7));
                                    toolbar.onNavigate('prev', newDate);
                                    this.setState({
                                        dataCalendario: newDate
                                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                                }
                                else {
                                    let mDate = this.state.dataCalendario;
                                    let newDate = new Date(
                                        mDate.getFullYear(),
                                        mDate.getMonth(),
                                        mDate.getDate() - count);
                                    toolbar.onNavigate('prev', newDate);
                                    this.setState({
                                        dataCalendario: newDate
                                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                                }
                            }
                            else {
                                if (toolbar.view === "month") {
                                    toolbar.date.setMonth(toolbar.date.getMonth() + (count * 1));
                                    toolbar.onNavigate('next');
                                    this.setState({
                                        dataCalendario: toolbar.date
                                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                                }
                                else if (toolbar.view === "week") {
                                    let mDate = this.state.dataCalendario;
                                    let newDate = new Date(
                                        mDate.getFullYear(),
                                        mDate.getMonth(),
                                        mDate.getDate() + (count * 7));
                                    toolbar.onNavigate('prev', newDate);
                                    this.setState({
                                        dataCalendario: newDate
                                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                                }
                                else {
                                    try {
                                        let mDate = this.state.dataCalendario;
                                        let newDate = new Date(
                                            mDate.getFullYear(),
                                            mDate.getMonth(),
                                            mDate.getDate() + count);
                                        toolbar.onNavigate('prev', newDate);
                                        this.setState({
                                            dataCalendario: newDate
                                        }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                                    }
                                    catch (error) {

                                    }
                                }
                            }
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        this.setState({
                            iconLoading: false
                        })
                    });
            }
        );
    }

    onRangeChange = (range) => {
        if (range.length) {
            this.setState({
                inicio: moment(range[0]).format("YYYY/MM/DD"),
                termino: moment(range.pop()).format("YYYY/MM/DD")
            }, () => this.listar())
        }
        else {
            this.setState({
                inicio: moment(range.start).format("YYYY/MM/DD"),
                termino: moment(range.end).format("YYYY/MM/DD")
            }, () => this.listar())
        }
    }

    onDrillDown = (range) => {
        this.setState({
            inicio: moment(range).format("YYYY/MM/DD"),
            termino: moment(range).format("YYYY/MM/DD"),
            vista: "day",
            dataCalendario: range
        }, () => {
            localStorage.setItem("posicao_calendario", this.state.dataCalendario);
            localStorage.setItem("vista_calendario", "day");
        })
    }

    onSelectEvent = (event) => {
        localStorage.setItem("aula_diario", event.id)
        this.setState({
            redirect: true,
            redirectLink: `/curso/${this.props.match.params.curso}/curso-diario-detalhe`
        })
    }

    onSelectSlot = ({ start, end, action }) => {
        if (action !== "click") {
            this.setState({
                visible: true,
                dataHoraInicio: start,
                dataHoraFim: end
            })
        }
    }

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}>
                Voltar
            </Link>);
        }
    }

    render() {
        const {
            dataCalendario,
            vista,
            aulas,
            ultimaCor,
            fases,
            //DRAWER ADICIONAR
            visible,
            dataHoraInicio,
            dataHoraFim,
            //MODAL DETALHE
            aulaId,
            cursoId,
            moduloIndex,
            tarefaId,
            stepmeet,
            tipo,
            link,
            botaoAtivo,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            iconLoading,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const eventStyleGetter = (event, start, end, isSelected) => {
            const style = {
                backgroundColor: event.cor,
                border: 'none',
                border: '1px solid',
            };
            return {
                style: style
            };
        }

        const toolbar = (toolbar) => {
            const aulasAnteriores = () => {
                this.listarOndeExistem(toolbar, "ANTERIOR");
            }

            const proximasAulas = () => {
                this.listarOndeExistem(toolbar, "PROXIMO");
            }

            const anterior = () => {
                if (toolbar.view === "month") {
                    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
                    toolbar.onNavigate('prev');
                    this.setState({
                        dataCalendario: toolbar.date
                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                }
                else if (toolbar.view === "week") {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(
                        mDate.getFullYear(),
                        mDate.getMonth(),
                        mDate.getDate() - 7);
                    toolbar.onNavigate('prev', newDate);
                    this.setState({
                        dataCalendario: newDate
                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                }
                else {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(
                        mDate.getFullYear(),
                        mDate.getMonth(),
                        mDate.getDate() - 1);
                    toolbar.onNavigate('prev', newDate);
                    this.setState({
                        dataCalendario: newDate
                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                }
            };

            const proximo = () => {
                if (toolbar.view === "month") {
                    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
                    toolbar.onNavigate('next');
                    this.setState({
                        dataCalendario: toolbar.date
                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                }
                else if (toolbar.view === "week") {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(
                        mDate.getFullYear(),
                        mDate.getMonth(),
                        mDate.getDate() + 7);
                    toolbar.onNavigate('prev', newDate);
                    this.setState({
                        dataCalendario: newDate
                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                }
                else {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(
                        mDate.getFullYear(),
                        mDate.getMonth(),
                        mDate.getDate() + 1);
                    toolbar.onNavigate('prev', newDate);
                    this.setState({
                        dataCalendario: newDate
                    }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
                }
            };

            const vistaHoje = () => {
                toolbar.onNavigate('current', new Date());
                this.setState({
                    dataCalendario: new Date()
                }, () => localStorage.setItem("posicao_calendario", this.state.dataCalendario))
            };

            const labelData = () => {
                const date = moment(toolbar.date);
                if (toolbar.view === "month")
                    return (
                        <span>{date.format('MMMM YYYY')}</span>
                    );
                else if (toolbar.view === "week") {
                    return (
                        <span>{toolbar.label}</span>
                    );
                }
                else
                    return (
                        <span>{date.format('dddd, DD [de] MMMM YYYY')}</span>
                    );
            };

            const vistaMes = () => {
                toolbar.onView('month');
                this.setState({
                    vista: 'month'
                }, () => localStorage.setItem("vista_calendario", 'month'))
            }

            const vistaSemana = () => {
                toolbar.onView('week');
                this.setState({
                    vista: 'week'
                }, () => localStorage.setItem("vista_calendario", 'week'))
            }

            const vistaDia = () => {
                toolbar.onView('day');
                this.setState({
                    vista: 'day'
                }, () => localStorage.setItem("vista_calendario", 'day'))
            }

            const montarTitleDuplaSetaEsq = () => {
                if (vista === "month")
                    return "Mês anterior com aulas";
                else if (vista === "week")
                    return "Semana anterior com aulas";
                else
                    return "Dia anterior com aulas";
            }

            const montarTitleDuplaSetaDir = () => {
                if (vista === "month")
                    return "Próximo mês com aulas";
                else if (vista === "week")
                    return "Próxima semana com aulas";
                else
                    return "Próximo dia com aulas";
            }

            const montarTitleSetaEsq = () => {
                if (vista === "month")
                    return "Mês anterior";
                else if (vista === "week")
                    return "Semana anterior";
                else
                    return "Dia anterior";
            }

            const montarTitleSetaDir = () => {
                if (vista === "month")
                    return "Próximo mês";
                else if (vista === "week")
                    return "Próxima semana";
                else
                    return "Próximo dia";
            }

            return (
                <>
                    <div className='toolbar-container toolbar-container-desktop'>
                        <div className="toolbal-opcoes-esquerda">
                            <Button.Group>
                                <Button className='btn-anterior' title={montarTitleDuplaSetaEsq()} onClick={aulasAnteriores}>
                                    <Icon type="double-left" />
                                </Button>
                                <Button className='btn-anterior' title={montarTitleSetaEsq()} onClick={anterior}>
                                    <Icon type="left" />
                                </Button>
                                <Button className='btn-hoje' onClick={vistaHoje}>Hoje</Button>
                                <Button className='btn-proximo' title={montarTitleSetaDir()} onClick={proximo}>
                                    <Icon type="right" />
                                </Button>
                                <Button className='btn-proximo' title={montarTitleDuplaSetaDir()} onClick={proximasAulas}>
                                    <Icon type="double-right" />
                                </Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-label-data">
                            <label className='label-data'>{labelData()}</label>
                        </div>
                        <div className="toolbal-opcoes-direita">
                            <Button.Group>
                                <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>Mês</Button>
                                <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>Semana</Button>
                                <Button className={`btn-dia ${vista === "day" ? " ativa" : ""}`} onClick={vistaDia}>Dia</Button>
                            </Button.Group>
                        </div>
                    </div>
                    <div className='toolbar-container toolbar-container-tablet'>
                        <div className="toolbal-opcoes-cima">
                            <Button.Group>
                                <Button.Group>
                                    <Button className='btn-anterior' title={montarTitleDuplaSetaEsq()} onClick={aulasAnteriores}>
                                        <Icon type="double-left" />
                                    </Button>
                                    <Button className='btn-anterior' title={montarTitleSetaEsq()} onClick={anterior}>
                                        <Icon type="left" />
                                    </Button>
                                    <Button className='btn-hoje' onClick={vistaHoje}>Hoje</Button>
                                    <Button className='btn-proximo' title={montarTitleSetaDir()} onClick={proximo}>
                                        <Icon type="right" />
                                    </Button>
                                    <Button className='btn-proximo' title={montarTitleDuplaSetaDir()} onClick={proximasAulas}>
                                        <Icon type="double-right" />
                                    </Button>
                                </Button.Group>
                            </Button.Group>
                            <Button.Group>
                                <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>Mês</Button>
                                <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>Semana</Button>
                                <Button className={`btn-dia ${vista === "day" ? " ativa" : ""}`} onClick={vistaDia}>Dia</Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-opcoes-baixo">
                            <div className="toolbal-label-data">
                                <label className='label-data'>{labelData()}</label>
                            </div>
                        </div>
                    </div>
                    <div className='toolbar-container toolbar-container-mobile'>
                        <div className="toolbal-opcoes-esquerda">
                            <Button.Group>
                                <Button.Group>
                                    <Button className='btn-anterior' title={montarTitleDuplaSetaEsq()} onClick={aulasAnteriores}>
                                        <Icon type="double-left" />
                                    </Button>
                                    <Button className='btn-anterior' title={montarTitleSetaEsq()} onClick={anterior}>
                                        <Icon type="left" />
                                    </Button>
                                    <Button className='btn-hoje' onClick={vistaHoje}>Hoje</Button>
                                    <Button className='btn-proximo' title={montarTitleSetaDir()} onClick={proximo}>
                                        <Icon type="right" />
                                    </Button>
                                    <Button className='btn-proximo' title={montarTitleDuplaSetaDir()} onClick={proximasAulas}>
                                        <Icon type="double-right" />
                                    </Button>
                                </Button.Group>
                            </Button.Group>
                        </div>
                        <div className="toolbal-opcoes-direita">
                            <Button.Group>
                                <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>Mês</Button>
                                <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>Semana</Button>
                                <Button className={`btn-dia ${vista === "day" ? " ativa" : ""}`} onClick={vistaDia}>Dia</Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-label-data">
                            <label className='label-data'>{labelData()}</label>
                        </div>
                    </div>
                </>
            );
        };

        const hoje = new Date();

        return (
            <>
                <div className="container container-body bloco-diario">
                    <div className="bloco-curso-diario-opcoes">
                        <div className="bloco-botoes-curso-diario">
                            {this.montarBotaoVoltar()}
                        </div>
                    </div>
                    <div className="curso-diario">
                        <Calendar
                            localizer={localizer}
                            events={aulas}
                            scrollToTime={new Date(
                                hoje.getFullYear(),
                                hoje.getMonth(),
                                hoje.getDate(),
                                8
                            )}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 700 }}
                            defaultView={vista}
                            view={vista}
                            date={dataCalendario}
                            views={["month", "week", "day"]}
                            components={{
                                toolbar: toolbar
                            }}
                            onView={() => { }}
                            onNavigate={() => { }}
                            onSelectEvent={this.onSelectEvent}
                            onDrillDown={this.onDrillDown}
                            onRangeChange={this.onRangeChange}
                            eventPropGetter={(eventStyleGetter)}
                            selectable={true}
                            onSelectSlot={this.onSelectSlot}
                        />
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default Main;
