import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../User/Header";
import Conversa from "./Conversa";
import Conversas from "./Conversas";
import Opcoes from "./Opcoes";
import axios from "axios";
import { notification } from "antd";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        //FILTROS
        pesquisa: "",
        //LISTAGEM
        iconLoadingGrupos: false,
        grupos: [],
        //GRUPO
        block: 1,
        blockSize: 10,
        iconLoadingMais: false,
        iconLoadingGrupo: false,
        grupo: "",
        selecionada: 0,
        mensagens: [],
        //LOADING
        loading: false,
        iconLoadingRecarregar: false,
        //FORM NOVO GRUPO
        grupoId: 0,
        visibleNovoGrupo: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""

    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.validar();
    }

    validar = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-chat/validar-estado2",
            params: {
                codigo: this.getParameterByName("code")
            }
        })
            .then(response => {
                switch (response.data) {
                    case "INATIVO":
                        this.setState({
                            redirect: true,
                            redirectLink: `/${this.props.match.params.entidade}/chat-inativo`
                        });
                        break;
                    case "EXPIRADO":
                        this.setState({
                            redirect: true,
                            redirectLink: `/${this.props.match.params.entidade}/chat-confirmacao?code=${this.getParameterByName("code")}`
                        });
                        break;
                    default:
                        this.listarGrupos();
                        break;
                }

            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Ocorreu um erro!");
            });
    };

    componentDidMount() {
        var objDiv = document.getElementsByClassName("lista-mensagens")[0];
        objDiv.addEventListener("scroll", () => { 
            if (objDiv.scrollTop === 0)
                this.carregarMensagensAnteriores()
        }, false);
    }

    handlerChangeCheckbox = event => {
        this.setState({
            selecionada: 0,
            grupo: "",
            [event.target.name]: event.target.checked
        }, () => this.listarGrupos());
    };

    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1]);
    };

    listarGrupos = () => {
        this.setState(
            {
                iconLoadingGrupos: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pagina-entidade-chat/listar-grupos",
                    params: {
                        pesquisa: this.state.pesquisa,
                        codigo: this.getParameterByName("code")
                    }
                })
                    .then(response => {
                        this.setState({
                            grupos: response.data,
                            iconLoadingGrupos: false
                        }, () => {
                                if (response.data.length > 0) {
                                    this.carregarMensagens(this.state.grupo ? this.state.grupo : response.data[0])
                                }
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    carregarMensagens = grupo => {
        this.setState(
            {
                grupo: "",
                mensagens: [],
                iconLoadingGrupo: true,
                block: 1,
                blockSize: 10
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pagina-entidade-chat/carregar-mensagens",
                    params: {
                        grupoId: grupo.id,
                        blockSize: this.state.blockSize,
                        block: this.state.block,
                        codigo: this.getParameterByName("code")
                    }
                })
                    .then(response => {
                        this.setState({
                            grupo,
                            mensagens: response.data,
                            iconLoadingGrupo: false,
                            iconLoadingRecarregar: false
                        }, () => {
                            var objDiv = document.getElementsByClassName("lista-mensagens")[0];
                                objDiv.scrollTop = objDiv.scrollHeight;
                                this.carregarTotalConversas();
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    carregarMensagensAnteriores = () => {
        this.setState(
            {
                block: this.state.block + 1,
                iconLoadingMais: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pagina-entidade-chat/carregar-mensagens",
                    params: {
                        grupoId: this.state.grupo.id,
                        blockSize: this.state.blockSize,
                        block: this.state.block,
                        codigo: this.getParameterByName("code")
                    }
                })
                    .then(response => {
                        this.setState({
                            mensagens: [...response.data, ...this.state.mensagens],
                            iconLoadingMais: false
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    atualizarMensagens = (grupoId, lastId) => {
        this.setState({iconLoadingRecarregar: true},
            () => {
                axios({
                    method: "get",
                    url: "/api/pagina-entidade-chat/atualizar-mensagens",
                    params: {
                        grupoId,
                        lastId,
                        codigo: this.getParameterByName("code")
                    }
                })
                    .then(response => {
                        if (response.data.length) {
                            this.setState({
                                mensagens: [...this.state.mensagens, ...response.data],
                                iconLoadingRecarregar: false
                            }, () => {
                                    var objDiv = document.getElementsByClassName("lista-mensagens")[0];
                                    objDiv.scrollTop = objDiv.scrollHeight;
                                    this.carregarTotalConversas();
                            })
                        }
                        else
                            this.setState({
                                iconLoadingRecarregar: false
                            })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    carregarTotalConversas = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-chat/total-porler",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.context.atualizarState({
                    totalConversasPorLer: response.data
                });
            })
            .catch(error => {

            });
    };

    addMensagem = mensagem => {
        this.setState({
            mensagens: [...this.state.mensagens, mensagem]
        })
        //this.listarGrupos()
    }

    atualizarGrupos = grupoId => {
        if (grupoId)
            this.setState({
                visibleNovoGrupo: false,
                grupo: this.state.grupos.find(x => x.id === grupoId),
                selecionada: this.state.grupos.findIndex(x => x.id === grupoId)
            }, () => this.listarGrupos())
        else
            this.setState({
                visibleNovoGrupo: false
            }, () => this.listarGrupos())
    }

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    block: 1,
                    blockSize: 10,
                },
                () => {
                    this.listarGrupos();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                block: 1,
                blockSize: 10,
            },
            () => {
                this.listarGrupos();
            }
        );
    };

    recarregarMensagens = event => {
        event.preventDefault();
        if (this.state.mensagens.length)
            this.atualizarMensagens(this.state.grupo.id, this.state.mensagens[this.state.mensagens.length - 1].id)
        else
            this.carregarMensagens(this.state.grupo)

        this.listarGrupos();
        this.carregarTotalConversas();
    }

    render() {
        const {
            //LISTAGEM
            iconLoadingGrupos,
            grupos,
            //GRUPO
            iconLoadingMais,
            iconLoadingGrupo,
            grupo,
            selecionada,
            mensagens,
            //LOADING
            iconLoadingRecarregar,
            //FORM NOVO GRUPO
            grupoId,
            visibleNovoGrupo,
        } = this.state;

        if (this.state.redirect)
            return <Redirect to={this.state.redirectLink} />;

        return (
            <>
                <Header titulo="Conversas" />
                <div className="container container-body">
                    <Opcoes
                        iconLoadingRecarregar={iconLoadingRecarregar}
                        adicionar={() => this.setState({ visibleNovoGrupo: true, grupoId: 0 })}
                        recarregar={this.recarregarMensagens}
                        HandleKeyPress={this.HandleKeyPress}
                        resetCaixaProcura={this.resetCaixaProcura}
                        handlerChangeCheckbox={this.handlerChangeCheckbox}
                    />
                    <div className="grupos-chat">
                        <Conversas
                            selecionada={selecionada}
                            selecionarConversa={selecionada => this.setState({ selecionada })}
                            iconLoadingGrupos={iconLoadingGrupos}
                            grupos={grupos}
                            carregarMensagens={this.carregarMensagens}
                        />
                        <Conversa
                            iconLoadingMais={iconLoadingMais}
                            iconLoadingGrupo={iconLoadingGrupo}
                            grupo={grupo}
                            mensagens={mensagens}
                            addMensagem={this.addMensagem}
                            carregarMensagensAnteriores={this.carregarMensagensAnteriores}
                            atualizarListaGrupos={this.listarGrupos}
                            codigo={this.getParameterByName("code")}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
