import React, { Component } from "react";

import ExcelTabelaFrequencia from "./Tabela";
import Opcao from "./Opcoes"

class ListaFrequencia extends Component {
    render() {
        return (
            <>
                <div className="container container-body">
                    <div className="bloco-curso-diario-opcoes">
                        <h3 className="titulo-bloco">Frequência</h3>
                        <Opcao />
                    </div>
                    <ExcelTabelaFrequencia setDisciplinaId={disciplinaId => this.setState({ disciplinaId })} />
                </div>
            </>
        );
    }
}

export default ListaFrequencia;
