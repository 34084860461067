import { Badge, Collapse, Dropdown, Icon, Menu, Modal } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import logo from "../images/logos/stepforma-01.png";
import noimage from "../images/noimage.png";
import DrawerCarrinho from "./_Aux/DrawerCarrinho";

const { Panel } = Collapse;
class Navbar extends Component {
    static contextType = GlobalContext;

    state = {
        //ESTABELECIMENTOS E PERFIS
        estabelecimentos: [],
        perfis: [],
        entidade_id: 0,
        entidade_imagem_propria: false,
        entidade_logo: "",
        entidade_nome: "StepForma",
        entidade_nome_abrev: "",
        entidade_afiliado: false,
        entidade_visivel: false,
        entidade_url: "",
        entidade_site: "",
        entidade_chave: "",
        stepmeet_gestao: "",
        entidade_escolas: false,
        categorias: [],
        cursos_stepforma: false,
        acessos: [],
        code: "",
        nome: "",
        foto: "",
        nr_tickets: 0,
        nr_mensagens: 0,
        //FORMADOR
        formador: false,
        tem_licenca: false,
        //MOBILE
        mobile: false,
        //DRAWER CHAT
        mostrarChat: false,
        //DRAWER CARRINHO
        visibleCart: false,
        //LOADING
        iconLoading: false,
        //MENU MOBILE
        menuAdministracaoMobileEstado: [],
        menuGestaoMobileEstado: [],
        menuInstrutorMobileEstado: [],
        menuUtilizadorMobileEstado: ["1"]
    };

    componentDidMount() {
        if (this.context.carrinhoProdutos) {
            this.setState({
                visibleCart: true
            });
        }

        this.carregarUtilizadorInfo();
        this.carregaDadosEntidade();
        this.carregarTotalConversas();
        this.carregarTotalEventosHoje();
        this.carregarTicketsPorLer();
    }

    componentDidUpdate() {
        if (this.context.atualizarDadosGerias) {
            this.carregarUtilizadorInfo();
            this.context.atualizarState({ atualizarDadosGerias: false });
        }

        if (this.context.atualizarEntidade) {
            this.carregaDadosEntidade();
            this.context.atualizarState({ atualizarEntidade: false });
        }
    }

    //onVisibleChange = visible => {
    //    if (visible && this.context.role !== "ENTIDADE") {
    //        this.carregarTicketsPorLer();
    //    }
    //};

    onVisibleChangeEntidade = visible => {
        if (visible) {
            this.carregarTicketsPorLer();
        }
    };

    carregarTotalConversas = () => {
        axios({
            method: "get",
            url: "/api/chat/total-porler",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.context.atualizarState({
                    totalConversasPorLer: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregarTotalEventosHoje = () => {
        this.context.atualizarEventosHoje();
    };

    carregarUtilizadorInfo = () => {
        axios({
            method: "get",
            url: "/api/navbar/dados-utilizador",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                var dados = response.data;

                this.context.atualizarState({
                    id_utilizador: dados.id,
                    foto_utilizador: dados.foto,
                    avatar_utilizador: dados.avatar,
                    nome_utilizador: dados.nome_completo,
                    numero_utilizador: dados.numero,
                    sexo_utilizador: dados.sexo,
                    estabelecimento: dados.estabelecimento,
                    perfil: dados.perfil
                });

                this.carregarEstabelecimentos();
                this.setState({
                    code: dados.code
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregarTicketsPorLer = () => {
        axios({
            method: "get",
            url: "/api/tickets/total-tickets-por-ler-menu"
        })
            .then(response => {
                this.setState({
                    nr_tickets: response.data
                });
            })
            .catch(error => {});
    };

    decodeHTML = moeda => {
        var map = { gt: ">" /* , … */ };
        return moeda.replace(/&(#(?:x[0-9a-f]+|\d+)|[a-z]+);?/gi, ($0, $1) => {
            if ($1[0] === "#") {
                return String.fromCharCode($1[1].toLowerCase() === "x" ? parseInt($1.substr(2), 16) : parseInt($1.substr(1), 10));
            } else {
                return map.hasOwnProperty($1) ? map[$1] : $0;
            }
        });
    };

    carregaDadosEntidade = () => {
        axios({
            method: "get",
            url: "/api/navbar/dados-entidade"
        })
            .then(response => {
                this.context.atualizarState({
                    id_entidade: response.data.id,
                    nome_entidade: response.data.nome,
                    entidade_abreviatura: response.data.abreviatura,
                    responsaveis_entidade: response.data.responsaveis,
                    formacao_entidade: response.data.formacao,
                    projeto_entidade: response.data.projeto,
                    organizacional_entidade: response.data.organizacional,
                    visivel_entidade: response.data.visivel_entidade,
                    albumId_entidade: response.data.albumId,
                    chave_entidade: response.data.chave_integracao,
                    escolas_entidade: response.data.escolas,
                    login2_entidade: response.data.login2,
                    disciplinas_entidade: response.data.disciplinas,
                    conf_total_participantes: response.data.totalParticipantes,
                    conf_total_recorders: response.data.totalRecorders,
                    monetizacao_entidade: response.data.monetizacao_entidade,
                    monetizacao_stepforma: response.data.monetizacao_stepforma,
                    vendaCursos: response.data.vendaCursos,
                    vendaCertificados: response.data.vendaCertificados,
                    valorMensal: response.data.valorMensal,
                    subscricao_entidade: response.data.subscricao,
                    moeda_entidade: this.decodeHTML(response.data.moeda),
                    gamificacao_entidade: response.data.gamificacao,
                    gamificacao_stepforma: response.data.gamificacao_stepforma,
                    conteudos_animados_entidade: response.data.conteudos_animados,
                    stepmeet_gestao: response.data.stepmeet_gestao,
                    whatsapp_entidade: response.data.whatsapp,
                    reclamacoes_entidade: response.data.reclamacoes,
                    diario_entidade: response.data.diario,
                    diario_stepforma: response.data.diario_stepforma,
                    modulo_formacao_stepforma: response.data.modulo_formacao_stepforma,
                    modulo_formacao: response.data.modulo_formacao,
                    conversas_stepforma: response.data.conversas_stepforma,
                    conversas: response.data.conversas,
                    calendario_stepforma: response.data.calendario_stepforma,
                    calendario: response.data.calendario,
                    relatorios: response.data.relatorios,
                    estatisticas: response.data.estatisticas,
                    eventos: response.data.eventos,
                    stepmeets: response.data.stepmeets,
                    stebinars: response.data.stebinars,
                    newsletter: response.data.newsletter,
                    stepforms: response.data.stepforms
                });

                this.setState({
                    entidade_id: response.data.id,
                    entidade_imagem_propria: response.data.imagem_propria,
                    entidade_logo: response.data.logo,
                    entidade_nome: response.data.nome,
                    entidade_nome_abrev: response.data.nome_abrev,
                    entidade_visivel: response.data.visivel_entidade,
                    entidade_url: response.data.url,
                    entidade_site: response.data.site,
                    entidade_chave: response.data.chave_integracao,
                    stepmeet_gestao: response.data.stepmeet_gestao,
                    entidade_escolas: response.data.escolas
                });

                localStorage.setItem("entidade_id", response.data.id);

                if (response.data.imagem_propria)
                    this.context.atualizarState({
                        favicon_entidade: response.data.logo,
                        title_entidade: response.data.nome
                    });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    onChangeEstabelecimento = estabelecimento => {
        localStorage.setItem("escola_selecionada", JSON.stringify(estabelecimento));
        this.context.atualizarState({
            estabelecimento,
            filtroEstabelecimentosUtilizado: true
        });
        this.carregarPerfis(estabelecimento.id);
    };

    onChangePerfil = perfil => {
        this.context.atualizarState({ perfil });
        this.carregarAcessos(perfil.id);
    };

    carregarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/navbar/listar-organismos-utilizador"
        })
            .then(response => {
                this.setState(
                    {
                        estabelecimentos: response.data
                    },
                    () => {
                        if (localStorage.getItem("escola_selecionada")) {
                            const estabelecimento = JSON.parse(localStorage.getItem("escola_selecionada"));
                            this.context.atualizarState({ estabelecimento });
                            this.carregarPerfis(estabelecimento.id);
                        }
                        else {
                            const estabelecimento = response.data.find(x => x.principal) || response.data[0];
                            this.context.atualizarState({ estabelecimento });
                            this.carregarPerfis(estabelecimento.id);
                        }

                        this.context.atualizarState({ estabelecimentos: response.data });
                    }
                );
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregarPerfis = entidadeId => {
        axios({
            method: "get",
            url: "/api/navbar/listar-perfis-utilizador",
            params: {
                entidadeId
            }
        })
            .then(response => {
                this.setState(
                    {
                        perfis: response.data
                    },
                    () => {
                        const perfil = response.data.find(x => x.principal) || response.data[0];
                        this.context.atualizarState({ perfil });
                        this.carregarAcessos(perfil.id);
                    }
                );
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregarAcessos = perfilId => {
        axios({
            method: "get",
            url: "/api/permissoes/carregar-acessos",
            params: {
                perfilId
            }
        })
            .then(response => {
                this.setState(
                    {
                        acessos: response.data
                    },
                    () => this.context.atualizarState({ acessos: this.state.acessos })
                );
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    logOut = () => {
        this.setState({
            mobile: false,
            iconLoading: true
        });

        axios({
            method: "post",
            url: "/api/autenticacao/logout"
        })
            .then(response => {
                localStorage.removeItem("accessToken");
                delete axios.defaults.headers.common.Authorization;

                localStorage.removeItem("participanteNaMeet");
                this.context.atualizarState({
                    favicon_entidade: "",
                    title_entidade: ""
                });

                if (
                    !this.state.entidade_chave ||
                    localStorage.getItem("redirecionado") !== "true" ||
                    (this.state.entidade_chave && localStorage.getItem("redirecionado") === "true" && !this.state.entidade_site)
                ) {
                    localStorage.removeItem("redirecionado");

                    this.setState({
                        redirect: true,
                        redirectLink: this.state.entidade_visivel ? `/${response.data}` : "/"
                    });
                } else {
                    localStorage.removeItem("redirecionado");
                    window.open(this.state.entidade_site, "_self");
                }
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    iconLoading: false
                });
            });
    };

    //DRAWER CHAT
    fecharChat = () => {
        this.setState({ mostrarChat: false });
    };

    voltarCarrinho = () => {
        this.setState(
            {
                visibleCart: false
            },
            () => {
                this.context.atualizarState({
                    carrinho: []
                });
            }
        );
    };

    render() {
        const {
            //ESTABELECIMENTOS E PERFIS
            estabelecimentos,
            perfis,
            entidade_imagem_propria,
            entidade_logo,
            entidade_nome,
            entidade_afiliado,
            entidade_escolas,
            stepmeet_gestao,
            foto,
            nome,
            nr_tickets,
            acessos,
            //DRAWER CARRINHO
            visibleCart,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            iconLoading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        //SUPORTE
        const menuAdmin = (
            <>
                {acessos?.find(x => x.func === 101) && (
                    <li className="nav-item">
                        <NavLink
                            to="/gestao-utilizadores-todos"
                            className="nav-link"
                            onClick={() => {
                                this.setState({mobile: false});
                                this.context.atualizarState({
                                    pesquisaInfo: null
                                });
                            }}
                        >
                            Usuários
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 102) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-categoria-noticias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Categorias Notícias
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 102) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-noticias" className="nav-link"
                                 onClick={() => this.setState({mobile: false})}>
                            Notícias
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 128) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-fotos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Galerias de fotos
                        </NavLink>
                    </li>)}
                {acessos?.find(x => x.func === 129) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-videos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Videos
                        </NavLink>
                    </li>)}
                {acessos?.find(x => x.func === 130) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-carrossel" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Carrossel
                        </NavLink>
                    </li>)}
                {acessos?.find(x => x.func === 131) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-comentario" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Comentarios
                        </NavLink>
                    </li>)}
                {acessos?.find(x => x.func === 103) && this.context.eventos && (
                    <li className="nav-item">
                        <NavLink to="/gestao-calendarios-eventos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Stepeventos
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 104) && this.context.conf_total_participantes > 0 && this.context.stepmeets && (
                    <li className="nav-item">
                        <NavLink
                            to="/gestao-videoconferencias"
                            className="nav-link"
                            onClick={() => {
                                this.setState({ mobile: false });
                                localStorage.removeItem("menu_mensagens");
                            }}
                        >
                            Stepmeets
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 105) &&
                    this.context.conf_total_participantes > 0 &&
                    this.context.conf_total_recorders > 0 &&
                    this.context.stebinars && (
                        <li className="nav-item">
                            <NavLink
                                to="/gestao-webinars"
                                className="nav-link"
                                onClick={() => {
                                    this.setState({ mobile: false });
                                    localStorage.removeItem("menu_mensagens");
                                }}
                            >
                                Stebinars
                            </NavLink>
                        </li>
                    )}
                {acessos?.find(x => x.func === 106) && this.context.newsletter && (
                    <li className="nav-item">
                        <NavLink to="/gestao-newsletters" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Newsletters
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 107) && this.context.stepforms && (
                    <li className="nav-item">
                        <NavLink to="/gestao-questionarios" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Stepforms
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 108) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Configurações
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 109) && (
                    <li className="nav-item">
                        <NavLink
                            to="/gestao-unidades-organizacionais"
                            className="nav-link"
                            onClick={() => this.setState({ mobile: false })}
                        >
                            Estabelecimentos
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 110) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-paginas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Páginas
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 132) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-dashboard" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Dashboard
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 133) && (
                    <li className="nav-item">
                        <NavLink to="/dashboard" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Dashboard
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 111) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-faq" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            FAQ
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 112) && this.context.monetizacao_entidade && this.context.monetizacao_stepforma && (
                    <li className="nav-item">
                        <NavLink to="/gestao-pagamentos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Pagamentos
                        </NavLink>
                    </li>
                )}
            </>
        );

        const menuFormacao = (
            <>
                {acessos?.find(x => x.func === 201) && (
                    <li className="nav-item">
                        <NavLink
                            to="/gestao-cursos"
                            className="nav-link"
                            onClick={() => {
                                this.setState({ mobile: false });
                                localStorage.removeItem("opcao_voltar");
                                localStorage.removeItem("temp_opcao_voltar");
                            }}
                        >
                            <strong>Cursos</strong>
                        </NavLink>
                    </li>
                )}
                {this.context.relatorios && acessos?.find(x => x.func === 202) && (
                    <li className="nav-item">
                        <NavLink to="/relatorios" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Relatórios
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 203) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-edicoes" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Edições
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 204) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-categorias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Categorias
                        </NavLink>
                    </li>
                )}
                {this.context.estatisticas && acessos?.find(x => x.func === 204) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-estatisticas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Estatísticas
                        </NavLink>
                    </li>
                )}
            </>
        );

        const menuEducacao = (
            <>
                {acessos?.find(x => x.func === 1) && (
                    <li className="nav-item">
                        <NavLink
                            to="/gestao-turmas"
                            className="nav-link"
                            onClick={() => {
                                this.setState({ mobile: false });
                                localStorage.removeItem("opcao_voltar");
                                localStorage.removeItem("temp_opcao_voltar");
                                localStorage.removeItem("filtroEdicao");
                                localStorage.removeItem("filtroCategoria");
                            }}
                        >
                            <strong>Turmas</strong>
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 2) && (
                    <li className="nav-item">
                        <NavLink
                            to="/gestao-templates-turmas"
                            className="nav-link"
                            onClick={() => {
                                this.setState({ mobile: false });
                                localStorage.removeItem("opcao_voltar");
                                localStorage.removeItem("temp_opcao_voltar");
                            }}
                        >
                            Planos de estudos
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 3) && this.context.relatorios && (
                    <li className="nav-item">
                        <NavLink to="/relatorios" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Relatórios
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 4) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-diario" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Avaliação
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 5) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-calendarios-escolares" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Calendários
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 6) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-turmas-edicoes" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Edições
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 7) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-turmas-anos-letivos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Anos letivos
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 8) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-turmas-turnos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Turnos
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 9) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-turmas-tipo-horas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Tipos de horas
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 10) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-turmas-categorias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Níveis de ensino
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 11) && this.context.estatisticas && (
                    <li className="nav-item">
                        <NavLink to="/gestao-estatisticas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Estatísticas
                        </NavLink>
                    </li>
                )}
                {acessos?.find(x => x.func === 12) && (
                    <li className="nav-item">
                        <NavLink to="/gestao-fichas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Fichas
                        </NavLink>
                    </li>
                )}
            </>
        );

        const menuAdminSuporteMobile = (
            <div className="bloco-nav-list">
                <Collapse
                    className="collapse-nav-list-gestao"
                    defaultActiveKey={this.state.menuGestaoMobileEstado}
                    onChange={menuGestaoMobileEstado => this.setState({ menuGestaoMobileEstado })}
                >
                    <Panel header={<h3>Menu</h3>} key="1">
                        <ul className="nav-list-mobile-ul">
                            {acessos?.find(x => x.func === 101) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-utilizadores-todos"
                                        className="nav-link"
                                        onClick={() => {
                                            this.setState({ mobile: false });
                                            this.context.atualizarState({
                                                pesquisaInfo: null
                                            });
                                        }}
                                    >
                                        Usuários
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 102) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-categoria-noticias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Categorias Notícias
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 102) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-noticias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Notícias
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 103) && this.context.eventos && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-calendarios-eventos"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Stepeventos
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 104) && this.context.conf_total_participantes > 0 && this.context.stepmeets && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-videoconferencias"
                                        className="nav-link"
                                        onClick={() => {
                                            this.setState({ mobile: false });
                                            localStorage.removeItem("menu_mensagens");
                                        }}
                                    >
                                        Stepmeets
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 105) &&
                                this.context.conf_total_participantes > 0 &&
                                this.context.conf_total_recorders > 0 &&
                                this.context.stebinars && (
                                    <li className="nav-item">
                                        <NavLink
                                            to="/gestao-webinars"
                                            className="nav-link"
                                            onClick={() => {
                                                this.setState({ mobile: false });
                                                localStorage.removeItem("menu_mensagens");
                                            }}
                                        >
                                            Stebinars
                                        </NavLink>
                                    </li>
                                )}
                            {acessos?.find(x => x.func === 106) && this.context.newsletter && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-newsletters" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Newsletters
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 107) && this.context.stepforms && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-questionarios"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Stepforms
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 108) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Configurações
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 109) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-unidades-organizacionais"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Estabelecimentos
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 110) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-paginas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Páginas
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 132) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-dashboard" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Dashboard
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 133) && (
                                <li className="nav-item">
                                    <NavLink to="/dashboard" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Dashboard
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 111) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-faq" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        FAQ
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 112) &&
                                this.context.monetizacao_entidade &&
                                this.context.monetizacao_stepforma && (
                                    <li className="nav-item">
                                        <NavLink to="/logs" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                            Logs
                                        </NavLink>
                                    </li>
                                )}
                        </ul>
                    </Panel>
                </Collapse>
            </div>
        );

        const menuFormacaoSuporteMobile = (
            <div className="bloco-nav-list">
                <Collapse
                    className="collapse-nav-list-gestao"
                    defaultActiveKey={this.state.menuGestaoMobileEstado}
                    onChange={menuGestaoMobileEstado => this.setState({ menuGestaoMobileEstado })}
                >
                    <Panel header={<h3>Formação</h3>} key="1">
                        <ul className="nav-list-mobile-ul">
                            {acessos?.find(x => x.func === 201) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-cursos"
                                        className="nav-link"
                                        onClick={() => {
                                            this.setState({ mobile: false });
                                            localStorage.removeItem("opcao_voltar");
                                            localStorage.removeItem("temp_opcao_voltar");
                                        }}
                                    >
                                        <strong>Cursos</strong>
                                    </NavLink>
                                </li>)}
                            {(this.context.relatorios && acessos?.find(x => x.func === 202)) && (
                                <li className="nav-item">
                                    <NavLink to="/relatorios" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Relatórios
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 203) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-edicoes" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Edições
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 204) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-categorias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Categorias
                                    </NavLink>
                                </li>
                            )}
                            {this.context.estatisticas && acessos?.find(x => x.func === 205) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-estatisticas"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Estatísticas
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </Panel>
                </Collapse>
            </div>
        );

        const menuEducacaoSuporteMobile = (
            <div className="bloco-nav-list">
                <Collapse
                    className="collapse-nav-list-gestao"
                    defaultActiveKey={this.state.menuGestaoMobileEstado}
                    onChange={menuGestaoMobileEstado => this.setState({ menuGestaoMobileEstado })}
                >
                    <Panel header={<h3>Educação</h3>} key="1">
                        <ul className="nav-list-mobile-ul">
                            {acessos?.find(x => x.func === 1) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-turmas"
                                        className="nav-link"
                                        onClick={() => {
                                            this.setState({ mobile: false });
                                            localStorage.removeItem("opcao_voltar");
                                            localStorage.removeItem("temp_opcao_voltar");
                                        }}
                                    >
                                        <strong>Turmas</strong>
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 2) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-templates-turmas"
                                        className="nav-link"
                                        onClick={() => {
                                            this.setState({ mobile: false });
                                            localStorage.removeItem("opcao_voltar");
                                            localStorage.removeItem("temp_opcao_voltar");
                                        }}
                                    >
                                        Planos de estudos
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 3) && this.context.relatorios && (
                                <li className="nav-item">
                                    <NavLink to="/relatorios" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Relatórios
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 4) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-diario" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Avaliação
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 5) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-calendarios-escolares"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Calendários
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 6) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-turmas-edicoes"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Edições
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 7) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-turmas-anos-letivos"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Anos letivos
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 8) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-turmas-turnos"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Turnos
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 9) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-turmas-tipo-horas"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Tipos de horas
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 10) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-turmas-categorias"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Níveis de ensino
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 11) && this.context.estatisticas && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/gestao-estatisticas"
                                        className="nav-link"
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Estatísticas
                                    </NavLink>
                                </li>
                            )}
                            {acessos?.find(x => x.func === 12) && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-fichas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Fichas
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </Panel>
                </Collapse>
            </div>
        );
        //SUPORTE

        const menuEstabelecimentos = (
            <Menu className="navbar-opcao-escolas">
                {estabelecimentos.map(estabelecimento => (
                    <Menu.Item
                        key={estabelecimento.id}
                        title={estabelecimento.nome}
                        onClick={() => this.onChangeEstabelecimento(estabelecimento)}
                    >
                        {estabelecimento.abreviatura}
                    </Menu.Item>
                ))}
            </Menu>
        );

        const montarLogoLink = perfilId => {
            if (!perfilId) perfilId = this.context.perfil?.id;

            if (perfilId == 1)
                //SUPORTE
                return "/gestao-suporte";
            else if (perfilId == 2)
                //GESTOR
                return "/gestao-turmas"; // "/gestao-utilizadores-todos";
            else if (perfilId == 3)
                //ADMINISTRATIVO
                return "/gestao-turmas"; //"/relatorios";
            else if (perfilId == 4)
                //DIRETOR
                return "/gestao-turmas";
            //ALUNO, PROFESSOR E COORDENADOR
            else return "/utilizador-detalhe";
        };

        const menuPerfis = (
            <Menu>
                {perfis.map(perfil => (
                    <Menu.Item key={perfil.id} onClick={() => this.onChangePerfil(perfil)}>
                        <Link to={montarLogoLink(perfil.id)}>{perfil.nome}</Link>
                    </Menu.Item>
                ))}
            </Menu>
        );

        //ALUNO
        const menuUtilizador = () => {
            if (!this.state.entidade_chave || localStorage.getItem("redirecionado") !== "true")
                return (
                    <>
                        <li
                            className="nav-item"
                            style={{ textAlign: "center", background: "rgba(0, 0, 0, 0.04)", borderRadius: "4px", padding: "5px 10px" }}
                        >
                            {estabelecimentos.length > 1 ? (
                                <Dropdown overlay={menuEstabelecimentos} trigger={["click"]}>
                                    <span
                                        className="value"
                                        onClick={e => e.preventDefault()}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "5px",
                                            width: "100%"
                                        }}
                                    >
                                        {this.context.estabelecimento?.abreviatura} <Icon type="down" />
                                    </span>
                                </Dropdown>
                            ) : (
                                <span
                                    className="value"
                                    onClick={e => e.preventDefault()}
                                    style={{
                                        cursor: "not-allowed",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: "5px",
                                        width: "100%"
                                    }}
                                >
                                    {this.context.estabelecimento?.abreviatura} <Icon type="down" />
                                </span>
                            )}
                        </li>
                        <li
                            className="nav-item"
                            style={{ textAlign: "center", background: "rgba(0, 0, 0, 0.04)", borderRadius: "4px", padding: "5px 10px" }}
                        >
                            {perfis.length > 1 ? (
                                <Dropdown overlay={menuPerfis} trigger={["click"]}>
                                    <span
                                        className="value"
                                        onClick={e => e.preventDefault()}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "5px",
                                            width: "100%"
                                        }}
                                    >
                                        {this.context.perfil?.nome} <Icon type="down" />
                                    </span>
                                </Dropdown>
                            ) : (
                                <span
                                    className="value"
                                    onClick={e => e.preventDefault()}
                                    style={{
                                        cursor: "not-allowed",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: "5px",
                                        width: "100%"
                                    }}
                                >
                                    {this.context.perfil?.nome} <Icon type="down" />
                                </span>
                            )}
                        </li>
                        <li className="nav-item">
                            <NavLink to="/utilizador-detalhe" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/user-alterar-senha" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                Alterar Senha
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/user-alterar-login" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                Alterar Login
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/user-configuracoes"
                                className="nav-link"
                                title=""
                                onClick={() => this.setState({ mobile: false })}
                            >
                                Configurações
                            </NavLink>
                        </li>
                        {this.context.estatisticas && (
                            <li className="nav-item">
                                <NavLink to="/user-estatisticas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Estatísticas
                                </NavLink>
                            </li>
                        )}
                        <li className="nav-item">
                            <button
                                className="nav-link botao-principal"
                                onClick={this.logOut}
                                style={{ color: "white", fontSize: "14px", paddingTop: 5, paddingBottom: 5, textAlign: "center" }}
                            >
                                Sair
                            </button>
                        </li>
                    </>
                );
            else if (this.state.entidade_chave === "sabio" && localStorage.getItem("redirecionado") === "true")
                return (
                    <>
                        <li className="nav-item">
                            <NavLink to="/user-minha-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                Conta
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/user-configuracoes"
                                className="nav-link"
                                title=""
                                onClick={() => this.setState({ mobile: false })}
                            >
                                Configurações
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/sistema-informacao"
                                className="nav-link"
                                title=""
                                onClick={() => {
                                    this.setState({ mobile: false });
                                    localStorage.removeItem("opcao_voltar");
                                    localStorage.removeItem("temp_opcao_voltar");
                                }}
                            >
                                <strong>Cursos</strong>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/turmas"
                                className="nav-link"
                                title=""
                                onClick={() => {
                                    this.setState({ mobile: false });
                                    localStorage.removeItem("opcao_voltar");
                                    localStorage.removeItem("temp_opcao_voltar");
                                }}
                            >
                                <strong>Turmas</strong>
                            </NavLink>
                        </li>
                        {this.context.estatisticas && (
                            <li className="nav-item">
                                <NavLink to="/user-estatisticas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Estatísticas
                                </NavLink>
                            </li>
                        )}
                        <li className="nav-item">
                            <button
                                className="nav-link botao-principal"
                                onClick={this.logOut}
                                style={{ color: "white", fontSize: "14px", paddingTop: 5, paddingBottom: 5, textAlign: "center" }}
                            >
                                Sair
                            </button>
                        </li>
                    </>
                );
            else
                return (
                    <li className="nav-item">
                        <button
                            className="nav-link botao-principal"
                            onClick={this.logOut}
                            style={{ color: "white", fontSize: "14px", paddingTop: 5, paddingBottom: 5, textAlign: "center" }}
                        >
                            Sair
                        </button>
                    </li>
                );
        };

        const menuUtilizadorMobile =
            (!this.state.entidade_chave && this.context.role === "UTILIZADOR") || this.context.role !== "UTILIZADOR" ? (
                <div className="bloco-nav-list">
                    <Collapse
                        className="collapse-nav-list-gestao"
                        defaultActiveKey={this.state.menuUtilizadorMobileEstado}
                        onChange={menuUtilizadorMobileEstado => this.setState({ menuUtilizadorMobileEstado })}
                    >
                        <Panel header={<h3>Usuário</h3>} key="1">
                            <ul className="nav-list-mobile-ul">
                                <li className="nav-item">
                                    <NavLink to="/user-minha-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Conta
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/user-alterar-senha" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Alterar Senha
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/user-alterar-login" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Alterar Login
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/user-configuracoes"
                                        className="nav-link"
                                        title=""
                                        onClick={() => this.setState({ mobile: false })}
                                    >
                                        Configurações
                                    </NavLink>
                                </li>
                                {this.context.estatisticas && (
                                    <li className="nav-item">
                                        <NavLink
                                            to="/user-estatisticas"
                                            className="nav-link"
                                            onClick={() => this.setState({ mobile: false })}
                                        >
                                            Estatísticas
                                        </NavLink>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <button className="nav-link botao-principal" onClick={this.logOut} style={{ padding: "5px 30px" }}>
                                        Sair
                                    </button>
                                </li>
                            </ul>
                        </Panel>
                    </Collapse>
                </div>
            ) : (
                <div className="bloco-nav-list">
                    <Collapse className="collapse-nav-list-gestao" defaultActiveKey={["1"]}>
                        <Panel header={<h3>Usuário</h3>} key="1">
                            <ul className="nav-list-mobile-ul">
                                <li className="nav-item">
                                    <button className="nav-link botao-principal" onClick={this.logOut} style={{ padding: "5px 30px" }}>
                                        Sair
                                    </button>
                                </li>
                            </ul>
                        </Panel>
                    </Collapse>
                </div>
            );
        //ALUNO

        const acessosEducacao = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const acessosGestao = [101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112];
        const acessosFormacao = [201, 202, 203, 204, 205];

        return (
            <div className="navbar-log-in">
                <div className="container container-v2">
                    <Link to={montarLogoLink()} className="brand" title={entidade_nome}>
                        <img src={entidade_imagem_propria ? (entidade_logo ? entidade_logo : noimage) : logo} alt="Logo" className="logo" />
                    </Link>
                    <div className="links">
                        <ul className="nav-list nav-list-esquerda nav-list-esquerda-desktop">
                            {this.context.modulo_formacao && this.context.modulo_formacao_stepforma && this.context.role !== "ADMIN" && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/pesquisa-cursos"
                                        className="nav-link"
                                        onClick={() => {
                                            localStorage.removeItem("opcao_voltar");
                                            localStorage.removeItem("temp_opcao_voltar");
                                        }}
                                    >
                                        CATÁLOGO
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                        <ul className="nav-list nav-list-esquerda nav-list-esquerda-mobile">
                            {this.context.calendario && this.context.calendario_stepforma && (
                                <li className="nav-item nav-item-icon">
                                    <Badge count={this.context.totalEventosHoje} showZero={false} style={{ backgroundColor: "#f79071" }}>
                                        <Link to="/calendario" style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.8)" }}>
                                            <i className="fas fa-calendar-alt"></i>
                                        </Link>
                                    </Badge>
                                </li>
                            )}
                            {this.context.monetizacao_entidade && this.context.monetizacao_stepforma && (
                                <li className="nav-item">
                                    <Badge count={this.context.carrinho.length} showZero={false} style={{ backgroundColor: "#f79071" }}>
                                        <Link
                                            to="#"
                                            className="carrinho-compras"
                                            onClick={() => this.setState({ visibleCart: true })}
                                            style={{ fontSize: 25, color: "rgba(0, 0, 0, 0.8)" }}
                                        >
                                            <i className="fas fa-shopping-cart"></i>
                                        </Link>
                                    </Badge>
                                </li>
                            )}
                            {this.context.conversas && this.context.conversas_stepforma && (
                                <li className="nav-item nav-item-icon">
                                    <Badge
                                        count={this.context.totalConversasPorLer}
                                        showZero={false}
                                        style={{ backgroundColor: "#f79071" }}
                                    >
                                        <Link to="/chat" className="link-chat-ipad" style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.8)" }}>
                                            <i className="fas fa-comment-dots"></i>
                                        </Link>
                                        <Link
                                            to="/chat-m"
                                            className="link-chat-mobile"
                                            style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.8)" }}
                                        >
                                            <i className="fas fa-comment-dots"></i>
                                        </Link>
                                    </Badge>
                                </li>
                            )}
                        </ul>
                        {this.state.mobile ? (
                            <ul className="nav-list nav-list-direita nav-list-mobile">
                                <li className="nav-item nav-item-btn">
                                    <Link
                                        to="#"
                                        className="btn-close-nav-list"
                                        onClick={() => this.setState({ mobile: !this.state.mobile })}
                                    >
                                        <Icon type="close" />
                                    </Link>
                                </li>
                                <li
                                    className="nav-item"
                                    style={{
                                        textAlign: "center",
                                        background: "rgba(0, 0, 0, 0.04)",
                                        borderRadius: "4px",
                                        padding: "5px 10px"
                                    }}
                                >
                                    {estabelecimentos.length > 1 ? (
                                        <Dropdown overlay={menuEstabelecimentos} trigger={["click"]}>
                                            <span
                                                className="value"
                                                onClick={e => e.preventDefault()}
                                                style={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    gap: "5px",
                                                    width: "100%"
                                                }}
                                            >
                                                {this.context.estabelecimento?.abreviatura} <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                    ) : (
                                        <span
                                            className="value"
                                            onClick={e => e.preventDefault()}
                                            style={{
                                                cursor: "not-allowed",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: "5px",
                                                width: "100%"
                                            }}
                                        >
                                            {this.context.estabelecimento?.abreviatura} <Icon type="down" />
                                        </span>
                                    )}
                                </li>
                                <li
                                    className="nav-item"
                                    style={{
                                        textAlign: "center",
                                        background: "rgba(0, 0, 0, 0.04)",
                                        borderRadius: "4px",
                                        padding: "5px 10px"
                                    }}
                                >
                                    {perfis.length > 1 ? (
                                        <Dropdown overlay={menuPerfis} trigger={["click"]}>
                                            <span
                                                className="value"
                                                onClick={e => e.preventDefault()}
                                                style={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    gap: "5px",
                                                    width: "100%"
                                                }}
                                            >
                                                {this.context.perfil?.nome} <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                    ) : (
                                        <span
                                            className="value"
                                            onClick={e => e.preventDefault()}
                                            style={{
                                                cursor: "not-allowed",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: "5px",
                                                width: "100%"
                                            }}
                                        >
                                            {this.context.perfil?.nome} <Icon type="down" />
                                        </span>
                                    )}
                                </li>
                                {this.context.modulo_formacao &&
                                    this.context.modulo_formacao_stepforma &&
                                    this.context.role !== "ADMIN" && (
                                        <li className="nav-item">
                                            <NavLink
                                                to="/pesquisa-cursos"
                                                className="nav-link link-catalogo"
                                                onClick={() => {
                                                    this.setState({ mobile: false });
                                                    localStorage.removeItem("opcao_voltar");
                                                    localStorage.removeItem("temp_opcao_voltar");
                                                }}
                                            >
                                                CATÁLOGO
                                            </NavLink>
                                        </li>
                                    )}
                                {acessos?.find(x => acessosGestao.includes(x.func)) && (
                                    <li className="nav-item bloco-nav-item">{menuAdminSuporteMobile}</li>
                                )}
                                {this.context.diario_entidade &&
                                    this.context.diario_stepforma &&
                                    acessos?.find(x => acessosEducacao.includes(x.func)) && (
                                        <li className="nav-item bloco-nav-item">{menuEducacaoSuporteMobile}</li>
                                    )}
                                {this.context.modulo_formacao &&
                                    this.context.modulo_formacao_stepforma &&
                                    acessos?.find(x => acessosFormacao.includes(x.func)) && (
                                        <li className="nav-item bloco-nav-item">{menuFormacaoSuporteMobile}</li>
                                    )}
                                <li className="nav-item bloco-nav-item">{menuUtilizadorMobile}</li>
                            </ul>
                        ) : (
                            <ul className="nav-list nav-list-direita">
                                {this.context.calendario && this.context.calendario_stepforma && (
                                    <li className="nav-item nav-item-icon">
                                        <Badge
                                            count={this.context.totalEventosHoje}
                                            showZero={false}
                                            style={{ backgroundColor: "#f79071" }}
                                        >
                                            <Link
                                                to="/calendario"
                                                className="link-chat-ipad"
                                                    style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.8)" }}
                                            >
                                                <i className="fas fa-calendar-alt"></i>
                                            </Link>
                                        </Badge>
                                    </li>
                                )}
                                {this.context.monetizacao_entidade && this.context.monetizacao_stepforma && (
                                    <li className="nav-item">
                                        <Badge count={this.context.carrinho.length} showZero={false} style={{ backgroundColor: "#f79071" }}>
                                            <Link
                                                to="#"
                                                className="carrinho-compras"
                                                onClick={() => this.setState({ visibleCart: true })}
                                                    style={{ fontSize: 25, color: "rgba(0, 0, 0, 0.8)" }}
                                            >
                                                <i className="fas fa-shopping-cart"></i>
                                            </Link>
                                        </Badge>
                                    </li>
                                )}
                                {this.context.conversas && this.context.conversas_stepforma && (
                                    <li className="nav-item nav-item-icon">
                                        <Badge
                                            count={this.context.totalConversasPorLer}
                                            showZero={false}
                                            style={{ backgroundColor: "#f79071" }}
                                        >
                                                <Link to="/chat" style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.8)" }}>
                                                <i className="fas fa-comment-dots"></i>
                                            </Link>
                                        </Badge>
                                    </li>
                                )}
                                {this.context.modulo_formacao &&
                                    this.context.modulo_formacao_stepforma &&
                                    acessos?.find(x => acessosFormacao.includes(x.func)) && (
                                        <li className="nav-item">
                                            <Dropdown
                                                overlay={menuFormacao}
                                                overlayClassName="dropdown-menu-navbar"
                                                onVisibleChange={this.onVisibleChangeEntidade}
                                            >
                                                <span className="nav-link">
                                                    Formação <Icon type="down" />
                                                </span>
                                            </Dropdown>
                                        </li>
                                    )}
                                {this.context.diario_entidade &&
                                    this.context.diario_stepforma &&
                                    acessos?.find(x => acessosEducacao.includes(x.func)) && (
                                        <li className="nav-item">
                                            <Dropdown
                                                overlay={menuEducacao}
                                                overlayClassName="dropdown-menu-navbar"
                                                onVisibleChange={this.onVisibleChangeEntidade}
                                            >
                                                <span className="nav-link">
                                                    Educação <Icon type="down" />
                                                </span>
                                            </Dropdown>
                                        </li>
                                    )}
                                {acessos?.find(x => acessosGestao.includes(x.func)) && (
                                    <li className="nav-item">
                                        <Dropdown
                                            overlay={menuAdmin}
                                            overlayClassName="dropdown-menu-navbar"
                                            onVisibleChange={this.onVisibleChangeEntidade}
                                        >
                                            <span className="nav-link">
                                                Menu <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <Dropdown
                                        overlay={menuUtilizador()}
                                        overlayClassName="dropdown-menu-navbar"
                                        onVisibleChange={this.onVisibleChange}
                                        //trigger={['click']}
                                    >
                                        <span className="nav-link">
                                            <div className="container-img">
                                                <img
                                                    src={this.context.foto_utilizador ? this.context.foto_utilizador : noimage}
                                                    alt="Avatar"
                                                    title={this.context.nome_utilizador}
                                                />
                                            </div>
                                            <Icon type="down" />
                                        </span>
                                    </Dropdown>
                                </li>
                                <li className="nav-item nav-item-icon">
                                    <Badge
                                        count={nr_tickets}
                                        showZero={false}
                                        style={{ backgroundColor: "#f79071" }}
                                        title="Total de tickets por ler"
                                    >
                                        <Link
                                            to={this.context.perfil?.id === 1 ? "/gestao-suporte" : "/user-suporte"}
                                                style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.8)" }}
                                        >
                                            <i className="fas fa-headset"></i>
                                        </Link>
                                    </Badge>
                                </li>
                            </ul>
                        )}
                    </div>
                    <div className="burger" onClick={() => this.setState({ mobile: !this.state.mobile })}>
                        <i className="fas fa-bars"></i>
                    </div>
                </div>
                <DrawerCarrinho
                    visibleCart={visibleCart}
                    onClose={() => this.setState({ visibleCart: false })}
                    voltarCarrinho={this.voltarCarrinho}
                />
                <Modal visible={iconLoading} maskClosable={false} className="modal-loading" footer={null} closable={false}>
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A sair...</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Navbar;
