import React, { useState, useEffect } from "react";
import { Link, withRouter, useHistory, useParams } from "react-router-dom";
import { FaFacebookSquare, FaInstagramSquare, FaWhatsappSquare, FaEnvelope, FaPhone, FaTwitterSquare, FaLinkedinIn, FaGlobe } from "react-icons/fa";

import InputSender from "./InputSender";
import logo from "../../../images/logos/logo_stepforma.svg"
const Footer = ({ redessociais: { facebook, instagram, twitter, linkedin, whatsapp }, entidade: { email, telefone, site, logo_entidade, imagem_propria } }) => {
    const [semFooter, setSemFooter] = useState("");

    const history = useHistory();

    const { entidade } = useParams();

    const whastapp = `https://api.whatsapp.com/send?phone=${whatsapp}`

    useEffect(() => {
        const excludedPaths = [
            "player-webinar",
            "player-videoconferencia",
            "player-video",
            "player-video-meets",
            "player-audio",
            "player-pagina",
            "player-pdf",
            "player-questionario",
            "player-questionario-resumo",
            "player-questionario-resolucao",
            "player-avaliacao",
            "player-avaliacao-resumo",
            "player-avaliacao-resolucao",
            "player-download",
            "player-trabalho",
            "player-link",
            "player-sessao-online",
            "player-sessao-presencial",
            "player-trabalho-ficheiro",
            "player-ficheiro",
            "player-assiduidade",
            "player-sessao-persencial"
        ];

        const pathParts = history.location.pathname.split("/");
        const lastPathSegment = pathParts.pop();

        if (excludedPaths.includes(lastPathSegment) || pathParts[1] === "controller")
            setSemFooter("sem-navbar");
        else
            setSemFooter("");

    }, [history.location.pathname]);

    return (
        <>
            <div className={`footer-home ${semFooter}`}>
                <div className="footer-left-block footer-left-block-desktop">
                    <div className="logo-block">
                        <img src={imagem_propria && logo_entidade ? logo_entidade : logo} alt="Logo" />
                        <div className="terms-links">
                            <Link to={`/${entidade}/politicas-privacidade`}>Política de Privacidade</Link>
                            <Link to={`/${entidade}/termos-uso`}>Termos de Uso</Link>
                        </div>
                        <span>Todos os direitos reservados @ StepForma - 2021 v3.6</span><br />
                    </div>
                    <div className="contacts-block">
                        {(facebook || instagram || twitter || linkedin || whastapp) && (
                            <span>
                                <span className="contacts-title">Social</span>
                                <ul>
                                    {facebook && (
                                        <li>
                                            <a href={facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                                <FaFacebookSquare />
                                                Facebook
                                            </a>
                                        </li>)}
                                    {instagram && (
                                        <li>
                                            <a href={instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                                <FaInstagramSquare />
                                                Instagram
                                            </a>
                                        </li>)}
                                    {twitter && (
                                        <li>
                                            <a href={twitter} target="_blank" rel="noopener noreferrer" title="Twitter">
                                                <FaTwitterSquare />
                                                Twitter
                                            </a>
                                        </li>)}
                                    {linkedin && (
                                        <li>
                                            <a href={linkedin} target="_blank" rel="noopener noreferrer" title="LinkedIn">
                                                <FaLinkedinIn />
                                                Linkedin
                                            </a>
                                        </li>)}
                                    {whastapp && (
                                        <li>
                                            <a href={whastapp} target="_blank" rel="noopener noreferrer" title="Whatsapp">
                                                <FaWhatsappSquare />
                                                Whatsapp
                                            </a>
                                        </li>)}
                                </ul>
                            </span>)}
                        {(email || telefone || site) && (
                            <span>
                                <span className="contacts-title">Contato</span>
                                <ul>
                                    {email && (
                                        <li>
                                            <a href={`mailto:${email}`} className="contacts-smaller-icon" rel="noopener noreferrer" title="E-mail">
                                                <FaEnvelope />
                                                {email}
                                                {/*E-mail*/}
                                            </a>
                                        </li>)}
                                    {telefone && (
                                        <li>
                                            <a href="#" className="contacts-smaller-icon" rel="noopener noreferrer" title="Telefone" style={{ cursor: "default" }}>
                                                <FaPhone />
                                                {telefone}
                                            </a>
                                        </li>)}
                                    {site && (
                                        <li>
                                            <a href={site} className="contacts-smaller-icon" rel="noopener noreferrer" title="Website" style={{ cursor: "default" }}>
                                                <FaGlobe />
                                                {site}
                                            </a>
                                        </li>)}
                                </ul>
                            </span>)}
                    </div>
                </div>
                <div className="footer-left-block footer-left-block-laptop">
                    <div>
                        <div className="footer-left-block-top">
                            <div className="logo-block">
                                <img src={logo} alt="Logo" />
                            </div>
                            <div className="contacts-block">
                                {(facebook || instagram || twitter || linkedin || whastapp) && (
                                    <span>
                                        <span className="contacts-title">Social</span>
                                        <ul>
                                            {facebook && (
                                                <li>
                                                    <a href={facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                                        <FaFacebookSquare />
                                                        Facebook
                                                    </a>
                                                </li>)}
                                            {instagram && (
                                                <li>
                                                    <a href={instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                                        <FaInstagramSquare />
                                                        Instagram
                                                    </a>
                                                </li>)}
                                            {twitter && (
                                                <li>
                                                    <a href={twitter} target="_blank" rel="noopener noreferrer" title="Twitter">
                                                        <FaTwitterSquare />
                                                        Twitter
                                                    </a>
                                                </li>)}
                                            {linkedin && (
                                                <li>
                                                    <a href={linkedin} target="_blank" rel="noopener noreferrer" title="LinkedIn">
                                                        <FaLinkedinIn />
                                                        Linkedin
                                                    </a>
                                                </li>)}
                                            {whastapp && (
                                                <li>
                                                    <a href={whastapp} target="_blank" rel="noopener noreferrer" title="Whatsapp">
                                                        <FaWhatsappSquare />
                                                        Whatsapp
                                                    </a>
                                                </li>)}
                                        </ul>
                                    </span>)}
                                {(email || telefone || site) && (
                                    <span>
                                        <span className="contacts-title">Contato</span>
                                        <ul>
                                            {email && (
                                                <li>
                                                    <a href={`mailto:${email}`} className="contacts-smaller-icon" rel="noopener noreferrer" title="E-mail">
                                                        <FaEnvelope />
                                                        {email}
                                                    </a>
                                                </li>)}
                                            {telefone && (
                                                <li>
                                                    <a href="#" className="contacts-smaller-icon" rel="noopener noreferrer" title="Telefone" style={{ cursor: "default" }}>
                                                        <FaPhone />
                                                        {telefone}
                                                    </a>
                                                </li>)}
                                            {site && (
                                                <li>
                                                    <a href={site} className="contacts-smaller-icon" rel="noopener noreferrer" title="Website" style={{ cursor: "default" }}>
                                                        <FaGlobe />
                                                        {site}
                                                    </a>
                                                </li>)}
                                        </ul>
                                    </span>)}
                            </div>
                        </div>
                        <div className="footer-left-block-bottom">
                            <div className="terms-links">
                                <Link to={`/${entidade}/politicas-privacidade`}>Política de Privacidade</Link>
                                <Link to={`/${entidade}/termos-uso`}>Termos de Uso</Link>
                            </div>
                            <span>Todos os direitos reservados @ StepForma - Agosto 2023 v5</span>
                        </div>
                    </div>
                </div>
                <div className="footer-separator"></div>
                <div className="footer-right-block">
                    <span className="message-block">
                        <div className="sentence">Nunca foi tão fácil</div>
                        <div className="sentence">Aprender e ensinar.</div>
                        <div className="sentence">Tudo em um só lugar!</div>
                    </span>
                </div>
            </div>
            <div className="footer-home footer-home-tablet">
                <div className="footer-right-block">
                    <span className="message-block">
                        <div className="sentence">Nunca foi tão fácil</div>
                        <div className="sentence">Aprendere ensinar</div>
                        <div className="sentence">Tudo em um só lugar!</div>
                    </span>
                </div>
                <div className="footer-left-block">
                    <div>
                        <div className="footer-left-block-top">
                            <div className="logo-block">
                                <img src={logo} alt="Logo" />
                            </div>
                            <div className="contacts-block">
                                {(facebook || instagram || twitter || linkedin || whastapp) && (
                                    <span>
                                        <span className="contacts-title">Social</span>
                                        <ul>
                                            {facebook && (
                                                <li>
                                                    <a href={facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                                        <FaFacebookSquare />
                                                        Facebook
                                                    </a>
                                                </li>)}
                                            {instagram && (
                                                <li>
                                                    <a href={instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                                        <FaInstagramSquare />
                                                        Instagram
                                                    </a>
                                                </li>)}
                                            {twitter && (
                                                <li>
                                                    <a href={twitter} target="_blank" rel="noopener noreferrer" title="Twitter">
                                                        <FaTwitterSquare />
                                                        Twitter
                                                    </a>
                                                </li>)}
                                            {linkedin && (
                                                <li>
                                                    <a href={linkedin} target="_blank" rel="noopener noreferrer" title="LinkedIn">
                                                        <FaLinkedinIn />
                                                        Linkedin
                                                    </a>
                                                </li>)}
                                            {whastapp && (
                                                <li>
                                                    <a href={whastapp} target="_blank" rel="noopener noreferrer" title="Whatsapp">
                                                        <FaWhatsappSquare />
                                                        Whatsapp
                                                    </a>
                                                </li>)}
                                        </ul>
                                    </span>)}
                                {(email || telefone || site) && (
                                    <span>
                                        <span className="contacts-title">Contato</span>
                                        <ul>
                                            {email && (
                                                <li>
                                                    <a href={`mailto:${email}`} className="contacts-smaller-icon" rel="noopener noreferrer" title="E-mail">
                                                        <FaEnvelope />
                                                        {email}
                                                    </a>
                                                </li>)}
                                            {telefone && (
                                                <li>
                                                    <a href="#" className="contacts-smaller-icon" rel="noopener noreferrer" title="Telefone" style={{ cursor: "default" }}>
                                                        <FaPhone />
                                                        {telefone}
                                                    </a>
                                                </li>)}
                                            {site && (
                                                <li>
                                                    <a href={site} className="contacts-smaller-icon" rel="noopener noreferrer" title="Website" style={{ cursor: "default" }}>
                                                        <FaGlobe />
                                                        {site}
                                                    </a>
                                                </li>)}
                                        </ul>
                                    </span>)}
                            </div>
                        </div>
                        <div className="footer-left-block-bottom">
                            <div className="terms-links">
                                <Link to={`/${entidade}/politicas-privacidade`}>Política de Privacidade</Link>
                                <Link to={`/${entidade}/termos-uso`}>Termos de Uso</Link>
                            </div>
                            <span>Todos os direitos reservados @ StepForma - Agosto 2023 v5</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-home footer-home-mobile">
                <div className="message-block">
                    <div className="sentence">Ensinar e aprender é mais fácil com a Stepforma.</div>
                    <div className="sentence">Tenha tudo em um só lugar!</div>
                </div>
                <div className="request-demo-block">
                    <h2 className="request-demo-title">Solicite uma demonstração</h2>
                    <InputSender />
                </div>
                <div className="contacts-block">
                    <div>
                        {(facebook || instagram || twitter || linkedin || whastapp) && (
                            <span>
                                <span className="contacts-title">Social</span>
                                <ul>
                                    {facebook && (
                                        <li>
                                            <a href={facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                                <FaFacebookSquare />
                                                Facebook
                                            </a>
                                        </li>)}
                                    {instagram && (
                                        <li>
                                            <a href={instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                                <FaInstagramSquare />
                                                Instagram
                                            </a>
                                        </li>)}
                                    {twitter && (
                                        <li>
                                            <a href={twitter} target="_blank" rel="noopener noreferrer" title="Twitter">
                                                <FaTwitterSquare />
                                                Twitter
                                            </a>
                                        </li>)}
                                    {linkedin && (
                                        <li>
                                            <a href={linkedin} target="_blank" rel="noopener noreferrer" title="LinkedIn">
                                                <FaLinkedinIn />
                                                Linkedin
                                            </a>
                                        </li>)}
                                    {whastapp && (
                                        <li>
                                            <a href={whastapp} target="_blank" rel="noopener noreferrer" title="Whatsapp">
                                                <FaWhatsappSquare />
                                                Whatsapp
                                            </a>
                                        </li>)}
                                </ul>
                            </span>)}
                        {(email || telefone || site) && (
                            <span>
                                <span className="contacts-title">Contato</span>
                                <ul>
                                    {email && (
                                        <li>
                                            <a href={`mailto:${email}`} className="contacts-smaller-icon" rel="noopener noreferrer" title="E-mail">
                                                <FaEnvelope />
                                                {email}
                                            </a>
                                        </li>)}
                                    {telefone && (
                                        <li>
                                            <a href="#" className="contacts-smaller-icon" rel="noopener noreferrer" title="Telefone" style={{ cursor: "default" }}>
                                                <FaPhone />
                                                {telefone}
                                            </a>
                                        </li>)}
                                    {site && (
                                        <li>
                                            <a href={site} className="contacts-smaller-icon" rel="noopener noreferrer" title="Website" style={{ cursor: "default" }}>
                                                <FaGlobe />
                                                {site}
                                            </a>
                                        </li>)}
                                </ul>
                            </span>)}
                    </div>
                </div>
                <div className="logo-block">
                    <img src={logo} alt="Logo" />
                    <div className="terms-links">
                        <Link to={`/${entidade}/politicas-privacidade`}>Política de Privacidade</Link>
                        <Link to={`/${entidade}/termos-uso`}>Termos de Uso</Link>
                    </div>
                    <span>Todos os direitos reservados @ StepForma - Agosto 2023 v5</span>
                </div>
            </div>
        </>
    );
}

export default withRouter(Footer);
