import React from "react";
import noImg from '../../../../images/noimage.jpg'

const FotoAluno = ({ foto, nome, numero }) => {
    return (
        <div className="fotoInfo">
            <div className="foto">
                <span className="aluno">Aluno</span>
                <div className="fotoAluno">
                    <img src={foto != null ? foto : noImg} alt="foto" />
                </div>
                {nome !== null &&
                    <div className="container">
                        <div>
                            <span className="nome">{nome}</span>
                        </div>
                        <div className="separador"></div>
                        <div>
                            <span className="numero">{numero}</span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default FotoAluno;