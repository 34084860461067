import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Form,
    Input,
    notification,
    Checkbox,
    Icon,
    Drawer,
    Modal,
    Tooltip,
    Row,
    Col,
    Upload
} from "antd";
import axios from "axios";
import noimage from "../../../images/noimage.png";

import { arraysHaveSameElements } from "../../../Utilities/compare"

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
const DrawerFotos = ({ ativo, visible, id, form, onClose, atualizar }) => {
    const [todos, setTodos] = useState(false);
    const [formData, setFormData] = useState({
        capa: "",
        preview: "",
        titulo: "",
        descricao: "",
        selecionados: [],
        previews: [],
        files: [],

    });
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [validate, setValidate] = useState({
        totalBytes: 0,
        formatoValido: false,
        validateStatus: "",
        help: ""
    });
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator } = form;
    const { TextArea } = Input;
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [fileList, setFileList] = useState([])

    const tamanhoMaximo = (5 * (1024 * 1024));

    const onClose2 = () => {
        onClose();
        setFileList([])
    }

    const guardar = () => {
        const item = new FormData();
        const file = formData.capa;

        // Adiciona os arquivos ao FormData
        fileList.forEach(fileItem => {
            item.append('files', fileItem.originFileObj);
        });
        item.append('file', file);
        item.append("titulo", formData.titulo);
        item.append("descricao", formData.descricao);
        item.append("estabelecimentos", JSON.stringify(formData.selecionados));

        form.validateFieldsAndScroll(async err => {
            if (!err && validate.totalBytes <= tamanhoMaximo && validate.formatoValido && formData.preview !== "") {
                setLoading(true);

                axios({
                    method: "post",
                    url: "/api/fotos-recentes/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Galeria adicionada!");
                        setLoading(false);
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });


            } else {
                if (formData.preview == "")
                    setValidate({
                        totalBytes: 0,
                        formatoValido: false,
                        validateStatus: "error",
                        help: "Campo obrigatório"
                    });
            }
        });
    };

    const urlToFile = async (url, filename, mimeType) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], filename, { type: mimeType });
    }

    const alterar = () => {
        form.validateFieldsAndScroll(async (err) => {
            if (!err && validate.totalBytes <= tamanhoMaximo && formData.preview !== "") {

                setLoading(true);
                const file = formData.capa;
                const item = new FormData();

                // Adiciona os arquivos ao FormData
                for (const fileItem of fileList) {

                    if (fileItem.type === undefined) {
                        const file = await urlToFile(fileItem.url, fileItem.name, 'image/png');
                        item.append('files', file)

                    } else {
                        item.append('files', fileItem.originFileObj);
                    }

                }

                // Adiciona outros campos ao FormData
                item.append('file', file);
                item.append("id", id);
                item.append("titulo", formData.titulo);
                item.append("descricao", formData.descricao);
                item.append("estabelecimentos", JSON.stringify(formData.selecionados));

                axios({
                    method: "put",
                    url: "/api/fotos-recentes/atualizar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Galeria alterada!");
                        atualizar();
                        setLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            } else {
                if (formData.preview === "")
                    setValidate({
                        totalBytes: 0,
                        formatoValido: false,
                        validateStatus: "error",
                        help: "Campo obrigatório"
                    });
            }
        });
    };

    const carregarDetalhe = () => {
        axios({
            method: "get",
            url: "/api/fotos-recentes/detalhe",
            params: {
                id
            }
        })
            .then(response => {
                const sels = response.data.fotoPrincipal.estabelecimentos.filter(estabelecimento => !!estabelecimento);

                setFormData({
                    capa: response.data.fotoPrincipal.capa,
                    preview: response.data.fotoPrincipal.capa,
                    titulo: response.data.fotoPrincipal.titulo,
                    descricao: response.data.fotoPrincipal.texto,
                    selecionados: sels,
                    previews: [],
                    files: []
                });
                
                // Carregando as imagens da galeria
                const galeriaImagens = response.data.galeria.map((foto, index) => ({
                    uid: foto.id,
                    name: 'image.png',
                    status: 'done',
                    url: foto.caminho,
                }));

                // Atualizando o estado do fileList com as imagens da galeria
                setFileList(galeriaImagens)

            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar informação");
            });

    };

    const listarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos"
        })
            .then(response => {
                setEstabelecimentos(response.data);
            })
            .catch(error => { });
    };

    //UPLOAD DA FOTO/IMAGEM DO CURSO
    const uploadFoto = event => {
        if (event.target.files[0].size < tamanhoMaximo) {
            if (validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                setFormData({
                    ...formData,
                    capa: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
                setValidate({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: ""
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        } else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 5 MB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    const validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    const ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //REMOVE A FOTO
    const removerFoto = () => {
        document.getElementById("input-foto").value = "";
        setFormData({ ...formData, capa: "", preview: "" });
    };

    const validarTodosSelecionados = () => {
        const sels = formData.selecionados.filter(estabelecimento => !!estabelecimento);
        const ids = estabelecimentos.map(x => x.id);

        const sameElements = arraysHaveSameElements(ids, sels);

        setTodos(sameElements)
    }

    const afterVisibleChange = aberto => {
        if (aberto) {
            form.resetFields();
            document.getElementById("input-foto").value = "";
            if (id > 0)
                carregarDetalhe();
            else {
                setFormData({
                    capa: "",
                    preview: "",
                    titulo: "",
                    descricao: "",
                    selecionados: [],
                    previews: [],
                    files: [],
                });
                setValidate({
                    totalBytes: 0,
                    formatoValido: false,
                    validateStatus: "",
                    help: ""
                });
                setFileList([])
            }
        }
    };

    useEffect(() => {
        listarEstabelecimentos();
    }, []);

    useEffect(() => {
        validarTodosSelecionados()
    }, [formData.selecionados, todos]);

    // Helper function to get base64 representation of a file
    const getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const handleChange = ({ fileList }) => setFileList(fileList);

    const handleChangeCheckTodos = e => {
        const todos = e.target.checked;
        setTodos(todos)
        form.resetFields();

        if (todos) {
            const ids = estabelecimentos.map(x => x.id)
            setFormData({ ...formData, selecionados: ids })
        }
        else
            setFormData({ ...formData, selecionados: [] })
    };

    const uploadButton = (
        <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <Drawer
            className="drawer-add-cursos drawer-videoconferencias"
            //title={noticiaId > 0 ? "Alterar" : "Adicionar"}
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias" layout="horizontal">
                    <div className="bloco-img">
                        <Form.Item validateStatus={validate.validateStatus} help={validate.help}>
                            <div className="container-img">
                                <img src={formData.preview ? formData.preview : noimage} />
                            </div>
                            {ativo && (
                                <>
                                    {!formData.preview ? (
                                        <>
                                            <Link to="#" className="link-principal-border" onClick={ativarUploadFoto}>
                                                Adicionar
                                            </Link>{" "}
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <div>
                                                        <p>Tamanho máximo: 5 MB</p>
                                                        {/*<p>Tamanho recomendado: 670x400px</p>*/}
                                                        <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                    </div>
                                                }
                                            >
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <>
                                            <Link to="#" className="link-principal-border" onClick={ativarUploadFoto}>
                                                Alterar
                                            </Link>
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <div>
                                                        <p>Tamanho máximo: 5 MB</p>
                                                        {/*<p>Tamanho recomendado: 670x400px</p>*/}
                                                        <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                    </div>
                                                }
                                            >
                                                <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                            </Tooltip>
                                            <Link to="#" className="link-principal-border" onClick={removerFoto}>
                                                Remover
                                            </Link>
                                        </>
                                    )}
                                </>
                            )}
                        </Form.Item>
                        <input type="file" id="input-foto" accept="image/*" onChange={uploadFoto} style={{ display: "none" }} />
                    </div>
                    <Form.Item label="Título">
                        {getFieldDecorator("titulo", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: formData.titulo
                        })(
                            <Input
                                name="titulo"
                                maxLength={100}
                                disabled={!ativo}
                                onChange={e => setFormData({ ...formData, titulo: e.target.value })}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Descricao">
                        {getFieldDecorator("descricao", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: formData.descricao
                        })(
                            <TextArea
                                rows={6}
                                name="descricao"
                                disabled={!ativo}
                                maxLength={400}
                                onChange={e => setFormData({ ...formData, descricao: e.target.value })}
                                style={{ height: "100%" }}
                            />
                        )}
                        <p>{formData.descricao.length} / 400</p>
                    </Form.Item>
                    <Form.Item label={"Upload Fotos"}>
                        <div className="clearfix">
                            <Upload
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                beforeUpload={() => false}
                            >
                                {fileList.length >= 8 ? null : uploadButton}
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </div>

                    </Form.Item>

                    <Form.Item label="Publicar em:">
                        <Checkbox checked={todos} onClick={handleChangeCheckTodos}>Selecionar todos</Checkbox>
                        {getFieldDecorator("selecionados", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: formData.selecionados // Certifique-se de que selecionados é um valor único (não uma array)
                        })(
                            <Checkbox.Group
                                style={{ width: "100%" }}
                                disabled={!ativo}
                                onChange={selecionados => setFormData({ ...formData, selecionados })}
                            >
                                <Row>
                                    {estabelecimentos.map((estabelecimento, index) => (
                                        <Col key={estabelecimento.id} span={16}>
                                            <Checkbox value={estabelecimento.id} title={estabelecimento.nome}>{estabelecimento.abrev}</Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        )}
                    </Form.Item>

                </Form>
            </div>
            <div className="ant-drawer-footer">
                <Link to="#" className="botao-secundario" onClick={onClose2} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </Link>
                {ativo && (
                    <button className="botao-principal" disabled={loading} onClick={id > 0 ? alterar : guardar}>
                        {loading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                )}
            </div>
        </Drawer>
    );
};

const FormDrawerFotos = Form.create({ name: "form-drawer-fotos" })(DrawerFotos);

export default FormDrawerFotos;
