import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, Checkbox, Icon, notification, Select, Upload, DatePicker, Radio } from "antd";
import axios from "axios";

const { Option } = Select;
const Dragger = Upload.Dragger;
const RadioGroup = Radio.Group;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class FormInformacoes extends Component {
    state = {
        data_status: "",
        data_help: "",
        info_status: "",
        info_help: ""
    };

    handleChange = event => {
        this.props.handleChange(event);
        this.setState({
            info_status: "",
            info_help: ""
        });
    };

    handleChangeData = date => {
        this.props.handleChangeData(date);
        this.setState({
            data_status: "",
            data_help: ""
        });
    };

    adicionar = () => {
        if (this.props.dataInfo && this.props.descricaoInfo.replace(/\s/g, "")) {
            this.props.adicionar();
        } else {
            if (!this.props.dataInfo) {
                this.setState({
                    data_status: "error",
                    data_help: "Campo obrigatório"
                });
            }

            if (!this.props.descricaoInfo.replace(/\s/g, "")) {
                this.setState({
                    info_status: "error",
                    info_help: "Campo obrigatório"
                });
            }
        }
    };

    render() {
        const { TextArea } = Input;
        const { dataInfo, descricaoInfo } = this.props;
        const { data_status, data_help, info_status, info_help } = this.state;

        return (
            <Drawer
                className="drawer-from-informacoes"
                //title={this.props.detalhe ? "Editar informação" : "Adicionar nova informação"}
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
            >
                <div className="bloco-info">
                    <Form className="form-aula" layout="horizontal">
                        <Form.Item label="Data" validateStatus={data_status} help={data_help}>
                            <DatePicker placeholder="Selecionar" format="DD/MM/YYYY" value={dataInfo} name="dataInfo" onChange={this.handleChangeData} />
                        </Form.Item>
                        <Form.Item label="Informações" validateStatus={info_status} help={info_help}>
                            <TextArea name="descricaoInfo" value={descricaoInfo} onChange={this.handleChange} rows={4} style={{ height: 100 }} />
                        </Form.Item>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" onClick={() => !this.props.detalhe ? this.adicionar() : this.props.editar(this.props.index)} type="primary">
                        Adicionar
                    </button>
                    {/*{!this.props.detalhe ? (*/}
                    {/*    <button className="botao-principal" onClick={this.adicionar} type="primary">*/}
                    {/*        Adicionar*/}
                    {/*    </button>*/}
                    {/*) : (*/}
                    {/*    <button className="botao-principal" onClick={() => this.props.editar(this.props.index)} type="primary">*/}
                    {/*        Guardar*/}
                    {/*    </button>*/}
                    {/*)}*/}
                </div>
            </Drawer>
        );
    }
}

export default FormInformacoes;
