// src/contexts/DataContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from "axios";

const DataContext = createContext();

const converterData = (isoDate) => {
    const monthNames = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} de ${month} de ${year}`;
};

// Cria o provedor do contexto
const DataProvider = ({ children }) => {
    const [dataAlunosCompioresNotas, setDataAlunosCompioresNotas] = useState([]);
    const [listaFaltasAlunos, setListaFaltasAlunos] = useState([]);
    const [listaFaltasTurma, setListaFaltasTurma] = useState([]);
    const [dataTurmasCompioresNotas, setDataTurmasCompioresNotas] = useState([])
    const [eficienciaAlunos, setEficienciaAlunos] = useState()
    
    const listarPioresNotas = () => {
        axios.get("/api/dashboard/disciplinas")
            .then(response => {
                const alunos = response.data.mediaAluno.map(aluno => {
                    const nomeCompleto = aluno.aluno.trim().split(" ");
                    const primeiroNome = nomeCompleto[0];
                    const ultimoNome = nomeCompleto.length > 1 ? nomeCompleto[nomeCompleto.length - 1] : primeiroNome;
                    return {
                        ...aluno,
                        aluno: `${primeiroNome} ${ultimoNome}`,

                    };
                });
                // console.log(response.data.notasAcimaMedia)
                setDataTurmasCompioresNotas(response.data.mediasTurmas)
                setDataAlunosCompioresNotas(alunos);
                setEficienciaAlunos(response.data.notasAcimaMedia)
            })
            .catch(error => {
                console.error("Erro ao carregar os dados dos alunos:", error);
            });
    };

    const listarAulas = () => {
        axios.get("/api/dashboard/faltas-completo")
            .then(response => {
                
                const alunos =response.data.faltasPorAluno.map(aluno => {
                    const nomeCompleto = aluno.nomeAluno.trim().split(" ");
                    const primeiroNome = nomeCompleto[0];
                    const ultimoNome = nomeCompleto.length > 1 ? nomeCompleto[nomeCompleto.length - 1] : primeiroNome;
                    const dataUltimaPresenca = aluno.dataUltimaPresenca === "0001-01-01T00:00:00" ? "-" : converterData(aluno.dataUltimaPresenca)

                    return {
                        ...aluno,
                        nomeAluno: `${primeiroNome} ${ultimoNome}`,
                        dataUltimaPresenca : dataUltimaPresenca

                    };
                });
                
                setListaFaltasAlunos(alunos);
                setListaFaltasTurma(response.data.faltasPorTurma)
            })
            .catch(error => {
                console.error("Erro ao carregar os dados dos alunos:", error);
            });
    };
    
    useEffect(() => {
        listarPioresNotas();
        listarAulas();
    }, []);

    return (
        <DataContext.Provider value={{ 
            dataAlunosCompioresNotas,
            dataTurmasCompioresNotas,
            listaFaltasAlunos, 
            listaFaltasTurma,
            eficienciaAlunos
        }}>
            {children}
        </DataContext.Provider>
    );
};

export { DataProvider, DataContext };
