import React, {useState} from 'react';

import {Link} from "react-router-dom";
import {FaTableCells, FaTableList} from "react-icons/fa6";
import {log} from "react-modal/lib/helpers/bodyTrap";

const TabelaRelatorioAluno = ({ dynamicColumns, disciplinas }) => {

    const tempCols = dynamicColumns.filter(dc => dc.title !== "Disciplinas" && dc.title !== "Estado")

    const montarValores = (avaliacoes) => {
        let registos = [];
        let media = 0;

        let tempAvaliacoes = avaliacoes;

        tempCols.map(column => {
            const avaIndex = tempAvaliacoes.findIndex(av => av.nome === column.title);

            if (avaIndex !== -1) {
                const ava = tempAvaliacoes[avaIndex];
                if (ava.nome !== "Média Bimestral" && ava.nome !== "Média Anual") {
                    registos.push(<td>{ava.nota}</td>);
                } else {
                    media = ava.nota.replace(",", ".");
                    registos.push(
                        <td>
                            <div className={media >= 6 ? 'fundoCelula aprovado' : 'fundoCelula reprovado'}>
                                {media}
                            </div>
                        </td>
                    );
                }

                // Remove o item encontrado
                tempAvaliacoes = tempAvaliacoes.filter((_, index) => index !== avaIndex);
            } else {
                registos.push(<td>-</td>);
            }
        })

        registos.push(<td>
            <div className={media >= 6 ? 'fundoCelula aprovado' : 'fundoCelula reprovado'}>
                {media >= 6 ? 'Aprovado' : 'Reprovado'}
            </div>
        </td>)

        return registos;
    }
    
    return (
        <table className="tabelaRelatorioAluno">
            <thead>
                <tr>
                    {dynamicColumns.map(column => (
                        <th key={column.key}>{column.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {disciplinas.map((disciplina, index) => (
                    <tr key={index}>
                        <td>{disciplina.disciplina}</td>
                        {montarValores(disciplina.avaliacoes).map(registo => registo)}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TabelaRelatorioAluno;