import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, notification, Modal, Button, Collapse, Select } from "antd";
import ModalAta from "../../Aproveitamento/Relatorios/Drawer/Ata";
import ModalViewer from "../../../Componentes/Modals/ModalViewer";
import ModalLoading from "../../../Componentes/Modals/ModalLoading";

import DrawerAdicionarParecer from "../Drawers/DrawerAdicionarParecer";
import ModalGerarDocumento from "../../../Componentes/Modals/GerarDocumento";

import noimage from "../../../images/noimage.png";
import axios from "axios";
import { useEffect } from "react";

const { Option } = Select;
const { Panel } = Collapse;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const TurmaPareceres = ({ match }) => {
    const [num, setNum] = useState(1);
    const [totalPeriodos, setTotalPeriodos] = useState(0);
    const [periodos, setPeriodos] = useState([]);
    const [periodo, setPeriodo] = useState({
        id: null,
        titulo: "",
        dtInicio: "",
        dtFim: ""
    });
    const [textoParecerGeral, setTextoParecerGeral] = useState("");
    const [drawerAdicionarParecerVisible, setDrawerAdicionarParecerVisible] = useState(false);
    const [parecer, setParecer] = useState({});
    const [participantes, setParticipantes] = useState([]);
    const [docs, setDocs] = useState([]);
    const [ficheiroExportar, setFicheiroExportar] = useState("");
    const [btnDescarregar, setBtnDescarregar] = useState(false);
    const [visibleExportar, setVisibleExportar] = useState(false);
    const [ataVisible, setAtaVisible] = useState(false);
    const [visibleViewer, setVisibleViewer] = useState(false);
    const [semPeriodos, setSemPeriodos] = useState(false);
    const [iconLoading, setIconLoading] = useState(false);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [alunoId, setAlunoId] = useState();
    const [previousLocation, setPreviousLocation] = useState("");

    const location = useLocation();

    const total = () => {
        setIconLoading(true);

        axios({
            method: "get",
            url: "/api/aulas/total-periodos-observacoes",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                if (response.data.length > 0) {
                    setTotalPeriodos(response.data.length);
                    setPeriodos(response.data);
                    setPeriodo({
                        id: response.data[0].id,
                        titulo: response.data[0].titulo,
                        dtInicio: response.data[0].dt_inicio,
                        dtFim: response.data[0].dt_fim,
                    });
                } 
                else {
                    setSemPeriodos(true);
                    setIconLoading(false);

                    openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                }
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    };

    const carregarPareceresAlunos = () => {
        axios({
            method: "get",
            url: "/api/pareceres/carregar-pareceres-alunos",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                periodoId: periodo.id
            }
        })
            .then(response => {
                setParticipantes(response.data);
                setIconLoading(false);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os pareceres!");
            });
    };

    const carregarParecerGeral = () => {
        axios({
            method: "get",
            url: "/api/pareceres/carregar-parecer-geral",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                periodoId: periodo.id
            }
        })
            .then(response => {
                setTextoParecerGeral(response.data);
                setIconLoading(false);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar o parecer geral!");
            });
    };

    const anteriorProximo = num => {
        var periodo = periodos[num - 1];

        setNum(num);
        setPeriodo({
            id: periodo.id,
            titulo: periodo.titulo,
            dtInicio: periodo.dt_inicio,
            dtFim: periodo.dt_fim
        });
        setIconLoading(true);
    };

    const guardar = (texto) => {
        if (parecer.participante?.id == null)
            setTextoParecerGeral(texto);
        else {
            let tempParticipantes = participantes;
            const index = tempParticipantes.findIndex(x => x.id === parecer.participante?.id);

            tempParticipantes[index].parecer = texto;
            setParticipantes([...tempParticipantes]);
        }
    }

    const descarregarParecerDescritivoTurma = dataEmissao => {
        setBtnDescarregar(true);
        setVisibleExportar(true);

        axios({
            method: "get",
            url: "/api/pdf/parecer-descritivo-turma",
            params: {
                utilizadorId: alunoId,
                turmaId: localStorage.getItem("codigo_curso"),
                periodoId: periodo.id,
                dataEmissao: dataEmissao.format("YYYY-MM-DD")
            }
        })
            .then(response => {
                setBtnDescarregar(false);
                setFicheiroExportar(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível descarregar o documento!");

                setVisibleExportar(false);
            });
    };

    const exportarPdf = () => {
        setVisibleLoading(true);

        axios({
            method: "get",
            url: "/api/aulas-exportar/pareceres-turma-aluno-pdf",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                periodoId: periodo.id
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setVisibleViewer(true);
                setDocs([{ uri: response.data }]);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");

                setVisibleLoading(false);
            });
    };

    const exportarGeraisPdf = () => {
        setVisibleLoading(true);

        axios({
            method: "get",
            url: "/api/aulas-exportar/pareceres-gerais-turma-pdf",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                periodoId: periodo.id
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setVisibleViewer(true);
                setDocs([{ uri: response.data }]);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");

                setVisibleLoading(false);
            });
    };

    useEffect(() => {
        if (location.pathname !== previousLocation.pathname) {
            total();
        }

        setPreviousLocation(location);
    }, [location.pathname])

    useEffect(() => {
        if (periodo.id) {
            carregarPareceresAlunos();
            carregarParecerGeral();
        }
    }, [periodo])

    return (
        <>
            <div className="container container-body">
                <div className="bloco-curso-diario-opcoes" style={{ gap: 10 }}>
                    <h3 className="titulo-bloco">Pareceres</h3>
                    <div className="bloco-opcoes-esquerda">
                        <Link
                            to={`/gerir-detalhe-turma/${match.params.turma}/${localStorage.getItem("vista") === "TURMA" ? "horario" : "horario-disciplina"
                                }`}
                            className="botao-secundario botao-secundario-redondo"
                            title="Voltar"
                            style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            <Icon type="arrow-left" />
                        </Link>
                    </div>
                </div>
                <div className="curso-diario">
                    <div className="bloco-controlos-frequencia">
                        <div className="filters" style={{ display: "flex", justifyContent: "center" }}>
                            {!semPeriodos && (
                                <div>
                                    <Button
                                        className="botao-periodo botao-periodo-anterior"
                                        disabled={num === 1}
                                        style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                        onClick={() => anteriorProximo(num - 1)}
                                    >
                                        <Icon type="left" />
                                    </Button>
                                    <span>{periodo.titulo}</span>
                                    <Button
                                        className="botao-periodo botao-periodo-proximo"
                                        disabled={num === periodos.length}
                                        style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                        onClick={() => anteriorProximo(num + 1)}
                                    >
                                        <Icon type="right" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    <Collapse defaultActiveKey={[]} className="collapse-diario-observacoes">
                        <Panel
                            header={
                                <span
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%"
                                    }}
                                >
                                    <label>Pareceres por Aluno</label>
                                    <span style={{ display: "flex", gap: 10 }}>
                                        <Link
                                            className="botao-icon-detalhe"
                                            to="#"
                                            title="Visualizar"
                                            onClick={event => {
                                                event.stopPropagation();
                                                exportarPdf();
                                            }}
                                        >
                                            <Icon type="eye" />
                                        </Link>
                                    </span>
                                </span>
                            }
                            key="1"
                        >
                            <div className="bloco-diario-observacoes">
                                <ul className="lista-observacoes">{participantes.map(participante => (
                                    <li key={participante.id} className="item-observacoes">
                                        <div
                                            className="info-participante"
                                            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <div className="container-img">
                                                <img src={participante.foto ? participante.foto : noimage} />
                                            </div>
                                            <div className="info" style={{ flex: "fit-content" }}>
                                                <span className="nome">{participante.nome}</span>
                                                <p className="numero">{participante.numero}</p>
                                            </div>
                                            <div className="bloco-opcoes-esquerda" style={{ display: "flex", gap: 5 }}>
                                                <Link
                                                    to="#"
                                                    className="botao-icon-editar"
                                                    title="Editar"
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        setDrawerAdicionarParecerVisible(true);
                                                        setParecer({
                                                            participante
                                                        })
                                                    }}
                                                >
                                                    <Icon type="edit" />
                                                </Link>
                                                <Link
                                                    className="botao-icon-parecer-aluno"
                                                    to="#"
                                                    title="Visualizar"
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        setAlunoId(participante.id);
                                                        setAtaVisible(true);
                                                    }}
                                                >
                                                    <Icon type="profile" />
                                                </Link>
                                            </div>
                                        </div>
                                        <p>{participante.parecer}</p>
                                    </li>
                                ))}
                                </ul>
                            </div>
                        </Panel>
                        <Panel
                            header={
                                <span
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%"
                                    }}
                                >
                                    <label>Pareceres Gerais</label>
                                    <span style={{ display: "flex", gap: 10 }}>
                                        <Link
                                            to="#"
                                            className="botao-icon-editar"
                                            title="Editar"
                                            onClick={event => {
                                                event.stopPropagation();
                                                setDrawerAdicionarParecerVisible(true);
                                                setParecer({
                                                    textoParecerGeral
                                                })
                                            }}
                                        >
                                            <Icon type="edit" />
                                        </Link>
                                        <Link
                                            className="botao-icon-detalhe"
                                            to="#"
                                            title="Visualizar"
                                            onClick={event => {
                                                event.stopPropagation();
                                                exportarGeraisPdf();
                                            }}
                                        >
                                            <Icon type="eye" />
                                        </Link>
                                    </span>
                                </span>
                            }
                            key="2"
                        >
                            <div className="bloco-diario-observacoes">
                                <p className="observacao-geral">{textoParecerGeral ? textoParecerGeral : "Sem dados..."}</p>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div>
            <Modal visible={iconLoading} maskClosable={false} className="modal-loading" footer={null} closable={false}>
                <div className="modal-loading-bloco">
                    <p>
                        <Icon type="loading" />
                    </p>
                    <p className="texto">A carregar...</p>
                </div>
            </Modal>
            <ModalGerarDocumento
                visibleExportar={visibleExportar}
                ficheiroExportar={ficheiroExportar}
                btnDescarregar={btnDescarregar}
                onClose={() => setVisibleExportar(false)}
            />
            <ModalLoading visible={visibleLoading} text="A gerar documento..." />
            <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => setVisibleViewer(false)} />
            <ModalAta
                visible={ataVisible}
                onClose={() => setAtaVisible(false)}
                onConfirm={dataEmissao => {
                    descarregarParecerDescritivoTurma(dataEmissao)
                    setAtaVisible(false);
                }}
            />
            <DrawerAdicionarParecer
                parecerInfo={parecer}
                periodoId={periodo.id}
                visible={drawerAdicionarParecerVisible}
                onClose={() => setDrawerAdicionarParecerVisible(false)}
                onConfirm={(texto) => {
                    guardar(texto);
                    setDrawerAdicionarParecerVisible(false);
                }}
            />
        </>
    );
}

export default TurmaPareceres;
