import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Checkbox, notification, Icon, Tooltip } from "antd";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Formacao extends Component {
    static contextType = GlobalContext;

    state = {
        //CONFIGURACOES
        imagem_propria: false,
        novidades: false,
        visivel: false,
        visivel_entidade: true,
        nome_abrev: "",
        //
        responsaveis_stepforma: false,
        responsaveis: false,
        login2_stepforma: false,
        login2: false,
        autoconta_stepforma: false,
        autoconta: false,
        gamificacao_stepforma: false,
        gamificacao: false,
        conteudos_animados_stepforma: false,
        conteudos_animados: false,
        organismos_stepforma: false,
        escolas: false,
        monetizacao: false,
        monetizacaoEntidade: false,
        subscricao: false,
        valorMensal: 0,
        vendaCursos: false,
        vendaCertificados: false,
        //METODOS PAGAMENTO
        metodos: [],
        paypal: false,
        cartao: false,
        transferencia: false,
        iban: "",
        servicoExterno: false,
        linkPagamento: "",
        //DADOS HOST
        host: "",
        port: "",
        ssl: false,
        username: "",
        password: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.carregarDados();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeHost = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
                if (!this.state.host && !this.state.port && !this.state.username && !this.state.password) {
                    this.props.form.resetFields()
                }
        });
    };

    handleChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    carregarDados = () => {
        axios({
            method: "get",
            url: "/api/empresa/configs-formacao"
        })
            .then(response => {
                this.setState({
                    //CONFIGURACOES
                    novidades: response.data.novidades,
                    autoconta_stepforma: response.data.autoconta_stepforma,
                    autoconta: response.data.autoconta,
                    gamificacao_stepforma: response.data.gamificacao_stepforma,
                    gamificacao: response.data.gamificacao,
                    monetizacao: response.data.monetizacao,
                    monetizacaoEntidade: response.data.monetizacao_entidade,
                    subscricao: response.data.subscricao,
                    valorMensal: (response.data.valorMensal + "").replace(".", ","),
                    vendaCursos: response.data.vendaCursos,
                    vendaCertificados: response.data.vendaCertificados,
                    //metodos,
                    iban: response.data.iban ? response.data.iban : "",
                    linkPagamento: response.data.linkPagamento ? response.data.linkPagamento : "",
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    atualizarDados = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll(["nome_abrev"], (err, values) => {
            if (!err) {

                this.setState({ iconLoading: true });
                 
                var item = new FormData();
                item.append("novidades", this.state.novidades);
                item.append("gamificacao", this.state.gamificacao);
                item.append("autoconta", this.state.autoconta);
                item.append("monetizacao_entidade", this.state.monetizacaoEntidade);
                item.append("subscricao", this.state.subscricao);
                item.append("valorMensal", (this.state.valorMensal + "").replace(",", "."));
                item.append("vendaCursos", this.state.vendaCursos);
                item.append("vendaCertificados", this.state.vendaCertificados);
                item.append("paypal", this.state.metodos.find(x => x === "paypal") ? true : false);
                item.append("cartao", this.state.metodos.find(x => x === "cartao") ? true : false);
                item.append("transferencia", this.state.metodos.find(x => x === "transferencia") ? true : false);
                if (this.state.metodos.find(x => x === "transferencia"))
                    item.append("iban", this.state.iban);
                item.append("pagamentoExterno", this.state.metodos.find(x => x === "servicoExterno") ? true : false);
                if (this.state.metodos.find(x => x === "servicoExterno"))
                    item.append("linkPagamento", this.state.linkPagamento);

                axios({
                    method: "put",
                    url: "/api/empresa/alterar-configuracoes-formacao",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.setState({
                            iconLoading: false
                        });

                        this.context.atualizarState({
                            atualizarEntidade: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    };

    validarEstrutura = (rule, value, callback) => {
        var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (format.test(value))
            callback("Nome abreviado deve conter apenas letras e números");

        callback();
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            autoconta_stepforma,
            autoconta,
            gamificacao_stepforma,
            gamificacao,
            monetizacao,
            monetizacaoEntidade,
            subscricao,
            valorMensal,
            vendaCursos,
            vendaCertificados,
            //METODOS PAGAMENTO
            metodos,
            paypal,
            cartao,
            transferencia,
            iban,
            servicoExterno,
            linkPagamento,
            novidades,
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-redes-sociais bloco-personalizacao">
                <Form className="personalizacao">
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="novidades" checked={novidades} onChange={this.handleChangeCheckbox}>
                            Destacar os cursos recentemente adicionados{" "}
                            <Tooltip className="info-icon" title="Mostrar a lista de novos cursos / novidades na página de entrada">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="gamificacao" checked={gamificacao} onChange={this.handleChangeCheckbox} disabled={!gamificacao_stepforma}>
                            Gamificação
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="autoconta" checked={autoconta} onChange={this.handleChangeCheckbox} disabled={!autoconta_stepforma}>
                            Criação de Conta{" "}
                            <Tooltip className="info-icon" title="Conta criada pelo usuário, sem necessidade de aprovação pela gestão">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="monetizacaoEntidade" checked={monetizacaoEntidade} onChange={this.handleChangeCheckbox} disabled={!monetizacao}>
                            Monetização
                        </Checkbox>
                    </Form.Item>
                    {monetizacaoEntidade &&
                        <>
                            <h3 className="titulo-separador" style={{ marginTop: "60px", marginBottom: "20px" }}>
                                Monetização
                            </h3>
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="subscricao" checked={subscricao} onChange={this.handleChangeCheckbox}>
                                    Incluir subscrição
                                </Checkbox>
                            </Form.Item>
                            {subscricao &&
                                <Form.Item label="Valor mensal">
                                    {getFieldDecorator("valorMensal", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            },
                                            {
                                                validator: this.validarValor
                                            }
                                        ],
                                        initialValue: valorMensal
                                    })(<Input
                                        className="input-50"
                                        name="valorMensal"
                                        onChange={this.handleChange}
                                        prefix={this.context.moeda_entidade}
                                    />)}
                                </Form.Item>}
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="vendaCursos" checked={vendaCursos} onChange={this.handleChangeCheckbox}>
                                    Incluir valor de venda nos cursos
                                </Checkbox>
                            </Form.Item>
                            <h4 className="titulo-separador" style={{ marginTop: "60px", marginBottom: "15px" }}>
                                Metodos de pagamento
                            </h4>
                            <Form.Item>
                                {getFieldDecorator("metodos", {
                                    rules: [
                                        {
                                            required: (subscricao || vendaCursos || vendaCertificados),
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: (subscricao || vendaCursos || vendaCertificados) ? metodos : []
                                })(<Checkbox.Group name="metodos" style={{ width: '100%' }} disabled={!(subscricao || vendaCursos || vendaCertificados)} onChange={this.handleChangeCheckboxMetodos}>
                                    <Form.Item style={{ marginBottom: 20 }}>
                                        <Checkbox value="paypal">
                                            Paypal e Cartão de Crédito
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: 20 }}>
                                        <Checkbox value="transferencia">
                                            Transferência para conta bancária
                                        </Checkbox>
                                    </Form.Item>
                                    {(metodos.find(x => x === "servicoExterno") && (subscricao || vendaCursos || vendaCertificados)) &&
                                        <Form.Item label="Endereço da página de pagamento de serviços">
                                            {getFieldDecorator("linkPagamento", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    },
                                                    {
                                                        validator: this.validarLink
                                                    }
                                                ],
                                                initialValue: linkPagamento
                                            })(<Input name="linkPagamento" className="input-50" onChange={this.handleChange} />)}
                                        </Form.Item>}
                                </Checkbox.Group>)}
                            </Form.Item>
                        </>
                    }
                    <button className="botao-principal" onClick={this.atualizarDados} disabled={iconLoading}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                </Form>
            </div>
        );
    }
}

const FormFormacao = Form.create({ name: "form-formacao" })(Formacao);

export default FormFormacao;
