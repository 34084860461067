import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../User/Header";
import { Icon, notification, Modal } from "antd";
import ExcelTabelaNotasPre from "../../GerirCursos/Diario/_Aux/ExcelTabelaNotasPre";
import CardsNotasPre from "../../GerirCursos/Diario/_Aux/CardsNotasPre";
import axios from "axios";

import ModalGerarDocumento from "../../Componentes/Modals/GerarDocumento";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Lista extends Component {
    state = {
        utilizador: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //PERIODO
        periodos: [],
        avaliacoes: [],
        //LOADING
        iconLoading: false
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    listar = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/si-consultar-notas/listar-notas-pre",
                params: {
                    cursoId: localStorage.getItem("codigo_curso")
                }
            })
                .then(response => {
                    if (response.data.periodos.length > 0) {
                        this.setState({
                            periodos: response.data.periodos,
                            avaliacoes: response.data.avaliacoes,
                            iconLoading: false
                        })
                    }
                    else {
                        this.setState({
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Curso sem periodos registados!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a informação!");
                });
        })
    }

    exportar = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/si-consultar-notas/notas-pre-creche",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        tipo: "PRE"
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    exportarPdf = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/si-consultar-notas/notas-pre-creche-pdf",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        tipo: "PRE"
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    render() {
        const {
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //TABELA
            periodos,
            avaliacoes,
            //LOADING
            iconLoading
        } = this.state;

        return (
            <>
                <Header titulo="Histórico" />
                <div className="container container-noticias conteiner-consultar container-body">
                    <div className="content">
                        <div className="bloco-cursos">
                            <div className="bloco-tabela">
                                <div className="header" style={{ justifyContent: "flex-end" }}>
                                    <div className="controlos" style={{ marginBottom: 0 }}>
                                        <Link to="/sistema-informacao/consultar" className="botao-icon-voltar" title="Voltar">
                                            <Icon type="arrow-left" />
                                        </Link>
                                        <Link className="botao-icon-pdf" to="#" title="Descarregar" onClick={this.exportarPdf}>
                                            <Icon type="file-pdf" />
                                        </Link>
                                        <Link className="botao-icon-download" to="#" title="Descarregar" onClick={this.exportar}>
                                            <Icon type="download" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="bloco-notas-desktop">
                                    <ExcelTabelaNotasPre
                                        periodos={periodos}
                                        avaliacoes={avaliacoes}
                                    />
                                </div>
                                <div className="bloco-notas-mobile">
                                    <CardsNotasPre
                                        periodos={periodos}
                                        avaliacoes={avaliacoes}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
            </>
        );
    }
}
export default Lista;
