import React, { Component } from "react";
import { Modal, notification, List } from "antd";
import { Link } from "react-router-dom";
import Modulo from "./_Aux/Modulo";
import { GeralContext } from "./ContextProvider";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Modulos extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        curso: "",
        modulos: [],
        //PERMISSOES
        loading: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        var cursoId = this.props.match.params.cursoid;

        this.setState({
            cursoid: cursoId,
            curso: this.props.match.params.curso
        });

        if (this.context.lista_modulos.length === 0)
            this.listarModulos(cursoId);
        else
            this.setState({
                modulos: this.context.lista_modulos,
            })
    }

    //CARREGA E LISTA TODOS OS MODULOS PERTENCENTES AO CURSO E IDENTIFICA A ULTIMA POSIÇÃO NA ORDEM
    listarModulos = cursoid => {
        this.setState({
            loading: true
        })

        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-modulos",
            params: {
                cursoIdEnc: cursoid
            }
        })
            .then(response => {
                if (response.data.length > 0) {
                    this.context.atualizarState({
                        lista_modulos: response.data
                    });

                    this.setState({
                        modulos: response.data,
                        loading: false
                    });
                }
                else
                    this.setState({
                        loading: false
                    });
            })
            .catch(() => { });
    };

    render() {
        const { cursoid, curso, modulos } = this.state;

        return (
            <GeralContext.Consumer>{(geralContext) => {
                return(
                    <div className="container">
                        {this.state.loading ? (
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                        ) : (
                                <div className="curso-modulos">
                                    {modulos.map((modulo, index) => (
                                        <Modulo
                                            key={index}
                                            curso={curso}
                                            cursoid={cursoid}
                                            index={index}
                                            modulo={modulo}
                                            entidade={this.props.match.params.entidade}
                                        />
                                    ))}
                                </div>
                            )}
                    </div>
                )
            }
            }
            </GeralContext.Consumer>
        );
    }
}

export default Modulos;
