import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Checkbox, notification, Icon } from "antd";
import axios from "axios";
import noimage from "../../images/noimage.png";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerPresencas extends Component {
    state = {
        //DRAWER PRESENÇAS
        falta: false,
        presenca: false,
        presencas: [],
        participantes: [],
        loadingParticipantes: false,
        //LOADING
        iconLoading: false
    };

    handlerChangeCheckboxPresencaColetiva = event => {
        this.props.form.resetFields();
        this.setState({
            [event.target.name]: event.target.checked,
            falta: false,
            presencas: event.target.checked ? this.state.participantes.map(participante => participante.id) : []
        });
    };

    handlerChangeCheckboxFaltaColetiva = event => {
        this.props.form.resetFields();
        this.setState({
            [event.target.name]: event.target.checked,
            presenca: false,
            presencas: []
        });
    };

    handlerChangeCheckboxPresencas = presencas => {
        this.setState({
            falta: false,
            presenca: false,
            presencas
        });
    };

    listarParticipantes = () => {
        this.setState(
            {
                loadingParticipantes: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/curso/presencas-participantes",
                    timeout: 60 * 10 * 1000,
                    params: {
                        cursoid: this.props.cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            participantes: response.data,
                            loadingParticipantes: false
                        }, () => this.carregarPresencas());
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    }

    carregarPresencas = () => {
        axios({
            method: "get",
            url: "/api/gerir-curso/carregar-presencas",
            params: {
                aulaid: this.props.folhaPresencasId
            }
        })
            .then(response => {
                this.setState({
                    presenca: response.data.presenca_coletiva,
                    falta: response.data.falta_coletiva,
                    presencas: response.data.presencas,
                });
            })
            .catch(() => { });
    };

    guardarPresencas = () => {
        if (this.state.presenca || this.state.falta || this.state.presencas.length) {
            this.setState({ iconLoading: true })

            var item = new FormData()
            var presencas = {
                presenca_coletiva: this.state.presenca,
                falta_coletiva: this.state.falta,
                presencas: this.state.presencas
            }

            item.append("tarefaId", this.props.folhaPresencasId)
            item.append("presencas", JSON.stringify(presencas))

            axios({
                method: "put",
                url: "/api/curso-aula/guardar-presencas",
                data: item,
                timeout: 60 * 30 * 1000
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Presenças guardadas!")
                    this.setState({ iconLoading: false })
                    this.props.onClose();
                    this.props.form.resetFields();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi guardar presenças!")
                    this.setState({ iconLoading: false })
                })
        }
        else {
            openNotificationWithIcon("info", "Informação", "Não existem dados a serem guardados")
        }
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.listarParticipantes();
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            //DRAWER PRESENÇAS
            falta,
            presenca,
            presencas,
            participantes,
            loadingParticipantes,
            //LOADING
            iconLoading
        } = this.state;

        return (
            <Drawer
                className="drawer-from-presencas"
                //title={"Presenças"}
                width={720}
                onClose={this.props.onClose}
                visible={this.props.drawerPresencasVisible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-presencas" layout="horizontal">
                        <div className="bloco">
                            <Form.Item className="form-item-checkbox">
                                <Checkbox name="presenca" checked={presenca} onChange={this.handlerChangeCheckboxPresencaColetiva}>
                                    Presença coletiva
                                </Checkbox>
                            </Form.Item>
                            <Form.Item className="form-item-checkbox">
                                <Checkbox name="falta" checked={falta} onChange={this.handlerChangeCheckboxFaltaColetiva}> 
                                    Falta coletiva
                                </Checkbox>
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador">Participantes</h3>
                            <Form.Item>
                                {loadingParticipantes ?
                                    <div className="loading-data">
                                        <div className="loading" />
                                    </div>
                                    :
                                    getFieldDecorator("presencas", {
                                        initialValue: presencas
                                    })(<Checkbox.Group name="competenciasGerais" className="lista-presencas" onChange={this.handlerChangeCheckboxPresencas}>
                                        {participantes.map((participante, index) => (
                                            <Checkbox key={index} value={participante.id}>
                                                <div className="participante">
                                                    <div className="info-participante">
                                                        <div className="container-img">
                                                            <img src={participante.foto ? participante.foto : noimage} />
                                                        </div>
                                                        <div className="info">
                                                            <span className="nome">{participante.nome}</span>
                                                            <p className="numero">{participante.numero}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Checkbox>
                                        ))}
                                    </Checkbox.Group>)
                                }
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button
                        className="botao-principal"
                        type="primary"
                        onClick={this.guardarPresencas}
                        disabled={iconLoading}
                    >
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                                </button>
                </div>
            </Drawer>
        );
    }
}

const FormDrawerPresencas = Form.create({ name: "form-drawer-competencia" })(DrawerPresencas);

export default FormDrawerPresencas;
