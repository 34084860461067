import React, { useState } from 'react';
import noimage from "../../../images/noimage.png";

const VideoCard = (props) => {
    
    return(
        <div className="side-card" onClick={props.openModal}>
            <img src={props.capa ? props.capa : noimage} alt="Side Image 1" className="side-img"/>
            <div className="side-content">
                <h4 className="side-title">{props.title}</h4>
                <p className="side-description">{props.description}</p>
            </div>
        </div>
    );
}

export default VideoCard;