import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Form, Input, InputNumber, Icon, notification, Modal, Select } from "antd";
import axios from "axios";
import { GlobalContext } from "../../../../GlobalState";

const { Option } = Select
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerPagina extends Component {
    static contextType = GlobalContext
    state = {
        titulo: "",
        ordem: 1,
        //LOADING
        iconLoading: false
    };

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeOrdem = ordem => {
        this.setState({
            ordem
        });
    };

    handlerChangeSelect = tipo => {
        this.setState({
            tipo
        })
    }

    carregar = (id) => {
        axios({
            method: "get",
            url: "/api/empresa/carregar-faq-subgrupo",
            params: {
                id
            }
        })
            .then(response => {
                this.setState({
                    titulo: response.data.titulo,
                    ordem: response.data.ordem
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar página!");

                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    criar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData()
                item.append("id_grupo", this.props.grupoId);
                item.append("titulo", this.state.titulo);
                item.append("ordem", this.state.ordem);

                axios({
                    method: "post",
                    url: "/api/empresa/adicionar-faq-subgrupo",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!");
                        this.setState({
                            iconLoading: false
                        }, () => this.props.atualizarRegisto());
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar registo!");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    }

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData()
                item.append("id", this.props.id);
                item.append("titulo", this.state.titulo);
                item.append("ordem", this.state.ordem);

                axios({
                    method: "post",
                    url: "/api/empresa/alterar-faq-subgrupo",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Registo alterada");
                        this.setState({
                            iconLoading: false
                        }, () => this.props.atualizarRegisto());
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível alterar registo!");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.id) {
                this.carregar(this.props.id);
            }
            else {
                this.setState({
                    titulo: "",
                    ordem: this.props.ordem,
                    //LOADING
                    iconLoading: false
                })
            }
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            estabelecimentos,
            estabelecimento,
            titulo,
            ordem,
            tipo,
            //LOADING
            iconLoading
        } = this.state;

        const {
            ativo,
            id,
            visible
        } = this.props;

        return (
            <Drawer
                className="drawer-paginas"
                width={720}
                onClose={this.props.onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Titulo">
                                {getFieldDecorator("titulo", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: titulo
                                })(<Input name="titulo" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Ordem">
                                {getFieldDecorator("ordem", {
                                    rules: [{
                                        required: true,
                                        message: "Campo obrigatório"
                                    }],
                                    initialValue: ordem
                                })(<InputNumber className="input-25" name="ordem" min={1} disabled={!ativo} onChange={this.handlerChangeOrdem} />)}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <Link
                        to="#"
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </Link>
                    {ativo && (
                        <button className="botao-principal" disabled={iconLoading} onClick={id ? this.alterar : this.criar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>
                    )}
                </div>
            </Drawer>
        );
    }
}
const FormDrawerPagina = Form.create({ name: "form-drawer-pagina" })(DrawerPagina);

export default FormDrawerPagina;
