import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Icon, notification } from "antd";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Inscricao = ({ match, form }) => {
    const { getFieldDecorator } = form;
    const [evento, setEvento] = useState({
        capa: "",
        nome: "",
        descricao: ""
    });
    const [inscricao, setInscricao] = useState({
        nome: "",
        empresa: "",
        email: ""
    });
    const [loading, setLoading] = useState(false);

    const carregarInfo = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/detalhe-inscricao",
            params: {
                code: match.params.code
            }
        })
            .then(response => {
                setEvento(response.data);
            })
            .catch(() => { });
    }

    const guardar = (event) => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("code", match.params.code);
                item.append("nome", inscricao.nome);
                if (inscricao.empresa)
                    item.append("empresa", inscricao.empresa);
                item.append("email", inscricao.email);

                axios({
                    method: "post",
                    url: "/api/pagina-entidade/inscricao-evento",
                    data: item
                })
                    .then(() => {
                        form.resetFields();
                        setInscricao({
                            nome: "",
                            empresa: "",
                            email: ""
                        })
                        openNotificationWithIcon("success", "Sucesso", "Inscrição submetida");
                        setLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível submeter a inscrição");
                        setLoading(false);
                    });
            }
        });
    }

    useEffect(() => {
        carregarInfo();
    }, [])

    return (
        <div className="bloco-form-inscricoes">
            <div className="bloco-form-inscricoes-header">
                <div className="titulo-background">
                    <span>{moment(evento.dt_inicio).format("DD [de] MMMM [de] YYYY")}</span>
                    <h2 className="titulo">{evento.nome}</h2>
                    <p className="descricao" dangerouslySetInnerHTML={{ __html: evento.descricao }}></p>
                </div>
            </div>
            <div className="bloco-form-inscricoes-body">
                <Form className="form-competencias" layout="horizontal">
                    <h3 className="titulo">Inscrição</h3>
                    <Form.Item label="Nome">
                        {getFieldDecorator("nome", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: inscricao.nome
                        })(<Input name="nome" enabled="true" onChange={(e) => setInscricao({ ...inscricao, nome: e.target.value })} />)}
                    </Form.Item>
                    <Form.Item label="Empresa / Organização">
                        <Input name="empresa" value={inscricao.empresa} onChange={(e) => setInscricao({ ...inscricao, empresa: e.target.value })} />
                    </Form.Item>
                    <Form.Item label="Email">
                        {getFieldDecorator("email", {
                            rules: [
                                {
                                    type: "email",
                                    message: "Introduza um endereço de e-mail válido"
                                },
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: inscricao.email
                        })(<Input name="email" enabled="true" onChange={(e) => setInscricao({ ...inscricao, email: e.target.value })} />)}
                    </Form.Item>
                    <div className="form-opcoes-footer">
                        <Link to="#" className="botao-principal" disabled={loading} onClick={guardar}>
                            {loading ? <Icon type="loading" /> : null}
                            Submeter
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    );
}

const FormInscricao = Form.create({ name: "form-inscricao" })(Inscricao);

export default FormInscricao;
