import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Input, DatePicker, TimePicker, notification, Radio, Icon, Drawer, Modal, Tooltip, Checkbox, Row, Col, Select } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";
import { GlobalContext } from "../../../GlobalState";
import DrawerHTML from "../../../GerirCursos/_Aux/FormPaginaHTML2";

import { arraysHaveSameElements } from "../../../Utilities/compare"

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerNoticia = ({ ativo, visible, noticiaId, form, onClose, atualizar }) => {
    const context = useContext(GlobalContext);

    const [todos, setTodos] = useState(false);
    const [formData, setFormData] = useState({
        opcao: false,
        opcao_autor: false,
        capa: "",
        foto_autor: "",
        preview: "",
        preview_autor: "",
        titulo: "",
        autor: "",
        descricao: "",
        texto: "",
        link: "",
        id_categoria: undefined,
        data: null,
        hora: null,
        selecionados: [],
        dataVisivelAte: null
    });
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [imagens, setImagens] = useState([]);
    const [validate, setValidate] = useState({
        totalBytes: 0,
        formatoValido: false,
        validateStatus: "",
        help: ""
    });
    const [visibleHTML, setVisibleHTML] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categorias, setCategorias] = useState([]);

    const { getFieldDecorator } = form;

    const { TextArea } = Input;

    const tamanhoMaximo = (5 * (1024 * 1024));

    const carregarImagens = () => {
        axios({
            method: "get",
            url: "/api/empresa/carregar-imagens",
            params: {
                estabalecimentoId: context.estabelecimento.id
            }
        })
            .then(response => {
                setImagens(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as imagens");
            });
    };

    const listarCategorias = () => {
        axios({
            method: "get",
            url: "/api/empresa/listar-categorias"
        })
            .then(response => {
                setCategorias(response.data);
            })
            .catch(error => {});
    };

    const guardarNoticia = () => {
        form.validateFieldsAndScroll(err => {
            if (
                !err &&
                ((!formData.opcao && validate.totalBytes <= tamanhoMaximo && validate.formatoValido) || formData.opcao) &&
                formData.preview != ""
            ) {
                setLoading(true);

                var item = new FormData();
                item.append("opcao", formData.opcao);
                item.append("opcao_autor", formData.opcao_autor);
                item.append("preview", formData.preview);
                item.append("preview_autor", formData.preview_autor);
                item.append("capa", formData.capa);
                if (formData.foto_autor)
                    item.append("foto_autor", formData.foto_autor);
                item.append("titulo", formData.titulo);
                item.append("autor", formData.autor);
                item.append("descricao", formData.descricao);
                item.append("texto", formData.texto);
                item.append("autor", formData.autor);
                item.append("descricao", formData.descricao);
                item.append("id_categoria", formData.id_categoria);
                if (formData.link) item.append("link", formData.link);
                if (formData.data) item.append("data", moment(formData.data).format("YYYY/MM/DD"));
                if (formData.hora) item.append("hora", formData.hora.format("HH:mm"));
                item.append("estabelecimentos", JSON.stringify(formData.selecionados));
                if (formData.dataVisivelAte) item.append("data_visivel_ate", moment(formData.dataVisivelAte).format("YYYY/MM/DD"));

                axios({
                    method: "post",
                    url: "/api/empresa/adicionar-informacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Notícia adicionada!");
                        atualizar();
                        setLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            } else {
                if (formData.preview == "" && !formData.opcao)
                    setValidate({
                        totalBytes: 0,
                        formatoValido: false,
                        validateStatus: "error",
                        help: "Campo obrigatório"
                    });
            }
        });
    };

    const alterarNoticia = () => {
        form.validateFieldsAndScroll(err => {
            if (
                !err &&
                ((!formData.opcao && validate.totalBytes <= tamanhoMaximo && validate.formatoValido) || formData.opcao) &&
                formData.preview != ""
            ) {
                setLoading(true);

                var item = new FormData();
                item.append("id", noticiaId);
                item.append("opcao", formData.opcao);
                item.append("opcao_autor", formData.opcao_autor);
                item.append("preview", formData.preview);
                item.append("preview_autor", formData.preview_autor);
                item.append("capa", formData.capa);
                if (formData.foto_autor)
                    item.append("foto_autor", formData.foto_autor);
                item.append("titulo", formData.titulo);
                item.append("autor", formData.autor);
                item.append("descricao", formData.descricao);
                item.append("id_categoria", formData.id_categoria);
                item.append("texto", formData.texto);
                if (formData.link)
                    item.append("link", formData.link);
                if (formData.data)
                    item.append("data", moment(formData.data).format("YYYY/MM/DD"));
                if (formData.hora)
                    item.append("hora", formData.hora.format("HH:mm"));
                item.append("estabelecimentos", JSON.stringify(formData.selecionados));
                if (formData.dataVisivelAte)
                    item.append("data_visivel_ate", moment(formData.dataVisivelAte).format("YYYY/MM/DD"));

                axios({
                    method: "put",
                    url: "/api/empresa/alterar-informacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Notícia alterada!");
                        atualizar();
                        setLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            } else {
                if (formData.preview == "" && !formData.opcao)
                    setValidate({
                        totalBytes: 0,
                        formatoValido: false,
                        validateStatus: "error",
                        help: "Campo obrigatório"
                    });
            }
        });
    };

    const carregarDetalhe = () => {
        axios({
            method: "get",
            url: "/api/empresa/detalhe-informacao",
            params: {
                noticiaId
            }
        })
            .then(response => {
                form.resetFields();
                const sels = response.data.estabelecimentos.filter(estabelecimento => !!estabelecimento);

                setFormData({
                    opcao: response.data.opcao,
                    opcao_autor: response.data.opcao_autor,
                    capa: response.data.capa,
                    foto_autor: response.data.foto_autor,
                    preview: response.data.capa,
                    preview_autor: response.data.foto_autor,
                    titulo: response.data.titulo,
                    autor: response.data.autor,
                    descricao: response.data.descricao,
                    id_categoria: response.data.id_categoria,
                    texto: response.data.texto,
                    link: response.data.link ? response.data.link : "",
                    data: response.data.data ? moment(response.data.data) : null,
                    hora: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                    selecionados: sels,
                    dataVisivelAte: response.data.data_visivel_ate ? moment(response.data.data_visivel_ate) : null
                });

                if (response.data.capa)
                    setValidate({
                        ...validate,
                        formatoValido: true
                    })
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a notícia");
            });
    };

    const listarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos"
        })
            .then(response => {
                setEstabelecimentos(response.data);
            })
            .catch(error => {});
    };

    //UPLOAD DA FOTO/IMAGEM DO CURSO
    const uploadFoto = event => {
        if (event.target.files[0].size < tamanhoMaximo) {
            if (validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                setFormData({
                    ...formData,
                    capa: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
                setValidate({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: ""
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        } else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 2 MB para a imagem.");
        }
    };

    const uploadFotoAutor = event => {
        if (event.target.files[0].size < tamanhoMaximo) {
            if (validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                setFormData({
                    ...formData,
                    foto_autor: event.target.files[0],
                    preview_autor: URL.createObjectURL(event.target.files[0])
                });
            } else {
                document.getElementById("input-foto-autor").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        } else {
            document.getElementById("input-foto-autor").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 5 MB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    const validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    const ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //REMOVE A FOTO
    const removerFoto = () => {
        document.getElementById("input-foto").value = "";
        setFormData({ ...formData, capa: "", preview: "" });
    };

    const ativarUploadFotoAutor = () => {
        document.getElementById("input-foto-autor").click();
    };

    //REMOVE A FOTO
    const removerFotoAutor = () => {
        document.getElementById("input-foto-autor").value = "";
        setFormData({ ...formData, foto_autor: "", preview_autor: "" });
    };

    const validarTodosSelecionados = () => {
        const sels = formData.selecionados.filter(estabelecimento => !!estabelecimento);
        const ids = estabelecimentos.map(x => x.id);

        const sameElements = arraysHaveSameElements(ids, sels);

        setTodos(sameElements)
    }

    const afterVisibleChange = aberto => {
        if (aberto) {
            form.resetFields();
            document.getElementById("input-foto").value = "";
            if (noticiaId > 0)
                carregarDetalhe();
            else {
                setFormData({
                    opcao: false,
                    opcao_autor: false,
                    capa: "",
                    preview: "",
                    preview_autor: "",
                    titulo: "",
                    autor: "",
                    descricao: "",
                    texto: "",
                    link: "",
                    id_categoria: undefined,
                    data: null,
                    hora: null,
                    selecionados: [],
                    dataVisivelAte: null
                });
                setValidate({
                    totalBytes: 0,
                    formatoValido: false,
                    validateStatus: "",
                    help: ""
                });
            }
        }
    };

    const onCloseDrawerHTML = () => {
        form.resetFields();
        setVisibleHTML(false);
    };

    const handleChangeEditor = (value, index) => {
        setFormData({ ...formData, texto: value });
    };

    const handleChangeCheckTodos = e => {
        const todos = e.target.checked;
        setTodos(todos)
        form.resetFields();

        if (todos) {
            const ids = estabelecimentos.map(x => x.id)
            setFormData({ ...formData, selecionados: ids })
        }
        else
            setFormData({ ...formData, selecionados: [] })
    };

    useEffect(() => {
        carregarImagens();
        listarEstabelecimentos();
        listarCategorias();
    }, []);

    useEffect(() => {
        validarTodosSelecionados()
    }, [formData.selecionados, todos]);

    let textHtmlConverted = "";
    if (formData?.texto !== "<p><br></p>")
        textHtmlConverted = formData.texto;

    return (
        <>
            <Drawer
                className="drawer-add-cursos drawer-videoconferencias"
                //title={noticiaId > 0 ? "Alterar" : "Adicionar"}
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        <Form.Item>
                            {getFieldDecorator("opcao", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: formData.opcao
                            })(
                                <Radio.Group
                                    name="opcao"
                                    disabled={!ativo}
                                    onChange={e => setFormData({ ...formData, opcao: e.target.value, preview: "" })}
                                >
                                    <Radio value={false}>Upload de imagem</Radio>
                                    <Radio value={true}>Banco de imagens</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <div className="bloco">
                            <div className="bloco-img">
                                {!formData.opcao ? (
                                    <Form.Item validateStatus={validate.validateStatus} help={validate.help}>
                                        <div className="container-img">
                                            <img src={formData.preview ? formData.preview : noimage} />
                                        </div>
                                        {ativo && (
                                            <>
                                                {!formData.preview ? (
                                                    <>
                                                        <Link to="#" className="link-principal-border" onClick={ativarUploadFoto}>
                                                            Adicionar
                                                        </Link>{" "}
                                                        <Tooltip
                                                            className="info-icon"
                                                            title={
                                                                <div>
                                                                    <p>Tamanho máximo: 5 MB</p>
                                                                    {/*<p>Tamanho recomendado: 670x400px</p>*/}
                                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                                </div>
                                                            }
                                                        >
                                                            <Icon type="question-circle-o" />
                                                        </Tooltip>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Link to="#" className="link-principal-border" onClick={ativarUploadFoto}>
                                                            Alterar
                                                        </Link>
                                                        <Tooltip
                                                            className="info-icon"
                                                            title={
                                                                <div>
                                                                    <p>Tamanho máximo: 5 MB</p>
                                                                    {/*<p>Tamanho recomendado: 670x400px</p>*/}
                                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                                </div>
                                                            }
                                                        >
                                                            <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                                        </Tooltip>
                                                        <Link to="#" className="link-principal-border" onClick={removerFoto}>
                                                            Remover
                                                        </Link>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Form.Item>
                                ) : (
                                    <>
                                        <Form.Item>
                                            <div className="container-img">
                                                <img src={formData.preview ? formData.preview : noimage} />
                                            </div>
                                        </Form.Item>
                                        {ativo && (
                                            <Form.Item label="Imagens">
                                                {getFieldDecorator("preview", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        }
                                                    ],
                                                    initialValue: formData.preview
                                                })(
                                                    <Select
                                                        placeholder="Selecionar"
                                                        onChange={preview => setFormData({ ...formData, preview })}
                                                    >
                                                        {imagens.map(imagem => (
                                                            <Option value={imagem.caminho}>{imagem.titulo}</Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        )}
                                    </>
                                )}
                                <input type="file" id="input-foto" accept="image/*" onChange={uploadFoto} style={{ display: "none" }} />
                            </div>
                            <Form.Item label="Título">
                                {getFieldDecorator("titulo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: formData.titulo
                                })(
                                    <Input
                                        name="titulo"
                                        maxLength={100}
                                        disabled={!ativo}
                                        onChange={e => setFormData({ ...formData, titulo: e.target.value })}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Categoria">
                                {getFieldDecorator("id_categoria", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: formData.id_categoria
                                })(
                                    <Select
                                        disabled={!ativo}
                                        placeholder="Selecionar"
                                        onChange={e => setFormData({ ...formData, id_categoria: e })}
                                    >
                                        {categorias.map(categoria => (
                                            <Option key={categoria.id} value={categoria.id}>
                                                {categoria.nome}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: formData.descricao
                                })(
                                    <TextArea
                                        rows={6}
                                        name="descricao"
                                        disabled={!ativo}
                                        onChange={e => setFormData({ ...formData, descricao: e.target.value })}
                                        maxLength={400}
                                        style={{ height: "100%", resize: "none" }}
                                    />
                                )}
                                <p>{formData.descricao.length} / 400</p>
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator("texto", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: formData.texto
                                })(
                                    <>
                                        <h3 className="titulo-separador">Texto</h3>
                                        {ativo && (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                style={{ paddingLeft: 0 }}
                                                onClick={() => {
                                                    setVisibleHTML(true);
                                                }}
                                            >
                                                {textHtmlConverted ? "Editar" : "Adicionar"}
                                            </Link>
                                        )}
                                    </>
                                )}
                            </Form.Item>
                            <Form.Item label="Data">
                                {getFieldDecorator("data", {
                                    initialValue: formData.data
                                })(
                                    <DatePicker
                                        name="data"
                                        className="input-25"
                                        disabled={!ativo}
                                        onChange={data => setFormData({ ...formData, data })}
                                        format="DD/MM/YYYY"
                                    />
                                )}
                            </Form.Item>
                            {/*<Form.Item label="Hora">*/}
                            {/*    {getFieldDecorator("hora", {*/}
                            {/*        initialValue: formData.hora*/}
                            {/*    })(*/}
                            {/*        <TimePicker*/}
                            {/*            className="input-25"*/}
                            {/*            name="hora"*/}
                            {/*            disabled={!ativo || !formData.data}*/}
                            {/*            onChange={hora => setFormData({ ...formData, hora })}*/}
                            {/*            defaultOpenValue={moment("00:00", "HH:mm")}*/}
                            {/*            format="HH:mm"*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*</Form.Item>*/}
                            <Form.Item label="Publicar em:">
                                <Checkbox checked={todos} onClick={handleChangeCheckTodos}>Selecionar todos</Checkbox>
                                {getFieldDecorator("selecionados", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: formData.selecionados
                                })(
                                    <Checkbox.Group
                                        style={{ width: "100%" }}
                                        disabled={!ativo}
                                        onChange={selecionados => setFormData({ ...formData, selecionados })}
                                    >
                                        <Row>
                                            {estabelecimentos.map((estabelecimento, index) => (
                                                <Col key={estabelecimento.id} span={16}>
                                                    <Checkbox value={estabelecimento.id} title={estabelecimento.nome}>{estabelecimento.abrev}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                            {/*<Form.Item label="Publicar até">*/}
                            {/*    {getFieldDecorator("data_visivel_ate", {*/}
                            {/*        initialValue: formData.dataVisivelAte*/}
                            {/*    })(*/}
                            {/*        <DatePicker*/}
                            {/*            name="data_visivel_ate"*/}
                            {/*            className="input-25"*/}
                            {/*            disabled={!ativo}*/}
                            {/*            onChange={dataVisivelAte => setFormData({ ...formData, dataVisivelAte })}*/}
                            {/*            format="DD/MM/YYYY"*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*</Form.Item>*/}
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador" style={{ marginBottom: 20, marginTop: 60 }}>
                                Autor
                            </h3>
                            <div className="bloco-img">
                                <Form.Item>
                                    <div className="container-img">
                                        <img src={formData.preview_autor ? formData.preview_autor : noimage} />
                                    </div>
                                    {ativo && (
                                        <>
                                            {!formData.preview_autor ? (
                                                <>
                                                    <Link to="#" className="link-principal-border" onClick={ativarUploadFotoAutor}>
                                                        Adicionar
                                                    </Link>{" "}
                                                    <Tooltip
                                                        className="info-icon"
                                                        title={
                                                            <div>
                                                                <p>Tamanho máximo: 5 MB</p>
                                                                {/*<p>Tamanho recomendado: 670x400px</p>*/}
                                                                <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                            </div>
                                                        }
                                                    >
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                <>
                                                    <Link to="#" className="link-principal-border" onClick={ativarUploadFotoAutor}>
                                                        Alterar
                                                    </Link>
                                                    <Tooltip
                                                        className="info-icon"
                                                        title={
                                                            <div>
                                                                <p>Tamanho máximo: 5 MB</p>
                                                                {/*<p>Tamanho recomendado: 670x400px</p>*/}
                                                                <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                            </div>
                                                        }
                                                    >
                                                        <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                                    </Tooltip>
                                                    <Link to="#" className="link-principal-border" onClick={removerFotoAutor}>
                                                        Remover
                                                    </Link>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Form.Item>
                                <input
                                    type="file"
                                    id="input-foto-autor"
                                    accept="image/*"
                                    onChange={uploadFotoAutor}
                                    style={{ display: "none" }}
                                />
                            </div>
                            <Form.Item label="Nome Autor">
                                {getFieldDecorator("autor", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: formData.autor
                                })(
                                    <Input
                                        name="autor"
                                        maxLength={100}
                                        disabled={!ativo}
                                        onChange={e => setFormData({ ...formData, autor: e.target.value })}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <Link to="#" className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </Link>
                    {ativo && (
                        <button className="botao-principal" disabled={loading} onClick={noticiaId > 0 ? alterarNoticia : guardarNoticia}>
                            {loading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>
                    )}
                </div>
            </Drawer>
            <DrawerHTML
                recurso={formData.texto}
                visible={visibleHTML}
                onClose={onCloseDrawerHTML}
                handleChangeEditor={handleChangeEditor}
            />
        </>
    );
};

const FormDrawerNoticia = Form.create({ name: "form-drawer-noticia" })(DrawerNoticia);

export default FormDrawerNoticia;
