import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import { notification, Modal, Icon } from "antd";
import moment from "moment";

const TabelaCalendario = ({ data }) => {
    const { id: turmaId } = useParams();
    const [aulas, setAulas] = useState([]);
    const [loading, setLoading] = useState([]);

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description
        });
    };

    const tabelaTH = ["Horário", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    // const horarios = ["13:00 - 13:50", "13:50 - 14:40", "14:40 - 15:30", "15:50 - 16:40", "16:40 - 17:30"];
    const [horarios, setHorarios] = useState([]);
    const weekDays = ["segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"];
    const schedule = {};

    const listarAulas = () => {
        setLoading(true);

        // Supondo que 'data' seja a data atual
        const diaSemana = data.getDay(); // 0 (domingo) a 6 (sábado)

        // Calcula a diferença para a segunda-feira (dia 1)
        const diffSegunda = diaSemana === 0 ? -6 : 1 - diaSemana;

        // Calcula a diferença para o sábado (dia 6)
        const diffSabado = 6 - diaSemana;

        // Define dtInicio como a segunda-feira
        const dtInicio = new Date(data.getTime() + diffSegunda * 24 * 60 * 60 * 1000);

        // Define dtFim como o sábado
        const dtFim = new Date(data.getTime() + diffSabado * 24 * 60 * 60 * 1000);

        axios({
            method: "get",
            url: "/api/area-aluno/horario",
            params: {
                code: turmaId,
                dtInicio: dtInicio,//new Date(data.getTime() + -1 * 24 * 60 * 60 * 1000),
                dtFim: dtFim//new Date(data.getTime() + 5 * 24 * 60 * 60 * 1000)
            }
        })
            .then(response => {
                setHorarios(extractUniqueHorarios(response.data))
                setAulas(response.data);
                setLoading(false);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os horários!");
                setLoading(false);
            });
    };

    useEffect(() => {
        listarAulas();
    }, [data]);

    // Inicializa o objeto de horários
    horarios.forEach(horario => {
        schedule[horario] = {
            "segunda-feira": null,
            "terça-feira": null,
            "quarta-feira": null,
            "quinta-feira": null,
            "sexta-feira": null,
            "sábado": null
        };
    });

    // Verifica se há aulas e popula a tabela
    if (aulas.length > 0) {
        aulas.forEach(aula => {
            const diaSemana = moment(aula.dataAula).format('dddd').toLowerCase();
            const horario = `${aula.inicio} - ${aula.fim}`;

            if (schedule[horario]) {
                if (weekDays.includes(diaSemana)) {
                    schedule[horario][diaSemana] = aula.disciplina;
                } else {
                    console.log(`Dia da semana não encontrado: ${diaSemana}`);
                }
            } else {
                console.log(`Horário não encontrado: ${horario}`);
            }
        });


    }

    // Função para extrair horários únicos
    const extractUniqueHorarios = (aulas) => {
        const horariosSet = new Set();

        aulas.forEach(aula => {
            const horario = `${aula.inicio} - ${aula.fim}`;
            horariosSet.add(horario);
        });

        return Array.from(horariosSet).sort();
    };

    return (
        <>
            <table className="tabelaRelarioCalendario">
                <thead>
                    <tr>
                        {tabelaTH.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {horarios.map(horario => (
                        <tr key={horario}>
                            <td className="horas">{horario}</td>
                            {weekDays.map(day => (
                                <td key={day}>{schedule[horario][day] || '-'}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal visible={loading} maskClosable={false} className="modal-loading" footer={null} closable={false}>
                <div className="modal-loading-bloco">
                    <p>
                        <Icon type="loading" />
                    </p>
                    <p className="texto">A carregar...</p>
                </div>
            </Modal>
        </>
    );
};

export default TabelaCalendario;
