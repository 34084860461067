import React, { Component } from "react";

class CardsNotas extends Component {

    montarNotasAvaliacoes = (avaliacoes) => {
        const avaliacoesSemRecuperacao = avaliacoes.filter(x => !x.recuperacao)
        const recuperacao = avaliacoes.find(x => x.recuperacao)
        let _avaliacoes = [];

        for (let i = 0; i < this.props.total; i++) {
            if (i < avaliacoesSemRecuperacao.length) {
                _avaliacoes.push(<div className="nota">
                    <span className="titulo">{avaliacoes[i].titulo}
                        {/*{!avaliacoes[i].recuperacao ? <>({avaliacoes[i].peso} %)</> : null}*/}
                    </span>
                    <span className="texto">{avaliacoesSemRecuperacao[i].nota}</span>
                </div>)
            }
            else {
                _avaliacoes.push(<div className="nota">
                    <span className="titulo"></span>
                    <span className="texto"></span>
                </div>)
            }
        }


        return [
            ..._avaliacoes,
            <div className="nota">
                <span className="titulo">Recuperação</span>
                <span className="texto">{recuperacao?.nota}</span>
            </div>
        ];
    }

    calcularTotalPeriodo = (avaliacoes) => {
        var total = 0;
        var _avaliacoes = avaliacoes.filter(x => !x.recuperacao);
        _avaliacoes.forEach(avaliacao => {
            if (avaliacao.nota.length)
                total += parseFloat(avaliacao.nota)
            //total += (avaliacao.peso / 100) * avaliacao.nota
        });

        var recuperacao = avaliacoes.find(x => x.recuperacao);
        if (recuperacao && recuperacao.nota.length) {
            if (total < recuperacao.nota)
                total = parseFloat(recuperacao.nota)
        }

        return parseFloat(total.toFixed(2));
    }

    montarFinal = () => {
        let listaPeriodos = [];
        let notas = [];
        let aprovado = false;

        this.props.periodos.map((periodo, indexPeriodo) => {
            const nota = this.calcularTotalPeriodo(periodo.avaliacoes);
            notas.push({
                nota,
                peso: periodo.peso
            })

            listaPeriodos.push(<div key={indexPeriodo} className="nota">
                <span className="titulo">{periodo.titulo}
                    {/*({periodo.peso} %)*/}
                </span>
                <span className="texto">{nota}</span>
            </div>);
        })

        let totalGeral = this.calcularNotaGeral(notas);
        let mediaAnual = this.calcularMediaAnual(notas);
        let mediaFinal = 0;
        let pontuacaoFinal = 0;
        if (this.props.formula === 1) {
            mediaFinal = this.props.provaFinal ? ((parseFloat(this.props.provaFinal) + mediaAnual) / 2) : mediaAnual;
            aprovado = mediaFinal >= 6;
        }
        else {
            mediaFinal = this.props.provaFinal ? (((parseFloat(this.props.provaFinal) * 3) + (mediaAnual * 4)) / 7) : mediaAnual;

            if (this.props.ano === "9º" && !this.props.eja || this.props.ano === "10º" && this.props.eja) {
                pontuacaoFinal = this.props.segundaChamada ? ((mediaFinal + parseFloat(this.props.segundaChamada)) / 2) : mediaFinal;
                aprovado = pontuacaoFinal >= 6;
            }
            else
                aprovado = mediaFinal >= 6;
        }

        const body = <>
            <div className="body">
                <div className="lista-notas">
                    {listaPeriodos.map(periodo => periodo)}
                </div>
                <div className="notas-resultado-final">
                    <div className="nota">
                        <span className="titulo">TOTAL GERAL</span>
                        <span className="texto">{totalGeral}</span>
                    </div>
                    <div className="nota">
                        <span className="titulo">MÉDIA ANUAL</span>
                        <span className="texto">{mediaAnual}</span>
                    </div>
                    <div className="nota">
                        <span className="titulo">PROVA FINAL</span>
                        <span className="texto">{this.props.provaFinal}</span>
                    </div>
                    <div className="nota">
                        <span className="titulo">MÉDIA FINAL</span>
                        <span className="texto">{mediaFinal.toFixed(2)}</span>
                    </div>
                    {this.props.ano === "9º" && !this.props.eja || this.props.ano === "10º" && this.props.eja ?
                        <>
                            <div className="nota">
                                <span className="titulo">2ª CHAMADA</span>
                                <span className="texto">{this.props.segundaChamada}</span>
                            </div>
                            <div className="nota">
                                <span className="titulo">PONTUAÇÃO FINAL</span>
                                <span className="texto">{pontuacaoFinal.toFixed(2)}</span>
                            </div>
                        </>
                        :
                        null}
                </div>
            </div>
            <div className="footer">
                {aprovado ?
                    <span className="notas-aprovado">Aprovado</span>
                    :
                    <span className="notas-reprovado">Reprovado</span>
                }
            </div>
        </>;

        return body;
    }

    calcularNotaGeral = (notas) => {
        let total = 0;
        notas.forEach(_nota => {
            total += parseFloat(_nota.nota)
            //total += (_nota.peso / 100) * _nota.nota
        })

        return parseFloat(total.toFixed(2));
    }

    calcularMediaAnual = (notas) => {
        let total = 0;
        notas.forEach(_nota => {
            total += parseFloat(_nota.nota)
        })

        return parseFloat((total / notas.length).toFixed(2));
    }

    render() {
        return (
            <div className="bloco-cards">
                {this.props.periodos.map((periodo, index) => (
                    <div key={index} className="card">
                        <div className="header">
                            <span className="titulo">{periodo.titulo}</span>
                        </div>
                        <div className="body">
                            <div className="lista-notas">
                                {this.montarNotasAvaliacoes(periodo.avaliacoes).map(nota => nota)}
                            </div>
                            <div className="notas-resultado-final">
                                <div className="nota">
                                    <span className="titulo">NOTA BIMESTRAL</span>
                                    <span className="texto">{this.calcularTotalPeriodo(periodo.avaliacoes)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            {this.calcularTotalPeriodo(periodo.avaliacoes) >= 6 ?
                                <span className="notas-aprovado">Aprovado</span>
                                :
                                <span className="notas-reprovado">Reprovado</span>}
                        </div>
                    </div>
                ))}
                <div className="card">
                    <div className="header">
                        <span className="titulo">Geral</span>
                    </div>
                    {this.montarFinal(this.props.periodos)}
                </div>
            </div>
        );
    }
}

export default CardsNotas;
