import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';

const BarChart = ({ datasetSource }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        // Ensure datasetSource is defined and properly structured
        if (!datasetSource || datasetSource.length === 0) {
            return;
        }

        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);
        

        const option = {
            backgroundColor: "rgb(253,253,253)",
            legend: {
                borderRadius: [7, 7, 7, 7],
                padding: [15, 15, 15, 15],
                top: "0%",
                right: "5%",
                icon: 'circle',
                show: true,
                backgroundColor: 'rgba(255, 255, 255, 1)',
                shadowColor: '#7090B00F',
                shadowBlur: 40,
                shadowOffsetX: 0,
                shadowOffsetY: 18,
                textStyle: {
                    color: "rgba(163, 174, 208, 1)",
                    fontFamily: "sans-serif",
                    fontSize: 12
                }
            },

            tooltip: false,
            grid: {
                left: "5%",
                right: "3%",
            },
            color: ["#6ad2ff", "#f89b7f"],
            dataset: {
                source: datasetSource,
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    show: true,
                    fontStyle: "normal",
                    fontWeight: "bold",
                    color: "rgba(135, 136, 136, 1)"
                },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
            },
            yAxis: [{
                type: "value",
                show: false,
            }],
            series: [
                {
                    type: 'bar',
                    name: 'Presentes',
                    label: {
                        show: true,
                        position: "outside",
                        fontSize: "10px",
                        formatter: (params) => {
                            // const total = datasetSource[params.dataIndex + 1][1] + datasetSource[params.dataIndex + 1][2];
                            // const percentage = ((datasetSource[params.dataIndex + 1][1] / total) * 100).toFixed(0);
                            return datasetSource[params.dataIndex + 1][1] > 0 ? `${datasetSource[params.dataIndex + 1][1]}%`:'';
                        },
                    },
                },
                {
                    type: 'bar',
                    name: 'Ausentes',
                    label: {
                        show: true,
                        position: "outside",
                        fontSize: "10px",
                        formatter: (params) => {
                            // const total = datasetSource[params.dataIndex + 1][1] + datasetSource[params.dataIndex + 1][2];
                            // const percentage = ((datasetSource[params.dataIndex + 1][2] / total) * 100).toFixed(0);
                            return datasetSource[params.dataIndex + 1][2] > 0 ?  `${datasetSource[params.dataIndex + 1][2]}%`:"";
                        },
                    },
                },
            ],
        }

        myChart.setOption(option);

        return () => {
            if (myChart && !myChart.isDisposed()) {
                myChart.dispose();
            }
        };
    }, [datasetSource]);

    return (
        <div
            ref={chartRef}
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default BarChart;
