import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table } from 'antd';
import TabelaDetalhe from "./Detalhe";
import { GlobalContext } from "../../../../../GlobalState";
import axios from "axios";

const Tabela = ({ match }) => {
    const globalContext = useContext(GlobalContext);
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);

    const carregarDados = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-dados-aproveitamento",
            params: {
                code: match.params.code,
                turmaId: globalContext[`turma${match.params.code}`]?.id
            }
        })
            .then(response => {
                const avaliacoes = response.data.map(avaliacao => {
                    let pautas = {};
                    avaliacao.pautas.map(pauta => {
                        pautas[pauta.titulo.toLowerCase().replaceAll(' ', '')] = pauta.nota
                    })

                    return {
                        id: avaliacao.id,
                        disciplinas: avaliacao.disciplina,
                        ...pautas
                    }
                })

                setDados(avaliacoes);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                setLoading(false);
            });
    };

    const onExpand = (expanded, record) => {
        const expandedKeys = [...expandedRows];
        if (expanded) {
            expandedKeys.push(record.key);
        } else {
            const index = expandedKeys.indexOf(record.key);
            if (index !== -1) {
                expandedKeys.splice(index, 1);
            }
        }
        setExpandedRows(expandedKeys);
    }

    const isRowExpanded = (record) => {
        return expandedRows.includes(record.key);
    };

    useEffect(() => {
        if (globalContext[`turma${match.params.code}`])
            carregarDados();
    }, [globalContext[`turma${match.params.code}`]])

    const columns = [
        {
            title: "Disciplinas",
            dataIndex: "disciplinas",
            className: "bold-field"
        }
    ]

    return (
        <Table
            className="tabela-aproveitamento"
            columns={columns}
            pagination={false}
            dataSource={dados}
            loading={{
                spinning: loading,
                indicator: (
                    <div className="loading-data-table">
                        <div className="loading" />
                    </div>
                )
            }}
            onExpand={onExpand}
            rowClassName={(record, index) =>
                isRowExpanded(record) ? 'parent-row-expanded' : ''
            }
            expandedRowRender={record => <TabelaDetalhe record={record} />}
        />
    );
}

export default withRouter(Tabela);