import React, { useState } from "react";
import '../../../scss/_card.scss';
import Modal from 'react-modal';
import Carousel from "../Components/ModalCarousel"
Modal.setAppElement('#root');

export default function Card(props) {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '1100px',
            borderRadius: '10px',
            overflow: 'hidden',
            border:0,
            padding:0
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }
    };

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }
    
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <div className="cardImg" style={{ position: "relative" }}>
                <img src={props.caminho} className="card_img fotosRecentes" style={{height:"450px"}} onClick={openModal} alt={props.titulo} />
               
                    <div className="autor detalhe-foto">
                        <h3 className={"limited-text1"}>{props.titulo}</h3>
                        <span className="limited-text2">{props.descricao}</span>
                    </div>
                

            </div>

            <Modal
                isOpen={modalIsOpen}
                //onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Carousel handlerClose={closeModal} idFoto={props.id}></Carousel>
            </Modal>
        </>
    );
}
