import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Select, Dropdown, Menu, Icon, Modal, Switch, Drawer, Form } from "antd";
import Header from "../../User/Header";
import noimage from "../../images/noimage.png";
import axios from "axios";
import moment from "moment";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import { GlobalContext } from "../../GlobalState";

import ModalGerarDocumento from "../../Componentes/Modals/GerarDocumento";

class Turmas extends Component {
    static contextType = GlobalContext;
    state = {
        titulo_pagina: "cursos",
        //FILTROS
        pesquisa: "",
        filtro: 2,
        ativo: true,
        //TABELA
        loading_table: true,
        cursos: [],
        cursosMobile: [],
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalhePeriodo: "",
        detalheEstado: "",
        detalheInscritos: "",
    };

    UNSAFE_componentWillMount() {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));

            this.setState({
                pesquisa: opcaoInfo.pesquisa,
                ativo: opcaoInfo.ativo,
                pagination: opcaoInfo.pagination,
            }, () => this.chamarListagens())
        }
        else {
            this.chamarListagens();
        }
    }

    chamarListagens = () => {
        this.totalCursos();
        this.listarCursos();
        localStorage.removeItem("modulo_aberto");
    }

    //VALIDA SE O UTILIZADOR ESTA AUTENTICADO
    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                this.listarCursos();
            }
        );
    };

    handleChangeFiltro = value => {
        var titulo_pagina = "";
        if (value === 1) titulo_pagina = "cursos";
        else titulo_pagina = "cursos com disciplinas";

        this.setState(
            {
                loading_table: true,
                cursos: [],
                titulo_pagina
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    this.totalCursos();
                    this.listarCursos();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    onClick = () => {
        this.context.atualizarState({
            lista_modulos: [],
            lista_disciplinas: []
        });
    };

    abrirDetalhe = (curso) => {
        const detalhePeriodo = <div>
            <span>{
                curso.dt_fim ?
                    `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                    :
                    moment(curso.dt_inicio).format("DD/MM/YYYY")
            }</span>
            {curso.fechado ?
                <div className="tag-fechado">Fechado ({curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}</div>
                : ""}
        </div>;
        const detalheInscritos = curso.inscritos;

        this.setState({
            visibleDetalhe: true,
            detalhePeriodo,
            /*detalheEstado,*/
            detalheInscritos
        })
    }

    montarMenuMobile = curso => {
        if (this.state.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={event => { event.stopPropagation(); this.abrirDetalhe(curso); }}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
        else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={event => { event.stopPropagation(); this.abrirDetalhe(curso); }}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        }
    };

    // MONTA O MENU DE OPÇÕES POR CADA DISCIPLINA
    montarMenuSub = (subcurso, curso) => {
        //if (this.state.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link
                            to={`/${subcurso.nome
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/formador-lista-cursos/inscricoes`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.nome);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                            }}
                        >
                            Inscrições
                    </Link>
                    </Menu.Item>
                    {/*<Menu.Item>*/}
                    {/*    <Link*/}
                    {/*        to={`/${subcurso.nome*/}
                    {/*            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")*/}
                    {/*            .toLowerCase()}/formador-lista-cursos/estatisticas`}*/}
                    {/*        onClick={() => {*/}
                    {/*            localStorage.setItem("cod_curso_pai", curso.codigo);*/}
                    {/*            localStorage.setItem("nome_curso_pai", curso.nome);*/}
                    {/*            localStorage.setItem("cod_subcurso", subcurso.nome);*/}
                    {/*            localStorage.setItem("nome_subcurso", subcurso.nome);*/}
                    {/*            localStorage.setItem("codigo_curso", subcurso.id);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Estatísticas*/}
                    {/*</Link>*/}
                    {/*</Menu.Item>*/}
                </Menu>
            );
        //}
        //else {
        //    return (
        //        <Menu>
        //            <Menu.Item>
        //                <Link
        //                    to={`/${subcurso.nome
        //                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
        //                        .toLowerCase()}/formador-lista-cursos/estatisticas`}
        //                    onClick={() => {
        //                        localStorage.setItem("cod_curso_pai", curso.codigo);
        //                        localStorage.setItem("nome_curso_pai", curso.nome);
        //                        localStorage.setItem("cod_subcurso", subcurso.nome);
        //                        localStorage.setItem("nome_subcurso", subcurso.nome);
        //                        localStorage.setItem("codigo_curso", subcurso.id);
        //                    }}
        //                >
        //                    Estatísticas
        //            </Link>
        //            </Menu.Item>
        //        </Menu>
        //    );
        //}
    };

    montarMenuSubMobile = (subcurso, curso) => {
        if (this.state.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={event => { event.stopPropagation(); this.abrirDetalhe(subcurso); }}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link
                            to={`/${subcurso.nome
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/formador-lista-cursos/inscricoes`}
                            onClick={() => {
                                localStorage.setItem("cod_curso_pai", curso.codigo);
                                localStorage.setItem("nome_curso_pai", curso.nome);
                                localStorage.setItem("cod_subcurso", subcurso.nome);
                                localStorage.setItem("nome_subcurso", subcurso.nome);
                                localStorage.setItem("codigo_curso", subcurso.id);
                            }}
                        >
                            Inscrições
                    </Link>
                    </Menu.Item>
                    {/*<Menu.Item>*/}
                    {/*    <Link*/}
                    {/*        to={`/${subcurso.nome*/}
                    {/*            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")*/}
                    {/*            .toLowerCase()}/formador-lista-cursos/estatisticas`}*/}
                    {/*        onClick={() => {*/}
                    {/*            localStorage.setItem("cod_curso_pai", curso.codigo);*/}
                    {/*            localStorage.setItem("nome_curso_pai", curso.nome);*/}
                    {/*            localStorage.setItem("cod_subcurso", subcurso.nome);*/}
                    {/*            localStorage.setItem("nome_subcurso", subcurso.nome);*/}
                    {/*            localStorage.setItem("codigo_curso", subcurso.id);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Estatísticas*/}
                    {/*</Link>*/}
                    {/*</Menu.Item>*/}
                </Menu>
            );
        }
        else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={event => { event.stopPropagation(); this.abrirDetalhe(subcurso); }}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item>*/}
                    {/*    <Link*/}
                    {/*        to={`/${subcurso.nome*/}
                    {/*            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")*/}
                    {/*            .toLowerCase()}/formador-lista-cursos/estatisticas`}*/}
                    {/*        onClick={() => {*/}
                    {/*            localStorage.setItem("cod_curso_pai", curso.codigo);*/}
                    {/*            localStorage.setItem("nome_curso_pai", curso.nome);*/}
                    {/*            localStorage.setItem("cod_subcurso", subcurso.nome);*/}
                    {/*            localStorage.setItem("nome_subcurso", subcurso.nome);*/}
                    {/*            localStorage.setItem("codigo_curso", subcurso.id);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Estatísticas*/}
                    {/*</Link>*/}
                    {/*</Menu.Item>*/}
                </Menu>
            );
        }
    };

    //TOTAL CURSOS DA ENTIDADE
    totalCursos = () => {
        axios({
            method: "get",
            url: "/api/formador-turmas/total-cursos",
            params: {
                pesquisa: this.state.pesquisa,
                filtro: this.state.filtro,
                ativo: this.state.ativo
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarCursos = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/formador-turmas/listar-cursos",
                    params: {
                        pesquisa: this.state.pesquisa,
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current,
                        filtro: this.state.filtro,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var cursos = [];
                        var cursosMobile = [];

                        response.data.map((curso, index) => {
                            cursos.push({
                                key: index,
                                id: curso.id,
                                edicao: curso.edicao,
                                curso: (
                                    <>
                                        {/*<div className="imagem-curso">
                                            <img src={curso.imagem ? curso.imagem : noimage} />
                                        </div>*/}
                                        <div className="nome-colaborador">
                                            {!curso.grupo ?
                                                <span className="titulo-curso">
                                                    {curso.codigo} - {curso.nome}
                                                </span>
                                                :
                                                <span className="titulo-curso">
                                                    {curso.nome}
                                                </span>
                                            }
                                            {curso.nome_organismo ?
                                                <span className="nome-organismo">
                                                    {curso.nome_organismo}
                                                </span>
                                                : null}
                                        </div>
                                    </>
                                ),
                                codigo: curso.codigo,
                                nome: curso.nome,
                                    periodo: <div className="calendario-info">
                                        {curso.dt_inicio && curso.dt_fim &&
                                            <span className="calendario-info__titulo">{moment(curso.dt_inicio).format("DD/MM/YYYY")} - {moment(curso.dt_fim).format("DD/MM/YYYY")}</span>}
                                        <span className="calendario-info__tipo">{curso.periodo_letivo}</span>
                                    </div>,
                                dt_inicio: curso.dt_inicio,
                                dt_fim: curso.dt_fim,
                                coordenadores: curso.coordenadores,
                                subcursos: curso.subcursos ? curso.subcursos : []
                            });

                            cursosMobile.push({
                                key: index,
                                id: curso.id,
                                curso: <>
                                    {/*<div className="imagem-curso">
                                        <img src={curso.imagem ? curso.imagem : noimage} />
                                    </div>*/}
                                    <div className="nome-curso">
                                        {curso.edicao ?
                                            <span className="texto-edicao">
                                                {`${curso.edicao}${(curso.ano && curso.turma ? ` / ${curso.ano} ${curso.turma}` : `${(curso.ano || curso.turma ? " / " : "")} ${(curso.ano ? curso.ano : "")} ${(curso.turma ? curso.turma : "")}`)}`}
                                            </span> : null}
                                        <span className="titulo-curso">
                                            {curso.codigo} - {curso.nome}
                                            <Dropdown
                                                overlay={this.montarMenuMobile(curso)}
                                                placement="bottomLeft"
                                                onClick={event => event.stopPropagation()}
                                                overlayStyle={{
                                                    zIndex: "9"
                                                }}
                                                trigger={['click']}
                                            >
                                                <Link to="#" className="botao-icon-configs">
                                                    <i className="fas fa-cog" />
                                                </Link>
                                            </Dropdown>
                                        </span>
                                    </div>
                                </>,
                                codigo: curso.codigo,
                                nome: curso.nome,
                                periodo: (
                                    <div>
                                        <span>{
                                            curso.dt_fim ?
                                                `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                                                :
                                                moment(curso.dt_inicio).format("DD/MM/YYYY")
                                        }</span>
                                        {curso.fechado ?
                                            <div className="tag-fechado">Fechado ({curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}</div>
                                            : ""}
                                    </div>
                                ),
                                dt_inicio: curso.dt_inicio,
                                dt_fim: curso.dt_fim,
                                inscritos: curso.inscritos,
                                opcoes: (
                                    <Dropdown
                                        overlay={this.montarMenuMobile(curso)}
                                        placement="bottomLeft"
                                        onClick={event => event.stopPropagation()}
                                        overlayStyle={{
                                            zIndex: "9"
                                        }}
                                        trigger={['click']}
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ),
                                subcursos: curso.subcursos ? curso.subcursos : []
                            });
                        });

                        this.setState({
                            cursos,
                            cursosMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarSubCursos = (subcursos, curso) => {
        var _subcursos = [];

        subcursos.map(subcurso => {
            _subcursos.push({
                key: subcurso.id,
                id: subcurso.id,
                edicao: (
                    <div>
                        <span>{subcurso.edicao}</span>
                        {subcurso.ano || subcurso.turma ?
                            <span style={{ display: "block" }}>{`${(subcurso.ano ? subcurso.ano : "")} ${(subcurso.turma ? subcurso.turma : "")}`}</span>
                            : null}
                    </div>
                ),
                curso: (
                    <>
                        {/*<div className="imagem-curso">
                            <img src={subcurso.imagem ? subcurso.imagem : noimage} />
                        </div>*/}
                        <div className="nome-colaborador">
                            {!subcurso.agrupado ?
                                <span className="titulo-curso">
                                    {subcurso.codigo} - {subcurso.nome}
                                </span>
                                :
                                <span className="titulo-curso">
                                    {subcurso.nome}
                                </span>
                            }
                        </div>
                    </>
                ),
                nome: subcurso.nome,
                periodo: (
                    <div>
                        <span>{
                            subcurso.dt_fim
                                ? `${moment(subcurso.dt_inicio).format("DD/MM/YYYY")} - ${moment(subcurso.dt_fim).format("DD/MM/YYYY")}`
                                : moment(subcurso.dt_inicio).format("DD/MM/YYYY")
                        }</span>
                        {subcurso.fechado ? <div className="tag-fechado">Fechado ({subcurso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(subcurso.dt_fechado).format("DD/MM/YYYY")}</span>})</div> : ""}
                    </div>
                ),
                professores: subcurso.professores,
                inscritos: subcurso.inscritos,
                opcoes: this.state.ativo && subcurso.professor ? (
                    <Dropdown
                        overlay={this.montarMenuSub(subcurso, curso)}
                        placement="bottomLeft"
                        onClick={event => event.stopPropagation()}
                        overlayStyle={{
                            zIndex: "9"
                        }}
                    >
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                ) : null,
                ativo: subcurso.ativo
            });
        });

        return _subcursos;
    };

    listarSubCursosMobile = (subcursos, curso) => {
        var _subcursos = [];

        subcursos.map(subcurso => {
            _subcursos.push({
                key: subcurso.id,
                id: subcurso.id,
                curso: (
                    <>
                        {/*<div className="imagem-curso">
                            <img src={subcurso.imagem ? subcurso.imagem : noimage} />
                        </div>*/}
                        <div className="nome-curso">
                            <span className="texto-edicao">
                                {`${subcurso.edicao}${(subcurso.ano && subcurso.turma ? ` / ${subcurso.ano} ${subcurso.turma}` : `${(subcurso.ano || subcurso.turma ? " / " : "")} ${(subcurso.ano ? subcurso.ano : "")} ${(subcurso.turma ? subcurso.turma : "")}`)}`}
                            </span>
                            <span className="titulo-curso">
                                {subcurso.codigo} - {subcurso.nome}
                            </span>
                        </div>
                    </>
                ),
                nome: subcurso.nome,
                opcoes: (
                    <Dropdown
                        overlay={this.montarMenuSubMobile(subcurso, curso)}
                        placement="bottomLeft"
                        onClick={event => event.stopPropagation()}
                        overlayStyle={{
                            zIndex: "9"
                        }}
                        trigger={['click']}
                    >
                        <Link to="#" className="botao-icon-configs">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                ),
                ativo: subcurso.ativo
            });
        });

        return _subcursos;
    };

    exportarQuestionarioAlunoCSV = cursoid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/curso-questionario-aluno-csv",
                    params: {
                        cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {});
            }
        );
    };

    exportarQuestionarioAlunoPDF = cursoid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/curso-questionario-aluno-pdf",
                    params: {
                        cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {});
            }
        );
    };

    onRowClick = (cursoNome, cursoId, cursoPai) => {
        this.context.atualizarState({
            lista_modulos: [],
            lista_disciplinas: []
        });
        localStorage.setItem("codigo_curso", cursoId);
        localStorage.setItem("opcao_voltar", JSON.stringify({
            pesquisa: this.state.pesquisa,
            tipo_curso: this.state.filtro,
            ativo: this.state.ativo,
            link_voltar: "/formador-lista-turmas",
            link_voltar_curso: "",
            pagination: this.state.pagination
        }))
        this.setState({
            redirect: true,
            redirectLink: `/gerir-detalhe-turma/${cursoNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${cursoPai ? "horario" : "modulos"}`
        })
    }

    render() {
        const {
            //TABELA
            loading_table,
            cursos,
            cursosMobile,
            //PAGINATION
            pagination,
            //REDIRECT
            redirect,
            redirectLink,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //DRAWER DETALHE
            visibleDetalhe,
            detalhePeriodo,
            detalheEstado,
            detalheInscritos
        } = this.state;

        const columnsCursosComDisciplinas = [
            {
                title: "Edição",
                dataIndex: "edicao",
                className: "td-150"
            },
            {
                title: "Turma",
                dataIndex: "curso",
                className: "td-265"
            },
            {
                title: "Cal. Escolar",
                dataIndex: "periodo",
                className: "td-300"
            },
            {
                title: "Coordenadores",
                dataIndex: "coordenadores",
                className: "td-300"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsCursosComDisciplinasMobile = [
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-265"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50 td-opcoes-lvl1"
            }
        ];

        const columnsDisciplinas = [
            {
                title: "Disciplina",
                dataIndex: "curso",
                className: "td-250",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "Inscritos",
                dataIndex: "inscritos",
                className: "td-160",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "Coordenadores / Professores",
                dataIndex: "professores",
                width: 400,
                //className: "td-300",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsDisciplinasMobile = [
            {
                title: "Disciplina",
                dataIndex: "curso",
                className: "td-250",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Turmas" />
                <div className="container container-body">
                    <div className="bloco-cursos">
                        <div className="bloco-tabela">
                            <div className="filtros filtros-tabela-cursos" style={{ justifyContent: "space-between" }}>
                                <div className="bloco-esquerda">
                                    <BotaoProcurar
                                        pesquisa={this.state.pesquisa}
                                        tem_placeholder
                                        placeholder="Procurar por Curso"
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                </div>
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    checked={this.state.ativo}
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="cursos-disciplinas"
                                className="curso-disciplinas-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsCursosComDisciplinas}
                                dataSource={cursos}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem dados!" }}
                                onChange={this.handleChangeTable}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.nome, record.id, true)
                                    };
                                }}
                                expandedRowRender={(record, index) => (
                                    <Table
                                        id="disciplinas"
                                        columns={columnsDisciplinas}
                                        dataSource={this.listarSubCursos(record.subcursos, record)}
                                        pagination={false}
                                        locale={{ emptyText: "Não existem dados!" }}
                                        rowClassName="clickeble-row"
                                        onRow={(record2, index2) => {
                                            return {
                                                onClick: () => this.onRowClick(record2.nome, record2.id, false)
                                            };
                                        }}
                                    />
                                )}
                            />
                            <Table
                                id="cursos-disciplinas"
                                className="curso-disciplinas-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsCursosComDisciplinasMobile}
                                dataSource={cursosMobile}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem dados!" }}
                                onChange={this.handleChangeTable}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.nome, record.id, true)
                                    };
                                }}
                                expandedRowRender={(record, index) => (
                                    <Table
                                        id="disciplinas"
                                        columns={columnsDisciplinasMobile}
                                        dataSource={this.listarSubCursosMobile(record.subcursos, record)}
                                        pagination={false}
                                        locale={{ emptyText: "Não existem dados!" }}
                                        rowClassName="clickeble-row"
                                        onRow={(record2, index2) => {
                                            return {
                                                onClick: () => this.onRowClick(record2.nome, record2.id, false)
                                            };
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
                <Drawer
                    className="drawer-detalhe-curso"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Periodo">
                                    {detalhePeriodo}
                                </Form.Item>
                                {detalheEstado ?
                                    <Form.Item label="Estado">
                                        {detalheEstado}
                                    </Form.Item> : null}
                                {detalheInscritos ?
                                    <Form.Item label="Inscritos">
                                        {detalheInscritos}
                                    </Form.Item> : null}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default Turmas;
