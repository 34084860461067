import {FaRegCalendar} from "react-icons/fa6";

import React from "react";
import {FaReply} from "react-icons/fa";

export default function RespostaComentario(props) {
    return (
        <>
            <div className="headerperfil">
                <div className="perfil">
                    {/*<div>*/}
                    {/*    <img className="imgperfil" src={props.img} alt="perfil"/>*/}
                    {/*</div>*/}
                    <div>
                        <span className="nome"> {props.nome}</span>
                        <div className="iconCalendario">
                            <FaRegCalendar size="14px"/>
                            <span className="data">{props.data}</span>
                        </div>
                    </div>
                </div>
                
            </div>
            <div style={{padding: "10px"}} >
                <p>
                    {props.texto}
                </p>
            </div>
        </>

    )
        ;
}