import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Icon, notification, Select, Modal, Tabs } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";

const { TabPane } = Tabs;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class Login2 extends Component {
    static contextType = GlobalContext;
    state = {
        email: "",
        nomeapelido: "",
        password: "",
        passwordAluno: "",
        organismo: undefined,
        organismos: [],
        estabelecimento: false,
        redirect: false,
        redirectLink: "",
        iconLoadingProf: false,
        iconLoadingAluno: false,
        rememberProf: false,
        rememberAluno: false,
        formLoginNormalVisivel: true
    };

    componentDidMount() {
        this.listarOrganismos();
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            remember: false
        });
    };

    handleChangeEscola = value => {
        this.setState({
            organismo: value
        });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleKeyPressProf = event => {
        if (event.key === "Enter") this.loginInstrutor(event);
    };

    handleKeyPressAluno = event => {
        if (event.key === "Enter") this.loginAluno(event);
    };

    listarOrganismos = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-organismos",
            params: {
                entidadeUrl: this.props.match.params.entidade
            }
        })
            .then(response => {
                const info = response.data;

                this.setState({
                    organismos: info.organismos,
                    organismo: info.estabelecimento ? info.estabelecimentoId : undefined,
                    estabelecimento: info.estabelecimento
                });
            })
            .catch(error => { });
    };

    loginInstrutor = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(["email", "password"], (err, values) => {
            if (!err) {
                this.setState({ iconLoadingProf: true });

                var item = new FormData();
                item.append("email", this.state.email);
                item.append("password", this.state.password);
                item.append("rememberMe", this.state.rememberProf);
                item.append("entidadeUrl", this.props.match.params.entidade);

                axios({
                    method: "post",
                    url: "/api/autenticacaonew/login",
                    data: item
                })
                    .then(response => {
                        if (response.data.token) {
                            localStorage.setItem("accessToken", response.data.token)
                            localStorage.removeItem("redirecionado")
                            axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
                            this.context.atualizarState({
                                atualizarEntidadeFooter: true,
                                is_authenticated: true,
                                is_authenticated_nav: true,
                                role: response.data.role ? response.data.role : "",
                                informacoes: response.data.informacoes
                            });
                            localStorage.removeItem("entidadeId");
                            localStorage.removeItem("nome_curso");
                            localStorage.removeItem("escola_selecionada");

                            if (response.data.senhaValida)
                                this.setState({
                                    redirectLink: response.data.link,
                                    redirect: true
                                });

                            this.setState({
                                redirectLink: `/${this.props.match.params.entidade}/alteracao-senha`,
                                redirect: true
                            });
                        }
                        else {
                            localStorage.removeItem("accessToken")
                            delete axios.defaults.headers.common.Authorization;
                        }
                    })
                    .catch(error => {
                        if (error.response.data.split(";")[0] === "NAO_PAGO") {
                            this.setState({
                                visibleMensagem: true,
                                redirectLink: error.response.data.split(";")[1]
                            });
                        }
                        else if (error.response.data.split(";")[0] === "INATIVA") {
                            openNotificationWithIcon(
                                "warning",
                                "Aviso",
                                <>
                                    Conta inativa ou excluida. Por favor entre em contato com o <button style={{ border: "none", background: "none", color: "#16817a", cursor: "pointer", padding: 0 }} onClick={() => this.setState({ redirect: true, redirectLink: `/${localStorage.getItem("entidade_url")}/suporte-contacto` })}><strong>suporte</strong></button>
                                </>
                            );
                        }
                        else if (error.response.data.split(";")[0] === "SEM_TERMOS_POLITICA") {
                            openNotificationWithIcon(
                                "warning",
                                "Aviso",
                                <>
                                    Não concluiu o seu registo. <br /> Para finalizar o tem de aceitar de aceitar a <strong>Política de Privacidade</strong> e os <strong>Termos de Uso</strong>
                                </>
                            );
                            localStorage.setItem("codigo_utilizador", error.response.data.split(";")[1])
                            this.setState({
                                redirect: true,
                                redirectLink: `/${this.props.match.params.entidade}/utilizador-registar-passo-2`
                            });
                        }
                        else {
                            openNotificationWithIcon("error", "Erro", error.response.data);
                        }

                        this.setState({
                            iconLoadingProf: false
                        });
                    });
            }
        });
    };

    loginAluno = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(["nomeapelido", "passwordAluno", "organismo"], (err, values) => {
            if (!err) {
                this.setState({ iconLoadingAluno: true });

                var item = new FormData();
                item.append("nomeapelido", this.state.nomeapelido);
                item.append("password", this.state.passwordAluno);
                item.append("id_organismo", this.state.organismo);
                item.append("rememberMe", this.state.rememberAluno);
                item.append("entidadeUrl", this.props.match.params.entidade);

                axios({
                    method: "post",
                    url: "/api/autenticacaonew/login-aluno",
                    data: item
                })
                    .then(response => {
                        if (response.data.token) {
                            localStorage.setItem("accessToken", response.data.token)
                            localStorage.removeItem("redirecionado")
                            axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
                            this.context.atualizarState({
                                atualizarEntidadeFooter: true,
                                is_authenticated: true,
                                is_authenticated_nav: true,
                                role: response.data.role ? response.data.role : "",
                                informacoes: response.data.informacoes
                            });
                            localStorage.removeItem("entidadeId");
                            localStorage.removeItem("nome_curso");
                            if (response.data.senhaValida)
                                this.setState({
                                    redirectLink: "/utilizador-detalhe",
                                    redirect: true
                                });

                            this.setState({
                                redirectLink: `/${this.props.match.params.entidade}/alteracao-senha`,
                                redirect: true
                            });
                                
                        }
                        else {
                            localStorage.removeItem("accessToken")
                            delete axios.defaults.headers.common.Authorization;
                        }
                    })
                    .catch(error => {
                        if (error.response.data.split(";")[0] === "INATIVA") {
                            openNotificationWithIcon(
                                "warning",
                                "Aviso",
                                <>
                                    Conta inativa ou excluida. Por favor entre em contato com o <button style={{ border: "none", background: "none", color: "#16817a", cursor: "pointer", padding: 0 }} onClick={() => this.setState({ redirect: true, redirectLink: `/${localStorage.getItem("entidade_url")}/suporte-contacto` })}><strong>suporte</strong></button>
                                </>
                            );
                        }
                        else {
                            openNotificationWithIcon("error", "Erro", error.response.data);
                        }

                        this.setState({
                            iconLoadingAluno: false
                        });
                    });
            }
        });
    };

    render() {
        const {
            organismo,
            organismos,
            estabelecimento,
            redirect,
            redirectLink,
            iconLoadingProf,
            iconLoadingAluno,
            formLoginNormalVisivel
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo={formLoginNormalVisivel ? "Login" : "Login Simplificado"} style={{ height: 80 }} marginTop0={true} />
                <div className="content">
                    {formLoginNormalVisivel ?
                        <Form onSubmit={this.handleSubmit} className="login template-form">
                            <Form.Item label="E-mail">
                                {getFieldDecorator("email", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Introduza o e-mail"
                                        }
                                    ]
                                })(<Input name="email" onChange={this.handleChange} onKeyPress={this.handleKeyPressProf} />)}
                            </Form.Item>
                            <Form.Item label="Senha">
                                {getFieldDecorator("password", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Introduza a senha"
                                        }
                                    ]
                                })(<Input.Password type="password" name="password" onChange={this.handleChange} onKeyPress={this.handleKeyPressProf} />)}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0, marginTop: "auto" }}>
                                <Link to="#">
                                    <Button
                                        type="primary"
                                        className="login-form-button form-button"
                                        onClick={this.loginInstrutor}
                                    >
                                        Entrar
                                </Button>
                                </Link>
                                {getFieldDecorator("rememberAluno", {
                                    valuePropName: "checked"
                                })(
                                    <Checkbox name="rememberAluno" onChange={this.handleChangeCheckbox}>
                                        <span className="ant-lembrar-dados">Lembrar os meus dados</span>
                                    </Checkbox>
                                )}
                                <div className="bloco-login-links">
                                    <Link to={`/${this.props.match.params.entidade}/recuperar-senha-escola`} className="form-link">
                                        Esqueci a senha <i className="fas fa-chevron-right"></i>
                                    </Link>
                                    <Link to="#" onClick={() => this.setState({ formLoginNormalVisivel: false })} className="form-link">
                                        Login Simplificado <i className="fas fa-chevron-right"></i>
                                    </Link>
                                </div>
                            </Form.Item>
                        </Form>
                        :
                        <Form className="login template-form">
                            <Form.Item label="Primeiro Nome e Sobrenome">
                                {getFieldDecorator("nomeapelido", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Introduza o primeiro nome e o apelido"
                                        }
                                    ]
                                })(<Input name="nomeapelido" onChange={this.handleChange} onKeyPress={this.handleKeyPressAluno} />)}
                            </Form.Item>
                            <Form.Item label="Senha">
                                {getFieldDecorator("passwordAluno", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Introduza a senha"
                                        }
                                    ]
                                })(
                                    <Input.Password
                                        type="password"
                                        name="passwordAluno"
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPressAluno}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Unidade Organizacional/Escola">
                                {getFieldDecorator("organismo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Selecione a escola"
                                        }
                                    ],
                                    initialValue: organismo
                                })(
                                    <Select placeholder="Selecionar" disabled={estabelecimento} allowClear={true} onChange={e => this.handleChangeEscola(e)}>
                                        {organismos.map(organismo => (
                                            <Option key={organismo.id} value={organismo.id}>
                                                {organismo.nome}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Link to="#">
                                    <Button
                                        type="primary"
                                        className="login-form-button form-button"
                                        onClick={this.loginAluno}
                                    >
                                        Entrar
                                </Button>
                                </Link>
                                {getFieldDecorator("rememberAluno", {
                                    valuePropName: "checked"
                                })(
                                    <Checkbox name="rememberAluno" onChange={this.handleChangeCheckbox}>
                                        <span className="ant-lembrar-dados">Lembrar os meus dados</span>
                                    </Checkbox>
                                )}
                                <div className="bloco-login-links">
                                    <Link to={`/${this.props.match.params.entidade}/recuperar-senha-escola`} className="form-link">
                                        Esqueci a senha <i className="fas fa-chevron-right"></i>
                                    </Link>
                                    <Link to="#" onClick={() => this.setState({ formLoginNormalVisivel: true })} className="form-link">
                                        Login Email-Senha <i className="fas fa-chevron-right"></i>
                                    </Link>
                                </div>
                            </Form.Item>
                        </Form>
                    }
                </div>
                <Modal
                    visible={iconLoadingProf || iconLoadingAluno}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A entrar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

const Login2Form = Form.create({ name: "form_login_publico" })(Login2);

export default Login2Form;
