import React, { Component } from "react";
import Gestor from "./_Aux/Gestor"
import { GlobalContext } from "../../GlobalState";

export class Main extends Component {
    static contextType = GlobalContext;

    render() {

        return (
            <div className="container container-body">
                <Gestor />
            </div>
        );
    }
}

export default Main;
