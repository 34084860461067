import React, { Component } from "react";
import { Form, Input, Icon, notification } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Conteudos extends Component {
    state = {
        conteudo: "",
        metodologia: ""
    }

    componentDidMount() {
        this.detalhe();
    }

    detalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe-conteudo",
            params: {
                aulaId: localStorage.getItem("aula_diario")
            }
        })
            .then(response => {
                this.setState({
                    conteudo: response.data.conteudo,
                    metodologia: response.data.metodologia,
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    render() {
        const {
            conteudo,
            metodologia
        } = this.state;

        return (
            <>
                <Form className="form-categorias bloco-conteudos-aluno" layout="horizontal">
                    <div className="bloco">
                        <div className="bloco-conteudo-aluno">
                            <div className="bloco-conteudo-titulo">
                                <label>Objeto de Conhecimento/Conteúdo</label>
                            </div>
                            <p>{conteudo ? conteudo : "Sem dados..."}</p>
                        </div>
                        <div className="bloco-conteudo-aluno">
                            <div className="bloco-conteudo-titulo">
                                <label>DESDP/Metodologia</label>
                            </div>
                            <p>{metodologia ? metodologia : "Sem dados..."}</p>
                        </div>
                    </div>
                </Form>
            </>
        );
    }
}

const FormConteudos = Form.create({ name: "form-conteudos" })(Conteudos);

export default FormConteudos;