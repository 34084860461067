import {Link, useLocation} from "react-router-dom";
import {FaArrowLeft} from "react-icons/fa";
import React from "react";

export default  function  BarraAreaAlunoRelatorio(props){

    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entidade = pathParts[1]

    return (

        <div className="nomeAluno">

            <div className="nome">
                <span style={{fontWeight:'bold'}}> RELATÓRIO</span>
            </div>
            <div className="seta">
                <Link to={`/${entidade}/areaAluno/${localStorage.getItem('numeroAluno')}`} className="seta">
                    <FaArrowLeft style={{}}/>
                </Link>
            </div>
        </div>
    );
}