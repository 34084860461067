import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Oops from "../../Geral/Oops.jsx";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Audio extends Component {
    static contextType = GlobalContext;
    state = {
        entidade: "",
        curso: "",
        cursoId: "",
        aulaid: 0,
        nome: "",
        objetivos: "",
        current: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        start: true,
        play: false,
        sameUrl: true,
        //LOADING
        loading: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            entidade: this.props.match.params.entidade,
            curso: this.props.match.params.curso,
            cursoId: this.props.match.params.cursoid,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-player/conteudo1",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    loading: false
                }, () => {
                    this.player.addEventListener("play", e => {
                        this.setState({ play: true });
                    });

                    this.player.addEventListener("pause", e => {
                        this.setState({ play: false });
                    });

                    this.player.oncanplay = () => {
                        if (this.getParameterByName("start") && this.state.start) {
                            this.player.currentTime = this.getParameterByName("start");
                            this.setState({
                                start: false
                            });
                        }
                    };
                    this.player.src = response.data.recurso
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //APANHA OS ATRIBUTOS PASSADOS PELO ENDEREÇO
    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    render() {
        const {
            entidade,
            nome,
            objetivos,
            curso,
            cursoId,
            current,
            //REDIRECT
            redirect,
            redirectLink,
            play,
            sameUrl,
            //LOADING
            loading,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
                <div className="questionario">
                    <div className="player-wrapper">
                        {/*<LazyLoad offsetVertical={200}>*/}
                        {/*    <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                        {/*</LazyLoad>*/}
                        <div className="container">
                            <div className="bloco-presencial">
                                <div className="bloco-download-link">
                                    <div className="bloco-info-tarefa">
                                        <span className="icon-tarefa">
                                            <img src={require("../../images/icons/audio.png")} />
                                        </span>
                                        <span className="titulo-tarefa">{nome}</span>
                                    </div>
                                    <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                    <div className="player-audio">
                                        <div className="container-audio">
                                            <audio controls ref={ref => (this.player = ref)} controlsList="nodownload">
                                                <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/9473/new_year_dubstep_minimix.ogg" type="audio/x-wav" />
                                            </audio>
                                        </div>
                                        <div className="container-audio container-barras">
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                            <div className={"item" + `${play ? " item-animado" : ""}`}>
                                                <div className="row"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Controlos
                            backUrl={`/${entidade}/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${cursoId}/curso-modulos`}
                            sameUrl={sameUrl}
                            current={current}
                            player
                        />
                    </div>
                </div>
            );
    }
}

export default Audio;
