import React from "react"
import noimage from "../../images/noimage.png";
import moment from "moment";

const Participante = ({ foto, nome, numero, professor, coordenador, ultimoAcesso }) => {

    const montarTitulo = (professor, coordenador) => {
        if (professor && coordenador)
            return <span className="perfil coordenador">
                Coord. / Prof.
            </span>
        else if (coordenador)
            return <span className="perfil coordenador">
                Coordenador
            </span>
        else if (professor)
            return <span className="perfil professor">
                Professor
            </span>
        else return;
    }

    const ultimoLogin = (ultimoAcesso) => {
        if (!ultimoAcesso || !moment(ultimoAcesso).isAfter(moment('0001-01-01'))) {
            return "Nunca";
        }

        const timeDiff = moment().diff(moment(ultimoAcesso));

        switch (true) {
            case timeDiff >= 604800000: // maior que 7 dias
                return moment(ultimoAcesso).format("DD/MM/YYYY");
            case timeDiff >= 86400000: // maior que 1 dia
                return `${Math.floor(timeDiff / 86400000)} dias`;
            case timeDiff >= 3600000: // maior que 1 hora
                return `${Math.floor(timeDiff / 3600000)} horas`;
            case timeDiff >= 60000: // maior que 1 minuto
                return `${Math.floor(timeDiff / 60000)} minutos`;
            default:
                return `${timeDiff / 1000} segundos`;
        }
    }

    return (
        <div className="cartao-participante">
            {montarTitulo(professor, coordenador)}
            <div className="container-img">
                <img src={foto ? foto : noimage} />
            </div>
            <div className="info">
                <span className="nome">{nome.split(" ").length > 1 ? `${nome.split(" ")[0]} ${nome.split(" ").pop()}` : nome}</span>
                <span className="numero">{numero}</span>
            </div>
            <span className="ultimo-acesso" title="Último acesso ao curso">{ultimoLogin(ultimoAcesso)}</span>
        </div>
    )
}

export default Participante
