import React, { Component } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Input, Select, Checkbox } from "antd";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import noimage from "../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerRelatorio extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        utilizador: "",
        //INFO
        assunto: "",
        estado: "Normal",
        mensagem: "",
        anexo: "",
        fileList: [],
        //VALIDACOES
        status: "",
        helper: "",
        totalBytes: 0,
        formatoValido: true,
        //NOTIFICAR
        notificar: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.setState({
            curso: this.props.match.params.curso,
            cursoid: localStorage.getItem("codigo_curso"),
        });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeCheck = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    //ADICIONA UMA NOVA ANOTAÇÃO
    enviarMensagem = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                let item = new FormData();
                item.append("id_inscricao", this.state.utilizador.id_inscricao);
                item.append("id_curso", this.state.cursoid);
                item.append("assunto", this.state.assunto);
                item.append("estado", this.state.estado);
                item.append("mensagem", this.state.mensagem);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });
                item.append("notificar", this.state.notificar);

                axios({
                    method: "post",
                    url: "/api/gestao-curso-inscricoes/enviar-mensagem",
                    data: item
                })
                    .then(() => {
                        this.setState({
                            iconLoading: false,
                            //INFO
                            assunto: "",
                            estado: "Normal",
                            mensagem: "",
                            anexo: "",
                            fileList: [],
                            //VALIDACOES
                            status: "",
                            helper: "",
                            totalBytes: 0,
                            formatoValido: true,
                            //NOTIFICAR
                            notificar: false
                        });
                        this.props.form.resetFields();
                        openNotificationWithIcon("success", "Sucesso", "Mensagem enviada!");
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = formato => {
        let formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        let valido = false;
        for (let i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.setState({
                utilizador: JSON.parse(this.props.utilizador),
                //INFO
                assunto: "",
                estado: "Normal",
                mensagem: "",
                anexo: "",
                fileList: [],
                //VALIDACOES
                status: "",
                helper: "",
                totalBytes: 0,
                formatoValido: true,
                //NOTIFICAR
                notificar: false
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const Dragger = Upload.Dragger;
        const { Option } = Select;
        const { fileList, validateStatus, help, iconLoading } = this.state;
        
        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <Drawer
                //title={this.props.editarRelatorio ? "Editar" : "Adicionar"}
                placement="right"
                className="drawer-envio-mensagem-inscricao"
                maskClosable={false}
                closable={true}
                onClose={this.props.onClose}
                visible={this.props.visibleDrawerRelatorio}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-nova-pergunta">
                        <div className="bloco">
                            <Form.Item label="Texto">
                                {getFieldDecorator("mensagem", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<TextArea rows="8" name="mensagem" placeholder="Mensagem ..." style={{ height: "unset" }} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <i className="fas fa-upload" />
                                    </p>
                                    <p className="ant-upload-text">
                                        Adicionar anexos{" "}
                                        <Tooltip className="info-icon" title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </p>
                                    <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                </Dragger>
                            </Form.Item>
                            {!this.props.editarRelatorio &&
                                <>
                                    <Form.Item className="form-item-checkbox">
                                        <Checkbox name="notificar" onChange={this.handlerChangeCheck}>
                                            {" "}
                                Notificar Aluno por e-mail{" "}
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item className="form-item-checkbox">
                                        <Checkbox name="notificar" onChange={this.handlerChangeCheck}>
                                            {" "}
                                Notificar Responsável por e-mail{" "}
                                        </Checkbox>
                                    </Form.Item>
                                </>
                            }
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                    <button
                        className="botao-principal"
                        onClick={this.enviarMensagem}
                        disabled={iconLoading}
                    >
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                    {/*{this.props.editarRelatorio ?*/}
                    {/*    <button*/}
                    {/*        className="botao-principal"*/}
                    {/*        onClick={this.enviarMensagem}*/}
                    {/*        disabled={iconLoading}*/}
                    {/*    >*/}
                    {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*                Guardar*/}
                    {/*            </button>*/}
                    {/*    :*/}
                    {/*    <button*/}
                    {/*        className="botao-principal"*/}
                    {/*        onClick={this.enviarMensagem}*/}
                    {/*        disabled={iconLoading}*/}
                    {/*    >*/}
                    {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*                Guardar*/}
                    {/*            </button>*/}
                    {/*}*/}
                </div>
            </Drawer>
        );
    }
}

const FormDrawerRelatorio = Form.create({ name: "form-relatorio" })(DrawerRelatorio);

export default withRouter(FormDrawerRelatorio);
