import React from "react";

const MsgSecundaria = props => {
    return (
        <span className="msg-secundaria" style={props.style}>
            {props.texto}
        </span>
    );
};

export default MsgSecundaria;
