import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Drawer, Select, notification, Icon, Input, Form } from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../../GlobalState"
import noimage from "../../../images/noimage.png";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerPasta extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        //LOADING
        iconLoading: false
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    carregarDetalhe = pastaId => {
        axios({
            method: "get",
            url: "/api/curso-fichs/carregar-detalhe-pasta",
            params: {
                pastaId
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data
                });
            })
            .catch(() => { });
    }

    guardar = () => {
        this.setState({
            iconLoading: true
        }, () => {
            var item = new FormData();
            item.append("id_curso", localStorage.getItem("codigo_curso"));
            item.append("nome", this.state.nome);
            item.append("paiNiveis", this.props.niveis);

            axios({
                method: "post",
                url: "/api/curso-fichs/adicionar-pasta",
                data: item
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Pasta adicionada!");
                    this.setState({
                        iconLoading: false
                    });
                    this.props.atualizarListagem();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    this.setState({
                        iconLoading: false
                    });
                });
        })
    }

    alterar = () => {
        this.setState({
            iconLoading: true
        }, () => {
            var item = new FormData();
            item.append("id", this.props.pastaId);
            item.append("id_curso", localStorage.getItem("codigo_curso"));
            item.append("nome", this.state.nome);

            axios({
                method: "put",
                url: "/api/curso-fichs/alterar-pasta",
                data: item
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Pasta alterada!");
                    this.setState({
                        iconLoading: false
                    });
                    this.props.atualizarListagem();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    this.setState({
                        iconLoading: false
                    });
                });
        })
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.pastaId > 0) {
                this.carregarDetalhe(this.props.pastaId);
            }
            else {
                this.setState({
                    nome: ""
                })
            }
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            nome,
            //LOADING
            iconLoading
        } = this.state;

        const {
            pastaId,
            visiblePasta
        } = this.props;

        return (
            <Drawer
                className="drawer-add-cursos drawer-videoconferencias drawer-drive"
                //title={pastaId > 0 ? "Editar" : "Adicionar"}
                width={720}
                onClose={this.props.onClose}
                visible={visiblePasta}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" onChange={this.handleChange} />)}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" disabled={iconLoading} onClick={pastaId > 0 ? this.alterar : this.guardar}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                    {/*{pastaId > 0 ? (*/}
                    {/*    <button className="botao-principal" disabled={iconLoading} onClick={this.alterar}>*/}
                    {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*            Guardar*/}
                    {/*    </button>*/}
                    {/*) : (*/}
                    {/*    <button className="botao-principal" disabled={iconLoading} onClick={this.guardar}>*/}
                    {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*            Guardar*/}
                    {/*    </button>*/}
                    {/*)}*/}
                </div>
            </Drawer>
        );
    }
}

const DrawerPastaForm = Form.create({ name: "form-drawer-pasta" })(DrawerPasta);

export default withRouter(DrawerPastaForm);
