import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, notification, Input, Form, Icon, Checkbox, Tooltip } from "antd";
import Resposta from "./Resposta";
import { GeralContext } from "../../ContextProvider";
import { GlobalContext } from "../../../GlobalState";
import noimage from "../../../images/noimage.png";
import axios from "axios";
import moment from "moment";

const { TextArea } = Input;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Topico extends Component {
    static contextType = GeralContext;
    state = {
        forum_respostas: [],
        descricao: "",
        razao: "",
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //NOTIFICAR
        notificar: false
    };

    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.CarregarRespostas();
    }

    handleChangeEditor = event => {
        this.setState({
            descricao: event.target.value
        });
    };

    handlerChange = event => {
        this.setState({
            razao: event.target.value
        });
    };

    handlerChangeCheck = event => {
        this.setState({
            notificar: event.target.checked
        })
    }

    //LISTA TODAS AS RESPOSTAS NO TOPICO
    CarregarRespostas = () => {
        axios({
            method: "get",
            url: "/api/gerir-forum/gerir-forum-respostas",
            params: {
                forumid: this.props.forumid
            }
        })
            .then(response => {
                this.setState({
                    forum_respostas: response.data.respostas
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //ADICIONA UMA NOVA RESPOSTA AO TOPICO
    AdicionarResposta = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData();
                item.append("id_forum", this.props.forumid);
                item.append("texto", this.state.descricao);
                //item.append("notificar", this.state.notificar);

                axios({
                    method: "post",
                    url: "/api/gerir-forum/adicionar-resposta",
                    data: item
                })
                    .then(() => {
                        this.CarregarRespostas();
                        this.setState({
                            descricao: "",
                            notificar: false,
                            iconLoading: false
                        });
                        this.props.form.resetFields();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar nova publicação!");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //APROVA A RESPOSTA PARA QUE FIQUE VISIVEL PARA TODOS
    aprovarResposta = respostaid => {
        confirm({
            title: "Pretende aprovar esta publicação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gerir-forum/aprovar-resposta",
                    params: {
                        respostaid: respostaid
                    }
                })
                    .then(() => {
                        this.CarregarRespostas();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possivel aprovar a publicação!");
                    });
            }
        });
    };

    //REJEITA A RESPOSTA REMOVENDO DA VISTA
    rejeitarResposta = respostaid => {
        confirm({
            title: "Pretende rejeitar esta publicação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                confirm({
                    title: "Introduza a razão pela qual rejeita esta publicação",
                    content: <TextArea onChange={this.handlerChange} rows={4} style={{ height: 100 }} />,
                    okText: "Confirmar",
                    okType: "Cancelar",
                    onOk: () => {
                        axios({
                            method: "put",
                            url: "/api/gerir-forum/rejeitar-resposta",
                            params: {
                                respostaid: respostaid,
                                razao: this.state.razao
                            }
                        })
                            .then(() => {
                                this.CarregarRespostas();
                            })
                            .catch(error => {
                                openNotificationWithIcon("error", "Erro", "Não foi possivel rejeitar a publicação!");
                            });
                    }
                });
            }
        });
    };

    excluir = respostaid => {
        confirm({
            title: "Pretende excluir esta publicação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gerir-forum/excluir-forum-resposta",
                    params: {
                        respostaid: respostaid
                    }
                })
                    .then(() => {
                        this.CarregarRespostas();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a publicação!");
                    });
            }
        });
    };

    excluirAprovada = respostaid => {
        confirm({
            title: "Pretende excluir esta publicação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gerir-forum/excluir-forum-resposta-aprovada",
                    params: {
                        respostaid: respostaid
                    }
                })
                    .then(() => {
                        this.CarregarRespostas();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a publicação!");
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            forum_respostas,
            descricao,
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink,
            //NOTIFICAR
            notificar
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <GlobalContext.Consumer>
                {globalContext => {
                    return (
                        <div>
                            {forum_respostas.length > 0 && (
                                <div className="lista-respostas">
                                    {forum_respostas.map((resposta, index) => (
                                        <Resposta
                                            key={index}
                                            forumid={this.props.forumid}
                                            resposta={resposta}
                                            reportarResposta={this.props.reportarResposta}
                                            aprovarResposta={this.aprovarResposta}
                                            rejeitarResposta={this.rejeitarResposta}
                                            excluirAprovada={this.excluirAprovada}
                                            excluir={this.excluir}
                                            tem_comandos={this.props.tem_comandos}
                                            formador_curso={this.props.formador_curso}
                                            fechado={this.context.fechado}
                                        />
                                    ))}
                                </div>
                            )}
                            {!this.context.fechado &&
                                <div className="adicionar-resposta">
                                    <div className="caixa-texto">
                                        <Form className="form-nova-pergunta">
                                            <Form.Item>
                                                {getFieldDecorator("nome-completo", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Introduza uma resposta"
                                                        }
                                                    ],
                                                    initialValue: descricao
                                                })(
                                                    <TextArea
                                                        name="descricao"
                                                        onChange={this.handleChangeEditor}
                                                        rows={6}
                                                        style={{ height: "100%" }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Form>
                                    </div>
                                    <button
                                        className="botao-principal"
                                        onClick={this.AdicionarResposta}
                                        disabled={
                                            iconLoading ||
                                            (!this.context.inscrito && globalContext.role === "UTILIZADOR" && this.context.inscricao)
                                        }
                                    >
                                        {iconLoading ? <Icon type="loading" /> : null}
                                    Adicionar publicação
                                </button>
                                </div>
                            }
                        </div>
                    );
                }}
            </GlobalContext.Consumer>
        );
    }
}

const FormTopico = Form.create({ name: "form-topico" })(Topico);

export default FormTopico;
