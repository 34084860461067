import React, { Component } from "react";
import { Drawer, Form, Card, Icon, notification, Upload, Button, Tooltip } from "antd";
import noimage from "../../../images/noimage.png";
import axios from "axios";
import { GlobalContext } from "../../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerImportar extends Component {
    static contextType = GlobalContext;
    state = {
        //AULA TIPO DOWNLOAD
        ficheiro: [],
        utilizadores: [],
        //LOADING
        iconLoading: false,
        loading: false
    };

    handleChangeEmail = (event, index) => {
        var emails = this.state.emails;
        emails[index].email = event.target.value;

        this.setState({
            emails
        });
    };

    excluir = index => {
        this.props.form.resetFields();
        var emails = this.state.emails;
        emails.splice(index, 1);

        this.setState({
            emails
        });
    };

    inscrever = event => {
        event.preventDefault();

        this.setState({ iconLoading: true });

        axios({
            method: "post",
            url: "/api/gestao-curso-inscricoes/inscrever-utilizador-csv",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                lista: JSON.stringify(this.state.utilizadores)
            }
        })
            .then(response => {
                if (this.state.utilizadores.length > 1)
                    openNotificationWithIcon("success", "Sucesso", "Alunos inscritos ao curso");
                else
                    openNotificationWithIcon("success", "Sucesso", "Aluno inscrito ao curso");

                this.setState({
                    ficheiro: [],
                    iconLoading: false
                });
                this.props.fecharDrawer(false);
                this.props.atualizarListagem();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.setState({
                    iconLoading: false
                });
            });
    };

    afterVisibleChange = aberto => {
        if (aberto)
            this.setState({
                ficheiro: [],
                utilizadores: []
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            ficheiro,
            utilizadores,
            //LOADING
            iconLoading,
            loading
        } = this.state;

        const props = {
            multiple: false,
            onChange: info => {
                var item = new FormData();
                item.append("cursoId", localStorage.getItem("codigo_curso"));
                item.append("ficheiro", info.file);
                if (localStorage.getItem("escola_id"))
                    item.append("organismoId", localStorage.getItem("escola_id"));

                axios({
                    method: "post",
                    url: localStorage.getItem("escola_id") ? "/api/gestao-curso-inscricoes/adicionar-csv-organismos" : "/api/gestao-curso-inscricoes/adicionar-csv",
                    data: item
                })
                    .then(response => {
                        this.setState({
                            utilizadores: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível carregar ficheiro");
                    });
            },
            beforeUpload: file => {
                return false;
            },
            ficheiro
        };

        return (
            <Drawer
                className="drawer-inscrever"
                //title={
                //    <>
                //        Importar inscrições{" "}
                //        <Tooltip className="info-icon" title="Importação com emails de alunos">
                //            <Icon type="question-circle-o" />
                //        </Tooltip>
                //    </>
                //}
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <Upload {...props} fileList={this.state.ficheiro}>
                        <Button disabled={this.state.ficheiro.length > 0}>
                            <i className="fas fa-upload" style={{ marginRight: "10px" }} /> Carregar ficheiro
                        </Button>
                    </Upload>
                    <div className="listagem" style={{ marginTop: 30 }}>
                        {loading ?
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                            :
                            utilizadores.map((utilizador, index) =>
                                <Card key={index} >
                                    <div className="bloco-info-card-head">
                                        <div className="container-img">
                                            <img src={utilizador.foto ? utilizador.foto : noimage} alt="Foto" />
                                        </div>
                                        <span className="nome">
                                            {utilizador.nome_completo}
                                        </span>
                                    </div>
                                    <p><strong>Número:</strong> {utilizador.nr_aluno}</p>
                                    <p><strong>Email:</strong> {utilizador.email}</p>
                                    {utilizador.contacto &&
                                        <p><strong>Contacto:</strong> {utilizador.contacto}</p>}
                                    {(utilizador.organismo && this.context.escolas_entidade) &&
                                        <p><strong>Organismo:</strong> {utilizador.organismo}</p>}
                                    <p><strong>Estado:</strong> {utilizador.inscrito ? <span className="estado-inscrito">Inscrito</span> : <span className="estado-nao-inscrito">Não inscrito</span>}</p>
                                </Card>
                            )}
                    </div>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" type="primary" disabled={iconLoading || utilizadores.length === 0} onClick={this.inscrever}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Inscrever
                    </button>
                </div>
            </Drawer>
        );
    }
}

const FormDrawerImportar = Form.create({ name: "drawer-importar" })(DrawerImportar);

export default FormDrawerImportar;
