import React, { Component, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Icon, notification } from "antd";
import axios from "axios";

import ModalGerarDocumento from "../../../Componentes/Modals/GerarDocumento";
import ModalLoading from "../../../Componentes/Modals/ModalLoading";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Popup = () => {
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");

    //MONTA AS OPÇÕES DE MENU POR CADA QUESTIONARIO NA TABELA
    const montarMenu = () => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-download"
                    title="Inativar"
                    onClick={exportar}
                >
                    <Icon type="download" />
                </Link>
            </div>
        );
    };

    const exportar = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/excel/exportar-popup"
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    }

    const columns = [
        {
            title: "Questionário",
            dataIndex: "questionario"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const dataSource = [
        {
            questionario: "Popup",
            opcoes: montarMenu()
        }
    ]

    return (
        <>
            <div className="bloco-minha-conta">
                <div className="bloco-gestor-paginas">
                    <div className="bloco-tabela">
                        <Table
                            id="tabela_categorias"
                            className="tabela-paginas-desktop"
                            loading={{
                                spinning: false,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            rowClassName="clickeble-row"
                            locale={{ emptyText: "Não existem dados!" }}
                        />
                    </div>
                </div>
            </div>
            <ModalLoading
                visible={visibleLoading}
                text="A gerar documento..."
            />
            <ModalGerarDocumento
                visibleExportar={visibleExport}
                ficheiroExportar={exportFile}
                btnDescarregar={buttonDownload}
                onClose={() => setVisibleExport(false)}
            />
        </>
    );
}

export default Popup;
