import {Link, useLocation} from "react-router-dom";
import React, {useState} from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

export default function NomeBara({ num, nome, handlerClick, total, periodo }){
    /*var bimestres = ["1º Bimestre","2º Bimestre","3º Bimestre","4º Bimestre"]*/
    
    
    return (
        <div className="bimestres">
            <div className="nome">
                <span style={{textTransform: 'uppercase'}}>{nome}</span>
            </div>
            <div className="bimestre">
                <button onClick={() => handlerClick(num - 1)} disabled={num === 1}><FaAngleLeft color='#647177' /></button>
                <span>{periodo?.titulo}</span>
                <button onClick={() => handlerClick(num + 1)} disabled={num === total}><FaAngleRight color='#647177' /></button>
            </div>
        </div>
    );
}