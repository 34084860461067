
import React, {useEffect, useState} from "react";
import GraficoBarras from "./GraficoBarras";
import axios from "axios";

export default function CoordenadoresAtrasoValidacao (){
    

    const [datasetSource, setDatasetSource] = useState();
    const [ano, setAno] = useState(new Date().getFullYear())
    const atualizarAno2  = (valor) => {
        setAno(valor)
        dados(valor)
    }

    const dados = (ano) => {
        axios.get(`/api/dashboard/coordenador-desempenho?ano=${ano}`)
            .then(response => {
                setDatasetSource(response.data);
            })
            .catch(error => {
                console.error("Erro ao carregar os dados dos alunos:", error);
            });
    };
    
    useEffect(() => {
        dados(ano)
    }, []);
    
    return(
        <GraficoBarras 
            titulo="Coordenadores com baixo desempenho" 
            datasetSource={datasetSource}
            atualizarAno2={atualizarAno2}
        ></GraficoBarras>
    );

}