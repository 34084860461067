import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';
import index from "react-highlight-words";

const BarChart = (data) => {
    const chartRef = useRef(null);
    useEffect(() => {
        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);
        
        var bimestres = []

        data.data.map((x, index) => {
            if (x > 0) {
                bimestres.splice(index, 1, index+1 + "º Bimestre");
            }
            else{
                bimestres.splice(index, 1,  "");
            }
            return null; // Returning null because map expects a return value
        });




        const option = {
            xAxis: {
                type: 'category',
                data: bimestres,
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show:true,
                    inside: true,
                    rotate:90,
                    verticalAlign: "bottom",
                    lineHeight: 40,
                    fontSize: 14,
                    fontWeight: "500", 
                    color: "rgba(163, 174, 208, 1)"


    }
            },
            yAxis: {
                type: 'value',
                show: false
            },
            series: [
                {
                    data: data.data,
                    type: 'bar',
                    barWidth: '30%',
                    itemStyle: {
                        borderRadius: [50, 50, 0, 0], // Bordas arredondadas no topo
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#3a3376' },   // Cor inicial
                            { offset: 1, color: '#c8bcfe' }    // Cor final
                        ])
                    },

                }
            ]
        };

        myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, []);


    return (
        <div
            ref={chartRef}
            style={{ width: '347px', height: '280px' }}
        />
    );
};

export default BarChart;
