import React, { useState, useEffect, useContext } from "react";
import { notification, Drawer, Input, Card, Upload, Radio, Button, Select } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../../images/noimage.png";
import { GlobalContext } from "../../../../GlobalState";
//import DrawerListaCursos from "../DrawerListaCursos/DrawerListaCursos";
import DrawerTransferenciaListaDisciplinas from "../Drawers/TransferenciaListaDisciplinas";

const { Search } = Input;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerInscricao = ({ visible, onClose }) => {
    const [pesquisa, setPesquisa] = useState("");
    const [utilizadores, setUtilizadores] = useState([]);
    const [utilizador, setUtilizador] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [turmas, setTurmas] = useState([]);
    const [ficheiro, setFicheiro] = useState([]);
    const [tipoInscricao, setTipoInscricao] = useState(1);
    const [loading, setLoading] = useState(false);
    const [inscreverLoading, setInscreverLoading] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [turmaSelecionadaId, setTurmaSelecionadaId] = useState(false);

    const context = useContext(GlobalContext);

    const handleChangeRadio = (e) => {
        setTipoInscricao(e.target.value);
        setPesquisa("");
        setUtilizadores([]);
    }

    const pesquisar = value => {
        if (value) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/gestao-turma-matriculas/pesquisar-utilizador-curso-disciplinas",
                params: {
                    pesquisa: value,
                    cursoid: localStorage.getItem("codigo_curso"),
                    organismoId: localStorage.getItem("escola_id")
                }
            })
                .then(response => {
                    setUtilizadores(response.data);
                    setLoading(false);
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar!");
                    setLoading(false);
                });
        }
        else
            setUtilizadores([]);
    };

    const listarCursos = () => {
        axios({
            method: "get",
            url: "/api/gestao-turma-matriculas/listar-subcursos",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setCursos(response.data);
            })
            .catch(() => { });
    }

    const listarTurmas = () => {
        axios({
            method: "get",
            url: "/api/gestao-turma-matriculas/listar-turmas",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setTurmas(response.data);
            })
            .catch(() => { });
    }

    const listarAlunosTurma = cursoSelecionadoId => {
        if (cursoSelecionadoId) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/gestao-turma-matriculas/listar-utilizador-turma",
                params: {
                    cursoSelecionadoId,
                    cursoId: localStorage.getItem("codigo_curso")
                }
            })
                .then(response => {
                    setTurmaSelecionadaId(cursoSelecionadoId)
                    setUtilizadores(response.data);
                    setLoading(false);
                })
                .catch(() => { });
        }
        else
            setUtilizadores([]);
    };

    const abrirDrawer = (utilizador) => {
        setUtilizador(utilizador);
        setDrawerVisible(true);
    }

    const confirmarDrawer = (selecionados) => {
        const utilizadorIndex = utilizadores.findIndex(x => x.id === utilizador.id);
        const tempUtilizador = utilizadores;
        tempUtilizador[utilizadorIndex].inscricoes = selecionados;
        setUtilizadores(tempUtilizador);
        setDrawerVisible(false);
    }

    const afterVisibleChange = visible => {
        if (!visible) {
            setPesquisa("");
            setUtilizadores([]);
            setTipoInscricao(1);
        }
    }

    const montarEstado = (inscricoes) => {
        var disciplinas = [];
        inscricoes.map(inscricao => {
            disciplinas.push(<span className="estado-inscrito">{inscricao.nome}</span>)
        })
        return disciplinas;
    }

    const props = {
        multiple: false,
        onChange: info => {
            setLoading(true);
            var item = new FormData();
            item.append("cursoId", localStorage.getItem("codigo_curso"));
            item.append("ficheiro", info.file);
            //if (localStorage.getItem("escola_id"))
            item.append("organismoId", localStorage.getItem("escola_id"));

            axios({
                method: "post",
                //url: localStorage.getItem("escola_id") ? "/api/gestao-turma-matriculas/adicionar-csv-organismos" : "/api/gestao-turma-matriculas/adicionar-csv",
                url: "/api/gestao-turma-matriculas/adicionar-csv-organismos",
                data: item
            })
                .then(response => {
                    setUtilizadores(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar ficheiro");
                    setLoading(false);
                });
        },
        beforeUpload: file => {
            return false;
        },
        ficheiro
    };

    const radioStyle = {
        display: 'block',
        height: '20px'
    };

    useEffect(() => {
        listarCursos();
        //listarTurmas();
    }, [])

    return (
        <>
            <Drawer
                className="drawer-inscrever"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <Radio.Group name="periodo" className="drawer-associar-filtros" value={tipoInscricao} onChange={handleChangeRadio}>
                        <Radio style={radioStyle} value={1}>Matrícula por pesquisa</Radio>
                        <Radio style={radioStyle} value={2}>Matrícula por importação</Radio>
                    </Radio.Group>
                    {tipoInscricao === 1 && (
                        <>
                            <div className="pesquisar">
                                <Search
                                    placeholder="Coloque o nome, código ou e-mail a pesquisar..."
                                    onChange={e => setPesquisa(e.target.value)}
                                    onSearch={pesquisar}
                                    style={{ width: 400 }}
                                    allowClear={true}
                                    value={pesquisa}
                                />
                            </div>
                            <div className="listagem" style={{ marginTop: 30 }}>
                                {loading ? (
                                    <div className="loading-data">
                                        <div className="loading" />
                                    </div>
                                ) : (
                                    utilizadores.map((utilizador, index) => (
                                        <Card key={index}>
                                            <div className="bloco-info-card-head">
                                                <div className="container-img">
                                                    <img src={utilizador.foto ? utilizador.foto : noimage} alt="Foto" />
                                                </div>
                                                <span className="nome">{utilizador.nome_completo}</span>
                                            </div>
                                            <p>
                                                <strong>Código:</strong> {utilizador.nr_aluno} {utilizador.pendente ? <span className="info-estado-pendente">Registo Pendente</span> : ""}
                                            </p>
                                            <p>
                                                <strong>Email:</strong> {utilizador.email}
                                            </p>
                                            {utilizador.contacto && (
                                                <p>
                                                    <strong>Contacto:</strong> {utilizador.contacto}
                                                </p>
                                            )}
                                            {utilizador.organismo && context.escolas_entidade && (
                                                <p>
                                                    <strong>Organismo:</strong> {utilizador.organismo}
                                                </p>
                                            )}
                                            <p><strong>Matrículas:</strong> {utilizador.inscricoesDisciplina.length ? <span className="lista-inscricoes">{montarEstado(utilizador.inscricoesDisciplina).map(disciplina => disciplina)}</span> : ""}</p>
                                            <div className="card-footer-controlos">
                                                <button className="botao-principal" onClick={() => abrirDrawer(utilizador)}>
                                                    Selecionar
                                                </button>
                                            </div>
                                        </Card>
                                    ))
                                )}
                            </div>
                        </>)}
                    {tipoInscricao === 2 && (
                        <>
                            <Upload {...props} fileList={ficheiro}>
                                <Button disabled={ficheiro.length > 0}>
                                    <i className="fas fa-upload" style={{ marginRight: "10px" }} /> Carregar ficheiro
                                </Button>
                            </Upload>
                            <div className="listagem" style={{ marginTop: 30 }}>
                                {loading ?
                                    <div className="loading-data">
                                        <div className="loading" />
                                    </div>
                                    :
                                    utilizadores.map((utilizador, index) => (
                                        <Card key={index} >
                                            <div className="bloco-info-card-head">
                                                <div className="container-img">
                                                    <img src={utilizador.foto ? utilizador.foto : noimage} alt="Foto" />
                                                </div>
                                                <span className="nome">
                                                    {utilizador.nome_completo}
                                                </span>
                                            </div>
                                            <p><strong>Número:</strong> {utilizador.nr_aluno}</p>
                                            <p><strong>Email:</strong> {utilizador.email}</p>
                                            {utilizador.contacto &&
                                                <p><strong>Contacto:</strong> {utilizador.contacto}</p>}
                                            {(utilizador.organismo && context.escolas_entidade) &&
                                                <p><strong>Organismo:</strong> {utilizador.organismo}</p>}
                                            <p><strong>Matrículas:</strong> {utilizador.inscricoesDisciplina.length ? <span className="lista-inscricoes">{montarEstado(utilizador.inscricoesDisciplina).map(disciplina => disciplina)}</span> : ""}</p>
                                        </Card>
                                    ))}
                            </div>
                        </>)}
                    {tipoInscricao === 3 && (
                        <>
                            <div className="pesquisar">
                                <Select placeholder="Selecionar" className="input-50" allowClear={true} onChange={listarAlunosTurma}>
                                    {turmas.map(turma => (
                                        <Option key={turma.id}>{turma.nome}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="listagem" style={{ marginTop: 30 }}>
                                {loading ? (
                                    <div className="loading-data">
                                        <div className="loading" />
                                    </div>
                                ) : (
                                    utilizadores.map((utilizador, index) => (
                                        <Card key={index}>
                                            <div className="bloco-info-card-head">
                                                <div className="container-img">
                                                    <img src={utilizador.foto ? utilizador.foto : noimage} alt="Foto" />
                                                </div>
                                                <span className="nome">{utilizador.nome_completo}</span>
                                            </div>
                                            <p>
                                                <strong>Código:</strong> {utilizador.nr_aluno} {utilizador.pendente ? <span className="info-estado-pendente">Registo Pendente</span> : ""}
                                            </p>
                                            <p>
                                                <strong>Email:</strong> {utilizador.email}
                                            </p>
                                            {utilizador.contacto && (
                                                <p>
                                                    <strong>Contacto:</strong> {utilizador.contacto}
                                                </p>
                                            )}
                                            {utilizador.organismo && context.escolas_entidade && (
                                                <p>
                                                    <strong>Organismo:</strong> {utilizador.organismo}
                                                </p>
                                            )}
                                            <p><strong>Matrículas:</strong> {utilizador.inscricoesDisciplina.length ? <span className="lista-inscricoes">{montarEstado(utilizador.inscricoesDisciplina).map(disciplina => disciplina)}</span> : ""}</p>
                                            <div className="card-footer-controlos">
                                                <button className="botao-principal" onClick={() => abrirDrawer(utilizador)}>
                                                    Selecionar
                                                </button>
                                            </div>
                                        </Card>
                                    ))
                                )}
                            </div>
                        </>)}
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                    {tipoInscricao === 2 &&
                        <button className="botao-principal" type="primary" disabled={utilizadores.length === 0} onClick={() => setDrawerVisible(true)}>
                            Selecionar
                        </button>
                    }
                </div>
            </Drawer>
            <DrawerTransferenciaListaDisciplinas
                visible={drawerVisible}
                cursos={cursos}
                utilizador={utilizador}
                utilizadores={utilizadores}
                onClose={() => setDrawerVisible(false)}
                onConfirm={(selecionados) => confirmarDrawer(selecionados)}
                onConfirmImport={() => {
                    setDrawerVisible(false);
                    onClose();
                }}
                importacao={tipoInscricao === 2}
                turmaId={turmaSelecionadaId}
            />
        </>
    );
}

export default DrawerInscricao;
