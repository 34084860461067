import React, { Component } from "react";
import { Button, Icon, notification, Tooltip, Modal, Collapse } from "antd";
import InfoAluno from "../InfoAluno/InfoAluno";
import axios from "axios";
import moment from "moment";

const { Panel } = Collapse;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ExcelTabelaFrequencia extends Component {
    state = {
        num: 1,
        total: 0,
        semPeriodos: false,
        mesesPeriodo: [],
        numDias: 0,
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        periodoId: 0,
        mes: "",
        ano: "",
        periodoTitulo: "",
        titulo: "",
        aulas: [],
        /*aulasTotais: [],*/
        participantes: [],
        //LOADING
        iconLoading: false,
        //MODAL LISTAGEM AULAS
        visible: false,
        aulasDia: [],
        dataAula: ""
    }

    componentDidMount() {
        this.total();
    }

    total = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/aulas/total-periodos-frequencia",
                params: {
                    cursoId: this.props.cursoId
                }
            })
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            total: response.data.length,
                            mesesPeriodo: response.data,
                            numDias: response.data[0].dias,
                            //periodoId: response.data[0].periodoId,
                            mes: response.data[0].mes,
                            ano: response.data[0].ano,
                            periodoTitulo: response.data[0].titulo,
                            titulo: `${response.data[0].mes.charAt(0).toUpperCase() + response.data[0].mes.slice(1)} ${response.data[0].ano} / ${response.data[0].titulo}`,
                            dt_inicio: response.data[0].dt_inicio,
                            dt_fim: response.data[0].dt_fim,
                        }, () => {
                            this.listar();
                            //this.listarTotais();
                            this.listarParticipantes();
                        })
                    }
                    else {
                        this.setState({
                            semPeriodos: true,
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                });
        });
    }

    listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-frequencia",
            params: {
                cursoId: this.props.cursoId,
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulas: response.data,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    listarParticipantes = () => {
        axios({
            method: "get",
            url: "/api/aulas/participantes",
            timeout: 60 * 10 * 1000,
            params: {
                cursoid: this.props.cursoId,
                mostrarTodos: true
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    anteriorProximo = (num) => {
        var periodo = this.state.mesesPeriodo[num - 1];
        this.setState({
            num,
            numDias: periodo.dias,
            mes: periodo.mes,
            ano: periodo.ano,
            periodoTitulo: periodo.titulo,
            titulo: `${periodo.mes.charAt(0).toUpperCase() + periodo.mes.slice(1)} / ${periodo.titulo}`,
            dt_inicio: periodo.dt_inicio,
            dt_fim: periodo.dt_fim,
            iconLoading: true
        }, () => this.listar())
    }

    montarDias = () => {
        var dias = [];
        for (let i = 0; i < this.state.numDias; i++) {
            dias.push(<td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9" sdnum="1033;1033;M/D/YYYY"><font face="Cambria" color="#000000"><br />{(('0' + (i + 1)).slice(-2))}</font></td>)
        }

        return dias;
    }

    montarColunasDias = (participanteId, participanteAtivo, participanteData) => {
        let dias = [];
        for (let i = 0; i < this.state.numDias; i++) {
            const aulas = this.state.aulas.filter(x => moment(x.dt_inicio).isSame(`${this.state.ano}-${(('0' + (moment().month(this.state.mes).format("M"))).slice(-2))}-${(('0' + (i + 1)).slice(-2))}`));
            if (aulas.length > 0) {
                let presencas = 0;
                let faltas = 0;
                let faltasJustificadas = 0;
                let aulasPresencas = [];
                let aulasFaltas = [];
                let aulasFaltasJustificadas = [];
                const ativo = participanteAtivo || !participanteAtivo && moment(participanteData).isSameOrAfter(moment(aulas[0].dt_inicio));

                if (ativo) {
                    aulas.map(aula => {
                        const presencasAlunos = aula.presencas ? JSON.parse(aula.presencas) : [];
                        const participante = presencasAlunos.find(x => x.id === participanteId);
                        if ((aula.presenca || !aula.presenca && presencasAlunos.length && presencasAlunos.find(x => x.id === participanteId && x.estado === "P")) && participante != undefined && !participante?.bloqueado) {
                            aulasPresencas.push(<><span>{aula.titulo}: Presença</span><br /></>)
                            presencas++;
                        }
                        if ((aula.falta || !aula.falta && presencasAlunos.length && presencasAlunos.find(x => x.id === participanteId && x.estado === "F")) && participante != undefined && !participante?.bloqueado) {
                            aulasFaltas.push(<><span>{aula.titulo}: Falta</span><br /></>)
                            faltas++;
                        }
                        if (!aula.presenca && !aula.falta && presencasAlunos.length && presencasAlunos.find(x => x.id === participanteId && x.estado === "FJ")) {
                            aulasFaltasJustificadas.push(<><span>{aula.titulo}: Falta justificada</span><br /></>)
                            faltasJustificadas++;
                        }
                    });
                    dias.push(<td className="tabela-celula" style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#eaeaea" sdnum="1033;0;@" onClick={() => this.abrirModal(aulas, participanteId)}>
                        {presencas > 0 ?
                            <font face="Cambria" style={{ color: "green" }}>
                                <Tooltip placement="bottom"
                                    title={<span>
                                        {aulasPresencas.map(presenca => presenca)}
                                    </span>}
                                >
                                    <strong>{presencas > 1 ? `P (${presencas})` : "P"}</strong>
                                </Tooltip>
                                <br />
                            </font>
                            : null}
                        {faltas > 0 ?
                            <font face="Cambria" style={{ color: "red" }}>
                                <Tooltip placement="bottom"
                                    title={<span>
                                        {aulasFaltas.map(falta => falta)}
                                    </span>}
                                >
                                    <strong>{faltas > 1 ? `F (${faltas})` : "F"}</strong>
                                </Tooltip>
                                <br />
                            </font>
                            : null}
                        {faltasJustificadas > 0 ?
                            <font face="Cambria" style={{ color: "orange" }}>
                                <Tooltip placement="bottom"
                                    title={<span>
                                        {aulasFaltasJustificadas.map(falta => falta)}
                                    </span>}
                                >
                                    <strong>{faltasJustificadas > 1 ? `FJ (${faltasJustificadas})` : "FJ"}</strong>
                                </Tooltip>
                                <br />
                            </font>
                            : null}
                    </td>)
                }
                else
                    dias.push(<td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#FFFFFF" sdnum="1033;0;@"><font face="Cambria"><br /></font></td>)
            }
            else
                dias.push(<td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#FFFFFF" sdnum="1033;0;@"><font face="Cambria"><br /></font></td>)
        }

        return dias;
    }

    calcularTotalPresencas = (participanteId) => {
        let total = 0;

        this.state.aulas.map(aula => {
            const presencas = aula.presencas ? JSON.parse(aula.presencas) : [];
            const participante = presencas.find(x => x.id === participanteId);
            if ((aula.presenca || !aula.presenca && presencas.length && presencas.find(x => x.id === participanteId && x.estado === "P")) && participante != undefined && !participante?.bloqueado)
                total++;
        });

        return total;
    }

    calcularTotalFaltas = (participanteId) => {
        let total = 0;

        this.state.aulas.map(aula => {
            const presencas = aula.presencas ? JSON.parse(aula.presencas) : [];
            const participante = presencas.find(x => x.id === participanteId);
            if (participante?.presente) {
                if ((aula.falta || !aula.falta && presencas.length && presencas.find(x => x.id === participanteId && x.estado === "F")) && participante != undefined && !participante?.bloqueado)
                    total++;
            }
            else {
                if ((aula.falta || !aula.falta && presencas.length && presencas.find(x => x.id === participanteId && x.estado === "F")) && participante != undefined && !participante?.bloqueado)
                    total++;
            }
        });

        return total;
    }

    abrirModal = (aulasDia, participanteId) => {
        this.setState({
            visible: true,
            participanteId,
            aulasDia,
            keys: [],
            dataAula: moment(aulasDia[0].dt_inicio).format("DD/MM/YYYY")
        })
    }

    fecharModal = () => {
        this.setState({
            visible: false
        })
        this.anteriorProximo(this.state.num);
    }

    render() {
        const {
            num,
            semPeriodos,
            mesesPeriodo,
            //PERIODO
            periodoTitulo,
            titulo,
            aulas,
            participantes,
            //LOADING
            iconLoading,
            //MODAL LISTAGEM AULAS
            visible,
            participanteId,
            aulasDia,
            keys,
            dataAula
        } = this.state;

        const montarTitulo = (aula) => {
            return (
                <div className="bloco-titulo-frequencia">
                    <span className="titulo-aula">{aula.titulo}</span>
                    <span className="data-aula">{moment(aula.dt_inicio).format("DD/MM/YYYY")}<i className="fas fa-circle"></i>{aula.hr_inicio} - {aula.hr_termino}</span>
                </div>
            )
        }

        return (
            <>
                <div>
                    <div className="bloco-controlos-frequencia">
                        {!semPeriodos ?
                            <div>
                                <Button className='botao-periodo botao-periodo-anterior' disabled={num === 1} style={{ color: "rgba(0, 0, 0, 0.8)" }} onClick={() => this.anteriorProximo(num - 1)}>
                                    <Icon type="left" />
                                </Button>
                                <span>{titulo}</span>
                                <Button className='botao-periodo botao-periodo-proximo' disabled={num === mesesPeriodo.length} style={{ color: "rgba(0, 0, 0, 0.8)" }} onClick={() => this.anteriorProximo(num + 1)}>
                                    <Icon type="right" />
                                </Button>
                            </div>
                            : null}
                    </div>
                    <table cellSpacing={0} border={0} style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={80} align="center" valign="bottom" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000">ORDEM</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000">ALUNOS</font></b></td>
                                {this.montarDias().map(dia => dia)}
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="bottom" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000">TOTAL PRESENÇAS<br />({periodoTitulo})</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="bottom" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000">TOTAL FALTAS<br />({periodoTitulo})</font></b></td>
                            </tr>
                            {participantes.map((participante, index) => (
                                <tr>
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={15} align="center" valign="middle" bgcolor="#FFFFFF" sdval={2} sdnum="1033;0;00"><b><font face="Cambria">{(('0' + (index + 1)).slice(-2))}</font></b></td>
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="left" valign="middle" bgcolor="#FFFFFF"><font face="Cambria">{participante.nome.split(" ").length > 1 ? `${participante.nome.split(" ")[0]} ${participante.nome.split(" ")[participante.nome.split(" ").length - 1]}` : participante.nome}<br /></font></td>
                                    {this.montarColunasDias(participante.id, participante.ativo, participante.dataAtualizado).map(dia => dia)}
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#c9ffce" sdnum="1033;0;00"><font face="Cambria">{this.calcularTotalPresencas(participante.id)}<br /></font></td>
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#FFC7CE" sdnum="1033;0;00"><font face="Cambria">{this.calcularTotalFaltas(participante.id)}<br /></font></td>
                                </tr>
                            ))}
                        </tbody></table>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
                <Modal
                    title={`Aulas lecionadas no dia ${dataAula}`}
                    visible={visible}
                    maskClosable={false}
                    onCancel={this.fecharModal}
                    className="modal-listagem-aulas"
                    footer={[
                        <Button key="back" onClick={this.fecharModal}>
                            Voltar
                        </Button>,
                    ]}
                >
                    <div className="collapse-info-diario collapse-frequencia">
                        <Collapse onChange={(keys) => this.setState({ keys })} activeKey={keys} defaultActiveKey={[]} className="collapse-campos">
                            {aulasDia.map(aula => (
                                <Panel header={montarTitulo(aula)} key={aula.id} >
                                    <InfoAluno
                                        aulaId={aula.id}
                                        participanteId={participanteId}
                                    />
                                </Panel>
                            ))}
                        </Collapse>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ExcelTabelaFrequencia;