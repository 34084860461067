import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Drawer,
    Icon,
    Form,
    Input,
    InputNumber,
    Select,
    Collapse,
    notification,
    Radio,
    Checkbox
} from "antd";
import axios from "axios";
import uuid from 'react-uuid'

const { Panel } = Collapse;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerAvalicao = ({ estruturaId, pautaId, bloqueada, ordem, visible, onClose, atualizar, form }) => {
    const [epocas, setEpocas] = useState([]);
    const [formData, setFormData] = useState({
        epoca: undefined,
        nome: "",
        legenda: "",
        escala: false,
        notaMinima: 0,
        notaMaxima: 0,
        notaAprovacao: 0,
        casasDecimais: 2,
        ordem,
        visivelAluno: true,
        visivelRelatorios: false,
        visivelExportacoes: false,
        campoCalculado: "MANUAL",
        tipoFormula: "CALCULO",
        provaFinal: false,
        operador: "MAIOR"
    })
    const [escalas, setEscalas] = useState([])
    const [niveis, setNiveis] = useState([])
    const [avaliacoesSelecionadas, setAvaliacoesSelecionadas] = useState([])
    const [avaliacoes, setAvaliacoes] = useState([])
    const [permissao, setPermissao] = useState(false)
    const [iconLoading, setIconLoading] = useState(false)

    const { getFieldDecorator } = form;

    const handleChangeId = (value, index) => {
        let _avaliacoesSelecionadas = avaliacoesSelecionadas;
        _avaliacoesSelecionadas[index].id = value;

        setAvaliacoesSelecionadas([..._avaliacoesSelecionadas]);
    };

    const handleChangePeso = (event, index) => {
        let _avaliacoesSelecionadas = avaliacoesSelecionadas;
        _avaliacoesSelecionadas[index].peso = event.target.value;

        setAvaliacoesSelecionadas([..._avaliacoesSelecionadas]);
    };

    const handleChangeEscalao = (event, index) => {
        let _escalas = escalas;
        _escalas[index].escalao = event.target.value;

        setEscalas([..._escalas]);
    };

    const handleChangeNotaMaxima = (nota, index) => {
        let _escalas = escalas;
        _escalas[index].notaMaxima = nota;

        setEscalas([..._escalas]);
    };

    const handleChangeNotaMinima = (nota, index) => {
        let _escalas = escalas;
        _escalas[index].notaMinima = nota;

        setEscalas([..._escalas]);
    };

    const carregarEpocas = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/listar-epocas",
            params: {
                estruturaId: estruturaId
            }
        })
            .then(response => {
                setEpocas(response.data);
            })
            .catch(error => {

            });
    }

    const carregarAvaliacoes = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/carregar-avaliacoes",
            params: {
                disciplinaId: localStorage.getItem("codigo_curso"),
                ordem: formData.ordem
            }
        })
            .then(response => {
                setAvaliacoes(response.data);
            })
            .catch(error => {

            });
    }

    const validarPermissao = () => {
        axios({
            method: "get",
            url: "/api/turma/user-permissao",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setPermissao(response.data)
            })
            .catch(error => {

            });
    }

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/detalhe",
            params: {
                pautaId
            }
        })
            .then(response => {
                setEscalas(JSON.parse(response.data.escalas))
                setFormData({ ...response.data });

                if (response.data.campoCalculado) {
                    let tempAv = [];
                    let tempPesos = [];

                    if (response.data.avaliacoes) {
                        tempAv = response.data.avaliacoes?.split(";");
                    }

                    if (response.data.pesos) {
                        tempPesos = response.data.pesos?.split(";");
                    }

                    const avs = tempAv.map(av => {
                        return {
                            id: parseFloat(av),
                            peso: parseFloat(tempPesos[tempAv.findIndex(x => x === av)])
                        }
                    })
                    setAvaliacoesSelecionadas(avs);

                    if (response.data.niveis)
                        setNiveis(JSON.parse(response.data.niveis))
                }
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("id_periodo", formData.epoca);
                item.append("id_disciplina", localStorage.getItem("codigo_curso"));
                item.append("nome", formData.nome);
                if (formData.legenda)
                    item.append("legenda", formData.legenda);
                item.append("ordem", formData.ordem);
                item.append("escala", formData.escala);
                if (escalas)
                    item.append("escalas", JSON.stringify(escalas));
                if (formData.notaMinima)
                    item.append("nota_minima", formData.notaMinima);
                if (formData.notaMaxima)
                    item.append("nota_maxima", formData.notaMaxima);
                if (formData.notaAprovacao)
                    item.append("nota_aprovacao", formData.notaAprovacao);
                item.append("casas_decimais", formData.casasDecimais);
                item.append("visivel_aluno", formData.visivelAluno);
                item.append("visivel_relatorios", formData.visivelRelatorios);
                item.append("visivel_exportacoes", formData.visivelExportacoes);
                item.append("tipo_formula", formData.tipoFormula);
                item.append("campo_calculado", formData.campoCalculado === "FORMULA");
                if (formData.campoCalculado === "FORMULA") {
                    item.append("operador", formData.operador);
                    item.append("avaliacoes", JSON.stringify(avaliacoesSelecionadas));
                    item.append("niveis", JSON.stringify(niveis));
                }

                axios({
                    method: "post",
                    url: "/api/aproveitamento/adicionar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        setIconLoading(false);
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false);
                    });
            }
        })
    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("id", pautaId);
                item.append("id_periodo", formData.epoca);
                item.append("id_disciplina", localStorage.getItem("codigo_curso"));
                item.append("nome", formData.nome);
                if (formData.legenda)
                    item.append("legenda", formData.legenda);
                item.append("ordem", formData.ordem);
                item.append("escala", formData.escala);
                if (escalas)
                    item.append("escalas", JSON.stringify(escalas));
                if (formData.notaMinima)
                    item.append("nota_minima", formData.notaMinima);
                if (formData.notaMaxima)
                    item.append("nota_maxima", formData.notaMaxima);
                if (formData.notaAprovacao)
                    item.append("nota_aprovacao", formData.notaAprovacao);
                item.append("casas_decimais", formData.casasDecimais);
                item.append("visivel_aluno", formData.visivelAluno);
                item.append("visivel_relatorios", formData.visivelRelatorios);
                item.append("visivel_exportacoes", formData.visivelExportacoes);
                item.append("tipo_formula", formData.tipoFormula);
                item.append("campo_calculado", formData.campoCalculado === "FORMULA");
                if (formData.campoCalculado === "FORMULA") {
                    item.append("operador", formData.operador);
                    item.append("avaliacoes", JSON.stringify(avaliacoesSelecionadas));
                    item.append("niveis", JSON.stringify(niveis));
                }

                axios({
                    method: "put",
                    url: "/api/aproveitamento/alterar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        setIconLoading(false);
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false);
                    });
            }
        })
    }

    const afterVisibleChange = (aberto) => {
        form.resetFields();

        if (aberto) {
            if (pautaId > 0)
                detalhe();
            else {
                setFormData({
                    epoca: undefined,
                    nome: "",
                    legenda: "",
                    escala: false,
                    notaMinima: 0,
                    notaMaxima: 0,
                    notaAprovacao: 0,
                    casasDecimais: 2,
                    ordem,
                    visivelAluno: true,
                    visivelRelatorios: false,
                    visivelExportacoes: false,
                    campoCalculado: "MANUAL",
                    tipoFormula: "CALCULO",
                    provaFinal: false,
                    operador: "MAIOR"
                });
                setEscalas([]);
                setAvaliacoesSelecionadas([])
                setIconLoading(false);
            }

            carregarEpocas();
            //carregarAvaliacoes();
        }
    }

    useEffect(() => {
        validarPermissao();
    }, []);

    useEffect(() => {
        carregarAvaliacoes();
    }, [formData.ordem]);

    return (
        <Drawer
            className="drawer-add-cursos"
            //title="Período"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias listagem-avaliacoes" layout="horizontal">
                    <div className="bloco">
                        {!formData.mediaDisciplinas &&
                            <Form.Item label="Periodo Letivo">
                                {getFieldDecorator("epoca", {
                                    initialValue: formData.epoca,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(
                                    <Select placeholder="Selecionar" name="epoca" onChange={(epoca) => setFormData({ ...formData, epoca }) }>
                                        {epocas.map(epoca => (
                                            <Option key={epoca.id} value={epoca.id}>{epoca.titulo}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>}
                        <Form.Item label="Nome da pauta">
                            {getFieldDecorator("nome_pauta", {
                                initialValue: formData.nome,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="nome_pauta" onChange={(e) => setFormData({ ...formData, nome: e.target.value })} />)}
                        </Form.Item>
                        <Form.Item label="Legenda">
                            {getFieldDecorator("legenda", {
                                initialValue: formData.legenda
                            })(<Input name="legenda" onChange={(e) => setFormData({ ...formData, legenda: e.target.value })} />)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("escala", {
                                initialValue: formData.escala,
                                valuePropName: 'checked'
                            })(
                                <Checkbox name="escala" onChange={(e) => setFormData({ ...formData, escala: e.target.checked })}>
                                    Escalas
                                </Checkbox>
                            )}
                        </Form.Item>
                        <div className="bloco">
                            {formData.escala &&
                                <div className="bloco bloco-lista-avaliacao-opcoes">
                                    <h3 className="titulo-separador">
                                        Escalas
                                        <button className="botao-principal" onClick={(event) => { event.stopPropagation(); setEscalas([...escalas, { id: uuid(), escalao: "", notaMaxima: 0, notaMinima: 0 }]) }}>
                                            <Icon type="plus" />
                                        </button>
                                    </h3>
                                    {escalas && (
                                        <div className="lista-avaliacao-opcoes">
                                            {escalas.map((escala, index) => (
                                                <div key={escala.id} className="avaliacao-opcao">
                                                    <div className="bloco-inputs">
                                                        <Form.Item label="Escalão">
                                                            {getFieldDecorator(`escalao${index}`, {
                                                                initialValue: escala.escalao,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Campo obrigatório"
                                                                    }
                                                                ]
                                                            })(<Input name="escalao" onChange={(event) => handleChangeEscalao(event, index)} />)}
                                                        </Form.Item>
                                                        <Form.Item label="Nota mínima">
                                                            {getFieldDecorator(`nota_minima${index}`, {
                                                                initialValue: escala.notaMinima,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Campo obrigatório"
                                                                    }
                                                                ]
                                                            })(<InputNumber name="nota_minima" onChange={(event) => handleChangeNotaMinima(event, index)} />)}
                                                        </Form.Item>
                                                        <Form.Item label="Nota máxima">
                                                            {getFieldDecorator(`nota_maxima${index}`, {
                                                                initialValue: escala.notaMaxima,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Campo obrigatório"
                                                                    }
                                                                ]
                                                            })(<InputNumber name="nota_maxima" onChange={(event) => handleChangeNotaMaxima(event, index)} />)}
                                                        </Form.Item>
                                                    </div>
                                                    <button className="botao-excluir excluir-av" onClick={() => setEscalas([...escalas.filter(x => x.id !== escala.id)])}>
                                                        <Icon type="delete" />
                                                    </button>
                                                </div>
                                            ))}
                                            {!escalas.length && "Sem dados..."}
                                        </div>)}
                                </div>
                            }
                        </div>
                        <Form.Item label="Nota mínima">
                            {getFieldDecorator("nota_minima", {
                                initialValue: formData.notaMinima
                            })(<InputNumber name="notaMinima" onChange={(notaMinima) => setFormData({ ...formData, notaMinima })} />)}
                        </Form.Item>
                        <Form.Item label="Nota máxima">
                            {getFieldDecorator("nota_maxima", {
                                initialValue: formData.notaMaxima
                            })(<InputNumber name="notaMaxima" onChange={(notaMaxima) => setFormData({ ...formData, notaMaxima })} />)}
                        </Form.Item>
                        <Form.Item label="Nota de aprovação">
                            {getFieldDecorator("nota_aprovacao", {
                                initialValue: formData.notaAprovacao
                            })(<InputNumber name="notaAprovacao" onChange={(notaAprovacao) => setFormData({ ...formData, notaAprovacao })} />)}
                        </Form.Item>
                        <Form.Item label="Casas decimais">
                            {getFieldDecorator("casas_decimais", {
                                initialValue: formData.casasDecimais
                            })(
                                <Select placeholder="Selecionar" onChange={(casasDecimais) => setFormData({ ...formData, casasDecimais })}>
                                    <Option value={0}>0</Option>
                                    <Option value={1}>1</Option>
                                    <Option value={2}>2</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Ordem">
                            {getFieldDecorator("ordem", {
                                initialValue: formData.ordem,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="ordem" onChange={(e) => setFormData({ ...formData, ordem: e.target.value })} />)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("visivel_aluno", {
                                initialValue: formData.visivelAluno,
                                valuePropName: 'checked'
                            })(
                                <Checkbox name="visivel_aluno" onChange={(e) => setFormData({ ...formData, visivelAluno: e.target.checked })}>
                                    Visível para os alunos
                                </Checkbox>
                            )}
                        </Form.Item>
                        {permissao && (
                            <>
                                <Form.Item>
                                    {getFieldDecorator("visivel_relatorios", {
                                        initialValue: formData.visivelRelatorios,
                                        valuePropName: 'checked'
                                    })(
                                        <Checkbox name="visivel_relatorios" onChange={(e) => setFormData({ ...formData, visivelRelatorios: e.target.checked })}>
                                            Visível nos relatórios
                                        </Checkbox>
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator("visivel_exportacoes", {
                                        initialValue: formData.visivelExportacoes,
                                        valuePropName: 'checked'
                                    })(
                                        <Checkbox name="visivel_exportacoes" onChange={(e) => setFormData({ ...formData, visivelExportacoes: e.target.checked })}>
                                            Visível nas exportações (Atas, Ficha individual e Excel)
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            </>
                        )}
                        {/*<Form.Item>*/}
                        {/*    {getFieldDecorator("campo_calculado", {*/}
                        {/*        initialValue: formData.campoCalculado,*/}
                        {/*        rules: [*/}
                        {/*            {*/}
                        {/*                required: true,*/}
                        {/*                message: "Campo obrigatório"*/}
                        {/*            }*/}
                        {/*        ]*/}
                        {/*    })(*/}
                        {/*        <Radio.Group name="operador" onChange={(e) => setFormData({ ...formData, campoCalculado: e.target.value })}>*/}
                        {/*            <Radio value="MANUAL"><span className="texto-com-marca">Manual <i className="marca-campo-normal medio"></i></span></Radio>*/}
                        {/*            <Radio value="FORMULA"><span className="texto-com-marca">Fórmula <i className="marca-campo-calculado medio"></i></span></Radio>*/}
                        {/*        </Radio.Group>*/}
                        {/*    )}*/}
                        {/*</Form.Item>*/}
                    </div>
                    {formData.campoCalculado === "FORMULA" && (
                        <>
                            <div className="bloco">
                                {/*{permissao && (*/}
                                {/*    <Form.Item>*/}
                                {/*        {getFieldDecorator("media_disciplinas", {*/}
                                {/*            initialValue: formData.tipoFormula*/}
                                {/*        })(*/}
                                {/*            <Radio.Group name="operador" onChange={(e) => { setFormData({ ...formData, tipoFormula: e.target.value, operador: e.target.value === "MEDIA" ? "MEDIA" : formData.operador }); setAvaliacoesSelecionadas([{ id: undefined, peso: "" }]) }}>*/}
                                {/*                <Radio value="CALCULO">Cálculo</Radio>*/}
                                {/*                <Radio value="MEDIA">É média da seguinte nota para todas as disciplinas</Radio>*/}
                                {/*            </Radio.Group>*/}
                                {/*        )}*/}
                                {/*    </Form.Item>*/}
                                {/*)}*/}
                                {formData.tipoFormula === "CALCULO" ? (
                                    <>
                                        <Form.Item label="Operador">
                                            {getFieldDecorator("operador", {
                                                initialValue: formData.operador,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(
                                                <Radio.Group name="operador" onChange={(e) => setFormData({ ...formData, operador: e.target.value })}>
                                                    <Radio value="MAIOR">Maior</Radio>
                                                    <Radio value="MEDIA">Média</Radio>
                                                    <Radio value="SOMA">Soma</Radio>
                                                </Radio.Group>
                                            )}
                                        </Form.Item>
                                        <div className="bloco bloco-lista-avaliacao-opcoes">
                                            <h3 className="titulo-separador">
                                                Notas
                                                {!formData.mediaDisciplinas &&
                                                    <button className="botao-principal" onClick={(event) => { event.stopPropagation(); setAvaliacoesSelecionadas([...avaliacoesSelecionadas, { id: undefined, peso: "" }]) }}>
                                                        <Icon type="plus" />
                                                    </button>}
                                            </h3>
                                            <div className="lista-avaliacao-opcoes">
                                                {avaliacoesSelecionadas.map((avaliacaoSelecionada, index) => (
                                                    <div key={`${avaliacaoSelecionada}${index}`} className="avaliacao-opcao">
                                                        <div className="bloco-inputs">
                                                            <Form.Item label="Nota">
                                                                {getFieldDecorator(`avalicao${index}`, {
                                                                    initialValue: avaliacaoSelecionada.id,
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: "Campo obrigatório"
                                                                        }
                                                                    ]
                                                                })(
                                                                    <Select placeholder="Selecionar" onChange={(value) => handleChangeId(value, index)}>
                                                                        {avaliacoes.map(avaliacao => (
                                                                            <Option key={avaliacao.id} value={avaliacao.id}>{avaliacao.nome}</Option>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            </Form.Item>
                                                            {(formData.operador === "MEDIA" && !formData.mediaDisciplinas) &&
                                                                <Form.Item label="Ponderação">
                                                                    {getFieldDecorator(`peso${index}`, {
                                                                        initialValue: avaliacaoSelecionada.peso,
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: "Campo obrigatório"
                                                                            }
                                                                        ]
                                                                    })(<Input name="peso" onChange={(event) => handleChangePeso(event, index)} />)}
                                                                </Form.Item>}
                                                        </div>
                                                        <button className="botao-excluir excluir-av" onClick={() => setAvaliacoesSelecionadas([...avaliacoesSelecionadas.filter(x => x.id !== avaliacaoSelecionada.id)])}>
                                                            <Icon type="delete" />
                                                        </button>
                                                    </div>
                                                ))}
                                                {!avaliacoesSelecionadas.length && "Sem dados..."}
                                            </div>
                                        </div>
                                    </>
                                )
                                    :
                                    (
                                        <div className="lista-avaliacao-opcoes">
                                            {avaliacoesSelecionadas.map((avaliacaoSelecionada, index) => (
                                                <div key={`${avaliacaoSelecionada}${index}`} className="avaliacao-opcao">
                                                    <div className="bloco-inputs">
                                                        <Form.Item label="Nota">
                                                            {getFieldDecorator(`avalicao${index}`, {
                                                                initialValue: avaliacaoSelecionada.id,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Campo obrigatório"
                                                                    }
                                                                ]
                                                            })(
                                                                <Select placeholder="Selecionar" onChange={(value) => handleChangeId(value, index)}>
                                                                    {avaliacoes.map(avaliacao => (
                                                                        <Option key={avaliacao.id} value={avaliacao.id}>{avaliacao.nome}</Option>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                            </div>
                        </>)
                    }
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </button>
                <button className="botao-principal" onClick={pautaId > 0 ? alterar : adicionar} disabled={iconLoading} type="primary">
                    {iconLoading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
        </Drawer>
    );
}

const FormDrawerAvalicao = Form.create({ name: "drawer-avaliacao" })(DrawerAvalicao);

export default FormDrawerAvalicao;