import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { Row, Col, Table } from "antd";
import { GeralContext } from "../ContextProvider";
import { GlobalContext } from "../../GlobalState";
import Card from "../Participantes/Participante";
import axios from "axios";
import noimage from "../../images/noimage.png";

const VisaoGeral = ({ match }) => {
    const [professores, setProfessores] = useState([]);
    const [disciplinas, setDisciplinas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const context = useContext(GeralContext);
    const globalContext = useContext(GlobalContext);

    const listarProfessores = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/turma/lista-professores",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setProfessores(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    const listarDisciplinas = () => {
        setLoadingTable(true);
        axios({
            method: "get",
            url: "/api/turma/listar-disciplinas",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                var disciplinas = [];

                response.data.map(disciplina => {
                    disciplinas.push({
                        key: disciplina.id,
                        id: disciplina.id,
                        disciplina: !disciplina.agrupado ? `${disciplina.codigo} - ${disciplina.nome}` : disciplina.nome,
                        nome: disciplina.nome,
                        professores: disciplina.professores
                    });
                });

                setLoadingTable(false);
                setDisciplinas(disciplinas);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoadingTable(false);
            });
    };

    const montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<div className="controlos controlos-fundo">
                <div className="controlos-opcoes">
                    <Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}>
                        Voltar
                    </Link>
                </div>
            </div>);
        }
    }

    const onRowClick = (nome, id) => {
        var turmaId = localStorage.getItem("codigo_curso");
        localStorage.setItem("codigo_curso", id);
        localStorage.setItem("opcao_voltar", JSON.stringify({
            link_voltar_curso: `/detalhe-turma/${match.params.turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/visao-geral`,
            id_curso: turmaId
        }));

        globalContext.atualizarState({
            lista_modulos: []
        });

        setRedirectLink(`/detalhe-turma/${nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/modulos`);
        setRedirect(true);
    }

    useEffect(() => {
        listarProfessores();
        listarDisciplinas();
    }, [])

    const columns = [
        {
            title: "Disciplina",
            dataIndex: "disciplina",
            className: "td-curso"
        },
        {
            title: "Coordenadores / Professores",
            dataIndex: "professores",
            className: "td-300"
        }
    ];

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <div className="container container-body">
            {loading ? (
                <div className="loading-data">
                    <div className="loading" />
                </div>
            ) : (
                <div className="turma-visao-geral">
                    {montarBotaoVoltar()}
                    <div>
                        <Row gutter={16}>
                            <Col span={5}>
                                <div className="bloco-v2">
                                    <div className="item">
                                        <div className="lista-professores">
                                            {professores.map(professor => (
                                                <Card
                                                    {...professor}
                                                    key={professor.id}
                                                    dt_fechado={context.dt_fechado}
                                                    dt_inicio={context.dt_inicio}
                                                    dt_fim={context.dt_fim}
                                                    fechado={context.fechado}
                                                    perfil={professor.coordenador ? "Coordenador" : "Professor"}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={19}>
                                <div className="bloco-v2 bloco-cursos">
                                    <Table
                                        id="disciplinas"
                                        loading={{
                                            spinning: loadingTable,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        columns={columns}
                                        dataSource={disciplinas}
                                        pagination={false}
                                        locale={{ emptyText: "Não existem dados!" }}
                                        rowClassName="clickeble-row"
                                        onRow={(record, index) => {
                                            return {
                                                onClick: () => onRowClick(record.nome, record.id)
                                            };
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </div>
    );
}
export default VisaoGeral;
