import React, { Component } from "react";
import { Form } from "antd";
import { Link } from "react-router-dom";
import Footer from "../../Geral/Footer";
import MsgSecundaria from "../../Geral/_Aux/MsgSecundaria";
import LazyLoad from "react-lazy-load";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";

class RegistoConclusao extends Component {
    static contextType = GlobalContext;

    state = {
        logoEntidade: "",
        nomeEntidade: ""
    };

    render() {
        return (
            <>
                <div className="pagina-erro">
                    <div className="hero">
                        {/*<LazyLoad offsetVertical={200}>*/}
                        {/*    <img src={require("../../images/wave_hero_user.svg")} />*/}
                        {/*</LazyLoad>*/}
                        <div className="container" style={{ marginTop: 80 }}>
                            <div className="pagina-erro-header">
                                <div className="container-img">
                                    <img src={require("../../images/celebrate.svg")} />
                                </div>
                            </div>
                            <div className="pagina-erro-content">
                                <MsgSecundaria texto="Parabéns por ter criado conta" style={{ fontSize: 32, color: "white" }} />
                                <MsgSecundaria texto="Boa aprendizagem!" />
                                <Link
                                    to="/login"
                                    className="botao-principal"
                                    onClick={() => localStorage.removeItem("codigo_utilizador")}
                                >
                                    Ir para a STEPFORMA
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const FormRegistoConclusao = Form.create({ name: "registo-conclusao" })(RegistoConclusao);

export default FormRegistoConclusao;
