import React, { Component } from "react";
import { Link, Redirect, matchPath } from "react-router-dom";
import Header from "../../User/Header";
import { Icon, Popover, notification, Modal, Button } from "antd";
import DrawerCalendarioAtividade from "./_Aux/DrawerCalendarioAtividade";
import { Calendar, momentLocalizer } from "react-big-calendar";
import axios from "axios";
import moment from "moment";

import ModalDetalhe from "./Modals/Detalhe";

const localizer = momentLocalizer(moment);

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Detalhe extends Component {
    state = {
        dataCalendario: new Date(),
        vista: "month",
        inicio: moment().startOf("week").format("YYYY/MM/DD"),
        termino: moment().endOf("week").format("YYYY/MM/DD"),
        aulas: [],
        calendarioId: 0,
        nome: "",
        edicao: "",
        tipo: [],
        nr_modulos: 0,
        inicioCal: "",
        terminoCal: "",
        listaMeses: [],
        atividades: [],
        periodos: [],
        hoverId: 0,
        //DRAWER
        atividadeId: 0,
        visible: false,
        //MODAL
        info: "",
        visibleModalDetalhe: false
    };

    UNSAFE_componentWillMount() {
        this.carregarCalendarioInfo();
    }

    carregarCalendarioInfo = () => {
        axios({
            method: "get",
            url: "api/gestao-calendario/calendario-info",
            params: {
                calendarioCode: this.props.match.params.code
            }
        })
            .then(response => {
                const info = response.data;

                const dataInicio = moment(info.inicio);
                const dataTermino = moment(info.termino);
                var listaMeses = [];

                if (dataInicio < dataTermino) {
                    var data = dataInicio.startOf("month");

                    while (data < dataTermino.endOf("month")) {
                        listaMeses.push({
                            mes: data.month(),
                            ano: data.format("YYYY")
                        });
                        data.add(1, "month");
                    }
                }

                const t = JSON.parse(info.tipo);

                this.setState(
                    {
                        calendarioId: info.id,
                        edicao: info.edicao,
                        nome: info.nome,
                        tipo: JSON.parse(info.tipo),
                        nr_modulos: info.nr_modulos,
                        inicioCal: moment(info.inicio).format("DD/MM/YYYY"),
                        terminoCal: moment(info.termino).format("DD/MM/YYYY"),
                        listaMeses
                    },
                    () => {
                        this.listar();
                        this.listarPeriodos();
                    }
                );
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listar = () => {
        axios({
            method: "get",
            url: "/api/gestao-calendario-atividades",
            params: {
                calendarioId: this.state.calendarioId,
                inicio: this.state.inicio,
                termino: this.state.termino
            }
        })
            .then(response => {
                const atividades = response.data.map(atividade => {
                    return {
                        id: atividade.id,
                        title: atividade.nome,
                        start: new Date(atividade.inicio),
                        end: new Date(atividade.termino),
                        cor: atividade.cor
                    };
                });

                this.setState({
                    atividades,
                    iconLoading: false
                });
            })
            .catch(error => {});
    };

    listarOndeExiste = (toolbar, tipo) => {
        axios({
            method: "get",
            url: "/api/gestao-calendario-atividades",
            params: {
                calendarioId: this.state.calendarioId,
                inicio: this.state.inicio,
                termino: this.state.termino,
                tipo
            }
        })
            .then(response => {
                if (response.data === "ANTERIOR") {
                    openNotificationWithIcon("warning", "Atenção", "Não existem atividades anteriores");
                    return null;
                }

                if (response.data === "PROXIMO") {
                    openNotificationWithIcon("warning", "Atenção", "Não existem próximas atividades");
                    return null;
                }

                var count = response.data.count;
                if (tipo === "ANTERIOR") {
                    toolbar.date.setMonth(toolbar.date.getMonth() - count * 1);
                    toolbar.onNavigate("prev");
                    this.setState({
                        dataCalendario: toolbar.date
                    });
                } else {
                    toolbar.date.setMonth(toolbar.date.getMonth() + count * 1);
                    toolbar.onNavigate("next");
                    this.setState({
                        dataCalendario: toolbar.date
                    });
                }

                const atividades = response.data.atividades.map(atividade => {
                    return {
                        id: atividade.id,
                        title: atividade.nome,
                        start: new Date(atividade.inicio),
                        end: new Date(atividade.termino),
                        cor: atividade.cor
                    };
                });

                this.setState({
                    atividades,
                    iconLoading: false
                });
            })
            .catch(() => {});
    };

    listarPeriodos = () => {
        axios({
            method: "get",
            url: "/api/gestao-calendario-atividades/listar-atividades-periodos",
            params: {
                calendarioId: this.state.calendarioId
            }
        })
            .then(response => {
                this.setState({
                    periodos: response.data
                });
            })
            .catch(() => {});
    };

    showDrawer = () => {
        this.setState({
            atividadeId: 0,
            visible: true
        });
    };

    onClose = () => {
        this.setState({
            visible: false
        });
    };

    montarMeses = () => {
        const mesesNomes = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ];
        let html = this.state.listaMeses.map((item, index) => {
            return (
                <div style={{ display: "flex", gap: 100, marginBottom: 40 }}>
                    <div style={{ flex: "50%" }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                            {mesesNomes[item.mes]} {item.ano}
                        </span>
                        <div>
                            <ul>{this.montarAtividades(item, false)}</ul>
                        </div>
                    </div>
                    <div style={{ flex: "50%" }}>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                            {mesesNomes[item.mes]} {item.ano}
                        </span>
                        <div>
                            <ul>{this.montarAtividades(item, true)}</ul>
                        </div>
                    </div>
                </div>
            );
        });

        return html;
    };

    montarAtividades = (item, suspensao) => {
        const atividades = this.state.atividades.filter(
            x => moment(x.inicio).month() === item.mes && moment(x.inicio).format("YYYY") === item.ano && x.suspensao === suspensao
        );

        if (atividades.length > 0) {
            let html = atividades.map(atividade => {
                return (
                    <li>
                        <Popover
                            placement="right"
                            content={
                                <span className="controlos-item-estrutura" style={{ display: "flex", gap: 5 }}>
                                    <Link
                                        className="botao-icon-editar"
                                        to="#"
                                        title="Editar registo"
                                        onClick={() => this.setState({ atividadeId: atividade.id, visible: true })}
                                    >
                                        <Icon type="edit" />
                                    </Link>
                                    <Link
                                        className="botao-icon-excluir"
                                        to="#"
                                        title="Excluir registo"
                                        onClick={() => this.excluir(atividade.id)}
                                    >
                                        <Icon type="delete" />
                                    </Link>
                                </span>
                            }
                            visible={this.state.hoverId === atividade.id}
                            onVisibleChange={visible => this.setState({ hoverId: visible ? atividade.id : 0 })}
                        >
                            <span style={{ cursor: "pointer" }}>
                                {atividade.nome} ({moment(atividade.inicio).format("DD/MM/YYYY")}{" "}
                                {atividade.termino && ` - ${moment(atividade.termino).format("DD/MM/YYYY")}`})
                            </span>
                        </Popover>
                    </li>
                );
            });

            return html;
        }
    };

    montarMesesAtividades = () => {
        const mesesNomes = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ];
        let html = this.state.listaMeses.map((item, index) => {
            return (
                <div style={{ display: "flex", gap: 100, marginBottom: 40 }}>
                    <div>
                        <span style={{ fontSize: 15, fontWeight: "bold" }}>
                            {mesesNomes[item.mes]} {item.ano}
                        </span>
                        <div>
                            <ul>{this.montarTodasAtividades(item)}</ul>
                        </div>
                    </div>
                </div>
            );
        });

        return html;
    };

    montarTodasAtividades = item => {
        const atividades = this.state.atividades.filter(
            x => moment(x.inicio).month() === item.mes && moment(x.inicio).format("YYYY") === item.ano
        );

        if (atividades.length > 0) {
            let html = atividades.map(atividade => {
                return (
                    <li>
                        <Popover
                            placement="right"
                            content={
                                <span className="controlos-item-estrutura" style={{ display: "flex", gap: 5 }}>
                                    <Link
                                        className="botao-icon-editar"
                                        to="#"
                                        title="Editar registo"
                                        onClick={() => this.setState({ atividadeId: atividade.id, visible: true })}
                                    >
                                        <Icon type="edit" />
                                    </Link>
                                    <Link
                                        className="botao-icon-excluir"
                                        to="#"
                                        title="Excluir registo"
                                        onClick={() => this.excluir(atividade.id)}
                                    >
                                        <Icon type="delete" />
                                    </Link>
                                </span>
                            }
                            visible={this.state.hoverId === atividade.id}
                            onVisibleChange={visible => this.setState({ hoverId: visible ? atividade.id : 0 })}
                        >
                            <span style={{ cursor: "pointer" }}>
                                {atividade.nome} ({moment(atividade.inicio).format("DD/MM/YYYY")}{" "}
                                {atividade.termino && ` - ${moment(atividade.termino).format("DD/MM/YYYY")}`})
                            </span>
                        </Popover>
                    </li>
                );
            });

            return html;
        }
    };

    excluir = () => {
        confirm({
            title: "Pretende excluir esta atividade?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-calendario-atividades/excluir",
                    params: {
                        atividadeId: this.state.atividadeId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Atividade excluida!");
                        this.setState({
                            visibleModalDetalhe: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir esta atividade!");
                    });
            }
        });
    };

    onRangeChange = range => {
        if (range.length) {
            this.setState(
                {
                    inicio: moment(range[0]).format("YYYY/MM/DD"),
                    termino: moment(range.pop()).format("YYYY/MM/DD")
                },
                () => this.listar()
            );
        } else {
            this.setState(
                {
                    inicio: moment(range.start).format("YYYY/MM/DD"),
                    termino: moment(range.end).format("YYYY/MM/DD")
                },
                () => this.listar()
            );
        }
    };

    onDrillDown = range => {
        this.setState(
            {
                inicio: moment(range).format("YYYY/MM/DD"),
                termino: moment(range).format("YYYY/MM/DD"),
                vista: "day",
                dataCalendario: range
            },
            () => {
                localStorage.setItem("posicao_calendario", this.state.dataCalendario);
                localStorage.setItem("vista_calendario", "day");
            }
        );
    };

    getMonthNameInPortuguese(date) {
        const monthIndex = date.month();
        const monthNames = [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro"
        ];
        return monthNames[monthIndex];
    }

    getDayOfWeekInPortuguese(date) {
        const dayIndex = date.day();
        const dayNames = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
        return dayNames[dayIndex];
    }

    onSelectEvent = event => {
        try {
            const date = moment(event.data);
            const formattedDate = date.format("dddd, D [de] MMMM");
            const monthNameInPortuguese = this.getMonthNameInPortuguese(date);
            const dayOfWeekInPortuguese = this.getDayOfWeekInPortuguese(date);
            const finalFormattedDate = formattedDate
                .replace(date.format("MMMM"), monthNameInPortuguese)
                .replace(date.format("dddd"), dayOfWeekInPortuguese);

            const info = {
                atividadeId: event.id,
                nome: event.title,
                data: finalFormattedDate
            };

            this.setState({
                atividadeId: event.id,
                info,
                visibleModalDetalhe: true
            });
        } catch {
            openNotificationWithIcon("error", "Erro", "Não foi possível abrir o detalhe da aula.");
        }
    };

    render() {
        const {
            dataCalendario,
            vista,
            aulas,
            inicio,
            termino,
            calendarioId,
            nome,
            edicao,
            tipo,
            nr_modulos,
            inicioCal,
            terminoCal,
            //DRAWER
            atividadeId,
            atividades,
            periodos,
            visible,
            //MODAL
            info,
            visibleModalDetalhe
        } = this.state;

        const eventStyleGetter = (event, start, end, isSelected) => {
            const style = {
                background: event.avaliacao
                    ? `linear-gradient(145deg, rgba(255,166,0,1) 0%, rgba(255,166,0,1) 15%, ${event.cor} 15%)`
                    : event.cor,
                border: `3px solid ${event.corBorder}`
            };
            return {
                style: style
            };
        };

        const toolbar = toolbar => {
            const aulasAnteriores = () => {
                this.listarOndeExiste(toolbar, "ANTERIOR");
            };

            const proximasAulas = () => {
                this.listarOndeExiste(toolbar, "PROXIMO");
            };

            const anterior = () => {
                if (toolbar.view === "month") {
                    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
                    toolbar.onNavigate("prev");
                    this.setState(
                        {
                            dataCalendario: toolbar.date
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                } else if (toolbar.view === "week") {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 7);
                    toolbar.onNavigate("prev", newDate);
                    this.setState(
                        {
                            dataCalendario: newDate
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                } else {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 1);
                    toolbar.onNavigate("prev", newDate);
                    this.setState(
                        {
                            dataCalendario: newDate
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                }
            };

            const proximo = () => {
                if (toolbar.view === "month") {
                    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
                    toolbar.onNavigate("next");
                    this.setState(
                        {
                            dataCalendario: toolbar.date
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                } else if (toolbar.view === "week") {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + 7);
                    toolbar.onNavigate("prev", newDate);
                    this.setState(
                        {
                            dataCalendario: newDate
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                } else {
                    let mDate = this.state.dataCalendario;
                    let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + 1);
                    toolbar.onNavigate("prev", newDate);
                    this.setState(
                        {
                            dataCalendario: newDate
                        },
                        () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                    );
                }
            };

            const vistaHoje = () => {
                toolbar.onNavigate("current", new Date());
                this.setState(
                    {
                        dataCalendario: new Date()
                    },
                    () => localStorage.setItem("posicao_calendario", this.state.dataCalendario)
                );
            };

            const labelData = () => {
                const date = moment(toolbar.date);
                if (toolbar.view === "month") return <span>{date.format("MMMM YYYY")}</span>;
                else if (toolbar.view === "week") {
                    return <span>{toolbar.label}</span>;
                } else return <span>{date.format("dddd, DD [de] MMMM YYYY")}</span>;
            };

            const vistaMes = () => {
                toolbar.onView("month");
                this.setState(
                    {
                        vista: "month"
                    },
                    () => localStorage.setItem("vista_calendario", "month")
                );
            };

            const vistaSemana = () => {
                toolbar.onView("week");
                this.setState(
                    {
                        vista: "week"
                    },
                    () => localStorage.setItem("vista_calendario", "week")
                );
            };

            const vistaDia = () => {
                toolbar.onView("day");
                this.setState(
                    {
                        vista: "day"
                    },
                    () => localStorage.setItem("vista_calendario", "day")
                );
            };

            const montarTitleDuplaSetaEsq = () => {
                if (vista === "month") return "Mês anterior com aulas";
                else if (vista === "week") return "Semana anterior com aulas";
                else return "Dia anterior com aulas";
            };

            const montarTitleDuplaSetaDir = () => {
                if (vista === "month") return "Próximo mês com aulas";
                else if (vista === "week") return "Próxima semana com aulas";
                else return "Próximo dia com aulas";
            };

            const montarTitleSetaEsq = () => {
                if (vista === "month") return "Mês anterior";
                else if (vista === "week") return "Semana anterior";
                else return "Dia anterior";
            };

            const montarTitleSetaDir = () => {
                if (vista === "month") return "Próximo mês";
                else if (vista === "week") return "Próxima semana";
                else return "Próximo dia";
            };

            return (
                <>
                    <div className="toolbar-container toolbar-container-desktop">
                        <div className="toolbal-opcoes-esquerda" style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <button className="botao-principal botao-principal-redondo" title="Adicionar" onClick={this.showDrawer}>
                                +
                            </button>
                            <Button.Group>
                                <Button className="btn-anterior" title={montarTitleDuplaSetaEsq()} onClick={aulasAnteriores}>
                                    <Icon type="double-left" />
                                </Button>
                                <Button className="btn-anterior" title={montarTitleSetaEsq()} onClick={anterior}>
                                    <Icon type="left" />
                                </Button>
                                <Button className="btn-hoje" onClick={vistaHoje}>
                                    Hoje
                                </Button>
                                <Button className="btn-proximo" title={montarTitleSetaDir()} onClick={proximo}>
                                    <Icon type="right" />
                                </Button>
                                <Button className="btn-proximo" title={montarTitleDuplaSetaDir()} onClick={proximasAulas}>
                                    <Icon type="double-right" />
                                </Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-label-data">
                            <label className="label-data">{labelData()}</label>
                        </div>
                        <div className="toolbal-opcoes-direita" style={{ minWidth: "150px", display: "flex", justifyContent: "flex-end" }}>
                            {/*<Button.Group>*/}
                            {/*    <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>Mês</Button>*/}
                            {/*    <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>Semana</Button>*/}
                            {/*</Button.Group>*/}
                            <Link
                                to="/gestao-calendarios-escolares"
                                className="botao-secundario botao-secundario-redondo"
                                title="Voltar"
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <Icon type="arrow-left" />
                            </Link>
                        </div>
                    </div>
                    <div className="toolbar-container toolbar-container-tablet">
                        <div className="toolbal-opcoes-cima">
                            <button className="botao-principal botao-principal-redondo" title="Adicionar" onClick={this.showDrawer}>
                                +
                            </button>
                            <Button.Group>
                                <Button.Group>
                                    <Button className="btn-anterior" title={montarTitleDuplaSetaEsq()} onClick={aulasAnteriores}>
                                        <Icon type="double-left" />
                                    </Button>
                                    <Button className="btn-anterior" title={montarTitleSetaEsq()} onClick={anterior}>
                                        <Icon type="left" />
                                    </Button>
                                    <Button className="btn-hoje" onClick={vistaHoje}>
                                        Hoje
                                    </Button>
                                    <Button className="btn-proximo" title={montarTitleSetaDir()} onClick={proximo}>
                                        <Icon type="right" />
                                    </Button>
                                    <Button className="btn-proximo" title={montarTitleDuplaSetaDir()} onClick={proximasAulas}>
                                        <Icon type="double-right" />
                                    </Button>
                                </Button.Group>
                            </Button.Group>
                            <Link
                                to="/gestao-calendarios-escolares"
                                className="botao-secundario botao-secundario-redondo"
                                title="Voltar"
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <Icon type="arrow-left" />
                            </Link>
                            {/*<Button.Group>*/}
                            {/*    <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>Mês</Button>*/}
                            {/*    <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>Semana</Button>*/}
                            {/*</Button.Group>*/}
                        </div>
                        <div className="toolbal-opcoes-baixo">
                            <div className="toolbal-label-data">
                                <label className="label-data">{labelData()}</label>
                            </div>
                        </div>
                    </div>
                    <div className="toolbar-container toolbar-container-mobile">
                        <div
                            className="toolbal-opcoes-direita"
                            style={{ display: "flex", justifyContent: "space-between", gap: 10, marginTop: 0 }}
                        >
                            {/*<Button.Group>*/}
                            {/*    <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>Mês</Button>*/}
                            {/*    <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>Semana</Button>*/}
                            {/*</Button.Group>*/}
                            <button className="botao-principal botao-principal-redondo" title="Adicionar" onClick={this.showDrawer}>
                                +
                            </button>
                            <Link
                                to="/gestao-calendarios-escolares"
                                className="botao-secundario botao-secundario-redondo"
                                title="Voltar"
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <Icon type="arrow-left" />
                            </Link>
                        </div>
                        <div className="toolbal-opcoes-esquerda">
                            <Button.Group>
                                <Button.Group>
                                    <Button className="btn-anterior" title={montarTitleDuplaSetaEsq()} onClick={aulasAnteriores}>
                                        <Icon type="double-left" />
                                    </Button>
                                    <Button className="btn-anterior" title={montarTitleSetaEsq()} onClick={anterior}>
                                        <Icon type="left" />
                                    </Button>
                                    <Button className="btn-hoje" onClick={vistaHoje}>
                                        Hoje
                                    </Button>
                                    <Button className="btn-proximo" title={montarTitleSetaDir()} onClick={proximo}>
                                        <Icon type="right" />
                                    </Button>
                                    <Button className="btn-proximo" title={montarTitleDuplaSetaDir()} onClick={proximasAulas}>
                                        <Icon type="double-right" />
                                    </Button>
                                </Button.Group>
                            </Button.Group>
                        </div>
                        <div className="toolbal-label-data">
                            <label className="label-data">{labelData()}</label>
                        </div>
                    </div>
                </>
            );
        };

        const hoje = new Date();

        moment.updateLocale("pt", {
            week: {
                dow: 0
            }
        });

        return (
            <>
                <Header
                    titulo={edicao ? `${edicao} - ${nome} ( ${inicioCal} - ${terminoCal} )` : `${nome} ( ${inicioCal} - ${terminoCal} )`}
                    tem_subtitulo
                    subtitulo={tipo.join(", ")}
                />
                <div className="container container-body bloco-diario">
                    <div className="curso-diario">
                        <Calendar
                            localizer={localizer}
                            events={atividades}
                            scrollToTime={new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(), 8)}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 700 }}
                            defaultView={vista}
                            view={vista}
                            date={dataCalendario}
                            views={["month", "week", "day"]}
                            components={{
                                toolbar: toolbar
                            }}
                            onView={() => {}}
                            onNavigate={() => {}}
                            onSelectEvent={this.onSelectEvent}
                            onDrillDown={this.onDrillDown}
                            onRangeChange={this.onRangeChange}
                            eventPropGetter={eventStyleGetter}
                            selectable={true}
                            //onSelectSlot={this.onSelectSlot}
                        />
                        <div className="legenda">
                            <span className="bloco-cores">
                                <span className="item">
                                    <i className="cor-estrutura cor-2"></i>Sem suspensão de atividades
                                </span>
                                <span className="item">
                                    <i className="cor-estrutura cor-1"></i>Com suspensão de atividades
                                </span>
                            </span>
                        </div>
                    </div>
                    <ModalDetalhe
                        info={info}
                        visible={visibleModalDetalhe}
                        editar={() => this.setState({ visible: true, visibleModalDetalhe: false })}
                        excluir={() => this.excluir()}
                        onClose={() => this.setState({ visibleModalDetalhe: false })}
                        atualizarListagem={() => this.listar()}
                    />
                    <DrawerCalendarioAtividade
                        periodos={periodos}
                        inicioCalendario={this.state.inicio}
                        terminoCalendario={this.state.termino}
                        tipo={tipo}
                        nrModulos={nr_modulos}
                        calendarioId={calendarioId}
                        atividadeId={atividadeId}
                        visible={visible}
                        onClose={() => this.setState({ visible: false })}
                        atualizar={() => {
                            this.setState({ visible: false });
                            this.listar();
                        }}
                    />
                </div>
            </>
        );
    }
}

export default Detalhe;
