import React, { useState, useEffect } from "react";
import { Form, Radio, Icon } from "antd";
import { Link } from "react-router-dom";

const Questao = ({ loading, respostas, onAvancar, onRetroceder, onSelecionarResposta, onEnviar, onFechar, questao, questoes }) => {
    const [respostaSelecionada, setRespostaSelecionada] = useState();

    // Efeito para configurar a resposta ao carregar ou mudar de pergunta

    const handleAvancar = () => {
        onAvancar();
        onSelecionarResposta(questao.id, questao.resposta[respostaSelecionada]);

        // Obtém a resposta da pergunta anterior
        const perguntaAnterior = respostas.find(p => p.id === questao.id + 1);
        const arrayRespostas = questoes.find(p => p.id === questao.id + 1);

        //console.log(questaox.resposta);

        // Define a resposta anterior, se existir
        if (perguntaAnterior) {
            //console.log(perguntaAnterior.resposta);
            const indice = arrayRespostas.resposta.indexOf(perguntaAnterior.resposta);

            setRespostaSelecionada(indice);
        } else {
            setRespostaSelecionada(null);
        }
    };

    const handleRetroceder = () => {
        onRetroceder();

        // Obtém a resposta da pergunta anterior
        const perguntaAnterior = respostas.find(p => p.id === questao.id - 1);
        const arrayRespostas = questoes.find(p => p.id === questao.id - 1);

        //console.log(questaox.resposta);

        // Define a resposta anterior, se existir
        if (perguntaAnterior) {
            //console.log(perguntaAnterior.resposta);
            const indice = arrayRespostas.resposta.indexOf(perguntaAnterior.resposta);

            setRespostaSelecionada(indice);
        } else {
            setRespostaSelecionada(null);
        }
    };

    const handleSelecionarRespostaLocal = index => {
        setRespostaSelecionada(index);
        onSelecionarResposta(questao.id, index);
    };

    const handlerEnviar = () => {
        onEnviar();
    };

    const handlerFechar = () => {
        onFechar();
    };

    const radioStyle = {
        ...(questao.satisfacao === false
            ? {
                  display: "block",
                  height: "20px",
                  lineHeight: "20px",
                  lineWight: "20px",
                  textAlign: "left",
                  color: "white"
              }
            : { color: "white", lineHeight: "20px", lineWight: "20px" })
    };

    return (
        <div className="popup-container">
            <Link to="#" onClick={handlerFechar}>
                <Icon type="close" className="popup-icone-fechar" />
            </Link>

            <div className="popup-bloco-pergunta">
                <h3 className="popup-pergunta">{questao.questao}</h3>

                {questao.descricao && (
                    <p className="popup-descricao">{questao.descricao}</p>)}

                {questao.resposta?.length > 0 && (
                    <>
                        {questao.satisfacao ?
                            (<div className="popup-respostas popup-escala">
                                <span className="popup-escala-nivel">Muito Insatisfeito(a)</span>
                                <Form>
                                    <Radio.Group onChange={e => handleSelecionarRespostaLocal(e.target.value)} value={respostaSelecionada}>
                                        {questao.resposta.map((opcao, index) => (
                                            <Radio style={radioStyle} key={index} value={index}>
                                                {opcao}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </Form>
                                <span className="popup-escala-nivel">Satisfeito(a)</span>
                            </div>)
                            :
                            (<div className="popup-respostas">
                                <Form>
                                    <Radio.Group onChange={e => handleSelecionarRespostaLocal(e.target.value)} value={respostaSelecionada}>
                                        {questao.resposta.map((opcao, index) => (
                                            <Radio style={radioStyle} key={index} value={index}>
                                                {opcao}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </Form>
                            </div>)
                        }
                    </>
                )}

                <div className="popup-bloco-botoes">
                    {questao.id !== 1 ? (
                        <button className="popup-botao" onClick={handleRetroceder}>
                            Anterior
                        </button>
                    ) : (
                        <></>
                    )}

                    {questao.id !== 8 ? (
                        <button className="popup-botao" onClick={handleAvancar}>
                            Próxima
                        </button>
                    ) : (
                        <></>
                    )}

                    {questao.id === 8 ? (
                        <button className="popup-botao" onClick={handlerEnviar}>
                            {loading ? <Icon type="loading" /> : null}
                            Enviar
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Questao;
