import React, { Component } from "react";
import { Link } from "react-router-dom";
import Gestor from "./_Aux/Gestor"
import Instrutor from "./_Aux/Instrutor"
import { GlobalContext } from "../../GlobalState";

export class Main extends Component {
    static contextType = GlobalContext;

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<div className="controlos controlos-fundo">
                <div className="controlos-opcoes">
                    <Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)} style={{ marginRight: 10 }}>
                        Voltar
                    </Link>
                </div>
            </div>);
        }
    }

    render() {
        return (
            <div className="container container-body">
                <div className="curso-resultados">
                    {this.montarBotaoVoltar()}
                    {this.context.role === "ENTIDADE" ?
                        <Gestor />
                        : null}
                    {this.context.role === "FORMADOR" ?
                        <Instrutor />
                        : null}
                </div>
            </div>
        );
    }
}

export default Main;
