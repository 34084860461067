import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, notification, Icon, Drawer, Checkbox, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";
import UploadImagem from "./UploadImagem";
import { GlobalContext } from "../../../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerEscola extends Component {
    static contextType = GlobalContext;
    state = {
        icon: "",
        imagem_propria: false,
        capa: "",
        foto: "",
        nome: "",
        abreviatura: "",
        tipo: "",
        descricao: "",
        inep: "",
        portaria: "",
        cod_postal: "",
        localidade: "",
        morada: "",
        documento: "",
        telefone: "",
        formacao: false,
        educacao: false,
        //DIRETOR
        diretor_foto: "",
        diretor_nome: "",
        diretor_email: "",
        diretor_assinatura: "",
        //SECREATRIO
        secretario_foto: "",
        secretario_nome: "",
        secretario_email: "",
        secretario_assinatura: "",
        //LINK
        facebook: "",
        twitter: "",
        linkedin: "",
        instagram: "",
        email: "",
        site: "",
        whatsapp: "",
        //LOADING
        iconLoading: false
    };

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeCheck = event => {
        this.setState({ [event.target.name]: event.target.checked });
    }

    adicionar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("icon", this.state.icon);
                item.append("imagem_propria", this.state.imagem_propria);
                item.append("capa", this.state.capa);
                item.append("foto", this.state.foto);
                item.append("nome", this.state.nome);
                if (this.state.abreviatura)
                    item.append("abreviatura", this.state.abreviatura);
                if (this.state.tipo)
                    item.append("tipo", this.state.tipo);
                if (this.state.descricao)
                    item.append("descricao", this.state.descricao);
                if (this.state.inep)
                    item.append("inep", this.state.inep);
                if (this.state.portaria)
                    item.append("portaria", this.state.portaria);
                if (this.state.cod_postal)
                    item.append("cod_postal", this.state.cod_postal);
                if (this.state.localidade)
                    item.append("localidade", this.state.localidade);
                if (this.state.morada)
                    item.append("morada", this.state.morada);
                if (this.state.documento)
                    item.append("documento", this.state.documento);
                if (this.state.telefone)
                    item.append("telefone", this.state.telefone);
                item.append("formacao", this.state.formacao);
                item.append("educacao", this.state.educacao);
                //DIRETOR
                item.append("diretor_foto", this.state.diretor_foto);
                if (this.state.diretor_nome)
                    item.append("diretor_nome", this.state.diretor_nome);
                if (this.state.diretor_email)
                    item.append("diretor_email", this.state.diretor_email);
                item.append("diretor_assinatura", this.state.diretor_assinatura);
                //SECREATRIO
                item.append("secretario_foto", this.state.secretario_foto);
                if (this.state.secretario_nome)
                    item.append("secretario_nome", this.state.secretario_nome);
                if (this.state.secretario_email)
                    item.append("secretario_email", this.state.secretario_email);
                item.append("secretario_assinatura", this.state.secretario_assinatura);
                //LINK
                if (this.state.facebook)
                    item.append("facebook", this.state.facebook);
                if (this.state.twitter)
                    item.append("twitter", this.state.twitter);
                if (this.state.linkedin)
                    item.append("linkedin", this.state.linkedin);
                if (this.state.instagram)
                    item.append("instagram", this.state.instagram);
                if (this.state.email)
                    item.append("email", this.state.email);
                if (this.state.site)
                    item.append("site", this.state.site);
                if (this.state.whatsapp)
                    item.append("whatsapp", this.state.whatsapp);

                axios({
                    method: "post",
                    url: "/api/gestao-organismos/adicionar-organismo",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        this.props.atualizar();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.organismoId);
                item.append("icon", this.state.icon);
                item.append("imagem_propria", this.state.imagem_propria);
                item.append("capa", this.state.capa);
                item.append("foto", this.state.foto);
                item.append("nome", this.state.nome);
                if (this.state.abreviatura)
                    item.append("abreviatura", this.state.abreviatura);
                if (this.state.tipo)
                    item.append("tipo", this.state.tipo);
                if (this.state.descricao)
                    item.append("descricao", this.state.descricao);
                if (this.state.inep)
                    item.append("inep", this.state.inep);
                if (this.state.portaria)
                    item.append("portaria", this.state.portaria);
                if (this.state.cod_postal)
                    item.append("cod_postal", this.state.cod_postal);
                if (this.state.localidade)
                    item.append("localidade", this.state.localidade);
                if (this.state.morada)
                    item.append("morada", this.state.morada);
                if (this.state.documento)
                    item.append("documento", this.state.documento);
                if (this.state.telefone)
                    item.append("telefone", this.state.telefone);
                item.append("formacao", this.state.formacao);
                item.append("educacao", this.state.educacao);
                //DIRETOR
                item.append("diretor_foto", this.state.diretor_foto);
                if (this.state.diretor_nome)
                    item.append("diretor_nome", this.state.diretor_nome);
                if (this.state.diretor_email)
                    item.append("diretor_email", this.state.diretor_email);
                item.append("diretor_assinatura", this.state.diretor_assinatura);
                //SECREATRIO
                item.append("secretario_foto", this.state.secretario_foto);
                if (this.state.secretario_nome)
                    item.append("secretario_nome", this.state.secretario_nome);
                if (this.state.secretario_email)
                    item.append("secretario_email", this.state.secretario_email);
                item.append("secretario_assinatura", this.state.secretario_assinatura);
                //LINK
                if (this.state.facebook)
                    item.append("facebook", this.state.facebook);
                if (this.state.twitter)
                    item.append("twitter", this.state.twitter);
                if (this.state.linkedin)
                    item.append("linkedin", this.state.linkedin);
                if (this.state.instagram)
                    item.append("instagram", this.state.instagram);
                if (this.state.email)
                    item.append("email", this.state.email);
                if (this.state.site)
                    item.append("site", this.state.site);
                if (this.state.whatsapp)
                    item.append("whatsapp", this.state.whatsapp);

                axios({
                    method: "put",
                    url: "/api/gestao-organismos/alterar-organismo",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        this.props.atualizar();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregar = () => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-organismos/carregar-organismo",
            params: {
                organismoId: this.props.organismoId
            }
        })
            .then((response) => {
                this.setState({
                    icon: response.data.icon,
                    imagem_propria: response.data.imagem_propria,
                    capa: response.data.capa,
                    foto: response.data.foto,
                    nome: response.data.nome,
                    abreviatura: response.data.abreviatura,
                    tipo: response.data.tipo,
                    descricao: response.data.descricao ? response.data.descricao : "",
                    inep: response.data.inep,
                    portaria: response.data.portaria,
                    cod_postal: response.data.cod_postal,
                    localidade: response.data.localidade,
                    morada: response.data.morada,
                    documento: response.data.documento,
                    telefone: response.data.telefone,
                    email: response.data.email,
                    formacao: response.data.formacao,
                    educacao: response.data.educacao,
                    //DIRETOR
                    diretor_foto: response.data.diretor_foto,
                    diretor_nome: response.data.diretor_nome,
                    diretor_email: response.data.diretor_email,
                    diretor_assinatura: response.data.diretor_assinatura,
                    //SECREATRIO
                    secretario_foto: response.data.secretario_foto,
                    secretario_nome: response.data.secretario_nome,
                    secretario_email: response.data.secretario_email,
                    secretario_assinatura: response.data.secretario_assinatura,
                    //LINK
                    facebook: response.data.facebook,
                    twitter: response.data.twitter,
                    linkedin: response.data.linkedin,
                    instagram: response.data.instagram,
                    email: response.data.email,
                    site: response.data.site,
                    whatsapp: response.data.whatsapp,
                    ativo: response.data.ativo,
                    visible: true
                });
            })
            .catch(() => {

            });
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.organismoId > 0)
                this.carregar();
            else
                this.setState({
                    icon: "",
                    imagem_propria: false,
                    capa: "",
                    foto: "",
                    nome: "",
                    abreviatura: "",
                    tipo: "",
                    descricao: "",
                    inep: "",
                    portaria: "",
                    cod_postal: "",
                    localidade: "",
                    morada: "",
                    documento: "",
                    telefone: "",
                    formacao: false,
                    educacao: false,
                    //DIRETOR
                    diretor_foto: "",
                    diretor_nome: "",
                    diretor_email: "",
                    diretor_assinatura: "",
                    //SECREATRIO
                    secretario_foto: "",
                    secretario_nome: "",
                    secretario_email: "",
                    secretario_assinatura: "",
                    //LINK
                    facebook: "",
                    twitter: "",
                    linkedin: "",
                    instagram: "",
                    email: "",
                    site: "",
                    whatsapp: "",
                });
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            icon,
            imagem_propria,
            capa,
            foto,
            nome,
            abreviatura,
            tipo,
            descricao,
            inep,
            portaria,
            cod_postal,
            localidade,
            morada,
            documento,
            telefone,
            //DIRETOR
            diretor_foto,
            diretor_nome,
            diretor_email,
            diretor_assinatura,
            //SECREATRIO
            secretario_foto,
            secretario_nome,
            secretario_email,
            secretario_assinatura,
            //LINK
            facebook,
            twitter,
            linkedin,
            instagram,
            email,
            site,
            whatsapp,
            //LOADING
            iconLoading
        } = this.state;

        const {
            ativo,
            visible,
            organismoId,
        } = this.props;

        return (
            <Drawer
                className="drawer-add-cursos drawer-videoconferencias"
                //title={organismoId > 0 ? "Alterar Estabelecimento" : "Adicionar Estabelecimento"}
                width={720}
                onClose={() => this.setState({ visible: false })}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        <div className="bloco">
                            <UploadImagem
                                ativo={ativo}
                                titulo="Logo"
                                idName="input-logo"
                                limite={100}
                                size="200x200px"
                                imagem={icon}
                                atualizarImagem={icon => this.setState({ icon })}
                            />
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="imagem_propria" disabled={!ativo} checked={imagem_propria} onChange={this.handlerChangeCheck}>
                                    Usar o logo no topo da página
                                </Checkbox>
                            </Form.Item>
                            <UploadImagem
                                ativo={ativo}
                                titulo="Capa"
                                idName="input-capa"
                                limite={100}
                                size="1170x280px"
                                imagem={capa}
                                atualizarImagem={capa => this.setState({ capa })}
                            />
                            <Form.Item label="Estabelecimento de ensino/Escola">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Abreviatura">
                                {getFieldDecorator("abreviatura", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: abreviatura
                                })(<Input name="abreviatura" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            {this.context.id_entidade === 3 && (
                                <Form.Item label="Tipo">
                                    {getFieldDecorator("tipo", {
                                        initialValue: tipo
                                    })(<Input name="tipo" disabled={!ativo} onChange={this.handlerChange} />)}
                                </Form.Item>)}
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    initialValue: descricao
                                })(<TextArea name="descricao" disabled={!ativo} onChange={this.handlerChange} rows={10} style={{ height: 100 }} />)}
                            </Form.Item>
                            <Form.Item label="INEP">
                                {getFieldDecorator("inep", {
                                    initialValue: inep
                                })(<Input name="inep" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Portaria">
                                {getFieldDecorator("portaria", {
                                    initialValue: portaria
                                })(<Input name="portaria" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="CEP">
                                {getFieldDecorator("cod_postal", {
                                    initialValue: cod_postal
                                })(<Input name="cod_postal" disabled={!ativo} className="input-25" onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Localidade / Estado">
                                {getFieldDecorator("localidade", {
                                    initialValue: localidade
                                })(<Input name="localidade" disabled={!ativo} className="input-50" onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Endereço">
                                {getFieldDecorator("morada", {
                                    initialValue: morada
                                })(<TextArea name="morada" disabled={!ativo} onChange={this.handlerChange} rows={10} style={{ height: 100 }} />)}
                            </Form.Item>
                            <Form.Item label="Documento de Credenciação">
                                {getFieldDecorator("documento", {
                                    initialValue: documento
                                })(<Input name="documento" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Telefone">
                                {getFieldDecorator("telefone", {
                                    initialValue: telefone
                                })(<Input name="telefone" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>Diretor</h3>
                            <UploadImagem
                                ativo={ativo}
                                titulo="Foto"
                                idName="input-foto-diretor"
                                limite={100}
                                imagem={diretor_foto}
                                atualizarImagem={diretor_foto => this.setState({ diretor_foto })}
                            />
                            <Form.Item label="Nome">
                                {getFieldDecorator("diretor_nome", {
                                    initialValue: diretor_nome
                                })(<Input name="diretor_nome" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Email">
                                {getFieldDecorator("diretor_email", {
                                    initialValue: diretor_email
                                })(<Input name="diretor_email" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <UploadImagem
                                ativo={ativo}
                                titulo="Assinatura"
                                idName="input-assinatura-diretor"
                                limite={100}
                                imagem={diretor_assinatura}
                                atualizarImagem={diretor_assinatura => this.setState({ diretor_assinatura })}
                            />
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>Secretário</h3>
                            <UploadImagem
                                ativo={ativo}
                                titulo="Foto"
                                idName="input-foto-secretario"
                                limite={100}
                                imagem={secretario_foto}
                                atualizarImagem={secretario_foto => this.setState({ secretario_foto })}
                            />
                            <Form.Item label="Nome">
                                {getFieldDecorator("secretario_nome", {
                                    initialValue: secretario_nome
                                })(<Input name="secretario_nome" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Email">
                                {getFieldDecorator("secretario_email", {
                                    initialValue: secretario_email
                                })(<Input name="secretario_email" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <UploadImagem
                                ativo={ativo}
                                titulo="Assinatura"
                                idName="input-assinatura-secretario"
                                limite={100}
                                imagem={secretario_assinatura}
                                atualizarImagem={secretario_assinatura => this.setState({ secretario_assinatura })}
                            />
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador">Endereços das contas sociais a constar no rodapé</h3>
                            <Form.Item
                                label={
                                    <>
                                        <Icon type="facebook" />
                                        Facebook
                                    </>
                                }
                                className="redes-sociais-link"
                            >
                                <Input value={facebook} name="facebook" disabled={!ativo} className="input-50" onChange={this.handlerChange} />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        <Icon type="twitter" />
                                        Twitter
                                    </>
                                }
                                className="redes-sociais-link"
                            >
                                <Input value={twitter} name="twitter" disabled={!ativo} className="input-50" onChange={this.handlerChange} />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        <Icon type="linkedin" />
                                        LinkedIn
                                    </>
                                }
                                className="redes-sociais-link"
                            >
                                <Input value={linkedin} name="linkedin" disabled={!ativo} className="input-50" onChange={this.handlerChange} />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        <Icon type="instagram" />
                                        Instagram
                                    </>
                                }
                                className="redes-sociais-link"
                            >
                                <Input value={instagram} name="instagram" disabled={!ativo} className="input-50" onChange={this.handlerChange} />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        <i className="fab fa-whatsapp"></i>
                                        WhatsApp
                                    </>
                                }
                                className="redes-sociais-link"
                            >
                                <Input value={whatsapp} name="whatsapp" disabled={!ativo} className="input-50" onChange={this.handlerChange} />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        <Icon type="mail" />
                                        E-mail
                                    </>
                                }
                                className="redes-sociais-link"
                            >
                                {getFieldDecorator("email", {
                                    rules: [
                                        {
                                            type: "email",
                                            message: "Introduza um endereço de e-mail válido"
                                        }
                                    ],
                                    initialValue: email
                                })(<Input name="email" className="input-50" disabled={!ativo} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        <Icon type="global" />
                                        Site
                                    </>
                                }
                                className="redes-sociais-link"
                            >
                                <Input value={site} name="site" className="input-50" disabled={!ativo} onChange={this.handlerChange} />
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <Link to="#" className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </Link>
                    {ativo && (
                    <button className="botao-principal" disabled={iconLoading} onClick={organismoId > 0 ? this.alterar : this.adicionar}>
                        {iconLoading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>)}
                </div>
            </Drawer>
        );
    }
}

const FormDrawerEscola = Form.create({ name: "form-drawer-escola" })(DrawerEscola);

export default FormDrawerEscola;
