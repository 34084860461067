import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Switch } from "antd";
import VistaPautas from "./Pautas/Pautas";
import VistaAlunos from "./Alunos/Alunos";

const Bncc = ({ estruturaId, periodo, tipo, vistaPautas }) => {
    return (
        <>
            <div className="bloco">
                <VistaPautas
                    estruturaId={estruturaId}
                    periodo={periodo}
                    tipo={tipo}
                />
            </div>
        </>
    );
}
export default Bncc;
