import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ConteudoTrabalho from "./ConteudoTrabalho";
import Controlos from "../_Aux/Controlos";
import ControlosSimples from "../_Aux/ControlosSimples";
import LazyLoad from "react-lazy-load";
import Oops from "../../Geral/Oops.jsx";

class Trabalho extends Component {
    state = {
        nome: "",
        objetivos: "",
        icon: "",
        sameUrl: true,
        dataHora: "",
        //NAO DISPONIVEL
        nao_disponivel: false
    };

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    marcarComoNaoDisponivel = () => {
        this.setState({
            nao_disponivel: true
        })
    }

    render() {
        const {
            nome,
            objetivos,
            icon,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="trabalho">
                <div className="player-wrapper">
                    {/*<LazyLoad offsetVertical={200}>*/}
                    {/*    <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                    {/*</LazyLoad>*/}
                    {!nao_disponivel ?
                        <>
                            {sameUrl && <ConteudoTrabalho marcarComoNaoDisponivel={this.marcarComoNaoDisponivel} carregarInfo={(nome, objetivos, icon, dataHora) => this.setState({ nome, objetivos, icon, dataHora })}/>}
                            <Controlos
                                backUrl={`/gerir-curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                sameUrl={sameUrl}
                                temAnotacoes
                                nome={nome}
                                objetivos={objetivos}
                                icon={icon}
                                dataHora={dataHora}
                            />
                        </>
                        :
                        <>
                            <div className="container">
                                <div className="bloco-nao-disponivel">
                                    <div className="bloco-info nao-disponivel">
                                        <Oops tem_texto texto={"Conteúdo não disponível"} />
                                    </div>
                                </div>
                            </div>
                            <ControlosSimples />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default Trabalho;
