import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, Menu, Dropdown } from 'antd';
import UserInfo from "../UserInfo/UserInfo";
import TabelaNotas from "./Tabelas/Notas/Tabela";
import TabelaConceitos from "./Tabelas/Conceitos/Tabela";
import { GlobalContext } from "../../../GlobalState";

const Aproveitamento = ({ menuOptionSelected, match }) => {
    const globalContext = useContext(GlobalContext);
    const turma = globalContext[`turma${match.params.code}`];

    return (
        <>
            <div className="page-container">
                <UserInfo
                    gestao
                />
                <div className="user-info-detail">
                    <div className="page-info">
                        <div>
                            <h1 className="page-title" style={{ margin: "20px 20px 10px 20px" }}>Aproveitamento</h1>
                        </div>
                        <span className="page-controls">
                            <Link className="botao-icon-detalhe botao-maior" to="#" title="Descarregar">
                                <Icon type="eye" />
                            </Link>
                        </span>
                    </div>
                    {turma &&
                        <div className="block-table">
                            {turma.tipo === "Notas" ?
                                <TabelaNotas />
                                :
                                <TabelaConceitos />
                            }
                        </div>}
                </div>
            </div>
        </>
    );
}

export default withRouter(Aproveitamento);