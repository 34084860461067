import React, { useState } from "react";
import { Drawer, Form, DatePicker } from "antd";
import moment from "moment";

const DrawerAta = ({ visible, form, onClose, onConfirm }) => {
    const [dataEmissao, setDataEmissao] = useState(moment());

    const { getFieldDecorator } = form;

    const handleChangeDataEmissao = (date) => {
        setDataEmissao(date);
    };

    const afterVisibleChange = visible => {
        if (!visible) {
            form.resetFields();
            setDataEmissao(moment());
        }
    }

    return (
        <>
            <Drawer
                className="drawer-inscrever"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <Form className="form-emitir-declaracao">
                        <Form.Item label="Data de emissão">
                            {getFieldDecorator("dataEmissao", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: dataEmissao
                            })(
                                <DatePicker
                                    name="dataEmissao"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={handleChangeDataEmissao}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                    <button className="botao-principal" disabled={!dataEmissao} type="primary" onClick={() => onConfirm(dataEmissao)}>
                        Visualizar
                    </button>
                </div>
            </Drawer>
        </>
    );
}

const FormDrawerAta = Form.create({ name: "drawer-ata" })(DrawerAta);

export default FormDrawerAta;
