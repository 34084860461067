import React from "react";
import { Icon, Modal } from 'antd';

const ModalLoading = ({ visible, text }) => {
    return (
        <Modal
            visible={visible}
            maskClosable={false}
            className="modal-loading"
            footer={null}
            closable={false}
        >
            <div className="modal-loading-bloco">
                <p>
                    <Icon type="loading" />
                </p>
                <p className="texto">{text}</p>
            </div>
        </Modal>
    );
}

export default ModalLoading;