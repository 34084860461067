import React, {useEffect, useState} from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import {FaArrowLeft} from "react-icons/fa";
import TabelaRelatorioAluno from "./Components/TabelaRelatorioAluno";
import TabelaRelatorioAlunoMobile from "./Components/TabelaRelatorioAlunoMobile";
import BarraAreaAlunoRelatorio from "./Components/BaraAreaAlunoRelatorio";
import {FaAngleLeft} from "react-icons/fa6";
import NomeBara from "./Components/NomeBara";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import {
    notification
} from "antd";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export default function AreaAlunoRelatorio() {
    const { entidade, id: turmaId } = useParams();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  
    const [dynamicColumns, setDynamicColumns] = useState([])
    const [dados, setDados] = useState([])
    const [periodo, setPeriodo] = useState();
    const [periodos, setPeriodos] = useState([]);
    const [num, setNum] = useState(1);

    const handlerClick = (num) => {
        const periodo = periodos[num - 1]

        setPeriodo(periodo);
        setNum(num);

        fetchDisciplinaAluno(periodo.dt_inicio, periodo.dt_fim);
    }
    
    const fetchDisciplinaAluno = async (dt_inicio, dt_fim)=>{

        const url = `/api/area-aluno/disciplinas`;

        try{
            const response = await axios.get(url,{
                params: { 
                    numeroAluno: localStorage.getItem("numeroAluno"),
                    entidadeUrl: entidade,
                    code: turmaId,
                    dt_inicio,
                    dt_fim
                }
            });

            const dynamicColumns = [
                {
                    title: "Disciplinas",
                    dataIndex: "disciplinas",
                    key: "disciplinas",
                    align: "center",
                }
            ];

            response.data.colunas.forEach((coluna, index) => {
                dynamicColumns.push({
                    title: `${coluna}`,
                    dataIndex: `${coluna}_${index}`,
                    key: `${coluna}_${index}`,
                    align: "center"
                });
            });

            dynamicColumns.push({
                title: "Estado",
                dataIndex: "estado",
                key: "estado",
                align: "center"
            });

            setDynamicColumns(dynamicColumns);
            setDados(response.data.registos);

        } catch (error) {
            openNotificationWithIcon("Não foi possível carregar as avaliações");
        }
    }

    const listarPeriodos = () => {
        axios({
            method: "get",
            url: "/api/area-aluno/carregar-periodos",
            params: {
                code: turmaId
            }
        })
            .then(response => {
                if (response.data.length > 0) {
                    setPeriodos(response.data);
                    setPeriodo(response.data[0])
                    fetchDisciplinaAluno(response.data[0].dt_inicio, response.data[0].dt_fim)
                } 
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os periodos!");
            });
    };
    
    useEffect(() => {
        listarPeriodos();
    }, []);
    
    return(
        <div className="areaAlunoRelatorio">
            <div className="titulo">
                <h1> ÁREA DO ALUNO</h1>
            </div>
            
            <BarraAreaAlunoRelatorio></BarraAreaAlunoRelatorio>

            <NomeBara 
                num={num}
                nome={localStorage.getItem('nome')} 
                handlerClick={handlerClick}
                total={periodos.length}
                periodo={periodo}
            ></NomeBara>

            {isTabletOrMobile ? (
                <TabelaRelatorioAlunoMobile dynamicColumns={dynamicColumns} disciplinas={dados} />
            ) : (
                    <TabelaRelatorioAluno dynamicColumns={dynamicColumns} disciplinas={dados} />
            )}
        </div>
    );
}