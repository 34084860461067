import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import BotaoDefinicoes from "../../Geral/_Aux/BotaoDefinicoes";
import noimage from "../../images/noimage.png";
import moment from "moment";

const Colaborador = ({ primeiro, colaborador, tem_definicoes, menu, menuMobile, filtro, paddingLeft, tag, organismos, acesso }) => {
    const [dropOpen, setDropOpen] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const montarDiasAusente = (data) => {
        const totalDias = moment().diff(moment(data, "DD/MM/YYYY"), 'days');
        if (totalDias > 0)
            return <div className="bloco-total-dias-ausente"><span className={`total-dias-ausente ${totalDias > 7 ? "ausente-acima" : "ausente-normal"}`} title="Número de dias ausente">{totalDias} {totalDias > 1 ? "dias" : "dia"}</span></div>
    }

    const onRowClick = () => {
        if (!dropOpen) {
            localStorage.setItem("link_voltar", "/gestao-utilizadores-todos");
            setRedirectLink(`/gestao-utilizadores-detalhe/${colaborador.code}`);
            setRedirect(true);
        }
    }

    const montarEstabelecimentos = () => {
        if (colaborador.estabelecimentos.length > 1) {
            const estabelecimento = colaborador.estabelecimentos[0];
            const perfis = <span className="tag" style={{ display: "unset" }}>{estabelecimento.perfils.join(", ")}</span>

            return (
                <>
                    <span key={`${estabelecimento.abreviatura}`} className="tag-organismo" title="Organismo" style={{ marginLeft: 0 }}>
                        {estabelecimento.abreviatura} ({perfis})
                    </span>
                    <span className="tag-organismo" style={{ marginLeft: 0 }}> e {colaborador.estabelecimentos.length} outros estabelecimentos</span>
                </>
            );
        }
        else {
            return colaborador.estabelecimentos.map((estabelecimento, index) => {
                const perfis = <span className="tag" style={{ display: "unset" }}>{estabelecimento.perfils.join(", ")}</span>

                return (
                    <>
                        <span key={`${estabelecimento.abreviatura}${index}`} className="tag-organismo" title="Organismo" style={{ marginLeft: 0 }}>
                            {estabelecimento.abreviatura} ({perfis})
                        </span>{index < colaborador.estabelecimentos.length - 1 ? ", " : ""}
                    </>
                );
            });
        }
    };

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <>
            <div className={`colaborador colaborador-desktop ${!acesso?.read ? "read-disabled" : ""}`} style={paddingLeft} onClick={acesso?.read ? () => onRowClick() : () => { }}>
                <div className="info-participante">
                    <div className="container-img">
                        <img src={colaborador.foto ? colaborador.foto : noimage} />
                    </div>
                    <div className="info">
                        <span className="nome">
                            {colaborador.nome_completo}
                        </span>
                        {/*{colaborador.estabelecimentos && <span className="tag-organismo" title="Organismo">{colaborador.estabelecimentos}</span>}*/}
                        <div className="bottom">
                            <span className="email">{colaborador.email} ({colaborador.nr_aluno})</span>
                            <span style={{ display: "block" }}>{montarEstabelecimentos()}</span>
                        </div>
                    </div>
                </div>
                <div className="entidadeUser">
                    {colaborador.emp_nome &&
                        <>
                            <div className="container-img">
                                <img src={colaborador.emp_logo ? colaborador.emp_logo : noimage} alt="Foto" />
                            </div>
                            <div className="head">
                                <span className="nome">
                                    {colaborador.emp_nome}
                                </span>
                            </div>
                        </>}
                </div>
                <div className="ultimoLoginUser">
                    {colaborador.ultimoLogin &&
                        <>
                        {primeiro && <label>Último login</label>}
                        <div>
                            <span className="ultimo-acesso">{colaborador.ultimoLogin}</span>
                            {montarDiasAusente(colaborador.ultimoLogin)}
                        </div>
                        </>}
                </div>
                {(tem_definicoes && acesso?.update) && (<BotaoDefinicoes menu={menu} dropOpen={(open) => setDropOpen(open)} />)}
            </div>
            <div className="colaborador colaborador-mobile" style={paddingLeft}>
                <div className="info-participante">
                    <div className="container-img">
                        <img src={colaborador.foto ? colaborador.foto : noimage} />
                    </div>
                    <div className="info">
                        <span className="nome">
                            {colaborador.nome_completo}
                        </span>
                        <div className="bottom">
                            <span className="email">{colaborador.email} ({colaborador.nr_aluno})</span>
                            <span style={{ display: "block" }}>{montarEstabelecimentos()}</span>
                        </div>
                    </div>
                </div>
                {(tem_definicoes && acesso?.update) && (<BotaoDefinicoes menu={menuMobile} mobile />)}
            </div>
        </>
    );
};

export default Colaborador;
