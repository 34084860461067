import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { notification } from "antd";
import { GlobalContext } from "../GlobalState";
//foi alterado para home 2
import Home from "./Home2/Main";
import PaginaConsultar from "./Consultar/Main";
import PaginaConsultarNotasCreche from "./Consultar/NotasCreche";
import PaginaConsultarNotasPre from "./Consultar/NotasPre";
import PaginaConsultarNotasRegular from "./Consultar/NotasRegular";
import PaginaEntidadeConsultar from "./Consultar";
import ConsultarResultados from "./ConsultarResultados";
import PaginaEntidadeLogin from "./Autenticacao/Login";
import PaginaEntidadeLogin2 from "./Autenticacao/Login2";
import PaginaEntidadeAlterarPassword from "./Autenticacao/AlterarPassword";
import PaginaEntidadeAlterarSenha from "./AlterarSenha";
import PaginaEntidadeRecuperarPassword from "./Autenticacao/RecuperarPassword";
import PaginaEntidadeRecuperarPassword2 from "./Autenticacao/RecuperarPassword2";
import PaginaEntidadeRegistoPasso1 from "./Autenticacao/Registo/RegistoPasso1";
import PaginaEntidadeRegistoPasso2 from "./Autenticacao/Registo/RegistoPasso2";
import PaginaEntidadeRegistoConclusao from "./Autenticacao/Registo/RegistoConclusao";
import PaginaEntidadeSolicitarConta from "./SolicitarConta";
import PaginaEntidadeCriarConta from "./CriarConta";
import PaginaEntidadeRegistarConta from "./RegistarConta";
import PaginaRegistoPasso2 from "./RegistoPasso2";
import PaginaRegistoConclusao from "./RegistoConclusao";
import PaginaEntidadeSuporte from "./Suporte/Suporte";
import PaginaEntidadeContacto from "./Contacto";
import PaginaEntidadeInformacoes from "./Noticias/Lista";
import PaginaEntidadeCalendarios from "./Calendarios/Calendarios";
import PaginaEntidadeEventos from "./Eventos/Eventos";
import PaginaEntidadeUnsubscribe from "./Unsubscribe";
import PaginaEntidadeVideoConfInscricao from "./VideoConfInscricao";
import PaginaEntidadeVideoConfCodigo from "./VideoConfCodigo";
import PaginaEntidadeVideoConfComCodigo from "./VideoConfComCodigo";
import PaginaEntidadeVideoConfAguardar from "./VideoConfAguardar";
import PaginaEntidadeJitsiMeetingGeral from "./JitsiMeetingGeral";
import PaginaVideoSimples from "./VideoSimples";
import PaginaEntidadeCurso from "./Curso/CursoLayout";
import PaginaEntidadeTurma from "./Turma/Layout";
import PaginaEntidadePlayer from "./Player/PlayerLayout";
import PaginaChatConfirmacao from "./Chat/Confirmacao";
import PaginaChatCodigo from "./Chat/Codigo";
import PaginaChatInativo from "./Chat/Inativo";
import PaginaPaginas from "./Paginas/Pagina";
import PaginaDetalhe from "./Noticias/Detalhe";
import EventoDetalhe from "./Eventos/Detalhe";
import EventoInscricao from "./Eventos/Inscricao";
import PaginaChat from "./Chat/Main";
//import Navbar from "./Navbar/Navbar";
//import Footer from "./Footer/Footer";
import PaginaErro from "../Geral/PaginaErro";
import PoliticaPrivacidade from "./Home2/Termos/PoliticaPrivacidade";
import TermosUso from "./Home2/Termos/TermosUso"
import Contactos from "./Home2/Contactos/Contactos"


import axios from "axios";
import Navbar from "../PaginaEntidade/Home2/Navbar/Navbar";
import Footer from "../PaginaEntidade/Home2/Footer/Footer";

import Noticia from "./Home2/Noticia/Noticia";
import AreaAluno from "../PaginaEntidade/Home2/AreaAluno/AreaAluno";
import AreaAlunoRelatorio from "./Home2/AreaAluno/AreaAlunoRelatorio";
import AreaAlunoCalendario from "./Home2/AreaAluno/AreaAlunoCalendario";
import Dashboard from "../Gestao/Dashboard/Dashboard";
import Dashboard2 from "../Gestao/Dashboard/Dashboard2";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class Layout extends Component {
    static contextType = GlobalContext;
    state = {
        redessociais: [],
        fundoBranco: false,
        espacoBranco: false,
        semIniciarSessao: false,
        semFooter: false,
        entidade: "",
        estabelecimento: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    componentDidMount() {
        this.redirecionarUltimaPagina();

        this.carregarEntidadeID(this.props.match.params.entidade);

        this.setState({
            semIniciarSessao: this.props.location.pathname.split("/").pop() === "utilizador-registar-passo-2",
            espacoBranco: this.props.location.pathname.split("/").pop() === "calendarios",
            semFooter:
                this.props.match.path.split("/").pop() === ":pagina" ||
                this.props.location.pathname.split("/").pop() === "registo-conclusao" ||
                this.props.location.pathname.split("/").pop() === "pagina-erro"
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.carregarEntidadeID(this.props.match.params.entidade);
            this.setState({
                semIniciarSessao: this.props.location.pathname.split("/").pop() === "utilizador-registar-passo-2",
                espacoBranco: this.props.location.pathname.split("/").pop() === "calendarios",
                semFooter:
                    this.props.match.path.split("/").pop() === ":pagina" ||
                    this.props.location.pathname.split("/").pop() === "registo-conclusao" ||
                    this.props.location.pathname.split("/").pop() === "pagina-erro"
            });
        }
    }

    redirecionarUltimaPagina = () => {
        const path = this.props.match.path;
        if (path === "/:entidade") localStorage.setItem("ultima_pagina", this.props.match.url);
    };

    carregarEntidadeID = nome_abrev => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/carregar-entidadeid",
            params: {
                nome_abrev
            }
        })
            .then(response => {
                if (response.data) {
                    this.carregarEstabelecimento(response.data.id);
                    this.entidadeRS(response.data.id, response.data.estabelecimento);
                } else {
                    localStorage.removeItem("ultima_pagina");
                    this.setState({
                        redirect: true,
                        redirectLink: "/404"
                    });
                }
            })
            .catch(error => {});
    };

    carregarEstabelecimento = estabelecimentoId => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/carregar-estabelecimentos",
            params: {
                estabelecimentoId
            }
        })
            .then(response => {
                if (response.data) {
                    this.setState({
                        entidade: {
                            logo_entidade: response.data.logo,
                            nome_entidade: response.data.nome,
                            site_entidade: "/" + this.props.match.params.entidade,
                            login_entidade: response.data.login2 ? "login-escola" : "login",
                            imagem_propria: response.data.imagem_propria,
                            paginas: response.data.paginas,
                            estabelecimentos: response.data.estabelecimentos,
                            descricao: response.data.descricao,
                            morada: response.data.morada,
                            cod_postal: response.data.cod_postal,
                            localidade: response.data.localidade,
                            email: response.data.email,
                            contacto: response.data.contacto,
                            diretor: response.data.diretor,
                            secretario: response.data.secretario
                        }
                    });
                    if (response.data.imagem_propria)
                        this.context.atualizarState({
                            capa: response.data.capa,
                            capavideo: response.data.capavideo,
                            favicon_entidade: response.data.logo,
                            permissoes: response.data.permissoes,
                            title_entidade: response.data.nome,
                            login2_entidade: response.data.login2,
                            autoconta_entidade: response.data.autoconta,
                            escolas_entidade: response.data.escolas_entidade,
                            login_entidade: response.data.login2 ? "login-escola" : "login",
                            diario_entidade: response.data.diario
                        });
                    else
                        this.context.atualizarState({
                            capa: response.data.capa,
                            capavideo: response.data.capavideo,
                            login2_entidade: response.data.login2,
                            autoconta_entidade: response.data.autoconta,
                            escolas_entidade: response.data.escolas_entidade,
                            login_entidade: response.data.login2 ? "login-escola" : "login"
                        });
                } else
                    this.setState({
                        redirect: true,
                        redirectLink: "/"
                    });
            })
            .catch(() => {});
    };

    entidadeRS = (entidadeId, estabelecimento) => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/entidade-rs-publico",
            params: {
                entidadeId,
                estabelecimento
            }
        })
            .then(response => {
                this.setState({ redessociais: response.data });
            })
            .catch(() => {});
    };

    render() {
        const { redessociais, fundoBranco, espacoBranco, semIniciarSessao, semFooter, entidade, redirect, redirectLink } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Navbar semIniciarSessao={semIniciarSessao} semPesquisa estabelecimentos={entidade.estabelecimentos} entidade={entidade} />
                <Switch>
                    <Switch>
                        <Route path="/:entidade/informacoes/:detalhe" component={PaginaDetalhe} />
                        <Route path="/:entidade/eventos/:detalhe" component={EventoDetalhe} />
                        <Route path="/:entidade/inscricao-evento/:code" component={EventoInscricao} />
                        <Route path="/:entidade/curso/:curso/:cursoid/tarefa/:aula" component={PaginaEntidadePlayer} />
                        <Route path="/:entidade/curso/:curso/:cursoid" component={PaginaEntidadeCurso} />
                        <Route path="/:entidade/turma/:turma/:id" component={PaginaEntidadeTurma} />
                        <Route path="/:entidade/consultar-resultados" component={ConsultarResultados} />
                        <Route path="/:entidade/consultar/notas-creche/:utilizadorid" component={PaginaConsultarNotasCreche} />
                        <Route path="/:entidade/consultar/notas-pre/:utilizadorid" component={PaginaConsultarNotasPre} />
                        <Route path="/:entidade/consultar/notas-regular/:utilizadorid" component={PaginaConsultarNotasRegular} />
                        <Route path="/:entidade/consultar/:utilizadorid" component={PaginaConsultar} />
                        <Route path="/:entidade/consultar" component={PaginaEntidadeConsultar} />
                        <Route path="/:entidade/login" component={PaginaEntidadeLogin} />
                        <Route path="/:entidade/login-escola" component={PaginaEntidadeLogin2} />
                        <Route path="/:entidade/alterar-senha" component={PaginaEntidadeAlterarPassword} />
                        <Route path="/:entidade/alteracao-senha" component={PaginaEntidadeAlterarSenha} />
                        <Route path="/:entidade/recuperar-senha" component={PaginaEntidadeRecuperarPassword} />
                        <Route path="/:entidade/recuperar-senha-escola" component={PaginaEntidadeRecuperarPassword2} />
                        <Route path="/:entidade/pagina-erro" component={PaginaErro} />
                        <Route path="/:entidade/registar-passo-1" component={PaginaEntidadeRegistoPasso1} />
                        <Route path="/:entidade/registar-passo-2" component={PaginaEntidadeRegistoPasso2} />
                        <Route path="/:entidade/registo-conclusao" component={PaginaEntidadeRegistoConclusao} />
                        <Route path="/:entidade/solicitar-conta" component={PaginaEntidadeSolicitarConta} />
                        <Route path="/:entidade/criar-conta" component={PaginaEntidadeCriarConta} />
                        <Route path="/:entidade/registar-conta" component={PaginaEntidadeRegistarConta} />
                        <Route path="/:entidade/utilizador-registar-passo-2" component={PaginaRegistoPasso2} />
                        <Route path="/:entidade/utilizador-registo-conclusao" component={PaginaRegistoConclusao} />
                        <Route path="/:entidade/suporte" component={PaginaEntidadeSuporte} />
                        <Route path="/:entidade/suporte-contacto" component={PaginaEntidadeContacto} />
                        <Route path="/:entidade/informacoes" component={PaginaEntidadeInformacoes} />
                        <Route path="/:entidade/calendarios" component={PaginaEntidadeCalendarios} />
                        <Route path="/:entidade/eventos" component={PaginaEntidadeEventos} />
                        <Route path="/:entidade/cancelar-subscricao" component={PaginaEntidadeUnsubscribe} />
                        <Route path="/:entidade/vi/:token" component={PaginaEntidadeVideoConfInscricao} />
                        {/*/:entidade/videoconferencia-inscricao/:token*/}
                        <Route path="/:entidade/vsc/:token" component={PaginaEntidadeVideoConfCodigo} />
                        {/*/:entidade/videoconferencia-codigo/:token*/}
                        <Route path="/:entidade/vcc/:token" component={PaginaEntidadeVideoConfComCodigo} />
                        {/*/:entidade/videoconferencia-com-codigo/:token*/}
                        <Route path="/:entidade/videoconferencia-aguardar/:token" component={PaginaEntidadeVideoConfAguardar} />
                        <Route
                            path="/:entidade/videoconferencia/:vconfid/player-videoconferencia"
                            component={PaginaEntidadeJitsiMeetingGeral}
                        />
                        <Route path="/:entidade/politicas-privacidade" component={PoliticaPrivacidade} />
                        <Route path="/:entidade/termos-uso" component={TermosUso} />
                        <Route path="/:entidade/contactos" component={Contactos} />
                        <Route path="/:entidade/noticia/:id" component={Noticia} />
                        <Route path="/:entidade/areaAluno/:id" component={AreaAluno} />
                        <Route path="/:entidade/areaAlunoRelatorio/:id" component={AreaAlunoRelatorio} />
                        <Route path="/:entidade/areaAlunoCalendario/:id" component={AreaAlunoCalendario} />
                        <Route path="/dashboard" component={Dashboard2} />
                        <Route path="/gestao-dashboard" component={Dashboard} />
                        <Route path="/:entidade/videoconferencia/:vconfid/player-video-meets" component={PaginaVideoSimples} />
                        <Route path="/:entidade/chat-confirmacao" component={PaginaChatConfirmacao} />
                        <Route path="/:entidade/chat-codigo" component={PaginaChatCodigo} />
                        <Route path="/:entidade/chat-inativo" component={PaginaChatInativo} />
                        <Route path="/:entidade/chat" component={PaginaChat} />
                        <Route path="/:entidade/:nome/:pagina" component={PaginaPaginas} />
                        <Route path="/:entidade" component={Home} />
                    </Switch>
                </Switch>
                {!semFooter && <div style={{ height: 100, backgroundColor: !espacoBranco ? "unset" : "white" }}></div>}
                <Footer redessociais={redessociais} fundoBranco={fundoBranco} semFiltro entidade={entidade} />
            </>
        );
    }
}

export default Layout;
