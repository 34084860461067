import React, { useState, useEffect } from "react";
import { Button, Icon, notification, Modal } from "antd";
import axios from "axios";

const CardsNotas = ({ cursoId, faseId, utilizadorId }) => {
    const [record, setRecord] = useState("");
    const [periods, setPeriods] = useState([]);
    const [loading, setLoading] = useState(true);

    const listar = () => {
        axios({
            method: "get",
            url: "/api/diario/listar-notas",
            params: {
                cursoId,
                faseId,
                utilizadorId
            }
        })
            .then(response => {
                setRecord(response.data);
                setPeriods(response.data.periodos);
                setLoading(false);
            })
            .catch();
    }

    const montarAvaliacoes = (tests, testsOriginal) => {
        let html = tests.map(test => {
            var subTests = testsOriginal.filter(x => x.parentId === test.id)
            return (
                <>
                    <div key={test.id} className="nota">
                        <span className="titulo">{test.name}</span>
                        {subTests.length === 0 &&
                            <span className="texto"><strong>{test.grade}</strong></span>}
                    </div>
                    {montarAvaliacoes(subTests, testsOriginal)}
                </>
            );
        })
        let estruturaHtml = tests.length ? <div className="lista-notas"> {html}</div> : null;
        return estruturaHtml;
    }

    useEffect(() => {
        listar();
    }, [])

    if (loading)
        return (
            <div className="loading-data" style={{ minHeight: 100 }}>
                <div className="loading" />
            </div>
        );

    return (
        <>
            <div>
                <div className="bloco-cards">
                    {periods.map(period => (
                        <div key={period.id} className="card">
                            <div className="header">
                                <span className="titulo">{period.name}</span>
                            </div>
                            <div className="body">
                                {montarAvaliacoes(period.tests.filter(x => x.parentId === period.id), period.tests)}
                                <div className="notas-resultado-final">
                                    <div className="nota">
                                        <span className="titulo">NOTA BIMESTRAL</span>
                                        <span className="texto">{period.total}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="footer">
                                {period.total >= 6 ?
                                    <span className="notas-aprovado">Aprovado</span>
                                    :
                                    <span className="notas-reprovado">Reprovado</span>}
                            </div>
                        </div>
                    ))}
                    <div className="card">
                        <div className="header">
                            <span className="titulo">Geral</span>
                        </div>
                        <div className="body">
                            <div className="lista-notas">
                                {periods.map(period => (
                                    <div key={period.id} className="nota">
                                        <span className="titulo">{period.name}
                                        </span>
                                        <span className="texto">{period.total}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="notas-resultado-final">
                                <div className="nota">
                                    <span className="titulo">TOTAL GERAL</span>
                                    <span className="texto">{record.total}</span>
                                </div>
                                <div className="nota">
                                    <span className="titulo">MÉDIA ANUAL</span>
                                    <span className="texto">{record.anualEverage}</span>
                                </div>
                                <div className="nota">
                                    <span className="titulo">PROVA FINAL</span>
                                    <span className="texto">{record.finalTest}</span>
                                </div>
                                <div className="nota">
                                    <span className="titulo">MÉDIA FINAL</span>
                                    <span className="texto">{parseFloat(record.finalEverage.toFixed(2))}</span>
                                </div>
                                {record.courseYear === "9º" && !record.courseEja || record.courseYear === "10º" && record.courseEja ?
                                    <>
                                        <div className="nota">
                                            <span className="titulo">2ª CHAMADA</span>
                                            <span className="texto">{record.secondCall}</span>
                                        </div>
                                        <div className="nota">
                                            <span className="titulo">PONTUAÇÃO FINAL</span>
                                            <span className="texto">{parseFloat(record.final.toFixed(2))}</span>
                                        </div>
                                    </>
                                    :
                                    null}
                            </div>
                        </div>
                        <div className="footer">
                            {record.final >= 6 ?
                                <span className="notas-aprovado">Aprovado</span>
                                :
                                <span className="notas-reprovado">Reprovado</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardsNotas;