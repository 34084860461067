import Tabela from "./Tabela";
import React, {useContext} from "react";
import { DataContext } from '../Contexts/DataProvider';

export default function TurmasPioreNotas (){

    const {dataTurmasCompioresNotas} = useContext(DataContext);
    const columnsTurmasCompioresNotas = [
        {
            title: 'Turma',
            dataIndex: 'turma',
            key: 'turma',
            width: "25%",
        },
        {
            title: 'Escola',
            dataIndex: 'escola',
            key: 'escola',
            width: "25%",
        },
        {
            title: 'Média de Notas',
            dataIndex: 'mediaAnual',
            key: 'mediaAnual',
            width: "25%",
        },
        {
            title: 'Edição',
            dataIndex: 'edicao',
            key: 'edicao',
            width: "25%",
        },
    ];
   
    
    
    return(
        dataTurmasCompioresNotas && <Tabela data={dataTurmasCompioresNotas} columns={columnsTurmasCompioresNotas} titulo="Turmas com baixo desempenho"></Tabela>
    );

}