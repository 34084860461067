import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { Icon, notification } from "antd"
import axios from "axios"

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export class Aluno extends Component {
    state = {
        inscricoes: "",
        tarefas_visitadas: "",
        conteudos_concluidos: "",
        interacoes_forum: "",
        interacoes_anotacoes: "",
        mensagens_enviadas: "",
        tempos_tarefas: "",
        doc_fichs: "",
        cursos: "",
        ratingscursos: "",
        //ratingsaulas: "",
        avaliacoescursos: "",
        avaliacoesaulas: "",
        assiduidade: "",
        presencasStepmeets: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
    }

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.carregar()
    }

    componentDidUpdate() {
        if (this.props.atualizar) {
            this.carregar();
            this.props.paraAtualizar();
        }
    }

    //CARREGA ESTATISTICAS
    carregar = () => {
        axios({
            method: "get",
            url: "/api/estatistica/aluno",
            params: {
                dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                dtfim: this.props.dt_termino.format("YYYY/MM/DD")
            }
        })
            .then(response => {
                this.setState({
                    inscricoes: response.data.inscricoes,
                    tarefas_visitadas: response.data.tarefas_visitadas,
                    conteudos_concluidos: response.data.conteudos_concluidos,
                    interacoes_forum: response.data.interacoes_forum,
                    interacoes_anotacoes: response.data.interacoes_anotacoes,
                    mensagens_enviadas: response.data.mensagens_enviadas,
                    tempos_tarefas: response.data.tempos_tarefas,
                    doc_fichs: response.data.doc_fichs,
                   // cursos: response.data.cursos,
                    ratingscursos: response.data.ratingscursos,
                    //ratingsaulas: response.data.ratingsaulas,
                    avaliacoescursos: response.data.avaliacoescursos,
                    avaliacoesaulas: response.data.avaliacoesaulas,
                    assiduidade: response.data.assiduidade,
                    presencasStepmeets: response.data.presencasStepmeets,
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            inscricoes,
            tarefas_visitadas,
            conteudos_concluidos,
            interacoes_forum,
            interacoes_anotacoes,
            mensagens_enviadas,
            tempos_tarefas,
            doc_fichs,
            //cursos,
            ratingscursos,
            ratingsaulas,
            avaliacoescursos,
            avaliacoesaulas,
            assiduidade,
            presencasStepmeets,
            //REDIRECT
            redirect,
            redirectLink,
        } = this.state

        if (redirect) return <Redirect to={redirectLink} />

        return (
            <div className="dashboard" style={{ borderRadius: "0 0 4px 0" }}>
                <div className="bloco">
                    <div className="grid">
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Inscrições realizadas em novos Cursos", "")}>
                            <Icon type="team" />
                            <span className="desc">{inscricoes}</span>
                            <span className="titulo">Novas Inscrições</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas visitadas nos cursos", "")}>
                            <Icon type="desktop" />
                            <span className="desc">{tarefas_visitadas}</span>
                            <span className="titulo">Tarefas visitadas</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tempo de consulta nas tarefas visitadas nos cursos", "")}>
                            <Icon type="history" />
                            <span className="desc">{tempos_tarefas}</span>
                            <span className="titulo">Tempo nas tarefas</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas concluídas nos cursos", "")}>
                            <Icon type="check" />
                            <span className="desc">{conteudos_concluidos}</span>
                            <span className="titulo">Tarefas concluídas</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Ficheiros adicionados na drive", "")}>
                            <Icon type="file-add" />
                            <span className="desc">{doc_fichs}</span>
                            <span className="titulo">Ficheiros</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Anotações pessoais adicionadas", "")}>
                            <Icon type="file" />
                            <span className="desc">{interacoes_anotacoes}</span>
                            <span className="titulo">Anotações</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Mensagens registadas em conversações", "")}>
                            <Icon type="message" />
                            <span className="desc">{mensagens_enviadas}</span>
                            <span className="titulo">Mensagens enviadas</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Questões submetidas em fóruns", "")}>
                            <Icon type="form" />
                            <span className="desc">{interacoes_forum}</span>
                            <span className="titulo">Interações forúm</span>
                        </div>
                        {/*<div className="item" onClick={() => openNotificationWithIcon("info", "Ratings registados nas tarefas dos cursos", "")}>
                            <Icon type="number" />
                            <span className="desc">{ratingsaulas}</span>
                            <span className="titulo">Ratings/média (tarefas)</span>
                        </div>*/}
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Classificações obtidas nas tarefas dos cursos (quando aplicável)", "")}>
                            <Icon type="check-circle" />
                            <span className="desc">{avaliacoesaulas}</span>
                            <span className="titulo">Classificações/média (tarefas)</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Ratings registados nos cursos (após a sua conclusão)", "")}>
                            <Icon type="number" />
                            <span className="desc">{ratingscursos}</span>
                            <span className="titulo">Ratings/média (cursos)</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Classificações obtidas nos cursos (após a sua conclusão)", "")}>
                            <Icon type="check-circle" />
                            <span className="desc">{avaliacoescursos}</span>
                            <span className="titulo">Classificações/média (cursos)</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Assiduidade em sessões presencais com registo", "")}>
                            <Icon type="user" />
                            <span className="desc">{assiduidade}</span>
                            <span className="titulo">Presenças/Total<br />(frequência)</span>
                        </div>
                        <div className="item" onClick={() => openNotificationWithIcon("info", "Presenças em stepmeets realizadas", "")}>
                            <Icon type="video-camera" />
                            <span className="desc">{presencasStepmeets}</span>
                            <span className="titulo">Presenças/Total<br />(Stepmeets)</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Aluno
