import React, {useEffect, useState} from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import {FaArrowLeft} from "react-icons/fa";
import TabelaRelatorioAluno from "./Components/TabelaRelatorioAluno";
import TabelaRelatorioAlunoMobile from "./Components/TabelaRelatorioAlunoMobile";
import BarraAreaAlunoRelatorio from "./Components/BaraAreaAlunoRelatorio";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import NomeBara from "./Components/NomeBara";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import {
    notification
} from "antd";
import DateChanger from "./Components/DateChanger";
import TabelaCalendario from "./Components/TabelaCalendario";
import TabelaCalendarioMobile from "./Components/TabelaCalendarioMobile";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export default function AreaAlunoCalendario() {
    // const { entidade, id: turmaId } = useParams();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const [dynamicColumns, setDynamicColumns] = useState([])
    const [dados, setDados] = useState([])
    const [data, setData] = useState(new Date());
    
    const handlerClickData = (data) => {
        setData(data)
        
    }

    //useEffect(() => {
    //    console.log(data)
    //}, [data]);

    return(
        <div className="areaAlunoRelatorio">
            <div className="titulo">
                <h1> ÁREA DO ALUNO</h1>
            </div>

            <BarraAreaAlunoRelatorio title={"CALENDÁRIO DE AULAS"}></BarraAreaAlunoRelatorio>
            
            <DateChanger  nome={localStorage.getItem('nome')} alterarData={handlerClickData} data={data}></DateChanger>
            
            {isTabletOrMobile ? (
                <div>
                <TabelaCalendarioMobile dynamicColumns={dynamicColumns} disciplinas={dados} alterarData={handlerClickData} data={data}/>
               
                </div>
            ) : (
                <div style={{backgroundColor:"white", padding:"30px"}}>
                    <TabelaCalendario dynamicColumns={dynamicColumns} disciplinas={dados} alterarData={handlerClickData} data={data}/>
                </div>
               
            )}
        </div>
    );
}