import React, { useState, useEffect } from "react";
import { Icon } from "antd";
import axios from "axios";

const CardsNotasConceito = ({ cursoId, faseId, utilizadorId }) => {
    const [periodos, setPeriodos] = useState([]);
    const [loading, setLoading] = useState(true);

    const listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-conceito-aluno",
            params: {
                cursoId,
                faseId,
                utilizadorId
            }
        })
            .then(response => {
                setPeriodos(response.data.periodos);
                setLoading(false);
            })
            .catch();
    }

    const montarParametros = (niveis, avaliacoesFiltradas, listaAvaliacoesOriginal) => {
        const parametros = avaliacoesFiltradas.map((avaliacao, index) => {
            let lista = [];
            const subParametros = listaAvaliacoesOriginal.filter(x => x.idPai === avaliacao.id);
            if (subParametros.length > 0) {
                lista = montarParametros(`${niveis}${index + 1}.`, subParametros, listaAvaliacoesOriginal)
            }

            return <>
                {subParametros.length === 0 ?
                    <div className="ponto">
                        <span className="ponto-titulo">{niveis + (index + 1)} - {avaliacao.titulo}</span>
                        <div className="lista-notas">
                            <div className="nota" style={{ justifyContent: "left" }}>
                                <span className="titulo">Nota:</span>
                                <span className="texto">{avaliacao.nota ? avaliacao.nota : "-"}</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="grupo">
                        <span className="ponto-titulo">{niveis + (index + 1)} - {avaliacao.titulo}</span>
                    </div>
                }
                {lista.map(item => item)}
            </>
        });

        return parametros;
    }

    useEffect(() => {
        listar();
    }, [])

    if (loading)
        return (
            <div className="loading-data" style={{ minHeight: 100 }}>
                <div className="loading" />
            </div>
        );

    return (
        <div>
            <div className="bloco-cards">
                {periodos.map(periodo => (
                    <div key={periodo.id} className="card">
                        <div className="header">
                            <span className="titulo">{periodo.titulo}</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                {montarParametros("", periodo.avaliacoes.filter(x => x.idPai === periodo.id), periodo.avaliacoes)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CardsNotasConceito;