import React, { useState, useEffect } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Modal, Form, Upload, notification, Icon, Tooltip, Input, Select, Checkbox, Radio } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import noimage from "../../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const ModalFicha = ({ visible, onClose, atualizar, form, match }) => {
    const [ficheiro, setFicheiro] = useState("");
    const [codigo, setCodigo] = useState("");
    const [loading, setLoading] = useState(false);

    const confirmar = (event) => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let item = new FormData()
                item.append("disciplinaId", localStorage.getItem("codigo_curso"))
                item.append("code", match.params.code)
                item.append("codigo", codigo)

                axios({
                    method: "put",
                    url: "/api/turma/validar-pauta",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        })
    }

    const gerarDoc = () => {
        axios({
            method: "get",
            url: "/api/pdf/notas-exame",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                code: match.params.code,
                isValidating: true
            }
        })
            .then(response => {
                setFicheiro(response.data)
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (visible)
            gerarDoc();
    }, [visible])

    const { getFieldDecorator } = form;

    return (
        <Modal
            title=""
            className="modal-validar-ficha"
            visible={visible}
            onOk={confirmar}
            okText="Confirmar"
            onCancel={onClose}
            cancelText="Cancelar"
            okButtonProps={{ disabled: !ficheiro }}
        >
            <div className="bloco" style={{ marginBottom: 30 }}>
                <h3>Pauta</h3>
                {!ficheiro ?
                    <span>A gerar documento...</span>
                    :
                    <a href={ficheiro} download="Ficha.pdf">Descarregar</a>
                }
            </div>
            <div className="bloco">
                <h3>Inserir código de validação</h3>
                <Form className="form-categorias" layout="horizontal" style={{padding: 0}}>
                    <div className="bloco">
                        <Form.Item>
                            {getFieldDecorator("codigo", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: codigo
                            })(<Input name="codigo" disabled={!ficheiro} onChange={event => setCodigo(event.target.value)} />)}
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}

const FormModalFicha = Form.create({ name: "form-ficha" })(ModalFicha);

export default withRouter(FormModalFicha);
