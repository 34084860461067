import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Select, Dropdown, Menu, Icon, Switch, notification, Modal, Drawer, Form } from "antd";
import Header from "../User/Header";
import noimage from "../images/noimage.png";
import axios from "axios";
import moment from "moment";
import BotaoProcurar from "../Geral/_Aux/BotaoProcurar";
import { GlobalContext } from "../GlobalState";

import ModalGerarDocumento from "../Componentes/Modals/GerarDocumento";

import { downloadFile } from '../Utilities/FileDownloader';

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class SistemaInformacao extends Component {
    static contextType = GlobalContext;
    state = {
        //UTILIZADOR
        utilizador: "",
        //FILTROS
        pesquisa: "",
        filtro: 1,
        ativo: true,
        //TABELA
        loading_table: true,
        cursos: [],
        cursosMobile: [],
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalhePeriodo: "",
        detalheEstado: "",
        detalheClassificacao: "",
        detalheDocumento: ""
    };

    UNSAFE_componentWillMount() {
        this.setState(
            {
                utilizador: JSON.parse(localStorage.getItem("sis_utilizador"))
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    }

    //VALIDA SE O UTILIZADOR ESTA AUTENTICADO
    componentDidUpdate() {
        if (!this.context.is_authenticated || this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR") {
            if (!this.context.is_authenticated)
                this.setState({
                    redirect: true,
                    redirectLink: "/login"
                });
            //else if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
            //    this.setState({
            //        redirect: true,
            //        redirectLink: "/user"
            //    });
        }
    }

    handleChangeTable = (pagination, filters, sorter) => {
        let _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                this.listarCursos();
            }
        );
    };

    handleChangeFiltro = value => {
        this.setState(
            {
                loading_table: true,
                cursos: [],
                filtro: value
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        let pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    this.totalCursos();
                    this.listarCursos();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    abrirDetalhe = (curso) => {
        const detalhePeriodo = curso.dt_fim
            ? `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
            : moment(curso.dt_inicio).format("DD/MM/YYYY");

        const detalheEstado = curso.fechado ? <div>Fechado<br />{curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")}</span>}</div> : "";
        const detalheClassificacao = curso.classificacao;
        const detalheDocumento = curso.documento ? (<Link to="#" className="botao-icon-download" title="Descarregar certificado" onClick={() => this.downloadFicheiro(curso.id)}>
            <Icon type="download" />
        </Link>)
            : "";

        this.setState({
            visibleDetalhe: true,
            detalhePeriodo,
            detalheEstado,
            detalheClassificacao,
            detalheDocumento
        })
    }

    //// MONTA O MENU DE OPÇÕES POR CADA CURSO
    //montarMenuFiltro1 = curso => {
    //    return (
    //        <Menu>
    //            <Menu.Item>
    //                <Link
    //                    to={"/gestao-utilizadores-resultados/" + this.props.match.params.utilizador}
    //                    onClick={() => {
    //                        localStorage.removeItem("nome_curso_pai");
    //                        localStorage.setItem("cod_curso", curso.codigo);
    //                        localStorage.setItem("nome_curso", curso.nome);
    //                        localStorage.setItem("codigo_curso", curso.id);
    //                        localStorage.setItem("habilitar_competencias", curso.habilitar_competencias);
    //                    }}
    //                >
    //                    Resultados
    //                </Link>
    //            </Menu.Item>
    //        </Menu>
    //    );
    //};

    //montarMenuFiltro1Mobile = curso => {
    //    return (
    //        <Menu>
    //            <Menu.Item>
    //                <Link to="#" onClick={() => this.abrirDetalhe(curso)}>
    //                    Detalhe
    //                    </Link>
    //            </Menu.Item>
    //            {curso.documento ?
    //                <Menu.Item>
    //                    <Link to="#" onClick={() => this.downloadFicheiro(curso.id)}>
    //                        Descarregar
    //                </Link>
    //                </Menu.Item>
    //                : null}
    //        </Menu>
    //    );
    //};

    // MONTA O MENU DE OPÇÕES POR CADA DISCIPLINA
    montarMenuFiltro2Sub = (subcurso, cursoCodigo, cursoNome) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link
                        to={"/gestao-utilizadores-resultados/" + this.props.match.params.utilizador}
                        onClick={() => {
                            localStorage.setItem("cod_curso_pai", cursoCodigo);
                            localStorage.setItem("nome_curso_pai", cursoNome);
                            localStorage.setItem("cod_subcursoi", subcurso.codigo);
                            localStorage.setItem("nome_subcurso", subcurso.nome);
                            localStorage.setItem("codigo_curso", subcurso.id);
                            localStorage.setItem("habilitar_competencias", subcurso.habilitar_competencias);
                        }}
                    >
                        Resultados
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //montarMenuFiltro2SubMobile = (subcurso, cursoCodigo, cursoNome) => {
    //    return (
    //        <Menu>
    //            <Menu.Item>
    //                <Link to="#" onClick={() => this.abrirDetalhe(subcurso)}>
    //                    Detalhe
    //                    </Link>
    //            </Menu.Item>
    //            <Menu.Item>
    //                <Link
    //                    to={"/gestao-utilizadores-resultados/" + this.props.match.params.utilizador}
    //                    onClick={() => {
    //                        localStorage.setItem("cod_curso_pai", cursoCodigo);
    //                        localStorage.setItem("nome_curso_pai", cursoNome);
    //                        localStorage.setItem("cod_subcursoi", subcurso.codigo);
    //                        localStorage.setItem("nome_subcurso", subcurso.nome);
    //                        localStorage.setItem("codigo_curso", subcurso.id);
    //                        localStorage.setItem("habilitar_competencias", subcurso.habilitar_competencias);
    //                    }}
    //                >
    //                    Resultados
    //                </Link>
    //            </Menu.Item>
    //        </Menu>
    //    );
    //};

    //TOTAL CURSOS DA ENTIDADE
    totalCursos = () => {
        axios({
            method: "get",
            url: "/api/colaboradores-sistema-informacao/total-cursos",
            params: {
                utilizadorId: this.state.utilizador.id,
                pesquisa: this.state.pesquisa,
                filtro: this.state.filtro,
                ativo: this.state.ativo
            }
        })
            .then(response => {
                let _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarCursos = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/colaboradores-sistema-informacao/listar-cursos",
                    params: {
                        utilizadorId: this.state.utilizador.id,
                        pesquisa: this.state.pesquisa,
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current,
                        filtro: this.state.filtro,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        let cursos = [];
                        let cursosMobile = [];

                        response.data.map((curso, index) => {
                            cursos.push({
                                key: index,
                                id: curso.id,
                                curso: (
                                    <>
                                        <div className="imagem-curso">
                                            <img src={curso.imagem ? curso.imagem : noimage} />
                                        </div>
                                        <div className="nome-curso">
                                            <span className="titulo-curso">
                                                {curso.codigo} - {curso.nome}
                                            </span>
                                        </div>
                                    </>
                                ),
                                codigo: curso.codigo,
                                nome: curso.nome,
                                periodo: (
                                    <div>
                                        <span>{
                                            curso.dt_fim ?
                                                `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                                                :
                                                moment(curso.dt_inicio).format("DD/MM/YYYY")
                                        }</span>
                                        {curso.fechado ?
                                            <div className="tag-fechado">Fechado ({curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}</div>
                                            : ""}
                                    </div>
                                ),
                                dt_inicio: curso.dt_inicio,
                                dt_fim: curso.dt_fim,
                                classificacao: curso.classificacao,
                                descarregar: curso.documento ? (
                                    <Link to="#" className="botao-icon-download" title="Descarregar certificado" onClick={() => this.downloadFicheiro(curso.id)}>
                                        <Icon type="download" />
                                    </Link>
                                ) : (
                                    ""
                                ),
                                subcursos: curso.subcursos ? curso.subcursos : []
                            });

                            cursosMobile.push({
                                key: index,
                                id: curso.id,
                                curso: (
                                    <>
                                        <div className="imagem-curso">
                                            <img src={curso.imagem ? curso.imagem : noimage} />
                                        </div>
                                        <div className="nome-curso">
                                            <span className="titulo-curso">
                                                {curso.codigo} - {curso.nome}
                                            </span>
                                        </div>
                                    </>
                                ),
                                codigo: curso.codigo,
                                nome: curso.nome,
                                periodo: curso.dt_fim
                                    ? `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                                    : moment(curso.dt_inicio).format("DD/MM/YYYY"),
                                dt_inicio: curso.dt_inicio,
                                dt_fim: curso.dt_fim,
                                estado: curso.fechado ? <div>Fechado<br />{curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")}</span>}</div> : "",
                                classificacao: curso.classificacao,
                                descarregar: curso.documento ? (
                                    <Link to="#" className="botao-icon-download" title="Descarregar certificado" onClick={() => this.downloadFicheiro(curso.id)}>
                                        <Icon type="download" />
                                    </Link>
                                ) : null,
                                opcoes: <Link to="#" className="botao-icon-detalhe" title="Detalhe" onClick={() => this.abrirDetalhe(curso)}>
                                        <Icon type="eye" />
                                    </Link>,
                                subcursos: curso.subcursos ? curso.subcursos : []
                            });
                        });

                        this.setState({
                            cursos,
                            cursosMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarSubCursos = (subcursos, cursoCodigo, cursoNome) => {
        let _subcursos = [];

        subcursos.map(subcurso => {
            _subcursos.push({
                key: subcurso.id,
                id: subcurso.id,
                curso: (
                    <>
                        <div className="imagem-curso">
                            <img src={subcurso.imagem ? subcurso.imagem : noimage} />
                        </div>
                        <div className="nome-curso">
                            <span className="titulo-curso">
                                {subcurso.codigo} - {subcurso.nome}
                            </span>
                        </div>
                    </>
                ),
                periodo: `${moment(subcurso.dt_inicio).format("DD/MM/YYYY")} - ${moment(subcurso.dt_fim).format("DD/MM/YYYY")}`,
                estado: subcurso.fechado ? "Fechado" : "",
                classificacao: subcurso.classificacao,
                descarregar: subcurso.documento ? (
                    <Link to="#" className="botao-icon-download" title="Descarregar certificado" onClick={() => this.downloadFicheiro(subcurso.id)}>
                        <Icon type="download" />
                    </Link>
                ) : (
                    ""
                ),
                ativo: subcurso.ativo
            });
        });

        return _subcursos;
    };

    listarSubCursosMobile = (subcursos, cursoCodigo, cursoNome) => {
        let _subcursos = [];

        subcursos.map(subcurso => {
            _subcursos.push({
                key: subcurso.id,
                id: subcurso.id,
                curso: (
                    <>
                        <div className="imagem-curso">
                            <img src={subcurso.imagem ? subcurso.imagem : noimage} />
                        </div>
                        <div className="nome-curso">
                            <span className="titulo-curso">
                                {subcurso.codigo} - {subcurso.nome}
                            </span>
                        </div>
                    </>
                ),
                opcoes: <Link to="#" className="botao-icon-detalhe" title="Detalhe" onClick={() => this.abrirDetalhe(subcurso)}>
                    <Icon type="eye" />
                </Link>,
                ativo: subcurso.ativo
            });
        });

        return _subcursos;
    };

    montarMenuExportar = utilizadorid => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarListagemCursos(utilizadorid)}>
                        Exportar
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    exportarListagemCursos = utilizadorid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/listagem-cursos",
                    params: {
                        pesquisa: this.state.pesquisa,
                        utilizadorid,
                        ativo: this.state.ativo,
                        filtro: this.state.filtro
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    downloadFicheiro = (cursoId) => {
        axios({
            method: "post",
            url: "/api/colaboradores/download-ficheiro-curso",
            params: {
                cursoId,
                utilizadorId: this.state.utilizador.id
            }
        })
            .then(response => {
                const fileUrl = response.data.url;
                const fileName = response.data.nome;

                downloadFile(fileName, fileUrl);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    render() {
        const {
            //UTILIZADOR
            utilizador,
            //FILTROS
            filtro,
            //TABELA
            loading_table,
            cursos,
            cursosMobile,
            //PAGINATION
            pagination,
            //REDIRECT
            redirect,
            redirectLink,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //DRAWER DETALHE
            visibleDetalhe,
            detalhePeriodo,
            detalheEstado,
            detalheClassificacao,
            detalheDocumento
        } = this.state;

        const columnsCursos = [
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-curso td-265"
            },
            {
                title: "Periodo",
                dataIndex: "periodo",
                className: "td-300"
            },
            {
                title: "Estado",
                dataIndex: "estado",
                className: "td-155"
            },
            {
                title: "Classificação",
                dataIndex: "classificacao"
            },
            {
                title: "",
                dataIndex: "descarregar",
                className: "td-50"
            }
        ];

        const columnsCursosMobile = [
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-curso td-265"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsCursosComDisciplinas = [
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-curso td-265"
            },
            {
                title: "Periodo",
                dataIndex: "periodo",
                className: "td-300"
            },
            {
                title: "Categoria",
                dataIndex: "categoria",
                className: "td-155"
            },
            {
                title: "Classificação",
                dataIndex: "classificacao",
                className: "td-165"
            },
            {
                title: "",
                dataIndex: "descarregar",
                className: "td-50"
            },
        ];

        const columnsCursosComDisciplinasMobile = [
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-curso td-265"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50 td-opcoes-lvl1"
            }
        ];

        const columnsDisciplinas = [
            {
                title: "Disciplina",
                dataIndex: "curso",
                className: "td-curso td-250",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "periodo",
                className: "td-295",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "categoria",
                className: "td-160",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "classificao",
                className: "td-160",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "descarregar",
                className: "td-50"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsDisciplinasMobile = [
            {
                title: "Disciplina",
                dataIndex: "curso",
                className: "td-curso td-250",
                render: (text, record) => (!record.ativo ? <div className="inativo">{text}</div> : text)
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-sistema-informacao bloco-cursos-utilizador">
                <Header titulo="Cursos" />
                <div className="container container-body">
                    <div className="controlos" style={{ background: "white", borderRadius: "4px 4px 0 0", padding: 30 }}>
                        <div className="bloco-flex">
                            <div className="bloco-info" style={{ padding: 0 }}>
                                <div className="dados-user">
                                    <div className="container-img">
                                        <img src={utilizador.foto ? utilizador.foto : noimage} alt="" />
                                    </div>
                                    <div>
                                        <h6 style={{ marginBottom: 0 }}>{utilizador.nome}</h6>
                                        <span style={{ fontSize: 12 }}>{utilizador.numero}</span>
                                    </div>
                                </div>
                            </div>
                            <Link to="/gestao-utilizadores-todos" className="botao-principal">
                                Voltar
                            </Link>
                        </div>
                    </div>
                    <div className="bloco-cursos">
                        <div className="bloco-tabela">
                            <div className="filtros filtros-tabela-cursos filtros-tabela-cursos-utilizadores" style={{ justifyContent: "space-between" }}>
                                <div className="bloco-esquerda">
                                    <BotaoProcurar
                                        tem_placeholder
                                        placeholder="Procurar por Curso"
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                    {this.context.disciplinas_entidade &&
                                        <Select defaultValue={1} className="filtro-tipos-curso" onChange={this.handleChangeFiltro}>
                                            <Option value={1}>Cursos</Option>
                                            <Option value={2}>Cursos com disciplinas</Option>
                                        </Select>}
                                </div>
                                <div className="bloco-direita">
                                    <Switch
                                        checkedChildren="Ativos"
                                        unCheckedChildren="Inativos"
                                        defaultChecked
                                        onChange={this.handleChangeSwitch}
                                    />
                                    <Link to="#" className="botao-icon-download" onClick={() => this.exportarListagemCursos(this.state.utilizador.id)} title="Exportar">
                                        <Icon type="download" />
                                    </Link>
                                    {this.context.diario_entidade &&
                                        <Link to={`/gestao-utilizadores-sistema-informacao/consultar/${this.props.match.params.utilizador}`} className="botao-icon-historico" title="Histórico">
                                            <Icon type="solution" />
                                        </Link>}
                                </div>
                            </div>
                            {filtro === 2 ? (
                                <>
                                    <Table
                                        id="cursos-disciplinas"
                                        className="curso-disciplinas-desktop"
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        columns={columnsCursosComDisciplinas}
                                        dataSource={cursos}
                                        pagination={pagination}
                                        locale={{ emptyText: "Não existem dados!" }}
                                        onChange={this.handleChangeTable}
                                        expandedRowRender={(record, index) => (
                                            <Table
                                                id="disciplinas"
                                                columns={columnsDisciplinas}
                                                dataSource={this.listarSubCursos(record.subcursos, record.codigo, record.nome)}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem dados!" }}
                                            />
                                        )}
                                    />
                                    <Table
                                        id="cursos-disciplinas"
                                        className="curso-disciplinas-mobile"
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        columns={columnsCursosComDisciplinasMobile}
                                        dataSource={cursosMobile}
                                        pagination={pagination}
                                        locale={{ emptyText: "Não existem dados!" }}
                                        onChange={this.handleChangeTable}
                                        expandedRowRender={(record, index) => (
                                            <Table
                                                id="disciplinas"
                                                columns={columnsDisciplinasMobile}
                                                dataSource={this.listarSubCursosMobile(record.subcursos, record.codigo, record.nome)}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem dados!" }}
                                            />
                                        )}
                                    />
                                </>
                            ) : (
                                    <>
                                        <Table
                                            id="tabela-cursos"
                                            className="tabela-cursos-desktop"
                                            loading={{
                                                spinning: loading_table,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columnsCursos}
                                            dataSource={cursos}
                                            pagination={pagination}
                                            locale={{ emptyText: "Não existem dados!" }}
                                            onChange={this.handleChangeTable}
                                        />
                                        <Table
                                            id="tabela-cursos"
                                            className="tabela-cursos-mobile"
                                            loading={{
                                                spinning: loading_table,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columnsCursosMobile}
                                            dataSource={cursosMobile}
                                            pagination={pagination}
                                            locale={{ emptyText: "Não existem dados!" }}
                                            onChange={this.handleChangeTable}
                                        />
                                    </>
                            )}
                        </div>
                    </div>
                </div>
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
                <Drawer
                    className="drawer-detalhe-curso"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Periodo">
                                    {detalhePeriodo}
                                </Form.Item>
                                {detalheEstado &&
                                    <Form.Item label="Estado">
                                        {detalheEstado}
                                    </Form.Item>}
                                {detalheClassificacao &&
                                    <Form.Item label="Classificação">
                                        {detalheClassificacao}
                                    </Form.Item>}
                                {detalheDocumento &&
                                    <Form.Item label="Certificado">
                                        {detalheDocumento}
                                    </Form.Item>}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default SistemaInformacao;
