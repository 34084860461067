import React, { Component } from "react";
import { withRouter } from "react-router-dom"
import { Icon, notification, Modal } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ExcelTabelaNotasRegular extends Component {
    state = {
        num: 1,
        total: 0,
        //PERIODO
        grupos: [],
        periodos: [],
        //LOADING
        iconLoading: false
    }

    componentDidMount() {
        this.listar();
    }

    listar = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/consultar-notas/listar-notas-pre",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    utilizadorId: this.props.match.params.utilizadorid
                }
            })
                .then(response => {
                    if (response.data.length > 0) {
                        let grupos = [];
                        if (response.data.length > 1)
                            grupos = response.data[0].grupos;

                        this.setState({
                            grupos,
                            periodos: response.data,
                            iconLoading: false
                        })
                    }
                    else {
                        this.setState({
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Curso sem periodos registados!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a informação!");
                });
        })
    }

    render() {
        const {
            grupos,
            periodos,
            //LOADING
            iconLoading
        } = this.state;

        const montarAvaliacoes = (grupo, grupoIndex) => {
            const avaliacoes = grupo.avaliacoes.map((avaliacao, index) => {
                return <tr className="tabela-body">
                    <td className="td-border-right" align="left" valign="middle"><p color="#000000">{avaliacao.titulo}</p></td>
                    {periodos.map((periodo, periodoIndex) => {
                        const _grupo = periodo.grupos[grupoIndex];

                        let nota = "";
                        if (_grupo !== null) {
                            const _avaliacao = _grupo.avaliacoes[index];
                            if (_avaliacao !== null)
                                nota = _avaliacao.nota;
                        }

                        return <>
                            <td className="celula-tabela-notas-pre" align="left" valign="bottom" ><div>{nota === "S" ? <Icon type="check-square" /> : <Icon type="border" />}</div></td>
                            <td className="celula-tabela-notas-pre" align="left" valign="bottom" ><div>{nota === "P" ? <Icon type="check-square" /> : <Icon type="border" />}</div></td>
                            <td className={`celula-tabela-notas-pre ${periodoIndex + 1 < periodos.length ? "td-border-right" : ""}`} align="left" valign="bottom" ><div>{nota === "N" ? <Icon type="check-square" /> : <Icon type="border" />}</div></td>
                        </>
                    })}
                </tr>
            });

            return avaliacoes;
        }

        return (
            <>
                <div>
                    {grupos.map((grupo, index) => (
                        <table className="tabela-notas-pre tabela-notas-regular" cellSpacing={0} border={0} style={{ width: "100%" }}>
                            <tbody>
                                <tr className="tabela-head">
                                    <td align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">{grupo.titulo}</font></b></td>
                                    {periodos.map(periodo => (
                                        <td colSpan={3} align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                                    ))}
                                </tr>
                                <tr>
                                    <td align="center" valign="middle"><b><font color="#000000">Conceituais - Procedimentais e Atitudinais</font></b></td>
                                    {periodos.map(periodo => (
                                        <>
                                            <td align="center" valign="middle"><b><font color="#000000">S</font></b></td>
                                            <td align="center" valign="middle"><b><font color="#000000">P</font></b></td>
                                            <td align="center" valign="middle"><b><font color="#000000">N</font></b></td>
                                        </>
                                    ))}
                                </tr>
                                {montarAvaliacoes(grupo, index)}
                            </tbody>
                        </table>
                    ))}
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(ExcelTabelaNotasRegular);