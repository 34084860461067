import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Controlos from "./_Aux/Controlos";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import screenfull from "screenfull";
import { Icon } from "antd";

class Pagina extends Component {
    constructor(props) {
        super(props);
        this.state = {
            html: "",
            fullscreen: false,
        };

        this.elementFullScreen = React.createRef();
    }

    componentDidMount() {
        this.CarregarConteudo();
    }

    //CARREGA CONTEUDO DA AULA
    CarregarConteudo = () => {
        axios({
            method: "get",
            url: "/api/curso-fichs/carregar-ficheiro",
            params: {
                ficheiroId: localStorage.getItem("codigo_documento")
            }
        })
            .then(response => {
                this.setState({
                    html: response.data
                });
            })
            .catch(() => { });
    };

    onClickFullscreenElement = () => {
        screenfull.toggle(this.elementFullScreen.current);
        this.setState({ fullscreen: !this.state.fullscreen });
    };

    render() {
        return (
            <div>
                <div className="pagina-html">
                    <div className="player-wrapper">
                        {/*<LazyLoad offsetVertical={200}>*/}
                        {/*    <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                        {/*</LazyLoad>*/}
                        <div className="container" ref={this.elementFullScreen}>
                            <div className="bloco-html">
                                <Icon
                                    type={this.state.fullscreen ? "fullscreen-exit" : "fullscreen"}
                                    onClick={this.onClickFullscreenElement}
                                    className="btn-fullscreen"
                                />
                                <div className="bloco-info" dangerouslySetInnerHTML={{ __html: this.state.html }}></div>
                            </div>
                        </div>
                        <Controlos />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Pagina);
