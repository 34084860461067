import '../../../scss/_carousel.scss';
import React from 'react';
import slide1 from '../../../images/Caroucel/c1.jpeg';
import slide2 from '../../../images/Caroucel/c2.jpg';
import { AiOutlineClose } from "react-icons/ai";



const ModalVideo = ({handlerClose, card}) => {
    return (
        <div className="modalvideo">
            <button className="botaoClose" onClick={handlerClose}>
                <AiOutlineClose color="#FFFFFF" size="20px"/>
            </button>
            <div >
                <iframe className="frame" src={`https://player.vimeo.com/video/${card.idVideo}`} id="player" data-ready="true"></iframe>
            </div>
        </div>
    );
};

export default ModalVideo;
