import React from "react";
import { Link } from "react-router-dom";
import { Collapse } from "antd";

const Indice = props => {
    const { Panel } = Collapse;

    return (<li className="opcao-tutoriais">
        <Collapse defaultActiveKey={props.collapsed ? ['0'] : []}>
            <Panel header={props.nivel.nome}>
                {props.nivel.indices.map((indice, index) => (
                    <p className={props.opcao === indice.id ? "ativo" : ""} key={index} onClick={() => props.listarPerguntasRespostas(indice.id)}>{indice.tema}</p>
                ))}
            </Panel>
        </Collapse>
    </li>);
};

export default Indice;
