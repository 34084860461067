import React from "react";
import { Icon, Modal } from "antd";

const ModalAssociar = ({ visibleAssociar, totalAssociado, totalUsuarios, totalErros }) => {
    return (
        <Modal visible={visibleAssociar} maskClosable={false} className="modal-associar" closable={false} footer={null}>
            <div className="modal-associar-bloco">
                <p>
                    <Icon type="loading" />
                </p>
                <p className="texto">A associar usuários...</p>
                <p className="sub-texto">Usuários associados: {totalAssociado} / {totalUsuarios}</p>
                {totalErros > 0 &&
                    <p className="sub-texto">Usuários não associados: {totalErros}</p>}
            </div>
        </Modal>
    );
};

export default ModalAssociar;
