import React, { Component } from "react"
import { Link, withRouter, Redirect } from "react-router-dom"
import { Menu, Table, Dropdown, Modal, notification, Form, Drawer, Icon } from "antd"
import Header from "../User/Header"
import DrawerRelatorio from "./_Aux/DrawerRelatorio";
import axios from "axios"
import moment from "moment"
import { GlobalContext } from "../GlobalState"
import noimage from "../images/noimage.png"

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    })
}

class CursosResultados extends Component {
    static contextType = GlobalContext
    state = {
        cursoid: 0,
        utilizador: "",
        questoes: [],
        utilizadores: [],
        avaliacoesCompetencias: [],
        avaliacoesCompetenciasMobile: [],
        tarefas: [],
        tarefasMobile: [],
        outras_avaliacoes: [],
        outras_avaliacoesMobile: [],
        relatorios_progresso: [],
        relatorios_progressoMobile: [],
        estatisticas: "",
        //FILTRO
        pesquisa: "",
        dt_inicio: "",
        dt_fim: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING
        loading_table: false,
        loading_table_competencias: false,
        loading_table_avaliacoes: false,
        loading_table_questionarios: false,
        loading_table_trabalhos: false,
        loading_table_outras: false,
        loading_table_lvl3: false,
        //TIPO RESULTADO
        tipo_resultado: "",
        temp_record: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //DRAWER RELATORIO
        visibleDrawerRelatorio: false,
        editarRelatorio: false,
        //TEMP
        expanded: false,
        record: "",
        //DRAWER DETAHE
        visibleDetalhe: false,
        visibleDetalheRelatorio: false,
        detalheRegisto: "",
        detalheClassificacao: "",
        detalheProgresso: "",
        detalheDescarregar: "",
        detalheNotificacao: ""
    }

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.setState(
            {
                cursoid: localStorage.getItem("codigo_curso"),
                utilizador: JSON.parse(localStorage.getItem("sis_utilizador"))
            },
            () => {
                this.listar()
                if (localStorage.getItem("habilitar_competencias") === "true")
                    this.listarAvaliacaoCompetencias()
                this.listarTodasAvaliacoes();
                this.listarOutrasAvaliacoes()
                this.listarRelatoriosProgresso()
                this.listarEstatisticas()
            }
        );
    }

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleChangeDataInicio = date => {
        this.setState({ dt_inicio: date })
    }

    handleChangeDataFim = date => {
        this.setState({ dt_fim: date })
    }

    handleChangeData = date => {
        this.setState({ data_avaliacao: date })
    }

    //CARREGA TODOS OS ALUNOS E SUAS AVALIAÇÕES
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/colaboradores-resultados/listar-resultados-curso",
                    params: {
                        utilizadorid: this.state.utilizador.id,
                        cursoid: this.state.cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            utilizadores: response.data,
                            loading_table: false
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    })
            }
        )
    }

    montarMenuTarefa = (tarefa) => {
        const tipo = tarefa.tipo_recurso;
        if (tipo === 6) {
            return <Dropdown overlay={this.montarMenuAvaliacao(tarefa.id)} placement="bottomLeft">
                <Link to="#" className="btn-opcoes">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>;
        }
        else if (tipo === 5) {
            return <Dropdown overlay={this.montarMenuQuestionario(tarefa.id)} placement="bottomLeft">
                <Link to="#" className="btn-opcoes">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
        else {
            return <Dropdown overlay={this.montarMenuTrabalho(tarefa.id)} placement="bottomLeft">
                <Link to="#" className="btn-opcoes">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
    }

    montarMenuTarefaMobile = (tarefa) => {
        const tipo = tarefa.tipo_recurso;
        if (tipo === 6) {
            return <Dropdown overlay={this.montarMenuAvaliacaoMobile(tarefa)} placement="bottomLeft">
                <Link to="#" className="btn-opcoes">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>;
        }
        else if (tipo === 5) {
            return <Dropdown overlay={this.montarMenuQuestionarioMobile(tarefa)} placement="bottomLeft">
                <Link to="#" className="btn-opcoes">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
        else {
            return <Dropdown overlay={this.montarMenuTrabalhoMobile(tarefa)} placement="bottomLeft">
                <Link to="#" className="btn-opcoes">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
    }

    // MONTA O MENU DE OPÇÕES POR CADA AVALIACAO
    montarMenuAvaliacao = aulaid => {
        return (
            <Menu>
                {/*<Menu.Item>
                    <Link to="#" onClick={() => this.exportarAvaliacaoCSV(aulaid)}>
                        Exportar
                        </Link>
                </Menu.Item>*/}
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarAvaliacao(aulaid)}>
                        Exportar
                        </Link>
                </Menu.Item>
            </Menu>
        )
    }

    montarMenuAvaliacaoMobile = tarefa => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarAvaliacao(tarefa.id)}>
                        Exportar
                        </Link>
                </Menu.Item>
            </Menu>
        )
    }

    // MONTA O MENU DE OPÇÕES POR CADA QUESTIONARIO
    montarMenuQuestionario = aulaid => {
        return (
            <Menu>
                {/* <Menu.Item>
                    <Link to="#" onClick={() => this.exportarQuestionarioCSV(aulaid)}>
                        Exporta CSV
                        </Link>
                </Menu.Item>*/}
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarQuestionario(aulaid)}>
                        Exportar
                        </Link>
                </Menu.Item>
            </Menu>
        )
    }

    montarMenuQuestionarioMobile = tarefa => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarQuestionario(tarefa.id)}>
                        Exportar
                        </Link>
                </Menu.Item>
            </Menu>
        )
    }

    // MONTA O MENU DE OPÇÕES POR CADA UTILIZADOR
    montarMenuTrabalho = aulaid => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarTrabalho(aulaid)}>Exportar</Link>
                </Menu.Item>
            </Menu>
        )
    }

    montarMenuTrabalhoMobile = tarefa => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarTrabalho(tarefa.id)}>Exportar</Link>
                </Menu.Item>
            </Menu>
        )
    }

    montarMenuProgresso = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleDrawerRelatorio: true, editarRelatorio: true })}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => openNotificationWithIcon("success", "Sucesso", "Participante notificado!")}>
                        Notificar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => openNotificationWithIcon("success", "Sucesso", "Responsável notificado!")}>
                        Notificar Responsável
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuCompetencia = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#">
                        Exportar
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //CARREGA OS DADOS NA TABELA DE NIVEL 1
    carregaDados = lista => {
        var utilizadores = []

        lista.map((utilizador, index) => {
            utilizadores.push({
                key: index,
                id: utilizador.id,
                nome: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="imagem-curso">
                            <img src={utilizador.foto ? utilizador.foto : noimage} />
                        </div>
                        <div className="nome-colaborador">
                            <span className="titulo-curso">{utilizador.nome_completo}</span>
                        </div>
                    </div>
                ),
                registo: utilizador.dt_criado ? moment(utilizador.dt_criado).format("DD/MM/YYYY") : "",
                classificacao: utilizador.classificacao,
                classificacao_observacoes: utilizador.classificacao_observacoes,
                progresso: utilizador.progresso,
                medalha: utilizador.medalha,
                descarregar: utilizador.documento ? (
                    <a href={utilizador.documento} download={utilizador.documento_nome} className="icon-download" title="Descarregar certificado">
                        <Icon type="download" />
                    </a>
                ) : (
                        ""
                    )
            })
        })
        return utilizadores
    }

    carregaDadosMobile = lista => {
        var utilizadores = []

        lista.map((utilizador, index) => {
            utilizadores.push({
                key: index,
                id: utilizador.id,
                nome: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="imagem-curso">
                            <img src={utilizador.foto ? utilizador.foto : noimage} />
                        </div>
                        <div className="nome-colaborador">
                            <span className="titulo-curso">{utilizador.nome_completo}</span>
                        </div>
                    </div>
                ),
                opcoes: (
                    <Dropdown overlay={this.montarMenuUtilizador(utilizador)} placement="bottomLeft">
                        <Link to="#" className="btn-opcoes">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                )
            })
        })
        return utilizadores
    }

    montarMenuUtilizador = utilizador => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalhe(utilizador)}>
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    abrirDetalhe = utilizador => {
        const detalheRegisto = utilizador.dt_criado ? moment(utilizador.dt_criado).format("DD/MM/YYYY") : "";
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{utilizador.classificacao}{utilizador.classificacao_observacoes ? "*" : ""}</span> {utilizador.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;
        const detalheProgresso = utilizador.progresso;
        const detalheDescarregar = utilizador.documento ? (<a href={utilizador.documento} download={utilizador.documento_nome} className="icon-download" title="Descarregar certificado">
            <Icon type="download" />
        </a>) : "";

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao,
            detalheProgresso,
            detalheDescarregar
        })
    }

    abrirDetalheTarefa = tarefa => {
        const detalheRegisto = tarefa.modulo_Aulas_Utilizadores?.dt_nota_final
            ? moment(tarefa.modulo_Aulas_Utilizadores.dt_nota_final).format("DD/MM/YYYY")
            : "";
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{tarefa.modulo_Aulas_Utilizadores?.nota_final}{tarefa.modulo_Aulas_Utilizadores?.nota_final_notas ? "*" : ""}</span> {tarefa.modulo_Aulas_Utilizadores?.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao
        })
    }

    //LISTA RESULTADOS DE TODAS AS TABELAS DO ALUNO SELECIONADO
    listarResultadosLvl2 = (expanded, record) => {
        if (expanded) {
            this.listarCompetencias(record);
        }

        this.setState({
            expanded,
            record
        })
    };

    //LISTA TODAS AS AVALIACOES PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarAvaliacaoCompetencias = () => {
        this.setState({
            loading_table_competencias: true
        });

        axios({
            method: "get",
            url: "/api/colaboradores-resultados/listar-avaliacoes-competencias",
            params: {
                cursoid: this.state.cursoid,
                utilizadorid: this.state.utilizador.id
            }
        })
            .then(response => {
                var avaliacoesCompetencias = [];
                var avaliacoesCompetenciasMobile = [];

                response.data.map((avaliacao, index) => {
                    if (!avaliacao.modulo_Aulas_Utilizadores) {
                        avaliacoesCompetencias.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconCompetencia(avaliacao.tipo_recurso, avaliacao.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            )
                        });

                        avaliacoesCompetenciasMobile.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconCompetencia(avaliacao.tipo_recurso, avaliacao.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: this.montarMenuTarefaMobile(avaliacao)
                        });
                    }
                    else {
                        avaliacoesCompetencias.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconCompetencia(avaliacao.tipo_recurso, avaliacao.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: avaliacao.modulo_Aulas_Utilizadores.dt_nota_final
                                ? moment(avaliacao.modulo_Aulas_Utilizadores.dt_nota_final).format("DD/MM/YYYY")
                                : "",
                            classificacao: avaliacao.modulo_Aulas_Utilizadores.nota_final,
                            medalha: avaliacao.modulo_Aulas_Utilizadores.medalha,
                            opcoes: this.montarMenuTarefa(avaliacao)
                        });

                        avaliacoesCompetenciasMobile.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconCompetencia(avaliacao.tipo_recurso, avaliacao.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: this.montarMenuTarefaMobile(avaliacao)
                        });
                    }
                });

                this.setState({
                    avaliacoesCompetencias,
                    avaliacoesCompetenciasMobile,
                    loading_table_competencias: false
                });
            })
            .catch(() => { });
    };

    monterCoresBNCC = gerais => {
        var _gerais = gerais.map((geral, index) => {
            switch (geral.split(".")[0]) {
                case "1":
                    return <span key={index} className="bcnn-competencia competencia-1">{geral}</span>
                    break;
                case "2":
                    return <span key={index} className="bcnn-competencia competencia-2">{geral}</span>
                    break;
                case "3":
                    return <span key={index} className="bcnn-competencia competencia-3">{geral}</span>
                    break;
                case "4":
                    return <span key={index} className="bcnn-competencia competencia-4">{geral}</span>
                    break;
                case "5":
                    return <span key={index} className="bcnn-competencia competencia-5">{geral}</span>
                    break;
                case "6":
                    return <span key={index} className="bcnn-competencia competencia-6">{geral}</span>
                    break;
                case "7":
                    return <span key={index} className="bcnn-competencia competencia-7">{geral}</span>
                    break;
                case "8":
                    return <span key={index} className="bcnn-competencia competencia-8">{geral}</span>
                    break;
                case "9":
                    return <span key={index} className="bcnn-competencia competencia-9">{geral}</span>
                    break;
                case "10":
                    return <span key={index} className="bcnn-competencia competencia-10">{geral}</span>
                    break;
            }
        })

        return _gerais;
    }

    //LISTA TODAS AS AVALIACOES PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarCompetencias = record => {
        this.setState({
            loading_table_lvl3: true
        });

        axios({
            method: "get",
            url: "/api/colaboradores-resultados/listar-competencias",
            params: {
                aulaid: record.id,
                utilizadorid: this.state.utilizador.id
            }
        })
            .then(response => {
                var avaliacoesCompetencias = this.state.avaliacoesCompetencias;
                var competencias = [];

                response.data.competencias.map((competencia, index) => {
                    var tipos = JSON.parse(competencia.tipos)
                    var lista = this.monterCoresBNCC(JSON.parse(competencia.gerais));

                    competencias.push({
                        key: index,
                        id: competencia.id,
                        competencia: (<div className="compentencia">
                            <div className="info-compentencia">
                                <span className="titulo-competencia">{competencia.nome}</span>
                                <div className="lista-tipos-competencias">
                                    {tipos.map((tipo, index) => (
                                        <div key={index} className="tipo-competencia">
                                            <span className={"icon-competencia " + (this.montarIcon(tipo, competencia.id, response.data.avaliacao))}></span>
                                            <span className="tipo">{tipo}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="lista-tipos-competencias-gerais">
                                    <span className="titulo-competencias-gerais lista-competencias-gerais"><strong>(BNCC </strong> {lista.map(geral => (geral))}<strong>)</strong></span>
                                </div>
                            </div>
                        </div>)
                    });
                });


                avaliacoesCompetencias[record.key] = { ...avaliacoesCompetencias[record.key], competencias }
                this.setState({
                    avaliacoesCompetencias,
                    loading_table_lvl3: false
                });
            })
            .catch(() => { });
    };

    montarIconCompetencia = (tipoId, tipoTrabalho) => {
        switch (tipoId) {
            case 5:
                return (<div className="imagem-curso" title="Questionário">
                    <img src={require("../images/icons/questionario.png")} />
                </div>)
                break;
            case 6:
                return (<div className="imagem-curso" title="Avaliação">
                    <img src={require("../images/icons/avaliacao.png")} />
                </div>)
                break;
            case 8:
                switch (tipoTrabalho) {
                    case "":
                        return (<div className="imagem-curso" title="Trabalho Individual">
                            <img src={require("../images/icons/trabalho_individual.png")} />
                        </div>)
                        break;
                    case "":
                        return (<div className="imagem-curso" title="Trabalho de Grupo">
                            <img src={require("../images/icons/trabalho_grupo.png")} />
                        </div>)
                        break;
                    default:
                        return (<div className="imagem-curso" title="Trabalho Colaborativo">
                            <img src={require("../images/icons/trabalho_colaborativo.png")} />
                        </div>)
                        break;
                }
                break;
            case 14:
                return (<div className="imagem-curso" title="Questionario Progresso">
                    <img src={require("../images/icons/progresso.png")} />
                </div>)
                break;
            case 15:
                return (<div className="imagem-curso" title="Questionário Dirigido">
                    <img src={require("../images/icons/competencia.png")} />
                </div>)
                break;
        }
    }

    montarIcon = (tipo, competenciaId, avaliacao) => {
        var valor = "";
        if (avaliacao)
            if (avaliacao.tipos)
                if (avaliacao.tipos.length) {
                    var tipos = JSON.parse(avaliacao.tipos)
                    var _competencia = tipos.find(x => x.competenciaId === competenciaId)
                    if (_competencia) {
                        var _tipo = _competencia.tipos.find(x => x.tipo === tipo)
                        if (_tipo)
                            valor = _tipo.valor;
                    }
                }

        if (valor === "Competência satisfatória")
            return "icon-satisfatoria"
        else if (valor === "Competência não satisfatória")
            return "icon-nao-satisfatoria"
        else if (valor === "Competência parcialmente satisfatória")
            return "icon-parcialmente-satisfatoria"
        else
            return "icon-sem-avaliacao"

    }

    montarIconTarefa = (tipo, tipoTrabalho) => {
        if (tipo === 6) {
            return <div className="imagem-curso" title="Avaliação">
                <img src={require("../images/icons/avaliacao.png")} />
            </div>
        }
        else if (tipo === 5) {
            return <div className="imagem-curso" title="Questionário">
                <img src={require("../images/icons/questionario.png")} />
            </div>
        }
        else if (tipo === 8) {
            if (tipoTrabalho === "INDIVIDUAL")
                return <div className="imagem-curso" title="Trabalho Individual">
                    <img src={require("../images/icons/trabalho_individual.png")} />
                </div>
            else if (tipoTrabalho === "GRUPO")
                return <div className="imagem-curso" title="Trabalho de Grupo">
                    <img src={require("../images/icons/trabalho_grupo.png")} />
                </div>
            else
                return <div className="imagem-curso" title="Trabalho Colaborativo">
                    <img src={require("../images/icons/trabalho_colaborativo.png")} />
                </div>
        }
        else {
            return <div className="imagem-curso" title="Questionário de Progresso">
                <img src={require("../images/icons/progresso.png")} />
            </div>
        }
    }

    listarTodasAvaliacoes = () => {
        this.setState({
            loading_table_avaliacoes: true
        });

        axios({
            method: "get",
            url: "/api/colaboradores-resultados/listar-todas-avaliacoes",
            params: {
                utilizadorid: this.state.utilizador.id,
                cursoid: this.state.cursoid
            }
        })
            .then(response => {
                var tarefas = [];
                var tarefasMobile = [];

                response.data.map((tarefa, index) => {
                    if (!tarefa.modulo_Aulas_Utilizadores) {
                        tarefas.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "" : "",
                        });
                        tarefasMobile.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "" : "",
                            opcoes: this.montarMenuTarefaMobile(tarefa)
                        });
                    } else {
                        tarefas.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: tarefa.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(tarefa.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : "" : "",
                            classificacao: tarefa.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: tarefa.modulo_Aulas_Utilizadores.medalha,
                            opcoes: tarefa.modulo_Aulas_Utilizadores.dt_concluido ? this.montarMenuTarefa(tarefa) : null
                        });
                        tarefasMobile.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: tarefa.modulo_Aulas_Utilizadores.dt_concluido ? this.montarMenuTarefaMobile(tarefa) : null
                        });
                    }
                });

                this.setState({
                    tarefas,
                    tarefasMobile,
                    loading_table_avaliacoes: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //LISTA TODAS AS AVALIACOES PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarAvaliacoes = () => {
        this.setState({
            loading_table_avaliacoes: true
        })

        axios({
            method: "get",
            url: "/api/colaboradores-resultados/listar-avaliacoes",
            params: {
                utilizadorid: this.state.utilizador.id,
                cursoid: this.state.cursoid
            }
        })
            .then(response => {
                var avaliacoes = []

                response.data.map((avaliacao, index) => {
                    if (!avaliacao.modulo_Aulas_Utilizadores) {
                        avaliacoes.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Avaliação">
                                        <img src={require("../images/icons/avaliacao.png")} />
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            )
                        })
                    } else {
                        avaliacoes.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Avaliação">
                                        <img src={require("../images/icons/avaliacao.png")} />
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: avaliacao.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(avaliacao.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : "",
                            classificacao: avaliacao.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: avaliacao.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: avaliacao.modulo_Aulas_Utilizadores.medalha,
                            opcoes: (
                                <Dropdown
                                    overlay={this.montarMenuAvaliacao(avaliacao.id)}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="btn-opcoes">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            )
                        })
                    }
                })

                this.setState({
                    avaliacoes,
                    loading_table_avaliacoes: false
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            })
    }

    //LISTA TODAS OS QUESTIONARIOS PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarQuestionarios = () => {
        this.setState({
            loading_table_questionarios: true
        })

        axios({
            method: "get",
            url: "/api/colaboradores-resultados/listar-questionarios",
            params: {
                utilizadorid: this.state.utilizador.id,
                cursoid: this.state.cursoid
            }
        })
            .then(response => {
                var questionarios = []

                response.data.map((questionario, index) => {
                    if (!questionario.modulo_Aulas_Utilizadores) {
                        questionarios.push({
                            key: index,
                            id: questionario.id,
                            questionario: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Questionário">
                                        {questionario.tipo_recurso === 5 ?
                                            <img src={require("../images/icons/questionario.png")} />
                                            :
                                            <img src={require("../images/icons/progresso.png")} />
                                        }
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{questionario.nome}</span>
                                        <p className="atividade">{questionario.atividade}</p>
                                    </div>
                                </div>
                            )
                        })
                    } else {
                        questionarios.push({
                            key: index,
                            id: questionario.id,
                            questionario: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Questionário">
                                        {questionario.tipo_recurso === 5 ?
                                            <img src={require("../images/icons/questionario.png")} />
                                            :
                                            <img src={require("../images/icons/progresso.png")} />
                                        }
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{questionario.nome}</span>
                                        <p className="atividade">{questionario.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: questionario.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(questionario.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : "",
                            classificacao: questionario.tipo_recurso === 5 ? questionario.modulo_Aulas_Utilizadores.nota_final : questionario.modulo_Aulas_Utilizadores.dt_concluido ? "Realizado com sucesso" : "Realizado com respostas erradas",
                            classificacao_observacoes: questionario.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: questionario.modulo_Aulas_Utilizadores.medalha,
                            opcoes: questionario.tipo_recurso === 5 && (
                                <Dropdown
                                    overlay={this.montarMenuQuestionario(questionario.id)}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="btn-opcoes">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            )
                        })
                    }
                })

                this.setState({
                    questionarios,
                    loading_table_questionarios: false
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            })
    }

    montarIconTrabalho = tipo => {
        if (tipo === "INDIVIDUAL")
            return <div className="imagem-curso" title="Trabalho Individual">
                <img src={require("../images/icons/trabalho_individual.png")} />
            </div>
        else if (tipo === "GRUPO")
            return <div className="imagem-curso" title="Trabalho de Grupo">
                <img src={require("../images/icons/trabalho_grupo.png")} />
            </div>
        else
            return <div className="imagem-curso" title="Trabalho Colaborativo">
                <img src={require("../images/icons/trabalho_colaborativo.png")} />
            </div>
    }

    //LISTA TODAS OS TRABALHOS PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarTrabalhos = () => {
        this.setState({
            loading_table_trabalhos: true
        })

        axios({
            method: "get",
            url: "/api/colaboradores-resultados/listar-trabalhos",
            params: {
                utilizadorid: this.state.utilizador.id,
                cursoid: this.state.cursoid
            }
        })
            .then(response => {
                var trabalhos = []

                response.data.map((trabalho, index) => {
                    if (!trabalho.modulo_Aulas_Utilizadores) {
                        trabalhos.push({
                            key: index,
                            id: trabalho.id,
                            trabalho: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTrabalho(trabalho.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{trabalho.nome}</span>
                                        <p className="atividade">{trabalho.atividade}</p>
                                    </div>
                                </div>
                            )
                        })
                    } else {
                        trabalhos.push({
                            key: index,
                            id: trabalho.id,
                            trabalho: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTrabalho(trabalho.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso">{trabalho.nome}</span>
                                        <p className="atividade">{trabalho.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: trabalho.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(trabalho.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : "",
                            classificacao: trabalho.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: trabalho.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: trabalho.modulo_Aulas_Utilizadores.medalha,
                            opcoes: (
                                <Dropdown
                                    overlay={this.montarMenuTrabalho(trabalho.id)}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="btn-opcoes">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            )
                        })
                    }
                })

                this.setState({
                    trabalhos,
                    loading_table_trabalhos: false
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            })
    }

    //LISTA TODAS AS OUTRAS AVALIACOES PARA O CURSO EM QUESTAO DO ALUNO SELECIONADO
    listarOutrasAvaliacoes = () => {
        this.setState({
            loading_table_outras: true
        })

        axios({
            method: "get",
            url: "/api/colaboradores-resultados/listar-outras-avaliacoes",
            params: {
                utilizadorid: this.state.utilizador.id,
                cursoid: this.state.cursoid
            }
        })
            .then(response => {
                var outras_avaliacoes = []
                var outras_avaliacoesMobile = [];

                response.data.map((avaliacao, index) => {
                    outras_avaliacoes.push({
                        key: index,
                        id: avaliacao.id,
                        nome: avaliacao.nome,
                        data: avaliacao.data ? moment(avaliacao.data).format("DD/MM/YYYY") : "",
                        classificacao: avaliacao.classificacao,
                        observacoes: avaliacao.classificacao_notas,
                        medalha: avaliacao.medalha,
                    })

                    outras_avaliacoesMobile.push({
                        key: index,
                        id: avaliacao.id,
                        nome: avaliacao.nome,
                        opcoes: <Dropdown overlay={this.montarMenuOutrasAvaliacoes(avaliacao)} placement="bottomLeft">
                            <Link to="#" className="btn-opcoes">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                    })
                })

                this.setState({
                    outras_avaliacoes,
                    outras_avaliacoesMobile,
                    loading_table_outras: false
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            })
    }

    montarMenuOutrasAvaliacoes = tarefa => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheOutraAvaliacao(tarefa)}>
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    abrirDetalheOutraAvaliacao = avaliacao => {
        const detalheRegisto = avaliacao.data ? moment(avaliacao.data).format("DD/MM/YYYY") : "";
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{avaliacao.classificacao}{avaliacao.classificacao_notas ? "*" : ""}</span> {avaliacao.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao
        })
    }

    //LISTA TODAS AS OUTRAS AVALIACOES PARA O CURSO EM QUESTAO DO ALUNO SELECIONADO
    listarRelatoriosProgresso = () => {
        this.setState({
            loading_table_relatorios: true
        });

        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/listar-relatorios-progresso",
            params: {
                utilizadorid: this.state.utilizador.id,
                cursoid: this.state.cursoid
            }
        })
            .then(response => {
                var relatorios_progresso = [];
                var relatorios_progressoMobile = [];

                response.data.map((relatorio, index) => {
                    relatorios_progresso.push({
                        key: index,
                        id: relatorio.id,
                        nome: (<div style={{ display: "flex", alignItems: "center" }}>
                            <div className="imagem-curso" title="Relatório de Progresso">
                                <img src={require("../images/icons/relatorio.png")} />
                            </div>
                            <div className="nome-colaborador">
                                <span className="titulo-curso" style={{ marginTop: 0 }}>{relatorio.nome}</span>
                            </div>
                        </div>),
                        data: moment(relatorio.data).format("DD/MM/YYYY"),
                        partilhado: this.montarTipoPartilha(relatorio.tipo_partilha),
                        notificacao: relatorio.responsavel_notificado && (<Link href="#" className="icon-notificacao" title="Responsável notificado">
                            <Icon type="mail" />
                        </Link>)
                    });

                    relatorios_progressoMobile.push({
                        key: index,
                        id: relatorio.id,
                        nome: (<div style={{ display: "flex", alignItems: "center" }}>
                            <div className="imagem-curso" title="Relatório de Progresso">
                                <img src={require("../images/icons/relatorio.png")} />
                            </div>
                            <div className="nome-colaborador">
                                <span className="titulo-curso" style={{ marginTop: 0 }}>{relatorio.nome}</span>
                            </div>
                        </div>),
                        opcoes: <Dropdown overlay={this.montarMenuRelatoriosProgresso(relatorio)} placement="bottomLeft">
                            <Link to="#" className="btn-opcoes">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                    });
                });

                this.setState({
                    relatorios_progresso,
                    relatorios_progressoMobile,
                    loading_table_relatorios: false
                });
            })
            .catch(() => { });
    };

    montarMenuRelatoriosProgresso = relatorio => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheRelatoriosProgresso(relatorio)}>
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    abrirDetalheRelatoriosProgresso = relatorio => {
        const detalheRegisto = moment(relatorio.data).format("DD/MM/YYYY");
        const detalheNotificacao = relatorio.responsavel_notificado ? "Sim" : "Não";

        this.setState({
            visibleDetalheRelatorio: true,
            detalheRegisto,
            detalheNotificacao
        })
    }

    montarTipoPartilha = tipo => {
        if (tipo === "PESSOAL")
            return (<i className="fas fa-user icon-tipo-partilha pessoal" title="Pessoal"></i>);
        else if (tipo === "INSTRUTORES")
            return (<i className="fas fa-user-friends icon-tipo-partilha partilhado-instrutor" title="Partilhado com instrutores" ></i>);
        else
            return (<i className="fas fa-users icon-tipo-partilha partilhado" title="Partilhado com o participante"></i>)
    };

    //LISTA TODAS AS OUTRAS AVALIACOES PARA O CURSO EM QUESTAO DO ALUNO SELECIONADO
    listarEstatisticas = () => {
        //this.setState({
        //    loading_table_outras: true
        //})

        axios({
            method: "get",
            url: "/api/gestao-estatistica/curso-aluno",
            params: {
                utilizadorid: this.state.utilizador.id,
                cursoid: this.state.cursoid
            }
        })
            .then(response => {
                var estatisticas = {
                    tarefas_visitadas: response.data.tarefas_visitadas,
                    tempos_tarefas: response.data.tempos_tarefas,
                    conteudos_concluidos: response.data.conteudos_concluidos,
                    doc_fichs: response.data.doc_fichs,
                    interacoes_anotacoes: response.data.interacoes_anotacoes,
                    mensagens_enviadas: response.data.mensagens_enviadas,
                    interacoes_forum: response.data.interacoes_forum,
                    ratingsaulas: response.data.ratingsaulas,
                    ratingscursos: response.data.ratingscursos,
                    avaliacoescursos: response.data.avaliacoescursos,
                    avaliacoesaulas: response.data.avaliacoesaulas,
                    medalhacurso: response.data.medalhacurso,
                    medalhastarefas: response.data.medalhastarefas,
                };

                this.setState({
                    estatisticas
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            })
    }

    exportarAvaliacao = aulaid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/avaliacao-csv",
                    params: {
                        utilizadorId: this.state.utilizador.id,
                        moduloaulaId: aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        )
    }

    exportarQuestionario = aulaid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/questionario-csv",
                    params: {
                        utilizadorId: this.state.utilizador.id,
                        moduloaulaId: aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        )
    }

    exportarTrabalho = (aulaid) => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/trabalho-zip",
                    params: {
                        utilizadorId: this.state.utilizador.id,
                        moduloaulaId: aulaid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        )
    }

    exportarresumoCSV = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/resumo-curso-csv",
                    params: {
                        utilizadorid: this.state.utilizador.id,
                        cursoid: this.state.cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        )
    }

    exportarEstatisticasCSV = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/curso-aluno",
                    params: {
                        utilizadorid: this.state.utilizador.id,
                        cursoid: this.state.cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        )
    }

    exportarEstatisticasDET = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/curso-aluno-det",
                    params: {
                        utilizadorid: this.state.utilizador.id,
                        cursoid: this.state.cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        )
    }


    render() {
        const { getFieldDecorator } = this.props.form

        const {
            utilizadores,
            avaliacoesCompetencias,
            avaliacoesCompetenciasMobile,
            tarefas,
            tarefasMobile,
            outras_avaliacoes,
            outras_avaliacoesMobile,
            relatorios_progresso,
            relatorios_progressoMobile,
            estatisticas,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading_table,
            loading_table_avaliacoes,
            loading_table_questionarios,
            loading_table_trabalhos,
            loading_table_outras,
            loading_table_lvl3,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //DRAWER RELATORIO
            visibleDrawerRelatorio,
            editarRelatorio,
            //DRAWER DETAHE
            visibleDetalhe,
            visibleDetalheRelatorio,
            detalheRegisto,
            detalheClassificacao,
            detalheProgresso,
            detalheDescarregar,
            detalheNotificacao
        } = this.state

        //COLUNAS DA TABELA DE NIVEL 1
        const columns = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-500"
            },
            {
                title: "Data",
                dataIndex: "registo",
                key: "registo",
                className: "td-200"
            },
            {
                title: "Classificação",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-200",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}{record.classificacao_observacoes ? "*" : ""}</span> {record.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>,
            },
            {
                title: "Progresso",
                dataIndex: "progresso",
                key: "progresso",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "descarregar",
                className: "td-50"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ]

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-500"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ]

        //COLUNAS DA TABELA DE NIVEL 2 AVALICOES
        const columnslvl2Competencias = [
            {
                title: "Avaliação por Competências",
                dataIndex: "avaliacao",
                key: "avaliacao",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-205",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}{record.classificacao_observacoes ? "*" : ""}</span> {record.medalha && <i className="fas fa-award icon-medalha-aula" title="Medalha de Tarefa"></i>}</div>,
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-250",
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ]

        const columnslvl2CompetenciasMobile = [
            {
                title: "Avaliação por Competências",
                dataIndex: "avaliacao",
                key: "avaliacao",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ]

        const columnslvl3Competencias = [
            {
                title: "Competências",
                dataIndex: "competencia",
                key: "competencia"
            }
        ]

        //COLUNAS DA TABELA DE NIVEL 2 AVALICOES
        const columnslvl2TodasAvaliacoes = [
            {
                title: "Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-205",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}{record.classificacao_observacoes ? "*" : ""}</span> {record.medalha && <i className="fas fa-award icon-medalha-aula" title="Medalha de Tarefa"></i>}</div>,
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-250",
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnslvl2TodasAvaliacoesMobile = [
            {
                title: "Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        //COLUNAS DA TABELA DE NIVEL 2 OUTRAS AVALIAÇÕES
        const columnslvl2OutrasAvaliacoes = [
            {
                title: "Outras Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-205",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}{record.classificacao_observacoes ? "*" : ""}</span> {record.medalha && <i className="fas fa-award icon-medalha-aula" title="Medalha de Tarefa"></i>}</div>,
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-250",
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ]

        const columnslvl2OutrasAvaliacoesMobile = [
            {
                title: "Outras Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ]

        //COLUNAS DA TABELA DE NIVEL 2 PROGRESSOS
        const columnslvl2Progressos = [
            {
                title: "Relatórios de Progresso",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "partilhado",
                key: "partilhado",
                className: "td-50",
            },
            {
                title: "",
                dataIndex: "notificacao",
                key: "notificacao",
                className: "td-50"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        const columnslvl2ProgressosMobile = [
            {
                title: "Relatórios de Progresso",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />

        return (
            <div>
                <Header titulo="Resultados" />
                <div className="container">
                    <div className="bloco-formadores bloco-resultados-meus-cursos">
                        <div className="bloco">
                            <div className="bloco-flex">
                                {localStorage.getItem("nome_curso_pai") ? (
                                    <div>
                                        <h3>{localStorage.getItem("cod_curso_pai")} - {localStorage.getItem("nome_curso_pai")}</h3>
                                        <span>{localStorage.getItem("cod_subcurso")} - {localStorage.getItem("nome_subcurso")}</span>
                                    </div>
                                ) : (
                                        <h3 className="tabela_titulo">{localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}</h3>
                                    )}
                                <Link to={`/gestao-utilizadores-sistema-informacao/${this.props.match.params.utilizador}`} className="botao-principal" style={{ display: "inline" }}>
                                    Voltar
                                </Link>
                            </div>
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-desktop"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)", marginTop: 40 }}
                                columns={columns}
                                dataSource={this.carregaDados(utilizadores)}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-mobile"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)", marginTop: 40 }}
                                columns={columnsMobile}
                                dataSource={this.carregaDadosMobile(utilizadores)}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            {localStorage.getItem("habilitar_competencias") === "true" &&
                                <>
                                    <Table
                                    className="tabela_lvl2 tabela-competencias-desktop"
                                        columns={columnslvl2Competencias}
                                        dataSource={avaliacoesCompetencias}
                                        pagination={false}
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                        onExpand={this.listarResultadosLvl2}
                                        expandedRowRender={record => (
                                            <div className="tabela-lvl2">
                                                <div className="bloco-avaliacoes">
                                                    <Table
                                                        id="tabela-avaliacoes-finais_lvl2"
                                                        className="tabela_lvl2"
                                                        columns={columnslvl3Competencias}
                                                        dataSource={avaliacoesCompetencias[record.key].competencias}
                                                        pagination={false}
                                                        locale={{ emptyText: "Não existem registos!" }}
                                                        loading={{
                                                            spinning: loading_table_lvl3,
                                                            indicator: (
                                                                <div className="loading-data-table">
                                                                    <div className="loading" />
                                                                </div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                />
                                <Table
                                    className="tabela_lvl2 tabela-competencias-mobile"
                                    columns={columnslvl2CompetenciasMobile}
                                    dataSource={avaliacoesCompetenciasMobile}
                                    pagination={false}
                                    loading={{
                                        spinning: loading_table,
                                        indicator: (
                                            <div className="loading-data-table">
                                                <div className="loading" />
                                            </div>
                                        )
                                    }}
                                    locale={{ emptyText: "Não existem registos!" }}
                                    onExpand={this.listarResultadosLvl2}
                                    expandedRowRender={record => (
                                        <div className="tabela-lvl2">
                                            <div className="bloco-avaliacoes">
                                                <Table
                                                    id="tabela-avaliacoes-finais_lvl2"
                                                    className="tabela_lvl2"
                                                    columns={columnslvl3Competencias}
                                                    dataSource={avaliacoesCompetencias[record.key].competencias}
                                                    pagination={false}
                                                    locale={{ emptyText: "Não existem registos!" }}
                                                    loading={{
                                                        spinning: loading_table_lvl3,
                                                        indicator: (
                                                            <div className="loading-data-table">
                                                                <div className="loading" />
                                                            </div>
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                                </>
                            }
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-desktop"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)", marginTop: 40 }}
                                columns={columnslvl2TodasAvaliacoes}
                                dataSource={tarefas}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-mobile"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)", marginTop: 40 }}
                                columns={columnslvl2TodasAvaliacoesMobile}
                                dataSource={tarefasMobile}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-desktop"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)", marginTop: 40 }}
                                columns={columnslvl2OutrasAvaliacoes}
                                dataSource={outras_avaliacoes}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-desktop"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)", marginTop: 40 }}
                                columns={columnslvl2OutrasAvaliacoesMobile}
                                dataSource={outras_avaliacoesMobile}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-desktop"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)", marginTop: 40 }}
                                columns={columnslvl2Progressos}
                                dataSource={relatorios_progresso}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-mobile"
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)", marginTop: 40 }}
                                columns={columnslvl2ProgressosMobile}
                                dataSource={relatorios_progressoMobile}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <div style={{ marginTop: 20 }}>
                                <h3>Estatísticas</h3>
                                <div className="dashboard" style={{ padding: 0, boxShadow: "none" }}>
                                    <div className="bloco" style={{ padding: 0, boxShadow: "none" }}>
                                        <div className="grid">
                                            <div className="item">
                                                <Icon type="desktop" />
                                                <span className="desc">{estatisticas ? estatisticas.tarefas_visitadas : 0}</span>
                                                <span className="titulo">Tarefas visitadas</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="history" />
                                                <span className="desc">{estatisticas ? estatisticas.tempos_tarefas : 0}</span>
                                                <span className="titulo">Tempo nas tarefas</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="check" />
                                                <span className="desc">{estatisticas ? estatisticas.conteudos_concluidos : 0}</span>
                                                <span className="titulo">Tarefas concluídas</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="file-add" />
                                                <span className="desc">{estatisticas ? estatisticas.doc_fichs : 0}</span>
                                                <span className="titulo">Ficheiros</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="file" />
                                                <span className="desc">{estatisticas ? estatisticas.interacoes_anotacoes : 0}</span>
                                                <span className="titulo">Anotações</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="message" />
                                                <span className="desc">{estatisticas ? estatisticas.mensagens_enviadas : 0}</span>
                                                <span className="titulo">Mensagens enviadas</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="form" />
                                                <span className="desc">{estatisticas ? estatisticas.interacoes_forum : 0}</span>
                                                <span className="titulo">Interações fórum</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="number" />
                                                <span className="desc">{estatisticas ? estatisticas.ratingsaulas : 0}</span>
                                                <span className="titulo">Ratings/média (tarefas)</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="check-circle" />
                                                <span className="desc">{estatisticas ? estatisticas.avaliacoesaulas : 0}</span>
                                                <span className="titulo">Classificações/média (tarefas)</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="number" />
                                                <span className="desc">{estatisticas ? estatisticas.ratingscursos : 0}</span>
                                                <span className="titulo">Rating (curso)</span>
                                            </div>
                                            <div className="item">
                                                <Icon type="check-circle" />
                                                <span className="desc">{estatisticas ? estatisticas.avaliacoescursos : 0}</span>
                                                <span className="titulo">Classificação (curso)</span>
                                            </div>
                                            <div className="item icon-medalha" onClick={() => openNotificationWithIcon("info", "Medalhas recebida ao curso", "")}>
                                                <i className="fas fa-award icon-curso" style={{ width: 40 }}></i>
                                                <span className="desc">{estatisticas ? estatisticas.medalhacurso : 0}</span>
                                                <span className="titulo">Medalha no curso</span>
                                            </div>
                                            <div className="item icon-medalha" onClick={() => openNotificationWithIcon("info", "Medalhas recebidas em tarefas", "")}>
                                                <i className="fas fa-award icon-aula" style={{ width: 40 }}></i>
                                                <span className="desc">{estatisticas ? estatisticas.medalhastarefas : 0}</span>
                                                <span className="titulo">Medalhas em Tarefas</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="botoes-exportar">
                                        <Link to="#" onClick={this.exportarEstatisticasCSV}>
                                            <Icon type="export" />
                                                        Exportar resumo para csv
                                                    </Link>
                                        <Link to="#" onClick={this.exportarEstatisticasDET}>
                                            <Icon type="export" />
                                                        Exportar detalhe para csv
                                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DrawerRelatorio
                    editarRelatorio={editarRelatorio}
                    onClose={() => this.setState({ visibleDrawerRelatorio: false })}
                    visibleDrawerRelatorio={this.state.visibleDrawerRelatorio}
                />
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ?
                            <>
                                <p><Icon type="loading" /></p>
                                <p className="texto">A gerar documento...</p>
                            </>
                            :
                            <>
                                <p><Icon type="check-circle" style={{ color: "#84bd00" }} /></p>
                                <p className="texto">Dados exportados com sucesso!</p>
                                <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        }
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-curso"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Data">
                                    {detalheRegisto}
                                </Form.Item>
                                {detalheClassificacao &&
                                    <Form.Item label="Classificação">
                                        {detalheClassificacao}
                                    </Form.Item>}
                                {detalheProgresso &&
                                    <Form.Item label="Progresso">
                                        {detalheProgresso}
                                    </Form.Item>}
                                {detalheDescarregar &&
                                    <Form.Item label="Certificado">
                                        {detalheDescarregar}
                                    </Form.Item>}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Drawer
                    className="drawer-detalhe-curso"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalheRelatorio: false })}
                    visible={visibleDetalheRelatorio}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Data">
                                    {detalheRegisto}
                                </Form.Item>
                                {detalheNotificacao &&
                                    <Form.Item label="Responsável notificado">
                                        {detalheNotificacao}
                                    </Form.Item>}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalheRelatorio: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        )
    }
}

const FormCursosResultados = Form.create({ name: "form-cursos-resultados" })(CursosResultados)

export default withRouter(FormCursosResultados)
