import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Icon, Select, Radio, notification } from "antd";
import axios from "axios";
import { useReactToPrint } from "react-to-print";

import ModalGerarDocumento from "../../../Componentes/Modals/GerarDocumento";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Controlos = ({
    selectedOption,
    setSelectedOption,
    filterSchool,
    setFilterSchool,
    filterCourse,
    setFilterCourse,
    filterSubcourse,
    setFilterSubcourse,
    menuOptionSelected,
    addData,
    disabledAddButton,
    disabledButtons,
    data,
    pageTitle
}) => {
    const { Option } = Select;
    const [schools, setSchools] = useState([]);
    const [courses, setCourses] = useState([]);
    const [subcourses, setSubcourses] = useState([]);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const loadSchoolsList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-escolas"
        })
            .then(response => {
                setSchools(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const loadCoursesList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-escola-cursos",
            params: {
                schoolId: filterSchool
            }
        })
            .then(response => {
                setCourses(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const loadSubCoursesList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-subcursos",
            params: {
                courseId: filterCourse
            }
        })
            .then(response => {
                setSubcourses(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    const exportPdf = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/relatorios/lista-comparativo-exportar-pdf",
            params: {
                data: JSON.stringify(data)
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    };

    const exportCsv = () => {
        setVisibleExport(true);
        setButtonDownload(true);

        axios({
            method: "get",
            url: "/api/relatorios/lista-comparativo-exportar-csv",
            params: {
                data: JSON.stringify(data)
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    };

    useEffect(() => {
        loadSchoolsList();
    }, [menuOptionSelected]);

    useEffect(() => {
        if (filterSchool) loadCoursesList();
        else {
            setFilterCourse(undefined);
            setCourses([]);
        }
    }, [filterSchool]);

    useEffect(() => {
        if (filterCourse) {
            setFilterSubcourse(undefined);
            loadSubCoursesList();
        } else {
            setFilterSubcourse(undefined);
            setSubcourses([]);
        }
    }, [filterCourse]);

    return (
        <>
            <div className="controls">
                <div className="controls-block controls-block-space-between">
                    <div className="controls-block">
                        <Radio.Group onChange={e => setSelectedOption(e.target.value)} value={selectedOption}>
                            <Radio value={1}>Comparar Escolas</Radio>
                            <Radio value={2}>Comparar Turmas</Radio>
                        </Radio.Group>
                    </div>
                    <div className="controls-block">
                        <span className="options">
                            <Link
                                to="#"
                                className={`button-print ${disabledButtons ? "button-disabled" : ""}`}
                                disabled={disabledButtons}
                                onClick={handlePrint}
                            >
                                <Icon type="printer" />
                            </Link>
                            <Link
                                to="#"
                                className={`button-export-pdf ${disabledButtons ? "button-disabled" : ""}`}
                                disabled={disabledButtons}
                                onClick={exportPdf}
                            >
                                <Icon type="file-pdf" />
                            </Link>
                            <Link
                                to="#"
                                className={`button-export ${disabledButtons ? "button-disabled" : ""}`}
                                disabled={disabledButtons}
                                onClick={exportCsv}
                            >
                                <Icon type="download" />
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="controls-block-space-between">
                    <span className="filters">
                        <Select
                            className="filter"
                            showSearch
                            placeholder="Selecionar Escola"
                            allowClear={true}
                            value={filterSchool}
                            onChange={filter => setFilterSchool(filter)}
                        >
                            {schools.map((item, index) => (
                                <Option key={index} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                        {selectedOption === 2 && (
                            <>
                                <Select
                                    className="filter"
                                    showSearch
                                    placeholder="Selecionar Turma"
                                    allowClear={true}
                                    value={filterCourse}
                                    onChange={filter => setFilterCourse(filter)}
                                    disabled={!filterSchool}
                                >
                                    {courses.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                                <Select
                                    className="filter"
                                    showSearch
                                    placeholder="Selecionar Disciplina"
                                    allowClear={true}
                                    value={filterSubcourse}
                                    onChange={filter => setFilterSubcourse(filter)}
                                    disabled={!filterCourse}
                                >
                                    {subcourses.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </>
                        )}
                        <button className="btn-add" disabled={disabledAddButton} onClick={addData}>
                            <Icon type="plus" />
                        </button>
                    </span>
                </div>
            </div>
            <div ref={componentRef} className="printArea">
                <div className="print-header">
                    <h1>Relatórios - Frequência {pageTitle}</h1>
                    <div className="filters-block">
                        <span>Filtros</span>
                        <div className="filters-tags">
                            <span>Opção: {selectedOption === 1 ? "Comparar Escolas" : "Comparar Cursos"}</span>
                        </div>
                    </div>
                </div>
                <div className="print-body">
                    <div className="blocks">
                        {data.map((item, index) => (
                            <div key={index} className="block">
                                <div className="block-header">
                                    <div className="block-titles">
                                        {item.uppername && <span>{item.uppername}</span>}
                                        <span className="name">{item.name}</span>
                                        {item.subname && <span className="subname">{item.subname}</span>}
                                    </div>
                                </div>
                                <div className={`percentage ${item.average > 75 ? "percentage-above" : "percentage-below"}`}>
                                    {item.average.toFixed(2)}%
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ModalGerarDocumento
                visibleExportar={visibleExport}
                ficheiroExportar={exportFile}
                btnDescarregar={buttonDownload}
                onClose={() => setVisibleExport(false)}
            />
        </>
    );
};

export default Controlos;
