import React, { Component } from "react"
import { Link, withRouter, Redirect } from "react-router-dom"
import { Menu, Modal, notification, Form, Icon, Upload, Collapse, Dropdown, Drawer } from "antd"
import Header from "../User/Header"
import axios from "axios"
import moment from "moment"
import DrawerFormUploadFicheiro from "./_Aux/FormUploadFicheiro";
import { GlobalContext } from "../GlobalState"
import noimage from "../images/noimage.png"

const { Panel } = Collapse;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    })
}

class Pagamentos extends Component {
    static contextType = GlobalContext
    state = {
        utilizador: "",
        pagamentos: [],
        //IMPORTAR RECIBO
        pagamentoId: 0,
        codigoPagamento: "",
        visibleImportar: false,
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL
        modalIconLoading: false,
        modalMensagem: "",
        //DRAWER DETALHE
        visibleDetalhe: false,
        tipoPagamento: "",
        docComprovativo: "",
        produtos: []
    }

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.setState(
            {
                utilizador: JSON.parse(localStorage.getItem("sis_utilizador"))
            },
            () => {
                this.listarPagamentos();
            }
        );
    }

    listarPagamentos = () => {
        axios({
            method: "get",
            url: "/api/colaboradores/listar-pagamentos",
            params: {
                utilizadorid: this.state.utilizador.id
            }
        })
            .then(response => {
                let pagamentos = [];

                response.data.map(pagamento => {
                    let produtos = [];

                    pagamento.produtos.map(produto => {
                        produtos.push({
                            capa: produto.curso.imagem,
                            nome: produto.nome,
                            descricao: produto.descricao,
                            preco: produto.preco,
                            tipo: produto.tipo,
                            nr_meses: produto.nr_meses,
                            dt_inicio: produto.dt_inicio,
                            dt_fim: produto.dt_fim
                        })
                    });

                    pagamentos.push({
                        id: pagamento.id,
                        codigo: pagamento.codigo,
                        data: pagamento.dt_criado,
                        total: pagamento.total,
                        tipo: pagamento.tipo,
                        estado: pagamento.pago ? "PAGO" : "PENDENTE",
                        documento: pagamento.documento,
                        comprovativo: pagamento.comprovativo,
                        produtos
                    })
                });

                this.setState({
                    pagamentos
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    marcarComoPago = (pagamentoId) => {
        confirm({
            title: "Pretende marcar este registo como pago?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState({
                    modalIconLoading: true,
                    modalMensagem: "A marcar como pago..."
                })
                axios({
                    method: "post",
                    url: "/api/colaboradores/marcar-pagamento-pago",
                    params: {
                        pagamentoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo atualizado!");
                        var pagamentos = this.state.pagamentos;
                        var pagamentoIndex = this.state.pagamentos.findIndex(x => x.id === pagamentoId)
                        pagamentos[pagamentoIndex].estado = "PAGO";
                        this.setState({
                            pagamentos,
                            modalIconLoading: false,
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível atualizar o registo!");
                        this.setState({
                            modalIconLoading: false,
                        })
                    });
            }
        });
    }

    marcarComoPendente = (pagamentoId) => {
        confirm({
            title: "Pretende marcar este registo como pendente?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState({
                    modalIconLoading: true,
                    modalMensagem: "A marcar como pendente..."
                })
                axios({
                    method: "post",
                    url: "/api/colaboradores/marcar-pagamento-pendente",
                    params: {
                        pagamentoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo atualizado!");
                        var pagamentos = this.state.pagamentos;
                        var pagamentoIndex = this.state.pagamentos.findIndex(x => x.id === pagamentoId)
                        pagamentos[pagamentoIndex].estado = "PENDENTE";
                        this.setState({
                            pagamentos,
                            modalIconLoading: false
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível atualizar o registo!");
                        this.setState({
                            modalIconLoading: false
                        })
                    });
            }
        });
    }

    abrirDrawerUpload = (pagamentoId, codigoPagamento) => {
        this.setState({
            pagamentoId,
            codigoPagamento,
            visibleImportar: true,
        })
    }

    abrirDetalhe = (pagamento) => {
        this.setState({
            visibleDetalhe: true,
            tipoPagamento: pagamento.tipo === "PAYPAL" ? "Paypal, CC ou Mercado Pago" : "Transferência",
            docRecibo: pagamento.documento,
            docComprovativo: pagamento.tipo === "TRANSFERENCIA" && pagamento.estado === "PAGO" && pagamento.comprovativo ? pagamento.comprovativo : "",
            produtos: pagamento.produtos
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const Dragger = Upload.Dragger;

        const {
            utilizador,
            pagamentos,
            //IMPORTAR RECIBO
            pagamentoId,
            codigoPagamento,
            visibleImportar,
            //REDIRECT
            redirect,
            redirectLink,
            //MODAL
            modalIconLoading,
            modalMensagem,
            //DRAWER DETALHE
            visibleDetalhe,
            tipoPagamento,
            docComprovativo,
            produtos
        } = this.state

        if (redirect)
            return <Redirect to={redirectLink} />

        const opcoes = pagamento => {
            return (
                <Menu>
                    {pagamento.estado === "PENDENTE" ?
                        <Menu.Item>
                            <Link to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.marcarComoPago(pagamento.id)
                                }}
                            >
                                Marcar como Pago
                        </Link>
                        </Menu.Item>
                        : null}
                    {pagamento.estado === "PAGO" ?
                        <Menu.Item>
                            <Link to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.marcarComoPendente(pagamento.id)
                                }}
                            >
                                Marcar como Pendente
                        </Link>
                        </Menu.Item>
                        : null}
                    <Menu.Item>
                        <Link to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.abrirDrawerUpload(pagamento.id, pagamento.codigo)
                            }}
                        >
                            Recibo
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        const opcoesMobile = pagamento => {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(pagamento)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    {pagamento.estado === "PENDENTE" ?
                        <Menu.Item>
                            <Link to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.marcarComoPago(pagamento.id)
                                }}
                            >
                                Marcar como Pago
                        </Link>
                        </Menu.Item>
                        : null}
                    {pagamento.estado === "PAGO" ?
                        <Menu.Item>
                            <Link to="#"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.marcarComoPendente(pagamento.id)
                                }}
                            >
                                Marcar como Pendente
                        </Link>
                        </Menu.Item>
                        : null}
                    <Menu.Item>
                        <Link to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.abrirDrawerUpload(pagamento.id, pagamento.codigo)
                            }}
                        >
                            Recibo
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        const montarHeader = pagamento => {
            return (<div className="content">
                <span className="desc">{pagamento.codigo}</span>
                <span className="desc">{moment(pagamento.data).format("DD/MM/YYYY")}</span>
                <span className="desc">{pagamento.tipo === "PAYPAL" ? "Paypal, CC ou Mercado Pago" : "Transferência"}</span>
                <span className={`desc ${pagamento.estado === "PAGO" ? "pagamento-pago" : "pagamento-pendente"}`}>{pagamento.estado}</span>
                <span className="desc">{this.context.moeda_entidade} {pagamento.total}</span>
                <span className="desc" style={{ textAlign: "right" }}>
                    {pagamento.comprovativo ?
                        <a href={pagamento.comprovativo} download title="Descarregar Comprovativo" onClick={event => event.stopPropagation()}><Icon type="download" /></a>
                        : null}
                </span>
                <span className="desc">
                    <Dropdown overlay={opcoes(pagamento)} placement="bottomLeft">
                        <Link to="#" className="btn-opcoes">
                            <i className="fas fa-cog" />
                        </Link>
                    </Dropdown>
                </span>
            </div>)
        }

        return (
            <div>
                <Header titulo="Pagamentos" />
                <div className="container container-body">
                    <div className="bloco-entidades bloco-pagamentos-utilizador">
                        <div className="bloco">
                            <div className="bloco-flex">
                                <div className="bloco-info" style={{ padding: 0 }}>
                                    <div className="dados-user">
                                        <div className="container-img">
                                            <img src={utilizador.foto ? utilizador.foto : noimage} alt="" />
                                        </div>
                                        <div>
                                            <h6 style={{ marginBottom: 0, color: "#3d464d", fontSize: "14px" }}>{utilizador.nome}</h6>
                                            <span style={{ fontSize: 12 }}>{utilizador.numero}</span>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/gestao-utilizadores-todos" className="botao-principal">
                                    Voltar
                                </Link>
                            </div>
                            <div className="bloco-pagamentos">
                                <div className="box-registos-pagamentos box-registos-pagamentos-desktop">
                                    <div className="header">
                                        <span className="desc"></span>
                                        <span className="desc">Compra</span>
                                        <span className="desc">Data</span>
                                        <span className="desc">Tipo de Pagamento</span>
                                        <span className="desc">Estado</span>
                                        <span className="desc">Preço total</span>
                                        <span className="desc"></span>
                                        <span className="desc"></span>
                                    </div>
                                    <Collapse className="lista-pagamentos-collapse">
                                        {pagamentos.length > 0 ?
                                            pagamentos.map((pagamento, index) => (
                                                <Panel key={index} header={montarHeader(pagamento)}>
                                                    <div className="box-registos-pagamentos-produtos">
                                                        <div className="header">
                                                            <span className="desc">Nome</span>
                                                            <span className="desc"></span>
                                                            <span className="desc"></span>
                                                        </div>
                                                        {pagamento.produtos.map((produto, index2) => (
                                                            <div key={index2} className="content">
                                                                {produto.tipo === "CURSO" ?
                                                                    <span className="desc desc-grid">
                                                                        <div className="container-capa">
                                                                            <img src={produto.capa} />
                                                                        </div>
                                                                        <div className="bloco-info">
                                                                            <span className="produto-nome">{produto.nome}</span>
                                                                            <span className="produto-descricao">{produto.descricao}</span>
                                                                        </div>
                                                                    </span>
                                                                    :
                                                                    <span className="desc">
                                                                        <div className="bloco-info">
                                                                            <span className="produto-nome">Subscrição {produto.nr_meses > 1 ? `${produto.nr_meses} meses` : `${produto.nr_meses} mês`}</span>
                                                                            <span className="produto-descricao">Subscrição de {moment(produto.dt_inicio).format("DD/MM/YYYY")} a {moment(produto.dt_fim).format("DD/MM/YYYY")}</span>
                                                                        </div>
                                                                    </span>
                                                                }
                                                                <span className="desc"></span>
                                                                <span className="desc">{this.context.moeda_entidade} {produto.preco}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Panel>
                                            ))
                                            :
                                            <div className="sem-registos">
                                                Não existem registos!
                                </div>
                                        }
                                    </Collapse>
                                </div>
                                <div className="box-registos-pagamentos box-registos-pagamentos-mobile">
                                    <div className="header">
                                        <span className="desc">Compra</span>
                                        <span className="desc">Estado</span>
                                        <span className="desc"></span>
                                    </div>
                                    {pagamentos.length > 0 ?
                                        pagamentos.map((pagamento, index) => (
                                            <div key={index} className="content">
                                                <span className="desc">
                                                    <div className="box-pagamento">
                                                        <div className="info-pagamento">
                                                            <span className="codigo-pagamento">
                                                                {pagamento.codigo}
                                                            </span>
                                                            <div className="data-pagamento">
                                                                {moment(pagamento.data).format("DD/MM/YYYY")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                                <span className="desc">
                                                    <div className="estado-pagamento">
                                                        {pagamento.estado === "PAGO" ?
                                                            <span className="estado-pagamento-icon-pago"><Icon type="check-circle" title="Pagamento Efetuado" /></span>
                                                            :
                                                            <span className="estado-pagamento-icon-pendente"><Icon type="warning" title="Pagamento Pendente" /></span>
                                                        }
                                                        <span className="estado-pagamento-texto">{this.context.moeda_entidade} {pagamento.total}</span>
                                                    </div>
                                                </span>
                                                <span className="desc">
                                                    <Dropdown overlay={opcoesMobile(pagamento)} placement="bottomLeft">
                                                        <Link to="#" className="btn-opcoes">
                                                            <i className="fas fa-cog" />
                                                        </Link>
                                                    </Dropdown>
                                                </span>
                                            </div>
                                        ))
                                        :
                                        <div className="sem-registos">
                                            Não existem registos!
                                </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DrawerFormUploadFicheiro
                    pagamentoId={pagamentoId}
                    codigoPagamento={codigoPagamento}
                    visible={visibleImportar}
                    onClose={() => this.setState({ visibleImportar: false })}
                />
                <Modal
                    visible={modalIconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">{modalMensagem}</p>
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-pagamentos"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Tipo de Pagamento">
                                    {tipoPagamento}
                                </Form.Item>
                                {docComprovativo &&
                                    <Form.Item label="Comprovativo">
                                        <a href={docComprovativo} download title="Descarregar Comprovativo"><Icon type="download" /></a>
                                    </Form.Item>}
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Produtos</h3>
                                <div className="box-registos-pagamentos-produtos">
                                    <div className="header">
                                        <span className="desc">Nome</span>
                                        <span className="desc"></span>
                                    </div>
                                    {produtos.map((produto, index2) => (
                                        <div key={index2} className="content">
                                            {produto.tipo === "CURSO" ?
                                                <span className="desc desc-grid">
                                                    <div className="container-capa">
                                                        <img src={produto.capa} />
                                                    </div>
                                                    <div className="bloco-info">
                                                        <span className="produto-nome">{produto.nome}</span>
                                                        <span className="produto-descricao">{produto.descricao}</span>
                                                    </div>
                                                </span>
                                                :
                                                <span className="desc">
                                                    <div className="bloco-info">
                                                        <span className="produto-nome">Subscrição {produto.nr_meses > 1 ? `${produto.nr_meses} meses` : `${produto.nr_meses} mês`}</span>
                                                        <span className="produto-descricao">Subscrição de {moment(produto.dt_inicio).format("DD/MM/YYYY")} a {moment(produto.dt_fim).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                </span>
                                            }
                                            <span className="desc">{this.context.moeda_entidade} {produto.preco}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        )
    }
}

const FormPagamentos = Form.create({ name: "pagamentos" })(Pagamentos)

export default withRouter(FormPagamentos)
