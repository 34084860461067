import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import { Icon, Table, Form, Input, notification, Modal, Select, Alert } from "antd";
import axios from "axios";

import { GlobalContext } from "../../../../GlobalState";

const Alunos = ({ match }) => {
    const context = useContext(GlobalContext);

    const [alunos, setAlunos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [grupoInfo, setGrupoInfo] = useState("");
    const [pautaId, setPautaId] = useState(0);
    const [bloqueada, setBloqueada] = useState(false);
    const [visible, setVisible] = useState(false);

    const [estado, setEstado] = useState("");
    const [texto, setTexto] = useState("");
    const [campoCalculado, setCampoCalculado] = useState(false);

    const listar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/aproveitamento/lista-alunos",
            timeout: 60 * 10 * 1000,
            params: {
                disciplinaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                let alunos = [];

                response.data.map(item => {
                    alunos.push({
                        key: item.id,
                        code: item.code,
                        aluno: item.nome
                    });
                });

                setAlunos(alunos);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const info = () => {
        const grupo = JSON.parse(localStorage.getItem("grupo"));

        axios({
            method: "get",
            url: "/api/turma/info-grupo",
            params: {
                grupoId: grupo.id
            }
        })
            .then(response => {
                if (response.data) {
                    if (response.data !== "CAMPO_CALCULADO") {
                        setEstado(response.data.estado);
                        setTexto(response.data.texto);
                        setCampoCalculado(response.data.campoCalculado);
                    } else setCampoCalculado(true);
                }
            })
            .catch(error => {});
    };

    const onRowClick = record => {
        localStorage.setItem(record.code, `${record.aluno}`);
        setRedirectLink(
            `/gerir-detalhe-turma/${match.params.turma
                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                .toLowerCase()}/aproveitamento-notas-alunos/${record.code}`
        );
        setRedirect(true);

        //localStorage.setItem("aproveitamento", JSON.stringify({
        //    estrutura: estruturaId,
        //    periodo: periodo,
        //    tipo: tipo
        //}))
    };

    useEffect(() => {
        listar();
        info();
        setGrupoInfo(JSON.parse(localStorage.getItem("grupo")));
    }, []);

    const columns = [
        {
            title: "Aluno",
            dataIndex: "aluno"
        }
    ];

    if (redirect) return <Redirect to={redirectLink} />;

    const acesso = context.acessos?.find(x => x.func === 21);

    return (
        <div className="container container-body">
            <div className="turma-resultados">
                <div className="controlos controlos-fundo" style={{ justifyContent: "flex-end" }}>
                    <div className="bloco-direita">
                        {/*<Link className="botao-icon-pdf botao-maior" to="#" title="Descarregar PDF">*/}
                        {/*    <Icon type="file-pdf" />*/}
                        {/*</Link>*/}
                        <Link
                            to={`/gerir-detalhe-turma/${match.params.turma
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/aproveitamento`}
                            className="botao-secundario botao-secundario-redondo"
                            style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            <Icon type="arrow-left" />
                        </Link>
                    </div>
                </div>
                <div className="bloco">
                    <div className="info" style={{ display: "block" }}>
                        <div className="info-header-conceitos">
                            <div style={{ fontSize: 16 }}>{grupoInfo.nome}</div>
                            <p className="estado" dangerouslySetInnerHTML={{ __html: texto }}></p>
                        </div>
                    </div>
                    <Table
                        id="cursos-disciplinas"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                        columns={columns}
                        dataSource={alunos}
                        pagination={false}
                        locale={{ emptyText: "Não existem dados!" }}
                        rowClassName={acesso?.read ? "clickeble-row" : ""}
                        onRow={record => {
                            return {
                                onClick: acesso?.read ? () => onRowClick(record) : () => {}
                            };
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default withRouter(Alunos);
