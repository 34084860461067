import React from "react";
import { Link } from "react-router-dom";
import { Modal, notification, Icon } from "antd";

const Detalhe = ({ info, visible, editar, excluir, onClose  }) => {
    return (
        <>
            <Modal
                visible={visible}
                maskClosable={false}
                className="modal-aula-detalhe"
                footer={null}
                closable={false}
            >
                <div className="container">
                    <div className="header">
                        <h3>{info.nome}</h3>
                        <div className="controls">
                            <Link className="botao-icon-editar" to="#" title="Editar" onClick={editar}>
                                <Icon type="edit" />
                            </Link>
                            <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={excluir}>
                                <Icon type="delete" />
                            </Link>
                            <Link to="#" className="botao-icon-fechar" title="Voltar" onClick={onClose}>
                                <Icon type="close" />
                            </Link>
                        </div>
                    </div>
                    <div className="body">
                        <div className="date"><Icon type="calendar" /> {info.data}</div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Detalhe;
