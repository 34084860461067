import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { Modal, Tooltip, Button } from "antd";
import axios from "axios";
import moment from "moment";

const CarouselGestor = ({ visivelModalInformacoes, confirmar, cancelar }) => {
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(true);
    const [lastLogin, setLastLogin] = useState("");
    const [informacoes, setInformacoes] = useState(true);
    const [redirect, setRedirect] = useState("");
    const [redirectLink, setRedirectLink] = useState("");

    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/utilizadores-informacoes/carregar",
        })
            .then(response => {
                setLastLogin(response.data.lastLogin)
                setInformacoes(response.data.lista)
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    }

    useEffect(() => {
        carregar();
    }, []);

    if (redirect)
        return <Redirect to={redirectLink} />

    return (
        <>
            <Modal
                title={<>
                    <span className="novidades-header-titulo">Novidades desde o último acesso</span>
                    <span className="novidades-header-subtitulo">{moment(lastLogin).format("DD MMMM YYYY - HH:mm")}</span>
                </>}
                visible={visivelModalInformacoes}
                //okText="Confirmar"
                //onOk={confirmar}
                //cancelText="Cancelar"
                //onCancel={cancelar}
                maskClosable={false}
                className="modal-novidades"
                closable={false}
                footer={[
                    <Button type="primary" onClick={confirmar}>
                        Confirmar
                    </Button>
                ]}
            >
                {loading ?
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                    :
                    <div className="body-modal-novidades">
                        {informacoes.map(info => (
                            <div className="bloco-novidade">
                                <div className="bloco-titulo-novidades">
                                    <h3>{info.codigo} - {info.nome}</h3>
                                    <span>
                                        <hr />
                                    </span>
                                </div>
                                <div className="bloco-lista-novidades">
                                    {info.informacoes.map(info2 => (
                                        <Tooltip placement="bottom" className="tag-tooltip" title={info2.texto}>
                                            <span className={`tag-novidade ${info2.tag}`}>{info2.tipo}</span>
                                        </Tooltip>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </Modal>
        </>
    );
};

export default CarouselGestor;
