import React, { useEffect, useState } from "react";
import axios from "axios";
import GraficoBarras from "./GraficoBarras";

export default function ProfessorDiariosAtrasados() {
    const [datasetSource, setDatasetSource] = useState();

    const [ano, setAno] = useState(new Date().getFullYear())

    const atualizarAno2  = (valor) => {
        setAno(valor)
        dados(valor)
    }
    const dados = (ano) => {
        axios.get(`/api/dashboard/desempenho-preenchimento?ano=${ano}`
            )
            .then(response => {
                

                // Converte os valores em percentuais
                const dadosConvertidos = response.data.map((linha, index) => {
                    if (index === 0) {
                        return linha;
                    }
                    const total = linha[1] + linha[2];
                    const preenchidaTruePercent = total !== 0 ? (linha[1] / total * 100).toFixed(2) : 0;
                    const preenchidaFalsePercent = total !== 0 ? (linha[2] / total * 100).toFixed(2) : 0;
                    return [linha[0], preenchidaTruePercent, preenchidaFalsePercent];
                });
                
                setDatasetSource(dadosConvertidos);
            })
            .catch(error => {
                console.error("Erro ao carregar os dados dos alunos:", error);
            });
    };

    useEffect(() => {
        dados(ano);
    }, []);

    return (
        <GraficoBarras
            titulo="Professor com baixo desempenho em preenchimento"
            datasetSource={datasetSource}
            atualizarAno2={atualizarAno2}
        />
    );
}
