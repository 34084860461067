import React from "react";
import img from "../images/oops.png";

const Oops = props => {
    return (
        <div className="oops">
            <div className="container-img">
                <img src={img} alt="Imagem" />
            </div>
            {props.tem_texto ?
                <span>{props.texto}</span>
                :
                <span>Não existem dados!</span>
            }
        </div>
    );
};

export default Oops;
