import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import HelmetMetaData from "./Geral/_Aux/Helmet";

import "./scss/main.scss";
import "antd/dist/antd.css";
import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

import { GlobalContext } from "./GlobalState";

import PaginaEntidadeLayout from "./PaginaEntidade/Layout";

import HomeLayout from "./Home/Layout";
import UserLayout from "./User/Layout";
import UserResponsavelLayout from "./UserResponsavel/Layout";
import PaginaSucesso from "./Geral/PaginaSucesso";
import PaginaErro from "./Geral/PaginaErro";

import axios from "axios";
import { sha256 } from "js-sha256";
import Popup from "./Popup/Main";


class App extends Component {
    static contextType = GlobalContext;

    render() {
        var d = new Date();
        var dia = d.getUTCDate();
        var mes = d.getUTCMonth() + 1;
        var ano = d.getUTCFullYear();
        axios.defaults.headers.common["apikey"] = sha256(`${dia}/${mes}/${ano}`);

        return (
            <div className="App">
                <HelmetMetaData
                    title={this.context.title_entidade ? this.context.title_entidade : undefined}
                    icon={this.context.favicon_entidade ? this.context.favicon_entidade : undefined}
                />

                <Switch>
                    <Route exact path="/" component={HomeLayout} />
                    {/*<Route exact path="/popup" component={Popup} />*/}

                    <Route path="/dropbox-auth" component={HomeLayout} />
                    <Route path="/pagina-nao-encontrada" component={HomeLayout} />
                    {/*<Route path="/login" component={HomeLayout} />*/}
                    <Route path="/alteracao-senha" component={HomeLayout} />
                    {/*<Route path="/testes" component={HomeLayout} />*/}
                    {/*<Route path="/testes2" component={HomeLayout} />*/}
                    {/*<Route path="/tarefas-temp" component={HomeLayout} />*/}
                    <Route path="/redirecionar" component={HomeLayout} />
                    {/*<Route path="/redirecionar-utilizador" component={HomeLayout} />*/}
                    <Route path="/controller" component={HomeLayout} />
                    <Route path="/recuperar-senha" component={HomeLayout} />
                    <Route path="/alterar-senha" component={HomeLayout} />
                    <Route path="/confirmar-email" component={HomeLayout} />
                    <Route path="/registar-passo1" component={HomeLayout} />
                    <Route path="/registar-passo2" component={HomeLayout} />
                    <Route path="/registar-passo3" component={HomeLayout} />
                    <Route path="/registo-conclusao" component={HomeLayout} />
                    <Route path="/registo-conclusao-contacto" component={HomeLayout} />
                    <Route path="/entidade-registar-passo-1" component={HomeLayout} />
                    <Route path="/entidade-registar-passo-2" component={HomeLayout} />
                    <Route path="/utilizador-registar-passo-1" component={HomeLayout} />
                    <Route path="/utilizador-registar-passo-2" component={HomeLayout} />
                    <Route path="/utilizador-registo-conclusao" component={HomeLayout} />
                    <Route path="/suporte-contacto" component={HomeLayout} />
                    <Route path="/termos-uso" component={HomeLayout} />
                    <Route path="/politica-privacidade" component={HomeLayout} />
                    <Route path="/cancelar-subscricao/:entidade" component={HomeLayout} />
                    <Route path="/cancelar-subscricao" component={HomeLayout} />
                    <Route path="/404" component={HomeLayout} />
                    {/* ROUTING AUTENTICADO */}
                    {/*<Route path="/user-contacto" component={UserLayout} />*/}
                    <Route path="/faq" component={UserLayout} />
                    <Route path="/tutoriais" component={UserLayout} />
                    <Route path="/versoes" component={UserLayout} />
                    <Route path="/informacoes" component={UserLayout} />
                    <Route path="/pagina" component={UserLayout} />
                    <Route path="/hometeste" component={UserLayout} />
                    <Route path="/user" component={UserLayout} />
                    <Route path="/gestao-utilizadores-detalhe/:code" component={UserLayout} />
                    <Route path="/utilizador-detalhe" component={UserLayout} />
                    <Route path="/detalhe/:code" component={UserLayout} />
                    <Route path="/turmas" component={UserLayout} />
                    <Route path="/documentos/:code" component={UserLayout} />
                    <Route path="/sistema-informacao/consultar" component={UserLayout} />
                    <Route path="/sistema-informacao" component={UserLayout} />
                    <Route path="/sistema-informacao-questionario/:curso/player-questionario" component={UserLayout} />
                    <Route path="/sistema-informacao-avaliacao/:curso" component={UserLayout} />
                    <Route path="/ranking" component={UserLayout} />
                    {/*<Route path="/resultados" component={UserLayout} />*/}
                    <Route path="/favoritos" component={UserLayout} />
                    <Route path="/formador-lista-cursos" component={UserLayout} />
                    <Route path="/formador-lista-turmas" component={UserLayout} />
                    <Route path="/formador-competencias" component={UserLayout} />
                    <Route path="/formador-videoconferencias" component={UserLayout} />
                    <Route path="/formador-webinars" component={UserLayout} />
                    <Route exact path="/administracao-entidades" component={UserLayout} />
                    <Route exact path="/administracao-entidades/documentacao" component={UserLayout} />
                    <Route exact path="/administracao-entidades/:entidadeid/novo-ticket" component={UserLayout} />
                    <Route path="/administracao-newsletters" component={UserLayout} />
                    <Route path="/administracao-sistema-informacao" component={UserLayout} />
                    <Route path="/gestao-suporte" component={UserLayout} />
                    <Route path="/gestao-suporte-detalhe" component={UserLayout} />
                    <Route path="/gestao-suporte-novo" component={UserLayout} />
                    <Route path="/administracao-suporte" component={UserLayout} />
                    <Route path="/administracao-suporte-detalhe" component={UserLayout} />
                    <Route path="/administracao-suporte-novo" component={UserLayout} />
                    <Route path="/calendario" component={UserLayout} />
                    <Route path="/relatorios" component={UserLayout} />
                    <Route path="/logs" component={UserLayout} />
                    <Route path="/chat/:code" component={UserLayout} />
                    <Route path="/chat" component={UserLayout} />
                    <Route path="/chat-m" component={UserLayout} />
                    <Route path="/user-suporte" component={UserLayout} />
                    <Route path="/user-suporte-detalhe" component={UserLayout} />
                    <Route path="/user-suporte-novo" component={UserLayout} />
                    <Route path="/questionario-satisfacao" component={UserLayout} />
                    <Route path="/avaliar-curso" component={UserLayout} />
                    <Route path="/user-minha-conta" component={UserLayout} />
                    <Route path="/user-alterar-senha" component={UserLayout} />
                    <Route path="/user-alterar-login" component={UserLayout} />
                    <Route path="/user-configuracoes" component={UserLayout} />
                    <Route path="/user-estatisticas" component={UserLayout} />
                    <Route path="/gestao-unidades-organizacionais" component={UserLayout} />
                    <Route path="/gestao-conta" component={UserLayout} />
                    <Route path="/gestao-noticias" component={UserLayout} />

                    <Route path="/gestao-fotos" component={UserLayout} />
                    <Route path="/gestao-videos" component={UserLayout} />
                    <Route path="/gestao-carrossel" component={UserLayout} />
                    <Route path="/gestao-comentario" component={UserLayout} />
                    <Route path="/gestao-dashboard" component={UserLayout} />
                    <Route path="/dashboard" component={UserLayout} />

                    <Route path="/gestao-paginas" component={UserLayout} />
                    <Route path="/gestao-faq" component={UserLayout} />
                    <Route path="/gestao-cursos" component={UserLayout} />
                    <Route path="/gestao-turmas" component={UserLayout} />
                    <Route path="/gestao-templates-turmas" component={UserLayout} />
                    <Route path="/gestao-agrupar-turmas/:code" component={UserLayout} />
                    <Route path="/gestao-videoconferencias" component={UserLayout} />
                    <Route path="/gestao-webinars" component={UserLayout} />
                    <Route path="/:curso/gestao-cursos-inscricoes" component={UserLayout} />
                    <Route path="/:curso/gestao-curso-inscricoes" component={UserLayout} />
                    <Route path="/:curso/gestao-cursos-estatisticas" component={UserLayout} />
                    <Route path="/:curso/gestao-turmas-inscricoes" component={UserLayout} />
                    <Route path="/:curso/gestao-turma-inscricoes" component={UserLayout} />
                    <Route path="/:curso/gestao-turmas-estatisticas" component={UserLayout} />
                    <Route path="/gestao-formadores" component={UserLayout} />
                    <Route path="/gestao-tipos" component={UserLayout} />
                    <Route path="/gestao-competencias" component={UserLayout} />
                    <Route path="/gestao-categorias" component={UserLayout} />
                    <Route path="/gestao-turmas-categorias" component={UserLayout} />
                    <Route path="/gestao-categoria-noticias" component={UserLayout} />
                    <Route path="/gestao-fichas" component={UserLayout} />
                    <Route path="/gestao-calendarios-escolares" component={UserLayout} />
                    <Route path="/gestao-calendarios-escolares-detalhe/:code" component={UserLayout} />
                    <Route path="/gestao-calendarios-eventos" component={UserLayout} />
                    <Route path="/gestao-calendarios-eventos-detalhe/:code" component={UserLayout} />
                    <Route path="/gestao-edicoes" component={UserLayout} />
                    <Route path="/gestao-turmas-edicoes" component={UserLayout} />
                    <Route path="/gestao-turmas-anos-letivos" component={UserLayout} />
                    <Route path="/gestao-turmas-turnos" component={UserLayout} />
                    <Route path="/gestao-turmas-tipo-horas" component={UserLayout} />
                    <Route path="/gestao-diario" component={UserLayout} />
                    <Route path="/gestao-pagamentos" component={UserLayout} />
                    <Route path="/gestao-newsletters" component={UserLayout} />
                    <Route path="/gestao-estatisticas" component={UserLayout} />
                    <Route path="/gestao-questionarios" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-todos/novo-ticket/:tipo" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-todos/declaracao/:id" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-todos" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-sistema-informacao/consultar-notas-creche/:utilizador" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-sistema-informacao/consultar-notas-pre/:utilizador" component={UserLayout} />
                    <Route
                        exact
                        path="/gestao-utilizadores-sistema-informacao/consultar-notas-regular/:utilizador"
                        component={UserLayout}
                    />
                    <Route exact path="/gestao-utilizadores-sistema-informacao/consultar/:utilizador" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-sistema-informacao/:utilizador" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-ranking/:utilizador" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-resultados/:utilizador" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-documentacao/:utilizador" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-pagamentos/:utilizador" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-associar" component={UserLayout} />
                    <Route exact path="/gestao-associar-alunos" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-pendentes" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-inscricoes" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-formacoes/:idcolaborador" component={UserLayout} />
                    <Route exact path="/gestao-utilizadores-estatisticas" component={UserLayout} />
                    <Route path="/pesquisa-cursos" component={UserLayout} />
                    <Route path="/:entidade/curso/:curso/:cursoid" component={PaginaEntidadeLayout} />

                    <Route path="/:entidade/noticia/:id" component={PaginaEntidadeLayout} />
                    <Route path="/:entidade/areaAluno/:id" component={PaginaEntidadeLayout} />
                    <Route path="/:entidade/areaAlunoRelatorio/:id" component={PaginaEntidadeLayout} />
                    <Route path="/:entidade/areaAlunoCalendario/:id" component={PaginaEntidadeLayout} />

                    <Route path="/pagina-sucesso" component={PaginaSucesso} />
                    <Route path="/pagina-erro" component={PaginaErro} />
                    <Route path="/:curso/gestao-cursos-resultados" component={UserLayout} />
                    <Route path="/:curso/gestao-cursos-disciplinas-resultados" component={UserLayout} />
                    <Route path="/:curso/:cursoid/formador-lista-cursos/avaliacoes-resultados" component={UserLayout} />
                    <Route path="/:curso/formador-lista-cursos/inscricoes" component={UserLayout} />
                    <Route path="/:curso/:cursoid/dados-aluno-curso/:utilizadorid" component={UserLayout} />
                    <Route path="/:curso/gestao-cursos-ranking" component={UserLayout} />
                    <Route path="/consultar/:curso/:pagina/:utilizadorid" component={UserResponsavelLayout} />
                    <Route path="/curso/:curso/:pagina" component={UserLayout} />
                    <Route path="/detalhe-turma/:turma/:pagina" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:pagina" component={UserLayout} />
                    <Route path="/gerir-detalhe-turma/:turma/:pagina" component={UserLayout} />
                    <Route path="/gerir-multisseriado/:turma/:pagina" component={UserLayout} />
                    <Route path="/:curso/formador-lista-cursos/ranking" component={UserLayout} />
                    <Route path="/:curso/formador-lista-cursos/resultados" component={UserLayout} />
                    <Route path="/:curso/formador-lista-cursos/resultados-curso-disciplinas" component={UserLayout} />
                    <Route path="/:curso/formador-lista-cursos/estatisticas" component={UserLayout} />
                    <Route path="/:curso/formador-lista-cursos/avaliacao-resultados" component={UserLayout} />
                    <Route path="/:curso/formador-lista-cursos/player-questionario" component={UserLayout} />

                    {/***************ROUTING PLAYERS TURMA GESTAO/INSTRUTOR***************/}
                    <Route path="/turma-player/:curso/:aula/player-videoconferencia" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-video" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-audio" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-pagina" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-pdf" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-questionario-dirigido" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-questionario-dirigido-resumo" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-questionario" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-questionario-resumo" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-questionario-resolucao" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-avaliacao" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-avaliacao-resumo" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-avaliacao-resolucao" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-questionario-progresso" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-questionario-progresso-resumo" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-questionario-progresso-resolucao" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-download" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-trabalho" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-link" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-sumario" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-sessao-presencial" component={UserLayout} />
                    <Route path="/turma-player/:curso/:aula/player-assiduidade" component={UserLayout} />
                    <Route path="/turma-player/:curso/:ficheiro/player-trabalho-ficheiro" component={UserLayout} />
                    <Route path="/turma-player/:curso/:ficheiro/player-ficheiro" component={UserLayout} />
                    <Route path="/turma-player/:curso/:ficheiro/player-editor" component={UserLayout} />
                    <Route path="/turma-player/:curso/vconf/:vconfid/player-videoconferencia" component={UserLayout} />
                    <Route path="/turma-player/:curso/vconf/:vconfid/player-video" component={UserLayout} />
                    <Route path="/turma-player/:curso/vconf/:vconfid/player-video-meets" component={UserLayout} />
                    {/***************ROUTING PLAYERS TURMA GESTAO/INSTRUTOR***************/}

                    {/***************ROUTING PLAYERS GESTAO/INSTRUTOR***************/}
                    <Route path="/gerir-curso/:curso/:aula/player-videoconferencia" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-video" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-audio" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-pagina" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-pdf" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-dirigido" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-dirigido-resumo" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-resumo" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-resolucao" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-avaliacao" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-avaliacao-resumo" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-avaliacao-resolucao" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-progresso" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-progresso-resumo" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-progresso-resolucao" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-download" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-trabalho" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-link" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-sumario" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-sessao-presencial" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:aula/player-assiduidade" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:ficheiro/player-trabalho-ficheiro" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:ficheiro/player-ficheiro" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/:ficheiro/player-editor" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/vconf/:vconfid/player-videoconferencia" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/vconf/:vconfid/player-video" component={UserLayout} />
                    <Route path="/gerir-curso/:curso/vconf/:vconfid/player-video-meets" component={UserLayout} />
                    <Route path="/gestao-videoconferencia/:vconfid/player-videoconferencia" component={UserLayout} />
                    <Route exact path="/gestao-webinar/:vconfid/player-webinar" component={UserLayout} />
                    <Route path="/gestao-videoconferencia/:vconfid/player-video" component={UserLayout} />
                    <Route path="/gestao-videoconferencia/:vconfid/player-video-meets" component={UserLayout} />
                    <Route path="/gestao-webinar/:vconfid/player-video" component={UserLayout} />
                    <Route path="/gestao-webinar/:vconfid/player-video-meets" component={UserLayout} />
                    {/***************ROUTING PLAYERS GESTAO/INSTRUTOR***************/}

                    {/***************ROUTING PLAYERS***************/}
                    <Route exact path="/curso/:curso/:aula/player-videoconferencia" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-video" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-audio" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-pagina" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-pdf" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-questionario-dirigido" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-questionario-dirigido-resumo" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-questionario" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-questionario-resumo" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-questionario-resolucao" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-avaliacao" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-avaliacao-resumo" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-avaliacao-resolucao" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-questionario-progresso" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-questionario-progresso-resumo" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-questionario-progresso-resolucao" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-download" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-trabalho" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-link" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-sumario" component={UserLayout} />
                    <Route exact path="/curso/:curso/:aula/player-sessao-presencial" component={UserLayout} />
                    <Route exact path="/curso/:curso/:ficheiro/player-trabalho-ficheiro" component={UserLayout} />
                    <Route exact path="/curso/:curso/:ficheiro/player-ficheiro" component={UserLayout} />
                    <Route exact path="/curso/:curso/vconf/:vconfid/player-videoconferencia" component={UserLayout} />
                    <Route exact path="/curso/:curso/vconf/:vconfid/player-videoconferencia-aguardar" component={UserLayout} />
                    <Route exact path="/curso/:curso/vconf/:vconfid/player-video" component={UserLayout} />
                    <Route exact path="/curso/:curso/vconf/:vconfid/player-video-meets" component={UserLayout} />
                    {/***************ROUTING PLAYERS***************/}

                    {/***************ROUTING PLAYERS***************/}
                    <Route exact path="/detalhe-turma/:turma/:aula/player-videoconferencia" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-video" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-audio" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-pagina" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-pdf" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-questionario-dirigido" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-questionario-dirigido-resumo" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-questionario" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-questionario-resumo" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-questionario-resolucao" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-avaliacao" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-avaliacao-resumo" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-avaliacao-resolucao" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-questionario-progresso" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-questionario-progresso-resumo" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-questionario-progresso-resolucao" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-download" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-trabalho" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-link" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-sumario" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:aula/player-sessao-presencial" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:ficheiro/player-trabalho-ficheiro" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/:ficheiro/player-ficheiro" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/vconf/:vconfid/player-videoconferencia" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/vconf/:vconfid/player-videoconferencia-aguardar" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/vconf/:vconfid/player-video" component={UserLayout} />
                    <Route exact path="/detalhe-turma/:turma/vconf/:vconfid/player-video-meets" component={UserLayout} />
                    {/***************ROUTING PLAYERS***************/}
                    <Route path="/:entidade/:nome/:pagina" component={PaginaEntidadeLayout} />
                    <Route path="/:entidade" component={PaginaEntidadeLayout} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(props => <App {...props} />);
