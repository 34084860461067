import React, { Component } from "react";
import { Input, Icon } from "antd";

class BotaoProcurar extends Component {
    state = {
        pesquisa: ""
    };

    UNSAFE_componentWillMount() {
        this.setState({
            pesquisa: this.props.pesquisa
        });
    }

    emitEmpty = () => {
        this.setState({ pesquisa: "" });
        this.props.resetCaixaProcura();
    };

    onChange = e => {
        this.setState({ pesquisa: e.target.value });

        if (!e.target.value) this.props.resetCaixaProcura();
    };

    render() {
        const { pesquisa } = this.state;
        const suffix = pesquisa ? <Icon type="close-circle" onClick={this.emitEmpty} /> : null;

        return (
            <div className="procurar" style={this.props.style}>
                <Input
                    id="procurar"
                    prefix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder={this.props.tem_placeholder ? this.props.placeholder : "Procurar"}
                    suffix={suffix}
                    value={pesquisa}
                    onChange={this.onChange}
                    onKeyPress={e => this.props.HandleKeyPress(e)}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

export default BotaoProcurar;
