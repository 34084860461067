import React, { useEffect, useRef, useState } from 'react';
import '../../../scss/_cardCarrosel.scss';
import TituloSeccao from './tituloSeccao';
import VideoCard from './VideoCard';
import VideoCard2 from './VideoCard2';
import Modal from 'react-modal';
import ModalVideo from './ModalVideo';
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import Card from "./card";


const CardCarousel = ({ cards, titulo, slides, Card }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalIsOpen, setIsOpen] = useState(false);
    const mainContentRef = useRef(null);
    const [topPosition, setTopPosition] = useState(0);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const [capa, setCapa] = useState(false)

    // const accessToken = "38964d4d0ceba6f3a5003fc0103f96ba";
    //
    // const headerPost = {
    //     Accept: "application/vnd.vimeo.*+json;version=3.4",
    //     Authorization: `Bearer ${accessToken}`,
    //     "Content-Type": "application/json"
    // };
    // const fetchPictures = async (idPic)=>{
    //     const url = `https://api.vimeo.com/videos/${idPic}/pictures`
    //
    //     try{
    //         const response = await axios.get(url,{
    //             transformRequest: [function (data, headers) {
    //                 delete headers.common['apikey'];
    //                 return JSON.stringify(data);
    //             }],
    //             headers: headerPost
    //         });
    //         return(response.data);
    //
    //     } catch (error) {
    //         console.error('Error ao fazer a requisição GET', error);
    //     }
    // }

    // const carregarCapa = async () => {
    //    
    //     cards.map( async (e) => {
    //         try {
    //             const data = await fetchPictures(e.idVideo);
    //             //console.log('Dados recebidos:', data.data[0].base_link);
    //             e.capa = data.data[0].base_link
    //         } catch (error) {
    //             console.error('Erro:', error);
    //         }
    //     })
    //    
    //     setCapa(true)
    //    
    // };
    // //carregarCapa()

    const cardpairs = [];


    for (let i = -1; i < cards.length - 1; i += 2) {
        if (i === -1) {
            cardpairs.push([cards[0]]);
        } else {
            if (cards[i + 1] === null) {
                cardpairs.push([cards[i]])
            } else {
                cardpairs.push([cards[i], cards[i + 1]]);
            }
        }
    }


    var cardUnico

    if (cards.length % 2 === 0) {
        cardpairs.push([cards[cards.length - 1]])
        cardUnico = true
    }

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - slides, 0));
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            const remainingSlides = isTabletOrMobile ? (cardpairs.length - 1) : (cardpairs.length) - (prevIndex + slides);
            return remainingSlides > slides ? prevIndex + slides : prevIndex + remainingSlides;
        });
    };

    useEffect(() => {
        if (mainContentRef.current) {
            setTopPosition(mainContentRef.current.offsetHeight + 30);
        }
    }, []);

    const [selectedCard, setSelectedCard] = useState(null);
    const handleOpenModal = (card) => {
        setSelectedCard(card);
        openModal();
    };

    const openModal = () => setIsOpen(true);

    const closeModal = () => {
        setIsOpen(false)
    }


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '1100px',
            borderRadius: '10px',
            overflow: 'hidden',
            border: 0,
            padding: 0,
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
    };
    if (cards.length === 0) {
        return (
            <>
            </>
        )
    }
    return (


        <div>
            <div style={{ width: isTabletOrMobile ? '100%' : '1440px', margin: 'auto' }}>
                <TituloSeccao
                    titulo={titulo}
                    handlerPrevSlide={prevSlide}
                    handlerNextSlide={nextSlide}
                    currentIndex={currentIndex}
                    numeroDeSlides={isTabletOrMobile ? (cardpairs.length - 1) : (cardpairs.length)}
                    slides={slides}
                />
            </div>
            <div style={{ backgroundColor: '#d0eaf2' }}>
                {isTabletOrMobile && (
                    <div>
                        <VideoCard2
                            openModal={() => handleOpenModal(cardpairs[0][0])}
                            mainContentRef={mainContentRef}
                            topPosition={topPosition}
                            title={cardpairs[0][0].titulo}
                            text={cardpairs[0][0].descricao}
                            capa={cardpairs[0][0].capa}
                        />
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            {selectedCard && <ModalVideo handlerClose={closeModal} card={selectedCard} />}
                        </Modal>
                    </div>
                )}
                <div className="card-carousel-container corpo">
                    <div className="carousel-wrapper">
                        <div className="card-carousel">
                            <div
                                className="carousel-inner"
                                style={{ transform: `translateX(-${currentIndex * (100 / slides)}%)` }}
                            >
                                {cardpairs.map((card, index) => (

                                    index === 0 ? (
                                        !isTabletOrMobile && (
                                            <div key={index} className={`carousel-item ${(cardUnico && card[1] === undefined) ? 'card-unico' : ''}`} style={{ minWidth: `${100 / slides}%` }}>
                                                <div style={{ display: "flex" }}>
                                                    <div className="main-card">
                                                        <VideoCard2
                                                            openModal={() => handleOpenModal(card[0])}
                                                            mainContentRef={mainContentRef}
                                                            topPosition={topPosition}
                                                            title={card[0].titulo}
                                                            text={card[0].descricao}
                                                            capa={card[0].capa}
                                                        />
                                                        <Modal
                                                            isOpen={modalIsOpen}
                                                            onRequestClose={closeModal}
                                                            style={customStyles}
                                                            contentLabel="Example Modal"
                                                        >
                                                            {selectedCard && <ModalVideo handlerClose={closeModal} card={selectedCard} />}
                                                        </Modal>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div key={index}
                                            className="carousel-item"
                                            //className={`carousel-item ${(cardUnico && card[1] === undefined) ? 'card-unico' : ''}`}
                                            style={{ minWidth: `${100 / slides}%` }}>
                                            <div>
                                                <VideoCard
                                                    openModal={() => handleOpenModal(card[0])}
                                                    img={card[0].img}
                                                    title={card[0].titulo}
                                                    description={card[0].descricao}
                                                    capa={card[0].capa}
                                                />
                                                {card[1] !== undefined ?
                                                    <VideoCard
                                                        openModal={() => handleOpenModal(card[1])}
                                                        img={card[1].img}
                                                        title={card[1].titulo}
                                                        description={card[1].descricao}
                                                        capa={card[1].capa}
                                                    />
                                                    :
                                                    <div style={{ height: 200, margin: "10px 0" }}></div>
                                                }
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardCarousel;
