import React, { Component } from "react";
import { Form } from "antd";
import { Link } from "react-router-dom";
import logo from "../images/logos/stepforma-01.png";
import imageSuccess from "../images/email/msg-sucesso.svg";
import imageError from "../images/email/msg-erro.svg";
import axios from "axios";
import Header from "../User/Header";

class ConfirmarEmail extends Component {
    state = {
        emailConfirmado: true
    };

    UNSAFE_componentWillMount() {
        this.confirmarEmail();
    }

    getParameterByName = name => {
        const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    confirmarEmail = () => {
        axios({
            method: "put",
            url: "/api/autenticacaonew/confirmar-email",
            params: {
                userId: this.getParameterByName("userId"),
                code: this.getParameterByName("code")
            }
        })
            .then(() => {
                this.setState({
                    emailConfirmado: true
                });
            })
            .catch(() => {
                this.setState({
                    emailConfirmado: false
                });
            });
    };

    render() {
        const { emailConfirmado } = this.state;

        return (
            <>
                <Header titulo="Confirmar E-mail" />
                <Form className="confirmar-email">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    {emailConfirmado ? (
                        <>
                            <div className="bloco-confirmar-email">
                                <img src={imageSuccess} />
                                <span>E-mail confirmado!</span>
                            </div>
                            <Form.Item>
                                <Link to="/login" className="link-principal">
                                    Continuar
                                </Link>
                            </Form.Item>
                        </>
                    ) : (
                        <div className="bloco-confirmar-email">
                            <img src={imageError} />
                            <span>Não foi possível confirmar o e-mail!</span>
                        </div>
                    )}
                </Form>
            </>
        );
    }
}
const FormConfirmarEmail = Form.create({ name: "confirmar-email" })(ConfirmarEmail);

export default FormConfirmarEmail;
