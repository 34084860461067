import React, { useState, useEffect } from "react";
import { Icon } from "antd";
import axios from "axios";

const TabelaNotasConceito = ({ cursoId, faseId, utilizadorId }) => {
    const [periodos, setPeriodos] = useState([]);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [loading, setLoading] = useState(true);

    const listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-conceito",
            params: {
                cursoId,
                faseId,
                utilizadorId
            }
        })
            .then(response => {
                setPeriodos(response.data.periodos);
                setAvaliacoes(response.data.avaliacoes);
                setLoading(false);
            })
            .catch();
    }

    const montarNotas = (nota) => {
        return (avaliacoes.map((avaliacao, index) => (
            <td className={`celula-tabela-notas-pre ${(index === (avaliacoes.length - 1) ? "td-border-right" : "")}`} align="left" valign="bottom" ><div>{nota === avaliacao || nota === "true" ? <Icon type="check-square" /> : <Icon type="border" />}</div></td>
        )));
    }

    const montarParametros = (niveis, avaliacoesFiltradas, listaAvaliacoesOriginal) => {
        const parametros = avaliacoesFiltradas.map((avaliacao, index) => {
            let lista = [];
            const subParametros = listaAvaliacoesOriginal.filter(x => x.idPai === avaliacao.id);
            if (subParametros.length > 0) {
                lista = montarParametros(`${niveis}${index + 1}.`, subParametros, listaAvaliacoesOriginal)
            }

            return <>
                <tr key={avaliacao.id} className="tabela-body">
                    {subParametros.length > 0 ?
                        <td colSpan={avaliacoes.length + 1} align="left" valign="middle"><p color="#000000"><strong>{niveis + (index + 1)}</strong> - {avaliacao.titulo}</p></td>
                        :
                        <>
                            <td className="td-border-right" align="left" valign="middle"><p color="#000000"><strong>{niveis + (index + 1)}</strong> - {avaliacao.titulo}</p></td>
                            {montarNotas(avaliacao.nota)}
                        </>
                    }
                </tr>
                {lista.map(item => item)}
            </>
        });

        return parametros;
    }

    useEffect(() => {
        listar();
    }, []);

    if (loading)
        return (
            <div className="loading-data" style={{ minHeight: 100 }}>
                <div className="loading" />
            </div>
        );

    return (
        <>
            <div>
                {periodos.map(periodo => (
                    <table key={periodo.id} className="tabela-notas-pre tabela-notas-regular" cellSpacing={0} border={0} style={{ width: "100%" }}>
                        <tbody>
                            <tr className="tabela-head">
                                <td align="center" colSpan={avaliacoes.length + 1} valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                            </tr>
                            <tr>
                                <td align="center" valign="middle"><b><font color="#000000"></font></b></td>
                                {avaliacoes.map((avaliacao, index) => (
                                    <td key={index} align="center" valign="middle"><b><font color="#000000">{avaliacao}</font></b></td>
                                ))}
                            </tr>
                            {montarParametros("", periodo.avaliacoes.filter(x => x.idPai === periodo.id), periodo.avaliacoes)}
                        </tbody>
                    </table>
                ))}
            </div>
        </>
    );
}

export default TabelaNotasConceito;