import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, notification, Input, Icon } from "antd";
import Topico from "./_Aux/Topico";
import DrawerNovaPergunta from "./DrawerNovaPergunta";
import { GeralContext } from "../ContextProvider";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

import Acessos from "../../Geral/Acessos/Acessos";

const { TextArea } = Input;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Forum extends Component {
    static contextType = GeralContext;
    state = {
        cursoid: 0,
        turma: "",
        retomar: {},
        forum_curso: [],
        forum_curso_modulo: [],
        razao: "",
        total_conteudos: 0,
        total_conteudos_realizados: 0,
        //DRAWER
        visible: false,
        //LOADING
        loading: true,
        //REDIRECT
        redirect: false,
        redirectLink: "",
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso")

        this.setState({
            cursoid: cursoId,
            turma: this.props.match.params.turma
        });
        this.CarregarForumCurso(cursoId);
    }

    handlerChange = event => {
        this.setState({
            razao: event.target.value
        });
    };

    //CARREGA OS TOPICO SOBRE O CURSO
    CarregarForumCurso = cursoid => {
        axios({
            method: "get",
            url: "/api/gerir-forum/gerir-forum-curso",
            timeout: 60 * 10 * 1000,
            params: {
                cursoid: cursoid
            }
        })
            .then(response => {
                this.setState({
                    forum_curso: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    enviarAulaInfo = info => {
        localStorage.setItem("info", info);
    };

    //EXCLUI O TOPICO
    excluir = forumid => {
        confirm({
            title: "Pretende excluir este tópico?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gerir-forum/excluir-forum",
                    params: {
                        forumid: forumid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tópico excluído!");
                        this.CarregarForumCurso(this.state.cursoid);
                        this.context.atualizarForum(this.context.total_topicos - 1);
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o tópico!");
                    });
            }
        });
    };

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<Link
                to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar}
                className="botao-secundario botao-secundario-redondo"
                onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}
                style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Icon type="arrow-left" />
            </Link>);
        }
    }

    render() {
        const {
            cursoid,
            turma,
            forum_curso,
            visible,
            //REDIRECT
            redirect,
            redirectLink,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const acesso = this.context.acessos?.find(x => x.func === 20);

        return (
            <>
                <Acessos funcionalidadeId={20} />
                <GlobalContext.Consumer>
                {GlobalContext => {
                    return (
                        <div className="container container-body">
                            {this.state.loading ? (
                                <div className="loading-data">
                                    <div className="loading" />
                                </div>
                            ) : (
                                <div className="curso-forum">
                                    <div className="controlos controlos-fundo">
                                            <div className="bloco-esquerda">
                                                {acesso?.create && (
                                                    <Link to="#" className='botao-principal botao-principal-redondo' title="Novo tópico" disabled={this.context.fechado} onClick={() => this.setState({ visible: true })}>
                                                        +
                                                    </Link>)}
                                        </div>
                                        <div className="bloco-direita">
                                            {this.montarBotaoVoltar()}
                                        </div>
                                    </div>
                                    <div className="topicos">
                                        <div className="bloco-topicos">
                                            <div className="forum-aulas-listagem">
                                                {forum_curso.length > 0 ? (
                                                    <div className="forum-content">
                                                        <div className="forum-listagem">
                                                            {forum_curso.map((topico, index) => (
                                                                <Topico
                                                                    key={index}
                                                                    url={`/gerir-detalhe-turma/${turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/forum-detalhe`}
                                                                    topico={topico}
                                                                    excluir={this.excluir}
                                                                    fechado={this.context.fechado}
                                                                    acesso={acesso}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <DrawerNovaPergunta
                                visible={visible}
                                onClose={() => this.setState({ visible: false })}
                                Adicionar={() => {
                                    this.setState({ visible: false });
                                    this.CarregarForumCurso(localStorage.getItem("codigo_curso"));
                                }}
                            />
                        </div>
                    );
                }}
                </GlobalContext.Consumer>
            </>
        );
    }
}

export default Forum;
