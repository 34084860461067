import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { notification, Form, Icon } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";
import LazyLoad from "react-lazy-load";
import Oops from "../../Geral/Oops.jsx";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Download extends Component {
    static contextType = GlobalContext;
    state = {
        entidade: "",
        curso: "",
        cursoId: "",
        aulaid: 0,
        nome: "",
        objetivos: "",
        ficheiro: "",
        ficheiro_nome: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        //LOADING
        loading: true,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            entidade: this.props.match.params.entidade,
            curso: this.props.match.params.curso,
            cursoId: this.props.match.params.cursoid,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-player/conteudo2",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    ficheiro: response.data.recurso,
                    ficheiro_nome: response.data.recurso_nome,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    downloadFicheiro = (documentoId) => {
        axios({
            method: "post",
            url: "/api/pagina-entidade-player/download-ficheiro",
            params: {
                documentoId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    render() {
        const {
            entidade,
            curso,
            cursoId,
            nome,
            objetivos,
            ficheiro,
            ficheiro_nome,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //LOADING
            loading,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
                <div className="questionario">
                    <div className="player-wrapper">
                        {/*<LazyLoad offsetVertical={200}>*/}
                        {/*    <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                        {/*</LazyLoad>*/}
                        <div className="container">
                            <div className="bloco-presencial">
                                <div className="bloco-download-link">
                                    <div className="bloco-info-tarefa">
                                        <span className="icon-tarefa">
                                            <img src={require("../../images/icons/download.png")} />
                                        </span>
                                        <span className="titulo-tarefa">{nome}</span>
                                    </div>
                                    <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                    <Link className="botao-principal" to="#" onClick={() => this.downloadFicheiro(this.state.aulaid)}>
                                        <Icon type="download" /> descarregar
                                            </Link>
                                </div>
                            </div>
                        </div>
                        <Controlos
                            backUrl={`/${entidade}/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${cursoId}/curso-modulos`}
                            sameUrl={sameUrl}
                        />
                    </div>
                </div>
            );
    }
}

export default Download;
