import React, { Component } from "react";
import { Form, Input, Button, notification, Icon } from "antd";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../../User/Header";
import { Link } from "react-router-dom";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class RecuperarPassword2 extends Component {
    state = {
        email: "",
        iconLoading: false
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    reset = (event) => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                axios({
                    method: "get",
                    url: "/api/autenticacaonew/recuperar-senha",
                    params: {
                        email: this.state.email,
                        url: localStorage.getItem("ultima_pagina")
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "E-mail de recuperação de senha enviado!");
                        this.setState({
                            redirect: true,
                            redirectLink: `/${this.props.match.params.entidade}/login-escola`,
                            iconLoading: false
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível enviar o e-mail de recuperação");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    render() {
        const { redirect, redirectLink, iconLoading } = this.state;
        const { getFieldDecorator } = this.props.form;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Recuperar Password" marginTop0={true} />
                <Form className="login template-form">
                    <div className="recuperar-password" style={{ paddingTop: 24 }}>
                        <Form.Item>
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        type: "email",
                                        message: "Introduza um endereço de e-mail válido"
                                    },
                                    { required: true, message: "Campo obrigatório" }
                                ]
                            })(<Input placeholder="E-mail" name="email" onChange={this.handleChange} />)}
                        </Form.Item>
                    </div>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button type="primary" className="login-form-button form-button" disabled={iconLoading} onClick={this.reset}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Recuperar
                        </Button>
                        <div className="bloco-login-links">
                            <Link to={`/${this.props.match.params.entidade}/login-escola`} className="form-link">
                                Voltar <i className="fas fa-chevron-right"></i>
                            </Link>
                        </div>
                    </Form.Item>
                </Form>
            </>
        );
    }
}
const FormRecuperarPassword2 = Form.create({ name: "recuperar-password2" })(RecuperarPassword2);

export default FormRecuperarPassword2;
