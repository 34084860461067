import React, { createContext, Component } from "react";
import moment from "moment";

export const GeralContext = createContext();

class ContextProvider extends Component {
    state = {
        //HEADER
        formacao: "",
        progress: 0,
        total_conteudos: 0,
        total_conteudos_realizados: 0,
        //retormar: "",
        //FORUM
        total_topicos: 0,
        //ANOTACOES
        total_anotacoes: 0,
        //INSCRICAO
        inscricao: false,
        inscrito: false,
        role: ""
    };

    atualizarHeader = (total_conteudos, total_conteudos_realizados) => {
        this.setState({
            formacao: total_conteudos_realizados > 0 ? total_conteudos_realizados + "/" + total_conteudos : "0/" + total_conteudos,
            progress: total_conteudos_realizados > 0 ? (total_conteudos_realizados / total_conteudos) * 100 : 0,
            total_conteudos,
            total_conteudos_realizados
        });
    };

    atualizarForum = total_topicos => {
        this.setState({
            total_topicos
        });
    };

    atualizarAnotacoes = total_anotacoes => {
        this.setState({
            total_anotacoes
        });
    };

    atualizarInscricao = inscrito => {
        this.setState({
            inscrito
        });
    };

    atualizar = state => {
        this.setState(state);
    };

    formatarPeriodo = periodo => {
        var um_hora = moment()
            .startOf("day")
            .seconds(60 * 60 * 1000);
        var format = moment()
            .startOf("day")
            .seconds(periodo);

        if (format > um_hora)
            return moment()
                .startOf("day")
                .seconds(periodo)
                .format("HH:mm:ss");
        else
            return moment()
                .startOf("day")
                .seconds(periodo)
                .format("mm:ss");
    };

    //RetomarAula = cursoid => {
    //    axios({
    //        method: "get",
    //        url: "/api/curso/retomar-aula",
    //        params: {
    //            cursoid: cursoid
    //        }
    //    })
    //        .then(response => {
    //            if (response.data)
    //                this.setState({
    //                    retomar: response.data
    //                });
    //        })
    //        .catch(error => {
    //         //   this.log("Tentativa de carregar o retomar", "/api/curso/retomar-aula");
    //        });
    //};

    render() {
        return (
            <GeralContext.Provider
                value={{
                    ...this.state,
                    atualizarHeader: this.atualizarHeader,
                    atualizarForum: this.atualizarForum,
                    atualizarAnotacoes: this.atualizarAnotacoes,
                    atualizarInscricao: this.atualizarInscricao,
                    atualizar: this.atualizar,
                    formatarPeriodo: this.formatarPeriodo,
                    //RetomarAula: this.RetomarAula
                }}>
                {this.props.children}
            </GeralContext.Provider>
        );
    }
}
export default ContextProvider;
