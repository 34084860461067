import React, { Component } from "react";
import { Link, Redirect, matchPath, withRouter } from "react-router-dom";
import { Rate, notification, Icon, Skeleton, Modal } from "antd";
import { GeralContext } from "./ContextProvider";
import { GlobalContext } from "../GlobalState";
import noimage from "../images/noimage.png";
import Avatar2 from "../images/Avatar2.png";
import axios from "axios";
import moment from "moment";
import LazyLoad from "react-lazy-load";
import Pontos from "./Pontos";
import AdicionarCarrinho from "./_Aux/AdicionarCarrinho";
import AdicionarSubscricao from "./_Aux/AdicionarSubscricao";
import ModalWelcome from "./_Aux/ModalWelcome";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Header extends Component {
    static contextType = GeralContext;
    state = {
        //DADOS
        cursoId: 0,
        imagem: "",
        codigo: "",
        nome: "",
        edicao: "",
        ano: "",
        turma: "",
        nivel: "",
        turno: "",
        periodo: "",
        objectivo: "",
        avaliacao: "",
        totalAvaliacao: "",
        coordenador: "",
        dt_curso: "",
        linguas: [],
        linguasLegendas: [],
        formacao: "",
        progress: 0,
        classificacao: "",
        concluido: "",
        inscricao: false,
        aula: "",
        retomar: "",
        fechado: false,
        escola: "",
        favorito: false,
        precedencia: 0,
        precedenciaNome: "",
        precedenciaConcluida: false,
        obrigatoria: false,
        habilitar_gamificacao: false,
        //ESTADOS
        inscrito: false,
        preco: "",
        //DRAWER
        visible: false,
        loading: true,
        //INSCRICAO
        inscrito: false,
        pagamentoPendente: false,
        tipologia: "",
        subscricao: false,
        subscricaoAtiva: false,
        loaded: false,
        //WELCOME
        visibleWelcome: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoId
        });
        this.carregarInfo(cursoId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.turma !== prevProps.match.params.turma) {
            var cursoId = localStorage.getItem("codigo_curso");

            this.setState({
                cursoId
            });
            this.carregarInfo(cursoId);
        }
    }

    //CARREGA A INFORMAÇÃO DO CURSO
    carregarInfo = cursoid => {
        axios({
            method: "get",
            url: "/api/turma/carregar-header",
            params: {
                cursoid: cursoid
            }
        })
            .then(response => {
                this.context.atualizar({
                    fechado: response.data.fechado,
                    inscrito: response.data.inscrito,
                    tipo_inscricao: response.data.tipo_inscricao,
                    inscricao: response.data.inscricao,
                    subcursos: response.data.subcursos,
                    preco: response.data.preco,
                    subscricao: response.data.subscricao,
                    subscricaoAtiva: response.data.subscricaoAtiva,
                    precedenciaPorConcluir: response.data.precedencia && response.data.obrigatoria && !response.data.precedenciaConcluida,
                    habilitar_gamificacao: response.data.habilitar_gamificacao,
                    esconderListaParticipantes: response.data.esconderListaParticipantes,
                    cursoIniciado: response.data.cursoIniciado,
                    diarioCurso: response.data.diario,
                    temFicha: response.data.temFicha
                });

                this.setState({
                    codigo: response.data.codigo,
                    nome: response.data.nome,
                    edicao: response.data.edicao,
                    ano: response.data.ano,
                    turma: response.data.turma,
                    nivel: response.data.nivel,
                    turno: response.data.turno,
                    periodo: response.data.periodo ? JSON.parse(response.data.periodo).toString() : "",
                    disciplina: response.data.subcurso,
                    fechado: response.data.fechado,
                    escola: response.data.escola,
                    loading: false
                });

                localStorage.setItem("curso_fechado", response.data.fechado);
                localStorage.setItem("habilitar_competencias", response.data.habilitar_competencias);
                localStorage.setItem("habilitar_gamificacao", response.data.habilitar_gamificacao);
                localStorage.setItem("nome_curso", response.data.nome);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA SE O CURSO É OU NÃO FAVORITO
    favorito = cursoid => {
        axios({
            method: "get",
            url: "/api/favoritos/favorito",
            params: {
                cursoid
            }
        })
            .then(response => {
                this.setState({
                    favorito: response.data
                });
            })
            .catch(() => {});
    };

    //MARCA O CURSO COMO FAVORITO
    marcarFavorito = () => {
        axios({
            method: "post",
            url: "/api/favoritos/marcar-favorito",
            params: {
                cursoid: this.state.cursoId
            }
        })
            .then(() => {
                this.setState({
                    favorito: !this.state.favorito
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível marcar este curso como favorito!");
            });
    };

    retomarBotao = tem_comandos => {
        if (this.context.inscricao && !this.context.inscrito)
            return (
                <Link to="#" className="botao-principal" onClick={this.openDrawerInscricao}>
                    Inscrever
                </Link>
            );
    };

    openDrawerInscricao = () => {
        this.setState({
            visible: true
        });
    };

    onClose = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        const {
            //DADOS
            cursoId,
            codigo,
            imagem,
            nome,
            edicao,
            ano,
            turma,
            nivel,
            turno,
            periodo,
            disciplina,
            objectivo,
            avaliacao,
            totalAvaliacao,
            fechado,
            escola,
            precedencia,
            precedenciaNome,
            habilitar_gamificacao,
            obrigatoria,
            //ESTADOS
            inscrito,
            pagamentoPendente,
            tipologia,
            subscricao,
            subscricaoAtiva,
            preco,
            //REDIRECT
            redirect,
            redirectLink,
            loaded,
            //WELCOME
            visibleWelcome
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const montarEstado = () => {
            if (fechado)
                return (
                    <div className="bloco-fechado">
                        <span className="fechado">fechado</span>
                    </div>
                );
            else if (precedencia && !obrigatoria)
                return (
                    <div className="bloco-precedencia" title="Precedência sugerida">
                        <span className="precedencia">
                            <Icon className="precedencia-sugerida" type="info-circle" /> {precedenciaNome}
                        </span>
                    </div>
                );
            else if (precedencia && obrigatoria)
                return (
                    <div className="bloco-precedencia" title="Precedência obrigatória">
                        <span className="precedencia">
                            <Icon className="precedencia-obrigatoria" type="exclamation-circle" /> {precedenciaNome}
                        </span>
                    </div>
                );
            else return null;
        };

        return (
            <>
                <GlobalContext.Consumer>
                    {GlobalContext => {
                        return (
                            <div className="header-cursos" style={{ height: "auto" }}>
                                {/*<LazyLoad offsetVertical={335}>*/}
                                {/*    <img src={require("../images/wave_hero_user.svg")} className="img-hero" />*/}
                                {/*</LazyLoad>*/}
                                <div className="container">
                                    <Skeleton active={this.state.loading} avatar loading={this.state.loading}>
                                        <div className="lado-esquerdo">
                                            <div className="titulo-favorito">
                                                <div className="titulo-curso">
                                                    <h2 title={nome}>
                                                        {codigo} - {nome}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="bloco-rating-info bloco-rating-info-desktop">
                                                <div className="bloco-esquerda info-items">
                                                    {fechado && (
                                                        <span
                                                            className="info-item tag-fechado"
                                                            title="Uma vez que curso está fechado, não é possível realizar avaliações, questionários e trabalhos"
                                                        >
                                                            <Icon className="alerta" type="warning" />
                                                            Fechado
                                                        </span>
                                                    )}
                                                    {disciplina && (
                                                        <span className="info-item">
                                                            {ano ? ano : ""} {turma ? turma : ""}
                                                        </span>
                                                    )}
                                                    <span className="info-item">{nivel}</span>
                                                    <span className="info-item">{turno}</span>
                                                    <span className="info-item">{periodo}</span>
                                                    <span className="info-item">{edicao}</span>
                                                    {escola && GlobalContext.escolas_entidade && (
                                                        <span className="info-item">{escola}</span>
                                                    )}
                                                    {habilitar_gamificacao && GlobalContext.gamificacao_entidade && inscrito && (
                                                        <span className="info-item">
                                                            <Pontos cursoId={this.state.cursoId} />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="bloco-rating-info bloco-rating-info-mobile">
                                                <div className="bloco-esquerda info-items">
                                                    {fechado && (
                                                        <span
                                                            className="info-item"
                                                            title="Uma vez que curso está fechado, não é possível realizar avaliações, questionários e trabalhos"
                                                        >
                                                            <Icon className="alerta" type="warning" />
                                                            Fechado
                                                        </span>
                                                    )}
                                                    <span className="info-item">{edicao}</span>
                                                    <span className="info-item">
                                                        {ano ? ano : ""} {turma ? turma : ""}
                                                    </span>
                                                    <span className="info-item">{turno}</span>
                                                    {escola && GlobalContext.escolas_entidade && (
                                                        <span className="info-item">{escola}</span>
                                                    )}
                                                    {habilitar_gamificacao && GlobalContext.gamificacao_entidade && inscrito && (
                                                        <span className="info-item">
                                                            <Pontos cursoId={this.state.cursoId} />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Skeleton>
                                </div>
                            </div>
                        );
                    }}
                </GlobalContext.Consumer>
                <ModalWelcome
                    visibleWelcome={visibleWelcome}
                    imagem={imagem ? imagem : noimage}
                    nome={nome}
                    onCancel={() => this.setState({ visibleWelcome: false })}
                />
            </>
        );
    }
}

export default withRouter(Header);
