import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, InputNumber, notification, Icon, Drawer, Menu, Modal, Dropdown, Select, Table } from "antd";
import axios from "axios";
import UploadImagem from "../Unidade/UploadImagem";
import { GlobalContext } from "../../../GlobalState";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerSala extends Component {
    static contextType = GlobalContext;
    state = {
        escolas: [],
        escolaId: 0,
        edificios: [],
        edificioId: 0,
        foto: "",
        nome: "",
        descricao: "",
        lotacao: 1,
        //LOADING
        iconLoading: false
    };

    componentDidMount() {
        this.listarEscolas();
    }

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeLotacao = lotacao => {
        this.setState({ lotacao });
    };

    handleChangeSelectEscolas = escolaId => {
        this.props.form.resetFields();
        this.setState({
            escolaId,
            edificios: [],
            edificioId: undefined
        });
        this.listarEdificios(escolaId);
    };

    handleChangeSelectEdificios = edificioId => {
        this.setState({
            edificioId
        });
    };

    listarEscolas = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos-drawer"
        })
            .then(response => {
                this.setState({
                    escolas: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarEdificios = escolaId => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-edificios-filtro",
            params: {
                escolaId
            }
        })
            .then(response => {
                this.setState({
                    edificios: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    adicionar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_edificio", this.state.edificioId);
                item.append("foto", this.state.foto);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);
                item.append("lotacao", this.state.lotacao);

                axios({
                    method: "post",
                    url: "/api/gestao-organismos/adicionar-sala",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        this.props.atualizar();
                        this.setState({
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.salaId);
                item.append("foto", this.state.foto);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);
                item.append("lotacao", this.state.lotacao);

                axios({
                    method: "put",
                    url: "/api/gestao-organismos/alterar-sala",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        this.props.atualizar();
                        this.setState({
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregar = salaId => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/carregar-sala",
            params: {
                salaId
            }
        })
            .then(response => {
                this.setState(
                    {
                        escolaId: response.data.id_escola,
                        edificioId: response.data.id_edificio,
                        foto: response.data.foto,
                        nome: response.data.nome,
                        descricao: response.data.descricao ? response.data.descricao : "",
                        lotacao: response.data.lotacao
                    },
                    () => {
                        this.listarEdificios(this.state.escolaId);
                    }
                );
            })
            .catch(() => {});
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.salaId > 0) this.carregar(this.props.salaId);
            else
                this.setState({
                    escolaId: undefined,
                    edificioId: undefined,
                    edificios: [],
                    foto: "",
                    nome: "",
                    descricao: "",
                    lotacao: 1
                });
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            escolas,
            escolaId,
            edificios,
            edificioId,
            foto,
            nome,
            descricao,
            lotacao,
            //LOADING
            iconLoading
        } = this.state;

        const { ativo, salaId, visible } = this.props;

        return (
            <Drawer
                className="drawer-add-cursos drawer-videoconferencias"
                //title={salaId > 0 ? "Alterar Sala" : "Adicionar Sala"}
                width={720}
                onClose={this.props.onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        {/*<UploadImagem*/}
                        {/*    ativo={ativo}*/}
                        {/*    titulo="Foto"*/}
                        {/*    idName="input-foto"*/}
                        {/*    limite={1000}*/}
                        {/*    size="400x400px"*/}
                        {/*    imagem={foto}*/}
                        {/*    atualizarImagem={foto => this.setState({ foto })}*/}
                        {/*/>*/}
                        <Form.Item label="Estabelecimento">
                            {getFieldDecorator("escola", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: escolaId
                            })(
                                <Select
                                    placeholder="Selecionar"
                                    allowClear={true}
                                    disabled={!ativo}
                                    onChange={this.handleChangeSelectEscolas}
                                >
                                    {escolas.map(escola => (
                                        <Option key={escola.id} value={escola.id}>
                                            {escola.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Edifício">
                            {getFieldDecorator("edificio", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: edificioId
                            })(
                                <Select
                                    placeholder="Selecionar"
                                    disabled={!edificios.length || !ativo}
                                    allowClear={true}
                                    onChange={this.handleChangeSelectEdificios}
                                >
                                    {edificios.map(edificio => (
                                        <Option key={edificio.id} value={edificio.id}>
                                            {edificio.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Sala">
                            {getFieldDecorator("nome", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: nome
                            })(<Input name="nome" disabled={!ativo} onChange={this.handlerChange} />)}
                        </Form.Item>
                        <Form.Item label={<span>Descrição</span>}>
                            {getFieldDecorator("descricao", {
                                initialValue: descricao
                            })(
                                <TextArea
                                    name="descricao"
                                    disabled={!ativo}
                                    onChange={this.handlerChange}
                                    rows={10}
                                    style={{ height: 100 }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Lotação">
                            {getFieldDecorator("lotacao", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: lotacao
                            })(<InputNumber name="lotacao" disabled={!ativo} min={1} onChange={this.handlerChangeLotacao} />)}
                        </Form.Item>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <Link
                        to="#"
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </Link>
                    {ativo && (
                        <button className="botao-principal" disabled={iconLoading} onClick={salaId > 0 ? this.alterar : this.adicionar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>
                    )}
                </div>
            </Drawer>
        );
    }
}

const FormDrawerSala = Form.create({ name: "form-drawer-sala" })(DrawerSala);

export default FormDrawerSala;
