import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Icon } from 'antd';
import axios from "axios";
import moment from "moment";

export default function Detalhe({ match }) {
    const [data, setData] = useState([]);
    const [dias, setDias] = useState([]);

    const carregarDetalhe = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/evento-detalhe",
            params: {
                code: match.params.detalhe
            }
        })
            .then(response => {
                setData(response.data);
            })
            .catch(() => {});
    };

    const carregarOradores = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/evento-detalhe-oradores",
            params: {
                code: match.params.detalhe
            }
        })
            .then(response => {
                setDias(response.data);
            })
            .catch(() => { });
    }

    useEffect(() => {
        carregarDetalhe();
        carregarOradores();
    }, [match.params.detalhe]);

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <a href="http://www.alipay.com/">1st menu item</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a href="http://www.taobao.com/">2nd menu item</a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">3rd menu item</Menu.Item>
        </Menu>
    );

    return (
        <>
            <div className="panel-body bloco-noticia-detalhe">
                <div className="left-panel" style={{ width: "100%" }}>
                    <h2 className="panel-h2">{data.titulo}</h2>
                    <div className="corpo-noticia">
                        {data.data && (
                            <p className="texto texto-info info-publicacao">
                                {moment(data.data).format("DD [de] MMMM [de] YYYY")}
                            </p>)}
                        {data.capa && (
                            <img className="panel-img" src={data.capa} alt="Image" />)}
                        <p
                            className="texto texto-noticia"
                            dangerouslySetInnerHTML={{ __html: data.texto }}
                            style={{ fontSize: 22 }}
                        >
                        </p>
                        {data.link && (
                            <div className="texto texto-info link">
                                <a href={data.link} target="_blank" rel="noopener noreferrer" className="link">
                                    <i className="fa fa-link"></i> Inscrição
                                </a>
                            </div>)}
                        {data.codigo && (
                            <div className="texto texto-info link">
                                <a href={`/${match.params.entidade}/inscricao-evento/${data.codigo}`} target="_blank" rel="noopener noreferrer" className="link">
                                    <i className="fa fa-link"></i> Inscrição
                                </a>
                            </div>)}
                    </div>
                    <div className="bloco-partes">
                        <h1>Programação do evento</h1>
                        <div>
                            {dias.map((dia, index) => (
                                <div key={`dia${index}`} className="bloco-dia">
                                    <span className="lista-data">{moment(dia.data).format("DD MMM, YYYY")}</span>
                                    <ul className="lista-partes">
                                        {dia.registos.map((registo, index) => (
                                            <li key={`registo${index}`}>
                                                <div className="item-parte">
                                                    <span className="parte-hora">{registo.hr_inicio} - {registo.hr_termino}</span>
                                                    <h3 className="parte-titulo">{registo.nome}</h3>
                                                    <p className="parte-tema">{registo.descricao}</p>
                                                    <span className="parte-oradores">{(JSON.parse(registo.oradores).map(x => x.nome)).join(', ')}</span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
