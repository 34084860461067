import React, { Component } from "react";
import { Icon } from "antd";

class CardsNotasRegular extends Component {
    render() {
        const {
            grupos,
            periodos,
            avaliacoes
        } = this.props;

        const montarParametros = (grupo, grupoIndex) => {
            const parametros = grupo.avaliacoes.map((avaliacao, index) => {
                return <div className="ponto">
                    <span className="ponto-titulo">{avaliacao.titulo}</span>
                    <div className="lista-notas">
                        {periodos.map((periodo, periodoIndex) => {
                            const _grupo = periodo.grupos[grupoIndex];

                            let nota = "";
                            if (_grupo !== null) {
                                const _avaliacao = _grupo.avaliacoes[index];
                                if (_avaliacao !== null)
                                    nota = _avaliacao.nota;
                            }

                            if (nota && avaliacoes.length === 1)
                                nota = avaliacoes[0].nome;

                            return <div className="nota">
                                <span className="titulo">{periodo.titulo}:</span>
                                <span className="texto">{nota ? nota : "-"}</span>
                            </div>
                        })}
                    </div>
                </div>
            });

            return parametros;
        }

        return (
            <div>
                <div className="bloco-cards">
                    {grupos.map((grupo, index) => (
                        <div className="card">
                            <div className="header">
                                <span className="titulo">{grupo.titulo}</span>
                            </div>
                            <div className="body">
                                <div className="lista-pontos">
                                    {montarParametros(grupo, index)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default CardsNotasRegular;