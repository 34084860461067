import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import pt_PT from "antd/es/locale-provider/pt_PT";
import GlobalState from "./GlobalState";

import App from "./App";
import ScrollToTop from "./ScrollToTop";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop>
            <ConfigProvider locale={pt_PT}>
                <GlobalState>
                    <App />
                </GlobalState>
            </ConfigProvider>
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById("root")
);

serviceWorker.unregister();
