import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Header from "./Header";
import Resultados from "./Resultados/Main";
import ResultadosGeral from "./ResultadosGeral/Main";
import Ranking from "./Resultados/Ranking";
import RankingGeral from "./ResultadosGeral/Ranking";
import { GlobalContext } from "../GlobalState";
import ContextProvider from "./ContextProvider";

class CursoLayout extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    componentDidUpdate(prevProps) {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });

        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.location.pathname.split("/").pop() !== "curso-modulos") localStorage.setItem("modulo_aberto", "0");
        }
    }

    render() {
        const {
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <ContextProvider>
                    <Header/>
                    <Switch>
                        <Route path="/consultar/:curso/curso-resultados/:utilizadorid" component={Resultados} />
                        <Route path="/consultar/:curso/curso-resultados-geral/:utilizadorid" component={ResultadosGeral} />
                        <Route path="/consultar/:curso/curso-ranking/:utilizadorid" component={Ranking} />
                        <Route path="/consultar/:curso/curso-ranking-geral/:utilizadorid" component={RankingGeral} />
                    </Switch>
                </ContextProvider>
            </>
        );
    }
}

export default CursoLayout;
