import React, { useState, useEffect, useContext } from "react";
import { Drawer, Form, Card, Icon, notification, Upload, Button, Tooltip } from "antd";
import noimage from "../../../../images/noimage.png";
import axios from "axios";
import { GlobalContext } from "../../../../GlobalState";
import DrawerListaCursos from "../DrawerListaCursos/DrawerListaCursos";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerImportar = ({ visible, onClose, onConfirm }) => {
    const [cursos, setCursos] = useState([]);
    const [ficheiro, setFicheiro] = useState([]);
    const [utilizadores, setUtilizadores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inscreverLoading, setInscreverLoading] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const context = useContext(GlobalContext);

    const listarCursos = () => {
        axios({
            method: "get",
            url: "/api/gestao-curso-inscricoes/listar-subcursos",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setCursos(response.data);
            })
            .catch(() => { });
    }

    const inscrever = event => {
        event.preventDefault();
        setInscreverLoading(true);

        var item = new FormData();
        item.append("cursoId", localStorage.getItem("codigo_curso"));
        item.append("lista", JSON.stringify(utilizadores));

        axios({
            method: "post",
            url: "/api/gestao-curso-inscricoes/inscrever-utilizador-csv",
            data: item
            //params: {
            //    cursoId: localStorage.getItem("codigo_curso"),
            //    lista: JSON.stringify(utilizadores)
            //}
        })
            .then(response => {
                if (utilizadores.length > 1)
                    openNotificationWithIcon("success", "Sucesso", "Alunos inscritos ao curso");
                else
                    openNotificationWithIcon("success", "Sucesso", "Aluno inscrito ao curso");

                setFicheiro([]);
                setUtilizadores([]);
                setInscreverLoading(false);
                onConfirm();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                setInscreverLoading(false);
            });
    };

    const afterVisibleChange = visible => {
        if (!visible) {
            setFicheiro([]);
            setUtilizadores([]);
        }
    }

    const props = {
        multiple: false,
        onChange: info => {
            setLoading(true);
            var item = new FormData();
            item.append("cursoId", localStorage.getItem("codigo_curso"));
            item.append("ficheiro", info.file);
            if (localStorage.getItem("escola_id"))
                item.append("organismoId", localStorage.getItem("escola_id"));

            axios({
                method: "post",
                url: localStorage.getItem("escola_id") ? "/api/gestao-curso-inscricoes/adicionar-csv-organismos" : "/api/gestao-curso-inscricoes/adicionar-csv",
                data: item
            })
                .then(response => {
                    setUtilizadores(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar ficheiro");
                    setLoading(false);
                });
        },
        beforeUpload: file => {
            return false;
        },
        ficheiro
    };

    const montarEstado = (inscricoes) => {
        if (inscricoes.length === 0)
            return <span className="estado-nao-inscrito">Não inscrito</span>;
        else if (inscricoes.length < cursos.length)
            return <span className="estado-inscrito-parcial">Inscrito parcial</span>;
        else
            return <span className="estado-inscrito">Inscrito em todos</span>;
    }

    const confirmarDrawer = () => {
        setDrawerVisible(false);
        onConfirm();
    }

    useEffect(() => {
        listarCursos();
    }, [])

    return (
        <>
            <Drawer
                className="drawer-inscrever"
                //title={
                //    <>
                //        Importar inscrições{" "}
                //        <Tooltip className="info-icon" title="Importação com emails de alunos">
                //            <Icon type="question-circle-o" />
                //        </Tooltip>
                //    </>
                //}
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <Upload {...props} fileList={ficheiro}>
                        <Button disabled={ficheiro.length > 0}>
                            <i className="fas fa-upload" style={{ marginRight: "10px" }} /> Carregar ficheiro
                        </Button>
                    </Upload>
                    <div className="listagem" style={{ marginTop: 30 }}>
                        {loading ?
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                            :
                            utilizadores.map((utilizador, index) => (
                                <Card key={index} >
                                    <div className="bloco-info-card-head">
                                        <div className="container-img">
                                            <img src={utilizador.foto ? utilizador.foto : noimage} alt="Foto" />
                                        </div>
                                        <span className="nome">
                                            {utilizador.nome_completo}
                                        </span>
                                    </div>
                                    <p><strong>Número:</strong> {utilizador.nr_aluno}</p>
                                    <p><strong>Email:</strong> {utilizador.email}</p>
                                    {utilizador.contacto &&
                                        <p><strong>Contacto:</strong> {utilizador.contacto}</p>}
                                    {(utilizador.organismo && context.escolas_entidade) &&
                                        <p><strong>Organismo:</strong> {utilizador.organismo}</p>}
                                    <p><strong>Estado:</strong> {montarEstado(utilizador.inscricoes)}</p>
                                </Card>
                            ))}
                    </div>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" type="primary" disabled={inscreverLoading || utilizadores.length === 0} onClick={inscrever} style={{ marginRight: 20 }}>
                        {inscreverLoading ? <Icon type="loading" /> : null}
                        Inscrever em todos
                    </button>
                    <button className="botao-principal" type="primary" disabled={utilizadores.length === 0} onClick={() => setDrawerVisible(true)}>
                        Selecionar cursos
                    </button>
                </div>
            </Drawer>
            <DrawerListaCursos
                visible={drawerVisible}
                cursos={cursos}
                utilizadores={utilizadores}
                onClose={() => setDrawerVisible(false)}
                onConfirm={confirmarDrawer}
                importacao
            />
        </>
    );
}

const FormDrawerImportar = Form.create({ name: "drawer-importar" })(DrawerImportar);

export default FormDrawerImportar;
