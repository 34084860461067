import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";

import noimage from "../../images/noimage.png";

const Info = ({ sem_resposta, assunto, autor, estado, estado_cor, responder, remover, fechar, reabrir }) => {
    const context = useContext(GlobalContext);

    let link = "/administracao-suporte";

    if (context?.role == "ENTIDADE") link = "/gestao-suporte";

    if (context?.role !== "ADMIN" && context?.role !== "ENTIDADE") link = "/user-suporte";

    const useWindowWidth = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            // Function to update windowWidth state with current innerWidth
            const handleWindowResize = () => {
                setWindowWidth(window.innerWidth);
            };

            // Event listener for window resize
            window.addEventListener("resize", handleWindowResize);

            // Cleanup: remove event listener on unmount
            return () => {
                window.removeEventListener("resize", handleWindowResize);
            };
        }, []);

        return windowWidth;
    };

    const windowWidth = useWindowWidth();

    return (
        <>
            {windowWidth > 1025 ? (
                <div className="info-topo">
                    <div className="info-principal">
                        <span className="assunto">{assunto}</span>
                        <div className="info-autor">
                            <div className="container-img">
                                <img src={autor.foto ? autor.foto : noimage} />
                            </div>
                            <div className="dados">
                                <div className="nome">
                                    {autor.nome_completo}
                                    <span className="codigo">{autor.numero}</span>
                                </div>
                                <div>
                                    <span className={"estado " + estado_cor}>{estado}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="info-controls">
                            {!sem_resposta && estado !== "Fechado" && estado !== "Removido" && (
                                <button className="botao-principal" onClick={responder} style={{ padding: "0 20px", height: "35px" }}>
                                    Responder
                                </button>
                            )}
                            {(estado === "Fechado" || estado === "Removido") && (
                                <button className="botao-principal" style={{ padding: "0 20px", height: "35px" }} onClick={reabrir}>
                                    Reabrir
                                </button>
                            )}
                            {estado !== "Removido" && (
                                <button className="botao-excluir" onClick={() => remover()} style={{ padding: "0 20px", height: "35px" }}>
                                    Remover
                                </button>
                            )}
                            {estado !== "Fechado" ? (
                                <button className="botao-secundario" onClick={fechar} style={{ padding: "0 20px", height: "35px" }}>
                                    Fechar
                                </button>
                            ) : null}
                            <Link
                                to={link}
                                className="link-principal-border"
                                style={{ fontSize: 16, display: "flex", alignItems: "center", padding: "0 20px" }}
                                onClick={() => localStorage.setItem("voltou", true)}
                            >
                                Voltar
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="info-topo">
                        <div className="info-principal">
                            <span className="assunto">{assunto}</span>
                            <div className="info-autor">
                                <div className="container-img">
                                    <img src={autor.foto ? autor.foto : noimage} />
                                </div>
                                <div className="dados">
                                    <div className="nome">
                                        {autor.nome_completo}
                                        <span className="codigo">{autor.numero}</span>
                                    </div>
                                    <div>
                                        <span className={"estado " + estado_cor}>{estado}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="info-controls" style={{ padding: "7px 5px auto auto" }}>
                            {!sem_resposta && estado !== "Fechado" && estado !== "Removido" && (
                                <button
                                    className="botao-principal"
                                    onClick={responder}
                                    style={{ padding: "0 10px", height: "35px", margin: "0px 4px" }}
                                >
                                    Responder
                                </button>
                            )}
                            {(estado === "Fechado" || estado === "Removido") && (
                                <button
                                    className="botao-principal"
                                    style={{ padding: "0 10px", height: "35px", margin: "0px 4px" }}
                                    onClick={reabrir}
                                >
                                    Reabrir
                                </button>
                            )}
                            {estado !== "Removido" && (
                                <button
                                    className="botao-excluir"
                                    onClick={() => remover()}
                                    style={{ padding: "0 10px", height: "35px", margin: "0px 4px" }}
                                >
                                    Remover
                                </button>
                            )}
                            {estado !== "Fechado" ? (
                                <button
                                    className="botao-secundario"
                                    onClick={fechar}
                                    style={{ padding: "0 10px", height: "35px", margin: "0px 4px" }}
                                >
                                    Fechar
                                </button>
                            ) : null}
                            <Link
                                to={link}
                                className="link-principal-border"
                                style={{ padding: "0 10px", height: "35px", margin: "0px 4px" }}
                                onClick={() => localStorage.setItem("voltou", true)}
                            >
                                Voltar
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Info;
