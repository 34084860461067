import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Icon, Modal, Form, Select } from "antd";

const ModalEmitirDeclaracao = ({ visibleEmitirDeclaracao, onCancel, form }) => {
    const [declaracao, setDeclaracao] = useState(undefined)
    const [redirect, setRedirect] = useState(false)
    const [redirectLink, setRedirectLink] = useState("")
    const { Option } = Select;

    if (redirect) return <Redirect to={redirectLink} />;

    const confirmar = () => {
        setRedirectLink(`/gestao-utilizadores-todos/declaracao/${declaracao}`);
        setRedirect(true)
    }

    return (
        <Modal
            visible={visibleEmitirDeclaracao}
            okText="Confirmar"
            onOk={confirmar}
            onCancel={onCancel}
            cancelText="Cancelar"
            okButtonProps={{ disabled: !declaracao }}
            maskClosable={false}
            className=""
        >
            <div className="bloco-alerta">
                <span className="icon-alerta">
                    <Icon type="info-circle" />
                </span>
                <span className="mensagem-alerta">Selecione abaixo qual a declaração que pretende emitir.</span>
            </div>
            <div>
                <Form className="form-emitir-declaracao">
                    <Form.Item label="Declaração">
                        {localStorage.getItem("entidade_id") === "3" ? //MARAVILHA
                            <Select showSearch placeholder="Selecionar" allowClear={true} onChange={value => setDeclaracao(value)}>
                                <Option value="1">Declaração de escolaridade</Option>
                                <Option value="2">Declaração de escolaridade - EJA</Option>
                                <Option value="3">Declaração de transferência - EJA</Option>
                            </Select>
                            : null}
                        {localStorage.getItem("entidade_id") === "28" ? //BELO MONTE
                            <Select showSearch placeholder="Selecionar" allowClear={true} onChange={value => setDeclaracao(value)}>
                                <Option value="4">Declaração de conclusão</Option>
                                <Option value="5">Declaração de escolaridade</Option>
                                <Option value="6">Declaração de transferência</Option>
                            </Select>
                            : null}
                        {localStorage.getItem("entidade_id") === "37" ? // OURO BRANCO
                            <Select showSearch placeholder="Selecionar" allowClear={true} onChange={value => setDeclaracao(value)}>
                                <Option value="7">Declaração de escolaridade</Option>
                                <Option value="8">Declaração de matrícula</Option>
                            </Select>
                            : null}
                        {localStorage.getItem("entidade_id") !== "3" && localStorage.getItem("entidade_id") !== "28" && localStorage.getItem("entidade_id") !== "37" ?
                            <Select showSearch placeholder="Selecionar" allowClear={true} onChange={value => setDeclaracao(value)}>
                                
                            </Select>
                            : null}
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

const FormModalEmitirDeclaracao = Form.create({ name: "modal-emitir-declaracao" })(ModalEmitirDeclaracao);

export default FormModalEmitirDeclaracao;
