import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Icon } from 'antd';
import ReactDragListView from "react-drag-listview";
import moment from 'moment';
import axios from "axios";
import Controlos from "./Controlos/Controlos";

const Relatorio = ({ menuOptionSelected }) => {
    const [filterSchool, setFilterSchool] = useState(undefined);
    const [filterCourse, setFilterCourse] = useState(undefined);
    const [filterSubcourse, setFilterSubcourse] = useState(undefined);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(1);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);

    const loadSchoolsList = schools => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/relatorios/lista-comparativo-escolas",
            params: {
                selectedSchools: JSON.stringify(schools)
            }
        })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                setLoading(false);
            });
    }

    const loadCoursesList = courses => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-comparativo-cursos",
            params: {
                selectedCourses: JSON.stringify(courses)
            }
        })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                setLoading(false);
            });
    }

    const addData = () => {
        if (selectedOption === 1) {
            if (!selectedSchools.find(x => x === filterSchool)) {
                setSelectedSchools([...selectedSchools, filterSchool]);
                loadSchoolsList([...selectedSchools, filterSchool]);
                setFilterSchool(undefined);
            }
        }
        else {
            if (filterSubcourse) {
                if (!selectedCourses.find(x => x === filterSubcourse)) {
                    setSelectedCourses([...selectedCourses, filterSubcourse]);
                    loadCoursesList([...selectedCourses, filterSubcourse]);
                    setFilterSubcourse(undefined);
                }
            }
            else {
                if (!selectedCourses.find(x => x === filterCourse)) {
                    setSelectedCourses([...selectedCourses, filterCourse]);
                    loadCoursesList([...selectedCourses, filterCourse]);
                    setFilterCourse(undefined);
                }
            }
        }
    }

    const deleteData = (id) => {
        setData(data.filter(x => x.id !== id));
        if (selectedOption === 1)
            setSelectedSchools(selectedSchools.filter(x => x !== id));
        else
            setSelectedCourses(selectedCourses.filter(x => x !== id));
    }

    useEffect(() => {
        setFilterSchool(undefined);
        setFilterCourse(undefined);
        setData([]);

    }, [menuOptionSelected]);

    useEffect(() => {
        setFilterSchool(undefined);
        setFilterCourse(undefined);
        setSelectedSchools([]);
        setSelectedSchools([]);
        setData([]);

    }, [selectedOption]);

    //useEffect(() => {
    //    loadSchoolsList();

    //}, [selectedSchools]);

    //useEffect(() => {
    //    loadCoursesList();

    //}, [selectedCourses]);

    return (
        <>
            <div className="report-info">
                <Controlos
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    filterSchool={filterSchool}
                    setFilterSchool={(filter) => setFilterSchool(filter)}
                    filterCourse={filterCourse}
                    setFilterCourse={(filter) => setFilterCourse(filter)}
                    filterSubcourse={filterSubcourse}
                    setFilterSubcourse={(filter) => setFilterSubcourse(filter)}
                    menuOptionSelected={menuOptionSelected}
                    addData={addData}
                    //disabledAddButton={filterSchool !== "" && filterCourse !== "" && filterSubcourse !== ""}
                    disabledAddButton={false}
                    disabledButtons={data.length === 0}
                    data={data}
                    pageTitle={menuOptionSelected?.menuItemName}
                />
                <div className="page-container">
                    <div className="page-header">
                        <h1 className="page-title">Relatório - Frequência {menuOptionSelected?.menuItemName}</h1>
                    </div>
                    <div className="page-body page-body-transparent">
                        <div className="blocks">
                            {data.map((item, index) => (
                                <div key={index} className="block">
                                    <div className="block-header">
                                        <div className="block-titles">
                                            {item.uppername &&
                                                <span>{item.uppername}</span>}
                                            <span className="name">{item.name}</span>
                                            {item.subname &&
                                                <span className="subname">{item.subname}</span>}
                                        </div>
                                        <button className="btn-delete" onClick={() => deleteData(item.id)}>
                                            <Icon type="delete" />
                                        </button>
                                    </div>
                                    <div className={`percentage ${item.average > 75 ? "percentage-above" : "percentage-below"}`}>
                                        {item.average.toFixed(2)}%
                                    </div>
                                </div>
                             ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Relatorio;