import React, { useState, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { notification } from "antd";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

import Carroucel from "./Components/Carousel";
import CardCarousel from "./Components/cardCarousel";
import VideoCarousel from "./Components/videoCarousel";
import Card from "./Components/card";
import CardImagem from "./Components/cardImagem";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Main = ({ match }) => {
    const location = useLocation();
    const pathParts = location.pathname.split("/");
    const entidade = pathParts[1];

    const [listaNoticias, setListaNoticias] = useState([]);
    const [listaImagensRecentes, setListaImagensRecentes] = useState([]);
    const [listaVideos, setListaVideos] = useState([]);

    const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514";

    const headerPost = {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
    };

    //carregarCapa()
    const fetchNoticias = async () => {
        const url = `/api/pagina-publica/listar-noticias`;

        try {
            const response = await axios.get(url, {
                params: { entidadeUrl: entidade }
            });
            setListaNoticias(response.data);
        } catch (error) {
            openNotificationWithIcon("error", "Erro", "Não foi possível carregar as notícias.")
        }
    };

    const fetchImagensRecentes = async () => {
        const url = `/api/pagina-publica/fotos-recentes/listar`;

        try {
            const response = await axios.get(url, {
                params: { entidadeUrl: entidade }
            });
            setListaImagensRecentes(response.data);
        } catch (error) {
            openNotificationWithIcon("error", "Erro", "Não foi possível carregar as fotos recentes.")
        }
    };

    const fetchPictures = async idPic => {
        const url = `https://api.vimeo.com/videos/${idPic}/pictures`;

        try {
            const response = await axios.get(url, {
                transformRequest: [
                    function (data, headers) {
                        delete headers.common["apikey"];
                        return JSON.stringify(data);
                    }
                ],
                headers: headerPost
            });
            return response.data;
        }
        catch {

        }
    };

    const fetchVideo = async () => {
        const url = `/api/pagina-publica/video/listar`;

        try {
            const response = await axios.get(url, {
                params: { entidadeUrl: entidade }
            });

            const videosWithCapa = await Promise.all(
                response.data.map(async e => {
                    const pictureData = await fetchPictures(e.idVideo);
                    if (pictureData && pictureData.data && pictureData.data.length > 0) {
                        e.capa = pictureData.data[0].base_link;
                    } else {
                        e.capa = null;
                    }
                    return e;
                })
            );
            
            if (videosWithCapa !== undefined) {
                setListaVideos(videosWithCapa);
            }
        } catch (error) {
            openNotificationWithIcon("error", "Erro", "Não foi possível carregar os vídeos.")
        }
    };

    useEffect(() => {
        fetchNoticias();
        fetchImagensRecentes();
        fetchVideo();
    }, [entidade]);

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    return (
        <div>
            <div style={{ height: 560 }}>
                <Carroucel entidade={entidade} />
            </div>

            <div className="corpo">
                {listaNoticias.length > 0 && (
                    <CardCarousel
                        cards={listaNoticias}
                        titulo={"Noticias Recentes"}
                        slides={isTabletOrMobile ? 1 : 4}
                        Card={Card}
                    />
                )}
                {listaImagensRecentes.length > 0 && (
                    <CardCarousel
                        cards={listaImagensRecentes}
                        titulo={"Fotos Recentes"}
                        slides={isTabletOrMobile ? 1 : 3}
                        Card={CardImagem}
                    />
                )}
            </div>

            {listaVideos.length > 0 && (
                <VideoCarousel cards={listaVideos} titulo={"Ultimos Videos"} slides={isTabletOrMobile ? 1 : 2} />
            )}
        </div>
    );
};

export default withRouter(Main);
