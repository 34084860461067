import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Modal, Icon, notification } from "antd";
import Participante from "./Participante";
import DrawerEnviarMsg from "./_Aux/DrawerEnviarMsg";
import DrawerNotificarTodos from "./_Aux/DrawerNotificarTodos";
import DrawerNotificarAusentes from "./_Aux/DrawerNotificarAusentes";
import DrawerVideoConf from "./_Aux/DrawerVideoConf";
import DrawerNotificar from "./_Aux/DrawerNotificar";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import { GeralContext } from "../ContextProvider";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
export class Participantes extends Component {
    static contextType = GlobalContext;
    state = {
        participantes: [],
        //LOADING
        loading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER NOTIFICAR
        visibleNotificar: false,
        //DRAWER ENVIAR MENSAGENS
        utilizador: "",
        visibleDrawerEnviarMsg: false,
        notificar: false,
        responsavel: false,
        //DRAWER NOTIFICAR TODOS
        visibleDrawerNotificarTodos: false,
        //DRAWER CRIAR STEPMEET
        visibleDrawerCriarStepmeet: false,
        //DRAWER NOTIFICAR AUSENTES
        visibleDrawerNotificarAusentes: false,
        //MODAL
        visibleIniciarMeet: false,
        prepararMeet: false,
        linkIniciar: ""
    };

    componentDidMount() {
        this.listar(localStorage.getItem("codigo_curso"));
    }

    listar = cursoid => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/curso/participantes",
                    timeout: 60 * 10 * 1000,
                    params: {
                        cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            participantes: response.data,
                            loading: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    abrirDrawerNotificar = (utilizadoId) => {
        var utilizador = JSON.stringify(this.state.participantes.find(x => x.id === utilizadoId));
        this.setState({
            visibleNotificar: true,
            utilizador
        });
    }

    abrirDrawerEnviarMsg = (utilizadoId, notificar, responsavel) => {
        var utilizador = JSON.stringify(this.state.participantes.find(x => x.id === utilizadoId));
        this.setState({
            visibleDrawerEnviarMsg: true,
            utilizador,
            notificar,
            responsavel
        });
    }

    abrirDrawerCriarStepmeet = utilizadoId => {
        var utilizador = JSON.stringify(this.state.participantes.find(x => x.id === utilizadoId));
        this.setState({
            visibleDrawerCriarStepmeet: true,
            utilizador
        });
    }

    iniciarMeet = utilizadorId => {
        this.setState({
            visibleIniciarMeet: true,
            prepararMeet: true
        });

        axios({
            method: "post",
            url: "/api/gerir-videoconferencia/iniciar-meet",
            timeout: 60 * 10 * 1000,
            params: {
                utilizadorId,
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    prepararMeet: false,
                    linkIniciar: `/gerir-curso/${response.data.split(";")[0]
                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                        .toLowerCase()}/vconf/${response.data.split(";")[1]}/player-videoconferencia`
                });
            })
            .catch(error => {
                this.setState({
                    visibleIniciarMeet: false
                });
                openNotificationWithIcon("error", "Erro", "N�o foi poss�vel iniciar Stepmeet");
            });
    }

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<div className="curso-resultados">
                <div className="controlos controlos-fundo">
                    <div className="controlos-opcoes">
                        <Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)} style={{ marginRight: 10 }}>
                            Voltar
                        </Link>
                    </div>
                </div>
            </div>);
        }
    }

    render() {
        const {
            participantes,
            //LOADING
            loading,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER NOTIFICAR
            visibleNotificar,
            //DRAWER ENVIAR MENSAGENS
            utilizador,
            visibleDrawerEnviarMsg,
            notificar,
            responsavel,
            //DRAWER NOTIFICAR TODOS
            visibleDrawerNotificarTodos,
            //DRAWER CRIAR STEPMEET
            visibleDrawerCriarStepmeet,
            //DRAWER NOTIFICAR AUSENTES
            visibleDrawerNotificarAusentes,
            //MODAL
            visibleIniciarMeet,
            prepararMeet,
            linkIniciar
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <GeralContext.Consumer>
                {geralContext => {
                    return (
                        <div className="container container-body">
                            {this.montarBotaoVoltar()}
                            <div className="curso-participantes" style={loading ? { display: "block" } : {}}>
                                {loading ? (
                                    <div className="loading-data">
                                        <div className="loading" />
                                    </div>
                                ) : (
                                    participantes.map((participante, index) =>
                                        <Participante {
                                            ...participante}
                                            key={index}
                                            dt_fechado={geralContext.dt_fechado}
                                            dt_inicio={geralContext.dt_inicio}
                                            dt_fim={geralContext.dt_fim}
                                            fechado={geralContext.fechado}
                                            abrirDrawerNotificar={this.abrirDrawerNotificar}
                                            //abrirDrawerEnviarMsg={this.abrirDrawerEnviarMsg}
                                            //abrirDrawerNotificarTodos={() => this.setState({ visibleDrawerNotificarTodos: true })}
                                            //abrirDrawerNotificarAusentes={() => this.setState({ visibleDrawerNotificarAusentes: true })}
                                            //iniciarMeet={this.iniciarMeet}
                                            abrirDrawerCriarStepmeet={this.abrirDrawerCriarStepmeet}
                                            responsaveis_entidade={this.context.responsaveis_entidade}
                                            conf_total_participantes={this.context.conf_total_participantes}
                                            habilitar_gamificacao={geralContext.habilitar_gamificacao && this.context.gamificacao_entidade}
                                        />
                                    )
                                )}
                            </div>
                            <DrawerEnviarMsg
                                utilizador={utilizador}
                                notificar={notificar}
                                responsavel={responsavel}
                                onClose={() => this.setState({ visibleDrawerEnviarMsg: false })}
                                visibleDrawerEnviarMsg={visibleDrawerEnviarMsg}
                            />
                            <DrawerNotificarTodos
                                onClose={() => this.setState({ visibleDrawerNotificarTodos: false })}
                                visibleDrawerNotificarTodos={visibleDrawerNotificarTodos}
                            />
                            <DrawerVideoConf
                                utilizador={utilizador}
                                visible={visibleDrawerCriarStepmeet}
                                onClose={() => this.setState({ visibleDrawerCriarStepmeet: false })}
                                prepararMeet={estado =>
                                    this.setState({
                                        visibleIniciarMeet: estado,
                                        prepararMeet: true
                                    })
                                }
                                IniciarMeet={info =>
                                    this.setState({
                                        prepararMeet: false,
                                        linkIniciar: `/gerir-curso/${info.split(";")[0]
                                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                            .toLowerCase()}/vconf/${info.split(";")[1]}/player-videoconferencia`
                                    })
                                }
                            />
                            <DrawerNotificarAusentes
                                participantes={participantes}
                                nomeUtilizador={this.context.nome_utilizador}
                                onClose={() => this.setState({ visibleDrawerNotificarAusentes: false })}
                                visibleDrawerNotificarAusentes={visibleDrawerNotificarAusentes}
                            />
                            <DrawerNotificar
                                visibleNotificar={visibleNotificar}
                                utilizador={utilizador}
                                participantes={participantes}
                                nomeUtilizador={this.context.nome_utilizador}
                                onClose={() => this.setState({ visibleNotificar: false })}
                            />
                            <Modal
                                visible={visibleIniciarMeet}
                                okText={
                                    <Link to={linkIniciar}>
                                        Entrar
                                    </Link>
                                }
                                onCancel={() => this.setState({ visibleIniciarMeet: false })}
                                cancelText="Cancelar"
                                okButtonProps={{ disabled: prepararMeet }}
                                maskClosable={false}
                                className="modal-loading"
                            >
                                <div className="modal-loading-bloco">
                                    {prepararMeet ? (
                                        <>
                                            <p>
                                                <Icon type="loading" />
                                            </p>
                                            <p className="texto">A preparar Stepmeet...</p>
                                        </>
                                    ) : (
                                        <>
                                            <p>
                                                <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                            </p>
                                            <p className="texto">Stepmeet pronto!</p>
                                        </>
                                    )}
                                </div>
                            </Modal>
                        </div>
                    )
                }}
            </GeralContext.Consumer>
        );
    }
}

export default Participantes;
