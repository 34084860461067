import React, { useContext, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";
import ModalLoading from "../../Componentes/Modals/ModalLoading";
import ModalViewer from "../../Componentes/Modals/ModalViewer";
import axios from "axios";

const Calendarios = ({ match }) => {
    const context = useContext(GlobalContext);
    const [calendarios, setCalendarios] = useState([]);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [docs, setDocs] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const listarCalendarios = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-calendarios",
            params: {
                entidadeUrl: match.params.entidade
            }
        })
            .then(response => {
                setCalendarios(response.data);
            })
            .catch(error => {

            });
    }

    const gerarDoc = (calendarioId) => {
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/calendario-escolar",
            params: {
                calendarioId,
                entidadeUrl: match.params.entidade 
            }
        })
            .then(response => {
                setDocs([{ uri: response.data }]);
                setModalVisible(true);
                setVisibleLoading(false);
            })
            .catch(error => {
                setVisibleLoading(false);
            });
    }

    useEffect(() => {
        listarCalendarios();
    }, [])

    return (
        <>
            <div className="pagina-entidade body-pagina-entidade">
                <div className="bloco-hero">
                    {context.capa ?
                        <div style={{ display: "grid", gridTemplateRows: "auto 1fr" }}>
                            <div className="header_user" style={{ background: `url(${context.capa})` }}></div>
                        </div>
                        :
                        <div style={{ height: 60 }}></div>
                    }
                </div>
                <div className="bloco-listagem">
                    <div className="listagem">
                        <h2>Calendários</h2>
                        <ul>
                            {calendarios.map(calendario => (
                                <li key={calendario.id}>
                                    <Link to="#" onClick={() => gerarDoc(calendario.id)}>{calendario.nome}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <ModalLoading
                visible={visibleLoading}
                text="A gerar documento..."
            />
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </>
    )
}

export default withRouter(Calendarios);
