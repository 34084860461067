import React, { useState } from "react";
import {Checkbox, Icon, notification} from "antd";
import axios from "axios";
import {useLocation} from "react-router-dom";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

export default function Contactos() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        assunto: '',
        cidade: '',
        mensagem: '',
        option1: false,
        option2: false,
    });

    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entidade = pathParts[1]

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Nome é obrigatório";
        if (!formData.email) newErrors.email = "Email é obrigatório";
        if (!formData.assunto) newErrors.assunto = "Assunto é obrigatório";
        if (!formData.cidade) newErrors.cidade = "Cidade é obrigatória";
        if (!formData.mensagem) newErrors.mensagem = "Mensagem é obrigatória";
        if (!formData.option2) newErrors.option2 = "Você deve concordar com a política de privacidade";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validate();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            openNotificationWithIcon('error', 'Erro no formulário', 'Por favor, preencha todos os campos obrigatórios.');
            return;
        }
        
      

        const item = {
            nome: formData.name,
            enderecoEmail: formData.email,
            assunto: formData.assunto,
            cidade: formData.cidade,
            mensagem: formData.mensagem,
            entidadeUrl: entidade
        };

        setLoading(true);
        
        axios({
            method: "post",
            url: "/api/contacto/email",
            data: item
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Email enviado com sucesso!");
                setFormData({
                    name: '',
                    email: '',
                    assunto: '',
                    cidade: '',
                    mensagem: '',
                    option1: false,
                    option2: false,
                })
                setLoading(false);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                setLoading(false);
            });
    };

    return (
        <div className="contactos-paginas-publicas">
            <div className="titulo">
                <span>Entre em contato conosco</span>
                <span>Coloque as suas questões ou comentários</span>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="area">
                    <div className="area-input">
                        <div className="input">
                            <label htmlFor="name">Nome:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="input">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="input">
                            <label htmlFor="assunto">Assunto:</label>
                            <input
                                type="text"
                                id="assunto"
                                name="assunto"
                                value={formData.assunto}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="input">
                            <label htmlFor="cidade">Cidade:</label>
                            <input
                                type="text"
                                id="cidade"
                                name="cidade"
                                value={formData.cidade}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="textarea-container">
                            <label htmlFor="mensagem">Mensagem:</label>
                            <textarea
                                id="mensagem"
                                name="mensagem"
                                rows="3"
                                cols="50"
                                value={formData.mensagem}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>

                    <div>
                        <div className="checkbox-container">
                            <Checkbox
                                type="checkbox"
                                id="option1"
                                name="option1"
                                checked={formData.option1}
                                onChange={handleChange}
                                
                            />
                            <label htmlFor="option1">Eu concordo em receber novidades da Stepforma.</label>
                        </div>
                        <div className="checkbox-container">
                            <Checkbox 
                                name="option2" 
                                onChange={handleChange} 
                                checked={formData.option2} 
                                id="option2" type="checkbox">
                            </Checkbox>
                            <label htmlFor="option2">Eu concordo com a política de privacidade da Stepforma.</label>
                        </div>
                        <div className="checkbox-container">
                            <button type="submit" disabled={loading}>
                                {loading ? <Icon type="loading" style={{marginRight: 10}} /> : null}
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
