import React from 'react';
import { useMediaQuery } from "react-responsive";
import noimage from "../../../images/noimage.png";

const VideoCard2 = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    //console.log("capa", props.capa)

    return (
        !isTabletOrMobile ?
            <div className="main-card-block" onClick={props.openModal}>
                <img src={props.capa ? props.capa : noimage} alt="Main Image" className="main-img" />
                <div ref={props.mainContentRef} className="main-content"
                    style={{ top: `-${!isTabletOrMobile ? props.topPosition : 0}px` }}>
                    <h3 className="main-title">{props.title}</h3>
                    <p className="main-description">
                        {props.text}
                    </p>
                </div>
            </div>
            :
            <div className='App' style={{ padding: "20px" }} onClick={props.openModal}>
                <div className="card-video">
                    <img src={props.capa ? props.capa : noimage} alt="Card image" className="card-image" />
                    <div className="card-description">
                        <div className="estilo" ref={props.mainContentRef}>
                            <h3 className="main-title">{props.title}</h3>
                            <p className="main-description limited-text">
                                {props.text}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default VideoCard2;