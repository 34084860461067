import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Radio, Checkbox, Row, Upload, Tooltip, Input, Icon, Collapse } from "antd";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { TextArea } = Input;

class ConteudoResolucao extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        aulaid: 0,
        nrQuestoes: 0,
        questaoAtual: 0,
        questao: {},
        resposta: 0,
        resposta_checkbox: [],
        resposta_livre: "",
        fileList: [],
        fim: false,
        confirmacao: false,
        totalEmBranco: 0,
        //ESTADOS
        apresentacao: true,
        iniciado: false,
        //INFO
        modulo: "",
        aula: "",
        total_questoes: 0,
        resolucao: false,
        estado: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        redirectLinkResolucao: "",
        redirectLinkRepetir: "",
        //
        numPages: null,
        pageNumber: 1,
        scale: 1.5,
        rotate: 0,
        //LOADING
        iconLoadingProximo: false,
        iconLoadingAnterior: false,
        //LOADING
        loading: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: localStorage.getItem("codigo_aula")
        });

        this.carregarQuestionarioInfo();
    }

    //APANHA OS ATRIBUTOS PASSADOS PELO ENDEREÇO
    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    //DEVOLVE O TOTAL DE PERGUNTAS
    carregarQuestionarioInfo = () => {
        axios({
            method: "get",
            url: "/api/gestao-questionario/carregar-questionario-info",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        })
            .then(response => {
                this.setState(
                    {
                        modulo: response.data.modulo,
                        aula: response.data.aula,
                        total_questoes: response.data.totalQuestoes,
                        loading: false
                    },
                    () => {
                        this.carregaPergunta();
                    }
                );
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.props.marcarComoNaoDisponivel();
            });
    };

    carregaPergunta = () => {
        this.setState({
            iconLoadingProximo: true
        });

        axios({
            method: "get",
            url: "/api/gestao-questionario/carrega-pergunta",
            params: {
                aulaid: localStorage.getItem("codigo_aula"),
                pergunta: this.state.questaoAtual
            }
        })
            .then(response => {
                this.setState({
                    questao: response.data,
                    questaoAtual: this.state.questaoAtual + 1,
                    iconLoadingProximo: false
                });
            })
            .catch(() => { });
    };

    carregaPerguntaAnterior = () => {
        this.setState({
            iconLoadingAnterior: true
        });

        axios({
            method: "get",
            url: "/api/gestao-questionario/carrega-pergunta",
            params: {
                aulaid: localStorage.getItem("codigo_aula"),
                pergunta: this.state.questaoAtual - 2
            }
        })
            .then(response => {
                this.setState({
                    questao: response.data,
                    questaoAtual: this.state.questaoAtual - 1,
                    iconLoadingAnterior: false
                });
            })
            .catch(() => { });
    };

    validar = (rule, value, callback) => {
        //VALIDAR NO UPLOAD...
        if (!this.validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase())) callback("Ficheiro com formato inválido");

        callback();
    };

    //LEITOR DE PDF
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    scaleDown = () => {
        if (this.state.scale >= 0.8) {
            this.setState({ scale: this.state.scale - 0.3 });
        }
    };

    scaleUp = () => {
        if (this.state.scale <= 1.8) {
            this.setState({ scale: this.state.scale + 0.3 });
        }
    };

    render() {
        const { Panel } = Collapse;
        const Dragger = Upload.Dragger;

        const {
            questaoAtual,
            questao,
            resposta_checkbox,
            resposta,
            resposta_livre,
            fileList,
            //INFO
            total_questoes,
            //LOADING
            iconLoadingProximo,
            iconLoadingAnterior,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading
        } = this.state;
        const RadioGroup = Radio.Group;

        const radioStyle = {
            display: "block",
            height: "30px",
            lineHeight: "30px"
        };

        const montar_perguntar = questao => {
            if (questao.tipo_pergunta === "Escolha única") {
                return (
                    <RadioGroup value={resposta} disabled>
                        {questao.opcoes.map((opcao, index) => (
                            <Radio key={"opcao" + index} style={radioStyle} value={opcao.id}>
                                {opcao.texto}
                            </Radio>
                        ))}
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Escolha múltipla") {
                return (
                    <Checkbox.Group style={{ width: "100%" }} defaultValue={resposta_checkbox} disabled>
                        {questao.opcoes.map((opcao, index) => (
                            <Row key={"opcao" + index}>
                                <Checkbox value={opcao.id}>{opcao.texto}</Checkbox>
                            </Row>
                        ))}
                    </Checkbox.Group>
                );
            } else if (questao.tipo_pergunta === "Escala 1 a 5") {
                return (
                    <RadioGroup className="escala" value={resposta} disabled>
                        <span className="minimo">1</span>
                        <Radio style={radioStyle} className="escala" value="1"></Radio>
                        <Radio style={radioStyle} className="escala" value="2"></Radio>
                        <Radio style={radioStyle} className="escala" value="3"></Radio>
                        <Radio style={radioStyle} className="escala" value="4"></Radio>
                        <Radio style={radioStyle} className="escala" value="5"></Radio>
                        <span className="maximo">5</span>
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Escala 1 a 10") {
                return (
                    <RadioGroup className="escala" value={resposta} disabled>
                        <span className="minimo">1</span>
                        <Radio style={radioStyle} className="escala" value="1"></Radio>
                        <Radio style={radioStyle} className="escala" value="2"></Radio>
                        <Radio style={radioStyle} className="escala" value="3"></Radio>
                        <Radio style={radioStyle} className="escala" value="4"></Radio>
                        <Radio style={radioStyle} className="escala" value="5"></Radio>
                        <Radio style={radioStyle} className="escala" value="6"></Radio>
                        <Radio style={radioStyle} className="escala" value="7"></Radio>
                        <Radio style={radioStyle} className="escala" value="8"></Radio>
                        <Radio style={radioStyle} className="escala" value="9"></Radio>
                        <Radio style={radioStyle} className="escala" value="10"></Radio>
                        <span className="maximo">10</span>
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Resposta livre") {
                return (
                    <>
                        <TextArea value={resposta_livre} style={{ height: "100%" }} rows={5} disabled />
                        <div className="anexos" style={{ marginTop: 20 }}>
                            <Dragger {...props} disabled>
                                <p className="ant-upload-drag-icon">
                                    <i className="fas fa-upload" />
                                </p>
                                <p className="ant-upload-text">
                                    Adicionar anexo{" "}
                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </p>
                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                            </Dragger>
                        </div>
                    </>
                );
            }
        };

        const props = {
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("ficheiro");
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            defaultFileList: this.state.fileList,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }));

                return false;
            },
            fileList
        };

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
                <div className="container">
                    <div className="bloco-questionario" style={{ background: "transparent", boxShadow: "none" }}>
                        <div className="bloco-info">
                            <div className="prova">
                                <div className="bloco-questao">
                                    <div className="bloco-enunciado">
                                        <span className="num-pergunta">
                                            Pergunta {questaoAtual} - {total_questoes}
                                        </span>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: questao.pergunta ? questao.pergunta.replace(/(?:\r\n|\r|\n)/g, "<br>") : ""
                                            }}
                                        ></p>
                                        {questao.ficheiro_pergunta && (
                                            <div className="primeiro-bloco-links">
                                                <Collapse
                                                    expandIcon={({ isActive }) =>
                                                        <div className="bloco-links-ficheiro">
                                                            <span className="bloco-links-img-container">
                                                                <img src={require("../../images/icons/pdf.png")} />
                                                            </span>
                                                            <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                        </div>
                                                    }
                                                >
                                                    <Panel>
                                                        <Document file={questao.ficheiro_pergunta} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                            <Page
                                                                pageNumber={this.state.pageNumber}
                                                                scale={this.state.scale}
                                                                rotate={this.state.rotate}
                                                            />
                                                        </Document>
                                                        <div className="controlos-pdf">
                                                            <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber > this.state.numPages ? prevState.pageNumber - 1 : 1) }))}>
                                                                <Icon type="caret-left" />
                                                            </button>
                                                            <span>
                                                                {this.state.pageNumber}/{this.state.numPages}
                                                            </span>
                                                            <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber < this.state.numPages ? prevState.pageNumber + 1 : this.state.numPages) }))}>
                                                                <Icon type="caret-right" />
                                                            </button>
                                                            <button onClick={this.scaleUp}>
                                                                <Icon type="zoom-in" />
                                                            </button>
                                                            <button onClick={this.scaleDown}>
                                                                <Icon type="zoom-out" />
                                                            </button>
                                                            <button onClick={() => this.setState({ rotate: this.state.rotate === 180 ? 180 : this.state.rotate + 90 })}>
                                                                <Icon type="reload" />
                                                            </button>
                                                            <button onClick={() => this.setState({ rotate: this.state.rotate === -180 ? -180 : this.state.rotate - 90 })}>
                                                                <Icon type="reload" style={{ transform: "scaleX(-1) " }} />
                                                            </button>
                                                        </div>
                                                    </Panel>
                                                </Collapse>
                                            </div>
                                        )}
                                    </div>
                                    <div className="bloco-resposta">
                                        <h4 className="titulo-bloco">Resposta</h4>
                                        <div className="respostas">{montar_perguntar(questao)}</div>
                                    </div>
                                    <div className="bloco-resolucao">
                                        {questao.texto_resolucao || questao.ficheiro_resolucao ?
                                            <>
                                                <h3 className="bloco-titulo">Solução</h3>
                                                {questao.texto_resolucao && (
                                                    <div className="bloco-resolucao-texto">
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: questao.texto_resolucao
                                                                    ? questao.texto_resolucao.replace(/(?:\r\n|\r|\n)/g, "<br>")
                                                                    : ""
                                                            }}
                                                        ></span>
                                                    </div>
                                                )}
                                                {questao.ficheiro_resolucao && (
                                                    <div className="bloco-links">
                                                        <Collapse
                                                            expandIcon={({ isActive }) =>
                                                                <div className="bloco-links-ficheiro">
                                                                    <span className="bloco-links-img-container">
                                                                        <img src={require("../../images/icons/pdf.png")} />
                                                                    </span>
                                                                    <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                                </div>
                                                            }
                                                        >
                                                            <Panel>
                                                                <Document file={questao.ficheiro_resolucao} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                                    <Page
                                                                        pageNumber={this.state.pageNumber}
                                                                        scale={this.state.scale}
                                                                        rotate={this.state.rotate}
                                                                    />
                                                                </Document>
                                                                <div className="controlos-pdf">
                                                                    <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber > this.state.numPages ? prevState.pageNumber - 1 : 1) }))}>
                                                                        <Icon type="caret-left" />
                                                                    </button>
                                                                    <span>
                                                                        {this.state.pageNumber}/{this.state.numPages}
                                                                    </span>
                                                                    <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber < this.state.numPages ? prevState.pageNumber + 1 : this.state.numPages) }))}>
                                                                        <Icon type="caret-right" />
                                                                    </button>
                                                                    <button onClick={this.scaleUp}>
                                                                        <Icon type="zoom-in" />
                                                                    </button>
                                                                    <button onClick={this.scaleDown}>
                                                                        <Icon type="zoom-out" />
                                                                    </button>
                                                                    <button onClick={() => this.setState({ rotate: this.state.rotate === 180 ? 180 : this.state.rotate + 90 })}>
                                                                        <Icon type="reload" />
                                                                    </button>
                                                                    <button onClick={() => this.setState({ rotate: this.state.rotate === -180 ? -180 : this.state.rotate - 90 })}>
                                                                        <Icon type="reload" style={{ transform: "scaleX(-1) " }} />
                                                                    </button>
                                                                </div>
                                                            </Panel>
                                                        </Collapse>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                                <h3 className="bloco-titulo">Solução</h3>
                                                <div className="bloco-resolucao-texto">
                                                    <span>Sem Solução</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="bloco-resposta">
                                        <div className="controlos-resposta">
                                            {questaoAtual > 1 && (
                                                <button
                                                    className="botao-secundario"
                                                    disabled={iconLoadingProximo || iconLoadingAnterior}
                                                    onClick={this.carregaPerguntaAnterior}
                                                >
                                                    {iconLoadingAnterior ? <Icon type="loading" /> : null}
                                            Anterior
                                                </button>
                                            )}
                                            {questaoAtual < total_questoes && (
                                                <button
                                                    className="botao-principal"
                                                    disabled={iconLoadingProximo || iconLoadingAnterior}
                                                    onClick={this.carregaPergunta}
                                                >
                                                    {iconLoadingProximo ? <Icon type="loading" /> : null}
                                            Próxima
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

export default withRouter(ConteudoResolucao);
