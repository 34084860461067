import React from "react"
import noimage from "../../images/noimage.png";
import { Dropdown, Menu, notification, Icon } from "antd";
import { Link } from "react-router-dom";
import Pontos from "./_Aux/Pontos";
import moment from "moment";

const Participante = ({ id, foto, nome, numero, instrutor, coordenador, proprio, notificacao, responsavelNome, responsavelEmail, ultimoAcesso, abrirDrawerNotificar, abrirDrawerEnviarMsg, abrirDrawerNotificarTodos, abrirDrawerNotificarAusentes, iniciarMeet, abrirDrawerCriarStepmeet, responsaveis_entidade, habilitar_gamificacao, conf_total_participantes, dt_fechado, dt_inicio, dt_fim, fechado }) => {

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description
        });
    };

    // MONTA O MENU DE OPÇÕES POR CADA CURSO
    const montarMenu = <Menu>
        <Menu.Item>
            <Link to="#" onClick={() => notificacao ? abrirDrawerEnviarMsg(id, true, false) : openNotificationWithIcon("info", "O aluno não pretende receber notificações por e-mail de acordo com as suas configurações", "")}>
                Notificar
                    </Link>
        </Menu.Item>
        {instrutor ?
            <Menu.Item>
                <Link to="#" onClick={abrirDrawerNotificarTodos}>
                    Notificar todos
                    </Link>
            </Menu.Item> : null}
        {instrutor || coordenador ?
            <Menu.Item>
                <Link to="#" onClick={abrirDrawerNotificarAusentes}>
                    Notificar ausentes
                    </Link>
            </Menu.Item> : null}
        {!instrutor && !coordenador ?
            <Menu.Item>
                <Link to="#" onClick={() => responsaveis_entidade && responsavelNome && responsavelEmail && !instrutor ? abrirDrawerEnviarMsg(id, false, true) : openNotificationWithIcon("info", "O e-mail do responsável não está definido", "")}>
                    Notificar Responsável
                    </Link>
            </Menu.Item> : null}
        {/*{conf_total_participantes > 0 && !fechado ?
            <Menu.Item>
                <Link to="#" onClick={() => iniciarMeet(id)}>
                    Iniciar Stepmeet
                    </Link>
            </Menu.Item>
            : null}*/}
        {conf_total_participantes > 0 && !fechado ?
            <Menu.Item>
                <Link to="#" onClick={() => abrirDrawerCriarStepmeet(id)}>
                    Agendar Stepmeet
                    </Link>
            </Menu.Item>
            : null}
    </Menu>;

    const montarMenuProprio = <Menu>
        <Menu.Item>
            <Link to="#" onClick={() => abrirDrawerEnviarMsg(id, true, false)}>
                Notificar
                    </Link>
        </Menu.Item>
        <Menu.Item>
            <Link to="#" onClick={abrirDrawerNotificarTodos}>
                Notificar todos
                    </Link>
        </Menu.Item>
    </Menu>;

    const montarTitulo = (instrutor, coordenador) => {
        if (instrutor && coordenador)
            return "(Coordenador / Professor)";
        else if (coordenador)
            return "(Coordenador)";
        else
            return "(Professor)";
    }

    const montarDiasAusente = (data) => {
        var dataMenor = moment().startOf('day');
        if (dt_fechado && moment(dt_fechado).startOf('day').isBefore(dataMenor))
            dataMenor = moment(dt_fechado).startOf('day');

        if (dt_fim && moment(dt_fim).startOf('day').isBefore(dataMenor))
            dataMenor = moment(dt_fim).startOf('day');

        if (!data)
            data = dt_inicio;

        const totalDias = dataMenor.diff(moment(data).startOf('day'), 'days');

        if (totalDias > 0)
            return <div className="bloco-total-dias-ausente"><span className={`total-dias-ausente ${totalDias > 7 ? "ausente-acima" : "ausente-normal"}`} title="Número de dias ausente no curso">{totalDias} {totalDias > 1 ? "dias" : "dia"}</span></div>
    }

    return (
        <div className="cartao-participante">
            <div className="opcao-notificar-space">
                {/*<Dropdown
                    overlay={proprio ? montarMenuProprio : montarMenu}
                    placement="bottomLeft"
                >
                    <Link to="#" className="botao-icon-configs">
                        <i className="fas fa-cog" />
                    </Link>
                </Dropdown>*/}
                <Link to="#" className="botao-icon-iniciar" onClick={() => abrirDrawerCriarStepmeet(id)}>
                    <Icon type="play-circle" />
                </Link>
                {/*<Link to="#" className="botao-icon-enviar" onClick={() => notificacao ? abrirDrawerEnviarMsg(id) : openNotificationWithIcon("info", "Participante não pretende receber notificações por e-mail de acordo com as suas configurações", "")}>
                    <Icon type="mail" />
                </Link>*/}
                <Link to="#" className="botao-icon-enviar" onClick={() => abrirDrawerNotificar(id)}>
                    <Icon type="mail" />
                </Link>
            </div>
            <div className="container-img">
                <img src={foto ? foto : noimage} />
            </div>
            <div className="info">
                <span className="nome">{nome.split(" ").length > 1 ? `${nome.split(" ")[0]} ${nome.split(" ").pop()}` : nome}</span>
                <span className="numero">{numero}</span>
            </div>
            {(instrutor || coordenador) && <span className="instrutor">{montarTitulo(instrutor, coordenador)}</span>}
            {!instrutor && !coordenador && habilitar_gamificacao && <Pontos cursoid={localStorage.getItem("codigo_curso")} utilizadorid={id} />}
            {ultimoAcesso?.valida ?
                <span className="ultimo-acesso" title="Último acesso ao curso">{moment(ultimoAcesso.data).isAfter(moment('0001-01-01')) ? moment(ultimoAcesso.data).format("DD/MM/YYYY HH:mm") : "Nunca"}</span>
                : null}
            {montarDiasAusente(ultimoAcesso?.data)}
        </div>
    )
}

export default Participante
