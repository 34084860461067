import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";
import VisaoGeral from "./VisaoGeral";
import Horarios from "./Horarios/Horarios";
import ContextProvider from "./ContextProvider";

class Layout extends Component {
    render() {
        return (
            <>
                <ContextProvider>
                    <Header />
                    <Menu />
                    <Switch>
                        <Route path="/:entidade/turma/:turma/:id/visao-geral" component={VisaoGeral} />
                        <Route path="/:entidade/turma/:turma/:id/horarios" component={Horarios} />
                    </Switch>
                </ContextProvider>
            </>
        );
    }
}

export default Layout;
