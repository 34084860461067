import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../User/Header";
import { Form } from "antd";
import Gerais from "./Gerais";
import Educacao from "./Educacao";
import Formacao from "./Formacao";
import Popup from "./Popups/Popup";
import { GlobalContext } from "../../GlobalState";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //GRID
        filtroAtivo: "Gerais",
        escolasCarregadas: false
    };

    ativarFiltro = (filtroAtivo) => {
        this.setState({ filtroAtivo });
    }

    render() {
        const {
            //REDIRECT
            redirect,
            redirectLink,
            filtroAtivo
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const acesso = this.context.acessos?.find(x => x.func === 127);

        const opcoesMenu = () => {
            var opcoes = ["Gerais", "Educação", "Formação", "Questionários"];

            if (!this.context.diario_entidade || !this.context.diario_stepforma)
                opcoes = opcoes.filter(x => x !== "Educação");

            if (!this.context.modulo_formacao || !this.context.modulo_formacao_stepforma)
                opcoes = opcoes.filter(x => x !== "Formação");


            if (!acesso)
                opcoes = opcoes.filter(x => x !== "Questionários");

            return opcoes;
        }

        return (
            <>
                <Header titulo="Configurações" />
                <div className="administracao-empresa">
                    <div className="container container-body">
                        <div className="bloco-grid">
                            <div className="filtros">
                                <ul>
                                    {opcoesMenu().map((item, index) => (
                                        <li
                                            key={index}
                                            className={filtroAtivo === item ? "ativo" : ""}
                                            onClick={() => this.ativarFiltro(item)}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="bloco-cursos-estatisticas">
                                {filtroAtivo === "Gerais" && <Gerais />}
                                {filtroAtivo === "Educação" && <Educacao />}
                                {filtroAtivo === "Formação" && <Formacao />}
                                {filtroAtivo === "Questionários" && <Popup />}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const FormMain = Form.create({ name: "form-minha-conta" })(Main);

export default FormMain;
