import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, Dropdown } from 'antd';
import UserInfo from "../UserInfo/UserInfo";
import Tabela from "./Tabela";

const Formacao = ({ menuOptionSelected }) => {
    return (
        <>
            <div className="page-container">
                <UserInfo
                    formacao
                />
                <div className="user-info-detail">
                    <div className="page-info">
                        <div>
                            <h1 className="page-title" style={{ margin: "20px 20px 10px 20px" }}>Cursos</h1>
                        </div>
                        <span className="page-controls">
                            <Link className="botao-icon-detalhe botao-maior" to="#" title="Descarregar">
                                <Icon type="eye" />
                            </Link>
                        </span>
                    </div>
                    <div className="block-table">
                        <Tabela />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Formacao;