import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Modal, Select, Form } from 'antd';
import { GlobalContext } from "../../GlobalState";
import moment from "moment";
import axios from "axios";

const { Option } = Select;
class AdicionarSubscricao extends Component {
    static contextType = GlobalContext;
    state = {
        visible: false,
        nr_meses: undefined,
        valorMensal: 0,
        dt_fim: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
    }

    componentDidMount() {
        this.carregarValorMensal();
        //this.calcularNrMeses();
    }

    carregarValorMensal = () => {
        axios({
            method: "get",
            url: "/api/carrinho/entidade-valor-mensal"
        })
            .then(response => {
                this.setState({
                    valorMensal: response.data.valorMensal,
                    dt_fim: response.data.dt_fim ? moment(response.data.dt_fim).add(1, "d") : ""
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    //calcularNrMeses = () => {
    //    var subs = this.context.carrinho.find(x => x.tipo === "SUBSCRICAO")
    //    if (subs) {
    //        return subs.nr_meses;
    //    }
    //    else
    //        return 0;
    //}

    //adicionarProduto = () => {
    //    var carrinho = [...this.context.carrinho, this.props.produto];

    //    this.context.atualizarState({
    //        carrinho
    //    })
    //}

    handleChangeSelect = value => {
        this.setState({
            nr_meses: value
        })
    }

    abrirModal = () => {
        this.setState({
            visible: true
        })
    }

    //validar = () => {
    //    return this.context.carrinho.find(x => x.id === this.props.produto.id || x.tipo === "SUBSCRICAO")
    //}

    handleOk = () => {
        var produto = "";
        if (this.state.dt_fim)
            produto = {
                id: 0,
                nome: `Subscrição ${this.state.nr_meses > 1 ? `${this.state.nr_meses} meses` : "1 mês"}`,
                descricao: `Subscrição de ${moment(this.state.dt_fim).format("DD/MM/YYYY")} a ${moment(this.state.dt_fim).add(this.state.nr_meses, 'M').format("DD/MM/YYYY")}`,
                preco: this.state.nr_meses * this.state.valorMensal,
                tipo: "SUBSCRICAO",
                dt_inicio: moment(this.state.dt_fim).format("YYYY/MM/DD"),
                dt_fim: moment(this.state.dt_fim).add(this.state.nr_meses, 'M').format("YYYY/MM/DD"),
                nr_meses: this.state.nr_meses
            }
        else
            produto = {
                id: 0,
                nome: `Subscrição ${this.state.nr_meses > 1 ? `${this.state.nr_meses} meses` : "1 mês"}`,
                descricao: `Subscrição de ${moment().format("DD/MM/YYYY")} a ${moment().add(this.state.nr_meses, 'M').format("DD/MM/YYYY")}`,
                preco: this.state.nr_meses * this.state.valorMensal,
                tipo: "SUBSCRICAO",
                dt_inicio: moment().format("YYYY/MM/DD"),
                dt_fim: moment().add(this.state.nr_meses, 'M').format("YYYY/MM/DD"),
                nr_meses: this.state.nr_meses
            }


        var carrinho = [...this.context.carrinho, produto];

        this.context.atualizarState({
            carrinho
        })

        this.setState({
            visible: false,
            nr_meses: undefined
        })
        this.props.form.resetFields()

        document.getElementsByClassName("carrinho-compras")[0].click()
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            nr_meses: undefined
        })
        this.props.form.resetFields()
    }

    render() {
        const {
            visible,
            nr_meses,
            //REDIRECT
            redirect,
            redirectLink,
        } = this.state;
        const { getFieldDecorator } = this.props.form;

        if (redirect)
            return <Redirect to={redirectLink} />;

        //const items = []
        //const _nr_meses = this.calcularNrMeses();
        //for (var i = 0; i < (24 - _nr_meses); i++) {
        //    items.push(<Option key={i} value={(i + 1)}>
        //        {(i + 1)} {(i + 1) > 1 ? "meses" : "mês"}
        //    </Option>)
        //}

        return (
            <>
                <button className="botao-principal botao-subscricao" onClick={this.abrirModal} style={{ marginLeft: 10 }}>
                    <i className="fas fa-plus" style={{ marginRight: 5 }}></i>
                    <i className="fas fa-shopping-cart" style={{ marginRight: 5 }}></i>                            
                    Subscrição
                </button>
                <Modal
                    title="Número de meses de subscrição com acesso a todos os cursos nesta modalidade de pagamento"
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText="Confirmar"
                    cancelText="Cancelar"
                >
                    <Form className="form-selecionar-subscricao" layout="horizontal">
                        <Form.Item>
                            {getFieldDecorator("nr_meses", {
                                initialValue: nr_meses
                            })(<Select
                                placeholder="Selecionar"
                                allowClear={true}
                                style={{ width: "100%" }}
                                onChange={this.handleChangeSelect}>
                                <Option value={1}>1 mês - {this.context.moeda_entidade} {this.context.valorMensal}</Option>
                                <Option value={3}>3 meses - {this.context.moeda_entidade} {(3 * this.context.valorMensal)}</Option>
                                <Option value={6}>6 meses - {this.context.moeda_entidade} {(6 * this.context.valorMensal)}</Option>
                                <Option value={12}>12 meses - {this.context.moeda_entidade} {(12 * this.context.valorMensal)}</Option>
                                <Option value={24}>24 meses - {this.context.moeda_entidade} {(24 * this.context.valorMensal)}</Option>
                            </Select>)}
                        </Form.Item>
                    </Form>
                </Modal>
            </>);
    }
}

const FormAdicionarSubscricao = Form.create({ name: "form-adicionar-subscricao" })(AdicionarSubscricao);

export default FormAdicionarSubscricao;
