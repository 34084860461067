import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Icon, notification } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class SolicitarConta extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        email: "",
        telefone: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    enviar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("entidadeUrl", this.props.match.params.entidade);
                item.append("nome", this.state.nome);
                item.append("email", this.state.email);
                item.append("telefone", this.state.telefone);

                axios({
                    method: "post",
                    url: "/api/pagina-entidade/solicitar-conta",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Pedido de registo enviado!");
                        this.props.form.resetFields();
                        this.setState({
                            nome: "",
                            email: "",
                            telefone: "",
                            mensagem: "",
                            iconLoading: false,
                            redirect: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível enviar o pedido de registo");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            nome,
            email,
            telefone,
            //LOADING
            iconLoading,
            //REDIRECT  
            redirect
        } = this.state;

        if (redirect) return <Redirect to={`/${this.props.match.params.entidade}`} />;

        return (
            <>
                <Header titulo="Solicitar conta" marginTop0={true} />
                <div className="content">
                    <Form onSubmit={this.handleSubmit} className="login template-form">
                        <Form.Item label="Nome">
                            {getFieldDecorator("nome", {
                                initialValue: nome,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="nome" onChange={this.handleChange} />)}
                        </Form.Item>
                        <Form.Item label="E-mail">
                            {getFieldDecorator("email", {
                                initialValue: email,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="email" onChange={this.handleChange} />)}
                        </Form.Item>
                        <Form.Item label="Telefone / Celular">
                            <Input value={telefone} name="telefone" onChange={this.handleChange} />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button type="primary" className="login-form-button form-button" disabled={iconLoading} onClick={this.enviar}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                    Enviar
                                </Button>
                            <div className="bloco-login-links">
                                <Link to={`/${this.props.match.params.entidade}/login`} className="form-link">
                                    Voltar <i className="fas fa-chevron-right"></i>
                                </Link>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </>
        );
    }
}

const FormSolicitarConta = Form.create({ name: "form-solicitar-conta" })(SolicitarConta);

export default FormSolicitarConta;
