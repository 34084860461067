import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import axios from "axios";
import Vimeo from "@vimeo/player";
import { GlobalContext } from "../../GlobalState";
import Oops from "../../Geral/Oops.jsx";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Video extends Component {
    static contextType = GlobalContext;
    state = {
        entidade: "",
        curso: "",
        cursoId: "",
        aulaid: 0,
        vimeo: "",
        current: "",
        loading: true,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        player: "",
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            entidade: this.props.match.params.entidade,
            curso: this.props.match.params.curso,
            cursoId: this.props.match.params.cursoid,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.setState(
                {
                    sameUrl: false,
                    aulaid: prevProps.match.params.aulaid
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-player/conteudo1",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState(
                    {
                        vimeo: "https://player.vimeo.com/video/" + response.data.recurso,
                        loading: false
                    },
                    () => {
                        var iframe = document.querySelector("iframe");
                        var player = new Vimeo(iframe);

                        this.setState({
                            player
                        });

                        player.on("seeked", data => {
                            this.setState({ current: data.seconds });
                        });
                    }
                );
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            entidade,
            curso,
            cursoId,
            vimeo,
            current,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return this.state.loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
                <div className="player">
                    <div className="player-wrapper">
                        {sameUrl && <iframe src={vimeo} id="player"></iframe>}
                        <Controlos
                            backUrl={`/${entidade}/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${cursoId}/curso-modulos`}
                            sameUrl={sameUrl}
                            current={current}
                            player
                        />
                    </div>
                </div>
            );
    }
}

export default Video;
