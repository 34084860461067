import React, { Component } from "react";
import { Form, Input, Icon, notification, Button } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    if (description === "Esta conta já foi validada!") {
        const btn = (
            <Button className="notification-btn" onClick={() => { window.location = "/login" }}>
                Iniciar Sessão
            </Button>
        );
        notification[type]({
            message: message,
            description: <span dangerouslySetInnerHTML={{ __html: description }}></span>,
            btn
        });
    }
    else {
        notification[type]({
            message: message,
            description: <span dangerouslySetInnerHTML={{ __html: description }}></span>
        });
    }
};

class RegistoPasso1 extends Component {
    static contextType = GlobalContext;
    state = {
        id_utilizador: 0,
        nome_completo: "",
        redirect: false,
        redirectLink: "",
        password: "",
        termos: false,
        politica: false
    };

    teste = () => {
        this.setState({ redirect: true, redirectLink: "/login" })
    }

    componentDidMount() {
        this.validarToken();
    }

    getParameterByName = name => {
        const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    validarToken = () => {
        const code = this.getParameterByName("code");
        if (code) {
            axios({
                method: "get",
                url: "/api/autenticacaonew/validar-code",
                timeout: 60 * 10 * 1000,
                params: {
                    code: code,
                    entidade: false
                }
            })
                .then(response => {
                    const dados = response.data.split(";")
                    this.setState({
                        id_utilizador: dados[0],
                        nome_completo: dados[1],
                    });
                    localStorage.setItem("codigo_utilizador", dados[0]);
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                });
        }
        else {
            openNotificationWithIcon("error", "Erro", "Não foi possível confirmar o e-mail! Por favor, volte a carregar no link existente no email.");
        }
    };

    concluirRegisto = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                axios({
                    method: "post",
                    url: "/api/registo/registar-utilizador",
                    params: {
                        userid: this.state.id_utilizador,
                        nome_completo: this.state.nome_completo,
                        password: this.state.password
                    }
                })
                    .then(() => {
                        this.setState({
                            redirect: true,
                            redirectLink: "/utilizador-registar-passo-2",
                            iconLoading: false
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível concluir este passo!");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue("password")) {
            callback("Senhas não coincidentes");
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { redirect, redirectLink, iconLoading } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Cadastro" marginTop0={true} />
                <div className="container">
                    <div className="registar registar-passo6">
                        <div className="header">
                            <div className="passos passos-password">
                                <span>Passo 1</span>
                            </div>
                            <div className="descritivo">
                                <span>Defina a sua password de acesso para finalizar o seu cadastro.</span>
                            </div>
                            <Link to="/" className="btn-close" title="Fechar">
                                <i className="fas fa-times fechar"></i>
                            </Link>
                        </div>
                        <div className="content">
                            <Form className="registar-passo2">
                                <div className="row">
                                    <div className="col-lg-3" />
                                    <div className="col-lg-6">
                                        <Form.Item label={<span>Nome Completo</span>}>
                                            {getFieldDecorator("nome-completo", {
                                                initialValue: this.state.nome_completo,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório",
                                                        whitespace: true
                                                    }
                                                ]
                                            })(<Input name="nome_completo" onChange={this.handleChange} />)}
                                        </Form.Item>
                                        <Form.Item label="Senha">
                                            {getFieldDecorator("password", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    },
                                                    {
                                                        pattern: "^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})",
                                                        message: <p>A senha deve conter pelo menos uma letra maiúscula, um número e um mínimo de 6 caracteres</p>
                                                    },
                                                    {
                                                        validator: this.validateToNextPassword
                                                    }
                                                ]
                                            })(<Input.Password type="password" name="password" onChange={this.handleChange} />)}
                                        </Form.Item>
                                        <Form.Item label="Confirme a senha">
                                            {getFieldDecorator("confirm", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    },
                                                    {
                                                        validator: this.compareToFirstPassword
                                                    }
                                                ]
                                            })(<Input.Password type="password" onBlur={this.handleConfirmBlur} />)}
                                        </Form.Item>
                                    </div>
                                    <div className="col-lg-3" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-3" />
                                    <div className="col-lg-6">
                                        <Form.Item className="bloco-controlos" style={{ marginTop: "30px" }}>
                                            <div className="controlos">
                                                <Link to="#" onClick={() => this.concluirRegisto()} disabled={iconLoading} className="botao-principal">
                                                    {iconLoading ? <Icon type="loading" /> : null}
                                                    Continuar
                                                </Link>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div className="col-lg-3" />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const FormRegistoPasso1 = Form.create({ name: "registo-passo1" })(RegistoPasso1);

export default FormRegistoPasso1;
