import React, { Component } from "react";
import { Link } from "react-router-dom";
import Topico from "./_Aux/TopicoDetalhe";
import axios from "axios";
import noimage from "../../images/noimage.png";

class ForumDetalhe extends Component {
    state = {
        forumid: 0,
        curso: "",
        //TOPICO AUTOR
        topico: "",
        autor_topico: "",
    };

    UNSAFE_componentWillMount() {
        this.setState({
            curso: this.props.match.params.curso,
            forumid: localStorage.getItem("codigo_forum")
        });
        this.CarregarTopicoInfo();
    }

    CarregarTopicoInfo = () => {
        axios({
            method: "get",
            url: "/api/gerir-forum/gerir-forum-info",
            params: {
                forumid: localStorage.getItem("codigo_forum")
            }
        })
            .then(response => {
                this.setState({
                    topico: response.data.topico,
                    autor_topico: response.data.utilizador
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            curso,
            forumid,
            //TOPICO AUTOR
            topico,
            autor_topico,
        } = this.state;

        return (
            <div className="container container-body">
                <div className="curso-forum">
                    <div className="respostas">
                        <div className="bloco-flex">
                            <div className="topico">
                                {autor_topico && (
                                    <div className="info-autor">
                                        <div className="container-img">
                                            <img src={autor_topico.foto ? autor_topico.foto : noimage} />
                                        </div>
                                        <div className="info">
                                            <span className="nome">
                                                {autor_topico.nome_completo.split(" ").length > 1 ? `${autor_topico.nome_completo.split(" ")[0]} ${autor_topico.nome_completo.split(" ")[autor_topico.nome_completo.split(" ").length - 1]}` : autor_topico.nome_completo}
                                            </span>
                                            <p className="numero">{autor_topico.numero}</p>
                                        </div>
                                    </div>
                                )}
                                <div className="info-topico">
                                    <div className="questao">
                                        <span className="texto">{topico}</span>
                                    </div>
                                </div>
                            </div>
                            <Link
                                to={`/gerir-curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-forum`}
                                className="botao-principal"
                            >
                                Voltar
                                        </Link>
                        </div>
                        <Topico forumid={forumid} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ForumDetalhe;
