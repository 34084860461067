import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Checkbox, notification, Icon, Tooltip } from "antd";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Gerais extends Component {
    static contextType = GlobalContext;

    state = {
        //CONFIGURACOES
        diario_stepforma: false,
        diario: false,
        modulo_formacao_stepforma: false,
        modulo_formacao: false,
        conversas_stepforma: false,
        conversas: false,
        calendario_stepforma: false,
        calendario: false,
        relatorios_stepforma: false,
        relatorios: false,
        estatisticas_stepforma: false,
        estatisticas: false,
        eventos_stepforma: false,
        eventos: false,
        stepmeets_stepforma: false,
        stepmeets: false,
        stebinars_stepforma: false,
        stebinars: false,
        newsletter_stepforma: false,
        newsletter: false,
        stepforms_stepforma: false,
        stepforms: false,
        //DADOS HOST
        host: "",
        port: "",
        ssl: false,
        username: "",
        password: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.carregarDados();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeHost = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
                if (!this.state.host && !this.state.port && !this.state.username && !this.state.password) {
                    this.props.form.resetFields()
                }
        });
    };

    handleChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    carregarDados = () => {
        axios({
            method: "get",
            url: "/api/empresa/configs-gerais"
        })
            .then(response => {
                this.setState({
                    //CONFIGURACOES
                    diario_stepforma: response.data.diario_stepforma,
                    diario: response.data.diario,
                    modulo_formacao_stepforma: response.data.modulo_formacao_stepforma,
                    modulo_formacao: response.data.modulo_formacao,
                    conversas_stepforma: response.data.conversas_stepforma,
                    conversas: response.data.conversas,
                    calendario_stepforma: response.data.calendario_stepforma,
                    calendario: response.data.calendario,
                    relatorios_stepforma: response.data.relatorios_stepforma,
                    relatorios: response.data.relatorios,
                    estatisticas_stepforma: response.data.estatisticas_stepforma,
                    estatisticas: response.data.estatisticas,
                    eventos_stepforma: response.data.eventos_stepforma,
                    eventos: response.data.eventos,
                    stepmeets_stepforma: response.data.stepmeets_stepforma,
                    stepmeets: response.data.stepmeets,
                    stebinars_stepforma: response.data.stebinars_stepforma,
                    stebinars: response.data.stebinars,
                    newsletter_stepforma: response.data.newsletter_stepforma,
                    newsletter: response.data.newsletter,
                    stepforms_stepforma: response.data.stepforms_stepforma,
                    stepforms: response.data.stepforms,
                    //DADOS HOST
                    host: response.data.host ? response.data.host : "",
                    port: response.data.port ? response.data.port : "",
                    ssl: response.data.ssl,
                    username: response.data.username ? response.data.username : "",
                    password: response.data.password ? response.data.password : "",
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    atualizarDados = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll(["nome_abrev"], (err, values) => {
            if (!err) {

                this.setState({ iconLoading: true });

                var item = new FormData();
                if (this.state.diario_stepforma)
                    item.append("diario", this.state.diario);
                if (this.state.modulo_formacao_stepforma)
                    item.append("modulo_formacao", this.state.modulo_formacao);
                if (this.state.conversas_stepforma)
                    item.append("conversas", this.state.conversas);
                if (this.state.calendario_stepforma)
                    item.append("calendario", this.state.calendario);
                if (this.state.relatorios_stepforma)
                    item.append("relatorios", this.state.relatorios);
                if (this.state.estatisticas_stepforma)
                    item.append("estatisticas", this.state.estatisticas);
                if (this.state.eventos_stepforma)
                    item.append("eventos", this.state.eventos);
                if (this.state.stepmeets_stepforma)
                    item.append("stepmeets", this.state.stepmeets);
                if (this.state.stebinars_stepforma)
                    item.append("stebinars", this.state.stebinars);
                if (this.state.newsletter_stepforma)
                    item.append("newsletter", this.state.newsletter);
                if (this.state.stepforms_stepforma)
                    item.append("stepforms", this.state.stepforms);

                axios({
                    method: "put",
                    url: "/api/empresa/alterar-configuracoes-gerais",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.setState({
                            iconLoading: false
                        });

                        this.context.atualizarState({
                            atualizarEntidade: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    };

    atualizarDadosHost = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(["host", "port", "username", "password"],(err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("host", this.state.host);
                item.append("port", this.state.port);
                item.append("ssl", this.state.ssl);
                item.append("username", this.state.username);
                item.append("password", this.state.password);

                axios({
                    method: "put",
                    url: "/api/empresa/alterar-configuracoes-host",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.setState({
                            iconLoading: false
                        });

                        this.context.atualizarState({
                            atualizarEntidade: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    validarEstrutura = (rule, value, callback) => {
        var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (format.test(value))
            callback("Nome abreviado deve conter apenas letras e números");

        callback();
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            diario_stepforma,
            diario,
            modulo_formacao_stepforma,
            modulo_formacao,
            conversas_stepforma,
            conversas,
            calendario_stepforma,
            calendario,
            relatorios_stepforma,
            relatorios,
            estatisticas_stepforma,
            estatisticas,
            eventos_stepforma,
            eventos,
            stepmeets_stepforma,
            stepmeets,
            stebinars_stepforma,
            stebinars,
            newsletter_stepforma,
            newsletter,
            stepforms_stepforma,
            stepforms,
            //DADOS HOST
            host,
            port,
            ssl,
            username,
            password,
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-redes-sociais bloco-personalizacao">
                <Form className="personalizacao" onSubmit={this.atualizarDados}>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="calendario" checked={calendario} onChange={this.handleChangeCheckbox} disabled={!calendario_stepforma}>
                            Calendário
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="conversas" checked={conversas} onChange={this.handleChangeCheckbox} disabled={!conversas_stepforma}>
                            Conversas
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="diario" checked={diario} onChange={this.handleChangeCheckbox} disabled={!diario_stepforma}>
                            Educação
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="estatisticas" checked={estatisticas} onChange={this.handleChangeCheckbox} disabled={!estatisticas_stepforma}>
                            Estatísticas
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="eventos" checked={eventos} onChange={this.handleChangeCheckbox} disabled={!eventos_stepforma}>
                            Eventos
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="modulo_formacao" checked={modulo_formacao} onChange={this.handleChangeCheckbox} disabled={!modulo_formacao_stepforma}>
                            Formação
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="stepforms" checked={stepforms} onChange={this.handleChangeCheckbox} disabled={!stepforms_stepforma}>
                            Formulários / Stepforms
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="newsletter" checked={newsletter} onChange={this.handleChangeCheckbox} disabled={!newsletter_stepforma}>
                            Newsletters
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="relatorios" checked={relatorios} onChange={this.handleChangeCheckbox} disabled={!relatorios_stepforma}>
                           Relatórios
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="stepmeets" checked={stepmeets} onChange={this.handleChangeCheckbox} disabled={!stepmeets_stepforma}>
                            Videoconferências/ Stepmeets
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="stebinars" checked={stebinars} onChange={this.handleChangeCheckbox} disabled={!stebinars_stepforma}>
                            Webinar / Stepwebinars
                        </Checkbox>
                    </Form.Item>
                    <button type="submit" className="botao-principal" disabled={iconLoading}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                </Form>
                <Form className="personalizacao" onSubmit={this.atualizarDadosHost} style={{ marginTop: 100 }}>
                    <h3 className="titulo-separador" style={{ marginBottom: 20 }}>Dados da conta de e-mail do Canal a usar nas notificações e nas newsletters</h3>
                    <Form.Item label="Host">
                        {getFieldDecorator("host", {
                            rules: [
                                {
                                    required: (host || port || username || password),
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: host
                        })(<Input name="host" className="input-50" onChange={this.handleChangeHost} />)}
                    </Form.Item>
                    <Form.Item label="Port">
                        {getFieldDecorator("port", {
                            rules: [
                                {
                                    required: (host || port || username || password),
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: port
                        })(<Input name="port" className="input-50" onChange={this.handleChangeHost} />)}
                    </Form.Item>
                    <Form.Item>
                        <Checkbox name="ssl" className="input-50" checked={ssl} onChange={this.handleChangeCheckbox}>
                            SSL/TLS
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Conta / Email">
                        {getFieldDecorator("username", {
                            rules: [
                                {
                                    type: "email",
                                    message: "Introduza um endereço de e-mail válido"
                                },
                                {
                                    required: (host || port || username || password),
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: username
                        })(<Input name="username" className="input-50" onChange={this.handleChangeHost} />)}
                    </Form.Item>
                    <Form.Item label="Senha">
                        {getFieldDecorator("password", {
                            rules: [
                                {
                                    required: (host || port || username || password),
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: password
                        })(<Input.Password type="password" name="password" className="input-50" onChange={this.handleChangeHost} />)}
                    </Form.Item>
                    <button type="submit" className="botao-principal" disabled={iconLoading}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                </Form>
            </div>
        );
    }
}

const FormGerais = Form.create({ name: "form-gerais" })(Gerais);

export default FormGerais;
