import React, { Component } from "react";
import ConteudoResumo from "./ConteudoResumo";
import Controlos from "../_Aux/Controlos";
import { GlobalContext } from "../../GlobalState";
import LazyLoad from "react-lazy-load";

class Resumo extends Component {
    static contextType = GlobalContext;

    render() {
        const { curso, aula } = this.props.match.params;

        return (
            <div className="questionario-resumo">
                <div className="player-wrapper">
                    {/*<LazyLoad offsetVertical={200}>*/}
                    {/*    <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                    {/*</LazyLoad>*/}
                    <ConteudoResumo
                        redirectLink={`/gerir-curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${aula.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/player-questionario`}
                        redirectLinkResolucao={`/gerir-curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${aula.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/player-questionario-resolucao`}
                    />
                    <Controlos
                        backUrl={`/gerir-curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                        sameUrl={true}
                        temAnotacoes
                    />
                </div>
            </div>
        );
    }
}

export default Resumo;
