import React, { Component } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Input, InputNumber, Select, Radio, Alert } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerNotificar extends Component {
    static contextType = GlobalContext;
    state = {
        tipoNotificacao: "ALUNO",
        utilizador: "",
        //INFO
        dias: "7",
        texto: "",
        anexo: "",
        fileList: [],
        //VALIDACOES
        status: "",
        helper: "",
        totalBytes: 0,
        formatoValido: true
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeCheck = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    handleChangeNumber = dias => {
        this.setState({ dias });
    };

    handlerChangeTipoNotificacao = event => {
        var texto = "";
        if (event.target.value === "AUSENTES")
            texto = `Olá gostariamos de o ter de volta ao curso ${localStorage.getItem("nome_curso")}. \n\n ${this.props.nomeUtilizador}`;

        this.setState({
            [event.target.name]: event.target.value,
            texto
        });
    };

    envair = event => {
        event.preventDefault();

        if (this.state.tipoNotificacao === "TODOS")
            this.enviarMensagemTodos();
        else if (this.state.tipoNotificacao === "AUSENTES")
            this.enviarMensagemAusentes();
        else
            this.enviarMensagem();
    }

    //ADICIONA UMA NOVA ANOTAÇÃO
    enviarMensagem = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_utilizador", this.state.utilizador.id);
                item.append("id_curso", localStorage.getItem("codigo_curso"));
                item.append("texto", this.state.texto);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });
                item.append("responsavel", this.state.tipoNotificacao === "RESPONSAVEL");

                axios({
                    method: "post",
                    url: "/api/chat/enviar-mensagem-participante",
                    data: item
                })
                    .then(() => {
                        this.setState({
                            iconLoading: false,
                            //INFO
                            texto: "",
                            anexo: "",
                            fileList: [],
                            //VALIDACOES
                            status: "",
                            helper: "",
                            totalBytes: 0,
                            formatoValido: true
                        });
                        this.props.form.resetFields();
                        openNotificationWithIcon("success", "Sucesso", "E-mail enviado!");
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    enviarMensagemTodos = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_curso", localStorage.getItem("codigo_curso"));
                item.append("texto", this.state.texto);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "post",
                    url: "/api/chat/notificar-todos",
                    data: item
                })
                    .then(() => {
                        this.setState({
                            iconLoading: false,
                            //INFO
                            texto: "",
                            anexo: "",
                            fileList: [],
                            //VALIDACOES
                            status: "",
                            helper: "",
                            totalBytes: 0,
                            formatoValido: true
                        });
                        this.props.form.resetFields();
                        openNotificationWithIcon("success", "Sucesso", "E-mail enviado!");
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    enviarMensagemAusentes = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                let listaAusentes = [];
                this.props.participantes.map(participante => {
                    if (moment().startOf('day').diff(moment(participante.ultimoAcesso.data).startOf('day'), 'days') >= this.state.dias)
                        listaAusentes.push(participante.id);
                });

                var item = new FormData();
                item.append("id_curso", localStorage.getItem("codigo_curso"));
                item.append("dias", this.state.dias);
                item.append("texto", this.state.texto.replace(/(?:\r\n|\r|\n)/g, "<br>"));
                item.append("participantes", JSON.stringify(listaAusentes));

                axios({
                    method: "post",
                    url: "/api/gerir-curso/notificar-ausentes",
                    data: item
                })
                    .then(() => {
                        this.setState({
                            iconLoading: false,
                            //INFO
                            dias: "7",
                            texto: ""
                        });
                        this.props.form.resetFields();
                        openNotificationWithIcon("success", "Sucesso", "E-mail enviado!");
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.setState({
                tipoNotificacao: "ALUNO",
                utilizador: JSON.parse(this.props.utilizador),
                //INFO
                dias: "7",
                texto: "",
                anexo: "",
                fileList: [],
                //VALIDACOES
                status: "",
                helper: "",
                totalBytes: 0,
                formatoValido: true
            }, () => {
                if (!this.state.utilizador.notificacao)
                    this.setState({
                        tipoNotificacao: "TODOS"
                    })
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const Dragger = Upload.Dragger;
        const { Option } = Select;
        const {
            utilizador,
            dias,
            texto,
            tipoNotificacao,
            fileList,
            validateStatus,
            help,
            iconLoading
        } = this.state;
        
        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <Drawer
                placement="right"
                className="drawer-envio-mensagem-inscricao"
                maskClosable={false}
                closable={true}
                onClose={this.props.onClose}
                visible={this.props.visibleNotificar}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">

                    <Form className="form-nova-pergunta">
                        <Form.Item>
                            <Radio.Group name="tipoNotificacao" onChange={this.handlerChangeTipoNotificacao} value={tipoNotificacao}>
                                <Radio value="ALUNO" disabled={!utilizador.notificacao}>
                                    Notificar aluno
                                    <Tooltip className="info-icon" title="O aluno não pretende receber notificações por e-mail de acordo com as suas configurações">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                                {this.context.responsaveis_entidade ?
                                    <Radio value="RESPONSAVEL" disabled={!utilizador.responsavelNome && !utilizador.responsavelEmail}>
                                        Notificar responsável
                                    <Tooltip className="info-icon" title="O e-mail do responsável não está definido">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </Radio> : null}
                                <Radio value="TODOS">
                                    Notificar todos
                                    <Tooltip className="info-icon" title="A notificação apenas irá para quem tenha aceite as a receção de e-mail nas suas configurações">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                                <Radio value="AUSENTES">
                                    Notificar ausentes
                                    <Tooltip className="info-icon" title="A notificação apenas irá para quem tenha aceite as a receção de e-mail nas suas configurações">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        {tipoNotificacao !== "AUSENTES" ?
                            <>
                                {tipoNotificacao === "ALUNO" ?
                                    <div className="bloco">
                                        <h3 className="titulo-separador">Para</h3>
                                        <Form.Item>
                                            <div className="info-utilizador">
                                                <div className="container-img">
                                                    <img src={utilizador.foto ? utilizador.foto : noimage} />
                                                </div>
                                                <div className="info">
                                                    <span className="nome">{utilizador.nome}</span>
                                                    <span className="numero">{utilizador.numero}</span>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div> : null}
                                {tipoNotificacao === "RESPONSAVEL" ?
                                    <div className="bloco">
                                        <h3 className="titulo-separador">Para o responsável de</h3>
                                        <Form.Item>
                                            <div className="info-utilizador">
                                                <div className="container-img">
                                                    <img src={utilizador.foto ? utilizador.foto : noimage} />
                                                </div>
                                                <div className="info">
                                                    <span className="nome">{utilizador.nome}</span>
                                                    <span className="numero">{utilizador.numero}</span>
                                                </div>
                                            </div>
                                        </Form.Item>
                                        <h3 className="titulo-separador">Responsável</h3>
                                        <Form.Item>
                                            <div className="info-responsavel">
                                                <span className="nome">{utilizador.responsavelNome}</span>
                                                <span className="email">{utilizador.responsavelEmail}</span>
                                            </div>
                                        </Form.Item>
                                    </div> : null}
                                <div className="bloco">
                                    <Form.Item label="Mensagem">
                                        {getFieldDecorator("texto", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<TextArea rows={15} name="texto" placeholder="Mensagem..." style={{ height: "100%" }} onChange={this.handleChange} />)}
                                    </Form.Item>
                                    <Form.Item validateStatus={validateStatus} help={help}>
                                        <Dragger {...props}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexos{" "}
                                                <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    </Form.Item>
                                </div>
                            </>
                            : 
                            <div className="bloco">
                                <Form.Item label="Nº de dias sem acesso ao curso">
                                    {getFieldDecorator("dias", {
                                        rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                        }],
                                        initialValue: dias
                                    })(<InputNumber name="dias" onChange={this.handleChangeNumber} />)}
                                </Form.Item>
                                <Form.Item label="Mensagem">
                                    {getFieldDecorator("texto", {
                                        rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                        }],
                                        initialValue: texto
                                    })(<TextArea rows={15} name="texto" placeholder="Mensagem..." style={{ height: "100%" }} onChange={this.handleChange} />)}
                                </Form.Item>
                            </div>
                            }
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                                </button>
                    <button
                        className="botao-principal"
                        onClick={this.enviarMensagem}
                        disabled={iconLoading}
                    >
                        {iconLoading ? <Icon type="loading" /> : null}
                                    Enviar
                                </button>
                </div>
            </Drawer>
        );
    }
}

const FormDrawerNotificar = Form.create({ name: "form-notificar" })(DrawerNotificar);

export default withRouter(FormDrawerNotificar);
