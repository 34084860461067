import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Icon, Carousel, notification } from "antd";
import Card from "./Participantes/Participante";
import ModalViewer from "../../Componentes/Modals/ModalViewer";
import ModalLoading from "../../Componentes/Modals/ModalLoading";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const VisaoGeralDisciplina = ({ match }) => {
    const [professores, setProfessores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [disciplinas, setDisciplinas] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);

    const listarProfessores = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/pagina-entidade/lista-professores",
            params: {
                id: match.params.id
            }
        })
            .then(response => {
                setProfessores(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const listarDisciplinas = () => {
        setLoadingTable(true);
        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-disciplinas",
            params: {
                turmaId: match.params.id
            }
        })
            .then(response => {
                var disciplinas = [];

                response.data.map(disciplina => {
                    disciplinas.push({
                        key: disciplina.id,
                        id: disciplina.id,
                        disciplina: !disciplina.agrupado ? `${disciplina.codigo} - ${disciplina.nome}` : disciplina.nome,
                        nome: disciplina.nome,
                        professores: disciplina.professores,
                        opcoes: <Link className="botao-icon-detalhe" to="#" title="Descarregar PDF" onClick={() => gerarDocFicha(disciplina.id)}>
                            <Icon type="eye" />
                        </Link>
                    });
                });

                setLoadingTable(false);
                setDisciplinas(disciplinas);
            })
            .catch(error => {
                setLoadingTable(false);
            });
    };

    const gerarDocFicha = (cursoId) => {
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/ficha-curricular",
            params: {
                cursoId
            }
        })
            .then(response => {
                setDocs([{ uri: response.data }]);
                setVisibleLoading(false);
                setModalVisible(true);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível visualizar documento.");
                setVisibleLoading(false);
            });
    }

    useEffect(() => {
        listarProfessores();
        listarDisciplinas();
    }, [])

    const columns = [
        {
            title: "Disciplina",
            dataIndex: "disciplina",
            className: "td-curso"
        },
        {
            title: "Coordenadores / Professores",
            dataIndex: "professores"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    return (
        <>
            <div className="container container-body">
                {loading ? (
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                ) : (
                        <div className="turma-visao-geral">
                            <div>
                                <Row gutter={16}>
                                    <Col span={5}>
                                        <div className="bloco-v2">
                                            <div className="item">
                                                <div className="lista-professores">
                                                    <Carousel autoplay>
                                                        {professores.map(professor => (
                                                            <Card
                                                                {...professor}
                                                                key={professor.id}
                                                                perfil={professor.coordenador ? "Coordenador" : "Professor"}
                                                            />
                                                        ))}
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={19}>
                                        <div className="bloco-v2 bloco-cursos">
                                            <Table
                                                id="disciplinas"
                                                loading={{
                                                    spinning: loadingTable,
                                                    indicator: (
                                                        <div className="loading-data-table">
                                                            <div className="loading" />
                                                        </div>
                                                    )
                                                }}
                                                columns={columns}
                                                dataSource={disciplinas}
                                                pagination={false}
                                                locale={{ emptyText: "Não existem dados!" }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                    </div>
                )}
            </div>
            <ModalLoading
                visible={visibleLoading}
                text="A gerar documento..."
            />
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </>
    );
}
export default VisaoGeralDisciplina;
