import React from "react";

class JitsiLoader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="vdcLoader">
                    <div id="load-wrapper">
                        <div id="label">
                            <h2>A Iniciar Stepmeet</h2>
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                        </div>
                    </div>
                </div>
            </>)
    }
}
export default JitsiLoader;