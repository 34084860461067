import React, { useState } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Input, Select, Checkbox, Radio } from "antd";
import { GlobalContext } from "../../../../GlobalState";
import axios from "axios";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerFicha = ({ fichaId, fichaCampos, visible, onClose, atualizar, form }) => {
    const [nome, setNome] = useState("");
    const [estrutura, setEstrutura] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (event, index) => {
        let _estrutura = estrutura;
        _estrutura[index].texto = event.target.value;

        setEstrutura(_estrutura);
    }

    const listarCampos = (fichaId) => {
        axios({
            method: "get",
            url: "/api/gestao-fichas/detalhe",
            params: {
                fichaId
            }
        })
            .then(response => {
                setNome(response.data.nome);
                setEstrutura(response.data.estrutura.map(campo => {
                    return {
                        id: campo.id,
                        nome: campo.nome,
                        texto: fichaCampos?.find(x => x.id === campo.id)?.texto
                    }
                }));
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possivel carregar informação.");
                setLoading(false);
            });
    }

    const guardar = (event) => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                atualizar(estrutura.map(campo => {
                    return {
                        id: campo.id,
                        texto: campo.texto
                    }
                }))
            }
        })
    }

    const afterVisibleChange = aberto => {
        if (aberto) {
            listarCampos(fichaId);
        }
    }

    const { getFieldDecorator } = form;

    return (
        <Drawer
            title={`Ficha ${nome}`}
            placement="right"
            className="drawer-envio-mensagem-inscricao drawer-add-cursos"
            maskClosable={false}
            closable={true}
            onClose={onClose}
            visible={visible}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-nova-pergunta">
                    {estrutura.map((campo, index) => (
                        <div key={index} className="bloco">
                            <h3 className="titulo-separador">{campo.nome}</h3>
                            <Form.Item>
                                {getFieldDecorator(`texto${index}`, {
                                    initialValue: campo.texto,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(
                                    <TextArea
                                        maxLength="200"
                                        name="breveDescricao"
                                        onChange={event => handleChange(event, index)}
                                        rows={4}
                                        style={{ height: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    ))}
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button
                    className="botao-principal"
                    onClick={guardar}
                    disabled={loading}
                >
                    {loading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
        </Drawer>
    );
}

const FormDrawerFicha = Form.create({ name: "form-ficha" })(DrawerFicha);

export default withRouter(FormDrawerFicha);
