import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../../User/Header";
import { Form, Table, Modal, notification, Switch, Icon, Menu } from "antd";
import DrawerVideos from "./DrawerVideos/DrawerVideos";
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Videos = () => {
    const [ativo, setAtivo] = useState(true);
    const [videos, setVideos] = useState([]);
    const [videoId, setVideoId] = useState(0);
    const [visible, setVisible] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [loading, setLoading] = useState(false);

    const listar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "api/videos/listar",
            params: {
                ativo: ativo
            }
        })
            .then(response => {
                let videos = [];

                response.data.map(video => {
                    videos.push({
                        key: video.id,
                        id: video.id,
                        idVideo: video.idVideo,
                        estabelecimentos: video.estabelecimentos.join(", "),
                        titulo: video.titulo,
                        descricao: video.descricao,
                        opcoes: montarMenu(video.id, video.ativo)
                    });
                });

                setVideos(videos);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    };

    const montarMenu = (videoId, ativo) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (ativo)
            return (
                <div style={divStyle}>
                    <Link
                        to="#"
                        className="botao-icon-inativar"
                        title="Inativar"
                        onClick={event => {
                            event.stopPropagation();
                            ativar(videoId, ativo);
                        }}
                    >
                        <Icon type="close" />
                    </Link>
                </div>
            );
        return (
            <div style={divStyle}>
                <Link
                    to="#"
                    className="botao-icon-ativar"
                    title="Ativar"
                    onClick={event => {
                        event.stopPropagation();
                        ativar(videoId, ativo);
                    }}
                >
                    <Icon type="check" />
                </Link>
                <Link
                    to="#"
                    className="botao-icon-excluir"
                    title="Excluir"
                    onClick={event => {
                        event.stopPropagation();
                        excluir(videoId);
                    }}
                >
                    <Icon type="delete" />
                </Link>
            </div>
        );
    };
    const excluir = id => {
        confirm({
            title: "Pretende excluir esta notícia?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/videos/excluir-video",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Vídeo excluido!");
                        listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o vídeo!");
                    });
            }
        });
    };

    const ativar = (id, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar esta notícia?" : "Pretende ativar este vídeo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/videos/ativar-video",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Vídeo inativado!" : "Vídeo ativado!");
                        listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar este vídeo!" : "Não foi possível ativar este vídeo!"
                        );
                    });
            }
        });
    };

    const onRowClick = videoId => {
        setVisible(true);
        setVideoId(videoId);
    };

    useEffect(() => {
        listar(true);
    }, [ativo]);

    const columns = [
        {
            title: "Título",
            dataIndex: "titulo"
        },
        {
            title: "Descricao",
            dataIndex: "descricao",
            width: 400
        },
        {
            title: "Estabelecimentos",
            dataIndex: "estabelecimentos"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const columnsMobile = [
        {
            title: "Título",
            dataIndex: "titulo"
        },
        {
            title: "Estabelecimentos",
            dataIndex: "estabelecimentos"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <>
            <Header titulo="Vídeos" />
            <div className="administracao-empresa">
                <div className="container container-body">
                    <div style={{ minHeight: 600, background: "white" }}>
                        <div className="bloco-minha-conta">
                            <div className="bloco-gestor-paginas">
                                <div className="noticias container-body">
                                    <div className="controlos" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <button
                                            className="botao-principal botao-principal-redondo"
                                            title="Adicionar"
                                            style={{ marginTop: 0 }}
                                            onClick={() => {
                                                setVisible(true);
                                                setVideoId(0);
                                            }}
                                            disabled={!ativo}
                                        >
                                            +
                                        </button>
                                        <div className="filtros">
                                            <Switch
                                                checkedChildren="Ativos"
                                                unCheckedChildren="Inativos"
                                                defaultChecked
                                                onChange={ativo => setAtivo(ativo)}
                                            />
                                        </div>
                                    </div>
                                    <div className="bloco-tabela">
                                        <Table
                                            id="tabela_categorias"
                                            className="tabela-paginas-desktop"
                                            loading={{
                                                spinning: loading,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columns}
                                            dataSource={videos}
                                            pagination={false}
                                            rowClassName="clickeble-row"
                                            onRow={(record, index) => {
                                                return {
                                                    onClick: () => onRowClick(record.id)
                                                };
                                            }}
                                            locale={{ emptyText: "Não existem dados!" }}
                                        />
                                        <Table
                                            id="tabela_categorias"
                                            className="tabela-paginas-mobile"
                                            loading={{
                                                spinning: loading,
                                                indicator: (
                                                    <div className="loading-data-table">
                                                        <div className="loading" />
                                                    </div>
                                                )
                                            }}
                                            columns={columnsMobile}
                                            dataSource={videos}
                                            pagination={false}
                                            rowClassName="clickeble-row"
                                            onRow={(record, index) => {
                                                return {
                                                    onClick: () => onRowClick(record.id)
                                                };
                                            }}
                                            locale={{ emptyText: "Não existem dados!" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DrawerVideos
                ativo={ativo}
                videoId={videoId}
                visible={visible}
                onClose={() => setVisible(false)}
                atualizar={() => {
                    setVisible(false);
                    listar();
                }}
            />
        </>
    );
};

const FormVideos = Form.create({ name: "form-videos" })(Videos);

export default FormVideos;
