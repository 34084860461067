import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, notification, Icon, Drawer, Menu, Modal, Dropdown, Select, Table } from "antd";
import axios from "axios";
import UploadImagem from "../Unidade/UploadImagem";
import { GlobalContext } from "../../../GlobalState";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerEdificio extends Component {
    static contextType = GlobalContext;
    state = {
        escolas:[],
        escolaId: 0,
        foto: "",
        nome: "",
        descricao: "",
        link: "",
        //LOADING
        iconLoading: false
    };

    componentDidMount() {
        this.listarEscolas();
    }

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSelect = escolaId => {
        this.setState({
            escolaId
        });
    };

    listarEscolas = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos-drawer"
        })
            .then(response => {
                this.setState({
                    escolas: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    adicionar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_organismo", this.state.escolaId);
                item.append("foto", this.state.foto);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);
                item.append("link", this.state.link);

                axios({
                    method: "post",
                    url: "/api/gestao-organismos/adicionar-edificio",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        this.props.atualizar();
                        this.setState({
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.edificioId);
                item.append("foto", this.state.foto);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);
                item.append("link", this.state.link);

                axios({
                    method: "put",
                    url: "/api/gestao-organismos/alterar-edificio",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!!");
                        this.props.atualizar();
                        this.setState({
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregar = () => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-organismos/carregar-edificio",
            params: {
                edificioId: this.props.edificioId
            }
        })
            .then(response => {
                this.setState({
                    escolaId: response.data.id_organismo,
                    foto: response.data.foto,
                    nome: response.data.nome,
                    descricao: response.data.descricao ? response.data.descricao : "",
                    link: response.data.link ? response.data.link : ""
                });
            })
            .catch(() => {});
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.edificioId > 0) this.carregar();
            else
                this.setState({
                    escolaId: undefined,
                    foto: "",
                    nome: "",
                    descricao: "",
                    link: ""
                });
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            escolas,
            escolaId,
            foto,
            nome,
            descricao,
            link,
            //LOADING
            iconLoading
        } = this.state;

        const { ativo, visible, edificioId } = this.props;

        return (
            <Drawer
                className="drawer-add-cursos drawer-videoconferencias"
                width={720}
                onClose={this.props.onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        {/*<UploadImagem*/}
                        {/*    ativo={ativo}*/}
                        {/*    titulo="Foto"*/}
                        {/*    idName="input-foto"*/}
                        {/*    limite={1000}*/}
                        {/*    size="400x400px"*/}
                        {/*    imagem={foto}*/}
                        {/*    atualizarImagem={foto => this.setState({ foto })}*/}
                        {/*/>*/}
                        <Form.Item label="Estabelecimento">
                            {getFieldDecorator("escola", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: escolaId
                            })(
                                <Select placeholder="Selecionar" allowClear={true} disabled={!ativo} onChange={this.handleChangeSelect}>
                                    {escolas.map(escola => (
                                        <Option key={escola.id} value={escola.id}>
                                            {escola.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Edifício">
                            {getFieldDecorator("nome", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: nome
                            })(<Input name="nome" disabled={!ativo} onChange={this.handlerChange} />)}
                        </Form.Item>
                        <Form.Item label={<span>Descrição</span>}>
                            {getFieldDecorator("descricao", {
                                initialValue: descricao
                            })(
                                <TextArea
                                    name="descricao"
                                    disabled={!ativo}
                                    onChange={this.handlerChange}
                                    rows={10}
                                    style={{ height: 100 }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Link (Mapa)">
                            {getFieldDecorator("link", {
                                initialValue: link
                            })(<Input name="link" disabled={!ativo} onChange={this.handlerChange} />)}
                        </Form.Item>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <Link
                        to="#"
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </Link>
                    {ativo && (
                        <button className="botao-principal" disabled={iconLoading} onClick={edificioId > 0 ? this.alterar : this.adicionar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Salvar
                        </button>
                    )}
                </div>
            </Drawer>
        );
    }
}

const FormDrawerEdificio = Form.create({ name: "form-drawer-edificio" })(DrawerEdificio);

export default FormDrawerEdificio;
