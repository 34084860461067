import React, { Component } from "react";
import Indice from "./_Aux/IndiceVersoes";
import Artigo from "./_Aux/Artigo";
import Header from "./Header";
import axios from "axios";

class Suporte extends Component {
    state = {
        indices: [],
        artigos: [],
        pergunta: "",
        resposta: "",
        indexAtivo: 0
    };

    UNSAFE_componentWillMount() {
        this.listarTemas();
    }

    listarTemas = () => {
        axios({
            method: "get",
            url: "/api/home-utilizador/listar-versoes-temas"
        })
            .then(response => {
                var _indice = [];

                response.data.map((indice, index) =>
                    _indice.push({
                        id: indice.id,
                        tema: indice.tema,
                        classAtivo: index === 0 ? "ativo" : ""
                    })
                );

                this.setState({
                    indices: _indice
                });

                if (response.data.length > 0) this.listarRespostas(response.data[0].tema, response.data[0].id);
            })
            .catch(() => {});
    };

    listarRespostas = (indice, temaid) => {
        axios({
            method: "get",
            url: "/api/home-utilizador/listar-versoes-respostas",
            params: {
                temaid: temaid
            }
        })
            .then(response => {
                var _indices = this.state.indices;
                _indices[this.state.indexAtivo].classAtivo = "";

                var newIndexAtivo = _indices.findIndex(x => x.id === temaid);
                _indices[newIndexAtivo].classAtivo = "ativo";

                this.setState({
                    artigos: response.data,
                    indexAtivo: newIndexAtivo,
                    indices: _indices
                });
            })
            .catch(() => {});
    };

    render() {
        const { indices, artigos } = this.state;

        return (
            <>
                <div className="suporte">
                    <Header titulo="Versões" />
                    <div className="container container-body">
                        <div className="content bloco-grid">
                            <div className="indice">
                                <ul>
                                    {indices.map((indice, index) => (
                                        <Indice
                                            key={index}
                                            indice_ativo={indice.classAtivo}
                                            titulo_indice={indice.tema}
                                            url="#"
                                            onClick={() => this.listarRespostas(indice.tema, indice.id)}
                                        />
                                    ))}
                                </ul>
                            </div>
                            <div className="artigo">
                                {artigos.map((artigo, index) => (
                                    <Artigo key={index} dados={artigo} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Suporte;
