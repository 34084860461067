import React, { Component } from "react";
import { Link } from "react-router-dom";

class Controlos extends Component {
    render() {
        return (
            <div className="bloco-controlos">
                <div className="controlos">
                    <div className="container-btn">
                        <Link to="/user">
                            <i className="fas fa-chevron-left" title="Voltar" />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Controlos;
