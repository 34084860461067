import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Modal, Input } from "antd";
import Anotacao from "./Anotacao";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerSolicitarReabertura extends Component {
    static contextType = GlobalContext;
    state = {
        motivo: "",
        //LOADING
        iconLoading: false
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    solicitar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                })
                axios({
                    method: "post",
                    url: "/api/player/solicitar-reabertura",
                    params: {
                        aulaid: localStorage.getItem("codigo_aula"),
                        motivo: this.state.motivo
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Reabertura solicitada!");
                        this.setState({ iconLoading: false });
                        this.props.fecharSolicitar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível solicitar reabertura!");
                        this.setState({ iconLoading: false });
                    });
            }
        })
    }

    afterVisibleChange = aberto => {
        if (aberto)
            this.props.form.resetFields();
            this.setState({
                motivo: "",
                //LOADING
                iconLoading: false
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            motivo,
            //LOADING
            iconLoading
        } = this.state;

        return (
            <>
                <Drawer
                    title="Solicitar Reabertura"
                    placement="right"
                    maskClosable={false}
                    closable={true}
                    onClose={this.props.fecharSolicitar}
                    visible={this.props.drawerSolicitar}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="drawer-solicitar-reabertura">
                        <Form className="form-nova-pergunta">
                            <Form.Item label="Indique o motivo">
                                {getFieldDecorator("motivo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: motivo
                                })(<TextArea name="motivo" onChange={this.handleChange} style={{ height: "100%" }} rows={10} />)}
                                <span className="nota-info">A resposta ao pedido de reabertura será enviada para as Conversas e por e-mail.</span>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.props.fecharSolicitar} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.solicitar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Enviar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerSolicitarReabertura = Form.create({ name: "opcao-solicitar-reabertura" })(DrawerSolicitarReabertura);

export default withRouter(FormDrawerSolicitarReabertura);
