import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Checkbox, Modal, notification } from "antd";
import Header from "../User/Header";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../GlobalState";

import { cancelLink } from "../Utilities/cancelRedirect"

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Configuracoes extends Component {
    static contextType = GlobalContext;
    state = {
        editar: false,
        termos: false,
        dt_termos: "",
        politica: false,
        dt_politica: "",
        newsletterEntidade: false,
        dt_newsletterEntidade: "",
        newsletterStepforma: false,
        dt_newsletterStepforma: "",
        notificacao: false,
        dt_notificacao: "",
        temp_termos: false,
        temp_politica: false,
        temp_newsletterEntidade: false,
        temp_newsletterStepforma: false,
        temp_notificacao: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    componentDidMount() {
        this.obterDados();
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.checked });

        if (event.target.name === "comercial_campanhas_mensagens" && !event.target.checked)
            this.setState({
                comercial_campanhas: false
            });
    };

    obterDados = () => {
        axios({
            method: "get",
            url: "/api/privacidade"
        })
            .then(response => {
                if (response.data)
                    this.setState({
                        termos: response.data.termos,
                        dt_termos: response.data.dt_termos ? response.data.dt_termos : "",
                        politica: response.data.politica,
                        dt_politica: response.data.dt_politica ? response.data.dt_politica : "",
                        newsletterEntidade: response.data.newsletterEntidade,
                        dt_newsletterEntidade: response.data.dt_newsletterEntidade ? response.data.dt_newsletterEntidade : "",
                        newsletterStepforma: response.data.newsletterStepforma,
                        dt_newsletterStepforma: response.data.dt_newsletterStepforma ? response.data.dt_newsletterStepforma : "",
                        notificacao: response.data.notificacao,
                        dt_notificacao: response.data.dt_notificacao ? response.data.dt_notificacao : "",
                        temp_termos: response.data.termos,
                        temp_politica: response.data.politica,
                        temp_newsletterEntidade: response.data.newsletterEntidade,
                        temp_newsletterStepforma: response.data.newsletterStepforma,
                        temp_notificacao: response.data.notificacao
                    });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    atualizarDados = event => {
        event.preventDefault();

        var item = new FormData();
        item.append("termos", this.state.termos);
        item.append("politica", this.state.politica);
        item.append("newsletterEntidade", this.state.newsletterEntidade);
        item.append("newsletterStepforma", this.state.newsletterStepforma);
        item.append("notificacao", this.state.notificacao);

        axios({
            method: "post",
            url: "/api/privacidade",
            data: item
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Configurações atualizadas!");
                this.obterDados();
                this.setState({ editar: false });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível atualizar as configurações!");
                this.setState({ editar: false });
            });
    };

    cancelar = () => {
        this.setState({
            editar: false,
            termos: this.state.temp_termos,
            politica: this.state.temp_politica,
            newsletterEntidade: this.state.temp_newsletterEntidade,
            newsletterStepforma: this.state.temp_newsletterStepforma,
            notificacao: this.state.temp_notificacao
        });
    };

    redirectUser = () => {
        const redirectLink = cancelLink(this.context.perfil?.id);

        this.setState({
            redirect: true,
            redirectLink
        })
    }

    render() {
        const {
            termos,
            dt_termos,
            politica,
            dt_politica,
            newsletterEntidade,
            dt_newsletterEntidade,
            newsletterStepforma,
            dt_newsletterStepforma,
            notificacao,
            dt_notificacao,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                {/* <Navbar /> */}
                <Header titulo="Configurações" />
                <div className="container container-body">
                    <div className="bloco-privacidade">
                        {!this.state.editar ? (
                            <div className="bloco-info">
                                {termos ? (
                                    <span>
                                        <i className="fa fa-check aceite"></i>
                                        <Link to="/termos-uso">Termos de Uso </Link> aceites a{" "}
                                        {dt_termos && moment(dt_termos).format("DD/MM/YYYY")}
                                    </span>
                                ) : (
                                    <span>
                                        <i className="fa fa-times por-aceitar"></i>
                                        <Link to="/termos-uso">Termos de Uso</Link> não aceites!
                                    </span>
                                )}
                                {politica ? (
                                    <span>
                                        <i className="fa fa-check aceite"></i>
                                        <Link to="/politica-privacidade">Política de Privacidade</Link> aceite a{" "}
                                        {dt_politica && moment(dt_politica).format("DD/MM/YYYY")}
                                    </span>
                                ) : (
                                    <span>
                                        <i className="fa fa-times por-aceitar"></i>
                                        <Link to="/politica-privacidade">Política de Privacidade</Link> não aceite!
                                    </span>
                                )}
                                {this.context.newsletter &&
                                    <>
                                        {newsletterEntidade ? (
                                            <span>
                                                <i className="fa fa-check aceite"></i>
                                                Newsletter {this.context.nome_entidade} subscrita a{" "}
                                                {dt_newsletterEntidade && moment(dt_newsletterEntidade).format("DD/MM/YYYY")}
                                            </span>
                                        ) : (
                                            <span>
                                                <i className="fa fa-times por-aceitar"></i>
                                                Newsletter {this.context.nome_entidade} não subscrita</span>
                                        )}
                                        {newsletterStepforma ? (
                                            <span>
                                                <i className="fa fa-check aceite"></i>
                                                Newsletter StepForma subscrita a{" "}
                                                {dt_newsletterStepforma && moment(dt_newsletterStepforma).format("DD/MM/YYYY")}
                                            </span>
                                        ) : (
                                            <span>
                                                <i className="fa fa-times por-aceitar"></i>
                                                Newsletter StepForma não subscrita</span>
                                        )}
                                    </>
                                }
                                {notificacao ? (
                                    <span>
                                        <i className="fa fa-check aceite"></i>
                                        Notificações para o meu endereço de e-mail aceites a{" "}
                                        {dt_notificacao && moment(dt_notificacao).format("DD/MM/YYYY")}
                                    </span>
                                ) : (
                                    <span style={{ marginLeft: 26 }}>Notificações para o meu e-mail inativas</span>
                                )}
                                <div className="controlos" style={{ display: "flex", gap: 10 }}>
                                    <button
                                        className="botao-principal"
                                        onClick={() => {
                                            this.setState({ editar: true });
                                            //this.context.logOperacao("Editar");
                                        }}
                                    >
                                        Alterar
                                    </button>
                                    <button className="botao-secundario" onClick={this.redirectUser}>
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="bloco-info-editar">
                                <Form className="privacidade">
                                    <Form.Item>
                                        <Checkbox name="termos" disabled checked={termos} onChange={this.handleChange}>
                                            <span className="agreement-msg">
                                                Eu aceito os <Link to="/termos-uso">Termos de Uso</Link>
                                            </span>
                                        </Checkbox>
                                        <Checkbox name="politica" disabled checked={politica} onChange={this.handleChange}>
                                            <span className="agreement-msg">
                                                Eu aceito a <Link to="/politica-privacidade">Política de Privacidade</Link>
                                            </span>
                                        </Checkbox>
                                        {this.context.newsletter &&
                                            <>
                                                <Checkbox name="newsletterEntidade" checked={newsletterEntidade} onChange={this.handleChange}>
                                                    <span className="agreement-msg">
                                                        Eu pretendo subscrever a newsletter {this.context.nome_entidade}
                                                    </span>
                                                </Checkbox>
                                                <Checkbox name="newsletterStepforma" checked={newsletterStepforma} onChange={this.handleChange}>
                                                    <span className="agreement-msg">Eu pretendo subscrever a newsletter StepForma</span>
                                                </Checkbox>
                                            </>
                                        }
                                        <Checkbox name="notificacao" checked={notificacao} onChange={this.handleChange}>
                                            <span className="agreement-msg">Eu aceito receber as notificações por e-mail</span>
                                        </Checkbox>
                                    </Form.Item>
                                    <button
                                        className="botao-principal"
                                        onClick={e => {
                                            this.atualizarDados(e);
                                            //this.context.logOperacao("Guardar");
                                        }}
                                    >
                                            Salvar
                                    </button>
                                    <button
                                        className="botao-secundario"
                                        onClick={() => {
                                            this.cancelar();
                                            //this.context.logOperacao("Cancelar");
                                        }}
                                    >
                                        Cancelar
                                    </button>
                                </Form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
const FormConfiguracoes = Form.create({ name: "configuracoes" })(Configuracoes);

export default FormConfiguracoes;
