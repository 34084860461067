import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import screenfull from "screenfull";

class Controlos extends Component {
    state = {
        fullscreen: false
    };

    onClickFullscreen = () => {
        this.setState({ fullscreen: !this.state.fullscreen });
        screenfull.toggle();
    };

    render() {
        return (
            <div className="bloco-controlos">
                <div className="controlos">
                    <div className="container-btn">
                        <Link to={localStorage.getItem("player_back_url")} title="Voltar">
                            <i className="fas fa-chevron-left" />
                        </Link>
                    </div>
                    <div className="container-btn">
                        <Link to="#" onClick={this.onClickFullscreen} title={this.context.fullscreen ? "Sair de ecrã inteiro" : "Ecrã inteiro"}>
                            {this.state.fullscreen ? <i className="fas fa-compress" /> : <i className="fas fa-expand" />}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Controlos);
