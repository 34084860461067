import React, { Component } from "react";
import { Form, Upload, Input, notification, Icon, Tooltip, Checkbox } from "antd";
import { Link, matchPath, Redirect } from "react-router-dom";
import Header from "../User/Header";
import axios from "axios";
import { GlobalContext } from "../GlobalState";

const Dragger = Upload.Dragger;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class NovaMensagem extends Component {
    static contextType = GlobalContext;
    state = {
        responsavel: false,
        destinatario: "",
        info: "",
        mensagem: "",
        fileList: [],
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    componentDidMount() {
        switch (this.props.match.params.tipo) {
            case "todos":
                this.setState({
                    destinatario: "Todos os Participantes",
                    info: "Todos exceto Professores e Gestores"
                });
                break;
            case "instrutores":
                this.setState({
                    destinatario: "Todos os Professores"
                });
                break;
            case "gestores":
                this.setState({
                    destinatario: "Todos os Gestores"
                });
                break;
            default:
                this.setState({
                    utilizador: JSON.parse(localStorage.getItem("sis_utilizador")),
                    destinatario: JSON.parse(localStorage.getItem("sis_utilizador")).nome,
                    responsavel: JSON.parse(localStorage.getItem("sis_utilizador")).responsavel
                });
                break;
        }
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated || this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR") {
            if (!this.context.is_authenticated)
                this.setState({
                    redirect: true,
                    redirectLink: "/login"
                });
            //else if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
            //    this.setState({
            //        redirect: true,
            //        redirectLink: "/user"
            //    });
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    //ENVIA A NOVA MENSAGEM
    novaMensagem = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                let item = new FormData();
                if (this.props.match.params.tipo === "todos" ||
                    this.props.match.params.tipo === "instrutores" ||
                    this.props.match.params.tipo === "gestores")
                    item.append("tipo", this.props.match.params.tipo);
                else
                    item.append("id_utilizador", this.state.utilizador.id);
                item.append("mensagem", this.state.mensagem);
                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });
                item.append("responsavel", this.state.responsavel !== "");

                axios({
                    method: "post",
                    url: "/api/gestao-chat/enviar-mensagem",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Mensagem enviada!");
                        this.setState({
                            redirect: true,
                            redirectLink: "/gestao-utilizadores-todos",
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = formato => {
        const formatos = [
            "zip",
            "rar",
            "7z",
            "docx",
            "doc",
            "pdf",
            "odf",
            "txt",
            "rtf",
            "xlsx",
            "xls",
            "csv",
            "ods",
            "pptx",
            "ppt",
            "odp",
            "bmp",
            "gif",
            "png",
            "jpeg",
            "jpg"
        ];
        let valido = false;
        for (let i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    render() {
        const {
            destinatario,
            responsavel,
            info,
            fileList,
            validateStatus,
            help,
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        if (redirect) return <Redirect to={redirectLink} />;

        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                let tamanhoTotal = 0;
                let ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    let tamanhoTotal = 0;
                    let ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <div>
                <Header titulo="Nova Mensagem" />
                <div className="container">
                    <div className="curso-forum" style={{ marginTop: 0 }}>
                        <div className="bloco-nova-pergunta-users">
                            <div className="bloco-info">
                                <div className="bloco-titulo-nova-mensagem">
                                    {responsavel ?
                                        <h2>
                                            {`Para ${responsavel} (responsável por ${destinatario})`}
                                        </h2>
                                        :
                                        <h2>
                                            {`Para ${destinatario}`}
                                        </h2>
                                    }
                                    <span>{info}</span>
                                </div>
                                <Form className="form-nova-pergunta">
                                    <Form.Item label="Mensagem">
                                        {getFieldDecorator("descricao", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                    whitespace: true
                                                }
                                            ]
                                        })(<TextArea name="mensagem" onChange={this.handleChange} style={{ height: "100%" }} rows={15} />)}
                                    </Form.Item>
                                    <Form.Item validateStatus={validateStatus} help={help}>
                                        {
                                            <Dragger {...props}>
                                                <p className="ant-upload-drag-icon">
                                                    <i className="fas fa-upload" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Adicionar anexos{" "}
                                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </p>
                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                            </Dragger>
                                        }
                                    </Form.Item>
                                    <div className="form-controlos">
                                        <Link to="/gestao-utilizadores-todos" className="botao-secundario" style={{ marginRight: 20 }}>
                                            Voltar
                                        </Link>
                                        <button className="botao-principal" onClick={e => this.novaMensagem(e)} disabled={iconLoading}>
                                            {iconLoading ? <Icon type="loading" /> : null}
                                            <span>Enviar</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const FormNovaMensagem = Form.create({ name: "form-nova-mensagem" })(NovaMensagem);

export default FormNovaMensagem;
