import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Link as LinkScroll, animateScroll as scroll } from 'react-scroll';
import { Icon, notification, Input, Dropdown } from "antd";

import logo from "../../images/logos/logo_stepforma.svg";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import logoSF from "../../images/logos/Logo sem nome.png";

const { Search } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false)
    const [entidades, setEntidades] = useState([])
    const [entidadesCombo, setEntidadesCombo] = useState([])

    const context = useContext(GlobalContext);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    const pesquisarEntidades = pesquisa => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/pesquisar-entidade",
            params: {
                pesquisa
            }
        })
            .then(response => {
                setEntidades(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível procurar canal!");
            });
    };

    const onKeyDown = event => {
        if (event.keyCode === 27) {
            context.atualizarState({
                search_entidade: false
            });
        }
    };

    const carregarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/carregar-estabelecimentos-combo"
        })
            .then(response => {
                setEntidadesCombo(response.data);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar canais!");
            });
    }

    useEffect(() => {
        carregarEstabelecimentos();
        context.atualizarState({
            search_entidade: false
        });
    }, [])

    const subOptions =
        (
            <ul>
                {entidadesCombo.map(entidade => (
                    <li key={entidade.id}>
                        <Link
                            to={
                            entidade.nome_abrev
                                ? entidade.dominio
                                    ? `https://${entidade.dominio}/${entidade.nome_abrev}`
                                    : `/${entidade.nome_abrev}`
                                : `https://${entidade.dominio}`
                        }
                        >
                            {entidade.nome}
                        </Link>
                    </li>
                ))}
            </ul>
        ) ?? null;

    return (
        <>
            <nav className="navbar">
                <div className="container-menu">
                    <div className="logo">
                        <Link to="/">
                            <img
                                src={logo}
                                alt="Logo"
                            />
                        </Link>
                    </div>
                    <div className={`nav-elements  ${showNavbar && 'active'}`}>
                        <ul>
                            <li>
                                <NavLink to="#" onClick={!showNavbar ? () => scroll.scrollToTop() : () => { scroll.scrollToTop(); handleShowNavbar(); } }>Home</NavLink>
                            </li>
                            {/*<li>*/}
                            {/*    <NavLink to="#">Serviços</NavLink>*/}
                            {/*</li>*/}
                            <li>
                                <NavLink to="#" onClick={!showNavbar ? () => scroll.scrollToBottom() : () => { scroll.scrollToBottom(); handleShowNavbar(); }}>Contato</NavLink>
                            </li>
                            <li>
                                <Dropdown overlay={subOptions} overlayClassName="navbar-dropdown-menu">
                                    <NavLink to="#" className="dropdown-menu">
                                        Canais
                                        <Icon type="down" />
                                    </NavLink>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                    <div className="search-block">
                        <Link to="#" onClick={() => context.atualizarState({ search_entidade: true })}>
                            <Icon type="search" />
                        </Link>
                    </div>
                    <div className="search-block search-block-mobile">
                        <Link to="#" onClick={() => context.atualizarState({ search_entidade: true })}>
                            <Icon type="search" />
                        </Link>
                        <div className="menu-icon" onClick={handleShowNavbar}>
                            <Icon type="menu" />
                        </div>
                    </div>
                </div>
            </nav>
            {context.search_entidade && (
                <div className="search-fullscreen" onKeyDown={onKeyDown}>
                    <div className="container">
                        <Icon
                            type="close"
                            className="close-search"
                            onClick={() => context.atualizarState({ search_entidade: false })}
                        />
                        <Search
                            placeholder="Procurar Canal"
                            name="inputPesquisa"
                            autoComplete="off"
                            onSearch={pesquisarEntidades}
                        />
                        <div className="listagem-entidades">
                            {entidades.map((entidade, index) => (
                                <Link
                                    key={index}
                                    to={
                                        entidade.nome_abrev
                                            ? entidade.dominio
                                                ? `https://${entidade.dominio}/${entidade.nome_abrev}`
                                                : `/${entidade.nome_abrev}`
                                            : `https://${entidade.dominio}`
                                    }
                                    className="entidade"
                                >
                                    <div className="container-img">
                                        <img src={entidade.logo ? entidade.logo : logoSF} />
                                    </div>
                                    <span className="nome">{entidade.nome}</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Navbar
