import React, { Component } from "react";
import { notification } from "antd";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { GlobalContext } from "../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class LoginAuto extends Component {
    static contextType = GlobalContext;
    state = {
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.login();
    }

    login = () => {
        if (this.getParameterByName("code"))
            axios({
                method: "post",
                url: "/api/autenticacaonew/login-auto",
                params: {
                    code: this.getParameterByName("code")
                }
            })
                .then(response => {
                    this.context.atualizarState({
                        atualizarEntidadeFooter: true,
                        is_authenticated: true,
                        is_authenticated_nav: true,
                        role: response.data
                    });
                    this.setState({
                        redirect: true,
                        redirectLink: "/user"
                    });
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    window.history.back();
                });
        else
            this.setState({
                redirect: true,
                redirectLink: "/"
            });
    };

    getParameterByName = name => {
        const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1]);
    };

    render() {
        if (this.state.redirect) return <Redirect to={this.state.redirectLink} />;

        return (
            <div className="bloco-redirecionar">
                <div>
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                    <div className="texto-redirecionar">
                        <h2>A redirecionar...</h2>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginAuto;
