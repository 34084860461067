import React, { useState, useContext, useEffect } from "react";
import { Icon, Menu, Dropdown, Select, notification } from 'antd';
import DadosGerais from "./User/DadosGerais/DadosGerais";
import Educacao from "./User/Educacao/Educacao";
import Aproveitamento from "./User/Aproveitamento/Aproveitamento";
import Assiduidade from "./User/Assiduidade/Assiduidade";
import Matriculas from "./User/Historico/Matriculas/Matriculas";
import Percurso from "./User/Historico/Percurso/Percurso";
import Estados from "./User/Historico/Estados/Estados";
import Estatutos from "./User/Historico/Estatutos/Estatutos";
import Outros from "./User/Historico/Outros/Outros";
import Formacao from "./User/Formacao/Formacao";
import Documentos from "./User/Documentos/Documentos";
import Pagamentos from "./User/Pagamentos/Pagamentos";
//COORDENADOR
//import EducacaoCoordenador from "./User/Coordenador/Educacao/Educacao";
//import FormacaoCoordenador from "./User/Coordenador/Formacao/Formacao";
//COORDENADOR E PROFESSOR
import EducacaoCoordProf from "./User/Professor/Educacao/Educacao";
import FormacaoCoordenador from "./User/Coordenador/Formacao/Formacao";
import FormacaoProfessor from "./User/Professor/Formacao/Formacao";
//CONTEXT
import { GlobalContext } from "../GlobalState";
import axios from "axios";


const { SubMenu } = Menu;

const UserLoggedInDetalhe = ({ match }) => {
    const globalContext = useContext(GlobalContext);

    const listaAcessosEducacao = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];
    const listaAcessosFormacao = [201, 202, 203, 204, 205];

    const [menuOptionSelected, setMenuOptionSelected] = useState({})

    const handleClick = e => {
        setMenuOptionSelected({
            menuItemKey: e.keyPath[0],
            menuItemName: e.item.props.children, 
        })
    };

    const abrirOpcaoMenu = () => {
        const perfisComOpcoes = ["Coordenador", "Professor", "Aluno"];
        const perfilId = globalContext.perfil.id;
        let page = "";
        if (perfisComOpcoes.includes(globalContext.perfil.nome)) {
            if (globalContext.modulo_formacao && globalContext.modulo_formacao_stepforma && !(globalContext.diario_entidade && globalContext.diario_stepforma)) {
                if (perfilId === 6) {
                    page = "3";
                } else if (perfilId === 7) {
                    page = "6";
                } else {
                    page = "1";
                }
            }
            else if (globalContext.diario_entidade && globalContext.diario_stepforma) {
                page = "2";
            }
            else {
                page = "1";
            }
        }
        else {
            page = "1";
        }

        setMenuOptionSelected({
            menuItemKey: page
        })
    }

    const buildPage = () => {
        var key = menuOptionSelected.menuItemKey;

        if (globalContext.perfil?.nome == "Suporte") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
        }

        if (globalContext.perfil?.nome == "Gestor") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
        }

        if (globalContext.perfil?.nome == "Administrativo") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
        }

        if (globalContext.perfil?.nome == "Diretor") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
        }

        if (globalContext.perfil?.nome === "Coordenador") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
            if (key === "2")
                return <EducacaoCoordProf menuOptionSelected={menuOptionSelected} />;
            if (key === "3")
                return <FormacaoCoordenador menuOptionSelected={menuOptionSelected} />;
            if (key === "4")
                return <Documentos menuOptionSelected={menuOptionSelected} />;
            if (key === "5")
                return <Pagamentos menuOptionSelected={menuOptionSelected} />;
        }

        if (globalContext.perfil?.nome === "Professor") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
            if (key === "2")
                return <EducacaoCoordProf menuOptionSelected={menuOptionSelected} />;
            if (key === "3")
                return <FormacaoProfessor menuOptionSelected={menuOptionSelected} />;
            if (key === "4")
                return <Documentos menuOptionSelected={menuOptionSelected} />;
            if (key === "5")
                return <Pagamentos menuOptionSelected={menuOptionSelected} />;
        }

        if (globalContext.perfil?.nome === "Aluno") {
            if (key === "1")
                return <DadosGerais menuOptionSelected={menuOptionSelected} />;
            if (key === "2")
                return <Educacao menuOptionSelected={menuOptionSelected} />;
            if (key === "3")
                return <Aproveitamento menuOptionSelected={menuOptionSelected} />;
            if (key === "4")
                return <Assiduidade menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-1")
                return <Matriculas menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-3")
                return <Percurso menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-4")
                return <Estados menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-5")
                return <Estatutos menuOptionSelected={menuOptionSelected} />;
            if (key === "sub5-6")
                return <Outros menuOptionSelected={menuOptionSelected} />;
            if (key === "6")
                return <Formacao menuOptionSelected={menuOptionSelected} />;
            if (key === "7")
                return <Documentos menuOptionSelected={menuOptionSelected} />;
            if (key === "8")
                return <Pagamentos menuOptionSelected={menuOptionSelected} />;
        }
    }

    useEffect(() => {
        if (globalContext.perfil?.id)
            abrirOpcaoMenu();
    }, [globalContext.perfil])

    const acessos = globalContext.acessos;
    const acessosEducacao = acessos?.find(x => listaAcessosEducacao.includes(x.func));
    const acessosFormacao = acessos?.find(x => listaAcessosFormacao.includes(x.func));

    return (
        <div className="details-page" style={{ marginTop: 80 }}>
            <div className="menu">
                {globalContext.perfil?.nome === "Suporte" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                    </Menu>}
                {globalContext.perfil?.nome === "Gestor" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                    </Menu>}
                {globalContext.perfil?.nome === "Administrativo" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                    </Menu>}
                {globalContext.perfil?.nome === "Diretor" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                    </Menu>}
                {(globalContext.perfil?.nome === "Coordenador" || globalContext.perfil?.nome === "Professor") &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                        {(globalContext.diario_entidade && globalContext.diario_stepforma && acessosEducacao) ?
                                <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} /> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma && acessosEducacao) ?
                            <Menu.Item key="2">
                                Educação
                            </Menu.Item> : null}
                        {(globalContext.modulo_formacao && globalContext.modulo_formacao_stepforma && acessosFormacao) ?
                            <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} /> : null}
                        {(globalContext.modulo_formacao && globalContext.modulo_formacao_stepforma && acessosFormacao) ?
                            <Menu.Item key="3">
                                Formação
                            </Menu.Item> : null}
                        <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} />
                        <Menu.Item key="4">
                            Documentação
                        </Menu.Item>
                        {/*<Menu.Item key="5">*/}
                        {/*    Pagamentos*/}
                        {/*</Menu.Item>*/}
                    </Menu>}
                {globalContext.perfil?.nome === "Aluno" &&
                    <Menu
                        selectedKeys={[menuOptionSelected.menuItemKey]}
                        onClick={handleClick}
                        style={{ width: 256 }}
                        mode="inline"
                    >
                        <Menu.Item key="1">
                            Dados Gerais
                        </Menu.Item>
                        {(globalContext.diario_entidade && globalContext.diario_stepforma) ?
                            <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} /> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma) ?
                            <Menu.Item key="2">
                                Educação
                            </Menu.Item> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma) ?
                            <Menu.Item key="3">
                                Aproveitamento
                            </Menu.Item> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma) ?
                            <Menu.Item key="4">
                                Assiduidade
                            </Menu.Item> : null}
                        {(globalContext.diario_entidade && globalContext.diario_stepforma) ?
                            <SubMenu key="sub5" title="Histórico">
                                <Menu.Item key="sub5-1">Matrículas</Menu.Item>
                                <Menu.Item key="sub5-2">Inscrições</Menu.Item>
                                <Menu.Item key="sub5-3">Percurso</Menu.Item>
                                <Menu.Item key="sub5-4">Estados</Menu.Item>
                                <Menu.Item key="sub5-5">Estatutos</Menu.Item>
                                <Menu.Item key="sub5-6">Outros</Menu.Item>
                            </SubMenu> : null}
                        {(globalContext.modulo_formacao && globalContext.modulo_formacao_stepforma) ?
                            <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} /> : null}
                        {(globalContext.modulo_formacao && globalContext.modulo_formacao_stepforma) ?
                            <Menu.Item key="6">
                                Formação
                            </Menu.Item> : null}
                        <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.04)" }} />
                        <Menu.Item key="7">
                            Documentação
                        </Menu.Item>
                        <Menu.Item key="8">
                            Pagamentos
                        </Menu.Item>
                    </Menu>}
            </div>
            {buildPage()}
        </div>
    );
}

export default UserLoggedInDetalhe;