import React, { Component } from "react";
import { Link } from "react-router-dom"
import { notification, Drawer, Icon } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerRelatorio extends Component {
    state = {
        nome: "",
        texto: "",
        ficheiro: "",
        ficheiroNome: "",
        //LOADING
        iconLoading: false,
        inscricao: ""
    };

    carregar = (relatorioId, utilizadorId) => {
        axios({
            method: "get",
            url: "/api/consultar-resultados/carregar-relatorio-progresso",
            params: {
                relatorioId,
                utilizadorId
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    texto: response.data.texto,
                    ficheiro: response.data.ficheiro,
                    ficheiroNome: response.data.ficheiro_nome,
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar relatório de progresso");
            });
    };

    downloadFicheiro = () => {
        axios({
            method: "post",
            url: "/api/consultar-resultados/download-relatorio",
            params: {
                relatorioId: this.props.relatorioId,
                utilizadorId: this.props.utilizadorId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.carregar(this.props.relatorioId, this.props.utilizadorId);
        }
    }

    render() {
        const {
            nome,
            texto,
            ficheiro,
            ficheiroNome,
        } = this.state;
        const {
            visibleDrawerRelatorio
        } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos"
                    title={nome}
                    width={720}
                    onClose={this.props.onClose}
                    visible={visibleDrawerRelatorio}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <div className="bloco">
                            <div className="campo">
                                <label className="titulo">Texto</label>
                                <span>{texto}</span>
                            </div>
                            {ficheiro ?
                                <>
                                    <h3 className="titulo-separador">Anexos</h3>
                                    <div className="lista-videos">
                                        <div className="item-video">
                                            <span className="video-titulo">{ficheiroNome}</span>
                                            <div className="video-controlos">
                                                <Link to="#" className="botao-icon-download" title="Descarregar" onClick={this.downloadFicheiro}>
                                                    <Icon type="download" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}
                        </div>
                        <div className="ant-drawer-footer">
                            <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                                Voltar
                            </button>
                        </div>
                    </div>
                </Drawer>
            </>
        );
    }
}

export default DrawerRelatorio;
