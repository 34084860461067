import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { notification, Form, Icon } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../GlobalState";
import LazyLoad from "react-lazy-load";
import Oops from "../Geral/Oops.jsx";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Download extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        nome: "",
        objetivos: "",
        aulaIndex: 0,
        fullscreen: false,
        ficheiro: "",
        ficheiro_nome: "",
        current: "",
        updateModulos: false,
        dataHora: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        //NAO DISPONIVEL
        nao_disponivel: false,
        //LOADING
        loading: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/gestao-player/conteudo2",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    ficheiro: response.data.recurso,
                    ficheiro_nome: response.data.recurso_nome,
                    dataHora: moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"),
                    loading: false
                });
            })
            .catch(error => {
                //if (error.response.data === "SEM_PERMISSAO")
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/user"
                //    });
                //else
                    if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    nao_disponivel: true
                });
            });
    };

    downloadFicheiro = (documentoId) => {
        axios({
            method: "post",
            url: "/api/gestao-player/download-ficheiro",
            params: {
                documentoId,
                cursoId: localStorage.getItem("codigo_curso"),
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    render() {
        const {
            curso,
            nome,
            objetivos,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
                <div className="questionario">
                    <div className="player-wrapper">
                        {/*<LazyLoad offsetVertical={200}>*/}
                        {/*    <img src={require("../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                        {/*</LazyLoad>*/}
                        {!nao_disponivel ? (
                            <>
                                <div className="container">
                                    <div className="bloco-presencial">
                                        <div className="bloco-download-link">
                                            <div className="bloco-info-tarefa">
                                                <span className="icon-tarefa">
                                                    <img src={require("../images/icons/download.png")} />
                                                </span>
                                                <span className="titulo-tarefa">{nome}</span>
                                            </div>
                                            <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                            <Link className="botao-principal" to="#" onClick={() => this.downloadFicheiro(this.state.aulaid)}>
                                                <Icon type="download" /> descarregar
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <Controlos
                                    backUrl={localStorage.getItem("player_back_url") ? localStorage.getItem("player_back_url") : `/gerir-curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                    sameUrl={sameUrl}
                                    temAnotacoes
                                    temMensagens
                                    nome={nome}
                                    objetivos={objetivos}
                                    icon={require("../images/icons/download.png")}
                                    dataHora={dataHora}
                                />
                            </>
                        ) : (
                                <>
                                    <div className="container">
                                        <div className="bloco-nao-disponivel">
                                            <div className="bloco-info nao-disponivel">
                                                <Oops tem_texto texto={"Conteúdo não disponível"} />
                                            </div>
                                        </div>
                                    </div>
                                    <ControlosSimples />
                                </>
                            )}
                    </div>
                </div>
            );
    }
}

export default Download;
