import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { notification } from "antd";
import Modulo from "../_Aux/Modulo";
import { GeralContext } from "../ContextProvider";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Modulos extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        curso: "",
        retomar: {},
        //modulo_aberto: "0",
        modulos: [],
        botoesVisiveis: false,
        total_conteudos: 0,
        total_conteudos_realizados: 0,
        //PERMISSOES
        loading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //PRESENÇAS
        totalPresencas: 0,
        totalFolhasPresencas: 0
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoid: cursoId,
            curso: this.props.match.params.turma
        });

        if (this.context.lista_modulos.length === 0) this.listarModulos(cursoId);
        else
            this.setState({
                modulos: this.context.lista_modulos,
                proximaPosicaoModulo: this.context.lista_modulos[this.context.lista_modulos.length - 1].ordem + 1
            });

        //var modulo = this.getParameterByName("modulo");
        //if (this.getParameterByName("modulo"))
        //    this.setState({
        //        modulo_aberto: modulo
        //    });
    }

    //APANHA PARAMETROS PASSADOS PELO URL
    //getParameterByName = name => {
    //    var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
    //    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    //};

    //CARREGA E LISTA TODOS OS MODULOS PERTENCENTES AO CURSO E IDENTIFICA A ULTIMA POSIÇÃO NA ORDEM
    listarModulos = cursoid => {
        this.setState({
            loading: true
        });

        axios({
            method: "get",
            url: "/api/curso-modulo/listar-modulos",
            params: {
                cursoid
            }
        })
            .then(response => {
                if (response.data.length > 0) {
                    this.context.atualizarState({
                        lista_modulos: response.data
                    });

                    this.setState({
                        modulos: response.data,
                        proximaPosicaoModulo: response.data[response.data.length - 1].ordem + 1,
                        loading: false
                    });
                }
                else
                    this.setState({
                        loading: false
                    });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarModulo = moduloid => {
        axios({
            method: "get",
            url: "/api/curso-modulo/listar-modulo",
            params: {
                cursoid: localStorage.getItem("codigo_curso"),
                moduloid
            }
        })
            .then(response => {
                var moduloIndex = this.state.modulos.findIndex(x => x.id === moduloid);
                var _modulos = this.state.modulos;
                _modulos[moduloIndex] = response.data;

                this.setState({
                    modulos: _modulos
                });
            })
            .catch(() => {});
    };

    concluirAula = (moduloid, aulaid) => {
        axios({
            method: "post",
            url: "/api/player/concluir-aula",
            params: {
                aulaid
            }
        })
            .then(response => {
                this.listarModulo(moduloid);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível concluir o conteúdo!");
            });
    };

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)} style={{ marginRight: 10 }}>
                Voltar
            </Link>);
        }
    }

    render() {
        const {
            cursoid,
            curso,
            modulo_aberto,
            modulos,
            //REDIRECT
            redirect,
            redirectLink,
            ////PRESENÇAS
            //totalPresencas,
            //totalFolhasPresencas
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <GeralContext.Consumer>
                {geralContext => {
                    return (
                        <div className="container container-body">
                            {this.state.loading ? (
                                <div className="loading-data">
                                    <div className="loading" />
                                </div>
                            ) : (
                                    <div className="curso-modulos">
                                        <div className="controlos controlos-fundo">
                                            <div className="controlos-opcoes">
                                                {this.montarBotaoVoltar()}
                                            </div>
                                        </div>
                                    {modulos.map((modulo, index) => (
                                        <Modulo
                                            key={index}
                                            curso={curso}
                                            cursoid={cursoid}
                                            index={index}
                                            modulo_aberto={modulo_aberto}
                                            modulo={modulo}
                                            concluirAula={this.concluirAula}
                                            disabled={modulo.estado === "BLOQUEADO" || !(modulo.modulo_Aula.length > 0)}
                                            inscrito={!geralContext.inscricao || geralContext.inscrito }
                                            subscricao={!geralContext.subscricao || geralContext.subscricaoAtiva}
                                            habilitar_gamificacao={geralContext.habilitar_gamificacao}
                                            //totalPresencas={totalPresencas}
                                            //totalFolhasPresencas={totalFolhasPresencas}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                }}
            </GeralContext.Consumer>
        );
    }
}

export default Modulos;
