import React from 'react'
import { Button, Icon, Select, Tooltip } from "antd";
import moment from "moment";

import { montarCamposDias } from "../../../Utilities/Diario/Frequencias/tabela"

const { Option } = Select;

const VistaMobile = ({ dados, selectDiscipline, anteriorProximo, abrirModal }) => {
    const {
        disciplinaId,
        //FILTROS
        disciplinas,
        num,
        semPeriodos,
        isSelected,
        disciplinasNome,
        //PERIODO
        titulo,
        periodos,
        participantes,
        //LOADING
        iconLoading,
        numDias,
        aulas: listaAulas,
        ano: anoPeriodo,
        mes: mesPeriodo,
        ano,
        mes,
        estado,
        periodoTitulo
    } = dados;

    const montarColunasDias = (participanteId, participanteAtivo, participanteData) => {
        let dias = [];
        for (let i = 0; i < numDias; i++) {
            const aulas = listaAulas.filter(x =>
                moment(x.dt_inicio).isSame(
                    `${ano}-${("0" + moment().month(mes).format("M")).slice(-2)}-${("0" + (i + 1)).slice(-2)}`
                )
            );

            if (aulas.length > 0) {
                let presencas = 0;
                let faltas = 0;
                let faltasJustificadas = 0;
                let aulasPresencas = [];
                let aulasFaltas = [];
                let aulasFaltasJustificadas = [];
                const ativo =
                    participanteAtivo || (!participanteAtivo && moment(participanteData).isSameOrAfter(moment(aulas[0].dt_inicio)));

                var presente = aulas[0].presentes?.some(x => x === participanteId);

                if (ativo && presente) {
                    aulas.map((aula, index) => {
                        const presencasAlunos = aula.presencas ? JSON.parse(aula.presencas) : [];
                        const participante = presencasAlunos.find(x => x.id === participanteId);
                        if (
                            (aula.presenca ||
                                (!aula.presenca &&
                                    presencasAlunos.length &&
                                    presencasAlunos.find(x => x.id === participanteId && x.estado === "P"))) &&
                            participante != undefined &&
                            !participante?.bloqueado
                        ) {
                            aulasPresencas.push(
                                <React.Fragment key={`presenca-${index}`}>
                                    <span>{aula.titulo}: Presença</span>
                                    <br />
                                </React.Fragment>
                            );
                            presencas++;
                        }
                        if (
                            (aula.falta ||
                                (!aula.falta &&
                                    presencasAlunos.length &&
                                    presencasAlunos.find(x => x.id === participanteId && x.estado === "F"))) &&
                            participante != undefined &&
                            !participante?.bloqueado
                        ) {
                            aulasFaltas.push(
                                <React.Fragment key={`falta-${index}`}>
                                    <span>{aula.titulo}: Falta</span>
                                    <br />
                                </React.Fragment>
                            );
                            faltas++;
                        }
                        if (
                            !aula.presenca &&
                            !aula.falta &&
                            presencasAlunos.length &&
                            presencasAlunos.find(x => x.id === participanteId && x.estado === "FJ")
                        ) {
                            aulasFaltasJustificadas.push(
                                <React.Fragment key={`faltaJustificada-${index}`}>
                                    <span>{aula.titulo}: Falta justificada</span>
                                    <br />
                                </React.Fragment>
                            );
                            faltasJustificadas++;
                        }
                    });
                    dias.push(
                        <span key={`dia-${i}`} className="dia" onClick={() => abrirModal(aulas, participanteId)}>
                            <span className="titulo">{("0" + (i + 1)).slice(-2)}</span>
                            {presencas > 0 ? (
                                <font face="Cambria" style={{ color: "green" }}>
                                    <Tooltip placement="bottom" title={<span>{aulasPresencas.map((presenca, index) => <React.Fragment key={`tooltipPresenca-${index}`}>{presenca}</React.Fragment>)}</span>}>
                                        <strong>{`P (${presencas})`}</strong>
                                    </Tooltip>
                                    <br />
                                </font>
                            ) : null}
                            {faltas > 0 ? (
                                <font face="Cambria" style={{ color: "red" }}>
                                    <Tooltip placement="bottom" title={<span>{aulasFaltas.map((falta, index) => <React.Fragment key={`tooltipFalta-${index}`}>{falta}</React.Fragment>)}</span>}>
                                        <strong>{`F (${faltas})`}</strong>
                                    </Tooltip>
                                    <br />
                                </font>
                            ) : null}
                            {faltasJustificadas > 0 ? (
                                <font face="Cambria" style={{ color: "orange" }}>
                                    <Tooltip placement="bottom" title={<span>{aulasFaltasJustificadas.map((falta, index) => <React.Fragment key={`tooltipFJ-${index}`}>{falta}</React.Fragment>)}</span>}>
                                        <strong>{`FJ (${faltasJustificadas})`}</strong>
                                    </Tooltip>
                                    <br />
                                </font>
                            ) : null}
                        </span>
                    );
                } else {
                    dias.push(
                        <span key={`diaVazio-${i}`} className="dia">
                            <span className="titulo">{("0" + (i + 1)).slice(-2)}</span>
                            <span className="texto"></span>
                        </span>
                    );
                }
            } else
                dias.push(
                    <span key={`diaVazio-${i}`} className="dia">
                        <span className="titulo">{("0" + (i + 1)).slice(-2)}</span>
                        <span className="texto"></span>
                    </span>
                );
        }

        return dias;
    };

    const calcularTotalMes = acumulados => {
        let totalPresencas = 0;
        let totalFaltas = 0;
        let totalJustificadas = 0;
        acumulados.forEach(acumulado => {
            if (
                ((estado === "Anterior" && acumulado.periodo === periodoTitulo) || estado === "Proximo") &&
                acumulado.mes === mes
            ) {
                totalPresencas += acumulado.totalPresencas;
                totalFaltas += acumulado.totalFaltas;
                totalJustificadas += acumulado.totalJustificadas;
            }
        });

        return (
            <span style={{ display: "flex", gap: 10, justifyContent: "center" }}>
                {totalPresencas > 0 && <strong style={{ color: "green" }}>P{`(${totalPresencas})`}</strong>}
                {totalFaltas > 0 && <strong style={{ color: "red" }}>F{`(${totalFaltas})`}</strong>}
                {totalJustificadas > 0 && <strong style={{ color: "orange" }}>FJ{`(${totalJustificadas})`}</strong>}
            </span>
        );
    };

    const calcularTotalPeriodo = acumulados => {
        let totalPresencas = 0;
        let totalFaltas = 0;
        let totalJustificadas = 0;

        const index = acumulados.findIndex(x => x.periodo === periodoTitulo && x.mes === mes);

        if (index === -1) return null;

        acumulados.slice(0, index + 1).forEach(acumulado => {
            if (acumulado.periodo === periodoTitulo) {
                totalPresencas += acumulado.totalPresencas;
                totalFaltas += acumulado.totalFaltas;
                totalJustificadas += acumulado.totalJustificadas;
            }
        });

        return (
            <span style={{ display: "flex", gap: 10, justifyContent: "center" }}>
                {totalPresencas > 0 && <strong style={{ color: "green" }}>P{`(${totalPresencas})`}</strong>}
                {totalFaltas > 0 && <strong style={{ color: "red" }}>F{`(${totalFaltas})`}</strong>}
                {totalJustificadas > 0 && <strong style={{ color: "orange" }}>FJ{`(${totalJustificadas})`}</strong>}
            </span>
        );
    };

    const calcularTotalAcumulado = acumulados => {
        let totalPresencas = 0;
        let totalFaltas = 0;
        let totalJustificadas = 0;
        const index = acumulados.findIndex(x => x.periodo === periodoTitulo && x.mes === mes);

        if (index === -1) return null;

        acumulados.slice(0, index + 1).forEach(acumulado => {
            totalPresencas += acumulado.totalPresencas;
            totalFaltas += acumulado.totalFaltas;
            totalJustificadas += acumulado.totalJustificadas;
        });

        return (
            <span style={{ display: "flex", gap: 10, justifyContent: "center" }}>
                {totalPresencas > 0 && <strong style={{ color: "green" }}>P{`(${totalPresencas})`}</strong>}
                {totalFaltas > 0 && <strong style={{ color: "red" }}>F{`(${totalFaltas})`}</strong>}
                {totalJustificadas > 0 && <strong style={{ color: "orange" }}>FJ{`(${totalJustificadas})`}</strong>}
            </span>
        );
    };

    return (
        <div>
            <div className="bloco-controlos-frequencia-2">
                <div className="filters">
                    <div>
                        {localStorage.getItem("vista") === "TURMA" && (
                            <Select
                                className="filter"
                                placeholder="Selecionar Disciplina"
                                name="disciplinas"
                                allowClear={true}
                                onChange={selectDiscipline}
                                disabled={disciplinas.length === 0}
                            >
                                <Option value="Todos">Todos</Option>
                                {disciplinas.map(disciplina => (
                                    <Option key={disciplina.id} value={disciplina.id}>
                                        {disciplina.nome}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </div>
                </div>
            </div>
            <div className="bloco-controlos-frequencia">
                <div className="filters">
                    <div>
                        {!semPeriodos && (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {isSelected === false && typeof disciplinaId === "number" ? (
                                    <>
                                        <Button
                                            className="botao-periodo botao-periodo-anterior"
                                            disabled={
                                                num === 1
                                            }
                                            style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                            onClick={() => {
                                                anteriorProximo(num - 1, "Anterior");
                                            }}
                                        >
                                            <Icon type="left" />
                                        </Button>
                                        <span>{titulo}</span>
                                        <Button
                                            className="botao-periodo botao-periodo-proximo"
                                            disabled={
                                                num === periodos.length || localStorage.getItem("vista") === "TURMA" && !disciplinaId
                                            }
                                            style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                            onClick={() => {
                                                anteriorProximo(num + 1, "Proximo");
                                            }}
                                        >
                                            <Icon type="right" />
                                        </Button>{" "}
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            className="botao-periodo botao-periodo-anterior"
                                            disabled={num === 1}
                                            style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                            onClick={() => anteriorProximo(num - 1, "Anterior")}
                                        >
                                            <Icon type="left" />
                                        </Button>
                                        <span>{titulo}</span>
                                        <Button
                                            className="botao-periodo botao-periodo-proximo"
                                            disabled={
                                                num === periodos.length || localStorage.getItem("vista") === "TURMA" && !disciplinaId
                                            }
                                            style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                            onClick={() => anteriorProximo(num + 1, "Proximo")}
                                        >
                                            <Icon type="right" />
                                        </Button>
                                    </>
                                )}
                            </div>
                        )}
                        {isSelected === false ? (
                            <div style={{ fontSize: 16, fontWeight: "bold", marginTop: 15 }}>{disciplinasNome.nome}</div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <div className="bloco-cards">
                {participantes.map((participante, index) => (
                    <div key={index} className="card">
                        <div className="header">
                            <span className="titulo">
                                {("0" + (index + 1)).slice(-2)} - {participante.nome}
                            </span>
                        </div>
                        <div className="body">
                            <div className="estrutura-calendario">
                                {montarColunasDias(participante.id, participante.ativo, participante.dataAtualizado).map(
                                    dia => dia
                                )}
                            </div>
                            {montarCamposDias(participante.utilizadorEstados, anoPeriodo, mesPeriodo).map(
                                estado => estado
                            )}
                        </div>
                        <div className="footer">
                            <span className="total-presencas2">
                                MÊS {!iconLoading ? calcularTotalMes(participante.acumulados) : "..."}
                            </span>
                            <span className="total-presencas2">
                                PERÍODO {!iconLoading ? calcularTotalPeriodo(participante.acumulados) : "..."}
                            </span>
                            <span className="total-presencas2">
                                ACUMULADO
                                {!iconLoading ? calcularTotalAcumulado(participante.acumulados) : "..."}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default VistaMobile