import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, notification } from "antd";
import axios from "axios";
import { GlobalContext } from "../../GlobalState"
import noimage from "../../images/noimage.png";

class Detalhe extends Component {
    static contextType = GlobalContext;
    state = {
        preview: "",
        nome: "",
        //PARTICIANTES
        participantes: []
    };

    carregarDetalhe = grupoId => {
        axios({
            method: "get",
            url: "/api/chat/detalhe",
            params: {
                grupoId
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data.participantes,
                    preview: response.data.capa,
                    nome: response.data.nome,
                });
            })
            .catch(() => { });
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.grupoId) {
                this.carregarDetalhe(this.props.grupoId);
            }
            else {
                this.setState({
                    preview: "",
                    nome: "",
                    //PARTICIANTES
                    participantes: []
                })
            }
        }
    };

    render() {
        const {
            preview,
            nome,
            //PARTICIANTES
            participantes
        } = this.state;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias"
                    //title="Detalhe do Grupo"
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <h3 className="titulo-separador">
                                    Membros
                                </h3>
                                <Form.Item className="form-item-checkbox">
                                    <div className="lista-participantes">
                                        <label className="label-participantes">
                                            Incluidos os seguintes
                                        </label>
                                        {participantes.length > 0 ?
                                            participantes.map((participante, index) => (
                                                <div key={index} className="participante">
                                                    <div className="info-participante">
                                                        <div className="container-img">
                                                            <img src={participante.foto ? participante.foto : noimage} />
                                                        </div>
                                                        <div className="info">
                                                            <span className="nome">
                                                                {participante.nome}
                                                            </span>
                                                            <p className="numero">{participante.numero}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <span className="sem-registos">Sem dados...</span>
                                        }
                                    </div>
                                </Form.Item>
                            </div>
                            {participantes.length > 1 &&
                                <>
                                <div className="bloco">
                                    <h3 className="titulo-separador">
                                        Grupo
                                    </h3>
                                    <Form.Item>
                                        <div className="bloco-container-img">
                                            <div className="container-img">
                                                <img src={preview ? preview : noimage} alt="Logo do curso" />
                                            </div>
                                        </div>
                                    </Form.Item>
                                    </div>
                                    <div className="bloco">
                                    <Form.Item label="Nome do grupo">
                                        <span>{nome}</span>
                                        </Form.Item>
                                    </div>
                                </>}
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <Link
                            to="#"
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                                </Link>
                    </div>
                </Drawer>
            </>
        );
    }
}
const FormDetalhe = Form.create({ name: "form-detalhe" })(Detalhe);

export default FormDetalhe;
