import React, { useState, useEffect } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Icon, Modal } from 'antd';
import DrawerEstatuto from "./Drawer/DrawerEstatuto";
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;

const Tabela = ({ match }) => {
    const [estatutoId, setEstatutoId] = useState();
    const [estatutos, setEstatutos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [visible, setVisible] = useState(false)

    const cols = [
        {
            title: "Data",
            dataIndex: "data"
        },
        {
            title: "Tipo",
            dataIndex: "tipo"
        },
        {
            title: "Descrição",
            dataIndex: "descricao"
        },
        {
            title: "Estado",
            dataIndex: "estado",
            render: (text) => text ? <span className="state-green-tag">Aprovado</span> : <span className="state-red-tag">Pendente</span>
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 50
        }
    ];

    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-estatutos-utilizador"
        })
            .then(response => {
                const lista = response.data.map(item => {
                    return {
                        key: item.id,
                        data: item.termino ? `${moment(item.inicio).format("DD/MM/YYYY") } - ${moment(item.termino).format("DD/MM/YYYY") }` : moment(item.inicio).format("DD/MM/YYYY"),
                        tipo: item.titulo,
                        descricao: item.descricao,
                        estado: item.aprovado,
                        opcoes: !item.aprovado && item.autor ? <div className="documentos-opcoes">
                            <Link className="botao-icon-editar" to="#" title="Editar" onClick={() => { setEstatutoId(item.id); setVisible(true) }}>
                                <Icon type="edit" />
                            </Link>
                            <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={() => excluir(item.id)}>
                                <Icon type="delete" />
                            </Link>
                        </div> : null
                    }
                })

                setEstatutos(lista);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    const excluir = (estatutoId) => {
        confirm({
            title: "Pretende excluir este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/user-detalhe/excluir-estatuto",
                    params: {
                        estatutoId
                    }
                })
                    .then(response => {
                        carregar();
                    })
                    .catch(error => {

                    });
            }
        });
    }

    useEffect(() => {
        carregar();
    }, [carregar]);

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <>
            <Table
                className="tabela-documentos"
                columns={cols}
                pagination={false}
                dataSource={estatutos}
                loading={{
                    spinning: loading,
                    indicator: (
                        <div className="loading-data-table">
                            <div className="loading" />
                        </div>
                    )
                }}
            />
            <div className="opcoes-meus-cursos">
                <Link to="#" className="btn-adicionar" title="Adicionar" onClick={() => { setVisible(true); setEstatutoId(); }}>
                    +
                </Link>
            </div>
            <DrawerEstatuto
                estatutoId={estatutoId}
                visible={visible}
                onClose={() => setVisible(false)}
                atualizar={() => { carregar(); setVisible(false); }}
            />
        </>
    );
}

export default withRouter(Tabela);