import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Upload, notification, Dropdown, Icon, Drawer, Menu, Tooltip, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../images/noimage.png";
import { GlobalContext } from "../../GlobalState";

const Dragger = Upload.Dragger;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerAdicionarDoc = ({ documentoId, code, visible, onClose, form }) => {
    const [nome, setNome] = useState("");
    const [fileList, setFileList] = useState([]);
    const [estado, setEstado] = useState("");
    const [loading, setLoading] = useState(false);

    const { getFieldDecorator } = form;

    const handlerChange = event => {
        setNome(event.target.value);
    };

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/documentos/detalhe",
            params: {
                documentoId
            }
        })
            .then(response => {
                const dados = response.data;
                setNome(dados.nome);
                setEstado(dados.estado);
            })
            .catch(error => {

            });
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("code", code);
                item.append("nome", nome + "." + fileList[0].name.split('.').pop());
                item.append("ficheiro", fileList[0]);

                axios({
                    method: "post",
                    url: "/api/documentos/adicionar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        onClose();
                        setLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            }
        })
    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("id", documentoId);
                item.append("nome", nome + "." + fileList[0].name.split('.').pop());
                item.append("ficheiro", fileList[0]);

                axios({
                    method: "put",
                    url: "/api/documentos/alterar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        onClose();
                        setLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false);
                    });
            }
        })
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (visible) {
            if (documentoId > 0)
                detalhe();
        }
        else {
            setNome("");
            setFileList([]);
            setLoading(false);
        }
    }

    const validarFormatoFicheiro = formato => {
        var formatos = ["docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file)
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed")
                callback("Campo obrigatório");

        callback();
    };

    const props = {
        multiple: false,
        onRemove: file => {
            form.resetFields("ficheiro");
            setFileList(fileList => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: file => {
            setFileList([file])

            return false;
        },
        fileList
    };


    return (
        <Drawer
            className="drawer-add-cursos drawer-videoconferencias"
            //title={documentoId > 0 ? "Alterar" : "Adicionar"}
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label="Nome">
                            {getFieldDecorator("nome", {
                                rules: [{
                                    required: true,
                                    message: "Campo obrigatório"
                                }],
                                initialValue: nome.replace("." + nome.split(".").pop(), "")
                            })(<Input name="nome" onChange={handlerChange} />)}
                        </Form.Item>
                        <Form.Item label="Ficheiro">
                            <div className="anexos">
                                {getFieldDecorator("ficheiro", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        },
                                        {
                                            validator: validar
                                        }
                                    ],
                                    initialValue: fileList
                                })(
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexo{" "}
                                            <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                )}
                            </div>
                        </Form.Item>
                        {estado &&
                            <Form.Item>
                                <button className="botao-principal">
                                    Gerar Documento
                                </button>
                            </Form.Item>}
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <Link to="#" className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </Link>
                <button className="botao-principal" disabled={loading} onClick={documentoId > 0 ? alterar : adicionar}>
                    {loading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
                {/*{documentoId > 0 ?*/}
                {/*    <button className="botao-principal" disabled={loading} onClick={alterar}>*/}
                {/*        {loading ? <Icon type="loading" /> : null}*/}
                {/*        Guardar*/}
                {/*    </button>*/}
                {/*    :*/}
                {/*    <button className="botao-principal" disabled={loading} onClick={adicionar}>*/}
                {/*        {loading ? <Icon type="loading" /> : null}*/}
                {/*        Guardar*/}
                {/*    </button>*/}
                {/*}*/}
            </div>
        </Drawer>
    );
}

const FormDrawerAdicionarDoc = Form.create({ name: "form-drawer-add-doc" })(DrawerAdicionarDoc);

export default FormDrawerAdicionarDoc;
