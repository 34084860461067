import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { Icon, Select, Switch } from "antd";
import axios from "axios";

import { GlobalContext } from "../../GlobalState";

import Notas from "./Notas/Notas";
import Conceitos from "./Conceitos/Conceitos";
import Bncc from "./Bncc/Bncc";
import Acessos from "../../Geral/Acessos/Acessos";

const { Option } = Select;
const Aproveitamento = ({ match }) => {
    const context = useContext(GlobalContext);

    const [parametros, setParametros] = useState([]);
    const [estruturaId, setEstruturaId] = useState(undefined);
    const [tipo, setTipo] = useState("");
    const [periodo, setPeriodo] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    //const [vistaPautas, setVistaPautas] = useState(true);

    const listarParametros = () => {
        axios({
            method: "get",
            url: "/api/aproveitamento/listar-estruturas-avaliacao",
            params: {
                disciplinaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setParametros(response.data);
                if (response.data.length === 1) {
                    const estrutura = response.data[0];
                    setEstruturaId(estrutura.id);
                    setTipo(estrutura.tipo);
                    setPeriodo(estrutura.periodo);
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    };

    const handleChangeSelect = value => {
        if (value) {
            setEstruturaId(value);
            setTipo(parametros.find(x => x.id === value).tipo);
            setPeriodo(parametros.find(x => x.id === value).periodo);
        } else {
            setEstruturaId(undefined);
            setTipo("");
            setPeriodo([]);
        }
    };

    const montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (
                <>
                    <Link
                        to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar}
                        className="botao-secundario botao-secundario-redondo"
                        onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}
                        style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Icon type="arrow-left" />
                    </Link>
                </>
            );
        }
    };

    useEffect(() => {
        listarParametros();
        if (localStorage.getItem("aproveitamento")) {
            const info = JSON.parse(localStorage.getItem("aproveitamento"));

            setEstruturaId(info.estrutura);
            setTipo(info.tipo);
            setPeriodo(info.periodo);
            localStorage.removeItem("aproveitamento");
        }
    }, []);

    if (redirect) return <Redirect to={redirectLink} />;

    localStorage.setItem("vista_pautas", true);

    const acesso = context.acessos?.find(x => x.func === 21);

    return (
        <>
            <Acessos funcionalidadeId={21} />
            <div className="container container-body">
                <div className="turma-visao-geral">
                    <div className="controlos controlos-fundo ">
                        <div className="bloco-esquerda bloco-esquerda-column" >
                            {parametros.length > 1 && (
                                <Select
                                    placeholder="Selecionar estrutura"
                                    value={estruturaId}
                                    allowClear={true}
                                    onChange={handleChangeSelect}
                                >
                                    {parametros.map(parametro => (
                                        <Option key={parametro.id} value={parametro.id}>
                                            {parametro.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                            {periodo && tipo && (
                                <div className='periodo'>
                                    <span style={{ display: "block", lineHeight: "initial" }}>
                                        Periodo Letivo: <strong>{periodo}</strong>
                                    </span>
                                    <span style={{ display: "block", lineHeight: "initial" }}>
                                        Tipo: <strong>{tipo}</strong>
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="bloco-direita">
                            {montarBotaoVoltar()}
                        </div>
                    </div>
                    {tipo === "Notas" && <Notas estruturaId={estruturaId} tipo={tipo} />}
                    {(tipo === "Conceitos" || tipo === "Modulos") && (
                        <Conceitos
                            estruturaId={estruturaId}
                            periodo={periodo}
                            tipo={tipo}
                            /*vistaPautas={vistaPautas}*/
                        />
                    )}
                    {tipo === "BNCC" && (
                        <Bncc
                            estruturaId={estruturaId}
                            periodo={periodo}
                            tipo={tipo}
                            /*vistaPautas={vistaPautas}*/
                        />
                    )}
                </div>
            </div>
        </>
    );
};
export default Aproveitamento;
