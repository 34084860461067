import React, { Component } from "react";
import { withRouter } from "react-router-dom"
import { Icon, notification, Modal } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class CardsNotasRegular extends Component {
    state = {
        num: 1,
        total: 0,
        //PERIODO
        grupos: [],
        periodos: [],
        //LOADING
        iconLoading: false
    }

    componentDidMount() {
        this.listar();
    }

    listar = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/consultar-notas/listar-notas-pre",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    utilizadorId: this.props.match.params.utilizadorid
                }
            })
                .then(response => {
                    if (response.data.length > 0) {
                        let grupos = [];
                        if (response.data.length > 1)
                            grupos = response.data[0].grupos;

                        this.setState({
                            grupos,
                            periodos: response.data,
                            iconLoading: false
                        })
                    }
                    else {
                        this.setState({
                            iconLoading: false
                        })
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a informação!");
                });
        })
    }

    render() {
        const {
            grupos,
            periodos,
            //LOADING
            iconLoading
        } = this.state;

        const montarAvaliacoes = (grupo, grupoIndex) => {
            const avaliacoes = grupo.avaliacoes.map((avaliacao, index) => {
                return <div className="ponto">
                    <span className="ponto-titulo">{avaliacao.titulo}</span>
                    <div className="lista-notas">
                        {periodos.map((periodo, periodoIndex) => {
                            const _grupo = periodo.grupos[grupoIndex];

                            let nota = "";
                            if (_grupo !== null) {
                                const _avaliacao = _grupo.avaliacoes[index];
                                if (_avaliacao !== null)
                                    nota = _avaliacao.nota;
                            }

                            return <div className="nota">
                                <span className="titulo">{periodo.titulo}:</span>
                                <span className="texto">{nota ? nota : "-"}</span>
                            </div>
                        })}
                    </div>
                </div>
            });

            return avaliacoes;
        }

        return (
            <>
                <div>
                    <div className="bloco-cards">
                        {grupos.map((grupo, index) => (
                            <div className="card">
                                <div className="header">
                                    <span className="titulo">{grupo.titulo}</span>
                                </div>
                                <div className="body">
                                    <div className="lista-pontos">
                                        {montarAvaliacoes(grupo, index)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(CardsNotasRegular);