import React, { useState, useEffect } from "react";
import { Table } from "antd";
import moment from "moment";
import axios from "axios";
import Controlos from "./Controlos/Controlos";

const Disciplina = ({ menuOptionSelected }) => {
    const [search, setSearch] = useState("");
    const [filterDates, setFilterDates] = useState([]);
    const [filterSchool, setFilterSchool] = useState(undefined);
    const [filterEdition, setFilterEdtion] = useState(undefined);
    const [filterCourse, setFilterCourse] = useState(undefined);
    const [filterDiscipline, setFilterDiscipline] = useState(undefined);
    const [activeFilters, setActiveFilters] = useState([]);
    const [cols, setCols] = useState([]);
    const [data, setData] = useState([]);
    const [dataBackUp, setDataBackUp] = useState([]);
    const [sorter, setSorter] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        total: 0
    });
    const [loading, setLoading] = useState(false);

    const getLogs = () => {
        if (search || filterDates || filterSchool || filterEdition || filterCourse || filterDiscipline) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/logs/buscar-turmas-aulas-sumarios",
                params: {
                    columns: JSON.stringify(cols.map(col => col.dataIndex)),
                    filterDates: JSON.stringify(filterDates),
                    disciplinaId: filterDiscipline
                }
            })
                .then(response => {
                    setData(response.data);
                    setDataBackUp(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });

                    setLoading(false);
                });
        } else {
            setData([]);
        }
    };

    useEffect(() => {
        setSearch("");
        setFilterDates(undefined);
        setFilterSchool(undefined);
        setFilterEdtion(undefined);
        setFilterCourse(undefined);
        setFilterDiscipline(undefined);
        setCols([
            {
                title: "Data Hora",
                dataIndex: "data_hora",
                sorter: (a, b) => moment(a.data_hora).unix() - moment(b.data_hora).unix(),
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "Usuario",
                dataIndex: "utilizador",
                sorter: (a, b) => a.utilizador.localeCompare(b.utilizador),
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "Ação",
                dataIndex: "acao_vista",
                sorter: (a, b) => a.acao_vista.localeCompare(b.acao_vista),
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "Logs",
                dataIndex: "objeto_json",
                sorter: (a, b) => a.objeto_json.localeCompare(b.objeto_json),
                sortDirections: ["descend", "ascend"]
            }
        ]);

        setData([]);
    }, [menuOptionSelected]);

    useEffect(() => {
        if (activeFilters.length === 0) getLogs();
    }, [activeFilters]);

    const handleSearch = () => {
        getLogs();
    };

    const handleChangeSearch = value => {
        setActiveFilters([...activeFilters.filter(x => x !== "SEARCH"), "SEARCH"]);
        setSearch(value);
    };

    const resetSearch = () => {
        setActiveFilters(activeFilters.filter(x => x !== "SEARCH"));
        setSearch("");
    };

    const handleChangeDates = filter => {
        if (filter) setActiveFilters([...activeFilters, "DATES"]);
        else {
            setActiveFilters(activeFilters.filter(x => x !== "DATES"));
        }
        setFilterDates(filter);
    };

    const handleChangeSchool = filter => {
        if (filter) setActiveFilters([...activeFilters, "SCHOOL"]);
        else {
            setActiveFilters(activeFilters.filter(x => x !== "SCHOOL"));
        }
        setFilterSchool(filter);
    };

    const handleChangeEdition = filter => {
        if (filter) setActiveFilters([...activeFilters, "EDITION"]);
        else {
            setActiveFilters(activeFilters.filter(x => x !== "EDITION"));
        }
        setFilterEdtion(filter);
    };

    const handleChangeCourse = filter => {
        if (filter) setActiveFilters([...activeFilters, "CLASS"]);
        else {
            setActiveFilters(activeFilters.filter(x => x !== "CLASS"));
        }
        setFilterCourse(filter);
    };

    const handleChangeDiscipline = filter => {
        if (filter) setActiveFilters([...activeFilters, "DISCIPLINE"]);
        else {
            setActiveFilters(activeFilters.filter(x => x !== "DISCIPLINE"));
        }
        setFilterDiscipline(filter);
    };

    const handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = pagination;
        _pagination.current = pagination.current;

        setSorter(sorter.order ? { order: sorter.order, key: sorter.columnKey } : undefined);
        setPagination(_pagination);
    };

    return (
        <>
            <div className="report-info">
                <Controlos
                    handleSearch={handleSearch}
                    menuItemKey={menuOptionSelected?.menuItemKey}
                    menuItemName={menuOptionSelected?.menuItemName}
                    search={search}
                    handleChangeSearch={handleChangeSearch}
                    resetSearch={resetSearch}
                    filterDate={filterDates}
                    setFilterDate={filter => handleChangeDates(filter)}
                    filterSchool={filterSchool}
                    setFilterSchool={filter => handleChangeSchool(filter)}
                    filterEdition={filterEdition}
                    setFilterEdition={filter => handleChangeEdition(filter)}
                    filterCourse={filterCourse}
                    setFilterCourse={filter => handleChangeCourse(filter)}
                    filterDiscipline={filterDiscipline}
                    setFilterDiscipline={filter => handleChangeDiscipline(filter)}
                    setCols={columns => setCols(columns)}
                    sorter={sorter}
                    menuOptionSelected={menuOptionSelected}
                    disabledSearch={!search && !filterDates && !filterSchool && !filterEdition && !filterCourse && !filterDiscipline}
                    disabledButtons={data.length === 0}
                    columnsPositions={cols.map(col => col.dataIndex)}
                    cols={cols}
                    data={data}
                    pageTitle={menuOptionSelected?.menuItemName}
                />
                <div className="page-container">
                    <div className="page-header">
                        <h1 className="page-title">Logs - {menuOptionSelected?.menuItemName}</h1>
                        <h3 className="page-counters">
                            <strong>Contar:</strong>
                            <span>{data.length}</span>
                        </h3>
                    </div>
                    <div className="page-body">
                        <Table
                            columns={cols}
                            pagination={pagination}
                            showSizeChanger
                            dataSource={data}
                            onChange={handleChangeTable}
                            loading={{
                                spinning: loading,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Disciplina;
