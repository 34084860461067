import React from "react";
import { Modal } from "antd";
import moment from "moment";

const ModalLogDetail = ({ logDetail, visible, onClose }) => {
    return (
        <Modal
            title="Detalhe do log"
            className="modal-log-detail"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <div className="bloco">
                <div className="log-detail-info">
                    <span className="log-detail-title">Autor:</span>
                    <span className="log-detail-text">{logDetail?.autor}</span>
                </div>
                <div className="log-detail-info">
                    <span className="log-detail-title">Data:</span>
                    <span className="log-detail-text">{moment(logDetail?.data_hora).format("DD/MM/YYYY às HH:mm")}</span>
                </div>
                {logDetail?.objeto_json.nota && (
                    <div className="log-detail-info">
                        <span className="log-detail-title">Nota:</span>
                        <span className="log-detail-text">{logDetail?.objeto_json.nota}</span>
                    </div>)}
                {logDetail?.objeto_json.observacoes && (
                    <div className="log-detail-info">
                        <span className="log-detail-title">Observações:</span>
                        <span className="log-detail-long-text">{logDetail?.objeto_json.observacoes}</span>
                    </div>)}
            </div>
        </Modal>
    );
}

export default ModalLogDetail;
