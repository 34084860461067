import React, {useEffect, useState} from "react";
import 'antd/dist/antd.css';
import BarChart from "./BarChart"
import DropdownAnos from "./DropdownAnos";
import {Icon} from "antd";


export default function GraficoBarras({ titulo,datasetSource, atualizarAno2 }) {

    const [ano, setAno] = useState()

    const atualizarAno = (valor) => {
        setAno(valor);
    };

    useEffect(() => {
        if (ano) {
            atualizarAno2(ano); 
        }
    }, [ano]);
    
    return (
        <div className="graficoBarras">
            <div className="topo-tabela">
                <span>{titulo}</span>
                <DropdownAnos atualizarAno={atualizarAno} />
            </div>
            <div className="bordaBar">
                {datasetSource ? <BarChart datasetSource={datasetSource}/> : <div className="carregar">
                    <div className="alinhar"><Icon type="loading"/> <span>A carregar</span></div>
                </div>}
            </div>
        </div>
    );
}
