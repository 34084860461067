import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Icon } from 'antd';
import { GlobalContext } from "../../../../GlobalState";
import axios from "axios";

const Tabela = ({ match }) => {
    const globalContext = useContext(GlobalContext);
    const [percurso, setPercurso] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const cols = [
        {
            title: "Edição",
            dataIndex: "edicao"
        },
        {
            title: "Turma",
            dataIndex: "turma"
        },
        {
            title: "Disciplina",
            dataIndex: "disciplina"
        },
        {
            title: "Aproveitamento",
            dataIndex: "aproveitamento"
        },
        {
            title: "Resultado",
            dataIndex: "resultado"
        }
    ];

    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-percurso",
            params: {
                edicaoSelecioanda: globalContext[`edicao${match.params.code}`]?.id
            }
        })
            .then(response => {
                const percurso = response.data.map((item, index) => {
                    return {
                        key: index,
                        edicao: item.edicao,
                        turma: item.turma,
                        disciplina: item.disciplina,
                        aproveitamento: item.aproveitamento,
                        resultado: ""
                    }
                })

                setPercurso(percurso);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    useEffect(() => {
        if (globalContext[`edicao${match.params.code}`])
            carregar();
    }, [globalContext[`edicao${match.params.code}`]])

    if (redirect) return <Redirect to={redirectLink} />;

    const data = [
        {
            edicao: <strong>2021 / 2022</strong>
        },
        {
            turma: "8º A",
            disciplina: "0001 - Matemática",
            aproveitamento: 10,
            resultado: <span className="state-green-tag">Aprovado</span>,
        },
        {
            turma: "8º A",
            disciplina: "0001 - Português",
            aproveitamento: 10,
            resultado: <span className="state-green-tag">Aprovado</span>,
        },
        {
            edicao: <strong>2020 / 2021</strong>
        },
        {
            turma: "7º A",
            disciplina: "0001 - Matemática",
            aproveitamento: 10,
            resultado: <span className="state-green-tag">Aprovado</span>,
        },
        {
            turma: "7º A",
            disciplina: "0001 - Português",
            aproveitamento: 10,
            resultado: <span className="state-green-tag">Aprovado</span>,
        },
        {
            edicao: <strong>2019 / 2020</strong>
        },
        {
            turma: "5º A",
            disciplina: "0001 - Matemática",
            aproveitamento: 10,
            resultado: <span className="state-green-tag">Aprovado</span>,
        }
    ]

    return (
        <Table
            className="tabela-documentos tabela-aproveitamento"
            columns={cols}
            pagination={false}
            dataSource={percurso}
            loading={{
                spinning: loading,
                indicator: (
                    <div className="loading-data-table">
                        <div className="loading" />
                    </div>
                )
            }}
        />
    );
}

export default withRouter(Tabela);