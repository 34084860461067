import React, { Component } from "react";
import { Link, matchPath } from "react-router-dom";
import { Modal, Form, Button, Checkbox, notification } from "antd";
import { GlobalContext } from "../../GlobalState";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ModalInscricaoPart2Generico extends Component {
    static contextType = GlobalContext;
    state = {
        condicoesAceites: false,
        nomeUtilizador: "",
        numero: "",
        horarios: [],
        //LOADING
        loading: false
    };

    UNSAFE_componentWillMount() {
        this.carregarInfo();
    }

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    carregarInfo = () => {
        axios({
            method: "get",
            url: "/api/curso/carregar-info",
            params: {
                cursoid: this.props.id_curso
            }
        })
            .then(response => {
                this.setState({
                    nomeUtilizador: response.data.utilizador.nome,
                    numero: response.data.utilizador.numero,
                    horarios: response.data.horarios
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    finalizar = () => {
        if (!this.state.condicoesAceites) {
            openNotificationWithIcon("warning", "Atenção", "Para finalizar deve aceitar as condições.");
            return false;
        }

        this.setState({
            loading: true
        })

        var item = new FormData();
        item.append("id_curso", this.props.id_curso);
        item.append("nome_completo", this.props.nome_completo);
        item.append("cc", this.props.cc);
        item.append("dt_ccvalidade", this.props.dt_ccvalidade.format("YYYY/MM/DD"));

        axios({
            method: "post",
            url: "/api/curso/finalizar-generico",
            data: item
        })
            .then(response => {
                this.props.finalizar();
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            condicoesAceites,
            //INFO CARREGAD
            nomeUtilizador,
            numero,
            horarios,
            //LOADING
            loading
        } = this.state;
        const {
            visiblePart2,
            nome_completo,
            cc,
            dt_ccvalidade,
            codigo,
            nome,
            carga_horaria,
            dt_inicio,
            dt_fim,
            valor
        } = this.props;

        const montarTexto = () => {
            let t = "";
            let countLocais = 0;

            horarios.map(info => {
                let p1 = `${countLocais > 0 ? " e " : ""} ${info.morada}, ${info.localidade}, `
                let p2 = "";
                let countHorarios = 0;

                info.horarios.map(horario => {
                    let p4 = `no horário das ${horario.horaIni} às ${horario.horaFim} `
                    let p3 = "";

                    horario.meses.map(mes => {
                        p3 += `${countHorarios > 0 ? " e " : ""}nos dias ${mes.dias.join(", ")} de ${mes.mes} de ${mes.ano}, `
                        countHorarios++;
                    });

                    p2 += p3 + p4;
                })

                t += p1 += p2;
                countLocais++;
            })

            return t;
            //MORADA, LOCALIDADE, nos dias xx, xx, xx de MÊS de ANO, no horário das xxHxx às xxHxx
        }

        return (
            <>
                <Modal
                    title="Inscrição"
                    visible={visiblePart2}
                    width={900}
                    className="modal-inscricao"
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={this.props.cancelar}>
                            Cancelar
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={this.finalizar}>
                            Finalizar
                        </Button>,
                    ]}
                >
                    <div style={{ margin: "0 50px" }}>
                        <meta charSet="utf-8" />
                        <title />
                        <p>
                            <title />
                        </p>
                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n        @page {\n            size: 21cm 29.7cm;\n            margin: 2cm\n        }\n\n        h1 {\n            margin-left: -0.95cm;\n            margin-right: -0.87cm;\n            direction: ltr;\n            font-variant: small-caps;\n            color: #000000;\n            line-height: 150%;\n            text-align: center;\n            orphans: 2;\n            widows: 2;\n            background: transparent;\n            page-break-after: avoid\n        }\n\n            h1.western {\n                font-family: \"Book Antiqua\", serif;\n                font-size: 12pt;\n                so-language: pt-PT;\n                font-weight: bold\n            }\n\n            h1.cjk {\n                font-family: \"Batang\", \"바탕\", serif;\n                font-size: 12pt;\n                font-weight: bold\n            }\n\n            h1.ctl {\n                font-family: \"Book Antiqua\", serif;\n                font-size: 12pt;\n                so-language: ar-SA;\n                font-weight: bold\n            }\n\n        p {\n            margin-bottom: 0.21cm;\n            direction: ltr;\n            color: #000000;\n            orphans: 2;\n            widows: 2;\n            background: transparent\n        }\n\n            p.western {\n                font-family: \"Times New Roman\", serif;\n                font-size: 12pt;\n                so-language: pt-PT\n            }\n\n            p.cjk {\n                font-family: \"Times New Roman\", serif;\n                font-size: 12pt\n            }\n\n            p.ctl {\n                font-family: \"Times New Roman\", serif;\n                font-size: 12pt;\n                so-language: ar-SA\n            }\n    " }} />
                        <p className="western" align="center" style={{ marginBottom: '0cm', fontVariant: 'small-caps', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">
                                <font size={6} style={{ fontSize: '22pt' }}>
                                    <u>
                                        <b>
                                            Contrato
                                            de Formação
                </b>
                                    </u>
                                </font>
                            </font>
                        </p>
                        <p className="western" align="center" style={{ marginBottom: '0cm', fontVariant: 'small-caps', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <p className="western" style={{ marginBottom: '0cm', fontVariant: 'small-caps', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif"><u><b>Entre:</b></u></font>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <span style={{ fontVariant: 'small-caps' }}>
                                <font face="Calibri, sans-serif">
                                    <u>
                                        <b>
                                            NextOpinion
                </b>
                                    </u>
                                </font>
                            </span>
                            <font face="Calibri, sans-serif">
                                ,
                                pessoa colectiva nº 502280280, com sede na Várzea de Mesiões,
                                Apartado 177, Torres Novas, representada pelo seu Presidente de
                                Direção, Domingos da Silva Chambel, com os poderes bastantes para o
                                ato, adiante designado por
          </font>
                            <font face="Calibri, sans-serif">
                                <i>
                                    primeira
                                    contratante
            </i>
                            </font>
                            <font face="Calibri, sans-serif">, ou </font><span style={{ fontVariant: 'small-caps' }}>
                                <font face="Calibri, sans-serif">NextOpinion</font>
                            </span>
                            <font face="Calibri, sans-serif">;</font>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif"><b>e</b></font>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif"><span lang="pt-PT">{nome_completo}</span></font>
                            <font face="Calibri, sans-serif">
                                ,
                                portador do documento de identificação nº
          </font>
                            <font face="Calibri, sans-serif"><span lang="pt-PT">{cc}</span></font>
                            <font face="Calibri, sans-serif">
                                ,
                                na qualidade de segunda contratante
          </font>
                            <font face="Calibri, sans-serif">, </font>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">
                                <b>
                                    É livremente e de boa fé
                                    celebrado o presente contrato que se rege pelas cláusulas seguintes:
            </b>
                            </font>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <h1 className="western" style={{ lineHeight: '100%' }}>
                            <font face="Calibri, sans-serif">
                                Cláusula Primeira
                            </font>
                        </h1>
                        <p className="western" align="center" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <span style={{ fontVariant: 'small-caps' }}>
                                <font face="Calibri, sans-serif">(Objeto)</font>
                            </span>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    A primeira contratante propõe-se realizar uma ação de Formação Profissional, a que o segundo contratante se candidatou e foi seleccionado, que abrange os módulos referentes ao curso
                                </font>
                                <span style={{ fontVariant: 'small-caps' }}>
                                    <font face="Calibri, sans-serif"><b>“</b></font>
                                </span>
                                <span style={{ fontVariant: 'small-caps' }}>
                                    <font face="Calibri, sans-serif"><b>{codigo} - {nome}</b></font>
                                </span>
                                <span style={{ fontVariant: 'small-caps' }}>
                                    <font face="Calibri, sans-serif"><b>”</b></font>
                                </span>
                                <font face="Calibri, sans-serif">
                                    , com a carga horária total de <b>{carga_horaria}</b> horas.
                                </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <h1 className="western" style={{ lineHeight: '100%' }}>
                            <font face="Calibri, sans-serif">
                                Cláusula Segunda
          </font>
                        </h1>
                        <p className="western" align="center" style={{ marginBottom: '0cm', fontVariant: 'small-caps', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">(Local e Horário)</font>
                        </p>
                        <ol style={{ paddingLeft: "10px" }}>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        {dt_fim ?
                                            <font face="Calibri, sans-serif">
                                                A ação referida na cláusula anterior será realizada em regime de e-Learning/b-Learning (riscar o que não interessa), tendo início em <b>{moment(dt_inicio).format("DD/MM/YYYY")}</b> e termo previsto em <b>{moment(dt_fim).format("DD/MM/YYYY")}</b>, em conformidade com a planificação e carga horária divulgada.
                                            </font>
                                            :
                                            <font face="Calibri, sans-serif">
                                                A ação referida na cláusula anterior será realizada em regime de e-Learning/b-Learning (riscar o que não interessa), tendo início em <b>{moment(dt_inicio).format("DD/MM/YYYY")}</b>, em conformidade com a planificação e carga horária divulgada.
                                            </font>
                                        }
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        {horarios.length ?
                                            <font face="Calibri, sans-serif">
                                                No caso de formação em regime de b-Learning (à distância e presencial) as sessões presenciais terão lugar em {montarTexto()}, em conformidade com a planificação e carga horária divulgada.
                                            </font>
                                            :
                                            <font face="Calibri, sans-serif">
                                                No caso de formação em regime de b-Learning (à distância e presencial) as sessões presenciais terão lugar em conformidade com a planificação e carga horária divulgada.
                                            </font>
                                        }
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            O segundo contratante frequentará a componente presencial da formação no local indicado no ponto 2 desta cláusula.
                                        </font>
                                    </font>
                                </p>
                            </li>
                        </ol>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <p className="western" align="center" style={{ fontVariant: 'small-caps' }}>
                            <font face="Calibri, sans-serif"><b>Cláusula Terceira</b></font>
                        </p>
                        <p className="western" align="center" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <span style={{ fontVariant: 'small-caps' }}>
                                <font face="Calibri, sans-serif">
                                    (Direitos dos Formandos)
            </font>
                            </span>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    1.
                                    O segundo contratante terá direito a frequentar e a participar na
                                    ação de formação referida na cláusula primeira.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    2.
                                    No final da ação de formação, ao segundo contratante será
                                    entregue um certificado comprovativo da sua frequência e do
                                    aproveitamento obtido.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    3.
                                    O certificado comprovativo referido no ponto 2 desta cláusula, está
                                    dependente da frequência de pelo menos 90% do volume de formação
                                    previsto para a ação, bem como da participação nas sessões
                                    síncronas e na realização de todas as tarefas (propostas de
                                    trabalho e/ou testes de avaliação), por parte do segundo
                                    contratante.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    4.
                                    Para as ações do curso Formação Pedagógica Inicial de Formadores
                                    o certificado emitido será o CCP – Certificado de Competências
                                    Pedagógicas, pelo I. E. F. P., apenas se houver aproveitamento na
                                    ação e assiduidade mínima de 95%, por parte do segundo
                                    contratante.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <p className="western" align="center" style={{ fontVariant: 'small-caps' }}>
                            <font face="Calibri, sans-serif"><b>Cláusula Quarta</b></font>
                        </p>
                        <p className="western" align="center" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <span style={{ fontVariant: 'small-caps' }}>
                                <font face="Calibri, sans-serif">
                                    (Deveres
                                    dos Formandos)
            </font>
                            </span>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    1.
                                    O segundo contratante obriga-se a frequentar a ação referida na
                                    cláusula primeira, com interesse, assiduidade e pontualidade, de
                                    acordo com a duração prevista.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    2.
                                    O segundo contratante obriga-se designadamente a:
            </font>
                            </font>
                        </p>
                        <ol type="a">
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Frequentar
                                            com assiduidade e pontualidade a formação, visando adquirir os
                                            conhecimentos teóricos e práticos que forem ministrados. Aos
                                            formandos que não estejam presentes à hora de início da formação
                                            ou se ausentem por períodos diferentes dos intervalos realizados
                                            será registado o tempo de ausência, contabilizando-o para efeitos
                                            de assiduidade;
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Tratar
                                            com urbanidade a primeira contratante e a entidade gestora e seus
                                            representantes;
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Salvar
                                            lealdade não divulgando, por qualquer forma informações sobre
                                            equipamentos que assumam natureza confidencial ou reservada;
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Utilizar
                                            com cuidado e zelar pela boa conservação dos equipamentos e demais
                                            bens que lhes sejam confiados para efeitos de formação;
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Suportar
                                            os custos de substituição ou reparação dos equipamentos e
                                            materiais que utilizar na ação de formação, bem como a reparação
                                            de instalações sempre que os danos produzidos resultem de
                                            comportamento doloso ou gravemente negligente;
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Responder,
                                            pela forma e no prazo solicitado, a todos os inquéritos formulados
                                            e directamente relacionados com a ação de formação;
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Responsabilizar-se
                                            por garantir os meios necessários (computador, internet, etc), para
                                            a componente de e-Learning.
                </font>
                                    </font>
                                </p>
                            </li>
                        </ol>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <h1 className="western" style={{ lineHeight: '100%' }}>
                            <font face="Calibri, sans-serif">
                                Cláusula Quinta
          </font>
                        </h1>
                        <p align="center" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <span style={{ fontVariant: 'small-caps' }}>
                                    <font face="Calibri, sans-serif">
                                        (Deveres
                                        da NextOpinion)
              </font>
                                </span>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">
                                São deveres da NextOpinion, os que a
                                seguir descriminamos:
          </font>
                        </p>
                        <ol type="a">
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Desenvolver
                                            a formação programada com respeito pelas disposições legais e
                                            regulamentares em vigor e pelas condições de aprovação do curso.
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Facultar
                                            aos formandos o acesso aos benefícios e, na formação presencial,
                                            os equipamentos que sejam compatíveis com o curso frequentado e sua
                                            duração.
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Respeitar
                                            e fazer respeitar as condições de Segurança e Saúde no Trabalho.
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Verdana, sans-serif">
                                        <font face="Calibri, sans-serif">
                                            Não
                                            exigir ao formando tarefas não compreendidas no objeto do curso.
                </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                                    <font face="Calibri, sans-serif">
                                        Cumprir os termos do presente
                                        contrato.
              </font>
                                </p>
                            </li>
                        </ol>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <h1 className="western" style={{ lineHeight: '100%' }}>
                            <font face="Calibri, sans-serif">
                                Cláusula Sexta
          </font>
                        </h1>
                        <p className="western" align="center" style={{ marginBottom: '0cm', fontVariant: 'small-caps', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">(Sanções)</font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    1.
                                    O primeiro contratante poderá rescindir o presente contrato, nos
                                    casos em que se verifique o incumprimento dos deveres do segundo
                                    contratante, enquanto formando, ou o abandono da ação de formação.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    2.
                                    Em qualquer dos casos previstos no número anterior, o segundo
                                    contratante fica obrigado a realizar o pagamento ao primeiro
                                    contratante do valor integral da ação de formação profissional.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <p className="western" align="center" style={{ marginBottom: '0cm' }}>
                            <span style={{ fontVariant: 'small-caps' }}>
                                <font face="Calibri, sans-serif">
                                    <b>
                                        Cláusula Sétima
              </b>
                                </font>
                            </span>
                        </p>
                        <p className="western" align="center" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <span style={{ fontVariant: 'small-caps' }}>
                                <font face="Calibri, sans-serif">(Pagamentos)</font>
                            </span>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    1. O segundo contratante pagará ao primeiro contratante, pela ação de formação referida na cláusula segunda, o valor total de <b>{valor}</b>.
                                </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    2.
                                    O valor mencionado no ponto 1 desta cláusula deverá ser
                                    efetivamente pago até ao primeiro dia de realização da ação de
                                    formação.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    3.
                                    Para as ações de formação cuja divulgação pela NextOpinion permita
                                    o pagamento faseado, a 1ª prestação será paga até ao primeiro
                                    dia da ação de formação em metade do valor que se encontre em
                                    falta, e a 2ª e última prestação até ao último dia de formação,
                                    no restante valor, ao primeiro contratante.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Verdana, sans-serif">
                                <font face="Calibri, sans-serif">
                                    4.
                                    O pagamento pode ser efetuado através de transferência bancária ou
                                    cheque à ordem da NextOpinion.
            </font>
                            </font>
                        </p>
                        <p align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <h1 className="western" style={{ lineHeight: '100%' }}>
                            <font face="Calibri, sans-serif">
                                Cláusula Oitava
          </font>
                        </h1>
                        <p className="western" align="center" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <span style={{ fontVariant: 'small-caps' }}>
                                <font face="Calibri, sans-serif">(Desistências)</font>
                            </span>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">
                                1. Após o início da formação,
                                qualquer valor pago ou entregue para pagamento pelo segundo
                                contratante será considerado pelo primeiro contratante como
                                pagamento dos encargos suportados por este, como se o segundo
                                contratante frequentasse a ação de formação até ao final.
          </font>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">
                                2. No final da ação de formação
                                o primeiro contratante emitirá ao segundo contratante o recibo de
                                quitação dos valores pagos de acordo com este contrato.
          </font>
                        </p>
                        <p className="western" style={{ marginBottom: '0cm' }}>
                            <br />
                        </p>
                        <p className="western" style={{ marginBottom: '0cm' }}>
                            <br />
                        </p>
                        <h1 className="western" style={{ lineHeight: '100%' }}>
                            <font face="Calibri, sans-serif">
                                Cláusula Nona
          </font>
                        </h1>
                        <p className="western" align="center" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <span style={{ fontVariant: 'small-caps' }}>
                                <font face="Calibri, sans-serif">
                                    (Permissão
                                    expressa no âmbito do RGPD)
            </font>
                            </span>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">
                                No âmbito da formação objeto do
                                presente contrato, e em cumprimento do Regulamento Geral sobre
                                Proteção de Dados (RGPD), aplicável desde 25 de maio de 2018, o
                                Segundo Outorgante manifesta a sua autorização expressa a que os
                                seus dados, fornecidos ao Primeiro Outorgante, sejam também
                                utilizados nas plataformas de formação à distância, em áreas de
                                trabalho colaborativas para partilha de conteúdos, sistemas de
                                videoconferência, sistemas de messaging e partilha de ficheiros,
                                dando o seu consentimento livre, explícito, inequívoco e informado,
                                para efeitos de gravação de todas as atividades e interações
                                desenvolvidas no âmbito destas plataformas à distância e com as
                                finalidades seguintes: Formação à distância (gravação das
                                atividades e interações desenvolvidas no âmbito das plataformas à
                                distância); Áreas de trabalho colaborativas para partilha de
                                conteúdos (por ex. Padlet, Google Drive); Sistemas de
                                videoconferência e partilha de ficheiros (por ex.: Zoom, Microsoft
          </font>
                            <font face="Calibri, sans-serif">
                                Teams, STEPFORMA); Sistemas de
                                messaging e partilha de ficheiros (por ex.: WhatsApp).
          </font>
                        </p>
                        <p className="western" style={{ marginBottom: '0cm' }}>
                            <br />
                        </p>
                        <h1 className="western" style={{ lineHeight: '100%' }}>
                            <font face="Calibri, sans-serif">
                                Cláusula Décima
          </font>
                        </h1>
                        <p className="western" align="center" style={{ marginBottom: '0cm', fontVariant: 'small-caps', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">(Duração)</font>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">
                                O presente contrato vigorará pelo
                                período de duração da ação de formação profissional.
          </font>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <a name="_Hlk50645181" />
                            <Checkbox name="condicoesAceites" checked={condicoesAceites} onChange={this.handleChangeCheckbox}>
                                <font face="Calibri, sans-serif" style={{ fontSize: "12pt", color: "black" }}>
                                    <b>Li, concordo e aceito as condições expressas no presente contrato, celebrado à distância.</b>
                                </font>
                            </Checkbox>
                        </p>
                        <p className="western" align="justify" style={{ marginBottom: '0cm', lineHeight: '150%' }}>
                            <br />
                        </p>
                        <p className="western" align="justify" style={{ lineHeight: '150%' }}>
                            <font face="Calibri, sans-serif">
                                Data
                                e hora da aceitação do contrato pelo formando (gerada na
                                STEPFORMA):
          </font>
                            <font face="Calibri, sans-serif"><b>{moment().format("DD/MM/YYYY, HH[H]mm")}, por: {nome_completo}</b></font>
                        </p>
                    </div>
                </Modal>
            </>
        );
    }
}

const FormModalInscricaoPart2Generico = Form.create({ name: "form-modal-inscricao-part2-generico" })(ModalInscricaoPart2Generico);

export default FormModalInscricaoPart2Generico;
