import React, { Component } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, notification } from "antd";
import Modulo from "./Modulo";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class OpcaoModulos extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        cursoid: 0,
        aulaid: 0,
        //modulos: [],
        //loading: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.setState({
            curso: this.props.match.params.curso,
            cursoid: localStorage.getItem("codigo_curso"),
            aulaid: localStorage.getItem("codigo_aula")
        });
    }

    //CARREGA OS MODULOS DO CURSO
    //listarModulos = () => {
    //    this.setState({
    //        loading: true
    //    });

    //    axios({
    //        method: "get",
    //        url: "/api/player/listar-modulos",
    //        params: {
    //            cursoid: this.state.cursoid
    //        }
    //    })
    //        .then(response => {
    //            this.context.atualizarState({
    //                lista_modulos: response.data
    //            });

    //            this.setState(
    //                {
    //                    modulos: response.data,
    //                    loading: false
    //                });
    //        })
    //        .catch(() => { });
    //};

    //listarModulo = moduloid => {
    //    axios({
    //        method: "get",
    //        url: "/api/player/listar-modulo",
    //        params: {
    //            cursoid: this.state.cursoid,
    //            moduloid
    //        }
    //    })
    //        .then(response => {
    //            var moduloIndex = this.state.modulos.findIndex(x => x.id == moduloid);
    //            var _modulos = this.state.modulos;
    //            _modulos[moduloIndex] = response.data

    //            this.setState({
    //                modulos: _modulos
    //            })
    //        })
    //        .catch(() => { });
    //};

    //concluirAula = (moduloid, aulaid) => {
    //    axios({
    //        method: "post",
    //        url: "/api/player/concluir-aula",
    //        params: {
    //            aulaid
    //        }
    //    })
    //        .then(() => {
    //            this.listarModulo(moduloid);
    //        })
    //        .catch(error => {
    //            openNotificationWithIcon("error", "Erro", "Não foi possível concluir o conteúdo!");
    //        });
    //};

    //afterVisibleChange = aberto => {
    //    if (aberto) {
    //        if (this.context.lista_modulos.length === 0) {
    //            this.setState({ loading: true })
    //            this.listarModulos();
    //        }
    //        else {
    //            this.setState({
    //                modulos: this.context.lista_modulos
    //            })
    //        }
    //    }
    //    else
    //        this.setState({
    //            modulos: []
    //        })
    //}

    render() {
        const { curso, cursoid, aulaid } = this.state;
        const { modulos, loading } = this.props;

        return (
            <>
                <Drawer
                    placement="right"
                    closable={true}
                    onClose={this.props.fecharModulos}
                    visible={this.props.drawerModulos}
                    //afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="curso-modulos">
                        {loading ? (
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                        ) : (
                            modulos.map((modulo, index) => (
                                <Modulo
                                    key={index}
                                    modulo_aberto={String(this.context.moduloIndex)}
                                    collapsed={modulo.collapsed}
                                    curso={curso}
                                    cursoid={cursoid}
                                    aulaid={aulaid}
                                    index={index}
                                    modulo={modulo}
                                    player
                                    disabled={modulo.estado === "BLOQUEADO"}
                                    concluirAula={this.props.concluirAula}
                                    fecharModulos={this.props.fecharModulos}
                                    inscrito
                                />
                            ))
                        )}
                    </div>
                </Drawer>
            </>
        );
    }
}

export default withRouter(OpcaoModulos);
