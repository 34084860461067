import React, {useEffect, useState} from "react";
import '../../../scss/_comentarios.scss'
import { FaRegCalendar, FaReply } from "react-icons/fa";
import RespostaComentario from "./RespostaComentario";
import axios from "axios";
import { notification } from "antd";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Comentarios = ({ nome, data, texto, idComentario, trocarComentario, setIdComentarioResponder,scrollToTarget }) => {
    const [repostaComentario, setRepostaComentario] = useState([])
   
    const converterData = (isoDate) => {
        const monthNames = [
            "janeiro", "fevereiro", "março", "abril", "maio", "junho",
            "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
        ];
        const date = new Date(isoDate);
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} de ${month} de ${year}`;
    };
    
    const handlerResponder = () => {
        trocarComentario();
        scrollToTarget();
        setIdComentarioResponder(idComentario);
    }
    
    useEffect(() => {
        const fetchComentarios = async () => {
            const url = `/api/pagina-publica/resposta_comentario/listar`;

            try {
                const response = await axios.get(url, {
                    params: {IdComentario: idComentario}
                });
                setRepostaComentario(response.data.filter(e => e.ativo && e.validado));
            }
            catch (error) {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os comentários");
            }
        };
        fetchComentarios();
    }, [idComentario]);

    return (
        <div className="comentario">
            <div style={{ backgroundColor: "#d0eaf2", borderRadius: "12px", paddingBottom:"50px", boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)" }}>
                <div className="headerperfil">
                    <div className="perfil">
                        <div>
                            <span className="nome"> {nome}</span>
                            <div className="iconCalendario">
                                <FaRegCalendar size="14px"/>
                                <span className="data">{data}</span>
                            </div>
                        </div>
                    </div>
                    <div className="botaoResponder" onClick={handlerResponder}>
                        <FaReply size='14px'/>
                        <span>Responder</span>
                    </div>
                </div>
                <div style={{padding:"10px"}} className="texto">
                    <p>
                        {texto}
                    </p>
                </div>
            </div>
            {repostaComentario.length > 0 && (
                <div className="respostas">
                    {repostaComentario.map((resposta, index) => (
                        <RespostaComentario
                            key={index}
                            nome={resposta.nome}
                            data={converterData(resposta.dtCriado)}
                            texto={resposta.comentario}
                        />
                    ))}
                </div>
            )}
            
        </div>
    );
}

export default Comentarios;
