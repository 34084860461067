import React, { Component } from "react";
import { Icon, notification } from "antd";

class CardsNotasCreche extends Component {
    render() {
        const {
            periodos,
            avaliacoes
        } = this.props;

        const montarNotas = (titulo, nota) => {
            if (nota && avaliacoes.length === 1)
                nota = avaliacoes[0].nome;

            return (<div className="nota">
                <span className="titulo">{titulo}:</span>
                <span className="texto">{nota ? nota : "-"}</span>
            </div>);
        }

        return (
            <div>
                <div className="bloco-cards">
                    <div className="card">
                        <div className="header">
                            <span className="titulo">CORPO, GESTOS E MOVIMENTOS</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02CG01) - Apropriar-se de gestos e movimentos de sua cultura no cuidado de si e nos jogos e brincadeiras.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02CG02) - Deslocar seu corpo no espaço, orientando-se por noções como em frente, atrás, no alto, embaixo, dentro, fora etc., ao se envolver em brincadeiras e atividades de diferentes naturezas.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02CG03) - Explorar formas de deslocamento no espaço (pular, saltar, dançar), combinando movimentos e seguindo orientações.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02CG04) - Demonstrar progressiva independência no cuidado do seu corpo.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[3].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02CG05) - Desenvolver progressivamente as habilidades manuais, adquirindo controle para desenhar, pintar, rasgar, folhear, entre outros.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[4].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="header">
                            <span className="titulo">ESCUTA, FALA PENSAMENTO E IMAGINAÇÃO</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EF01) - Dialogar com crianças e adultos, expressando seus desejos, necessidades, sentimentos e opiniões.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EF02) - Identificar e criar diferentes sons e reconhecer rimas e aliterações em cantigas de roda e textos poéticos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EF03) - Demonstrar interesse e atenção ao ouvir a leitura de histórias e outros textos, diferenciando escrita de ilustrações, e acompanhando, com orientação do adulto-leitor, a direção da leitura (de cima para baixo, da esquerda para a direita).</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EF04) - Formular e responder perguntas sobre fatos da história narrada, identificando cenários, personagens e principais acontecimentos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[3].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EF05) - Relatar experiências e fatos acontecidos, histórias ouvidas, filmes ou peças teatrais assistidos etc.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[4].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EF06) - Criar e contar histórias oralmente, com base em imagens ou temas sugeridos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[5].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EF07) - Manusear diferentes portadores textuais, demonstrando reconhecer seus usos sociais.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[6].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EF08) - Manipular textos e participar de situações de escuta para ampliar seu contato com diferentes gêneros textuais (parlendas, histórias de aventura, tirinhas, cartazes de sala, cardápios, notícias etc.).</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[7].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EF09) - Manusear diferentes instrumentos e suportes de escrita para desenhar, traçar letras e outros sinais gráficos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[8].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="header">
                            <span className="titulo">TRAÇOS, SONS, CORES E FORMAS</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02TS01) - Criar sons com materiais, objetos e instrumentos musicais, para acompanhar diversos ritmos de música.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[2].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02TS02) - Utilizar materiais variados com possibilidades de manipulação (argila, massa de modelar), explorando cores, texturas, superfícies, planos, formas e volumes ao criar objetos tridimensionais.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[2].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02TS03) - Utilizar diferentes fontes sonoras disponíveis no ambiente em brincadeiras cantadas, canções, músicas e melodias.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[2].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="header">
                            <span className="titulo">O EU, O OUTRO E O NÓS</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EO01) - Demonstrar atitudes de cuidado e solidariedade na interação com crianças e adultos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EO02) - Demonstrar imagem positiva de si e confiança em sua capacidade para enfrentar dificuldades e desafios.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EO03) - Compartilhar os objetos e os espaços com crianças da mesma faixa etária e adultos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EO04) - Comunicar-se com os colegas e os adultos, buscando compreendê-los e fazendo-se compreender.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[3].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EO05) - Perceber que as pessoas têm características físicas diferentes, respeitando essas diferenças.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[4].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EO06) - Respeitar regras básicas de convívio social nas interações e brincadeiras.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[5].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02EO07) - Resolver conflitos nas interações e brincadeiras, com a orientação de um adulto.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[6].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="header">
                            <span className="titulo">ESPAÇOS,TEMPOS, QUANTIDADES, RELAÇÕES E TRANSFORMAÇÕES</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02ET01) - Explorar e descrever semelhanças e diferenças entre as características e propriedades dos objetos (textura, massa, tamanho).</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02ET02) - Observar, relatar e descrever incidentes do cotidiano e fenômenos naturais (luz solar, vento, chuva etc.).</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02ET03) - Compartilhar, com outras crianças, situações de cuidado de plantas e animais nos espaços da instituição e fora dela.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02ET04) - Identificar relações espaciais (dentro e fora, em cima, embaixo, acima, abaixo, entre e do lado) e temporais (antes, durante e depois).</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[3].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02ET05) - Classificar objetos, considerando determinado atributo (tamanho, peso, cor, forma etc.).</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[4].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02ET06) - Utilizar conceitos básicos de tempo (agora, antes, durante, depois, ontem, hoje, amanhã, lento, rápido, depressa, devagar).</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[5].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02ET07) - Contar oralmente objetos, pessoas, livros etc., em contextos diversos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[6].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI02ET08) - Registrar com números a quantidade de crianças (meninas e meninos, presentes e ausentes) e a quantidade de objetos da mesma natureza (bonecas, bolas, livros etc.).</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[7].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardsNotasCreche;