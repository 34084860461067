import React from "react";
import MsgSecundaria from "./_Aux/MsgSecundaria";

const Pagina404 = () => {
    localStorage.clear();

    return (
        <div className="pagina-erro">
            <div className="hero" style={{ height: 620 }}>
                <div className="container" style={{ marginTop: 60, display: "flex", alignItems: "center", height: "100%" }}>
                    <div style={{ width: "100%" }}>
                        <div className="pagina-erro-header" style={{ textAlign: "center" }}>
                            <div className="container-img" style={{ width: "100%" }}>
                                <img src={require("../images/notfoundv2.svg")} alt="" />
                            </div>
                        </div>
                        <div className="pagina-erro-content" style={{ textAlign: "center" }}>
                            <MsgSecundaria texto="404" style={{ fontSize: 40, fontWeight: "bold" }} />
                            <MsgSecundaria texto="A página não foi encontrada ou não existe." />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pagina404;
