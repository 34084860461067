import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, notification, Icon, Modal, Tooltip, Select, Switch } from "antd";
import Header from "../User/Header";
import axios from "axios";
import { GlobalContext } from "../GlobalState";
import ModalAssociar from "./_Aux/ModalAssociar";
import ModalAssociarErros from "./_Aux/ModalAssociarErros";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class AssociarAlunos extends Component {
    static contextType = GlobalContext;
    state = {
        escolas: [],
        escolaId: undefined,
        licencas: [],
        total: 0,
        iconLoading: false,
        cpfVerificar: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        escolasCarregadas: false,
        //CARREGAR ALUNOS
        visibleCarregar: false,
        //ASSOCIAR
        visibleAssociar: false,
        totalUsuarios: 0,
        totalAssociado: 0,
        totalErros: 0,
        listaPartida: [],
        //ERROS
        visibleErros: false,
        erros: []
    };

    componentDidMount() {
        this.carregarLicencasDisponiveis();
    }

    componentDidUpdate() {
        const { estabelecimento } = this.props;
        const { escolaId } = this.state;

        if (estabelecimento?.id && estabelecimento.id !== escolaId) {
            this.setState({
                escolaId: estabelecimento.id
            });
        }
    }

    handleChangePrimeiro = (event, index) => {
        let licencas = this.state.licencas;
        licencas[index].primeiro = event.target.value;

        this.setState({
            licencas: licencas
        });
    };

    handleChangeUltimo = (event, index) => {
        let licencas = this.state.licencas;
        licencas[index].ultimo = event.target.value;

        this.setState({
            licencas: licencas
        });
    };

    handleChangeNumero = (event, index) => {
        let licencas = this.state.licencas;
        licencas[index].numero = event.target.value;

        this.setState({
            licencas: licencas
        });
    };

    handleChangeSenha = (event, index) => {
        let licencas = this.state.licencas;
        licencas[index].senha = event.target.value;

        this.setState({
            licencas: licencas
        });
    };

    handleChangeCpf = (event, index) => {
        let licencas = this.state.licencas;
        licencas[index].nif = event.target.value;

        this.setState({
            licencas: licencas
        });
    };

    onChangeSelect = escolaId => {
        this.setState({
            escolaId
        });
    };

    carregarLicencasDisponiveis = () => {
        axios({
            method: "get",
            url: "/api/colaboradores/total-licencas"
        })
            .then(response => {
                let licencas = [];
                let total = response.data.total - response.data.utilizadas;

                if (total > 0) {
                    licencas.push({
                        primeiro: "",
                        ultimo: "",
                        numero: "",
                        senha: ""
                    });

                    this.setState({
                        licencas: licencas,
                        total
                    });
                } else openNotificationWithIcon("warning", "Atenção", "Não existem licenças disponíveis");
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    adicionar = () => {
        let licencas = this.state.licencas;

        licencas.push({
            primeiro: "",
            ultimo: "",
            numero: "",
            senha: ""
        });

        this.setState({
            licencas
        });
    };

    excluir = index => {
        this.props.form.resetFields();
        let _licencas = this.state.licencas;
        _licencas.splice(index, 1);

        this.setState({
            licencas: _licencas
        });
    };

    guardar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let _lista = this.state.licencas;
                let totalLista = _lista.length;

                if (_lista.length > 0) {
                    this.setState({
                        totalUsuarios: _lista.length,
                        totalAssociado: 0,
                        totalErros: 0,
                        erros: [],
                        visibleAssociar: true
                    });

                    let listaPartida = [],
                        tamanho = 5;

                    while (_lista.length > 0) listaPartida.push(_lista.splice(_lista, tamanho));

                    this.setState(
                        {
                            listaPartida
                        },
                        () => {
                            if (listaPartida.length) this.associar(0);
                        }
                    );
                } else {
                    openNotificationWithIcon("warning", "Atenção", "Para poder associar tem de inserir pelo menos um e-mail.");
                }
            }
        });
    };

    associar = index => {
        if (index <= this.state.listaPartida.length - 1) {
            let listaItem = this.state.listaPartida[index];
            let item = new FormData();
            item.append("lista", JSON.stringify(listaItem));
            item.append("duplicarCPF", this.state.cpfVerificar);
            if (this.context.estabelecimento) item.append("organismoId", this.state.escolaId);

            axios({
                method: "post",
                url: "/api/colaboradores/associar-alunos",
                data: item
            })
                .then(response => {
                    this.setState(
                        {
                            totalAssociado: this.state.totalAssociado + (listaItem.length - response.data.length),
                            totalErros: this.state.totalErros + response.data.length,
                            erros: [...this.state.erros, ...response.data]
                        },
                        () => this.associar(index + 1)
                    );
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    this.setState({
                        visibleAssociar: false,
                        visibleErros: false
                    });
                });
        } else {
            this.setState({
                visibleAssociar: false,
                visibleErros: this.state.totalErros > 0,
                licencas: [
                    {
                        primeiro: "",
                        ultimo: "",
                        numero: "",
                        senha: ""
                    }
                ],
                total: this.state.total - this.state.totalAssociado
            });

            if (this.state.totalAssociado > 0 && this.state.totalErros === 0)
                openNotificationWithIcon(
                    "success",
                    "Sucesso",
                    this.state.totalAssociado > 1 ? "Usuários associados!" : "Usuário associado!"
                );
        }
    };

    //ABRE O FILE BROWSER
    ativarUpload = () => {
        document.getElementById("input-csv").click();
    };

    verificarCPFDuplicar = e => {
        this.setState({
            cpfVerificar: e
        });
    };

    //UPLOAD DE UM LOGO PARA A ENTIDADE
    uploadCSV = event => {
        if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop().toLowerCase())) {
            this.setState({
                visibleCarregar: true
            });

            let item = new FormData();
            item.append("ficheiro", event.target.files[0]);

            axios({
                method: "post",
                url: "/api/colaboradores/adicionar-alunos-csv",
                data: item
            })
                .then(response => {
                    let total = this.state.total;
                    let licencas = [];

                    response.data.forEach((utilizador, index) => {
                        if (total >= index + 1)
                            licencas.push({
                                primeiro: utilizador.primeiro,
                                ultimo: utilizador.ultimo,
                                completo: utilizador.completo,
                                numero: utilizador.numero,
                                senha: utilizador.senha,
                                nif: utilizador.nif
                            });
                    });

                    document.getElementById("input-csv").value = "";

                    this.setState({
                        licencas,
                        visibleCarregar: false
                    });

                    if (total < response.data.length)
                        openNotificationWithIcon(
                            "warning",
                            "Atenção",
                            response.data.length - total + " alunos não foram importados por falta de licenças disponíveis."
                        );
                })
                .catch(error => {
                    this.setState({
                        visibleCarregar: false
                    });
                    document.getElementById("input-csv").value = "";
                    openNotificationWithIcon("error", "Erro", error.response.data);
                });
        } else {
            document.getElementById("input-csv").value = "";
            openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        const formatos = ["csv"];
        let valido = false;

        for (let i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) valido = true;
        }

        return valido;
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            escolaId,
            escolas,
            licencas,
            iconLoading,
            total,
            cpfVerificar,
            //REDIRECT
            redirect,
            redirectLink,
            //CARREGAR ALUNOS
            visibleCarregar,
            //ASSOCIAR
            visibleAssociar,
            totalUsuarios,
            totalAssociado,
            totalErros,
            //ERROS
            visibleErros,
            erros
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Associar usuários em modo simplificado" />
                <div className="container">
                    <div className="associar-colaboradores">
                        <div className="cabecalho">
                            <div className="bloco-flex">
                                <span>
                                    <div className="bloco-titulos">
                                        <span className="titulo">
                                            Introduza ou importe  o e-mail dos usuários que pretende associar a{" "}
                                            {this.context.estabelecimento?.nome} ({this.context.estabelecimento?.abreviatura})
                                            <Tooltip
                                                className="info-icon"
                                                title="No modo de acesso simplificado o usuário usa no Login o seu Nome e Sobrenome"
                                            >
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </span>
                                        <span className="subtitulo">
                                            Os usuários podem ser associados a outros estabelecimentos no programa principal
                                        </span>
                                    </div>
                                </span>
                                <Link to="/gestao-utilizadores-todos" className="botao-principal">
                                    Voltar
                                </Link>
                            </div>
                            <div className="associar">
                                <div className="controlos">
                                    <button className="botao-secundario" disabled={total === licencas.length} onClick={this.adicionar}>
                                        Adicionar
                                    </button>
                                    <button className="botao-secundario" title="Importar e-mails" onClick={this.ativarUpload}>
                                        Importar{" "}
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <>
                                                    <span>Estrutura do documento CSV a importar:</span>
                                                    <br />
                                                    <ul>
                                                        <li>1ª Coluna - Primeiro Nome e Sobrenome</li>
                                                        <li>2ª Coluna - Código (Opcional)</li>
                                                        <li>3ª Coluna - CPF</li>
                                                        <li>4ª Coluna - Senha</li>
                                                    </ul>
                                                </>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </button>
                                    <input type="file" id="input-csv" accept=".csv" onChange={this.uploadCSV} style={{ display: "none" }} />
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            {licencas.map((licenca, index) => (
                                <Form key={index} className="associar associar-form">
                                    <button className="btn-excluir" title="Excluir" onClick={() => this.excluir(index)}>
                                        <Icon type="close-circle" />
                                    </button>
                                    <Form.Item label="Primeiro Nome">
                                        {getFieldDecorator("primeiro" + index, {
                                            initialValue: licenca.primeiro,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<Input name={"primeiro" + index} onChange={e => this.handleChangePrimeiro(e, index)} />)}
                                    </Form.Item>
                                    <Form.Item label="Sobrenome">
                                        {getFieldDecorator("ultimo" + index, {
                                            initialValue: licenca.ultimo,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<Input name={"ultimo" + index} onChange={e => this.handleChangeUltimo(e, index)} />)}
                                    </Form.Item>
                                    <Form.Item label="Código">
                                        <Input
                                            name={"numero" + index}
                                            value={licenca.numero}
                                            onChange={e => this.handleChangeNumero(e, index)}
                                        />
                                    </Form.Item>
                                    <Form.Item label="CPF">
                                        {getFieldDecorator("nif" + index, {
                                            initialValue: licenca.nif,
                                            rules: [
                                                {
                                                    pattern: "^(?=.*[0-9]).{11}$",
                                                    message: <p>O CPF deve conter exatamente 11 caracteres</p>
                                                }
                                            ]
                                        })(<Input name={"cpf" + index} onChange={e => this.handleChangeCpf(e, index)} />)}
                                    </Form.Item>
                                    {/*                                    <Form.Item>
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 10
                                            }}
                                        >
                                            <Switch size="small" onChange={this.verificarCPFDuplicar} />
                                            Duplicar Aluno
                                        </span>
                                    </Form.Item>*/}
                                    <Form.Item label="Senha">
                                        {getFieldDecorator("senha" + index, {
                                            initialValue: licenca.senha,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                },
                                                {
                                                    pattern: "^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})",
                                                    message: (
                                                        <p>
                                                            A senha deve conter pelo menos uma letra maiúscula, um número e um mínimo de 6
                                                            caracteres
                                                        </p>
                                                    )
                                                }
                                            ]
                                        })(<Input name={"senha" + index} onChange={e => this.handleChangeSenha(e, index)} />)}
                                    </Form.Item>
                                </Form>
                            ))}
                        </div>
                        <div className="rodape">
                            <Form className="associar">
                                <div className="controlos">
                                    <button className="botao-principal" disabled={iconLoading} onClick={this.guardar}>
                                        <span>{iconLoading ? <Icon type="loading" /> : null}</span>
                                        Associar
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <Modal visible={visibleCarregar} maskClosable={false} className="exportar-csv" closable={false} footer={null}>
                    <div className="exportar-csv-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar informações...</p>
                    </div>
                </Modal>
                <ModalAssociar
                    visibleAssociar={visibleAssociar}
                    totalAssociado={totalAssociado}
                    totalUsuarios={totalUsuarios}
                    totalErros={totalErros}
                />
                <ModalAssociarErros
                    visibleErros={visibleErros}
                    totalAssociado={totalAssociado}
                    erros={erros}
                    onClose={() => this.setState({ visibleErros: false })}
                />
            </div>
        );
    }
}
const ContextAssociarAlunos = ({ form }) => (
    <GlobalContext.Consumer>
        {({ estabelecimento }) => <AssociarAlunos estabelecimento={estabelecimento} form={form} />}
    </GlobalContext.Consumer>
);

const FormAssociarAlunos = Form.create({ name: "associar" })(ContextAssociarAlunos);

export default FormAssociarAlunos;
