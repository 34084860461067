import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, notification, Icon } from "antd";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Confirmacao extends Component {
    static contextType = GlobalContext;
    state = {
        email: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    componentDidMount() {
        this.validar();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1]);
    };

    validar = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-chat/validar-estado",
            params: {
                codigo: this.getParameterByName("code")
            }
        })
            .then(response => {
                if (!response.data)
                    this.setState({
                        redirect: true,
                        redirectLink: `/${this.props.match.params.entidade}/chat-inativo`
                    });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Ocorreu um erro!");
            });
    };

    //ENVIA A INSCRICAO PARA PODER ACEDER A VIDEOCONFERENCIA
    confirmar = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("email", this.state.email);
                item.append("entidadeId", localStorage.getItem("entidadeId"));
                item.append("codigo", this.getParameterByName("code"));

                axios({
                    method: "post",
                    url: "/api/pagina-entidade-chat/chat-confirmacao",
                    data: item
                })
                    .then(response => {
                        localStorage.setItem("videoconf_userid", response.data)
                        this.setState({
                            iconLoading: false,
                            redirect: true,
                            redirectLink: `/${this.props.match.params.entidade}/chat-codigo?code=${this.getParameterByName("code")}`
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível validar o email!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        if (this.state.redirect)
            return <Redirect to={this.state.redirectLink} />;

        const {
            //LOADING
            iconLoading
        } = this.state;

        return (
            <>
                <Header titulo="Iniciar Chat"/>
                <div className="container container-inscricao container-body">
                    <div className="content">
                        <Form className="suporte-contacto">
                            <Form.Item label="Introduzir email">
                                {getFieldDecorator("email", {
                                    rules: [
                                        {
                                            type: "email",
                                            message: "Introduza um endereço de e-mail válido"
                                        },
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="email" onChange={this.handleChange} />)}
                            </Form.Item>
                            <div className="btn_plano">
                                <Link to="#" className="botao-principal" disabled={iconLoading} onClick={this.confirmar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Próximo
                                </Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}
const FormConfirmacao = Form.create({ name: "form-confirmacao" })(Confirmacao);

export default FormConfirmacao;
