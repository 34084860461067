import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
    Drawer,
    Form,
    Input,
    InputNumber,
    Icon,
    notification,
    Select,
    Upload,
    Button,
    TimePicker,
    Checkbox,
    DatePicker,
    Tooltip,
    Progress,
    Radio,
    Modal,
    Alert,
    Row,
    Col
} from "antd"
import DrawerQuestionario from "./FormQuestionario"
import DrawerAvaliacao from "./FormAvaliacao"
import DrawerQuestionarioProgresso from "./FormQuestionarioProgresso"
import DrawerPaginaHTML from "./FormPaginaHTML"
import DrawerPresencas from "./DrawerPresencas"
import { GlobalContext } from "../../GlobalState"
import { Upload as UploadVimeo } from "tus-js-client"
import axios from "axios"
import moment from "moment"

const { Option } = Select
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    })
}

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514"

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
}

const headerPut = {
    Accept: "application/vnd.vimeo.*+json;version=3.4"
}

class FormAula extends Component {
    static contextType = GlobalContext
    state = {
        //tipos: [],
        linguasTranscricao: [],
        tipoSelecionado: 0,
        //AULA TIPO VIDEO
        video: [],
        videoLista: [],
        video_thumbnail: [],
        transcricaoPT: [],
        transcricaoBR: [],
        transcricaoEN: [],
        transcricaoES: [],
        //AULA TIPO AUDIO
        audio: [],
        audioLista: [],
        //AULA TIPO PDF
        pdf: [],
        pdfLista: [],
        //AULA PRESENCIAL
        criarTarefas: [],
        //AULA TIPO QUESTIONARIO
        drawerQuestionarioVisible: false,
        //AULA TIPO AVALIACAO
        drawerAvaliacaoVisible: false,
        //AULA TIPO QUESTIONARIO PROGRESSO
        drawerQuestProgressoVisible: false,
        //AULA TIPO HTML
        drawerPaginaHTMLVisible: false,
        //LISTA DE PRESENÇAS
        drawerPresencasVisible: false,
        //AULA TIPO DOWNLOAD
        download: [],
        downloadLista: [],
        //AULA TIPO TRABALHO
        enunciado: [],
        enunciadoLista: [],
        // VALIDAÇÕES
        html_status: "",
        html_help: "",
        questionario_status: "",
        questionario_help: "",
        //LOADING
        iconLoading: false,
        iconSending: false,
        //PROGRESS
        upload_progress: 0,
        //RECORENCIA
        recorrente: false,
        recorrenciaIntervalo: 1,
        recorrencia: "SEMANA",
        recorrenciaFim: null,
        //NOTIFICAR
        notificar: false
    }

    //CAHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000
        //CM this.listarTipos();
        //CM this.linguasTransCurso(this.props.cursoid);
    }

    //CHAMADA APOS HAVER UPDATE NO RENDER
    componentDidUpdate() {
        if (this.props.reset) {
            this.setState({
                video: this.props.video,
                videoLista: this.props.videoLista,
                video_thumbnail: this.props.video_thumbnail,
                transcricaoPT: this.props.transcricaoPT,
                transcricaoBR: this.props.transcricaoBR,
                transcricaoEN: this.props.transcricaoEN,
                transcricaoES: this.props.transcricaoES,
                audio: this.props.audio,
                audioLista: this.props.audioLista,
                pdf: this.props.pdf,
                pdfLista: this.props.pdfLista,
                download: this.props.download,
                downloadLista: this.props.downloadLista,
                enunciado: this.props.enunciado,
                enunciadoLista: this.props.enunciadoLista,
                html_status: "",
                html_help: "",
                questionario_status: "",
                questionario_help: ""
            }, () => {
                this.props.stopReset();
                this.linguasTransCurso(localStorage.getItem("codigo_curso"))
            });
        }
    }

    //LIMPA O ESTADO E PASSA O TEXTO PARA O PARENT
    //handleChangeEditor = event => {
    //    this.setState({
    //        html_status: "",
    //        html_help: ""
    //    })
    //    this.props.handleChangeEditor(event.editor.getData())
    //}
    handleChangeEditor = text => {
        this.setState({
            html_status: "",
            html_help: ""
        })
        this.props.handleChangeEditor(text)
    }

    handlerChangeSelectTipo = value => {
        if (value === 1) this.linguasTransCurso(this.props.cursoid)
    }

    handlerChangeSelect = recorrencia => {
        this.setState({
            recorrencia
        })
    }

    handlerChangeCheck = event => {
        this.setState({
            notificar: event.target.checked
        })
    }

    handlerChangeRecorrenciaIntervalo = value => {
        this.setState({
            recorrenciaIntervalo: value
        });
    };

    handlerChangeRadio = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeCheckboxGroup = criarTarefas => {
        this.setState({
            criarTarefas
        })
    }

    //LIMPA O ESTADO E PASSA AS QUESTOES PARA O PARENT
    atualizarQuestionario = questoes => {
        this.props.alterarQuestionario(questoes)
        this.setState({
            questionario_status: "",
            questionario_help: ""
        })
    }

    //LIMPA O ESTADO E PASSA AS QUESTOES PARA O PARENT
    atualizarAvaliacao = questoes => {
        this.props.alterarAvaliacao(questoes)
        this.setState({
            questionario_status: "",
            questionario_help: ""
        })
    }

    //LIMPA O ESTADO E PASSA AS QUESTOES PARA O PARENT
    atualizarQuestProgresso = questoes => {
        this.props.alterarQuestProgresso(questoes)
        this.setState({
            questionario_status: "",
            questionario_help: ""
        })
    }

    //CARREGA E LISTA OS TIPOS DE AULA
    //listarTipos = () => {
    //    axios({
    //        method: "get",
    //        url: "/api/curso/listar-tipos"
    //    })
    //        .then(response => {
    //            this.setState({
    //                tipos: response.data
    //            })
    //        })
    //        .catch(() => { })
    //}

    //CARREGA E LISTA AS LINGUAS QUE EXISTEM NA BD
    linguasTransCurso = cursoid => {
        axios({
            method: "get",
            url: "/api/curso/carregar-curso-linguas",
            params: {
                cursoid
            }
        })
            .then(response => {
                this.setState({
                    linguasTranscricao: response.data
                })
            })
            .catch(() => { })
    }

    criar = event => {
        event.preventDefault()

        switch (this.props.tipoAula) {
            case 1:
                this.criarVideoVimeo()
                break
            case 2:
                this.criarAudio()
                break
            case 3:
                this.criarHtml()
                break
            case 4:
                this.criarPdf()
                break
            case 5:
                switch (this.props.tipoQuestionario) {
                    case 14:
                        this.criarQuestionarioProgresso()
                        break;
                    default:
                        this.criarQuestionario()
                        break;
                }
                break
            case 6:
                this.criarAvaliacao()
                break
            case 7:
                this.criarDownload()
                break
            case 8:
                this.criarTrabalho()
                break
            case 9:
                this.criarLink()
                break
            case 10:
                this.criarSumario()
                break
            case 11:
                this.criarPresencial()
                break
            case 12:
                this.criarHtml()
                break
            case 13:
                this.criarPasta()
                break
            case 17:
                this.criarPresencas()
                break
        }
    }

    criarVideoVimeo = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true, iconSending: true })

                const file = this.state.video[0]
                const fileName = file.name
                const fileSize = file.size.toString()

                axios({
                    method: "post",
                    url: `https://api.vimeo.com/me/videos`,
                    transformRequest: [function (data, headers) {
                        delete headers.common['apikey'];

                        return JSON.stringify(data);
                    }],
                    headers: headerPost,
                    data: {
                        upload: {
                            status: "in_progress",
                            approach: "tus",
                            size: fileSize
                        },
                        name: this.props.tituloAula.trim(),
                        privacy: { view: "disable" }
                    }
                }).then(response => {
                    this.uploadVimeo(file, response)
                })
            }
        })
    }

    uploadVimeo = (file, response) => {
        const upload = new UploadVimeo(file, {
            endPoint: "https://api.vimeo.com/me/videos",
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1)

                this.setState({
                    upload_progress: percentage
                })
            },
            onSuccess: () => {
                var videoId = response.data.link.split("/").pop()
                this.moverParaPasta(videoId)
                this.adicionarDominioEPreset(videoId)
                this.criarVideo(videoId, file.name)
            }
        })

        upload.start()
    }

    moverParaPasta = videoId => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/me/projects/${this.context.albumId_entidade}/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    adicionarDominioEPreset = videoId => {
        axios({
            method: "get",
            url: "/api/curso/carregar-vimeo-configs",
        }).then(response => {
            this.adicionarPreset(videoId, response.data.presetId);
            this.adicionarDominio(videoId, response.data.dominio);
        });
    };

    adicionarPreset = (videoId, presetId) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/presets/${presetId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    adicionarDominio = (videoId, dominio) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/privacy/domains/${dominio}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    criarVideo = (videoId, FileName) => {
        var item = new FormData()
        item.append("id_curso_modulo", this.props.moduloid)
        if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
        item.append("id_tipo_recurso", this.props.tipoAula)
        item.append("nome", this.props.tituloAula.trim())
        item.append("objetivos", this.props.objetivosAula)
        item.append("ordem", this.props.ordem)
        //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
        item.append("recurso_nome", FileName)
        item.append("recurso", videoId)
        item.append("aulalvl2", this.props.aulalvl2)
        item.append("notificar", this.state.notificar)

        axios({
            method: "post",
            url: "/api/curso-aula/criar-aula-video",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                if (this.state.transcricaoPT.length > 0)
                    this.criarVideoTrackVimeo(this.state.transcricaoPT[0], videoId, "pt", response.data, 1)

                if (this.state.transcricaoBR.length > 0)
                    this.criarVideoTrackVimeo(this.state.transcricaoBR[0], videoId, "pt-BR", response.data, 2)

                if (this.state.transcricaoEN.length > 0)
                    this.criarVideoTrackVimeo(this.state.transcricaoEN[0], videoId, "en", response.data, 3)

                if (this.state.transcricaoES.length > 0)
                    this.criarVideoTrackVimeo(this.state.transcricaoES[0], videoId, "es", response.data, 4)

                this.setState({
                    iconLoading: false,
                    iconSending: false
                })
                this.props.criarAula(this.props.moduloid)
                this.props.form.resetFields()
            })
            .catch(error => {
                this.setState({ iconLoading: false, iconSending: false })
                openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                this.removerVideoVimeoErro(videoId);
            })
    }

    criarVideoTrackVimeo = (file, videoId, tipo, aulaId, tipoLingua) => {
        const fileName = file.name
        const fileSize = file.size.toString()

        axios({
            method: "post",
            url: `https://api.vimeo.com/videos/${videoId}/texttracks`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost,
            data: {
                type: "subtitles",
                language: tipo,
                name: fileName
            }
        }).then(response => {
            this.uploadTrackVimeo(file, response.data.link)
            this.adicionarLegendaVideo(response.data.uri.split("/").pop(), fileName, aulaId, tipoLingua)
        })
    }

    uploadTrackVimeo = (file, link) => {
        axios({
            method: "put",
            url: link,
            headers: headerPut,
            data: file
        })
    }

    adicionarLegendaVideo = (legendaId, FileName, aulaId, tipoLingua) => {
        var item = new FormData()
        item.append("id_modulo_aula", aulaId)
        item.append("id_lingua", tipoLingua)
        item.append("transcricao_nome", FileName)
        item.append("transcricao", legendaId)

        axios({
            method: "post",
            url: "/api/curso-aula/adicionar-legenda",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(() => { })
            .catch(error => {
                this.setState({ iconLoading: false })
                openNotificationWithIcon("error", "Erro", "Ocorreu um erro!")
            })
    }

    //CRIA UMA NOVA AULA DO TIPO AUDIO
    criarAudio = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("recurso", this.state.audio[0])
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula-audio",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO HTML
    criarHtml = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.recursoAula) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("recurso", this.props.recursoAula)
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            } else {
                if (!this.props.recursoAula)
                    this.setState({
                        html_status: "error",
                        html_help: "Campo obrigatório"
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO PDF
    criarPdf = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("recurso", this.state.pdf[0])
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula-pdf",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO QUESTIONARIO
    criarQuestionario = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.questionario) {
                this.setState({ iconLoading: true })
                var questionario = []

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2)
                    item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoQuestionario)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)
                item.append("avaliacao_competencia", this.props.avaliacao_competencia)

                questionario = this.props.questoes

                questionario.forEach(quest => {
                    if (quest.ficheiro_pergunta[0]) {
                        item.append(quest.ficheiro_pergunta[0].uid, quest.ficheiro_pergunta[0])
                    }
                    if (quest.ficheiro_resolucao[0]) {
                        item.append(quest.ficheiro_resolucao[0].uid, quest.ficheiro_resolucao[0])
                    }
                })
                if (this.props.questionario)
                    item.append("questionario", JSON.stringify(questionario))

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula-questionario",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            } else {
                if (!this.props.questionario)
                    this.setState({
                        questionario_status: "error",
                        questionario_help: "Campo obrigatório"
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO AVALIACAO
    criarAvaliacao = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.questionario) {
                this.setState({ iconLoading: true })
                var questionario = []

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("permitir_exportar", this.props.permitir_exportar)
                item.append("atribuir_classificacao", this.props.atribuir_classificacao)
                item.append("notificar", this.state.notificar)
                item.append("avaliacao_competencia", this.props.avaliacao_competencia)

                questionario = this.props.questoes
                questionario.forEach(quest => {
                    if (quest.ficheiro_pergunta[0]) {
                        item.append(quest.ficheiro_pergunta[0].uid, quest.ficheiro_pergunta[0])
                    }
                    if (quest.ficheiro_resolucao[0]) {
                        item.append(quest.ficheiro_resolucao[0].uid, quest.ficheiro_resolucao[0])
                    }
                })
                item.append("questionario", this.props.questionario ? JSON.stringify(questionario) : "")

                item.append("timer", this.props.timer)
                if (this.props.dt_inicioAula) {
                    item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                    if (this.props.horaAula) item.append("hora", this.props.horaAula.format("HH:mm"))
                }
                item.append("gerar_aleatorio", this.props.gerar_aleatorio)
                item.append("permissao", this.props.permissaoAula)
                item.append("baralhar_questoes", this.props.baralhar_questoes)
                item.append("baralhar_opcoes", this.props.baralhar_opcoes)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula-questionario",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada. Tarefa fora da data prevista para a Atividade!")
                            else
                                openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")
                        }

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.context.atualizarEventosHoje();
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            } else {
                if (!this.props.questionario)
                    this.setState({
                        questionario_status: "error",
                        questionario_help: "Campo obrigatório"
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO DOWNLOAD
    criarDownload = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("recurso", this.state.download[0])
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula-download",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO TRABALHO
    criarTrabalho = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2) {
                    item.append("id_modulo_aula", this.props.aulaid)
                }
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("descricao", this.props.descricaoAula)
                if (this.state.enunciado.length > 0) {
                    item.append("recurso", this.state.enunciado[0])
                }
                if (this.props.dt_inicioAula) {
                    item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                }
                if (this.props.dt_fimAula) {
                    item.append("dt_fim", moment(this.props.dt_fimAula).format("YYYY/MM/DD"))
                }
                item.append("tipo_trabalho", this.props.tipo_trabalhoAula)
                item.append("numero_elementos", this.props.numero_elementosAula)
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)
                item.append("avaliacao_competencia", this.props.avaliacao_competencia)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula-trabalho",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (moment(this.props.dt_fimAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_fimAula) > moment(this.props.dt_modulo_fim).endOf("day") || moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada. Tarefa fora da data prevista para a Atividade!")
                            else
                                openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")
                        }

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.context.atualizarEventosHoje();
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO LINK
    criarLink = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("recurso", this.props.link)
                item.append("link_embed", this.props.link_embed)
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO SUMARIO
    criarSumario = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })
                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2)
                    item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                if (this.props.aulalvl2 && this.props.aulaTipo === 11) {
                    item.append("duracao", this.props.tempDuracao.format("HH:mm"))
                    item.append("dt_inicio", moment(this.props.tempData).format("YYYY/MM/DD"))
                    if (this.props.tempHora) {
                        item.append("hora", this.props.tempHora.format("HH:mm"))
                    }
                }
                else {
                    item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                    item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                    if (this.props.horaAula) {
                        item.append("hora", this.props.horaAula.format("HH:mm"))
                    }
                }
                item.append("recurso", this.props.recursoAula)
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada. Tarefa fora da data prevista para a Atividade!")
                            else
                                openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")
                        }

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.context.atualizarEventosHoje();
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO PRESENCIAL
    criarPresencial = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2)
                    item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                //PRESENCIAL
                item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                if (this.props.horaAula)
                    item.append("hora", this.props.horaAula.format("HH:mm"))
                item.append("recurso", this.props.localAula)
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)
                item.append("criarTarefas", JSON.stringify(this.state.criarTarefas))
                if (this.state.recorrente) {
                    item.append("recorrente", this.state.recorrente);
                    item.append("recorrenciaIntervalo", this.state.recorrenciaIntervalo);
                    item.append("recorrencia", this.state.recorrencia);
                    item.append("recorrenciaFim", moment(this.state.recorrenciaFim).format("YYYY/MM/DD"));
                }

                axios({
                    method: "post",
                    url: !this.state.recorrente ? "/api/curso-aula/criar-aula" : "/api/curso-aula/criar-aula-recorrencia",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(response => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (!this.state.recorrente) {
                                if (moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                    openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada. Tarefa fora da data prevista para a Atividade!")
                                else
                                    openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")
                            }
                            else {
                                if (response.data.totalFalhas > 0)
                                    openNotificationWithIcon("success", "Sucesso", `Criadas ${response.data.totalPrevisto - response.data.totalFalhas} Stepmeets das ${response.data.totalPrevisto} pretendidas`);
                                else
                                    openNotificationWithIcon("success", "Sucesso", `Criadas ${response.data.totalPrevisto} Stepmeets`);
                            }
                        }

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.context.atualizarEventosHoje();
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO PASTA
    criarPasta = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula-pasta",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO PRESENCAS
    criarPresencas = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                //if (this.props.horaAula)
                //    item.append("hora", this.props.horaAula.format("HH:mm"))
                //if (this.props.horaFimAula)
                //    item.append("hora_fim", this.props.horaFimAula.format("HH:mm"))
                item.append("dt_inicio", moment(this.props.tempData).format("YYYY/MM/DD"))
                if (this.props.tempHora)
                    item.append("hora", this.props.tempHora.format("HH:mm"))
                if (this.props.tempHoraFim)
                    item.append("hora_fim", this.props.tempHoraFim.format("HH:mm"))
                item.append("aulalvl2", this.props.aulalvl2)

                var presencas = {
                    presenca_coletiva: this.props.presenca,
                    falta_coletiva: this.props.falta,
                    presencas: this.props.presencas
                }

                item.append("recurso", JSON.stringify(presencas))

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada. Tarefa fora da data prevista para a Atividade!")
                            else
                                openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")
                        }

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO PASTA
    criarAvaliacaoCompetencias = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                if (this.props.aulalvl2)
                    item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAvaliacao)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula-avaliacao-competencias",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //CRIA UMA NOVA AULA DO TIPO QUESTIONARIO PROGRESSO
    criarQuestionarioProgresso = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.questionario) {
                this.setState({ iconLoading: true })
                var questionario = []

                var item = new FormData()
                item.append("id_curso_modulo", this.props.moduloid)
                item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoQuestionario)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("aulalvl2", this.props.aulalvl2)
                item.append("notificar", this.state.notificar)
                item.append("avaliacao_competencia", this.props.avaliacao_competencia)

                questionario = this.props.questoes

                questionario.forEach(quest => {
                    if (quest.ficheiro_pergunta[0]) {
                        item.append(quest.ficheiro_pergunta[0].uid, quest.ficheiro_pergunta[0])
                    }
                })
                item.append("questionario", this.props.questionario ? JSON.stringify(questionario) : "")
                item.append("gerar_aleatorio", this.props.gerar_aleatorio)

                axios({
                    method: "post",
                    url: "/api/curso-aula/criar-aula-questionario-progresso",
                    data: item,
                    timeout: 60 * 30 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova tarefa adicionada!")

                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.props.form.resetFields()
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                        this.setState({ iconLoading: false })
                    })
            } else {
                if (!this.props.questionario)
                    this.setState({
                        questionario_status: "error",
                        questionario_help: "Campo obrigatório"
                    })
            }
        })
    }

    alterar = event => {
        event.preventDefault()

        switch (this.props.tipoAula) {
            case 1:
                if (this.state.video.length)
                    this.alterarVideoVimeo()
                else
                    this.alterarVideo();
                break
            case 2:
                this.alterarAudio()
                break
            case 3:
                this.alterarHtml()
                break
            case 4:
                this.alterarPdf()
                break
            case 5:
                switch (this.props.tipoQuestionario) {
                    case 14:
                        this.alterarQuestionarioProgresso()
                        break;
                    default:
                        this.alterarQuestionario()
                        break;
                }
                break
            case 6:
                this.alterarAvaliacao()
                break
            case 7:
                this.alterarDownload()
                break
            case 8:
                this.alterarTrabalho()
                break
            case 9:
                this.alterarLink()
                break
            case 10:
                this.alterarSumario()
                break
            case 11:
                this.alterarPresencial()
                break
            case 12:
                this.alterarHtml()
                break
            case 13:
                this.alterarPasta()
                break
            case 17:
                this.alterarPresencas()
                break
        }
    }

    alterarVideoVimeo = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true, iconSending: true })

                if (this.state.video[0].status === "done") {
                    this.alterarVideo(this.props.recurso, this.state.video[0].name)
                } else {
                    const file = this.state.video[0]
                    const fileName = file.name
                    const fileSize = file.size.toString()

                    axios({
                        method: "post",
                        url: `https://api.vimeo.com/me/videos`,
                        transformRequest: [function (data, headers) {
                            delete headers.common['apikey'];

                            return JSON.stringify(data);
                        }],
                        headers: headerPost,
                        data: {
                            upload: {
                                status: "in_progress",
                                approach: "tus",
                                size: fileSize
                            },
                            name: this.props.tituloAula.trim(),
                            privacy: { view: "disable" }
                        }
                    }).then(response => {
                        this.alterarUploadVimeo(file, response)
                    })
                }
            }
        })
    }

    alterarUploadVimeo = (file, response) => {
        const upload = new UploadVimeo(file, {
            endPoint: `https://api.vimeo.com/videos/${this.props.recurso}/versions`,
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1)

                this.setState({
                    upload_progress: percentage
                })
            },
            onSuccess: () => {
                this.removerVideoVimeoAnterior(this.props.recurso)
                var videoId = response.data.link.split("/").pop()
                this.moverParaPasta(videoId, file.name)
                this.adicionarDominioEPreset(videoId)
                this.alterarVideo(videoId, file.name)
            }
        })

        upload.start()
    }

    removerVideoVimeoAnterior = videoId => {
        axios({
            method: "delete",
            url: `https://api.vimeo.com/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    removerVideoVimeoErro = videoId => {
        axios({
            method: "delete",
            url: `https://api.vimeo.com/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarVideo = (videoId, FileName) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })
                var id = !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2

                var item = new FormData()
                item.append("id", id)
                if (this.props.aulalvl2)
                    item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                if (videoId) {
                    item.append("recurso_nome", FileName)
                    item.append("recurso", videoId)
                }
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula-video",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        this.setState({ iconLoading: false, iconSending: false })
                        this.props.criarAula(this.props.moduloid)

                        if (this.state.transcricaoPT.length > 0) {
                            if (this.state.transcricaoPT[0].status === "done") {
                                this.adicionarLegendaVideo(
                                    this.props.transcricaoPTId,
                                    this.state.transcricaoPT[0].name,
                                    id,
                                    1
                                )
                            } else {
                                this.criarVideoTrackVimeo(this.state.transcricaoPT[0], videoId, "pt", id, 1)
                            }
                        } else {
                            if (this.props.transcricaoPTId > 0)
                                this.excluirLegendaVimeo(videoId, this.props.transcricaoPTId, id, 1)
                        }

                        if (this.state.transcricaoBR.length > 0) {
                            if (this.state.transcricaoBR[0].status === "done") {
                                this.adicionarLegendaVideo(
                                    this.props.transcricaoBRId,
                                    this.state.transcricaoBR[0].name,
                                    id,
                                    2
                                )
                            } else {
                                this.criarVideoTrackVimeo(this.state.transcricaoBR[0], videoId, "pt-BR", id, 2)
                            }
                        } else {
                            if (this.props.transcricaoBRId > 0)
                                this.excluirLegendaVimeo(videoId, this.props.transcricaoBRId, id, 2)
                        }

                        if (this.state.transcricaoEN.length > 0) {
                            if (this.state.transcricaoEN[0].status === "done") {
                                this.adicionarLegendaVideo(
                                    this.props.transcricaoENId,
                                    this.state.transcricaoEN[0].name,
                                    id,
                                    3
                                )
                            } else {
                                this.criarVideoTrackVimeo(this.state.transcricaoEN[0], videoId, "en", id, 3)
                            }
                        } else {
                            if (this.props.transcricaoENId > 0)
                                this.excluirLegendaVimeo(videoId, this.props.transcricaoENId, id, 3)
                        }

                        if (this.state.transcricaoES.length > 0) {
                            if (this.state.transcricaoES[0].status === "done") {
                                this.adicionarLegendaVideo(
                                    this.props.transcricaoESId,
                                    this.state.transcricaoES[0].name,
                                    id,
                                    4
                                )
                            } else {
                                this.criarVideoTrackVimeo(this.state.transcricaoES[0], videoId, "es", id, 4)
                            }
                        } else {
                            if (this.props.transcricaoESId > 0)
                                this.excluirLegendaVimeo(videoId, this.props.transcricaoESId, id, 4)
                        }
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false, iconSending: false })
                        this.removerVideoVimeoErro(videoId);
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarAudio = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                if (this.state.audio.length)
                    item.append("recurso", this.state.audio[0])
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula-audio",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarHtml = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.recursoAula) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("recurso", this.props.recursoAula)
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            } else {
                if (!this.props.recursoAula)
                    this.setState({
                        html_status: "error",
                        html_help: "Campo obrigatório"
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarPdf = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                if (this.state.pdf.length)
                    item.append("recurso", this.state.pdf[0])
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula-pdf",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarQuestionario = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.questionario) {
                this.setState({ iconLoading: true })
                var questionario = []

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoQuestionario)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("avaliacao_competencia", this.props.avaliacao_competencia)

                questionario = this.props.questoes
                questionario.forEach(quest => {
                    if (quest.ficheiro_resolucao[0]) {
                        item.append(quest.ficheiro_resolucao[0].uid, quest.ficheiro_resolucao[0])
                    }

                    if (quest.ficheiro_pergunta[0]) {
                        item.append(quest.ficheiro_pergunta[0].uid, quest.ficheiro_pergunta[0])
                    }
                })
                item.append("questionario", this.props.questionario ? JSON.stringify(questionario) : "")
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula-questionario",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            } else {
                if (!this.props.questionario)
                    this.setState({
                        questionario_status: "error",
                        questionario_help: "Campo obrigatório"
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarAvaliacao = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.questionario) {
                this.setState({ iconLoading: true })
                var questionario = []

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("permitir_exportar", this.props.permitir_exportar)
                item.append("atribuir_classificacao", this.props.atribuir_classificacao)
                item.append("avaliacao_competencia", this.props.avaliacao_competencia)

                questionario = this.props.questoes

                questionario.forEach(quest => {
                    if (quest.ficheiro_resolucao[0]) {
                        item.append(quest.ficheiro_resolucao[0].uid, quest.ficheiro_resolucao[0])
                    }

                    if (quest.ficheiro_pergunta[0]) {
                        item.append(quest.ficheiro_pergunta[0].uid, quest.ficheiro_pergunta[0])
                    }
                })

                item.append("questionario", this.props.questionario ? JSON.stringify(questionario) : "")

                item.append("timer", this.props.timer)
                if (this.props.dt_inicioAula) {
                    item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                    if (this.props.horaAula) item.append("hora", this.props.horaAula.format("HH:mm"))
                }
                item.append("gerar_aleatorio", this.props.gerar_aleatorio)
                item.append("permissao", this.props.permissaoAula)
                item.append("baralhar_questoes", this.props.baralhar_questoes)
                item.append("baralhar_opcoes", this.props.baralhar_opcoes)
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula-questionario",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada! Tarefa fora da data prevista para a Atividade!")
                            else
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        }
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.context.atualizarEventosHoje();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            } else {
                if (!this.props.questionario)
                    this.setState({
                        questionario_status: "error",
                        questionario_help: "Campo obrigatório"
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarDownload = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                if (this.state.download.length)
                    item.append("recurso", this.state.download[0])
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula-download",
                    data: item,
                    timeout: 60 * 10 * 1000,
                    params: {
                        aulalvl2: this.props.aulalvl2
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarTrabalho = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("descricao", this.props.descricaoAula)
                if (this.state.enunciado.length > 0) item.append("recurso", this.state.enunciado[0])
                if (this.props.dt_inicioAula)
                    item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                if (this.props.dt_fimAula) item.append("dt_fim", moment(this.props.dt_fimAula).format("YYYY/MM/DD"))
                item.append("tipo_trabalho", this.props.tipo_trabalhoAula)
                item.append("numero_elementos", this.props.numero_elementosAula)
                item.append("avaliacao_competencia", this.props.avaliacao_competencia)
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula-trabalho",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (moment(this.props.dt_fimAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_fimAula) > moment(this.props.dt_modulo_fim).endOf("day") || moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada! Tarefa fora da data prevista para a Atividade!")
                            else
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        }
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.context.atualizarEventosHoje();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarLink = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("recurso", this.props.link)
                item.append("link_embed", this.props.link_embed)
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarSumario = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                if (!this.props.aulalvl2 || this.props.aulalvl2 && this.props.aulaTipo !== 11) {
                    item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                    item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                    if (this.props.horaAula) {
                        item.append("hora", this.props.horaAula.format("HH:mm"))
                    }
                }
                item.append("recurso", this.props.recursoAula)
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada! Tarefa fora da data prevista para a Atividade!")
                            else
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        }
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.context.atualizarEventosHoje();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarPresencial = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", !this.props.aulalvl2 ? this.props.aulaid : this.props.aulaidlvl2)
                if (this.props.aulalvl2) item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                if (this.props.horaAula) item.append("hora", this.props.horaAula.format("HH:mm"))
                item.append("recurso", this.props.localAula)
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada! Tarefa fora da data prevista para a Atividade!")
                            else
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        }
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                        this.context.atualizarEventosHoje();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarPasta = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", this.props.aulaid)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula-pasta",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarQuestionarioProgresso = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.questionario) {
                this.setState({ iconLoading: true })
                var questionario = []

                var item = new FormData()
                item.append("id", this.props.aulaidlvl2)
                item.append("id_modulo_aula", this.props.aulaid)
                item.append("id_tipo_recurso", this.props.tipoQuestionario)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("duracao", this.props.duracaoAula.format("HH:mm"))
                item.append("avaliacao_competencia", this.props.avaliacao_competencia)

                questionario = this.props.questoes

                questionario.forEach(quest => {
                    if (quest.ficheiro_resolucao[0]) {
                        item.append(quest.ficheiro_resolucao[0].uid, quest.ficheiro_resolucao[0])
                    }

                    if (quest.ficheiro_pergunta[0]) {
                        item.append(quest.ficheiro_pergunta[0].uid, quest.ficheiro_pergunta[0])
                    }
                })

                item.append("questionario", this.props.questionario ? JSON.stringify(questionario) : "")
                item.append("gerar_aleatorio", this.props.gerar_aleatorio)
                item.append("aulalvl2", this.props.aulalvl2)

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula-questionario",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            } else {
                if (!this.props.questionario)
                    this.setState({
                        questionario_status: "error",
                        questionario_help: "Campo obrigatório"
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    alterarPresencas = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                var item = new FormData()
                item.append("id", this.props.aulaidlvl2)
                item.append("id_tipo_recurso", this.props.tipoAula)
                item.append("nome", this.props.tituloAula.trim())
                item.append("objetivos", this.props.objetivosAula)
                item.append("ordem", this.props.ordem)
                //item.append("dt_inicio", moment(this.props.dt_inicioAula).format("YYYY/MM/DD"))
                //if (this.props.horaAula)
                //    item.append("hora", this.props.horaAula.format("HH:mm"))
                //if (this.props.horaFimAula)
                //    item.append("hora_fim", this.props.horaFimAula.format("HH:mm"))
                item.append("aulalvl2", this.props.aulalvl2)

                var presencas = {
                    presenca_coletiva: this.props.presenca,
                    falta_coletiva: this.props.falta,
                    presencas: this.props.presencas
                }

                item.append("recurso", JSON.stringify(presencas))

                axios({
                    method: "post",
                    url: "/api/curso-aula/alterar-aula",
                    data: item,
                    timeout: 60 * 10 * 1000
                })
                    .then(() => {
                        if (this.props.dt_modulo_inicio || this.props.dt_modulo_fim) {
                            if (moment(this.props.dt_inicioAula) < moment(this.props.dt_modulo_inicio).startOf("day") || moment(this.props.dt_inicioAula) > moment(this.props.dt_modulo_fim).endOf("day"))
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada! Tarefa fora da data prevista para a Atividade!")
                            else
                                openNotificationWithIcon("success", "Sucesso", "Tarefa alterada!")
                        }
                        this.setState({ iconLoading: false })
                        this.props.criarAula(this.props.moduloid)
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data)
                        this.setState({ iconLoading: false })
                    })
            }
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    excluirLegendaVimeo = (videoId, legendaId, aulaId, linguaId) => {
        axios({
            method: "delete",
            url: `https://api.vimeo.com/videos/${videoId}/texttracks/${legendaId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        }).then(() => {
            this.excluirLegenda(aulaId, linguaId)
        })
    }

    //ALTERA A AULA EM EDIÇÃO
    excluirLegenda = (aulaId, linguaId) => {
        axios({
            method: "delete",
            url: "/api/curso-aula/excluir-legenda",
            params: {
                aulaId,
                linguaId
            }
        }).catch(error => { })
    }

    //ABRE O DRAWER VAZIO PARA SE CRIAR UM NOVO HTML OU QUESTIONARIO/AVALIACAO
    onOpenDrawer = () => {
        this.setState({
            drawerQuestionarioVisible: true,
            drawerPaginaHTMLVisible: true
        })
    }

    //FECHA O DRAWER DO HTML(NOVO OU EDIÇÃO)
    onCloseDrawer = () => {
        this.setState({
            drawerQuestionarioVisible: false,
            drawerAvaliacaoVisible: false,
            drawerQuestProgressoVisible: false,
            drawerPaginaHTMLVisible: false
        })
    }

    //CM
    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatos = (formato, formatos_aceites) => {
        var valido = false

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato === formatos_aceites[i]) valido = true
        }

        return valido
    }

    //MP4, MOV, WMV, AVI e FLV - Video
    validar = (rule, value, callback) => {
        if (value.file)
            switch (rule.field) {
                case "video":
                    if (!this.validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["mp4", "mov", "wmv", "avi", "flv"]) || value.file.size > 5368709120)
                        callback("Campo obrigatório")
                    break
                case "audio":
                    if (!this.validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["mp3"]) || value.file.size > 26214400)
                        callback("Campo obrigatório")
                    break
                case "pdf":
                    if (!this.validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["pdf"]) || value.file.size > 26214400)
                        callback("Campo obrigatório")
                    break
                case "download":
                    if (!this.validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"]) || value.file.size > 26214400)
                        callback("Campo obrigatório")
                    break
            }

        callback()
    }

    afterVisibleChange = aberto => {
        this.props.form.resetFields();
        this.setState({
            //RECORENCIA
            recorrente: false,
            recorrenciaIntervalo: 1,
            recorrencia: "SEMANA",
            recorrenciaFim: null,
            //NOTIFICAR
            notificar: false
        })
    }

    copiarCodigo = () => {
        var iframe = document.createElement("input");
        iframe.value = `<iframe src="https://player.vimeo.com/video/${this.props.recurso}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;

        iframe.style.top = "0";
        iframe.style.left = "0";
        iframe.style.position = "fixed";

        document.body.appendChild(iframe);
        iframe.focus();
        iframe.select();
        iframe.setSelectionRange(0, 99999)
        var sucesso = document.execCommand("copy");
        if (sucesso)
            openNotificationWithIcon("success", "Sucesso", "Código de incorporação copiado!")
        else
            openNotificationWithIcon("error", "Erro", "Ocorreu um erro ao copiar o código!")
    }

    calcular = () => {
        var total = 0;

        if (this.props.dt_inicioAula && this.props.horaAula) {
            let d1 = moment(this.props.horaAula, "HH:mm:ss");
            let d2 = moment(this.props.dt_inicioAula);

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            total = d2.diff(moment(), "hours");
        } else if (this.props.dt_inicioAula)
            total = moment(this.props.dt_inicioAula).diff(moment(), "hours");
        else if (this.props.horaAula) {
            let d1 = moment(this.props.horaAula, "HH:mm:ss");
            let d2 = moment();

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            total = d2.diff(moment(), "hours");
        }

        return total;
    };

    disabledDate = current => {
        return current < moment(this.props.dt_modulo_inicio).startOf("day") || current > moment(this.props.dt_modulo_fim).endOf("day");
    };

    render() {
        const { TextArea } = Input
        const {
            //tipos,
            video,
            videoLista,
            audio,
            audioLista,
            transcricaoPT,
            transcricaoBR,
            transcricaoEN,
            transcricaoES,
            pdf,
            pdfLista,
            download,
            downloadLista,
            enunciado,
            enunciadoLista,
            //LOADING
            iconLoading,
            iconSending,
            //RECORENCIA
            recorrente,
            recorrenciaIntervalo,
            recorrencia,
            recorrenciaFim,
            //NOTIFICAR
            notificar
        } = this.state

        const { getFieldDecorator } = this.props.form
        const Dragger = Upload.Dragger;

        //validarFormatos MP4, MOV, WMV, AVI e FLV
        const propsVideo = {
            accept: ".mp4, .mov, .wmv, .avi, .flv",
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("video")
                this.setState({
                    video: []
                })
            },
            defaultFileList: this.state.video,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["mp4", "mov", "wmv", "avi", "flv"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 5368709120) {
                    openNotificationWithIcon("error", "Erro", "Limite de 5 GB por upload")
                    return false;
                }

                this.setState(state => ({
                    video: [file]
                }))

                return false
            },
            video
        }

        const propsAudio = {
            accept: ".mp3",
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("audio")
                this.setState({
                    audio: []
                })
            },
            defaultFileList: this.state.audio,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["mp3"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }
                this.setState(state => ({
                    audio: [file]
                }))

                return false
            },
            audio
        }

        // legendas SRT, WebVTT, DFXP/TTML, SCC e SAMI
        // srt; .vtt; .dxfp; .scc; .sami
        const propsPT = {
            accept: ".srt, .vtt, .dxfp, .scc, .sami",
            multiple: false,
            onRemove: file => {
                this.setState({
                    transcricaoPT: []
                })
            },
            defaultFileList: this.props.transcricaoPT,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["srt", "vtt", "dxfp", "scc", "sami"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    transcricaoPT: [file]
                }))

                return false
            },
            transcricaoPT
        }

        const propsBR = {
            accept: ".srt, .vtt, .dxfp, .scc, .sami",
            multiple: false,
            onRemove: file => {
                this.setState({
                    transcricaoBR: []
                })
            },
            defaultFileList: this.props.transcricaoBR,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["srt", "vtt", "dxfp", "scc", "sami"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    transcricaoBR: [file]
                }))

                return false
            },
            transcricaoBR
        }

        const propsEN = {
            accept: ".srt, .vtt, .dxfp, .scc, .sami",
            multiple: false,
            onRemove: file => {
                this.setState({
                    transcricaoEN: []
                })
            },
            defaultFileList: this.props.transcricaoEN,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["srt", "vtt", "dxfp", "scc", "sami"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    transcricaoEN: [file]
                }))

                return false
            },
            transcricaoEN
        }

        const propsES = {
            accept: ".srt, .vtt, .dxfp, .scc, .sami",
            multiple: false,
            onRemove: file => {
                this.setState({
                    transcricaoES: []
                })
            },
            defaultFileList: this.props.transcricaoES,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["srt", "vtt", "dxfp", "scc", "sami"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    transcricaoES: [file]
                }))

                return false
            },
            transcricaoES
        }

        const propsPDF = {
            accept: ".pdf",
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("pdf")
                this.setState({
                    pdf: []
                })
            },
            defaultFileList: this.state.pdf,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["pdf"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    pdf: [file]
                }))

                return false
            },
            pdf
        }

        const propsDownload = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("download")
                this.setState({
                    download: []
                })
            },
            defaultFileList: this.state.download,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    download: [file]
                }))

                return false
            },
            download
        }

        const propsEnunciado = {
            accept: ".pdf, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: false,
            onRemove: file => {
                this.setState({
                    enunciado: []
                })
            },
            defaultFileList: this.props.enunciado,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["pdf", "bmp", "gif", "png", "jpeg", "jpg"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    enunciado: [file]
                }))

                return false
            },
            enunciado
        }

        return (
            <>
                <Drawer
                    className="drawer-from-aula"
                    //title={
                    //    (this.props.aulaid > 0 && !this.props.aulalvl2) ||
                    //        (this.props.aulaidlvl2 > 0 && this.props.aulalvl2)
                    //        ? "Editar"
                    //        : "Adicionar"
                    //}
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.drawerAulaVisible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-aula" layout="horizontal">
                            <Form.Item label="Titulo">
                                {getFieldDecorator("tituloAula", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: this.props.tituloAula
                                })(<Input name="tituloAula" maxLength={75} onChange={this.props.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Ordem">
                                {getFieldDecorator("ordemAula", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: this.props.ordem
                                })(<InputNumber className="input-25" name="ordemAula" min={1} onChange={this.props.handlerChangeNumber} />)}
                            </Form.Item>
                            <Form.Item label="Tipo">
                                {getFieldDecorator("tipoAula", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: this.props.tipoAula
                                })(
                                    <Select
                                        className="input-100"
                                        placeholder="Selecionar"
                                        allowClear={true}
                                        disabled={this.props.editar}
                                        onChange={value => {
                                            this.props.handlerChangeSelectTipo(value)
                                            this.handlerChangeSelectTipo(value)
                                        }}
                                    >
                                        <Option value={2}>
                                            Áudio
                                    </Option>
                                        <Option value={6}>
                                            Avaliação
                                    </Option>
                                        <Option value={7}>
                                            Download
                                    </Option>
                                        <Option value={3}>
                                            Editor Web
                                    </Option>
                                        <Option value={9}>
                                            Link
                                    </Option>
                                        <Option value={12}>
                                            Página Web como única tarefa
                                    </Option>
                                        {!this.props.aulalvl2 &&
                                            <Option value={13}>
                                                Pasta
                                        </Option>}
                                        <Option value={4}>
                                            PDF Reader
                                    </Option>
                                        {(this.props.aulalvl2 && this.props.aulaTipo === 11 || this.props.editar) &&
                                            <Option value={17}>
                                                Assiduidade
                                            </Option>
                                        }
                                        <Option value={5}>
                                            Questionário
                                    </Option>
                                        <Option value={11}>
                                            Sessão Presencial
                                    </Option>
                                        <Option value={10}>
                                            Sumário
                                            </Option>
                                        {this.context.conteudos_animados_entidade &&
                                            <Option value={18}>
                                                Tarefas Animadas
                                    </Option>}
                                        <Option value={8}>
                                            Trabalho
                                    </Option>
                                        <Option value={1}>
                                            Vídeo
                                    </Option>
                                    </Select>
                                )}
                                {this.props.tipoAula === 12 &&
                                    <div className="bloco-opcao-descricao">
                                        <span className="opcao-descricao">
                                            Este tipo de conteudo substitui os restantes na mesma atividade
                                            </span>
                                    </div>}
                            </Form.Item>
                            {this.props.tipoAula === 5 &&
                                <Form.Item label="Tipo de Questionário">
                                    {getFieldDecorator("tipoQuestionario", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: this.props.tipoQuestionario
                                    })(
                                        <Select
                                            className="input-100"
                                            placeholder="Selecionar"
                                            allowClear={true}
                                            disabled={this.props.editar}
                                            onChange={value => this.props.handlerChangeSelectTipoQuestionario(value)}
                                        >
                                            <Option value={5}>
                                                Questionário Simples
                                        </Option>
                                            <Option value={15}>
                                                Questionário Dirigido
                                        </Option>
                                            {(this.props.aulalvl2 && this.props.aulaTipo !== 13) &&
                                                <Option value={14}>
                                                    Questionário de Progresso
                                            </Option>}
                                        </Select>
                                    )}
                                    {this.props.tipoQuestionario === 5 &&
                                        <div className="bloco-opcao-descricao">
                                            <span className="opcao-descricao">
                                                Resolução disponibilizada após conclusão
                                            </span>
                                        </div>}
                                    {this.props.tipoQuestionario === 14 &&
                                        <div className="bloco-opcao-descricao">
                                            <span className="opcao-descricao">
                                                Confirma a realização da tarefa principal
                                            </span>
                                        </div>}
                                    {this.props.tipoQuestionario === 15 &&
                                        <div className="bloco-opcao-descricao">
                                            <span className="opcao-descricao">
                                                Resolução disponibilizada após cada questão
                                            </span>
                                        </div>}
                                </Form.Item>}
                            {this.props.tipoAula === 18 &&
                                <Form.Item label="Tipo de Tarefa">
                                    {getFieldDecorator("tipoTarefa", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: this.props.tipoQuestionario
                                    })(
                                        <Select
                                            className="input-100"
                                            placeholder="Selecionar"
                                            allowClear={true}
                                        >
                                            <Option value={1}>Adivinha</Option>
                                            <Option value={2}>Ditado</Option>
                                            <Option value={3}>Jogo da memória</Option>
                                            <Option value={4}>Ligar frases</Option>
                                            <Option value={5}>Preencher espaços</Option>
                                            <Option value={6}>Quiz matemático</Option>
                                        </Select>
                                    )}
                                    <div className="bloco-opcao-descricao">
                                        <Alert
                                            message="A disponibilizar na próxima atualização"
                                            description=""
                                            type="info"
                                            showIcon
                                        />
                                    </div>
                                </Form.Item>}
                            {this.props.tipoAula === 1 ? ( //VIDEO
                                <>
                                    <Form.Item label="Video (MP4, MOV, WMV, AVI, FLV)">
                                        {getFieldDecorator("video", {
                                            rules: [
                                                {
                                                    required: !this.props.editar,
                                                    message: "Campo obrigatório"
                                                },
                                                {
                                                    validator: this.validar
                                                }
                                            ],
                                            initialValue: this.state.video
                                        })(
                                            <Dragger {...propsVideo} fileList={this.state.video}>
                                                <p className="ant-upload-drag-icon">
                                                    <i className="fas fa-upload" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    {this.props.editar ? "Substituir anexo" : "Adicionar anexo"}{" "}
                                                    <Tooltip title="Formatos válidos: .mp4, .mov, .wmv, .avi, .flv">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </p>
                                                <p className="ant-upload-hint">Limite de 5GB por upload</p>
                                            </Dragger>
                                        )}
                                    </Form.Item>
                                    {videoLista.length ?
                                        <Form.Item>
                                            <h3 className="titulo-separador">Anexo</h3>
                                            <div className="lista-videos">
                                                {videoLista.map((ficheiro, index) => (
                                                    <div key={index} className="item-video">
                                                        <span className="video-titulo">{ficheiro}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Form.Item>
                                        : null}
                                    {this.props.editar &&
                                        <Form.Item label="Código de incorporação">
                                            <button className="botao-principal botao-copiar" onClick={this.copiarCodigo}>Copiar código</button>
                                        </Form.Item>}
                                    {this.state.linguasTranscricao.length > 0 && (
                                        <>
                                            <h3 className="titulo-separador">Legendas</h3>
                                            {this.state.linguasTranscricao.map((trans, index) => (
                                                <React.Fragment key={index}>
                                                    {trans.lingua.abreviatura === "PT" ? (
                                                        <Form.Item label="Português">
                                                            <Dragger {...propsPT} fileList={this.state.transcricaoPT}>
                                                                <p className="ant-upload-drag-icon">
                                                                    <i className="fas fa-upload" />
                                                                </p>
                                                                <p className="ant-upload-text">
                                                                    Adicionar anexo{" "}
                                                                    <Tooltip title="Formatos válidos: .srt, .vtt, .dxfp, .scc, .sami">
                                                                        <Icon type="question-circle-o" />
                                                                    </Tooltip>
                                                                </p>
                                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                                            </Dragger>
                                                        </Form.Item>
                                                    ) : null}
                                                    {trans.lingua.abreviatura === "BR" ? (
                                                        <Form.Item label="Português(BR)">
                                                            <Dragger {...propsBR} fileList={this.state.transcricaoBR}>
                                                                <p className="ant-upload-drag-icon">
                                                                    <i className="fas fa-upload" />
                                                                </p>
                                                                <p className="ant-upload-text">
                                                                    Adicionar anexo{" "}
                                                                    <Tooltip title="Formatos válidos: .srt, .vtt, .dxfp, .scc, .sami">
                                                                        <Icon type="question-circle-o" />
                                                                    </Tooltip>
                                                                </p>
                                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                                            </Dragger>
                                                        </Form.Item>
                                                    ) : null}
                                                    {trans.lingua.abreviatura === "EN" ? (
                                                        <Form.Item label="Inglês">
                                                            <Dragger {...propsEN} fileList={this.state.transcricaoEN}>
                                                                <p className="ant-upload-drag-icon">
                                                                    <i className="fas fa-upload" />
                                                                </p>
                                                                <p className="ant-upload-text">
                                                                    Adicionar anexo{" "}
                                                                    <Tooltip title="Formatos válidos: .srt, .vtt, .dxfp, .scc, .sami">
                                                                        <Icon type="question-circle-o" />
                                                                    </Tooltip>
                                                                </p>
                                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                                            </Dragger>
                                                        </Form.Item>
                                                    ) : null}
                                                    {trans.lingua.abreviatura === "ES" ? (
                                                        <Form.Item label="Espanhol">
                                                            <Dragger {...propsES} fileList={this.state.transcricaoES}>
                                                                <p className="ant-upload-drag-icon">
                                                                    <i className="fas fa-upload" />
                                                                </p>
                                                                <p className="ant-upload-text">
                                                                    Adicionar anexo{" "}
                                                                    <Tooltip title="Formatos válidos: .srt, .vtt, .dxfp, .scc, .sami">
                                                                        <Icon type="question-circle-o" />
                                                                    </Tooltip>
                                                                </p>
                                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                                            </Dragger>
                                                        </Form.Item>
                                                    ) : null}
                                                </React.Fragment>
                                            ))}
                                        </>
                                    )}
                                </>
                            ) : null}
                            {this.props.tipoAula === 2 ? ( //AUDIO
                                <>
                                    <Form.Item label="Audio (MP3)">
                                        {getFieldDecorator("audio", {
                                            rules: [
                                                {
                                                    required: !this.props.editar,
                                                    message: "Campo obrigatório"
                                                },
                                                {
                                                    validator: this.validar
                                                }
                                            ],
                                            initialValue: audio
                                        })(
                                            <Dragger {...propsAudio} fileList={audio}>
                                                <p className="ant-upload-drag-icon">
                                                    <i className="fas fa-upload" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Adicionar anexo{" "}
                                                    <Tooltip title="Formato válido: .mp3">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </p>
                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                            </Dragger>
                                        )}
                                    </Form.Item>
                                    {audioLista.length ?
                                        <Form.Item>
                                            <h3 className="titulo-separador">Anexo</h3>
                                            <div className="lista-videos">
                                                {audioLista.map((ficheiro, index) => (
                                                    <div key={index} className="item-video">
                                                        <span className="video-titulo">{ficheiro}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Form.Item>
                                        : null}
                                </>
                            ) : null}
                            {this.props.tipoAula === 3 || this.props.tipoAula === 12 ? ( //PAGINA HTML
                                <>
                                    <Form.Item validateStatus={this.state.html_status} help={this.state.html_help}>
                                        <h3 className="titulo-separador">Editor Web</h3>
                                        {this.props.recursoAula ? (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                style={{ paddingLeft: 0 }}
                                                onClick={() => this.setState({ drawerPaginaHTMLVisible: true })}
                                            >
                                                Editar
                                            </Link>
                                        ) : (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                style={{ paddingLeft: 0 }}
                                                onClick={() => this.setState({ drawerPaginaHTMLVisible: true })}
                                            >
                                                Adicionar
                                            </Link>
                                        )}
                                    </Form.Item>
                                </>
                            ) : null}
                            {this.props.tipoAula === 4 ? ( //PDF
                                <>
                                    <Form.Item label="Ficheiro PDF">
                                        {getFieldDecorator("pdf", {
                                            rules: [
                                                {
                                                    required: !this.props.editar,
                                                    message: "Campo obrigatório"
                                                },
                                                {
                                                    validator: this.validar
                                                }
                                            ],
                                            initialValue: pdf
                                        })(
                                            <Dragger {...propsPDF} fileList={pdf}>
                                                <p className="ant-upload-drag-icon">
                                                    <i className="fas fa-upload" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Adicionar anexo{" "}
                                                    <Tooltip title="Formato válido: .pdf">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </p>
                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                            </Dragger>
                                        )}
                                    </Form.Item>
                                    {pdfLista.length ?
                                        <Form.Item>
                                            <h3 className="titulo-separador">Anexo</h3>
                                            <div className="lista-videos">
                                                {pdfLista.map((ficheiro, index) => (
                                                    <div key={index} className="item-video">
                                                        <span className="video-titulo">{ficheiro}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Form.Item>
                                        : null}
                                </>
                            ) : null}
                            {this.props.tipoAula === 5 && (this.props.tipoQuestionario === 5 || this.props.tipoQuestionario === 15) ? ( //QUESTIONARIO
                                <>
                                    <Form.Item
                                        validateStatus={this.state.questionario_status}
                                        help={this.state.questionario_help}
                                    >
                                        <h3 className="titulo-separador">
                                            Questionário{" "}
                                            {this.props.questionario ? (
                                                <span style={{ fontSize: 12 }}>
                                                    (Nº Questões: {this.props.questoes.length})
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </h3>
                                        {this.props.questionario ? (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                onClick={() => this.setState({ drawerQuestionarioVisible: true })}
                                            >
                                                Editar
                                            </Link>
                                        ) : (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                onClick={() => {
                                                    this.props.adicionarQuestionarioAvaliacao()
                                                    this.setState({ drawerQuestionarioVisible: true })
                                                }}
                                            >
                                                Adicionar
                                            </Link>
                                        )}
                                    </Form.Item>
                                </>
                            ) : null}
                            {this.props.tipoAula === 6 ? ( //AVALIACAO
                                <>
                                    <Form.Item label="Duração">
                                        {getFieldDecorator("duracaoAula", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: this.props.duracaoAula
                                        })(
                                            <TimePicker
                                                className="input-25"
                                                name="duracaoAula"
                                                defaultOpenValue={moment("00:00", "HH:mm")}
                                                format="HH:mm"
                                                onChange={this.props.handleChangeTime}
                                                placeholder="Selecionar"
                                                suffixIcon={false}
                                                clearIcon={false}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Inicio">
                                        <DatePicker
                                            className="input-25"
                                            value={this.props.dt_inicioAula}
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            onChange={this.props.handleChangeDataInicio}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Hora">
                                        <TimePicker
                                            className="input-25"
                                            value={this.props.horaAula}
                                            name="duracaoAula"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.props.handleChangeHora}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                            disabled={!this.props.dt_inicioAula}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        validateStatus={this.state.questionario_status}
                                        help={this.state.questionario_help}
                                    >
                                        <h3 className="titulo-separador">
                                            Avaliação{" "}
                                            {this.props.questionario ? (
                                                <span style={{ fontSize: 12 }}>
                                                    (Nº Questões: {this.props.questoes.length})
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </h3>
                                        {this.props.questionario ? (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                onClick={() => this.setState({ drawerAvaliacaoVisible: true })}
                                            >
                                                Editar
                                            </Link>
                                        ) : (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                onClick={() => {
                                                    this.props.adicionarQuestionarioAvaliacao()
                                                    this.setState({ drawerAvaliacaoVisible: true })
                                                }}
                                            >
                                                Adicionar
                                            </Link>
                                        )}
                                    </Form.Item>
                                    <h3 className="titulo-separador">Navegação</h3>
                                    <Form.Item className="form-item-checkbox">
                                        <Radio.Group onChange={this.props.handlerChangeRadio} value={this.props.permissaoAula}>
                                            <Radio value={1}>Não permitir recuar nas questões</Radio>
                                            <Radio value={2}>Permitir avançar e recuar nas questões</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <h3 className="titulo-separador">Comportamento das questões</h3>
                                    <Form.Item className="form-item-checkbox">
                                        <Checkbox name="baralhar_questoes" checked={this.props.baralhar_questoes} onChange={this.props.handlerChangeCheck}>
                                            Baralhar Questões
                                                </Checkbox>
                                        <Checkbox name="baralhar_opcoes" checked={this.props.baralhar_opcoes} onChange={this.props.handlerChangeCheck}>
                                            Baralhar Opções
                                                </Checkbox>
                                    </Form.Item>
                                    {this.props.editar &&
                                        <>
                                            <h3 className="titulo-separador">Fecho</h3>
                                            <Form.Item className="form-item-checkbox">
                                                <Checkbox name="permitir_exportar" checked={this.props.permitir_exportar} onChange={this.props.handlerChangeCheck}>
                                                    Fechar avaliação para todos os alunos
                                                </Checkbox>
                                            </Form.Item>
                                        </>}
                                </>
                            ) : null}
                            {this.props.tipoAula === 7 ? ( //DOWNLOAD
                                <>
                                    <Form.Item label="Recurso">
                                        {getFieldDecorator("download", {
                                            rules: [
                                                {
                                                    required: !this.props.editar,
                                                    message: "Campo obrigatório"
                                                },
                                                {
                                                    validator: this.validar
                                                }
                                            ],
                                            initialValue: download
                                        })(
                                            <Dragger {...propsDownload} fileList={download}>
                                                <p className="ant-upload-drag-icon">
                                                    <i className="fas fa-upload" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    {this.props.editar ? "Substituir anexo" : "Adicionar anexo"}{" "}
                                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </p>
                                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                            </Dragger>
                                        )}
                                    </Form.Item>
                                    {downloadLista.length ?
                                        <Form.Item>
                                            <h3 className="titulo-separador">Anexo</h3>
                                            <div className="lista-videos">
                                                {downloadLista.map((ficheiro, index) => (
                                                    <div key={index} className="item-video">
                                                        <span className="video-titulo">{ficheiro}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Form.Item>
                                        : null}
                                </>
                            ) : null}
                            {this.props.tipoAula === 8 ? ( //TRABALHO
                                <>
                                    <h3 className="titulo-separador">Enunciado</h3>
                                    <Form.Item label="Texto">
                                        {getFieldDecorator("descricaoAula", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: this.props.descricaoAula
                                        })(
                                            <TextArea
                                                name="descricaoAula"
                                                onChange={this.props.handlerChange}
                                                rows={4}
                                                style={{ height: 100 }}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Anexo">
                                        <Dragger {...propsEnunciado} fileList={enunciado}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formatos válidos: .pdf, .bmp, .gif, .png, .jpeg, .jpg">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    </Form.Item>
                                    {enunciadoLista.length ?
                                        <Form.Item>
                                            <h3 className="titulo-separador">Anexo</h3>
                                            <div className="lista-videos">
                                                {enunciadoLista.map((ficheiro, index) => (
                                                    <div key={index} className="item-video">
                                                        <span className="video-titulo">{ficheiro}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Form.Item>
                                        : null}
                                    <h3 className="titulo-separador">Realização do Trabalho</h3>
                                    <Form.Item label="Inicio">
                                        <DatePicker
                                            className="input-25"
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            name="dt_inicioAula"
                                            value={this.props.dt_inicioAula}
                                            onChange={this.props.handleChangeDataInicio}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Fim">
                                        {getFieldDecorator("dt_fimAula", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: this.props.dt_fimAula
                                        })(
                                            <DatePicker
                                                className="input-25"
                                                placeholder="Selecionar"
                                                format="DD-MM-YYYY"
                                                name="dt_fimAula"
                                                onChange={this.props.handleChangeDataFim}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Tipo de trabalho">
                                        {getFieldDecorator("tipoTrabalho", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: this.props.tipo_trabalhoAula
                                        })(
                                            <Select
                                                className="input-50"
                                                placeholder="Selecionar"
                                                onChange={this.props.handlerChangeSelectTipoTrabalho}
                                            >
                                                <Option value="INDIVIDUAL">Individual</Option>
                                                <Option value="GRUPO">Grupo</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    {this.props.tipo_trabalhoAula !== "INDIVIDUAL" && this.props.tipo_trabalhoAula && (
                                        <Form.Item label="Nº máximo de elementos">
                                            {getFieldDecorator("numero_elementos", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: this.props.numero_elementosAula
                                            })(
                                                <InputNumber
                                                    name="numero_elementosAula"
                                                    min={2}
                                                    defaultValue={2}
                                                    onChange={this.props.handlerChangeNumberNrElementos}
                                                />
                                            )}
                                        </Form.Item>
                                    )}
                                </>
                            ) : null}
                            {this.props.tipoAula === 9 ? ( //LINK
                                <>
                                    <Form.Item label="Link">
                                        {getFieldDecorator("link", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: this.props.link
                                        })(<Input name="link" onChange={this.props.handlerChange} />)}
                                    </Form.Item>
                                    <Form.Item>
                                        <Checkbox checked={this.props.link_embed} name="link_embed" onChange={this.props.handlerChangeCheck}>
                                            Embeber este endereço na stepforma
                                            <Tooltip className="info-icon" title="Alguns sites não permitem serem embebidos em plataformas de terceiros. Pelo que nesse caso ao serem embebidos, este continua sem ser visíveis.">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </Checkbox>
                                    </Form.Item>
                                </>
                            ) : null}
                            {this.props.tipoAula === 10 ? //SUMARIO
                                !this.props.subTarefaSumario || this.props.subTarefaSumario && this.props.aulaTipo !== 11 || this.props.editar ?
                                    (<>
                                        <Form.Item label="Duração">
                                            {getFieldDecorator("duracaoAula", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: this.props.duracaoAula
                                            })(
                                                <TimePicker
                                                    className="input-25"
                                                    name="duracaoAula"
                                                    defaultOpenValue={moment("00:00", "HH:mm")}
                                                    format="HH:mm"
                                                    onChange={this.props.handleChangeTime}
                                                    placeholder="Selecionar"
                                                    suffixIcon={false}
                                                    clearIcon={false}
                                                    disabled={(this.props.aulalvl2 && this.props.aulaTipo === 11)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Data">
                                            {getFieldDecorator("dt_inicio", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: this.props.dt_inicioAula
                                            })(
                                                <DatePicker
                                                    className="input-25"
                                                    placeholder="Selecionar"
                                                    format="DD-MM-YYYY"
                                                    name="dt_ccvalidade"
                                                    onChange={this.props.handleChangeDataInicio}
                                                    disabled={(this.props.aulalvl2 && this.props.aulaTipo === 11)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Hora">
                                            {getFieldDecorator("horaAula", {
                                                initialValue: this.props.horaAula
                                            })(
                                                <TimePicker
                                                    className="input-25"
                                                    name="horaAula"
                                                    defaultOpenValue={moment("00:00", "HH:mm")}
                                                    format="HH:mm"
                                                    onChange={this.props.handleChangeHora}
                                                    placeholder="Selecionar"
                                                    suffixIcon={false}
                                                    clearIcon={false}
                                                    disabled={(this.props.aulalvl2 && this.props.aulaTipo === 11)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Sumário">
                                            {getFieldDecorator("recursoAula", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: this.props.recursoAula
                                            })(
                                                <TextArea
                                                    name="recursoAula"
                                                    onChange={this.props.handlerChange}
                                                    rows={4}
                                                    style={{ height: 100 }}
                                                />
                                            )}
                                        </Form.Item>
                                    </>)
                                    :
                                    <span className="mensagem-erro">Apenas é permitida uma subtarefa Sumário por Sessão Presencial</span>
                                : null}
                            {this.props.tipoAula === 11 ? ( //SESSAO PRESENCIAL
                                <>
                                    <Form.Item label="Duração">
                                        {getFieldDecorator("duracaoAula", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: this.props.duracaoAula
                                        })(
                                            <TimePicker
                                                className="input-25"
                                                name="duracaoAula"
                                                defaultOpenValue={moment("00:00", "HH:mm")}
                                                format="HH:mm"
                                                onChange={this.props.handleChangeTime}
                                                placeholder="Selecionar"
                                                suffixIcon={false}
                                                clearIcon={false}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Data">
                                        {getFieldDecorator("dt_inicio", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: this.props.dt_inicioAula
                                        })(
                                            <DatePicker
                                                className="input-25"
                                                placeholder="Selecionar"
                                                format="DD-MM-YYYY"
                                                name="dt_inicio"
                                                onChange={this.props.handleChangeDataInicio}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Hora">
                                        <TimePicker
                                            className="input-25"
                                            value={this.props.horaAula}
                                            name="hora"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.props.handleChangeHora}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Local">
                                        {getFieldDecorator("local", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: this.props.localAula
                                        })(
                                            <TextArea
                                                name="localAula"
                                                onChange={this.props.handlerChange}
                                                rows={4}
                                                style={{ height: 100 }}
                                            />
                                        )}
                                    </Form.Item>
                                    {!this.props.editar ?
                                        <>
                                            <h3 className="titulo-separador">Outras opções</h3>
                                            <Form.Item className="form-item-checkbox">
                                                <Checkbox.Group className="checkbox-group-criar-tarefas" onChange={this.handlerChangeCheckboxGroup}>
                                                    <Checkbox value="10">Criar tarefa Sumário</Checkbox>
                                                    <Checkbox value="17">Criar tarefa Assiduidade</Checkbox>
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </>
                                        : null}
                                    {!this.props.editar ?
                                        <>
                                            <h3 className="titulo-separador">recorrência</h3>
                                            <Form.Item className="form-item-checkbox">
                                                <Radio.Group name="recorrente" value={recorrente} onChange={this.handlerChangeRadio}>
                                                    <Radio value={false}>Evento único</Radio>
                                                    <Radio value={true}>Recorrente</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </>
                                        : null}
                                    {recorrente &&
                                        <>
                                            <Form.Item>
                                                <Row gutter={8}>
                                                    <Col span={3}>
                                                        <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>A cada</label>
                                                    </Col>
                                                    <Col span={3}>
                                                        <InputNumber value={recorrenciaIntervalo} onChange={this.handlerChangeRecorrenciaIntervalo} />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Select
                                                            className="input-100"
                                                            placeholder="Selecionar"
                                                            defaultValue={recorrencia}
                                                            onChange={this.handlerChangeSelect}
                                                        >
                                                            <Option value="SEMANA">Semana</Option>
                                                            <Option value="MES">Mês</Option>
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                            <Form.Item>
                                                <Row gutter={8}>
                                                    <Col span={4}>
                                                        <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>Repetir até</label>
                                                    </Col>
                                                    <Col span={8}>
                                                        {getFieldDecorator("recorrenciaFim", {
                                                            rules: [{ required: true, message: "Campo obrigatório" }],
                                                            initialValue: recorrenciaFim
                                                        })(<DatePicker
                                                            placeholder="Selecionar"
                                                            format="DD-MM-YYYY"
                                                            name="dia"
                                                            onChange={recorrenciaFim => this.setState({ recorrenciaFim })}
                                                        />)}
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </>}
                                </>
                            ) : null}
                            {this.props.tipoAula === 5 && this.props.tipoQuestionario === 14 ? ( //QUESTIONARIO PROGRESSO
                                <>
                                    <Form.Item
                                        validateStatus={this.state.questionario_status}
                                        help={this.state.questionario_help}
                                    >
                                        <h3 className="titulo-separador">
                                            Questionário{" "}
                                            {this.props.questionario ? (
                                                <span style={{ fontSize: 12 }}>
                                                    (Nº Questões: {this.props.questoes.length})
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </h3>
                                        {this.props.questionario ? (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                onClick={() => this.setState({ drawerQuestProgressoVisible: true })}
                                            >
                                                Editar
                                            </Link>
                                        ) : (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                onClick={() => {
                                                    this.props.adicionarQuestionarioAvaliacao()
                                                    this.setState({ drawerQuestProgressoVisible: true })
                                                }}
                                            >
                                                Adicionar
                                            </Link>
                                        )}
                                    </Form.Item>
                                </>
                            ) : null}
                            {this.props.tipoAula === 17 ? //ASSIDUIDADE
                                <>
                                    {
                                        this.props.subTarefaAssiduidade ?
                                            <span className="mensagem-erro">Apenas é permitida uma subtarefa Assiduidade por Sessão Presencial</span>
                                            : null
                                    }
                                </>
                                : null
                            }
                            {((this.props.tipoAula === 5 || this.props.tipoAula === 6 || this.props.tipoAula === 8) && this.props.existeCompetencias) &&
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox name="avaliacao_competencia" checked={this.props.avaliacao_competencia} onChange={this.props.handlerChangeCheck}>
                                        Incluir na Avalição por Competências
                                                </Checkbox>
                                </Form.Item>}
                            <Form.Item label="Descrição">
                                {getFieldDecorator("objetivosAula", {
                                    initialValue: this.props.objetivosAula
                                })(<TextArea name="objetivosAula" maxLength="200" onChange={this.props.handlerChange} rows="3" style={{ height: "100%" }} />)}
                            </Form.Item>
                            {(!this.props.editar && this.props.tipoAula !== 13 && this.props.tipoAula !== 17 && this.props.tipoAula !== 18 && !(this.props.tipoAula === 17 && this.props.subTarefaAssiduidade) && !(this.props.tipoAula === 10 && this.props.subTarefaSumario)) &&
                                <Form.Item className="form-item-checkbox">
                                    <Checkbox checked={notificar} name="notificar" onChange={this.handlerChangeCheck}>
                                        Notificar por e-mail
                                        <Tooltip className="info-icon" title="Notificar os participantes por e-mail">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>}
                        </Form>
                        <DrawerQuestionario
                            questoes={this.props.questoes}
                            visible={this.state.drawerQuestionarioVisible}
                            onClose={this.onCloseDrawer}
                            alterarQuestionario={this.atualizarQuestionario}
                        />
                        <DrawerAvaliacao
                            questoes={this.props.questoes}
                            visible={this.state.drawerAvaliacaoVisible}
                            onClose={this.onCloseDrawer}
                            alterarAvaliacao={this.atualizarAvaliacao}
                        />
                        <DrawerQuestionarioProgresso
                            questoes={this.props.questoes}
                            visible={this.state.drawerQuestProgressoVisible}
                            onClose={this.onCloseDrawer}
                            alterarQuestProgresso={this.atualizarQuestProgresso}
                        />
                        <DrawerPaginaHTML
                            recurso={this.props.recursoAula}
                            visible={this.state.drawerPaginaHTMLVisible}
                            onClose={this.onCloseDrawer}
                            handleChangeEditor={this.handleChangeEditor}
                        />
                    </div>
                    <div className="ant-drawer-footer">
                        <button
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                    </button>
                        {this.props.editar ? (
                            <button
                                className="botao-principal"
                                type="primary"
                                disabled={iconLoading}
                                onClick={this.alterar}
                                style={{ padding: "0 10px" }}
                            >
                                {iconLoading ? <Icon type="loading" /> : null}
                                Salvar
                            </button>
                        ) : (
                            <button
                                className="botao-principal"
                                type="primary"
                                    disabled={iconLoading || this.props.tipoAula === 18 || (this.props.tipoAula === 17 && this.props.subTarefaSumario)}
                                onClick={this.criar}
                            >
                                {iconLoading ? <Icon type="loading" /> : null}
                                    Salvar
                            </button>
                        )}
                    </div>
                </Drawer>
                <Modal
                    visible={this.props.tipoAula === 1 && iconSending}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Progress type="circle" percent={Number(this.state.upload_progress)} />
                        </p>
                        <p className="texto">A enviar...</p>
                    </div>
                </Modal>
            </>
        )
    }
}
const FormFormAula = Form.create({ name: "form-aula" })(FormAula)

export default FormFormAula
