import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { GlobalContext } from "../../GlobalState";

const ListaColaboradores = ({ todosAtivo, pendentesAtivo, licencasUtilizadas, totalLicencas, acesso }) => {
    const context = useContext(GlobalContext);

    return (
        <div className="lista-colaboradores">
            <ul className="opcoes">
                <li className={`opcao ${todosAtivo}`}>
                    <Link to="/gestao-utilizadores-todos">Todos</Link>
                </li>
                <li className={`opcao ${pendentesAtivo}`}>
                    <Link to="/gestao-utilizadores-pendentes">Pendentes</Link>
                </li>
            </ul>
            <ul className="info">
                <li className="descritivo">
                    Usadas {licencasUtilizadas} de {totalLicencas} licenças
                </li>
                {(licencasUtilizadas < totalLicencas && acesso?.create) && (
                    <>
                        <li className="botao-associar">
                            <Link to="/gestao-utilizadores-associar">Associar</Link>
                        </li>
                        {context.login2_entidade && (
                            <li className="botao-associar-alunos">
                                <Link to="/gestao-associar-alunos">Associar (simplificado)</Link>
                            </li>
                        )}
                    </>
                )}
            </ul>
        </div>
    )
}

export default ListaColaboradores;
