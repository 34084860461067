import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const DateChanger = ({ nome,alterarData,data }) => {
    // const [currentDate, setCurrentDate] = useState(data);

    const incrementDate = () => {
        const newDate = new Date(data);
        newDate.setDate(data.getDate() + 7);
        alterarData(newDate)
        
       
    };

    const decrementDate = () => {
        const newDate = new Date(data);
        newDate.setDate(data.getDate() - 7);
        alterarData(newDate)
        
    };

    const getMonday = (date) => {
        const day = date.getDay(); // Obter o dia da semana (0 = domingo, 1 = segunda-feira, etc.)
        const difference = day === 0 ? -6 : 1 - day; // Se for domingo (0), retroceder para a segunda-feira anterior (-6)
        const monday = new Date(date);
        monday.setDate(date.getDate() + difference); // Ajustar a data para a última segunda-feira
        // alterarData(monday);
        return monday;
    };

    const formatDate = (date) => {
        
        const monday = getMonday(date); // Obter a segunda-feira da semana atual
        const options = { month: 'long' };
        const monthName = monday.toLocaleDateString('pt-BR', options);
        const startDay = monday.getDate();
        const endDay = startDay + 5;
        return `${monthName} ${startDay} - ${endDay}`;
    };

    return (
        <div className="bimestres">
            <div className="nome">
                <span style={{ textTransform: 'uppercase' }}>{nome}</span>
            </div>
            <div className="bimestre">
                <button onClick={decrementDate}><FaAngleLeft color='#647177' /></button>
                <span className="data">{formatDate(data)}</span>
                <button onClick={incrementDate}><FaAngleRight color='#647177' /></button>
            </div>
        </div>
    );
};

export default DateChanger;
