import React, { Component } from "react"
import { Link, matchPath, withRouter, NavLink } from "react-router-dom"
import { Dropdown, Icon } from "antd"
import { GeralContext } from "./ContextProvider"
import { GlobalContext } from "../GlobalState"

class MenuCurso extends Component {
    static contextType = GeralContext;

    render() {
        const { turma } = this.props.match.params;
        var inicioUrl = `/detalhe-turma/${turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}`;

        let menuOpcaoAtivo = ""

        const match = matchPath(window.location.pathname, {
            path: "/detalhe-turma/:turma/:pagina"
        })

        if (match && match.params.pagina) menuOpcaoAtivo = match.params.pagina

        if (this.context.subcursos)
            return (
                <GlobalContext.Consumer>
                    {globalContext => {
                        return (
                            <GeralContext.Consumer>
                                {geralContext => {
                                    const montarOpcoes = () => {
                                        if (geralContext.tipo_inscricao === "DIGITAL" && !geralContext.inscrito && !geralContext.subscricaoAtiva ||
                                            geralContext.inscricao && !geralContext.inscrito && !geralContext.subscricaoAtiva ||
                                            geralContext.subscricao && !geralContext.subscricaoAtiva && !geralContext.inscrito ||
                                            !geralContext.inscricao && geralContext.preco && !geralContext.inscrito ||
                                            geralContext.precedenciaPorConcluir ||
                                            !geralContext.cursoIniciado) {
                                            return (<li>
                                                <Link
                                                    to={`${inicioUrl}/visao-geral`}
                                                    className={menuOpcaoAtivo === "visao-geral" ? "ativo" : ""}
                                                >
                                                    Visão Geral
                                                </Link>
                                            </li>);
                                        }
                                        else {
                                            return (<>
                                                <li>
                                                    <Link
                                                        to={`${inicioUrl}/visao-geral`}
                                                        className={menuOpcaoAtivo === "visao-geral" ? "ativo" : ""}
                                                    >
                                                        Visão Geral
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={`${inicioUrl}/horario`}
                                                        className={menuOpcaoAtivo === "horario" ? "ativo" : ""}
                                                    >
                                                        Horário
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to={`${inicioUrl}/videoconferencias`} className="nav-link" onClick={() => localStorage.removeItem("menu_mensagens")}>
                                                        Stepmeet
                                                    </NavLink>
                                                </li>
                                            </>);
                                        }
                                    }

                                    return (
                                        <div className="menu-detalhe-curso menu-detalhe-curso-v2">
                                            <div className="container">
                                                {(!globalContext.chave_entidade && globalContext.role === "UTILIZADOR") || globalContext.role !== "UTILIZADOR" ? (
                                                    <ul>
                                                        {montarOpcoes()}
                                                    </ul>
                                                ) : (
                                                    <ul>
                                                        <li>
                                                            <Link
                                                                to={`${inicioUrl}/visao-geral`}
                                                                className={menuOpcaoAtivo === "visao-geral" ? "ativo" : ""}
                                                            >
                                                                Visão Geral
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to={`${inicioUrl}/horario`}
                                                                className={menuOpcaoAtivo === "horario" ? "ativo" : ""}
                                                            >
                                                                Horário
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }}
                            </GeralContext.Consumer>
                        )
                    }}
                </GlobalContext.Consumer>
            )

        return (
            <GlobalContext.Consumer>
                {globalContext => {
                    return (
                        <GeralContext.Consumer>
                            {geralContext => {
                                const montarOpcoes = () => {
                                    if (geralContext.tipo_inscricao === "DIGITAL" && !geralContext.inscrito && !geralContext.subscricaoAtiva ||
                                        geralContext.inscricao && !geralContext.inscrito && !geralContext.subscricaoAtiva ||
                                        geralContext.subscricao && !geralContext.subscricaoAtiva && !geralContext.inscrito ||
                                        !geralContext.inscricao && geralContext.preco && !geralContext.inscrito ||
                                        geralContext.precedenciaPorConcluir ||
                                        !geralContext.cursoIniciado) {
                                        return (<li>
                                            <Link
                                                to={`${inicioUrl}/visao-geral-disciplina`}
                                                className={menuOpcaoAtivo === "visao-geral-disciplina" ? "ativo" : ""}
                                            >
                                                Visão Geral
                                            </Link>
                                        </li>);
                                    }
                                    else if (!geralContext.inscrito || !geralContext.inscrito && geralContext.subscricaoAtiva) {
                                        return (<>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/visao-geral-disciplina`}
                                                    className={menuOpcaoAtivo === "visao-geral-disciplina" ? "ativo" : ""}
                                                >
                                                    Visão Geral
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/horario-disciplina`}
                                                    className={menuOpcaoAtivo === "horario-disciplina" ? "ativo" : ""}
                                                >
                                                    Horário
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/modulos`}
                                                    className={menuOpcaoAtivo === "modulos" ? "ativo" : ""}
                                                >
                                                    Conteúdos
                                                </Link>
                                            </li>
                                        </>);
                                    }
                                    else {
                                        return (<>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/visao-geral-disciplina`}
                                                    className={menuOpcaoAtivo === "visao-geral-disciplina" ? "ativo" : ""}
                                                >
                                                    Visão Geral
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/horario-disciplina`}
                                                    className={menuOpcaoAtivo === "horario-disciplina" ? "ativo" : ""}
                                                >
                                                    Horário
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/modulos`}
                                                    className={menuOpcaoAtivo === "modulos" ? "ativo" : ""}
                                                >
                                                    Conteúdos
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/ficheiros`}
                                                    className={menuOpcaoAtivo === "ficheiros" ? "ativo" : ""}
                                                >
                                                    Drive
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/forum`}
                                                    className={`${menuOpcaoAtivo === "forum" ||
                                                        menuOpcaoAtivo === "nova-pergunta" ||
                                                        menuOpcaoAtivo === "forum-detalhe" ||
                                                        menuOpcaoAtivo === "mensagens" ||
                                                        menuOpcaoAtivo === "mensagens-nova" ||
                                                        menuOpcaoAtivo === "mensagens-detalhe"
                                                        ? "ativo" : ""} ant-dropdown-link"`}
                                                >
                                                    Fórum
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${inicioUrl}/videoconferencias`}
                                                    className={menuOpcaoAtivo === "videoconferencias" ? "ativo" : ""}
                                                >
                                                    Stepmeet
                                                </Link>
                                            </li>
                                            {!geralContext.esconderListaParticipantes ?
                                                <li>
                                                    <Link
                                                        to={`${inicioUrl}/participantes`}
                                                        className={menuOpcaoAtivo === "participantes" ? "ativo" : ""}
                                                    >
                                                        Alunos
                                                    </Link>
                                                </li> : null}
                                        </>);
                                    }
                                }

                                return (
                                    <div className="menu-detalhe-curso menu-detalhe-curso-v2">
                                        <div className="container">
                                            {(!globalContext.chave_entidade && globalContext.role === "UTILIZADOR") ||
                                                globalContext.role !== "UTILIZADOR" ? (
                                                <ul>
                                                    {montarOpcoes()}
                                                </ul>
                                            ) : (
                                                <ul>
                                                    <li>
                                                        <Link
                                                                to={`${inicioUrl}/visao-geral-disciplina`}
                                                                className={menuOpcaoAtivo === "visao-geral-disciplina" ? "ativo" : ""}
                                                        >
                                                            Visão Geral
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to={`${inicioUrl}/modulos`}
                                                            className={menuOpcaoAtivo === "modulos" ? "ativo" : ""}
                                                        >
                                                            Atividades
                                                        </Link>
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                )
                            }}
                        </GeralContext.Consumer>
                    )
                }}
            </GlobalContext.Consumer>
        )
    }
}

export default withRouter(MenuCurso)
