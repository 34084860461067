import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import Navbar from "./Navbar";
import CursoLayout from "../ConsultarCursos/CursoLayout";
import Footer from "../Geral/Footer";
import axios from "axios";

class Layout extends Component {
    static contextType = GlobalContext;
    state = {
        redessociais: [],
        fundoBranco: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        if (localStorage.getItem("accessToken"))
            axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

        //this.entidadeRS();
    }

    //componentDidUpdate() {
    //    if (this.context.atualizarEntidadeFooter) {
    //        this.entidadeRS();
    //        this.context.atualizarState({
    //            atualizarEntidadeFooter: false
    //        });
    //    }
    //}

    //entidadeRS = () => {
    //    axios({
    //        method: "get",
    //        timeout: 60 * 10 * 1000,
    //        url: "/api/home-utilizador/entidade-rs"
    //    })
    //        .then(response => {
    //            this.setState({
    //                redessociais: response.data
    //            });
    //        })
    //        .catch(error => {
    //            if (error.response.status === 401)
    //                this.setState({
    //                    redirect: true,
    //                    redirectLink: "/login"
    //                });
    //        });
    //};

    render() {
        const {
            redessociais,
            fundoBranco,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const opcoes = (
            <ul className="links_uteis" style={{ textAlign: "center" }}>
                {/*<li>
                    <Link to="/user-contacto">Contato</Link>
                </li>*/}
                <li>
                    <Link to="/tutoriais">Tutoriais e Manuais</Link>
                </li>
                <li>
                    <Link to="/versoes">Versões</Link>
                </li>
                <li>
                    <Link to="/informacoes">Informações</Link>
                </li>
            </ul>
        );

        return (
            <>
                <Navbar />
                <Switch>
                    <Route exact path="/consultar/:curso/:pagina/:utilizadorid" component={CursoLayout} />
                </Switch>
                <Footer redessociais={redessociais} opcoes={opcoes} fundoBranco={fundoBranco} />
            </>
        );
    }
}

export default Layout;
