import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Icon, notification, Form, Input, Upload, Tooltip, Modal } from "antd";
import { GeralContext } from "../ContextProvider";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class AnotacoesDetalhe extends Component {
    static contextType = GeralContext;
    state = {
        anotacaoId: 0,
        cursoId: 0,
        curso_nome: "",
        retomar: {},
        topico: "",
        motivo: "",
        motivoOutro: false,
        outro: "",
        //ANOTACAO
        periodo: "",
        fracao: "",
        texto: "",
        //anexo: "",
        ficheiros: [],
        fileList: [],
        info: "",
        autor: false,
        aulaInfo: {
            aula: ""
        },
        total_conteudos: 0,
        total_conteudos_realizados: 0,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING
        loading: false,
        iconLoading: false,
        //VALIDACOES
        validateStatus: "",
        help: "",
        totalBytes: 0,
        formatoValido: true,
    };

    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var anotacaoId = localStorage.getItem("codigo_anotacao");
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            curso_nome: this.props.match.params.curso,
            anotacaoId,
            cursoId,
            aulaInfo: localStorage.getItem("info") ? JSON.parse(localStorage.getItem("info")) : ""
        });

        this.carregaAnotacao(anotacaoId);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSelect = value => {
        this.setState({
            motivo: value
        });
    };

    carregaAnotacao = anotacaoId => {
        axios({
            method: "get",
            url: "/api/anotacoes/carregar-anotacao",
            params: {
                anotacaoId
            }
        })
            .then(response => {
                this.setState({
                    texto: response.data.texto,
                    info: response.data.info,
                    ficheiros: response.data.anexos
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregaAnexos = anotacaoId => {
        axios({
            method: "get",
            url: "/api/anotacoes/carregar-anexos",
            params: {
                anotacaoId
            }
        })
            .then(response => {
                this.setState({
                    ficheiros: response.data,
                    fileList: []
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    downloadFicheiro = (index, nome) => {
        axios({
            method: "post",
            url: "/api/anotacoes/download-ficheiro",
            params: {
                anotacaoId: this.state.anotacaoId,
                index
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data;
                link.setAttribute(
                    'download',
                    nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    excluirFicheiro = (index) => {
        confirm({
            title: "Pretende excluir este ficheiro?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/anotacoes/excluir-ficheiro",
                    params: {
                        anotacaoId: this.state.anotacaoId,
                        index
                    }
                })
                    .then(response => {
                        var ficheiros = this.state.ficheiros;
                        ficheiros.splice(index, 1);

                        this.setState({
                            ficheiros
                        })
                    })
                    .catch(() => {
                        this.setState({ iconLoading: false })
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o ficheiro!")
                    })
            }
        });
    }

    alterarAnotacao = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.anotacaoId);
                item.append("id_curso", this.state.cursoId);
                item.append("texto", this.state.texto);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "post",
                    url: "/api/anotacoes/alterar-anotacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Anotação alterada!");

                        this.setState({
                            iconLoading: false
                        });
                        this.carregaAnexos(this.state.anotacaoId);
                    })
                    .catch(error => {
                        this.setState({
                            iconLoading: false
                        });
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        })
    };

    validarFormato = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const Dragger = Upload.Dragger;
        const {
            anotacaoId,
            curso_nome,
            texto,
            anexo,
            ficheiros,
            fileList,
            info,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading,
            iconLoading,
            //VALIDACOES
            validateStatus,
            help,
        } = this.state;

        //if (redirect) return <Redirect to={`/gerir-curso/${curso_nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-anotacoes`} />;
        if (redirect) return <Redirect to={redirectLink} />;

        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <div className="container">
                <div className="curso-forum">
                    <div className="respostas">
                        <div className="bloco-flex">
                            <div className="info-header">
                                {info &&
                                    <div className="anotacao-header">
                                        <span className="modulo-num">{info.atividade} - {info.tarefa}</span>
                                    </div>}
                            </div>
                            <Link
                                to={`/gerir-curso/${curso_nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-anotacoes`}
                                className="botao-principal"
                            >
                                Voltar
                            </Link>
                        </div>
                        <div className="anotacao-detalhe">
                            <div className="info-anotacao-detalhe">
                                <span className="texto-anotacao">
                                    {/*<span dangerouslySetInnerHTML={{ __html: texto.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>*/}
                                    <Form className="form-nova-pergunta">
                                        <Form.Item label="Anotação">
                                            {getFieldDecorator("texto", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: this.state.texto
                                            })(<TextArea name="texto" onChange={this.handleChange} style={{ height: "100%" }} rows={10} />)}
                                        </Form.Item>
                                        <Form.Item validateStatus={validateStatus} help={help}>
                                            <div className="anexos">
                                                <Dragger {...props}>
                                                    <p className="ant-upload-drag-icon">
                                                        <i className="fas fa-upload" />
                                                    </p>
                                                    <p className="ant-upload-text">
                                                        Adicionar anexos{" "}
                                                        <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                            <Icon type="question-circle-o" />
                                                        </Tooltip>
                                                    </p>
                                                    <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                                </Dragger>
                                            </div>
                                        </Form.Item>
                                        <Form.Item>
                                            <h3 className="titulo-separador">Anexos</h3>
                                            {loading ?
                                                <div className="loading-data">
                                                    <div className="loading" />
                                                </div>
                                                :
                                                <div className="lista-videos">
                                                    {!ficheiros.length ? <span>Sem dados</span> : null}
                                                    {ficheiros.map((ficheiro, index) => (
                                                        <div key={index} className="item-video">
                                                            <span className="video-titulo">{ficheiro}</span>
                                                            <div className="video-controlos">
                                                                <Link to="#" className="botao-icon-download" title="Descarregar" onClick={() => this.downloadFicheiro(index, ficheiro)}>
                                                                    <Icon type="download" />
                                                                </Link>
                                                                <Link to="#" className="botao-icon-excluir" title="Excluir" onClick={() => this.excluirFicheiro(index)}>
                                                                    <Icon type="delete" />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </Form.Item>
                                        <Form.Item>
                                            <button
                                                className="botao-principal"
                                                onClick={this.alterarAnotacao}
                                                style={{ lineHeight: "normal" }}
                                            >
                                                {iconLoading ? <Icon type="loading" /> : null}
                                                Salvar
                                            </button>
                                        </Form.Item>
                                    </Form>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const FormAnotacoesDetalhe = Form.create({ name: "anotacoes-detalhe" })(AnotacoesDetalhe);

export default withRouter(FormAnotacoesDetalhe);
