import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Badge, Select } from "antd";
import BotaoProcurar from "../Geral/_Aux/BotaoProcurar";
import { GlobalContext } from "../GlobalState";

const { Option } = Select;
class Menu extends Component {
    static contextType = GlobalContext;

    render() {
        return (
            <div className="menu">
                <div className="menu-controlos">
                    <div className="menu-item">
                        {this.context.role === "ENTIDADE" && (
                            <Link to="gestao-suporte-novo" className="btn-add">
                                Novo Pedido
                            </Link>
                        )}
                        {this.context.role === "ADMIN" && (
                            <Link to="administracao-suporte-novo" className="btn-add">
                                Novo Pedido
                            </Link>
                        )}
                        {this.context.role !== "ADMIN" && this.context.role !== "ENTIDADE" && (
                            <Link to="user-suporte-novo" className="btn-add">
                                Novo Pedido
                            </Link>
                        )}
                    </div>
                    <div className="menu-item">
                        <div className="botao-procurar">
                            <BotaoProcurar HandleKeyPress={this.props.HandleKeyPress} resetCaixaProcura={this.props.resetCaixaProcura} />
                        </div>
                    </div>
                    <div className="menu-item">
                        <Select
                            value={this.props.filtroEstado}
                            className="menu-item"
                            onChange={this.props.handleChangeFiltroEstados}
                            placeholder="Selecionar Estado"
                            allowClear={true}
                        >
                            <Option value="Por Ler">Por ler</Option>
                            <Option value="Aberto">Aberto</Option>
                            <Option value="Respondido">Respondido</Option>
                            <Option value="Fechado">Fechado</Option>
                            <Option value="Removido">Removido</Option>
                        </Select>
                    </div>
                </div>
                <div className="menu-filtros">
                    <div
                        className={"menu-item " + `${this.props.mensagens_ativo}`}
                        onClick={this.props.filtroMensagens}
                    >
                        Apoio
                        <Badge count={this.props.total_mensagens_por_ler} style={{ backgroundColor: "#ff9933", marginLeft: 5 }} title="Total de tickets por ler" />
                    </div>
                    <div
                        className={"menu-item " + `${this.props.reclamacoes_ativo}`}
                        onClick={this.props.filtroReclamacoes}
                    >
                        Reclamações
                        <Badge count={this.props.total_reclamacoes_por_ler} style={{ backgroundColor: "#ff9933", marginLeft: 5 }} title="Total de tickets por ler" />
                    </div>
                    {this.context.reclamacoes_entidade ?
                        <div className="link-reclamacoes">
                            <a href={this.context.reclamacoes_entidade} target="_blank" className="link-principal" >Livro de Reclamações</a>
                        </div>
                        : null}
                </div>
            </div>
        );
    }
}

export default Menu;
