import React, { Component } from "react";
import { Collapse, Tooltip, notification, Icon } from "antd";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";
import screenfull from "screenfull";
import Pontos from "./Pontos";

const Panel = Collapse.Panel;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Modulo extends Component {
    static contextType = GlobalContext;
    constructor(props) {
        super(props);
        this.state = {
            moduloid: 0,
            tituloModulo: "",
            ordemModulo: "",
            drawerModuloVisible: false,
            precedencia_nome: "",
            precedencia_concluida: false,
            //COLLAPSE
            aberto: false,
            collapsed: ["0"],
            //PERMISSOES
            curso_iniciado: false,
            //INSCRICAO
            inscrito: false,
            //FULLSCREEN
            fullscreen: false
        };

        this.elementFullScreen = React.createRef();
    }

    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
    }

    //CHAMADA ANTES DO RENDER
    componentDidMount() {
        //this.carregaPrecedencia();

        if (this.props.index === this.props.modulo_aberto)
            this.setState({
                aberto: true
            });
    }

    //FORMATA AS HORAS EM STRING 00H00
    formatarHoras = hora => {
        var horas = moment(hora, "HH:mm");
        if (moment.duration(hora, "minutes").asMinutes() > 59) return horas.format("HH").slice(0, 2) + "h" + horas.format("mm").slice(0, 2);
        else return horas.format("m").slice(0, 2) + "m";
    };

    alertar = bloqueado => {
        this.setState(
            {
                aberto: !this.state.aberto
            },
            () => {
                var precendencia_concluida = localStorage.getItem("estado_atividade_" + this.props.modulo.precendenciaId);

                if (this.state.aberto && !bloqueado) {
                    if (this.props.modulo.precedencia && !this.props.modulo.obrigatoria && precendencia_concluida === "false")
                        openNotificationWithIcon(
                            "info",
                            "Atenção",
                            <span>
                                Sugerimos que conclua primeiro a atividade <strong>{this.props.modulo.precedencia}</strong>
                            </span>
                        );
                    else if (this.props.modulo.precedencia && this.props.modulo.obrigatoria && precendencia_concluida === "false")
                        openNotificationWithIcon(
                            "warning",
                            "Atenção",
                            <span>
                                Tem de finalizar primeiro a atividade <strong>{this.props.modulo.precedencia}</strong>
                            </span>
                        );
                }
            }
        );
    };

    alertarAulaUnica = (moduloid, aulaid, bloqueado) => {
        this.setState(
            {
                aberto: !this.state.aberto
            },
            () => {
                var precendencia_concluida = localStorage.getItem("estado_atividade_" + this.props.modulo.precendenciaId);

                if (this.state.aberto && !bloqueado) {
                    if (this.props.modulo.precedencia && !this.props.modulo.obrigatoria && precendencia_concluida === "false") {
                        openNotificationWithIcon(
                            "info",
                            "Atenção",
                            <span>
                                Sugerimos que conclua primeiro a atividade <strong>{this.props.modulo.precedencia}</strong>
                            </span>
                        );
                        this.props.concluirAula(moduloid, aulaid);
                    }
                    else if (this.props.modulo.precedencia && this.props.modulo.obrigatoria && precendencia_concluida === "false")
                        openNotificationWithIcon(
                            "warning",
                            "Atenção",
                            <span>
                                Tem de finalizar primeiro a atividade <strong>{this.props.modulo.precedencia}</strong>
                            </span>
                        );
                    else
                        this.props.concluirAula(moduloid, aulaid);
                }
            }
        );
    };

    validarDisponibilidade = () => {
        var precendencia_concluida = localStorage.getItem("estado_atividade_" + this.props.modulo.precendenciaId);
        if (this.props.modulo.precedencia && this.props.modulo.obrigatoria && !precendencia_concluida && this.context.role === "UTILIZADOR")
            return true;

        return false;
    };

    calcular = aula => {
        var total = 0;

        if (aula.dt_inicio && aula.hora) {
            let d1 = moment(aula.hora, "HH:mm:ss");
            let d2 = moment(aula.dt_inicio);

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            total = d2.diff(moment(), "hours");
        } else if (aula.dt_inicio) total = moment(aula.dt_inicio).diff(moment(), "hours");
        else if (aula.hora) {
            let d1 = moment(aula.hora, "HH:mm:ss");
            let d2 = moment();

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            total = d2.diff(moment(), "hours");
        }

        return total;
    };

    notificar = (aula, tipo) => {
        if (aula.dt_inicio && aula.hora) {
            let d1 = moment(aula.hora, "HH:mm:ss");
            let d2 = moment(aula.dt_inicio);

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            openNotificationWithIcon("warning", "Atenção", tipo + " disponível a " + d2.format("DD/MM/YYYY") + " às " + d2.format("HH:mm"));
        } else if (aula.dt_inicio)
            openNotificationWithIcon("warning", "Atenção", tipo + " disponível a " + moment(aula.dt_inicio).format("DD/MM/YYYY"));
        else if (aula.hora) {
            let d1 = moment(aula.hora, "HH:mm:ss");
            let d2 = moment();

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            openNotificationWithIcon("warning", "Atenção", tipo + " disponível a " + d2.format("DD/MM/YYYY") + " às " + d2.format("HH:mm"));
        }
    };

    onClink = aulaId => {
        localStorage.setItem("modulo_aberto", this.props.index);
        localStorage.setItem("codigo_aula", aulaId);
    };

    onClinkConcluido = (moduloId, aulaId) => {
        localStorage.setItem("modulo_aberto", this.props.index);
        this.props.concluirAula(moduloId, aulaId);
    };

    onClinkValidar = (aula, disponivel, tipo, aulaId) => {
        localStorage.setItem("modulo_aberto", this.props.index);
        localStorage.setItem("codigo_aula", aulaId);

        if (!disponivel) {
            this.notificar(aula, tipo);
        }
    };

    onChangeCollapse = collapsed => {
        this.setState({
            collapsed
        });

        if (this.props.modulo.pagina_unica)
            this.alertarAulaUnica(this.props.modulo.id, this.props.modulo.modulo_Aula[0].id, this.props.modulo.estado === "BLOQUEADO");
        else
            this.alertar(this.props.modulo.estado === "BLOQUEADO");
    };

    onClickFullscreenElement = () => {
        screenfull.toggle(this.elementFullScreen.current);
        this.setState({ fullscreen: !this.state.fullscreen });
    };

    render() {
        const semaforo = estado => {
            if (estado === "ATIVO") return <span className="modulo-semaforo verde" title="Ativo" />;
            else if (estado === "FECHADO") {
                return <span className="modulo-semaforo amarelo" title="Fechado" />;
            } else {
                return <span className="modulo-semaforo vermelho" title="Bloqueado" />;
            }
        };

        //CALCULA O TOTAL DE AULAS NO MODULO E O TOTAL DE CONCLUIDAS PARA MARCAR O MODULO COMO CONCLUIDO
        const concluidos = () => {
            var totalAulas = this.props.modulo.modulo_Aula.filter(x => x.tipo_Recurso.id !== 13).length;
            var totalConcluidas = 0;

            this.props.modulo.modulo_Aula.forEach(aula => {
                totalAulas += aula.modulo_Aula_lvl2.filter(x => x.tipo_Recurso.id !== 17).length;

                if (aula.modulo_Aulas_Utilizadores)
                    if (aula.modulo_Aulas_Utilizadores.concluido)
                        totalConcluidas += 1;

                aula.modulo_Aula_lvl2.forEach(aulalvl2 => {
                    if (aulalvl2.modulo_Aulas_Utilizadores)
                        if (aulalvl2.modulo_Aulas_Utilizadores.concluido) totalConcluidas += 1;
                });
            });

            localStorage.setItem("estado_atividade_" + this.props.modulo.id, totalAulas === totalConcluidas);
            return this.props.modulo.modulo_Aula.length > 0 && totalAulas === totalConcluidas;
        };

        //MONTA O TITULO E LOGICA NO MODULO
        const tituloModulo = (
            <div>
                <div className="modulo-header">
                    <div className="modulo-container-esquerda modulo-container-esquerda-desktop">
                        {semaforo(this.props.modulo.estado)}
                        <span className="modulo-num">{this.props.modulo.nome}</span>
                        {this.props.modulo.precedencia && (
                            <span className="modulo-precedencia" title={`Precedência${this.props.modulo.obrigatoria ? " obrigatória" : ""}: ${this.props.modulo.precedencia}`}>
                                <Icon type="api" className={this.props.modulo.obrigatoria ? "obrigatoria" : ""} />
                            </span>
                        )}
                        {(this.props.habilitar_gamificacao && this.context.gamificacao_entidade) &&
                            <Pontos cursoid={this.props.cursoid} atividadeid={ this.props.modulo.id }/>}
                        <span className="modulo-validacao">
                            <i
                                className={
                                    concluidos() && this.props.modulo.estado !== "BLOQUEADO" ? "fa fa-check" : "fa fa-check por-validar"
                                }
                            />
                        </span>
                    </div>
                    <div className="modulo-container-esquerda modulo-container-esquerda-mobile">
                        <div className="container-lvl1">
                            {semaforo(this.props.modulo.estado)}
                        <span className="modulo-num">{this.props.modulo.nome}</span>
                            <span className="modulo-validacao">
                                <i
                                    className={
                                        concluidos() && this.props.modulo.estado !== "BLOQUEADO" ? "fa fa-check" : "fa fa-check por-validar"
                                    }
                                />
                            </span>
                        </div>
                        <div className="container-lvl2">
                            {this.props.modulo.precedencia && (
                                <span className="modulo-precedencia" title={`Precedência${this.props.modulo.obrigatoria ? " obrigatória" : ""}: ${this.props.modulo.precedencia}`}>
                                    <Icon type="api" className={this.props.modulo.obrigatoria ? "obrigatoria" : ""} />
                                </span>
                            )}
                            {(this.props.habilitar_gamificacao && this.context.gamificacao_entidade) &&
                                <Pontos cursoid={this.props.cursoid} atividadeid={this.props.modulo.id} />}
                        </div>
                    </div>
                    <div className="modulo-container-direita">
                        <span className="modulo-datas">
                            {moment().isAfter(moment(this.props.modulo.dt_termino)) && this.props.modulo.estado === "ATIVO" && !concluidos() ? (
                                <Icon type="warning" className="atrasado" />
                            ) : (
                                    ""
                                )}{" "}
                            {this.props.modulo.dt_inicio ? (
                                <>
                                    Inicio: <strong>{moment(this.props.modulo.dt_inicio).format("DD/MM/YYYY")}</strong>
                                </>
                            ) : (
                                    ""
                                )}{" "}
                            {this.props.modulo.dt_termino ? (
                                <>
                                    Término: <strong>{moment(this.props.modulo.dt_termino).format("DD/MM/YYYY")}</strong>
                                </>
                            ) : (
                                    ""
                                )}
                        </span>
                        <span className="modulo-validacao">
                            <i
                                className={
                                    concluidos() && this.props.modulo.estado !== "BLOQUEADO" ? "fa fa-check" : "fa fa-check por-validar"
                                }
                            />
                        </span>
                    </div>
                </div>
                {this.props.modulo.objetivos && (
                    <div className="modulo-header-objetivos">
                        <span>{this.props.modulo.objetivos}</span>
                    </div>
                )}
            </div>
        );

        const montarHoraTermino = (horaInicial, duracao) => {
            const hora = moment(horaInicial).add(duracao.hours(), "hours").add(duracao.minutes(), "minutes");
            return hora.format("HH:mm");
        }

        //MONTA A RESPETIVA AULA
        const montaAula = (moduloId, aula, estado) => {
            var tipoId = aula.tipo_Recurso.id;
            if (
                ((this.props.inscrito && estado) || (this.props.subscricao && estado) || (!estado && tipoId !== 5 && tipoId !== 6 && tipoId !== 8 && tipoId !== 15)) &&
                (!this.props.modulo.precedencia ||
                    (this.props.modulo.precedencia && !this.props.modulo.obrigatoria) ||
                    (this.props.modulo.precedencia &&
                        this.props.modulo.obrigatoria &&
                        localStorage.getItem("estado_atividade_" + this.props.modulo.precendenciaId) === "true"))
            ) {
                var inicioUrl = `/curso/${this.props.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${aula.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}-${Math.floor(100000 + Math.random() * 900000)}`;

                if (tipoId === 1) {
                    //VIDEO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}${
                                aula.modulo_Aulas_Utilizadores
                                    ? aula.modulo_Aulas_Utilizadores.periodo_onde_esta !== null
                                        ? "?start=" + aula.modulo_Aulas_Utilizadores.periodo_onde_esta
                                        : ""
                                    : ""
                                }`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/video.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //VIDEO
                else if (tipoId === 2) {
                    //AUDIO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}${
                                aula.modulo_Aulas_Utilizadores
                                    ? aula.modulo_Aulas_Utilizadores.periodo_onde_esta !== null
                                        ? "?start=" + aula.modulo_Aulas_Utilizadores.periodo_onde_esta
                                        : ""
                                    : ""
                                }`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/audio.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //AUDIO
                else if (tipoId === 3 || tipoId === 12) {
                    //HTML
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/html.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //HTML
                else if (tipoId === 4) {
                    //PDF
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/pdf.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //PDF
                else if (tipoId === 5) {
                    //QUESTIONARIO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/questionario.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //QUESTIONARIO
                else if (tipoId === 6) {
                    //AVALIACAO
                    var link = "#";
                    var disponivel = this.calcular(aula) <= 0;

                    if (disponivel) link = `${inicioUrl}${aula.tipo_Recurso.url}`;

                    return (
                        <Link
                            to={link}
                            onClick={() => this.onClinkValidar(aula, disponivel, "Avaliação", aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/avaliacao.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //AVALIACAO
                else if (tipoId === 7) {
                    //DOWNLOAD
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/download.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //DOWNLOAD
                else if (tipoId === 8) {
                    //TRABALHO
                    const icon = () => {
                        if (aula.tipo_trabalho === "INDIVIDUAL")
                            return (
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/trabalho_individual.png")}
                                />
                            );
                        else if (aula.tipo_trabalho === "GRUPO")
                            return (
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/trabalho_grupo.png")}
                                />
                            );
                        else
                            return (
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/trabalho_colaborativo.png")}
                                />
                            );
                    };

                    let link = "#";
                    let disponivel = this.calcular(aula) <= 0;

                    if (disponivel) link = `${inicioUrl}${aula.tipo_Recurso.url}`;

                    return (
                        <Link
                            to={link}
                            onClick={() => this.onClinkValidar(aula, disponivel, "Trabalho", aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                {icon()}
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong title="Inicio do Trabalho">{moment(aula.dt_inicio).format("DD/MM/YYYY")}</strong>
                                        {" "}até{" "}
                                        <strong title="Data limite de entrega">{moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                    </span>
                                )}
                                {(aula.dt_fim && !aula.dt_inicio) && (
                                    <span className="data-hora">
                                        <strong title="Data limite de entrega">Até {moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                    </span>
                                )}
                                {moment().isAfter(moment(aula.dt_fim)) && (aula.modulo_Aulas_Utilizadores && !aula.modulo_Aulas_Utilizadores.concluido || !aula.modulo_Aulas_Utilizadores) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : ""}
                            </span>
                        </Link>
                    );
                } //TRABALHO
                else if (tipoId === 9) {
                    //LINK
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/link.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //LINK
                else if (tipoId === 10) {
                    //SUMÁRIO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/sumario.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //SUMÁRIO
                else if (tipoId === 11) {
                    //PRESENCIAL
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/sessao-presencial.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                                {aula.dt_inicio && (
                                    <span className="data-hora" title="Data - Hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                                {aula.presente !== "SEM_REGISTO" ?
                                    <span className="presenca">
                                        {aula.presente === "PRESENCA" ?
                                            <i className="fas fa-check presente" title="Presença"></i>
                                            :
                                            <i className="fas fa-times faltou" title="Falta"></i>
                                        }
                                    </span>
                                    : null
                                }
                            </span>
                        </Link>
                    );
                } //PRESENCIAL
                else if(tipoId === 13) {//PASTA
                    return (
                        <Link
                            to="#"
                            className="tarefa-sem-link"
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/pasta.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                            </span>
                        </Link>
                    );
                }//PASTA
                else {
                    //QUESTIONARIO DIRIGIDO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/dirigido.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //QUESTIONARIO DIRIGIDO

            }
            else {
                var icon = "";
                var data = "";

                if (tipoId === 1)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/video.png")}
                        />
                    );
                else if (tipoId === 2)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/audio.png")}
                        />
                    );
                else if (tipoId === 3)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/html.png")}
                        />
                    );
                else if (tipoId === 4)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/pdf.png")}
                        />
                    );
                else if (tipoId === 5)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/questionario.png")}
                        />
                    );
                else if (tipoId === 6) {
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/avaliacao.png")}
                        />
                    );
                    data = aula.dt_inicio && (
                        <span className="data-hora">
                            <strong>
                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                            </strong>
                        </span>
                    )
                }
                else if (tipoId === 7)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/download.png")}
                        />
                    );
                else if (tipoId === 8) {
                    if (aula.tipo_trabalho === "INDIVIDUAL")
                        icon = (
                            <img
                                className="tarefa-icon"
                                src={require("../../images/icons/trabalho_individual.png")}
                            />
                        );
                    else if (aula.tipo_trabalho === "GRUPO")
                        icon = (
                            <img
                                className="tarefa-icon"
                                src={require("../../images/icons/trabalho_grupo.png")}
                            />
                        );
                    else
                        icon = (
                            <img
                                className="tarefa-icon"
                                src={require("../../images/icons/trabalho_colaborativo.png")}
                            />
                        );

                    data = <>
                        {aula.dt_inicio && (
                            <span className="data-hora">
                                <strong title="Inicio do Trabalho">{moment(aula.dt_inicio).format("DD/MM/YYYY")}</strong>
                                {" "}até{" "}
                                <strong title="Data limite de entrega">{moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                            </span>
                        )}
                        {(aula.dt_fim && !aula.dt_inicio) && (
                            <span className="data-hora">
                                <strong title="Data limite de entrega">Até {moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                            </span>
                        )}
                    </>
                }
                else if (tipoId === 9)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/link.png")}
                        />
                    );
                else if (tipoId === 10)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/sumario.png")}
                        />
                    );
                else if (tipoId === 11)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/sessao-presencial.png")}
                        />
                    );
                else if(tipoId === 13)
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/pasta.png")}
                        />
                    );
                else
                    icon = (
                        <img
                            className="tarefa-icon"
                            src={require("../../images/icons/dirigido.png")}
                        />
                    );

                return (
                    <Link to="#" style={{ cursor: "default" }}>
                        <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                            {icon}
                        </span>
                        <span className="licao">{aula.nome}{data}</span>
                    </Link>
                );
            }
        };

        //MONTA A RESPETIVA AULA DE LVL 2
        const montaAulaLvl2 = (moduloId, aula, index, estado, sessaoPresencial) => {
            var tipoId = aula.tipo_Recurso.id;

            if (
                ((this.props.inscrito && estado) || (this.props.subscricao && estado) || (!estado && tipoId !== 5 && tipoId !== 6 && tipoId !== 8 && tipoId !== 14 && tipoId !== 15)) &&
                (!this.props.modulo.precedencia ||
                    (this.props.modulo.precedencia && !this.props.modulo.obrigatoria) ||
                    (this.props.modulo.precedencia &&
                        this.props.modulo.obrigatoria &&
                        localStorage.getItem("estado_atividade_" + this.props.modulo.precendenciaId) === "true"))
            ) {
                var inicioUrl = `/curso/${this.props.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${aula.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}-${Math.floor(100000 + Math.random() * 900000)}`;

                if (tipoId === 1) {
                    //VIDEO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}${aula.modulo_Aulas_Utilizadores
                                    ? aula.modulo_Aulas_Utilizadores.periodo_onde_esta !== null
                                        ? "?start=" + aula.modulo_Aulas_Utilizadores.periodo_onde_esta
                                        : ""
                                    : ""
                                }`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/video.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //VIDEO
                else if (tipoId === 2) {
                    //AUDIO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}${aula.modulo_Aulas_Utilizadores
                                    ? aula.modulo_Aulas_Utilizadores.periodo_onde_esta !== null
                                        ? "?start=" + aula.modulo_Aulas_Utilizadores.periodo_onde_esta
                                        : ""
                                    : ""
                                }`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/audio.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //AUDIO
                else if (tipoId === 3 || tipoId === 12) {
                    //HTML
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/html.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //HTML
                else if (tipoId === 4) {
                    //PDF
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/pdf.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //PDF
                else if (tipoId === 5) {
                    //QUESTIONARIO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/questionario.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //QUESTIONARIO
                else if (tipoId === 6) {
                    //AVALIACAO
                    var link = "#";
                    var disponivel = this.calcular(aula) <= 0;

                    if (disponivel) link = `${inicioUrl}${aula.tipo_Recurso.url}`;

                    return (
                        <Link
                            to={link}
                            onClick={() => this.onClinkValidar(aula, disponivel, "Avaliação", aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/avaliacao.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //AVALIACAO
                else if (tipoId === 7) {
                    //DOWNLOAD
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/download.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //DOWNLOAD
                else if (tipoId === 8) {
                    //TRABALHO
                    const icon = () => {
                        if (aula.tipo_trabalho === "INDIVIDUAL")
                            return (
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/trabalho_individual.png")}
                                />
                            );
                        else if (aula.tipo_trabalho === "GRUPO")
                            return (
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/trabalho_grupo.png")}
                                />
                            );
                        else
                            return (
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/trabalho_colaborativo.png")}
                                />
                            );
                    };

                    let link = "#";
                    let disponivel = this.calcular(aula) <= 0;

                    if (disponivel) link = `${inicioUrl}${aula.tipo_Recurso.url}`;

                    return (
                        <Link
                            to={link}
                            onClick={() => this.onClinkValidar(aula, disponivel, "Trabalho", aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                {icon()}
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong title="Inicio do Trabalho">{moment(aula.dt_inicio).format("DD/MM/YYYY")}</strong>
                                        {" "}até{" "}
                                        <strong title="Data limite de entrega">{moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                    </span>
                                )}
                                {(aula.dt_fim && !aula.dt_inicio) && (
                                    <span className="data-hora">
                                        <strong title="Data limite de entrega">Até {moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                    </span>
                                )}
                                {moment().isAfter(moment(aula.dt_fim), "days") && (aula.modulo_Aulas_Utilizadores && !aula.modulo_Aulas_Utilizadores.concluido || !aula.modulo_Aulas_Utilizadores) ? <Icon type="warning" className="atrasado" title="Existe atraso na realização desta tarefa" /> : ""}
                            </span>
                        </Link>
                    );
                } //TRABALHO
                else if (tipoId === 9) {
                    //LINK
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    src={require("../../images/icons/link.png")}
                                    style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //LINK
                else if (tipoId === 10) {
                    //SUMÁRIO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    src={require("../../images/icons/sumario.png")}
                                    style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                                {!sessaoPresencial ?
                                    aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong>
                                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                                {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                            </strong>
                                        </span>
                                    ) : null}
                            </span>
                        </Link>
                    );
                } //SUMÁRIO
                else if (tipoId === 11) {
                    //PRESENCIAL
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    src={require("../../images/icons/sessao-presencial.png")}
                                    style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //PRESENCIAL
                else if (tipoId === 14) {//QUESTIONARIO PROGRESSO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    src={require("../../images/icons/progresso.png")}
                                    style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //QUESTIONARIO PROGRESSO
                else if (tipoId === 15) {
                    //QUESTIONARIO DIRIGIDO
                    return (
                        <Link
                            to={`${inicioUrl}${aula.tipo_Recurso.url}`}
                            onClick={() => this.onClink(aula.id)}
                            disabled={this.validarDisponibilidade()}
                        >
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    src={require("../../images/icons/dirigido.png")}
                                    style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.marcador && (
                                    <span className="marcador" style={{ marginLeft: "10px" }}>
                                        <i className="fas fa-bookmark" />
                                    </span>
                                )}
                            </span>
                        </Link>
                    );
                } //QUESTIONARIO DIRIGIDO
                else {
                    //PRESENÇAS
                    return (
                        <Link to="#" className="tarefa-sem-link">
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    src={require("../../images/icons/presencas.png")}
                                    style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                <span className="data-hora">
                                    <strong>
                                        {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                    </strong>
                                    {(aula.hora && !aula.hora_fim) && <> - <strong>{aula.hora}</strong></>}
                                    {(!aula.hora && aula.hora_fim) && <> - <strong>{aula.hora_fim}</strong></>}
                                    {(aula.hora && aula.hora_fim) && <> - <strong>{aula.hora}</strong> a <strong>{aula.hora_fim}</strong></>}
                                </span>
                            </span>
                        </Link>
                    );
                }//PRESENÇAS
            }
            else {
                var icon = "";
                var data = "";

                if (tipoId === 1)
                    icon = (
                        <img
                            src={require("../../images/icons/video.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 2)
                    icon = (
                        <img
                            src={require("../../images/icons/audio.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 3)
                    icon = (
                        <img
                            src={require("../../images/icons/html.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 4)
                    icon = (
                        <img
                            src={require("../../images/icons/pdf.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 5)
                    icon = (
                        <img
                            src={require("../../images/icons/questionario.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 6) {
                    icon = (
                        <img
                            src={require("../../images/icons/avaliacao.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                    data = aula.dt_inicio && (
                        <span className="data-hora">
                            <strong>
                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                            </strong>
                        </span>
                    )
                }
                else if (tipoId === 7)
                    icon = (
                        <img
                            src={require("../../images/icons/download.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 8) {
                    if (aula.tipo_trabalho === "INDIVIDUAL")
                        icon = (
                            <img
                                src={require("../../images/icons/trabalho_individual.png")}
                                style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                            />
                        );
                    else if (aula.tipo_trabalho === "GRUPO")
                        icon = (
                            <img
                                src={require("../../images/icons/trabalho_grupo.png")}
                                style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                            />
                        );
                    else
                        icon = (
                            <img
                                src={require("../../images/icons/trabalho_colaborativo.png")}
                                style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                            />
                        );
                    data = <>
                        {aula.dt_inicio && (
                            <span className="data-hora">
                                <strong title="Inicio do Trabalho">{moment(aula.dt_inicio).format("DD/MM/YYYY")}</strong>
                                {" "}até{" "}
                                <strong title="Data limite de entrega">Até {moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                            </span>
                        )}
                        {(aula.dt_fim && !aula.dt_inicio) && (
                            <span className="data-hora">
                                <strong title="Data limite de entrega">{moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                            </span>
                        )}
                    </>
                }
                else if (tipoId === 9)
                    icon = (
                        <img
                            src={require("../../images/icons/link.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 10)
                    icon = (
                        <img
                            src={require("../../images/icons/sumario.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 11)
                    icon = (
                        <img
                            src={require("../../images/icons/sessao-presencial.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 14)
                    icon = (
                        <img
                            src={require("../../images/icons/progresso.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else if (tipoId === 15)
                    icon = (
                        <img
                            src={require("../../images/icons/dirigido.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );
                else
                    icon = (
                        <img
                            src={require("../../images/icons/presencas.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    );

                return (
                    <Link to="#" style={{ cursor: "default" }}>
                        <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                            {icon}
                        </span>
                        <span className="licao">{aula.nome} {data}</span>
                    </Link>
                );
            }
        };

        const montarPresencaSelecionado = aula => {
            var recurso = JSON.parse(aula.recurso);

            if (recurso.presenca_coletiva || recurso.falta_coletiva || recurso.presencas.length) {
                var presenca = (recurso.presenca_coletiva && !recurso.falta_coletiva) || recurso.presencas.find(x => x === this.context.id_utilizador) > 0

                return <span className="validacao">
                    <Tooltip className="info-icon" title={presenca ? "Presença" : "Falta"}>
                        <i className={presenca ? "fa fa-check presenca" : "fas fa-times falta"} />
                    </Tooltip>
                </span>;
            }
            else
                return <span className="validacao">
                    <Tooltip className="info-icon" title="Sem dados">
                        <i className="fa fa-check sem-registo" />
                    </Tooltip>
                </span>;
        }

        const validarDisponibilidadePaginaUnica = () => {
            var precendencia_concluida = localStorage.getItem("estado_atividade_" + this.props.modulo.precendenciaId);

            return (this.props.modulo.pagina_unica && this.props.modulo.precedencia && this.props.modulo.obrigatoria && precendencia_concluida === "false")
        }

        return (
            <>
                <Collapse
                    defaultActiveKey={this.props.disabled ? [] : [localStorage.getItem("modulo_aberto")]}
                    bordered={false}
                    onChange={this.onChangeCollapse}
                    key={this.props.index}
                >
                    <Panel
                        header={tituloModulo}
                        key={this.props.index}
                        className={!this.props.modulo.ativo ? "modulo ativo" : "modulo"}
                        //disabled={this.props.disabled || validarDisponibilidadePaginaUnica()}
                        disabled={this.props.disabled}
                    >
                        {this.props.modulo.pagina_unica ? (
                            (!this.props.disabled && this.state.collapsed.find(x => x === this.props.index + "") && !validarDisponibilidadePaginaUnica()) && (
                                <div
                                    ref={this.elementFullScreen}
                                    style={this.state.fullscreen ? { padding: 40, background: "white" } : { background: "white" }}
                                >
                                    <Icon
                                        type={this.state.fullscreen ? "fullscreen-exit" : "fullscreen"}
                                        onClick={this.onClickFullscreenElement}
                                        className="btn-fullscreen"
                                        style={{ padding: "0 0 20px 0" }}
                                    />
                                    <div className="bloco-pagina-unica" dangerouslySetInnerHTML={{ __html: this.props.modulo.modulo_Aula[0].recurso }}></div>
                                </div>
                            )
                        ) : (
                                <ul>
                                    {this.props.modulo.modulo_Aula.map((aula, index) => (
                                        <li key={index}>
                                            <div className={aula.ativo ? "nivel1" : "nivel1 inativo"}>
                                                {montaAula(this.props.modulo.id, aula, this.props.modulo.estado === "ATIVO")}
                                                {aula.tipo_Recurso.id !== 13 &&
                                                    <span className="validacao">
                                                        <i
                                                            className={
                                                                aula.modulo_Aulas_Utilizadores && aula.modulo_Aulas_Utilizadores.concluido
                                                                    ? "fa fa-check"
                                                                    : "fa fa-check por-validar"
                                                            }
                                                        />
                                                    </span>}
                                            </div>
                                            <div className="nivel2">
                                                <ul>
                                                    {aula.modulo_Aula_lvl2.map((aulalvl2, index2) => (
                                                        <li className={!aulalvl2.ativo ? "inativo" : ""} key={index2}>
                                                            {montaAulaLvl2(
                                                                this.props.modulo.id,
                                                                aulalvl2,
                                                                index2,
                                                                this.props.modulo.estado === "ATIVO",
                                                                aula.tipo_Recurso.id === 11
                                                            )}
                                                            <span className="validacao">
                                                                <i
                                                                    className={
                                                                        aulalvl2.modulo_Aulas_Utilizadores &&
                                                                            aulalvl2.modulo_Aulas_Utilizadores.concluido
                                                                            ? "fa fa-check"
                                                                            : "fa fa-check por-validar"
                                                                    }
                                                                />
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                    </Panel>
                </Collapse>
            </>
        );
    }
}

export default Modulo;
