import React, { useEffect, useState } from "react";
import { notification } from "antd";
import axios from "axios";
import { Icon } from "antd";

import Questao from "./Components/Questao";
//import "../scss/popup";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export default function Main() {
    const [visivel, setVisivel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [questoes, setQuestoes] = useState([
        {
            id: 1,
            questao: "Pesquisa Stepforma 2023.2 - Maravilha",
            descricao:
                "Com o intuito de validarmos alguns processos no que diz respeito à Stepforma, Stepformers e Atendimento, formulamos essa pesquisa de satisfação.",
            resposta: []
        },
        {
            id: 2,
            questao: "Selecione o seu cargo:",
            resposta: ["Diretor/Gestor/Coordenador", "Professor", "Setor Administrativo"],
            satisfacao: false
        },
        {
            id: 3,
            questao: "Com que frequência você utiliza a Stepforma?",
            resposta: ["Diariamente", "Semanalmente", "Mensalmente", "Menos de uma vez por mês"],
            satisfacao: false
        },
        {
            id: 4,
            questao: "De 1 a 5, qual seu nível de satisfação com a usabilidade da Stepforma?",
            resposta: ["1", "2", "3", "4", "5"],
            satisfacao: true
        },
        {
            id: 5,
            questao: "De 1 a 5, qual seu nível de satisfação com os atendimentos presenciais do Stepformer?",
            resposta: ["1", "2", "3", "4", "5"],
            satisfacao: true
        },
        {
            id: 6,
            questao:
                "De 1 a 5, qual o seu nível de satisfação com o atendimento por outros canais/outros agentes de atendimento e suporte?",
            resposta: ["1", "2", "3", "4", "5"],
            satisfacao: true
        },
        {
            id: 7,
            questao: "Para finalizarmos, no geral, quão satisfeito(a) você está com a Stepforma e os serviços ofertados?",
            resposta: ["1", "2", "3", "4", "5"],
            satisfacao: true
        },
        {
            id: 8,
            descricao:
                "Prezado(a) participante, Agradecemos sinceramente, por dedicar seu tempo para completar nossa pesquisa de satisfação. Sua opinião é extremamente valiosa e nos ajudará a aprimorar nossos processos na Stepforma, Obrigado !!",
            questao: "",
            resposta: [],
            satisfacao: false
        }
        // Adicione mais questões conforme necessário
    ]);
    const [respostas, setRespostas] = useState([]);
    const [indiceQuestaoAtual, setIndiceQuestaoAtual] = useState(0);
    const questaoAtual = questoes[indiceQuestaoAtual];

    const avancarQuestao = () => {
        setIndiceQuestaoAtual(indiceQuestaoAtual + 1);
    };

    const retrocederQuestao = () => {
        setIndiceQuestaoAtual(indiceQuestaoAtual - 1);
    };

    const handleSelecionarResposta = (id, resposta) => {
        // Verificar se já existe uma resposta para a pergunta com o mesmo ID
        const perguntaExistente = respostas.find(p => p.id === id);

        // Se existe, substituir a resposta
        if (perguntaExistente) {
            perguntaExistente.resposta = resposta;
        } else {
            // Se não existe, adicionar a nova resposta
            if (id !== 1) {
                respostas.push({ id: id, resposta: resposta });
            }
        }
    };

    const handlerEnviar = () => {
        setLoading(true);
        var item = new FormData();
        item.append("respostas", JSON.stringify(respostas));

        axios({
            method: "post",
            url: "/api/popup/guardar",
            data: item
        })
            .then(() => {
                setVisivel(false);
                setLoading(false);
                openNotificationWithIcon("success", "Sucesso", "Formulário enviado com sucesso!");
            })
            .catch(() => {
                setVisivel(false);
                setLoading(false);
                openNotificationWithIcon("error", "Erro", "Não foi possível enviar o formulário.");
            });
    };

    const handlerFechar = () => {
        setVisivel(false);
    };

    //const validarPopup = () => {
    //    axios({
    //        method: "get",
    //        url: "/api/popup/validar"
    //    })
    //        .then(response => {
    //            setVisivel(response.data);
    //        })
    //        .catch(error => {});
    //};

    //useEffect(() => {
    //    validarPopup();
    //}, []);

    return (
        <>
            {visivel && (
                <div className="background-popup">
                    <Questao
                        loading={loading}
                        questoes={questoes}
                        respostas={respostas}
                        questao={questaoAtual}
                        onEnviar={handlerEnviar}
                        onFechar={handlerFechar}
                        onAvancar={avancarQuestao}
                        onRetroceder={retrocederQuestao}
                        onSelecionarResposta={handleSelecionarResposta}
                    />
                </div>
            )}
        </>
    );
}
