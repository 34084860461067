import React, { useState } from "react";
import { Drawer, Form, Select, DatePicker, Upload, Icon, notification, Tooltip, Checkbox } from "antd";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const { Option } = Select;
const Dragger = Upload.Dragger;

const DrawerDetalhe = ({ visivel, onClose, adicionarDetalhe, form }) => {
    const [tipo, setTipo] = useState(undefined);
    const [ficheiro, setFicheiro] = useState("");
    const [variosDias, setVariosDias] = useState(false);
    const [dataInicio, setDataInicio] = useState("");
    const [dataFim, setDataFim] = useState("");
    const { getFieldDecorator } = form;

    const handleChangeSelect = value => {
        setTipo(value);
    };

    const handleChangeCheckbox = event => {
        setVariosDias(event.target.checked);
    };

    const handleChangeDataInicio = data => {
        setDataInicio(data);
    };

    const handleChangeDataFim = data => {
        setDataFim(data);
    };

    const confirmar = () => {
        const detalhe = {
            tipo,
            ficheiro,
            variosDias,
            dataInicio,
            dataFim
        }
        adicionarDetalhe(detalhe);
    }

    const validarFormatos = (formato, formatos_aceites) => {
        var valido = false

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato === formatos_aceites[i]) valido = true
        }

        return valido
    }

    const validar = (rule, value, callback) => {
        if (value.file)
            if (!validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"]) || value.file.size > 26214400)
                callback("Campo obrigatório")

        callback()
    }

    const propsFileUpload = {
        accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
        multiple: false,
        onRemove: file => {
            form.resetFields("download")
            setFicheiro([])
        },
        defaultFileList: ficheiro,
        beforeUpload: file => {
            if (!validarFormatos(file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"])) {
                openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                return false;
            }

            if (file.size > 26214400) {
                openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                return false;
            }

            setFicheiro([file])

            return false
        },
        ficheiro
    }

    return (
        <>
            <Drawer
                className="drawer-adicionar-evento"
                //title="Conteúdos"
                width={720}
                onClose={onClose}
                visible={visivel}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Tipo">
                                <Select placeholder="Selecionar" value={tipo} allowClear={true} onChange={handleChangeSelect}>
                                    <Option value="Falta de transporte">
                                        Falta de transporte
                                    </Option>
                                    <Option value="Atestado médico">
                                        Atestado médico
                                    </Option>
                                    <Option value="Licença">
                                        Licença
                                    </Option>
                                    <Option value="Outros fatores">
                                        Outros fatores
                                    </Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator("download", {
                                    rules: [
                                        {
                                            validator: validar
                                        }
                                    ],
                                    initialValue: ficheiro
                                })(
                                    <Dragger {...propsFileUpload} fileList={ficheiro}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexo
                                            <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Checkbox checked={variosDias} onChange={handleChangeCheckbox}>
                                    Justificação para vários dias
                                </Checkbox>
                            </Form.Item>
                            {variosDias &&
                                <>
                                    <Form.Item label="Inicio">
                                        {getFieldDecorator("dataInicio", {
                                            rules: [{
                                                required: true,
                                                message: "Campo obrigatório"
                                            }],
                                            initialValue: dataInicio
                                        })(
                                            <DatePicker
                                                className="input-25"
                                                placeholder="Selecionar"
                                                format="DD-MM-YYYY"
                                                name="dataInicio"
                                                onChange={handleChangeDataInicio}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Fim">
                                        {getFieldDecorator("dataFim", {
                                            rules: [{
                                                required: true,
                                                message: "Campo obrigatório"
                                            }],
                                            initialValue: dataFim
                                        })(
                                            <DatePicker
                                                className="input-25"
                                                placeholder="Selecionar"
                                                format="DD-MM-YYYY"
                                                name="dataFim"
                                                onChange={handleChangeDataFim}
                                            />
                                        )}
                                    </Form.Item>
                                </>
                            }
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" onClick={confirmar} type="primary">
                        Salvar
                    </button>
                </div>
            </Drawer>
        </>
    );
}

const FormDrawerDetalhe = Form.create({ name: "form-drawer-detalhe" })(DrawerDetalhe);

export default FormDrawerDetalhe;