import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, notification, Icon } from "antd";

class Detalhe extends Component {
    render() {
        const {
            visible,
            info
        } = this.props;

        return (
            <Modal
                visible={visible}
                maskClosable={false}
                className="modal-aula-detalhe"
                footer={null}
                closable={false}
            >
                <div className="container">
                    <div className="header">
                        <h3>{info.disciplina}</h3>
                        <div className="controls">
                            <Link to="#" className="botao-icon-fechar" title="Voltar" onClick={this.props.onClose}>
                                <Icon type="close" />
                            </Link>
                        </div>
                    </div>
                    <div className="body">
                        <div className="date"><Icon type="calendar" /> {info.data}</div>
                        <div className="hours"><Icon type="clock-circle" /> {info.horario}</div>
                        <div className="place"><Icon type="environment" /> {info.sala}</div>
                        <div className="teacher"><Icon type="user" /> {info.professor}</div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default Detalhe;
