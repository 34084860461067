import '../../../scss/_carousel.scss';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";

const Carousel = ({ handlerClose, idFoto }) => {

    const [slides, setSlides] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    const fetchImagens = async () => {
        const url = '/api/pagina-publica/galeria/buscar-fotos';
        try {
            const response = await axios.get(url, {
                params: { idFoto: idFoto }
            });
            setSlides(response.data);
        } catch (error) {
            console.error('Error ao fazer a requisição GET', error);
        }
    }

    useEffect(() => {
        fetchImagens();
    }, [idFoto]);

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
    };

    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div className="carousel-container carousel-container-gallery">
            <div className="carousel" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {slides.length > 0 && slides.map((slide, index) => (
                    <div key={index} className={`slide ${index === currentSlide ? 'active' : ''}`}>
                        <div className="background-blur" style={{ backgroundImage: `url(${slide.caminho})` }}></div>
                        <img src={slide.caminho} alt={`Slide ${index + 1}`} className="imgCarrocelImagens" />
                    </div>
                ))}
            </div>

            <button className="botaoClose" onClick={handlerClose}>
                <AiOutlineClose color="#FFFFFF" size="20px" />
            </button>

            <div className="thumbnails">
                {slides.length > 0 && slides.map((slide, index) => (
                    <img
                        key={index}
                        src={slide.caminho}
                        alt={`Thumbnail ${index + 1}`}
                        className={`thumbnail ${index === currentSlide ? 'active' : ''}`}
                        onClick={() => goToSlide(index)}
                        style={{ width: "140px", height: "90px", objectFit: "cover", margin: "0 5px" }}
                    />
                ))}
            </div>

            <div className="dots">
                {slides.length > 0 && slides.map((_, index) => (
                    <span key={index} className={`dot ${index === currentSlide ? 'active' : ''}`}
                          onClick={() => goToSlide(index)}>
                        <div></div>
                    </span>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
