import React, { useState, useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const MyHeader = (state, previousDocument, nextDocument) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
        return null;
    }

    if (state.documents.length === 1)
        return null;

    return (
        <>
            <div>{state.currentDocument.uri || ""}</div>
            <div>
                <button onClick={previousDocument} disabled={state.currentFileNo === 0}>
                    Previous Document
                </button>
                <button
                    onClick={nextDocument}
                    disabled={state.currentFileNo >= state.documents.length - 1}
                >
                    Next Document
                </button>
            </div>
        </>
    );
};

const Viewer = ({ docs }) => {
    return (
        <div style={{ height: "100vh", width: "100%" }}>
            <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                language="pt"
                config={{
                    header: {
                        overrideComponent: MyHeader,
                        //disableHeader: true,
                        disableFileName: true
                    },
                    pdfZoom: {
                        defaultZoom: 0.8,
                    },
                    noRenderer: {
                        overrideComponent: <span>Não foi possível carregar o documento.</span>,
                    }
                }}
            />
        </div>
    );
};

export default Viewer;
