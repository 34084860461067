import React, { Component } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Input, Select, Checkbox, Radio } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import noimage from "../../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerRelatorio extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        utilizador: "",
        //INFO
        nome: "",
        texto: "",
        anexo: "",
        fileList: [],
        tipo_partilha: "PESSOAL",
        //VALIDACOES
        status: "",
        helper: "",
        totalBytes: 0,
        formatoValido: true,
        //NOTIFICAR
        notificar: false,
        notificar_responsavel: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.setState({
            curso: this.props.match.params.curso,
            cursoid: localStorage.getItem("codigo_curso"),
        });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeCheck = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    handlerChangeRadioPartilha = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    //ADICIONA UMA NOVA ANOTAÇÃO
    carregar = relatorioId => {
        axios({
            method: "get",
            url: "/api/gestao-curso-resultados/carregar-relatorio-progresso",
            params: {
                relatorioId
            }
        })
            .then(response => {
                var fileList = [];

                if (response.data.ficheiro) {
                    fileList = [
                        {
                            uid: "-1",
                            name: response.data.ficheiro_nome,
                            status: "done",
                            url: response.data.ficheiro
                        }
                    ]
                }

                this.setState({
                    nome: response.data.nome,
                    texto: response.data.texto,
                    tipo_partilha: response.data.tipo_partilha,
                    fileList,
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar relatório de progresso");
                this.setState({
                    iconLoading: false
                });
            });
    };

    //ADICIONA UMA NOVA ANOTAÇÃO
    adicionar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_curso", this.state.cursoid);
                item.append("id_utilizador", this.props.utilizadorId);
                item.append("nome", this.state.nome);
                item.append("texto", this.state.texto);
                item.append("tipo_partilha", this.state.tipo_partilha);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });
                item.append("responsavel_notificado", this.state.notificar_responsavel);
                item.append("notificar", this.state.notificar);

                axios({
                    method: "post",
                    url: "/api/gestao-curso-resultados/adicionar-relatorio-progresso",
                    data: item
                })
                    .then(() => {
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar novo relatório de progresso");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ADICIONA UMA NOVA ANOTAÇÃO
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.relatorioid);
                item.append("id_curso", this.state.cursoid);
                item.append("id_utilizador", this.props.utilizadorId);
                item.append("nome", this.state.nome);
                item.append("texto", this.state.texto);
                item.append("tipo_partilha", this.state.tipo_partilha);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });
                item.append("responsavel_notificado", this.state.notificar_responsavel);
                item.append("notificar", this.state.notificar);

                axios({
                    method: "put",
                    url: "/api/gestao-curso-resultados/alterar-relatorio-progresso",
                    data: item
                })
                    .then(() => {
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar novo relatório de progresso");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            if (this.props.relatorioid) {
                this.carregar(this.props.relatorioid);
            }
            else {
                this.props.form.resetFields();
                this.setState({
                    //INFO
                    nome: "",
                    texto: "",
                    anexo: "",
                    fileList: [],
                    tipo_partilha: "PESSOAL",
                    //VALIDACOES
                    status: "",
                    helper: "",
                    totalBytes: 0,
                    formatoValido: true,
                    //NOTIFICAR
                    notificar: false,
                    notificar_responsavel: false
                })
            }
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const Dragger = Upload.Dragger;
        const { Option } = Select;
        const {
            nome,
            texto,
            fileList,
            tipo_partilha,
            validateStatus,
            help,
            iconLoading,
            //NOTIFICAR
            notificar,
            notificar_responsavel
        } = this.state;
        
        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <Drawer
                //title={this.props.relatorioid ? "Editar" : "Adicionar"}
                placement="right"
                className="drawer-envio-mensagem-inscricao"
                maskClosable={false}
                closable={true}
                onClose={this.props.onClose}
                visible={this.props.visibleDrawerRelatorio}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-nova-pergunta">
                        <div className="bloco">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Texto">
                                {getFieldDecorator("texto", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: texto
                                })(<TextArea rows="8" name="texto" placeholder="Mensagem ..." style={{ height: "unset" }} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <i className="fas fa-upload" />
                                    </p>
                                    <p className="ant-upload-text">
                                        Adicionar anexos{" "}
                                        <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </p>
                                    <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                </Dragger>
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador titulo-com-botao">
                                Partilhado
                                </h3>
                            <Form.Item className="form-item-checkbox">
                                <Radio.Group name="tipo_partilha" disabled={this.props.relatorioid} onChange={this.handlerChangeRadioPartilha} value={tipo_partilha}>
                                    <Radio value="PESSOAL">
                                        <i className="fas fa-user icon-tipo-partilha pessoal" title="Pessoal"></i>
                                            Pessoal</Radio>
                                    <Radio value="INSTRUTORES">
                                        <i className="fas fa-user-friends icon-tipo-partilha partilhado-instrutor" title="Partilhado com os professores" ></i>
                                        Com os professores</Radio>
                                    <Radio value="PARTILHADO">
                                        <i className="fas fa-users icon-tipo-partilha partilhado" title="Partilhado com o aluno"></i>
                                            Com o aluno </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            {!this.props.relatorioid &&
                                <>
                                    <Form.Item className="form-item-checkbox">
                                        <Checkbox name="notificar" checked={notificar} onChange={this.handlerChangeCheck}>
                                            Notificar por e-mail
                                        <Tooltip className="info-icon" title="Notificar os participantes por e-mail">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </Checkbox>
                                    </Form.Item>
                                    {this.context.responsaveis_entidade &&
                                        <Form.Item className="form-item-checkbox">
                                            <Checkbox name="notificar_responsavel" checked={notificar_responsavel} onChange={this.handlerChangeCheck}>
                                                Notificar Responsável por e-mail
                                            </Checkbox>
                                        </Form.Item>}
                                </>
                            }
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                    <button
                        className="botao-principal"
                        onClick={this.props.relatorioid ? this.alterar : this.adicionar}
                        disabled={iconLoading}
                    >
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                    {/*{*/}
                    {/*    <button*/}
                    {/*        className="botao-principal"*/}
                    {/*        onClick={this.alterar}*/}
                    {/*        disabled={iconLoading}*/}
                    {/*    >*/}
                    {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*                Guardar*/}
                    {/*            </button>*/}
                    {/*    :*/}
                    {/*    <button*/}
                    {/*        className="botao-principal"*/}
                    {/*        onClick={this.adicionar}*/}
                    {/*        disabled={iconLoading}*/}
                    {/*    >*/}
                    {/*        {iconLoading ? <Icon type="loading" /> : null}*/}
                    {/*                Guardar*/}
                    {/*            </button>*/}
                    {/*}*/}
                </div>
            </Drawer>
        );
    }
}

const FormDrawerRelatorio = Form.create({ name: "form-relatorio" })(DrawerRelatorio);

export default withRouter(FormDrawerRelatorio);
