import React, { Component } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Input, Select, Checkbox, InputNumber } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import moment from "moment";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerNotificarAusentes extends Component {
    static contextType = GlobalContext;
    state = {
        //INFO
        dias: "7",
        texto: ""
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeNumber = dias => {
        this.setState({ dias });
    };

    //ADICIONA UMA NOVA ANOTAÇÃO
    enviarMensagem = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                let listaAusentes = [];
                this.props.participantes.map(participante => {
                    if (moment().startOf('day').diff(moment(participante.ultimoAcesso.data).startOf('day'), 'days') >= this.state.dias)
                        listaAusentes.push(participante.id);
                });

                var item = new FormData();
                item.append("id_curso", localStorage.getItem("codigo_curso"));
                item.append("dias", this.state.dias);
                item.append("texto", this.state.texto.replace(/(?:\r\n|\r|\n)/g, "<br>"));
                item.append("participantes", JSON.stringify(listaAusentes));

                axios({
                    method: "post",
                    url: "/api/gerir-curso/notificar-ausentes",
                    data: item
                })
                    .then(() => {
                        this.setState({
                            iconLoading: false,
                            //INFO
                            dias: "7",
                            texto: ""
                        });
                        this.props.form.resetFields();
                        openNotificationWithIcon("success", "Sucesso", "E-mail enviado!");
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.setState({
                //INFO
                dias: "7",
                texto: `Olá gostariamos de o ter de volta ao curso ${localStorage.getItem("nome_curso")}. \n\n ${this.props.nomeUtilizador}`
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { dias, texto, iconLoading } = this.state;

        return (
            <Drawer
                placement="right"
                className="drawer-envio-mensagem-inscricao"
                maskClosable={false}
                closable={true}
                onClose={this.props.onClose}
                visible={this.props.visibleDrawerNotificarAusentes}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-nova-pergunta">
                        <div className="bloco">
                            <h3 className="titulo-separador">Notificar ausentes</h3>
                        </div>
                        <div className="bloco">
                            <Form.Item label="Nº de dias sem acesso ao curso">
                                {getFieldDecorator("dias", {
                                    rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                    }],
                                    initialValue: dias
                                })(<InputNumber name="dias" onChange={this.handleChangeNumber} />)}
                            </Form.Item>
                            <Form.Item label="Mensagem">
                                {getFieldDecorator("texto", {
                                    rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                        }],
                                    initialValue: texto
                                })(<TextArea rows={15} name="texto" placeholder="Mensagem..." style={{ height: "100%" }} onChange={this.handleChange} />)}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                                </button>
                    <button
                        className="botao-principal"
                        onClick={this.enviarMensagem}
                        disabled={iconLoading}
                    >
                        {iconLoading ? <Icon type="loading" /> : null}
                                    Enviar
                                </button>
                </div>
            </Drawer>
        );
    }
}

const FormDrawerNotificarAusentes = Form.create({ name: "form-notificar-ausentes" })(DrawerNotificarAusentes);

export default withRouter(FormDrawerNotificarAusentes);
