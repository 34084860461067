import React from 'react'
import { Button, Icon, Select } from "antd";

import { montarColunasDias } from "../../../Utilities/Diario/Frequencias/tabela"

const { Option } = Select;

const VistaDesktop = ({ dados, selectDiscipline, anteriorProximo, abrirModal }) => {

    // Vai popular a tabela com os dias do mês
    const montarDias = () => {
        var dias = [];
        for (let i = 0; i < dados.numDias; i++) {
            dias.push(
                <td
                    key={i}
                    style={{
                        borderTop: "1px solid #000000",
                        borderBottom: "1px solid #000000",
                        borderLeft: "1px solid #000000",
                        borderRight: "1px solid #000000"
                    }}
                    align="center"
                    valign="bottom"
                    bgcolor="#D9D9D9"
                    sdnum="1033;1033;M/D/YYYY"
                >
                    <font face="Cambria" color="#000000">
                        <br />
                        {("0" + (i + 1)).slice(-2)}
                    </font>
                </td>
            );
        }

        return dias;
    };

    //Calcula o total de frequência do mês que se está a visualizar
    const calcularTotalMes = acumulados => {
        let totalPresencas = 0;
        let totalFaltas = 0;
        let totalJustificadas = 0;

        acumulados.forEach(acumulado => {
            if (((dados.estado === "Anterior" && acumulado.periodo === dados.periodoTitulo) || dados.estado === "Proximo") && acumulado.mes === dados.mes) {
                totalPresencas += acumulado.totalPresencas;
                totalFaltas += acumulado.totalFaltas;
                totalJustificadas += acumulado.totalJustificadas;
            }
        });

        return (
            <span style={{ display: "flex", gap: 10, justifyContent: "center" }}>
                {totalPresencas > 0 && <strong style={{ color: "green" }}>P{`(${totalPresencas})`}</strong>}
                {totalFaltas > 0 && <strong style={{ color: "red" }}>F{`(${totalFaltas})`}</strong>}
                {totalJustificadas > 0 && <strong style={{ color: "orange" }}>FJ{`(${totalJustificadas})`}</strong>}
            </span>
        );
    };

    //Calcula o total de frequência em acumulado no período desde o inicio dele até ao mês que se está a visualizar
    const calcularTotalPeriodo = acumulados => {
        let totalPresencas = 0;
        let totalFaltas = 0;
        let totalJustificadas = 0;

        const index = acumulados.findIndex(x => x.periodo === dados.periodoTitulo && x.mes === dados.mes);

        if (index === -1) return null;

        acumulados.slice(0, index + 1).forEach(acumulado => {
            if (acumulado.periodo === dados.periodoTitulo) {
                totalPresencas += acumulado.totalPresencas;
                totalFaltas += acumulado.totalFaltas;
                totalJustificadas += acumulado.totalJustificadas;
            }
        });

        return (
            <span style={{ display: "flex", gap: 10, justifyContent: "center" }}>
                {totalPresencas > 0 && <strong style={{ color: "green" }}>P{`(${totalPresencas})`}</strong>}
                {totalFaltas > 0 && <strong style={{ color: "red" }}>F{`(${totalFaltas})`}</strong>}
                {totalJustificadas > 0 && <strong style={{ color: "orange" }}>FJ{`(${totalJustificadas})`}</strong>}
            </span>
        );
    };

    //Calcula o total de frequência em acumulado desde o inicio do 1º período até ao mês se está a visualizar na tabela
    const calcularTotalAcumulado = acumulados => {
        let totalPresencas = 0;
        let totalFaltas = 0;
        let totalJustificadas = 0;

        const index = acumulados.findIndex(x => x.periodo === dados.periodoTitulo && x.mes === dados.mes);

        if (index === -1) return null;

        acumulados.slice(0, index + 1).forEach(acumulado => {
            totalPresencas += acumulado.totalPresencas;
            totalFaltas += acumulado.totalFaltas;
            totalJustificadas += acumulado.totalJustificadas;
        });

        return (
            <span style={{ display: "flex", gap: 10, justifyContent: "center" }}>
                {totalPresencas > 0 && <strong style={{ color: "green" }}>P{`(${totalPresencas})`}</strong>}
                {totalFaltas > 0 && <strong style={{ color: "red" }}>F{`(${totalFaltas})`}</strong>}
                {totalJustificadas > 0 && <strong style={{ color: "orange" }}>FJ{`(${totalJustificadas})`}</strong>}
            </span>
        );
    };

    const {
        disciplinaId,
        //FILTROS
        disciplinas,
        num,
        semPeriodos,
        isSelected,
        disciplinasNome,
        //PERIODO
        titulo,
        periodos,
        participantes,
        //LOADING
        iconLoading,
        aulas,
        numDias,
        ano,
        mes
    } = dados;

    return (
        <div>
            <div className="bloco-controlos-frequencia">
                <div className="filters">
                    <div>
                        {localStorage.getItem("vista") === "TURMA" && (
                            <Select
                                className="filter"
                                placeholder="Selecionar Disciplina"
                                name="disciplinas"
                                allowClear={true}
                                onChange={selectDiscipline}
                                disabled={disciplinas.length === 0}
                            >
                                <Option value="Todos">Todos</Option>
                                {disciplinas.map(disciplina => (
                                    <Option key={disciplina.id} value={disciplina.id}>
                                        {disciplina.nome}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </div>
                    {!semPeriodos && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {isSelected === false && typeof disciplinaId === "number" ? (
                                <>
                                    <Button
                                        className="botao-periodo botao-periodo-anterior"
                                        disabled={
                                            num === 1
                                        }
                                        style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                        onClick={() => {
                                            anteriorProximo(num - 1, "Anterior");
                                        }}
                                    >
                                        <Icon type="left" />
                                    </Button>
                                    <span>{titulo}</span>
                                    <Button
                                        className="botao-periodo botao-periodo-proximo"
                                        disabled={
                                            num === periodos.length || localStorage.getItem("vista") === "TURMA" && !disciplinaId
                                        }
                                        style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                        onClick={() => {
                                            anteriorProximo(num + 1, "Proximo");
                                        }}
                                    >
                                        <Icon type="right" />
                                    </Button>{" "}
                                </>
                            ) : (
                                <>
                                    <Button
                                        className="botao-periodo botao-periodo-anterior"
                                        disabled={num === 1}
                                        style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                        onClick={() => anteriorProximo(num - 1, "Anterior")}
                                    >
                                        <Icon type="left" />
                                    </Button>
                                    <span>{titulo}</span>
                                    <Button
                                        className="botao-periodo botao-periodo-proximo"
                                        disabled={
                                            num === periodos.length || localStorage.getItem("vista") === "TURMA" && !disciplinaId
                                        }
                                        style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                        onClick={() => anteriorProximo(num + 1, "Proximo")}
                                    >
                                        <Icon type="right" />
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                    <div style={{ textAlign: "end" }}>
                        {isSelected === false ? (
                            <div style={{ fontSize: 16, fontWeight: "bold" }}>{disciplinasNome.nome}</div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <table cellSpacing={0} border={0} style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td
                            style={{
                                borderTop: "1px solid #000000",
                                borderBottom: "1px solid #000000",
                                borderLeft: "1px solid #000000",
                                borderRight: "1px solid #000000"
                            }}
                            align="center"
                            valign="bottom"
                            bgcolor="#D9D9D9"
                        >
                            <b>
                                <font face="Cambria" color="#000000">
                                    ORDEM
                                </font>
                            </b>
                        </td>
                        <td
                            style={{
                                borderTop: "1px solid #000000",
                                borderBottom: "1px solid #000000",
                                borderLeft: "1px solid #000000",
                                borderRight: "1px solid #000000"
                            }}
                            align="center"
                            valign="bottom"
                            bgcolor="#D9D9D9"
                        >
                            <b>
                                <font face="Cambria" color="#000000">
                                    ALUNOS
                                </font>
                            </b>
                        </td>
                        {montarDias().map(dia => dia)}
                        <td
                            style={{
                                borderTop: "1px solid #000000",
                                borderBottom: "1px solid #000000",
                                borderLeft: "1px solid #000000",
                                borderRight: "1px solid #000000"
                            }}
                            align="center"
                            valign="bottom"
                            bgcolor="#D9D9D9"
                        >
                            <b>
                                <font face="Cambria" color="#000000">
                                    MÊS
                                </font>
                            </b>
                        </td>
                        <td
                            style={{
                                borderTop: "1px solid #000000",
                                borderBottom: "1px solid #000000",
                                borderLeft: "1px solid #000000",
                                borderRight: "1px solid #000000"
                            }}
                            align="center"
                            valign="bottom"
                            bgcolor="#D9D9D9"
                        >
                            <b>
                                <font face="Cambria" color="#000000">
                                    PERIODO
                                </font>
                            </b>
                        </td>
                        <td
                            style={{
                                borderTop: "1px solid #000000",
                                borderBottom: "1px solid #000000",
                                borderLeft: "1px solid #000000",
                                borderRight: "1px solid #000000"
                            }}
                            align="center"
                            valign="bottom"
                            bgcolor="#D9D9D9"
                        >
                            <b>
                                <font face="Cambria" color="#000000">
                                    ACUMULADO
                                </font>
                            </b>
                        </td>
                    </tr>
                    {participantes.map((participante, index) => (
                        <tr key={participante.id}>
                            <td
                                style={{
                                    borderTop: "1px solid #000000",
                                    borderBottom: "1px solid #000000",
                                    borderLeft: "1px solid #000000",
                                    borderRight: "1px solid #000000"
                                }}
                                height={15}
                                align="center"
                                valign="middle"
                                bgcolor={index % 2 === 0 ? "#FFFFFF" : "#D9D9D9"}
                                sdval={2}
                                sdnum="1033;0;00"
                            >
                                <b>
                                    <font face="Cambria">{("0" + (index + 1)).slice(-2)}</font>
                                </b>
                            </td>
                            <td
                                style={{
                                    borderTop: "1px solid #000000",
                                    borderBottom: "1px solid #000000",
                                    borderLeft: "1px solid #000000",
                                    borderRight: "1px solid #000000"
                                }}
                                align="left"
                                valign="middle"
                                bgcolor={index % 2 === 0 ? "#FFFFFF" : "#D9D9D9"}
                            >
                                <font face="Cambria">
                                    {participante.nome}
                                    <br />
                                </font>
                            </td>
                            {montarColunasDias(
                                participante.id,
                                participante.ativo,
                                participante.dataAtualizado,
                                participante.utilizadorEstados,
                                aulas,
                                numDias,
                                ano,
                                mes,
                                abrirModal
                            ).map(dia => dia)}
                            <td
                                style={{
                                    borderTop: "1px solid #000000",
                                    borderBottom: "1px solid #000000",
                                    borderLeft: "1px solid #000000",
                                    borderRight: "1px solid #000000"
                                }}
                                align="center"
                                valign="middle"
                                sdnum="1033;0;00"
                            >
                                <font face="Cambria">{!iconLoading ? calcularTotalMes(participante.acumulados) : "..."}</font>
                            </td>
                            <td
                                style={{
                                    borderTop: "1px solid #000000",
                                    borderBottom: "1px solid #000000",
                                    borderLeft: "1px solid #000000",
                                    borderRight: "1px solid #000000"
                                }}
                                align="center"
                                valign="middle"
                                sdnum="1033;0;00"
                            >
                                <font face="Cambria">
                                    {!iconLoading ? calcularTotalPeriodo(participante.acumulados) : "..."}
                                </font>
                            </td>
                            <td
                                style={{
                                    borderTop: "1px solid #000000",
                                    borderBottom: "1px solid #000000",
                                    borderLeft: "1px solid #000000",
                                    borderRight: "1px solid #000000"
                                }}
                                align="center"
                                valign="middle"
                                sdnum="1033;0;00"
                            >
                                <font face="Cambria">
                                    {!iconLoading ? calcularTotalAcumulado(participante.acumulados) : "..."}
                                </font>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default VistaDesktop