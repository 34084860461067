import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Icon, notification, Modal } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class Login extends Component {
    static contextType = GlobalContext;
    state = {
        email: "",
        password: "",
        redirect: false,
        redirectLink: "",
        iconLoading: false,
        remember: false,
        //MODAL MENSAGEM
        visibleMensagem: false
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            remember: false
        });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleKeyPress = event => {
        if (event.key === "Enter") this.login(event);
    };

    login = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("email", this.state.email);
                item.append("password", this.state.password);
                item.append("rememberMe", this.state.remember);
                item.append("entidadeUrl", this.props.match.params.entidade);

                axios({
                    method: "post",
                    url: "/api/autenticacaonew/login",
                    data: item
                })
                    .then(response => {
                        if (response.data.token) {
                            localStorage.setItem("accessToken", response.data.token)
                            localStorage.removeItem("redirecionado")
                            axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
                            this.context.atualizarState({
                                atualizarEntidadeFooter: true,
                                is_authenticated: true,
                                is_authenticated_nav: true,
                                role: response.data.role ? response.data.role : "",
                                informacoes: response.data.informacoes
                            });
                            localStorage.removeItem("entidadeId");
                            localStorage.removeItem("nome_curso");
                            localStorage.removeItem("escola_selecionada");

                            if (response.data.senhaValida)
                                this.setState({
                                    redirectLink: response.data.link,
                                    redirect: true
                                });

                            this.setState({
                                redirectLink: `/${this.props.match.params.entidade}/alteracao-senha`,
                                redirect: true
                            });
                        }
                        else {
                            localStorage.removeItem("accessToken")
                            delete axios.defaults.headers.common.Authorization;
                        }
                    })
                    .catch(error => {
                        if (error.response.data.split(";")[0] === "NAO_PAGO") {
                            this.setState({
                                visibleMensagem: true,
                                redirectLink: error.response.data.split(";")[1]
                            });
                        }
                        else if (error.response.data.split(";")[0] === "INATIVA") {
                            openNotificationWithIcon(
                                "warning",
                                "Aviso",
                                <>
                                    Conta inativa ou excluida. Por favor entre em contato com o <button style={{ border: "none", background: "none", color: "#16817a", cursor: "pointer", padding: 0 }} onClick={() => this.setState({ redirect: true, redirectLink: `/${localStorage.getItem("entidade_url")}/suporte-contacto` })}><strong>suporte</strong></button>
                                </>
                            );
                        }
                        else if (error.response.data.split(";")[0] === "SEM_TERMOS_POLITICA") {
                            openNotificationWithIcon(
                                "warning",
                                "Aviso",
                                <>
                                    Não concluiu o seu registo. <br/> Para finalizar o tem de aceitar de aceitar a <strong>Política de Privacidade</strong> e os <strong>Termos de Uso</strong>
                                </>
                            );
                            localStorage.setItem("codigo_utilizador", error.response.data.split(";")[1])
                            this.setState({
                                redirect: true,
                                redirectLink: `/${this.props.match.params.entidade}/utilizador-registar-passo-2`
                            });
                        }
                        else {
                            openNotificationWithIcon("error", "Erro", error.response.data);
                        }

                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    render() {
        const { redirect, redirectLink, iconLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Iniciar Sessão" marginTop0={true} />
                <div className="content">
                    <Form onSubmit={this.handleSubmit} className="login template-form">
                        <Form.Item label="E-mail">
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Introduza o e-mail"
                                    }
                                ]
                            })(<Input name="email" onChange={this.handleChange} onKeyPress={this.handleKeyPress} />)}
                        </Form.Item>
                        <Form.Item label="Senha">
                            {getFieldDecorator("password", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Introduza a senha"
                                    }
                                ]
                            })(<Input.Password type="password" name="password" onChange={this.handleChange} onKeyPress={this.handleKeyPress} />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Link to="#">
                                <Button type="primary" className="login-form-button form-button" disabled={iconLoading} onClick={this.login}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Entrar
                                </Button>
                            </Link>
                            {getFieldDecorator("remember", {
                                valuePropName: "checked"
                            })(
                                <Checkbox name="remember" onChange={this.handleChangeCheckbox}>
                                    <span className="ant-lembrar-dados">Lembrar os meus dados</span>
                                </Checkbox>
                            )}
                            <div className="bloco-login-links">
                                <Link to={`/${this.props.match.params.entidade}/recuperar-senha`} className="form-link">
                                    Esqueci a senha <i className="fas fa-chevron-right"></i>
                                </Link>
                                {this.context.autoconta_entidade ?
                                    <Link to={`/${this.props.match.params.entidade}/registar-conta`} className="form-link">
                                        Criar nova conta <i className="fas fa-chevron-right"></i>
                                </Link>
                                    :
                                    <Link to={`/${this.props.match.params.entidade}/solicitar-conta`} className="form-link">
                                        Não tenho conta <i className="fas fa-chevron-right"></i>
                                </Link>
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A entrar...</p>
                    </div>
                </Modal>
                <Modal
                    title=""
                    visible={this.state.visibleMensagem}
                    onOk={() => window.location.href = this.state.redirectLink}
                    onCancel={() => this.setState({ visibleMensagem: false })}
                    okText="Subscrição de Serviços"
                    className="modal-falta-pagamento"
                >
                    <Icon type="exclamation-circle" className="anticon-exclamation-circle" />
                    <h3>Regularize a sua situação para poder acessar!</h3>
                </Modal>
            </>
        );
    }
}

const LoginForm = Form.create({ name: "form_login_publico" })(Login);

export default LoginForm;
