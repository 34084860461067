import React, { useContext, useEffect } from "react";
import MsgSecundaria from "./_Aux/MsgSecundaria";
import { GlobalContext } from "../GlobalState";
import LazyLoad from "react-lazy-load";

const PaginaErro = () => {
    return (
        <>
            <div className="pagina-erro">
                <div className="hero" style={{ height: 620 }}>
                    <div className="container" style={{ marginTop: 60 }}>
                        <div className="pagina-erro-header">
                            <div className="container-img">
                                <img src={require("../images/erro.svg")} />
                            </div>
                        </div>
                        <div className="pagina-erro-content">
                            <MsgSecundaria texto="Ocorreu um erro!" style={{ fontSize: 38 }} />
                            <MsgSecundaria texto="Tente novamente e caso o erro persista entre em contacto com o suporte." />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaginaErro;
