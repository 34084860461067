import React, { useState, useEffect } from "react";
import { Table } from "antd";
import ReactDragListView from "react-drag-listview";
import moment from "moment";
import axios from "axios";
import Controlos from "./Controlos/Controlos";

const Relatorio = ({ menuOptionSelected }) => {
    const [search, setSearch] = useState("");
    const [filterDates, setFilterDates] = useState(undefined);
    const [filterAction, setFilterAction] = useState(undefined);
    const [filterDevice, setFilterDevice] = useState(undefined);
    const [activeFilters, setActiveFilters] = useState([]);
    const [cols, setCols] = useState([]);
    const [data, setData] = useState([]);
    const [dataBackUp, setDataBackUp] = useState([]);
    const [sorter, setSorter] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        total: 0
    });
    const [loading, setLoading] = useState(false);

    const colunas = [
        {
            title: "Número",
            dataIndex: "number",
            sorter: (a, b) => a.number.localeCompare(b.number),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Nome",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Último Acesso",
            dataIndex: "lastAccess",
            sorter: (a, b) => moment(a.lastAccess).unix() - moment(b.lastAccess).unix(),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Ausência",
            dataIndex: "absence",
            sorter: (a, b) => a.absence - b.absence,
            sortDirections: ["descend", "ascend"],
            render: absence =>
                absence ? (
                    <span className={`total-absence ${absence > 7 ? "above" : "normal"}`} title="Número de dias ausente">
                        {absence} {absence > 1 ? "dias" : "dia"}
                    </span>
                ) : (
                    ""
                )
        },
        {
            title: "Localidade",
            dataIndex: "locale",
            sorter: (a, b) => a.locale.localeCompare(b.locale),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Idade",
            dataIndex: "age",
            sorter: (a, b) => a.age.localeCompare(b.age),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Escola",
            dataIndex: "school",
            sorter: (a, b) => a.school.localeCompare(b.school),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Sexo",
            dataIndex: "gender",
            sorter: (a, b) => a.gender.localeCompare(b.gender),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Perfil",
            dataIndex: "role",
            sorter: (a, b) => a.role.localeCompare(b.role),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Última Alteração",
            dataIndex: "lastChange",
            sorter: (a, b) => moment(a.lastChange).unix() - moment(b.lastChange).unix(),
            sortDirections: ["descend", "ascend"]
        }
    ];

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const columns = [...cols];
            const item = columns.splice(fromIndex, 1)[0];
            columns.splice(toIndex, 0, item);
            setCols(columns);
        },
        nodeSelector: "th"
    };

    /*const loadList = () => {
        if (search || filterSchool || filterEdition || filterCourse || filterSubcourse || filterPhase || filterRole || filterState) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/relatorios/lista-utilizadores",
                params: {
                    search,
                    columns: JSON.stringify(cols.map(col => col.dataIndex)),
                    filterSchool,
                    filterEdition,
                    filterCourse,
                    filterSubcourse,
                    filterPhase,
                    filterRole,
                    filterState
                }
            })
                .then(response => {
                    //const buildDaysAway = (data) => {
                    //    const totalDias = moment().diff(moment(data, "YYYY-MM-DD"), 'days');
                    //    return totalDias ? totalDias : 0;
                    //}

                    //const buildAge = (birthday) => {
                    //    if (birthday)
                    //        return moment().diff(birthday, 'years') + "";
                    //    else
                    //        return ""
                    //}

                    //const buildRole = (role) => {
                    //    if (role === 1)
                    //        return "Gestor";
                    //    if (role === 2)
                    //        return "Instrutor";
                    //    else
                    //        return "Aluno";
                    //}

                    //var data = response.data.map((user, index) => {
                    //    return {
                    //        key: index,
                    //        number: user.number,
                    //        name: user.name,
                    //        lastAccess: user.lastAccess,
                    //        absence: buildDaysAway(user.lastAccess),
                    //        locale: user.locale ? user.locale : "",
                    //        age: buildAge(user.birthday),
                    //        school: user.school ? user.school : "",
                    //        gender: user.gender ? user.gender : "",
                    //        role: buildRole(user.role),
                    //        lastChange: moment(user.lastChange).format("YYYY-MM-DD")
                    //    }
                    //})

                    setData(response.data);
                    setDataBackUp(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });

                    setLoading(false);
                });
        } else {
            setData([]);
        }
    };*/

    const getLogs = () => {
        if (search || filterDates || filterAction || filterDevice) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/logs/buscar-logs",
                params: {
                    search,
                    columns: JSON.stringify(cols.map(col => col.dataIndex)),
                    filterDates,
                    filterAction,
                    filterDevice
                }
            })
                .then(response => {
                    setData(response.data);
                    setDataBackUp(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });

                    setLoading(false);
                });
        } else {
            setData([]);
        }
    };

    const dataEstatica = [
        {
            key: "1",
            datahora: "2023-05-11 10:45",
            usuario: "John Brown",
            acao: "ALTERACAO",
            campoalterado: "TURMAS - 54 (11)",
            origem: "Web"
        },
        {
            key: "2",
            datahora: "2023-06-15 14:30",
            usuario: "Alice Smith",
            acao: "ALTERACAO",
            campoalterado: "TURMAS - 102 (23)",
            origem: "Mobile"
        },
        {
            key: "3",
            datahora: "2023-07-20 08:15",
            usuario: "Michael Johnson",
            acao: "ADICAO",
            campoalterado: "TURMAS - 75 (17)",
            origem: "Web"
        },
        {
            key: "4",
            datahora: "2023-08-02 16:55",
            usuario: "Emily Williams",
            acao: "REMOCAO",
            campoalterado: "TURMAS - 29 (5)",
            origem: "Mobile"
        },
        {
            key: "5",
            datahora: "2023-09-10 12:30",
            usuario: "Daniel Jones",
            acao: "EXPORTACAO",
            campoalterado: "TURMAS - 88 (9)",
            origem: "Web"
        },
        {
            key: "6",
            datahora: "2023-10-18 09:20",
            usuario: "Olivia Davis",
            acao: "IMPORTACAO",
            campoalterado: "TURMAS - 44 (8)",
            origem: "Mobile"
        },
        {
            key: "7",
            datahora: "2023-11-25 11:10",
            usuario: "William Martin",
            acao: "LOGIN",
            campoalterado: "TURMAS - 66 (15)",
            origem: "Web"
        },
        {
            key: "8",
            datahora: "2023-12-30 15:45",
            usuario: "Sophia Wilson",
            acao: "LOGOUT",
            campoalterado: "TURMAS - 33 (7)",
            origem: "Mobile"
        },
        {
            key: "9",
            datahora: "2024-01-05 17:30",
            usuario: "James Anderson",
            acao: "CONSULTAR",
            campoalterado: "TURMAS - 12 (3)",
            origem: "Web"
        },
        {
            key: "10",
            datahora: "2024-02-10 20:15",
            usuario: "Isabella Clark",
            acao: "ALTERACAO",
            campoalterado: "TURMAS - 67 (18)",
            origem: "Mobile"
        }
    ];

    useEffect(() => {
        setSearch("");
        setFilterDates(undefined);
        setFilterAction(undefined);
        setFilterDevice(undefined);
        setCols([
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "Data Hora",
                dataIndex: "data_hora",
                sorter: (a, b) => moment(a.data_hora).unix() - moment(b.data_hora).unix(),
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "Usuario",
                dataIndex: "utilizador",
                sorter: (a, b) => a.utilizador.localeCompare(b.utilizador),
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "Ação",
                dataIndex: "acao_vista",
                sorter: (a, b) => a.acao_vista.localeCompare(b.acao_vista),
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "Campos Alterados",
                dataIndex: "objeto_xml",
                sorter: (a, b) => a.objeto_xml.localeCompare(b.objeto_xml),
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "Origem",
                dataIndex: "dispositivo",
                sorter: (a, b) => a.dispositivo.localeCompare(b.dispositivo),
                sortDirections: ["descend", "ascend"]
            }
        ]);

        setData([]);
    }, [menuOptionSelected]);

    useEffect(() => {
        if (activeFilters.length === 0) getLogs();
    }, [activeFilters]);

    const handleSearch = () => {
        getLogs();
    };

    const handleChangeSearch = value => {
        setActiveFilters([...activeFilters.filter(x => x !== "SEARCH"), "SEARCH"]);
        setSearch(value);
    };

    const resetSearch = () => {
        setActiveFilters(activeFilters.filter(x => x !== "SEARCH"));
        setSearch("");
    };

    const handleChangeDates = filter => {
        if (filter) setActiveFilters([...activeFilters, "DATES"]);
        else {
            setActiveFilters(activeFilters.filter(x => x !== "DATES"));
        }
        setFilterDates(filter);
    };

    const handleChangeAction = filter => {
        if (filter) setActiveFilters([...activeFilters, "ACTION"]);
        else {
            setActiveFilters(activeFilters.filter(x => x !== "ACTION"));
        }
        setFilterAction(filter);
    };

    const handleChangeDevice = filter => {
        if (filter) setActiveFilters([...activeFilters, "DEVICE"]);
        else {
            setActiveFilters(activeFilters.filter(x => x !== "DEVICE"));
        }
        setFilterDevice(filter);
    };

    const handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = pagination;
        _pagination.current = pagination.current;

        setSorter(sorter.order ? { order: sorter.order, key: sorter.columnKey } : undefined);
        setPagination(_pagination);
    };

    return (
        <>
            <div className="report-info">
                <Controlos
                    handleSearch={handleSearch}
                    menuItemKey={menuOptionSelected?.menuItemKey}
                    menuItemName={menuOptionSelected?.menuItemName}
                    search={search}
                    handleChangeSearch={handleChangeSearch}
                    resetSearch={resetSearch}
                    filterDate={filterDates}
                    setFilterDate={filter => handleChangeDates(filter)}
                    filterAction={filterAction}
                    setFilterAction={filter => handleChangeAction(filter)}
                    filterDevice={filterDevice}
                    setFilterDevice={filter => handleChangeDevice(filter)}
                    setCols={columns => setCols(columns)}
                    sorter={sorter}
                    menuOptionSelected={menuOptionSelected}
                    disabledSearch={!search && !filterDates && !filterAction && !filterDevice}
                    disabledButtons={data.length === 0}
                    columnsPositions={cols.map(col => col.dataIndex)}
                    cols={cols}
                    data={data}
                    pageTitle={menuOptionSelected?.menuItemName}
                />
                <div className="page-container">
                    <div className="page-header">
                        <h1 className="page-title">Logs - {menuOptionSelected?.menuItemName}</h1>
                        <h3 className="page-counters">
                            <strong>Contar:</strong>
                            <span>{data.length}</span>
                        </h3>
                    </div>
                    <div className="page-body">
                        <Table
                            columns={cols}
                            pagination={pagination}
                            showSizeChanger
                            dataSource={data}
                            onChange={handleChangeTable}
                            loading={{
                                spinning: loading,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Relatorio;
