import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table } from 'antd';
import { GlobalContext } from "../../../GlobalState";
import TabelaDetalhe from "./TabelaDetalhe/TabelaDetalhe";
import axios from "axios";

const Tabela = ({ vista, match }) => {
    const globalContext = useContext(GlobalContext);
    const [assiduidade, setAssiduidade] = useState([]);
    const [colunasDadas, setColunasDadas] = useState([]);
    const [colunasFaltas, setColunasFaltas] = useState([]);
    const [periodosLetivo, setPeriodosLetivo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-assiduidade",
            params: {
                vistaHoras: vista,
                code: match.params.code,
                turmaId: globalContext[`turma${match.params.code}`]?.id,
                escolaId: globalContext[`turma${match.params.code}`]?.escola.id
            }
        })
            .then(response => {
                let colunasDadas = [];
                let colunasFaltas = [];

                response.data.periodosLetivo.map(periodo => {
                    const nome = periodo.nome.substr(0, 6);
                    colunasDadas.push({
                        title: nome,
                        dataIndex: montarDataIndexDadas(periodo.nome),
                    });
                    colunasFaltas.push({
                        title: nome,
                        dataIndex: montarDataIndexFaltas(periodo.nome),
                    });
                });


                let assiduidade = response.data.lista.map(item => {
                    let temp = {
                        key: item.id,
                        disciplinas: `${item.codigo} - ${item.nome}`,
                        totalAulasDadas: item.totalAulas,
                        total: item.totalFaltas,
                        comparecimento: item.comparecimento,
                        resultadoFinal: parseFloat(item.resultadoFinal.replace(",", ".")),
                        limitePercentagemFaltas: item.limite_percentagem_faltas,
                        semRegistos: item.semRegistos
                    };

                    colunasDadas.map((col, index) => {
                        temp = { ...temp, [`${col.dataIndex}`]: item.aulas[index] }
                    })

                    colunasFaltas.map((col, index) => {
                        temp = { ...temp, [`${col.dataIndex}`]: item.faltas[index] }
                    })

                    return temp;
                })
                
                setAssiduidade(assiduidade);
                setColunasDadas(colunasDadas);
                setColunasFaltas(colunasFaltas);
                setPeriodosLetivo(response.data.periodosLetivo);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    const montarDataIndexDadas = (nome) => {
        switch (nome) {
            case "1º Bimestre":
                return "aulasDadasPriBim";
            case "2º Bimestre":
                return "aulasDadasSegBim";
            case "3º Bimestre":
                return "aulasDadasTerBim";
            case "4º Bimestre":
                return "aulasDadasQuaBim";
            case "1º Semestre":
                return "aulasDadasPriSem";
            case "2º Semestre":
                return "aulasDadasSegBim";
            default:
                return "aulasAnual";
        }
    }

    const montarDataIndexFaltas = (nome) => {
        switch (nome) {
            case "1º Bimestre":
                return "faltasDadasPriBim";
            case "2º Bimestre":
                return "faltasDadasSegBim";
            case "3º Bimestre":
                return "faltasDadasTerBim";
            case "4º Bimestre":
                return "faltasDadasQuaBim";
            case "1º Semestre":
                return "faltasDadasPriSem";
            case "2º Semestre":
                return "faltasDadasSegBim";
            default:
                return "faltasAnual";
        }
    }

    useEffect(() => {
        if (globalContext[`turma${match.params.code}`])
            carregar();
    }, [vista, globalContext[`turma${match.params.code}`]])

    if (vista) {
        const cols = [
            {
                title: "Disciplinas",
                dataIndex: "disciplinas"
            },
            {
                title: "Aulas Dadas",
                dataIndex: "aulasDadas",
                children: colunasDadas
            },
            {
                title: "TOTAL",
                dataIndex: "totalAulasDadas"
            },
            {
                title: "Faltas / Dias",
                dataIndex: "faltasDias",
                children: colunasFaltas
            },
            {
                title: "TOTAL",
                dataIndex: "total"
            },
            {
                title: "Comparecimento",
                dataIndex: "comparecimento",
                render: (text, record) => <span>{text} (<span className={record.resultadoFinal >= record.limitePercentagemFaltas ? "percentage-above" : "percentage-below"}>{(record.resultadoFinal + "").replace(".", ",")} %</span>)</span>
            }
        ];

        const data = [
            {
                disciplinas: "Língua Portuguesa",
                aulasDadasPriBim: "50h",
                aulasDadasSecBim: "50h",
                aulasDadasTerBim: "50h",
                aulasDadasQuaBim: "50h",
                totalAulasDadas: "200h",
                faltasDiasPriBim: "-",
                faltasDiasSecBim: "-",
                faltasDiasTerBim: "-",
                faltasDiasQuaBim: "-",
                total: "-",
                comparecimento: "200h",
                resultadoFinal: 100,
            },
            {
                disciplinas: "Arte",
                aulasDadasPriBim: "10h",
                aulasDadasSecBim: "10h",
                aulasDadasTerBim: "10h",
                aulasDadasQuaBim: "10h",
                totalAulasDadas: "40h",
                faltasDiasPriBim: "-",
                faltasDiasSecBim: "-",
                faltasDiasTerBim: "-",
                faltasDiasQuaBim: "-",
                total: "-",
                comparecimento: "40h",
                resultadoFinal: 100,
            },
            {
                disciplinas: "Educação Física",
                aulasDadasPriBim: "20h",
                aulasDadasSecBim: "20h",
                aulasDadasTerBim: "20h",
                aulasDadasQuaBim: "20h",
                totalAulasDadas: "80h",
                faltasDiasPriBim: "-",
                faltasDiasSecBim: "-",
                faltasDiasTerBim: "-",
                faltasDiasQuaBim: "-",
                total: "-",
                comparecimento: "80h",
                resultadoFinal: 100,
            },
            {
                disciplinas: "Ciências",
                aulasDadasPriBim: "40h",
                aulasDadasSecBim: "40h",
                aulasDadasTerBim: "40h",
                aulasDadasQuaBim: "40h",
                totalAulasDadas: "160h",
                faltasDiasPriBim: "-",
                faltasDiasSecBim: "-",
                faltasDiasTerBim: "-",
                faltasDiasQuaBim: "-",
                total: "-",
                comparecimento: "160h",
                resultadoFinal: 100,
            },
            {
                disciplinas: "Matemática",
                aulasDadasPriBim: "20h",
                aulasDadasSecBim: "20h",
                aulasDadasTerBim: "20h",
                aulasDadasQuaBim: "20h",
                totalAulasDadas: "80h",
                faltasDiasPriBim: "-",
                faltasDiasSecBim: "-",
                faltasDiasTerBim: "-",
                faltasDiasQuaBim: "-",
                total: "-",
                comparecimento: "80h",
                resultadoFinal: 100,
            },
            {
                disciplinas: "História",
                aulasDadasPriBim: "30h",
                aulasDadasSecBim: "30h",
                aulasDadasTerBim: "30h",
                aulasDadasQuaBim: "30h",
                totalAulasDadas: "120h",
                faltasDiasPriBim: "-",
                faltasDiasSecBim: "-",
                faltasDiasTerBim: "-",
                faltasDiasQuaBim: "-",
                total: "-",
                comparecimento: "120h",
                resultadoFinal: 100,
            },
            {
                disciplinas: "Geografia",
                aulasDadasPriBim: "10h",
                aulasDadasSecBim: "10h",
                aulasDadasTerBim: "10h",
                aulasDadasQuaBim: "10h",
                totalAulasDadas: "40h",
                faltasDiasPriBim: "-",
                faltasDiasSecBim: "-",
                faltasDiasTerBim: "-",
                faltasDiasQuaBim: "-",
                total: "-",
                comparecimento: "40h",
                resultadoFinal: 100,
            },
            {
                disciplinas: "Ensino Religioso",
                aulasDadasPriBim: "20h",
                aulasDadasSecBim: "20h",
                aulasDadasTerBim: "20h",
                aulasDadasQuaBim: "20h",
                totalAulasDadas: "80h",
                faltasDiasPriBim: "-",
                faltasDiasSecBim: "-",
                faltasDiasTerBim: "-",
                faltasDiasQuaBim: "-",
                total: "-",
                comparecimento: "80h",
                resultadoFinal: 100,
            },
            {
                disciplinas: "Projeto Integrador",
                aulasDadasPriBim: "10h",
                aulasDadasSecBim: "10h",
                aulasDadasTerBim: "10h",
                aulasDadasQuaBim: "10h",
                totalAulasDadas: "40h",
                faltasDiasPriBim: "-",
                faltasDiasSecBim: "-",
                faltasDiasTerBim: "-",
                faltasDiasQuaBim: "-",
                total: "-",
                comparecimento: "40h",
                resultadoFinal: 100,
            },
        ]

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <Table
                className="tabela-aproveitamento"
                columns={cols}
                pagination={false}
                dataSource={assiduidade}
                loading={{
                    spinning: loading,
                    indicator: (
                        <div className="loading-data-table">
                            <div className="loading" />
                        </div>
                    )
                }}
                expandedRowRender={record =>
                    <TabelaDetalhe
                        vista={vista}
                        record={record}
                        periodosLetivo={periodosLetivo}
                    />
                }
            />
        );
    }

    const cols = [
        {
            title: "Disciplinas",
            dataIndex: "disciplinas"
        },
        {
            title: "Aulas Dadas",
            dataIndex: "aulasDadas",
            children: colunasDadas
        },
        {
            title: "TOTAL",
            dataIndex: "totalAulasDadas"
        },
        {
            title: "Faltas / Dias",
            dataIndex: "faltasDias",
            children: colunasFaltas
        },
        {
            title: "TOTAL",
            dataIndex: "total"
        },
        {
            title: "Comparecimento",
            dataIndex: "comparecimento",
            render: (text, record) => <span>{text} (<span className={record.resultadoFinal >= record.limitePercentagemFaltas ? "percentage-above" : "percentage-below"}>{(record.resultadoFinal + "").replace(".", ",")} %</span>)</span>
        }
    ];

    const data = [
        {
            disciplinas: "Língua Portuguesa",
            aulasDadasPriBim: 10,
            aulasDadasSecBim: 10,
            aulasDadasTerBim: 10,
            aulasDadasQuaBim: 10,
            totalAulasDadas: 40,
            faltasDiasPriBim: 5,
            faltasDiasSecBim: 5,
            faltasDiasTerBim: 5,
            faltasDiasQuaBim: 5,
            total: 20,
            comparecimento: 20,
            resultadoFinal: 50,
        },
        {
            disciplinas: "Arte",
            aulasDadasPriBim: 10,
            aulasDadasSecBim: 10,
            aulasDadasTerBim: 10,
            aulasDadasQuaBim: 10,
            totalAulasDadas: 40,
            faltasDiasPriBim: "-",
            faltasDiasSecBim: "-",
            faltasDiasTerBim: "-",
            faltasDiasQuaBim: "-",
            total: "-",
            comparecimento: 40,
            resultadoFinal: 100,
        },
        {
            disciplinas: "Educação Física",
            aulasDadasPriBim: 20,
            aulasDadasSecBim: 20,
            aulasDadasTerBim: 20,
            aulasDadasQuaBim: 20,
            totalAulasDadas: 80,
            faltasDiasPriBim: "-",
            faltasDiasSecBim: "-",
            faltasDiasTerBim: "-",
            faltasDiasQuaBim: "-",
            total: "-",
            comparecimento: 80,
            resultadoFinal: 100,
        },
        {
            disciplinas: "Ciências",
            aulasDadasPriBim: 40,
            aulasDadasSecBim: 40,
            aulasDadasTerBim: 40,
            aulasDadasQuaBim: 40,
            totalAulasDadas: 160,
            faltasDiasPriBim: "-",
            faltasDiasSecBim: "-",
            faltasDiasTerBim: "-",
            faltasDiasQuaBim: "-",
            total: "-",
            comparecimento: 160,
            resultadoFinal: 100,
        },
        {
            disciplinas: "Matemática",
            aulasDadasPriBim: 20,
            aulasDadasSecBim: 20,
            aulasDadasTerBim: 20,
            aulasDadasQuaBim: 20,
            totalAulasDadas: 80,
            faltasDiasPriBim: "-",
            faltasDiasSecBim: "-",
            faltasDiasTerBim: "-",
            faltasDiasQuaBim: "-",
            total: "-",
            comparecimento: 80,
            resultadoFinal: 100,
        },
        {
            disciplinas: "História",
            aulasDadasPriBim: 30,
            aulasDadasSecBim: 30,
            aulasDadasTerBim: 30,
            aulasDadasQuaBim: 30,
            totalAulasDadas: 120,
            faltasDiasPriBim: "-",
            faltasDiasSecBim: "-",
            faltasDiasTerBim: "-",
            faltasDiasQuaBim: "-",
            total: "-",
            comparecimento: 120,
            resultadoFinal: 100,
        },
        {
            disciplinas: "Geografia",
            aulasDadasPriBim: 10,
            aulasDadasSecBim: 10,
            aulasDadasTerBim: 10,
            aulasDadasQuaBim: 10,
            totalAulasDadas: 40,
            faltasDiasPriBim: "-",
            faltasDiasSecBim: "-",
            faltasDiasTerBim: "-",
            faltasDiasQuaBim: "-",
            total: "-",
            comparecimento: 40,
            resultadoFinal: 100,
        },
        {
            disciplinas: "Ensino Religioso",
            aulasDadasPriBim: 20,
            aulasDadasSecBim: 20,
            aulasDadasTerBim: 20,
            aulasDadasQuaBim: 20,
            totalAulasDadas: 80,
            faltasDiasPriBim: "-",
            faltasDiasSecBim: "-",
            faltasDiasTerBim: "-",
            faltasDiasQuaBim: "-",
            total: "-",
            comparecimento: 80,
            resultadoFinal: 100,
        },
        {
            disciplinas: "Projeto Integrador",
            aulasDadasPriBim: 10,
            aulasDadasSecBim: 10,
            aulasDadasTerBim: 10,
            aulasDadasQuaBim: 10,
            totalAulasDadas: 40,
            faltasDiasPriBim: "-",
            faltasDiasSecBim: "-",
            faltasDiasTerBim: "-",
            faltasDiasQuaBim: "-",
            total: "-",
            comparecimento: 40,
            resultadoFinal: 100,
        },
    ]

    return (
        <Table
            className="tabela-aproveitamento"
            columns={cols}
            pagination={false}
            dataSource={assiduidade}
            loading={{
                spinning: loading,
                indicator: (
                    <div className="loading-data-table">
                        <div className="loading" />
                    </div>
                )
            }}
            expandedRowRender={record =>
                <TabelaDetalhe
                    record={record}
                    periodosLetivo={periodosLetivo}
                />
            }
        />
    );
}

export default withRouter(Tabela);