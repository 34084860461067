import React, { useEffect, useState, useContext } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Form, Input, Icon, Row, Col, DatePicker, TimePicker, Checkbox, notification, Select, Modal, Upload, Switch, Tooltip } from "antd";
import { GlobalContext } from "../../../GlobalState";
import moment from "moment";
import axios from "axios";

const { Option } = Select;
const { TextArea } = Input;
const Dragger = Upload.Dragger;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Detalhe = ({ aulaId, cargaAcima, duplicada, foraPeriodo, extracurricular, form, match, confirmarAula }) => {
    const [loading, setLoading] = useState(false);
    const [intervalos, setIntervalos] = useState({
        inicio: null,
        termino: null
    });
    const [data, setData] = useState(null);
    const [horaInicio, setHoraInicio] = useState(null);
    const [horaTermino, setHoraTermino] = useState(null);
    const [cargaHoraria, setCargaHoraria] = useState(undefined);
    const [dataComp, setDataComp] = useState(null);
    const [horaInicioComp, setHoraInicioComp] = useState(null);
    const [horaTerminoComp, setHoraTerminoComp] = useState(null);
    const [cargaHorariaComp, setCargaHorariaComp] = useState(undefined);
    const [listaProfessores, setListaProfessores] = useState([]);
    const [professorId, setProfessorId] = useState(undefined);
    const [listaSalas, setListaSalas] = useState([]);
    const [salaId, setSalaId] = useState(undefined);
    const [observacoes, setObservacoes] = useState("");
    const [aulaAvaliacao, setAulaAvaliacao] = useState(false);
    const [aulaExtracurricular, setAulaExtracurricular] = useState(false);
    const [falta, setFalta] = useState(false);
    const [reorganizacao, setReorganizacao] = useState(false);
    const [guardarRecorrencia, setGuardarRecorrencia] = useState(false);
    const [codigoRecorrencia, setCodigoRecorrencia] = useState(undefined);
    const [motivo, setMotivo] = useState("");
    const [permissao, setPermissao] = useState(false);
    const [editar, setEditar] = useState(false);
    const [reagendar, setReagendar] = useState(false);
    const [reagendada, setReagendada] = useState(false);
    const [ficheiro, setFicheiro] = useState("");
    const [backup, setBackup] = useState({
        data: null,
        horaInicio: null,
        horaTermino: null,
        cargaHoraria: undefined,
        dataComp: null,
        horaInicioComp: null,
        horaTerminoComp: null,
        cargaHorariaComp: undefined,
        professorId: undefined,
        salaId: undefined,
        observacoes: "",
        aulaAvaliacao: false,
        aulaExtracurricular: false,
        falta: false,
        codigoRecorrencia: codigoRecorrencia,
        motivo: ""
    });
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const globalContext = useContext(GlobalContext);

    const { getFieldDecorator } = form;

    const handleChangeHoraInicio = horaInicio => {
        form.resetFields();
        setHoraInicio(horaInicio);
        setHoraTermino(moment(horaInicio).add(1, "hour"));
    };

    const handleChangeHoraInicioComp = horaInicio => {
        form.resetFields();
        setHoraInicioComp(horaInicio);
        setHoraTerminoComp(moment(horaInicio).add(1, "hour"));
    };

    const validarFormatos = (formato, formatos_aceites) => {
        var valido = false;

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato === formatos_aceites[i]) valido = true;
        }

        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file)
            if (
                !validarFormatos(value.file.name.split(".").pop().toLowerCase(), [
                    "zip",
                    "rar",
                    "7z",
                    "docx",
                    "doc",
                    "pdf",
                    "odf",
                    "txt",
                    "rtf",
                    "xlsx",
                    "xls",
                    "csv",
                    "ods",
                    "pptx",
                    "ppt",
                    "odp",
                    "bmp",
                    "gif",
                    "png",
                    "jpeg",
                    "jpg"
                ]) ||
                value.file.size > 26214400
            )
                callback("Campo obrigatório");

        callback();
    };

    const propsFileUpload = {
        accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
        multiple: false,
        onRemove: file => {
            form.resetFields("download");
            setFicheiro([]);
        },
        defaultFileList: ficheiro,
        beforeUpload: file => {
            if (
                !validarFormatos(file.name.split(".").pop().toLowerCase(), [
                    "zip",
                    "rar",
                    "7z",
                    "docx",
                    "doc",
                    "pdf",
                    "odf",
                    "txt",
                    "rtf",
                    "xlsx",
                    "xls",
                    "csv",
                    "ods",
                    "pptx",
                    "ppt",
                    "odp",
                    "bmp",
                    "gif",
                    "png",
                    "jpeg",
                    "jpg"
                ])
            ) {
                openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
                return false;
            }

            if (file.size > 26214400) {
                openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload");
                return false;
            }
            setFicheiro([file]);

            return false;
        },
        ficheiro
    };

    const listarProfessores = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-professores-detalhe-aula",
            params: {
                aulaId: aulaId
            }
        })
            .then(response => {
                setListaProfessores(response.data);
            })
            .catch(() => {});
    };

    const listarSalas = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-salas",
            params: {
                estabelecimentoId: globalContext.estabelecimento?.id
            }
        })
            .then(response => {
                setListaSalas(response.data);
            })
            .catch(() => {});
    };

    const validarPermissao = async () => {
        try {
            const { data } = await axios.get("/api/aulas/validar-permissao-aula", {
                params: { aulaId }
            });

            const { perfil } = globalContext;
            const permissoesEspeciais = ["Suporte", "Gestor", "Administrativo"];

            setPermissao(data || permissoesEspeciais.includes(perfil?.nome));
        } catch (error) {
            console.error("Erro ao validar permissão", error);
        }
    };

    const carregarDetalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe",
            params: {
                aulaId
            }
        })
            .then(response => {
                const evento = response.data;
                setData(moment(evento.dt_inicio));
                setHoraInicio(moment(evento.hr_inicio, "HH:mm"));
                setHoraTermino(moment(evento.hr_termino, "HH:mm"));

                if (evento.carga_horaria) setCargaHoraria(evento.carga_horaria);
                if (evento.dt_inicio_reagendada) setDataComp(moment(evento.dt_inicio_reagendada));
                if (evento.hr_inicio_reagendada) setHoraInicioComp(moment(evento.hr_inicio_reagendada, "HH:mm"));
                if (evento.hr_termino_reagendada) setHoraTerminoComp(moment(evento.hr_termino_reagendada, "HH:mm"));
                if (evento.carga_horaria_reagendada) setCargaHorariaComp(evento.carga_horaria_reagendada);
                if (evento.id_professor) setProfessorId(evento.id_professor);
                if (evento.motivo) setMotivo(evento.motivo);

                setSalaId(evento.id_sala);
                setObservacoes(evento.titulo);
                setAulaAvaliacao(evento.avaliacao);
                setAulaExtracurricular(evento.extracurricular);
                setFalta(evento.falta);
                setCodigoRecorrencia(evento.recorrencia);

                setReagendada(
                    evento.dt_inicio_reagendada != null &&
                        evento.hr_inicio_reagendada != null &&
                        evento.hr_termino_reagendada != null &&
                        evento.carga_horaria_reagendada != null
                );

                const fichs = evento.ficheiros.map(file => ({
                    uid: file.id,
                    name: file.nome,
                    status: "done",
                    url: file.url
                }));
                setFicheiro(fichs);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    };

    const guardar = () => {
        if (falta) marcarFalta();
        else alterar();
    };

    const marcarFalta = multisseriado => {
        if (setProfessorId != null && professorId != null)
            confirm({
                title: "Pretende marcar falta para esta aula? Os dados que possam existir nesta aula não serão contabilizados, como por exemplo as frequências.",
                okText: "Confirmar",
                okType: "Cancelar",
                onOk: () => {
                    alterar(false, multisseriado);
                }
            });
        else openNotificationWithIcon("error", "Erro", "Para marcar a aula com falta precisa ser selecionado o professor");
    };

    const confirmar = () => {
        let texto = "Atenção que ao confirmar esta aula poderá ficar com frequências duplicadas.";
        if (foraPeriodo) texto = "Atenção que ao confirmar poderá haver incoerências na frequencia.";

        confirm({
            title: texto,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/aulas/confirmar-aula",
                    params: {
                        aulaId: aulaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Aula confirmada!");
                        confirmarAula();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível confirmar aula.");
                    });
            }
        });
    };

    const alterar = confirmado => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("id", aulaId);
                item.append("dt_inicio", data.format("YYYY/MM/DD"));
                item.append("hr_inicio", horaInicio.format("HH:mm"));
                item.append("hr_termino", horaTermino.format("HH:mm"));
                item.append("carga_horaria", cargaHoraria);
                if (dataComp) item.append("dt_inicio_reagendada", dataComp.format("YYYY/MM/DD"));
                if (horaInicioComp) item.append("hr_inicio_reagendada", horaInicioComp.format("HH:mm"));
                if (horaTerminoComp) item.append("hr_termino_reagendada", horaTerminoComp.format("HH:mm"));
                if (cargaHorariaComp) item.append("carga_horaria_reagendada", cargaHorariaComp);
                if (permissao && professorId) item.append("id_professor", professorId);
                if (salaId) item.append("id_sala", salaId);
                if (observacoes) item.append("titulo", observacoes);
                item.append("avaliacao", aulaAvaliacao);
                item.append("extracurricular", aulaExtracurricular);
                item.append("falta_prof", falta);
                item.append("falta_motivo_prof", motivo);
                item.append("isRecorrencia", guardarRecorrencia);
                item.append("reorganizacao", reorganizacao);
                item.append("reagendar", reagendar);
                item.append("ficheiro_nome", ficheiro[0]);
                if (confirmado) item.append("confirmado", confirmado);

                axios({
                    method: "put",
                    url: "/api/aulas/alterar-aula",
                    data: item
                })
                    .then(response => {
                        if (response.data) {
                            AlertarSobreposicao(response.data);

                            return null;
                        }

                        setLoading(false);
                        if (!reagendar || reagendada) {
                            openNotificationWithIcon("success", "Sucesso", "Aula atualizada!");
                            setEditar(false);
                        } else {
                            openNotificationWithIcon("success", "Sucesso", "Aula reagendada!");
                            //setRedirectLink(`/gerir-detalhe-turma/${match.params.curso}/horario-disciplina`);
                            setRedirectLink(localStorage.getItem("link_voltar_calendario"));
                            setRedirect(true);
                        }
                    })
                    .catch(error => {
                        if (!error.response.data) openNotificationWithIcon("error", "Erro", "Não foi possível alterar aula.");

                        openNotificationWithIcon("error", "Erro", error.response.data.mensagem);

                        setLoading(false);
                    });
            }
        });
        setGuardarRecorrencia(false);
        setReorganizacao(false);
    };

    const AlertarSobreposicao = mensagem => {
        confirm({
            title: mensagem,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                alterar(true);
            },
            onCancel: () => {
                setLoading(false);
            }
        });
    };

    const apanharUltimaHora = (prevista, dataInicio) => {
        axios({
            method: "get",
            url: "/api/aulas/apanhar-ultima-hora-aula",
            params: {
                aulaId: aulaId,
                data: dataInicio.format("YYYY/MM/DD")
            }
        })
            .then(response => {
                form.resetFields();

                if (response.data.hr_inicio && response.data.hr_termino) {
                    if (prevista) {
                        setHoraInicio(moment(response.data.hr_inicio, "HH:mm"));
                        setHoraTermino(moment(response.data.hr_termino, "HH:mm"));
                    } else {
                        setHoraInicioComp(moment(response.data.hr_inicio, "HH:mm"));
                        setHoraTerminoComp(moment(response.data.hr_termino, "HH:mm"));
                    }
                } else {
                    if (prevista) {
                        setHoraInicio(moment(response.data, "HH:mm"));
                        setHoraTermino(moment(response.data, "HH:mm").add(1, "hour"));
                    } else {
                        setHoraInicioComp(moment(response.data, "HH:mm"));
                        setHoraTerminoComp(moment(response.data, "HH:mm").add(1, "hour"));
                    }
                }
            })
            .catch(() => {});
    };

    const calcularCargaHoraria = () => {
        const inicio = horaInicio;
        const termino = horaTermino;

        const formatoHora = "HH:mm";
        const inicioMoment = moment(inicio, formatoHora);
        const terminoMoment = moment(termino, formatoHora);

        const duracao = moment.duration(terminoMoment.diff(inicioMoment));

        const minutosTotais = duracao.asMinutes();

        const minutosMinimos = Math.max(minutosTotais, 60);

        const minutosArredondados = Math.ceil(minutosMinimos / 30) * 30;

        const cargaHorariaFormatada = moment.utc(minutosArredondados * 60000).format("HH:mm");

        setCargaHoraria(cargaHorariaFormatada);
    };

    const calcularCargaHorariaComp = () => {
        const inicio = horaInicioComp;
        const termino = horaTerminoComp;

        const formatoHora = "HH:mm";
        const inicioMoment = moment(inicio, formatoHora);
        const terminoMoment = moment(termino, formatoHora);

        const duracao = moment.duration(terminoMoment.diff(inicioMoment));

        const minutosTotais = duracao.asMinutes();

        const minutosMinimos = Math.max(minutosTotais, 60);

        const minutosArredondados = Math.ceil(minutosMinimos / 30) * 30;

        const cargaHorariaFormatada = moment.utc(minutosArredondados * 60000).format("HH:mm");

        setCargaHorariaComp(cargaHorariaFormatada);
    };

    const criarBackup = () => {
        setBackup({
            data: data,
            horaInicio: horaInicio,
            horaTermino: horaTermino,
            cargaHoraria: cargaHoraria,
            dataComp: dataComp,
            horaInicioComp: horaInicioComp,
            horaTerminoComp: horaTerminoComp,
            cargaHorariaComp: cargaHorariaComp,
            professorId: professorId,
            salaId: salaId,
            observacoes: observacoes,
            aulaAvaliacao: aulaAvaliacao,
            aulaExtracurricular: aulaExtracurricular,
            falta: falta,
            guardarRecorrencia: guardarRecorrencia,
            motivo: motivo
        });
    };

    const usarBakcup = () => {
        form.resetFields();
        setData(backup.data);
        setHoraInicio(backup.horaInicio);
        setHoraTermino(backup.horaTermino);
        setCargaHoraria(backup.cargaHoraria);
        setDataComp(backup.dataComp);
        setHoraInicioComp(backup.horaInicioComp);
        setHoraTerminoComp(backup.horaTerminoComp);
        setCargaHorariaComp(backup.cargaHorariaComp);
        setProfessorId(backup.professorId);
        setSalaId(backup.salaId);
        setObservacoes(backup.observacoes);
        setAulaAvaliacao(backup.aulaAvaliacao);
        setAulaExtracurricular(backup.aulaExtracurricular);
        setFalta(backup.falta);
        setGuardarRecorrencia(backup.guardarRecorrencia);
        setMotivo(backup.motivo);
    };

    useEffect(() => {
        listarProfessores();
        listarSalas();
        validarPermissao();
        carregarDetalhe();
    }, []);

    useEffect(() => {
        listarProfessores();
        listarSalas();
        validarPermissao();
    }, [globalContext.estabelecimento]);

    useEffect(() => {
        if (horaInicio != null && horaTermino != null) calcularCargaHoraria();

        if (horaInicioComp != null && horaTerminoComp != null) calcularCargaHorariaComp();
    }, [horaInicio, horaTermino, horaInicioComp, horaTerminoComp]);

    if (redirect) return <Redirect to={redirectLink} />;

    const acesso = globalContext.acessos?.find(x => x.func === 17);

    const canalIranduba = localStorage.getItem("entidade_id") === "33";

    return (
        <>
            <Form className="form-categorias" layout="horizontal">
                <div className="bloco">
                    <Row>
                        <Col span={12}>
                            <div className="gutter-box">
                                <Form.Item
                                    label={
                                        <>
                                            <label>
                                                Aula
                                                {!reagendada && foraPeriodo && !extracurricular && (
                                                    <span className="aula-problemas">
                                                        <Icon type="warning" title="Aula fora do periodo" />
                                                    </span>
                                                )}
                                            </label>
                                            {!reagendada && acesso?.update && (
                                                <span style={{ marginLeft: 60, display: "inline-flex", gap: 5 }}>
                                                    {!reagendada && (
                                                        <button
                                                            className="botao-editar"
                                                            disabled={editar || reagendar}
                                                            style={{
                                                                height: 20,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                padding: 10,
                                                                fontSize: 11
                                                            }}
                                                            onClick={() => {
                                                                setEditar(true);
                                                                criarBackup();
                                                            }}
                                                            type="primary"
                                                        >
                                                            Editar
                                                        </button>
                                                    )}
                                                    <button
                                                        className="botao-editar"
                                                        disabled={editar || reagendar}
                                                        style={{
                                                            height: 20,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 10,
                                                            fontSize: 11
                                                        }}
                                                        onClick={() => {
                                                            setReagendar(true);
                                                            criarBackup();
                                                            setGuardarRecorrencia(false);
                                                        }}
                                                        type="primary"
                                                    >
                                                        Reagendar
                                                    </button>
                                                </span>
                                            )}
                                        </>
                                    }
                                >
                                    {getFieldDecorator("data", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: data
                                    })(
                                        <DatePicker
                                            className="input-50"
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            name="data"
                                            onChange={data => {
                                                setData(data);
                                                apanharUltimaHora(true, data);
                                            }}
                                            disabled={!editar || reagendada}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <span style={{ display: "inline-flex", alignItems: "center" }}>
                                            <span>Hora Inicio</span>
                                            {!reagendada && duplicada && ((canalIranduba && !extracurricular) || !canalIranduba) && (
                                                <span className="aula-problemas" style={{ display: "inline-flex" }}>
                                                    <Icon type="warning" title="Aula sobreposta" />
                                                </span>
                                            )}
                                        </span>
                                    }
                                >
                                    {getFieldDecorator("horaInicio", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: horaInicio
                                    })(
                                        <TimePicker
                                            className="input-50"
                                            name="horaInicio"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            placeholder="Selecionar"
                                            disabled={!editar || reagendada}
                                            onChange={handleChangeHoraInicio}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <span style={{ display: "inline-flex", alignItems: "center" }}>
                                            <span>Hora Término</span>
                                            {!reagendada && duplicada && ((canalIranduba && !extracurricular) || !canalIranduba) && (
                                                <span className="aula-problemas" style={{ display: "inline-flex" }}>
                                                    <Icon type="warning" title="Aula sobreposta" />
                                                </span>
                                            )}
                                        </span>
                                    }
                                >
                                    {getFieldDecorator("horaTermino", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: horaTermino
                                    })(
                                        <TimePicker
                                            className="input-50"
                                            name="horaTermino"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            placeholder="Selecionar"
                                            onChange={horaTermino => setHoraTermino(horaTermino)}
                                            disabled={!editar || reagendada}
                                        />
                                    )}
                                    {cargaHoraria && (
                                        <div style={{ borderTop: "1px solid #d9d9d9", marginTop: 10, paddingTop: 5, width: "50%" }}>
                                            <span style={{ display: "block", lineHeight: "initial" }}>
                                                <strong>Carga Horária:</strong> {cargaHoraria}
                                                {/*{!reagendada && cargaAcima && (*/}
                                                {/*    <span className="aula-problemas">*/}
                                                {/*        <Icon type="warning" title="Carga horária acima do limite de 5 horas" />*/}
                                                {/*    </span>*/}
                                                {/*)}*/}
                                            </span>
                                        </div>
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        {(reagendar || reagendada) && (
                            <Col span={12}>
                                <div className="gutter-box">
                                    <Form.Item
                                        label={
                                            <>
                                                <label>
                                                    Aula Reagendada
                                                    {reagendada && foraPeriodo && !aulaExtracurricular && (
                                                        <span className="aula-problemas">
                                                            <Icon type="warning" title="Aula fora do periodo" />
                                                        </span>
                                                    )}
                                                </label>
                                                {reagendada && acesso?.update && (
                                                    <span style={{ marginLeft: 70, display: "inline-flex", gap: 5 }}>
                                                        <button
                                                            className="botao-editar"
                                                            disabled={reagendar}
                                                            style={{
                                                                height: 20,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                padding: 10,
                                                                fontSize: 11
                                                            }}
                                                            onClick={() => {
                                                                setReagendar(true);
                                                                criarBackup();
                                                            }}
                                                            type="primary"
                                                        >
                                                            Editar
                                                        </button>
                                                    </span>
                                                )}
                                            </>
                                        }
                                    >
                                        {getFieldDecorator("dataComp", {
                                            rules: [
                                                {
                                                    required: reagendar,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: dataComp
                                        })(
                                            <DatePicker
                                                className="input-50"
                                                placeholder="Selecionar"
                                                format="DD-MM-YYYY"
                                                name="dataComp"
                                                onChange={data => {
                                                    setDataComp(data);
                                                    apanharUltimaHora(false, data);
                                                }}
                                                disabled={!reagendar}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span style={{ display: "inline-flex", alignItems: "center" }}>
                                                <span>Hora Inicio</span>
                                                {reagendada && duplicada && ((canalIranduba && !extracurricular) || !canalIranduba) && (
                                                    <span className="aula-problemas" style={{ display: "inline-flex" }}>
                                                        <Icon type="warning" title="Aula sobreposta" />
                                                    </span>
                                                )}
                                            </span>
                                        }
                                    >
                                        {getFieldDecorator("horaInicioComp", {
                                            rules: [
                                                {
                                                    required: reagendar,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: horaInicioComp
                                        })(
                                            <TimePicker
                                                className="input-50"
                                                name="horaInicioComp"
                                                defaultOpenValue={moment("00:00", "HH:mm")}
                                                format="HH:mm"
                                                onChange={handleChangeHoraInicioComp}
                                                placeholder="Selecionar"
                                                suffixIcon={false}
                                                clearIcon={false}
                                                disabled={!reagendar}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span style={{ display: "inline-flex", alignItems: "center" }}>
                                                <span>Hora Término</span>
                                                {reagendada && duplicada && ((canalIranduba && !extracurricular) || !canalIranduba) && (
                                                    <span className="aula-problemas" style={{ display: "inline-flex" }}>
                                                        <Icon type="warning" title="Aula sobreposta" />
                                                    </span>
                                                )}
                                            </span>
                                        }
                                    >
                                        {getFieldDecorator("horaTerminoComp", {
                                            rules: [
                                                {
                                                    required: reagendar,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: horaTerminoComp
                                        })(
                                            <TimePicker
                                                className="input-50"
                                                name="horaTerminoComp"
                                                defaultOpenValue={moment("00:00", "HH:mm")}
                                                format="HH:mm"
                                                onChange={horaTerminoComp => setHoraTerminoComp(horaTerminoComp)}
                                                placeholder="Selecionar"
                                                suffixIcon={false}
                                                clearIcon={false}
                                                disabled={!reagendar}
                                            />
                                        )}
                                        {cargaHorariaComp && (
                                            <div style={{ borderTop: "1px solid #d9d9d9", marginTop: 10, paddingTop: 5, width: "50%" }}>
                                                <span style={{ display: "block", lineHeight: "initial" }}>
                                                    <strong>Carga Horária:</strong> {cargaHorariaComp}
                                                    {/*{!reagendada && cargaAcima && (*/}
                                                    {/*    <span className="aula-problemas">*/}
                                                    {/*        <Icon type="warning" title="Carga horária acima do limite de 5 horas" />*/}
                                                    {/*    </span>*/}
                                                    {/*)}*/}
                                                </span>
                                            </div>
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        )}
                    </Row>
                    {permissao && (
                        <Form.Item label="Professor">
                            {getFieldDecorator("professor", {
                                //rules: [
                                //    {
                                //        required: true,
                                //        message: "Campo obrigatório"
                                //    }
                                //],
                                initialValue: professorId
                            })(
                                <Select
                                    placeholder="Selecionar"
                                    disabled={(!editar && !reagendar) || guardarRecorrencia}
                                    onChange={professorId => setProfessorId(professorId)}
                                    allowClear={true}
                                >
                                    {listaProfessores.map(professor => (
                                        <Option key={professor.id} value={professor.id}>
                                            {professor.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    )}
                    <Form.Item label="Sala">
                        {getFieldDecorator("sala", {
                            initialValue: salaId
                        })(
                            <Select
                                placeholder="Selecionar"
                                disabled={(!editar && !reagendar) || guardarRecorrencia}
                                onChange={salaId => setSalaId(salaId)}
                            >
                                {listaSalas.map(sala => (
                                    <Option key={sala.id} value={sala.id}>
                                        {sala.nome}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Observações">
                        {getFieldDecorator("observacoes", {
                            initialValue: observacoes
                        })(
                            <TextArea
                                name="observacoes"
                                disabled={(!editar && !reagendar) || guardarRecorrencia}
                                onChange={event => setObservacoes(event.target.value)}
                                rows={4}
                                style={{ height: 100 }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <Switch
                                size="small"
                                checked={aulaAvaliacao}
                                disabled={!editar && !reagendar}
                                onChange={avaliacao => setAulaAvaliacao(avaliacao)}
                            />
                            Aula de avaliação
                        </span>
                        <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <Switch
                                size="small"
                                checked={aulaExtracurricular}
                                disabled={!editar && !reagendar}
                                onChange={extracurricular => setAulaExtracurricular(extracurricular)}
                            />
                            {localStorage.getItem("entidade_id") === "33" ? "Reposição de aulas" : "Aula extracurricular"}
                        </span>
                        <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <Switch size="small" checked={falta} disabled={!editar && !reagendar} onChange={falta => setFalta(falta)} />
                            Falta
                        </span>
                        {codigoRecorrencia == undefined ? (
                            <></>
                        ) : (
                            <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <Switch
                                    size="small"
                                    checked={guardarRecorrencia == undefined ? setGuardarRecorrencia(false) : guardarRecorrencia}
                                    disabled={!editar || reagendar}
                                    onChange={recorrencia => setGuardarRecorrencia(recorrencia)}
                                />
                                Salvar para recorrencia
                            </span>
                        )}
                        <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <Switch
                                size="small"
                                checked={reorganizacao}
                                disabled={!editar && !reagendar}
                                onChange={reorganizacao => setReorganizacao(reorganizacao)}
                            />
                            Reorganização
                        </span>
                    </Form.Item>
                    {falta && (
                        <Form.Item label="Motivo">
                            {getFieldDecorator("motivo", {
                                initialValue: motivo
                            })(
                                <TextArea
                                    name="motivo"
                                    disabled={!editar && !reagendar}
                                    onChange={event => setMotivo(event.target.value)}
                                    rows={4}
                                    style={{ height: 100 }}
                                />
                            )}

                            {getFieldDecorator("download", {
                                rules: [
                                    {
                                        validator: validar
                                    }
                                ],
                                initialValue: ficheiro
                            })(
                                <>
                                    <Dragger style={{ marginTop: "7px" }} {...propsFileUpload} fileList={ficheiro}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexo
                                            <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                </>
                            )}
                        </Form.Item>
                    )}
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                    {(editar || reagendar) && acesso?.update && (
                        <>
                            <button className="botao-principal" onClick={guardar} disabled={loading} type="primary">
                                {loading ? <Icon type="loading" /> : null}
                                Salvar
                            </button>
                            <button
                                className="botao-secundario"
                                onClick={() => {
                                    setEditar(false);
                                    setReagendar(false);
                                    usarBakcup();
                                }}
                                type="primary"
                            >
                                Cancelar
                            </button>
                        </>
                    )}
                    {(duplicada || foraPeriodo) && !extracurricular && !editar && !reagendar && acesso?.update && (
                        <button className="botao-principal" onClick={confirmar} disabled={loading} type="primary">
                            Confirmar
                        </button>
                    )}
                </div>
            </Form>
        </>
    );
};

const FormDetalhe = Form.create({ name: "form-detalhe" })(Detalhe);

export default withRouter(FormDetalhe);
