import React, { Component } from "react";
import { Modal, notification, List, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Matriz extends Component {
    static contextType = GlobalContext;
    state = {
        cursos: [],
        //PERMISSOES
        loading: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        if (this.context.lista_disciplinas.length === 0)
            this.listar(this.props.match.params.cursoid);
        else
            this.setState({
                cursos: this.context.lista_disciplinas
            })
    }

    //CARREGA E LISTA TODOS OS MODULOS PERTENCENTES AO CURSO E IDENTIFICA A ULTIMA POSIÇÃO NA ORDEM
    listar = cursoid => {
        this.setState({
            loading: true
        })

        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-sub-cursos-publico",
            params: {
                cursoIdEnc: cursoid
            }
        })
            .then(response => {
                this.context.atualizarState({
                    lista_disciplinas: response.data
                });

                this.setState({
                    cursos: response.data,
                    loading: false
                });
            })
            .catch(() => { });
    };

    render() {
        const { cursos } = this.state;

        return (
            <div className="container">
                {this.state.loading ? (
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                ) : (
                    <div className="curso-modulos">
                        <List
                            bordered
                            dataSource={cursos}
                            renderItem={item => (
                                <List.Item>
                                    <Link
                                        to={`/${this.props.match.params.entidade}/curso/${item.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${item.idEnc}/curso-visao-geral`}
                                        //onClick={() => localStorage.setItem("codigo_curso", item.id)}
                                    >
                                        <span>
                                            {item.nome}
                                        </span>
                                    </Link>
                                </List.Item>
                            )}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default Matriz;
