import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, notification } from 'antd';
import axios from "axios";

import UserInfo from "../../UserInfo/UserInfo";
import Tabela from "./Tabela";
import ModalGerarDocumento from "../../../../Componentes/Modals/GerarDocumento";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Estados = ({ menuOptionSelected, match }) => {
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");

    const exportar = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/pdf/dados-gerais-historico-estado"
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    }

    return (
        <>
            <div className="page-container">
                <UserInfo />
                <div className="user-info-detail">
                    <div className="page-info">
                        <div>
                            <h1 className="page-title" style={{ margin: "20px 20px 10px 20px" }}>Histórico - Estados</h1>
                        </div>
                        <span className="page-controls">
                            <Link className="botao-icon-pdf botao-maior" to="#" title="Descarregar" onClick={exportar}>
                                <Icon type="file-pdf" />
                            </Link>
                        </span>
                    </div>
                    <div className="block-table">
                        <Tabela />
                    </div>
                </div>
                <ModalGerarDocumento
                    visibleExportar={visibleExport}
                    ficheiroExportar={exportFile}
                    btnDescarregar={buttonDownload}
                    onClose={() => setVisibleExport(false)}
                />
            </div>
        </>
    );
}

export default withRouter(Estados);