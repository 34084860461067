import React, { Component } from "react";
import FormAlterarSenha from "../Acessos/_Aux/FromAlterarSenha";

class AlterarSenha extends Component {
    render() {
        return (
            <>
                <FormAlterarSenha />
            </>
        );
    }
}
export default AlterarSenha;
