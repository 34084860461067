import React from "react";
import MsgSecundaria from "../../Geral/_Aux/MsgSecundaria";
import LazyLoad from "react-lazy-load";

const Inativo = () => {
    return (
        <div className="pagina-erro">
            <div className="hero">
                {/*<LazyLoad offsetVertical={200}>*/}
                {/*    <img src={require("../../images/wave_hero_user.svg")} alt="" />*/}
                {/*</LazyLoad>*/}
                <div className="container" style={{ marginTop: 80 }}>
                    <div className="pagina-erro-header">
                        <div className="container-img">
                            <img src={require("../../images/security.svg")} alt=""/>
                        </div>
                    </div>
                    <div className="pagina-erro-content">
                        <MsgSecundaria texto="Aluno inativo" style={{ fontSize: 38, color: "white" }} />
                        <MsgSecundaria texto="Para poder voltar a utilizar o chat, entre em contacto com o seu Canal." />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inativo;
