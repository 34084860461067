import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Participante from "./Participante";
import DrawerEnviarMsg from "./_Aux/DrawerEnviarMsg";
import axios from "axios";

export class Participantes extends Component {
    state = {
        participantes: [],
        //LOADING
        loading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER ENVIAR MENSAGENS
        utilizador: "",
        visibleDrawerEnviarMsg: false
    };

    componentDidMount() {
        this.listar(localStorage.getItem("codigo_curso"));
    }

    listar = cursoid => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/curso/participantes",
                    timeout: 60 * 10 * 1000,
                    params: {
                        cursoid
                    }
                })
                    .then(response => {
                        this.setState({
                            participantes: response.data,
                            loading: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    abrirDrawerEnviarMsg = utilizadoId => {
        var utilizador = JSON.stringify(this.state.participantes.find(x => x.id === utilizadoId));
        this.setState({
            visibleDrawerEnviarMsg: true,
            utilizador
        });
    }

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<div className="curso-resultados">
                <div className="controlos controlos-fundo">
                    <div className="controlos-opcoes">
                        <Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)} style={{ marginRight: 10 }}>
                            Voltar
                        </Link>
                    </div>
                </div>
            </div>);
        }
    }

    render() {
        const {
            participantes,
            //LOADING
            loading,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER ENVIAR MENSAGENS
            utilizador,
            visibleDrawerEnviarMsg
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="container container-body">
                {this.montarBotaoVoltar()}
                <div className="curso-participantes" style={loading ? { display: "block" } : {}}>
                    {loading ? (
                        <div className="loading-data">
                            <div className="loading" />
                        </div>
                    ) : (
                            participantes.map((participante, index) => <Participante {...participante} key={index} abrirDrawerEnviarMsg={this.abrirDrawerEnviarMsg} />)
                    )}
                </div>
                <DrawerEnviarMsg
                    utilizador={utilizador}
                    onClose={() => this.setState({ visibleDrawerEnviarMsg: false })}
                    visibleDrawerEnviarMsg={visibleDrawerEnviarMsg}
                />
            </div>
        );
    }
}

export default Participantes;
