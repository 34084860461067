import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Header from "../User/Header";
import MenuSuporte from "./Menu";
import Tabela from "./Tabela";
import TabelaMobile from "./TabelaMobile";
import noimage from "../images/noimage.png";
import axios from "axios";
import moment from "moment";
import { Dropdown, Menu, Drawer, Form } from "antd";
import { GlobalContext } from "../GlobalState";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        pesquisa: "",
        filtroEstado: undefined,
        tickets: [],
        ticketsMobile: [],
        total_reclamacoes_por_ler: 0,
        reclamacoes_ativo_on: "",
        total_mensagens_por_ler: 0,
        mensagens_ativo_on: "",
        //TABELA
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER ANTERIORES
        visibleDetalhe: false,
        detalheIniciadoPor: "",
        detalheUltimaAtualizacao: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        if (localStorage.getItem("voltou") === "true") {
            localStorage.removeItem("voltou");
            if (localStorage.getItem("tickets_separador") === "1") {
                this.totalTicketsMensagens();
                this.listarMensagens();
                this.setState({
                    mensagens_ativo_on: "ativo"
                });
            } else {
                this.totalTicketsReclamacoes();
                this.listarReclamacoes();
                this.setState({
                    reclamacoes_ativo_on: "ativo"
                });
            }
        } else {
            this.totalTicketsMensagens();
            this.listarMensagens();
            this.setState({
                mensagens_ativo_on: "ativo"
            });
            localStorage.setItem("tickets_separador", 1);
        }

        this.totalTicketsPorLer(false);
        this.totalTicketsPorLer(true);
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChangeSelect = pageSize => {
        const pagination = { ...this.state.pagination };
        pagination.current = 1;
        pagination.pageSize = pageSize;

        this.setState(
            {
                pagination
            },
            () => {
                if (this.state.mensagens_ativo_on === "ativo") {
                    this.totalTicketsMensagens();
                    this.listarMensagens();
                } else {
                    this.totalTicketsReclamacoes();
                    this.listarReclamacoes();
                }
            }
        );
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.setState(
            {
                pagination
            },
            () => {
                if (this.state.mensagens_ativo_on === "ativo") {
                    this.listarMensagens();
                } else {
                    this.listarReclamacoes();
                }
            }
        );
    };

    handleChangeFiltroEstados = value => {
        const pagination = { ...this.state.pagination };
        pagination.current = 1;

        this.setState(
            {
                pagination,
                filtroEstado: value
            },
            () => {
                if (this.state.mensagens_ativo_on === "ativo") {
                    this.totalTicketsMensagens();
                    this.listarMensagens();
                }
                else {
                    this.totalTicketsReclamacoes();
                    this.listarReclamacoes();
                }
            }
        );
    };

    HandleKeyPress = e => {
        const pagination = { ...this.state.pagination };
        pagination.current = 1;

        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pagination,
                    pesquisa: pesquisa,
                    iconLoading: true
                },
                () => {
                    if (this.state.mensagens_ativo_on === "ativo") {
                        this.totalTicketsMensagens();
                        this.listarMensagens();
                    } else {
                        this.totalTicketsReclamacoes();
                        this.listarReclamacoes();
                    }
                }
            );
        }
    };

    //LIMPA A CAIXA DE PESQUISA E LISTA NOVAMENTE O TOTAL DE TICKETS
    resetCaixaProcura = () => {
        const pagination = { ...this.state.pagination };
        pagination.current = 1;

        this.setState(
            {
                pagination,
                pesquisa: "",
                iconLoading: true
            },
            () => {
                if (this.state.mensagens_ativo_on === "ativo") {
                    this.totalTicketsMensagens();
                    this.listarMensagens();
                } else {
                    this.totalTicketsReclamacoes();
                    this.listarReclamacoes();
                }
            }
        );
    };

    //TOTAL DE TICKETS DO TIPO POR LER
    totalTicketsPorLer = reclamacao => {
        axios({
            method: "get",
            url: "/api/tickets/total-tickets-por-ler",
            params: {
                pesquisa: this.state.pesquisa,
                reclamacao
            }
        })
            .then(response => {
                if (reclamacao)
                    this.setState({
                        total_reclamacoes_por_ler: response.data
                    });
                else
                    this.setState({
                        total_mensagens_por_ler: response.data
                    });
            })
            .catch(() => {});
    };

    //TOTAL DE RECLAMAÇÕES
    totalTicketsReclamacoes = () => {
        axios({
            method: "get",
            url: "/api/tickets/total-tickets-reclamacoes",
            params: {
                pesquisa: this.state.pesquisa,
                filtro: this.state.filtroEstado
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(() => {});
    };

    //ALTERNA ENTRE LISTAR TODOS OS TICKETS E OS TICKETS DO TIPO FECHADOS
    filtroReclamacoes = () => {
        this.setState({ mensagens_ativo_on: "", reclamacoes_ativo_on: "ativo" });
        const pagination = { ...this.state.pagination };
        pagination.current = 1;

        this.setState(
            {
                pagination,
                iconLoading: true
            },
            () => {
                localStorage.setItem("tickets_separador", 2);
                this.totalTicketsReclamacoes();
                this.listarReclamacoes();
            }
        );
    };

    //LISTA TODOS AS RECLAMAÇÕES
    listarReclamacoes = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "get",
            url: "/api/tickets/listar-reclamacoes",
            params: {
                pageSize: this.state.pagination.pageSize,
                page: this.state.pagination.current,
                pesquisa: this.state.pesquisa,
                filtro: this.state.filtroEstado
            }
        })
            .then(response => {
                var tickets = [];
                var ticketsMobile = [];

                response.data.map((ticket, index) => {
                    tickets.push({
                        key: index,
                        id: ticket.id,
                        codigo: (
                            <div className="bloco-codigo">
                                <span className="codigo">{ticket.codigo}</span>
                                <br />
                                <span className={`estado-table ${ticket.estado.cor}-table`}>{ticket.estado.nome}</span>
                            </div>
                        ),
                        assunto: ticket.assunto,
                        de: !ticket.nome ? (
                            <div className="info-participante">
                                <div className="container-img">
                                    <img src={ticket.utilizador.foto ? ticket.utilizador.foto : noimage} />
                                </div>
                                {ticket.utilizador.numero ? (
                                    <div className="info">
                                        <span className="nome">
                                            {ticket.utilizador.nome_completo.length > 1
                                                ? `${ticket.utilizador.nome_completo.split(" ")[0]} ${
                                                      ticket.utilizador.nome_completo.split(" ")[
                                                          ticket.utilizador.nome_completo.split(" ").length - 1
                                                      ]
                                                  }`
                                                : ticket.utilizador.nome_completo}
                                        </span>
                                        <p className="numero">{ticket.utilizador.numero}</p>
                                    </div>
                                ) : (
                                    <div className="info">
                                        <span className="nome sem-numero">
                                            {ticket.utilizador.nome_completo.length > 1
                                                ? `${ticket.utilizador.nome_completo.split(" ")[0]} ${
                                                      ticket.utilizador.nome_completo.split(" ")[
                                                          ticket.utilizador.nome_completo.split(" ").length - 1
                                                      ]
                                                  }`
                                                : ticket.utilizador.nome_completo}
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="info-participante">
                                <div className="container-img">
                                    <img src={noimage} />
                                </div>
                                <div className="info">
                                    <span className="nome sem-numero">{ticket.nome}</span>
                                </div>
                            </div>
                        ),
                        ultimaAtualizacao: moment(ticket.atualizacao).format("DD/MM/YYYY - HH:mm")
                    });

                    const iniciadoPor = !ticket.nome ? (
                        <div className="info-participante">
                            <div className="container-img">
                                <img src={ticket.utilizador.foto ? ticket.utilizador.foto : noimage} />
                            </div>
                            {ticket.utilizador.numero ? (
                                <div className="info">
                                    <span className="nome">
                                        {ticket.utilizador.nome_completo.length > 1
                                            ? `${ticket.utilizador.nome_completo.split(" ")[0]} ${
                                                  ticket.utilizador.nome_completo.split(" ")[
                                                      ticket.utilizador.nome_completo.split(" ").length - 1
                                                  ]
                                              }`
                                            : ticket.utilizador.nome_completo}
                                    </span>
                                    <p className="numero">{ticket.utilizador.numero}</p>
                                </div>
                            ) : (
                                <div className="info">
                                    <span className="nome sem-numero">
                                        {ticket.utilizador.nome_completo.length > 1
                                            ? `${ticket.utilizador.nome_completo.split(" ")[0]} ${
                                                  ticket.utilizador.nome_completo.split(" ")[
                                                      ticket.utilizador.nome_completo.split(" ").length - 1
                                                  ]
                                              }`
                                            : ticket.utilizador.nome_completo}
                                    </span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="info-participante">
                            <div className="container-img">
                                <img src={noimage} />
                            </div>
                            <div className="info">
                                <span className="nome sem-numero">{ticket.nome}</span>
                            </div>
                        </div>
                    );

                    const ultimaAtualizacao = `${moment(ticket.atualizacao).format("DD/MM/YYYY")} ${moment(ticket.atualizacao).format(
                        "HH:mm"
                    )}`;

                    ticketsMobile.push({
                        key: index,
                        id: ticket.id,
                        codigo: (
                            <div className="bloco-codigo">
                                <div>
                                    <span className="assunto">{ticket.assunto}</span>
                                </div>
                                <div>
                                    <span className="codigo">{ticket.codigo}</span>
                                    <span className={`estado-table ${ticket.estado.cor}-table`}>{ticket.estado.nome}</span>
                                </div>
                            </div>
                        ),
                        assunto: ticket.assunto,
                        controlos: (
                            <div className="controlos">
                                <Dropdown
                                    overlay={this.montarMenuMobile(iniciadoPor, ultimaAtualizacao)}
                                    placement="bottomLeft"
                                    // onClick={event => event.stopPropagation()}
                                    trigger={["click"]}
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            </div>
                        )
                    });
                });

                this.setState({
                    iconLoading: false,
                    tickets,
                    ticketsMobile
                });
            })
            .catch(() => {});
    };

    //TOTAL DE RECLAMAÇÕES
    totalTicketsMensagens = () => {
        axios({
            method: "get",
            url: "/api/tickets/total-tickets-mensagens",
            params: {
                pesquisa: this.state.pesquisa,
                filtro: this.state.filtroEstado
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(() => {});
    };

    //ALTERNA ENTRE LISTAR TODOS OS TICKETS E OS TICKETS DO TIPO FECHADOS
    filtroMensagens = () => {
        this.setState({ mensagens_ativo_on: "ativo", reclamacoes_ativo_on: "" });
        const pagination = { ...this.state.pagination };
        pagination.current = 1;

        this.setState(
            {
                pagination,
                iconLoading: true
            },
            () => {
                localStorage.setItem("tickets_separador", 1);
                this.totalTicketsMensagens();
                this.listarMensagens();
            }
        );
    };

    //LISTA TODOS AS RECLAMAÇÕES
    listarMensagens = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "get",
            url: "/api/tickets/listar-mensagens",
            params: {
                pageSize: this.state.pagination.pageSize,
                page: this.state.pagination.current,
                pesquisa: this.state.pesquisa,
                filtro: this.state.filtroEstado
            }
        })
            .then(response => {
                var tickets = [];
                var ticketsMobile = [];

                response.data.map((ticket, index) => {
                    tickets.push({
                        key: index,
                        id: ticket.id,
                        codigo: (
                            <div className="bloco-codigo">
                                <span className="codigo">{ticket.codigo}</span>
                                <br />
                                <span className={`estado-table ${ticket.estado.cor}-table`}>{ticket.estado.nome}</span>
                            </div>
                        ),
                        assunto: ticket.assunto,
                        de: !ticket.nome ? (
                            <div className="info-participante">
                                <div className="container-img">
                                    <img src={ticket.utilizador.foto ? ticket.utilizador.foto : noimage} />
                                </div>
                                {ticket.utilizador.numero ? (
                                    <div className="info">
                                        <span className="nome">
                                            {ticket.utilizador.nome_completo.length > 1
                                                ? `${ticket.utilizador.nome_completo.split(" ")[0]} ${
                                                      ticket.utilizador.nome_completo.split(" ")[
                                                          ticket.utilizador.nome_completo.split(" ").length - 1
                                                      ]
                                                  }`
                                                : ticket.utilizador.nome_completo}
                                        </span>
                                        <p className="numero">{ticket.utilizador.numero}</p>
                                    </div>
                                ) : (
                                    <div className="info">
                                        <span className="nome sem-numero">
                                            {ticket.utilizador.nome_completo.length > 1
                                                ? `${ticket.utilizador.nome_completo.split(" ")[0]} ${
                                                      ticket.utilizador.nome_completo.split(" ")[
                                                          ticket.utilizador.nome_completo.split(" ").length - 1
                                                      ]
                                                  }`
                                                : ticket.utilizador.nome_completo}
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="info-participante">
                                <div className="container-img">
                                    <img src={noimage} />
                                </div>
                                <div className="info">
                                    <span className="nome sem-numero">{ticket.nome}</span>
                                </div>
                            </div>
                        ),
                        ultimaAtualizacao: (
                            <div className="bloco-data-hora">
                                <span className="data">{moment(ticket.atualizacao).format("DD/MM/YYYY")}</span>
                                <span className="hora">{moment(ticket.atualizacao).format("HH:mm")}</span>
                            </div>
                        )
                    });

                    const iniciadoPor = !ticket.nome ? (
                        <div className="info-participante">
                            <div className="container-img">
                                <img src={ticket.utilizador.foto ? ticket.utilizador.foto : noimage} />
                            </div>
                            {ticket.utilizador.numero ? (
                                <div className="info">
                                    <span className="nome">
                                        {ticket.utilizador.nome_completo.length > 1
                                            ? `${ticket.utilizador.nome_completo.split(" ")[0]} ${
                                                  ticket.utilizador.nome_completo.split(" ")[
                                                      ticket.utilizador.nome_completo.split(" ").length - 1
                                                  ]
                                              }`
                                            : ticket.utilizador.nome_completo}
                                    </span>
                                    <p className="numero">{ticket.utilizador.numero}</p>
                                </div>
                            ) : (
                                <div className="info">
                                    <span className="nome sem-numero">
                                        {ticket.utilizador.nome_completo.length > 1
                                            ? `${ticket.utilizador.nome_completo.split(" ")[0]} ${
                                                  ticket.utilizador.nome_completo.split(" ")[
                                                      ticket.utilizador.nome_completo.split(" ").length - 1
                                                  ]
                                              }`
                                            : ticket.utilizador.nome_completo}
                                    </span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="info-participante">
                            <div className="container-img">
                                <img src={noimage} />
                            </div>
                            <div className="info">
                                <span className="nome sem-numero">{ticket.nome}</span>
                            </div>
                        </div>
                    );

                    const ultimaAtualizacao = `${moment(ticket.atualizacao).format("DD/MM/YYYY")} ${moment(ticket.atualizacao).format(
                        "HH:mm"
                    )}`;

                    ticketsMobile.push({
                        key: index,
                        id: ticket.id,
                        codigo: (
                            <div className="bloco-codigo">
                                <div>
                                    <span className="assunto">{ticket.assunto}</span>
                                </div>
                                <div>
                                    <span className="codigo">{ticket.codigo}</span>
                                    <span className={`estado-table ${ticket.estado.cor}-table`}>{ticket.estado.nome}</span>
                                </div>
                            </div>
                        ),
                        assunto: ticket.assunto,
                        controlos: (
                            <div className="controlos">
                                <Dropdown
                                    overlay={this.montarMenuMobile(iniciadoPor, ultimaAtualizacao)}
                                    placement="bottomLeft"
                                    //onClick={event => event.stopPropagation()}
                                    trigger={["click"]}
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            </div>
                        )
                    });
                });

                this.setState({
                    iconLoading: false,
                    tickets,
                    ticketsMobile
                });
            })
            .catch(error => {});
    };

    montarMenuMobile = (iniciadoPor, ultimaAtualizacao) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link
                        to="#"
                        onClick={() =>
                            this.setState({
                                visibleDetalhe: true,
                                detalheIniciadoPor: iniciadoPor,
                                detalheUltimaAtualizacao: ultimaAtualizacao
                            })
                        }
                    >
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    render() {
        const {
            filtroEstado,
            tickets,
            ticketsMobile,
            pagination,
            iconLoading,
            total_mensagens_por_ler,
            total_reclamacoes_por_ler,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER ANTERIORES
            visibleDetalhe,
            detalheIniciadoPor,
            detalheUltimaAtualizacao
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Suporte" />
                <div className="container container-body">
                    <div className="tickets">
                        <MenuSuporte
                            filtroEstado={filtroEstado}
                            handleChangeFiltroEstados={this.handleChangeFiltroEstados}
                            filtroMensagens={this.filtroMensagens}
                            filtroReclamacoes={this.filtroReclamacoes}
                            mensagens_ativo={this.state.mensagens_ativo_on}
                            reclamacoes_ativo={this.state.reclamacoes_ativo_on}
                            total_mensagens_por_ler={total_mensagens_por_ler}
                            total_reclamacoes_por_ler={total_reclamacoes_por_ler}
                            HandleKeyPress={this.HandleKeyPress}
                            resetCaixaProcura={this.resetCaixaProcura}
                        />
                        <div className="bloco-formadores lista-tickets-desktop">
                            <Tabela
                                tickets={tickets}
                                ticketsMobile={ticketsMobile}
                                pagination={pagination}
                                loading={iconLoading}
                                handleTableChange={this.handleTableChange}
                                handleChangeSelect={this.handleChangeSelect}
                            />
                        </div>
                        <div className="bloco-formadores lista-tickets-mobile">
                            <TabelaMobile
                                tickets={ticketsMobile}
                                pagination={pagination}
                                loading={iconLoading}
                                handleTableChange={this.handleTableChange}
                                handleChangeSelect={this.handleChangeSelect}
                            />
                        </div>
                    </div>
                </div>
                <Drawer
                    className="drawer-detalhe-ticket"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Iniciado por">{detalheIniciadoPor}</Form.Item>
                                <Form.Item label="Ultima Atualização">{detalheUltimaAtualizacao}</Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

export default Main;
