import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import PdfReader from "./PdfReader";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import Oops from "../../Geral/Oops.jsx";

class Pdf extends Component {
    static contextType = GlobalContext;
    state = {
        entidade: "",
        curso: "",
        cursoId: "",
        aulaid: 0,
        pdf: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        visivel: true,
        sameUrl: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;

        this.setState({
            entidade: this.props.match.params.entidade,
            curso: this.props.match.params.curso,
            cursoId: this.props.match.params.cursoid,
            aulaid: localStorage.getItem("codigo_aula")
        });
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState(
                {
                    visivel: false,
                    sameUrl: false
                },
                () =>
                    this.setState({
                        visivel: true,
                        sameUrl: true
                    })
            );
        }
    }

    render() {
        const {
            entidade,
            curso,
            cursoId,
            sameUrl,
            //REDIRECT
            redirect,
            redirectLink,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="pagina-html" style={{ zIndex: 1, position: "relative" }}>
                <div className="player-wrapper" style={{ position: "fixed", width: "100%" }}>
                    <div className="bloco-html bloco-pdf" style={{ boxShadow: "none", background: "transparent" }}>
                        <div className="bloco-info" style={{ padding: "0" }}>
                            {this.state.visivel && <PdfReader marcarComoNaoDisponivel={this.marcarComoNaoDisponivel} />}
                        </div>
                    </div>
                    <Controlos
                        backUrl={`/${entidade}/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${cursoId}/curso-modulos`}
                        sameUrl={sameUrl}
                    />
                </div>
            </div>
        );
    }
}

export default Pdf;
