import Tabela from "./Tabela";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import { DataContext } from '../Contexts/DataProvider';
export default function TurmasProbabilidadeEvasao (){
    const {listaFaltasTurma} = useContext(DataContext);
    const columnsTurmasPropensasEvasao = [
        {
            title: 'Turma',
            dataIndex: 'turma',
            key: 'turma',
            width: "25%",
        },
        {
            title: 'Quant. de Faltas',
            dataIndex: 'faltas',
            key: 'faltas',
            width: "25%",
        },
        {
            title: 'Escola',
            dataIndex: 'escola',
            key: 'escola',
            width: "25%",
        },
        {
            title: 'Ediçao',
            dataIndex: 'edicao',
            key: 'edicao',
            width: "25%",
        },
    ];
    
    return(

        listaFaltasTurma &&  <Tabela data={listaFaltasTurma} columns={columnsTurmasPropensasEvasao} titulo="Turmas com maior probabilidade de evasão"></Tabela>
       
        
    );
    
}