import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import { GlobalContext } from "../GlobalState";
import axios from "axios";
import moment from "moment";
import LazyLoad from "react-lazy-load";
import Oops from "../Geral/Oops.jsx";
import screenfull from "screenfull";
import { Icon } from "antd";

class Pagina extends Component {
    static contextType = GlobalContext;
    constructor(props) {
        super(props);
        this.state = {
            curso: "",
            modulo: "",
            moduloIndex: 0,
            aulaid: 0,
            aula: "",
            nome: "",
            objetivos: "",
            aulaIndex: 0,
            html: "",
            fullscreen: false,
            updateModulos: false,
            dataHora: "",
            //REDIRECT
            redirect: false,
            redirectLink: "",
            sameUrl: true,
            //NAO DISPONIVEL
            nao_disponivel: false,
            //LOADING
            loading: true
        };

        this.elementFullScreen = React.createRef();
    }

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    //CARREGA CONTEUDO DA AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/gestao-player/conteudo1",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    html: response.data.recurso,
                    dataHora: moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"),
                    loading: false
                });
            })
            .catch(error => {
                //if (error.response.data === "SEM_PERMISSAO")
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/user"
                //    });
                //else
                    if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    nao_disponivel: true
                });
            });
    };

    onClickFullscreenElement = () => {
        screenfull.toggle(this.elementFullScreen.current);
        this.setState({ fullscreen: !this.state.fullscreen });
    };

    render() {
        const {
            curso,
            nome,
            objetivos,
            html,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
            <div>
                <div className="pagina-html">
                    <div className="player-wrapper">
                        {/*<LazyLoad offsetVertical={200}>*/}
                        {/*    <img src={require("../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                        {/*</LazyLoad>*/}
                        {!nao_disponivel ? (
                            <>
                                <div className="container" ref={this.elementFullScreen}>
                                    <div className="bloco-html">
                                        <Icon
                                            type={this.state.fullscreen ? "fullscreen-exit" : "fullscreen"}
                                            onClick={this.onClickFullscreenElement}
                                            className="btn-fullscreen"
                                        />
                                        <div className="bloco-info" dangerouslySetInnerHTML={{ __html: this.state.html }}></div>
                                    </div>
                                </div>
                                <Controlos
                                        backUrl={localStorage.getItem("player_back_url") ? localStorage.getItem("player_back_url") : `/gerir-curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                    sameUrl={sameUrl}
                                        temAnotacoes
                                        nome={nome}
                                        objetivos={objetivos}
                                        icon={require("../images/icons/html.png")}
                                        dataHora={dataHora}
                                />
                            </>
                        ) : (
                            <>
                                <div className="container">
                                    <div className="bloco-nao-disponivel">
                                        <div className="bloco-info nao-disponivel">
                                            <Oops tem_texto texto={"Conteúdo não disponível"} />
                                        </div>
                                    </div>
                                </div>
                                <ControlosSimples />
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Pagina;
