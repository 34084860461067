import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Input, Icon, Drawer, Tooltip, Upload, Select, Checkbox, notification } from "antd";
import ModalViewer from "../../../../Componentes/Modals/ModalViewer";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerPagamento = ({ extensions, dividaId, visible, atualizar, onClose, form }) => {
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ficheiros, setFicheiros] = useState([]);
    const [docs, setDocs] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const { getFieldDecorator } = form;
    const Dragger = Upload.Dragger;

    const detalhe = () => {
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-divida-utilizador",
            params: {
                dividaId
            }
        })
            .then(response => {
                setFicheiros(response.data)
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    }

    const alterar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                var item = new FormData();
                item.append("id", dividaId);
                if (ficheiros.length)
                    item.append("listaFicheiros", JSON.stringify(ficheiros));
                fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "put",
                    url: "/api/user-detalhe/alterar-divida-utilizador",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ação efetuada com sucesso!.");
                        setLoading(false)
                        atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setLoading(false)
                    });
            }
        })
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (visible) {
            if (dividaId > 0)
                detalhe();
        }
        else {
            setFicheiros([]);
            setFileList([]);
            setLoading(false);
        }
    }

    const validarFormatoFicheiro = formato => {
        var formatos = ["docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const validar = (rule, value, callback) => {
        if (value.file)
            if (!validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed")
                callback("Campo obrigatório");

        callback();
    };

    const props = {
        multiple: true,
        onRemove: (file) => {
            form.resetFields("ficheiro");
            setFileList((fileList) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        defaultFileList: fileList,
        beforeUpload: (file) => {
            setFileList((fileList) => [...fileList, file]);

            return false;
        },
        fileList,
    };

    const validarValor = (rule, value, callback) => {
        if (isNaN(value))
            callback("O valor deve ser um número")

        callback();
    }

    const download = (nome, url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            nome,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    return (
        <Drawer
            className="drawer-add-cursos drawer-videoconferencias"
            //title=""
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias" layout="horizontal">
                    <div className="bloco">
                        <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>Documentos</h3>
                        {ficheiros.map(ficheiro => (
                            <Form.Item>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <span>{ficheiro.nome}</span>
                                    <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10 }}>
                                        {extensions.find(x => x === ficheiro.extensao) &&
                                            <Link to="#" title="Visualizar" className="botao-icon-detalhe" onClick={() => { setModalVisible(true); setDocs([{ uri: ficheiro.url }]) }}>
                                                <Icon type="eye" />
                                            </Link>}
                                        <Link to="#" title="Descarregar" className="botao-icon-download" onClick={() => download(ficheiro.nome, ficheiro.url)}>
                                            <Icon type="download" />
                                        </Link>
                                        <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={() => setFicheiros([...ficheiros.filter(x => x.id !== ficheiro.id)])}>
                                            <Icon type="delete" />
                                        </Link>
                                    </span>
                                </div>
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <div className="anexos">
                                {getFieldDecorator("ficheiro", {
                                    rules: [
                                        {
                                            required: !fileList.length && !ficheiros.length,
                                            message: "Campo obrigatório"
                                        },
                                        {
                                            validator: validar
                                        }
                                    ],
                                    initialValue: fileList
                                })(
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexo{" "}
                                            <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                )}
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <Link to="#" className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </Link>
                <button className="botao-principal" disabled={loading} onClick={alterar}>
                    {loading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
            <ModalViewer
                visible={modalVisible}
                docs={docs}
                onCancel={() => setModalVisible(false)}
            />
        </Drawer>
    );
}

const FormDrawerPagamento = Form.create({ name: "form-drawer-pagamento" })(DrawerPagamento);

export default withRouter(FormDrawerPagamento);