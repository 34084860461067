import React from "react";
import { Input, Icon } from "antd";

const UserSearchBar = ({ search, setSearch, resetSearch, setActivated }) => {
    const onKeyPress = e => {
        if (e.key === "Enter") {
            setActivated(true);
        }
    };

    const emitEmpty = () => {
        setSearch("");
        resetSearch();
    };

    const onChange = e => {
        setSearch(e.target.value);

        if (!e.target.value) resetSearch();
    };

    const suffix = search ? <Icon type="close-circle" onClick={emitEmpty} /> : null;

    return (
        <div className="procurar">
            <Input
                id="procurar"
                prefix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Procurar"
                suffix={suffix}
                value={search}
                onChange={onChange}
                onKeyPress={onKeyPress}
            />
        </div>
    );
};

export default UserSearchBar;
