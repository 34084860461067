import React, { Component } from "react";
import { Tooltip } from "antd";
import moment from "moment";

class CardsFrequencia extends Component {
    state = {
        totalFaltas: 0
    }

    montarColunasDias = (periodo) => {
        let dias = [];
        for (let i = 0; i < 31; i++) {
            const aulas = periodo.aulas.filter(x => moment(x.dt_inicio).isSame(`${periodo.ano}-${(('0' + (moment().month(periodo.mes).format("M"))).slice(-2))}-${(('0' + (i + 1)).slice(-2))}`));
            if (aulas.length > 0) {
                let presencas = 0;
                let faltas = 0;
                let faltasJustificadas = 0;

                aulas.map(aula => {
                    const presencasAlunos = aula.presencas ? JSON.parse(aula.presencas) : [];
                    const participante = presencasAlunos.find(x => x.id === this.props.utilizadorId);

                    if ((aula.presenca || !aula.presenca && presencasAlunos.length && presencasAlunos.find(x => x.id === this.props.utilizadorId && x.estado === "P")) && participante != undefined && !participante?.bloqueado) {
                        presencas++;
                    }
                    if ((aula.falta || !aula.falta && presencasAlunos.length && presencasAlunos.find(x => x.id === this.props.utilizadorId && x.estado === "F")) && participante != undefined && !participante?.bloqueado) {
                        faltas++;
                    }
                    if (!aula.presenca && !aula.falta && presencasAlunos.length && presencasAlunos.find(x => x.id === this.props.utilizadorId && x.estado === "FJ")) {
                        faltasJustificadas++;
                    }
                });
                dias.push(<span className="dia">
                    <span className="titulo">{(('0' + (i + 1)).slice(-2))}</span>
                    {presencas > 0 ?
                        <span className="texto presenca">{presencas > 1 ? `P (${presencas})` : "P"}</span>
                        : null}
                    {faltas > 0 ?
                        <span className="texto falta">{faltas > 1 ? `F (${faltas})` : "F"}</span>
                        : null}
                    {faltasJustificadas > 0 ?
                        <span className="texto falta-justificada">{faltasJustificadas > 1 ? `FJ (${faltasJustificadas})` : "FJ"}</span>
                        : null}
                </span>)
            }
            else
                dias.push(<span className="dia">
                    <span className="titulo">{(('0' + (i + 1)).slice(-2))}</span>
                    <span className="texto"></span>
                </span>)
        }

        return dias;
    }

    calcularTotalPresencas = (periodo, index) => {
        let acumulado = 0;

        for (let i = index; i > 0; i--) {
            acumulado += this.props.periodos[i].presencas;
        }
        if (acumulado > 0)
            return `${periodo.presencas}/${acumulado}`;
        else
            return `${periodo.presencas}/${periodo.presencas}`;
    }

    calcularTotalFaltas = (periodo, index) => {
        let acumulado = 0;

        for (let i = index; i > 0; i--) {
            acumulado += this.props.periodos[i].faltas;
        }
        if (acumulado > 0)
            return `${periodo.faltas}/${acumulado}`;
        else
            return `${periodo.faltas}/${periodo.faltas}`;
    }

    render() {
        return (
            <div className="bloco-cards">
                {this.props.periodos.map((periodo, index) => (
                    <div key={index} className="card">
                        <div className="header">
                            <span className="titulo">{periodo.titulo} - {periodo.mes}</span>
                        </div>
                        <div className="body">
                            <div className="estrutura-calendario">
                                {this.montarColunasDias(periodo).map(dia => dia)}
                            </div>
                        </div>
                        <div className="footer">
                            <span className="total-presencas">PRESENÇAS (Mês / Bimestre): <span>{this.calcularTotalPresencas(periodo, index)}</span></span>
                            <span className="total-faltas">FALTAS (Mês / Bimestre): <span>{this.calcularTotalFaltas(periodo, index)}</span></span>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default CardsFrequencia;
