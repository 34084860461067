import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../GlobalState";

class Controlos extends Component {
    static contextType = GlobalContext;

    render() {
        return (
            <div className="bloco-controlos bloco-controlos-player">
                <div className="controlos">
                    <div className="container-btn">
                        <Link to={this.props.backUrl} title="Voltar">
                            <i className="fas fa-angle-double-left" />
                        </Link>
                    </div>
                    <div className="container-btn">
                        <Link
                            to="#"
                            onClick={this.context.onClickFullscreen}
                            title={this.context.fullscreen ? "Sair de ecrã inteiro" : "Ecrã inteiro"}
                        >
                            {this.context.fullscreen ? <i className="fas fa-compress" /> : <i className="fas fa-expand" />}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Controlos;
