import React, { Component, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, notification, Icon, Form, Input, Checkbox, Modal, Select, DatePicker, Radio } from "antd";
import axios from "axios";
import { GlobalContext } from "../../../GlobalState";
import moment from "moment";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerCalendarioAtividade extends Component {
    static contextType = GlobalContext;
    state = {
        periodo: undefined,
        nome: "",
        inicio: "",
        termino: "",
        dataUnica: false,
        suspensao: false,
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    componentDidUpdate() {
        //if (!this.context.is_authenticated || this.context.role === "UTILIZADOR") {
        //    if (!this.context.is_authenticated)
        //        this.setState({
        //            redirect: true,
        //            redirectLink: "/login"
        //        });
        //    else if (this.context.role === "UTILIZADOR")
        //        this.setState({
        //            redirect: true,
        //            redirectLink: "/user"
        //        });
        //}

        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChangePeriodo = value => {
        this.setState({
            periodo: value,
            nome: value
        });
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeInicio = (date) => {
        this.setState({ inicio: date });
    };

    handleChangeTermino = (date) => {
        this.setState({ termino: date });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregarInfo = atividadeId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-calendario-atividades/detalhe",
            params: {
                atividadeId
            }
        })
            .then(response => {
                const info = response.data;
                this.setState({
                    periodo: info.periodo ? info.periodo : undefined,
                    nome: info.nome,
                    inicio: moment(info.inicio, "YYYY-MM-DD"),
                    termino: info.termino ? moment(info.termino, "YYYY-MM-DD") : "",
                    dataUnica: info.dataUnica,
                    suspensao: info.suspensao,
                });
        })
        .catch(() => { });
    };

    //CRIA UMA NOVA CATEGORIA
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_calendario", this.props.calendarioId);
                item.append("nome", this.state.nome);
                item.append("dt_inicio", moment(this.state.inicio).format("YYYY/MM/DD"));
                item.append("dataUnica", this.state.dataUnica);
                if (!this.state.dataUnica)
                    item.append("dt_termino", moment(this.state.termino).format("YYYY/MM/DD"));
                item.append("suspensao", this.state.suspensao);
                if (this.state.periodo)
                    item.append("periodo", this.state.periodo);

                axios({
                    method: "post",
                    url: "/api/gestao-calendario-atividades/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionado nova atividade!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA A COMPETENCIA
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.atividadeId);
                item.append("id_calendario", this.props.calendarioId);
                item.append("nome", this.state.nome);
                item.append("dt_inicio", moment(this.state.inicio).format("YYYY/MM/DD"));
                item.append("dataUnica", this.state.dataUnica);
                if (!this.state.dataUnica)
                    item.append("dt_termino", moment(this.state.termino).format("YYYY/MM/DD"));
                item.append("suspensao", this.state.suspensao);

                axios({
                    method: "put",
                    url: "/api/gestao-calendario-atividades/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Atividade alterada!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    disabledDate = (current) => {
        return current < moment(this.props.inicioCalendario, "DD-MM-YYYY").startOf("day") || current > moment(this.props.terminoCalendario, "DD-MM-YYYY").endOf("day");
    }

    afterVisibleChange = aberto => {
        if (aberto) {

            if (this.props.atividadeId) {
                this.carregarInfo(this.props.atividadeId);
            }
            else {
                this.props.form.resetFields();
                this.setState({
                    periodo: undefined,
                    nome: "",
                    inicio: "",
                    termino: "",
                    dataUnica: false,
                    suspensao: false,
                    //LOADING
                    iconLoading: false,
                });
            }
        }
    };

    getPopupContainer = triggerNode => {
        return triggerNode.parentNode;
    };

    render() {
        const {
            periodo,
            nome,
            inicio,
            termino,
            dataUnica,
            suspensao,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        const {
            periodos,
            tipo,
            nrModulos,
            atividadeId,
            visible,
            onClose
        } = this.props;

        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        if (redirect) return <Redirect to={redirectLink} />;

        let lista = [];

        const listaTipos = tipo;
        if (listaTipos.find(x => x === "Módulos")) {
            for (var i = 0; i < nrModulos; i++) {
                lista = [...lista, `${i + 1}º Módulo`];
            }
        }

        if (listaTipos.find(x => x === "Trimestres"))
            lista = [...lista, "1º Trimestre", "2º Trimestre", "3º Trimestre", "4º Trimestre"];

        if (listaTipos.find(x => x === "Bimestres"))
            lista = [...lista, "1º Bimestre", "2º Bimestre", "3º Bimestre", "4º Bimestre"];

        if (listaTipos.find(x => x === "Semestres"))
            lista = [...lista, "1º Semestre", "2º Semestre"];

        return (
            <Drawer
                className="drawer-add-cursos"
                //title={atividadeId ? "Alterar Atividades" : "Adicionar Atividades"}
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-competencias" layout="horizontal">
                        <div className="bloco">
                            {(tipo !== "Anual" && tipo.length > 0) && (
                                <Form.Item label="Periodos Letivos">
                                    <Select
                                        className="filtro-categorias"
                                        onChange={this.handleChangePeriodo}
                                        placeholder="Selecionar"
                                        allowClear={true}
                                        value={periodo}
                                    >
                                        {lista.map((item, index) => (
                                            <Option value={item} key={index} disabled={periodos.find(x => x === item)}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                            {tipo.length > 0 && (
                                <Form.Item>
                                    <Checkbox checked={suspensao} name="suspensao" disabled={periodo} onChange={this.handleChangeCheckbox}>
                                        Com suspensão de atividades
                                    </Checkbox>
                                </Form.Item>
                            )}
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" disabled={periodo} maxLength={75} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Inicio">
                                {getFieldDecorator("inicio", {
                                    initialValue: inicio,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(
                                    <DatePicker
                                        name="inicio"
                                        className="dt-inicio"
                                        format="DD-MM-YYYY"
                                        onChange={this.handleChangeInicio}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                        disabledDate={this.disabledDate}
                                        getCalendarContainer={() => document.querySelector('.ant-drawer-footer')}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Checkbox checked={dataUnica} name="dataUnica" disabled={periodo} onChange={this.handleChangeCheckbox}>
                                    Evento dia unico
                                </Checkbox>
                            </Form.Item>
                            <Form.Item label="Término">
                                {getFieldDecorator("termino", {
                                    initialValue: termino,
                                    rules: [
                                        {
                                            required: !dataUnica,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(
                                    <DatePicker
                                        name="termino"
                                        className="dt-termino"
                                        format="DD-MM-YYYY"
                                        onChange={this.handleChangeTermino}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                        disabled={dataUnica}
                                        disabledDate={this.disabledDate}
                                        getCalendarContainer={() => document.querySelector('.ant-drawer-footer')}
                                        //getCalendarContainer={() => document.querySelector('.ant-row ant-form-item')}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" onClick={atividadeId ? this.alterar : this.criar} disabled={iconLoading} type="primary">
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                </div>
            </Drawer>
        );
    }
}
const FormDrawerCalendarioAtividade = Form.create({ name: "form-drawer-calenario-atividade" })(DrawerCalendarioAtividade);

export default FormDrawerCalendarioAtividade;
