import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, notification, Modal, Button, Collapse, Select } from "antd";
import axios from "axios";
import ModalAta from "../../GerirDetalheTurma/Aproveitamento/Relatorios/Drawer/Ata";
import ParecerAluno from "./_Aux/ParecerAluno";
import ParecerGeral from "./_Aux/ParecerGeral";
import ModalViewer from "../../Componentes/Modals/ModalViewer";
import ModalLoading from "../../Componentes/Modals/ModalLoading";

import ModalGerarDocumento from "../../Componentes/Modals/GerarDocumento";

const { Option } = Select;
const { Panel } = Collapse;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ListaPareceres extends Component {
    state = {
        //FILTROS
        disciplinas: [],
        disciplinaId: localStorage.getItem("vista") === "TURMA" ? undefined : localStorage.getItem("codigo_curso"),
        disciplinaNome: "",
        periodoId: 0,
        //
        num: 1,
        total: 0,
        semPeriodos: false,
        periodos: [],
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        mes: "",
        ano: "",
        titulo: "",
        aulasAlunos: [],
        aulas: [],
        participantes: [],
        //LOADING
        iconLoading: false,
        //EXPORTAR
        ataVisible: false,
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //LOADING
        visibleLoading: false,
        //VIEWER
        visibleViewer: false,
        docs: []
    };

    componentDidMount() {
        if (localStorage.getItem("vista") === "TURMA")
            this.listarDisciplinas();

        this.total();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                disciplinaId: localStorage.getItem("vista") === "TURMA" ? undefined : localStorage.getItem("codigo_curso"),
            }, () => {
                if (localStorage.getItem("vista") === "TURMA")
                    this.listarDisciplinas();

                this.total();
            })
        }
    }

    handleSelect = disciplinaId => {
        if (disciplinaId) {
            this.setState(
                {
                    disciplinaId,
                    disciplinaNome: this.state.disciplinas.find(x => x.id === disciplinaId)?.nome
                },
                () => {
                    this.listarParecerAluno();
                    this.listarPareceres();
                }
            );
        } else {
            this.setState({
                disciplinaId: undefined,
                disciplinaNome: "",
                aulasAlunos: [],
                aulas: []
            });
        }
    };

    total = () => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/total-periodos-observacoes",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        if (response.data.length > 0) {
                            this.setState({
                                    total: response.data.length,
                                    periodoId: response.data[0].id,
                                    periodos: response.data,
                                    titulo: response.data[0].titulo,
                                    dt_inicio: response.data[0].dt_inicio,
                                    dt_fim: response.data[0].dt_fim
                                },
                                () => {
                                    if (!localStorage.getItem("vista")) {
                                        this.listarParecerAluno();
                                        this.listarPareceres();
                                    }
                                }
                            );
                        } else {
                            this.setState({
                                semPeriodos: true,
                                iconLoading: false
                            });
                            openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                        }
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                    });
            }
        );
    };

    listarParecerAluno = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-pareceres-alunos",
            params: {
                cursoId: this.state.disciplinaId,
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulasAlunos: response.data.aulas,
                    participantes: response.data.participantes,
                    iconLoading: false
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os pareceres!");
            });
    };

    listarPareceres = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-pareceres",
            params: {
                cursoId: this.state.disciplinaId,
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulas: response.data,
                    iconLoading: false
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os pareceres!");
            });
    };

    descarregarParecerDescritivoEmMassa = dataEmissao => {
        this.setState({
            btnDescarregar: true,
            visibleExportar: true
        });

        axios({
            method: "get",
            url: "/api/pdf/parecer-descritivo-em-massa",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                periodoId: this.state.periodoId,
                dataEmissao: dataEmissao.format("YYYY-MM-DD")
            }
        })
            .then(response => {
                this.setState({
                    btnDescarregar: false,
                    ficheiroExportar: response.data
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível descarregar o documento!");
                this.setState({
                    visibleExportar: true
                });
            });
    };

    listarDisciplinas = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-turma-disciplinas",
            params: {
                turmaId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                const disciplinaNomeFormatado = this.state.disciplinaNome.toLowerCase().trim();
                const disciplinaId = response.data.find(disc => disc.nome.toLowerCase().trim() === disciplinaNomeFormatado)?.id;

                this.setState({
                    disciplinaId,
                    disciplinas: response.data,
                    iconLoading: false
                }, () => {
                    if (this.state.disciplinaId !== undefined) {
                        this.listarParecerAluno();
                        this.listarPareceres();
                    }
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as disciplinas!");
            });
    };

    anteriorProximo = num => {
        var periodo = this.state.periodos[num - 1];
        this.setState(
            {
                num,
                periodoId: periodo.id,
                titulo: periodo.titulo,
                dt_inicio: periodo.dt_inicio,
                dt_fim: periodo.dt_fim,
                iconLoading: true
            },
            () => {
                this.listarParecerAluno();
                this.listarPareceres();
            }
        );
    };

    exportarPdf = () => {
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/pareceres-pdf",
                    params: {
                        cursoId: this.state.disciplinaId,
                        periodoId: this.state.periodoId
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data }]
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    };

    exportarGeraisPdf = () => {
        this.setState(
            {
                visibleLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/pareceres-gerais-pdf",
                    params: {
                        cursoId: this.state.disciplinaId,
                        periodoId: this.state.periodoId
                    }
                })
                    .then(response => {
                        this.setState({
                            visibleLoading: false,
                            visibleViewer: true,
                            docs: [{ uri: response.data }]
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleLoading: false
                        });
                    });
            }
        );
    };

    render() {
        const {
            //FILTROS
            disciplinas,
            disciplinaId,
            num,
            semPeriodos,
            periodos,
            //PERIODO
            titulo,
            aulasAlunos,
            aulas,
            participantes,
            //LOADING
            iconLoading,
            //EXPORTAR
            ataVisible,
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //LOADING
            visibleLoading,
            //VIEWER
            visibleViewer,
            docs
        } = this.state;

        return (
            <>
                <div className="container container-body">
                    <div className="bloco-curso-diario-opcoes" style={{ gap: 10 }}>
                        <h3 className="titulo-bloco">Pareceres</h3>
                        <div className="bloco-opcoes-esquerda">
                            <Link
                                to={`/gerir-multisseriado/${this.props.match.params.turma}/${
                                    localStorage.getItem("vista") === "TURMA" ? "horario" : "horario-disciplina"
                                }`}
                                className="botao-secundario botao-secundario-redondo"
                                title="Voltar"
                                style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <Icon type="arrow-left" />
                            </Link>
                        </div>
                    </div>
                    <div className="curso-diario">
                        <div className="bloco-controlos-frequencia">
                            <div className="filters">
                                <div>
                                    {localStorage.getItem("vista") === "TURMA" && (
                                        <Select
                                            value={disciplinaId}
                                            className="filter"
                                            placeholder="Selecionar Disciplina"
                                            name="disciplinas"
                                            allowClear={true}
                                            onChange={this.handleSelect}
                                        >
                                            {disciplinas.map(disciplina => (
                                                <Option key={disciplina.id} value={disciplina.id}>
                                                    {disciplina.nome}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                                {!semPeriodos && (
                                    <div>
                                        <Button
                                            className="botao-periodo botao-periodo-anterior"
                                            disabled={num === 1 || (localStorage.getItem("vista") === "TURMA" && !disciplinaId)}
                                            style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                            onClick={() => this.anteriorProximo(num - 1)}
                                        >
                                            <Icon type="left" />
                                        </Button>
                                        <span>{titulo}</span>
                                        <Button
                                            className="botao-periodo botao-periodo-proximo"
                                            disabled={
                                                num === periodos.length || (localStorage.getItem("vista") === "TURMA" && !disciplinaId)
                                            }
                                            style={{ color: "rgba(0, 0, 0, 0.8)" }}
                                            onClick={() => this.anteriorProximo(num + 1)}
                                        >
                                            <Icon type="right" />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse defaultActiveKey={[]} className="collapse-diario-observacoes">
                            <Panel
                                header={
                                    <span
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}
                                    >
                                        <label>Pareceres por Aluno</label>
                                        <span style={{ display: "flex", gap: 10 }}>
                                            <Link
                                                className={`botao-icon-detalhe ${
                                                    localStorage.getItem("vista") === "TURMA" && !disciplinaId ? "botao-disabled" : ""
                                                }`}
                                                to="#"
                                                title="Visualizar"
                                                disabled={localStorage.getItem("vista") === "TURMA" && !disciplinaId}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    this.exportarPdf();
                                                }}
                                            >
                                                <Icon type="eye" />
                                            </Link>
                                        </span>
                                    </span>
                                }
                                key="1"
                            >
                                {!aulasAlunos.length ? "Sem dados..." : null}
                                {aulasAlunos.map((aula, index) => (
                                    <ParecerAluno key={index} aula={aula} participantes={participantes} />
                                ))}
                            </Panel>
                            <Panel
                                header={
                                    <span
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}
                                    >
                                        <label>Pareceres Gerais</label>
                                        <span style={{ display: "flex", gap: 10 }}>
                                            <Link
                                                className={`botao-icon-detalhe ${
                                                    localStorage.getItem("vista") === "TURMA" && !disciplinaId ? "botao-disabled" : ""
                                                }`}
                                                to="#"
                                                title="Visualizar"
                                                disabled={localStorage.getItem("vista") === "TURMA" && !disciplinaId}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    this.exportarGeraisPdf();
                                                }}
                                            >
                                                <Icon type="eye" />
                                            </Link>
                                        </span>
                                    </span>
                                }
                                key="2"
                            >
                                {!aulas.length ? "Sem dados..." : null}
                                {aulas.map((aula, index) => (
                                    <ParecerGeral key={index} aula={aula} />
                                ))}
                            </Panel>
                        </Collapse>
                    </div>
                </div>
                <Modal visible={iconLoading} maskClosable={false} className="modal-loading" footer={null} closable={false}>
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
                <ModalGerarDocumento
                    visibleExportar={visibleExportar}
                    ficheiroExportar={ficheiroExportar}
                    btnDescarregar={btnDescarregar}
                    onClose={() => this.setState({ visibleExportar: false })}
                />
                <ModalLoading visible={visibleLoading} text="A gerar documento..." />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => this.setState({ visibleViewer: false })} />
                <ModalAta
                    visible={ataVisible}
                    onClose={() =>
                        this.setState({
                            ataVisible: false
                        })
                    }
                    onConfirm={dataEmissao => {
                        this.descarregarParecerDescritivoEmMassa(dataEmissao);
                        this.setState({
                            ataVisible: false
                        });
                    }}
                />
            </>
        );
    }
}

export default withRouter(ListaPareceres);
