import React, { Component } from "react";
import { Icon, notification } from "antd";

class CardsNotasPre extends Component {
    render() {
        const {
            periodos,
            avaliacoes
        } = this.props;

        const montarNotas = (titulo, nota) => {
            if (nota && avaliacoes.length === 1)
                nota = avaliacoes[0].nome;

            return (<div className="nota">
                <span className="titulo">{titulo}:</span>
                <span className="texto">{nota ? nota : "-"}</span>
            </div>);
        }

        return (
            <div>
                <div className="bloco-cards">
                    <div className="card">
                        <div className="header">
                            <span className="titulo">CORPO, GESTOS E MOVIMENTOS</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03CG01) - Criar com o corpo formas diversificadas de expressão de sentimentos, sensações e emoções, tanto nas situações do cotidiano quanto em brincadeiras, dança, teatro, música.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03CG02) - Demonstrar controle e adequação do uso de seu corpo em brincadeiras e jogos, escuta e reconto de histórias, atividades artísticas, entre outras possibilidades.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03CG03) - Adotar hábitos de autocuidado relacionados a higiene, alimentação, conforto e aparência.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03CG04) - Criar movimentos, gestos, olhares e mímicas em brincadeiras, jogos e atividades artísticas como dança, teatro e música.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[3].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03CG05) - Coordenar suas habilidades manuais no atendimento adequado a seus interesses e necessidades em situações diversas.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[0].avaliacoes[4].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="header">
                            <span className="titulo">ESCUTA, FALA PENSAMENTO E IMAGINAÇÃO</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EF01) - Expressar ideias, desejos e sentimentos sobre suas vivências, por meio da linguagem oral e escrita (escrita espontânea), de fotos, desenhos e outras formas de expressão.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EF02) - Inventar brincadeiras cantadas, poemas e canções, criando rimas, aliterações e ritmos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EF03) - Escolher e folhear livros, procurando orientar-se por temas e ilustrações e tentando identificar palavras conhecidas.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EF04) - Recontar histórias ouvidas e planejar coletivamente roteiros de vídeos e de encenações, definindo os contextos, os personagens, a estrutura da história.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[3].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EF05) - Recontar histórias ouvidas para produção de reconto escrito, tendo o professor como escriba.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[4].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EF06) - Produzir suas próprias histórias orais e escritas (escrita espontânea), em situações com função social significativa.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[5].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EF07) - Levantar hipóteses sobre gêneros textuais veiculados em portadores conhecidos, recorrendo a estratégias de observação gráfica e/ou de leitura.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[6].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EF08) - Selecionar livros e textos de gêneros conhecidos para a leitura de um adulto e/ou para sua própria leitura (partindo de seu repertório sobre esses textos, como a recuperação pela memória, pela leitura das ilustrações etc.).</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[7].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EF09) - Levantar hipóteses em relação à linguagem escrita, realizando registros de palavras e textos, por meio de escrita espontânea.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[1].avaliacoes[8].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="header">
                            <span className="titulo">TRAÇOS, SONS, CORES E FORMAS</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03TS01) - Utilizar sons produzidos por materiais, objetos e instrumentos musicais durante brincadeiras de faz de conta, encenações, criações musicais, festas.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[2].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03TS02) - Expressar-se livremente por meio de desenho, pintura, colagem, dobradura e escultura, criando produções bidimensionais e tridimensionais.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[2].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03TS03) - Reconhecer as qualidades do som (intensidade, duração, altura e timbre), utilizando-as em suas produções sonoras e ao ouvir músicas e sons.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[2].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="header">
                            <span className="titulo">O EU, O OUTRO E O NÓS</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EO01) - Demonstrar empatia pelos outros, percebendo que as pessoas têm diferentes sentimentos, necessidades e maneiras de pensar e agir.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EO02) - Agir de maneira independente, com confiança em suas capacidades, reconhecendo suas conquistas e limitações.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EO03) - Ampliar as relações interpessoais, desenvolvendo atitudes de participação e cooperação.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EO04) - Comunicar suas ideias e sentimentos a pessoas e grupos diversos..</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[3].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EO05) - Demonstrar valorização das características de seu corpo e respeitar as características dos outros (crianças e adultos) com os quais convive.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[4].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EO06) - Manifestar interesse e respeito por diferentes culturas e modos de vida.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[5].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03EO07) - Usar estratégias pautadas no respeito mútuo para lidar com conflitos nas interações com crianças e adultos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[3].avaliacoes[6].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="header">
                            <span className="titulo">ESPAÇOS,TEMPOS, QUANTIDADES, RELAÇÕES E TRANSFORMAÇÕES</span>
                        </div>
                        <div className="body">
                            <div className="lista-pontos">
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03ET01) - Estabelecer relações de comparação entre objetos, observando suas propriedades.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[0].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03ET02) - Observar e descrever mudanças em diferentes materiais, resultantes de ações sobre eles, em experimentos envolvendo fenômenos naturais e artificiais.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[1].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03ET03) - Identificar e selecionar fontes de informações, para responder a questões sobre a natureza, seus fenômenos, sua conservação.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[2].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03ET04) - Registrar observações, manipulações e medidas, usando múltiplas linguagens (desenho, registro por números ou escrita espontânea), em diferentes suportes.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[3].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03ET05) - Classificar objetos e figuras de acordo com suas semelhanças e diferenças.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[4].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03ET06) - Relatar fatos importantes sobre seu nascimento e desenvolvimento, a história dos seus familiares e da sua comunidade.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[5].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03ET07) - Relacionar números às suas respectivas quantidades e identificar o antes, o depois e o entre em uma sequência.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[6].nota)
                                        })}
                                    </div>
                                </div>
                                <div className="ponto">
                                    <span className="ponto-titulo">(EI03ET08) - Expressar medidas (peso, altura etc.), construindo gráficos básicos.</span>
                                    <div className="lista-notas">
                                        {periodos.map(periodo => {
                                            return montarNotas(periodo.titulo, periodo.grupos[4].avaliacoes[7].nota)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardsNotasPre;