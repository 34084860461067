import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../User/Header";
import { Form } from "antd";
import Paginas from "./Paginas/Paginas";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        menu: [{ nome: "Outras Páginas", abreviatura: "Outras Páginas" }],
        estabelecimentos: [],
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //GRID
        filtroAtivo: { nome: "Outras Páginas", abreviatura: "Outras Páginas" },
        escolasCarregadas: false
    };

    componentWillMount() {
        this.listarEstabelecimentos();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    listarEstabelecimentos = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/carregar-canal-estabelecimentos"
        })
            .then(response => {
                const estabelecimentos = response.data.map(x => { return { nome: x.nome, abreviatura: x.abreviatura } })
                this.setState({
                    menu: [...estabelecimentos, { nome: "Outras Páginas", abreviatura: "Outras Páginas" }],
                    filtroAtivo: estabelecimentos[0],
                    estabelecimentos: response.data
                });
            })
            .catch(error => {

            });
    };

    ativarFiltro = (filtroAtivo) => {
        this.setState({ filtroAtivo });
    }

    render() {
        const {
            //REDIRECT
            redirect,
            redirectLink,
            filtroAtivo
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="FAQ" />
                <div className="administracao-empresa">
                    <div className="container container-body">
                        <div className="bloco-grid sem-grid" style={{ minHeight: 600 }}>
                            <div className="bloco-cursos-estatisticas">
                                <Paginas />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const FormMain = Form.create({ name: "form-minha-conta" })(Main);

export default FormMain;
