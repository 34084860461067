import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../GlobalState";

class Controlos extends Component {
    static contextType = GlobalContext;
    render() {
        return (
            <div className="bloco-controlos">
                <div className="controlos">
                    <div className="container-btn">
                        <Link to={`/${localStorage.getItem("nome_curso_pai") ? localStorage.getItem("nome_subcurso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase() : localStorage.getItem("nome_curso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/formador-lista-cursos/resultados`}>
                            <i className="fas fa-chevron-left" title="Voltar" />
                        </Link>
                    </div>
                    <div className="container-btn">
                        <Link to="#" onClick={this.context.onClickFullscreen} title={this.context.fullscreen ? "Sair de ecr� inteiro" : "Ecr� inteiro"}>
                            {this.context.fullscreen ? <i className="fas fa-compress" /> : <i className="fas fa-expand" />}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Controlos;
