import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Icon, Collapse } from "antd";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ConteudoTrabalho extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        objetivos: "",
        tipo: "",
        dt_inicio: "",
        dt_fim: "",
        enunciado: "",
        enunciado_nome: "",
        descricao: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LEITOR PDF
        numPages: null,
        pageNumber: 1,
        scale: 1.5,
        rotate: 0,
        //LOADING
        loading: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;

        this.CarregarConteudo();
    }

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/gestao-player-trabalho/carregar-trabalho",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        })
            .then(response => {
                let tipo = response.data.tipo_trabalho.charAt(0).toUpperCase() + response.data.tipo_trabalho.slice(1).toLowerCase();
                let icon = require("../../images/icons/trabalho_individual.png");
                if (tipo === "Grupo")
                    icon = require("../../images/icons/trabalho_grupo.png")
                if (tipo === "Colaborativo")
                    icon = require("../../images/icons/trabalho_colaborativo.png")
                this.props.carregarInfo(response.data.nome, response.data.objetivos, icon, moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"))
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    tipo: response.data.tipo_trabalho.charAt(0).toUpperCase() + response.data.tipo_trabalho.slice(1).toLowerCase(),
                    dt_inicio: response.data.dt_inicio ? moment(response.data.dt_inicio).format("DD/MM/YYYY") : "",
                    dt_fim: response.data.dt_fim ? moment(response.data.dt_fim).format("DD/MM/YYYY") : "",
                    enunciado: response.data.recurso,
                    enunciado_nome: response.data.recurso_nome,
                    descricao: response.data.descricao,
                    loading: false
                });
            })
            .catch(error => {
                //if (error.response.data === "SEM_PERMISSAO")
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/user"
                //    });
                //else
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.props.marcarComoNaoDisponivel();
            });
    };

    //LEITOR DE PDF
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    scaleDown = () => {
        if (this.state.scale >= 0.8) {
            this.setState({ scale: this.state.scale - 0.3 });
        }
    };

    scaleUp = () => {
        if (this.state.scale <= 1.8) {
            this.setState({ scale: this.state.scale + 0.3 });
        }
    };

    downloadFicheiro = (anexo, nome) => {
        const link = document.createElement('a');
        link.href = anexo;
        link.setAttribute(
            'download',
            nome,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    render() {
        const { Panel } = Collapse;
        const {
            nome,
            objetivos,
            tipo,
            dt_inicio,
            dt_fim,
            enunciado,
            enunciado_nome,
            descricao,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading
        } = this.state;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
                <div className="container">
                    <div className="trabalho-intro bloco-player-template">
                        <div className="bloco-info-tarefa">
                            <span className="icon-tarefa">
                                {tipo === "Individual" ?
                                    <img src={require("../../images/icons/trabalho_individual.png")} title="Trabalho individual" />
                                    : null}
                                {tipo === "Grupo" ?
                                    <img src={require("../../images/icons/trabalho_grupo.png")} title="Trabalho de grupo" />
                                    : null}
                                {tipo === "Colaborativo" ?
                                    <img src={require("../../images/icons/trabalho_colaborativo.png")} title="Trabalho colaborativo" />
                                    : null}
                            </span>
                            <span className="titulo-tarefa">{nome}</span>
                        </div>
                        <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                        {dt_inicio && dt_fim && (
                            <div className="item">
                                Período de realização:
                                <span className="label-conteudo">
                                    {dt_inicio} a {dt_fim}
                                </span>
                            </div>
                        )}
                        {dt_fim && !dt_inicio && (
                            <div className="item">
                                Entrega até:<span className="label-conteudo">{dt_fim}</span>
                            </div>
                        )}
                        <div className="item">
                            Enunciado:
                            <span className="label-conteudo" dangerouslySetInnerHTML={{ __html: descricao.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>
                        </div>
                        {enunciado &&
                            <>
                                <div className="item bloco-enunciado-leitor">
                                    {enunciado_nome.split(".").pop() === "pdf" ?
                                        <Collapse
                                            defaultActiveKey={['1']}
                                            expandIcon={({ isActive }) =>
                                                <div className="bloco-links-ficheiro">
                                                    <span className="bloco-download">
                                                        <Link to="#" className="botao-icon-download" title="Descarregar" onClick={event => {
                                                            event.stopPropagation();
                                                            this.downloadFicheiro(enunciado, enunciado_nome);
                                                        }}>
                                                            <Icon type="download" />
                                                        </Link>
                                                    </span>
                                                    <span className="bloco-links-img-container">
                                                        <img src={require("../../images/icons/pdf.png")} />
                                                    </span>
                                                    <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                </div>
                                            }
                                        >
                                            <Panel key="1">
                                                <Document file={enunciado} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                    <Page
                                                        pageNumber={this.state.pageNumber}
                                                        scale={this.state.scale}
                                                        rotate={this.state.rotate}
                                                    />
                                                </Document>
                                                <div className="controlos-pdf">
                                                    <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber > this.state.numPages ? prevState.pageNumber - 1 : 1) }))}>
                                                        <Icon type="caret-left" />
                                                    </button>
                                                    <span>
                                                        {this.state.pageNumber}/{this.state.numPages}
                                                    </span>
                                                    <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber < this.state.numPages ? prevState.pageNumber + 1 : this.state.numPages) }))}>
                                                        <Icon type="caret-right" />
                                                    </button>
                                                    <button onClick={this.scaleUp}>
                                                        <Icon type="zoom-in" />
                                                    </button>
                                                    <button onClick={this.scaleDown}>
                                                        <Icon type="zoom-out" />
                                                    </button>
                                                    <button onClick={() => this.setState({ rotate: this.state.rotate === 180 ? 180 : this.state.rotate + 90 })}>
                                                        <Icon type="reload" />
                                                    </button>
                                                    <button onClick={() => this.setState({ rotate: this.state.rotate === -180 ? -180 : this.state.rotate - 90 })}>
                                                        <Icon type="reload" style={{ transform: "scaleX(-1) " }} />
                                                    </button>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                        :
                                        <Collapse
                                            defaultActiveKey={['1']}
                                            expandIcon={({ isActive }) =>
                                                <div className="bloco-links-ficheiro">
                                                    <span className="bloco-download">
                                                        <Link to="#" className="botao-icon-download" title="Descarregar" onClick={event => {
                                                            event.stopPropagation();
                                                            this.downloadFicheiro(enunciado, enunciado_nome);
                                                        }}>
                                                            <Icon type="download" />
                                                        </Link>
                                                    </span>
                                                    <span className="bloco-links-img-container">
                                                        <img src={require("../../images/icons/images.png")} />
                                                    </span>
                                                    <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                </div>
                                            }
                                        >
                                            <Panel key="1">
                                                <img src={enunciado} style={{ width: "100%" }} />
                                            </Panel>
                                        </Collapse>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            );
    }
}
export default withRouter(ConteudoTrabalho);
