import React from "react";
import { Tooltip } from "antd";
import moment from 'moment';

export const montarColunasDias = (
    participanteId, participanteAtivo, participanteData, utilizadorEstados, listaAulas, numDias, ano, mes, abrirModal
) => {
    let dias = [];
    var numDias = numDias;

    const haEstadoNoIntervalo = VerificarEstadoNoIntervalo(utilizadorEstados, ano, mes);

    for (let i = 1; i <= numDias; i++) {
        const dataAtual = `${ano}-${("0" + moment().month(mes).format("M")).slice(-2)}-${("0" + i).slice(-2)}`;

        if (haEstadoNoIntervalo && (haEstadoNoIntervalo.estadoAntesDoMesSemFim || moment(dataAtual).isSame(haEstadoNoIntervalo.inicioOcupacao, 'day'))) {
            const estado = haEstadoNoIntervalo;
            const totalDias = !haEstadoNoIntervalo.estadoAntesDoMesSemFim ? haEstadoNoIntervalo.totalDiasOcupados : numDias;

            dias.push(
                <td
                    key={`dia-${i}`}
                    style={{
                        borderTop: "1px solid #000000",
                        borderBottom: "1px solid #000000",
                        borderLeft: "1px solid #000000",
                        borderRight: "1px solid #000000"
                    }}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                    sdnum="1033;0;@"
                    colSpan={totalDias}
                >
                    {estado.estado === "Anulado" ? (!estado.motivo ? "Anulado" : estado.motivo) : estado.estado} {moment(estado.data).format("DD/MM/YYYY")}
                </td>
            );

            i += totalDias - 1;
        }
        else {
            const aulas = listaAulas.filter(x => moment(x.dt_inicio).isSame(dataAtual));

            if (aulas.length > 0) {
                let presencas = 0;
                let faltas = 0;
                let faltasJustificadas = 0;
                let aulasPresencas = [];
                let aulasFaltas = [];
                let aulasFaltasJustificadas = [];

                const ativo = participanteAtivo || (!participanteAtivo && moment(participanteData).isSameOrAfter(moment(aulas[0].dt_inicio)));
                const presente = aulas[0].presentes?.some(x => x === participanteId);

                const adicionarAula = (aula, tipo, array, contador) => {
                    array.push(
                        <React.Fragment key={`aula-${aula.id}-${tipo}`}>
                            <span>{aula.titulo}: {tipo}</span>
                            <br />
                        </React.Fragment>
                    );
                    contador++;
                    return contador;
                };

                if (ativo && presente) {
                    aulas.forEach(aula => {
                        const presencasAlunos = aula.presencas ? JSON.parse(aula.presencas) : [];
                        const participante = presencasAlunos.find(x => x.id === participanteId);

                        if (participante != undefined && !participante?.bloqueado) {
                            if ((aula.presenca || (!aula.presenca && presencasAlunos.some(x => x.id === participanteId && x.estado === "P")))) {
                                presencas = adicionarAula(aula, 'Presença', aulasPresencas, presencas);
                            }

                            if ((aula.falta || (!aula.falta && presencasAlunos.some(x => x.id === participanteId && x.estado === "F")))) {
                                faltas = adicionarAula(aula, 'Falta', aulasFaltas, faltas);
                            }

                            if (!aula.presenca && !aula.falta && presencasAlunos.some(x => x.id === participanteId && x.estado === "FJ")) {
                                faltasJustificadas = adicionarAula(aula, 'Falta justificada', aulasFaltasJustificadas, faltasJustificadas);
                            }
                        }
                    });

                    dias.push(
                        <td
                            key={`dia-${i}`}
                            className="tabela-celula"
                            style={{
                                borderTop: "1px solid #000000",
                                borderBottom: "1px solid #000000",
                                borderLeft: "1px solid #000000",
                                borderRight: "1px solid #000000"
                            }}
                            align="center"
                            valign="middle"
                            bgcolor="#eaeaea"
                            sdnum="1033;0;@"
                            onClick={() => abrirModal(aulas, participanteId)}
                        >
                            {presencas > 0 && (
                                <font face="Cambria" style={{ color: "green" }}>
                                    <Tooltip placement="bottom" title={<span>{aulasPresencas}</span>}>
                                        <strong>{`P (${presencas})`}</strong>
                                    </Tooltip>
                                    <br />
                                </font>
                            )}
                            {faltas > 0 && (
                                <font face="Cambria" style={{ color: "red" }}>
                                    <Tooltip placement="bottom" title={<span>{aulasFaltas}</span>}>
                                        <strong>{`F (${faltas})`}</strong>
                                    </Tooltip>
                                    <br />
                                </font>
                            )}
                            {faltasJustificadas > 0 && (
                                <font face="Cambria" style={{ color: "orange" }}>
                                    <Tooltip placement="bottom" title={<span>{aulasFaltasJustificadas}</span>}>
                                        <strong>{`FJ (${faltasJustificadas})`}</strong>
                                    </Tooltip>
                                    <br />
                                </font>
                            )}
                        </td>
                    );
                }
                else {
                    dias.push(
                        <td
                            key={`dia-${i}`}
                            style={{
                                borderTop: "1px solid #000000",
                                borderBottom: "1px solid #000000",
                                borderLeft: "1px solid #000000",
                                borderRight: "1px solid #000000"
                            }}
                            align="center"
                            valign="middle"
                            bgcolor="#FFFFFF"
                            sdnum="1033;0;@"
                        >
                            <font face="Cambria">
                                <br />
                            </font>
                        </td>
                    );
                }
            }
            else {
                dias.push(
                    <td
                        key={`dia-${i}`}
                        style={{
                            borderTop: "1px solid #000000",
                            borderBottom: "1px solid #000000",
                            borderLeft: "1px solid #000000",
                            borderRight: "1px solid #000000"
                        }}
                        align="center"
                        valign="middle"
                        bgcolor="#FFFFFF"
                        sdnum="1033;0;@"
                    >
                        <font face="Cambria">
                            <br />
                        </font>
                    </td>
                );
            }
        }
    }

    return dias;
};


export const montarCamposDias = (utilizadorEstados, ano, mes) => {
    const estados = [];

    var dataInicioMes = moment([ano, (moment().month(mes).format("M")).slice(-2) - 1]).startOf('month').format('YYYY-MM-DD');
    var dataFimMes = moment([ano, (moment().month(mes).format("M")).slice(-2) - 1]).endOf('month').format('YYYY-MM-DD');

    const dataInicio = moment(dataInicioMes, 'YYYY-MM-DD');
    const dataFim = moment(dataFimMes, 'YYYY-MM-DD');

    const resultado = utilizadorEstados.map(utilizadorEstado => {
        const dataInicioEstado = moment(utilizadorEstado.data).startOf('day');
        const dataFimEstado = utilizadorEstado.fim ? moment(utilizadorEstado.fim).startOf('day') : null;

        let estaNoIntervalo = false;
        let estadoAntesDoMesSemFim = false;
        let totalDiasOcupados = 0;
        let inicioOcupacao = null;
        let fimOcupacao = null;

        if (!dataFimEstado) {
            if (dataInicioEstado.isBefore(dataInicio)) {
                estaNoIntervalo = true;
                estadoAntesDoMesSemFim = true;
            }
            else if (dataInicioEstado.isBetween(dataInicio, dataFim, undefined, '[]')) {
                estaNoIntervalo = true;
                totalDiasOcupados = dataFim.diff(dataInicioEstado, 'days') + 1;
                inicioOcupacao = dataInicioEstado.format('YYYY-MM-DD');
                fimOcupacao = dataFim.format('YYYY-MM-DD');
            }
        } else {
            if (dataInicioEstado.isBetween(dataInicio, dataFim, undefined, '[]') || dataFimEstado.isBetween(dataInicio, dataFim, undefined, '[]')) {
                estaNoIntervalo = true;
                inicioOcupacao = dataInicioEstado.isBefore(dataInicio) ? dataInicio.format('YYYY-MM-DD') : dataInicioEstado.format('YYYY-MM-DD');
                fimOcupacao = dataFimEstado.isAfter(dataFim) ? dataFim.format('YYYY-MM-DD') : dataFimEstado.format('YYYY-MM-DD');
                totalDiasOcupados = moment(fimOcupacao).diff(moment(inicioOcupacao), 'days') + 1;
            }
            else if (dataInicioEstado.isBefore(dataInicio) && dataFimEstado.isAfter(dataFim)) {
                estaNoIntervalo = true;
                inicioOcupacao = dataInicio.format('YYYY-MM-DD');
                fimOcupacao = dataFim.format('YYYY-MM-DD');
                totalDiasOcupados = dataFim.diff(dataInicio, 'days') + 1;
            }
        }

        return {
            estado: utilizadorEstado.estado,
            motivo: utilizadorEstado.motivo,
            data: utilizadorEstado.data,
            estaNoIntervalo,
            estadoAntesDoMesSemFim,
            totalDiasOcupados,
            inicioOcupacao,
            fimOcupacao
        };
    });

    const haEstadoNoIntervalo = resultado.find(r => r.estaNoIntervalo);

    if (haEstadoNoIntervalo) {
        estados.push(<div
            className="estrutura-calendario"
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <span style={{ flex: "1", textAlign: "center" }}>
                    {haEstadoNoIntervalo.estado === "Anulado" ? (haEstadoNoIntervalo.motivo != "" ? haEstadoNoIntervalo.motivo : "Anulado") : haEstadoNoIntervalo.estado} {moment(haEstadoNoIntervalo.data).format("DD/MM/YYYY")}
                </span>
            </div>
        </div>)
    }

    return estados;
}

const VerificarEstadoNoIntervalo = (utilizadorEstados, ano, mes) => {
    var dataInicioMes = moment([ano, (moment().month(mes).format("M")).slice(-2) - 1]).startOf('month').format('YYYY-MM-DD');
    var dataFimMes = moment([ano, (moment().month(mes).format("M")).slice(-2) - 1]).endOf('month').format('YYYY-MM-DD');

    const dataInicio = moment(dataInicioMes, 'YYYY-MM-DD');
    const dataFim = moment(dataFimMes, 'YYYY-MM-DD');

    const resultado = utilizadorEstados.map(utilizadorEstado => {
        const dataInicioEstado = moment(utilizadorEstado.data).startOf('day');
        const dataFimEstado = utilizadorEstado.fim ? moment(utilizadorEstado.fim).startOf('day') : null;

        let estaNoIntervalo = false;
        let estadoAntesDoMesSemFim = false;
        let totalDiasOcupados = 0;
        let inicioOcupacao = null;
        let fimOcupacao = null;

        if (!dataFimEstado) {
            if (dataInicioEstado.isBefore(dataInicio)) {
                estaNoIntervalo = true;
                estadoAntesDoMesSemFim = true;
            }
            else if (dataInicioEstado.isBetween(dataInicio, dataFim, undefined, '[]')) {
                estaNoIntervalo = true;
                totalDiasOcupados = dataFim.diff(dataInicioEstado, 'days') + 1;
                inicioOcupacao = dataInicioEstado.format('YYYY-MM-DD');
                fimOcupacao = dataFim.format('YYYY-MM-DD');
            }
        } else {
            if (dataInicioEstado.isBetween(dataInicio, dataFim, undefined, '[]') || dataFimEstado.isBetween(dataInicio, dataFim, undefined, '[]')) {
                estaNoIntervalo = true;
                inicioOcupacao = dataInicioEstado.isBefore(dataInicio) ? dataInicio.format('YYYY-MM-DD') : dataInicioEstado.format('YYYY-MM-DD');
                fimOcupacao = dataFimEstado.isAfter(dataFim) ? dataFim.format('YYYY-MM-DD') : dataFimEstado.format('YYYY-MM-DD');
                totalDiasOcupados = moment(fimOcupacao).diff(moment(inicioOcupacao), 'days') + 1;
            }
            else if (dataInicioEstado.isBefore(dataInicio) && dataFimEstado.isAfter(dataFim)) {
                estaNoIntervalo = true;
                inicioOcupacao = dataInicio.format('YYYY-MM-DD');
                fimOcupacao = dataFim.format('YYYY-MM-DD');
                totalDiasOcupados = dataFim.diff(dataInicio, 'days') + 1;
            }
        }

        return {
            estado: utilizadorEstado.estado,
            motivo: utilizadorEstado.motivo,
            data: utilizadorEstado.data,
            estaNoIntervalo,
            estadoAntesDoMesSemFim,
            totalDiasOcupados,
            inicioOcupacao,
            fimOcupacao
        };
    });

    return resultado.find(r => r.estaNoIntervalo);
};