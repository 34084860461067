import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';

const PieChart = ({data}) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);
        
        const total = data.reduce((sum, item) => sum + item.value, 0);
        const valueMap = data.reduce((acc, item) => {
            acc[item.name] = item.value;
            return acc;
        }, {});

        const option = {
            title: {
                show:false
            },
            tooltip: {
                show:false
            },
            
            backgroundColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#ffffff' },   // Cor inicial
                { offset: 1, color: '#f3f5f8' }    // Cor final
            ]),
            
            grid:{
                //show: true,
                top: "0%",
                height:"100%"
            },
            //backgroundColor: "rgba(190, 112, 112, 1)",
            legend: {
                borderRadius: [7, 7, 7, 7],
                padding: [10, 5, 10, 5],
                orient: 'horizontal',
                bottom: '10%',
                icon:'circle',
                height: "200",
                backgroundColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#f3f5f8' },   // Cor inicial
                    { offset: 1, color: '#ffffff' }    // Cor final
                ]),
                textStyle: {
                    rich: {
                        a: {
                            color:  '#A3AED0',
                            fontWeight: 'bold',
                            fontSize: 12,
                            weight: 500
                        },
                        b: {
                            color: '#2B3674',
                            fontSize: 18,
                            fontWeight: 700,
                            lineHeight: 30,
                            letterSpacing: '-0.02em',
                            textAlign: 'left'
                            

    }
                    }
                },
                formatter: function (name) {
                    const value = valueMap[name];
                    const percentage = ((value / total) * 100).toFixed(0);
                    return `{a|${name} \n} {b|${percentage}%}`;
                },
                
            },
            series: [
                {
                    top: "%",
                    bottom: "10%",
                    height: "100%",
                    name: 'Access From',
                    type: 'pie',
                    radius: '50%',
                    data:data,
                    label: {
                        show: false // Oculta os nomes e valores dos setores
                    },
                    labelLine: {
                        show: false // Oculta as setas de indicadores
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        myChart.setOption(option);
        
        return () => {
            myChart.dispose();
        };
    }, [data]);

    return (
        <div
            ref={chartRef}
            style={{ width: '350px', height:'100%'}}
        />
    );
};

export default PieChart;
