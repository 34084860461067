import React, { useState, useEffect } from "react";
import { Drawer, Form, Checkbox, Col, Row, notification } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerListaCursos = ({ visible, cursos, utilizador, utilizadores, onClose, onConfirm, importacao }) => {
    const [listaCursos, setListaCursos] = useState([]);
    const [selecionados, setSelecionados] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [todos, setTodos] = useState(false);

    const onChange = selecionados => {
        setSelecionados(selecionados);
        setIndeterminate(!!selecionados.length && selecionados.length < listaCursos.length);
        setTodos(selecionados.length === listaCursos.length);
    };

    const selecionarTodos = (event) => {
        setSelecionados(event.target.checked ? listaCursos.map(x => x.id) : []);
        setIndeterminate(false);
        setTodos(event.target.checked);
    }

    const confirma = () => {
        if (!importacao)
            axios({
                method: "post",
                url: "/api/gestao-curso-inscricoes/inscrever-utilizador-cursos-selecionados",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    utilizadorId: utilizador.id,
                    selecionados: JSON.stringify(selecionados)
                }
            })
                .then(() => {
                    onConfirm(selecionados);

                    openNotificationWithIcon("success", "Sucesso", "Usuário inscrito nos cursos selecionados!");
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                });
        else
            axios({
                method: "post",
                url: "/api/gestao-curso-inscricoes/inscrever-utilizadores-cursos-selecionados",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    utilizadores: JSON.stringify(utilizadores.map(x => x.id)),
                    selecionados: JSON.stringify(selecionados)
                }
            })
                .then(() => {
                    onConfirm();

                    openNotificationWithIcon("success", "Sucesso", "Usuário inscrito nos cursos selecionados!");
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                });
    }

    useEffect(() => {
        setListaCursos(cursos);
    }, [cursos])

    useEffect(() => {
        if (!importacao) {
            setSelecionados(utilizador.inscricoes);
            setIndeterminate(!!utilizador.inscricoes?.length && utilizador.inscricoes?.length < listaCursos.length);
            setTodos(utilizador.inscricoes?.length === listaCursos.length);
        }
    }, [utilizador])

    return (
        <Drawer
            className="drawer-inscrever"
            title="Selecione abaixo quais as opções que pretende inscrever o usuário"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
        >
            <div className="bloco-drawer-associar">
                <Form className="form-emitir-declaracao">
                    <Form.Item>
                        <div style={{ marginBottom: "20px" }}>
                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={selecionarTodos}
                                checked={todos}
                            >
                                Todos os cursos
                            </Checkbox>
                        </div>
                        <Checkbox.Group style={{ width: '100%' }} value={selecionados} onChange={onChange}>
                            <Row>
                                {listaCursos.map((curso, index) => (
                                    <Col span={16}>
                                        <Checkbox value={curso.id}>
                                            {curso.nome}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button className="botao-principal" type="primary" onClick={confirma}>
                    Confirmar
                </button>
            </div>
        </Drawer>
    );
}

const FormDrawerListaCursos = Form.create({ name: "drawer-lista-cursos" })(DrawerListaCursos);

export default FormDrawerListaCursos;
