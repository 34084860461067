import React, { useState } from 'react';

const DropdownAnos = ({atualizarAno}) => {
    // Estado para armazenar o mês selecionado
    const [mesSelecionado, setMesSelecionado] = useState(2024);

    // Lista de Anos
    const ano = [
        2022, 2023, 2024, 2025
    ];
  
    // Função para lidar com a mudança na seleção
    const handleChange = (event) => {
        setMesSelecionado(event.target.value);
        atualizarAno(event.target.value)
    };

    return (
        <div>
            <select id="meses" value={mesSelecionado} onChange={handleChange} style={{border: 0}}  className="custom-dropdown">
                <option value="">Selecione...</option>
                {ano.map((ano, index) => (
                    <option key={index} value={ano}>
                        {ano}
                    </option>
                ))}
            </select>

        </div>
    );
};

export default DropdownAnos;
