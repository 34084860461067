import React, { useState, useEffect } from "react";
import axios from "axios";

const TabelaTipoNotas = ({cursoId, faseId, utilizadorId}) => {
    const [record, setRecord] = useState("");
    const [periods, setPeriods] = useState([]);
    const [loading, setLoading] = useState(true);

    const listar = () => {
        axios({
            method: "get",
            url: "/api/diario/listar-notas-aluno",
            params: {
                cursoId,
                faseId
            }
        })
            .then(response => {
                setRecord(response.data);
                setPeriods(response.data.periodos);
                setLoading(false);
            })
            .catch();
    }

    const maxNumber = (periods) => {
        const totals = periods.map(period => {
            return period.totalGrades;
        });

        return Math.max(...totals);
    }

    const maxRetakesNumber = (periods) => {
        const totals = periods.map(period => {
            return period.totalRetakes;
        });

        return Math.max(...totals);
    }

    const buildTests = (period, periods) => {
        const biggerTotal = maxNumber(periods);
        const biggerRatakesTotal = maxRetakesNumber(periods);
        let tableRow = [];
        let tableRowLevelTwo = [];
        let tableData = [];
        let totals = [];

        for (let test of period.tests) {
            const subTests = period.tests.filter(x => x.parentId === test.id)
            if (subTests.length > 0) {
                totals.push({
                    id: test.id,
                    total: totalSubLevels(1, subTests, period.tests)
                });
            }
            else
                totals.push({
                    id: test.id,
                    total: 0
                });
        }

        const numTotals = totals.map(item => item.total);
        const max = Math.max(...numTotals);
        const testsList = period.tests.filter(x => x.parentId === period.id)
        for (let test of testsList) {
            const subTests = period.tests.filter(x => x.parentId === test.id)
            if (subTests.length > 0) {
                tableRowLevelTwo.push(...buildTableHead(subTests, period.tests));
            }
            const testTotal = totals.find(item => item.id == test.id)

            tableData.push(
                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={(max - testTotal.total) + 1} colSpan={subTests.length} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                    <b>
                        <font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{test.name}</font>
                    </b>
                </td>
            )
        }

        const totalRetakes = period.tests.filter(x => x.retake);
        const totalTests = tableData.length;
        for (let i = 0; i < ((biggerTotal - totalTests) + (biggerRatakesTotal - totalRetakes.length)); i++) {
            tableData.push(<td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                <b>
                    <font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}></font>
                </b>
            </td>)
        }

        tableRow.push(
            <tr>
                <td style={{ borderLeft: '1px solid #000000', borderTop: '1px solid #000000', borderRight: '1px solid #000000', fontSize: "14px" }} rowSpan={max + 1} height={40} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000"></font></b></td>
                {tableData.map(td => td)}
                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000', fontSize: "14px" }} rowSpan={max + 1} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#C00000">NOTA BIMESTRAL</font></b></td>
                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000', fontSize: "14px" }} rowSpan={max + 1} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#C00000"></font></b></td>
            </tr>
        )
        tableRow = [...tableRow, ...tableRowLevelTwo];

        return tableRow
    }

    const buildTestsGrades = (period, periods) => {
        const biggerTotal = maxNumber(periods);
        const biggerRatakesTotal = maxRetakesNumber(periods);
        let tableRow = [];
        let tableData = [];

        const testsList = period.tests.filter(x => x.parentId === period.id)
        for (let test of testsList) {
            const subTests = period.tests.filter(x => x.parentId === test.id)
            if (subTests.length > 0) {
                tableData.push(...buildTableRow(subTests, period.tests));
            }
            else {
                tableData.push(
                    <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><a className="comment-indicator" />
                        <b>
                            <font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{test.grade}</font>
                        </b>
                    </td>
                )
            }
        }

        const totalRetakes = period.tests.filter(x => x.retake);
        const totalTests = tableData.length;
        for (let i = 0; i < ((biggerTotal - totalTests) + (biggerRatakesTotal - totalRetakes.length)); i++) {
            tableData.push(<td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><a className="comment-indicator" />
                <b>
                    <font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}></font>
                </b>
            </td>)
        }

        tableRow.push(
            <tr>
                <td style={{ borderLeft: '1px solid #000000', borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} align="center" valign="middle" sdval={1} sdnum="1033;0;00"><b><font face="Cambria" color="#000000">1º Bimestre</font></b></td>
                {tableData.map(td => td)}
                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={period.total >= 6 ? "#c9ffdb" : "#FFC7CE"} sdval={0} sdnum="1033;0;0.0;-0.0;"><b><font face="Cambria" color="#000000">{period.total}<br /></font></b></td>
                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={period.total >= 6 ? "#c9ffdb" : "#FFC7CE"} sdval={0} sdnum="1033;0;0.0;-0.0;"><b><font face="Cambria" color="#000000">{period.total >= 6 ? "Aprovado" : "Reprovado"}<br /></font></b></td>
            </tr>
        )

        return tableRow
    }

    const buildTableHead = (testsList, originalTestsList) => {
        let tableRow = [];
        let tableData = [];
        for (let test of testsList) {
            const subTests = originalTestsList.filter(x => x.parentId === test.id)
            if (subTests.length > 0) {
                tableRow.push(...buildTableHead(subTests, originalTestsList));
            }

            tableData.push(
                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                    <b>
                        <font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{test.name}</font>
                    </b>
                </td>
            )
        }
        tableRow.push(<tr>{tableData.map(td => td)}</tr>)

        return tableRow;
    }

    const buildTableRow = (testsList, originalTestsList) => {
        let tableData = [];
        for (let test of testsList) {
            const subTests = originalTestsList.filter(x => x.parentId === test.id)
            if (subTests.length > 0) {
                tableData.push(...buildTableHead(subTests, originalTestsList));
            }

            tableData.push(
                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><a className="comment-indicator" />
                    <b>
                        <font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{test.grade}</font>
                    </b>
                </td>
            )
        }

        return tableData;
    }

    const totalSubLevels = (total, testsList, originalTestsList) => {
        for (let test of testsList) {
            const subTests = originalTestsList.filter(x => x.parentId === test.id)
            if (subTests.length > 0) {
                total += totalSubLevels((total + 1), subTests, originalTestsList);
            }
        }

        return total;
    }

    useEffect(() => {
        listar();
    }, []);

    if (loading)
        return (
            <div className="loading-data" style={{ minHeight: 100 }}>
                <div className="loading" />
            </div>
        );

    return (
        <table cellSpacing={0} border={0} style={{ width: "100%" }}>
            <tbody>
                {periods.map(period => (
                    <>
                        {buildTests(period, periods).map(test => test)}
                        {buildTestsGrades(period, periods).map(test => test)}
                    </>
                ))}
                <tr>
                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxNumber(periods) + 2}></td>
                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                        <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Total Geral</font></b>
                    </td>
                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={record.total >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                        <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{parseFloat(record.total.toFixed(2))}</font></b>
                    </td>
                </tr>
                <tr>
                    <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxNumber(periods) + 2}></td>
                    <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                        <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Média Anual</font></b>
                    </td>
                    <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={record.anualEverage >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                        <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{parseFloat(record.anualEverage.toFixed(2))}</font></b>
                    </td>
                </tr>
                <tr>
                    <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxNumber(periods) + 2}></td>
                    <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                        <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Prova Final</font></b>
                    </td>
                    <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={record.finalTest >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                        <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{parseFloat(record.finalTest.toFixed(2))}</font></b>
                    </td>
                </tr>
                <tr>
                    <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxNumber(periods) + 2}></td>
                    <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                        <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Média Final</font></b>
                    </td>
                    <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={record.finalEverage >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                        <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{parseFloat(record.finalEverage.toFixed(2))}</font></b>
                    </td>
                </tr>
                {record.courseYear === "9º" && !record.courseEja || record.courseYear === "10º" && record.courseEja ?
                    <>
                        <tr>
                            <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxNumber(periods) + 2}></td>
                            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                                <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>2ª Chamada</font></b>
                            </td>
                            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={record.secondCall >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                                <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{parseFloat(record.secondCall.toFixed(2))}</font></b>
                            </td>
                        </tr><tr>
                            <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxNumber(periods) + 2}></td>
                            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                                <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Pontuação Final</font></b>
                            </td>
                            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={record.final >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                                <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{parseFloat(record.final.toFixed(2))}</font></b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxNumber(periods) + 2}></td>
                            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                                <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}></font></b>
                            </td>
                            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={record.final >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                                <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{record.final >= 6 ? "Aprovado" : "Reprovado"}</font></b>
                            </td>
                        </tr>
                    </>
                    :
                    <tr>
                        <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxNumber(periods) + 2}></td>
                        <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                            <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}></font></b>
                        </td>
                        <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={record.finalEverage >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                            <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{record.finalEverage >= 6 ? "Aprovado" : "Reprovado"}</font></b>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    );
}

export default TabelaTipoNotas;
