import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, DatePicker, notification, Icon, Select, Tooltip } from "antd";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";
import noimage from "../../images/noimage.png";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DadosGerais extends Component {
    static contextType = GlobalContext;
    state = {
        //LOGO ENTIDADE
        foto: "",
        preview: null,
        //DADOS GERAIS
        nome: "",
        emp_email: "",
        nif: "",
        morada: "",
        cod_postal: "",
        localidade: "",
        pais: "",
        //UPLOAD VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.carregar();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSelect = value => {
        this.setState({ pais: value });
    };

    //ATUALIZA A FOTO
    uploadFoto = event => {
        if (event.target.files[0].size < 100 * 1024)
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop().toLowerCase())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    foto: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
            }
        else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a ficheiro.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //REMOVE A FOTO
    removerFoto = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            foto: "",
            preview: ""
        });
    };

    //CARREGA OS DADOS DO UTILIZADOR
    carregar = () => {
        axios({
            method: "get",
            url: "/api/minha-conta/entidade"
        })
            .then(response => {
                this.setState({
                    //LOGO ENTIDADE
                    foto: response.data.emp_logo,
                    preview: response.data.emp_logo,
                    //DADOS GERAIS
                    nome: response.data.emp_nome ? response.data.emp_nome : "",
                    emp_email: response.data.emp_email ? response.data.emp_email : "",
                    nif: response.data.emp_nif ? response.data.emp_nif : "",
                    morada: response.data.emp_morada ? response.data.emp_morada : "",
                    cod_postal: response.data.emp_cod_postal ? response.data.emp_cod_postal : "",
                    localidade: response.data.emp_localidade ? response.data.emp_localidade : "",
                    pais: response.data.emp_pais ? response.data.emp_pais : "",
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    atualizarDados = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 100 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                //LOGO ENTIDADE
                item.append("emp_logo", this.state.foto);
                //DADOS GERAIS
                item.append("emp_nome", this.state.nome);
                item.append("emp_email", this.state.emp_email);
                item.append("emp_nif", this.state.nif);
                item.append("emp_morada", this.state.morada);
                item.append("emp_cod_postal", this.state.cod_postal);
                item.append("emp_localidade", this.state.localidade);
                item.append("emp_pais", this.state.pais);

                axios({
                    method: "put",
                    url: "/api/minha-conta/entidade",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.setState({
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    disabledDate = current => {
        return current && current > moment().endOf("day");
    };

    render() {
        const { TextArea } = Input;
        const { getFieldDecorator } = this.props.form;
        const {
            preview,
            nome,
            emp_email,
            nif,
            morada,
            cod_postal,
            localidade,
            pais,
            //LOADING NO BOTAO
            iconLoading,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-minha-conta">
                <div className="dados-pessoais">
                    <div className="bloco-img bloco">
                        <h3 className="titulo-separador" style={{ textAlign: "left" }}>Dados sobre a minha Entidade</h3>
                        <Form>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="container-img">
                                    <img src={preview ? preview : noimage} />
                                </div>
                                <input
                                    type="file"
                                    id="input-foto"
                                    accept="image/*"
                                    onChange={this.uploadFoto}
                                    style={{ display: "none" }}
                                />
                                {!preview ? (
                                    <>
                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                            <span>Adicionar</span>
                                        </Link>{" "}
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <>
                                                    <p>Tamanho máximo: 100KB</p>
                                                    <p>Tamanho recomendado: 200x200px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                ) : (
                                        <>
                                            <Link
                                                to="#"
                                                className="link-principal-border"
                                                onClick={this.ativarUploadFoto}
                                            >
                                                <span>Alterar</span>
                                            </Link>
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <>
                                                        <p>Tamanho máximo: 100KB</p>
                                                        <p>Tamanho recomendado: 200x200px</p>
                                                        <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                    </>
                                                }
                                            >
                                                <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                            </Tooltip>
                                            <Link
                                                to="#"
                                                className="link-principal-border"
                                                onClick={this.removerFoto}
                                            >
                                                <span>Remover</span>
                                            </Link>
                                        </>
                                    )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="bloco-info">
                        <Form className="minha-conta">
                            <Form.Item
                                label={
                                    <>
                                        Entidade / Organização / Empresa / Escola{" "}
                                        <Tooltip className="info-icon" title="Dados sobre o meu local de trabalho: Empresa / Instituição / Associação ...">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                }
                            >
                                <Input value={nome} name="nome" onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        <Icon type="mail" />
                                        E-mail
                                    </>
                                }
                            >
                                {getFieldDecorator("email", {
                                    rules: [
                                        {
                                            type: "email",
                                            message: "Introduza um endereço de e-mail válido"
                                        }
                                    ],
                                    initialValue: emp_email
                                })(<Input name="emp_email" className="input-50" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="CPF / CNPJ">
                                <Input name="nif" className="input-25" value={nif} onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item label="CEP">
                                <Input value={cod_postal} name="cod_postal" className="input-25" onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item label="Localidade / Estado">
                                <Input value={localidade} name="localidade" className="input-50" onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item label="Endereço">
                                <TextArea value={morada} name="morada" onChange={this.handleChange} rows={4} style={{ height: 100 }} />
                            </Form.Item>
                            <Form.Item label="País">
                                <Select
                                    className="input-25 selecionar-pais"
                                    showSearch
                                    placeholder="Selecionar"
                                    value={pais}
                                    allowClear={true}
                                    onChange={this.handleChangeSelect}
                                >
                                    <Option value="África do Sul">África do Sul</Option>
                                    <Option value="Albânia">Albânia</Option>
                                    <Option value="Alemanha">Alemanha</Option>
                                    <Option value="Andorra">Andorra</Option>
                                    <Option value="Angola">Angola</Option>
                                    <Option value="Anguilla">Anguilla</Option>
                                    <Option value="Antigua">Antigua</Option>
                                    <Option value="Arábia Saudita">Arábia Saudita</Option>
                                    <Option value="Argentina">Argentina</Option>
                                    <Option value="Armênia">Armênia</Option>
                                    <Option value="Aruba">Aruba</Option>
                                    <Option value="Austrália">Austrália</Option>
                                    <Option value="Áustria">Áustria</Option>
                                    <Option value="Azerbaijão">Azerbaijão</Option>
                                    <Option value="Bahamas">Bahamas</Option>
                                    <Option value="Bahrein">Bahrein</Option>
                                    <Option value="Bangladesh">Bangladesh</Option>
                                    <Option value="Barbados">Barbados</Option>
                                    <Option value="Bélgica">Bélgica</Option>
                                    <Option value="Benin">Benin</Option>
                                    <Option value="Bermudas">Bermudas</Option>
                                    <Option value="Botsuana">Botsuana</Option>
                                    <Option value="Brasil" selected>
                                        Brasil
                                    </Option>
                                    <Option value="Brunei">Brunei</Option>
                                    <Option value="Bulgária">Bulgária</Option>
                                    <Option value="Burkina Fasso">Burkina Fasso</Option>
                                    <Option value="botão">botão</Option>
                                    <Option value="Cabo Verde">Cabo Verde</Option>
                                    <Option value="Camarões">Camarões</Option>
                                    <Option value="Camboja">Camboja</Option>
                                    <Option value="Canadá">Canadá</Option>
                                    <Option value="Cazaquistão">Cazaquistão</Option>
                                    <Option value="Chade">Chade</Option>
                                    <Option value="Chile">Chile</Option>
                                    <Option value="China">China</Option>
                                    <Option value="Cidade do Vaticano">Cidade do Vaticano</Option>
                                    <Option value="Colômbia">Colômbia</Option>
                                    <Option value="Congo">Congo</Option>
                                    <Option value="Coréia do Sul">Coréia do Sul</Option>
                                    <Option value="Costa do Marfim">Costa do Marfim</Option>
                                    <Option value="Costa Rica">Costa Rica</Option>
                                    <Option value="Croácia">Croácia</Option>
                                    <Option value="Dinamarca">Dinamarca</Option>
                                    <Option value="Djibuti">Djibuti</Option>
                                    <Option value="Dominica">Dominica</Option>
                                    <Option value="EUA">EUA</Option>
                                    <Option value="Egito">Egito</Option>
                                    <Option value="El Salvador">El Salvador</Option>
                                    <Option value="Emirados Árabes">Emirados Árabes</Option>
                                    <Option value="Equador">Equador</Option>
                                    <Option value="Eritréia">Eritréia</Option>
                                    <Option value="Escócia">Escócia</Option>
                                    <Option value="Eslováquia">Eslováquia</Option>
                                    <Option value="Eslovênia">Eslovênia</Option>
                                    <Option value="Espanha">Espanha</Option>
                                    <Option value="Estônia">Estônia</Option>
                                    <Option value="Etiópia">Etiópia</Option>
                                    <Option value="Fiji">Fiji</Option>
                                    <Option value="Filipinas">Filipinas</Option>
                                    <Option value="Finlândia">Finlândia</Option>
                                    <Option value="França">França</Option>
                                    <Option value="Gabão">Gabão</Option>
                                    <Option value="Gâmbia">Gâmbia</Option>
                                    <Option value="Gana">Gana</Option>
                                    <Option value="Geórgia">Geórgia</Option>
                                    <Option value="Gibraltar">Gibraltar</Option>
                                    <Option value="Granada">Granada</Option>
                                    <Option value="Grécia">Grécia</Option>
                                    <Option value="Guadalupe">Guadalupe</Option>
                                    <Option value="Guam">Guam</Option>
                                    <Option value="Guatemala">Guatemala</Option>
                                    <Option value="Guiana">Guiana</Option>
                                    <Option value="Guiana Francesa">Guiana Francesa</Option>
                                    <Option value="Guiné-bissau">Guiné-bissau</Option>
                                    <Option value="Haiti">Haiti</Option>
                                    <Option value="Holanda">Holanda</Option>
                                    <Option value="Honduras">Honduras</Option>
                                    <Option value="Hong Kong">Hong Kong</Option>
                                    <Option value="Hungria">Hungria</Option>
                                    <Option value="Iêmen">Iêmen</Option>
                                    <Option value="Ilhas Cayman">Ilhas Cayman</Option>
                                    <Option value="Ilhas Cook">Ilhas Cook</Option>
                                    <Option value="Ilhas Curaçao">Ilhas Curaçao</Option>
                                    <Option value="Ilhas Marshall">Ilhas Marshall</Option>
                                    <Option value="Ilhas Turks & Caicos">Ilhas Turks & Caicos</Option>
                                    <Option value="Ilhas Virgens (brit.)">Ilhas Virgens (brit.)</Option>
                                    <Option value="Ilhas Virgens(amer.)">Ilhas Virgens(amer.)</Option>
                                    <Option value="Ilhas Wallis e Futuna">Ilhas Wallis e Futuna</Option>
                                    <Option value="Índia">Índia</Option>
                                    <Option value="Indonésia">Indonésia</Option>
                                    <Option value="Inglaterra">Inglaterra</Option>
                                    <Option value="Irlanda">Irlanda</Option>
                                    <Option value="Islândia">Islândia</Option>
                                    <Option value="Israel">Israel</Option>
                                    <Option value="Itália">Itália</Option>
                                    <Option value="Jamaica">Jamaica</Option>
                                    <Option value="Japão">Japão</Option>
                                    <Option value="Jordânia">Jordânia</Option>
                                    <Option value="Kuwait">Kuwait</Option>
                                    <Option value="Latvia">Latvia</Option>
                                    <Option value="Líbano">Líbano</Option>
                                    <Option value="Liechtenstein">Liechtenstein</Option>
                                    <Option value="Lituânia">Lituânia</Option>
                                    <Option value="Luxemburgo">Luxemburgo</Option>
                                    <Option value="Macau">Macau</Option>
                                    <Option value="Macedônia">Macedônia</Option>
                                    <Option value="Madagascar">Madagascar</Option>
                                    <Option value="Malásia">Malásia</Option>
                                    <Option value="Malaui">Malaui</Option>
                                    <Option value="Mali">Mali</Option>
                                    <Option value="Malta">Malta</Option>
                                    <Option value="Marrocos">Marrocos</Option>
                                    <Option value="Martinica">Martinica</Option>
                                    <Option value="Mauritânia">Mauritânia</Option>
                                    <Option value="Mauritius">Mauritius</Option>
                                    <Option value="México">México</Option>
                                    <Option value="Moldova">Moldova</Option>
                                    <Option value="Mônaco">Mônaco</Option>
                                    <Option value="Montserrat">Montserrat</Option>
                                    <Option value="Nepal">Nepal</Option>
                                    <Option value="Nicarágua">Nicarágua</Option>
                                    <Option value="Niger">Niger</Option>
                                    <Option value="Nigéria">Nigéria</Option>
                                    <Option value="Noruega">Noruega</Option>
                                    <Option value="Nova Caledônia">Nova Caledônia</Option>
                                    <Option value="Nova Zelândia">Nova Zelândia</Option>
                                    <Option value="Omã">Omã</Option>
                                    <Option value="Palau">Palau</Option>
                                    <Option value="Panamá">Panamá</Option>
                                    <Option value="Papua-nova Guiné">Papua-nova Guiné</Option>
                                    <Option value="Paquistão">Paquistão</Option>
                                    <Option value="Peru">Peru</Option>
                                    <Option value="Polinésia Francesa">Polinésia Francesa</Option>
                                    <Option value="Polônia">Polônia</Option>
                                    <Option value="Porto Rico">Porto Rico</Option>
                                    <Option value="Portugal">Portugal</Option>
                                    <Option value="Qatar">Qatar</Option>
                                    <Option value="Quênia">Quênia</Option>
                                    <Option value="Rep. Dominicana">Rep. Dominicana</Option>
                                    <Option value="Rep. Tcheca">Rep. Tcheca</Option>
                                    <Option value="Reunion">Reunion</Option>
                                    <Option value="Romênia">Romênia</Option>
                                    <Option value="Ruanda">Ruanda</Option>
                                    <Option value="Rússia">Rússia</Option>
                                    <Option value="Saipan">Saipan</Option>
                                    <Option value="Samoa Americana">Samoa Americana</Option>
                                    <Option value="Senegal">Senegal</Option>
                                    <Option value="Serra Leone">Serra Leone</Option>
                                    <Option value="Seychelles">Seychelles</Option>
                                    <Option value="Singapura">Singapura</Option>
                                    <Option value="Síria">Síria</Option>
                                    <Option value="Sri Lanka">Sri Lanka</Option>
                                    <Option value="St. Kitts & Nevis">St. Kitts & Nevis</Option>
                                    <Option value="St. Lúcia">St. Lúcia</Option>
                                    <Option value="St. Vincent">St. Vincent</Option>
                                    <Option value="Sudão">Sudão</Option>
                                    <Option value="Suécia">Suécia</Option>
                                    <Option value="Suiça">Suiça</Option>
                                    <Option value="Suriname">Suriname</Option>
                                    <Option value="Tailândia">Tailândia</Option>
                                    <Option value="Taiwan">Taiwan</Option>
                                    <Option value="Tanzânia">Tanzânia</Option>
                                    <Option value="Togo">Togo</Option>
                                    <Option value="Trinidad & Tobago">Trinidad & Tobago</Option>
                                    <Option value="Tunísia">Tunísia</Option>
                                    <Option value="Turquia">Turquia</Option>
                                    <Option value="Ucrânia">Ucrânia</Option>
                                    <Option value="Uganda">Uganda</Option>
                                    <Option value="Uruguai">Uruguai</Option>
                                    <Option value="Venezuela">Venezuela</Option>
                                    <Option value="Vietnã">Vietnã</Option>
                                    <Option value="Zaire">Zaire</Option>
                                    <Option value="Zâmbia">Zâmbia</Option>
                                    <Option value="Zimbábue">Zimbábue</Option>
                                </Select>
                            </Form.Item>
                            <button
                                className="botao-principal"
                                style={{ padding: "10px 30px" }}
                                onClick={this.atualizarDados}
                                disabled={iconLoading}
                            >
                                {iconLoading ? <Icon type="loading" /> : null}
                                Salvar
                            </button>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

const FormDadosGerais = Form.create({ name: "form-minha-conta" })(DadosGerais);

export default FormDadosGerais;
