import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Upload, notification, Icon } from "antd";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class VideoConfAguardar extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //VERIFICA SE O MODERADOR JA ESTÁ NA CHAMADA. CASO SIM DEIXA ENTRAR
    verificar = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/videoconferencia-aguardar",
            params: {
                token: this.props.match.params.token,
                entidadeId: localStorage.getItem("entidadeId")
            }
        })
            .then(response => {
                if (response.data) {
                    this.setState({
                        redirect: true,
                        redirectLink: `/${this.props.match.params.entidade}/videoconferencia/${response.data}/player-videoconferencia`
                    })
                }
                else
                    openNotificationWithIcon("warning", "Atenção", "Moderador ainda não iniciou a sessão! Aguarde um pouco.");
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Ocorreu um erro!");
                this.setState({ iconLoading: false });
            });
    };

    render() {
        const {
            redirect,
            redirectLink,
        } = this.state;

        if (redirect)
            return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Aguarde a entrada do moderador" />
                <div className="container container-tentar container-body">
                    <div className="content">
                        <button className="btn-tentar" onClick={this.verificar}>Tentar entrar</button>
                    </div>
                </div>
            </>
        );
    }
}
const FormVideoConfAguardar = Form.create({ name: "video-conf-aguardar" })(VideoConfAguardar);

export default FormVideoConfAguardar;
