import React, { Component } from "react";
import { Modal } from "antd";

class ModalWelcome extends Component {

    render() {
        return (
            <>
                <Modal
                    visible={this.props.visibleWelcome}
                    okText="Confirmar"
                    cancelText="Cancelar"
                    className="modal-welcome"
                    onOk={this.props.onCancel}
                    onCancel={this.props.onCancel}
                    maskClosable={false}
                    footer={null}
                >
                    <div className="bloco-info">
                        <div className="container-img">
                            <img src={this.props.imagem} alt="Imagem do curso" />
                        </div>
                        <div className="texto">
                            <span>Bem vindo ao curso {this.props.nome}!</span>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ModalWelcome;
