import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Radio, Checkbox, Row, notification, Upload, Tooltip, Icon, Collapse, Input } from "antd";
import { GlobalContext } from "../../GlobalState";
import { HashLink as HashLink } from 'react-router-hash-link';
import axios from "axios";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ConteudoAvaliacao extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        aulaid: 0,
        nrQuestoes: 0,
        questaoAtual: 0,
        questao: {},
        resposta: 0,
        resposta_checkbox: [],
        resposta_livre: "",
        fileList: [],
        fim: false,
        confirmacao: false,
        //ESTADOS
        apresentacao: true,
        iniciado: false,
        //INFO
        modulo: "",
        aula: "",
        objetivos: "",
        total_questoes: 0,
        baralhar_questoes: false,
        existeResolucao: false,
        //resolucao: false,
        estado: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        redirectLinkResolucao: "",
        //redirectLinkRepetir: "",
        //
        numPages: null,
        pageNumber: 1,
        scale: 1.5,
        rotate: 0,
        //LOADING
        iconLoadingProximo: false,
        iconLoadingAnterior: false,
        //LOADING
        loading: true,
        //FASES
        conclusao: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: localStorage.getItem("codigo_aula"),
            redirectLink: `/gerir-curso/${this.props.match.params.curso
                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                .toLowerCase()}/${this.props.match.params.aula
                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                    .toLowerCase()}/player-avaliacao-resumo`,
            redirectLinkResolucao: `/gerir-curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${this.props.match.params.aula.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/player-avaliacao-resolucao`,
        });

        this.carregarQuestionarioInfo();
    }

    //DEVOLVE O TOTAL DE PERGUNTAS
    carregarQuestionarioInfo = () => {
        axios({
            method: "get",
            url: "/api/gestao-questionario/carregar-questionario-info",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        })
            .then(response => {
                localStorage.setItem("questionario_" + localStorage.getItem("codigo_aula"), JSON.stringify({ modulo: response.data.modulo, aula: response.data.aula }));

                this.setState({
                    modulo: response.data.modulo,
                    aula: response.data.aula,
                    objetivos: response.data.objetivos,
                    total_questoes: response.data.totalQuestoes,
                    existeResolucao: response.data.existeResolucao,
                    loading: false
                });
                this.props.carregarInfo(response.data.aula, response.data.objetivos, response.data.totalQuestoes, moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"));
            })
            .catch(error => {
                //if (error.response.data === "SEM_PERMISSAO")
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/user"
                //    });
                //else
                    if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.props.marcarComoNaoDisponivel();
            });
    };

    iniciarQuestionario = event => {
        event.preventDefault();

        this.carregaPergunta();
        this.setState({
            apresentacao: false,
            iniciado: true
        });
    };

    carregaPergunta = () => {
        if (this.state.questaoAtual !== this.state.total_questoes) {
            this.setState({
                iconLoadingProximo: true
            });

            axios({
                method: "get",
                url: "/api/gestao-questionario/carrega-pergunta",
                params: {
                    aulaid: localStorage.getItem("codigo_aula"),
                    pergunta: this.state.questaoAtual
                }
            })
                .then(response => {
                    this.setState({
                        questao: response.data,
                        questaoAtual: this.state.questaoAtual + 1,
                        iconLoadingProximo: false,
                        resposta: 0,
                        resposta_checkbox: [],
                        resposta_livre: "",
                        fileList: []
                    });
                })
                .catch(() => { });
        }
        else {
            //this.setState({
            //    iniciado: false,
            //    conclusao: true
            //});
            this.concluir();
        }
    };

    carregaPerguntaAnterior = () => {
        this.setState({
            iconLoadingAnterior: true
        });

        axios({
            method: "get",
            url: "/api/gestao-questionario/carrega-pergunta",
            params: {
                aulaid: localStorage.getItem("codigo_aula"),
                pergunta: this.state.questaoAtual - 2
            }
        })
            .then(response => {
                this.setState({
                    questao: response.data,
                    questaoAtual: this.state.questaoAtual - 1,
                    iconLoadingAnterior: false,
                    resposta: 0,
                    resposta_checkbox: [],
                    resposta_livre: "",
                    fileList: []
                });
            })
            .catch(() => { });
    };

    confirmacaoAnterior = event => {
        event.preventDefault();
        this.setState({
            iconLoadingAnterior: true
        });

        axios({
            method: "get",
            url: "/api/gestao-questionario/carrega-pergunta",
            params: {
                aulaid: localStorage.getItem("codigo_aula"),
                pergunta: this.state.questaoAtual - 1
            }
        })
            .then(response => {
                this.setState({
                    questao: response.data,
                    questaoAtual: this.state.questaoAtual,
                    iniciado: true,
                    conclusao: false,
                    iconLoadingAnterior: false
                });
            })
            .catch(() => { });
    };

    concluir = () => {
        this.setState({
            redirect: true
        });
    }

    validarFormatos = (formato, formatos_aceites) => {
        var valido = false

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato === formatos_aceites[i]) valido = true
        }

        return valido
    }

    //LEITOR DE PDF
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    scaleDown = () => {
        if (this.state.scale >= 0.8) {
            this.setState({ scale: this.state.scale - 0.3 });
        }
    };

    scaleUp = () => {
        if (this.state.scale <= 1.8) {
            this.setState({ scale: this.state.scale + 0.3 });
        }
    };

    render() {
        const { Panel } = Collapse;
        const Dragger = Upload.Dragger;

        const {
            questaoAtual,
            questao,
            existeResolucao,
            //REDIRECT
            redirect,
            redirectLink,
            redirectLinkResolucao,
            resposta_checkbox,
            resposta,
            resposta_livre,
            fileList,
            //INFO
            modulo,
            aula,
            objetivos,
            total_questoes,
            //LOADING
            iconLoadingProximo,
            iconLoadingAnterior,
            //LOADING
            loading,
            //FASES
            conclusao
        } = this.state;
        const RadioGroup = Radio.Group;

        const radioStyle = {
            display: "block",
            height: "30px",
            lineHeight: "30px"
        };

        const montarTitulo = (tipo_pergunta) => {
            var titulo = "";

            switch (tipo_pergunta) {
                case "Escolha única":
                    titulo = "Opção";
                    break;
                case "Escolha múltipla":
                    titulo = "Opções";
                    break;
                default:
                    titulo = "Respostas";
                    break;
            }

            return titulo;
        }

        const montar_perguntar = questao => {
            if (questao.tipo_pergunta === "Escolha única") {
                return (
                    <RadioGroup value={resposta} disabled>
                        {questao.opcoes.map((opcao, index) => (
                            <Radio key={"opcao" + index} style={radioStyle} value={opcao.id}>
                                {opcao.verdadeiro ?
                                    <i class='fa fa-check' style={{ marginRight: "10px", color: "#16817a" }}></i>
                                    : null}
                                {opcao.texto}
                            </Radio>
                        ))}
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Escolha múltipla") {
                return (
                    <Checkbox.Group style={{ width: "100%" }} defaultValue={resposta_checkbox} disabled>
                        {questao.opcoes.map((opcao, index) => (
                            <Row key={"opcao" + index}>
                                <Checkbox value={opcao.id}>
                                    {opcao.verdadeiro ?
                                        <i class='fa fa-check' style={{ marginRight: "10px", color: "#16817a" }}></i>
                                        : null}
                                    {opcao.texto}
                                </Checkbox>
                            </Row>
                        ))}
                    </Checkbox.Group>
                );
            } else if (questao.tipo_pergunta === "Escala 1 a 5") {
                return (
                    <RadioGroup className="escala" value={resposta} disabled>
                        <span className="minimo">1</span>
                        <Radio style={radioStyle} className="escala" value="1"></Radio>
                        <Radio style={radioStyle} className="escala" value="2"></Radio>
                        <Radio style={radioStyle} className="escala" value="3"></Radio>
                        <Radio style={radioStyle} className="escala" value="4"></Radio>
                        <Radio style={radioStyle} className="escala" value="5"></Radio>
                        <span className="maximo">5</span>
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Escala 1 a 10") {
                return (
                    <RadioGroup className="escala" value={resposta} disabled>
                        <span className="minimo">1</span>
                        <Radio style={radioStyle} className="escala" value="1"></Radio>
                        <Radio style={radioStyle} className="escala" value="2"></Radio>
                        <Radio style={radioStyle} className="escala" value="3"></Radio>
                        <Radio style={radioStyle} className="escala" value="4"></Radio>
                        <Radio style={radioStyle} className="escala" value="5"></Radio>
                        <Radio style={radioStyle} className="escala" value="6"></Radio>
                        <Radio style={radioStyle} className="escala" value="7"></Radio>
                        <Radio style={radioStyle} className="escala" value="8"></Radio>
                        <Radio style={radioStyle} className="escala" value="9"></Radio>
                        <Radio style={radioStyle} className="escala" value="10"></Radio>
                        <span className="maximo">10</span>
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Resposta livre") {
                return (
                    <>
                        <TextArea value={resposta_livre} style={{ height: "100%" }} rows={5} disabled />
                        <div className="anexos" style={{ marginTop: 20 }}>
                            <Dragger {...props} disabled>
                                <p className="ant-upload-drag-icon">
                                    <i className="fas fa-upload" />
                                </p>
                                <p className="ant-upload-text">
                                    Adicionar anexo{" "}
                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </p>
                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                            </Dragger>
                        </div>
                    </>
                );
            }
        };

        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("ficheiro");
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            defaultFileList: this.state.fileList,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    fileList: [file]
                }));

                return false;
            },
            fileList
        };

        if (this.state.redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
            <div className="container">
                <div className="bloco-questionario bloco-questionarioV2" style={{ background: "transparent", boxShadow: "none" }}>
                    <div className="bloco-info">
                        {this.state.apresentacao && (
                                <div className="bloco-presencial">
                                    <div className="bloco-download-link">
                                        <div className="bloco-info-tarefa">
                                            <span className="icon-tarefa">
                                                <img src={require("../../images/icons/avaliacao.png")} />
                                                </span>
                                                <span className="titulo-tarefa">{aula}<br /><span className="numero-questoes">{total_questoes} {total_questoes > 1 ? "Questões" : "Questão"}</span></span>
                                        </div>
                                        <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                        <Link className="botao-principal" to="#" onClick={e => this.iniciarQuestionario(e)}>
                                            Iniciar
                                            </Link>
                                    </div>
                                </div>
                        )}
                        {this.state.iniciado && (
                            <div className="prova">
                                <div className="bloco-questao">
                                    <div className="bloco-enunciado">
                                        <div className="num-pergunta" style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>
                                                Questão {questaoAtual} - {total_questoes}
                                            </span>
                                            {questao.pontuacao ?
                                                <span>(Pontos: {questao.pontuacao})</span> : null}
                                        </div>
                                        <p
                                            className="texto-questao"
                                            dangerouslySetInnerHTML={{
                                                __html: questao.pergunta ? questao.pergunta.replace(/(?:\r\n|\r|\n)/g, "<br>") : ""
                                            }}
                                        ></p>
                                        {questao.ficheiro_pergunta && (
                                            <div className="primeiro-bloco-links">
                                                {questao.ficheiro_nome_pergunta.split(".").pop() === "pdf" ?
                                                    <Collapse
                                                        defaultActiveKey={['1']}
                                                        expandIcon={({ isActive }) =>
                                                            <div className="bloco-links-ficheiro">
                                                                <span className="bloco-links-img-container">
                                                                    <img src={require("../../images/icons/pdf.png")} />
                                                                </span>
                                                                <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                            </div>
                                                        }
                                                    >
                                                        <Panel key="1">
                                                            <Document file={questao.ficheiro_pergunta} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                                <Page
                                                                    pageNumber={this.state.pageNumber}
                                                                    scale={this.state.scale}
                                                                    rotate={this.state.rotate}
                                                                />
                                                            </Document>
                                                            <div className="controlos-pdf">
                                                                <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber > this.state.numPages ? prevState.pageNumber - 1 : 1) }))}>
                                                                    <Icon type="caret-left" />
                                                                </button>
                                                                <span>
                                                                    {this.state.pageNumber}/{this.state.numPages}
                                                                </span>
                                                                <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber < this.state.numPages ? prevState.pageNumber + 1 : this.state.numPages) }))}>
                                                                    <Icon type="caret-right" />
                                                                </button>
                                                                <button onClick={this.scaleUp}>
                                                                    <Icon type="zoom-in" />
                                                                </button>
                                                                <button onClick={this.scaleDown}>
                                                                    <Icon type="zoom-out" />
                                                                </button>
                                                                <button onClick={() => this.setState({ rotate: this.state.rotate === 180 ? 180 : this.state.rotate + 90 })}>
                                                                    <Icon type="reload" />
                                                                </button>
                                                                <button onClick={() => this.setState({ rotate: this.state.rotate === -180 ? -180 : this.state.rotate - 90 })}>
                                                                    <Icon type="reload" style={{ transform: "scaleX(-1) " }} />
                                                                </button>
                                                            </div>
                                                        </Panel>
                                                    </Collapse>
                                                    :
                                                    <Collapse
                                                        defaultActiveKey={['1']}
                                                        expandIcon={({ isActive }) =>
                                                            <div className="bloco-links-ficheiro">
                                                                <span className="bloco-links-img-container">
                                                                    <img src={require("../../images/icons/images.png")} />
                                                                </span>
                                                                <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                            </div>
                                                        }
                                                    >
                                                        <Panel key="1">
                                                            <img src={questao.ficheiro_pergunta} />
                                                        </Panel>
                                                    </Collapse>
                                                }
                                            </div>
                                        )}
                                    </div>
                                    <div className="bloco-resposta">
                                        <h4 className="bloco-titulo">{montarTitulo(questao.tipo_pergunta)}</h4>
                                        <div className="respostas">{montar_perguntar(questao)}</div>
                                    </div>
                                    <div className="bloco-resolucao">
                                        <div className="bloco-amarelo">
                                            {questao.texto_resolucao || questao.ficheiro_resolucao ?
                                                <>
                                                    <h3 className="bloco-titulo">Solução</h3>
                                                    {questao.texto_resolucao && (
                                                        <div className="bloco-resolucao-texto">
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: questao.texto_resolucao
                                                                        ? questao.texto_resolucao.replace(/(?:\r\n|\r|\n)/g, "<br>")
                                                                        : ""
                                                                }}
                                                            ></span>
                                                        </div>
                                                    )}
                                                    {questao.ficheiro_resolucao && (
                                                        <div className="bloco-links">
                                                            {questao.ficheiro_nome_resolucao.split(".").pop() === "pdf" ?
                                                                <Collapse
                                                                    defaultActiveKey={['1']}
                                                                    expandIcon={({ isActive }) =>
                                                                        <div className="bloco-links-ficheiro">
                                                                            <span className="bloco-links-img-container">
                                                                                <img src={require("../../images/icons/pdf.png")} />
                                                                            </span>
                                                                            <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <Panel key="1">
                                                                        <Document file={questao.ficheiro_resolucao} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                                            <Page
                                                                                pageNumber={this.state.pageNumber}
                                                                                scale={this.state.scale}
                                                                                rotate={this.state.rotate}
                                                                            />
                                                                        </Document>
                                                                        <div className="controlos-pdf">
                                                                            <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber > this.state.numPages ? prevState.pageNumber - 1 : 1) }))}>
                                                                                <Icon type="caret-left" />
                                                                            </button>
                                                                            <span>
                                                                                {this.state.pageNumber}/{this.state.numPages}
                                                                            </span>
                                                                            <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber < this.state.numPages ? prevState.pageNumber + 1 : this.state.numPages) }))}>
                                                                                <Icon type="caret-right" />
                                                                            </button>
                                                                            <button onClick={this.scaleUp}>
                                                                                <Icon type="zoom-in" />
                                                                            </button>
                                                                            <button onClick={this.scaleDown}>
                                                                                <Icon type="zoom-out" />
                                                                            </button>
                                                                            <button onClick={() => this.setState({ rotate: this.state.rotate === 180 ? 180 : this.state.rotate + 90 })}>
                                                                                <Icon type="reload" />
                                                                            </button>
                                                                            <button onClick={() => this.setState({ rotate: this.state.rotate === -180 ? -180 : this.state.rotate - 90 })}>
                                                                                <Icon type="reload" style={{ transform: "scaleX(-1) " }} />
                                                                            </button>
                                                                        </div>
                                                                    </Panel>
                                                                </Collapse>
                                                                :
                                                                <Collapse
                                                                    defaultActiveKey={['1']}
                                                                    expandIcon={({ isActive }) =>
                                                                        <div className="bloco-links-ficheiro">
                                                                            <span className="bloco-links-img-container">
                                                                                <img src={require("../../images/icons/images.png")} />
                                                                            </span>
                                                                            <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <Panel key="1">
                                                                        <img src={questao.ficheiro_resolucao} />
                                                                    </Panel>
                                                                </Collapse>
                                                            }
                                                        </div>
                                                    )}
                                                </>
                                                :
                                                <>
                                                    <h4 className="bloco-titulo">Solução</h4>
                                                    <div className="bloco-resolucao-texto">
                                                        <span>Sem Solução</span>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <p className="bloco-resolucao-informacao">Nota: A solução não é apresentada ao aluno, na avaliação.</p>
                                    </div>
                                    <div className="bloco-resposta">
                                        <div className="controlos-resposta">
                                            {questaoAtual > 1 && (
                                                <>
                                                    <HashLink
                                                        to="#top"
                                                        className="botao-secundario"
                                                        disabled={iconLoadingProximo || iconLoadingAnterior}
                                                        onClick={e => this.carregaPerguntaAnterior(e)}
                                                    >
                                                        {iconLoadingAnterior ? <Icon type="loading" /> : null}
                                                            Anterior
                                                    </HashLink>
                                                </>
                                            )}
                                            <HashLink
                                                to="#top"
                                                className="botao-principal"
                                                disabled={iconLoadingProximo || iconLoadingAnterior}
                                                onClick={e => this.carregaPergunta(e)}
                                            >
                                                {iconLoadingProximo ? <Icon type="loading" /> : null}
                                                    Próxima
                                                    </HashLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/*{conclusao &&*/}
                        {/*    <div*/}
                        {/*        className="confirmacao"*/}
                        {/*        style={{ background: "white", boxShadow: "0 4px 6px #eee", height: "100vh", width: "100%" }}*/}
                        {/*    >*/}
                        {/*        <div className="bloco-confirmacao">*/}
                        {/*            <h2>Avaliação concluída!</h2>*/}
                        {/*            <div className="controlos-resposta">*/}
                        {/*                <button*/}
                        {/*                    className="botao-secundario"*/}
                        {/*                    disabled={iconLoadingAnterior}*/}
                        {/*                    onClick={e => this.confirmacaoAnterior(e)}*/}
                        {/*                >*/}
                        {/*                    {iconLoadingAnterior ? <Icon type="loading" /> : null}*/}
                        {/*                        Anterior*/}
                        {/*                    </button>*/}
                        {/*                <button className="botao-principal" disabled={iconLoadingAnterior} onClick={this.concluir}>*/}
                        {/*                    Submeter*/}
                        {/*                    </button>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>}*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ConteudoAvaliacao);
