import React, { useContext } from "react"
import { Link, withRouter } from "react-router-dom";
import noimage from "../../images/noimage.png";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";

const Participante = ({ code, foto, nome, numero, professor, coordenador, ultimaEstado, ultimoAcesso, perfil, match }) => {
    const globalContext = useContext(GlobalContext);

    const montarTitulo = (professor, coordenador) => {
        if (professor && coordenador)
            return <span className="perfil coordenador">
                Coord. / Prof.
            </span>
        else if (coordenador)
            return <span className="perfil coordenador">
                Coordenador
            </span>
        else if (professor)
            return <span className="perfil professor">
                Professor
            </span>
        else return;
    }

    const ultimoLogin = (ultimoAcesso) => {
        if (!ultimoAcesso || !moment(ultimoAcesso).isAfter(moment('0001-01-01'))) {
            return "Nunca";
        }

        const timeDiff = moment().diff(moment(ultimoAcesso));

        switch (true) {
            case timeDiff >= 604800000: // maior que 7 dias
                return moment(ultimoAcesso).format("DD/MM/YYYY");
            case timeDiff >= 86400000: // maior que 1 dia
                return `${Math.floor(timeDiff / 86400000)} dias`;
            case timeDiff >= 3600000: // maior que 1 hora
                return `${Math.floor(timeDiff / 3600000)} horas`;
            case timeDiff >= 60000: // maior que 1 minuto
                return `${Math.floor(timeDiff / 60000)} minutos`;
            default:
                return `${timeDiff / 1000} segundos`;
        }
    }

    const montarEstado = () => {
        const estado = ultimaEstado;
        let estilo = "frequentar"

        if (estado.titulo === "Transferido")
            estilo = "transferido"

        if (estado.titulo === "Anulado")
            estilo = "anulado"

        return <span className={`estado ${estilo}`} title={estado.titulo} >{estado.titulo}</span>;
    }

    var permissao = globalContext.perfil?.nome === "Suporte" || globalContext.perfil?.nome === "Gestor";

    return (
        <Link to={permissao ? `/gestao-utilizadores-detalhe/${code}` : "#"} disabled={!permissao} onClick={() => { globalContext.atualizarState({ cardPerfilSelecionado: perfil }); localStorage.setItem("link_voltar", match.url) }}>
            <div className="cartao-participante">
                {montarTitulo(professor, coordenador)}
                <div className="container-img">
                    <img src={foto ? foto : noimage} />
                </div>
                <div className="info">
                    <span className="nome">{nome.split(" ").length > 1 ? `${nome.split(" ")[0]} ${nome.split(" ").pop()}` : nome}</span>
                    <span className="numero">{numero}</span>
                </div>
                {ultimaEstado && (
                    <div className="bloco-estado">{montarEstado()}</div>)}
                {/*<span className="ultimo-acesso" title="Último acesso ao curso">{ultimoLogin(ultimoAcesso)}</span>*/}
            </div>
        </Link>
    )
}

export default withRouter(Participante)
