import React, { useState } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Input, Select, Checkbox, Radio } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import noimage from "../../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerAgrupar = ({ ids, visible, onClose, guardarAgrupar, form }) => {
    const [nome, setNome] = useState("");
    const [loading, setLoading] = useState(false);

    const guardar = (event) => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var item = new FormData();
                item.append("nome", nome);
                item.append("ids", JSON.stringify(ids));

                axios({
                    method: "post",
                    url: "/api/gestao-turmas/agrupar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Turmas agrupadas!");
                        guardarAgrupar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possivel agrupar as turmas");
                        setLoading(false);
                    });
            }
        })
    }

    const afterVisibleChange = aberto => {
        if (aberto) {
            setNome("");
            setLoading(false);
        }
    }

    const { getFieldDecorator } = form;

    return (
        <Drawer
            //title="Agrupar"
            placement="right"
            className="drawer-envio-mensagem-inscricao"
            maskClosable={false}
            closable={true}
            onClose={onClose}
            visible={visible}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-nova-pergunta">
                    <div className="bloco">
                        <Form.Item label="Nome">
                            {getFieldDecorator("nome", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: nome
                            })(<Input name="nome" onChange={(e) => setNome(e.target.value)} />)}
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button
                    className="botao-principal"
                    onClick={guardar}
                    disabled={loading}
                >
                    {loading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
        </Drawer>
    );
}

const FormDrawerAgrupar = Form.create({ name: "form-agrupar" })(DrawerAgrupar);

export default withRouter(FormDrawerAgrupar);
