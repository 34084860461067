import React, { Component } from "react";

class Conteudo extends Component {
    render() {
        return (
            <div className="item">
                <span className="titulo">{this.props.dados.pergunta}</span>
                <span className="descritivo" dangerouslySetInnerHTML={{ __html: this.props.dados.resposta }}>
                </span>
            </div>
        );
    }
}

export default Conteudo;
