import React, { useState, useEffect } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Icon, Modal } from 'antd';
import axios from "axios";

const confirm = Modal.confirm;

const Tabela = ({ match }) => {
    const [outros, setOutros] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");

    const cols = [
        {
            title: "Data",
            dataIndex: "data"
        },
        {
            title: "Tipo",
            dataIndex: "tipo"
        },
        {
            title: "Descrição",
            dataIndex: "descricao"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 50
        }
    ];

    const carregar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-outros-utilizador"
        })
            .then(response => {
                const lista = response.data.map(item => {
                    return {
                        key: item.id,
                        data: item.data,
                        tipo: item.titulo,
                        descricao: item.descricao
                    }
                })

                setOutros(lista);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    }

    useEffect(() => {
        carregar();
    }, [])

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <>
            <Table
                className="tabela-documentos"
                columns={cols}
                pagination={false}
                dataSource={outros}
                loading={{
                    spinning: loading,
                    indicator: (
                        <div className="loading-data-table">
                            <div className="loading" />
                        </div>
                    )
                }}
            />
        </>
    );
}

export default withRouter(Tabela);