import React, {useState} from "react";
import Table from 'rc-table';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
// import Table from "antd"
import {FaEllipsis} from "react-icons/fa6";
import "../../../scss/_dashboard.scss"
import Modal from "react-modal";
import {AiOutlineClose} from "react-icons/ai";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import {useMediaQuery} from "react-responsive";
import {Icon} from "antd";

export default function Tabela({data, columns, titulo }) {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8; // Número de linhas por página
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    
    const data2 = data.slice(0, 5);
    
    const processedColumns = columns.map(col => {
        // col.width='250px';
        return col;
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: isTabletOrMobile ? ('400px'):('1100px'),
            
            borderRadius: '25px',
            overflow: 'hidden',
            border: 0,
            padding: 0,
            zIndex:21
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }
    };

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Pagina os dados
    const paginatedData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    
    const setaDireita = <div className="seta"> <FaChevronRight style={{color:"#b8b8b8"}}/></div>
    const setaEsquerda = <div className="seta"> <FaChevronLeft style={{color:"#b8b8b8"}}/></div>

    return (
        <>
            <div className={`custom-table sobra-tabela`} >
                <div className="topo-tabela">
                    <span className="table-title">{titulo}</span>
                    <div className="botao-tabela" onClick={openModal}>
                        <FaEllipsis/>
                    </div>
                </div>
                <Table className="custom-table" columns={columns} data={data2} emptyText={() =><div className="carregar"> <div><Icon type="loading"/> <span>A carregar</span> </div> </div>}/>
            </div>
            <Modal
                isOpen={modalIsOpen}
                //onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="custom-table larguraModal">
                    <div className="topo-tabela">
                        <span className="table-title">{titulo}</span>
                        <div className="botao-tabela" onClick={closeModal}>
                            <AiOutlineClose/>
                        </div>
                    </div>
                    <Table columns={processedColumns} data={paginatedData} pagination={false} className="rc-table1" />
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={data.length}
                        onChange={handlePageChange}
                        className="pagination"
                        prevIcon={setaEsquerda}
                        nextIcon={setaDireita}
                    />
                </div>
            </Modal>
        </>


    )
}
