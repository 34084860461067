import React, { useState, useEffect } from "react";
import { Drawer, Form, DatePicker, TimePicker, Select } from "antd";

import moment from "moment";
import axios from "axios";

const { Option } = Select;

const DrawerEmissaoAtaClasse = ({ visible, form, onClose, onConfirm }) => {
    const [periodos, setPeriodos] = useState([]);
    const [periodo, setPeriodo] = useState(undefined);
    const [dataEmissao, setDataEmissao] = useState(moment());
    const [horaEmissao, setHoraEmissao] = useState(moment());

    const { getFieldDecorator } = form;

    const handleChangeDataEmissao = (date) => {
        setDataEmissao(date);
    };

    const carregarListaPeriodos = () => {
        axios({
            method: "get",
            url: "/api/aulas-exportar/carregar-periodos-calendario",
            params: {
                id: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                setPeriodos(response.data)
            })
            .catch(() => { });
    };

    const afterVisibleChange = visible => {
        if (!visible) {
            form.resetFields();
            setPeriodo(undefined);
            setDataEmissao(moment());
            setHoraEmissao(moment());
            
        }
    }

    const visualizar = (periodo, dataEmissao, horaEmissao) => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                onConfirm(periodo, dataEmissao, horaEmissao)
            }
        })
    }

    useEffect(() => {
        carregarListaPeriodos();
    }, [])

    return (
        <>
            <Drawer
                className="drawer-inscrever"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-drawer-associar">
                    <Form className="form-emitir-declaracao">
                        <Form.Item label="Período">
                            {getFieldDecorator("periodo", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: periodo
                            })(
                                <Select
                                    placeholder="Selecionar Opção"
                                    onChange={periodo => setPeriodo(periodo)}
                                    className="custom-select ant-select-selection--single"
                                >
                                    {periodos.map(periodo => (
                                        <Option key={periodo.id} value={periodo.id}>
                                            {periodo.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Data de emissão">
                            {getFieldDecorator("dataEmissao", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: dataEmissao
                            })(
                                <DatePicker
                                    name="dataEmissao"
                                    format="DD-MM-YYYY"
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    onChange={handleChangeDataEmissao}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Hora">
                            {getFieldDecorator("horaEmissao", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    }
                                ],
                                initialValue: horaEmissao
                            })(
                                <TimePicker
                                    className="input-25"
                                    name="horaEmissao"
                                    defaultOpenValue={moment("00:00", "HH:mm")}
                                    format="HH:mm"
                                    onChange={(hora) => setHoraEmissao(hora)}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                    </button>
                    <button className="botao-principal" disabled={!dataEmissao} type="primary" onClick={() => visualizar(periodo, dataEmissao, horaEmissao)}>
                        Visualizar
                    </button>
                </div>
            </Drawer>
        </>
    );
}

const FormDrawerEmissaoAtaClasse = Form.create({ name: "drawer-ata" })(DrawerEmissaoAtaClasse);

export default FormDrawerEmissaoAtaClasse;
