import React from "react";
import { Icon, Modal } from "antd";

const GerarDocumento = ({ ficheiroExportar, visibleExportar, btnDescarregar, onClose }) => {
    const extensao = ficheiroExportar.split(".").pop();

    let informacao = (
        <>
            <Icon type="loading" />
            <p className="texto">A gerar documento...</p>
        </>
    );

    if (!btnDescarregar) {
        informacao = (
            <>
                <Icon type="check-circle" style={{ color: "#84bd00" }} />
                <p className="texto">Dados exportados com sucesso!</p>
                <p className="ficheiro-extensao">.{extensao}</p>
            </>
        );
    }

    return (
        <Modal
            visible={visibleExportar}
            okText={
                <a href={ficheiroExportar} download onClick={onClose}>
                    Descarregar
                </a>
            }
            onCancel={onClose}
            cancelText="Cancelar"
            okButtonProps={{ disabled: btnDescarregar }}
            maskClosable={false}
            className="exportar-csv"
        >
            <div className="exportar-csv-bloco">
                {informacao}
            </div>
        </Modal>
    )
}

export default GerarDocumento;