import React, { Component } from "react";
import { Link, Redirect, matchPath, withRouter } from "react-router-dom";
import { Rate, notification, Icon, Skeleton, Modal } from "antd";
import { GeralContext } from "./ContextProvider";
import { GlobalContext } from "../GlobalState";
import noimage from "../images/noimage.png";
import Avatar2 from "../images/Avatar2.png";
import axios from "axios";
import moment from "moment";
import LazyLoad from "react-lazy-load";
import Pontos from "./Pontos";
import AdicionarCarrinho from "./_Aux/AdicionarCarrinho";
import AdicionarSubscricao from "./_Aux/AdicionarSubscricao";
import ModalWelcome from "./_Aux/ModalWelcome";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Header extends Component {
    static contextType = GeralContext;
    state = {
        //DADOS
        cursoId: 0,
        imagem: "",
        codigo: "",
        nome: "",
        edicao: "",
        ano: "",
        turma: "",
        objectivo: "",
        avaliacao: "",
        totalAvaliacao: "",
        coordenador: "",
        dt_curso: "",
        linguas: [],
        linguasLegendas: [],
        formacao: "",
        progress: 0,
        classificacao: "",
        concluido: "",
        inscricao: false,
        aula: "",
        retomar: "",
        fechado: false,
        escola: "",
        favorito: false,
        precedencia: 0,
        precedenciaNome: "",
        precedenciaConcluida: false,
        obrigatoria: false,
        habilitar_gamificacao: false,
        //ESTADOS
        inscrito: false,
        preco: "",
        //DRAWER
        visible: false,
        loading: true,
        //INSCRICAO
        inscrito: false,
        pagamentoPendente: false,
        tipologia: "",
        subscricao: false,
        subscricaoAtiva: false,
        loaded: false,
        //WELCOME
        visibleWelcome: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoId
        });
        this.carregarInfo(cursoId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.curso !== prevProps.match.params.curso) {
            var cursoId = localStorage.getItem("codigo_curso");

            this.setState({
                cursoId
            });
            this.carregarInfo(cursoId);
        }
    }

    //CARREGA A INFORMAÇÃO DO CURSO
    carregarInfo = cursoid => {
        axios({
            method: "get",
            url: "/api/curso/carregar-header-curso",
            params: {
                cursoid: cursoid
            }
        })
            .then(response => {
                this.context.atualizar({
                    fechado: response.data.fechado,
                    inscrito: response.data.inscrito,
                    tipo_inscricao: response.data.tipo_inscricao,
                    inscricao: response.data.inscricao,
                    preco: response.data.preco,
                    subscricao: response.data.subscricao,
                    subscricaoAtiva: response.data.subscricaoAtiva,
                    subcursos: response.data.subcursos,
                    precedenciaPorConcluir: (response.data.precedencia && response.data.obrigatoria && !response.data.precedenciaConcluida),
                    habilitar_gamificacao: response.data.habilitar_gamificacao,
                    esconderListaParticipantes: response.data.esconderListaParticipantes,
                    cursoIniciado: response.data.cursoIniciado,
                    diarioCurso: response.data.diario
                });

                this.setState({
                    imagem: response.data.imagem,
                    codigo: response.data.codigo,
                    nome: response.data.nome,
                    edicao: response.data.edicao,
                    ano: response.data.ano,
                    turma: response.data.turma,
                    objectivo: response.data.objectivo,
                    avaliacao: response.data.mediaAvaliacoes,
                    totalAvaliacao: response.data.totalAvaliacoes,
                    favorito: response.data.favorito,
                    fechado: response.data.fechado,
                    precedencia: response.data.precedencia,
                    precedenciaNome: response.data.precedenciaNome,
                    precedenciaConcluida: response.data.precedenciaConcluida,
                    obrigatoria: response.data.obrigatoria,
                    escola: response.data.escola,
                    habilitar_gamificacao: response.data.habilitar_gamificacao,
                    //ESTADOS
                    inscrito: response.data.inscrito,
                    preco: response.data.preco,
                    loading: false,
                    pagamentoPendente: response.data.pagamentoPendente,
                    tipologia: response.data.tipologia,
                    subscricao: response.data.subscricao,
                    subscricaoAtiva: response.data.subscricaoAtiva
                }, () => {
                    if (localStorage.getItem("welcome") === "true" || !response.data.primeiraVez && response.data.tipoInscricao === "MANUAL") {
                        localStorage.removeItem("welcome")
                        this.setState({
                            visibleWelcome: true
                        })
                    }
                });

                localStorage.setItem("curso_fechado", response.data.fechado);
                localStorage.setItem("habilitar_competencias", response.data.habilitar_competencias);
                localStorage.setItem("habilitar_gamificacao", response.data.habilitar_gamificacao);
                localStorage.setItem("nome_curso", response.data.nome);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA SE O CURSO É OU NÃO FAVORITO
    favorito = cursoid => {
        axios({
            method: "get",
            url: "/api/favoritos/favorito",
            params: {
                cursoid
            }
        })
            .then(response => {
                this.setState({
                    favorito: response.data
                });
            })
            .catch(() => { });
    };

    //MARCA O CURSO COMO FAVORITO
    marcarFavorito = () => {
        axios({
            method: "post",
            url: "/api/favoritos/marcar-favorito",
            params: {
                cursoid: this.state.cursoId
            }
        })
            .then(() => {
                this.setState({
                    favorito: !this.state.favorito
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível marcar este curso como favorito!");
            });
    };

    retomarBotao = tem_comandos => {
        if (this.context.inscricao && !this.context.inscrito)
            return (
                <Link to="#" className="botao-principal" onClick={this.openDrawerInscricao}>
                    Inscrever
                </Link>
            );
    };

    openDrawerInscricao = () => {
        this.setState({
            visible: true
        });
    };

    onClose = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        const {
            //DADOS
            cursoId,
            codigo,
            imagem,
            nome,
            edicao,
            ano,
            turma,
            objectivo,
            avaliacao,
            totalAvaliacao,
            fechado,
            escola,
            precedencia,
            precedenciaNome,
            habilitar_gamificacao,
            obrigatoria,
            //ESTADOS
            inscrito,
            pagamentoPendente,
            tipologia,
            subscricao,
            subscricaoAtiva,
            preco,
            //REDIRECT
            redirect,
            redirectLink,
            loaded,
            //WELCOME
            visibleWelcome
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const montarEstado = () => {
            if (fechado)
                return (<div className="bloco-fechado">
                    <span className="fechado">fechado</span>
                </div>);
            else if (precedencia && !obrigatoria)
                return (<div className="bloco-precedencia" title="Precedência sugerida">
                    <span className="precedencia"><Icon className="precedencia-sugerida" type="info-circle" /> {precedenciaNome}</span>
                </div>);
            else if (precedencia && obrigatoria)
                return (<div className="bloco-precedencia" title="Precedência obrigatória">
                    <span className="precedencia"><Icon className="precedencia-obrigatoria" type="exclamation-circle" /> {precedenciaNome}</span>
                </div>);
            else
                return null;
        }

        return (
            <>
                <GlobalContext.Consumer>
                    {GlobalContext => {
                        return (
                            <div className="header-cursos">
                                {/*<LazyLoad offsetVertical={335}>*/}
                                {/*    <img src={require("../images/wave_hero_user.svg")} className="img-hero" />*/}
                                {/*</LazyLoad>*/}
                                <div className="container">
                                    <Skeleton active={this.state.loading} avatar loading={this.state.loading}>
                                        <div className="lado-esquerdo">
                                            <div className="titulo-favorito">
                                                <div className="titulo-curso">
                                                    <h2 title={nome}>{codigo} - {nome}</h2>
                                                </div>
                                                <div className="marcar-favorito">
                                                    {!this.state.favorito ? (
                                                        <Link
                                                            to="#"
                                                            title="Marcar como favorito"
                                                            onClick={this.marcarFavorito}
                                                        >
                                                            <i className="far fa-heart" />
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="#"
                                                            title="Remover favorito"
                                                            onClick={this.marcarFavorito}
                                                        >
                                                            <i className="fas fa-heart" />
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                            <span className="descritivo">{objectivo}</span>
                                            <div className="bloco-rating-info bloco-rating-info-desktop">
                                                {totalAvaliacao || fechado || (escola && GlobalContext.escolas_entidade) ?
                                                    <div className="bloco-esquerda">
                                                        {totalAvaliacao ?
                                                            <div className="rating esquerda-item">
                                                                <Rate disabled value={avaliacao} />
                                                                <span className="ant-rate-text">({totalAvaliacao})</span>
                                                            </div> : null}
                                                        {fechado &&
                                                            <div className="bloco-fechado esquerda-item">
                                                                <span className="curso-fechado" onClick={() => openNotificationWithIcon("warning", "Atenção", "Uma vez que curso está fechado, não é possível realizar avaliações, questionários e trabalhos")}>fechado</span>
                                                            </div>}
                                                        <div className="bloco-ano-turma esquerda-item" title={ano && turma ? "Edição Ano e Turma" : ano ? "Edição e Ano" : turma ? "Edição e Turma" : "Edição"}>
                                                            <span className="ano-turma">{edicao} {ano ? ano : ""} {turma ? turma : ""}</span>
                                                        </div>
                                                        {(escola && GlobalContext.escolas_entidade) &&
                                                            <div className="bloco-escola esquerda-item">
                                                                <span className="escola">{escola}</span>
                                                            </div>}
                                                    </div> : null}
                                                {(habilitar_gamificacao && GlobalContext.gamificacao_entidade && inscrito) &&
                                                    <div className="bloco-pontos"><Pontos cursoId={this.state.cursoId} /></div>}
                                                {((preco && GlobalContext.vendaCursos && !inscrito && !subscricaoAtiva) || (GlobalContext.subscricao_entidade && subscricao && !subscricaoAtiva && !inscrito)) && GlobalContext.monetizacao_entidade && !pagamentoPendente && !(GlobalContext.carrinho.find(x => x.id === cursoId || x.tipo === "SUBSCRICAO")) ?
                                                    <div className="bloco-direita">
                                                        {(!inscrito && preco && GlobalContext.vendaCursos && !subscricaoAtiva) ?
                                                            <AdicionarCarrinho
                                                                produto={{
                                                                    id: cursoId,
                                                                    capa: this.state.imagem ? this.state.imagem : noimage,
                                                                    nome: `${codigo} - ${nome}`,
                                                                    descricao: objectivo,
                                                                    preco: preco,
                                                                    tipo: "CURSO"
                                                                }}
                                                            /> : null}
                                                        {(GlobalContext.subscricao_entidade && subscricao && !subscricaoAtiva && !inscrito) ?
                                                            <AdicionarSubscricao
                                                                produto={{
                                                                    id: cursoId
                                                                }}
                                                            /> : null}
                                                    </div>
                                                    :
                                                    <div className="bloco-direita">
                                                        {preco ?
                                                            <span className="tag-valor-curso" dangerouslySetInnerHTML={{ __html: `${GlobalContext.moeda_entidade} ${preco}` }}>
                                                                
                                                            </span>
                                                            : null}
                                                        {(GlobalContext.subscricao_entidade && subscricao && GlobalContext.monetizacao_entidade) ?
                                                            <span className="tag-valor-subscricao">
                                                                Subscrição
                                                        </span>
                                                            : null}
                                                    </div>
                                                }
                                            </div>
                                            <div className="bloco-rating-info bloco-rating-info-mobile">
                                                {totalAvaliacao ?
                                                    <div className="bloco-info-1">
                                                        <div className="rating esquerda-item">
                                                            <Rate disabled value={avaliacao} />
                                                            <span className="ant-rate-text">({totalAvaliacao})</span>
                                                        </div>
                                                    </div> : null}
                                                <div className="bloco-info-2">
                                                    {fechado &&
                                                        <div className="bloco-fechado">
                                                        <span className="curso-fechado" onClick={() => openNotificationWithIcon("warning", "Atenção", "Uma vez que curso está fechado, não é possível realizar avaliações, questionários e trabalhos")}>fechado</span>
                                                        </div>}
                                                    <div className="bloco-ano-turma" title={ano && turma ? "Edição Ano e Turma" : ano ? "Edição e Ano" : turma ? "Edição e Turma" : "Edição"}>
                                                        <span className="ano-turma">{edicao} {ano ? ano : ""} {turma ? turma : ""}</span>
                                                    </div>
                                                    {(escola && GlobalContext.escolas_entidade) &&
                                                        <div className="bloco-escola">
                                                            <span className="escola">{escola}</span>
                                                        </div>}
                                                    {(habilitar_gamificacao && GlobalContext.gamificacao_entidade && inscrito) &&
                                                        <div className="bloco-pontos"><Pontos cursoId={this.state.cursoId} /></div>}
                                                </div>
                                                {((preco && GlobalContext.vendaCursos && !inscrito && !subscricaoAtiva) || (GlobalContext.subscricao_entidade && subscricao && !subscricaoAtiva && !inscrito)) && GlobalContext.monetizacao_entidade && !pagamentoPendente && !(GlobalContext.carrinho.find(x => x.id === cursoId || x.tipo === "SUBSCRICAO")) ?
                                                    <div className="bloco-info-3">
                                                        {(!inscrito && preco && GlobalContext.vendaCursos && !subscricaoAtiva) ?
                                                            <AdicionarCarrinho
                                                                produto={{
                                                                    id: cursoId,
                                                                    capa: this.state.imagem ? this.state.imagem : noimage,
                                                                    nome: `${codigo} - ${nome}`,
                                                                    descricao: objectivo,
                                                                    preco: preco,
                                                                    tipo: "CURSO"
                                                                }}
                                                            /> : null}
                                                        {(GlobalContext.subscricao_entidade && subscricao && !subscricaoAtiva && !inscrito) ?
                                                            <AdicionarSubscricao
                                                                produto={{
                                                                    id: cursoId
                                                                }}
                                                            /> : null}
                                                    </div>
                                                    :
                                                    <div className="bloco-info-3">
                                                        {preco ?
                                                            <span className="tag-valor-curso" dangerouslySetInnerHTML={{ __html: `${GlobalContext.moeda_entidade} ${preco}` }}>

                                                            </span>
                                                            : null}
                                                        {(GlobalContext.subscricao_entidade && subscricao && GlobalContext.monetizacao_entidade) ?
                                                            <span className="tag-valor-subscricao">
                                                                Subscrição
                                                        </span>
                                                            : null}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="lado-direito">
                                            <div className="bloco-imagem">
                                                <div className="container-img">
                                                    <img src={this.state.imagem ? this.state.imagem : noimage} alt="Imagem do Curso" />
                                                </div>
                                            </div>
                                        </div>
                                    </Skeleton>
                                </div>
                            </div>
                        );
                    }}
                </GlobalContext.Consumer>
                <ModalWelcome
                    visibleWelcome={visibleWelcome}
                    imagem={imagem ? imagem : noimage}
                    nome={nome}
                    onCancel={() => this.setState({ visibleWelcome: false })}
                />
            </>
        );
    }
}

export default withRouter(Header);
