import React from "react";
import VistaPautas from "./Pautas/Pautas";

const Conceitos = ({ estruturaId, periodo, tipo }) => {
    return (
        <>
            <div className="bloco">
                <VistaPautas
                    estruturaId={estruturaId}
                    periodo={periodo}
                    tipo={tipo}
                />
            </div>
        </>
    );
}
export default Conceitos;
