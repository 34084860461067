import React from 'react';
import '../../../../scss/_tabelaAluno.scss'
import { Icon } from "antd";
import { Link, useLocation } from "react-router-dom";
import {FaTableList} from "react-icons/fa6";
const TabelaAluno = ({ columns, data }) => {

    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const entidade = pathParts[1]

    return (
        <table className="tabelaAluno">
            <thead>
            <tr>
                {columns.map((column) => (
                    <th key={column.key}>{column.header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {columns.map((column) => (
                        <td key={column.key}>
                            {column.key === 'turma' ? (

                                <div style={{ display: "flex", margin: "auto", justifyContent: "space-between" }}>
                                    <div>
                                        {row[column.key]}
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        {/*{row.notas && (*/}
                                        {/*    <>*/}
                                        {/*        <Link to={`/${entidade}/areaAlunoRelatorio/${row.code}`} className="botaotabela">*/}
                                        {/*            <Icon type="table" />*/}
                                        {/*        </Link>*/}


                                        {/*    </>*/}
                                        {/*)*/}
                                        {/*}*/}
                                        {row.notas && (
                                            <Link to={`/${entidade}/areaAlunoRelatorio/${row.code}`} className="botaotabela">
                                                <span><Icon type="table" /></span>
                                                <span className='botao-texto'>Notas</span>
                                            </Link>)}
                                        <Link to={`/${entidade}/areaAlunoCalendario/${row.code}`} className="botaotabela2">
                                            <span><FaTableList style={{ color: "white" }} /></span>
                                            <span className='botao-texto'>Horário</span>
                                        </Link>
                                        {/*<button className="botaotabela2">*/}
                                        {/*    <FaTableList style={{color:"white"}}/>*/}
                                        {/*</button>*/}
                                    </div>

                                </div>
                            ) : (
                                row[column.key]
                            )}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default TabelaAluno;