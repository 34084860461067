import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table, Icon } from 'antd';
import { GlobalContext } from "../../../../GlobalState";
import axios from "axios";

const TabelaDetalhe = ({ vista, record, periodosLetivo, match }) => {
    const globalContext = useContext(GlobalContext);
    const [disciplinaId, setDisciplinaId] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [periodoMeses, setPeriodoMeses] = useState([]);
    const [meses, setMeses] = useState([]);
    const [loading, setLoading] = useState(false);

    const carregar = (periodo) => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-meses-assiduidade",
            params: {
                code: match.params.code,
                disciplinaId,
                inicio: periodo.inicio,
                termino: periodo.termino
            }
        })
            .then(response => {
                let _periodos = periodoMeses.filter(x => x.key !== periodo.key);
                const novo = {
                    key: periodo.key,
                    meses: response.data
                }
                setPeriodoMeses([..._periodos, novo]);
                setLoading(false)
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const cols = [
        {
            title: "",
            dataIndex: "nome",
            width: 150,
        },
        {
            title: "",
            dataIndex: "nota"
        }
    ];

    //const data = [
    //    {
    //        key: 1,
    //        nome: '1º Bimestre'
    //    },
    //    {
    //        key: 2,
    //        nome: '2º Bimestre'
    //    },
    //    {
    //        key: 3,
    //        nome: '3º Bimestre'
    //    },
    //    {
    //        key: 4,
    //        nome: '4º Bimestre'
    //    }
    //]

    useEffect(() => {
        var periodos = periodosLetivo.map((periodo, index) => {
            return {
                key: index,
                nome: <span>{periodo.nome}{record.semRegistos[index] ? <Icon className="alerta-falta-registos" type="warning" title="Falta de registos" /> : ""}</span>,
                inicio: periodo.dt_inicio,
                termino: periodo.dt_termino,
            }
        })
        setPeriodos(periodos);
        setDisciplinaId(record.key);
    }, [record])

    return (
        <Table
            showHeader={false}
            className="tabela-aproveitamento"
            columns={cols}
            pagination={false}
            dataSource={periodos}
            expandedRowRender={record =>
                <div className="bloco-meses">
                    {periodoMeses.find(x => x.key === record.key)?.meses.map(mes => (
                        <div className="mes">
                            <div className="header">
                                <span className="titulo">{mes.nome}{mes.registosEmFalta ? <Icon className="alerta-falta-registos" type="warning" title="Falta de registos" /> : ""}</span>
                            </div>
                            <div className="body">
                                <ul className="lista-dias">
                                    {mes.aulas.map(aula => (
                                        <li>
                                            <span>{aula.dia}</span>
                                            <span className={aula.classname} title={aula.titulo}>{vista ? aula.horas : aula.tipo}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="footer">
                                <span className="totais">TOTAL PRESENÇAS: <span className="presenca"><strong>{vista ? mes.totalHorasPresencas : mes.totalPresencas}</strong></span></span>
                                <span className="totais">TOTAL FALTAS: <span className="falta"><strong>{vista ? mes.totalHorasFalta : mes.totalFalta}</strong></span></span>
                                <span className="totais">TOTAL FALTAS JUSTIFICADA: <span className="falta-justificada"><strong>{vista ? mes.totalHorasJustificadas : mes.totalJustificadas}</strong></span></span>
                            </div>
                        </div>
                    ))}
                </div>
            }
            onExpand={(expanded, record) => expanded ? carregar(record) : null}
        />
    );
}

export default withRouter(TabelaDetalhe);