import React, { Component } from "react";
import { GlobalContext } from "../GlobalState";
import { notification, Icon } from "antd";
import axios from "axios";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class PdfReader extends Component {
    static contextType = GlobalContext;
    state = {
        aulaid: 0,
        count: 0,
        //PDF
        pdf: "",
        numPages: null,
        pageNumber: 1,
        scale: 1.5,
        rotate: 0
    };

    //CHAMADA ANTES DO RENDER
    componentDidMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var aulaId = localStorage.getItem("codigo_aula");

        if (this.isMobile())
            this.setState({
                aulaid: aulaId,
                scale: 0.8
            });
        else
            this.setState({
                aulaid: aulaId
            });

        this.CarregarConteudo(aulaId);
        this.registarEntrada(aulaId);
    }

    isMobile = () => {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/player/conteudo1",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    pdf: response.data.recurso
                });
                this.props.carregarInfo(response.data.nome, response.data.objetivos, moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"));
                this.concluirAula(aulaid);
            })
            .catch(error => {
                //if (error.response.data === "SEM_PERMISSAO")
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/user"
                //    });
                //else 
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.props.marcarComoNaoDisponivel();
            });
    };

    concluirAula = aulaid => {
        axios({
            method: "put",
            url: "/api/player/concluir",
            params: {
                aulaid: aulaid
            }
        }).then(() => {
            this.context.atualizarListaModulos();
        })
            .catch(error => {
                //if (error.response.data === "SEM_PERMISSAO")
                //    this.setState({
                //        redirect: true,
                //        redirectLink: "/user"
                //    });
                //else 
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                openNotificationWithIcon("error", "Erro", "Não foi possível concluir tarefa.");
            });
    };

    registarEntrada = aulaid => {
        axios({
            method: "post",
            url: "/api/logs/registar-entrada",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                localStorage.setItem("codigo_entrada", response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    scaleDown = () => {
        if (this.state.scale >= 1.2) {
            this.setState({ scale: this.state.scale - 0.3 });
        }
    };

    scaleUp = () => {
        if (this.state.scale <= 3) {
            this.setState({ scale: this.state.scale + 0.3 });
        }
    };

    render() {
        return (
            <>
                <Document
                    file={this.state.pdf}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    loading={<div className="loading-data">
                        <div className="loading" />
                    </div>}
                >
                    <Page pageNumber={this.state.pageNumber} scale={this.state.scale} rotate={this.state.rotate} />
                </Document>
                <div className="controlos-pdf">
                    <button
                        onClick={() =>
                            this.setState(prevState => ({
                                pageNumber: prevState.pageNumber >= this.state.numPages ? prevState.pageNumber - 1 : 1
                            }))
                        }
                    >
                        <Icon type="caret-left" />
                    </button>
                    <span>
                        {this.state.pageNumber}/{this.state.numPages}
                    </span>
                    <button
                        onClick={() =>
                            this.setState(prevState => ({
                                pageNumber: prevState.pageNumber < this.state.numPages ? prevState.pageNumber + 1 : this.state.numPages
                            }))
                        }
                    >
                        <Icon type="caret-right" />
                    </button>
                    <button onClick={this.scaleUp}>
                        <Icon type="zoom-in" />
                    </button>
                    <button onClick={this.scaleDown}>
                        <Icon type="zoom-out" />
                    </button>
                    <button onClick={() => this.setState({ rotate: this.state.rotate === 180 ? 180 : this.state.rotate + 90 })}>
                        <Icon type="reload" />
                    </button>
                    <button onClick={() => this.setState({ rotate: this.state.rotate === -180 ? -180 : this.state.rotate - 90 })}>
                        <Icon type="reload" style={{ transform: "scaleX(-1) " }} />
                    </button>
                    {/*<a href={this.state.pdf} download>
                        <Icon type="download" />
                    </a>*/}
                </div>
            </>)
    }
}

export default PdfReader;
