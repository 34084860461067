import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Row, Col, Form, Input, notification, DatePicker } from "antd";
import noimage from "../../images/noimage.png";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
class VisaoGeral extends Component {
    static contextType = GlobalContext;
    state = {
        curso: {},
        retomar: "",
        total_atividades: 0,
        total_tarefas: 0,
        //coordenadores: [],
        formadores: [],
        duracao: "",
        loading: true,
        //FORMULARIO
        nome: "",
        email: "",
        contacto: "",
        dataNascimento: "",
        cidade: "",
        estado: "",
        cargo: "",
        escola: "",
        questoes: "",
        //LOADING
        iconLoading: false,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.CarregarInfo();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeData = date => {
        this.setState({ dataNascimento: date });
    };

    //CARREGA A INFORMAÇÃO DO CURSO
    CarregarInfo = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/info-curso",
            params: {
                cursoIdEnc: this.props.match.params.cursoid,
                entidadeUrl: this.props.match.params.entidade
            }
        })
            .then(response => {
                this.setState({
                    curso: response.data,
                    ativo: response.data.ativo,
                    total_atividades: response.data.total_modulos,
                    total_tarefas: response.data.total_aulas,
                    formadores: response.data.instrutores,
                    duracao: this.calcularSemanas(response.data.dt_inicio, response.data.dt_fim),
                    loading: false
                });
            })
            .catch(() => { });
    };

    calcularSemanas = (dt_inicio, dt_fim) => {
        if (dt_fim) {
            var semanas = moment(dt_fim).diff(dt_inicio, 'week');
            if (semanas <= 0) {
                var dias = moment(dt_fim).diff(dt_inicio, 'day');
                return `${dias} dias`
            }
            else
                return `${semanas} semanas`
        }
        else
            return "SEM_REGISTO"
    }

    montarTipologia = tipologia => {
        switch (tipologia) {
            case "ONLINE":
                return "Online";
                break;
            case "PRESENCIAL":
                return "Presencial";
                break;
            default:
                return "Misto";
                break;
        }
    }

    montarDedicacao = dedicacao => {
        switch (dedicacao) {
            case "PARCIAL":
                return "Tempo parcial";
                break;
            default:
                return "Tempo inteiro";
                break;
        }
    }

    montarCusto = (valorCurso, valorCertificado, moeda) => {
        if (valorCurso > 0 && valorCertificado > 0)
            return `${this.context.moeda_entidade} ${valorCurso} e ${this.context.moeda_entidade} ${valorCertificado}/Certificado`;
        else if (valorCurso > 0)
            return `${this.context.moeda_entidade} ${valorCurso}`;
        else if (valorCertificado > 0)
            return `${this.context.moeda_entidade} ${valorCertificado}/Certificado`;
        else
            return "Gratuito";
    }

    decodeHTML = moeda => {
        var map = { "gt": ">" /* , … */ };
        return moeda.replace(/&(#(?:x[0-9a-f]+|\d+)|[a-z]+);?/gi, ($0, $1) => {
            if ($1[0] === "#") {
                return String.fromCharCode($1[1].toLowerCase() === "x" ? parseInt($1.substr(2), 16) : parseInt($1.substr(1), 10));
            } else {
                return map.hasOwnProperty($1) ? map[$1] : $0;
            }
        });
    };

    submeter = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                })

                var item = new FormData();
                item.append("curso", this.state.curso.nome);
                item.append("nome", this.state.nome);
                item.append("email", this.state.email);
                item.append("contacto", this.state.contacto);
                item.append("dataNascimento", moment(this.state.dataNascimento).format("DD/MM/YYYY"));
                item.append("cidade", this.state.cidade);
                item.append("estado", this.state.estado);
                item.append("cargo", this.state.cargo);
                item.append("escola", this.state.escola);
                item.append("questoes", this.state.questoes);
                item.append("entidadeId", localStorage.getItem("entidadeId"));

                axios({
                    method: "post",
                    url: "/api/pagina-entidade/submeter",
                    data: item,
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Formulário submetido!");
                        this.props.form.resetFields();
                        this.setState({
                            nome: "",
                            email: "",
                            contacto: "",
                            dataNascimento: "",
                            cidade: "",
                            estado: "",
                            cargo: "",
                            escola: "",
                            questoes: "",
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível submeter o formulário.");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    }

    downloadFicheiro = (cursoIdEnc) => {
        axios({
            method: "post",
            url: "/api/pagina-entidade/download-ficheiro",
            params: {
                cursoIdEnc
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    disabledDate = current => {
        return current && current > moment().endOf("day");
    };

    render() {
        const { TextArea } = Input;
        const { getFieldDecorator } = this.props.form;
        const {
            curso,
            total_atividades,
            total_tarefas,
            formadores,
            duracao,
            //FORMULARIO
            nome,
            email,
            contacto,
            dataNascimento,
            cidade,
            estado,
            cargo,
            escola,
            questoes,
            //LOADING
            iconLoading
        } = this.state;

        const lista_linguas = linguas => {
            var _linguas = "";

            linguas.forEach(lingua => {
                _linguas += lingua.nome + ", ";
            })

            return _linguas.slice(0, -2);
        }

        return (
            <div className="container">
                {this.state.loading ? (
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                ) : (
                    <div className="curso-visao-geral curso-visao-geral-pagina-publica">
                        {formadores.length > 0 ? (
                            <div className="bloco centrado">
                                <div className="item">
                                    <div className="lista-instrutores">
                                        {formadores.map((formador, index) => (
                                            <div key={index} className="instrutor">
                                                <div className="imagem">
                                                    <div className="container-img">
                                                        <img src={formador.foto ? formador.foto : noimage} alt="Foto do professor" />
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <span className="nome">
                                                        {formador.nome_completo}
                                                    </span>
                                                    <span className="cargo">({formador.instrutor && formador.coordenador ? "Professor e Coordenador" : formador.instrutor ? "Professor" : "Coordenador"})</span>
                                                </div>
                                                {formador.descricao ?
                                                    <div className="instrutor-habilitacao" style={{ margin: "40px 0" }}>
                                                        {/*<h2>Habilitações</h2>*/}
                                                        <p className="descricao" dangerouslySetInnerHTML={{ __html: formador.habilitacao.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                                    </div>
                                                    : null}
                                                {formador.descricao ?
                                                    <div className="instrutor-descricao">
                                                        {/*<h2>Descrição</h2>*/}
                                                        <p className="descricao" dangerouslySetInnerHTML={{ __html: formador.descricao.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                                    </div>
                                                    : null}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {curso.descricao &&
                            <div className="bloco centrado">
                                <div className="item">
                                    <h2>Descrição</h2>
                                    {curso.descricao && <p className="descricao" dangerouslySetInnerHTML={{ __html: curso.descricao.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>}
                                </div>
                            </div>}
                        {curso.atividades &&
                            curso.atividades.length > 0 &&
                            <div className="bloco centrado">
                                <div className="item programa-curso">
                                    <h2>Estrutura do Programa</h2>
                                    <div className="conteudos">
                                        {curso.atividades.map((atividade, index) => (
                                            <div key={index} className="conteudo">
                                                <h3>{atividade.nome}</h3>
                                                {atividade.objetivos &&
                                                    <p className="texto-objetivo">{atividade.objetivos}</p>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>}
                        {curso.subcursos &&
                            curso.subcursos.length > 0 &&
                            <div className="bloco centrado">
                                <div className="item programa-curso">
                                    <h2>Estrutura do Programa</h2>
                                    <div className="conteudos">
                                        {curso.subcursos.map((subcurso, index) => (
                                            <div key={index} className="conteudo">
                                                <h3>
                                                    <a
                                                        className="item-link"
                                                        href={`/${this.props.match.params.entidade}/curso/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${subcurso.idEnc}/curso-visao-geral`}
                                                    //onClick={() => localStorage.setItem("codigo_curso", subcurso.id)}
                                                    >
                                                        {subcurso.nome}
                                                    </a>
                                                </h3>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>}
                        {(curso.ficha || curso.pagina_externa) &&
                            <div className="bloco centrado">
                                <div className="item">
                                    <h2 style={{ display: "flex", alignItems: "center" }}>
                                        Ficha do curso
                                        <Link to="#" className="botao-icon-download" onClick={() => this.downloadFicheiro(this.props.match.params.cursoid)} style={{ marginLeft: 10 }}>
                                            <Icon type="download" />
                                        </Link>
                                    </h2>
                                    {curso.pagina_externa &&
                                        <a href={curso.pagina_externa} target="_blank" rel="noopener noreferrer" className="botao-secundario btn-visitar">Visitar página do curso</a>}
                                </div>
                            </div>}
                        {curso.curso_Informacoes.length > 0 && (
                            <div className="bloco centrado">
                                <h2>Informações</h2>
                                <div className="lista-informacoes">
                                    {curso.curso_Informacoes.map((info, index) => (
                                        <div key={"info" + index} className="informacao">
                                            <p className="texto" dangerouslySetInnerHTML={{ __html: info.texto.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                            <div className="data">
                                                <span>{moment(info.dt_criado).format("DD/MM/YYYY")}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className={`bloco-indicadores ${(!this.context.atividadesLivres ? "border-top" : "")}`}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={8} xs={24} md={8}>
                                    <div className="gutter-box">
                                        <div className="item">
                                            <h2>
                                                <Icon className="item-icon" type="appstore" />
                                                Categoria
                                            </h2>
                                            <div className="conteudos">
                                                <div className="conteudo">
                                                    {curso.categoria.nome}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={8} xs={24} md={8}>
                                    <div className="gutter-box">
                                        <div className="item">
                                            <h2>
                                                <Icon className="item-icon" type="calendar" />
                                                Início
                                            </h2>
                                            <div className="conteudos">
                                                <div className="conteudo">
                                                    {moment(curso.dt_inicio).format("DD/MM/YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {curso.tipologia !== "NAO_APLICAVEL" ?
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="build" />
                                                    Tipologia
                                                </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {this.montarTipologia(curso.tipologia)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null}
                                <Col className="gutter-row" span={8} xs={24} md={8}>
                                    <div className="gutter-box">
                                        <div className="item">
                                            <h2>
                                                <Icon className="item-icon" type="global" />
                                                Idiomas
                                            </h2>
                                            <div className="conteudos">
                                                <div className="conteudo">
                                                    {lista_linguas(curso.linguas)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {curso.dt_fecho_inscricoes ?
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="carry-out" />
                                                    Fecho das inscrições
                                                </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {moment(curso.dt_fecho_inscricoes).format("DD/MM/YYYY")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null}
                                {curso.dedicacao !== "NAO_APLICAVEL" ?
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="history" />
                                                    Dedicação
                                                </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {this.montarDedicacao(curso.dedicacao)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null}
                                {duracao !== "SEM_REGISTO" || curso.carga_horaria ?
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="hourglass" />
                                                    Duração
                                                </h2>
                                                <div className="conteudos">
                                                    {duracao !== "SEM_REGISTO" &&
                                                        <div className="conteudo">
                                                            {duracao}
                                                        </div>}
                                                    {curso.carga_horaria && (
                                                        <div className="conteudo">
                                                            <span>Carga Horária:</span> {curso.carga_horaria}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null}
                                {this.context.monetizacao_entidade && (this.context.vendaCursos || this.context.vendaCertificados) &&
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="shopping" />
                                                    Custo
                                                </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {this.montarCusto(curso.valorCurso, curso.valorCertificado)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>}
                                {curso.gamificacao_stepforma && curso.gamificacao_entidade && curso.habilitar_gamificacao ?
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <i className="fas fa-award item-icon"></i>
                                                    Gamificação
                                                </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {curso.habilitar_gamificacao ? "Sim" : "Não"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null}
                                {curso.habilitar_competencias ?
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="file-done" />
                                                    Competências
                                                </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {curso.habilitar_competencias ? "Sim" : "Não"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null}
                                <Col className="gutter-row" span={8} xs={24} md={8}>
                                    <div className="gutter-box">
                                        <div className="item">
                                            <h2>
                                                <Icon className="item-icon" type="bars" />
                                                Conteúdos
                                            </h2>
                                            <div className="conteudos">
                                                <div className="conteudo">
                                                    <span>Atividades:</span> {total_atividades}
                                                </div>
                                                <div className="conteudo">
                                                    <span>Tarefas:</span> {total_tarefas}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {curso.edicao ?
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="schedule" />
                                                    Edição
                                                </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {curso.edicao} {curso.ano ? curso.ano : ""} {curso.turma ? curso.turma : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col> : null}
                            </Row>
                        </div>
                        <div className="bloco centrado">
                            <h2 style={{ marginBottom: 0 }}>Solicitar mais informações</h2>
                            <span style={{ display: "block", marginBottom: 20, fontSize: 16 }}>Complete este formulário e entraremos em contato </span>
                            <Form className="form-solicitar-info">
                                <Form.Item label="Nome completo">
                                    {getFieldDecorator("nome", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: nome
                                    })(<Input name="nome" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <Form.Item label="E-mail">
                                                {getFieldDecorator("email", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        },
                                                        {
                                                            type: "email",
                                                            message: "Introduza um endereço de e-mail válido"
                                                        },
                                                    ],
                                                    initialValue: email
                                                })(<Input name="email" onChange={this.handleChange} />)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={12} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <Form.Item label="Telefone / WhatsApp">
                                                {getFieldDecorator("contacto", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        }
                                                    ],
                                                    initialValue: contacto
                                                })(<Input name="contacto" onChange={this.handleChange} />)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={12} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <Form.Item label="Data de nascimento">
                                                {getFieldDecorator("dataNascimento", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        },
                                                    ],
                                                    initialValue: dataNascimento
                                                })(<DatePicker
                                                    placeholder="Selecionar"
                                                    format="DD-MM-YYYY"
                                                    name="dataNascimento"
                                                    onChange={this.handleChangeData}
                                                    disabledDate={this.disabledDate}
                                                />)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12} xs={24} md={12}>
                                        <div className="gutter-box">
                                            <Form.Item label="Cidade">
                                                {getFieldDecorator("cidade", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        }
                                                    ],
                                                    initialValue: cidade
                                                })(<Input name="cidade" onChange={this.handleChange} />)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={12} xs={24} md={12}>
                                        <div className="gutter-box">
                                            <Form.Item label="Estado">
                                                {getFieldDecorator("estado", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        }
                                                    ],
                                                    initialValue: estado
                                                })(<Input name="estado" onChange={this.handleChange} />)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12} xs={24} md={12}>
                                        <div className="gutter-box">
                                            <Form.Item label="Cargo">
                                                {getFieldDecorator("cargo", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        }
                                                    ],
                                                    initialValue: cargo
                                                })(<Input name="cargo" onChange={this.handleChange} />)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={12} xs={24} md={12}>
                                        <div className="gutter-box">
                                            <Form.Item label="Escola">
                                                {getFieldDecorator("escola", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Campo obrigatório"
                                                        }
                                                    ],
                                                    initialValue: escola
                                                })(<Input name="escola" onChange={this.handleChange} />)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                                <Form.Item label="Mensagem">
                                    {getFieldDecorator("questoes", {
                                        initialValue: questoes
                                    })(<TextArea
                                        name="questoes"
                                        onChange={this.handleChange}
                                        rows={4}
                                        style={{ height: 100 }}
                                    />)}
                                </Form.Item>
                                <button className="botao-principal" disabled={iconLoading} onClick={this.submeter}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Submeter formulário
                                </button>
                            </Form>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const FormVisaoGeral = Form.create({ name: "form-visao-geral" })(VisaoGeral);

export default FormVisaoGeral;
