import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Drawer, Icon, Form, Radio } from "antd";
import Paypal from "./Paypal";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";

class DrawerCarrinho extends Component {
    static contextType = GlobalContext;

    state = {
        metodo: 1,
        total: 0,
        //ENTIDADE
        nome: "",
        dadosPagamento: "",
        //iban: "",
        //entidade: "",
        //cnpj: "",
        //banco: "",
        //agencia: "",
        //conta: "",
        //operacao: "",
        paypal: false,
        transferencia: false,
        //LOADING
        iconLoading: false,
        parte: 1, //this.context.carrinhoProdutos ? 2 : 1,
        erro: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //
        carregadoConcluir: false
    };

    handleChange = event => {
        this.setState({
            metodo: event.target.value
        });
    };

    carregarEntidadeInfo = () => {
        axios({
            method: "get",
            url: "/api/carrinho/entidade-info"
        })
            .then(response => {
                var metodo = 1;

                if (!response.data.paypal && response.data.transferencia) metodo = 2;

                this.setState({
                    nome: response.data.nome,
                    dadosPagamento: response.data.dadosPagamento,
                    //iban: response.data.iban,
                    //entidade: response.data.entidade,
                    //cnpj: response.data.cnpj,
                    //banco: response.data.banco,
                    //agencia: response.data.agencia,
                    //conta: response.data.conta,
                    //operacao: response.data.operacao,
                    paypal: response.data.paypal,
                    transferencia: response.data.transferencia,
                    metodo
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    finalizarCompra = () => {
        this.setState({
            parte: 2
        });
    };

    calcularTotal = () => {
        var total = 0;
        this.context.carrinho.map(produto => {
            total = total + produto.preco;
        });

        this.setState({
            total
        });

        return total;
    };

    concluirPaypal = orderID => {
        var item = new FormData();
        item.append("order", orderID);
        item.append("total", this.state.total); //(this.state.total + "").replace(".", ","));
        item.append("carrinho", JSON.stringify(this.tratarCarrinho()));

        axios({
            method: "post",
            url: "/api/carrinho/pagamento-paypal",
            data: item
        })
            .then(response => {
                this.setState({
                    parte: 3
                });
            })
            .catch(error => {
                this.setState({
                    parte: 3,
                    erro: true
                });
            });
    };

    concluirTransferencia = data => {
        this.setState({
            carregadoConcluir: true
        })

        var item = new FormData();
        item.append("total", this.state.total); //(this.state.total + "").replace(".", ","));
        item.append("carrinho", JSON.stringify(this.tratarCarrinho()));

        axios({
            method: "post",
            url: "/api/carrinho/pagamento-transferencia",
            data: item
        })
            .then(response => {
                this.setState({
                    parte: 3
                });
            })
            .catch(error => {
                this.setState({
                    parte: 3,
                    erro: true,
                    carregadoConcluir: false
                });
            });
    };

    tratarCarrinho = () => {
        var carrinho = [];
        carrinho = this.context.carrinho.map(x => {
            return {
                id_curso: x.id,
                preco: x.preco, //(x.preco + "").replace(".", ","),
                dt_inicio: x.dt_inicio,
                dt_fim: x.dt_fim,
                nr_meses: x.nr_meses,
                tipo: x.tipo
            };
        });

        return carrinho;
    };

    erro = () => {
        this.setState({
            parte: 3,
            erro: true
        });
    };

    onClick = tipo => {
        if (tipo === "paypal")
            this.setState({
                iconLoading: true
            });
    };

    onCancel = () => {
        this.setState({
            iconLoading: false
        });
    };

    excluir = id => {
        var carrinho = this.context.carrinho.filter(x => x.id !== id);

        this.context.atualizarState({
            carrinho
        });
    };

    validarCarrinho = () => {
        if (this.context.carrinhoProdutos) {
            this.validarSubscricaoCarrinho();
            this.validarCursosCarrinho();

            this.context.atualizarState({
                carrinhoProdutos: false
            });
        }
    };

    validarCursosCarrinho = () => {
        axios({
            method: "get",
            url: "/api/carrinho/carregar-cursos-comprados"
        })
            .then(response => {
                var carrinho = this.context.carrinho;

                response.data.map(curso => {
                    carrinho = carrinho.filter(x => x.id !== curso.id);
                });

                this.context.atualizarState({
                    carrinho
                });

                if (carrinho.length > 0) {
                    this.setState({
                        parte: 2
                    });
                }

                this.calcularTotal();
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    validarSubscricaoCarrinho = () => {
        var carrinho = this.context.carrinho;
        var subscricoes = this.context.carrinho.filter(x => x.tipo === "SUBSCRICAO");

        if (subscricoes.length > 0) {
            axios({
                method: "get",
                url: "/api/carrinho/entidade-valor-mensal"
            })
                .then(response => {
                    if (response.data.dt_fim) {
                        var carrinhoSemsubs = this.context.carrinho.filter(x => x.tipo !== "SUBSCRICAO");
                        var subs = subscricoes.map(sub => {
                            return {
                                id: 0,
                                nome: `Subscrição ${sub.nr_meses > 1 ? `${sub.nr_meses} meses` : "1 mês"}`,
                                descricao: `Subscrição de ${moment(response.data.dt_fim).format("DD/MM/YYYY")} a ${moment(
                                    response.data.dt_fim
                                )
                                    .add(sub.nr_meses, "M")
                                    .format("DD/MM/YYYY")}`,
                                preco: sub.nr_meses * response.data.valorMensal,
                                tipo: "SUBSCRICAO",
                                dt_inicio: moment(response.data.dt_fim).format("YYYY/MM/DD"),
                                dt_fim: moment(response.data.dt_fim).add(sub.nr_meses, "M").format("YYYY/MM/DD"),
                                nr_meses: sub.nr_meses
                            };
                        });

                        carrinho = [...carrinhoSemsubs, ...subs];

                        this.context.atualizarState({
                            carrinho
                        });

                        if (carrinho.length > 0) {
                            this.setState({
                                parte: 2
                            });
                        }

                        this.calcularTotal();
                    }
                    //this.setState({
                    //    valorMensal: response.data.valorMensal,
                    //    dt_fim: response.data.dt_fim ? moment(response.data.dt_fim).add(1, "d") : ""
                    //})
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });
                });
        }
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.setState({
                metodo: 1,
                //LOADING
                iconLoading: false,
                parte: 1, //this.context.carrinhoProdutos ? 2 : 1,
                erro: false
            });
            //this.context.atualizarState({
            //    carrinhoProdutos: false
            //});
            this.validarCarrinho();
            this.calcularTotal();
            this.carregarEntidadeInfo();
        }
    };

    voltar = () => {
        var cursoNoCarrinho = this.context.carrinho.find(x => x.id === localStorage.getItem("codigo_curso"));
        //if (cursoNoCarrinho && this.props.match.params.pagina === "curso-visao-geral" || this.props.location.pathname === "/user" || this.props.location.pathname === "/sistema-informacao")
        if (
            (this.props.match.params.pagina === "curso-visao-geral" ||
                this.props.location.pathname === "/user" ||
                this.props.location.pathname === "/sistema-informacao") &&
            this.context.carrinho.length
        )
            window.location = window.location.href;
        else this.props.voltarCarrinho();
    };

    render() {
        const {
            metodo,
            total,
            //ENTIDADE
            nome,
            dadosPagamento,
            //iban,
            //entidade,
            //cnpj,
            //banco,
            //agencia,
            //conta,
            //operacao,
            parte,
            erro,
            paypal,
            transferencia,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink,
            //
            carregadoConcluir
        } = this.state;

        let carrinho = this.context.carrinho;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <Drawer
                className="drawer-carrinho"
                title={(carrinho.length || parte === 3) && !iconLoading ? (parte === 1 ? "Carrinho de compras" : "Finalizar compra") : ""}
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visibleCart}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    {parte !== 3 && carrinho.length ? (
                        <>
                            {parte === 1 && carrinho.length ? (
                                <>
                                    <div className="bloco-lista">
                                        <ul className="lista-produtos-carrinho">
                                            {this.context.carrinho.map((produto, index) => (
                                                <li key={index} className="produto-carrinho">
                                                    <div className="imagem-produto">
                                                        <img src={produto.capa} />
                                                    </div>
                                                    <div className="info-produto">
                                                        <div className="info-bloco">
                                                            <span className="produto-nome">{produto.nome}</span>
                                                            <span className="produto-descricao">{produto.descricao}</span>
                                                            <span className="produto-preco">
                                                                {this.context.moeda_entidade} {(produto.preco + "").replace(".", ",")}
                                                            </span>
                                                        </div>
                                                        <div className="info-bloco-opcoes">
                                                            <Link to="#" className="opcao-remover" onClick={() => this.excluir(produto.id)}>
                                                                <Icon type="delete" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            ) : null}
                            {parte === 2 ? (
                                <>
                                    <div className="bloco-pagamento">
                                        {paypal && transferencia && dadosPagamento ? (
                                            <>
                                                <Form className="form-categorias">
                                                    <Form.Item label="Metodo de pagamento">
                                                        <Radio.Group onChange={this.handleChange} value={this.state.metodo}>
                                                            <Radio value={1}>Paypal, Cartão de Crédito ou Mercado Pago</Radio>
                                                            <Radio value={2}>Transferência</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Form>
                                                {metodo === 1 ? (
                                                    <div className="bloco-paypal">
                                                        <Paypal
                                                            entidade={nome}
                                                            total={total}
                                                            concluirPaypal={this.concluirPaypal}
                                                            erro={this.erro}
                                                            onClick={this.onClick}
                                                            onCancel={this.onCancel}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="bloco-transferencia">
                                                        <p>Para concluir o processo, transfira o valor utilizando os seguintes dados:</p>
                                                        <p dangerouslySetInnerHTML={{ __html: dadosPagamento }}></p>
                                                        {/*<span><strong>{entidade}</strong></span><br />*/}
                                                        {/*<span><strong>CNPJ: </strong>{cnpj}</span><br />*/}
                                                        {/*<span><strong>Banco: </strong>{banco}</span><br />*/}
                                                        {/*<span><strong>Agência: </strong>{agencia}</span><br />*/}
                                                        {/*<span><strong>Conta: </strong>{conta}</span><br />*/}
                                                        {/*<span><strong>Operação: </strong>{operacao}</span>*/}
                                                    </div>
                                                )}
                                            </>
                                        ) : null}
                                        {paypal && !transferencia ? (
                                            <div className="bloco-paypal">
                                                <Paypal
                                                    entidade={nome}
                                                    total={total}
                                                    concluirPaypal={this.concluirPaypal}
                                                    erro={this.erro}
                                                    onClick={this.onClick}
                                                    onCancel={this.onCancel}
                                                />
                                            </div>
                                        ) : null}
                                        {transferencia && dadosPagamento && !paypal ? (
                                            <div className="bloco-transferencia">
                                                <p>Para concluir o processo, transfira o valor utilizando os seguintes dados:</p>
                                                <p dangerouslySetInnerHTML={{ __html: dadosPagamento }}></p>
                                                {/*<span><strong>{entidade}</strong></span><br />*/}
                                                {/*<span><strong>CNPJ: </strong>{cnpj}</span><br />*/}
                                                {/*<span><strong>Banco: </strong>{banco}</span><br />*/}
                                                {/*<span><strong>Agência: </strong>{agencia}</span><br />*/}
                                                {/*<span><strong>Conta: </strong>{conta}</span><br />*/}
                                                {/*<span><strong>Operação: </strong>{operacao}</span>*/}
                                            </div>
                                        ) : null}
                                        {iconLoading ? (
                                            <div className="bloco-loading">
                                                <div className="mensagem-info">
                                                    <div className="loading-data">
                                                        <div className="loading" />
                                                    </div>
                                                    <span className="mensagem-titulo">A concluir...</span>
                                                    <p className="mensagem-texto">Por favor aguarde.</p>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            ) : null}
                            <div className="bloco-total">
                                <span className="total-info">
                                    Total:{" "}
                                    <strong>
                                        {this.context.moeda_entidade} {(total + "").replace(".", ",")}
                                    </strong>
                                </span>
                            </div>
                        </>
                    ) : null}
                    {parte === 3 || !carrinho.length ? (
                        <div className="bloco-mensagem">
                            {carrinho.length ? (
                                !erro ? (
                                    <div className="mensagem-info">
                                        <i className="fas fa-check-circle mensagem-icon mensagem-icon-sucesso" />
                                        <span className="mensagem-titulo">Compra finalizada!</span>
                                        <p className="mensagem-texto">
                                            Foi enviado um email com os dados da sua compra. Verifique em Pagamentos na opção Conta do seu
                                            menu.
                                        </p>
                                    </div>
                                ) : (
                                    <div className="mensagem-info">
                                        <i className="fas fa-times-circle mensagem-icon mensagem-icon-erro" />
                                        <span className="mensagem-titulo">Ocorreu um erro!</span>
                                        <p className="mensagem-texto">
                                            Volte a tentar finalizar a sua compra. Caso o problema persista, contacte o suporte.
                                        </p>
                                    </div>
                                )
                            ) : (
                                <div className="mensagem-info">
                                    <i className="fas fa-shopping-cart mensagem-icon mensagem-icon-vazio"></i>
                                    <span className="mensagem-titulo">O carrinho está vazio!</span>
                                    <p className="mensagem-texto">Procure no catálogo o curso pretendido.</p>
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
                <div className="ant-drawer-footer">
                    {parte !== 3 && carrinho.length ? (
                        <>
                            <button className="botao-secundario" disabled={iconLoading} onClick={this.props.onClose}>
                                Voltar
                            </button>
                            {parte === 1 ? (
                                <button
                                    className="botao-principal"
                                    title="Finalizar compra"
                                    style={{ marginLeft: 20 }}
                                    onClick={this.finalizarCompra}
                                >
                                    Finalizar compra
                                </button>
                            ) : parte === 2 && metodo === 2 ? (
                                <button
                                    className="botao-principal"
                                    title="Finalizar compra"
                                    disabled={carregadoConcluir}
                                    style={{ marginLeft: 20 }}
                                    onClick={this.concluirTransferencia}
                                >
                                    Concluir
                                </button>
                            ) : null}
                        </>
                    ) : (
                        <button className="botao-secundario" onClick={this.voltar}>
                            Voltar
                        </button>
                    )}
                </div>
            </Drawer>
        );
    }
}
export default withRouter(DrawerCarrinho);
