import React, { Component } from "react";
import { Icon } from "antd";
import moment from "moment";
import noimage from "../../images/noimage.png";

class Mensagem extends Component {
    verificarData = data => {
        var diff = moment().diff(data, 'days')
        return diff > 0;
    }

    render() {
        return (
            <div className="mensagem">
                <div className="info">
                    <div className="lado-esquerdo">
                        <div className="container-img">
                            <img src={this.props.mensagem.utilizador.foto ? this.props.mensagem.utilizador.foto : noimage} />
                        </div>
                        <div className="dados">
                            <div className="nome">
                                {this.props.mensagem.utilizador.nome_completo}
                            </div>
                        </div>
                    </div>
                    {this.verificarData(this.props.mensagem.dt_criado) ?
                        <div className="bloco-data-hora">
                            <div className="data">{moment(this.props.mensagem.dt_criado).format("DD/MM/YYYY")}</div>
                            <div className="hora">{moment(this.props.mensagem.dt_criado).format("HH:mm")}</div>
                        </div>
                        :
                        <div className="data">{moment.duration(-(moment.duration(moment(new Date()).diff(this.props.mensagem.dt_criado)).asMinutes() - moment.duration(moment(new Date()).diff(this.props.mensagem.dt_atual)).asMinutes()), "minutes").humanize(true)}</div>
                    }
                </div>
                <div
                    className="texto"
                    dangerouslySetInnerHTML={{ __html: this.props.mensagem.mensagem }}
                ></div>
                {this.props.mensagem.anexo ? (
                    <div className="anexos">
                        <a href={this.props.mensagem.anexo} download>
                            <div className="anexo">
                                <Icon type="download" />
                                <div className="desc">Anexo</div>
                            </div>
                        </a>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default Mensagem;
