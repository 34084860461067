import React, { useState } from "react";
import { Drawer, Form, Input, Icon, notification, Select } from "antd";
import axios from "axios";

import noimage from "../../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerAdicionarParecer = ({ parecerInfo, periodoId, visible, onClose, onConfirm, form }) => {
    const { getFieldDecorator } = form;

    const [participante, setParticipante] = useState(null);
    const [parecer, setParecer] = useState("");
    const [iconLoading, setIconLoading] = useState(false);

    const guardar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                const utilizadorId = participante?.id;

                var item = new FormData();
                if (utilizadorId)
                    item.append("utilizadorId", utilizadorId);
                item.append("turmaId", localStorage.getItem("codigo_curso"));
                item.append("periodoId", periodoId);
                item.append("parecer", parecer);

                axios({
                    method: "put",
                    url: utilizadorId ? "/api/pareceres/guardar-paracer-aluno" : "/api/pareceres/guardar-paracer-geral",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Parecer adicionado!");
                        onConfirm(parecer);
                        setIconLoading(false);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível guardar o parecer!");

                        setIconLoading(false);
                    });
            }
        })
    }

    const afterVisibleChange = aberto => {
        if (aberto) {
            form.resetFields();
            if (parecerInfo.participante) {
                setParticipante(parecerInfo.participante)
                setParecer(parecerInfo.participante.parecer)
            }
            else {
                setParticipante(null)
                setParecer(parecerInfo.textoParecerGeral)
            }
        }
    }

    return (
        <>
            <Drawer
                className="drawer-adicionar-evento"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        {participante && (
                            <div className="bloco info-participante">
                                <div className="container-img">
                                    <img src={participante.foto ? participante.foto : noimage} />
                                </div>
                                <div className="info" style={{ flex: "fit-content" }}>
                                    <span className="nome">{participante.nome}</span>
                                    <p className="numero">{participante.numero}</p>
                                </div>
                            </div>)}
                        <div className="bloco">
                            <Form.Item label={participante ? "Parecer" : "Parecer geral"}>
                                {getFieldDecorator("parecer", {
                                    initialValue: parecer
                                })(
                                    <TextArea
                                        name="parecer"
                                        rows={4}
                                        style={{ height: 100 }}
                                        onChange={(event) => setParecer(event.target.value)}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" onClick={guardar} disabled={iconLoading} type="primary">
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                </div>
            </Drawer>
        </>
    );
}

const FormDrawerAdicionarParecer = Form.create({ name: "form-drawer-adicionar-parecer" })(DrawerAdicionarParecer);

export default FormDrawerAdicionarParecer;