import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Icon, notification, Modal } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class ConsultarResultados extends Component {
    static contextType = GlobalContext;
    state = {
        numero: "",
        redirect: false,
        redirectLink: "",
        iconLoading: false,
        remember: false,
        //MODAL MENSAGEM
        visibleMensagem: false
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            remember: false
        });
    };

    handleKeyPress = event => {
        if (event.key === "Enter") this.consultar(event);
    };

    consultar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                var token = this.getParameterByName("token");
                axios({
                    method: "get",
                    url: "/api/consultar-resultados/validar-email",
                    params: {
                        email: this.state.email,
                        token
                    }
                })
                    .then(response => {
                        localStorage.setItem("codigo_curso", response.data.cursoId)
                        localStorage.setItem("codigo_entidade", response.data.entidadeId)
                        localStorage.setItem("tokenConsultaResultados", token)
                        this.setState({
                            redirectLink: `/consultar/introdução-ao-javascript/${response.data.tipo}/${response.data.utilizadorId}`,
                            redirect: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível consultar os registos");
                        this.setState({
                            iconLoading: false
                        })
                    });
            }
        });
    };

    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    render() {
        const { redirect, redirectLink, iconLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Validar e-mail" marginTop0={true} />
                <div className="content">
                    <Form onSubmit={this.handleSubmit} className="login">
                        <Form.Item label="E-mail">
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Introduza o e-mail"
                                    },
                                    {
                                        type: "email",
                                        message: "Introduza um endereço de e-mail válido"
                                    }
                                ]
                            })(<Input name="email" onChange={this.handleChange} onKeyPress={this.handleKeyPress} />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Link to="#">
                                <Button type="primary" className="login-form-button" disabled={iconLoading} onClick={this.consultar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Confirmar
                                </Button>
                            </Link>
                        </Form.Item>
                    </Form>
                </div>
            </>
        );
    }
}

const ConsultarResultadosForm = Form.create({ name: "form-consultar-resultados" })(ConsultarResultados);

export default ConsultarResultadosForm;
