import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Switch, Menu, Modal, Tooltip } from "antd";
import Header from "../../User/Header";
import noimage from "../../images/noimage.png";
import axios from "axios";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";
import { GlobalContext } from "../../GlobalState";
import DrawerFicha from "./Drawer/DrawerFicha";
import Acessos from "../../Geral/Acessos/Acessos";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Fichas extends Component {
    static contextType = GlobalContext;
    state = {
        fichas: [],
        ativo: true,
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL
        fichaId: 0,
        visible: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    montarMenu = (fichaId, acesso) => {
        const divStyle = { display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" };

        if (this.state.ativo)
            return (
                <div style={divStyle}>
                    {acesso?.update && (
                        <Link
                            to="#"
                            className="botao-icon-inativar"
                            title="Inativar"
                            onClick={event => {
                                event.stopPropagation();
                                this.ativar(fichaId);
                            }}
                        >
                            <Icon type="close" />
                        </Link>)}
                </div>
            );

        return (
            <div style={divStyle}>
                {acesso?.update && (
                    <Link
                        to="#"
                        className="botao-icon-ativar"
                        title="Ativar"
                        onClick={event => {
                            event.stopPropagation();
                            this.ativar(fichaId);
                        }}
                    >
                        <Icon type="check" />
                    </Link>)}
                {acesso?.delete && (
                    <Link
                        to="#"
                        className="botao-icon-excluir"
                        title="Excluir"
                        onClick={event => {
                            event.stopPropagation();
                            this.excluir(fichaId);
                        }}
                    >
                        <Icon type="delete" />
                    </Link>)}
            </div>
        );
    };

    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-fichas",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        const acesso = this.context.acessos?.find(x => x.func === 12);
                        var fichas = [];

                        response.data.map((ficha, index) => {
                            fichas.push({
                                key: index,
                                id: ficha.id,
                                nome: ficha.nome,
                                //data: moment(ficha.data).format("DD/MM/YYYY HH:mm"),
                                opcoes: (acesso?.update || acesso?.delete) && this.montarMenu(ficha.id, acesso)
                            });
                        });

                        this.setState({
                            fichas,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    excluir = fichaId => {
        confirm({
            title: "Pretende excluir esta ficha?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-fichas/excluir",
                    params: {
                        fichaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    ativar = fichaId => {
        const ativo = this.state.ativo;
        confirm({
            title: ativo ? "Pretende inativar esta ficha?" : "Pretende ativar esta ficha?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-fichas/ativar",
                    params: {
                        fichaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Ficha inativada!" : "Ficha ativada!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a ficha!" : "Não foi possível ativar a ficha!"
                        );
                    });
            }
        });
    };

    onRowClick = fichaId => {
        this.setState({
            fichaId,
            visible: true
        });
    };

    render() {
        const {
            ativo,
            fichas,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //MODAL
            visible,
            fichaId
        } = this.state;

        const columns = [
            {
                title: "Estrutura",
                dataIndex: "nome"
            },
            //{
            //    title: "Ultima edição",
            //    dataIndex: "data"
            //},
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const acesso = this.context.acessos?.find(x => x.func === 12);

        if (redirect)
            return <Redirect to={redirectLink} />;

        return (
            <div>
                <Acessos funcionalidadeId={12} />
                <Header titulo="Estruturas das fichas de disciplinas" />
                <div className="container container-body">
                    <div className="bloco-categorias">
                        <div className="bloco-tabela">
                            <div className="controlos" style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    {acesso?.create && (
                                        <button
                                            className="botao-principal botao-principal-redondo"
                                            title="Adicionar"
                                            onClick={() => this.setState({ fichaId: 0, visible: true })}
                                            disabled={!this.state.ativo}
                                        >
                                            +
                                        </button>)}
                                </div>
                                <div className="filtros" style={{ paddingTop: 8 }}>
                                    <Switch
                                        checkedChildren="Ativos"
                                        unCheckedChildren="Inativos"
                                        defaultChecked
                                        onChange={this.handleChangeSwitch}
                                    />
                                </div>
                            </div>
                            <Table
                                id="tabela_categorias"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={fichas}
                                pagination={false}
                                rowClassName={acesso?.read ? "clickeble-row" : ""}
                                onRow={acesso?.read ?
                                    (record, index) => {
                                        return {
                                            onClick: () => this.onRowClick(record.id)
                                        };
                                    }
                                    :
                                    () => { }}
                                locale={{ emptyText: "Não existem dados!" }}
                            />
                        </div>
                    </div>
                </div>
                <DrawerFicha
                    ativo={ativo}
                    visible={visible}
                    fichaId={fichaId}
                    onClose={() => this.setState({ visible: false })}
                    atualizar={() => {
                        this.listar();
                        this.setState({ visible: false });
                    }}
                    update={acesso?.update}
                />
            </div>
        );
    }
}

export default Fichas;
