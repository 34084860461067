import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Checkbox, Icon, notification } from "antd";
import axios from "axios";

const DrawerEstabelecimentos = ({ utilizadorId, visible, onClose, onUpdate, form }) => {
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [selecionados, setSelecionados] = useState([]);
    const [iconLoading, setIconLoading] = useState(false);

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description
        });
    };

    const listar = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos"
        })
            .then(response => {
                setEstabelecimentos(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const listarSelecionados = () => {
        axios({
            method: "get",
            url: "/api/gestao-utilizadores-organismos/listar",
            params: {
                utilizadorId
            }
        })
            .then(response => {
                setSelecionados(response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const confirma = () => {
        if (selecionados.length > 0) {
            setIconLoading(true);

            var item = new FormData();
            item.append("id_utilizador", utilizadorId);
            item.append("estabelecimentos", JSON.stringify(selecionados));

            axios({
                method: "put",
                url: "/api/gestao-utilizadores-organismos/alterar",
                data: item
            })
                .then(response => {
                    setIconLoading(false);
                    openNotificationWithIcon("success", "Sucesso", "Estabelecimentos guardados");
                    onUpdate();
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });
                    setIconLoading(false);
                    openNotificationWithIcon("error", "Erro", error.response.data);
                });
        }
        else {
            openNotificationWithIcon("error", "Erro", "Não foram selecionados estabelecimentos!");
        }
    }

    const onChange = (sels) => {
        setSelecionados(selecionados => sels.map(selecionado => {
            const jaSelecionado = selecionados.find(x => x.id === selecionado);

            return {
                id: selecionado,
                principal: jaSelecionado?.principal || sels.length === 1
            }
        }));
    }

    const afterVisibleChange = visible => {
        form.resetFields();
        if (visible)
            listarSelecionados();
        else
            setSelecionados([]);
    }

    const marcarComoPrincipal = (estabelecimentoId) => {
        let tempSelecionados = selecionados.filter(x => x.id !== estabelecimentoId).map(sel => {
            return {
                id: sel.id,
                principal: false
            }
        });
        const selecionado = {
            id: estabelecimentoId,
            principal: true
        }
        setSelecionados([...tempSelecionados, selecionado])
    }

    const montrarBotaoCheck = (estabelecimento) => {
        const selecionado = selecionados.find(x => x.id === estabelecimento.id);

        if (selecionado) {
            if (selecionado.principal)
                return <Link className="botao-icon-check" to="#" title="Por defeito" onClick={event => event.stopPropagation()}>
                    <Icon type="check" />
                </Link>
            else
                return <Link className="botao-icon-uncheck" to="#" title="Marcar estabelecimento por defeito" onClick={event => { event.stopPropagation(); marcarComoPrincipal(estabelecimento.id); }}></Link>
        }
        else {
            return <Link className="botao-icon-uncheck" to="#" title="Marcar estabelecimento por defeito" style={{ cursor: "default" }} onClick={event => event.stopPropagation()}></Link>
        }
    }

    useEffect(() => {
        listar();
    }, [])

    return (
        <Drawer
            className="drawer-inscrever drawer-perfis"
            //title="Selecionar Estabelecimentos"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            afterVisibleChange={afterVisibleChange}
        >
            <div className="bloco-drawer-associar">
                <div className="bloco" style={{ marginTop: 40 }}>
                    <h3 className="titulo-separador" style={{ display: "flex", justifyContent: "space-between" }}><span>Lista</span><span>Principal</span></h3>
                    <Checkbox.Group className="lista-estabelecimentos" style={{ width: '100%' }} value={selecionados.map(x => x.id)} onChange={onChange}>
                        {estabelecimentos.map(estabelecimento => (
                            <div key={estabelecimento.id} style={{ marginBottom: "20px" }}>
                                <Checkbox value={estabelecimento.id}>
                                    {estabelecimento.nome}
                                    {montrarBotaoCheck(estabelecimento)}
                                </Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </div>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={onClose}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button className="botao-principal" disabled={iconLoading} type="primary" onClick={confirma}>
                    {iconLoading ? <Icon type="loading" /> : null}
                    Salvar
                </button>
            </div>
        </Drawer>
    );
}
const FormDrawerEstabelecimentos = Form.create({ name: "drawer-estabelecimentos" })(DrawerEstabelecimentos);

export default FormDrawerEstabelecimentos;