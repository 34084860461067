import Tabela from "./Tabela";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import { DataContext } from '../Contexts/DataProvider';

export default function AlunosPioresNotas (){
    const {dataAlunosCompioresNotas} = useContext(DataContext);
    const columnsAlunosCompioresNotas = [
        {
            title: 'Aluno',
            dataIndex: 'aluno',
            key: 'aluno',
            width: "25%",
        },
        {
            title: 'Escola',
            dataIndex: 'escola',
            key: 'escola',
            width: "25%",
        },
        {
            title: 'Média de Notas',
            dataIndex: 'mediaAnual',
            key: 'mediaAnual',
            width: "25%",
        },
        {
            title: 'Edição',
            dataIndex: 'edicao',
            key: 'edicao',
            width: "25%",
        },
    ];
    
    return(

        dataAlunosCompioresNotas &&  <Tabela data={dataAlunosCompioresNotas} columns={columnsAlunosCompioresNotas} titulo="Alunos com baixo desempenho"></Tabela>
        
    );
    
}