import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table, Icon } from 'antd';
import axios from "axios";

const TabelaGrupos = ({ record }) => {
    const [expandedRows, setExpandedRows] = useState([]);

    const onExpand = (expanded, record) => {
        const expandedKeys = [...expandedRows];
        if (expanded) {
            expandedKeys.push(record.key);
        } else {
            const index = expandedKeys.indexOf(record.key);
            if (index !== -1) {
                expandedKeys.splice(index, 1);
            }
        }
        setExpandedRows(expandedKeys);
    }

    const isRowExpanded = (record) => {
        return expandedRows.includes(record.key);
    };

    const cols = [
        {
            title: "",
            dataIndex: "nome",
            className: "bold-field"
        }
    ];

    const colsSub = [
        {
            title: "",
            dataIndex: "nome"
        },
        {
            title: "",
            dataIndex: "nota",
            render: (text, record) => <span className={`tags-aproveitamento ${record.cor}`}>{text}</span>
        }
    ];

    return (<Table
        showHeader={false}
        className="tabela-aproveitamento"
        columns={cols}
        pagination={false}
        dataSource={record.subItems}
        onExpand={onExpand}
        rowClassName={(record, index) =>
            isRowExpanded(record) ? 'parent-row-expanded' : ''
        }
        expandedRowRender={record =>
            <Table
                showHeader={false}
                className="tabela-aproveitamento"
                columns={colsSub}
                pagination={false}
                dataSource={record.subItems}
            />
        }
    />)
}

const Detalhe = ({ match, record }) => {
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);

    const carregarDados = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/user-detalhe/carregar-dados-aproveitamento-conceitos-periodo",
            params: {
                code: match.params.code,
                disciplinaId: record.id
            }
        })
            .then(response => {
                setDados(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                setLoading(false);
            });
    };

    const onExpand = (expanded, record) => {
        const expandedKeys = [...expandedRows];
        if (expanded) {
            expandedKeys.push(record.key);
        } else {
            const index = expandedKeys.indexOf(record.key);
            if (index !== -1) {
                expandedKeys.splice(index, 1);
            }
        }
        setExpandedRows(expandedKeys);
    }

    const isRowExpanded = (record) => {
        return expandedRows.includes(record.key);
    };

    const cols = [
        {
            title: "",
            dataIndex: "nome",
            className: "bold-field"
        }
    ];

    useEffect(() => {
        if (record)
            carregarDados();
    }, [record])

    return (
        <Table
            showHeader={false}
            className="tabela-aproveitamento"
            columns={cols}
            pagination={false}
            dataSource={dados}
            loading={{
                spinning: loading,
                indicator: (
                    <div className="loading-data-table">
                        <div className="loading" />
                    </div>
                )
            }}
            onExpand={onExpand}
            rowClassName={(record, index) =>
                isRowExpanded(record) ? 'parent-row-expanded' : ''
            }
            expandedRowRender={record => <TabelaGrupos record={record} />}
        />
    );
}

export default withRouter(Detalhe);