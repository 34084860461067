import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Select, Dropdown, Menu, Icon, Modal, Rate, notification, Switch, Drawer, Form } from "antd";
import Header from "../User/Header";
import noimage from "../images/noimage.png";
import axios from "axios";
import moment from "moment";
import BotaoProcurar from "../Geral/_Aux/BotaoProcurar";
import { GlobalContext } from "../GlobalState";
import DrawerInscricao from "./_Aux/DrawerInscricao";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class SistemaInformacao extends Component {
    static contextType = GlobalContext;
    state = {
        //FILTROS
        pesquisa: "",
        filtro: 1,
        ativo: true,
        //TABELA
        loading_table: true,
        cursos: [],
        cursosMobile: [],
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL RATING
        modalRating: false,
        disciplina: false,
        cod_curso: "",
        nome_curso: "",
        //DRAWER INSCRICAO
        inscricaoId: 0,
        anexo: "",
        nr_aluno: "",
        nome: "",
        email: "",
        contacto: "",
        descricao: "",
        habilitacao: "",
        paginaPessoal: "",
        dt_nascimento: "",
        naturalidade: "",
        sexo: "",
        estado_civil: "",
        cc: "",
        dt_ccvalidade: "",
        nif: "",
        cep: "",
        localidade: "",
        morada: "",
        pais: "",
        motivo: "",
        responsavel_1: "",
        responsavel_2: "",
        inscricao_visible: false,
        nomeID: "",
        ccID: "",
        dt_ccvalidadeID: "",
        ficheiro: [],
        ficheiro_inscricao: "",
        ficheiro_inscricao_nome: "",
        dt_ficheiro_inscricao: "",
        curso_ficha_inscricao: "",
        curso_ficha_inscricao_nome: "",
        //MODAL RATING
        cursoid: 0,
        modalRating: false,
        ratingEnviado: false,
        rating: null,
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalhePeriodo: "",
        detalheEstado: "",
        detalheClassificacao: "",
        detalheDocumento: ""
    };

    UNSAFE_componentWillMount() {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));

            this.setState({
                pesquisa: opcaoInfo.pesquisa,
                ativo: opcaoInfo.ativo,
                pagination: opcaoInfo.pagination,
            }, () => this.chamarListagens())
        }
        else {
            this.chamarListagens();
        }
    }

    chamarListagens = () => {
        this.totalCursos();
        this.listarCursos();
        localStorage.removeItem("modulo_aberto");
    }

    //VALIDA SE O UTILIZADOR ESTA AUTENTICADO
    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                this.listarCursos();
            }
        );
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    this.totalCursos();
                    this.listarCursos();
                }
            );
        }
    };

    handleChangeRating = value => {
        this.setState({ rating: value });
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                this.totalCursos();
                this.listarCursos();
            }
        );
    };

    onClick = () => {
        this.context.atualizarState({
            lista_modulos: [],
            lista_disciplinas: []
        });
    };

    consultar = cursoId => {
        axios({
            method: "get",
            url: "/api/sistema-informacao/consultar-inscricao",
            params: {
                cursoId
            }
        })
            .then(response => {
                this.setState({
                    inscricaoId: response.data.id,
                    nr_aluno: response.data.nr_aluno,
                    nome: response.data.nome,
                    email: response.data.email,
                    contacto: response.data.contacto ? response.data.contacto : "",
                    descricao: response.data.descricao ? response.data.descricao : "",
                    habilitacao: response.data.habilitacao ? response.data.habilitacao : "",
                    paginaPessoal: response.data.paginaPessoal ? response.data.paginaPessoal : "",
                    dt_nascimento: response.data.dt_nascimento ? moment(response.data.dt_nascimento).format("DD/MM/YYYY") : null,
                    naturalidade: response.data.naturalidade ? response.data.naturalidade : "",
                    sexo: response.data.sexo ? response.data.sexo : undefined,
                    estado_civil: response.data.estado_civil ? response.data.estado_civil : undefined,
                    cc: response.data.cc ? response.data.cc : "",
                    dt_ccvalidade: response.data.dt_ccvalidade ? moment(response.data.dt_ccvalidade).format("DD/MM/YYYY") : null,
                    nif: response.data.nif ? response.data.nif : "",
                    cep: response.data.cep ? response.data.cep : "",
                    localidade: response.data.localidade ? response.data.localidade : "",
                    morada: response.data.morada ? response.data.morada : "",
                    pais: response.data.pais ? response.data.pais : undefined,
                    motivo: response.data.motivo ? response.data.motivo : "",
                    responsavel_1: response.data.responsavel_1 ? response.data.responsavel_1 : "",
                    responsavel_2: response.data.responsavel_2 ? response.data.responsavel_2 : "",
                    inscricao_visible: true,
                    nomeID: response.data.nomeCompletoID,
                    ccID: response.data.docIdentificacaoID,
                    dt_ccvalidadeID: moment(response.data.identificacaoValidadeID).format("DD/MM/YYYY"),
                    ficheiro_inscricao: response.data.ficheiro_inscricao,
                    ficheiro_inscricao_nome: response.data.ficheiro_inscricao_nome,
                    dt_ficheiro_inscricao: response.data.dt_ficheiro_inscricao ? moment(response.data.dt_ficheiro_inscricao).format("DD/MM/YYYY") : null,
                    curso_ficha_inscricao: response.data.curso_ficha_inscricao,
                    curso_ficha_inscricao_nome: response.data.curso_ficha_inscricao_nome,
                });
            })
            .catch(() => {  });
    };

    abrirDetalhe = (curso) => {
        const detalhePeriodo = <div>
            <span>{
                curso.dt_fim ?
                    `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                    :
                    moment(curso.dt_inicio).format("DD/MM/YYYY")
            }</span>
            {curso.fechado ?
                <div className="tag-fechado">Fechado ({curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}</div>
                : ""}
        </div>;
        const detalheClassificacao = curso.classificacao;
        const detalheDocumento = curso.documento ? (<Link to="#" className="botao-icon-download" title="Descarregar certificado" onClick={() => this.downloadFicheiro(curso.id)}>
            <Icon type="download" />
        </Link>)
            : "";

        this.setState({
            visibleDetalhe: true,
            detalhePeriodo,
            //detalheEstado,
            detalheClassificacao,
            detalheDocumento
        })
    }

    // MONTA O MENU DE OPÇÕES POR CADA CURSO
    montarMenu = curso => {
        if (this.state.ativo) {
            return (
                <Menu>
                    {(curso.habilitar_gamificacao && this.context.gamificacao_entidade) ?
                        <Menu.Item>
                            <Link
                                to={`/ranking`}
                                onClick={() => {
                                    localStorage.removeItem("nome_curso_pai");
                                    localStorage.setItem("cod_curso", curso.codigo);
                                    localStorage.setItem("nome_curso", curso.nome);
                                    localStorage.setItem("codigo_curso", curso.id);
                                    localStorage.setItem("curso_fechado", curso.fechado);
                                }}
                            >
                                Ranking
                    </Link>
                        </Menu.Item>
                        : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => {
                            this.consultar(curso.id);
                            localStorage.removeItem("nome_curso_pai");
                            localStorage.setItem("cod_curso", curso.codigo);
                            localStorage.setItem("nome_curso", curso.nome);
                            localStorage.setItem("codigo_curso", curso.id);
                            localStorage.setItem("curso_fechado", curso.fechado);
                        }}>
                            Inscrição
                    </Link>
                    </Menu.Item>
                    {curso.fechado ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => this.abrirModalRating(curso.rating, curso.id)}
                            >
                                Rating
                        </Link>
                        </Menu.Item>
                        : null}
                </Menu>
            );
        }
        else {
            return (
                <Menu>
                    {(curso.habilitar_gamificacao && this.context.gamificacao_entidade) ?
                        <Menu.Item>
                            <Link
                                to={`/ranking`}
                                onClick={() => {
                                    localStorage.removeItem("nome_curso_pai");
                                    localStorage.setItem("cod_curso", curso.codigo);
                                    localStorage.setItem("nome_curso", curso.nome);
                                    localStorage.setItem("codigo_curso", curso.id);
                                    localStorage.setItem("curso_fechado", curso.fechado);
                                }}
                            >
                                Ranking
                    </Link>
                        </Menu.Item>
                        : null}
                </Menu>
            );
        }
    };

    // MONTA O MENU DE OPÇÕES POR CADA CURSO
    montarMenuMobile = curso => {
        if (this.state.ativo) {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(curso)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    {(curso.habilitar_gamificacao && this.context.gamificacao_entidade) ?
                        <Menu.Item>
                            <Link
                                to={`/ranking`}
                                onClick={() => {
                                    localStorage.removeItem("nome_curso_pai");
                                    localStorage.setItem("cod_curso", curso.codigo);
                                    localStorage.setItem("nome_curso", curso.nome);
                                    localStorage.setItem("codigo_curso", curso.id);
                                    localStorage.setItem("curso_fechado", curso.fechado);
                                }}
                            >
                                Ranking
                    </Link>
                        </Menu.Item>
                        : null}
                    <Menu.Item>
                        <Link to="#" onClick={() => {
                            this.consultar(curso.id);
                            localStorage.removeItem("nome_curso_pai");
                            localStorage.setItem("cod_curso", curso.codigo);
                            localStorage.setItem("nome_curso", curso.nome);
                            localStorage.setItem("codigo_curso", curso.id);
                            localStorage.setItem("curso_fechado", curso.fechado);
                        }}>
                            Inscrição
                    </Link>
                    </Menu.Item>
                    {curso.fechado ?
                        <Menu.Item>
                            <Link
                                to="#"
                                onClick={() => this.abrirModalRating(curso.rating, curso.id)}
                            >
                                Rating
                        </Link>
                        </Menu.Item>
                        : null}
                </Menu>
            );
        }
        else {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.abrirDetalhe(curso)}>
                            Detalhe
                        </Link>
                    </Menu.Item>
                    {(curso.habilitar_gamificacao && this.context.gamificacao_entidade) ?
                        <Menu.Item>
                            <Link
                                to={`/ranking`}
                                onClick={() => {
                                    localStorage.removeItem("nome_curso_pai");
                                    localStorage.setItem("cod_curso", curso.codigo);
                                    localStorage.setItem("nome_curso", curso.nome);
                                    localStorage.setItem("codigo_curso", curso.id);
                                    localStorage.setItem("curso_fechado", curso.fechado);
                                }}
                            >
                                Ranking
                    </Link>
                        </Menu.Item>
                        : null}
                </Menu>
            );
        }
    };

    abrirModalRating = (rating, cursoid) => {
        this.setState({
            rating,
            cursoid,
            ratingEnviado: rating !== null,
            modalRating: true
        })
    }

    //TOTAL CURSOS DA ENTIDADE
    totalCursos = () => {
        axios({
            method: "get",
            url: "/api/sistema-informacao/total-cursos",
            params: {
                pesquisa: this.state.pesquisa,
                filtro: this.state.filtro,
                ativo: this.state.ativo
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //LISTA TODOS OS CURSOS DA ENTIDADE
    listarCursos = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/sistema-informacao/listar-cursos",
                    params: {
                        pesquisa: this.state.pesquisa,
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current,
                        filtro: this.state.filtro,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var cursos = [];
                        var cursosMobile = [];

                        response.data.map((curso, index) => {
                            cursos.push({
                                key: index,
                                id: curso.id,
                                edicao: (
                                    <div>
                                        <span>{curso.edicao}</span>
                                        {curso.ano || curso.turma ?
                                            <span style={{ display: "block" }}>{`${(curso.ano ? curso.ano : "")} ${(curso.turma ? curso.turma : "")}`}</span>
                                            : null}
                                    </div>
                                ),
                                curso: (
                                    <>
                                        <div className="imagem-curso">
                                            <img src={curso.imagem ? curso.imagem : noimage} />
                                        </div>
                                        <div className="nome-curso">
                                            <span className="titulo-curso">
                                                {curso.codigo} - {curso.nome}
                                            </span>
                                        </div>
                                    </>
                                ),
                                codigo: curso.codigo,
                                nome: curso.nome,
                                periodo: (
                                    <div>
                                        <span>{
                                            curso.dt_fim ?
                                                `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                                                :
                                                moment(curso.dt_inicio).format("DD/MM/YYYY")
                                        }</span>
                                        {curso.fechado ?
                                            <div className="tag-fechado">Fechado ({curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}</div>
                                            : ""}
                                    </div>
                                ),
                                dt_inicio: curso.dt_inicio,
                                dt_fim: curso.dt_fim,
                                classificacao: curso.classificacao,
                                descarregar: curso.documento ? (
                                    <Link to="#" className="botao-icon-download" title="Descarregar certificado" onClick={() => this.downloadFicheiro(curso.id)}>
                                        <Icon type="download" />
                                    </Link>
                                ) : (
                                    ""
                                    ),
                                opcoes: <Dropdown
                                    overlay={this.montarMenu(curso)}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>,
                                subcursos: curso.subcursos ? curso.subcursos : []
                            });

                            cursosMobile.push({
                                key: index,
                                id: curso.id,
                                curso: (<>
                                        <div className="imagem-curso">
                                            <img src={curso.imagem ? curso.imagem : noimage} />
                                        </div>
                                        <div className="nome-curso">
                                            {curso.edicao ?
                                                <span className="texto-edicao">
                                                    {`${curso.edicao}${(curso.ano && curso.turma ? ` / ${curso.ano} ${curso.turma}` : `${(curso.ano || curso.turma ? " / " : "")} ${(curso.ano ? curso.ano : "")} ${(curso.turma ? curso.turma : "")}`)}`}
                                                </span> : null}
                                            <span className="titulo-curso">
                                                {curso.codigo} - {curso.nome}
                                            </span>
                                        </div>
                                    </>),
                                codigo: curso.codigo,
                                nome: curso.nome,
                                dt_inicio: curso.dt_inicio,
                                dt_fim: curso.dt_fim,
                                opcoes: <Dropdown
                                    overlay={this.montarMenuMobile(curso)}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>,
                                subcursos: curso.subcursos ? curso.subcursos : []
                            });
                        });

                        this.setState({
                            cursos,
                            cursosMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        var formatos = ["pdf"];
        var valido = false;

        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) valido = true;
        }

        return valido;
    };

    //SUBMETER RATING
    enviarRating = () => {
        axios({
            method: "put",
            url: "/api/sistema-informacao/avaliar-curso",
            params: {
                cursoid: this.state.cursoid,
                qualificacao: this.state.rating
            }
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Rating submetido!");
                this.listarCursos();

                this.setState({
                    ratingEnviado: true,
                    modalRating: false
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível submeter o rating!");
            });
    };

    downloadFicheiro = (cursoId) => {
        axios({
            method: "post",
            url: "/api/sistema-informacao/download-ficheiro",
            params: {
                cursoId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    onRowClick = (cursoNome, cursoId, cursoPai) => {
        this.context.atualizarState({
            lista_modulos: [],
            lista_disciplinas: []
        });
        localStorage.setItem("codigo_curso", cursoId);
        localStorage.setItem("opcao_voltar", JSON.stringify({
            pesquisa: this.state.pesquisa,
            ativo: this.state.ativo,
            link_voltar: "/sistema-informacao",
            link_voltar_curso: "",
            pagination: this.state.pagination
        }))
        this.setState({
            redirect: true,
            redirectLink: `/curso/${cursoNome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${cursoPai ? "curso-cursos" : "curso-modulos"}`
        })
    }

    render() {
        const {
            //TABELA
            loading_table,
            cursos,
            cursosMobile,
            //PAGINATION
            pagination,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER INSCRICAO
            inscricaoId,
            anexo,
            nr_aluno,
            nome,
            email,
            contacto,
            habilitacao,
            paginaPessoal,
            descricao,
            dt_nascimento,
            naturalidade,
            sexo,
            estado_civil,
            cc,
            dt_ccvalidade,
            nif,
            cep,
            localidade,
            morada,
            pais,
            motivo,
            responsavel_1,
            responsavel_2,
            inscricao_visible,
            nomeID,
            ccID,
            dt_ccvalidadeID,
            ficheiro,
            ficheiro_inscricao,
            ficheiro_inscricao_nome,
            dt_ficheiro_inscricao,
            curso_ficha_inscricao,
            curso_ficha_inscricao_nome,
            //DRAWER DETALHE
            visibleDetalhe,
            detalhePeriodo,
            detalheEstado,
            detalheClassificacao,
            detalheDocumento
        } = this.state;

        const columnsCursos = [
            {
                title: "Edição",
                dataIndex: "edicao",
                className: "td-150"
            },
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-curso td-265"
            },
            {
                title: "Periodo",
                dataIndex: "periodo",
                className: "td-300"
            },
            {
                title: "Classificação",
                dataIndex: "classificacao"
            },
            {
                title: "",
                dataIndex: "descarregar",
                className: "td-50"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsCursosMobile = [
            {
                title: "Curso",
                dataIndex: "curso",
                className: "td-curso td-265"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const propsInscricao = {
            multiple: false,
            onRemove: file => {
                this.setState({
                    ficheiro: []
                })
            },
            defaultFileList: ficheiro,
            beforeUpload: file => {
                if (!this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    ficheiro: [file]
                }))

                return false;
            },
            ficheiro
        };

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Cursos" />
                <div className="container container-body">
                    <div className="bloco-cursos">
                        <div className="bloco-tabela">
                            <div className="filtros filtros-tabela-cursos" style={{ justifyContent: "space-between" }}>
                                <div className="bloco-esquerda">
                                    <BotaoProcurar
                                        pesquisa={this.state.pesquisa}
                                        tem_placeholder
                                        placeholder="Procurar por Curso"
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Switch
                                        checkedChildren="Ativos"
                                        unCheckedChildren="Inativos"
                                        defaultChecked
                                        checked={this.state.ativo}
                                        onChange={this.handleChangeSwitch}
                                    />
                                    {this.context.diario_entidade &&
                                        <Link to="/sistema-informacao/consultar" className="botao-icon-historico" title="Histórico" style={{ marginLeft: 10 }}>
                                            <Icon type="solution" />
                                        </Link>}
                                </div>
                            </div>
                            <Table
                                id="tabela-cursos"
                                className="tabela-cursos-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsCursos}
                                dataSource={cursos}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem dados!" }}
                                onChange={this.handleChangeTable}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.nome, record.id, false)
                                    };
                                }}
                            />
                            <Table
                                id="tabela-cursos"
                                className="tabela-cursos-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsCursosMobile}
                                dataSource={cursosMobile}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem dados!" }}
                                onChange={this.handleChangeTable}
                                rowClassName="clickeble-row"
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => this.onRowClick(record.nome, record.id, false)
                                    };
                                }}
                            />
                        </div>
                    </div>
                </div>
                <DrawerInscricao
                    visible={inscricao_visible}
                    onClose={() => this.setState({ inscricao_visible: false })}
                    inscricaoId={inscricaoId}
                    anexo={anexo}
                    nome={nome}
                    nr_aluno={nr_aluno}
                    email={email}
                    contacto={contacto}
                    descricao={descricao}
                    habilitacao={habilitacao}
                    paginaPessoal={paginaPessoal}
                    dt_nascimento={dt_nascimento}
                    naturalidade={naturalidade}
                    sexo={sexo}
                    estado_civil={estado_civil}
                    cc={cc}
                    dt_ccvalidade={dt_ccvalidade}
                    nif={nif}
                    cep={cep}
                    localidade={localidade}
                    morada={morada}
                    pais={pais}
                    motivo={motivo}
                    responsavel_1={responsavel_1}
                    responsavel_2={responsavel_2}
                    nomeID={nomeID}
                    ccID={ccID}
                    dt_ccvalidadeID={dt_ccvalidadeID}
                    ficheiro={ficheiro}
                    ficheiro_inscricao={ficheiro_inscricao}
                    ficheiro_inscricao_nome={ficheiro_inscricao_nome}
                    dt_ficheiro_inscricao={dt_ficheiro_inscricao}
                    curso_ficha_inscricao={curso_ficha_inscricao}
                    curso_ficha_inscricao_nome={curso_ficha_inscricao_nome}
                    fecharDrawerInscricao={this.fecharDrawerInscricao}
                    propsInscricao={propsInscricao}
                />
                <Modal
                    title="Rating"
                    className={this.state.ratingEnviado ? "rating-submetido" : ""}
                    visible={this.state.modalRating}
                    onOk={this.enviarRating}
                    okText="Confirmar"
                    onCancel={() => this.setState({ modalRating: false })}
                    cancelText="Fechar"
                >
                    <div style={{ textAlign: "center", marginBottom: 20 }}>
                        <Rate value={this.state.rating} onChange={this.handleChangeRating} disabled={this.state.ratingEnviado} />
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-curso"
                    //title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Periodo">
                                    {detalhePeriodo}
                                </Form.Item>
                                {detalheEstado &&
                                    <Form.Item label="Estado">
                                        {detalheEstado}
                                    </Form.Item>}
                                {detalheClassificacao &&
                                    <Form.Item label="Classificação">
                                        {detalheClassificacao}
                                    </Form.Item>}
                                {detalheDocumento &&
                                    <Form.Item label="Certificado">
                                        {detalheDocumento}
                                    </Form.Item>}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default SistemaInformacao;
