import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";
import { Link } from "react-router-dom";

const ConteudoResumo = props => {
    const context = useContext(GlobalContext);
    const { modulo, aula } = JSON.parse(localStorage.getItem("questionario_" + localStorage.getItem("codigo_aula")));

    return (
        <div className="container">
            <div className="bloco-resumo">
                <div className="resumo">
                    <div className="header">
                        <i className="fas fa-check-circle" />
                        <span className="titulo">Parabéns!</span>
                        <span className="descricao">Questionário concluído</span>
                    </div>
                    <div className="apresentacao">
                        <span className="nome-modulo">{modulo}</span>
                        <span className="aula">
                            {aula}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ConteudoResumo);
