import React, { Component } from "react";
import { Link, Redirect, matchPath } from "react-router-dom";
import Header from "../../User/Header";
import { Icon, Popover, notification, Modal } from "antd";
import DrawerCalendarioAtividade from "./Drawers/DrawerCalendarioAtividade";
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Detalhe extends Component {
    state = {
        calendarioId: 0,
        nome: "",
        edicao: "",
        tipo: [],
        nr_modulos: 0,
        inicio: "",
        termino: "",
        listaMeses: [],
        atividades: [],
        hoverId: 0,
        //DRAWER
        atividadeId: 0,
        visible: false,
    };

    UNSAFE_componentWillMount() {
        this.carregarCalendarioInfo();
    }

    carregarCalendarioInfo = () => {
        axios({
            method: "get",
            url: "api/gestao-calendario-eventos/calendario-info",
            params: {
                calendarioCode: this.props.match.params.code
            }
        })
            .then(response => {
                const info = response.data;

                const dataInicio = moment(info.inicio);
                const dataTermino = moment(info.termino);
                var listaMeses = [];

                if (dataInicio < dataTermino) {
                    var data = dataInicio.startOf('month');

                    while (data < dataTermino.endOf('month')) {
                        listaMeses.push({
                            mes: data.month(),
                            ano: data.format('YYYY')
                        });
                        data.add(1, 'month');
                    }
                }

                this.setState({
                    calendarioId: info.id,
                    nome: info.nome,
                    inicio: moment(info.inicio).format("DD/MM/YYYY"), 
                    termino: moment(info.termino).format("DD/MM/YYYY"), 
                    listaMeses
                }, () => this.carregarAtividades());
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    carregarAtividades = () => {
        axios({
            method: "get",
            url: "/api/gestao-calendario-eventos-atividades",
            params: {
                calendarioId: this.state.calendarioId
            }
        })
            .then(response => {
                this.setState({
                    atividades: response.data
                })
            })
            .catch(() => { });
    }

    showDrawer = () => {
        this.setState({
            atividadeId: 0,
            visible: true
        })
    }

    onClose = () => {
        this.setState({
            visible: false
        });
    };

    montarAtividades = () => {
        const atividades = this.state.atividades;

        if (atividades.length > 0) {
            let html = atividades.map(atividade => {
                return (<li key={atividade.id}>
                    <Popover placement="right" content={
                        <span className="controlos-item-estrutura" style={{ display: "flex", gap: 5 }}>
                            <Link className="botao-icon-editar" to="#" title="Editar registo" onClick={() => this.setState({ atividadeId: atividade.id, visible: true })}>
                                <Icon type="edit" />
                            </Link>
                            <Link className="botao-icon-excluir" to="#" title="Excluir registo" onClick={() => this.excluir(atividade.id)}>
                                <Icon type="delete" />
                            </Link>
                        </span >}
                        visible={this.state.hoverId === atividade.id}
                        onVisibleChange={(visible) => this.setState({ hoverId: visible ? atividade.id : 0 })}
                    >
                        <span style={{ cursor: "pointer" }}>{atividade.nome} ({moment(atividade.inicio).format("DD/MM/YYYY")} {atividade.hr_inicio} - {atividade.hr_termino})</span>
                    </Popover>
                </li>
                );
            })

            return html;
        }
    }

    excluir = atividadeId => {
        confirm({
            title: "Pretende excluir esta atividade?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-calendario-eventos-atividades/excluir",
                    params: {
                        atividadeId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Atividade excluida!");
                        this.carregarAtividades();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir esta atividade!");
                    });
            }
        });
    };

    render() {
        const {
            calendarioId,
            nome,
            edicao,
            tipo,
            nr_modulos,
            inicio,
            termino,
            //DRAWER
            atividadeId,
            visible,
        } = this.state;

        return (
            <>
                <Header titulo={`${ nome } ( ${ inicio } - ${ termino } )`} />
                <div className="container container-body">
                    <div className="dashboard">
                        <div className="bloco-flex" style={{ display: "block" }}>
                            <div className="controlos" style={{ display: "flex", justifyContent: "space-between" }}>
                                <button className="botao-principal botao-principal-redondo" title="Adicionar" onClick={this.showDrawer}>
                                    +
                                </button>
                                <Link to="/gestao-calendarios-eventos" className="botao-secundario botao-secundario-redondo" title="Voltar" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Icon type="arrow-left" />
                                </Link>
                            </div>
                        </div>
                        <div className="bloco">
                            <div>
                                <ul>
                                    {this.montarAtividades()}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <DrawerCalendarioAtividade
                        inicioCalendario={this.state.inicio}
                        terminoCalendario={this.state.termino}
                        calendarioId={calendarioId}
                        atividadeId={atividadeId}
                        visible={visible}
                        onClose={() => this.setState({ visible: false })}
                        atualizar={() => { this.setState({ visible: false }); this.carregarAtividades(); }}
                    />
                </div>
            </>
        );
    }
}

export default Detalhe;
