import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import Navbar from "./Navbar/Navbar";
import Home from "./HomeConteudo/HomeConteudo";
import TesteDropBox from "./TesteDropBox";
import Login from "../Acessos/Login";
import AlterarSenha from "../Acessos/AlterarSenha";
import TarefasTemp from "../Home/TarefasTemp";
import LoginAuto from "../Acessos/LoginAuto";
import LoginAutoV2 from "../Acessos/LoginAutoV2";
import RecuperarPassword from "../Acessos/RecuperarPassword";
import AlterarPasswordEmail from "../Acessos/AlterarPassword";
import ConfirmarEmail from "../Acessos/ConfirmarEmail";
import EntidadeRegistarPasso1 from "../Acessos/Entidade/RegistoPasso1";
import EntidadeRegistarPasso2 from "../Acessos/Entidade/RegistoPasso2";
import RegistoConclusao from "../Acessos/Entidade/RegistoConclusao";
import UtilizadorRegistarPasso1 from "../Acessos/Utilizador/RegistoPasso1";
import UtilizadorRegistarPasso2 from "../Acessos/Utilizador/RegistoPasso2";
import UtilizadorRegistoConclusao from "../Acessos/Utilizador/RegistoConclusao";
import SuporteContacto from "../Suporte/Contacto";
import TermosCondicoes from "../Termos/TermosCondicoes";
import PoliticaPrivacidade from "../Termos/PoliticaPrivacidade";
import Pagina404 from "../Geral/Pagina404";
import Footer from "./Footer/Footer";
import Unsubscribe from "../Geral/Unsubscribe";
import axios from "axios";
import logo from "../images/logos/Logo sem nome.png";

class Layout extends Component {
    static contextType = GlobalContext;
    state = {
        redessociais: [],
        fundoBranco: false,
        semIniciarSessao: false,
        enderecos: [
            "/utilizador-registar-passo-1",
            "/utilizador-registar-passo-2",
            "/utilizador-registo-conclusao",
            "/termos-uso",
            "/politica-privacidade"
        ],
        //LOADING
        loading: true
    };

    UNSAFE_componentWillMount() {
        this.logout();
        this.localizarEntidade();
    }

    componentDidMount() {
        this.redirecionarUltimaPagina();

        this.setState({
            fundoBranco:
                this.props.location.pathname === "/" ||
                this.props.location.pathname === "/registo-conclusao" ||
                this.props.location.pathname === "/registo-conclusao-contacto" ||
                this.props.location.pathname === "/pagina-nao-encontrada",
            semIniciarSessao: this.props.location.pathname === "/utilizador-registar-passo-1" ||
                this.props.location.pathname === "/utilizador-registar-passo-2"
        });
    }

    redirecionarUltimaPagina = () => {
        const path = this.props.match.path;
        if (path === "/" && localStorage.getItem("ultima_pagina"))
            this.props.history.push(localStorage.getItem("ultima_pagina"));
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                fundoBranco:
                    this.props.location.pathname === "/" ||
                    this.props.location.pathname === "/registo-conclusao" ||
                    this.props.location.pathname === "/registo-conclusao-contacto" ||
                    this.props.location.pathname === "/pagina-nao-encontrada",
                semIniciarSessao: this.props.location.pathname === "/utilizador-registar-passo-1" ||
                    this.props.location.pathname === "/utilizador-registar-passo-2"
            });
        }
    }

    logout = () => {
        axios({
            method: "get",
            url: "/api/anonimo/logout"
        });
    };

    stepformaRS = () => {
        axios({
            method: "get",
            timeout: 60 * 10 * 1000,
            url: "/api/anonimo/stepforma-rs"
        })
            .then(response => {
                localStorage.setItem("whatsapp", response.data[6])
                this.setState({
                    redessociais: response.data
                });
            })
            .catch(error => {
                //   this.context.criarLog(error, "Tentativa de carregar redes sociais da stepforma", "/api/anonimo/stepforma-rs");
            });
    };

    localizarEntidade = () => {
        axios({
            method: "get",
            url: "/api/anonimo/localizar-entidade",
            params: {
                dominio: window.location.hostname
            }
        })
            .then(response => {
                if (!response.data && !response.data.nome_abrev || this.state.enderecos.find(x => x === window.location.pathname) !== undefined) {
                    this.setState({
                        loading: false
                    });
                    this.stepformaRS();
                }
                else
                    window.location.replace(`/${response.data.nome_abrev}`);
            })
            .catch(error => {

            });
    }

    render() {
        const { redessociais, fundoBranco, semIniciarSessao } = this.state;

        const opcoes = (
            <ul className="links_uteis" style={{ textAlign: "center" }}>
                {/*<li>
                    <Link to="/suporte-contacto">Contato</Link>
                </li>*/}
                <li>
                    <Link to="/termos-uso">Termos de Uso</Link>
                </li>
                <li>
                    <Link to="/politica-privacidade">Política de Privacidade</Link>
                </li>
            </ul>
        );

        return (
            this.state.loading ?
                <div id="loader-wrapper">
                    <div id="loader">
                        <img src={logo}/>
                    </div>
                </div>
                :
                <>
                    <Navbar semIniciarSessao={semIniciarSessao} style={this.props.location.pathname === "/" ? { marginTop: "0" } : {}} />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/dropbox-auth" component={TesteDropBox} />
                        <Route path="/pagina-nao-encontrada" component={Pagina404} />
                        <Route path="/login" component={Login} />
                        <Route path="/alteracao-senha" component={AlterarSenha} />
                        <Route path="/tarefas-temp" component={TarefasTemp} />
                        <Route path="/redirecionar" component={LoginAuto} />
                        <Route path="/controller" component={LoginAutoV2} />
                        <Route path="/recuperar-senha" component={RecuperarPassword} />
                        <Route path="/alterar-senha" component={AlterarPasswordEmail} />
                        <Route path="/confirmar-email" component={ConfirmarEmail} />
                        <Route path="/registo-conclusao" component={RegistoConclusao} />
                        <Route path="/entidade-registar-passo-1" component={EntidadeRegistarPasso1} />
                        <Route path="/entidade-registar-passo-2" component={EntidadeRegistarPasso2} />
                        <Route path="/utilizador-registar-passo-1" component={UtilizadorRegistarPasso1} />
                        <Route path="/utilizador-registar-passo-2" component={UtilizadorRegistarPasso2} />
                        <Route path="/utilizador-registo-conclusao" component={UtilizadorRegistoConclusao} />
                        <Route path="/suporte-contacto" component={SuporteContacto} />
                        <Route path="/termos-uso" component={TermosCondicoes} />
                        <Route path="/politica-privacidade" component={PoliticaPrivacidade} />
                        <Route path="/cancelar-subscricao/:entidade" component={Unsubscribe} />
                        <Route path="/cancelar-subscricao" component={Unsubscribe} />
                        <Route path="/404" component={Pagina404} />
                    </Switch>
                    <Footer redessociais={redessociais} opcoes={opcoes} fundoBranco={fundoBranco} homeStepforma />
                </>
        );
    }
}

export default Layout;
