import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, notification, Icon } from "antd";
import ModalExcluir from "../Excluir/Excluir";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
class Detalhe extends Component {
    state = {
        //MODAL EXCLUIR
        visibleExcluir: false,
        visibleExcluirWaiting: false,
        opcao: 1
    }

    onChangeSelect = (event) => {
        this.setState({
            opcao: event.target.value
        })
    }

    excluir = () => {
        this.setState({
            visibleExcluir: false,
            visibleExcluirWaiting: true
        })
        axios({
            method: "delete",
            url: "/api/aulas/excluir",
            params: {
                aulaId: this.props.info?.aulaId,
                opcao: this.state.opcao
            }
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", response.data);
                this.setState({
                    visibleExcluirWaiting: false
                }, () => {
                    this.props.atualizarListagem();
                    this.props.onClose();
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.setState({
                    visibleExcluirWaiting: false
                });
            });
    }

    selecionarExcluir = () => {
        if (!this.props.info.recorrencia)
            confirm({
                title: "Pretende excluir esta aula?",
                okText: "Confirmar",
                okType: "Cancelar",
                onOk: () => {
                    this.excluir();
                }
            });
        else {
            this.setState({
                visibleExcluir: true
            })
        }
    }

    render() {
        const {
            //MODAL EXCLUIR
            visibleExcluir,
            visibleExcluirWaiting,
            opcao
        } = this.state;

        const {
            visible,
            info
        } = this.props;

        return (
            <>
                <Modal
                    visible={visible}
                    maskClosable={false}
                    className="modal-aula-detalhe"
                    footer={null}
                    closable={false}
                >
                    <div className="container">
                        <div className="header">
                            <h3>{info.disciplina}</h3>
                            <div className="controls">
                                {/*<Link className="botao-icon-editar" to="#" title="Editar" onClick={this.props.abrirEditar}>*/}
                                {/*    <Icon type="edit" />*/}
                                {/*</Link>*/}
                                {/*<Link className="botao-icon-excluir" to="#" title="Excluir" onClick={this.selecionarExcluir}>*/}
                                {/*    <Icon type="delete" />*/}
                                {/*</Link>*/}
                                <Link to="#" className="botao-icon-fechar" title="Voltar" onClick={this.props.onClose}>
                                    <Icon type="close" />
                                </Link>
                            </div>
                        </div>
                        <div className="body">
                            <div className="date"><Icon type="calendar" /> {info.data}</div>
                            <div className="hours"><Icon type="clock-circle" /> {info.horario}</div>
                            {info.sala && (
                                <div className="place"><Icon type="environment" /> {info.sala}</div>)}
                            <div className="teacher"><Icon type="user" /> {info.professor}</div>
                        </div>
                    </div>
                </Modal>
                <ModalExcluir
                    opcao={opcao}
                    visible={visibleExcluir}
                    onChangeSelect={this.onChangeSelect}
                    onClose={() => this.setState({ visibleExcluir: false })}
                    excluir={this.excluir}
                />
                <Modal visible={visibleExcluirWaiting} maskClosable={false} className="exportar-csv" closable={false} footer={null}>
                    <div className="exportar-csv-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A excluir...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default Detalhe;
