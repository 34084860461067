import React, { Component } from "react";
import ConteudoResolucao from "./ConteudoResolucao";
import Controlos from "../_Aux/Controlos";
import ControlosSimples from "../_Aux/ControlosSimples";
import LazyLoad from "react-lazy-load";
import Oops from "../../Geral/Oops.jsx";

class Resolucao extends Component {
    state = {
        //NAO DISPONIVEL
        nao_disponivel: false,
        sameUrl: true,
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    marcarComoNaoDisponivel = () => {
        this.setState({
            nao_disponivel: true
        })
    }

    render() {
        const {
            //NAO DISPONIVEL
            nao_disponivel,
            sameUrl
        } = this.state;

        return (
            <div className="questionario">
                <div className="player-wrapper">
                    {/*<LazyLoad offsetVertical={200}>*/}
                    {/*    <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />*/}
                    {/*</LazyLoad>*/}
                    {!nao_disponivel ?
                        <>
                            <ConteudoResolucao marcarComoNaoDisponivel={this.marcarComoNaoDisponivel} />
                            <Controlos
                                backUrl={`/curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                sameUrl={sameUrl}
                            />
                        </>
                        :
                        <>
                            <div className="container">
                                <div className="bloco-nao-disponivel">
                                    <div className="bloco-info nao-disponivel">
                                        <Oops tem_texto texto={"Conteúdo não disponível"} />
                                    </div>
                                </div>
                            </div>
                            <ControlosSimples />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default Resolucao;
