import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Checkbox, notification, Icon, Tooltip } from "antd";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Educacao extends Component {
    static contextType = GlobalContext;

    state = {
        responsaveis_stepforma: false,
        responsaveis: false,
        login2_stepforma: false,
        login2: false,
        escolas: false,
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.carregarDados();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeHost = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
                if (!this.state.host && !this.state.port && !this.state.username && !this.state.password) {
                    this.props.form.resetFields()
                }
        });
    };

    handleChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    carregarDados = () => {
        axios({
            method: "get",
            url: "/api/empresa/configs-gestao"
        })
            .then(response => {
                this.setState({
                    responsaveis_stepforma: response.data.responsaveis_stepforma,
                    responsaveis: response.data.responsaveis,
                    login2_stepforma: response.data.login2_stepforma,
                    login2: response.data.login2,
                    escolas: response.data.escolas
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    atualizarDados = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll(["nome_abrev"], (err, values) => {
            if (!err) {

                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("login2", this.state.login2);
                item.append("escolas", this.state.escolas);
                item.append("responsaveis", this.state.responsaveis);

                axios({
                    method: "put",
                    url: "/api/empresa/alterar-configuracoes-gestao",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.setState({
                            iconLoading: false
                        });

                        this.context.atualizarState({
                            atualizarEntidade: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    };

    validarEstrutura = (rule, value, callback) => {
        var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (format.test(value))
            callback("Nome abreviado deve conter apenas letras e números");

        callback();
    }

    render() {
        const {
            responsaveis_stepforma,
            responsaveis,
            login2_stepforma,
            login2,
            escolas,
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-redes-sociais bloco-personalizacao">
                <Form className="personalizacao" onSubmit={this.atualizarDados}>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="login2" checked={login2} onChange={this.handleChangeCheckbox} disabled={!login2_stepforma || !escolas || !this.context.visivel_entidade}>
                            Acesso por Formulário de Login Simplificado{" "}
                            <Tooltip className="info-icon" title="O formulário de login da página pública do Canal possui 2 modos: Professor/Gestor ou Simplificado. O segundo usa o Nome e Sobrenome, a Senha e a Unidade Organizacional (se aplicável) - para autenticação.">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </Checkbox>
                    </Form.Item>
                    {/*<Form.Item style={{ marginBottom: 20 }}>*/}
                    {/*    <Checkbox name="responsaveis" checked={responsaveis} onChange={this.handleChangeCheckbox} disabled={!responsaveis_stepforma}>*/}
                    {/*        Comunicação com Responsáveis{" "}*/}
                    {/*        <Tooltip className="info-icon" title="Pai e/ou Mãe, Encarregado de educação, Chefe,...">*/}
                    {/*            <Icon type="question-circle-o" />*/}
                    {/*        </Tooltip>*/}
                    {/*    </Checkbox>*/}
                    {/*</Form.Item>*/}
                    <button type="submit" className="botao-principal" disabled={iconLoading}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Salvar
                    </button>
                </Form>
            </div>
        );
    }
}

const FormEducacao = Form.create({ name: "form-educacao" })(Educacao);

export default FormEducacao;
