import React, { Component } from "react";
import axios from "axios";

class TarefasTemp extends Component {
    alterarSenhas = () => {
        axios({
            method: "get",
            url: "/api/tarefas-temp/alterar-senhas-maravilha"
        })
            .then(response => {
                console.log("OK")
            })
            .catch(error => {
                console.log("erro!", error)
            });
    }

    render() {
        return (
            <div>
                <button onClick={this.alterarSenhas}>Senhas Maravilha</button>
            </div>
        );
    }
}

export default TarefasTemp;
