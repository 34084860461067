import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Icon, Tabs, Collapse, Modal, notification, Button, Form, Select, Radio, Tooltip } from "antd";
import DetalheAula from "./_Aux/Detalhe";
import Conteudos from "./_Aux/Conteudos";
import Frequencia from "./_Aux/Frequencia";
import Parecer from "./_Aux/Parecer";
import DrawerAdicionar from "./_Aux/DrawerAdicionar";
import { GeralContext } from "../ContextProvider";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const { TabPane } = Tabs;
const { Panel } = Collapse;

class Detalhe extends Component {
    static contextType = GlobalContext;

    state = {
        aulaId: "",
        visible: false,
        titulo: "",
        avaliacao: false,
        dt_inicio: "",
        hr_inicio: "",
        hr_termino: "",
        recorrencia: false,
        cargaAcima: false,
        duplicada: false,
        foraPeriodo: false,
        extracurricular: false,
        //MODAL EXCLUIR
        visibleExcluir: false,
        visibleExcluirWaiting: false,
        opcao: 1,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //EXCLUIR
        excluir_sumario: false,
        excluir_frequencia: false,
        excluir_pareceres: false,
        sumarioExcluido: false,
        frequenciaExcluido: false,
        pareceresExcluido: false
    };

    componentDidMount() {
        this.detalhe();
    }

    selecionarOpcaoHandler = event => {
        this.setState({
            opcao: event.target.value
        });
    };

    detalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe",
            params: {
                aulaId: localStorage.getItem("aula_diario")
            }
        })
            .then(response => {
                if (!response.data.reagendada)
                    this.setState({
                        titulo: response.data.titulo,
                        avaliacao: response.data.avaliacao,
                        dt_inicio: moment(response.data.dt_inicio).format("DD/MM/YYYY"),
                        hr_inicio: response.data.hr_inicio,
                        hr_termino: response.data.hr_termino,
                        recorrencia: response.data.recorrencia,
                        cargaAcima: response.data.cargaAcima,
                        duplicada: response.data.duplicada,
                        foraPeriodo: response.data.foraPeriodo,
                        extracurricular: response.data.extracurricular,
                        //EXCLUIR
                        excluir_sumario: response.data.temSumario,
                        excluir_frequencia: response.data.temFrequencia,
                        excluir_pareceres: response.data.temPareceres
                    });
                else
                    this.setState({
                        titulo: response.data.titulo,
                        avaliacao: response.data.avaliacao,
                        dt_inicio: moment(response.data.dt_inicio_reagendada).format("DD/MM/YYYY"),
                        hr_inicio: response.data.hr_inicio_reagendada,
                        hr_termino: response.data.hr_termino_reagendada,
                        recorrencia: response.data.recorrencia,
                        cargaAcima: response.data.cargaAcima,
                        duplicada: response.data.duplicada,
                        extracurricular: response.data.extracurricular,
                        //EXCLUIR
                        excluir_sumario: response.data.temSumario,
                        excluir_frequencia: response.data.temFrequencia,
                        excluir_pareceres: response.data.temPareceres
                    });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    };

    selecionarExcluir = () => {
        if (!this.state.recorrencia)
            confirm({
                title: "Pretende excluir esta aula?",
                okText: "Confirmar",
                okType: "Cancelar",
                onOk: () => {
                    this.excluir(false);
                }
            });
        else {
            this.setState({
                visibleExcluir: true
            });
        }
    };

    excluir = () => {
        this.setState({
            visibleExcluir: false,
            visibleExcluirWaiting: true
        });
        axios({
            method: "delete",
            url: "/api/aulas/excluir",
            params: {
                aulaId: localStorage.getItem("aula_diario"),
                opcao: this.state.opcao
            }
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Aula excluida!");
                this.setState({
                    visibleExcluirWaiting: false,
                    redirect: true,
                    redirectLink: localStorage.getItem("link_voltar_calendario")
                    //redirectLink: `/gerir-detalhe-turma/${this.props.match.params.curso}/horario-disciplina`
                });
            })
            .catch(error => {
                openNotificationWithIcon(
                    "error",
                    "Erro",
                    <div>
                        <p>{error.response.data.mensagem}</p>
                        {error.response.data.infos && this.state.opcao === 1 && (
                            <ul>
                                {error.response.data.infos.sumario && <li>Conteúdo</li>}
                                {error.response.data.infos.frequencia && <li>Frequências</li>}
                                {error.response.data.infos.pareceres && <li>Pareceres</li>}
                                {error.response.data.infos.falta && <li>Falta de professor</li>}
                            </ul>
                        )}
                        {error.response.data.infos && this.state.opcao === 2 && (
                            <ul>
                                {error.response.data.infos.sumario && <li>Conteúdo: {error.response.data.infos.sumario}</li>}
                                {error.response.data.infos.frequencia && <li>Frequências: {error.response.data.infos.frequencia}</li>}
                                {error.response.data.infos.pareceres && <li>Pareceres: {error.response.data.infos.pareceres}</li>}
                                {error.response.data.infos.falta && <li>Falta de professor: {error.response.data.infos.falta}</li>}
                            </ul>
                        )}
                    </div>
                );
                this.setState({
                    visibleExcluirWaiting: false
                });
            });
    };

    excluirSumario = () => {
        confirm({
            title: "Pretende excluir os sumários desta aula?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/aulas/excluir-sumario",
                    params: {
                        aulaId: localStorage.getItem("aula_diario")
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Sumário excluido!");
                        this.setState({
                            excluir_sumario: false,
                            sumarioExcluido: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o sumário");
                    });
            }
        });
    };

    excluirFrequencia = () => {
        confirm({
            title: "Pretende excluir a frequência desta aula?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/aulas/excluir-frequencia",
                    params: {
                        aulaId: localStorage.getItem("aula_diario")
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Frequência excluida!");
                        this.setState({
                            excluir_frequencia: false,
                            frequenciaExcluido: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a frequência");
                    });
            }
        });
    };

    excluirPareceres = () => {
        confirm({
            title: "Pretende excluir os pareceres desta aula?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/aulas/excluir-pareceres",
                    params: {
                        aulaId: localStorage.getItem("aula_diario")
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Pareceres excluidos!");
                        this.setState({
                            excluir_pareceres: false,
                            pareceresExcluido: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir os pareceres");
                    });
            }
        });
    };

    render() {
        const {
            aulaId,
            visible,
            titulo,
            avaliacao,
            dt_inicio,
            hr_inicio,
            hr_termino,
            cargaAcima,
            duplicada,
            foraPeriodo,
            extracurricular,
            //MODAL EXCLUIR
            visibleExcluir,
            visibleExcluirWaiting,
            opcao,
            //REDIRECT
            redirect,
            redirectLink,
            //EXCLUIR
            excluir_sumario,
            excluir_frequencia,
            excluir_pareceres,
            sumarioExcluido,
            frequenciaExcluido,
            pareceresExcluido
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const radioStyle = {
            display: "block",
            height: "30px",
            lineHeight: "30px",
            marginBottom: 0
        };

        const acesso = this.context.acessos?.find(x => x.func === 17);
        const canalIranduba = localStorage.getItem("entidade_id") === "33";
        const mostrarAlerta = cargaAcima || duplicada && (canalIranduba && !extracurricular || !canalIranduba) || foraPeriodo && !extracurricular

        return (
            <>
                <div className="container container-body">
                    <div className="curso-diario curso-diario-desktop" style={{ padding: "15px 15px 30px" }}>
                        <div className="bloco-informacoes" style={{ border: "none", marginBottom: 0 }}>
                            <div className="info-aula">
                                <span>
                                    <span className="titulo-aula">{localStorage.getItem("horario_detalhe_titulo")}</span>
                                </span>
                                <span className="bloco-opcoes" style={{ gap: 10 }}>
                                    {acesso?.delete && (
                                        <Link
                                            to="#"
                                            className="botao-icon-excluir botao-secundario-redondo"
                                            title="Excluir"
                                            style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                                            onClick={this.selecionarExcluir}
                                        >
                                            <Icon type="delete" />
                                        </Link>
                                    )}
                                    <Link
                                        //to={`/gerir-detalhe-turma/${this.props.match.params.turma}/horario-disciplina`}
                                        to={localStorage.getItem("link_voltar_calendario")}
                                        className="botao-secundario botao-secundario-redondo"
                                        title="Voltar"
                                        style={{ marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <Icon type="arrow-left" />
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="collapse-info-diario">
                            <Collapse defaultActiveKey={[]} className="collapse-campos" accordion>
                                <Panel
                                    header={
                                        <span style={{ display: "flex", gap: 10 }}>
                                            <span className="data-aula">
                                                {dt_inicio}
                                                <i className="fas fa-circle"></i>
                                                {hr_inicio} - {hr_termino}
                                            </span>
                                            {mostrarAlerta && (
                                                <span className="aula-problemas">
                                                    <Icon type="warning" title={duplicada ? "Aula sobreposta" : "Aula fora do periodo"} />
                                                </span>
                                            )}
                                        </span>
                                    }
                                    key="1"
                                >
                                    <DetalheAula
                                        aulaId={localStorage.getItem("aula_diario")}
                                        cargaAcima={cargaAcima}
                                        duplicada={duplicada}
                                        foraPeriodo={foraPeriodo}
                                        extracurricular={extracurricular}
                                        confirmarAula={() => this.setState({ cargaAcima: false, duplicada: false, foraPeriodo: false })}
                                    />
                                </Panel>
                                <Panel
                                    header={
                                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                            <span>Conteúdo</span>
                                            {excluir_sumario && acesso?.delete && (
                                                <Link
                                                    to="#"
                                                    className="botao-icon-excluir"
                                                    title="Excluir"
                                                    style={{
                                                        marginRight: 10,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        this.excluirSumario();
                                                    }}
                                                >
                                                    <Icon type="delete" />
                                                </Link>
                                            )}
                                        </div>
                                    }
                                    key="2"
                                >
                                    <Conteudos
                                        sumarioExcluido={sumarioExcluido}
                                        resetSumarioExcluido={() => this.setState({ sumarioExcluido: false })}
                                        disponibilizarExcluir={() => this.setState({ excluir_sumario: true })}
                                    />
                                </Panel>
                                <Panel
                                    header={
                                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                            <span>Frequência</span>
                                            {excluir_frequencia && acesso?.delete && (
                                                <Link
                                                    to="#"
                                                    className="botao-icon-excluir"
                                                    title="Excluir"
                                                    style={{
                                                        marginRight: 10,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        this.excluirFrequencia();
                                                    }}
                                                >
                                                    <Icon type="delete" />
                                                </Link>
                                            )}
                                        </div>
                                    }
                                    key="3"
                                >
                                    <Frequencia
                                        dtInicio={moment(`${dt_inicio} ${hr_termino}`, "DD/MM/YYYY HH:mm")}
                                        frequenciaExcluido={frequenciaExcluido}
                                        resetFrequenciaExcluido={() => this.setState({ frequenciaExcluido: false })}
                                        disponibilizarExcluir={() => this.setState({ excluir_frequencia: true })}
                                    />
                                </Panel>
                                {this.props.pareceresPorAula && (
                                    <Panel
                                        header={
                                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                <span>Pareceres</span>
                                                {excluir_pareceres && acesso?.delete && (
                                                    <Link
                                                        to="#"
                                                        className="botao-icon-excluir"
                                                        title="Excluir"
                                                        style={{
                                                            marginRight: 10,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            this.excluirPareceres();
                                                        }}
                                                    >
                                                        <Icon type="delete" />
                                                    </Link>
                                                )}
                                            </div>
                                        }
                                        key="4"
                                    >
                                        <Parecer
                                            pareceresExcluido={pareceresExcluido}
                                            resetPareceresExcluido={() => this.setState({ pareceresExcluido: false })}
                                            disponibilizarExcluir={() => this.setState({ excluir_pareceres: true })}
                                        />
                                    </Panel>)}
                            </Collapse>
                        </div>
                    </div>
                    <div className="curso-diario curso-diario-mobile">
                        <div className="bloco-informacoes" style={{ border: "none", marginBottom: 0 }}>
                            <div className="info-aula">
                                <span>
                                    <span className="titulo-aula">{localStorage.getItem("horario_detalhe_titulo")}</span>
                                </span>
                                <span className="bloco-opcoes" style={{ gap: 10 }}>
                                    {acesso?.delete && (
                                        <Link
                                            to="#"
                                            className="botao-icon-excluir botao-secundario-redondo"
                                            title="Excluir"
                                            style={{
                                                marginRight: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: 30,
                                                height: 30
                                            }}
                                            onClick={this.selecionarExcluir}
                                        >
                                            <Icon type="delete" />
                                        </Link>
                                    )}
                                    <Link
                                        //to={`/gerir-detalhe-turma/${this.props.match.params.turma}/horario-disciplina`}
                                        to={localStorage.getItem("link_voltar_calendario")}
                                        className="botao-secundario botao-secundario-redondo"
                                        title="Voltar"
                                        style={{
                                            marginRight: 10,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: 30,
                                            height: 30
                                        }}
                                    >
                                        <Icon type="arrow-left" />
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="collapse-info-diario">
                            <Collapse defaultActiveKey={[]} className="collapse-campos">
                                <Panel
                                    header={
                                        <span style={{ display: "flex", gap: 10 }}>
                                            <span className="data-aula">
                                                {dt_inicio}
                                                <i className="fas fa-circle"></i>
                                                {hr_inicio} - {hr_termino}
                                            </span>
                                            {mostrarAlerta && (
                                                <span className="aula-problemas">
                                                    <Icon type="warning" title={duplicada ? "Aula sobreposta" : "Aula fora do periodo"} />
                                                </span>
                                            )}
                                        </span>
                                    }
                                    key="1"
                                >
                                    <DetalheAula
                                        aulaId={localStorage.getItem("aula_diario")}
                                        cargaAcima={cargaAcima}
                                        duplicada={duplicada}
                                        foraPeriodo={foraPeriodo}
                                        extracurricular={extracurricular}
                                        confirmar={() => this.setState({ cargaAcima: false, duplicada: false, foraPeriodo: false })}
                                    />
                                </Panel>
                                <Panel
                                    header={
                                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                            <span>Conteúdo</span>
                                            {excluir_sumario && acesso?.delete && (
                                                <Link
                                                    to="#"
                                                    className="botao-icon-excluir"
                                                    title="Excluir"
                                                    style={{
                                                        marginRight: 10,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        this.excluirSumario();
                                                    }}
                                                >
                                                    <Icon type="delete" />
                                                </Link>
                                            )}
                                        </div>
                                    }
                                    key="2"
                                >
                                    <Conteudos
                                        sumarioExcluido={sumarioExcluido}
                                        resetSumarioExcluido={() => this.setState({ sumarioExcluido: false })}
                                        disponibilizarExcluir={() => this.setState({ excluir_sumario: true })}
                                    />
                                </Panel>
                                <Panel
                                    header={
                                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                            <span>Frequência</span>
                                            {excluir_frequencia && acesso?.delete && (
                                                <Link
                                                    to="#"
                                                    className="botao-icon-excluir"
                                                    title="Excluir"
                                                    style={{
                                                        marginRight: 10,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        this.excluirFrequencia();
                                                    }}
                                                >
                                                    <Icon type="delete" />
                                                </Link>
                                            )}
                                        </div>
                                    }
                                    key="3"
                                >
                                    <Frequencia
                                        dtInicio={moment(`${dt_inicio} ${hr_termino}`, "DD/MM/YYYY HH:mm")}
                                        frequenciaExcluido={frequenciaExcluido}
                                        resetFrequenciaExcluido={() => this.setState({ frequenciaExcluido: false })}
                                        disponibilizarExcluir={() => this.setState({ excluir_frequencia: true })}
                                    />
                                </Panel>
                                {this.props.pareceresPorAula && (
                                    <Panel
                                        header={
                                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                <span>Pareceres</span>
                                                {excluir_pareceres && acesso?.delete && (
                                                    <Link
                                                        to="#"
                                                        className="botao-icon-excluir"
                                                        title="Excluir"
                                                        style={{
                                                            marginRight: 10,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            this.excluirPareceres();
                                                        }}
                                                    >
                                                        <Icon type="delete" />
                                                    </Link>
                                                )}
                                            </div>
                                        }
                                        key="4"
                                    >
                                        <Parecer
                                            pareceresExcluido={pareceresExcluido}
                                            resetPareceresExcluido={() => this.setState({ pareceresExcluido: false })}
                                            disponibilizarExcluir={() => this.setState({ excluir_pareceres: true })}
                                        />
                                    </Panel>)}
                            </Collapse>
                        </div>
                    </div>
                </div>
                <DrawerAdicionar
                    aulaId={localStorage.getItem("aula_diario")}
                    visible={visible}
                    onClose={() =>
                        this.setState({
                            visible: false
                        })
                    }
                    atualizarAula={aula =>
                        this.setState({
                            titulo: aula.titulo,
                            dt_inicio: moment(aula.dt_inicio).format("DD/MM/YYYY"),
                            hr_inicio: moment(aula.hr_inicio).format("HH:mm"),
                            hr_termino: moment(aula.hr_termino).format("HH:mm"),
                            visible: false
                        })
                    }
                    temRecorrencia={this.state.recorrencia}
                />
                <Modal
                    visible={visibleExcluir}
                    width="450px"
                    className="modal-encerrar-stepmeet"
                    cancelText="Cancelar"
                    onCancel={() => this.setState({ visibleExcluir: false })}
                    okText="Confirmar"
                    okButtonProps={{ disabled: !opcao }}
                    onOk={this.excluir}
                >
                    <Icon type="question-circle" className="icon-modal-encerrer-stepmeet" />
                    <p className="texto-modal-encerrar-stepmeet">Pretende excluir?</p>
                    <Form>
                        <Form.Item>
                            <Radio.Group onChange={this.selecionarOpcaoHandler} value={opcao}>
                                <Radio style={radioStyle} value={1}>
                                    Esta aula
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    Esta aula e as seguintes
                                    <Tooltip
                                        className="info-icon info-icon2"
                                        title="Esta opção não excluí aulas que contenham registos de conteúdos, frequências e pareceres."
                                    >
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                                {/*<Radio style={radioStyle} value={3}>*/}
                                {/*    Todas as aulas*/}
                                {/*</Radio>*/}
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal visible={visibleExcluirWaiting} maskClosable={false} className="exportar-csv" closable={false} footer={null}>
                    <div className="exportar-csv-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A excluir...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

const ContextGerirHorario = ({ match }) => (
    <GeralContext.Consumer>
        {({ pareceresPorAula }) => <Detalhe pareceresPorAula={pareceresPorAula} match={match} />}
    </GeralContext.Consumer>
);

export default ContextGerirHorario;
