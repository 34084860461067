import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, Menu, Dropdown, Icon } from "antd";
import { Link } from "react-router-dom";
import { Select } from "antd";
import { GeralContext } from "../ContextProvider"

const Option = Select.Option;

class TabelaEnviadas extends Component {
    static contextType = GeralContext;
    state = {
        filteredInfo: null,
        sortedInfo: null
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter
        });
    };

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        const { videoconferencias } = this.props;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = !this.context.esconderListaParticipantes ?
            [
                {
                    title: "Realizada",
                    dataIndex: "inicio",
                    key: "inicio"
                },
                {
                    title: "Assunto",
                    dataIndex: "assunto",
                    key: "assunto"
                },
                {
                    title: "Iniciada por",
                    dataIndex: "realizada",
                    key: "realizada"
                },
                {
                    title: "",
                    dataIndex: "controlos",
                    key: "controlos"
                }
            ]
            :
            [
                {
                    title: "Realizada",
                    dataIndex: "inicio",
                    key: "inicio"
                },
                {
                    title: "Assunto",
                    dataIndex: "assunto",
                    key: "assunto"
                },
                {
                    title: "Iniciada por",
                    dataIndex: "realizada",
                    key: "realizada"
                },
                {
                    title: "",
                    dataIndex: "controlos",
                    key: "controlos"
                }
            ];


        return (
            <>
                <Table
                    columns={columns}
                    dataSource={videoconferencias}
                    pagination={this.props.pagination}
                    loading={this.props.loading}
                    onChange={this.props.handleTableChange}
                    locale={{ emptyText: "Não existem dados!" }}
                />
                {videoconferencias.length > 0 && (
                    <div className="num_elementos">
                        Mostrar{" "}
                        <Select defaultValue="10" onChange={this.props.handleChangeSelect}>
                            <Option value="10">10</Option>
                            <Option value="25">25</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                    </div>
                )}
            </>
        );
    }
}

export default withRouter(TabelaEnviadas);
